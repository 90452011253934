import { Component, OnInit } from '@angular/core';
import { IotService } from '../../service/iot.service';
import { DeviceService } from '../../service/device.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import { faTimes,faAngleDoubleLeft, faAngleDoubleRight,faCheck} from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { EcosystemTemplatesService } from 'src/app/service/ecosystemTemplates.service';


@Component({
  selector: 'app-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.scss']
})
export class CreateEventComponent implements OnInit {
  
 errorMsg;
  eventTemplate;
  parameters = [];
  displayedColumns: string[] = ['Name', 'Description', 'Data Type', 'Binding',' '];
  attrColumns: string[] = ['Name','Type'];
  
  faAngleDoubleLeftIcon = faAngleDoubleLeft;
  faAngleDoubleRightIcon = faAngleDoubleRight;
  faTimesIcon = faTimes;
  faCheckIcon = faCheck;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tags:string[]=[];
  supportLanguages = ['en'];


  eventDisabledClass;
  eventEnabledClass = 'bg-enabled-selected';
  attributes = [];
  currentPage =1;
  showLoading:boolean=false;
  tagList =[];
  tagClass = 'tagInput';
  nameError: string;
  dataPointTypeError: string;
  dataPointNameError: string;
  eventCreated=false;
  constructor(private iotService:IotService,private deviceService:DeviceService,private translateService: TranslateService, private ecosystemTemplateService: EcosystemTemplatesService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }

  ngOnInit(): void {
    this.initEventTemplate();
    this.getAttributes(this.currentPage);
  }

  createEvent(){
    this.deviceService.createResource=false;
    this.deviceService.createEvent=false;
    this.deviceService.errorMessage = null;
    if (this.iotService.createEcosystemTemplate == true) {
      this.ecosystemTemplateService.createResource = true;
      this.ecosystemTemplateService.createEvent = false;
    }
  }

  initEventTemplate() {
    const that = this;
    that.eventTemplate = {
      "creator": that.iotService.getCreator(),
      "creatorAppId": that.iotService.getCreatorAppId(),
      "realm": that.iotService.getRealm(),
      'name': '',
      'description': [{
          'lang': 'en_us',
          'text': ''
      }],

      'isActive': true
    }
    that.parameters = [];
    that.parameters.push({
        name: '',
        description: '',
        type: '',
        binding: '',
        bindingname: 'Select binding'
    });
  }

  getAttributes(currentPage){
    const that = this;
    this.currentPage = currentPage;
    this.iotService.getCuiObjResponse().getAttributeTypes({
      qs:[['pageSize',5],['page',currentPage]]
    }).then(function(response) {
      that.attributes = response;
      console.log("attributes :: "+that.attributes);
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  nextPage() {
    this.currentPage++;
    this.getAttributes(this.currentPage);
  }

  prevPage() {
      this.currentPage--;
      if (this.currentPage < 1) { 
        this.currentPage= 1; 
      }   
      this.getAttributes(this.currentPage);
  }

  add(event: MatChipInputEvent): void {
    this.tagClass = 'tagInput';
    const input = event.input;
    const value = event.value;
    const index = this.tagList.indexOf(value);
    this.tagList.push(value);

    if(index<0){
    if ((value || '').trim()) {
      const that = this;
      that.tags.push(value);
      // Reset the input value
      if (input) {
        input.value = '';
      }
    }
   }else{
    this.tagClass = 'tagInput-duplicate';
    }
  }

  remove(tag: string): void {
    const that = this;
    const index = that.tags.indexOf(tag);
    if (index >= 0) {
      that.tags.splice(index, 1);
    }
  }

  addDataPoint() {
    const data = this.parameters;
    data.push({
      name: '',
      description: '',
      type: '',
      binding: '',
      bindingname: 'Select binding'
    });
    this.parameters = data;
    console.log("parameters :: "+this.parameters.length);
  }

  removeDataPoint(element) {
    const that = this;
    const index = that.parameters.indexOf(element);

    if (index >= 0) {
      that.parameters.splice(index, 1);
    }
    console.log("parameters :: "+that.parameters);
  }

  save() {
  
    const that = this;
    that.deviceService.errorMessage = null;
    if (that.eventTemplate.name === '') {
      that.nameError='Name Required';
      that.deviceService.errorMessage = "Please provide all required fields";
    }
    if (that.parameters.length > 0) {
        that.eventTemplate.eventFields = [];
        that.parameters.forEach(value => {
            if (value.type === "") {
                // that.dataPointTypeError="Data Point Type Required";
                // that.deviceService.errorMessage = "Data Point Type Required";
              }
            if (value.name === "") {
              // that.dataPointNameError="Data Point Name Required";
              // that.deviceService.errorMessage = "Data Point Name Required";
            }
            that.eventTemplate.eventFields.push({
                name: value.name,
                description: [{
                    'lang': 'en_us',
                    'text': value.description
                }],
                type: value.type,
                boundAttributeTypeId: value.binding
            });
        });
    }
    // if (undefined != that.targetDocInfo) {
    //   that.eventTemplate.documentInfoId = that.targetDocInfo.id;
    // }
    if (that.deviceService.errorMessage === null||that.deviceService.errorMessage === '') {
      that.eventCreated=true;
        that.iotService.getCuiObjResponse().createEventTemplate({
            data: that.eventTemplate
        }).then(function (response) {
          that.showLoading=true;

          // activate for newly created Event
          if(that.eventTemplate.isActive === true) {
            that.iotService.getCuiObjResponse().activateEventTemplate({
              eventTemplateId: response.id
            }).then(function (activateResponse) {
              console.log("Event id : "+response.id+" is activated");
            }).fail(function (err){
              that.eventCreated=false;
              if(err.responseJSON.apiStatusCode){
                var apiStatusCode = err.responseJSON.apiStatusCode;
        
                if(apiStatusCode === 'framework:resource:eventTemplate:duplicate') {
                  that.deviceService.warningMessage="Event created but disabled, duplicate event exists.";
                } else {
                  that.deviceService.warningMessage="Event created but disabled. Error:Msg:"+ err.responseJSON.apiStatusCode;
                  console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
                } 
              } else{
                console.log("Something went wrong. Please check chrome console or check with dev team");
              }
            });
          }

          // Save Tags for newly created Event
          if(that.tags != []) {
            that.tags.forEach(element => {
              that.iotService.getCuiObjResponse().createEventTemplateTag({
                  eventTemplateId: response.id,
                  tag: element
              }).then(function (response) {
                console.log("Added tag : "+element);
              })
            });
          }

          if(that.parameters != []) {

            that.parameters.forEach(value => {
              if (value.binding) {
                  that.iotService.getCuiObjResponse().bindEventTemplateField({
                      'eventTemplateId': response.id,
                      'qs': [
                          ['attributeTypeId', value.binding],
                          ['fieldName', value.name]
                      ]
                  })
                  .then(function (response) {
                      // Do something with response
                  })
                  .fail(function (err) {
                      // Handle error
                      that.eventCreated=false;
                  });
              }
            });  

          }
          
          setTimeout(()=>{ 
            that.deviceService.createResource=false;
            that.deviceService.createEvent=false;
            that.showLoading = false;
            if (that.iotService.createEcosystemTemplate == true) {
              that.ecosystemTemplateService.createResource = true;
              that.ecosystemTemplateService.createEvent = false;
            }
          }, 5000);
                            
        }).fail(function (err) {
          that.eventCreated=false;
          that.showLoading = false;
          if (err.responseJSON&&err.responseJSON.apiStatusCode) {
            if(err.responseJSON.apiMessage){
              that.deviceService.errorMessage = err.responseJSON.apiMessage;
            }
            else{
              that.deviceService.errorMessage = 'Error occured while creating event template';
            }
            console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
          } else{
            that.deviceService.errorMessage = 'Error occured while creating event template';
            console.log("Something went wrong. Please check chrome console or check with dev team");
          }
          if(err === null) {
            console.log("Error in createEventTemplate");
          }
        });

      
    } else {
        that.deviceService.errorMessage = that.deviceService.errorMessage;
    }
  }

  toggleActivate(active) {
    const that = this;
    if (active) {
      that.eventDisabledClass = 'bg-disabled';
      that.eventEnabledClass = 'bg-enabled-selected';
      that.eventTemplate.isActive = true;
    } else {
      that.eventDisabledClass = 'bg-disabled-selected';
      that.eventEnabledClass = 'bg-enabled';
      that.eventTemplate.isActive = false;
    }
    console.log('isActive:',that.eventTemplate.isActive);
  }
  
  setBinding(parameter,attribute) {
    console.log("setBinding -- "+attribute.name);
    if (parameter.type == attribute.type) {
        parameter.bindingname = attribute.name;
        parameter.binding = attribute.id;
        //window.location.reload();
    }
  }

}

/** 
 * This is an auto generated code for digitalTwinTemplates service listing page.
 * This file can be customized and will not be overwritten.
 * @version 1.0.0 
 * @readonly 
 * @author - System generated 
 */

import { Injectable } from '@angular/core';
import { SelectionModel} from '@angular/cdk/collections';
import { IotService } from '../service/iot.service'; 
import { ListDigitalTwinTemplatesService } from './ListDigitalTwinTemplates.service';
import { RulesService } from '../service/rules.service';

@Injectable({ 
	  providedIn: 'root' 
})

export class DigitalTwinTemplatesService extends ListDigitalTwinTemplatesService {
	events = [];
	commands = [];
	geofences = [];
	attributes = [];
	successMessage='';
  	warningMessage='';
	errorMessage: string;
	digitalTwinTemplateId;


	
	 constructor(public iotService:IotService, public ruleService: RulesService) {
		 super(iotService);
		 this.selection.clear();
	 }

	 
	associationMap = new Map<String, String>();
	
	 resetWarningMessage(){
		this.warningMessage='';
	  }
	  resetSuccessMessage(){
		this.successMessage='';
	  }
	  resetErrorMessage() {
		this.errorMessage = '';
	  }
	 getEvents(): any {
		return this.events;
	  }
	
	  setEvents(events: any) {
		this.events = events;
	  }

	  getCommands(): any {
		return this.commands;
	  }
	
	  setCommands(commands: any) {
		this.commands = commands;
	  }
	  getAttributes(): any {
		return this.attributes;
	  }
	
	  setAttributes(attributes: any) {
		this.attributes = attributes;
	  }
	  getGeofences(): any {
		return this.geofences;
	  }
	
	  setGeofences(geofences: any) {
		this.geofences = geofences;
	  }
	  
	  setDigitalTwinTemplateId(digitalTwinTemplateId) {
		this.digitalTwinTemplateId = digitalTwinTemplateId;
	  }
	
	  getDigitalTwinTemplateId() {
		return this.digitalTwinTemplateId;
	  }

	 fnOnSelectionSupport(results){
		 const that = this; 
		 if (that.iotService.createRule == true) {
			let deviceTemplates = that.ruleService.getDeviceTemplates()
			deviceTemplates.push(results);
			that.ruleService.setDeviceTemplates(deviceTemplates);
		}
	 } 

	 fnRemoveSelectionSupport(results){ 
		 const that = this; 
		 if (that.iotService.createRule == true) {
			let deviceTemplates = that.ruleService.getDeviceTemplates();
			deviceTemplates.forEach((value, index) => {
				if (value.id == results.id) deviceTemplates.splice(index, 1);
			});
			that.ruleService.setDeviceTemplates(deviceTemplates);
		}
	 } 

	 loadSelected() {
		if(this.ruleService.getDeviceTemplates().length>0 && this.iotService.viewRule==true){
			this.ruleService.getDeviceTemplates().forEach(deviceTemplate => {
				this.selection.select(deviceTemplate.id);
			});
		}
	}
} 

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { ActivatedRoute, Router } from '@angular/router';
import {
  faCheck,
  faCheckCircle,
  faExclamation,
  faExclamationTriangle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { EcosystemsService } from '../service/ecosystems.service';
import { EcosystemTemplatesService } from '../service/ecosystemTemplates.service';
import { IotService } from '../service/iot.service';
import { SideNavComponent } from '../side-nav/side-nav.component';

@Component({
  selector: 'app-create-ecosystem',
  templateUrl: './create-ecosystem.component.html',
  styleUrls: [
    '../base-list/baselist.component.scss',
    './create-ecosystem.component.scss',
  ],
})
export class CreateEcosystemComponent implements OnInit {
  faCheckIcon = faCheck;
  faCloseIcon = faTimes;
  faWarningIcon = faExclamationTriangle;
  faCriticalIcon = faExclamation;
  faSuccessIcon = faCheckCircle;

  supportLanguages = ['en'];
  ecosystem;
  tags = [];
  tagClass = 'tagInput';
  separatorKeysCodes: number[] = [ENTER, COMMA];
  selectedMemberColumns: string[] = ['Member Type', 'Kind'];
  ecosystemName;
  // parentEcosystemTemplateId;
  hasError: boolean = false;
  errorInName;
  errorMessage: string;
  ecosystemTemplateId;
  ecosystemTemplateName;
  ecosyStemCreated=false;

  constructor(
    public iotService: IotService,
    private translateService: TranslateService,
    private sideNavComponent: SideNavComponent,
    private router: Router,
    public ecosystemService: EcosystemsService,
    public ecosystemTemplateService: EcosystemTemplatesService,
    private route: ActivatedRoute
  ) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
    this.sideNavComponent.menuClear();
    this.sideNavComponent.menuChange('Dashboard', 'sub-type');
    this.sideNavComponent.menuChange('ecosystems.header', 'sub-type');
    this.sideNavComponent.menuChange('ecosystems.create.new', 'sub-type');
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      var ecosystemTemplateId = params['id'];
      this.ecosystemTemplateId = ecosystemTemplateId;
      if (
        this.ecosystemTemplateId != undefined &&
        this.ecosystemTemplateId != ''
      ) {
        this.iotService.createEcosystemFromTemplate = true;
      }
    });
    this.initEcosystem();
    this.iotService.createEcosystem = true;
    if (this.iotService.createEcosystemFromTemplate == true) {
      this.loadEcosystemTemplate(this.ecosystemTemplateId);
      this.ecosystem.parentGroupTemplateId = this.ecosystemTemplateId;
    }
  }

  initEcosystem() {
    const that = this;
    that.ecosystem = {
      creator: that.iotService.getCreator(),
      creatorAppId: that.iotService.getCreatorAppId(),
      realm: that.iotService.getRealm(),
      name: [
        {
          lang: 'en',
          text: '',
        },
      ],
      description: [
        {
          lang: 'en',
          text: '',
        },
      ],
      owner: {
        id: '',
        type: '',
        realm: '',
      },
      attributes: [],
      parentGroupTemplateId: '',
      lifecycleState: 'active',
      isImmutable: false,
      active: true,
    };
  }

  save() {
    const that = this;
    if (!that.errorValidations()) {
      if (!this.iotService.createEcosystemFromTemplate) {
        that.ecosystem.parentGroupTemplateId =
          that.ecosystemService.getParentEcosystemTemplateId();
      }
      that.ecosyStemCreated=true;
      that.iotService
        .getCuiObjResponse()
        .createEcosystemFromTemplate({
          qs: [['groupTemplateId', that.ecosystem.parentGroupTemplateId]],
        })
        .then(function (response) {
          console.log(response);
          response.name[0].text = that.ecosystemName;
          if (response.description != undefined) {
            response.description[0].text = that.ecosystem.description[0].text;
          } else {
            response['description'] = [
              {
                lang: 'en',
                text: that.ecosystem.description[0].text,
              },
            ];
          }

          that.iotService
            .getCuiObjResponse()
            .updateEcosystem({
              groupId: response.id,
              data: response,
            })
            .then(function (results) {
              that.tags.forEach((ecosystemTag) => {
                that.iotService.getCuiObjResponse().createEcosystemTag({
                  groupId: results.id,
                  tag: ecosystemTag,
                });
              });
              that.router.navigateByUrl('/ecosystems');
            })
            .fail(function (err) {
              that.ecosyStemCreated=false;
              if (
                err.responseJSON != undefined &&
                err.responseJSON.apiMessage != null &&
                err.responseJSON.apiMessage == 'The group name already exists'
              ) {
                that.ecosystemService.errorMessage =
                  'The ecosystem name already exists';
              } else {
                that.ecosystemService.errorMessage =
                  err.responseJSON.apiMessage;
              }
              that.iotService
                .getCuiObjResponse()
                .deleteEcosystem({
                  groupId: response.id,
                })
                .then(function (response) {
                  console.log('Deleted eocsystem');
                })
                .fail(function (err) {
                  that.ecosyStemCreated=false;
                  console.log(
                    'Something went wrong. Please check chrome console or check with dev team'
                  );
                });
            });
        })
        .fail(function (err) {
          that.ecosyStemCreated=false;
          if (err.responseJSON.apiStatusCode) {
            that.ecosystemService.errorMessage = err.responseJSON.apiMessage;
            console.log(
              'Error Occured: Status: ' +
                err.responseJSON.status +
                ' Msg: ' +
                err.responseJSON.apiStatusCode
            );
          } else {
            that.errorMessage =
              'Error occured while creating ecosystem from ecosystem template';
            console.log(
              'Something went wrong. Please check chrome console or check with dev team'
            );
          }
        });
    }
  }

  goBack() {
    this.ecosystemService.createResource = true;
    this.ecosystemService.createAttribute = false;
  }

  goBackToTemplate() {
    this.router.navigateByUrl('ecosystemTemplates/' + this.ecosystemTemplateId);
  }

  add(event: MatChipInputEvent): void {
    this.tagClass = 'tagInput';
    const input = event.input;
    const value = event.value;
    const index = this.tags.indexOf(value);
    if (index < 0) {
      if ((value || '').trim()) {
        if (this.tags != undefined && this.tags.includes(value)) {
          if (input) {
            input.value = '';
          }
        } else {
          this.tags.push(value);
          if (input) {
            input.value = '';
          }
        }
      }
    } else {
      this.tagClass = 'tagInput-duplicate';
    }
  }

  remove(tag: string): void {
    const that = this;
    that.tags.forEach((value, index) => {
      if (value == tag) that.tags.splice(index, 1);
    });
  }

  ecosystemNameInput(event: any) {
    const that = this;
    that.ecosystemName = event.target.value;
    if (that.ecosystemName.length > 0) {
      that.errorInName = '';
    } else {
      that.ecosystemName = '';
    }
  }

  loadEcosystemTemplate(ecosystemTemplateId: any) {
    const that = this;
    that.iotService
      .getCuiObjResponse()
      .getEcosystemTemplateById({
        groupTemplateId: ecosystemTemplateId,
      })
      .then(function (response) {
        that.ecosystemTemplateName = response.name[0].text;
        that.ecosystemName = 'Instance of ' + that.ecosystemTemplateName;
        that.ecosystem.name[0].text = that.ecosystemName;
      })
      .fail(function (err) {
        if (err.responseJSON && err.responseJSON.apiStatusCode) {
          console.log(
            'Error Occured: Status: ' +
              err.responseJSON.status +
              ' Msg: ' +
              err.responseJSON.apiStatusCode
          );
        } else {
          console.log(
            'Something went wrong. Please check chrome console or check with dev team'
          );
        }
      });
  }

  errorValidations() {
    const that = this;
    that.hasError = false;
    if (that.ecosystemName == undefined || that.ecosystemName == '') {
      that.errorInName = 'Name is mandatory';
      that.hasError = true;
    }
    return that.hasError;
  }

  resetErrorFields() {
    this.errorInName = '';
  }

  ngOnDestroy() {
    const that = this;
    that.tags = [];
    that.tagClass = 'tagInput';
    that.ecosystemName = '';
    that.ecosystemService.selectedEcosystemTemplate = '';
    that.ecosystemService.parentEcosystemTemplateId = '';
    that.ecosystemService.selectedAttributes = [];
    that.ecosystemService.selectedEvents = [];
    that.ecosystemService.selectedMembers = [];
    that.ecosystemService.resetErrorMessage();
    that.ecosystemService.resetSuccessMessage();
    that.ecosystemService.resetWarningMessage();
    that.ecosystemTemplateService.selection.clear();
    that.resetErrorFields();
    that.iotService.createEcosystem = false;
    that.iotService.createEcosystemFromTemplate = false;
    that.ecosystem = {
      creator: that.iotService.getCreator(),
      creatorAppId: that.iotService.getCreatorAppId(),
      realm: that.iotService.getRealm(),
      name: [
        {
          lang: 'en',
          text: '',
        },
      ],
      description: [
        {
          lang: 'en',
          text: '',
        },
      ],
      owner: {
        id: '',
        type: '',
        realm: '',
      },
      attributes: [],
      parentGroupTemplateId: '',
      lifecycleState: 'active',
      isImmutable: false,
      active: true,
    };
  }
}

<mat-sidenav-container class="key-bundle-detail" autosize>

    <mat-sidenav class="midNav-container" #sidenav mode="side" disableClose="true" opened="true">
        <p *ngIf="successMessage">{{successMessage}}</p>
        <!-- <div class="alert warning" *ngIf="securityService.warningMessage">
            <h5>
                <fa-icon [icon]="faWarningIcon"></fa-icon>
                <span>{{securityService.warningMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)="securityService.resetWarningMessages()" [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div> -->
        <div class="alert critical" *ngIf="securityService.warningMessage">
            <h5 style="font-size: 12px;">
                <fa-icon [icon]="faCriticalIcon"></fa-icon>
                <span>{{securityService.warningMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=securityService.resetWarningMessages() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>

        </div>
        <div class="form-data" *ngIf="keyBundle">
            <div style="margin-left: -20px;">
                <app-copy-resource-id></app-copy-resource-id>
            </div>
            <!-- <br> -->
            <label>{{ 'name' | translate}}</label>
            <p>{{keyBundle.name}}</p>
            <!-- <br> -->
            <label>{{ 'activated' | translate}}</label>
            <!-- <p>{{keyBundle.active}}</p> -->
            <div *ngIf="keyBundle.active">
                <button class="toggle-on toggle-switch" title="Disable {{keyBundle.name}}" (click)="disableKeyBundle(keyBundle.id)">
                    <fa-icon [icon]="faCheckIcon" class="fa-2x"></fa-icon>
                    <div class="toggle-on-btn"></div>
                </button>
            </div>
            <div *ngIf="!keyBundle.active">
                <button class="toggle-off toggle-switch" title="Enable {{keyBundle.name}}" (click)="enableKeyBundle(keyBundle.id)">
                    <div class="toggle-off-btn"></div>
                </button>
            </div>
        </div>

        <aside *ngIf="keyBundle">
            <hr>
            <p style="font-size: 14px; font-weight: 600;"> {{ 'trusted Certificate (end-entity)' | translate}}</p>

            <ng-container>
                <dl>
                    <dt>{{ 'issuer CA' | translate}}</dt>
                    <dd><a title="View Trusted Certificate Details" (click)=viewTrustedCertificates(keyBundle.trustedCertificate.id)>{{keyBundle.trustedCertificate.issuerHash}}
                        </a></dd>

                    <dt>{{ 'serial' | translate}}</dt>
                    <dd>{{keyBundle.trustedCertificate.serial}}</dd>

                    <dt>{{ 'status' | translate}}</dt>
                    <dd>{{keyBundle.trustedCertificate.isRevoked}}</dd>
                </dl>

            </ng-container>
        </aside>



        <aside *ngIf="securityService.trustedAuthority">
            <hr>
            <p style="font-size: 14px; font-weight: 600;"> {{ 'trusted Authorities (root/intermediate CA’s)' | translate}}</p>
            <ng-container>
                <dl>
                    <dt>{{ 'subject' | translate}}</dt>
                    <dd><a title="View Trusted Authorities Details" (click)=viewTrustedAuthorities(securityService.trustedAuthority.id)>{{securityService.trustedAuthority.subject}}
                        </a></dd>

                    <dt>{{ 'hash' | translate}}</dt>
                    <dd>{{securityService.trustedAuthority.subjectHash}}</dd>

                    <dt>{{ 'expiration date' | translate}}</dt>
                    <dd>{{securityService.trustedAuthority.expiration| date:'medium'}}</dd>

                    <dt>{{ 'Revoked' | translate}} </dt>
                    <dd>{{securityService.trustedAuthority.isRevoked}}</dd>

                </dl>


                <nav class="trustedAuthority">
                    <a class="pull-left" (click)="prevAuthority();" ng-disabled="1 == 2">
                        <fa-icon [icon]="faAngleLeftIcon"></fa-icon>
                    </a>
                    <span>Trusted Authority {{securityService.currentAuthority+1}} of {{authorities.length}}</span>
                    <a class="pull-right" (click)="nextAuthoritypage();" ng-disabled="1 == 2">
                        <fa-icon [icon]="faAngleRightIcon"></fa-icon>
                    </a>
                </nav>

            </ng-container>

        </aside>
        <br>
        <button class="btn btn-primary" style="margin-left: 10px;" (click)="updateKeyBundle(keyBundle.id)">{{ 'update' | translate}} </button>
        <br>
        <br>
    </mat-sidenav>
    <mat-sidenav-content class="example-sidenav-content" *ngIf="keyBundle">


        <!-- <div *ngIf="showCertificates"> -->
        <app-trustedCertificates [pageSize]="5" [showControlBar]=false [displayedColumns]="['ADD','subject', 'issuerHash', 'serial', 'isRevoked']" [showSelectColoumn]=true [showEnableColumn]=true>

        </app-trustedCertificates>


        <app-trustedAuthorities [pageSize]="5" [showControlBar]=false [displayedColumns]="['ADD','subject', 'subjectHash', 'expiration','isRevoked']" [showSelectColoumn]=true [showEnableColumn]=true>

        </app-trustedAuthorities>

        <!-- </div> -->

  




    </mat-sidenav-content>



</mat-sidenav-container>

<ng-container *ngIf="!keyBundle">
    <div>
        <br/><br/>
    </div>
    <div class="d-flex justify-content-center pageLoading">
        <div class="spinner-border" role="status">
            <span class="sr-only" id="loading"></span>
        </div>
    </div>
</ng-container>
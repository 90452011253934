import { Injectable } from '@angular/core';
import { SelectionModel} from '@angular/cdk/collections';
import { IotService } from '../service/iot.service'; 
import { ListGatewaysService } from './ListGateways.service';
import { RulesService } from '../service/rules.service';
import { DigitalTwinsService } from '../service/digitalTwins.service';

@Injectable({ 
	  providedIn: 'root' 
})

export class GatewaysService extends ListGatewaysService {
	selection: SelectionModel<String> = new SelectionModel<String>(true, []);
	warningMessage: string;
	gateWayAgentId;
	successMessage: string;
	errorMessage:   string;
	showAdaptor :boolean = false;
	createNewAdaptor=false;
	createResource=true;
	createNewLogRequest=false;
	showLogRequest :boolean = false;
	showRemoteConfig :boolean = false;
  	showSeedConfiguration: boolean;
	selectedAdaptorId: any;
	showAdaptorDetail: boolean=false;
  	creation: any;
	createNewRulePolicy=false;
	showRulePolicy : boolean = false;


	 constructor(public iotService:IotService, public ruleService: RulesService , public digitalTwinsService:DigitalTwinsService) {
		 super(iotService);
		 this.loadSelected();
	 }

	 fnOnSelectionSupport(results){
		const that = this;
		if (that.iotService.createRule == true) {
			that.ruleService.setGateways(results);
			that.ruleService.gatewayDevices="";
			that.ruleService.showGatewayDevices=false;
			that.ruleService.selectedGateway=true;
			that.ruleService.loading=true;
			
			that.ruleService.selectGatewaysCount=0;
			if (that.ruleService.showGateway)
			{
			that.ruleService.setDevices([]);
			that.digitalTwinsService.selection.clear();
			}
			if(that.ruleService.reload){ 
				that.ruleService.reload=false;
			  }else{
				that.ruleService.reload=true;
			}
		}
		if (that.iotService.viewRule == true){				
			that.ruleService.loading = false;
		}
	 } 

	 fnRemoveSelectionSupport(results){ 
		const that = this;
		if (that.iotService.createRule == true) {
			that.ruleService.setGateways(undefined);
			that.ruleService.selectedGateway=false;
			that.ruleService.showGatewayDevices=false;
			that.ruleService.loading=true;
			that.ruleService.selectGatewaysCount=0;
			if (that.ruleService.showGateway)
			{
			that.ruleService.setDevices([]);
			that.digitalTwinsService.selection.clear();
			}
		}
	 } 
	 resetWarningMessage() {
		this.warningMessage = '';
	}

	setGateWayAgentId(gateWayAgentId) {
		const that = this;
		this.gateWayAgentId = gateWayAgentId;
		// this.streamName = streamName;
	}

	getGateWayAgentId() {
		return this.gateWayAgentId;
	}

	resetErrorMessage() {
		this.errorMessage = '';
	}
	resetSuccessMessage() {
		this.successMessage = '';
	}

	loadSelected() {
		const that = this;
		if(that.ruleService.getGateways()!=undefined && that.iotService.viewRule==true){
			that.selection.select(that.ruleService.gateways.id);
		}
    }
} 

<main class="gettingStarted">
    <header>
        <h1><span>Welcome to the</span>OpenText IoT Platform</h1>
        <p>OpenText IoT Platform is a managed cloud platform that lets connected devices such as trains, planes, vehicles and factory equipment securely leverage device connectivity and implement a predictive device management strategy.</p>
        <button class="btn1" [routerLink]="['/dashboard']" (click)="onClickGettingStarted(true)" mat-raised-button color="primary">View dashboard</button>
    </header>

    <section class="container">
        
        <mat-grid-list rowHeight="3:3" [cols]="breakpoint" (window:resize)="onResize($event)"  [gutterSize]="'50px'">

            <article>
                <mat-grid-tile>
                    <div>
                        <h2>What is a Device?</h2>
                        <p>A device is a digital replica of people, systems or things, used to model the asset as it is operating in the physical world. Device objects & associated attributes capture real time streaming data to represent the state of the object in the physical world.</p>  
                        <button class="blue" [routerLink]="['/devices']" (click)="onClickGettingStarted(true)" mat-raised-button color="primary">Learn More</button>
                    </div>
                </mat-grid-tile>
            </article>
    
            <article>
                <mat-grid-tile>
                    <div>
                        <h2>What’s in a template?</h2>
                        <p>Modeling the physical world with a Device requires defining the attributes of the physical entity to capture data from the device. What if you are modeling a large cohort of devices? Templates allow you to define the generic twin & all of its associated attributes. </p>
                        <button class="lightblue" [routerLink]="['/templates']" (click)="onClickGettingStarted(true)" mat-raised-button color="primary">Learn More</button>
                    </div>
                </mat-grid-tile>
            </article>
            
            <article>
                <mat-grid-tile>
                    <div>
                        <h2>Ecosystems Explained</h2>
                        <p>Ecosystems are an integral part of Organizations. They are made up of all sorts of Things (devices, sensors, lights, etc.), People (admins, auditors, data scientists, etc.) and Apps, software that extends the functionality of connected entities.</p>
                        <button class="blue" mat-raised-button color="primary" onclick="window.location.href='https://b0wms2ojuok4bi2s1zhfjksf-wpengine.netdna-ssl.com/wp-content/uploads/Solution-Overview-Ecosystem-Integration-for-IoT.pdf'">Learn More</button>
                    </div>
                </mat-grid-tile>
            </article>

            <article>
                <mat-grid-tile>
                    <div>
                        <h2>Device Security</h2>
                        <p>OpenText&#8482; Secure IoT Device Management enables zero-trust, fine-grained control of IoT devices and data.  Protect and govern identity, data, commands and relationships across people, systems and devices. Manage the dynamic permissions and relationships within digital ecosystems.</p>
                        <button class="teal" mat-raised-button color="primary" onclick="window.location.href='https://businessnetwork.opentext.com/secure-iot-devices/'">Learn More</button>
                    </div>
                </mat-grid-tile>
            </article>
            
            <article>
                <mat-grid-tile>
                    <div>
                        <h2>Making it All Work</h2>
                        <p>IoT messaging brokers are critical to enabling IoT data stream integration. Connecting machines to systems and systems to people requires a methodical, purposeful approach to data management.</p>
                        <button class="blue" mat-raised-button color="primary" onclick="window.location.href='https://businessnetwork.opentext.com/iot-messaging-broker/'">Learn More</button>
                    </div>
                </mat-grid-tile>
            </article>

            <article>
                <mat-grid-tile>
                    <div>
                        <h2>Putting it All Together</h2>
                        <p>Aggregating and integrating IoT data delivers innovative service offerings and enables new business models that expand the ROI of IoT deployments. Create applications and integrations leveraging interactions between people, data, systems and things. Transform data into timely, relevant insight and actions.</p>
                        <button class="grey" mat-raised-button color="primary" onclick="window.location.href='https://businessnetwork.opentext.com/iot-data-integration/'">Learn More</button>
                    </div>
                </mat-grid-tile>
            </article>

        </mat-grid-list>
    </section>
</main>
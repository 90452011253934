import { hasLifecycleHook } from '@angular/compiler/src/lifecycle_reflector';
import { Component, OnInit,Output,EventEmitter } from '@angular/core';
import { IotService } from '../../service/iot.service';
import { DeviceService } from '../../service/device.service';
import { EventGroupsService } from '../../service/eventGroups.service';
import { faAngleDoubleLeft, faAngleDoubleRight,faToggleOff,faToggleOn,faCheck} from '@fortawesome/free-solid-svg-icons';
import {SelectionModel} from '@angular/cdk/collections';
import { element } from 'protractor';
import { TranslateService } from '@ngx-translate/core';
import {animate, state, style, transition, trigger} from '@angular/animations';


@Component({
  selector: 'app-device-template-list',
  templateUrl: './device-template-list.component.html',
  styleUrls: ['./device-template-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})

export class DeviceTemplateListComponent implements OnInit {

  dataSource: any;
  displayedColumns: string[] = ['Name', 'Description', 'Enabled'];
  length = 1000;
  faAngleDoubleLeftIcon = faAngleDoubleLeft;
  faAngleDoubleRightIcon = faAngleDoubleRight;
  faToggleOffIcon = faToggleOff;
  faToggleOnIcon = faToggleOn;
  faCheckIcon = faCheck;
  templateSelected:string;

 

  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;
  pageSize = 5;
  currentPage =1;
  searchText;
  templateName;
  deviceTemplates = [];
  associations = [];
  deviceTemplate = [];
  showValue = false;
  private role: string;
  showSelect = true;
  HighlightRow : Number;
  display = "Select"
  savedTemplate = []
  found = false;
  arrs = [9, 2, 5];
  toSelect = 'select'
  previousId ='';
  displaySelected = '';
  isPlaying=[];
  prevElement;
  supportLanguages = ['en'];
  selection: SelectionModel<Element> = new SelectionModel<Element>(true, []);
 
  selection1: SelectionModel<Element> = new SelectionModel<Element>(true, []);
 
  selectedDeviceTemplateEvents=[];
  selectedDeviceTemplateCommands=[];

  templateCount:number=0;
  showPagination:boolean=false;
  previousDeviceElement;

  constructor(private iotService:IotService,private deviceService:DeviceService,private translateService: TranslateService,
    private eventGroupService:EventGroupsService) { 
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
}

  ngOnInit(): void {
    this.deviceService.setSelectedTemplate(undefined);
    this.getDeviceTemplates();
    
    this.selection.isSelected = this.isChecked.bind(this);
    this.selection1.isSelected = this.isChecked1.bind(this);

    // if(this.deviceService.createDeviceFromNewTemplate){
    //   this.OnSelection(this.deviceService.newTemplate.id);
    //   this.playRecord(this.deviceService.newTemplate,true);
    // }
    this.countTemplates('');
  }
  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }

  
    isChecked(row: any): boolean {
      const found = this.selection.selected.find(el => el.id === row.id);
      if (found) {
        return true;
      }
      return false;
   }

   isChecked1(row: any): boolean {
    const found = this.selection1.selected.find(el => el.id === row.id);
    if (found) {
      return true;
    }
    return false;
 }

  onTemplateSearch(event: any){
      this.templateSelected = event.target.value;
      let val = '*'+event.target.value+'*';
      const that = this;
      that.countTemplates(val);
      that.currentPage=1;
      that.iotService.getCuiObjResponse().getDeviceTemplateWithoutChildren({
        qs:[['pageSize',5],['page',that.currentPage],['name',val]]
      }).then(function(response) {
        that.deviceTemplates = response;
        that.dataSource = that.deviceTemplates;
      }).fail(function (err){
        if(err.responseJSON.apiStatusCode){
          that.eventGroupService.errorMessage=err.responseJSON.apiMessage;
          console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
        } else{
          console.log("Something went wrong. Please check chrome console or check with dev team");
        } 
      });
  }

  getDeviceTemplates(){
    const that = this;
    this.iotService.getCuiObjResponse().getDeviceTemplates({
      qs:[['pageSize',5],['page',this.currentPage]]
    }).then(function(response) {
      that.deviceTemplates = response;
      that.dataSource = that.deviceTemplates;
     
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.eventGroupService.errorMessage=err.responseJSON.apiMessage;
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  enableDisableRule(button) {
    if(this.display=='Select'){
      this.display='Deselect'
    }else{
      this.display='Select'
    }
    
}

  playRecord(element,flag) {
    if(this.selection.isSelected(element)){
      this.selection.clear();
    }
    else{
      this.selection.toggle(element);
    }
    const that = this;
    element.isPlaying = flag;
    if(that.prevElement != undefined && that.prevElement.id != element.id) {
      that.prevElement.isPlaying = false;
      this.toSelect = 'select';

    }
    that.prevElement = element;
  }

  removeSelection (deviceTemplateId: any,flag:boolean) {
    this.deviceService.resetErrorMessage();
    var index = null;
    this.toSelect = 'select';
    this.prevElement.isPlaying = flag;
    // this.selection.toggle(this.deviceService.getSelectedTemplate());
    this.deviceService.setSelectedTemplate(undefined);
  };


  OnSelection(deviceTemplateId: any){
    const that = this;
    this.iotService.getCuiObjResponse().getDeviceTemplate({
      deviceTemplateId: deviceTemplateId
    }).then(function(response) {
      that.savedTemplate = response;
      that.deviceTemplate = response;
     
      that.deviceService.templateName = response.name[0].text;
      that.deviceService.templateSelect = true;
      that.deviceService.setSelectedTemplate(response);
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.eventGroupService.errorMessage=err.responseJSON.apiMessage;
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  OnEventSelection(event, deviceTemplate, eventTemaplate){
    const that = this;
      if(event === true){  
        that.selection.select(eventTemaplate);
        that.eventGroupService.deviceTemplateEventSelect = true;
        that.selectedDeviceTemplateEvents.push(eventTemaplate);
        that.addDeviceTemplateEvents(deviceTemplate, eventTemaplate)
      }else{
        that.selection.clear();
        that.removeDeviceTemplateEvents(deviceTemplate, eventTemaplate);
        
        that.selectedDeviceTemplateEvents.forEach((value,index)=>{
          if(value.id==eventTemaplate.id) {
            that.selectedDeviceTemplateEvents.splice(index,1);
          }
          
        });
        that.selectedDeviceTemplateEvents.forEach((value,index)=>{
          that.selection.select(value);
          });
        }
    if(that.eventGroupService.getSelectedDeviceTemplateEventTemplateData()!=undefined && that.eventGroupService.getSelectedDeviceTemplateEventTemplateData().length<1){
      that.eventGroupService.deviceTemplateEventSelect=false;
    }
  }

  OnCommandSelection(event, deviceTemplate, commandTemplate){
   const that = this;
      if(event === true){
        that.selection1.select(commandTemplate);
        that.eventGroupService.deviceTemplateCommandSelect = true;
        that.selectedDeviceTemplateCommands.push(commandTemplate);
        that.addDeviceTemplateCommands(deviceTemplate, commandTemplate);
      }else{
        that.selection1.clear();
        that.removeDeviceTemplateCommands(deviceTemplate, commandTemplate);
        that.selectedDeviceTemplateCommands.forEach((value,index)=>{
          if(value.id==commandTemplate.id) {
            that.selectedDeviceTemplateCommands.splice(index,1);
          }
        });
        that.selectedDeviceTemplateCommands.forEach((value,index)=>{
          that.selection1.select(value);
          });
        }
       
        if(that.eventGroupService.getSelectedDeviceTemplateCommandTemplateData()!=undefined && that.eventGroupService.getSelectedDeviceTemplateCommandTemplateData().length<1){
          that.eventGroupService.deviceTemplateCommandSelect=false;
        }
  }

  addDeviceTemplateEvents(deviceTemplate, eventTemplate) {
    const that = this;
    let selectedDeviceEventTemplates={
      'deviceTemplateId': deviceTemplate.id,
      'deviceTemplateName': deviceTemplate.name[0].text,
      'eventId': eventTemplate.id,
      'eventName': eventTemplate.name
    }

    let selectedDeviceEventTemplates1={
      'deviceTemplateId': deviceTemplate.id,
      'eventTemplateId': eventTemplate.id
    }
    that.eventGroupService.setSelectedDeviceTemplateEventTemplateData(selectedDeviceEventTemplates);
    that.eventGroupService.setEventGroupSourceDeviceTemplates(selectedDeviceEventTemplates1);
  }
    removeDeviceTemplateEvents(deviceTemplate, eventTemplate) {
      const that=this
      let  previousEventTemplateData= [];
     previousEventTemplateData= that.eventGroupService.getSelectedDeviceTemplateEventTemplateData();

     previousEventTemplateData.forEach((value,index)=>{
      if(value.eventId==eventTemplate.id && value.deviceTemplateId==deviceTemplate.id){
        previousEventTemplateData.splice(index,1);
      }
     });
    
    that.eventGroupService.sourceDeviceTemplates.forEach((value,index)=>{
    if(value.eventTemplateId==eventTemplate.id){
      that.eventGroupService.sourceDeviceTemplates.splice(index,1);
    }
    });

    that.eventGroupService.selectedDeviceTemplateEventTemplateData.forEach((value,index)=>{
      if(value.eventId==eventTemplate.id){
        that.eventGroupService.selectedDeviceTemplateEventTemplateData.splice(index,1);
      }
      });
  }

    addDeviceTemplateCommands(deviceTemplate, command) {
      const that = this;
      let selectedDeviceCommandTemplates={
        'deviceTemplateId': deviceTemplate.id,
        'deviceTemplateName': deviceTemplate.name[0].text,
        'commandId': command.id,
        'commandName': command.name
      }

      let selectedDeviceCommandTemplates1={
        'deviceTemplateId': deviceTemplate.id,
        'commandTemplateId': command.id
      }
      that.eventGroupService.setSelectedDeviceTemplateCommandTemplateData(selectedDeviceCommandTemplates);
      that.eventGroupService.setEventGroupSourceCommandDeviceTemplates(selectedDeviceCommandTemplates1);
    }
  
      removeDeviceTemplateCommands(deviceTemplate, commandTemplate) {
        const that=this
        let  previousCommandTemplateData= []
        previousCommandTemplateData= that.eventGroupService.getSelectedDeviceTemplateCommandTemplateData();
   
        previousCommandTemplateData.forEach((value,index)=>{
         if(value.commandId==commandTemplate.id && value.deviceTemplateId==deviceTemplate.id){
          previousCommandTemplateData.splice(index,1);
         }
      });

      that.eventGroupService.sourceCommandDeviceTemplates.forEach((value,index)=>{
      if(value.commandTemplateId==commandTemplate.id){
        that.eventGroupService.sourceCommandDeviceTemplates.splice(index,1);
      }
      });

      that.eventGroupService.selectedDeviceTemplateCommandTemplateData.forEach((value,index)=>{
        if(value.commandId==commandTemplate.id){
          that.eventGroupService.selectedDeviceTemplateCommandTemplateData.splice(index,1);
        }
        });
      }

  enableTemplate(deviceTemplateId : any){
    const that = this;
    this.iotService.getCuiObjResponse().activateDeviceTemplate({
      deviceTemplateId: deviceTemplateId
    }).then(function(response) {
      that.getDeviceTemplates();
      
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.eventGroupService.errorMessage=err.responseJSON.apiMessage;
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  disableTemplate(deviceTemplate:any,deviceTemplateId : any){
    const that = this;
    this.iotService.getCuiObjResponse().deactivateDeviceTemplate({
      deviceTemplateId: deviceTemplateId
    }).then(function(response) {
      if(that.selection.isSelected(deviceTemplate)){
        that.selection.clear();
      }
      that.deviceService.setSelectedTemplate(undefined);
      that.getDeviceTemplates();
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.eventGroupService.errorMessage=err.responseJSON.apiMessage;
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  enableEventTemplate(eventTemplateId : any){
    const that = this;
    this.iotService.getCuiObjResponse().activateEventTemplate({
      eventTemplateId: eventTemplateId
    }).then(function(response) {
     that.getDeviceTemplates();
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.eventGroupService.errorMessage=err.responseJSON.apiMessage;
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  disableEventTemplate(event: any, eventTemplateId : any){
    const that = this;
    this.iotService.getCuiObjResponse().deactivateEventTemplate({
      eventTemplateId: eventTemplateId
    }).then(function(response) {
      if(that.selection.isSelected(event)){
        that.selection.clear();
      }
      that.eventGroupService.setSelectedEventTemplate(undefined);
      that.getDeviceTemplates();
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.eventGroupService.errorMessage=err.responseJSON.apiMessage;
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  enableCommandTemplate(commandTemplateId : any){
    const that = this;
    this.iotService.getCuiObjResponse().activateCommandTemplate({
      commandTemplateId: commandTemplateId
    }).then(function(response) {
      that.getDeviceTemplates();
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.eventGroupService.errorMessage=err.responseJSON.apiMessage;
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  disableCommandTemplate(command: any, commandTemplateId : any){
    const that = this;
    this.iotService.getCuiObjResponse().deactivateCommandTemplate({
      commandTemplateId: commandTemplateId
    }).then(function(response) {
      if(that.selection.isSelected(command)){
        that.selection.clear();
      }
      that.eventGroupService.setSelectedCommandTemplate(undefined);
      that.getDeviceTemplates();
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.eventGroupService.errorMessage=err.responseJSON.apiMessage;
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  getSelectedTemplateForCurrentPage(templateSelected){
    this.templateSelected = templateSelected;
    let startValue = '*'+templateSelected;
    let endValue = templateSelected + '*';
    const that = this;
    this.iotService.getCuiObjResponse().getDeviceTemplateWithoutChildren({
      qs:[['pageSize',5],['page',this.currentPage],['name',startValue],['name',endValue]]
    }).then(function(response) {
      that.deviceTemplates = response;
      that.dataSource = that.deviceTemplates;
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.eventGroupService.errorMessage=err.responseJSON.apiMessage;
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      } 
    });
  }

  createNewTemplate(){
    this.deviceService.createNewTemplate=true;
  }

  countTemplates(name) {
    const that = this;
    that.showPagination=false;
    if(name === "**") {
      name = '';
    }
    if(name === '') {
      that.iotService.getCuiObjResponse().countDeviceTemplates({
        qs:[['name',name]]
      }).then(function (response) {
        that.templateCount = response;
        that.showPagination=true;
      }).fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          that.eventGroupService.errorMessage=err.responseJSON.apiMessage;
          console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
        } else {
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    } else {
      that.iotService.getCuiObjResponse().countDeviceTemplates({
        qs:[['name',name]]
      }).then(function (response) {
        that.templateCount = response;
        that.showPagination=true;
      }).fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          that.eventGroupService.errorMessage=err.responseJSON.apiMessage;
          console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
        } else {
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    }
  }

  onPageChange(page) {
    const that = this;
    that.currentPage = page;
    if(this.templateSelected) {
      this.getSelectedTemplateForCurrentPage(this.templateSelected);
    } else {
      this.getDeviceTemplates();
    }
  }

  deviceTemplateMessage(deviceTemplate,expandedElement) {
    const that = this;
    if(that.previousDeviceElement != undefined && that.previousDeviceElement.id != deviceTemplate.id) {
      that.previousDeviceElement.messageClass = 'bg-message';
    }

    if(expandedElement === null) {
      deviceTemplate.messageClass = 'bg-message';
    } else {
      deviceTemplate.messageClass = 'bg-message-selected';
    }
    that.previousDeviceElement = deviceTemplate;
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImportExportService {

  constructor() { }
  showExport = true;
  showImport = false;

  showImportExportSolution=true;
  showOrchIMpExp=false;
  showRuleImpExp=false;

  showDownloadLogs = false;

  orchestrationImport=true;
  orchestrationCompare=false;
  orchestrationExport=false;

  ruleImport=true;
  ruleExport=false;

  showEventGroup=true;
  eventGroupImport=true;
  eventGroupExport=false;

  successMessage='';
  warningMessage='';

  resetWarningMessage(){
    this.warningMessage='';
  }
  resetSuccessMessage(){
    this.successMessage='';
  }
  errorMessage: string;
  resetErrorMessage() {
    this.errorMessage = '';
  }
}

<div class="taskForm">
    <div class="col">
        <label for="type">Task Type</label>
        <select [(ngModel)] ="selectedTasktype" id="tasktype" (ngModelChange)="initTask($event)" [disabled]="mode == 1">
          <!-- <option value=" ">Select</option> -->
          <option *ngFor="let task of taskListUI;" [ngValue]="task">
            {{task.title}}
          </option>
        </select>

        <label for="taskName">{{ 'orchestrationConfigs.task.name' | translate}}</label>
        <input [(ngModel)]="task.name" id="taskName" placeholder="{{'required' | translate}}" name="taskName" type="text">

        <label for="serviceParameters">{{ 'orchestrationConfigs.task.serviceParameters' | translate}}</label>
        <textarea [(ngModel)]="task.serviceParms" placeholder="{{'optional' | translate}}" type="text"></textarea>

        <mat-checkbox [(ngModel)]="task.continueOnFailure">{{ 'orchestrationConfigs.task.continue.on.failure' | translate}}</mat-checkbox>
        <br>

        <app-ace *ngIf="task.type == 'javascript'" from="orchestrationConfig" editorMode={{task.type}} editorValue={{task.className}} #editor></app-ace>
        <app-ace *ngIf="task.type == 'XSLT'" from="orchestrationConfig" editorMode={{task.type}} editorValue={{task.className}} #editor></app-ace>
        <app-ace *ngIf="task.type == 'XSD'" from="orchestrationConfig" editorMode={{task.type}} editorValue={{task.className}} #editor></app-ace>

        <div style="float: right; margin-top: 15px;">
          <button class="btn btn-secondary" (click)="back()" type='submit'>{{ 'go_back' | translate}}</button>&nbsp;
          <button class="btn btn-primary" (click)="save()" type='submit'>{{ 'orchestrationConfigs.task.continue' | translate}}</button>
        </div>
    </div>
    <div class="col" *ngIf="task.type == 'javascript' || task.type == 'XSLT'">
      <app-code-examples codeExampleType="orchestration"></app-code-examples>
    </div>


    <div style="height: 10vmax;"></div>
</div>

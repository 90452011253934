import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faAngleUp, faAngleDown, faTimes, faExclamationTriangle, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { faToggleOff, faToggleOn, faAngleLeft, faAngleRight, faCheck, faCopy, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { IotService } from '../service/iot.service';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER, I } from '@angular/cdk/keycodes';
import { RoutesService } from '../service/routes.service';
import { FormControl, FormGroup } from '@angular/forms';
import { DigitalTwinsService } from '../service/digitalTwins.service';
import { EventGroupsService } from '../service/eventGroups.service';
import { DocumentInfoService } from '../service/documentInfo.service';
import { RoutingSourceRelationshipsService } from '../service/routingSourceRelationships.service';
import { RouteDetailsService } from '../service/route-details.service';
import { throwMatDuplicatedDrawerError } from '@angular/material/sidenav';

// export interface RouteType {
//   value: number;
//   viewValue: string;
// }
@Component({
  selector: 'app-new-route',
  templateUrl: './new-route.component.html',
  styleUrls: ['./new-route.component.scss']
})


export class RouteComponent implements OnInit {
  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;
  faCloseIcon = faTimes;
  faWarningIcon = faExclamationTriangle;

  failed = faTimes;
  faToggleOffIcon = faToggleOff;
  faToggleOnIcon = faToggleOn;
  faAngleLeftIcon = faAngleLeft;
  faAngleRightIcon = faAngleRight;
  faCheckIcon = faCheck;
  faCopyIcon = faCopy;
  faAngleDoubleLeftIcon = faAngleDoubleLeft;
  faAngleDoubleRightIcon = faAngleDoubleRight;
  faCriticalIcon = faExclamation;
  form: FormGroup;
  // routeTypes: RouteType[] = [
  //   { value: 1, viewValue: 'EVENT' },
  //   { value: 2, viewValue: 'COMMAND' },
  //   { value: 3, viewValue: 'MESSAGE_INFO' },
  //   { value: 4, viewValue: 'TP_RELATIONSHIP' }
  // ];
  routetypess = [
    { value: 1, viewValue: 'EVENT' },
    { value: 2, viewValue: 'COMMAND' },
    { value: 3, viewValue: 'MESSAGE_INFO' },
    { value: 4, viewValue: 'TP_RELATIONSHIP' }
  ];
  // mySelect = [];
  // selectedValue: any;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tags = [];
  tagClass = 'tagInput';
  successMessage: string;
  warningMessage: string;
  isActive = true;
  routeinfo;
  hasError: boolean = false;
  streamError;
  docInfoError;
  deviceError;
  eventGroupError;
  routingSourceRelError;
  viewValue = 'EVENT';
  idd: any;
  routeCreated=false;

  supportLanguages = ['en'];
  authorityColumns = ["Name", "Type"];



  constructor(public iotService: IotService, private translateService: TranslateService,
    private sideNavComponent: SideNavComponent, private router: Router, public routeService: RoutesService,
    public routeDetailsService: RouteDetailsService,
    public digitalTwinsService: DigitalTwinsService,
    public eventGroupsService: EventGroupsService, public documentInfoService: DocumentInfoService,
    public routingSourceRelationshipsService: RoutingSourceRelationshipsService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
    this.sideNavComponent.menuClear();
    this.sideNavComponent.menuChange('Dashboard', 'sub-type');
    this.sideNavComponent.menuChange('routes.header', 'sub-type');
    this.sideNavComponent.menuChange('routes.create.new', 'sub-type');
    // this.form = new FormGroup({
    //   typeChange: this.typeChangeControl
    // });
  }
  // typeChangeControl = new FormControl(this.routeTypes[0].value);
  ngOnInit(): void {
    const that = this;
    this.initRouteInfo();
    this.iotService.createRoute = true;
    that.routeService.resetErrorMessage();
  }
  resetErrorMessage() {
    this.routeService.resetErrorMessage();
    this.streamError = "";
    this.docInfoError = "";
    this.routingSourceRelError = "";
    this.deviceError = "";
    this.eventGroupError = "";
  }
  resetdata() {
    //this.routeService.setStream(undefined);
    this.routeService.setEventGroup(undefined);
    this.routeService.setDevice(undefined);
    this.routeService.setDocumentInfo(undefined);
    this.routeService.setRoutingSourceRelationship(undefined);
    this.eventGroupsService.selection.clear();
    this.digitalTwinsService.selection.clear();
    this.documentInfoService.selection.clear();
    this.routingSourceRelationshipsService.selection.clear();

  }
  ngOnDestroy() {
    const that = this;
    that.iotService.createRoute = false;
    that.routeService.setStream(undefined);
    that.routeService.setEventGroup(undefined);
    that.routeService.setDevice(undefined);
    that.routeService.setDocumentInfo(undefined);
    that.routeService.setRoutingSourceRelationship(undefined);
    that.eventGroupsService.selection.clear();
    that.digitalTwinsService.selection.clear();
    that.documentInfoService.selection.clear();
    that.routingSourceRelationshipsService.selection.clear();

  }

  resetWarningMessage() {
    this.warningMessage = '';
  }



  initRouteInfo() {
    const that = this;
    that.routeinfo = {
      "creator": that.iotService.getCreator(),
      "creatorAppId": that.iotService.getCreatorAppId(),
      "realm": that.iotService.getRealm(),
      "name": [{
        "lang": "en_US",
        "text": ""
      }],
      "description": [{
        "lang": "en_US",
        "text": ""
      }],
      "routeSourceId": "",
      "routeType": "",
      "streamId": "",
      "routingTopic": ""
    };
  }

  save() {
    const that = this;
    that.routeCreated=true;
    if (!that.errorValidations()) {
      that.populateData();
    }else{
      that.routeCreated=false;
    }
  }

  create() {
    const that = this;
    that.iotService.getCuiObjResponse().createRoute({
      data: that.routeinfo
    }).then(function (response) {
      // that.routeService.setRoutingTopic(response.);
      //idd = response.streamId;
      console.log("Route Info created succesfully. ID :", response.id);
      that.router.navigateByUrl("/routes");
    })
      .fail(function (err) {
        that.routeCreated=false;
        if (err.responseJSON.apiStatusCode) {
          that.routeService.errorMessage = err.responseJSON.apiMessage;
          console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
        } else {
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
        if (err === null) {
          that.warningMessage = "Error occured while creating token";
          console.log("Error occured while creating document info");
        }
      });
  }

  getStreamValidation(streamvarId: any) {
    // this.routeinfo.streamId = this.routeService.getStream().id;
    const that = this;
    that.iotService.getCuiObjResponse().getStream({
      streamId: streamvarId
    }).then(function (response) {
      if (response.consumerTopic) {
        that.routeinfo.routingTopic = response.consumerTopic;
      }
      // that.routeService.setRoutingTopic(response.);
      console.log("Route Info created succesfully. ID :", response.id);
      that.create();
    })
      .fail(function (err) {
        that.routeCreated=false;
        if (err.responseJSON.apiStatusCode) {
          that.routeService.errorMessage = err.responseJSON.apiMessage;
          console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
        } else {
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
        if (err === null) {
          that.warningMessage = "Error occured while creating token";
          console.log("Error occured while creating document info");
        }
      });
  }

  populateData() {
    this.routeinfo.streamId = this.routeService.getStream().id;
    console.log("setting value for routingTopic " + this.routeinfo.routingTopic);
    if (this.viewValue == 'EVENT') {
      this.routeinfo.routeSourceId = this.routeService.getEventGroup().id;
      this.routeinfo.routeType = "EVENT";
    }
    if (this.viewValue == 'COMMAND') {
      this.routeinfo.routeSourceId = this.routeService.getDevice().id;
      this.routeinfo.routeType = "COMMAND";
    }
    if (this.viewValue == 'MESSAGE_INFO') {
      this.routeinfo.routeSourceId = this.routeService.getDocumentInfo().id;
      this.routeinfo.routeType = "MESSAGE_INFO";
      // this.routeinfo.routingTopic = this.routeService.getDevice().id;
    }
    if (this.viewValue == 'TP_RELATIONSHIP') {
      this.routeinfo.routeSourceId = this.routeService.getRoutingSourceRelationship().id;
      this.routeinfo.routeType = "TP_RELATIONSHIP";
    }
    this.getStreamValidation(this.routeinfo.streamId);


  }

  toggleActive() {
    this.isActive = !this.isActive;
  }
  // selectChange() {

  //   this.selectedValue = this.commonService.getDropDownText(this.mySelect, this.routeTypes);
  // }
  errorValidations() {
    const that = this;
    that.hasError = false;
    if (that.routeService.getStream() == undefined) {
      that.streamError = 'Please Select Streams';
      that.hasError = true;
    }
    if (that.viewValue == 'EVENT') {
      if (that.routeService.getEventGroup() == undefined) {
        that.eventGroupError = 'Please Select Event Groups';
        that.hasError = true;
      }
    } if (that.viewValue == 'COMMAND') {
      if (that.routeService.getDevice() == undefined) {
        that.deviceError = 'Please Select Devices';
        that.hasError = true;
      }
    }
    if (that.viewValue == 'MESSAGE_INFO') {
      if (that.routeService.getDocumentInfo() == undefined) {
        that.docInfoError = 'Please Select DocumentInfo';
        that.hasError = true;
      }
    }
    if (that.viewValue == 'TP_RELATIONSHIP') {
      if (that.routeService.getRoutingSourceRelationship() == undefined) {
        that.routingSourceRelError = 'Please Select Routing Source Relationships';
        that.hasError = true;
      }
    }
    return that.hasError;


  }
  onChange(event) {
    this.resetErrorMessage();
    this.resetdata();
    console.log(this.routeService.getDocumentInfo());
  }
}
import { Component, OnInit } from '@angular/core';
import { IotService } from '../service/iot.service';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { faAngleUp, faAngleDown, faTimes, faExclamationTriangle, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { faToggleOff, faToggleOn, faAngleLeft, faAngleRight, faCheckCircle, faCheck, faCopy, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { RoutesService } from '../service/routes.service';
import { ImportExportService } from '../service/import-export.service';


@Component({
  selector: 'app-create-import-export',
  templateUrl: './create-import-export.component.html',
  styleUrls: ['./create-import-export.component.scss']
})
export class CreateImportExportComponent implements OnInit {
  exportInfo;
  faCheckIcon = faCheck;
  faSuccessIcon = faCheckCircle;
  faCriticalIcon = faExclamation;
  faCloseIcon = faTimes;
  faWarningIcon = faExclamationTriangle;
  errorMsg;
  executeExportError;
  importExportCreated=false;


  routetypess = [
    { value: 1, viewValue: 'Message ID' },
    { value: 2, viewValue: 'Device Template ID' },
    { value: 3, viewValue: 'Application ID' }
  ];
  warningMessage: string;
  viewValue = 'Message ID';
  errorMsgFLag = false;
  successMsgFlag = false;
  successMsg = "";
  exportworking = false;
  importworking = false;
  importExportState = 1;

  supportLanguages = ['en'];
  authorityColumns = ["Name", "Type"];
  constructor(public iotService: IotService, private translateService: TranslateService,
    private sideNavComponent: SideNavComponent, private router: Router, public routeService: RoutesService, public importExportService: ImportExportService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
    this.sideNavComponent.menuClear();
    this.sideNavComponent.menuChange('Dashboard', 'sub-type');
    this.sideNavComponent.menuChange('importExport.header', 'sub-type');
    this.sideNavComponent.menuChange('importExport.solution', 'sub-type');
  }

  ngOnInit(): void {
    const that = this;
    this.initExportInfo();
    that.importExportService.errorMessage = "";
    that.importExportService.successMessage = "";
    this.importExportService.showExport = true;
    this.importExportService.showImport = false;
    this.importExportService.showDownloadLogs = false;


  }
  ngOnDestroy(): void {
    const that = this;
    that.importExportService.errorMessage = "";
    that.importExportService.successMessage = "";
    that.exportInfo.resourceId = "";
    that.exportInfo.jobName = "";
    that.exportInfo.batchMessageId = "";

  }
  initExportInfo() {
    const that = this;
    that.exportInfo = {
      "resourceId": "",
      "batchMessageId": "",
      "jobName": "",
      "exportType": "msgid",
      "resourceType": ""
    };
  }

  resetMsg() {
    const that = this;
    that.errorMsgFLag = false;
    that.successMsgFlag = false;
    that.exportInfo.resourceId = "";
    that.exportInfo.jobName = "";
    that.exportInfo.batchMessageId = "";
  }


  save(exportInfo) {
    const that = this;
    that.errorMsg = "";
    var errorMsg = null;

    that.successMsg = "";
    var successMsg = null;

    // if (that.exportInfo.resourceId == "") {
    //   that.errorMsgFLag = true;
    //   errorMsg = "Resource ID is required for Export.";
    //   console.log("errorMessage-check" + errorMsg);
    // }
    if (that.exportInfo.resourceId == "") {
      that.errorMsgFLag = true;
      errorMsg = "Resource ID is required for Export.";
    }


    if (errorMsg === null) {
      that.exportworking = true;
      var ExportData = exportInfo;
      ExportData.jobName = that.exportInfo.jobName;
      ExportData.resourceId = that.exportInfo.resourceId;
      ExportData.batchMessageId = that.exportInfo.batchMessageId;
      if (that.exportInfo.exportType == "deviceTempId") {
        ExportData.resourceType = "DEVICE_TEMPLATE";
      }
      if (that.exportInfo.exportType == "applicationId") {
        ExportData.resourceType = "APPLICATION";
      }
      //that.exportworking = true;
      // that.populateData();
      that.importExportCreated=true;
      that.iotService.getCuiObjResponse().executeRepExport({
        data: ExportData
      }).then(function (response) {
        // that.routeService.setRoutingTopic(response.);
        //idd = response.streamId;
        that.exportworking = false;
        console.log("Route Info created succesfully. ID :", response.id);
        //that.router.navigateByUrl("/routes");
      })
        .fail(function (response) {
          if (response.status == 200) {
            that.saveTextAsFile(response.responseText, that.exportInfo.resourceId + ".pkg")
            // console.log("yesss-p-response.responseText"+response.responseText);
            that.errorMsgFLag = false;
            that.successMsgFlag = true;
            that.importExportService.successMessage = "File downloaded successfully.";
            that.exportworking = false;
            //that.contained_progressbar.complete();
            //that.refresh();
          } else {
            that.importExportCreated=false;
            that.successMsgFlag = false;
            that.errorMsgFLag = true;
            that.importExportService.errorMessage = response.responseJSON.apiMessage;
            that.errorMsg = that.importExportService.errorMessage;
            that.exportworking = false;
            //that.contained_progressbar.complete();
            //that.refresh();
          }
        });
    } else {
      that.errorMsg = errorMsg;

    }
  }
  resetWarningMessage() {
    this.warningMessage = '';
  }
  // populateData() {
  //   if (this.viewValue == 'Message ID') {
  //     // this.exportInfo.routeSourceId = this.routeService.getEventGroup().id;
  //     this.exportInfo.resourceType = "MESSAGE";
  //     this.exportInfo.exportType = "messageId";
  //   }
  //   if (this.viewValue == 'Device Template ID') {
  //     // this.exportInfo.routeSourceId = this.routeService.getDevice().id;
  //     this.exportInfo.resourceType = "DEVICE_TEMPLATE";
  //     this.exportInfo.exportType = "deviceTempId";
  //   }
  //   if (this.viewValue == 'Application ID') {
  //     //this.exportInfo.routeSourceId = this.routeService.getDocumentInfo().id;
  //     this.exportInfo.resourceType = "APPLICATION";
  //     this.exportInfo.exportType = "applicationId";
  //     // this.routeinfo.routingTopic = this.routeService.getDevice().id;
  //   }

  // }
  onChange(event) {
    // this.resetErrorMessage();
    // this.resetdata();
    //this.exportInfo.exportType=event;
  }

  saveTextAsFile(data, filename) {

    if (!data) {
      console.error('Console.save: No data')
      return;
    }

    if (!filename) filename = 'console.json'

    var blob = new Blob([data], { type: 'text/plain' }),
      e = document.createEvent('MouseEvents'),
      a = document.createElement('a')
    // FOR IE:

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    }
    else {
      var e = document.createEvent('MouseEvents'),
        a = document.createElement('a');

      a.download = filename;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/plain', a.download, a.href].join(':');
      e.initEvent('click', true, false);
      a.dispatchEvent(e);
    }
  }

  onButtonToggle(event) {
    const that = this;
    console.log("onButtonToggle: " + event)
    if (event == "Import") {
      that.importExportState = 1;
      console.log("onButtonToggle: " + that.importExportState)
    }
    else if (event == "Export") {
      that.importExportState = 2;
      that.initExportInfo();
      console.log("onButtonToggle: " + that.importExportState)
    }
    else if (event == "Download Logs") {
      that.importExportState = 3;
      console.log("onButtonToggle: " + that.importExportState)
    }

  }

  // tabClick(tab) {
  //   console.log(tab.index);
  //   if (tab.index == 0) {
  //     console.log("export");
  //     this.importExportService.showExport = true;
  //     this.importExportService.showImport = false;
  //     this.importExportService.showDownloadLogs = false;
  //     // this.securityService.showKeyBundles=false;
  //   }
  //   else if (tab.index == 1) {
  //     console.log("import");
  //     this.importExportService.showImport = true;
  //     this.importExportService.showExport = false;
  //     this.importExportService.showDownloadLogs = false;
  //   } else {
  //     console.log("downloadLogs");
  //     this.importExportService.showDownloadLogs = true;
  //     this.importExportService.showImport = false;
  //     this.importExportService.showExport = false;
  //   }

  // }

}

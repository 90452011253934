<mat-sidenav-container class="new-token" autosize>

    <mat-sidenav class="midNav-container" #sidenav mode="side" disableClose="true" opened="true">

        <!-- <p>test {{warningMessage}} test</p> -->
        <div class="alert critical" *ngIf="errorMessage">
            <h5 style="font-size: 12px;">
                <fa-icon [icon]="faCriticalIcon"></fa-icon>
                <span>{{errorMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)="reset()" [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>

        </div>

        <div class="alert warning" *ngIf="warningMessage">
            <h5>
                <fa-icon [icon]="faWarningIcon"></fa-icon>
                <span>{{warningMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)="reset()" [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>


        <div class="alert success" *ngIf="successMessage">
            <h5>
                <fa-icon [icon]="faSuccessIcon"></fa-icon>
                <span>{{successMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=reset() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>
        <div style="margin-left: -10px;">
            <app-copy-resource-id></app-copy-resource-id>
        </div>
        <div class="data" *ngIf="token">
            <label for="name"> {{ 'name' | translate}}</label>
            <p id="name">{{token.name[0].text}}</p>

            <label for="description"> {{ 'description' | translate}}</label>
            <p *ngIf="token.description" id="description">{{token.description[0].text}}</p>
            <p *ngIf="!token.description" id="description">-</p>

            <div class="tags">
                <label for="device-tags">{{ 'tags' | translate}}</label>
                <mat-chip-list #tagList aria-label="tags">
                    <mat-chip class="ot-chip" *ngFor="let tag of token.tags" [selectable]="true" [removable]=true (removed)="remove(tag)">
                        {{tag}}
                        <button class="ot-chip__close" matChipRemove></button>
                    </mat-chip>
                    <input style="width: 50%;" [ngClass]="tagClass" placeholder="add a tag" #tagInput [matChipInputFor]="tagList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="add($event)">
                </mat-chip-list>
            </div>

            <label for="created"> {{ 'creation' | translate}}</label>
            <p id="created">{{token.creation|date:'MMM d, y, h:mm:ss a'}}</p>

            <label>{{ 'activated' | translate}}</label>
            <div *ngIf="token.active">
                <button class="toggle-on toggle-switch" title="Disable {{token.name[0].text}}" (click)="disableToken(token.id)">
                    <fa-icon [icon]="faCheckIcon" class="fa-2x"></fa-icon>
                    <div class="toggle-on-btn"></div>
                </button>
            </div>
            <div *ngIf="!token.active">
                <button class="toggle-off toggle-switch" title="Enable {{token.name[0].text}}" (click)="enableToken(token.id)">
                    <div class="toggle-off-btn"></div>
                </button>
            </div>


        </div>



    </mat-sidenav>
    <mat-sidenav-content class="example-sidenav-content">
        <div class="token-data" *ngIf="token">

            <label for="clientId"> {{ 'clientId' | translate}}</label>
            <p>{{token.clientId}}</p>
            <!-- <input placeholder="required" [(ngModel)]="token.clientId" id="clientId" type="text" aria-label="clientId"> -->

            <label for="clientSecret"> {{ 'clientSecret' | translate}}</label>
            <input placeholder="required" *ngIf="clientSecretFlag" [(ngModel)]="token.clientSecret" id="clientSecret" type="text" aria-label="Client Secret">
            <input *ngIf="!clientSecretFlag" [(ngModel)]="token.clientSecret" id="clientSecret" type="password" aria-label="Client Secret">
            <!-- <mat-checkbox style="margin-left: 10px;" [(ngModel)]="clientSecretFlag">Edit</mat-checkbox> -->
            <fa-icon style="padding-left: 5px;" *ngIf="!clientSecretFlag" (click)="clientSecretFlag=!clientSecretFlag" [icon]="hidePassword"></fa-icon>
            <fa-icon style="padding-left: 5px;" *ngIf="clientSecretFlag" (click)="clientSecretFlag=!clientSecretFlag" [icon]="showPassword"></fa-icon>


            <label for="grantType"> {{ 'grantType' | translate}}</label>
            <p id="grantType">{{token.grantType}}</p>



            <label for="accessToken"> {{ 'accessToken' | translate}}</label>
            <input placeholder="required" *ngIf="accessTokenFlag" [(ngModel)]="token.accessToken" id="accessToken" type="text" aria-label="AccessToken">
            <input *ngIf="!accessTokenFlag" [(ngModel)]="token.accessToken" id="accessToken" type="password" aria-label="AccessToken">
            <!-- <mat-checkbox style="margin-left: 10px;" [(ngModel)]="accessTokenFlag">Edit</mat-checkbox> -->
            <fa-icon style="padding-left: 5px;" *ngIf="!accessTokenFlag" (click)="accessTokenFlag=!accessTokenFlag" [icon]="hidePassword"></fa-icon>
            <fa-icon style="padding-left: 5px;" *ngIf="accessTokenFlag" (click)="accessTokenFlag=!accessTokenFlag" [icon]="showPassword"></fa-icon>

            <div *ngIf="token.grantType=='password'">
                <label for="refreshToken"> {{ 'refreshToken' | translate}}</label>
                <input placeholder="required" *ngIf="refreshTokenFlag" [(ngModel)]="token.refreshToken" id="refreshToken" type="text" aria-label="refreshToken">
                <input type="password" *ngIf="!refreshTokenFlag" [(ngModel)]="token.refreshToken" id="refreshToken" aria-label="refreshToken">
                <!-- <mat-checkbox style="margin-left: 10px;" [(ngModel)]="refreshTokenFlag">Edit</mat-checkbox> -->
                <fa-icon style="padding-left: 5px;" *ngIf="!refreshTokenFlag" (click)="refreshTokenFlag=!refreshTokenFlag" [icon]="hidePassword"></fa-icon>
                <fa-icon style="padding-left: 5px;" *ngIf="refreshTokenFlag" (click)="refreshTokenFlag=!refreshTokenFlag" [icon]="showPassword"></fa-icon>
    
            </div>

            <label for="headers"> {{ 'headers' | translate}}</label>
            <textarea rows="2" placeholder="optional" [(ngModel)]="token.customHeaders" id="headers" aria-label="Headers"></textarea>

            <label for="formData"> {{ 'formData' | translate}}</label>
            <textarea rows="2" placeholder="optional" [(ngModel)]="token.customFormData" id="formData" aria-label="Form Data"></textarea>

            <br>
            <br>
            <mat-checkbox [(ngModel)]="token.customDataInIntrospection">{{'use.introspection' | translate}}</mat-checkbox>
            <br>

            <button mat-raised-button (click)="update()">{{'update' | translate}}</button>

        </div>
    </mat-sidenav-content>

</mat-sidenav-container>

<ng-container *ngIf="!token ">
    <div>
        <br/><br/>
    </div>
    <div class="d-flex justify-content-center pageLoading">
        <div class="spinner-border" role="status">
            <span class="sr-only" id="loading"></span>
        </div>
    </div>
</ng-container>
<button class="btn btn-secondary" style="margin-top: 20px; margin-left: 10px;" [cdkCopyToClipboard]="getAceEditorValue()">
    <fa-icon [icon]="faCopyIcon"></fa-icon> {{'cdkCopyToClipboard' | translate}}
</button>

<br>
<br>
<div style="margin-right: 20px;">
<ace-editor [ngClass]="editorClass" [(text)]="script" (textChanged)="onChange($event)" #editor>

</ace-editor>

</div>
<!-- style="height:500px;width: 680px;" -->

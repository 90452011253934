import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { IotService } from '../service/iot.service';
import { DeviceService } from '../service/device.service';
import { MapService } from '../service/map.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { GeofencesService } from '../service/geofences.service';

export interface User {
  name: string;
  id: number;
}


@Component({
  selector: 'app-map-widget',
  templateUrl: './map-widget.component.html',
  styleUrls: ['./map-widget.component.scss']
})
export class MapWidgetComponent implements OnInit {

  myControl = new FormControl();
  myTemplateControl=new FormControl();
  myGeofenceControl= new FormControl();
  filterdEcoSystems: User[] = [];
  filteredEcosystemOptions: Observable<User[]>;
  typeChangeControl = 1;
  deviceIds=[];
  devices= [];
  members: any;
  loadedDevice=false;
  criticalViolations: any;
  alertViolations: any;
  warningViolations: any;
  showMap=true;
  deviceTemplates: any;
  filteredTemplates: User[] = [];
  filteredTemplateOptions: Observable<User[]>;
  filteredGeofenceOptions: Observable<User[]>;
  filteredCustomTagOptions: Observable<User[]>;
  filteredGeofence: User[] = [];
  filteredCustomTags: User[] = [];
  customTagValues= [];
  customTagItem = '--select--';
  currentTagName;
  currentTagValue;
  tagNameSelected = false;
  currentEcosystemId;
  options = [
    { value: 1, viewValue: 'Exception' },
    { value: 2, viewValue: 'Ecosystem' },
    { value: 3, viewValue: 'Custom Tag' },
    { value: 4, viewValue: 'Device Template' },
    { value: 5, viewValue: 'Device Lifecycle' },
    { value: 6, viewValue: 'Geofence' }
  ];


  exceptions=[
    { value: 1, viewValue: 'Critical' },
    { value: 2, viewValue: 'Warning' },
    { value: 3, viewValue: 'Alert' },
  ]


  lifeCycles=[    
  { value: 1, viewValue: 'PENDING' },
  { value: 2, viewValue: 'ACTIVE' },
  { value: 3, viewValue: 'SUSPENDED' },
  { value: 4, viewValue: 'DELETED' }
]

  exceptionType=1;
  lifeCycleState=1;

  ecosystems = [];
  tags = [];

  selectedEcosystem;
  geofences: any;
  associations: any;
  deviceTemplateId;
  deviceLifeCycleState;
  deviceEcoSystemId;
  ecosystemTempId;
  criteriaName;
  criteriaValue;
  geofenceId: any;
  geofence: any;
  constructor(public iotService: IotService, public deviceService: DeviceService,public mapService:MapService,public geofencesService:GeofencesService) {

  }
  ngOnInit(): void {
    this.getCriticalDevices();
    this.loadEcosystems();
    this.loadDeviceTemplates();
    this.getPendingDevices();
    this.loadGeofences();
    this.loadCustomTags();
    this.filteredEcosystemOptions = this.myControl.valueChanges.pipe(
      startWith<string | User>(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((name) => (name ? this.ecoFilter(name) : this.filterdEcoSystems.slice()))
    );

    this.filteredTemplateOptions = this.myTemplateControl.valueChanges.pipe(
      startWith<string | User>(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((name) => (name ? this.templateFilter(name) : this.filteredTemplates.slice()))
    );

    
    this.filteredGeofenceOptions = this.myGeofenceControl.valueChanges.pipe(
      startWith<string | User>(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((name) => (name ? this.geofenceFilter(name) : this.filteredGeofence.slice()))
    );

    this.filteredCustomTagOptions = this.myGeofenceControl.valueChanges.pipe(
      startWith<string | User>(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((name) => (name ? this.tagFilter(name) : this.filteredCustomTags.slice()))
    );
  }

  displayFn(user?: User): string | undefined {
    return user ? user.name : undefined;
  }

  private ecoFilter(name: string): User[] {
    const filterValue = name.toLowerCase();

    return this.filterdEcoSystems.filter(
      (option) => option.name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  
  private templateFilter(name: string): User[] {
    const filterValue = name.toLowerCase();

    return this.filteredTemplates.filter(
      (option) => option.name.toLowerCase().indexOf(filterValue) === 0
    );
  }


  private geofenceFilter(name: string): User[] {
    const filterValue = name.toLowerCase();

    return this.filteredGeofence.filter(
      (option) => option.name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  private tagFilter(name: string): User[] {
    const filterValue = name.toLowerCase();

    return this.filteredCustomTags.filter(
      (option) => option.name.toLowerCase().indexOf(filterValue) === 0
    );
  }

loadCustomTags(){
  const that = this;
  that.filteredCustomTags=[];
  that.iotService.getCuiObjResponse().getUniqueTags({
  }).then(function (response) {
    that.tags = response;
    that.tags.forEach(element => {
      var tagging:User = {
        id: 0,
        name: ''

      };
      //tag.id=element.id;
      tagging.name=element.tagName;
      that.filteredCustomTags.push(tagging);
    });
   
  }).fail(function (err) {
    if (err.responseJSON.apiStatusCode) {
      console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
    } else {
      console.log("Something went wrong. Please check chrome console or check with dev team");
    }
  });
}

loadCustomTagValues(tagName){
  const that = this;
  that.tagNameSelected = false;
  that.customTagValues = [];
  that.customTagItem="--select--";
  that.customTagValues.push("--select--");
  that.iotService.getCuiObjResponse().getTags({
    tagName: tagName
    // tagValue: that.criteriaValue
  }).then(function (response) {
    that.tags = response;
    that.tags.forEach(element => {
      var tagging:User = {
        id: 0,
        name: ''

      };
      //tag.id=element.id;
      that.currentTagName = tagName;
      tagging.name=element.tagValue;
      //that.filteredCustomTags.push(tagging);
      that.customTagValues.push(tagging.name);
      if(that.currentTagName!=undefined){
        that.tagNameSelected = true;
      }
    });
   
  }).fail(function (err) {
    if (err.responseJSON.apiStatusCode) {
      console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
    } else {
      console.log("Something went wrong. Please check chrome console or check with dev team");
    }
  });
}

  loadEcosystems() {
    const that = this;
    that.iotService.getCuiObjResponse().getEcosystems({
    }).then(function (response) {
      that.ecosystems = response;
      that.ecosystems.forEach(element => {
        var eco:User = {
          id: 0,
          name: ''
  
        };
        eco.id=element.id;
        eco.name=element.name[0].text;
        that.filterdEcoSystems.push(eco);
      });
     
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }


  loadDeviceTemplates() {
    const that = this;
    that.iotService.getCuiObjResponse().getDeviceTemplates({
      // qs:[['pageSize',200],['page',1]]
    }).then(function (response) {
      that.deviceTemplates = response;
      that.deviceTemplates.forEach(element => {
        var template:User = {
          id: 0,
          name: ''
  
        };
        template.id=element.id;
        template.name=element.name[0].text;
        that.filteredTemplates.push(template);
      });
     
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }




  loadGeofences(){
    const that = this;
    that.iotService.getCuiObjResponse().getGeofences({
      qs:[['pageSize',200],['page',1]]
    }).then(function (response) {
      that.geofences = response;
      that.geofences.forEach(element => {
        var geofence:User = {
          id: 0,
          name: ''
  
        };
        geofence.id=element.id;
        geofence.name=element.name[0].text;
        that.filteredGeofence.push(geofence);
      });
     
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  fetchDevices(parentTemplateId){
    const that=this;
    that.deviceTemplateId = parentTemplateId;
    that.loadedDevice=false;
    that.iotService.getCuiObjResponse().getDevices({
      qs:['parentDeviceTemplateId', parentTemplateId]
    })
      .then(function (response) {
          that.devices=response;
          that.mapService.setDevices(that.devices);
          that.loadedDevice=true;      
      }).fail(function (response) {
        console.log("Unable to get device");
      });

  }

  getGeofenceDevices(geofenceId){
    const that=this;
    that.devices=[];
    that.mapService.setGeofence(undefined);
    that.geofencesService.geofence=undefined;
    that.geofencesService.polygon=undefined;
    that.geofencesService.maxSearchRadius=undefined;
    that.mapService.setDevices([]);
    that.deviceIds=[];
    that.geofenceId=geofenceId;
    that.loadedDevice=false;
    that.iotService.getCuiObjResponse().getGeofence({
      id: geofenceId
    }).then(function (response) {
      that.geofence=response;
      that.geofencesService.geofence=that.geofence;
  
      that.geofencesService.polygon=that.geofence.polygon;
      that.geofencesService.maxSearchRadius=that.geofence.maxSearchRadius;
        that.mapService.setGeofence(response);
      }).fail(function (response) {
        console.log("Unable to get geofence");
      });
    that.iotService.getCuiObjResponse().getDevices({
      qs:['geofenceId', geofenceId]
    }).then(function (response) {
        that.devices=response;
        that.mapService.setDevices(that.devices);
        that.loadedDevice=true; 
      }).fail(function (response) {
        console.log("Unable to get device");
      });
  }



  onChange(){
    const that=this;
    that.tagNameSelected=false;
    that.mapService.setDevices([]);
    that.mapService.setGeofence(undefined);
    that.geofencesService.geofence=undefined;
    that.geofencesService.polygon=undefined;
    that.geofencesService.maxSearchRadius=undefined;
    that.loadedDevice=false;
    if(that.typeChangeControl==2){
      that.myControl = new FormControl();

      that.loadEcosystems();
      that.filteredEcosystemOptions = that.myControl.valueChanges.pipe(
        startWith<string | User>(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) => (name ? that.ecoFilter(name) : that.filterdEcoSystems.slice()))
      );
        

    }
    if(that.typeChangeControl==1){
      that.exceptionType=1;
      that.getCriticalDevices();
    }
    if(that.typeChangeControl==4){
      that.loadDeviceTemplates();
      that.myTemplateControl=new FormControl();
      that.filteredTemplateOptions = that.myTemplateControl.valueChanges.pipe(
        startWith<string | User>(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) => (name ? that.templateFilter(name) : that.filteredTemplates.slice()))
      );
    }

    if(that.typeChangeControl==5){
      that.lifeCycleState=1;
      that.getPendingDevices();
    }

    if(that.typeChangeControl==6){
      this.loadGeofences();
      that.  myGeofenceControl= new FormControl();
      this.filteredGeofenceOptions = this.myGeofenceControl.valueChanges.pipe(
        startWith<string | User>(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) => (name ? this.geofenceFilter(name) : this.filteredGeofence.slice()))
      );
    }
    if(that.typeChangeControl==3){
      that.myControl = new FormControl();

      that.loadCustomTags();
      that.filteredCustomTagOptions = that.myControl.valueChanges.pipe(
        startWith<string | User>(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) => (name ? that.tagFilter(name) : that.filteredCustomTags.slice()))
      );

    }
  }

  onChangeException(){
    const that=this;
    that.loadedDevice=false;
    if(that.exceptionType==1){
      that.getCriticalDevices();
    }
    if(that.exceptionType==2){
      that.getWarningDevices();
    }
    if(that.exceptionType==3){
      that.getAlertDevices();
    }
  }

  onChnageLifeCycle(){
    const that=this;
    that.devices=[];
    that.mapService.setDevices([]);
    that.deviceIds=[];
    that.loadedDevice=false;
    if(that.lifeCycleState==1){
      that.getPendingDevices();
    }
    if(that.lifeCycleState==2){
      that.getActiveDevices();
    }
    if(that.lifeCycleState==3){
      that.getSuspendedDevices();
    }
    if(that.lifeCycleState==4){
      that.getDeletedDevices();
    }
  }

  getTagNameValueDevices(taggValue,currentTagName){
    const that = this;
    that.devices=[];
    that.mapService.setDevices([]);
    that.deviceIds=[];
    that.loadedDevice=false;
    that.currentTagName = currentTagName;
    that.currentTagValue = taggValue;
    that.iotService.getCuiObjResponse().getDevicesByTags({
      tagName: currentTagName,
      tagValue: taggValue
     }).then(function(response) {
      that.devices=response;
      that.mapService.setDevices(that.devices);
      that.loadedDevice=true;
     }).fail(function (err){
       if(err.responseJSON.apiStatusCode){
         console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
       } else{
         console.log("Something went wrong. Please check chrome console or check with dev team");
       }
     });
  }

  selectChangeHandler(event: any){
    const that = this;
   // that.loadCustomTags();
     var tagValue = event.target.value;
     if(tagValue!='--select--'){
     that.getTagNameValueDevices(tagValue,that.currentTagName);
     }

  }

  getPendingDevices(){
    const that=this;
    that.deviceLifeCycleState='PENDING';
    that.iotService.getCuiObjResponse().getDevices({
      qs:[['state.lifecycleState','PENDING']]
    }).then(function (response) {
        that.devices=response;
        that.mapService.setDevices(that.devices);
        that.loadedDevice=true;
      
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
    
  }


  getActiveDevices(){
    const that=this;
    that.deviceLifeCycleState = 'ACTIVE';
    that.iotService.getCuiObjResponse().getDevices({
      qs:[['state.lifecycleState','ACTIVE']]
    }).then(function (response) {
        that.devices=response;
        that.mapService.setDevices(that.devices);
        that.loadedDevice=true;
      
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
    
  }

  getSuspendedDevices(){
    const that=this;
    that.deviceLifeCycleState = 'SUSPENDED';
    that.iotService.getCuiObjResponse().getDevices({
      qs:[['state.lifecycleState','SUSPENDED']]
    }).then(function (response) {
        that.devices=response;
        that.mapService.setDevices(that.devices);
        that.loadedDevice=true;
      
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
    
  }

  getDeletedDevices(){
    const that=this;
    that.deviceLifeCycleState = 'DELETED';
    that.iotService.getCuiObjResponse().getDevices({
      qs:[['state.lifecycleState','DELETED']]
    }).then(function (response) {
        that.devices=response;
        that.mapService.setDevices(that.devices);
        that.loadedDevice=true;
      
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
    
  }

  getCriticalDevices(){
    const that = this;
    that.devices=[];
    that.mapService.setDevices([]);
    that.deviceIds=[];
    that.loadedDevice=false;
    that.iotService.getCuiObjResponse().getCriticalDevices({
    }).then(function(response) {
      that.devices=response;
      that.mapService.setDevices(that.devices);
      that.loadedDevice=true;
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  getWarningDevices(){   
    const that = this;
    that.devices=[];
    that.mapService.setDevices([]);
    that.deviceIds=[];
    that.loadedDevice=false;
    that.iotService.getCuiObjResponse().getWarningDevices({
    }).then(function(response) {
      that.devices=response;
      that.mapService.setDevices(that.devices);
      that.loadedDevice=true;
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  getAlertDevices(){
    const that = this;
    that.devices=[];
    that.mapService.setDevices([]);
    that.deviceIds=[];
    that.loadedDevice=false;
    that.iotService.getCuiObjResponse().getAlertDevices({
     
     }).then(function(response) {
      that.devices=response;
      that.mapService.setDevices(that.devices);
      that.loadedDevice=true;
     }).fail(function (err){
       if(err.responseJSON.apiStatusCode){
         console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
       } else{
         console.log("Something went wrong. Please check chrome console or check with dev team");
       }
     });
  }

  getEcosystemDevices(ecosystemId){
    const that = this;
    that.devices=[];
    that.mapService.setDevices([]);
    that.deviceIds=[];
    that.loadedDevice=false;
    that.currentEcosystemId=ecosystemId;
    that.iotService.getCuiObjResponse().getDevicesByEcosystems({
      ecosystemId: ecosystemId
     }).then(function(response) {
      that.devices=response;
      that.mapService.setDevices(that.devices);
      that.loadedDevice=true;
     }).fail(function (err){
       if(err.responseJSON.apiStatusCode){
         console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
       } else{
         console.log("Something went wrong. Please check chrome console or check with dev team");
       }
     });
  }

  getEcosystemMembers(ecosystemId) {
    const that = this;
    that.deviceEcoSystemId=ecosystemId;
    that.devices=[];
    that.mapService.setDevices([]);
    that.deviceIds=[];
    // that.markDevices([]);
    that.loadedDevice=false;
    that.iotService
      .getCuiObjResponse()
      .getEcosystemInstanceMembers({
        ecosystemId: ecosystemId,
      })
      .then(function (response) {
        that.members=response;
       
        that.members.forEach(element => {
          if(element.memberKind=='device'){
            that.deviceIds.push(element.memberId);
          
          }
        });
        if(that.deviceIds.length>0){
          that.getDevices(that.deviceIds);

        }
      })
      .fail(function (err) {
        if (err.responseJSON && err.responseJSON.apiStatusCode) {
          console.log(
            'Error Occured: Status: ' +
            err.responseJSON.status +
            ' Msg: ' +
            err.responseJSON.apiStatusCode
          );
        } else {
          console.log(
            'Something went wrong. Please check chrome console or check with dev team'
          );
        }
      });
  }

  
  getDevices(deviceIds: any[]) {
    const that=this;
    var i=0;
    deviceIds.forEach((element,index) => {
        const that = this;
      
        that.iotService.getCuiObjResponse().getDevice({
          deviceId: element
        })
          .then(function (response) {
            i++;
            that.devices.push(response);
          
            if(i==deviceIds.length){
              console.log("calling mark devices, for device array of length ",that.devices.length);
           
              that.mapService.setDevices(that.devices);
              that.loadedDevice=true;
              // that.markDevices(that.devices);
            }
           
          }).fail(function (response) {
            console.log("Unable to get device");
          });
      
    });
    
  }


}

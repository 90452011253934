import { Injectable } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { StreamService } from './stream.service';
import { IotService } from '../service/iot.service';
import { ListToTPCodesService } from './ListToTPCodes.service';
import { TradingPartnersService } from './tradingPartners.service';
import { MessageGovernancesService } from '../service/messageGovernances.service';

@Injectable({
	providedIn: 'root'
})

export class ToTPCodesService extends ListToTPCodesService {

	selection3: SelectionModel<String> = new SelectionModel<String>(true, []);
	selection4: SelectionModel<String> = new SelectionModel<String>(true, []);


	constructor(public iotService: IotService, public streamService: StreamService, public tpService: TradingPartnersService, public messageGovernancesService: MessageGovernancesService) {
		super(iotService);
		if (this.instanceValue == "1") {
			this.selection = this.selection3;
		}
		else if (this.instanceValue == "2") {
			this.selection = this.selection4;
		}
		this.selection.clear();
		this.loadSelected();


	}
	loadSelected() {
		if (this.tpService.getToCode()&& this.iotService.createTradingPartner==true) {
			this.onSelection(this.tpService.getToCode());
		}
		else if(this.streamService.getToCodeValue() && ( this.instanceValue == "1" ||this.instanceValue == "0") && this.iotService.createStream==true){
			this.selection.select(this.streamService.getToCodeValue().id);
		}
		else if(this.streamService.getToCodeValue2() && this.instanceValue == "2" && this.iotService.createStream==true){
			this.selection.select(this.streamService.getToCodeValue2().id);
		}
		else if(this.messageGovernancesService.getToCodeValue()&& this.iotService.msgGovLoader==true){
			this.messageGovernancesService.getToCodeValue().forEach(toTPCode => {
				this.selection.select(toTPCode.id);
			});	
		}
	}

	fnOnSelectionSupport(results) {
		const that = this;

		if (this.instanceValue == "1") {
			that.streamService.toCode = true;
			that.streamService.setToCodeValue(results);
		}
		else if (this.instanceValue == "2") {
			that.streamService.toCode2 = true;
			that.streamService.setToCodeValue2(results);
		}
		else {
			if (this.iotService.createStream == true) {
				that.streamService.toCode = true;
				that.streamService.setToCodeValue(results);
			}
			else if (that.iotService.createTradingPartner == true) {
				that.tpService.setToCode(results);
			}


		}

		if (that.iotService.createMessageGovernance == true) {
			let toCodeValue = that.messageGovernancesService.getToCodeValue();
			toCodeValue.push(results);
			that.messageGovernancesService.setToCodeValue(toCodeValue);
		}
	}

	fnRemoveSelectionSupport(results) {
		const that = this;

		if (this.instanceValue == "1") {
			that.streamService.toCode = false;
			that.streamService.setToCodeValue(undefined);
		}
		else if (this.instanceValue == "2") {
			that.streamService.toCode2 = false;
			that.streamService.setToCodeValue2(undefined);
		}
		else {

			if (this.iotService.createStream == true) {
				that.streamService.toCode = false;
				that.streamService.setToCodeValue(undefined);
			}
			else if (that.iotService.createTradingPartner == true) {
				that.tpService.setToCode(undefined);
			}
		}
		if (that.iotService.createMessageGovernance == true) {
			let toCodeValue = that.messageGovernancesService.getToCodeValue();
			toCodeValue.forEach((value, index) => {
				if (value.id == results.id) toCodeValue.splice(index, 1);
			});
			that.messageGovernancesService.setToCodeValue(toCodeValue);

		}
	}
}

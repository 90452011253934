<mat-sidenav-container class="mid-sidenav" autosize style="height: 450vh;">
    <mat-sidenav class="midNav-container" #sidenav mode="side" disableClose="true" opened="true">

        <div class="alert critical" *ngIf="errorMessage">
            <h5>
                <fa-icon [icon]="faCriticalIcon"></fa-icon>
                <span>{{errorMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=resetErrorMessage() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>
         <div *ngIf="!iotService.ecosystemAddRulePolicy">   
        <h1>{{'create.ecosystem.template' | translate}}</h1>
        <mat-nav-list class="without-template user-selections">
            <h2>{{'attributes' | translate}}</h2>
            <p *ngIf="ecosystemTemplateService.attributes.length == 0">
                {{'select.availableAttributes.desc' | translate}}
            </p>
            <ul>
                <li *ngFor="let attribute of ecosystemTemplateService.attributes">
                    <fa-icon [icon]="faCheckIcon"></fa-icon> {{ attribute.name }}
                </li>
            </ul>

            <h2>{{'events' | translate}}</h2>
            <p *ngIf="ecosystemTemplateService.events.length == 0">
               {{'select.availableEvents.desc' | translate}}
            </p>
            <ul>
                <li *ngFor="let event of ecosystemTemplateService.events">
                    <fa-icon [icon]="faCheckIcon"></fa-icon> {{ event.name }}
                </li>
            </ul>

            <button [disabled]="ecosystemTemplate.name[0].text=='' || ecosystemTemplateCreated" class="btn btn-primary"
                (click)="save()">{{ 'save' | translate}}</button>
        </mat-nav-list>
        </div>
    </mat-sidenav>

    <div class="container-data" *ngIf="ecosystemTemplateService.createResource">
        <div class=form-data>
            <label for="ecosystemTemplateName">{{ 'name' | translate}}</label>
            <input placeholder="required" id="ecosystemTemplateName" type="text" value=""
                (keyup)="ecosystemTemplateNameInput($event)" aria-label="Name"
                [(ngModel)]="ecosystemTemplate.name[0].text">
            <p *ngIf="errorInName" style="color: red;">{{errorInName}}</p>

            <label for="ecosystemTemplateDescription">{{ 'description' | translate}}</label>
            <textarea placeholder="optional" id="ecosystemTemplateDescription" aria-label="Description"
                [(ngModel)]="ecosystemTemplate.description[0].text"></textarea>

            <label for="ecosystemTemplateTags">{{ 'tags' | translate}}</label>
            <mat-chip-list #tagList aria-label="Tags">
                <mat-chip class="ot-chip" *ngFor="let tag of tags" [selectable]="true" [removable]="true"
                    (removed)="remove(tag)">
                    {{ tag }}
                    <button class="ot-chip__close" matChipRemove></button>
                </mat-chip>
                <input [ngClass]="tagClass" placeholder="add a tag" #tagInput [matChipInputFor]="tagList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                    (matChipInputTokenEnd)="add($event)" />
            </mat-chip-list>
        </div>

        <div class="container">
            <header class="table-header">
                <h2>
                    {{'ecosystemMembers.header' | translate}}
                    <span *ngIf="showHeaderToolTips" id="ot-tooltip-bottom" class="ot--is-width-px-200">
                        <a class="ot-tooltip ot-tooltip--is-bottom">
                            <fa-icon [icon]="faQuestionCircleIcon"></fa-icon>
                            <span class="ot-tooltip__text">{{'ecosystemMembers.header' | translate}}</span>
                        </a>
                    </span>
                </h2>
            </header>

            <div class="control-bar">
                <input placeholder="member name" id="member-name" type="text" value=""
                    (keyup)="allowedMemberNameInput($event)" [(ngModel)]="allowedMemberName"
                    aria-label="Members Allowed">

                <span>
                    <select name="allowedMemberKind" [(ngModel)]="allowedMemberKind"
                        (change)="allowedMemberKindInput($event)">
                        <option [value]="item.value" *ngFor="let item of allowedMemberOptions">
                            {{item.type}}
                        </option>
                    </select>
                </span>

                <span>
                    <button class="newBtn btn-primary" (click)="addMember()" aria-label="">{{'add' | translate}}</button>
                </span>
            </div>
            <div class="table-container">
                <table *ngIf="allowedMembers.length" mat-table [dataSource]="allowedMembers" class="membersTable">
                    <ng-container matColumnDef="Member Type">
                        <th class="name-cell nameCol" mat-header-cell *matHeaderCellDef>
                            {{'name' | translate}}
                        </th>
                        <td class="name-cell nameCol" mat-cell *matCellDef="let allowedMember">
                            {{allowedMember.type}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Kind">
                        <th class="name-cell nameCol" mat-header-cell *matHeaderCellDef>
                            {{'ecosystemMembers.kind' | translate}}
                        </th>
                        <td class="name-cell nameCol" mat-cell *matCellDef="let allowedMember">
                            {{allowedMember.kind}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Actions">
                        <th class="action-cell actionCol" mat-header-cell *matHeaderCellDef>
                            {{'actions' | translate}}
                        </th>
                        <td class="action-cell actionCol" mat-cell *matCellDef="let allowedMember"
                            style="cursor: pointer">
                            <fa-icon (click)="removeMember(allowedMember)" [icon]="faTimesIcon" style="color: red">
                            </fa-icon>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="allowedMemberColumns"></tr>
                    <tr mat-row *matRowDef="let allowedMember; columns: allowedMemberColumns;" class="bg-message"></tr>
                </table>
                <p *ngIf="errorInAllowedMember" style="color: red;">{{errorInAllowedMember}}</p>
                <p *ngIf="errorInAllowedMemberKind && !errorInAllowedMember" style="color: red;">
                    {{errorInAllowedMemberKind}}</p>
            </div>
        </div>

        <ng-container>
            <app-attributes [pageSize]="5" [showSelectColoumn]=true [showEnableColumn]=true [showControlBar]=true
                [showNew]=true [displayedColumns]="['ADD', 'name', 'description', 'type']"
                [newWithRoute]=false [showHeaderToolTips]=false></app-attributes>

            <app-events [pageSize]="5" [showSelectColoumn]=true [showEnableColumn]=true [showControlBar]=true
                [showNew]=true [displayedColumns]="['ADD', 'name', 'description']" [newWithRoute]=false [showHeaderToolTips]=false>
            </app-events>
        </ng-container>
    </div>

    <ng-container>
        <ng-container *ngIf="ecosystemTemplateService.createAttribute">
            <app-create-attribute></app-create-attribute>
        </ng-container>

        <ng-container *ngIf="ecosystemTemplateService.createEvent">
            <app-create-event></app-create-event>
        </ng-container>
    </ng-container>

</mat-sidenav-container>
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { IotService } from '../service/iot.service'; 
import { Pipe, PipeTransform } from '@angular/core';
import { faEnvelope, faTimes } from '@fortawesome/free-solid-svg-icons';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'limitTo'
})
export class TruncatePipe {
  transform(value: string, args: string): string {
    let limit = args ? parseInt(args, 10) : 10;
    let trail = '...';

    if (value != undefined) {
      return value.length > limit ? value.substring(0, limit) : value;
    }
    else {
      return value;
    }
  }
}


@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class TrackingComponent implements OnInit {
  
  displayedColumns: string[] = ['Timestamp', 'Type', 'MessageID', 'OwnerName', 'DocumentType', 'CustomerControl', 'Status'];
  dataSource: any;
  fromdate;
  failed = faTimes;
  envelope = faEnvelope;
  pageSize = 10;
  length = 1000;
  currentPage = 1;
  pageSizeOptions: number[] = [10, 15, 25, 100];
  previousTrackingElement;
  message: any;
  processorAudits: any;
  auditInfos: any;
  panelOpenState = true;
  senderFilePathToPayload = false;
  senderFilePayload: any;
  senderWirePathToPayload;
  senderWirePayload;
  receiverFilePathToPayload;
  receiverFilePayload;
  receiverWirePathToPayload;
  receiverWirePayload;
  commonFilePathToPayload;
  commonFilePayload;
  payloadName;
  pageClass='ot-pagination';
  trackingCount:number=0;
  showPagination:boolean=false;
  loaded=true;

  supportLanguages = ['en'];
  pageNumber = 1;

  constructor(private iotService: IotService,private translateService: TranslateService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en'); 
  }

  ngOnInit(): void {
    this.getTrackings(this.processingState,this.startDateValue);
    this.getTrackingCount(this.processingState);
  }

  @Input() processingState: string;
  @Input() startDateValue: number;

  getTrackings(processingState,startDateValue) {
    const that = this;
    that.fromdate = new Date();
    that.fromdate.setHours(that.fromdate.getHours() - startDateValue);
    that.dataSource='';
    that.iotService.getCuiObjResponse().getResourceTracking({
      qs: [
        ['processingState', processingState],
        ['endDate', Date.now()],
        ['startDate', that.fromdate.getTime()],
        ['pageSize', that.pageSize],
        ['page', that.currentPage],
        ['sortBy', '-dateTimeReceived']
      ]
    }).then(function (response) {
      console.log(response);
       that.dataSource = response;
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  
  getTrackingCount(processingState) {
    const that = this;
    that.showPagination=false;
    that.iotService.getCuiObjResponse().getResourceTracking({
      qs: [
        ['processingState', processingState],
        ['endDate', Date.now()],
        ['startDate', that.fromdate.getTime()],
        ['pageSize', 200],
        ['page', that.pageNumber++]
      ]
    }).then(function (response) {
      if (response == '' || response == 'undefined') {
        return;
      }
      if (response.length > 200 || response.length === 200) {
        that.trackingCount = that.trackingCount + response.length;
        that.showPagination=true;
        that.getTrackingCount(processingState);
      }
      if (response.length < 200) {
        that.trackingCount = that.trackingCount + response.length;
        that.showPagination=true;
        return;
      }
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  getMessageId(inputValue) {
    if (inputValue !== 'undefined' && inputValue != null) {
      var values = inputValue.substring(parseInt(inputValue.length) - 32, inputValue.length);
      return values;
    }
  }

  onPageChanged(event) {
    let currentPage = event.pageIndex + 1;
    const that = this;
    that.currentPage = currentPage;
    that.pageSize = event.pageSize;
    that.getTrackings(this.processingState,this.startDateValue);
  }

  selectPage(currentPageClass) {
    if(currentPageClass === 'ot-pagination') {
      this.pageClass='ot-pagination--is-active'; 
    } else {
      this.pageClass='ot-pagination'
    }
  }

  onPageSizeChange(pageSize) {
    const that = this;
    that.pageSize = pageSize;
    that.getTrackings(this.processingState,this.startDateValue); 
  }

  onPageChange(page) {
    const that = this;
    that.currentPage = page;
    that.getTrackings(this.processingState,this.startDateValue);
  }

  expandMessage(tracking, expandedMessage) {

    console.log("expand message -- " + expandedMessage);
    const that = this;
    if (that.previousTrackingElement != undefined && that.previousTrackingElement.messageId != tracking.messageId) {
      that.previousTrackingElement.messageClass = 'bg-message';
    }
    if (expandedMessage === null) {
      tracking.messageClass = 'bg-message';
    } else {
      tracking.messageClass = 'bg-message-selected';
    }
    that.previousTrackingElement = tracking;

  }

  getMessageDetails(tracking) {
    this.resetData();
    const that = this;
    // that.loaded=false;
    that.iotService.getCuiObjResponse().getResourceTracking({
      qs: [
        ['messageId', tracking.messageId]
      ]
    }).then(function (response) {
      that.message = response;
      that.processorAudits = that.message[0].processorAudits;
      if (that.processorAudits != null) {
        that.processorAudits.sort(function (a, b) {
          return a.startTime - b.startTime;
        });
      }

      that.auditInfos = that.message[0].auditInfo;

      if (that.message[0].senderFileAttributes) {
        //console.log('get')
        if (that.message[0].senderFileAttributes.size < 512) {
          //console.log('false');
          that.senderFilePathToPayload = false;
          that.senderFilePayload = that.message[0].senderFileBlob;

        }
        else if (that.message[0].senderFileAttributes.size > 512) {
          that.senderFilePathToPayload = true;
          that.senderFilePayload = 'null';
        }
      }
      if (that.message[0].senderWireAttributes) {
        if (that.message[0].senderWireAttributes.size < 512) {
          that.senderWirePathToPayload = false;
          that.senderWirePayload = that.message[0].senderWireBlob;
        }
        else if (that.message[0].senderWireAttributes.size > 512) {
          that.senderWirePathToPayload = true;
          that.senderWirePayload = 'null';
        }
      }
      if (that.message[0].receiverFileAttributes) {
        if (that.message[0].receiverFileAttributes.size < 512) {
          that.receiverFilePathToPayload = false;
          that.receiverFilePayload = that.message[0].receiverFileBlob;
        }
        else if (that.message[0].receiverFileAttributes.size > 512) {
          that.receiverFilePathToPayload = true;
          that.receiverFilePayload = 'null';
        }

      }
      if (that.message[0].receiverWireAttributes) {
        //console.log("receiver");
        if (that.message[0].receiverWireAttributes.size < 512) {
          that.receiverWirePathToPayload = false;
          that.receiverWirePayload = that.message[0].receiverWireBlob;
        }
        else if (that.message[0].receiverWireAttributes.size > 512) {
          that.receiverWirePathToPayload = true;
          that.receiverWirePayload = 'null'
        }
      }
      if (that.message[0].commonFileAttributes) {
        if (that.message[0].commonFileAttributes.size < 512) {
          that.commonFilePathToPayload = false;
          that.commonFilePayload = that.message[0].commonFileBlob;
        }
        else if (that.message[0].commonFileAttributes.size > 512) {
          that.commonFilePathToPayload = true;
          that.commonFilePayload = 'null';

        }
      }
      // that.loaded=true;
    }).fail(function (err) {
      // that.loaded=true;
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }

  resetData() {
    const that = this;
    that.senderFilePathToPayload = false;
    that.senderFilePayload = '';
    that.senderWirePathToPayload=false;
    that.senderWirePayload ='';
    that.receiverFilePathToPayload=false;
    that.receiverFilePayload ='';
    that.receiverWirePathToPayload=false;
    that.receiverWirePayload='';
    that.commonFilePathToPayload=false;
    that.commonFilePayload='';
    that.payloadName='';


  }


  reset(payloadName) {
    const that = this;
    if (payloadName === 'senderPayload') {
      that.senderWirePayload = that.message[0].senderWireBlob;
    }
    if (payloadName === 'receivePayload') {

      that.receiverWirePayload = that.message[0].receiverWireBlob;
    }
    if (payloadName === 'senderPayloadFile') {
      that.senderFilePayload = that.message[0].senderFileBlob;
    }
    if (payloadName === 'receivePayloadFile') {
      that.receiverFilePayload = that.message[0].receiverFileBlob;
    }
    if (payloadName === 'commonPayloadFile') {
      that.commonFilePayload = that.message[0].commonFileBlob;
    }
  }

  encodePayload(payloadName) {
    if (payloadName === 'senderPayload') {
      var encodedString = this.b64EncodeUnicode(this.senderWirePayload);
      this.senderWirePayload = encodedString;
    }
    if (payloadName === 'receivePayload') {
      var encodedString = this.b64EncodeUnicode(this.receiverWirePayload);
      this.receiverWirePayload = encodedString;
    }
    if (payloadName === 'senderPayloadFile') {
      var encodedString = this.b64EncodeUnicode(this.senderFilePayload);
      this.senderFilePayload = encodedString;
    }
    if (payloadName === 'receivePayloadFile') {
      var encodedString = this.b64EncodeUnicode(this.receiverFilePayload);
      this.receiverFilePayload = encodedString;
    }
    if (payloadName === 'commonPayloadFile') {
      var encodedString = this.b64EncodeUnicode(this.commonFilePayload);
      this.commonFilePayload = encodedString;
    }
  }

  decodePayload(payloadName) {
    if (payloadName === 'senderPayload') {
      var decodedString = this.b64DecodeUnicode(this.senderWirePayload);
      this.senderWirePayload = decodedString;
    }
    if (payloadName === 'receivePayload') {
      var decodedString = this.b64DecodeUnicode(this.receiverWirePayload);
      this.receiverWirePayload = decodedString;
    }
    if (payloadName === 'senderPayloadFile') {
      var decodedString = this.b64DecodeUnicode(this.senderFilePayload);
      this.senderFilePayload = decodedString;
    }
    if (payloadName === 'receivePayloadFile') {
      var decodedString = this.b64DecodeUnicode(this.receiverFilePayload);
      this.receiverFilePayload = decodedString;
    }
    if (payloadName === 'commonPayloadFile') {
      var decodedString = this.b64DecodeUnicode(this.commonFilePayload);
      this.commonFilePayload = decodedString;
    }
  }

  b64EncodeUnicode(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => {
      return String.fromCharCode(("0x" + p1) as any);
    }));
  }

  b64DecodeUnicode(str) {
    return decodeURIComponent(Array.prototype.map.call(atob(str), (c) => {
      return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(""));

  }

  downloadFile(filePath, realmId, payloadType, imgName, direction, messageId, bytes) {
    var data = {
      'realmId': realmId,
      'fileName': filePath,
      'messageId': messageId,
      'bytes': bytes,
      'direction': direction
    };
    this.iotService.getCuiObjResponse().downloadfile1(
      {
        data: data
      }
    ).then(
      function (results) {
        console.log(results);
        var isJson = true;
        try {
          eval(results);
        } catch (e) {
          isJson = false;
        }
        var a = document.createElement('a');
        if (isJson) {
          results = JSON.stringify(results);
        }
        var blob = new Blob([results], { "type": "application/octet-stream" });
        //console.log(blob.size)
        a.href = URL.createObjectURL(blob);
        a.download = messageId;
        a.click();
      }
    ).fail(
      function (results) {
        console.error("ERROR");
        // deferred.reject(results);
      }
    )
  }

  downloadPayload(payloadName) {
    this.payloadName = payloadName;
    if (payloadName === 'senderPayload') {
      this.payloadSuccess(this.b64DecodeUnicode(this.message[0].senderWireBlob), payloadName, this.message[0].messageId);
    }
    if (payloadName === 'receivePayload') {
      this.payloadSuccess(this.b64DecodeUnicode(this.message[0].receiverWireBlob), payloadName, this.message[0].messageId);
    }
    if (payloadName === 'senderPayloadFile') {
      this.payloadSuccess(this.b64DecodeUnicode(this.message[0].senderFileBlob), payloadName, this.message[0].messageId);
    }
    if (payloadName === 'receivePayloadFile') {
      this.payloadSuccess(this.b64DecodeUnicode(this.message[0].receiverFileBlob), payloadName, this.message[0].messageId);
    }
    if (payloadName === 'commonPayloadFile') {
      this.payloadSuccess(this.b64DecodeUnicode(this.message[0].commonFileBlob), payloadName, this.message[0].messageId);
    }
  }

  payloadSuccess(response, payloadName, messageId) {
    var a = document.createElement('a');
    var blob = new Blob([response], { "type": "application/octet-stream" });
    a.href = URL.createObjectURL(blob);
    a.download = messageId + '_' + payloadName + '.txt'
    a.click();
  }

}

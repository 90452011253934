/**
 * This is an auto generated code for tradingPartnerRelationships listing page.
 * Please don't make any change to this file.
 * @version 1.0.0
 * @readonly
 * @author - System generated
 */

import { Component, OnInit, ViewChild, Injector, Inject} from '@angular/core'
import { IotService } from '../service/iot.service'
import { TradingPartnerRelationshipsService } from '../service/tradingPartnerRelationships.service'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { faCaretUp, faCaretDown, faInfoCircle, faQuestionCircle, faArrowUp, faArrowDown, faSearch } from '@fortawesome/free-solid-svg-icons'
import { SideNavComponent } from '../side-nav/side-nav.component'
import { BaseListComponent } from '../base-list/baselist.component'

interface TypeChange {
	value: string;
	viewValue: string;
}
@Component({
	selector: 'app-tradingPartnerRelationships',
	templateUrl: './tradingPartnerRelationships.component.html',
	styleUrls: ['../base-list/baselist.component.scss','./tradingPartnerRelationships.component.scss']
})
export class TradingPartnerRelationshipsComponent extends BaseListComponent {
	searchCriteria="requestorTradingPartnerName";
	displayedColumns: string[] = ['requestorTradingPartnerName','approverTradingPartnerName','description','creation'];
	sortColumns: string[] = ['creation'];
	pageTitle="tradingPartnerRelationships.header";
	ascCreation:boolean = false;
	descCreation:boolean = false;
	countCallCompleted:boolean = false;
	getCallCompleted:boolean = false;
	showEnableColumn = true;

	constructor( router: Router,
				iotService: IotService,
				translateService: TranslateService,
				sideNavComponent:SideNavComponent,
				public tradingPartnerRelationshipsService:TradingPartnerRelationshipsService) {
				super(router, iotService, translateService, sideNavComponent )
						this.translateService.addLangs(this.supportLanguages);
						this.translateService.setDefaultLang('en');
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.tradingPartnerRelationshipsService.multiSelectRows = this.multiSelect;
		this.tradingPartnerRelationshipsService.loadSelected();
		if(!this.showSelectColoumn){
			this.sideNavComponent.menuClear();
			this.sideNavComponent.menuChange('Dashboard','sub-type');
			this.sideNavComponent.menuChange('tradingPartnerRelationships.header','sub-type');
		}
	}
	getFilteredResults(value, columnName , sortOrder){
		const that = this;
		that.iotService.getCuiObjResponse().getTPRelationships({
			qs: eval(that.fnGetQS(value, columnName, sortOrder))
		}).then(function (response) {
			that.fnSuccess(response);
			if(columnName == 'none' || columnName == '' || columnName == undefined){
				that.fnResetSortParam('creation', 'desc');
			}else{
				that.fnResetSortParam(columnName, sortOrder);
			}
			that.getCallCompleted = true;
		}).fail(function (err) {
			that.fnError(err);
			that.getCallCompleted = true;
		});
	}
	getCount() {
		const that = this;
		that.showPagination=false;
		let value = this.selected;
		that.iotService.getCuiObjResponse().countTPRelationships({
			qs: eval(that.fnGetQS(value, '', ''))
		}).then(function (response) {
				that.totalCount = response;
				that.countCallCompleted = true;
			if(that.totalCount > 0)
				that.showPagination=true;
			}).fail(function (err) {
				that.fnError(err);
				that.countCallCompleted = true;
			});
	}
	activateTPRelationships(resourceId){
		const that = this;
		that.iotService.getCuiObjResponse().activateTPRelationships({
			codeId: resourceId
		}).then(function (response) {
			that.getFilteredResults(that.selected, that.sortCriteria, that.sortDirection);
			that.tradingPartnerRelationshipsService.fnResetList();
		}).fail(function (err) {
			that.fnError(err);
		});
	}
	deactivateTPRelationships(resourceId){
		const that = this;
		that.iotService.getCuiObjResponse().deactivateTPRelationships({
			codeId: resourceId
		}).then(function (response) {
			that.getFilteredResults(that.selected, that.sortCriteria, that.sortDirection);
			that.tradingPartnerRelationshipsService.fnResetList();
		}).fail(function (err) {
			that.fnError(err);
		});
	}
	deleteTPRelationships(resourceId){
		const that = this;
		that.iotService.getCuiObjResponse().deleteTPRelationships({
			codeId: resourceId
		}).then(function (response) {
			that.getCount();
			that.getFilteredResults(that.selected, that.sortCriteria, that.sortDirection);
			that.tradingPartnerRelationshipsService.fnResetList();
		}).fail(function (err) {
			that.fnError(err);
			that.tradingPartnerRelationshipsService.showErrorMessage(err);
		});
	}
	viewDetails(selectedId){
		this.router.navigateByUrl("/tradingPartners/"+selectedId);
		this.tradingPartnerRelationshipsService.viewDetails(selectedId);
	}
	createNew(){
		this.tradingPartnerRelationshipsService.createNew();
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}
	onSelection(results){
		this.tradingPartnerRelationshipsService.onSelection(results);
	}
	removeSelection(results){
		this.tradingPartnerRelationshipsService.removeSelection(results);
	}
	changeActive(resourceId, isActivate){
		if(isActivate == 'true'){
			this.deactivateTPRelationships(resourceId);
		}else{
			this.activateTPRelationships(resourceId);
		}
	}
}

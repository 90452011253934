<mat-sidenav-container class="mid-sidenav" autosize *ngIf="tradingPartner" style="height: 450vh;">
    <mat-sidenav class="midNav-container" #sidenav mode="side" disableClose="true" opened="true" scrollable="true">
        <div class="alert critical" *ngIf="tpService.errorMessage">
            <h5 style="font-size: 12px;">
                <fa-icon [icon]="faCriticalIcon"></fa-icon>
                <span>{{tpService.errorMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=tpService.resetMessage() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>

        </div>

        <div class="alert warning" *ngIf="tpService.warningMessage">
            <h5 style="font-size: 12px;">
                <fa-icon [icon]="faWarningIcon"></fa-icon>
                <span>{{tpService.warningMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)="tpService.resetMessage()" [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>
        <div class=form *ngIf="tradingPartner">
            <div style="margin-left: -20px;">
                <app-copy-resource-id></app-copy-resource-id>
            </div>
            <label for="name"> {{ 'name' | translate}}</label>
            <p>{{tradingPartner.name[0].text}}</p>

            <label for="description"> {{ 'description' | translate}}</label>
            <p *ngIf="tradingPartner.description">{{tradingPartner.description[0].text}} </p>
            <p *ngIf="!tradingPartner.description">- </p>
            <div class="tags">

                <label for="name"> {{ 'created' | translate}}</label>
                <p>{{tradingPartner.creation | date:'mediumDate'}}</p>    

                <label for="device-tags">{{ 'tags' | translate}}</label>
                <mat-chip-list #tagList aria-label="tags">

                    <mat-chip class="ot-chip" *ngFor="let tag of tradingPartner.tags" [selectable]="true" [removable]=true (removed)="remove(tag)">
                        {{tag}}
                        <button class="ot-chip__close" matChipRemove></button>
                    </mat-chip>
                    <input style="width: 50%;" [ngClass]="tagClass" placeholder="add a tag" #tagInput [matChipInputFor]="tagList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="add($event)">

                </mat-chip-list>
            </div>

            <label>{{ 'status' | translate}}</label>
            <div *ngIf="tradingPartner.status=='ACTIVE'">
                <button class="toggle-on toggle-switch" (click)="toggleActive('INACTIVE')">
                    <fa-icon [icon]="faCheckIcon" class="fa-2x"></fa-icon>
                    <div class="toggle-on-btn"></div>
                </button>
            </div>

            <div *ngIf="tradingPartner.status=='INACTIVE'">
                <button class="toggle-off toggle-switch" (click)="toggleActive('ACTIVE')">
                    <div class="toggle-off-btn"></div>
                </button>
            </div>
            <br>
           
            <div class="button-row pull-left">
                <button class="btn btn-secondary" style="margin: 5px; margin-left: -1px;"
                (click)="auditTPA()">{{ 'audit' |
                translate}}</button>  
            </div>
                <!-- <button *ngIf="tradingPartner.name[0].text && !tradingPartnerCreated" class="save" mat-raised-button color="primary" (click)="save()">{{ 'save' | translate}}</button> -->
                <div class="button-row pull-right">
                <button *ngIf="tradingPartnerCreated"  style="margin: 5px; margin-right: 15px;"  class="btn btn-delete"  (click)="delete()">{{ 'delete' | translate}}</button>
    
            </div>
           
            <br>
        </div>


    </mat-sidenav>
    <mat-sidenav-content class="example-sidenav-content">
       <div *ngIf="!auditFlag">
        <div class="tradingPartnerData" *ngIf="tpService.createResource && tradingPartner">
            <ng-container *ngIf="tradingPartnerCreated">
                <app-tradingPartnerCodes [pageSize]="5" [showSearch]=true [showSelectColoumn]=true [showEnableColumn]=true [showActionColumn]=true [displayedColumns]="['code','qualifier', 'ACTIVE','ACTION']" addnQueryParam=[tradingPartnerId::{{tradingPartner.id}}] [newWithRoute]=false [showHeaderToolTips]=false>
                </app-tradingPartnerCodes>
                <app-codeRelationships [pageSize]="5" [showSelectColoumn]=true [showEnableColumn]=true [showActionColumn]=true [displayedColumns]="['fromCode','toCode','controlNumber','description', 'ACTIVE','ACTION']" addnQueryParam=[tradingPartnerId::{{tradingPartner.id}}]
                    [newWithRoute]=false [showHeaderToolTips]=false>
                </app-codeRelationships>
                <app-tradingPartnerRelationships [pageSize]="5" [showSelectColoumn]=true [showEnableColumn]=true [showActionColumn]=true [displayedColumns]="['requestorTradingPartnerName','approverTradingPartnerName', 'ACTIVE','ACTION']" addnQueryParam=[tradingPartnerId::{{tradingPartner.id}}]
                    [newWithRoute]=false [showHeaderToolTips]=false>
                </app-tradingPartnerRelationships>
                <app-tpApplications [pageSize]="5" [showSelectColoumn]=true [showEnableColumn]=true [showActionColumn]=true [displayedColumns]="['name', 'ACTION']" addnQueryParam=[tradingPartnerId::{{tradingPartner.id}}] [newWithRoute]=false [showHeaderToolTips]=false>
                </app-tpApplications>
                <app-routingSourceRelationships [pageSize]="5" [showSelectColoumn]=true [showEnableColumn]=true [showActionColumn]=true [displayedColumns]="['name','fromCode', 'toCode', 'documentInfoName', 'senderOwnerName','ACTIVE','ACTION']" addnQueryParam=[receiverOwnerId::{{tradingPartner.id}}]
                    [newWithRoute]=false [showHeaderToolTips]=false>
                </app-routingSourceRelationships>
            </ng-container>


        </div>

        <div *ngIf="tpService.createTpCode">
            <app-new-trading-partner-code></app-new-trading-partner-code>
        </div>

        <div *ngIf="tpService.createCodeRelationShip">
            <app-new-code-relationships></app-new-code-relationships>
        </div>

        <div *ngIf="tpService.createTpRelationShip" class="tradingPartnerRelationships">
            <app-tradingPartners [pageSize]="5" [showSelectColoumn]=true [displayedColumns]="['ADD','name','description','ACTIVE']" [showSelectColoumn]=true [showEnableColumn]=true [showHeaderToolTips]=false></app-tradingPartners>
            <div class="button-row pull-right">
                <button class="btn btn-secondary" style="margin-top: 20px;" (click)="goBack()">{{ 'go.back' | translate}}</button>
                <button class="btn btn-primary" style="margin-top: 20px; margin-right: 15px;" [disabled]="tpService.getTradingPartnersList().length==0" (click)="saveTpRelationship()">{{ 'save' | translate}}</button>             
            </div>
          <br>
            <br>
        </div>


        <div *ngIf="tpService.createApplication" class="tradingPartnerRelationships">
            <app-applications [pageSize]="5" [showSelectColoumn]=true [displayedColumns]="['ADD','name','description','creation']" [showSelectColoumn]=true [showHeaderToolTips]=false></app-applications>
            <div class="button-row pull-right">
                <button class="btn btn-secondary" style="margin-top: 20px;" (click)="goBackApplication()">{{ 'go.back' | translate}}</button>
                <button class="btn btn-primary" style="margin-top: 20px; margin-right: 15px;" [disabled]="tpService.getTpApplications()==undefined" (click)="saveTpApplication()">{{ 'save' | translate}}</button>
            </div>
            <br>
            <br>
        </div>

        <div *ngIf="tpService.createRouteSource">
            <app-new-route-resource-relationship [showHeaderToolTips]=false></app-new-route-resource-relationship>
        </div>

        <div *ngIf="tpService.viewTPCode" style="height:115vh">
            <app-trading-partner-code-detail></app-trading-partner-code-detail>
        </div>

        <div *ngIf="tpService.viewCodeRelationShip" style="height:115vh">
            <app-code-relation-ship-detail></app-code-relation-ship-detail>
        </div>

        <div *ngIf="tpService.viewRouteSource" style="height:115vh">
            <app-route-relationship-detail></app-route-relationship-detail>
        </div>
        </div>
        <!--Added for Auit Integration-->
        <div  style="height: 110vh;">
             <div *ngIf="auditFlag">
                <div class="tab-holder">
                    <mat-tab-group (selectedTabChange)="tabClick($event)" [selectedIndex]="0">
                        <mat-tab label="Trading Partner"></mat-tab>
                        <mat-tab label="TP Relationships"></mat-tab>
                        <mat-tab label="TP Codes"></mat-tab>
                        <mat-tab label="TP Code Relationship"></mat-tab>
                        <mat-tab label="TP Route Source"></mat-tab>
                    </mat-tab-group>
                </div>   
             </div>  

            <div *ngIf="tradingPartnerAudit"> 
                <app-tradingPartnerAudits *ngIf="auditFlag &&auditRefreshFlag && iotService.auditScriptFlag==false" 
                [showNew]="false"
                [showControlBar]=false 
                showSelectColoumn=false 
                showEnableColumn=false 
                addnQueryParam=[tradingPartnerId::{{tpService.tradingPartnerId}}^^resourceType::{{resourceType}}>
                </app-tradingPartnerAudits>
                <app-tradingPartnerAudits *ngIf="auditFlag && !auditRefreshFlag  && iotService.auditScriptFlag==false" 
                [showNew]="false"
                [showControlBar]=false 
                showSelectColoumn=false 
                showEnableColumn=false 
                addnQueryParam=[tradingPartnerId::{{tpService.tradingPartnerId}}^^resourceType::{{resourceType}}>
                </app-tradingPartnerAudits>
            </div>
            <div *ngIf="tpRelationshipsAudit"> 
                <app-tradingPartnerAudits *ngIf="auditFlag && auditRefreshFlag && iotService.auditScriptFlag==false" 
                [showNew]="false"
                [showControlBar]=false 
                showSelectColoumn=false 
                showEnableColumn=false 
                addnQueryParam=[tradingPartnerId::{{tpService.tradingPartnerId}}^^resourceType::{{resourceType}}>
                </app-tradingPartnerAudits>
                <app-tradingPartnerAudits *ngIf="auditFlag && !auditRefreshFlag && iotService.auditScriptFlag==false" 
                [showNew]="false"
                [showControlBar]=false 
                showSelectColoumn=false 
                showEnableColumn=false 
                addnQueryParam=[tradingPartnerId::{{tpService.tradingPartnerId}}^^resourceType::{{resourceType}}>
                </app-tradingPartnerAudits>
                </div>

            <div *ngIf="tpCodesAudit"> 
                 <app-tradingPartnerAudits *ngIf="auditFlag && auditRefreshFlag && iotService.auditScriptFlag==false" 
                 [showNew]="false"
                 [showControlBar]=false 
                 showSelectColoumn=false 
                 showEnableColumn=false 
                 addnQueryParam=[tradingPartnerId::{{tpService.tradingPartnerId}}^^resourceType::{{resourceType}}>
                 </app-tradingPartnerAudits>
                 <app-tradingPartnerAudits *ngIf="auditFlag  && !auditRefreshFlag && iotService.auditScriptFlag==false" 
                 [showNew]="false"
                 [showControlBar]=false 
                 showSelectColoumn=false 
                 showEnableColumn=false 
                 addnQueryParam=[tradingPartnerId::{{tpService.tradingPartnerId}}^^resourceType::{{resourceType}}>
                 </app-tradingPartnerAudits>
             </div>    

             <div *ngIf="tpCodesRelationshipAudit"> 
                 <app-tradingPartnerAudits *ngIf="auditFlag && auditRefreshFlag && iotService.auditScriptFlag==false" 
                 [showNew]="false"
                 [showControlBar]=false 
                 showSelectColoumn=false 
                 showEnableColumn=false 
                 addnQueryParam=[tradingPartnerId::{{tpService.tradingPartnerId}}^^resourceType::{{resourceType}}>
                 </app-tradingPartnerAudits>
                 <app-tradingPartnerAudits *ngIf="auditFlag && !auditRefreshFlag && iotService.auditScriptFlag==false" 
                 [showNew]="false"
                 [showControlBar]=false 
                 showSelectColoumn=false 
                 showEnableColumn=false 
                 addnQueryParam=[tradingPartnerId::{{tpService.tradingPartnerId}}^^resourceType::{{resourceType}}>
                 </app-tradingPartnerAudits>
            </div>  
                    
            <div *ngIf="tpRouteSourceAudit"> 
                 <app-tradingPartnerAudits *ngIf="auditFlag && auditRefreshFlag  && iotService.auditScriptFlag==false" 
                 [showNew]="false"
                 [showControlBar]=false 
                 showSelectColoumn=false 
                 showEnableColumn=false 
                 addnQueryParam=[tradingPartnerId::{{tpService.tradingPartnerId}}^^resourceType::{{resourceType}}>
                 </app-tradingPartnerAudits>
                 <app-tradingPartnerAudits *ngIf="auditFlag && !auditRefreshFlag && iotService.auditScriptFlag==false" 
                 [showNew]="false"
                 [showControlBar]=false 
                 showSelectColoumn=false 
                 showEnableColumn=false 
                 addnQueryParam=[tradingPartnerId::{{tpService.tradingPartnerId}}^^resourceType::{{resourceType}}>
                 </app-tradingPartnerAudits>
            </div>          
            <!-- <app-audits *ngIf="auditFlag && !auditRefreshFlag && iotService.auditScriptFlag==false" [showNew]="false" [showControlBar]=false [displayedColumns]="['creation','requestorId','actionType','id']" showSelectColoumn=false showEnableColumn=false addnQueryParam=[resourceId::{{tpService.tradingPartnerId}}] ></app-audits> -->
            <div *ngIf="auditFlag && iotService.auditScriptFlag==false">
                <br>
        
                <button class="btn btn-secondary" style="margin-left:15px ;" (click)="closeAudit()">{{ 'go.back' | translate}}</button>
                <br>
                <br>
            </div>
                <br>
                <div  *ngIf="iotService.auditScriptFlag" style="margin: 0 5px 0 5px;">      
                    <app-ace></app-ace>
               </div>
               <div *ngIf="iotService.auditScriptFlag" class="tpaAudit">
                <button class="goBack" mat-raised-button color="primary" (click)="goBackAuditList()">{{ 'go.back' | translate}}</button>
                <br>
                <br>
            </div>
        </div>


     <br>
     <br>
    </mat-sidenav-content>

</mat-sidenav-container>
<ng-container *ngIf="!tradingPartner ">
    <div>
        <br/><br/>
    </div>
    <div class="d-flex justify-content-center pageLoading">
        <div class="spinner-border" role="status">
            <span class="sr-only" id="loading"></span>
        </div>
    </div>
</ng-container>
import { Component, OnInit } from '@angular/core';
import { faToggleOff, faToggleOn, faAngleDown, faAngleUp, faAngleLeft, faAngleRight, faCheck, faCopy, faLock, faLockOpen, faTimes, faExclamation, faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-styleguide',
  templateUrl: './styleguide.component.html',
  styleUrls: ['./styleguide.component.scss']
})
export class StyleguideComponent implements OnInit {
  panelOpenState = false;

  faCheckIcon = faCheck;

  htmlToggleSwitches = `
    &lt;button class="toggle-on toggle-switch" title="Description of action performed by button">
    &lt;fa-icon [icon]="faCheckIcon" class="fa-2x"></fa-icon>
    &lt;div class="toggle-on-btn"></div>
    &lt;/button>
    &lt;button class="toggle-off toggle-switch" title="Description of action performed by button">
    &lt;div class="toggle-off-btn"></div>
    &lt;/button>
  `;
  cssToggleSwitches = `
    @mixin firefox() {
      @-moz-document url-prefix() {
        @content;
      }
    }
    
    .toggle-off {
      .toggle-off-btn {
        top: -3px;
        left: -18px;
        // alignment fix for toggle switches, targets firefox
        // @include firefox(){
        //   left: -18px;
        //   top: -3px;
        // }
      }
    }
    .toggle-on {
      fa-icon {
        top: 4px;
        left: -10px;
        // alignment fix for toggle switches, targets firefox
        // @include firefox(){
        //   top: 4px;
        //   left: -10px;
        // }
      }
      .toggle-on-btn {
        top: -14px;
        left: 8px;
        // alignment fix for toggle switches, targets firefox
        // @include firefox(){
        //   top: -14px;
        //   left: 8px;
        // }
      }
    }`;

  htmlTableHeader = `
  &lt;header class="table-header">
    &lt;h2>Table Header&lt;/h2>
  &lt;/header>
  &lt;div class="control-bar">
    &lt;input placeholder="search" type="text">
    &lt;button class="btn btn-primary" aria-label="Create new event">New&lt;/button>
  &lt;/div>
  &lt;div class="table-container">
    &lt;table>
      &lt;tr>
        &lt;th>Column 1&lt;/th>
        &lt;th>Column 2&lt;/th>
        &lt;th>Column 3&lt;/th>
        &lt;th>Column 4&lt;/th>
        &lt;th>Column 5&lt;/th>
      &lt;/tr>
      &lt;tr>
        &lt;td>Cell 1&lt;/td>
        &lt;td>Cell 2&lt;/td>
        &lt;td>Cell 3&lt;/td>
        &lt;td>Cell 4&lt;/td>
        &lt;td>Cell 5&lt;/td>
      &lt;/tr>
    &lt;/table>
  &lt;/div>
  &lt;div class="table-container">
    &lt;p>Pagination Component&lt;/p>
  &lt;/div>
  `;
  constructor() { }

  ngOnInit(): void {
  }

}

import { Injectable } from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import { StreamService } from './stream.service';
import { IotService } from '../service/iot.service'; 
import { ListKeyBundlesService } from './ListKeyBundles.service';

@Injectable({ 
	  providedIn: 'root' 
})

export class KeyBundlesService extends ListKeyBundlesService{
	 
	selection: SelectionModel<String> = new SelectionModel<String>(true, []); 
	constructor(public iotService:IotService, public streamService:StreamService) {
		  super(iotService);
		  this.selection.clear();
	 }

	 loadSelected(){
		 this.selection.clear();
		 if(this.streamService.getMutualAuthObject()){
			 this.selection.select(this.streamService.getMutualAuthObject().id);
		 }
	 }
	 
	 fnOnSelectionSupport(results){ 
		 const that = this; 
		 that.streamService.setMutualAuthObject(results);
		 that.streamService.setMutualAuth(results.name);
		 that.streamService.setMutualAuthId(results.id);
	 } 
	 
	 fnRemoveSelectionSupport(results){ 
		 const that = this;
		 that.streamService.setMutualAuthObject(undefined); 
		 that.streamService.setMutualAuth(undefined);
		 that.streamService.setMutualAuthId(undefined);

	 } 
} 

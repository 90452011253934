import { Injectable } from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import { StreamService } from './stream.service';
import { IotService } from '../service/iot.service'; 
import { ListSshKeyPairsService } from './ListSshKeyPairs.service';

@Injectable({ 
	  providedIn: 'root' 
})

export class SshKeyPairsService extends ListSshKeyPairsService{
	 
	selection: SelectionModel<String> = new SelectionModel<String>(true, []);
	 constructor(public iotService:IotService, public streamService:StreamService) {
		super(iotService);
		this.selection.clear();
	}

	loadSelected(){
		this.selection.clear();
		if(this.streamService.getSshKeyPairObject()){
			this.selection.select(this.streamService.getSshKeyPairObject().id)
		}
	}
	 
	 fnOnSelectionSupport(results){ 
		 const that = this; 
		 that.streamService.setSshKeyPairObject(results);
		 that.streamService.setSSHKeyPair(results.name);
		 that.streamService.setsshKeyPairId(results.id);
	 } 
	 
	 fnRemoveSelectionSupport(results){ 
		 const that = this; 
		 that.streamService.setSshKeyPairObject(undefined);
		 that.streamService.setSSHKeyPair(undefined);
		 that.streamService.setsshKeyPairId(undefined);

	 } 
	 
} 

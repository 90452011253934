import { Component, OnInit, Input } from '@angular/core';
import { IotService } from '../service/iot.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { faCheck,faCheckCircle,faExclamation,faExclamationTriangle,faTimes } from '@fortawesome/free-solid-svg-icons';
import { DeviceService } from '../service/device.service';
import { EventGroupsService } from '../service/eventGroups.service';
import { TranslateService } from '@ngx-translate/core';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { Router } from '@angular/router';
import { JsonPipe } from '@angular/common';

@Component({
  selector: 'app-create-event-group',
  templateUrl: './create-event-group.component.html',
  styleUrls: ['./create-event-group.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  }
})

export class CreateEventGroupComponent implements OnInit {

  @Input() data: any;

  constructor(private translateService: TranslateService, 
    public iotService: IotService,
    public deviceService : DeviceService,
    private sideNavComponent: SideNavComponent,
    private router: Router, public eventGroupService:EventGroupsService)  {
    this.sideNavComponent.menuClear();
    this.sideNavComponent.menuChange('Dashboard','sub-type');
    this.sideNavComponent.menuChange('eventGroups.header','sub-type');
    this.sideNavComponent.menuChange('eventGroups.create.new', 'sub-type');
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }

  isExpanded = true;
  isShowing = false;
  response: string;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  supportLanguages = ['en'];
  eventGroup: any;
  faCheckIcon = faCheck;
  faCriticalIcon = faExclamation;
  faWarningIcon = faExclamationTriangle;
  faSuccessIcon = faCheckCircle;
  faCloseIcon = faTimes;
  eventGroupCreate: string;
  device: any;
  deviceId: String;
  eventTemaplateId: String;
  commandtemplateId: String;
  deviceTemplate: any;
  devicetemplatesEvents: any;
  devicetemplatesCommands: any;
  devicesEvents: any;
  devicesCommands: any;
  tags = [];
  tagList = [];
  tagClass = 'tagInput';
  active:boolean;
  eventGroupNameInput;
  eventGroupNameSelect = false;
  eventGroupCreated=false;
  
  onClick(event) {
    const that = this;
    that.eventGroupService.resetErrorMessage();
    that.eventGroupService.resetWarningMessage();
    that.eventGroupService.resetSuccessMessage();
  }

  mouseenter() {
    const that = this;
    if (!that.isExpanded) {
      that.isShowing = true;
    }
  }

  mouseleave() {
    const that = this;
    if (!that.isExpanded) {
      that.isShowing = false;
    }
  }

  ngOnDestroy(): void {
    const that = this;
    that.eventGroupService.eventTemplateSelect = false;
    that.eventGroupService.setCommandId(undefined);
    that.eventGroupService.setEventGroupName(undefined);
    that.eventGroupService.setEventId(undefined);
    
    that.eventGroupService.selectedCommandTemplateData=[];
    that.eventGroupService.selectedDeviceTemplateCommandTemplateData=[];
    that.eventGroupService.selectedDeviceTemplateEventTemplateData=[];
    that.eventGroupService.selectedEventTemplateData=[];

    //this.eventGroupService.setEventGroupSourceDeviceTemplates(undefined);
    
    that.eventGroupService.eventGroupNameSelect = false;
    that.eventGroupService.eventSelect = false;
    that.eventGroupService.commandSelect = false;

    that.eventGroupService.sourceDevice = '';
    
    that.eventGroupService.selectedEventTemplates=[];
    that.eventGroupService.selectedCommandTemplates=[];
    that.eventGroupService.sourceDeviceTemplates=[];
    that.eventGroupService.sourceDeviceTemplates=[];
    that.eventGroupService.sourceDevices=[];
    
    // this.eventGroupService.setEventGroupName(undefined);
    that.eventGroupService.setEventGroupDescription(undefined);
    that.eventGroupService.setEventGroupTags(undefined);
    that.eventGroupService.resetErrorMessage();
    that.eventGroupService.resetWarningMessage();
    that.eventGroupService.resetSuccessMessage();

    //need the check the below line later
    //this.deviceService.setTemplateName(undefined);
    
    that.deviceId='';
    that.eventTemaplateId='';
    that.commandtemplateId='';
    that.tags.length=0;
    that.tagList.length=0;
    if(that.eventGroupService.eventGroupTags!=undefined){
      that.eventGroupService.eventGroupTags.length=0;
    }
    that.eventGroupService.eventGroupName = '';
    that.eventGroupService.eventGroupDescription = '';
    that.eventGroupService.eventGroupNameSelect = false;
  }

  ngOnInit(): void {
    const that = this;
    that.eventGroupCreate = '1';
    that.eventGroupService.eventTemplateSelect = false;
    that.eventGroupService.setCommandId(undefined);
    that.eventGroupService.setEventGroupName(undefined);
    that.eventGroupService.setEventId(undefined);
    
    that.eventGroupService.selectedCommandTemplateData=[];
    that.eventGroupService.selectedDeviceTemplateCommandTemplateData=[];
    that.eventGroupService.selectedDeviceTemplateEventTemplateData=[];
    that.eventGroupService.selectedEventTemplateData=[];
        
    that.eventGroupService.setEventGroupDescription(undefined);
    that.eventGroupService.setEventGroupTags(undefined);

    that.eventGroupService.eventGroupNameSelect = false;
    that.eventGroupService.eventSelect = false;
    that.eventGroupService.commandSelect = false;

    that.eventGroupService.sourceDevice = '';
    
    that.eventGroupService.selectedEventTemplates=[];
    that.eventGroupService.selectedCommandTemplates=[];
    that.eventGroupService.sourceDeviceTemplates=[];
    that.eventGroupService.sourceDeviceTemplates=[];
    that.eventGroupService.sourceDevices=[];

    that.eventGroupService.resetErrorMessage();
    that.eventGroupService.resetWarningMessage();
    that.eventGroupService.resetSuccessMessage();
    that.initEventGroup();
  }

  initEventGroup() {
    const that = this;
    that.eventGroup = {
      "creator": '', //that.iotService.getCreator(),
      "creatorAppId": '', //that.iotService.getCreatorAppId(),
      "realm": '', //that.iotService.getRealm(),
      'name': [{
        'lang': 'en_us',
        'text': ''
      }],
      'description': [{
        'lang': 'en_us',
        'text': ''
      }],
      //"tags":'', //that.getTags(),
      'sourceDeviceTemplates': [], //that.eventGroupService.getEventGroupSourceDeviceTemplates(),
      'sourceDevices': [], // that.eventGroupService.getEventGroupSourceDevices(),
      //'sourceCommandDeviceTemplates': [],
      'sourceCommandDevices': [],
      'active': true
    };
  }

  getTags() {
    const that=this;
    that.tags = [];
    if (that.eventGroupService.getEventGroupTags() != undefined && that.eventGroupService.getEventGroupTags().length > 0) {
      that.eventGroupService.getEventGroupTags().forEach((value) => {
        that.tags.push(value);
        that.eventGroup.tags.push(value);
      });
      // this.tags.push(this.deviceService.getDeviceTags());
    }
    return that.tags;
  }

  toggleActivate(active) {
    const that = this;
    if (active) {
      that.eventGroup.active = true;
    } else {
      that.eventGroup.active = false;
    }
  }

  eventGroupName(event: any) {
    const that = this;
    that.eventGroupNameInput = event.target.value;
    that.eventGroupService.setEventGroupName(event.target.value);
    if (that.eventGroupNameInput.length > 0) {
      that.eventGroupService.eventGroupNameSelect = true;
      that.eventGroup.name[0].text = that.eventGroupNameInput;
    }
    else {
      that.eventGroupNameInput.eventGroupNameSelect = false;
    }
  }

  eventGroupDescription(event: any) {
    const that = this;
    if(event.target.value != undefined){
      that.eventGroup.description[0].text = event.target.value;
    }
  }

  createEventGroup() {
    const that = this;
    that.eventGroup.creator= that.iotService.getCreator();
    that.eventGroup.creatorAppId=that.iotService.getCreatorAppId();
    that.eventGroup.realm=that.iotService.getRealm();

    if (that.tags.length>0)
    {
      that.eventGroup.tags = that.tags;
    }
    if(that.eventGroupService.getEventGroupSourceDevices()){
      that.eventGroup.sourceDevices=that.eventGroupService.getEventGroupSourceDevices();
    }
    if(that.eventGroupService.getEventGroupSourceDeviceTemplates()){
      that.eventGroup.sourceDeviceTemplates=that.eventGroupService.getEventGroupSourceDeviceTemplates();
    }
    if(that.eventGroupService.getEventGroupSourceCommandDevices()){
      that.eventGroup.sourceCommandDevices=that.eventGroupService.getEventGroupSourceCommandDevices();
    }
    if(that.eventGroupService.getEventGroupSourceCommandDeviceTemplates()!=undefined){
      that.eventGroup.sourceCommandDeviceTemplates=that.eventGroupService.getEventGroupSourceCommandDeviceTemplates();
    }
    if(that.eventGroupService.errorMessage=="" && that.eventGroupService.warningMessage=="")
    {
      that.eventGroupCreated=true;
    that.iotService.getCuiObjResponse().createEventSource({
      data: that.eventGroup
    }).then(function (response) {
      that.eventGroupService.successMessage="Event Group created successfully with name: "+ response.name[0].text;
      that.router.navigateByUrl("/eventGroups");
    }).fail(function (err) {
      that.eventGroupCreated=false;
      if (err.responseJSON && err.responseJSON.apiStatusCode) {
        that.eventGroupService.errorMessage = err.responseJSON.apiMessage;
        console.log(
          'Error Occured: Status: ' +
            err.responseJSON.status +
            ' Msg: ' +
            err.responseJSON.apiStatusCode
        );
      } else {
        console.log(
          'Something went wrong. Please check chrome console or check with dev team'
        );
      }
    });
  }
  }

  //viewEventGroup
  viewEventGroup(eventGroupId){
    const that=this;
    that.router.navigateByUrl("/eventSources/"+eventGroupId);  
  } 

  add(event: MatChipInputEvent): void {
    const that=this;
    that.tagClass = 'tagInput';
    const input = event.input;
    const value = event.value;
    const index = that.tagList.indexOf(value);
    that.tagList.push(value);
    if (index < 0) {
      if ((value || '').trim()) {
        that.tags.push(value);
        // Reset the input value
        if (input) {
          input.value = '';
        }
      }
    } else {
      that.tagClass = 'tagInput-duplicate';
    }
  }

  remove(tag: string): void {
    const that = this;
    const index = that.tags.indexOf(tag);
    if (index >= 0) {
      that.tags.splice(index, 1);
    }
  }

  setEventGroupStatus(){

  }

  resetRadio(val){
    const that = this;
    that.eventGroupCreate = val;
  }
}

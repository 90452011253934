import { Injectable } from '@angular/core';

@Injectable({
providedIn: 'root'
})
export class DeviceService {

constructor() { }

private selectedTemplate:any = undefined;
private attributedata:any = undefined;
private eventdata:any = undefined;
private commanddata:any = undefined;
private associationData:any = undefined;
private streamData:any = undefined;
private geofenceData:any = undefined;
private cloneTemplate:any = undefined;
private selectedDevice:any = undefined;
private devicedata:any = undefined;
templateName: string = '';
templateSelect : boolean =false;
deviceSelect : boolean =false;
streamSelect : boolean = false;
geofenceSelect:boolean=false;
attributeSelect : boolean = false;
eventSelect : boolean = false;
commandSelect:boolean=false;
deviceName:any = undefined;
deviceDescription:any = undefined;
deviceTags=[];
geofences = [];
deviceNameSelect : boolean = false;
templateNameSelect:boolean=false;
createFromScratch:boolean=false;
createGeofence:boolean=false;
createResource:boolean=false;
createAttribute:boolean=false;
createEvent:boolean=false;
createCommand:boolean=false;
mapLoad:boolean=false;
newTemplatename:string;
createNewTemplate=false;
warningMessage:string;
errorMessage :string;
successMessage : string;
createDeviceFromNewTemplate=false;
newTemplate:any;
displayCoordinates:boolean=false;
coordinateList:any=undefined;
requestCoordinateList:any=undefined;
duplicateAttributeId:string ='';
duplicateAttrFlag=false;
duplicateCommandId:string ='';
duplicateCommandFlag=false;
duplicateEventId:string ='';
duplicateEventFlag=false;
createNewDevice=false;
eventId:string;
commandId:string;
deviceId:string;
associationMap = new Map<String, String>();

setNewTemplate(template){
  this.newTemplate=template;
}

getNewTemplate(){
  return this.newTemplate;
}

resetWarningMessage(){
  this.warningMessage='';
}

resetSuccessMessage(){
  this.successMessage='';
}

resetErrorMessage(){
  this.errorMessage='';
}

setDeviceName(deviceName:any){
  this.deviceName = deviceName;
}

getDeviceName():any{
  return this.deviceName;
}

setTemplateName(templateName:any){
  this.newTemplatename = templateName
;
}

getTemplateName():any{
  return this.newTemplatename;
}

setDeviceDescription(deviceDescription:any){
  this.deviceDescription = deviceDescription;
}

getDeviceDescription():any{
  return this.deviceDescription;
}

setDeviceTags(deviceTags:any){
  this.deviceTags = deviceTags;
}

getDeviceTags():any{
  return this.deviceTags;
}

setSelectedTemplate(selectedTemplate:any){
  this.selectedTemplate = selectedTemplate;
}

getSelectedTemplate():any{
  return this.selectedTemplate;
}

setAttributeData(attributedata:any){
    this.attributedata = attributedata;
}

getAttributeData():any{
    return this.attributedata;
}

setEventData(eventdata:any){
  this.eventdata = eventdata;
}

getEventData():any{
  return this.eventdata;
}

setCommandData(commanddata:any){
this.commanddata = commanddata;
}

getCommandData():any{
return this.commanddata;
}

setAssociationData(associationData:any){
  this.associationData = associationData;
  }
  
getAssociationData():any{
  return this.associationData;
  }

setStreamData(streamData:any){
    this.streamData = streamData;
  }
    
getStreamData():any{
    return this.streamData;
  }

setGeofenceData(geofenceData:any){
    this.geofenceData = geofenceData;
  }
    
getGeofenceData():any{
    return this.geofenceData;
  }

  getGeofences(): any {
		return this.geofences;
	  }
	
	  setGeofences(geofences: any) {
		this.geofences = geofences;
	  }
setCoordinateList(coordinateList:any){
    this.coordinateList = coordinateList;
  }
    
getCoordinateList():any{
    return this.coordinateList;
  }
setRequestCoordinateList(requestCoordinateList:any){
    this.requestCoordinateList = requestCoordinateList;
  }
    
getRequestCoordinateList():any{
    return this.requestCoordinateList;
  }
setSelectedDevice(selectedDevice:any){
    this.selectedDevice = selectedDevice;
  }
  
getSelectedDevice():any{
    return this.selectedDevice;
  }

setDeviceData(devicedata:any){
  this.devicedata = devicedata;
}

getDeviceId():any{
  return this.deviceId;
}

setDeviceId(deviceId:any){
  this.deviceId=deviceId;
}
getDeviceData():any{
    return this.devicedata;
}
setEventId(eventId:string){
  this.eventId = eventId;
}

getEventId():string{
  console.log('eventTemplateId2: '+ this.eventId);
  return this.eventId;
}

setCommandId(commandId:string){
this.commandId = commandId;
}

getCommandId():string{
return this.commandId;
}
}

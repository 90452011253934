import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IotService } from '../service/iot.service';
//import finder from '../../assets/js/columnview.js';
//import container from '../../assets/js/columnview.js'
//import * as finder from '../../assets/js/columnview.js';
import { ViewEncapsulation } from '@angular/core';



declare var finder: any;
declare var container:any;
@Component({
  selector: 'app-column-view',
  templateUrl: './column-view.component.html',
  styleUrls: ['./column-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ColumnViewComponent implements OnInit {

  constructor(public iotService: IotService,public router:Router) {
    
   }
   ecosystem = [];
  ngOnInit(): void {
    this.loadColumnView();
  }

  goBack(){
    this.router.navigateByUrl("/ecosystems/"+this.iotService.ecoviewerId)
  }
  loadColumnView(){
    var id = this.iotService.ecoviewerId;
    const that = this;
    that.iotService
      .getCuiObjResponse().getEcoviewer({
      groupId: id
    }).then(function(ecosystem){
      
      that.ecosystem.push(ecosystem);
      
      document.title = document.title + ' - ' + that.ecosystem[0].label;
     
      

      const data = that.ecosystem;

      const options = {};
   
      var f = finder(container, data, options);

      f.on('leaf-selected', function(item) {
        // console.table(item);
      });
      f.on('interior-selected', function(item) {
        // console.log('interior selected', item);
      });
    });

  }
}

<mat-sidenav-container class="new-export" autosize>



    <mat-sidenav-content class="example-sidenav-content">
        <div class="export-data">

            <div class="form-data" *ngIf="eventGroupExportInfo" >

                <label for="Job Name"> {{ 'eventGroup.id' | translate}}</label>
                <input placeholder="required" id="Job Name" type="text" aria-label="Name"
                    [(ngModel)]="eventGroupExportInfo.resourceId">

                <label for="Export Job Name"> {{ 'export.job.name' | translate}}</label>
                <input placeholder="optional" id="Job Name" type="text" aria-label="Name"
                    [(ngModel)]="eventGroupExportInfo.jobName"><br><br>

                <button class="btn btn-primary" style="margin-left: 3px;" (click)="save()" [disabled]="eventGroupExportInfo.resourceId==''">{{
                    'execute.export' |
                    translate}}</button>
            </div>

        </div>

    </mat-sidenav-content>

</mat-sidenav-container>
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ImportExportService } from '../service/import-export.service';
import { IotService } from '../service/iot.service';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { faToggleOff, faToggleOn, faCheck, faExclamation, faExclamationTriangle, faTimes, faCheckCircle } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-rules-import-export',
  templateUrl: './rules-import-export.component.html',
  styleUrls: ['./rules-import-export.component.scss']
})
export class RulesImportExportComponent implements OnInit {

  faCheckIcon = faCheck;
  faToggleOffIcon = faToggleOff;
  faToggleOnIcon = faToggleOn;
  faCloseIcon = faTimes;
  faWarningIcon = faExclamationTriangle;
  faCriticalIcon = faExclamation;
  faSuccessIcon = faCheckCircle;

  supportLanguages = ['en'];
  testInput;
  uploadedFile: FileList;
  ruleImport;
  ruleExport;
  importExportRulesState = 1;


  constructor(public iotService: IotService, private translateService: TranslateService,
    private sideNavComponent: SideNavComponent, public importExportService: ImportExportService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
    this.sideNavComponent.menuChange('Dashboard', 'sub-type');
    this.sideNavComponent.menuChange('importExport.header', 'sub-type');
    this.sideNavComponent.menuChange('rules.header', 'sub-type');

  }

  ngOnInit(): void {
    this.importExportService.ruleImport = true;
    this.importExportService.ruleExport = false;
    this.initRuleExport();
    this.initRuleImport();
    this.importExportService.resetErrorMessage();
    this.importExportService.resetSuccessMessage();
    this.importExportService.resetWarningMessage();
  }
  initRuleExport() {
    this.ruleExport = {
      resourceId: "",
      jobName: "",
      resourceType: "RULE_POLICY"
    };
  }

  createFile(files: FileList) {
    this.uploadedFile = files;
  }

  initRuleImport() {
    this.ruleImport = {
      impJobName: "",
      packageFileData: "",
      fileName: ""
    }
  }

  executeImport(files) {

    const that = this;

    if (files === "" || files.name === "" || files.size <= 0) {

    } else {
      var reader = new FileReader();
      reader.onload = function () {
        that.testInput = this.result;
        console.log("testcontent" + that.testInput);
        that.populateData(files[0].name, that.testInput);
        that.makeImportCall(that.ruleImport);
      }
      reader.readAsText(files[0]);



    }

  }




  makeImportCall(ruleImport: any) {
    const that = this;
    that.iotService.getCuiObjResponse().executeRuleImport({
      data: ruleImport
    })
      .then(function (response) {
        var resp = response;
        that.importExportService.successMessage = resp.jobMessage + " RulePolicy ID: "+resp.resourceId;
      })
      .fail(function (err) {
        if (err.responseJSON && err.responseJSON.apiStatusCode && err.responseJSON.apiMessage) {
          that.importExportService.errorMessage = err.responseJSON.apiMessage;
          console.log(
            'Error Occured: Status: ' +
            err.responseJSON.status +
            ' Msg: ' +
            err.responseJSON.apiStatusCode
          );

        } else {
          that.importExportService.errorMessage = "Error occured while Importing";
          console.log(
            'Something went wrong. Please check chrome console or check with dev team'
          );
        }
      });
  }


  saveTextAsFile(data, filename) {

    if (!data) {
      console.error('Console.save: No data')
      return;
    }

    if (!filename) filename = 'console.json'

    var blob = new Blob([data], { type: 'text/plain' }),
      e = document.createEvent('MouseEvents'),
      a = document.createElement('a')
    // FOR IE:

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    }
    else {
      var e = document.createEvent('MouseEvents'),
        a = document.createElement('a');

      a.download = filename;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/plain', a.download, a.href].join(':');
      e.initEvent('click', true, false);
      a.dispatchEvent(e);
    }
  }


  executeExport() {
    const that = this;
    that.iotService.getCuiObjResponse().executeRuleExport({
      data: that.ruleExport
    })
      .then(function (response) {

        var resp = response;
      })
      .fail(function (err) {

        if (err.status == 200) {

          var fileName = that.ruleExport.resourceId + ".pkg";

          that.saveTextAsFile(err.responseText, fileName);
          that.importExportService.successMessage = "File downloaded successfully.";
        }
        else if (err.responseJSON && err.responseJSON.apiStatusCode && err.responseJSON.apiMessage) {
          that.importExportService.errorMessage = err.responseJSON.apiMessage;
          console.log(
            'Error Occured: Status: ' +
            err.responseJSON.status +
            ' Msg: ' +
            err.responseJSON.apiStatusCode
          );

        }
      });

  }


  populateData(filename, fileData) {
    const that = this;
    var encodedFileContent = fileData;
    that.ruleImport.packageFileData = fileData;
    that.ruleImport.fileName = filename;
  }

  onButtonToggle(event) {
    const that = this;
    console.log("onButtonToggle: " + event)
    if (event == "Import") {
      that.importExportRulesState = 1;
      console.log("onButtonToggle: " + that.importExportRulesState)
    }
    else if (event == "Export") {
      that.importExportRulesState = 2;
      console.log("onButtonToggle: " + that.importExportRulesState)
    }
    this.uploadedFile = undefined;
    this.initRuleExport();
    this.initRuleImport();
    this.importExportService.resetErrorMessage();
    this.importExportService.resetSuccessMessage();
    this.importExportService.resetWarningMessage();
  }

  // tabClick(tab) {
  //   console.log(tab.index);
  //   if (tab.index == 0) {
  //     console.log("Import");
  //     this.importExportService.ruleImport = true;
  //     this.importExportService.ruleExport = false;
  //   }
  //   else if (tab.index == 1) {
  //     console.log("Export");
  //     this.importExportService.ruleImport = false;
  //     this.importExportService.ruleExport = true;
  //   }
  //   this.uploadedFile = undefined;
  //   this.initRuleExport();
  //   this.initRuleImport();
  //   this.importExportService.resetErrorMessage();
  //   this.importExportService.resetSuccessMessage();
  //   this.importExportService.resetWarningMessage();

  // }
}

import { Component, OnInit } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER, I } from '@angular/cdk/keycodes';
import { faAngleUp, faAngleDown, faTimes, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { IotService } from '../service/iot.service';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { TranslateService } from '@ngx-translate/core';
import { TradingPartnersService } from '../service/tradingPartners.service';
import { faToggleOff, faToggleOn, faAngleLeft, faAngleRight, faCheck, faCopy, faAngleDoubleLeft, faAngleDoubleRight,faExclamation } from '@fortawesome/free-solid-svg-icons';
import { FromTPCodesService } from '../service/fromTPCodes.service';
import { ToTPCodesService } from '../service/toTPCodes.service';
import { ApplicationsService } from '../service/applications.service';
import { DocumentInfoService } from '../service/documentInfo.service';
import { map } from 'rxjs-compat/operator/map';


@Component({
  selector: 'app-trading-partner-detail',
  templateUrl: './trading-partner-detail.component.html',
  styleUrls: ['./trading-partner-detail.component.scss']
})
export class TradingPartnerDetailComponent implements OnInit {

  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;
  faCloseIcon = faTimes;
  faWarningIcon = faExclamationTriangle;
  failed = faTimes;
  faToggleOffIcon = faToggleOff;
  faToggleOnIcon = faToggleOn;
  faAngleLeftIcon = faAngleLeft;
  faAngleRightIcon = faAngleRight;
  faCheckIcon = faCheck;
  faCopyIcon = faCopy;
  faCriticalIcon=faExclamation;
  faAngleDoubleLeftIcon = faAngleDoubleLeft;
  faAngleDoubleRightIcon = faAngleDoubleRight;
 addnQueryData;

  
  warningMessage;
  tradingPartnerCreated = false;
  tagClass = 'tagInput';
  tradingPartner;
  name = '';
  description;
  // tags = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  supportLanguages = ['en'];
  tpRelationShip;
  tpApplication;
  auditFlag=false;
  auditRefreshFlag=false;
  resourceType='tradingPartner';
  tradingPartnerAudit=true;
  tpRelationshipsAudit=false;
  tpCodesAudit=false;
  tpCodesRelationshipAudit=false;
  tpRouteSourceAudit=false;

  
  constructor(public iotService: IotService, private translateService: TranslateService,
    private sideNavComponent: SideNavComponent,
    private router: Router,
    public tpService: TradingPartnersService,
    public fromCodesService: FromTPCodesService,
    public toCodesService: ToTPCodesService,
    public applicationService: ApplicationsService,
    public docInfoService: DocumentInfoService,
    private route: ActivatedRoute) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }





  ngOnInit(): void {
    this.resetData();
    this.iotService.createTradingPartner=true;
    this.route.params.subscribe(params => {
      //console.log(params['id']);
      var tradingPartnerId = params['id'];
      this.loadTradingPartner(tradingPartnerId);
      this.getUniqueTpIds(tradingPartnerId);
     
    });
    
  
  }




  loadTradingPartner(id){
    const that = this;
    that.tradingPartnerCreated=false;
    that.iotService.getCuiObjResponse().getTradingPartner({
      tradingPartnerId: id
    }).then(function (response) {
      console.log(response);
      that.tradingPartner = response;
      if(!that.tradingPartner.tags){
        that.tradingPartner["tags"]=[];
      }
     
      that.tpService.tradingPartnerId=response.id;
      that.tradingPartnerCreated=true;
      that.sideNavComponent.menuClear();
      that.sideNavComponent.menuChange('Dashboard', 'sub-type');
      that.sideNavComponent.menuChange('tradingPartners.header', 'sub-type');
      that.sideNavComponent.menuChange(that.tradingPartner.name[0].text, 'sub-type');
      that.tpService.tradingPartnerIds.push(response.id);
      // that.tpService.tradingPartnerIds.push(id); 
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }


  resetData() {
    const that = this;
    that.tpService.createTpCode = false;
    that.tpService.createResource = true;
    that.tpService.createCodeRelationShip = false;
    that.tpService.createTpRelationShip = false;
    that.tpService.createApplication = false;
    that.tpService.createRouteSource = false;
    that.tpService.viewCodeRelationShip=false;
    that.tpService.viewRouteSource=false;
    that.tpService.viewTPCode=false;
    that.tpService.resetMessage();
    that.tpService.setFromCode(undefined);
    that.tpService.setToCode(undefined);
    that.tpService.setTpApplications(new Array);
    that.tpService.setTradingPartner(undefined);
    that.tpService.setTpDocInfo(undefined);
    that.docInfoService.selection.clear();
    that.fromCodesService.selection.clear();
    that.toCodesService.selection.clear();
    that.applicationService.selection.clear();
    that.tpService.selection.clear();
    that.tpService.tradingPartnerIds=[];
    that.auditFlag=false;
    that.iotService.auditScriptFlag = false;
    that.tradingPartnerAudit=true;
    that.tpRelationshipsAudit=false;
    that.tpCodesAudit=false;
    that.tpCodesRelationshipAudit=false;
    that.tpRouteSourceAudit=false;

  }

  delete(){
    const that=this;
    that.iotService.getCuiObjResponse().deleteTradingPartner({
      tradingPartnerId: that.tradingPartner.id
    }).then(function (response) {
      console.log("Trading Partner deleted successfully, Id: ", that.tradingPartner.id);
     that.router.navigateByUrl("/tradingPartners");
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.tpService.errorMessage = err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }


  getUniqueTpIds(tradingPartnerId) {
    const that = this;
    that.iotService.getCuiObjResponse().getTPRelationships({
      qs: [['tradingPartnerId', tradingPartnerId]]
    }).then(function (response) {
      if(response.length>0){
      let tpIds = new Set();
      response.forEach(element => {
        tpIds.add(element.approverTradingPartnerId);
        tpIds.add(element.requestorTradingPartnerId);
      });
      that.tpService.tradingPartnerIds = Array.from(tpIds);
    }
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.warningMessage = err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
      if (err === null) {
        console.log("Error occured while creating trading partner");
      }
    });
  }

  getTradingPartner(){
    const that=this;
    that.iotService.getCuiObjResponse().getTradingPartner({
      tradingPartnerId: that.tradingPartner.id
    }).then(function (response) {
      that.tradingPartner=response;
     

    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.tpService.errorMessage = err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }

  add(event: MatChipInputEvent): void {

    this.tagClass = 'tagInput';
    const input = event.input;
    const value = event.value;
    const index = this.tradingPartner.tags.indexOf(value);
    if (index < 0) {
      if ((value || '').trim()) {
        if (  this.tradingPartner.tags != undefined && this.tradingPartner.tags.includes(value)) {
          if (input) {
            input.value = '';
          }
        }
        else {
          this.tradingPartner.tags.push(value);
          this.addTag(value);
          if (input) {
            input.value = '';
          }
        }
      }
    } else {
      this.tagClass = 'tagInput-duplicate';
    }
  }

  remove(tag: string): void {
    const that = this;
    that.tradingPartner.tags.forEach((value, index) => {
      if (value == tag) that.tradingPartner.tags.splice(index, 1);
    });
    that.removeTag(tag);

  }

  initTpRelationShip(tpId) {
    const that = this;
    that.tpRelationShip = {
      "creator": that.iotService.getCreator(),
      "creatorAppId": that.iotService.getCreatorAppId(),
      "realm": that.iotService.getRealm(),
      "requestorTradingPartnerId": that.tpService.tradingPartnerId,
      "approverTradingPartnerId": tpId
    }
  }

  toggleActive(status){
    this.tradingPartner.status=status;
    if(this.tradingPartnerCreated){
      if(this.tradingPartner.status=='ACTIVE'){
        this.activateTradingPartner(this.tradingPartner.id);
      }
      else{
        this.deactivateTradingPartner(this.tradingPartner.id);
      }
    }
  }
  deactivateTradingPartner(id: any) {
    const that=this;
    that.iotService.getCuiObjResponse().deactivateTradingPartner({
      tradingPartnerId: id
    }).then(function (response) {
      that.getTradingPartner();
      console.log("Trading Partner deactivated successfully, Id: ", id);
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.tpService.errorMessage = err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }
  activateTradingPartner(id: any) {
    const that=this;
    that.iotService.getCuiObjResponse().activateTradingPartner({
      tradingPartnerId: id
    }).then(function (response) {
      that.getTradingPartner();
      console.log("Trading Partner activated successfully, Id: ", id);
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.tpService.errorMessage = err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  saveTpRelationship() {

    const that = this;
    let count=0;

    if (that.tpService.getTradingPartnersList().length==0) {
      that.tpService.warningMessage = 'Please select a trading partner to create Trading Partner relationship';
    }
    else {
      that.tpService.getTradingPartnersList().forEach(element => {
        that.initTpRelationShip(element.id);
        that.iotService.getCuiObjResponse().saveTPRelationship({
          data: that.tpRelationShip
        }).then(function (response) {
          console.log("Trading Partner code relationshipc created successFully, Id: ", response.id);
          count++;
          if(count==that.tpService.getTradingPartnersList().length){
            that.tpService.createTpRelationShip = false;
            that.tpService.createResource = true;
            that.tpService.setTradingPartner(undefined);
            that.tpService.selection.clear();
            that.tpService.resetMessage();
            that.getUniqueTpIds(this.tradingPartner.id) ;
          }

  
        }).fail(function (err) {
          if (err.responseJSON.apiStatusCode) {
            that.tpService.errorMessage = err.responseJSON.apiMessage;
            console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
          } else {
            console.log("Something went wrong. Please check chrome console or check with dev team");
          }
        });
      });

    }

  }


  goBack() {
    const that=this;
    that.tpService.createTpRelationShip = false;
    that.tpService.createResource = true;
    that.tpService.setTradingPartner(undefined);
    that.tpService.selection.clear();
    that.tpService.resetMessage();
  }

  saveTpApplication() {

    const that = this;
    that.initTpApplication();
    that.addTpApplication();
    // that.iotService.getCuiObjResponse().getTpApplications({
    //   qs: [['tradingPartnerId', that.tpService.tradingPartnerId]]
    // }).then(function (response) {
    //   // that.tpApplication = response;
    //   if (response.length == 0) {
    //     that.addTpApplication();
    //     return;
    //   }
    //   else {
    //     that.updateTpApplication(response[0]);
    //     return;
    //   }
    // }).fail(function (err) {
    //   that.addTpApplication();
    // });


  }


  addTpApplication() {
    const that = this;
    that.iotService.getCuiObjResponse().saveTPApplication({
      tradingPartnerId:that.tpService.tradingPartnerId,
      data: that.tpApplication
    }).then(function (response) {
      console.log("Trading Partner application created successFully, Id: ", response.id);
      that.tpService.createApplication = false;
      that.tpService.createResource = true;
      that.applicationService.selection.clear();
      that.tpService.setTpApplications(new Array);
      that.tpService.resetMessage();

    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.tpService.errorMessage = err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  // updateTpApplication(tpApplication) {
  //   const that = this;
  //   let applicationId = that.tpService.getTpApplication().id;
  //   tpApplication.applications.push(applicationId);
  //   that.iotService.getCuiObjResponse().updateApplicationFromTP({
  //     applicationId: tpApplication.id,
  //     data: tpApplication
  //   }).then(function (response) {
  //     console.log("Trading Partner application updated successFully, Id: ", response.id);
  //     that.tpService.createApplication = false;
  //     that.tpService.createResource = true;
  //     that.applicationService.selection.clear();
  //     that.tpService.setTpApplication(undefined);
  //     that.tpService.resetMessage();

  //   }).fail(function (err) {
  //     if (err.responseJSON.apiStatusCode) {
  //       that.tpService.errorMessage = err.responseJSON.apiMessage;
  //       console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
  //     } else {
  //       console.log("Something went wrong. Please check chrome console or check with dev team");
  //     }
  //   });

  // }

  initTpApplication() {
    const that = this;
    let tpApplications = that.tpService.getTpApplications();
    let tpIds=[];
    tpApplications.forEach(element => {
      tpIds.push(element.id);
    });
    that.tpApplication = {
      "creator": that.iotService.getCreator(),
      "creatorAppId": that.iotService.getCreatorAppId(),
      "realm": that.iotService.getRealm(),
      "tradingPartnerId": that.tpService.tradingPartnerId,
      "applications": tpIds

    }
  }

  goBackApplication() {
    const that=this;
    that.tpService.createApplication = false;
    that.tpService.createResource = true;
    that.applicationService.selection.clear();
    that.tpService.setTpApplications(new Array);
    that.tpService.resetMessage();
  }


  addTag(tag){
    const that = this;
    that.iotService.getCuiObjResponse().createTpTag({
      tradingPartnerId:that.tradingPartner.id,
      tag: tag
    }).then(function (response) {
      console.log("Tag added to trading partner successfully "+ tag);

    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.tpService.errorMessage = err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }

  removeTag(tag){
    const that = this;
    that.iotService.getCuiObjResponse().deleteTPTag({
      tradingPartnerId:that.tradingPartner.id,
      tag: tag
    }).then(function (response) {
      console.log("Tag removed from trading partner successfully "+ tag);

    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.tpService.warningMessage = err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }

  ngOnDestroy(){
    this.resetData();
    this.iotService.createTradingPartner=false;
  }


  tabClick(tab) {
    this.tradingPartnerAudit=true;
    console.log(tab.index);
    if (tab.index == 0) {
      console.log("Trading Partner");
      this.resourceType='tradingPartner';
      this.tradingPartnerAudit=true;
      this.tpRelationshipsAudit=false;
      this.tpCodesAudit=false;
      this.tpCodesRelationshipAudit=false;
      this.tpRouteSourceAudit=false;
      this.auditRefreshFlag=!this.auditRefreshFlag;
    }
    else if (tab.index == 1) {
      console.log("TP Relationships");
      this.resourceType='tpRelationships';
      this.tradingPartnerAudit=false;
      this.tpRelationshipsAudit=true;
      this.tpCodesAudit=false;
      this.tpCodesRelationshipAudit=false;
      this.tpRouteSourceAudit=false;
      this.auditRefreshFlag=!this.auditRefreshFlag;
    }
    else if (tab.index == 2) {
      console.log("TP Codes");
      this.resourceType='tpCodes';  
      this.tradingPartnerAudit=false;
      this.tpRelationshipsAudit=false;
      this.tpCodesAudit=true;
      this.tpCodesRelationshipAudit=false;
      this.tpRouteSourceAudit=false;
      this.auditRefreshFlag=!this.auditRefreshFlag;
    }
    else if (tab.index == 3) {
      console.log("TP Code Relationship");
      this.resourceType='tpCodesRelationship';
      this.tradingPartnerAudit=false;
      this.tpRelationshipsAudit=false;
      this.tpCodesAudit=false;
      this.tpCodesRelationshipAudit=true;
      this.tpRouteSourceAudit=false;
      this.auditRefreshFlag=!this.auditRefreshFlag;
    }
    else if (tab.index == 4) {
      console.log("TP Route Source");
      this.resourceType='tpRouteSource';
      this.tradingPartnerAudit=false;
      this.tpRelationshipsAudit=false;
      this.tpCodesAudit=false;
      this.tpCodesRelationshipAudit=false;
      this.tpRouteSourceAudit=true;
      this.auditRefreshFlag=!this.auditRefreshFlag;
    }
  }
  auditTPA(){
 
    this.auditFlag=true;
    this.auditRefreshFlag=!this.auditRefreshFlag;
     
  }
  closeAudit(){
    this.auditFlag=false;
     

  }

  goBackAuditList() {
    const that=this;
    that.iotService.auditScriptFlag = false;
    that.iotService.auditscript="";
    that.auditFlag = true;
  }


}

<mat-sidenav-container class="new-trusted-authority" autosize>

    <!-- For new template creation -->
    <mat-sidenav class="midNav-container" #sidenav mode="side" disableClose="true" opened="true">

        <div class="alert success" *ngIf="successMessage">
            <h5>
                <fa-icon [icon]="faSuccessIcon"></fa-icon>
                <span>{{successMessage}}</span> 
                <button class="alert-close-btn">
                <fa-icon (click)=resetSuccessMessage() [icon]="faCloseIcon"></fa-icon></button>
            </h5>
        </div>
        <div class="alert critical" *ngIf="errorMessage">
            <h5>
                <fa-icon [icon]="faCriticalIcon"></fa-icon>
                <span>{{errorMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=resetSuccessMessage() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>

        </div>
        <div class="alert warning" *ngIf="warningMessage">
            <h5>
                <fa-icon [icon]="faWarningIcon"></fa-icon>
                <span>{{warningMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)="resetWarningMessage()" [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>
        <div class="data">
            <h1 style="margin-left: -15px;"> {{'create authority' | translate}}</h1>
           <p>{{'trustedAuthorities.definition' | translate}}</p>
            <div>
                <button [disabled]="securityService.certificateData=='' || trustedAuthorityCreated" class="btn btn-primary" (click)="save()">{{ 'save' | translate}}</button>
            </div>
        </div>



    </mat-sidenav>
    <mat-sidenav-content class="example-sidenav-content">
        <ng-container>
            <app-certificate-data></app-certificate-data>
        </ng-container>
    </mat-sidenav-content>

</mat-sidenav-container>
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DocumentInfoService } from '../service/documentInfo.service';
import { FromTPCodesService } from '../service/fromTPCodes.service';
import { IotService } from '../service/iot.service';
import { StreamService } from '../service/stream.service';
import { ToTPCodesService } from '../service/toTPCodes.service';
import { TradingPartnersService } from '../service/tradingPartners.service';

@Component({
  selector: 'app-route-relationship-detail',
  templateUrl: './route-relationship-detail.component.html',
  styleUrls: ['./route-relationship-detail.component.scss']
})
export class RouteRelationshipDetailComponent implements OnInit {

  byTp = false;
  byTpCodes = false;
  supportLanguages = ['en'];
  workingTradingPartnerRouteSource;
  routeSourceId;
  fromTpCodeLoaded;
  toTpCodeLoaded;
  docInfoLoaded;
  senderOwnerLoaded=false;

  constructor(public iotService: IotService, public tpService: TradingPartnersService,
    private translateService: TranslateService,
    public fromCodesService: FromTPCodesService,
    public toCodesService: ToTPCodesService,
    public docInfoService: DocumentInfoService,
    public streamService: StreamService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }

  ngOnInit(): void {

    this.routeSourceId = this.tpService.getRouteSourceRelationShipId();
    this.streamService.setDocinfoResponse(undefined);
    this.loadTpRouteSource(this.routeSourceId);
  }



  loadTpRouteSource(routeSourceId) {

    const that = this;
    that.iotService.getCuiObjResponse().getRoutingSourceRelationship({
      routingSourceId: routeSourceId
    }).then(function (response) {
      that.workingTradingPartnerRouteSource = response;
      if (that.workingTradingPartnerRouteSource.toCodeId != undefined || that.workingTradingPartnerRouteSource.fromCodeId != undefined) {
        that.byTpCodes = true;
        if (that.workingTradingPartnerRouteSource.toCodeId) {
          that.loadFromCode(that.workingTradingPartnerRouteSource.toCodeId);
        }
        else{
          that.fromTpCodeLoaded=true;
        }
        if (that.workingTradingPartnerRouteSource.fromCodeId) {

          that.loadToCode(that.workingTradingPartnerRouteSource.fromCodeId)
        }
        else{
          that.toTpCodeLoaded=true;
        }
        if(that.workingTradingPartnerRouteSource.documentInfoId){
          that.loadDocumentInfo(that.workingTradingPartnerRouteSource.documentInfoId)
          
        }
        else{
          that.docInfoLoaded=true;
        }
      } else {
        that.byTp = true;
        if(that.workingTradingPartnerRouteSource.documentInfoId){
          that.loadDocumentInfo(that.workingTradingPartnerRouteSource.documentInfoId)
        }
        else{
          that.docInfoLoaded=true;
        }
        if(that.workingTradingPartnerRouteSource.senderOwnerId){
          that.loadTradingPartner(that.workingTradingPartnerRouteSource.senderOwnerId);
        }
        else{
          that.senderOwnerLoaded=true;
        }
      }

    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.tpService.warningMessage = err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }

  loadFromCode(fromCode) {
    const that = this;
    that.iotService.getCuiObjResponse().getTradingPartnerCodeById({
      codeId: fromCode
    }).then(function (response) {

      that.tpService.setFromCode(response);
      that.fromTpCodeLoaded = true;
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }

  loadToCode(toCode) {
    const that = this;
    that.iotService.getCuiObjResponse().getTradingPartnerCodeById({
      codeId: toCode
    }).then(function (response) {

      that.tpService.setToCode(response);
      that.toTpCodeLoaded = true;
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }

  loadDocumentInfo(docInfoId: any) {
    const that = this;
    that.iotService.getCuiObjResponse().getDocumentInfoById({
      docInfoId: docInfoId
    }).then(function (response) {
      console.log(response);
      that.tpService.setTpDocInfo(response);
      that.docInfoLoaded=true;
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  loadTradingPartner(id){
    const that = this;
    that.iotService.getCuiObjResponse().getTradingPartner({
      tradingPartnerId: id
    }).then(function (response) {
      console.log(response);
      that.tpService.setTradingPartner(response);
      that.senderOwnerLoaded=true;
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  save() {
    const that = this;

    if (that.byTp) {
      if (that.tpService.getTradingPartner() == undefined || that.tpService.getTpDocInfo() == undefined) {
        that.tpService.warningMessage = "Please select a trading partner and Document info from the list";
      }
      else{
        that.populateData();
        that.updateTpRouteResource(that.workingTradingPartnerRouteSource.id);
      }
    }
    else if (this.byTpCodes) {
      if (that.tpService.getFromCode() == undefined || that.tpService.getTpDocInfo() == undefined || that.tpService.getToCode() == undefined) {
        that.tpService.warningMessage = "Please select a From code, To code, and Document info from the list";
      }
      else{
        that.populateData();
        that.updateTpRouteResource(that.workingTradingPartnerRouteSource.id);
      }
     
    }
      
  }

  populateData() {
    if (this.byTp) {
      this.workingTradingPartnerRouteSource.senderOwnerId = this.tpService.getTradingPartner().id;
      this.workingTradingPartnerRouteSource.documentInfoId = this.tpService.getTpDocInfo().id;
    }
    else {
      this.workingTradingPartnerRouteSource.documentInfoId = this.tpService.getTpDocInfo().id;
      this.workingTradingPartnerRouteSource.fromCodeId = this.tpService.getFromCode().id;
      this.workingTradingPartnerRouteSource.toCodeId = this.tpService.getToCode().id;
    }
  }

  updateTpRouteResource(routeResourceId) {
    const that = this;
    that.iotService.getCuiObjResponse().updateTPRouteSource({
      routingSourceId:routeResourceId,
      data: that.workingTradingPartnerRouteSource
    }).then(function (response) {
      console.log("Trading Partner application updated successFully, Id: ", response.id);
      that.tpService.createResource = true;
      that.tpService.viewRouteSource = false;
      that.tpService.resetMessage();
      that.tpService.selection.clear();
      that.tpService.setTradingPartner(undefined);
      that.tpService.setFromCode(undefined);
      that.tpService.setToCode(undefined);
      that.tpService.setTpDocInfo(undefined);


    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.tpService.errorMessage = err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }
  addTPRoutResource() {
    const that = this;
    that.iotService.getCuiObjResponse().saveTPRouteSource({
      data: that.workingTradingPartnerRouteSource
    }).then(function (response) {
      console.log("Trading Partner application created successFully, Id: ", response.id);
      const that = this;
      that.tpService.createResource = true;
      that.tpService.viewRouteSource = false;
      that.tpService.resetMessage();
      that.tpService.selection.clear();
      that.tpService.setTradingPartner(undefined);
      that.tpService.setFromCode(undefined);
      that.tpService.setToCode(undefined);
      that.tpService.setTpDocInfo(undefined);

    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.tpService.errorMessage = err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }

  goBack() {
    const that = this;
    that.tpService.createResource = true;
    that.tpService.viewRouteSource = false;
    that.tpService.resetMessage();
    that.tpService.setTradingPartner(undefined);
    that.tpService.selection.clear();
    that.tpService.setFromCode(undefined);
    that.tpService.setToCode(undefined);
    that.tpService.setTpDocInfo(undefined);

  }


}

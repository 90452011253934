import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IotService } from 'src/app/service/iot.service';
import { SideNavComponent } from 'src/app/side-nav/side-nav.component';
import { faToggleOff, faToggleOn, faAngleLeft, faAngleRight, faCheck, faCopy, faTimes, faAngleDoubleLeft, faAngleDoubleRight, faExclamationTriangle, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { SecurtiyService } from 'src/app/service/security.service';


@Component({
  selector: 'app-key-bundle-detail',
  templateUrl: './key-bundle-detail.component.html',
  styleUrls: ['./key-bundle-detail.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  }
})
export class KeyBundleDetailComponent implements OnInit {


  failed = faTimes;
  faToggleOffIcon = faToggleOff;
  faToggleOnIcon = faToggleOn;
  faAngleLeftIcon = faAngleLeft;
  faAngleRightIcon = faAngleRight;
  faCheckIcon = faCheck;
  faCopyIcon = faCopy;
  faAngleDoubleLeftIcon = faAngleDoubleLeft;
  faAngleDoubleRightIcon = faAngleDoubleRight;
  faCloseIcon = faTimes;
  faWarningIcon = faExclamationTriangle;
  faCriticalIcon=faExclamation;

  icon = this.faToggleOnIcon;

  supportLanguages = ['en'];
  authorities: any;
  index = -1;
  keyBundle;
  certificate;
  certificateColumns = ["IssuerCA", "Serial", "Status"];
  authorityColumns = ["Subject", "Hash", "Expiration", "Revoked", "Creation"];
  certificates;
  currentPage = 1;
  pageSize = 5;
  trustedAuthorities;
  // trustedAuthority;
  // currentAuthority=0;
  authority;
  certficateData;
  trustedPrivateKey;
  privateKeyLoaded = true;
  successMessage;
  errorMsg;
  showCertificates=true;
  showAuthorities=true;

  constructor(private router: Router, private route: ActivatedRoute, private iotService: IotService,
    private sideNavComponent: SideNavComponent, private translateService: TranslateService, public securityService:SecurtiyService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }


  onClick(event) {
    this.privateKeyLoaded = true;
    this.errorMsg="";

  }

  ngOnInit(): void {

    this.securityService.resetWarningMessages();
    this.route.params.subscribe(params => {
      //console.log(params['id']);
      var keyBundleId = params['id'];
      this.loadKeyBundle(keyBundleId);
    });
    
    this.loadCertificates();
    this.loadTrustedAuthorities();

  }

  loadListCertificates(){
    this.showCertificates=!this.showCertificates;
  }

  loadListAuthorities(){
    this.showAuthorities=!this.showAuthorities;
  }


  viewTrustedAuthorities(selectedId) {
    this.router.navigateByUrl("/trustedAuthorities/" + selectedId);
  }
  viewTrustedCertificates(selectedId) {
    this.router.navigateByUrl("/trustedCertificates/" + selectedId);
  }


  loadKeyBundle(keyBundleId: any) {

    const that = this;
    this.iotService.getCuiObjResponse().getKeyBundle({
      keyBundleId: keyBundleId
    }).then(function (response) {
      console.log(response);
      that.securityService.setKeyBundle(response);
      that.keyBundle = that.securityService.getKeyBundle();
      that.certificate = that.keyBundle.trustedCertificate;
      that.authorities = that.keyBundle.trustedAuthorities;
      that.securityService.currentAuthority=0;
      that.securityService.trustedAuthority=that.keyBundle.trustedAuthorities[0];
      // that.trustedAuthority=that.securityService.trustedAuthority;
      that.sideNavComponent.menuClear();
      that.sideNavComponent.menuChange('Dashboard', 'sub-type');
      that.sideNavComponent.menuChange('certificates.header', 'sub-type');
      that.sideNavComponent.menuChange('keyBundles.header', 'sub-type');
      that.sideNavComponent.menuChange(that.keyBundle.name, 'sub-type');
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  addAuthority(authority: any) {
    this.index = this.findById(this.keyBundle.trustedAuthorities, authority);
    if (this.index < 0) {
      this.keyBundle.trustedAuthorities.push(authority);
    }
  }

  removeAuthority(authority: any) {
    this.index = this.findById(this.keyBundle.trustedAuthorities, authority);
    if (this.index >= 0) {
      this.keyBundle.trustedAuthorities.splice(this.index, 1);
    }

  }

  updateKeyBundle(keyBundleId: any) {
    const that = this;
    that.iotService.getCuiObjResponse().updateKeyBundle({
      keyBundleId: keyBundleId,
      data: this.keyBundle
    }).then(function (response) {
      console.log(response);
      that.securityService.setKeyBundle(response);
      that.keyBundle = that.securityService.getKeyBundle();
      that.certificate = that.keyBundle.trustedCertificate;
      that.authorities = that.keyBundle.trustedAuthorities;
      that.successMessage = "KeyBundle updated successfully";
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.securityService.warningMessage=err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        that.securityService.warningMessage="Error occured while updating  keybundle"
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }

  findById(collection, value) {
    let index = -1;
    let filteredObj = collection.find(function (element, i) {
      if (element.id === value.id) {
        index = i;
        return i;
      }
    });
    return index;

  }

  certificateNextPage() {
    this.currentPage++;
    this.loadCertificates();
  }

  certificatePrevPage() {
    this.currentPage--;
    if (this.currentPage < 1) {
      this.currentPage = 1;
    }
    this.loadCertificates();
  }

  resetPage() {
    this.currentPage = 1;
  }

  authorityNextPage() {
    this.currentPage++;
    this.loadTrustedAuthorities();
  }

  authorityPrevPage() {
    this.currentPage--;
    if (this.currentPage < 1) {
      this.currentPage = 1;
    }
    this.loadTrustedAuthorities();
  }


  disableKeyBundle(keyBundleId: any) {
    const that = this;
    that.iotService.getCuiObjResponse().deactivateKeyBundle({
      keyBundleId: keyBundleId
    }).then(function (response) {
      // console.log(response);
      that.loadKeyBundle(keyBundleId);
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }


  enableKeyBundle(keyBundleId: any) {
    const that = this;
    that.iotService.getCuiObjResponse().activateKeyBundle({
      keyBundleId: keyBundleId
    }).then(function (response) {
      // console.log(response);
      that.loadKeyBundle(keyBundleId);
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }

  loadCertificates() {
    const that = this;
    that.iotService.getCuiObjResponse().getx509Certificates({
      qs: [['pageSize', that.pageSize], ['page', that.currentPage]]
    }).then(function (response) {
      that.certificates = response;
      console.log(response);
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }


  loadTrustedAuthorities() {
    const that = this;
    that.iotService.getCuiObjResponse().getx509Authorities({
      qs: [['pageSize', that.pageSize], ['page', that.currentPage]]
    }).then(function (response) {
      that.trustedAuthorities = response;
      console.log(response);
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }


  loadPrivateKey(certificate: any) {
    const that = this;
    var certificateId = certificate.id;
    that.privateKeyLoaded = true;
    that.iotService.getCuiObjResponse().getTrustedPrivateKeys({
      qs: [['parentCertificateId', certificateId]]
    }).then(function (response) {
      console.log(response);
      if (response.length > 0) {
        that.trustedPrivateKey = response[0];
        that.privateKeyLoaded = that.trustedPrivateKey.id;
        that.keyBundle.trustedCertificate = certificate;
        console.log(that.keyBundle);
      }
      else {
        that.privateKeyLoaded = false;
      }

    });

  }


  loadAuthority(authorityId: any) {
    const that = this;

    this.iotService.getCuiObjResponse().getx509Authority({
      authorityId: authorityId
    }).then(function (response) {
      console.log(response);
      that.authority = response;
      that.addAuthority(that.authority);
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }


  prevAuthority(){
    let currentAuthority = this.securityService.currentAuthority-1;
    if (currentAuthority < 0) { currentAuthority= 0; }  
    const that = this;
    that.securityService.currentAuthority=currentAuthority;
    that.securityService.trustedAuthority=that.keyBundle.trustedAuthorities[currentAuthority];
    // that.trustedAuthority=that.securityService.trustedAuthority;
  }

  nextAuthoritypage(){
    let currentAuthority = this.securityService.currentAuthority+1;
    if (currentAuthority == this.authorities.length) { 
      currentAuthority= this.authorities.length-1; 
    }  
    const that = this;
    console.log("nextStreamPage"+currentAuthority);
    that.securityService.currentAuthority=currentAuthority;
    that.securityService.trustedAuthority=that.keyBundle.trustedAuthorities[currentAuthority];
    // that.trustedAuthority=that.securityService.trustedAuthority;
  }


}

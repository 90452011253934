<mat-sidenav-container class="new-export" autosize>

    <mat-sidenav class="midNav-container" #sidenav mode="side" disableClose="true" opened="true">

        <div class="alert success" *ngIf="importExportService.successMessage">
            <h5>
                <fa-icon [icon]="faSuccessIcon"></fa-icon>
                <span>{{importExportService.successMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=importExportService.resetSuccessMessage() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>
        <div class="alert critical" *ngIf="importExportService.errorMessage">
            <h5>
                <fa-icon [icon]="faCriticalIcon"></fa-icon>
                <span>{{importExportService.errorMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=importExportService.resetErrorMessage() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>
        <div class="alert warning" *ngIf="importExportService.warningMessage">
            <h5 style="font-size: 12px;">
                <fa-icon [icon]="faWarningIcon"></fa-icon>
                <span>{{importExportService.warningMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)="importExportService.resetWarningMessage()" [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>
        <div class="description">
            <div *ngIf="importExportService.ruleImport && importExportRulesState==1">
                <b>{{'description' | translate}}{{':' | translate}}</b>
                <p>{{'select.configFile.exportOption' | translate}}
                </p>
            </div>

            <div *ngIf="importExportRulesState==2">
                <b>{{'description' | translate}}{{':' | translate}}</b>
                <p>{{'export.rulePolicy.resource.id' | translate}}
                </p>
            </div>
        </div>
        <div style="margin-left:15px;">
            <button class="btn" [ngClass]="importExportRulesState==1 ? 'btn-primary': 'btn-secondary'"
                (click)="importExportRulesState=1; onButtonToggle('Import')" value="Import">{{'import' | translate}}</button>&nbsp;&nbsp;
            <button class="btn" [ngClass]="importExportRulesState==2 ? 'btn-primary': 'btn-secondary'"
                (click)="importExportRulesState=2; onButtonToggle('Export')" value="Export">{{'export' | translate}}</button>&nbsp;&nbsp;
        </div>

    </mat-sidenav>

    <mat-sidenav-content class="example-sidenav-content">
        <div class="export-data">

            <!-- <div class="tab-holder">
                <mat-tab-group (selectedTabChange)="tabClick($event)" [selectedIndex]="0">

                    <mat-tab label="Import"></mat-tab>
                    <mat-tab label="Export"></mat-tab>
                </mat-tab-group>
            </div> -->

            <div class="form-data" *ngIf="importExportService.ruleImport && importExportRulesState==1">
                <label for="Package File"> {{ 'package.file' | translate}}</label>
                <input type="file" name="files" id="file" class="inputfile" (change)="createFile($event.target.files)">

                <label for="Job Name"> {{ 'job.name' | translate}}</label>
                <input placeholder="optional" id="Job Name" type="text" aria-label="Name"
                    [(ngModel)]="ruleImport.jobName">
                <br>
                <br>

                <button class="btn btn-primary" style="margin-left: 3px;" [disabled]="uploadedFile==undefined"
                    (click)="executeImport(uploadedFile)">{{ 'execute.import' |
                    translate}}</button>
            </div>

            <div class="form-data" *ngIf="importExportRulesState==2">
                <label for="Job Name"> {{ 'rule.policy.id' | translate}}</label>
                <input placeholder="required" id="Job Name" [(ngModel)]="ruleExport.resourceId" type="text"
                    aria-label="Name">

                <label for="Job Name"> {{ 'export.job.name' | translate}}</label>
                <input placeholder="optional" id="Job Name" type="text" [(ngModel)]="ruleExport.jobName"
                    aria-label="Name">

                <br>
                <br>

                <button class="btn btn-primary" style="margin-left: 3px;" [disabled]="ruleExport.resourceId==''" (click)="executeExport()">{{
                    'execute.export' |
                    translate}}</button>
            </div>




        </div>
    </mat-sidenav-content>

</mat-sidenav-container>
import { Injectable } from '@angular/core';
import { SelectionModel} from '@angular/cdk/collections';
import { BaseListService } from './BaseList.service';
import { IotService } from '../service/iot.service'; 
@Injectable({ 
	  providedIn: 'root' 
})

export abstract class ListTradingPartnersService extends BaseListService {
	 constructor(public iotService:IotService) {
		 super(iotService);
	 }
} 

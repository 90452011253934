<article class="create-geofence">
    <header *ngIf="!iotService.createGeofence">
        <h1>{{ 'create.new.geofence' | translate}}</h1>
    </header>
    
    
    <div class=form>
    
        <label for="geofenceName">{{'name' | translate}}</label>
        <input placeholder="{{'required' | translate}}" [(ngModel)]="geofenceName" id="geofence_name" type="text" (click)="errorMsgName=''" name="geofenceName">
        <div class="row sub-heading">
            <span class="error" *ngIf="nameLengthError">{{errorMsgName}}</span>
        </div>
    
        <label for="geofenceDescription">{{'description' | translate}}</label>
       
       <textarea placeholder="{{'optional' | translate}}" [(ngModel)]="geofenceDescription" id="geofence_desc" type="text" name="geofenceDescription" aria-label="Description"></textarea>
    
        <label for="device-tags">{{'tags' | translate}}</label>
        <mat-chip-list #tagList aria-label="tags">
            <mat-chip class="ot-chip" *ngFor="let tag of tags" [selectable]="selectable" [removable]=true (removed)="remove(tag)">
                {{tag}}
                <button class="ot-chip__close" matChipRemove></button>
            </mat-chip>
            <input [ngClass]="tagClass" placeholder="{{'add_a_tag' | translate}}" #tagInput [matChipInputFor]="tagList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="add($event)">
        </mat-chip-list>
    
        <label for="radius">{{ 'max.search.radius' | translate}}</label>
        <input placeholder="{{'required' | translate}}" id="radius" [(ngModel)]="maxSearchRadius" type="text">
        <div class="row sub-heading">
            <span class="error" *ngIf="maxSearchLengthRadius">{{errorMsgRadius}}</span>
        </div>
    
    </div>
    
    <div class="pull-right">
        <button class="btn btn-secondary"  (click)="removeGeofence()" type="submit">{{ 'go.back' | translate}}</button>&nbsp;
        <button *ngIf="!deviceService.displayCoordinates" class="btn btn-secondary" (click)=loadMap()>{{ 'add.coordinates' | translate}}</button>
        <button *ngIf="deviceService.displayCoordinates" [disabled]="geofenceCreated" class="btn btn-secondary" (click)=saveGeofencing()>{{ 'save' | translate}}</button>
    </div>
    <br class="clear">
</article>
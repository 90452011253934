<mat-sidenav-container class="new-governance" autosize>

   <mat-sidenav class="midNav-container" #sidenav mode="side" disableClose="true" opened="true">
        <div class="alert critical" *ngIf="messageGovernancesService.errorMessage">
            <h5>
                <fa-icon [icon]="faCriticalIcon"></fa-icon>
                <span>{{messageGovernancesService.errorMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=messageGovernancesService.resetErrorMessage() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>

        </div>

        <div class="alert success" *ngIf="messageGovernancesService.successMessage">
            <h5>
                <fa-icon [icon]="faSuccessIcon"></fa-icon>
                <span>{{messageGovernancesService.successMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=messageGovernancesService.resetSuccessMessage() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>
         <div class="data" *ngIf=messageGovernance>
            <div style="margin-left: -10px;">
                <app-copy-resource-id></app-copy-resource-id>
            </div>
            <h1> {{'view.message.governance' | translate}}</h1>

            <mat-nav-list class="without-template user-selections">

                <ng-container *ngIf="messageGovernance.trigger=='COMMAND'">
                    <div>
                        <h5>{{'command.template' | translate}}</h5>
                         <p *ngIf="messageGovernancesService.commandTemplates.length == 0">
                             {{'select.commandTemplates.list' | translate}}
                        </p>
                        <ul>
                            <li *ngFor="let command of messageGovernancesService.commandTemplates">
                                <fa-icon [icon]="faCheckIcon"></fa-icon>{{command.name}} 
                            </li>
                        </ul>
                    </div>
                </ng-container> 
                 <ng-container *ngIf="messageGovernance.trigger=='DEVICE_EVENT' || messageGovernance.trigger=='COMMAND'">
                    <div *ngIf="messageGovernancesService.devices.length> 0">
                        <h5>{{'devices' | translate}}</h5>
                         <p *ngIf="messageGovernancesService.devices.length == 0">
                             {{'select.devices.fromlist' | translate}}
                        </p>
                        <ul>
                            <li *ngFor="let device of messageGovernancesService.devices">
                                <fa-icon [icon]="faCheckIcon"></fa-icon> {{device.name[0].text}}
                            </li>
                        </ul>
                    </div>
                </ng-container> 

                <ng-container *ngIf="messageGovernance.trigger=='DEVICE_EVENT'">
                    <div *ngIf="messageGovernancesService.getEvents().length >0">
                        <h5>{{'events' | translate}}</h5>
                        <p *ngIf="messageGovernancesService.getEvents().length == 0">
                            {{'select.events.fromlist' | translate}}
                        </p>
                        <ul>
                            <li *ngFor="let event of messageGovernancesService.events">
                                <fa-icon [icon]="faCheckIcon"></fa-icon> {{ event.name }}
                            </li>
                        </ul>
                    </div>
                </ng-container> 

                <ng-container *ngIf="messageGovernance.trigger=='DEVICE_EVENT' || messageGovernance.trigger =='TRADING_PARTNER_MESSAGE'">
                    <div *ngIf="messageGovernancesService.documentInfos.length > 0">

                        <h5>{{'documentInfo' | translate}}</h5>
                        <p *ngIf="messageGovernancesService.documentInfos.length == 0">
                            {{'select.documentInfos.fromlist' | translate}}
                        </p>
                        <ul>
                            <li *ngFor="let documentInfo of messageGovernancesService.documentInfos">
                                <fa-icon [icon]="faCheckIcon"></fa-icon> {{ documentInfo.name[0].text }}
                            </li>
                        </ul>
                    </div>
                </ng-container> 

                <ng-container *ngIf="messageGovernance.trigger =='TRADING_PARTNER_MESSAGE'">
                    <div *ngIf="messageGovernancesService.fromCodeValue.length > 0">
                        <h5>{{'sender.tpCode' | translate}}</h5>
                        <p *ngIf="messageGovernancesService.fromCodeValue.length == 0">
                            {{'select.senderTPcodes' | translate}}
                        </p>
                        <ul>
                            <li *ngFor="let fromCode of messageGovernancesService.fromCodeValue">
                                <fa-icon [icon]="faCheckIcon"></fa-icon> {{ fromCode.code }}
                            </li>
                        </ul>
                    </div>
                </ng-container> 


                <ng-container *ngIf="messageGovernance.trigger=='TRADING_PARTNER_MESSAGE'">
                    <div *ngIf="messageGovernancesService.toCodeValue.length > 0">
                        <h5>{{'receiver.tpCode' | translate}}</h5>
                        <p *ngIf="messageGovernancesService.toCodeValue.length == 0">
                            {{'select.receiverTPcodes' | translate}}
                        </p>
                        <ul>
                            <li *ngFor="let toCode of messageGovernancesService.toCodeValue">
                                <fa-icon [icon]="faCheckIcon"></fa-icon> {{ toCode.code }}
                            </li>
                        </ul>
                    </div>
                </ng-container> 


                <ng-container>
                    <div *ngIf="messageGovernancesService.senderStreams.length > 0">
                        <h5>{{'sender.streams' | translate}}</h5>
                        <p *ngIf="messageGovernancesService?.senderStreams.length == 0">
                            {{'select.senderStreams' | translate}}
                        </p>
                        <ul>
                            <li *ngFor="let senderStream of messageGovernancesService.senderStreams">
                                <fa-icon [icon]="faCheckIcon"></fa-icon> {{ senderStream.name[0].text }}
                            </li>
                        </ul>
                    </div>
                </ng-container> 


                <ng-container>
                    <div *ngIf="messageGovernancesService.receiverStreams.length > 0">
                        <h5>{{'receiver.streams' | translate}}</h5>
                        <p *ngIf="messageGovernancesService?.receiverStreams.length == 0">
                            {{'select.receiverStreams' | translate}}
                        </p>
                        <ul>
                            <li *ngFor="let receiverStream of messageGovernancesService.receiverStreams">
                                <fa-icon [icon]="faCheckIcon"></fa-icon> {{ receiverStream.name[0].text }}
                            </li>
                        </ul>
                    </div>
                </ng-container> 
                <ng-container>
                <h5>{{ 'status' | translate}}</h5>
                <div *ngIf="messageGovernance.state == 'ACTIVE'" style="margin-left: -13px;">
                    <button class="toggle-on toggle-switch" (click)="deactivateMessageGovernance(messageGovernanceId)">
                        <fa-icon [icon]="faCheckIcon" class="fa-2x"></fa-icon>
                        <div class="toggle-on-btn"></div>
                    </button>
                </div>
                <div *ngIf="messageGovernance.state == 'INACTIVE'" style="margin-left: -13px;">
                    <button class="toggle-off toggle-switch" (click)="activateMessageGovernance(messageGovernanceId)">
                        <div class="toggle-off-btn"></div>
                    </button>
                </div>
            </ng-container>

            </mat-nav-list>
            <br>
            <br>
            <ng-container class="button-row " >
            <button class="btn btn-primary" style="margin: 5px; margin-left: 10px;"
            (click)="updateMessageGovernance(messageGovernanceId)">{{ 'update' |
            translate}}</button>     
  
            <button class="btn btn-delete pull-right" style="margin: 5px;" (click)="deleteMessageGovernance(messageGovernanceId)">
                {{'delete' | translate}}</button>
                <button class="btn btn-secondary pull-right" style="margin: 5px;"
                (click)="auditMessagegovernance()">{{ 'audit' |
                translate}}</button> 
            </ng-container>
        </div> 
    </mat-sidenav>
    <mat-sidenav-content class="example-sidenav-content" >
        <div class="governance-data" >
             <div class="form-data" *ngIf="messageGovernance" > 
                <div *ngIf="!auditFlag"> 
                <div class="row">
                    <div class="col-md-6">
                        <label for="name"> {{ 'messagingGovernances.name' | translate}}</label>
                        <input placeholder="required" id="name" type="text" aria-label="Name" (keyup)="messageGovernanceNameInput($event)"
                        [(ngModel)]="messageGovernance.name[0].text" ><br>
                        <p *ngIf="errorInName" style="color: red;">{{errorInName}}</p>

                        <label for="description">{{ 'messagingGovernances.description' | translate}}</label>
                        <textarea  placeholder="optional" id="description" aria-label="Description"
                        [(ngModel)]="messageGovernance.description[0].text" ></textarea><br>
                        <label for="tags">{{ 'messagingGovernances.tags' | translate}}</label>
                        <mat-chip-list #tagList aria-label="Tags">
                            <mat-chip class="ot-chip"  style="margin-left: 10px;" *ngFor="let tag of messageGovernance.tags" [selectable]="true" [removable]="true"
                                (removed)="remove(tag)">
                                {{ tag }}
                                <button class="ot-chip__close" matChipRemove></button>
                            </mat-chip>
                            <input placeholder="add a tag" #tagInput [matChipInputFor]="tagList" 
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                                (matChipInputTokenEnd)="add($event)" />
                        </mat-chip-list>
        
                       

                    </div>

                    <div class="col-md-6">
                        <label for="shedule"> {{ 'messagingGovernances.schedule' | translate}}</label>
                        <input placeholder="required" id="schedule" type="text" aria-label="Schedule" (keyup)="messageGovernanceScheduleInput($event)"
                        [(ngModel)]="messageGovernance.schedule"><br>
                        <p *ngIf="errorInSchedule" style="color: red;">{{errorInSchedule}}</p>

                        <label for="messageCount"> {{ 'messagingGovernances.messageCount' | translate}}</label>
                        <input  id="messageCount" type="number" aria-label="Message Count" (change)="messageGovernanceQuotaInput($event)"
                        [(ngModel)]="messageGovernance.quota"><br>
                        <p *ngIf="errorInMessageCount" style="color: red;">{{errorInMessageCount}}</p>

                        <label for="gracePeriod"> {{ 'messagingGovernances.gracePeriod' | translate}}</label>
                        <input id="gracePeriod" type="number" aria-label="Grace Period" (change)="messageGovernanceGracePeriodInput($event)"
                        [(ngModel)]="messageGovernance.graceTime" ><br>
                        <p *ngIf="errorInGracePeriod" style="color: red;">{{errorInGracePeriod}}</p>
                        
                        <label for="messageState"> {{ 'messagingGovernances.messageState' | translate}}</label>
                        <select name="messageState" [(ngModel)]="messageGovernance.messageState" (change)="messageGovernanceMessageStateInput($event)">
                            <option [value]="item.value" *ngFor="let item of messageStateTypes">
                                {{item.type}}
                            </option>
                        </select><br>
                        <p *ngIf="errorInMessagState" style="color: red;">{{errorInMessagState}}</p>
                        <label for="userDefinedMessage"> {{ 'messagingGovernances.userDefinedMessage' | translate}}</label>
                        <textarea  placeholder="optional" id="userDefinedMessage" aria-label="User Defined Message"
                        [(ngModel)]="messageGovernance.userDefineMessage" ></textarea><br>

                        <label for="sms"> {{ 'messagingGovernances.sms' | translate}}</label>
                        <input placeholder="optional" id="sms" type="text" aria-label="SMS"
                        [(ngModel)]="messageGovernance.smsAddress"  ><br>
                        <p *ngIf="errorInSmsAddress" style="color:red;">{{errorInSmsAddress}}</p>

                        <label for="emailAddress"> {{ 'messagingGovernances.emailAddress' | translate}}</label>
                        <input placeholder="required" id="emailAddress" type="text" aria-label="Email Address" (keyup)="messageGovernanceEmailAddressInput($event)"
                        [(ngModel)]="messageGovernance.emailAddress" email #prmEmail="ngModel"><br>
                        <p *ngIf="errorInEmailAddress" style="color:red;">{{errorInEmailAddress}}</p>
                        <label for="generateTrackingMessage"> {{ 'messagingGovernances.generateTrackingMessage' | translate}}</label>
                            <select [(ngModel)]="messageGovernance.generateTrackingMessage">
                                <option [ngValue]=true>{{'yes' | translate}}</option>
                                <option [ngValue]=false>{{'no' | translate}}</option>
                            </select><br>
                        

                        <label for="triggerType"> {{ 'messagingGovernances.triggerType' | translate}}</label>
                        <select name="triggerType" (change)="onChange()" [(ngModel)]="messageGovernance.trigger" >
                            <option [value]="item.value" *ngFor="let item of triggerTypes">
                                {{item.type}}
                            </option>
                        </select><br>
                    </div>
                </div>
                <div style="margin-left: -10px; margin-right: -15px;">
                <div *ngIf="messageGovernance.trigger=='COMMAND'">
                    <div *ngIf="commandFlag">
                     <app-commands [pageSize]="5 " [showControlBar]=true [displayedColumns]="[ 'ADD', 'name','description', 'creation','ACTIVE'] " [showSelectColoumn]=true [showEnableColumn]=true [showNew]="false" [showHeaderToolTips]=false></app-commands> 
                    </div>  
                    <div *ngIf="!commandFlag">
                        <app-commands [pageSize]="5 " [showControlBar]=true [displayedColumns]="[ 'ADD', 'name','description', 'creation','ACTIVE'] " [showSelectColoumn]=true [showEnableColumn]=true [showNew]="false" [showHeaderToolTips]=false></app-commands> 
                    </div>   
                 </div>  

                <div  *ngIf="messageGovernance.trigger=='DEVICE_EVENT' || messageGovernance.trigger=='COMMAND'">
                    <div *ngIf="digitalTwin">
                        <app-digitalTwins   [pageSize]="5" [showSelectColoumn]=true
                        [showControlBar]=true [displayedColumns]="['ADD','name','description','parentDeviceTemplateName','creation','ACTIVE']" [showNew]="false" [showEnableColumn]=true [showHeaderToolTips]=false></app-digitalTwins>
                    </div>
                    <div *ngIf="!digitalTwin">
                         <app-digitalTwins   [pageSize]="5" [showSelectColoumn]=true
                         [showControlBar]=true [displayedColumns]="['ADD','name','description','parentDeviceTemplateName','creation','ACTIVE']" [showNew]="false" [showEnableColumn]=true [showHeaderToolTips]=false></app-digitalTwins>
                     </div>
                </div>
                <div *ngIf="messageGovernance.trigger=='DEVICE_EVENT'">
                    <div *ngIf="eventsFlag">
                        <app-events  [pageSize]="5" [showSelectColoumn]="true" [showControlBar]="true"  [showEnableColumn]=true [showNew]="false"
                        [displayedColumns]="['ADD', 'name', 'description', 'creation', 'ACTIVE']" [showHeaderToolTips]=false>
                        </app-events>
                    </div>
                    <div *ngIf="!eventsFlag">
                        <app-events  [pageSize]="5" [showSelectColoumn]="true" [showControlBar]="true"  [showEnableColumn]=true [showNew]="false"
                        [displayedColumns]="['ADD', 'name', 'description', 'creation', 'ACTIVE']" [showHeaderToolTips]=false>
                        </app-events>
                    </div>

                </div>
               
              
               <div *ngIf="messageGovernance.trigger=='TRADING_PARTNER_MESSAGE'">
                    <div *ngIf="senderFromTPCodeLoad">
                         <app-fromTPCodes  [pageSize]="5" [showControlBar]=true [displayedColumns]="[ 'ADD', 'code', 'qualifier','creation', 'ACTIVE'] " [showSelectColoumn]=true [showEnableColumn]=true [showNew]="false" [showHeaderToolTips]=false></app-fromTPCodes>
                     </div>
                   <div *ngIf="!senderFromTPCodeLoad">
                        <app-fromTPCodes  [pageSize]="5" [showControlBar]=true [displayedColumns]="[ 'ADD', 'code', 'qualifier', 'creation', 'ACTIVE'] " [showSelectColoumn]=true [showEnableColumn]=true [showNew]="false" [showHeaderToolTips]=false></app-fromTPCodes>
                   </div>
                   
                </div>
                
                <div *ngIf="messageGovernance.trigger=='TRADING_PARTNER_MESSAGE'"> 
                    <div *ngIf="tOTPCodeLoad">
                        <app-toTPCodes  [pageSize]="5" [showControlBar]=true [displayedColumns]="[ 'ADD', 'code', 'qualifier', 'creation', 'ACTIVE'] " [showSelectColoumn]=true [showEnableColumn]=true [showNew]="false" [showHeaderToolTips]=false></app-toTPCodes>
                    </div>
                    <div *ngIf="!tOTPCodeLoad">
                        <app-toTPCodes  [pageSize]="5" [showControlBar]=true [displayedColumns]="[ 'ADD', 'code', 'qualifier', 'creation', 'ACTIVE'] " [showSelectColoumn]=true [showEnableColumn]=true [showNew]="false" [showHeaderToolTips]=false></app-toTPCodes>
                    </div>
                    
                </div>

               <div *ngIf="messageGovernance.trigger=='DEVICE_EVENT' || messageGovernance.trigger=='TRADING_PARTNER_MESSAGE'">
                     <div *ngIf= "documentLoaded">
                        <app-documentInfo  [pageSize]="5 " [showControlBar]=true [displayedColumns]="[ 'ADD', 'name', 'supportedDocTemplateType', 'docTemplateVersion', 'creation', 'ACTIVE'] " [showSelectColoumn]=true [showEnableColumn]=true [showNew]="false" [showHeaderToolTips]=false>
                        </app-documentInfo>
                     </div>
                     <div *ngIf= "!documentLoaded">
                        <app-documentInfo  [pageSize]="5 " [showControlBar]=true [displayedColumns]="[ 'ADD', 'name', 'supportedDocTemplateType', 'docTemplateVersion', 'creation', 'ACTIVE'] " [showSelectColoumn]=true [showEnableColumn]=true [showNew]="false" [showHeaderToolTips]=false>
                        </app-documentInfo>
                     </div>

               </div>

               <div *ngIf="messageGovernance.trigger=='DEVICE_EVENT'">
                   <div *ngIf= "senderStreamLoad">
                         <app-streams   [pageSize]="5" [showNew]="false" [showControlBar]=true [displayedColumns]="['ADD','name','protocolType','direction','streamType', 'creation','ACTIVE']" showSelectColoumn=true showEnableColumn=true addnQueryParam=[streamType::DEVICE^^direction::ACTIVE_SENDING^^direction::PASSIVE_SENDING^^direction::BOTH]  pageTitle="Sending Streams" instance="1" [showHeaderToolTips]=false>
                        </app-streams>
                   </div>
                  
                   <div *ngIf= "!senderStreamLoad">
                        <app-streams   [pageSize]="5" [showNew]="false" [showControlBar]=true [displayedColumns]="['ADD','name','protocolType','direction','streamType', 'creation','ACTIVE']" showSelectColoumn=true showEnableColumn=true addnQueryParam=[streamType::DEVICE^^direction::ACTIVE_SENDING^^direction::PASSIVE_SENDING^^direction::BOTH]  pageTitle="Sending Streams" instance="1" [showHeaderToolTips]=false>
                        </app-streams>
                   </div>
                   <div *ngIf="receiverStreamLoad">
                         <app-streams    [pageSize]="5" [showNew]="false" [showControlBar]=true [displayedColumns]="['ADD','name','protocolType','direction','streamType', 'creation','ACTIVE']" showSelectColoumn=true showEnableColumn=true addnQueryParam=[streamType::APPLICATION^^direction::ACTIVE_RECEIVING^^direction::PASSIVE_RECEIVING^^direction::BOTH] pageTitle="Receiving Streams" instance="2" [showHeaderToolTips]=false>
                        </app-streams>
                   </div>
                   <div *ngIf="!receiverStreamLoad">
                        <app-streams    [pageSize]="5" [showNew]="false" [showControlBar]=true [displayedColumns]="['ADD','name','protocolType','direction','streamType', 'creation','ACTIVE']" showSelectColoumn=true showEnableColumn=true addnQueryParam=[streamType::APPLICATION^^direction::ACTIVE_RECEIVING^^direction::PASSIVE_RECEIVING^^direction::BOTH] pageTitle="Receiving Streams" instance="2" [showHeaderToolTips]=false>
                        </app-streams>
                   </div>   
                 </div>
 
                <div *ngIf="messageGovernance.trigger=='COMMAND'">    
                    <div *ngIf= "senderStreamLoad">
                        <app-streams  [pageSize]="5" [showNew]="false" [showControlBar]=true [displayedColumns]="['ADD','name','protocolType','direction','streamType', 'creation','ACTIVE']" showSelectColoumn=true showEnableColumn=true addnQueryParam=[streamType::APPLICATION^^direction::ACTIVE_SENDING^^direction::PASSIVE_SENDING^^direction::BOTH]  pageTitle="Sending Streams" instance="1" [showHeaderToolTips]=false>
                        </app-streams>
                    </div>
                    <div *ngIf= "!senderStreamLoad">
                        <app-streams  [pageSize]="5" [showNew]="false" [showControlBar]=true [displayedColumns]="['ADD','name','protocolType','direction','streamType', 'creation','ACTIVE']" showSelectColoumn=true showEnableColumn=true addnQueryParam=[streamType::APPLICATION^^direction::ACTIVE_SENDING^^direction::PASSIVE_SENDING^^direction::BOTH]  pageTitle="Sending Streams" instance="1" [showHeaderToolTips]=false>
                        </app-streams>
                    </div>

                    <div *ngIf="receiverStreamLoad">
                        <app-streams  [pageSize]="5" [showNew]="false" [showControlBar]=true [displayedColumns]="['ADD','name','protocolType','direction','streamType', 'creation','ACTIVE']" showSelectColoumn=true showEnableColumn=true addnQueryParam=[streamType::DEVICE^^direction::ACTIVE_RECEIVING^^direction::PASSIVE_RECEIVING^^direction::BOTH] pageTitle="Receiving Streams" instance="2" [showHeaderToolTips]=false>
                        </app-streams>
                    </div>
                    <div *ngIf="!receiverStreamLoad">
                        <app-streams  [pageSize]="5" [showNew]="false" [showControlBar]=true [displayedColumns]="['ADD','name','protocolType','direction','streamType', 'creation','ACTIVE']" showSelectColoumn=true showEnableColumn=true addnQueryParam=[streamType::DEVICE^^direction::ACTIVE_RECEIVING^^direction::PASSIVE_RECEIVING^^direction::BOTH] pageTitle="Receiving Streams" instance="2" [showHeaderToolTips]=false>
                        </app-streams>
                    </div>
                   
                    
                </div>
             
               <div *ngIf="messageGovernance.trigger=='TRADING_PARTNER_MESSAGE'">
                <div *ngIf= "senderStreamLoad">
                    <app-streams   [pageSize]="5" [showNew]="false" [showControlBar]=true [displayedColumns]="['ADD','name','protocolType','direction','streamType', 'creation','ACTIVE']" showSelectColoumn=true showEnableColumn=true addnQueryParam=[streamType::MESSAGING^^direction::ACTIVE_SENDING^^direction::PASSIVE_SENDING^^direction::BOTH  pageTitle="Sending Streams" instance="1" [showHeaderToolTips]=false>
                    </app-streams>
                </div>
                <div *ngIf= "!senderStreamLoad">
                    <app-streams   [pageSize]="5" [showNew]="false" [showControlBar]=true [displayedColumns]="['ADD','name','protocolType','direction','streamType', 'creation','ACTIVE']" showSelectColoumn=true showEnableColumn=true addnQueryParam=[streamType::MESSAGING^^direction::ACTIVE_SENDING^^direction::PASSIVE_SENDING^^direction::BOTH  pageTitle="Sending Streams" instance="1" [showHeaderToolTips]=false>
                    </app-streams>
                </div>
                <div *ngIf="receiverStreamLoad">
                    <app-streams  [pageSize]="5" [showNew]="false" [showControlBar]=true [displayedColumns]="['ADD','name','protocolType','direction','streamType', 'creation','ACTIVE']" showSelectColoumn=true showEnableColumn=true addnQueryParam=[streamType::MESSAGING^^direction::ACTIVE_RECEIVING^^direction::PASSIVE_RECEIVING^^direction::BOTH] pageTitle="Receiving Streams" instance="2" [showHeaderToolTips]=false>
                    </app-streams> 
                </div>
                <div *ngIf="!receiverStreamLoad">
                    <app-streams  [pageSize]="5" [showNew]="false" [showControlBar]=true [displayedColumns]="['ADD','name','protocolType','direction','streamType', 'creation','ACTIVE']" showSelectColoumn=true showEnableColumn=true addnQueryParam=[streamType::MESSAGING^^direction::ACTIVE_RECEIVING^^direction::PASSIVE_RECEIVING^^direction::BOTH] pageTitle="Receiving Streams" instance="2">
                    </app-streams> 
                </div>
                    
                   
               </div>
               </div>
                </div>
               <div style="height: 110vh;" >
                 
                <app-audits *ngIf="auditFlag && iotService.auditScriptFlag==false" [showNew]="false" [showControlBar]=false [displayedColumns]="['creation','requestorId','actionType','id']" showSelectColoumn=false showEnableColumn=false addnQueryParam=[resourceId::{{messageGovernanceId}}] [showHeaderToolTips]=false></app-audits>
                    <div class="close-btn" *ngIf="auditFlag && iotService.auditScriptFlag==false">
                        <br>
                    
                        <button (click)="closeAudit()">{{'close' | translate}}</button>
                        <br>
                        <br>
                    </div>
                        <br>
                        <div  *ngIf="iotService.auditScriptFlag" style="margin: 0 5px 0 5px;">      
                            <app-ace></app-ace>
                       </div>
                       <div *ngIf="iotService.auditScriptFlag" class="messageGovernanceAudit">
                        <button class="goBack" mat-raised-button color="primary" (click)="goBackAuditList()">{{ 'go.back' | translate}}</button>
                        <br>
                        <br>
                    </div>
                    
                       <br>
                       <br> 
            </div>

            </div> 
            
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
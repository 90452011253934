import { Component, OnInit } from '@angular/core';
import { GatewaysService } from '../service/gateways.service';
import { IotService } from '../service/iot.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Http } from '@angular/http';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-remote-configuration-detail',
  templateUrl: './remote-configuration-detail.component.html',
  styleUrls: ['./remote-configuration-detail.component.scss']
})
export class RemoteConfigurationDetailComponent implements OnInit {
  supportLanguages = ['en'];
  authorityColumns = ["Name", "Type"];
  remoteConfigInfo;
  headers = ["Property", "Value"];
  rows = [];
  fileUrl = 'assets/json/conf.json';
  // private config: Object = null;
  private env: Object = null;
  // fileUrl = url('../../assets/json/conf.json');
  propArrayList = [];
  loadTable: boolean = false;
  conf;
  tooltip;
  valuee;
  filterString;


  constructor(public iotService: IotService, private translateService: TranslateService,
    private router: Router, public gatewaysService: GatewaysService, private http: Http) {
    this.iotService = iotService;
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }

  ngOnInit(): void {
    const that = this;
    that.getRemoteConfigInfo();
  }

  config = null;
  loadData() {
    const that = this;
    that.propArrayList.push("core_frozen_properties");
    that.propArrayList.push("db_path");
    that.propArrayList.push("core_property_change_message_type_id");
    that.propArrayList.push("core_property_change_success_message_type_id");
    that.propArrayList.push("core_property_change_failed_message_type_id");
    that.propArrayList.push("core_adaptor_registration_command_template_id");
    that.propArrayList.push("core_adaptor_registration_event_template_id");
    that.propArrayList.push("core_config_sync_request_event_template_id");
    that.propArrayList.push("core_config_sync_response_command_template_id");
    that.propArrayList.push("core_health_alert_message_type_id");
    that.propArrayList.push("core_gateway_device_id");
    that.propArrayList.push("core_gateway_device_template_id");
    that.propArrayList.push("authz_cloud_pip_sync_request_event_template_id");
    that.propArrayList.push("authz_cloud_pip_sync_response_command_template_id");
    that.propArrayList.push("authz_cloud_policy_sync_request_event_template_id");
    that.propArrayList.push("authz_cloud_policy_sync_response_command_template_id");
    that.propArrayList.push("rules_policy_sync_request_event_template_id");
    that.propArrayList.push("rules_policy_sync_response_command_template_id");
    that.propArrayList.push("provisioner_device_onboarding_request_event_template_id");
    that.propArrayList.push("provisioner_device_onboarding_response_command_template_id");
    that.propArrayList.push("datahub_payload_encryption_enabled");
    that.propArrayList.push("datahub_payload_encryption_key");
    that.propArrayList.push("datahub_payload_encryption_algorithm");
    that.propArrayList.push("datahub_payload_encryption_algorithm_standard");
    that.propArrayList.push("datahub_payload_encryption_algorithm_key_size");
    that.propArrayList.push("datahub_payload_encryption_algorithm_mode");
    that.propArrayList.push("datahubInboundUri");
    that.propArrayList.push("datahubOutboundUri");
    that.propArrayList.push("core_log_fetch_command_template_id");
    that.propArrayList.push("core_log_fetch_event_template_id");
    that.propArrayList.push("datahub_ftp_server_host");
    that.propArrayList.push("datahub_ftp_server_port");
    that.propArrayList.push("datahub_ftp_server_username");
    that.propArrayList.push("datahub_ftp_server_password");
    that.propArrayList.push("datahub_ftp_server_kex");
    that.propArrayList.push("datahub_ftp_server_host_key");
    that.propArrayList.push("datahub_ftp_server_preferred_auth");
    that.propArrayList.push("datahub_ftp_trading_partner_from_code");
    that.propArrayList.push("datahub_ftp_trading_partner_to_code");
    that.propArrayList.push("datahub_ftp_doc_info_type");
    that.propArrayList.push("datahub_ftp_doc_info_format");
    that.propArrayList.push("datahub_ftp_doc_info_version");
    that.propArrayList.push("core_agent_version");
    that.propArrayList.push("core_agent_env");
    that.propArrayList.push("core_agent_auto_update_request_event_template_id");
    that.propArrayList.push("core_agent_auto_update_response_command_template_id");
    that.propArrayList.push("core_agent_update_command_template_id");
    that.propArrayList.push("core_agent_sync_event_template_id");
    that.propArrayList.push("core_agent_upgrader_script");
    that.propArrayList.push("core_agent_update_conf_file");
  }
  //  validateEnable(rows,propArrayList){
  //    const that=this;
  //    rows.forEach(item=>{
  //     item.value._value = item.value.value;
  //     if(that.propArrayList.includes(item.value.name)){
  //     item.value.isEditable = false;
  //     } else {
  //     item.value.isEditable = true;
  //     }
  //     }); 
  //    }
  // loadJSONFile(filepath) {
  //   return $http.get(filepath);
  // }

  getRemoteConfigInfo() {
    const that = this;
    that.iotService.getCuiObjResponse().getGatewayRemoteConfig({
      gatewayId: that.gatewaysService.getGateWayAgentId()
    }).then(function (response) {
      that.loadData();
      that.config = response;
      that.config.properties = response.properties;
      that.rows = that.config.properties;
      that.config.properties.forEach((value, key) => {
        // copying row.value into row._value 
        //this.valuee=value;
        value['_value'] = value.value;
        if (that.propArrayList.includes(value.name)) {
          value['isEditable'] = false;
        } else {
          value['isEditable'] = true;
        }

        that.loadforData(value);
      });
      that.loadTable = true;
      //that.getTooltip();
      //that.load();

    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }
  scrub(value) {
    this.tooltip = "";
    if (!value.isEditable) {
      this.tooltip = value.tooltip;
      return value.name + "  * " + "<span class=\"tooltiptext\">" + value.tooltip + "</span>";
    } else {
      this.tooltip = value.tooltip;
      return value.name + "<span class=\"tooltiptext\">" + value.tooltip + "</span>";
    }
  }

  public loadforData(element) {

    return new Promise((resolve, reject) => {
      const that = this;
      this.http.get(this.fileUrl).map(res => res.json()).catch((error: any): any => {
        resolve(true);
        return Observable.throw(error.json().error || 'Server error');
      }).subscribe((envResponse) => {
        that.env = envResponse;
        that.conf = envResponse;
        if (that.conf != undefined) {
          if (that.conf[element.name] != undefined) {
            element.tooltip = that.conf[element.name];
          }
        }
      });
    });
  }

  goBack(){
    this.gatewaysService.createResource=true;
    this.gatewaysService.showRemoteConfig=false;
  }

  update() {
    const that = this;
    that.iotService.getCuiObjResponse().updateGatewayRemoteConfig({
      gatewayId: that.gatewaysService.gateWayAgentId,
      data: that.config
    }).then(function (response) {        
      that.gatewaysService.successMessage="Remote configuration updated successfully!!" 
      that.goBack();
    }).fail(function (err) {
      that.gatewaysService.errorMessage="Error occured while updating Gateway remote configuration"
    
    });
  }


  ngOnDestroy() {
    
  }

}




import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { APP_INITIALIZER } from '@angular/core';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { AppConfig } from './../config/AppConfig';
import { DatePipe } from '@angular/common'
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { IotService } from './service/iot.service';
import { DeviceService } from './service/device.service';
import { ApplicationsService } from './service/applications.service';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { ChartsModule } from 'ng2-charts';
import {MatTabsModule} from '@angular/material/tabs';
import {MatExpansionModule} from '@angular/material/expansion';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { DevicesComponent } from './devices/devices.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TemplatesComponent } from './templates/templates.component';
import { GettingStartedComponent } from './getting-started/getting-started.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatButtonModule} from '@angular/material/button';
import { NewDeviceComponent } from './new-device/new-device.component';
import { MidSideNavComponent } from './mid-side-nav/mid-side-nav.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { DescriptionComponent } from './mid-side-nav/description/description.component';
import { AttributeListComponent } from './mid-side-nav/attribute-list/attribute-list.component';
import { EventListComponent } from './mid-side-nav/event-list/event-list.component';
import { CommandListComponent } from './mid-side-nav/command-list/command-list.component';
import { ConnectionListComponent } from './mid-side-nav/connection-list/connection-list.component';
import { TemplateListComponent } from './mid-side-nav/template-list/template-list.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PerfmatrixComponent } from './perfmatrix/perfmatrix.component';
import { GeofenceListComponent } from './mid-side-nav/geofence-list/geofence-list.component';
import { DeviceDetailComponent } from './device-detail/device-detail.component';
import { CreateGeofenceComponent } from './new-device/create-geofence/create-geofence.component';
import { MapComponentComponent } from './new-device/map-component/map-component.component';
import { CreateAttributeComponent } from './new-device/create-attribute/create-attribute.component';
import { AgmCoreModule } from '@agm/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CreateEventComponent } from './new-device/create-event/create-event.component';
import { CreateCommandComponent } from './new-device/create-command/create-command.component';
import { CreateDeviceTemplateComponent } from './new-device-template/new-device-template.component';
import { TemplateDetailComponent } from './template-detail/template-detail.component';
import { TrackingComponent, TruncatePipe } from './tracking/tracking.component';
import { PaginationComponent } from './pagination/pagination.component';
import { ApplicationsComponent } from './applications/applications.component';
import { CreateApplicationComponent } from './create-application/create-application.component';
import { CertificatesComponent } from './certificates/certificates.component';
import { NewTrustedAuthorityComponent } from './certificates/new-trusted-authority/new-trusted-authority.component';
import { CreateStreamComponent } from './create-stream/create-stream.component';
import { CertificateDataComponent } from './certificates/certificate-data/certificate-data.component';
import { NewTrustedCertificateComponent } from './certificates/new-trusted-certificate/new-trusted-certificate.component';
import { TrustedAuthoritiesComponent } from './trustedAuthorities/trustedAuthorities.component';
import { TrustedCertificatesComponent } from './trustedCertificates/trustedCertificates.component';
import { KeyBundlesComponent } from './keyBundles/keyBundles.component';
import { NewKeyBundleComponent } from './new-key-bundle/new-key-bundle.component';
import { AuthorityDetailComponent } from './certificates/authority-detail/authority-detail.component';
import { CerificateDetailComponent } from './certificates/cerificate-detail/cerificate-detail.component';
import { TrustedPublicKeysComponent } from './trustedPublicKeys/trustedPublicKeys.component';
import { SshKeyPairsComponent } from './sshKeyPairs/sshKeyPairs.component';
import { OAuthTokensComponent } from './oAuthTokens/oAuthTokens.component';
import { EventGroupsComponent } from './eventGroups/eventGroups.component';
import { KeyBundleDetailComponent } from './new-key-bundle/key-bundle-detail/key-bundle-detail.component';
import { SshKeysComponent } from './ssh-keys/ssh-keys.component';
import { NewSshKeyComponent } from './ssh-keys/new-ssh-key/new-ssh-key.component';
import { NewTrustedPublicKeyComponent } from './ssh-keys/new-trusted-public-key/new-trusted-public-key.component';
import { DocumentInfoComponent } from './documentInfo/documentInfo.component';
import { SshKeyDetailComponent } from './ssh-keys/ssh-key-detail/ssh-key-detail.component';
import { TrustedPublicKeyDetailComponent } from './ssh-keys/trusted-public-key-detail/trusted-public-key-detail.component';
import { EventGroupDetailComponent } from './eventGroup-detail/eventGroup-detail.component';
import { CreateEventGroupComponent } from './create-event-group/create-event-group.component';
import { DeviceListComponent } from './create-event-group/device-list/device-list.component';
import { DeviceTemplateListComponent } from './create-event-group/device-template-list/device-template-list.component';
import { AceEditorModule } from 'ng2-ace-editor';
import { AceComponent } from './ace/ace.component';
import { NewOAuthTokenComponent } from './tokens/new-oAuth-token/new-oAuth-token.component';
import { OAuthTokenDetailComponent } from './tokens/oAuth-token-detail/oAuth-token-detail.component';
import { ToTPCodesComponent } from './toTPCodes/toTPCodes.component';
import { FromTPCodesComponent } from './fromTPCodes/fromTPCodes.component';
import { NewDocumentInfoComponent } from './documentInfo/new-document-info/new-document-info.component';
import { DocumentInfoDetailComponent } from './documentInfo/document-info-detail/document-info-detail.component';
import { StreamCredentialsComponent } from './stream-credentials/stream-credentials.component';
import {StreamsComponent} from './streams/streams.component';
import {OrchestrationConfigsComponent} from './orchestrationConfigs/orchestrationConfigs.component';
import { ApplicationDetailsComponent } from './application-details/application-details.component'
import { FromTPCodesService } from './service/fromTPCodes.service';
import { ToTPCodesService } from './service/toTPCodes.service';
import { DocumentInfoService } from './service/documentInfo.service';
import { TradingPartnersComponent } from './tradingPartners/tradingPartners.component';
import { TradingPartnerCodesComponent } from './tradingPartnerCodes/tradingPartnerCodes.component';
import { TradingPartnerRelationshipsComponent } from './tradingPartnerRelationships/tradingPartnerRelationships.component';
import { RoutingSourceRelationshipsComponent } from './routingSourceRelationships/routingSourceRelationships.component';
import { RoutesComponent } from './routes/routes.component';
import { NewTradingPartnerComponent } from './new-trading-partner/new-trading-partner.component';
import { CodeRelationshipsComponent } from './codeRelationships/codeRelationships.component';
import { TradingPartnerDetailComponent } from './trading-partner-detail/trading-partner-detail.component';
import { NewTradingPartnerCodeComponent } from './new-trading-partner-code/new-trading-partner-code.component';
import { TpApplicationsComponent } from './tpApplications/tpApplications.component';
import { NewCodeRelationshipsComponent } from './new-code-relationships/new-code-relationships.component';
import { NewRouteResourceRelationshipComponent } from './create-routing-source-relationship/new-route-resource-relationship.component';
import { DigitalTwinsComponent } from './digitalTwins/digitalTwins.component';
import { TradingPartnerCodeDetailComponent } from './trading-partner-code-detail/trading-partner-code-detail.component';
import { CodeRelationShipDetailComponent } from './code-relation-ship-detail/code-relation-ship-detail.component';
import { RouteRelationshipDetailComponent } from './routing-source-relationship-detail/route-relationship-detail.component';
import { RouteComponent } from './new-route/new-route.component';
import { StreamDetailComponent } from './stream-detail/stream-detail.component';
import { AttributesComponent } from './attributes/attributes.component';
import { EventsComponent } from './events/events.component';
import { EcosystemTemplatesComponent } from './ecosystemTemplates/ecosystemTemplates.component';
import { CreateEcosystemTemplateComponent } from './create-ecosystem-template/create-ecosystem-template.component';
import { MessageGovernancesComponent } from './messageGovernances/messageGovernances.component';
import { CreateRulePolicyComponent } from './create-rule-policy/create-rule-policy.component';
import { RulesComponent } from './rules/rules.component';
import { RulePolicyDetailsComponent } from './rule-policy-details/rule-policy-details.component';
import { CreateMessageGovernanceComponent } from './create-message-governance/create-message-governance.component';
import { CommandsComponent } from './commands/commands.component';
import { CreateOrchestrationConfigComponent } from './create-orchestration-config/create-orchestration-config.component';
import { CreateTasksComponent } from './create-orchestration-config/create-tasks/create-tasks.component';
import { RouteDetailComponent } from './route-detail/route-detail.component';
import { StreamsService } from './service/streams.service';
import { CodeExamplesComponent } from './code-examples/code-examples.component';
import { EcosystemTemplateDetailComponent } from './ecosystem-template-detail/ecosystem-template-detail.component';
import { EcosystemAttributesComponent } from './ecosystemAttributes/ecosystemAttributes.component';
import { EcosystemEventsComponent } from './ecosystemEvents/ecosystemEvents.component';
import { EcosystemMembersComponent } from './ecosystemMembers/ecosystemMembers.component';
import { GatewaysComponent } from './gateways/gateways.component';
import { MessageGovernanceDetailComponent } from './message-governance-detail/message-governance-detail.component';
import { CreateGatewayAgentComponent } from './create-gateway-agent/create-gateway-agent.component';
import { GatewayAgentDetailComponent } from './gateway-agent-detail/gateway-agent-detail.component';
import { CreateAdaptorComponent } from './create-adaptor/create-adaptor.component';
import { EcosystemsComponent } from './ecosystems/ecosystems.component';
import { StyleguideComponent } from './styleguide/styleguide.component';
import { CreateEcosystemComponent } from './create-ecosystem/create-ecosystem.component';
import { GatewayAdaptorsComponent } from './gatewayAdaptors/gatewayAdaptors.component';
import { LogRequestsComponent } from './logRequests/logRequests.component';
import { AuditsComponent } from './audits/audits.component';
import {RemoteConfigurationDetailComponent} from './remote-configuration-detail/remote-configuration-detail.component';
import { CreateLogRequestComponent } from './create-log-request/create-log-request.component';
import { EcosystemDetailComponent } from './ecosystem-detail/ecosystem-detail.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { DigitalTwinTemplatesComponent } from './digitalTwinTemplates/digitalTwinTemplates.component';
import { GeofencesComponent } from './geofences/geofences.component';
import { GatewayAdaptorDetailComponent } from './gateway-adaptor-detail/gateway-adaptor-detail.component';
import { FilterPipe } from './filter.pipe';
import { SortPipe } from './sort.pipe';
import { CreateImportExportComponent } from './create-import-export/create-import-export.component';
import { ImportExportComponent } from './importExport/importExport.component';
import { CreateImportComponent } from './create-import/create-import.component';
import { EcosystemInstanceAttributesComponent } from './ecosystemInstanceAttributes/ecosystemInstanceAttributes.component';
import { EcosystemInstanceMembersComponent } from './ecosystemInstanceMembers/ecosystemInstanceMembers.component';
import { OrganizationsComponent } from './organizations/organizations.component';
import { PersonsComponent } from './persons/persons.component';
import { DownloadLogsComponent } from './download-logs/download-logs.component';
import { CreateEcosystemMembershipComponent } from './create-ecosystem-membership/create-ecosystem-membership.component';
import { OrchestrationImportExportComponent } from './orchestration-import-export/orchestration-import-export.component';
import { EcosystemRelationshipsComponent } from './ecosystemRelationships/ecosystemRelationships.component';
import { EcosystemTemplateRulesComponent } from './ecosystemTemplateRules/ecosystemTemplateRules.component';
import { EcosystemInstanceRulesComponent } from './ecosystemInstanceRules/ecosystemInstanceRules.component';
import { RulesImportExportComponent } from './rules-import-export/rules-import-export.component';
import { EventgroupImportExportComponent } from './eventgroup-import-export/eventgroup-import-export.component';
import { EventgroupExportComponent } from './eventgroup-export/eventgroup-export.component';
import { GeofenceDetailComponent } from './geofence-detail/geofence-detail.component';
import { EcosystemBytemplatesComponent } from './ecosystemBytemplates/ecosystemBytemplates.component';
import { NewGeofenceComponent } from './new-geofence/new-geofence.component';
import { NotificationComponent } from './notification/notification.component';
import { ColumnViewComponent } from './column-view/column-view.component';
import { GatewayAnnouncedDevicesComponent } from './gatewayAnnouncedDevices/gatewayAnnouncedDevices.component';
import { GatewayRulePolicesComponent } from './gatewayRulePolices/gatewayRulePolices.component';
import { CreateNotificationComponent } from './create-notification/create-notification.component';
import { TradingPartnerAuditsComponent } from './tradingPartnerAudits/tradingPartnerAudits.component';
import { WidgetsComponent } from './widgets/widgets.component';
import { MapWidgetComponent } from './map-widget/map-widget.component';
import { DevicesByExceptionsComponent } from './devicesByExceptions/devicesByExceptions.component';
import { DevicesByEcosystemsComponent } from './devicesByEcosystems/devicesByEcosystems.component';
import { DashboardDeviceDetailComponent } from './dashboard-device-detail/dashboard-device-detail.component';
import { CopyResourceIdComponent } from './copy-resource-id/copy-resource-id.component';

@NgModule({
  declarations: [
    DevicesByExceptionsComponent,
    DevicesByEcosystemsComponent,
    TradingPartnerAuditsComponent,
    GatewayAnnouncedDevicesComponent,
    GatewayRulePolicesComponent,
    EcosystemBytemplatesComponent,
    EcosystemRelationshipsComponent,
    EcosystemTemplateRulesComponent,
    EcosystemInstanceRulesComponent,
    EcosystemInstanceAttributesComponent,
    EcosystemInstanceMembersComponent,
    OrganizationsComponent,
    PersonsComponent,
    ImportExportComponent,
    GeofencesComponent,
    DigitalTwinTemplatesComponent,
    GatewayAdaptorsComponent,
    LogRequestsComponent,
    AuditsComponent,
    EcosystemsComponent,
    GatewaysComponent,
    EcosystemMembersComponent,
    EcosystemAttributesComponent,
    EcosystemEventsComponent,
    CommandsComponent,
    MessageGovernancesComponent,
    AttributesComponent,
    EventsComponent,
    EcosystemTemplatesComponent,
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SideNavComponent,
    DevicesComponent,
    DashboardComponent,
    TemplatesComponent,
    GettingStartedComponent,
    NewDeviceComponent,
    MidSideNavComponent,
    DescriptionComponent,
    AttributeListComponent,
    EventListComponent,
    CommandListComponent,
    ConnectionListComponent,
    TemplateListComponent,
    PerfmatrixComponent,
    GeofenceListComponent,
    CreateGeofenceComponent,
    DeviceDetailComponent,
    MapComponentComponent,
    CreateAttributeComponent,
    CreateEventComponent,
    CreateCommandComponent,
    CreateDeviceTemplateComponent,
    TemplateDetailComponent,
    TrackingComponent,
    TruncatePipe,
    PaginationComponent,
    ApplicationsComponent,
    CreateApplicationComponent,
    CertificatesComponent,
    NewTrustedAuthorityComponent,
    CreateStreamComponent,
    CertificateDataComponent,
    NewTrustedCertificateComponent,
    TrustedAuthoritiesComponent,
    TrustedCertificatesComponent,
    KeyBundlesComponent,
    NewKeyBundleComponent,
    AuthorityDetailComponent,
    CerificateDetailComponent,
    TrustedPublicKeysComponent,
    SshKeyPairsComponent,
    OAuthTokensComponent,
    EventGroupsComponent,
    KeyBundleDetailComponent,
    SshKeysComponent,
    NewSshKeyComponent,
    NewTrustedPublicKeyComponent,
    DocumentInfoComponent,
    SshKeyDetailComponent,
    TrustedPublicKeyDetailComponent,
    EventGroupDetailComponent,
    DeviceListComponent,
    DeviceTemplateListComponent,
    EventGroupDetailComponent,
    CreateEventGroupComponent,
    AceComponent,
    NewOAuthTokenComponent,
    OAuthTokenDetailComponent,
    ToTPCodesComponent,
    FromTPCodesComponent,
    NewDocumentInfoComponent,
    DocumentInfoDetailComponent,
    StreamCredentialsComponent,
    StreamsComponent,
    OrchestrationConfigsComponent,
    ApplicationDetailsComponent,
    TradingPartnersComponent,
    TradingPartnerCodesComponent,
    TradingPartnerRelationshipsComponent,
    RoutingSourceRelationshipsComponent,
    RoutesComponent,
    NewTradingPartnerComponent,
    CodeRelationshipsComponent,
    TradingPartnerDetailComponent,
    NewTradingPartnerCodeComponent,
    TpApplicationsComponent,
    NewCodeRelationshipsComponent,
    NewRouteResourceRelationshipComponent,
    DigitalTwinsComponent,
    TradingPartnerCodeDetailComponent,
    CodeRelationShipDetailComponent,
    RouteRelationshipDetailComponent,
    RouteComponent,
    StreamDetailComponent,
    CreateEcosystemTemplateComponent,
    CreateRulePolicyComponent,
    RulesComponent,
    RulePolicyDetailsComponent,
    CreateMessageGovernanceComponent,
    CreateOrchestrationConfigComponent,
    CreateTasksComponent,
    RouteDetailComponent,
    CodeExamplesComponent,
    EcosystemTemplateDetailComponent,
    MessageGovernanceDetailComponent,
    CreateGatewayAgentComponent,
    GatewayAgentDetailComponent,
    CreateAdaptorComponent,
    CreateGatewayAgentComponent,
    GatewayAgentDetailComponent,
    StyleguideComponent,
    CreateEcosystemComponent,
    RemoteConfigurationDetailComponent,
    CreateLogRequestComponent,
    EcosystemDetailComponent,
    BreadcrumbComponent,
    GatewayAdaptorDetailComponent,
    FilterPipe,
    SortPipe,
    CreateImportExportComponent,
    CreateImportComponent,
    DownloadLogsComponent,
    CreateEcosystemMembershipComponent,
    OrchestrationImportExportComponent,
    RulesImportExportComponent,
    EventgroupImportExportComponent,
    EventgroupExportComponent,
    GeofenceDetailComponent,
    NewGeofenceComponent,
    NotificationComponent,
    ColumnViewComponent,
    CreateNotificationComponent,
    WidgetsComponent,
    MapWidgetComponent,
    DashboardDeviceDetailComponent,
    CopyResourceIdComponent
  ],
  imports: [
    MatButtonToggleModule,
    BrowserModule,
    AppRoutingModule,
    HttpModule,
    HttpClientModule,
    NgbModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    MaterialModule,
    MatAutocompleteModule,
    MatGridListModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    MatCheckboxModule,
    Ng2SearchPipeModule,
	  ChartsModule,
    NgxSkeletonLoaderModule,
    AceEditorModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBibaP3B7LIKdfaAbxaprvrVz5vuIuHXRs', //'AIzaSyAAKFUSVt3bJv8D9JvF8_6CgrM9vlfEUuY',
      libraries: ['places', 'drawing', 'geometry']
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    MatTabsModule,
    MatExpansionModule

  ],
  providers: [AppConfig, DatePipe,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    { provide: APP_INITIALIZER, useFactory: (config: AppConfig) => () => config.load(), deps: [AppConfig], multi: true },
    { provide: 'fromTPCodesService1', useClass: FromTPCodesService },
    { provide: 'fromTPCodesService2', useClass: FromTPCodesService },
    { provide: 'toTPCodesService1', useClass: ToTPCodesService },
    { provide: 'toTPCodesService2', useClass: ToTPCodesService },
    { provide: 'documentInfoService1', useClass: DocumentInfoService },
    { provide: 'documentInfoService2', useClass: DocumentInfoService },
    { provide: 'streamsService1', useClass: StreamsService },
    { provide: 'streamsService2', useClass: StreamsService },
    IotService,DeviceService],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http,'./assets/i18n/','.json');
}

import { Component, OnInit } from '@angular/core';
import { IotService } from '../../service/iot.service';
import { DeviceService } from '../../service/device.service';
import {MatChipInputEvent} from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { GeofencesService } from 'src/app/service/geofences.service';


@Component({
  selector: 'app-create-geofence',
  templateUrl: './create-geofence.component.html',
  styleUrls: ['./create-geofence.component.scss']
})
export class CreateGeofenceComponent implements OnInit {

  maxSearchLengthRadius = false;
  invalidRadius = false;
  geofence;
  nameLengthError = false;
  noCoordinatesSelected = false;
  maxSearchRadius:any;
  geofenceDescription:any;
  tags:string[]=[];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  geofenceName:any;
  errorMsgName;
  errorMsgRadius;
  supportLanguages = ['en'];
  tagList =[];
  tagClass = 'tagInput';
  geofenceCreated=false;
  constructor(public iotService:IotService,public deviceService:DeviceService,private router:Router,
    private translateService: TranslateService,
    public geofenceService:GeofencesService) { 
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');  
  }

  ngOnInit(): void {
    this.deviceService.displayCoordinates=false;
    this.initGeofence();
  }

  initGeofence() {
    const that = this;
    that.geofence = {
      "creator": this.iotService.getCreator(),
      "creatorAppId": this.iotService.getCreatorAppId(),
      "realm": this.iotService.getRealm(),
      "maxSearchRadius": "",
      'name': [{
        'lang': 'en_US',
        'text':''
       }],
      'description': [{
          'lang': 'en_US',
          'text': ''
      }],
      "isActive":true
    }
 
  }
  removeGeofence(){
    if(this.iotService.createGeofence==true){
      this.router.navigateByUrl('geofences');
      this.geofenceService.mapLoad=false;
      this.geofenceService.errorMessage='';
    }
    else{
      this.deviceService.createGeofence = false;
      this.deviceService.createResource = false;
      this.deviceService.mapLoad = false;
      this.deviceService.errorMessage='';
  
    }
    

  }

  loadMap(){
    if(this.iotService.createGeofence==true){
      this.geofenceService.mapLoad=true;
    }
    else{
    this.deviceService.createGeofence = true;
    this.deviceService.mapLoad = true;
    this.deviceService.displayCoordinates=false;
    }
  }

  getTagArray(tags) {

    var tagArray = new Array(); 
    for (var i = 0; i < tags.length; i++) {
        tagArray.push(tags[i].text);
    }
    return tagArray;
  }

  add(event: MatChipInputEvent): void {
    this.tagClass = 'tagInput';
    const input = event.input;
    const value = event.value;
    const index = this.tagList.indexOf(value);
    this.tagList.push(value);

    if(index<0){
    if ((value || '').trim()) {
      const that = this;
      that.tags.push(value);
      // Reset the input value
      if (input) {
        input.value = '';
      }
    }
    }else{
    this.tagClass = 'tagInput-duplicate';
    }
  }

  remove(tag: string): void {
    const that = this;
    const index = that.tags.indexOf(tag);
    if (index >= 0) {
      that.tags.splice(index, 1);
    }
  }

  saveGeofencing () {
    this.nameLengthError = false;
    this.maxSearchLengthRadius = false;
    this.invalidRadius = false;

    var isValid = true;

    if (this.geofenceName === '' || this.geofenceName===undefined) {
      this.nameLengthError = true
      this.errorMsgName = "Name is mandatory."
    }

    if(this.maxSearchRadius!='' && this.maxSearchRadius!=undefined){
        if (isNaN(this.maxSearchRadius)) {
          this.maxSearchLengthRadius = true;
          this.errorMsgRadius = "Please enter valid max search radius."

        }
        this.geofence = {
          "creator": this.iotService.getCreator(),
          "creatorAppId": this.iotService.getCreatorAppId(),
          "realm": this.iotService.getRealm(),
          "maxSearchRadius": this.maxSearchRadius,
          'name': [{
            'lang': 'en_US',
            'text': this.geofenceName
           }],
          'description': [{
              'lang': 'en_US',
              'text': this.geofenceDescription
          }],
          "isActive":true
        }
    }
    else{
      this.geofence = {
        "creator": this.iotService.getCreator(),
        "creatorAppId": this.iotService.getCreatorAppId(),
        "realm": this.iotService.getRealm(),
        'name': [{
          'lang': 'en_US',
          'text':this.geofenceName
        }],
        'description': [{
          'lang': 'en_US',
          'text': this.geofenceDescription
        }],
        "isActive":true
      }
      
    }
     
    if (this.nameLengthError == false
        && this.maxSearchLengthRadius == false) {
        const that = this;
        that.noCoordinatesSelected = false;
        var newCoordinateArray = [];
        newCoordinateArray = this.deviceService.getRequestCoordinateList()
        if (that.tags != null && that.tags!=undefined){
          that.geofence.tags = that.tags;
        }
    
      
        that.geofence.polygon = newCoordinateArray;
        that.geofenceCreated=true;
        that.iotService.getCuiObjResponse().addGeofence({

            data: that.geofence
        }).then(function (results2) {
            console.log('add geofence success');
            if(that.iotService.createGeofence==true){
              that.router.navigateByUrl('geofences');
              that.geofenceService.mapLoad=false;
            }
            // else if(this.iotService.newDeviceTemplate == true){
            //   that.deviceService.createResource=false;
            //   that.deviceService.createGeofence=false;
            //   that.deviceService.mapLoad=false;
            // }
            else{
            that.deviceService.createResource=false;
            that.deviceService.createGeofence=false;
            that.deviceService.mapLoad=false;
            }
         //   this.$apply();

        }).fail(function (err) {
          that.geofenceCreated=false;
            console.log('add geofence fail');
            if (err.responseJSON.apiStatusCode) {
              that.deviceService.errorMessage=err.responseJSON.apiMessage;   
              that.geofenceService.errorMessage=err.responseJSON.apiMessage;        
            } else {
              console.log("Something went wrong. Please check chrome console or check with dev team");
              that.deviceService.errorMessage="Error occured while creating geofence";   
              that.geofenceService.errorMessage="Error occured while creating geofence";   

            }
            if(!err.responseJSON){
              that.geofenceService.errorMessage="Error occured while creating geofence";   

            }
          
           
          /*   this.errorMsg = msg.responseJSON.apiMessage;
            this.refresh();
            this.$apply();
            */
        });
    }

  }
}

import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { IotService } from '../service/iot.service';
import { TranslateService } from '@ngx-translate/core';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { faCheck, faAngleUp, faAngleDown,faCheckCircle, faTimes,faExclamationTriangle,faExclamation } from '@fortawesome/free-solid-svg-icons';
import { RulesService } from '../service/rules.service';
import { SelectionModel} from '@angular/cdk/collections';
import { ActivatedRoute } from '@angular/router';
import { EcosystemTemplatesService } from '../service/ecosystemTemplates.service';
import { EventsService } from '../service/events.service';
import { EcosystemsService } from '../service/ecosystems.service';
import { GeofencesService } from '../service/geofences.service';
import { GatewaysService } from '../service/gateways.service';
import { DigitalTwinsService } from '../service/digitalTwins.service';
import { DigitalTwinTemplatesService } from '../service/digitalTwinTemplates.service';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-rule-policy-details',
  templateUrl: './rule-policy-details.component.html',
  styleUrls: ['./rule-policy-details.component.scss']
})
export class RulePolicyDetailsComponent implements OnInit {

  separatorKeysCodes: number[] = [ENTER, COMMA];
  supportLanguages = ['en'];
  rulePolicy: any;
  tagClass = 'tagInput';
  warningMessage: string;
  successMessage: string;
  faCheckIcon = faCheck;
  faTimesIcon = faTimes;
  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;
  faCloseIcon=faTimes;
  faWarningIcon = faExclamationTriangle;
  faCriticalIcon=faExclamation;
  faSuccessIcon = faCheckCircle;
  auditFlag=false;
  showGateway=false;
  //showGatewayDevices=false;
  showGatewayDevicesEvents=false;
  gatewayDeviceCount=0;
  setDefault=false;
  reset=false;
  //loading=false;
  triggers;
  simulatedata = '{}';
  simulate = false;
  simulatetimeout = 30;
  testing = false;
  beautifying = false;
  attributeIds = [];
  eventIds = [];
  allowedMembers = [];
  completions = [];
  timeout=30;
  allowedMemberText;
  showDeviceFromSideNav =true;
  showEventDeviceTab=false;
  triggerType = 'Select Trigger';
  viewLogLevel= 'Normal';
  script = "";
  task;
  mode = 0;
  showTestRule: boolean;
  type = "Device Event"; // Event Lifecycle 2=Presence
  lifecyclestate = 1;
  presencestate = 1;
  cestate = 1; // 1 is device inside geofence, 2 is device outside geofence
  healthstate = 1
  healthalert = 1
  ecosystemdevicestate = 1;
  ecosystempersonstate = 1;
  ecosystemapplicationstate = 1;
  ecosystemgroupstate = 1;
  ecosystemEvent=true;
  testResult = null;
  testError=null;
  testJSONError = null;
  chkBoxModel;
  codeExampleType = "rule"
  code = "";
 // iot={"data":""}
  onload= false;
  gatewayPageNo = 1;
  geofencePageNo = 1;
  gateway;
  gateways = [];
  obervableEvents = [];
  supportedCommands =[];
  selectedGatewayDevices = null;
  selectedGatewayGroupId = "";
  samplegatewayinputjson = "";
  selectedGatewayEventList = [];
  selectedGatewayEventTemp;
  gatewayCode = ' // Send the event to the cloud platform for additional processing. \n\n utils.uploadEvent();';
  gatewayCodeExamples = 'Upload Event';
  gatewayHasDevices = false;
  dropdownSelection ="0";
  deviceTrackingState=1;
  qualifierExpression;
  geofencestate=1;
  selection: SelectionModel<Element> = new SelectionModel<Element>(true, []);
  defaultScript= "ZnVuY3Rpb24gcG9saWN5KHV0aWxzLCBpbnB1dCkgew0KDQogICAgLyoNCiAgICAvLyBnZXQgdGhlIGRldmljZSBldmVudCBtZXNzYWdlIGZyb20gdGhlIGlucHV0DQogICAgDQogICAgLy8gcGVyZm9ybSBzZW5kIGVtYWlsIGFjdGlvbiBvbiBhIGNvbmRpdGlvbiAgIA0KICAgIGlmIChwYXJzZUZsb2F0KGlucHV0LmZsdXhTZW5zb3IpID4gODApIHsNCiAgICAgIHV0aWxzLmRvQWN0aW9uKCJTRU5EX0VNQUlMIiwgInJlY2lwaWVudHM6YWRkcmVzc0Bub2VtYWlsLmNvbSIsICJzdWJqZWN0OmZsdXggY2FwYWNpdG9yIGFsZXJ0ISIsICJtZXNzYWdlOkZsdXggc2Vuc29yIGlzIGhpZ2ghICIgKyAiIHZhbHVlIGlzICIgKyBmbHV4U2Vuc29yKTsNCiAgICB9ICANCiAgDQogICAgLy8gVGhpcyBwb2xpY3kgaGFzIGFjY2VzcyB0byBnbG9iYWwgc2Vzc2lvbiBhdHRyaWJ1dGVzLg0KDQogICAgLy8gU2V0IGEgdmFsdWUgZm9yIGEga2V5LCBsaWtlIHRoaXM6DQogICAgdXRpbHMuZ2V0U2Vzc2lvbigpLnNldCgibXlfa2V5IiwgIjQyIik7DQoNCiAgICAvLyAuLi5hbmQgdG8gcmV0cmlldmUgdmFsdWVzOg0KICAgIHZhciB2ID0gdXRpbHMuZ2V0U2Vzc2lvbigpLmdldCgibXlfa2V5Iik7DQogICAgLy8gWW91IGNhbiBkZWxldGUgYSBrZXkgYWx0b2dldGhlcjoNCiAgICB1dGlscy5nZXRTZXNzaW9uKCkuZGVsZXRlKCJteV9rZXkiKTsNCg0KICAgIC8vIFRvIHRyYWNrIGNvdW50ZXJzLCB1c2UgdGhlc2UgZnVuY3Rpb25zOg0KICAgIHZhciBjb3VudCA9IHV0aWxzLmdldFNlc3Npb24oKS5pbmNyZW1lbnQoIm15X2NvdW50ZXIiKTsNCiAgICAvLyAuLi5vciBpbmNyZW1lbnQgYnkgYSBnaXZlbiByYW5nZS4uLg0KICAgIGNvdW50ID0gdXRpbHMuZ2V0U2Vzc2lvbigpLmluY3JlbWVudCgibXlfY291bnRlciIsIDEwKTsNCg0KICAgIC8vIFNhbWUgYXBwbGllcyBmb3IgZGVjcmVtZW50Og0KICAgIHV0aWxzLmdldFNlc3Npb24oKS5kZWNyZW1lbnQoIm15X2NvdW50ZXIiKTsNCg0KICAgIC8vIENvdW50ZXJzIGNhbiBiZSBhY2Nlc3NlZCBhdCBhbnkgdGltZToNCiAgICB2YXIgbXlDb3VudGVyID0gdXRpbHMuZ2V0U2Vzc2lvbigpLmdldCgibXlfY291bnRlciIpOw0KICAgICovDQp9";

  testScript = '{"attribute": "value"}';
  gatewayScript = '';
  statechange = false;
  eventId = null;
  gatewayId;
  deviceEventName = "Select";
  gatewaysGroupName = "Select Device";
  gatewayName = "Select Gateway";
  gatewayEventName = "Select Event";
  //selectGatewaysCount=0;
  sampleinputjson = "";
  viewSampleinputjson:boolean= true;
  GWIDCCodeExample = "// Issue a command to a specific device \n// utils.sendCommand(<deviceId>, <commandTemplateId>, \n \t// <commandArgs>...);\n\n";
  GWBCCodeExample = "// Issue a command to all devices that support the command \n// utils.broadcastCommand(<commandTemplateId>, \n \t// <commandArgs>...);\n\n";
  gatewayUtilExamples = "keyValue";
  triggerEvents = [];
  triggerDeviceTemplates = [];
  triggerDevices = [];
  triggerGeofences = [];
  gatewayDevice;
  ecosystems = [];
  ecosystemTemplates = [];
  subscribersList = [];
  gatewayGroups =[];
  gatewayGroup;
  gatewayDeviceList=[];
  tags = [];
  existingSubscriberList = [];
  existingClassifier = "";

  lifecycleStates = [];

  ecosystemeventstate=1;
  rulePolicyId;
  rulePolicyNameInput;
  rulePolicyDescriptionInput;
  rulePolicyNameSelect = false;
  gatewayDeviceExamples;
  gatewayCommandExamples;
  gatewayBase64UtilExamples = "";
  commandArgs;
  errorInRulePolicyTimeout;
  errorInRulePolicyLogLevel;
  errorInRulePolicyTriggerType;
  errorInRulePolicyCustomMessage;
  ecosystemRulePolicy=false;
  updateEcosystemRulePolicy=false;
  ecosystemRuleRelationship=false;
  ecosystemTemplateRuleRelationship=false;
  gatewayRulPolicy=false;
  updateEcosysTemresponse:any;
  ecosysTemResponse:any;

  extendedRuleMappings=[];
  mappedRulePolicyIds=[];
  sourceEventKey=[];
  auditRefreshFlag=true;

  exampleDrodown = "Unselected";
  gtwRuleSample= 'Upload Event';

  triggerTypes = [
    { value: 1, triggerType: 'Select Trigger' },
    { value: 2, triggerType: 'Device Event' },
    { value: 3, triggerType: 'Device Lifecycle' },
    { value: 4, triggerType: 'Device Presence' },
    { value: 5, triggerType: 'Device Geofence' },
    { value: 6, triggerType: 'Device Health' },
    { value: 7, triggerType: 'Device Tracking' },
    { value: 8, triggerType: 'Ecosystem Event' },
    { value: 9, triggerType: 'Ecosystem Devices' },
    { value: 10, triggerType: 'Ecosystem Persons' },
    { value: 11, triggerType: 'Ecosystem Applications' },
    { value: 12, triggerType: 'Ecosystem Groups' }
  ];

  logLevels = [
    { value: 1, viewLogLevel: 'Normal', logLevelType: 'NORMAL' },
    { value: 2, viewLogLevel: 'Fine', logLevelType: 'FINE' },
    { value: 3, viewLogLevel: 'Trace', logLevelType: 'TRACE' }
  ];

  gatewayRuleSampleType = [
    { value: 'Upload Event', gtwRuleSample: 'Upload Event' },
    { value: 'Invoke Device Command', gtwRuleSample: 'Invoke Device Command' },
    { value: 'Broadcast Command', gtwRuleSample: 'Broadcast Command' },
    { value: 'Misc', gtwRuleSample: 'Misc' }
  ];

  @ViewChild('editor') editor;

  constructor(
    public iotService: IotService,
    private translateService: TranslateService,
    private sideNavComponent: SideNavComponent,
    private router: Router,
    public rulesService: RulesService,private route: ActivatedRoute, public ecosystemTemplatesService:EcosystemTemplatesService, public ecosystemsService:EcosystemsService, public digitalTwinsService:DigitalTwinsService, public digitalTwinTemplatesService:DigitalTwinTemplatesService, public geofenceService:GeofencesService, public gatewayService:GatewaysService, public eventsService:EventsService, private http: HttpClient
  ) { 

    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }

  resetToDefault(){
    const that = this;
    that.setDefault=true;
    that.triggerType="Select Trigger";
  }

  ngOnInit(): void {
    const that= this;
    that.iotService.createRule=true;
    that.iotService.viewRule=true;
    that.reset=false;
    that.resetdata();
    that.rulesService.resetMessage();
    that.rulesService.showDevices = false;
    that.route.params.subscribe(params => {
      var rulePolicyId = params['id'];
      that.rulePolicyId = rulePolicyId;
      that.loadRulePolicy(rulePolicyId);
    });
    that.eventsService.selection.clear();
    that.ecosystemsService.selection.clear();
    that.ecosystemTemplatesService.selection.clear();
    that.geofenceService.selection.clear();
    that.gatewayService.selection.clear();
    that.digitalTwinsService.selection.clear();
    that.digitalTwinTemplatesService.selection.clear();
  }
   
  ngOnDestroy() {
    const that = this;
    that.reset=true;
    that.iotService.createRule=false;
    that.iotService.viewRule=false;
    that.auditFlag=false;
    that.iotService.auditScriptFlag = false;
    that.iotService.auditscript="";
    that.resetdata();
    that.resetToDefault();
    that.rulesService.resetMessage();
    that.iotService.ecosystemTemplateAddRulePolicy=false;
    that.iotService.ecosystemAddRulePolicy=false;
    that.rulesService.ecosystems=[];
    that.rulesService.ecosystemTemplates=[];
    that.ecosystemRulePolicy=false;
    that.eventsService.selection.clear();
    that.ecosystemsService.selection.clear();
    that.ecosystemTemplatesService.selection.clear();
    that.geofenceService.selection.clear();
    that.gatewayService.selection.clear();
    that.digitalTwinsService.selection.clear();
    that.digitalTwinTemplatesService.selection.clear();
  }

  resetdata(){
    const that= this;
    that.eventId=undefined;
    that.gatewayDeviceCount=0;
    that.gateway=undefined;
    that.ecosystems=[];
    that.triggerEvents=[];
    that.ecosystemTemplates=[];
    that.triggerDevices=[];
    that.triggerDeviceTemplates=[];
    that.triggerGeofences=[];
    that.rulesService.selectGatewaysCount=0;
    that.ecosystemRulePolicy=false;
    that.rulesService.showEcosystems=false;
    that.rulesService.showEcosystemTemplates=false;
    that.rulesService.showDevices=false;
    that.rulesService.showDeviceTemplates=false;
    that.iotService.createGatewayRule = false;
    //this.rulesService.setEvents(undefined);
    that.rulesService.setEvents(undefined);
    that.rulesService.selectedEvent=false;
    that.rulesService.devices=[];
    that.rulesService.deviceTemplates=[];
    that.rulesService.ecosystems=[];
    that.rulesService.ecosystemTemplates=[];
    that.rulesService.geofences=[];
    that.rulesService.setGateways(undefined);
    //that.showGatewayDevicesEvents=false;
    that.rulesService.showGatewayDevices=false;
    that.showGateway=false;
    that.rulesService.showGateway=false;
    that.gatewayRulPolicy=false;
    that.updateEcosystemRulePolicy=false;
    that.extendedRuleMappings=[];
    that.mappedRulePolicyIds=[];
    that.sourceEventKey=[];
    that.eventsService.selection.clear();
    that.ecosystemsService.selection.clear();
    that.ecosystemTemplatesService.selection.clear();
    that.geofenceService.selection.clear();
    that.gatewayService.selection.clear();
    that.digitalTwinsService.selection.clear();
    that.digitalTwinTemplatesService.selection.clear();
  }

  rulePolicyName(event: any) {
    const that = this;
    that.rulePolicyNameInput = event.target.value;
    if (that.rulePolicyNameInput.length > 0) {
      that.rulePolicy.name = that.rulePolicyNameInput;
    }
    else {
      that.rulePolicyNameInput.rulePolicyNameSelect = false;
    }
  }

  rulePolicyDescription(event: any) {
    const that = this;
    if(event.target.value != undefined){
      that.rulePolicy.description[0].text = event.target.value;
    }
  }

  ruleTimeoutInput(event: any) {
    const that = this;
    that.rulePolicy.script.timeout = Number(event.target.value);
    if (that.rulePolicy.script.timeout> 0) {
      that.errorInRulePolicyTimeout = '';
    } else {
      that.rulePolicy.script.timeout = '';
    }
  }

  ruleLogLevelInput(event: any) {
    const that = this;
    that.rulePolicy.logLevel = event.target.value.toUpperCase();
    if (that.rulePolicy.logLevel.length> 0) {
      that.errorInRulePolicyLogLevel = '';
    } else {
      that.rulePolicy.logLevel = '';
    }
  }

  ruleViolationMessageInput(event: any) {
    const that = this;
    that.rulePolicy.customMessage = event.target.value;
    if (that.rulePolicy.customMessage.length> 0) {
      that.errorInRulePolicyCustomMessage = '';
    } else {
      that.rulePolicy.customMessage = '';
    }
  }

  ruleTriggerTypeInput(event: any) {
    const that = this;
    that.reset=true;
    that.resetdata();
    if (event.target.value=="Device Event"){
      that.rulePolicy.classifiers = ["DEVICE_EVENT"];
      that.showEventDeviceTab=true;
      that.rulesService.showDeviceTemplates=true;
      that.rulesService.showEcosystemTemplates=true;
    }
    else if (event.target.value=="Device Lifecycle"){
      that.rulePolicy.classifiers = ["DEVICE_LIFECYCLE_STATE_CHANGE"];
      that.rulesService.showDeviceTemplates=true;
    }
    else if (event.target.value=="Device Presence"){
      that.rulePolicy.classifiers = ["DEVICE_OPERATIONAL_STATE_CHANGE"];
      that.rulesService.showDeviceTemplates=true;
    }
    else if (event.target.value=="Device Geofence"){
      that.rulePolicy.classifiers = ["DEVICE_GEOFENCE_STATE_CHANGE"];
      that.rulesService.showDeviceTemplates=true;
    }
    else if (event.target.value=="Device Health"){
      that.rulePolicy.classifiers = ["DEVICE_HEALTH_STATE_CHANGE"];
      that.rulesService.showDeviceTemplates=true;
    }
    else if (event.target.value=="Device Tracking"){
      that.rulePolicy.classifiers = ["DEVICE_TRACKING"];
      that.rulesService.showDeviceTemplates=true;
    }
    else if (event.target.value=="Ecosystem Event"){
     that.rulePolicy.classifiers = ["ECOSYSTEM_EVENT"];
     that.ecosystemRulePolicy=true;
     that.rulesService.showEcosystemTemplates=true;
    }
    else if (event.target.value.includes("Ecosystem")){
      that.ecosystemRulePolicy=true;
      that.rulesService.showEcosystemTemplates=true;
    }
  }

  ruleQualifierInput(event: any) {
    const that = this;
    that.rulePolicy.logLevel = event.target.value;
    if (that.rulePolicy.logLevel.length> 0) {
      that.errorInRulePolicyLogLevel = '';
    } else {
      that.rulePolicy.logLevel = '';
    }
  }

  getEventIds() {
    const that=this;
    that.eventIds = [];
    if (that.rulesService.getEvents() != undefined) {
      that.showEventDeviceTab=true;
      that.eventIds=that.rulesService.getEvents().id;
    }
    else{
        that.showEventDeviceTab=false;
    }
    return that.eventIds;
  }

  getEventId() {
    const that = this;
    that.eventId=undefined;
    if (that.rulesService.getEvents() != undefined) {
      that.eventId=that.rulesService.getEvents().id;
      //that.eventIds.push(that.eventId);
        if(that.showGateway){
          that.selectedGatewayEventTemp=that.rulesService.getEvents();
        }
    }
    return that.eventId;
  }

  getDevices() {
    const that=this;
    that.triggerDevices = [];
    if (that.rulesService.getDevices != undefined) {
      that.rulesService.getDevices().forEach((value) => {
        that.triggerDevices.push(value);
      });
    }
    return that.triggerDevices;
  }

  getDeviceTemplates() {
    const that = this;
    that.triggerDeviceTemplates = [];
    if (that.rulesService.getDeviceTemplates() != undefined) {
    that.rulesService.getDeviceTemplates().forEach((value) => {
        that.triggerDeviceTemplates.push(value);
      });
    }
    return that.triggerDeviceTemplates;
  }
  
  getGeofence() {
    const that = this;
    that.triggerGeofences = [];
    if (that.rulesService.getGeofences() != undefined) {
      that.rulesService.getGeofences().forEach((value) => {
        that.triggerGeofences.push(value);
      });
    }
    return that.triggerGeofences;
  }

  getGateway() {
    const that = this;
    if (that.rulesService.getGateways() != undefined) {
      that.gateway=that.rulesService.getGateways();
      if(that.rulesService.selectGatewaysCount==0){
        that.selectGateways(that.gateway, true);        
      }
    }
    return that.gateway;
  }

  getGatewayDevices() {
    const that = this;
    if (that.rulesService.getGatewayDevice() != undefined) {
      that.gatewayDevice=that.rulesService.getGatewayDevice();
      that.selectedGatewayDevices=that.gatewayDevice;
    }
    return that.gatewayDevice;
  }

  getEcosystems() {
    const that=this;
    that.ecosystems = [];
    if (that.rulesService.getEcosystems != undefined) {
      that.rulesService.getEcosystems().forEach((value) => {
        that.ecosystems.push(value.id);
      });
    }
    return that.ecosystems;
  }

  getEcosystemTemplates() {
    const that=this;
    that.ecosystemTemplates = [];
    if (that.rulesService.getEcosystemTemplates != undefined) {
      that.rulesService.getEcosystemTemplates().forEach((value) => {
        that.ecosystemTemplates.push(value.id);
      });
    }
    return that.ecosystemTemplates;
  }

  back(){
    const that=this;
    that.rulesService.createResource = true;
    that.rulesService.resetMessage();
  }

  updateRule(rulePolicyId) {
    const that = this;
    that.rulePolicy.creator= that.iotService.getCreator();
    that.rulePolicy.creatorAppId= that.iotService.getCreatorAppId();
    that.rulePolicy.realm= that.iotService.getRealm();
    that.rulePolicy.script.value= that.editor.getEncodedAceEditorValue();
    if (that.tags.length>0)
    {
      that.rulePolicy.tags = that.tags;
    }
    that.getEventId();
    that.getDevices();
    that.getDeviceTemplates();
    that.getGeofence();
    that.getGateway();
    that.getGatewayDevices();
    that.getEcosystems();
    that.getEcosystemTemplates();
    that.buildTriggers();
    that.testScript=that.script;
    that.rulePolicy.qualifierExpression=that.qualifierExpression;
    if(that.rulesService.errorMessage=="" && that.rulesService.warningMessage=="")
    {
      that.iotService.getCuiObjResponse().updateRulePolicy({
        rulePolicyId: rulePolicyId,
        data: that.rulePolicy
      })
      .then(function (response) {
        that.rulePolicy.version=response.version;
        that.rulesService.successMessage="Rule Policy updated successfully";
      })
      .fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          that.rulesService.errorMessage = err.responseJSON.apiMessage;
          console.log(
            'Error Occured: Status: ' +
              err.responseJSON.status +
              ' Msg: ' +
              err.responseJSON.apiStatusCode
          );
        } else {
          console.log(
            'Something went wrong. Please check chrome console or check with dev team'
          );
        }
        if (err === null) {
          that.rulesService.errorMessage =
            'Error occured while updating rule policy';
          console.log('Error occured while updating rule policy');
        }
      });
    }
  }

  changeEventType(){
    const that = this;
    that.sampleinputjson = "";
    that.testScript = "";
    that.loadEcosystemExample();
  }

  add(event: MatChipInputEvent): void {
    const that=this;
    that.tagClass = 'tagInput';
    const input = event.input;
    const value = event.value;
    const index = that.tags.indexOf(value);
    if (index < 0) {
      if ((value || '').trim()) {
        if (that.tags != undefined && that.tags.includes(value)) {
          if (input) {
            input.value = '';
          }
        } else {
          that.tags.push(value);
          this.addTag(value);
          if (input) {
            input.value = '';
          }
        }
      }
    } else {
      that.tagClass = 'tagInput-duplicate';
    }
  }

  remove(tag: string): void {
    const that = this;
    that.tags.forEach((value, index) => {
      if (value == tag) that.tags.splice(index, 1);
      that.removeTag(tag);
    });
  }


  addTag(tag) {
    const that = this;
    that.iotService
      .getCuiObjResponse().createRulePolicyTag({
        rulePolicyId: that.rulePolicy.id,
        tag: tag,
      })
      .then(function (response) {
        console.log('Tag added to rule successfully ' + tag);
        var version=parseInt(that.rulePolicy.version);
        version=version+1;
        that.rulePolicy.version=version.toString();
      })
      .fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          that.rulesService.errorMessage = err.responseJSON.apiMessage;
          console.log(
            'Error Occured: Status: ' +
              err.responseJSON.status +
              ' Msg: ' +
              err.responseJSON.apiStatusCode
          );
        } else {
          console.log(
            'Something went wrong. Please check chrome console or check with dev team'
          );
        }
      });
  }

  removeTag(tag) {
    const that = this;
    that.iotService
      .getCuiObjResponse().deleteRulePolicyTag({
        rulePolicyId: that.rulePolicy.id,
        tag: tag,
      })
      .then(function (response) {
        console.log('Tag removed from rule successfully ' + tag);
        var version=parseInt(that.rulePolicy.version);
        version=version+1;
        that.rulePolicy.version=version.toString();
      })
      .fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          that.rulesService.errorMessage = err.responseJSON.apiMessage;
          console.log(
            'Error Occured: Status: ' +
              err.responseJSON.status +
              ' Msg: ' +
              err.responseJSON.apiStatusCode
          );
        } else {
          console.log(
            'Something went wrong. Please check chrome console or check with dev team'
          );
        }
      });
  }

  addMember(): void {
    const that = this;

  }

  removeMember(element) {
    const that = this;

  }

  onChange(event){
    const that=this;
    that.rulesService.resetMessage();
    that.resetdata();
  }


 chkchanged(){
   const that=this;
    if(that.chkBoxModel){
      if(that.gatewayEventName == 'generic_signal'){
        that.script = atob("ZnVuY3Rpb24gcG9saWN5KHV0aWxzLCBpbnB1dCkgew0KDQovKg0KIA0KICAvLyBwYXJzZSBldmVudCBKU09OIGRhdGENCiAgdmFyIGV2ZW50RGF0YSA9IEpTT04ucGFyc2UoaW5wdXQuZGF0YSk7DQogDQogIGlmKGV2ZW50RGF0YS5wcm94aW1pdHkgPiAxOCkgew0KICAgICAgLy8gdXBsb2FkIHRoZSBldmVudCB0byBjbG91ZA0KICAgICAgdXRpbHMudXBsb2FkRXZlbnQoKTsNCiAgfQ0KIA0KICAqLw0KDQp9");
      } else {
        that.script = atob("ZnVuY3Rpb24gcG9saWN5KHV0aWxzLCBpbnB1dCkgew0KDQogIC8qDQoNCiAgaWYgKHBhcnNlRmxvYXQoaW5wdXQuZmx1eFNlbnNvcikgPiA4MCkgew0KDQogICAgLy8gcGVyZm9ybSBpbnZva2UgZGV2aWNlIGNvbW1hbmQgYWN0aW9uIG9uIGEgY29uZGl0aW9uICAgDQogICAgdXRpbHMuc2VuZENvbW1hbmQoImRldmljZS1pZCIsICJjb21tYW5kLXRlbXBsYXRlLWlkIiwgIm5ld19jb2xvcjojMDBhOWU3Iik7DQogIA0KICB9IGVsc2UgaWYgKHBhcnNlRmxvYXQoaW5wdXQuZmx1eFNlbnNvcikgPCAyMCkgew0KDQogICAgLy8gdXBsb2FkIHRoZSBldmVudCB0byBjbG91ZA0KCXV0aWxzLnVwbG9hZEV2ZW50KCk7DQoNCiAgfQ0KDQogICovDQoNCn0=");
      }
    } else {
      that.script = atob("ZnVuY3Rpb24gcG9saWN5KHV0aWxzLCBpbnB1dCkgew0KDQogICAgLyoNCiAgICAvLyBnZXQgdGhlIGRldmljZSBldmVudCBtZXNzYWdlIGZyb20gdGhlIGlucHV0DQogICAgDQogICAgLy8gcGVyZm9ybSBzZW5kIGVtYWlsIGFjdGlvbiBvbiBhIGNvbmRpdGlvbiAgIA0KICAgIGlmIChwYXJzZUZsb2F0KGlucHV0LmZsdXhTZW5zb3IpID4gODApIHsNCiAgICAgIHV0aWxzLmRvQWN0aW9uKCJTRU5EX0VNQUlMIiwgInJlY2lwaWVudHM6YWRkcmVzc0Bub2VtYWlsLmNvbSIsICJzdWJqZWN0OmZsdXggY2FwYWNpdG9yIGFsZXJ0ISIsICJtZXNzYWdlOkZsdXggc2Vuc29yIGlzIGhpZ2ghICIgKyAiIHZhbHVlIGlzICIgKyBmbHV4U2Vuc29yKTsNCiAgICB9ICANCiAgDQogICAgLy8gVGhpcyBwb2xpY3kgaGFzIGFjY2VzcyB0byBnbG9iYWwgc2Vzc2lvbiBhdHRyaWJ1dGVzLg0KDQogICAgLy8gU2V0IGEgdmFsdWUgZm9yIGEga2V5LCBsaWtlIHRoaXM6DQogICAgdXRpbHMuZ2V0U2Vzc2lvbigpLnNldCgibXlfa2V5IiwgIjQyIik7DQoNCiAgICAvLyAuLi5hbmQgdG8gcmV0cmlldmUgdmFsdWVzOg0KICAgIHZhciB2ID0gdXRpbHMuZ2V0U2Vzc2lvbigpLmdldCgibXlfa2V5Iik7DQogICAgLy8gWW91IGNhbiBkZWxldGUgYSBrZXkgYWx0b2dldGhlcjoNCiAgICB1dGlscy5nZXRTZXNzaW9uKCkuZGVsZXRlKCJteV9rZXkiKTsNCg0KICAgIC8vIFRvIHRyYWNrIGNvdW50ZXJzLCB1c2UgdGhlc2UgZnVuY3Rpb25zOg0KICAgIHZhciBjb3VudCA9IHV0aWxzLmdldFNlc3Npb24oKS5pbmNyZW1lbnQoIm15X2NvdW50ZXIiKTsNCiAgICAvLyAuLi5vciBpbmNyZW1lbnQgYnkgYSBnaXZlbiByYW5nZS4uLg0KICAgIGNvdW50ID0gdXRpbHMuZ2V0U2Vzc2lvbigpLmluY3JlbWVudCgibXlfY291bnRlciIsIDEwKTsNCg0KICAgIC8vIFNhbWUgYXBwbGllcyBmb3IgZGVjcmVtZW50Og0KICAgIHV0aWxzLmdldFNlc3Npb24oKS5kZWNyZW1lbnQoIm15X2NvdW50ZXIiKTsNCg0KICAgIC8vIENvdW50ZXJzIGNhbiBiZSBhY2Nlc3NlZCBhdCBhbnkgdGltZToNCiAgICB2YXIgbXlDb3VudGVyID0gdXRpbHMuZ2V0U2Vzc2lvbigpLmdldCgibXlfY291bnRlciIpOw0KICAgICovDQp9");
    }
    that.loadAPIJSON();
    that.refresh();
  }

  loadAPIJSON(){
    const that= this;
    that.completions = [];
    var payload;
            var filepath = "";
              if (!that.chkBoxModel) {
                  filepath = 'assets/json/deviceSnippets.json';
              } else { 
                  filepath = 'assets/json/gatewaySnippets.json';
              }
              payload= that.loadJSONFile(filepath);
              payload.forEach((value,index)=>{
                that.completions.push({
                    caption: value.caption,
                    snippet: value.snippet,
                    meta: value.meta,
                    type: value.type
                });
                });
                that.refresh();
  }
  refresh(){

  }

  buildTriggers() {
      const that = this;
      var triggers = {
          "all": []
      }
      if (that.triggerType == 'Device Event') {
          if (that.chkBoxModel) {
            console.log("testing: "+that.selectedGatewayDevices);
              if (typeof that.selectedGatewayDevices != undefined && that.selectedGatewayDevices != null) {
                  triggers.all.push({
                      "expr": "equal(f:deviceId,v:string(" + that.selectedGatewayDevices.id + "))"
                  });
              }
              var list = [];
              if (typeof that.selectedGatewayEventTemp != null && that.selectedGatewayEventTemp != undefined) {
                list.push({
                    "expr": "equal(f:eventTemplateId,v:string(" + that.selectedGatewayEventTemp.id + "))"
                });
              }

              if (list.length > 0) {
                  triggers.all.push({
                      "any": list
                  });
              }
          } else {
              ///////// EVENT
              if (typeof that.eventId != undefined && that.eventId != null) {
                triggers.all.push({
                    "expr": "equal(f:eventTemplateId,v:string(" + that.eventId + "))"
                });
              }
              var list = [];
              that.triggerDeviceTemplates.forEach((value) => {
                if(typeof value != undefined && value.id!=undefined){
                list.push({
                  "expr": "equal(f:deviceTemplateId,v:string(" + value.id + "))"
              });
              }
              });

              that.triggerDevices.forEach((value) => {
                if(typeof value != undefined && value.id!=undefined){
                list.push({
                  "expr": "equal(f:deviceId,v:string(" + value.id + "))"
              });
              }
              });

              if (list.length > 0) {
                  triggers.all.push({
                      "any": list
                  });
              }

              var ecoList = [];

              that.ecosystemTemplates.forEach((value) => {
                if (typeof value != undefined && value != null) {
                  ecoList.push({
                    "expr": "arrayContains(f:groupTemplateIds,v:string(" + value + "))"
                });
              }
              });
    
              that.ecosystems.forEach((value) => {
                if (typeof value != undefined && value != null) {
                  ecoList.push({
                  "expr": "arrayContains(f:groupIds,v:string(" + value + "))"
              });
              }
              });
              // that.subscribersList.forEach((value) => {
              //   if (value.type == 'ecosystemtemplate') {
              //     ecoList.push({
              //         "expr": "arrayContains(f:groupTemplateIds,v:string(" + value.value.id + "))"
              //     });
              // } else if (value.type == 'group') {
              //     ecoList.push({
              //         "expr": "arrayContains(f:groupIds,v:string(" + value.value.id + "))"
              //     });
              // }
              // });
              if (ecoList.length > 0) {
                  triggers.all.push({
                      "any": ecoList
                  });
              }
          }
      } else if (that.triggerType == 'Device Lifecycle') {
          ///////// Lifecycle
          var from = "";
          var to = "";
          switch (that.lifecyclestate) {
              case 1: // Created
                  from = "";
                  to = "PENDING_ACTIVATION";
                  break;
              case 2: // Registered
                  from = "PENDING_ACTIVATION";
                  to = "ACTIVE";
                  break;
              case 3: // Suspended
                  from = "ACTIVE";
                  to = "SUSPENDED";
                  break;
              case 4: // Unsuspended
                  from = "SUSPENDED";
                  to = "ACTIVE";
                  break;
              case 5: // Locked
                  from = "ACTIVE";
                  to = "LOCKED";
                  break;
              case 6: // Unlocked
                  from = "LOCKED";
                  to = "ACTIVE";
                  break;
              case 7: // Deleted
                  from = "SUSPENDED";
                  to = "DELETED";
                  break;
              case 8: // Reset
                  from = "DELETED";
                  to = "PENDING_ACTIVATION";
                  break;
          }

          if (from != "") {
              triggers.all.push({
                  "expr": "equal(f:fromState,v:string(" + from + "))"
              });
          }
          if (to != "") {
              triggers.all.push({
                  "expr": "equal(f:toState,v:string(" + to + "))"
              });
          }

          // Device Templates
          var list = [];
          that.triggerDeviceTemplates.forEach((value) => {
            if(typeof value != undefined && value.id!=undefined){
            list.push({
              "expr": "equal(f:deviceTemplateId,v:string(" + value.id + "))"
          });
          }
          });
          // Devices
          that.triggerDevices.forEach((value) => {
            if(typeof value != undefined && value.id!=undefined){
            list.push({
              "expr": "equal(f:deviceId,v:string(" + value.id + "))"
          });
          }
          });
          if (list.length > 0) {
              triggers.all.push({
                  "any": list
              })
          }
      } else if (that.triggerType == 'Device Presence') {
          ///////// Presence

          var from = "";
          var to = "";

          if (that.presencestate == 1) {
              from = "STANDBY";
              to = "ONLINE";
          }
          if (that.presencestate == 2) {
              from = "OFFLINE";
              to = "ONLINE";
          }
          if (that.presencestate == 3) {
              from = "ONLINE";
              to = "OFFLINE";
          }

          if (from != "") {
              triggers.all.push({
                  "expr": "equal(f:fromState,v:string(" + from + "))"
              });
          }
          if (to != "") {
              triggers.all.push({
                  "expr": "equal(f:toState,v:string(" + to + "))"
              });
          }


          // Device Templates
          var list = [];          
          that.triggerDeviceTemplates.forEach((value) => {     
            if(typeof value != undefined && value.id!=undefined){       
            list.push({
              "expr": "equal(f:deviceTemplateId,v:string(" + value.id + "))"
          });
          }
          });
          // Devices
          that.triggerDevices.forEach((value) => {
            if(typeof value != undefined && value.id!=undefined){
            list.push({
              "expr": "equal(f:deviceId,v:string(" + value.id + "))"
          });
          }
          });

          if (list.length > 0) {
              triggers.all.push({
                  "any": list
              })
          }
      } else if (that.triggerType == 'Device Geofence') {
          ///////// Presence

          var from = "";
          var to = "";

          if (that.geofencestate == 1) {
              from = "OUTSIDE";
              to = "INSIDE";
          }
          if (that.geofencestate == 2) {
              from = "INSIDE";
              to = "OUTSIDE";
          }

          if (from != "") {
              triggers.all.push({
                  "expr": "equal(f:fromState,v:string(" + from + "))"
              });
          }
          if (to != "") {
              triggers.all.push({
                  "expr": "equal(f:toState,v:string(" + to + "))"
              });
          }


          // Device Templates
          var list = [];
          that.triggerDeviceTemplates.forEach((value) => {
            if(typeof value != undefined && value.id!=undefined){
            list.push({
              "expr": "equal(f:deviceTemplateId,v:string(" + value.id + "))"
          });
          }
          });
          // Devices
          that.triggerDevices.forEach((value) => {
            if(typeof value != undefined && value.id!=undefined){
            list.push({
              "expr": "equal(f:deviceId,v:string(" + value.id + "))"
          });
          }
          });

          if (typeof that.triggerGeofences == undefined || that.triggerGeofences==null || that.triggerGeofences.length==0)
          {
            that.rulesService.warningMessage="Atleast one geofence should be selected";
          }
          else{
            //Geofences
            that.triggerGeofences.forEach((value) => {
              list.push({
                "expr": "equal(f:geofenceId,v:string(" + value.id + "))"
            });
            });
          }

          if (that.triggerDevices.length>0 || that.triggerDeviceTemplates.length>0)
          {
            if (list.length > 0) {
              triggers.all.push({
                  "any": list
              })
          }
          }
          else
          {
            that.rulesService.warningMessage="Atleast one device or device template should be selected";
          }
          
      } else if (that.triggerType == 'Device Health') {
          ///////// Presence

          var from = "";
          var to = "";

          if (that.healthstate == 1) {
              from = "";
              to = "OK";
          }
          if (that.healthstate == 2) {
              from = "";
              to = "WARNING";
          }
          if (that.healthstate == 3) {
              from = "";
              to = "CRITICAL";
          }
          if (that.healthstate == 4) {
              from = "";
              to = "FAILURE";
          }
          if (that.healthstate == 5) {
              from = "";
              to = "SUSPENDED";
          }


          if (from != "") {
              triggers.all.push({
                  "expr": "equal(f:fromState,v:string(" + from + "))"
              });
          }
          if (to != "") {
              triggers.all.push({
                  "expr": "equal(f:toState,v:string(" + to + "))"
              });
          }


          // Device Templates
          var list = [];
          that.triggerDeviceTemplates.forEach((value) => {
            if(typeof value != undefined && value.id!=undefined){
            list.push({
              "expr": "equal(f:deviceTemplateId,v:string(" + value.id + "))"
          });
          }
          });
          // Devices
          that.triggerDevices.forEach((value) => {
            if(typeof value != undefined && value.id!=undefined){
            list.push({
              "expr": "equal(f:deviceId,v:string(" + value.id + "))"
          });
          }
          });

          if (list.length > 0) {
              triggers.all.push({
                  "any": list
              })
          }
      } else if (that.triggerType == 'Device Health Alert') {
          ///////// Presence

          var from = "";
          var to = "";

          if (from != "") {
              triggers.all.push({
                  "expr": "equal(f:fromState,v:string(" + from + "))"
              });
          }
          if (to != "") {
              triggers.all.push({
                  "expr": "equal(f:toState,v:string(" + to + "))"
              });
          }


          // Device Templates
          var list = [];
          that.triggerDeviceTemplates.forEach((value) => {
            if(typeof value != undefined && value.id!=undefined){
            list.push({
              "expr": "equal(f:deviceTemplateId,v:string(" + value.id + "))"
          });
          }
          });
          // Devices
          that.triggerDevices.forEach((value) => {
            if(typeof value != undefined && value.id!=undefined){
            list.push({
              "expr": "equal(f:deviceId,v:string(" + value.id + "))"
          });
          }
          });

          if (list.length > 0) {
              triggers.all.push({
                  "any": list
              })
          }
      } else if (that.triggerType == 'Device Tracking') {


          var from = "";
          var to = "";

          if (that.deviceTrackingState == 2) {
              from = "IDLE";
              to = "NONIDLE";
          }
          if (that.deviceTrackingState == 1) {
              from = "NONIDLE";
              to = "IDLE";
          }

          if (from != "") {
              triggers.all.push({
                  "expr": "equal(f:fromState,v:string(" + from + "))"
              });
          }
          if (to != "") {
              triggers.all.push({
                  "expr": "equal(f:toState,v:string(" + to + "))"
              });
          }


          // Device Templates
          var list = [];
          that.triggerDeviceTemplates.forEach((value) => {
            if(typeof value != undefined && value.id!=undefined){
            list.push({
              "expr": "equal(f:deviceTemplateId,v:string(" + value.id + "))"
          });
          }
          });
          // Devices
          that.triggerDevices.forEach((value) => {
            if(typeof value != undefined && value.id!=undefined){
            list.push({
              "expr": "equal(f:deviceId,v:string(" + value.id + "))"
          });
          }
          });

          //Geofences
          that.triggerGeofences.forEach((value) => {
            list.push({
              "expr": "equal(f:geofenceId,v:string(" + value.id + "))"
          });
          });


          if (list.length > 0) {
              triggers.all.push({
                  "any": list
              })
          }
      } else if (that.triggerType == 'Ecosystem Event') {
          ///////// Ecosystem Event

          var list = [];

          //EventTemplateId
          if (typeof that.eventId != undefined && that.eventId != null) {
            triggers.all.push({
                "expr": "equal(f:eventTemplateId,v:string(" + that.eventId + "))"
            });
          }

          // Group Templates
          that.ecosystemTemplates.forEach((value) => {
            if (typeof value != undefined && value != null) {
              list.push({
                "expr": "arrayContains(f:groupTemplateIds,v:string(" + value + "))"
            });
          }
          });
          // Groups
          that.ecosystems.forEach((value) => {
            if (typeof value != undefined && value != null) {
              list.push({
              "expr": "arrayContains(f:groupIds,v:string(" + value + "))"
          });
          }
          });
          
          // that.subscribersList.forEach((value) => {
          //   if (value.type == 'ecosystemtemplate') {
          //     list.push({
          //         "expr": "equal(f:groupTemplateId,v:string(" + value.value.id + "))"
          //     })
          // } else if (value.type == 'group') {
          //     list.push({
          //         "expr": "equal(f:groupId,v:string(" + value.value.id + "))"
          //     })
          // }
          // });

          if (list.length > 0) {
              triggers.all.push({
                  "any": list
              })
          }
        }
      else if (that.triggerType.includes('Ecosystem')) {
        ///////// Ecosystem Devices
        var list = [];
        // Groups
        that.ecosystemTemplates.forEach((value) => {
            list.push({
                "expr": "arrayContains(f:groupTemplateIds,v:string(" + value + "))"
            });
          });

          that.ecosystems.forEach((value) => {
            list.push({
              "expr": "arrayContains(f:groupIds,v:string(" + value + "))"
          });
          });

        if (list.length > 0) {
            triggers.all.push({
                "any": list
            })
        }
    }
      if (triggers.all.length > 0) {
          that.qualifierExpression = triggers;
      } else {
          triggers = {
            "all": []
          };
      }
      return triggers;
  }

  loadJSONFile(filepath) {
    const that= this;
    return that.http.get(filepath);
  }

  changeType(type) {
  const that=this;
    that.triggerType = type;
    //that.subscribersList = [];
    that.sampleinputjson = "";
    that.testScript = "";
    that.eventId = "";
    that.deviceEventName = "Select";
    that.triggerDeviceTemplates = [];
    that.triggerDevices = [];
    that.subscribersList = [];
      //var default=that.script;
      //that.originScript=default.slice(0);
      //console.log(that.originScript);
    if(type=="Ecosystem Applications"){
      that.ecosystemapplicationstate = 1;
          //that.script=that.originScript;
          that.script=atob(that.defaultScript);
          that.rulesService.showEcosystemTemplates=true;
      that.getEcosystemTemplates();
      that.chkBoxModel = false;
          that.ecosystemEvent=true;
      that.changeEventType();
    } else if(type=="Ecosystem Persons"){
      that.ecosystempersonstate = 1;
          //that.script=that.originScript;
          that.script=atob(that.defaultScript);
          that.rulesService.showEcosystemTemplates=true;
      that.getEcosystemTemplates();
      that.chkBoxModel = false;
          that.ecosystemEvent=true;
      that.changeEventType();
    } else if(type=="Ecosystem Devices"){
      that.ecosystemdevicestate = 1;
          //that.script=that.originScript;
          that.script=atob(that.defaultScript);
          that.rulesService.showEcosystemTemplates=true;
      that.getEcosystemTemplates();
      that.chkBoxModel = false;
          that.ecosystemEvent=true;
      that.changeEventType();
    } else if(type=="Ecosystem Groups"){
      that.ecosystemgroupstate = 1;
          //that.script=that.originScript;
          that.script=atob(that.defaultScript);
          that.rulesService.showEcosystemTemplates=true;
      that.getEcosystemTemplates();
      that.chkBoxModel = false;
          that.ecosystemEvent=true;
      that.changeEventType();
    } else if(type=="Ecosystem Event"){
      that.ecosystemeventstate = 1;
      that.rulesService.showEcosystemTemplates=true;
      that.getEcosystemTemplates();
          var script=that.script;
          script=script.replace(" // get the device event message from the input","");
          that.script=script;
          //console.log(script);
          //console.log("ecosystem Event!!")
          that.ecosystemEvent=false;
      //that.script = atob("ZnVuY3Rpb24gcG9saWN5KHV0aWxzLCBpbnB1dCkgew0KDQogICAgLyoNCiAgICANCiAgICAvLyBwZXJmb3JtIHNlbmQgZW1haWwgYWN0aW9uIG9uIGEgY29uZGl0aW9uICAgDQogICAgaWYgKHBhcnNlRmxvYXQoaW5wdXQuZmx1eFNlbnNvcikgPiA4MCkgew0KICAgICAgdXRpbHMuZG9BY3Rpb24oIlNFTkRfRU1BSUwiLCAicmVjaXBpZW50czphZGRyZXNzQG5vZW1haWwuY29tIiwgInN1YmplY3Q6Zmx1eCBjYXBhY2l0b3IgYWxlcnQhIiwgIm1lc3NhZ2U6Rmx1eCBzZW5zb3IgaXMgaGlnaCEgIiArICIgdmFsdWUgaXMgIiArIGZsdXhTZW5zb3IpOw0KICAgIH0gIA0KICANCiAgICAvLyBUaGlzIHBvbGljeSBoYXMgYWNjZXNzIHRvIGdsb2JhbCBzZXNzaW9uIGF0dHJpYnV0ZXMuDQoNCiAgICAvLyBTZXQgYSB2YWx1ZSBmb3IgYSBrZXksIGxpa2UgdGhpczoNCiAgICB1dGlscy5nZXRTZXNzaW9uKCkuc2V0KCJteV9rZXkiLCAiNDIiKTsNCg0KICAgIC8vIC4uLmFuZCB0byByZXRyaWV2ZSB2YWx1ZXM6DQogICAgdmFyIHYgPSB1dGlscy5nZXRTZXNzaW9uKCkuZ2V0KCJteV9rZXkiKTsNCiAgICAvLyBZb3UgY2FuIGRlbGV0ZSBhIGtleSBhbHRvZ2V0aGVyOg0KICAgIHV0aWxzLmdldFNlc3Npb24oKS5kZWxldGUoIm15X2tleSIpOw0KDQogICAgLy8gVG8gdHJhY2sgY291bnRlcnMsIHVzZSB0aGVzZSBmdW5jdGlvbnM6DQogICAgdmFyIGNvdW50ID0gdXRpbHMuZ2V0U2Vzc2lvbigpLmluY3JlbWVudCgibXlfY291bnRlciIpOw0KICAgIC8vIC4uLm9yIGluY3JlbWVudCBieSBhIGdpdmVuIHJhbmdlLi4uDQogICAgY291bnQgPSB1dGlscy5nZXRTZXNzaW9uKCkuaW5jcmVtZW50KCJteV9jb3VudGVyIiwgMTApOw0KDQogICAgLy8gU2FtZSBhcHBsaWVzIGZvciBkZWNyZW1lbnQ6DQogICAgdXRpbHMuZ2V0U2Vzc2lvbigpLmRlY3JlbWVudCgibXlfY291bnRlciIpOw0KDQogICAgLy8gQ291bnRlcnMgY2FuIGJlIGFjY2Vzc2VkIGF0IGFueSB0aW1lOg0KICAgIHZhciBteUNvdW50ZXIgPSB1dGlscy5nZXRTZXNzaW9uKCkuZ2V0KCJteV9jb3VudGVyIik7DQogICAgKi8NCn0=");
      that.chkBoxModel = false;
      that.changeEventType();
      that.refresh();
    } else if(type=="Device Event"){
          //that.script=that.originScript;
          that.script=atob(that.defaultScript);
          that.rulesService.showDeviceTemplates=true;
          that.rulesService.showEcosystemTemplates=true;
        that.getDeviceTemplates();
        that.getDevices();
        that.getEcosystemTemplates();
        //that.buildTriggers();
        that.changeEventType();
          that.ecosystemEvent=true;
    } else if(type=="Device Lifecycle"){
          //that.script=that.originScript;
          that.script=atob(that.defaultScript);
          that.rulesService.showDeviceTemplates=true;
      that.lifecyclestate = 1;
        that.getDeviceTemplates();
        that.getDevices();
        that.chkBoxModel = false;
       // that.buildTriggers();
        that.changeEventType();
          that.ecosystemEvent=true;
    } else if(type=="Device Presence"){
          //that.script=that.originScript;
          that.script=atob(that.defaultScript);
          that.rulesService.showDeviceTemplates=true;
      that.presencestate = 1;
        that.getDeviceTemplates();
        that.getDevices();
        that.chkBoxModel = false;
       // that.buildTriggers();
        that.changeEventType();
          that.ecosystemEvent=true;
  }
  else if(type=="Device Geofence"){
          //that.script=that.originScript;
          that.script=atob(that.defaultScript);
          that.rulesService.showDeviceTemplates=true;
      that.geofencestate = 1;
        that.getDeviceTemplates();
        that.getDevices();
        that.chkBoxModel = false;
      //  that.buildTriggers();
        that.changeEventType();
          that.ecosystemEvent=true;
    }
      else if(type=="Device Health"){
          //that.script=that.originScript;
          that.script=atob(that.defaultScript);
          that.rulesService.showDeviceTemplates=true;
          that.healthstate = 1;
          that.getDeviceTemplates();
          that.getDevices();
          that.chkBoxModel = false;
        //  that.buildTriggers();
          that.changeEventType();
          that.ecosystemEvent=true;
      }
      else if(type=="Device Health Alert"){
          //that.script=that.originScript;
          that.script=atob(that.defaultScript);
          that.rulesService.showDeviceTemplates=true;
          that.healthalert = 1;
          that.getDeviceTemplates();
          that.getDevices();
          that.chkBoxModel = false;
         // that.buildTriggers();
          that.changeEventType();
          that.ecosystemEvent=true;
      }
      else if (type == "Device Tracking") {
          //that.script=that.originScript;
          that.script = atob(that.defaultScript);
          that.rulesService.showDeviceTemplates=true;
          that.deviceTrackingState = 1;
          that.getDeviceTemplates();
          that.getDevices();
          that.chkBoxModel = false;
         // that.buildTriggers();
          that.changeEventType();
          that.ecosystemEvent = true;
      }
  // window.setTimeout(that.beautify2, 100);
  }

  safeToBeautify(e) {
    const that =this;
      if (!that.beautifying && e.action === 'insert' && e.start.row === 0 && e.start.column === 0) {
          return true;
      } else {
          return false;
      }
  }

  beautify2() {
  const that= this;
  var beautifying = true;
  //  var session = that.editor2.getSession();
  var session;
    // Get current text from editor
    var val = session.getValue();
    //cui.log('beautify before', val);

    // Remove leading spaces
    var array = val.split(/\n/);
    array[0] = array[0].trim();
    val = array.join('\n');

    // Beautify
    //val = js_beautify(val, beautificationOptions);
    //cui.log('beautify after', val);

    // Change current text to beautified text
    session.setValue(val);
    beautifying = false;
  };

  beautificationOptions = {
    indent_size: 2,
    indent_with_tabs: false,
  };

  loadEcosystemExample(){
    const that= this;
    that.type=that.triggerType;
    if(that.type == "Device Event"){
      that.loadJSONFile("assets/json/event.json").subscribe(payload => {
      
      that.sampleinputjson = JSON.stringify(payload,null,4);
      that.testScript = JSON.stringify(payload,null,4);
    });
      } else if(that.type == "Device Lifecycle"){
        switch (that.lifecyclestate) {
          case 1: // Created
          that.loadJSONFile("assets/json/lifecycleCreate.json").subscribe(payload => {
                  that.sampleinputjson = JSON.stringify(payload,null,4);
                  that.testScript = JSON.stringify(payload,null,4);
          });
                  break;
                case 2: // Registered
                that.loadJSONFile("assets/json/lifecycleRegister.json").subscribe(payload => {
                    that.sampleinputjson = JSON.stringify(payload,null,4);
                    that.testScript = JSON.stringify(payload,null,4);
                });
                    break;
                case 3: // Suspended
                that.loadJSONFile("assets/json/lifecycleSuspend.json").subscribe(payload => {
                    that.sampleinputjson = JSON.stringify(payload,null,4);
                    that.testScript = JSON.stringify(payload,null,4);
                });
                    break; 
                case 4: // Unsuspended
                that.loadJSONFile("assets/json/lifecycleUnsuspend.json").subscribe(payload => {
                    that.sampleinputjson = JSON.stringify(payload,null,4);
                    that.testScript = JSON.stringify(payload,null,4);
                });
                    break;
                case 5: // Locked
                that.loadJSONFile("assets/json/lifecycleLocked.json").subscribe(payload => {
                    that.sampleinputjson = JSON.stringify(payload,null,4);
                    that.testScript = JSON.stringify(payload,null,4);
                });
                    break;
                case 6: // Unlocked
                that.loadJSONFile("assets/json/lifecycleUnlocked.json").subscribe(payload => {
                    that.sampleinputjson = JSON.stringify(payload,null,4);
                    that.testScript = JSON.stringify(payload,null,4);
                });
                    break;
                case 7: // Deleted
                that.loadJSONFile("assets/json/lifecycleDelete.json").subscribe(payload => {
                    that.sampleinputjson = JSON.stringify(payload,null,4);
                    that.testScript = JSON.stringify(payload,null,4);
                });
                    break;
                case 8: // Reset
                that.loadJSONFile("assets/json/lifecycleReset.json").subscribe(payload => {
                    that.sampleinputjson = JSON.stringify(payload,null,4);
                    that.testScript = JSON.stringify(payload,null,4);
                });
                    break;
        }
      } else if(that.type == "Device Presence"){
        		switch (that.presencestate) {
        			case 1: // Intially Connected
              that.loadJSONFile("assets/json/presenceIntiallyConnected.json").subscribe(payload => {
                  		that.sampleinputjson = JSON.stringify(payload,null,4);
                  		that.testScript = JSON.stringify(payload,null,4);
              });
                  	 break;
                   case 2: // Connected
                   that.loadJSONFile("assets/json/presenceConnected.json").subscribe(payload => {
                   		that.sampleinputjson = JSON.stringify(payload,null,4);
                   		that.testScript = JSON.stringify(payload,null,4);
                   });
                       break;
                   case 3: // Disconnected
                   that.loadJSONFile("assets/json/presenceDisconnected.json").subscribe(payload => {
                   		that.sampleinputjson = JSON.stringify(payload,null,4);
                  		that.testScript = JSON.stringify(payload,null,4);
                   });
                       break;
        		}
			}else if(that.type == "Device Geofence"){
				
				switch (that.geofencestate) {
					case 1: // Intially Connected
					
          that.loadJSONFile("assets/json/deviceGeofenceInside.json").subscribe(payload => {
                  		that.sampleinputjson = JSON.stringify(payload,null,4);
                  		that.testScript = JSON.stringify(payload,null,4);
          });
                  	 break;
                   case 2: // Connected
                   that.loadJSONFile("assets/json/deviceGeofenceOutside.json").subscribe(payload => {
                   		that.sampleinputjson = JSON.stringify(payload,null,4);
                   		that.testScript = JSON.stringify(payload,null,4);
                   });
                       break;
        		}
      }else if (that.type == "Device Tracking") {

          switch (that.deviceTrackingState) {
              case 1:
              that.loadJSONFile("assets/json/deviceTrackingIdle.json").subscribe(payload => {
                      that.sampleinputjson = JSON.stringify(payload, null, 4);
                      that.testScript = JSON.stringify(payload, null, 4);
              });
                  break;
              case 2: 
                  that.loadJSONFile("assets/json/deviceTrackingNonIdle.json").subscribe(payload => {
                    payload=  that.sampleinputjson = JSON.stringify(payload, null, 4);
                      that.testScript = JSON.stringify(payload, null, 4);
                  
                  }); break;
          }
      }else if(that.type == "Device Health"){
          switch (that.healthstate) {
              case 1: // Intially Connected
              that.loadJSONFile("assets/json/deviceHealthOk.json").subscribe(payload => {
                  that.sampleinputjson = JSON.stringify(payload,null,4);
                  that.testScript = JSON.stringify(payload,null,4);
                
                }); break;
              case 2: // Connected
              that.loadJSONFile("assets/json/deviceHealthWarning.json").subscribe(payload => {
                  that.sampleinputjson = JSON.stringify(payload,null,4);
                  that.testScript = JSON.stringify(payload,null,4);
                
                  }); break;
              case 3: // Disconnected
              that.loadJSONFile("assets/json/deviceHealthCritical.json").subscribe(payload => {
                  that.sampleinputjson = JSON.stringify(payload,null,4);
                  that.testScript = JSON.stringify(payload,null,4);
                
                  }); break;
              case 4: // Disconnected
              that.loadJSONFile("assets/json/deviceHealthFailure.json").subscribe(payload => {
                  that.sampleinputjson = JSON.stringify(payload,null,4);
                  that.testScript = JSON.stringify(payload,null,4);
                
                  }); break;
              case 5: // Disconnected
              that.loadJSONFile('assets/json/deviceHealthSuspended.json').subscribe(payload => {
                  that.sampleinputjson = JSON.stringify(payload,null,4);
                  that.testScript = JSON.stringify(payload,null,4);
                
                  }); break;
          }
      }else if(that.type == "Ecosystem Event"){
        that.loadJSONFile("assets/json/ecosystemEvent.json").subscribe(payload => {
          that.sampleinputjson = JSON.stringify(payload,null,4);
          that.testScript = JSON.stringify(payload,null,4);
        });
      }else if(that.type == "Ecosystem Devices"){
       		  switch (that.ecosystemdevicestate) {
                case 1: // Created
                that.loadJSONFile('assets/json/ecosystemDeviceCreate.json').subscribe(payload => {
               		that.sampleinputjson = JSON.stringify(payload,null,4);
               		that.testScript = JSON.stringify(payload,null,4);
               	 
               	 }); break;
                case 2: // Registered
                that.loadJSONFile('assets/json/ecosystemDeviceRegister.json').subscribe(payload => {
                		that.sampleinputjson = JSON.stringify(payload,null,4);
                		that.testScript = JSON.stringify(payload,null,4);
                	 
                    }); break;
                case 3: // Updated
                that.loadJSONFile('assets/json/ecosystemDeviceUpdate.json').subscribe(payload => {
                		that.sampleinputjson = JSON.stringify(payload,null,4);
               		that.testScript = JSON.stringify(payload,null,4);
                });
                    break; 
                case 4: // Deleted
                that.loadJSONFile("assets/json/ecosystemDeviceDelete.json").subscribe(payload => {
                	that.sampleinputjson = JSON.stringify(payload,null,4);
               		that.testScript = JSON.stringify(payload,null,4);
                });
                    break;
                case 5: // Activated
                that.loadJSONFile("assets/json/ecosystemDeviceActivate.json").subscribe(payload => {
                		that.sampleinputjson = JSON.stringify(payload,null,4);
               		that.testScript = JSON.stringify(payload,null,4);
                });
                    break;
                case 6: // Deactivate
                that.loadJSONFile("assets/json/ecosystemDeviceDeactivate.json").subscribe(payload => {
                		that.sampleinputjson = JSON.stringify(payload,null,4);
               		that.testScript = JSON.stringify(payload,null,4);
                });
                    break;
                case 7: // Reset
                that.loadJSONFile("assets/json/ecosystemDeviceReset.json").subscribe(payload => {
                		that.sampleinputjson = JSON.stringify(payload,null,4);
               		that.testScript = JSON.stringify(payload,null,4);
                });
                    break;
                case 8: // Locked
                that.loadJSONFile("assets/json/ecosystemDeviceLocked.json").subscribe(payload => {
                		that.sampleinputjson = JSON.stringify(payload,null,4);
               		that.testScript = JSON.stringify(payload,null,4);
                });
                    break;
                case 9: // Unlocked
                that.loadJSONFile("assets/json/ecosystemDeviceUnlocked.json").subscribe(payload => {
                		that.sampleinputjson = JSON.stringify(payload,null,4);
               		that.testScript = JSON.stringify(payload,null,4);
                });
                    break;
                case 10: // Suspended
                that.loadJSONFile("assets/json/ecosystemDeviceSuspended.json").subscribe(payload => {
                		that.sampleinputjson = JSON.stringify(payload,null,4);
               		that.testScript = JSON.stringify(payload,null,4);
                	 
                    }); break;
                case 11: // Unsuspended
                that.loadJSONFile("assets/json/ecosystemDeviceUnsuspended.json").subscribe(payload => {
                		that.sampleinputjson = JSON.stringify(payload,null,4);
               		that.testScript = JSON.stringify(payload,null,4);
                	 
                    }); break;
                case 12: // Online
                that.loadJSONFile("assets/json/ecosystemDeviceOnline.json").subscribe(payload => {
                		that.sampleinputjson = JSON.stringify(payload,null,4);
               		that.testScript = JSON.stringify(payload,null,4);
                	 
                    }); break;
                case 13: // Offline
                that.loadJSONFile("assets/json/ecosystemDeviceOffline.json").subscribe(payload => {
                		that.sampleinputjson = JSON.stringify(payload,null,4);
               		that.testScript = JSON.stringify(payload,null,4);
                	 
                    }); break;
                case 14: // Migrate
                that.loadJSONFile("assets/json/ecosystemDeviceMigrate.json").subscribe(payload => {
                		that.sampleinputjson = JSON.stringify(payload,null,4);
               		that.testScript = JSON.stringify(payload,null,4);
                	 
                    }); break;
                }
      }else if(that.type == "Ecosystem Persons"){
        switch (that.ecosystempersonstate) {
            case 1: // Created
            that.loadJSONFile("assets/json/ecosystemPersonCreate.json").subscribe(payload => {
                    that.sampleinputjson = JSON.stringify(payload,null,4);
                    that.testScript = JSON.stringify(payload,null,4);
                    
                    }); break;
                case 2: // Activated
                that.loadJSONFile("assets/json/ecosystemPersonActivate.json").subscribe(payload => {
                that.sampleinputjson = JSON.stringify(payload,null,4);
                that.testScript = JSON.stringify(payload,null,4);
                  
                    }); break;
                case 3: // Updated
                that.loadJSONFile("assets/json/ecosystemPersonUpdate.json").subscribe(payload => {
                    that.sampleinputjson = JSON.stringify(payload,null,4);
                    that.testScript = JSON.stringify(payload,null,4);
                    
                    }); break; 
                case 4: // Suspended
                that.loadJSONFile("assets/json/ecosystemPersonSuspend.json").subscribe(payload => {
                    that.sampleinputjson = JSON.stringify(payload,null,4);
                    that.testScript = JSON.stringify(payload,null,4);
                    
                    }); break;
                case 5: // Unsuspended
                that.loadJSONFile("assets/json/ecosystemPersonUnsuspended.json").subscribe(payload => {
                    that.sampleinputjson = JSON.stringify(payload,null,4);
                    that.testScript = JSON.stringify(payload,null,4);
                    
                    }); break;
        }
      }else if(that.type == "Ecosystem Applications"){
        switch (that.ecosystemapplicationstate) {
            case 1: // Created
            that.loadJSONFile("assets/json/ecosystemApplicationCreate.json").subscribe(payload => {
                    that.sampleinputjson = JSON.stringify(payload,null,4);
                    that.testScript = JSON.stringify(payload,null,4);
                    
                    }); break;
                case 2: // Registered
                that.loadJSONFile("assets/json/ecosystemApplicationRegister.json").subscribe(payload => {
                    that.sampleinputjson = JSON.stringify(payload,null,4);
                    that.testScript = JSON.stringify(payload,null,4);
                    
                    }); break;
                case 3: // Updated
                that.loadJSONFile("assets/json/ecosystemApplicationUpdate.json").subscribe(payload => {
                    that.sampleinputjson = JSON.stringify(payload,null,4);
                    that.testScript = JSON.stringify(payload,null,4);
                    
                    }); break; 
                case 4: // Deleted
                that.loadJSONFile("assets/json/ecosystemApplicationDelete.json").subscribe(payload => {
                    that.sampleinputjson = JSON.stringify(payload,null,4);
                    that.testScript = JSON.stringify(payload,null,4);
                    
                    }); break;
        }
      }else if(that.type == "Ecosystem Groups"){
        switch (that.ecosystemgroupstate) {
            case 1: // Created
            that.loadJSONFile("assets/json/ecosystemGroupCreate.json").subscribe(payload => {
                    that.sampleinputjson = JSON.stringify(payload,null,4);
                    that.testScript = JSON.stringify(payload,null,4);
                    
                    }); break;
                case 2: // Activated
                that.loadJSONFile("assets/json/ecosystemGroupActivate.json").subscribe(payload => {
                    that.sampleinputjson = JSON.stringify(payload,null,4);
                    that.testScript = JSON.stringify(payload,null,4);
                    
                    }); break;
                case 3: // Deactivate
                that.loadJSONFile("assets/json/ecosystemGroupDeactivate.json").subscribe(payload => {
                    that.sampleinputjson = JSON.stringify(payload,null,4);
                    that.testScript = JSON.stringify(payload,null,4);
                    
                    }); break;
                case 4: // Updated
                that.loadJSONFile("assets/json/ecosystemGroupUpdate.json").subscribe(payload => {
                    that.sampleinputjson = JSON.stringify(payload,null,4);
                    that.testScript = JSON.stringify(payload,null,4);
                    
                    }); break;
                case 5: // Suspended
                that.loadJSONFile("assets/json/ecosystemGroupSuspend.json").subscribe(payload => {
                    that.sampleinputjson = JSON.stringify(payload,null,4);
                    that.testScript = JSON.stringify(payload,null,4);
                    
                    }); break;
                case 6: // Unsuspended
                that.loadJSONFile("assets/json/ecosystemGroupUnsuspend.json").subscribe(payload => {
                    that.sampleinputjson = JSON.stringify(payload,null,4);
                    that.testScript = JSON.stringify(payload,null,4);
                    
                    }); break;
                case 7: // AddEntitlement
                that.loadJSONFile("assets/json/ecosystemGroupAddEntitlement.json").subscribe(payload => {
                    that.sampleinputjson = JSON.stringify(payload,null,4);
                    that.testScript = JSON.stringify(payload,null,4);
                    
                    }); break;
                case 8: // RemoveEntitlement
                that.loadJSONFile("assets/json/ecosystemGroupRemoveEntitlement.json").subscribe(payload => {
                    that.sampleinputjson = JSON.stringify(payload,null,4);
                    that.testScript = JSON.stringify(payload,null,4);
                    
                    }); break;
                case 9: // Deleted
                that.loadJSONFile("assets/json/ecosystemGroupDelete.json").subscribe(payload => {
                    that.sampleinputjson = JSON.stringify(payload,null,4);
                    that.testScript = JSON.stringify(payload,null,4);
                    
                    }); break;
                case 10: // AddMember
                that.loadJSONFile("assets/json/ecosystemGroupAddMember.json").subscribe(payload => {
                    that.sampleinputjson = JSON.stringify(payload,null,4);
                    that.testScript = JSON.stringify(payload,null,4);
                    
                    }); break;
                case 11: // RemoveMember
                that.loadJSONFile("assets/json/ecosystemGroupRemoveMember.json").subscribe(payload => {
                    that.sampleinputjson = JSON.stringify(payload,null,4);
                    that.testScript = JSON.stringify(payload,null,4);
                    
                    }); break;
                case 12: // AddRelationship
                that.loadJSONFile("assets/json/ecosystemGroupAddRelationship.json").subscribe(payload => {
                    that.sampleinputjson = JSON.stringify(payload,null,4);
                    that.testScript = JSON.stringify(payload,null,4);
                    
                    }); break;
                case 13: // RemoveRelationship
                that.loadJSONFile("assets/json/ecosystemGroupRemoveRelationship.json").subscribe(payload => {
                    that.sampleinputjson = JSON.stringify(payload,null,4);
                    that.testScript = JSON.stringify(payload,null,4);
                    
                    }); break;
        }
      }
      that.refresh();
  }

  // OnSelection(event){

  // }

  gatewayExamples(event: any){
    const that = this;
    that.gatewayCodeExamples=event.target.value;
    if(that.gatewayCodeExamples == 'Upload Event'){
      that.gatewayCode = ' // Send the event to the cloud platform for additional processing. \n\n utils.uploadEvent();';
      that.gatewayDeviceExamples = {};
      that.gatewayCommandExamples = "";
      that.gatewayUtilExamples = "";
      that.gatewayBase64UtilExamples = "";
      that.refresh();
    } else if(that.gatewayCodeExamples == 'Invoke Device Command'){
      //that.gatewayDeviceExamples = that.gatewayDevices[0];
      that.gatewayCode = '';
      that.gatewayDeviceExamples = {};
      that.gatewayCommandExamples = "";
      that.gatewayUtilExamples = "";
      that.gatewayBase64UtilExamples = "";
      that.refresh();
    } else if(that.gatewayCodeExamples == 'Broadcast Command'){
      //that.gatewayCommandExamples = that.supportedCommands[0];
      that.gatewayCode = '';
      that.gatewayDeviceExamples = {};
      that.gatewayCommandExamples = "";
      that.gatewayUtilExamples = "";
      that.gatewayBase64UtilExamples = "";
      that.refresh();
    } else if(that.gatewayCodeExamples == 'Misc'){
      that.gatewayCode = '';
      that.gatewayDeviceExamples = {};
      that.gatewayCommandExamples = "";
      that.gatewayUtilExamples = "keyValue";
      that.updateUtilExample();
      that.gatewayBase64UtilExamples = "";
      that.refresh();
    } else if(that.gatewayCodeExamples == 'Base64Utils'){
      that.gatewayCode = '';
      that.gatewayDeviceExamples = {};
      that.gatewayCommandExamples = "";
      that.gatewayUtilExamples = "";
      that.gatewayBase64UtilExamples = "Encode";
      that.updateBase64UtilExample();
      that.refresh();
    }
  }
  updateUtilExample(){
    const that =this;
    if(that.gatewayUtilExamples == 'keyValue'){
      that.gatewayCode = ' // Set a value for a key, like this: \n utils.kvSet("my_key", "42"); \n\n // ...and to retrieve values: \n var v = utils.kvGet("my_key");\n\n // You can delete a key altogether: \n utils.kvDelete("my_key");';
    } else if(that.gatewayUtilExamples == 'ManageCounters'){
      that.gatewayCode = ' // To track counters, use these functions: \n var count = utils.kvIncrement("my_counter"); \n\n // ...or increment by a given range... \n count = utils.kvIncrement("my_counter", 10); \n\n // Same applies for decrement:\n utils.kvDecrement("my_counter");\n\n // Counters can be accessed at any time:\n var myCounter = utils.kvGet("my_counter")';
    } else if(that.gatewayUtilExamples == 'GPSLocation'){
      that.gatewayCode = 'var position = utils.getGatewayPosition(); \n\n /* \n { \n\t "latitude": "32.776475",\n\t "longitude": "-79.931051", \n\t "elevation": "-3492", \n\t "fixPrecision": "1.0", \n\t "bearing": "127", \n\t  "velocity": "13.5" \n } \n */';
    }
    that.refresh();
  }

  updateBase64UtilExample(){
    const that=this;
    if(that.gatewayBase64UtilExamples == 'Encode'){
      that.gatewayCode = 'var encoded = utils.base64.encode("a string to encode"); \n// "YSBzdHJpbmcgdG8gZW5jb2Rl"';
    } else if(that.gatewayBase64UtilExamples == 'Decode'){
      that.gatewayCode = 'var decoded = utils.base64.decode("YSBzdHJpbmcgdG8gZW5jb2Rl"); \n// "a string to encode"';
    }
    that.refresh();
  }

  updateGWDevExample(){
    const that =this;
    that.gatewayCode = 'utils.broadcastCommand(';
    if(typeof that.gatewayDeviceExamples !== "object"){
      //that.gatewayDeviceExamples = JSON.parse(that.gatewayDeviceExamples);
      that.gatewayDeviceExamples = that.gatewayDeviceExamples;
    }
    if(that.gatewayCodeExamples == 'Invoke Device Command'){
      that.gatewayCode = 'utils.sendCommand(';
    }
    if(typeof that.gatewayDeviceExamples != 'undefined'){
      if(that.gatewayCodeExamples == 'Invoke Device Command'){
        //that.gatewayCode = that.gatewayCode + '\n\t "' + that.gatewayDeviceExamples.id + '",';
        that.gatewayCode = that.gatewayCode + '\n\t "' + that.gatewayDeviceExamples + '",';
      }
    } else {
      if(that.gatewayCodeExamples == 'Invoke Device Command'){
        that.gatewayCode = that.gatewayCode + "\n\t <device Id>,";
      }
    }
    if(typeof that.gatewayCommandExamples != 'undefined'){
      that.gatewayCode = that.gatewayCode + '\n\t "' + that.gatewayCommandExamples.id + '",';
      that.commandArgs = '';
      // that.gatewayCommandExamples.args.forEach((value, index) => {
      //   that.commandArgs = that.commandArgs  + '\n\t "' + value.name + ':value here",';
      // });
      that.gatewayCode = that.gatewayCode + that.commandArgs;
    }
    that.gatewayCode =  that.gatewayCode.substring(0, that.gatewayCode.length - 1);
    that.gatewayCode = that.gatewayCode + "\n);";
    that.refresh();
  }

  updateSupportedCommands(){
    const that =this;
    if(typeof that.gatewayDeviceExamples !== "object"){
      that.gatewayDeviceExamples = JSON.parse(that.gatewayDeviceExamples);
    }
    that.supportedCommands = [];
    that.gatewayDeviceExamples.supportedCommands.forEach((value, key)=> {
      //$scope.supportedCommands.push(value);
      that.supportedCommands.push(value, function(e) { 
          return e.id === value.id; 
      });
    });
  }

  onButtonToggle(event){
      const that= this;
      if( that.triggerType=="Device Lifecycle"){
      if(event.value=="Created"){
        that.lifecyclestate = 1;
      }
      else if(event.value=="Registered"){
        that.lifecyclestate = 2;
      }
      else if(event.value=="Suspended"){
        that.lifecyclestate = 3;
      }
      else if(event.value=="Unsuspended"){
        that.lifecyclestate = 4;
      }
      else if(event.value=="Locked"){
        that.lifecyclestate = 5;
      }
      else if(event.value=="Unlocked"){
        that.lifecyclestate = 6;
      }
      else if(event.value=="Deleted"){
        that.lifecyclestate = 7;
      }
      else if(event.value=="Reset"){
        that.lifecyclestate = 8;
      }
    }
   else if( that.triggerType=="Device Presence"){
      if(event.value=="ReInitially Connected"){
        that.presencestate = 1;
      }
      else if(event.value=="Connected"){
        that.presencestate = 2;
      }
      else if(event.value=="Disconnected"){
        that.presencestate = 3;
      }
    }

   else if( that.triggerType=="Device Geofence"){
      if(event.value=="Entering"){
        that.geofencestate = 1;
      }
      else if(event.value=="Exiting"){
        that.geofencestate = 2;
      }
    }
   else if( that.triggerType=="Device Health"){
      if(event.value=="OK"){
        that.healthstate = 1;
      }
      else if(event.value=="WARNING"){
        that.healthstate = 2;
      }
      else if(event.value=="CRITICAL"){
        that.healthstate = 3;
      }
      else if(event.value=="FAILURE"){
        that.healthstate = 4;
      }
      else if(event.value=="SUSPENDED"){
        that.healthstate = 5;
      }
    }
   else if( that.triggerType=="Device Tracking"){
      if(event.value=="IDLE"){
        that.deviceTrackingState = 1;
      }
      else if(event.value=="NONIDLE"){
        that.deviceTrackingState = 2;
      }
    }
   else if( that.triggerType=="Ecosystem Devices"){
      if(event.value=="Created"){
        that.rulePolicy.classifiers = ["DeviceCreatedEvent"];
      }
      else if(event.value=="Registration"){
        that.rulePolicy.classifiers = ["DeviceRegisteredEvent"];
      }
      else if(event.value=="Updated"){
        that.rulePolicy.classifiers = ["DeviceUpdatedEvent"];
      }
      else if(event.value=="Deleted"){
        that.rulePolicy.classifiers = ["DeviceDeletedEvent"];
      }
      else if(event.value=="Activated"){
        that.rulePolicy.classifiers = ["DeviceActivatedEvent"];
      }
      else if(event.value=="Deactivated"){
        that.rulePolicy.classifiers = ["DeviceDeactivatedEvent"];
      }
      else if(event.value=="Reset"){
        that.rulePolicy.classifiers = ["DeviceResetEvent"];
      }
      else if(event.value=="Lock"){
        that.rulePolicy.classifiers = ["DeviceLockedEvent"];
      }
      else if(event.value=="Unlock"){
        that.rulePolicy.classifiers = ["DeviceUnlockedEvent"];
      }
      else if(event.value=="Suspended"){
        that.rulePolicy.classifiers = ["DeviceSuspendedEvent"];
      }
      else if(event.value=="Unsuspended"){
        that.rulePolicy.classifiers = ["DeviceUnsuspendedEvent"];
      }
      else if(event.value=="Online"){
        that.rulePolicy.classifiers = ["DeviceOnlineEvent"];
      }
      else if(event.value=="Offline"){
        that.rulePolicy.classifiers = ["DeviceOfflineEvent"];
      }
      else if(event.value=="Migrate"){
        that.rulePolicy.classifiers = ["DeviceMigratedEvent"];
      }
    }

    else if( that.triggerType=="Ecosystem Persons"){
      if(event.value=="Activated"){
        that.rulePolicy.classifiers = ["PersonActivatedEvent"];
      }
      else if(event.value=="Updates"){
        that.rulePolicy.classifiers = ["PersonUpdatedEvent"];
      }
      else if(event.value=="Suspended"){
        that.rulePolicy.classifiers = ["PersonSuspendedEvent"];
      }
      else if(event.value=="Unsuspended"){
        that.rulePolicy.classifiers = ["PersonUnsuspendedEvent"];
      }
    }
    else if( that.triggerType="Ecosystem Applications"){
      if(event.value=="Created"){
        that.rulePolicy.classifiers = ["ApplicationCreatedEvent"];
      }
      else if(event.value=="Registered"){
        that.rulePolicy.classifiers = ["ApplicationRegisteredEvent"];
      }
      else if(event.value=="Updated"){
        that.rulePolicy.classifiers = ["ApplicationUpdatedEvent"];
      }
      else if(event.value=="Deleted"){
        that.rulePolicy.classifiers = ["ApplicationDeletedEvent"];
      }
    }
    else if( that.triggerType=="Ecosystem Groups"){
      if(event.value=="Created"){
        that.rulePolicy.classifiers = ["GroupCreatedEvent"];
      }
      else if(event.value=="Activated"){
        that.rulePolicy.classifiers = ["GroupActivatedEvent"];
      }
      else if(event.value=="Deactivated"){
        that.rulePolicy.classifiers = ["GroupDeactivatedEvent"];
      }
      else if(event.value=="Updated"){
        that.rulePolicy.classifiers = ["GroupModifiedEvent"];
      }
      else if(event.value=="Suspended"){
        that.rulePolicy.classifiers = ["GroupSuspendedEvent"];
      }
      else if(event.value=="Unsuspended"){
        that.rulePolicy.classifiers = ["GroupUnsuspendedEvent"];
      }
      else if(event.value=="AddEntitlement"){
        that.rulePolicy.classifiers = ["GroupEntitlementCreatedEvent"];
      }
      else if(event.value=="RemoveEntitlement"){
        that.rulePolicy.classifiers = ["GroupEntitlementDeletedEvent"];
      }
      else if(event.value=="Deleted"){
        that.rulePolicy.classifiers = ["GroupDeletedEvent"];
      }
      else if(event.value=="AddMember"){
        that.rulePolicy.classifiers = ["GroupMembershipCreatedEvent"];
      }
      else if(event.value=="RemoveMember"){
        that.rulePolicy.classifiers = ["GroupMembershipDeletedEvent"];
      }
      else if(event.value=="AddRelationship"){
        that.rulePolicy.classifiers = ["MemberRelationshipCreatedEvent"];
      }
      else if(event.value=="RemoveRelationship"){
        that.rulePolicy.classifiers = ["MemberRelationshipDeletedEvent"];
      }
    }
  }

  tabClick(tab) {
    const that =this;
    if (tab.index == 0) {
      that.rulesService.showDeviceTemplates = true;
      that.rulesService.showDevices = false;
    }
    else if (tab.index == 1) {
      that.rulesService.showDevices = true;
      that.rulesService.showDeviceTemplates = false;
    }
  }

  subscribersTabClick(tab) {
    const that= this;
    if
    (tab.index == 0) {
      that.rulesService.showEcosystems = false;
      that.rulesService.showEcosystemTemplates = true;
    } 
    else if (tab.index == 1) {
      that.rulesService.showEcosystems = true;
      that.rulesService.showEcosystemTemplates = false;
    }
  }

  auditRulePolicy(){
    const that=this;
    that.auditFlag=true;
    that.auditRefreshFlag=!that.auditRefreshFlag;
  }

  closeAudit(){
    const that=this;
    that.auditFlag=false;
  }

  goBackAuditList() {
    const that=this;
    that.iotService.auditScriptFlag = false;
    that.iotService.auditscript="";
    that.auditFlag = true;
  }

  loadRulePolicy(rulePolicyId) {
    const that = this;
    that.iotService.getCuiObjResponse().getRulePolicy({
      rulePolicyId: rulePolicyId
    }).then(function(response) {
    that.rulePolicy = response;
    
    that.sideNavComponent.menuClear();
    that.sideNavComponent.menuChange('Dashboard', 'sub-type');
    that.sideNavComponent.menuChange('rules.header', 'sub-type');
    that.sideNavComponent.menuChange(that.rulePolicy.name,'sub-type');

      if(!that.rulePolicy.description){
        that.rulePolicy.description = [];
        that.rulePolicy.description.push({ lang: 'en-us', text: "" });
    }
    //that.iot.data.lastViewed['rules'] = response.id;
    if(typeof response.tags != 'undefined'){
      that.tags = response.tags.slice();
        that.tags.forEach((value, index) => {
          if(value.startsWith("gw:")){
            that.tags.splice(index,1);
          }
        });
    }
    that.rulesService.loading = false;
    that.existingClassifier = that.rulePolicy.classifiers;
    that.triggers = JSON.stringify(that.rulePolicy.qualifierExpression);
    that.eventId = null;

    if(that.rulePolicy.logLevel=="FINE"){
      that.viewLogLevel="Fine";
    }
    else if(that.rulePolicy.logLevel=="NORMAL"){
      that.viewLogLevel="Normal";
    }
    else if(that.rulePolicy.logLevel=="TRACE"){
      that.viewLogLevel="Trace";
    }
    if(that.rulePolicy.classifiers[0]=="DEVICE_EVENT"){
      that.triggerType="Device Event";
      that.rulesService.showDeviceTemplates=true;
    }
    else if(that.rulePolicy.classifiers[0]=="ECOSYSTEM_EVENT"){
      that.triggerType="Ecosystem Event";
      that.ecosystemRulePolicy=true;
    }
    else if(that.rulePolicy.classifiers[0]=="DeviceLockedEvent"){
      that.triggerType="Ecosystem Devices";
    }
    if (that.rulePolicy.classifiers == 'DEVICE_EVENT') {
      that.changeType('Device Event');
      that.triggerType="Device Event";
      that.rulesService.showDeviceTemplates=true;
      that.rulesService.showEcosystemTemplates=true;
      that.showEventDeviceTab=true;
      that.rulePolicy.qualifierExpression.all.forEach((value, index)=> {
          if (value.hasOwnProperty("expr")) {
            var value2=JSON.stringify(value);
            var f = value2.substring(value2.indexOf("equal(f:") + 8, value2.indexOf(",", value2.indexOf("equal(f:") + 8));
                  var v = value2.substring(value2.indexOf("v:string(") + 9, value2.indexOf(")", value2.indexOf("v:string(") + 9));
                if (f == 'eventTemplateId') {
                  that.eventId = v;
                  that.iotService.getCuiObjResponse().getEventTemplate({
                          'eventTemplateId': v
                        })
                        .then(function(response) {
                            //alert(response)
                            //that.rulesService.setEvents(response);
                            that.triggerEvents.push(response);
                            //that.deviceEventName = response[0].name;
                            that.getDeviceTemplates();
                            that.getDevices();
                            that.eventsService.onSelection(response);
                            that.refresh();
                        }).fail(function (err){
                          console.log("err msg: "+ JSON.stringify(err));
                          if(err.responseJSON.apiStatusCode){
                            that.rulesService.errorMessage = err.responseJSON.apiMessage;
                            alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
                              } else{
                                alert("Something went wrong. Please check chrome console or check with dev team");
                              }
                          });

                }
          }
          if (value.hasOwnProperty("any") && JSON.stringify(value).includes("equal")) {
          value.any.forEach((value2, index)=> {
              var f = value2.expr.substring(value2.expr.indexOf("equal(f:") + 8, value2.expr.indexOf(",", value2.expr.indexOf("equal(f:") + 8));
              var v = value2.expr.substring(value2.expr.indexOf("v:string(") + 9, value2.expr.indexOf(")", value2.expr.indexOf("v:string(") + 9))
              switch (f) {
                  case "eventTemplateId":
                    //if (that.eventId == null) {
                          that.eventId = v;
                          that.iotService.getCuiObjResponse().getEventTemplate({
                            'eventTemplateId': v
                          })
                              .then(function(response) {
                              //alert(response)
                              // that.deviceEventName = response[0].name;
                              
                              //that.rulesService.setEvents(response);                            
                              that.triggerEvents.push(response);
                              that.getDeviceTemplates();
                              that.getDevices();
                              that.eventsService.onSelection(response);
                              that.refresh();
                              }).fail(function (err){
                                console.log("err msg: "+ JSON.stringify(err));
                                if(err.responseJSON.apiStatusCode){
                                  that.rulesService.errorMessage = err.responseJSON.apiMessage;
                                  alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
                                    } else{
                                      alert("Something went wrong. Please check chrome console or check with dev team");
                                    }
                                });
                      //}
                      break;
                      case "deviceTemplateId":
                        that.iotService.getCuiObjResponse().getDeviceTemplate({
                          'deviceTemplateId':  v                                        
                        })
                        .then(function(response) {
                          let deviceTemplates = [];
                          //deviceTemplates=that.rulesService.getDeviceTemplates();
                          deviceTemplates.push(response);
                          //that.rulesService.setDeviceTemplates(deviceTemplates);
                          //that.triggerDeviceTemplates.push(response);
                          that.digitalTwinTemplatesService.onSelection(response);
                          that.refresh();
                        }).fail(function (err){
                          console.log("err msg: "+ JSON.stringify(err));
                          if(err.responseJSON.apiStatusCode){
                            that.rulesService.errorMessage = err.responseJSON.apiMessage;
                            alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
                              } else{
                                alert("Something went wrong. Please check chrome console or check with dev team");
                              }
                          });
                        break;
                        case "deviceId":
                        that.iotService.getCuiObjResponse().getDevice({
                          'deviceId': v
                        })
                        .then(function(response) {
                            let devices = [];
                            //devices=that.rulesService.getDevices();
                            devices.push(response);
                            //that.rulesService.setDevices(devices);
                            //that.triggerDevices.push(response);
                            that.digitalTwinsService.onSelection(response);
                            that.refresh();
                        }).fail(function (err){
                          console.log("err msg: "+ JSON.stringify(err));
                          if(err.responseJSON.apiStatusCode){
                            that.rulesService.errorMessage = err.responseJSON.apiMessage;
                            alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
                              } else{
                                alert("Something went wrong. Please check chrome console or check with dev team");
                              }
                          });
                      break;
              }
        });
      }
      if (value.hasOwnProperty("any") && JSON.stringify(value).includes("arrayContains")) {
        value.any.forEach((value2, key2)=> {
              var f = value2.expr.substring(value2.expr.indexOf("arrayContains(f:") + 16, value2.expr.indexOf(",", value2.expr.indexOf("arrayContains(f:") + 16));
              var v = value2.expr.substring(value2.expr.indexOf("v:string(") + 9, value2.expr.indexOf(")", value2.expr.indexOf("v:string(") + 9))
              switch (f) {
                case "groupTemplateIds":
                    //that.existingSubscriberList.push({"type":"ecosystemtemplate","id":v});
                    that.iotService.getCuiObjResponse().getEcosystemTemplateById({
                          'groupTemplateId': v
                      })
                      .then(function(response) {
                        that.ecosystemTemplatesService.onSelection(response);
                        let ecosystemTemplates = [];
                        //ecosystemTemplates=that.rulesService.getEcosystemTemplates();
                        ecosystemTemplates.push(response);
                        //that.rulesService.setEcosystemtemplates(ecosystemTemplates);
                        // that.triggEcosystemTemplates.push(response)
                        that.subscribersList.push({"type":"ecosystemtemplate", "value":response});
                          that.refresh();
                      }).fail(function (err){
                        console.log("err msg: "+ JSON.stringify(err));
                        if(err.responseJSON.apiStatusCode){
                          that.rulesService.errorMessage = err.responseJSON.apiMessage;
                          alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
                            } else{
                              alert("Something went wrong. Please check chrome console or check with dev team");
                            }
                        });
                    break;
                case "groupIds":
                    //that.existingSubscriberList.push({"type":"group","id":v});
                    that.iotService.getCuiObjResponse().getEcosystemById({
                            'groupId': v
                        })
                        .then(function(response) {
                          let ecosystems = [];
                          //ecosystems=that.rulesService.getEcosystems();
                          that.ecosystemsService.onSelection(response);
                          ecosystems.push(response);
                          //that.rulesService.setEcosystems(ecosystems);
                           // that.triggEcosystems.push(response)
                          that.subscribersList.push({"type":"group", "value":response});
                        that.refresh();
                        }).fail(function (err){
                          console.log("err msg: "+ JSON.stringify(err));
                          if(err.responseJSON.apiStatusCode){
                            that.rulesService.errorMessage = err.responseJSON.apiMessage;
                            alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
                              } else{
                                alert("Something went wrong. Please check chrome console or check with dev team");
                              }
                          });
                      break;
              }
        });
      }
      });
    }
    else if (that.rulePolicy.classifiers == 'DEVICE_LIFECYCLE_STATE_CHANGE') {
        that.changeType('Device Lifecycle');
        that.triggerType="Device Lifecycle";
        that.rulesService.showDeviceTemplates=true;
        var from = "";
        var to = "";

        that.rulePolicy.qualifierExpression.all.forEach((value, index)=> {
          if (value.hasOwnProperty("expr")) {           
            var value2=JSON.stringify(value);
              var f = value2.substring(value2.indexOf("equal(f:") + 8, value2.indexOf(",", value2.indexOf("equal(f:") + 8));
              var v = value2.substring(value2.indexOf("v:string(") + 9, value2.indexOf(")", value2.indexOf("v:string(") + 9))
              if (f == 'fromState') { from = v };
              if (f == 'toState') { to = v };
          }
          if (value.hasOwnProperty("any")) {
            value.any.forEach((value3, index)=> {
              var value2=JSON.stringify(value3);
              var f = JSON.stringify(value3.expr).substring(JSON.stringify(value3.expr).indexOf("equal(f:") + 8, JSON.stringify(value3.expr).indexOf(",", JSON.stringify(value3.expr).indexOf("equal(f:") + 8));
              var v = JSON.stringify(value3.expr).substring(JSON.stringify(value3.expr).indexOf("v:string(") + 9, JSON.stringify(value3.expr).indexOf(")", JSON.stringify(value3.expr).indexOf("v:string(") + 9));

              switch (f) {
                case "deviceTemplateId":
                that.iotService.getCuiObjResponse().getDeviceTemplate({
                  'deviceTemplateId':  v                                        
                })
                .then(function(response) {
                  let deviceTemplates = [];
                  //deviceTemplates=that.rulesService.getDeviceTemplates();
                  deviceTemplates.push(response);
                  //that.rulesService.setDeviceTemplates(deviceTemplates);
                  //that.triggerDeviceTemplates.push(response);
                  that.digitalTwinTemplatesService.onSelection(response);
                  that.refresh();
                }).fail(function (err){
                  console.log("err msg: "+ JSON.stringify(err));
                  if(err.responseJSON.apiStatusCode){
                    that.rulesService.errorMessage = err.responseJSON.apiMessage;
                    alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
                      } else{
                        alert("Something went wrong. Please check chrome console or check with dev team");
                      }
                  });
                break;
                case "deviceId":
                that.iotService.getCuiObjResponse().getDevice({
                  'deviceId': v
                })
                .then(function(response) {
                    let devices = [];
                    //devices=that.rulesService.getDevices();
                    devices.push(response);
                    //that.rulesService.setDevices(devices);
                    //that.triggerDevices.push(response);
                    that.digitalTwinsService.onSelection(response);
                    that.refresh();
                }).fail(function (err){
                  console.log("err msg: "+ JSON.stringify(err));
                  if(err.responseJSON.apiStatusCode){
                    that.rulesService.errorMessage = err.responseJSON.apiMessage;
                    alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
                      } else{
                        alert("Something went wrong. Please check chrome console or check with dev team");
                      }
                  });
                break;
              }
            });
          }
        });

        if (from.toUpperCase() == "" && to.toUpperCase() == "PENDING_ACTIVATION") { that.lifecyclestate = 1 };
        if (from.toUpperCase() == "PENDING_ACTIVATION" && to.toUpperCase() == "ACTIVE") { that.lifecyclestate = 2 };
        if (from.toUpperCase() == "ACTIVE" && to.toUpperCase() == "SUSPENDED") { that.lifecyclestate = 3 };
        if (from.toUpperCase() == "SUSPENDED" && to.toUpperCase() == "ACTIVE") { that.lifecyclestate = 4 };
        if (from.toUpperCase() == "ACTIVE" && to.toUpperCase() == "LOCKED") { that.lifecyclestate = 5 };
        if (from.toUpperCase() == "LOCKED" && to.toUpperCase() == "ACTIVE") { that.lifecyclestate = 6 };
        if (from.toUpperCase() == "SUSPENDED" && to.toUpperCase() == "DELETED") { that.lifecyclestate = 7 };
        if (from.toUpperCase() == "DELETED" && to.toUpperCase() == "PENDING_ACTIVATION") { that.lifecyclestate = 8 };

    }
    else if (that.rulePolicy.classifiers == 'DEVICE_OPERATIONAL_STATE_CHANGE') {

        that.changeType('Device Presence');
        that.triggerType="Device Presence";
        that.rulesService.showDeviceTemplates=true;
        var from = "";
        var to = "";
        that.rulePolicy.qualifierExpression.all.forEach((value, index)=> {
          if (value.hasOwnProperty("expr")) {           
            var value2=JSON.stringify(value);
              var f = value2.substring(value2.indexOf("equal(f:") + 8, value2.indexOf(",", value2.indexOf("equal(f:") + 8));
              var v = value2.substring(value2.indexOf("v:string(") + 9, value2.indexOf(")", value2.indexOf("v:string(") + 9))
              if (f == 'fromState') { from = v };
              if (f == 'toState') { to = v };
          }
          if (value.hasOwnProperty("any")) {
            value.any.forEach((value3, index)=> {
              var value2=JSON.stringify(value3);
              var f = JSON.stringify(value3.expr).substring(JSON.stringify(value3.expr).indexOf("equal(f:") + 8, JSON.stringify(value3.expr).indexOf(",", JSON.stringify(value3.expr).indexOf("equal(f:") + 8));
              var v = JSON.stringify(value3.expr).substring(JSON.stringify(value3.expr).indexOf("v:string(") + 9, JSON.stringify(value3.expr).indexOf(")", JSON.stringify(value3.expr).indexOf("v:string(") + 9));

              switch (f) {
                case "deviceTemplateId":
                that.iotService.getCuiObjResponse().getDeviceTemplate({
                  'deviceTemplateId':  v                                        
                })
                .then(function(response) {
                  let deviceTemplates = [];
                  //deviceTemplates=that.rulesService.getDeviceTemplates();
                  deviceTemplates.push(response);
                  //that.rulesService.setDeviceTemplates(deviceTemplates);
                  //that.triggerDeviceTemplates.push(response);
                  that.digitalTwinTemplatesService.onSelection(response);
                  that.refresh();
                }).fail(function (err){
                  console.log("err msg: "+ JSON.stringify(err));
                  if(err.responseJSON.apiStatusCode){
                    that.rulesService.errorMessage = err.responseJSON.apiMessage;
                    alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
                      } else{
                        alert("Something went wrong. Please check chrome console or check with dev team");
                      }
                  });
                break;
                case "deviceId":
                that.iotService.getCuiObjResponse().getDevice({
                  'deviceId': v
                })
                .then(function(response) {
                    let devices = [];
                    //devices=that.rulesService.getDevices();
                    devices.push(response);
                    //that.rulesService.setDevices(devices);
                    //that.triggerDevices.push(response);
                    that.digitalTwinsService.onSelection(response);
                    that.refresh();
                }).fail(function (err){
                  console.log("err msg: "+ JSON.stringify(err));
                  if(err.responseJSON.apiStatusCode){
                    that.rulesService.errorMessage = err.responseJSON.apiMessage;
                    alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
                      } else{
                        alert("Something went wrong. Please check chrome console or check with dev team");
                      }
                  });
                break;
              }
            });
          }
        });

        if (from.toUpperCase() == "STANDBY" && to.toUpperCase() == "ONLINE") { that.presencestate = 1 };
        if (from.toUpperCase() == "OFFLINE" && to.toUpperCase() == "ONLINE") { that.presencestate = 2 };
        if (from.toUpperCase() == "ONLINE" && to.toUpperCase() == "OFFLINE") { that.presencestate = 3 };

    }
    else if (that.rulePolicy.classifiers == 'DEVICE_GEOFENCE_STATE_CHANGE') {
      
        that.changeType('Device Geofence');
        that.triggerType="Device Geofence";
        that.rulesService.showDeviceTemplates=true;
        var from = "";
        var to = "";

        that.rulePolicy.qualifierExpression.all.forEach((value, index)=> {
          if (value.hasOwnProperty("expr")) {           
            var value2=JSON.stringify(value);
                    var f = value2.substring(value2.indexOf("equal(f:") + 8, value2.indexOf(",", value2.indexOf("equal(f:") + 8));
                    var v = value2.substring(value2.indexOf("v:string(") + 9, value2.indexOf(")", value2.indexOf("v:string(") + 9))
                    if (f == 'fromState') { from = v };
                    if (f == 'toState') { to = v };
              }
                if (value.hasOwnProperty("any")) {

                  value.any.forEach((value3, index)=> {
                    var value2=JSON.stringify(value3);

                        var f = JSON.stringify(value3.expr).substring(JSON.stringify(value3.expr).indexOf("equal(f:") + 8, JSON.stringify(value3.expr).indexOf(",", JSON.stringify(value3.expr).indexOf("equal(f:") + 8));
                        var v = JSON.stringify(value3.expr).substring(JSON.stringify(value3.expr).indexOf("v:string(") + 9, JSON.stringify(value3.expr).indexOf(")", JSON.stringify(value3.expr).indexOf("v:string(") + 9));

                        switch (f) {

                            case "deviceTemplateId":
                              that.iotService.getCuiObjResponse().getDeviceTemplate({
                                        'deviceTemplateId':  v                                        
                                    })
                                    .then(function(response) {
                                      let deviceTemplates = [];
                                      //deviceTemplates=that.rulesService.getDeviceTemplates();
                                      deviceTemplates.push(response);
                                      //that.rulesService.setDeviceTemplates(deviceTemplates);
                                      //that.triggerDeviceTemplates.push(response);
                                      that.digitalTwinTemplatesService.onSelection(response);
                                      that.refresh();
                                    }).fail(function (err){
                                      console.log("err msg: "+ JSON.stringify(err));
                                      if(err.responseJSON.apiStatusCode){
                                        that.rulesService.errorMessage = err.responseJSON.apiMessage;
                                        alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
                                          } else{
                                            alert("Something went wrong. Please check chrome console or check with dev team");
                                          }
                                      });

                                break;
                            case "deviceId":
                              that.iotService.getCuiObjResponse().getDevice({
                                        'deviceId': v
                                    })
                                    .then(function(response) {
                                      let devices = [];
                                      //devices=that.rulesService.getDevices();
                                      devices.push(response);
                                      //that.rulesService.setDevices(devices);
                                      //that.triggerDevices.push(response)
                                      that.digitalTwinsService.onSelection(response);
                                      that.refresh();
                                    }).fail(function (err){
                                      console.log("err msg: "+ JSON.stringify(err));
                                      if(err.responseJSON.apiStatusCode){
                                        that.rulesService.errorMessage = err.responseJSON.apiMessage;
                                        alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
                                          } else{
                                            alert("Something went wrong. Please check chrome console or check with dev team");
                                          }
                                      });
                                break;

                            case "geofenceId":
                              that.iotService.getCuiObjResponse().getGeofenceById({
                                    'geofenceId': v
                                })
                                    .then(function (response) {
                                        let geofences = [];
                                        //geofences=that.rulesService.getGeofences();
                                        geofences.push(response);
                                        //that.rulesService.setGeofences(geofences);
                                        //that.triggerGeofences.push(response);
                                        that.geofenceService.onSelection(response);
                                        that.refresh();
                                    }).fail(function (err){
                                      console.log("err msg: "+ JSON.stringify(err));
                                      if(err.responseJSON.apiStatusCode){
                                        that.rulesService.errorMessage = err.responseJSON.apiMessage;
                                        alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
                                          } else{
                                            alert("Something went wrong. Please check chrome console or check with dev team");
                                          }
                                      });
                                break;
                        }
                    });
                }
            //});
        //  }
        });
        if (from.toUpperCase() == "OUTSIDE" && to.toUpperCase() == "INSIDE") { that.geofencestate = 1 };
        if (from.toUpperCase() == "INSIDE" && to.toUpperCase() == "OUTSIDE") { that.geofencestate = 2 };
    }
    else if (that.rulePolicy.classifiers == 'DEVICE_HEALTH_STATE_CHANGE') {

        that.changeType('Device Health');
        that.triggerType="Device Health";
        that.rulesService.showDeviceTemplates=true;
        var from = "";
        var to = "";

        that.rulePolicy.qualifierExpression.all.forEach((value, index)=> {
          if (value.hasOwnProperty("expr")) {           
            var value2=JSON.stringify(value);
              var f = value2.substring(value2.indexOf("equal(f:") + 8, value2.indexOf(",", value2.indexOf("equal(f:") + 8));
              var v = value2.substring(value2.indexOf("v:string(") + 9, value2.indexOf(")", value2.indexOf("v:string(") + 9))
              if (f == 'fromState') { from = v };
              if (f == 'toState') { to = v };
          }
          if (value.hasOwnProperty("any")) {
            value.any.forEach((value3, index)=> {
              var value2=JSON.stringify(value3);
              var f = JSON.stringify(value3.expr).substring(JSON.stringify(value3.expr).indexOf("equal(f:") + 8, JSON.stringify(value3.expr).indexOf(",", JSON.stringify(value3.expr).indexOf("equal(f:") + 8));
              var v = JSON.stringify(value3.expr).substring(JSON.stringify(value3.expr).indexOf("v:string(") + 9, JSON.stringify(value3.expr).indexOf(")", JSON.stringify(value3.expr).indexOf("v:string(") + 9));

              switch (f) {
                case "deviceTemplateId":
                that.iotService.getCuiObjResponse().getDeviceTemplate({
                  'deviceTemplateId':  v                                        
                })
                .then(function(response) {
                  let deviceTemplates = [];
                  //deviceTemplates=that.rulesService.getDeviceTemplates();
                  deviceTemplates.push(response);
                  //that.rulesService.setDeviceTemplates(deviceTemplates);
                  //that.triggerDeviceTemplates.push(response)
                  that.digitalTwinTemplatesService.onSelection(response);
                  that.refresh();
                }).fail(function (err){
                  console.log("err msg: "+ JSON.stringify(err));
                  if(err.responseJSON.apiStatusCode){
                    that.rulesService.errorMessage = err.responseJSON.apiMessage;
                    alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
                      } else{
                        alert("Something went wrong. Please check chrome console or check with dev team");
                      }
                  });
                break;
                case "deviceId":
                that.iotService.getCuiObjResponse().getDevice({
                  'deviceId': v
                })
                .then(function(response) {
                    let devices = [];
                    //devices=that.rulesService.getDevices();
                    devices.push(response);
                    //that.rulesService.setDevices(devices);
                    //that.triggerDevices.push(response)
                    that.digitalTwinsService.onSelection(response);
                    that.refresh();
                }).fail(function (err){
                  console.log("err msg: "+ JSON.stringify(err));
                  if(err.responseJSON.apiStatusCode){
                    that.rulesService.errorMessage = err.responseJSON.apiMessage;
                    alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
                      } else{
                        alert("Something went wrong. Please check chrome console or check with dev team");
                      }
                  });
                break;
              }
            });
          }
        });

        if (from.toUpperCase() == "" && to.toUpperCase() == "OK") { that.healthstate = 1 };
        if (from.toUpperCase() == "" && to.toUpperCase() == "WARNING") { that.healthstate = 2 };
        if (from.toUpperCase() == "" && to.toUpperCase() == "CRITICAL") { that.healthstate = 3 };
        if (from.toUpperCase() == "" && to.toUpperCase() == "FAILURE") { that.healthstate = 4 };
        if (from.toUpperCase() == "" && to.toUpperCase() == "SUSPENDED") { that.healthstate = 5 };

    }
    else if (that.rulePolicy.classifiers == 'DEVICE_HEALTH_ALERT') {

      that.changeType('Device Health Alert');
      var from = "";
      var to = "";

      that.rulePolicy.qualifierExpression.all.forEach((value, index)=> {
        if (value.hasOwnProperty("expr")) {           
          var value2=JSON.stringify(value);
            var f = value2.substring(value2.indexOf("equal(f:") + 8, value2.indexOf(",", value2.indexOf("equal(f:") + 8));
            var v = value2.substring(value2.indexOf("v:string(") + 9, value2.indexOf(")", value2.indexOf("v:string(") + 9));
        }
        if (value.hasOwnProperty("any")) {
          value.any.forEach((value3, index)=> {
            var value2=JSON.stringify(value3);
            var f = JSON.stringify(value3.expr).substring(JSON.stringify(value3.expr).indexOf("equal(f:") + 8, JSON.stringify(value3.expr).indexOf(",", JSON.stringify(value3.expr).indexOf("equal(f:") + 8));
            var v = JSON.stringify(value3.expr).substring(JSON.stringify(value3.expr).indexOf("v:string(") + 9, JSON.stringify(value3.expr).indexOf(")", JSON.stringify(value3.expr).indexOf("v:string(") + 9));

            switch (f) {
              case "deviceTemplateId":
              that.iotService.getCuiObjResponse().getDeviceTemplate({
                'deviceTemplateId':  v                                        
              })
              .then(function(response) {
                let deviceTemplates = [];
                //deviceTemplates=that.rulesService.getDeviceTemplates();
                deviceTemplates.push(response);
                //that.rulesService.setDeviceTemplates(deviceTemplates);
                //that.triggerDeviceTemplates.push(response);
                that.digitalTwinTemplatesService.onSelection(response);
                that.refresh();
              }).fail(function (err){
                console.log("err msg: "+ JSON.stringify(err));
                if(err.responseJSON.apiStatusCode){
                  that.rulesService.errorMessage = err.responseJSON.apiMessage;
                  alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
                    } else{
                      alert("Something went wrong. Please check chrome console or check with dev team");
                    }
                });
              break;
              case "deviceId":
              that.iotService.getCuiObjResponse().getDevice({
                'deviceId': v
              })
              .then(function(response) {
                  let devices = [];
                  //devices=that.rulesService.getDevices();
                  devices.push(response);
                  //that.rulesService.setDevices(devices);
                  //that.triggerDevices.push(response);
                  that.digitalTwinsService.onSelection(response);
                  that.refresh();
              }).fail(function (err){
                console.log("err msg: "+ JSON.stringify(err));
                if(err.responseJSON.apiStatusCode){
                  that.rulesService.errorMessage = err.responseJSON.apiMessage;
                  alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
                    } else{
                      alert("Something went wrong. Please check chrome console or check with dev team");
                    }
                });
              break;
            }
          });
        }
      });

      if (from.toUpperCase() == "" && to.toUpperCase() == "NO HEALTH MESSAGE ALERT") { that.healthalert = 1 };
      if (from.toUpperCase() == "" && to.toUpperCase() == "NO HEALTH MESSAGE CHANGE ALERT") { that.healthalert = 2 };
      if (from.toUpperCase() == "" && to.toUpperCase() == "NO HEALTH MESSAGE UPDATE ALERT") { that.healthalert = 3 };

    }
    else if (that.rulePolicy.classifiers == 'DEVICE_TRACKING') {

      that.changeType('Device Tracking');
      that.triggerType="Device Tracking";
        that.rulesService.showDeviceTemplates=true;
      var from = "";
      var to = "";
      that.rulePolicy.qualifierExpression.all.forEach((value, index)=> {
        if (value.hasOwnProperty("expr")) {           
          var value2=JSON.stringify(value);
            var f = value2.substring(value2.indexOf("equal(f:") + 8, value2.indexOf(",", value2.indexOf("equal(f:") + 8));
            var v = value2.substring(value2.indexOf("v:string(") + 9, value2.indexOf(")", value2.indexOf("v:string(") + 9))
            if (f == 'fromState') { from = v };
            if (f == 'toState') { to = v };
        }
        if (value.hasOwnProperty("any")) {
          value.any.forEach((value3, index)=> {
            var value2=JSON.stringify(value3);
            var f = JSON.stringify(value3.expr).substring(JSON.stringify(value3.expr).indexOf("equal(f:") + 8, JSON.stringify(value3.expr).indexOf(",", JSON.stringify(value3.expr).indexOf("equal(f:") + 8));
            var v = JSON.stringify(value3.expr).substring(JSON.stringify(value3.expr).indexOf("v:string(") + 9, JSON.stringify(value3.expr).indexOf(")", JSON.stringify(value3.expr).indexOf("v:string(") + 9));

            switch (f) {
              case "deviceTemplateId":
              that.iotService.getCuiObjResponse().getDeviceTemplate({
                'deviceTemplateId':  v                                        
              })
              .then(function(response) {
                let deviceTemplates = [];
                //deviceTemplates=that.rulesService.getDeviceTemplates();
                deviceTemplates.push(response);
                //that.rulesService.setDeviceTemplates(deviceTemplates);
                //that.triggerDeviceTemplates.push(response);
                that.digitalTwinTemplatesService.onSelection(response);
                that.refresh();
              }).fail(function (err){
                console.log("err msg: "+ JSON.stringify(err));
                if(err.responseJSON.apiStatusCode){
                  that.rulesService.errorMessage = err.responseJSON.apiMessage;
                  alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
                    } else{
                      alert("Something went wrong. Please check chrome console or check with dev team");
                    }
                });
              break;
              case "deviceId":
              that.iotService.getCuiObjResponse().getDevice({
                'deviceId': v
              })
              .then(function(response) {
                  let devices = [];
                  //devices=that.rulesService.getDevices();
                  devices.push(response);
                  //that.rulesService.setDevices(devices);
                  //that.triggerDevices.push(response);
                  that.digitalTwinsService.onSelection(response);
                  that.refresh();
              }).fail(function (err){
                console.log("err msg: "+ JSON.stringify(err));
                if(err.responseJSON.apiStatusCode){
                  that.rulesService.errorMessage = err.responseJSON.apiMessage;
                  alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
                    } else{
                      alert("Something went wrong. Please check chrome console or check with dev team");
                    }
                });
              break;
            }
          });
        }
      });

      if (from.toUpperCase() == "NONIDLE" && to.toUpperCase() == "IDLE") { that.deviceTrackingState = 1 };
      if (from.toUpperCase() == "IDLE" && to.toUpperCase() == "NONIDLE") { that.deviceTrackingState = 2 };

    }
    else if(that.rulePolicy.classifiers[0] == 'SIGNAL' || that.rulePolicy.classifiers[0] == 'QUICK_SIGNAL'){
      that.chkBoxModel = true;
      that.showGateway=true;
      //that.chkchanged();
      that.triggerType="Device Event";
      that.rulePolicy.tags.forEach((value3, index)=> {
        if(value3.startsWith("gw:")){
        var selectedGatewayId = value3.split(":")[1];
        that.iotService.getCuiObjResponse().getGatewayAgent({
          gatewayId: selectedGatewayId
        }).then(function(response) {
          let gayeways = [];
          if(!that.rulesService.getGateways())
          {
            gayeways=that.rulesService.getGateways();
            //gayeways.push(response);
            that.rulesService.setGateways(gayeways);
            that.gatewayService.onSelection(response);
          }
          //that.triggergateways.push(response);
          that.selectGateways(response, true);
          that.onload = true;
          that.refresh();
        }).fail(function (err){
          console.log("err msg: "+ JSON.stringify(err));
          if(err.responseJSON.apiStatusCode){
            that.rulesService.errorMessage = err.responseJSON.apiMessage;
            alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
              } else{
                alert("Something went wrong. Please check chrome console or check with dev team");
              }
          });
        }
      });

      that.rulePolicy.qualifierExpression.all.forEach((value, key)=> {
        if (value.hasOwnProperty("expr")) {
          var value2=JSON.stringify(value);
            var deviceId = value2.substring(value2.indexOf("v:string(") + 9, value2.indexOf(")", value2.indexOf("v:string(") + 9))
            that.iotService.getCuiObjResponse().getDevice({
              deviceId: deviceId
            }).then(function(response) {
              let devices = [];
              devices=that.rulesService.getDevices();
              devices.push(response);
              that.rulesService.setDevices(devices);
              that.triggerDevices.push(response)
              that.selectGroup(response, true);
              that.refresh();
            }).fail(function (err){
              console.log("err msg: "+ JSON.stringify(err));
              if(err.responseJSON.apiStatusCode){
                that.rulesService.errorMessage = err.responseJSON.apiMessage;
                alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
                  } else{
                    alert("Something went wrong. Please check chrome console or check with dev team");
                  }
              });
            that.samplegatewayinputjson = '{\n\t "deviceId":"'+deviceId+'",\n\t}';
        }
            else if (value.hasOwnProperty("any")) {
             value.any.forEach((value3, index)=> {
                      var f = value3.expr.substring(value3.expr.indexOf("equal(f:") + 8, value3.expr.indexOf(",", value3.expr.indexOf("equal(f:") + 8));
                      var v = value3.expr.substring(value3.expr.indexOf("v:string(") + 9, value3.expr.indexOf(")", value3.expr.indexOf("v:string(") + 9))
                      if (f == 'eventTemplateId') {
                        that.iotService.getCuiObjResponse().getEventTemplate({
                          eventTemplateId: v
                        })
                        .then(function(response) {                            
                          that.rulesService.setEvents(response); 
                          that.eventId=response.id;                      
                          that.triggerEvents.push(response)
                          that.selectedGatewayEventTemp = response;
                          that.gatewayEventName = response.name;
                          that.chkchanged();
                          that.showGatewayDevicesEvents=true;
                          if(typeof that.samplegatewayinputjson == 'undefined' || that.samplegatewayinputjson == ""){
                            that.samplegatewayinputjson = that.samplegatewayinputjson + '{\n\t  "eventTemplateId":"'+v+'"';
                          } else{
                            that.samplegatewayinputjson = that.samplegatewayinputjson.substring(0, that.samplegatewayinputjson.length-1);
                            that.samplegatewayinputjson = that.samplegatewayinputjson + ' "eventTemplateId":"'+v+'"';
                          }

                          //Adding event fields
                          response.eventFields.forEach((value4,key4)=>{
                            that.samplegatewayinputjson = that.samplegatewayinputjson + ',\n\t "'+value4.name+'":"Value here"';
                          });
                          that.samplegatewayinputjson = that.samplegatewayinputjson + '\n}'
                          that.refresh();
                        }).fail(function (err){
                          console.log("err msg: "+ JSON.stringify(err));
                          if(err.responseJSON.apiStatusCode){
                            that.rulesService.errorMessage = err.responseJSON.apiMessage;
                            alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
                              } else{
                                alert("Something went wrong. Please check chrome console or check with dev team");
                              }
                          });
                      }
                  });
              }
       
      });

      that.refresh();
    }
    else {
      switch(that.rulePolicy.classifiers[0]){
        case "ECOSYSTEM_EVENT":
          that.changeType('Ecosystem Event');
          that.triggerType="Ecosystem Event";
          //that.ececosystemEvent=false;
          that.rulePolicy.qualifierExpression.all.forEach((value, index)=> {
                if (value.hasOwnProperty("expr")) {
                  var value2=JSON.stringify(value);
                  var f = value2.substring(value2.indexOf("equal(f:") + 8, value2.indexOf(",", value2.indexOf("equal(f:") + 8));
                        var v = value2.substring(value2.indexOf("v:string(") + 9, value2.indexOf(")", value2.indexOf("v:string(") + 9));
                      if (f == 'eventTemplateId') {
                        that.eventId = v;
                        that.iotService.getCuiObjResponse().getEventTemplate({
                                  'eventTemplateId':  v
                              })
                              .then(function(response) {
                                that.deviceEventName = response.name;
                                that.getEcosystemTemplates();                   
                                //that.rulesService.setEvents(response);                              
                                //that.triggerEvents.push(response)
                                that.eventsService.onSelection(response);
                                that.refresh();
                              }).fail(function (err){
                                console.log("err msg: "+ JSON.stringify(err));
                                if(err.responseJSON.apiStatusCode){
                                  that.rulesService.errorMessage = err.responseJSON.apiMessage;
                                  alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
                                    } else{
                                      alert("Something went wrong. Please check chrome console or check with dev team");
                                    }
                                });
                      }
                  }
          });
          that.parseEcosystem(that.rulePolicy.qualifierExpression.all);
          that.ecosystemeventstate = 1;
          that.loadEcosystemExample();
        break;
        case "DeviceCreatedEvent":
        that.changeType('Ecosystem Devices');
          that.parseEcosystem(that.rulePolicy.qualifierExpression.all);
          that.ecosystemdevicestate = 1;
          that.loadEcosystemExample();
          break;
        case "DeviceRegisteredEvent":
        that.changeType('Ecosystem Devices');
          that.parseEcosystem(that.rulePolicy.qualifierExpression.all);
          that.ecosystemdevicestate = 2;
          that.loadEcosystemExample();
          break;
        case "DeviceUpdatedEvent":
        that.changeType('Ecosystem Devices');
          that.parseEcosystem(that.rulePolicy.qualifierExpression.all);
          that.ecosystemdevicestate = 3;
          that.loadEcosystemExample();
          break;
        case "DeviceDeletedEvent":
        that.changeType('Ecosystem Devices');
        that.triggerType="Ecosystem Devices";
          that.parseEcosystem(that.rulePolicy.qualifierExpression.all);
          that.ecosystemdevicestate = 4;
          that.loadEcosystemExample();
          break;
        case "DeviceActivatedEvent":
        that.changeType('Ecosystem Devices');
        that.triggerType="Ecosystem Devices";
          that.parseEcosystem(that.rulePolicy.qualifierExpression.all);
          that.ecosystemdevicestate = 5;
          that.loadEcosystemExample();
          break;
        case "DeviceDeactivatedEvent":
        that.changeType('Ecosystem Devices');
        that.triggerType="Ecosystem Devices";
          that.parseEcosystem(that.rulePolicy.qualifierExpression.all);
          that.ecosystemdevicestate = 6;
          that.loadEcosystemExample();
          break;
        case "DeviceResetEvent":
        that.changeType('Ecosystem Devices');
        that.triggerType="Ecosystem Devices";
          that.parseEcosystem(that.rulePolicy.qualifierExpression.all);
          that.ecosystemdevicestate = 7;
          that.loadEcosystemExample();
          break;
        case "DeviceLockedEvent":
        that.changeType('Ecosystem Devices');
        that.triggerType="Ecosystem Devices";
          that.parseEcosystem(that.rulePolicy.qualifierExpression.all);
          that.ecosystemdevicestate = 8;
          that.loadEcosystemExample();
          break;
        case "DeviceUnlockedEvent":
        that.changeType('Ecosystem Devices');
        that.triggerType="Ecosystem Devices";
          that.parseEcosystem(that.rulePolicy.qualifierExpression.all);
          that.ecosystemdevicestate = 9;
          that.loadEcosystemExample();
          break;
        case "DeviceSuspendedEvent":
        that.changeType('Ecosystem Devices');
        that.triggerType="Ecosystem Devices";
          that.parseEcosystem(that.rulePolicy.qualifierExpression.all);
          that.ecosystemdevicestate = 10;
          that.loadEcosystemExample();
          break;
        case "DeviceUnsuspendedEvent":
        that.changeType('Ecosystem Devices');
        that.triggerType="Ecosystem Devices";
          that.parseEcosystem(that.rulePolicy.qualifierExpression.all);
          that.ecosystemdevicestate = 11;
          that.loadEcosystemExample();
          break;
        case "DeviceOnlineEvent":
        that.changeType('Ecosystem Devices');
        that.triggerType="Ecosystem Devices";
          that.parseEcosystem(that.rulePolicy.qualifierExpression.all);
          that.ecosystemdevicestate = 12;
          that.loadEcosystemExample();
          break;
        case "DeviceOfflineEvent":
        that.changeType('Ecosystem Devices');
        that.triggerType="Ecosystem Devices";
          that.parseEcosystem(that.rulePolicy.qualifierExpression.all);
          that.ecosystemdevicestate = 13;
          that.loadEcosystemExample();
          break;
        case "DeviceMigratedEvent":
        that.changeType('Ecosystem Devices');
        that.triggerType="Ecosystem Devices";
          that.parseEcosystem(that.rulePolicy.qualifierExpression.all);
          that.ecosystemdevicestate = 14;
          that.loadEcosystemExample();
          break;
        case "PersonCreatedEvent":
        that.changeType('Ecosystem Persons');
        that.triggerType="Ecosystem Persons";
          that.parseEcosystem(that.rulePolicy.qualifierExpression.all);
          that.ecosystempersonstate = 1;
          that.loadEcosystemExample();
          break;
        case "PersonActivatedEvent":
        that.changeType('Ecosystem Persons');
        that.triggerType="Ecosystem Persons";
          that.parseEcosystem(that.rulePolicy.qualifierExpression.all);
          that.ecosystempersonstate = 2;
          that.loadEcosystemExample();
          break;
        case "PersonUpdatedEvent":
        that.changeType('Ecosystem Persons');
        that.triggerType="Ecosystem Persons";
          that.parseEcosystem(that.rulePolicy.qualifierExpression.all);
          that.ecosystempersonstate = 3;
          that.loadEcosystemExample();
          break;
        case "PersonSuspendedEvent":
        that.changeType('Ecosystem Persons');
        that.triggerType="Ecosystem Persons";
          that.parseEcosystem(that.rulePolicy.qualifierExpression.all);
          that.ecosystempersonstate = 4;
          that.loadEcosystemExample();
          break;
        case "PersonUnsuspendedEvent":
        that.changeType('Ecosystem Persons');
        that.triggerType="Ecosystem Persons";
          that.parseEcosystem(that.rulePolicy.qualifierExpression.all);
          that.ecosystempersonstate = 5;
          that.loadEcosystemExample();
          break;
        case "ApplicationCreatedEvent":
        that.changeType('Ecosystem Applications');
        that.triggerType="Ecosystem Applications";
          that.parseEcosystem(that.rulePolicy.qualifierExpression.all);
          that.ecosystemapplicationstate = 1;
          that.loadEcosystemExample();
          break;
        case "ApplicationRegisteredEvent":
        that.changeType('Ecosystem Applications');
        that.triggerType="Ecosystem Applications";
          that.parseEcosystem(that.rulePolicy.qualifierExpression.all);
          that.ecosystemapplicationstate = 2;
          that.loadEcosystemExample();
          break;
        case "ApplicationUpdatedEvent":
        that.changeType('Ecosystem Applications');
        that.triggerType="Ecosystem Applications";
          that.parseEcosystem(that.rulePolicy.qualifierExpression.all);
          that.ecosystemapplicationstate = 3;
          that.loadEcosystemExample();
          break;
        case "ApplicationDeletedEvent":
        that.changeType('Ecosystem Applications');
        that.triggerType="Ecosystem Applications";
          that.parseEcosystem(that.rulePolicy.qualifierExpression.all);
          that.ecosystemapplicationstate = 4;
          that.loadEcosystemExample();
          break;
        case "GroupCreatedEvent":
        that.changeType('Ecosystem Groups');
        that.triggerType="Ecosystem Groups";
          that.parseEcosystem(that.rulePolicy.qualifierExpression.all);
          that.ecosystemgroupstate = 1;
          that.loadEcosystemExample();
          break;
        case "GroupActivatedEvent":
        that.changeType('Ecosystem Groups');
        that.triggerType="Ecosystem Groups";
          that.parseEcosystem(that.rulePolicy.qualifierExpression.all);
          that.ecosystemgroupstate = 2;
          that.loadEcosystemExample();
          break;
        case "GroupDeactivatedEvent":
        that.changeType('Ecosystem Groups');
        that.triggerType="Ecosystem Groups";
          that.parseEcosystem(that.rulePolicy.qualifierExpression.all);
          that.ecosystemgroupstate = 3;
          that.loadEcosystemExample();
          break;
        case "GroupModifiedEvent":
        that.changeType('Ecosystem Groups');
        that.triggerType="Ecosystem Groups";
          that.parseEcosystem(that.rulePolicy.qualifierExpression.all);
          that.ecosystemgroupstate = 4;
          that.loadEcosystemExample();
          break;
        case "GroupSuspendedEvent":
        that.changeType('Ecosystem Groups');
        that.triggerType="Ecosystem Groups";
          that.parseEcosystem(that.rulePolicy.qualifierExpression.all);
          that.ecosystemgroupstate = 5;
          that.loadEcosystemExample();
          break;
        case "GroupUnsuspendedEvent":
        that.changeType('Ecosystem Groups');
        that.triggerType="Ecosystem Groups";
          that.parseEcosystem(that.rulePolicy.qualifierExpression.all);
          that.ecosystemgroupstate = 6;
          that.loadEcosystemExample();
          break;
        case "GroupEntitlementCreatedEvent":
        that.changeType('Ecosystem Groups');
        that.triggerType="Ecosystem Groups";
          that.parseEcosystem(that.rulePolicy.qualifierExpression.all);
          that.ecosystemgroupstate = 7;
          that.loadEcosystemExample();
          break;
        case "GroupEntitlementDeletedEvent":
        that.changeType('Ecosystem Groups');
        that.triggerType="Ecosystem Groups";
          that.parseEcosystem(that.rulePolicy.qualifierExpression.all);
          that.ecosystemgroupstate = 8;
          that.loadEcosystemExample();
          break;
        case "GroupDeletedEvent":
        that.changeType('Ecosystem Groups');
        that.triggerType="Ecosystem Groups";
          that.parseEcosystem(that.rulePolicy.qualifierExpression.all);
          that.ecosystemgroupstate = 9;
          that.loadEcosystemExample();
          break;
        case "GroupMembershipCreatedEvent":
        that.changeType('Ecosystem Groups');
        that.triggerType="Ecosystem Groups";
          that.parseEcosystem(that.rulePolicy.qualifierExpression.all);
          that.ecosystemgroupstate = 10;
          that.loadEcosystemExample();
          break;
        case "GroupMembershipDeletedEvent":
        that.changeType('Ecosystem Groups');
        that.triggerType="Ecosystem Groups";
          that.parseEcosystem(that.rulePolicy.qualifierExpression.all);
          that.ecosystemgroupstate = 11;
          that.loadEcosystemExample();
          break;
        case "MemberRelationshipCreatedEvent":
        that.changeType('Ecosystem Groups');
        that.triggerType="Ecosystem Groups";
          that.parseEcosystem(that.rulePolicy.qualifierExpression.all);
          that.ecosystemgroupstate = 12;
          that.loadEcosystemExample();
          break;
        case "MemberRelationshipDeletedEvent":
        that.changeType('Ecosystem Groups');
        that.triggerType="Ecosystem Groups";
          that.parseEcosystem(that.rulePolicy.qualifierExpression.all);
          that.ecosystemgroupstate = 13;
          that.loadEcosystemExample();
          break;
      }
    }
    that.script = atob(that.rulePolicy.script.value);
    //that.$apply();
    }).fail(function (err){
    console.log("err msg: "+ JSON.stringify(err));
    if(err.responseJSON.apiStatusCode){
      that.rulesService.errorMessage = err.responseJSON.apiMessage;
      alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
        } else{
          alert("Something went wrong. Please check chrome console or check with dev team");
        }
    });
  }

  parseEcosystem(qualifierExpression){
    const that=this;
    that.ecosystemRulePolicy=true;
    qualifierExpression.forEach((value, key)=> {
      if (value.hasOwnProperty("any")) {
      value.any.forEach((value2, key2)=> {
        var f = value2.expr.substring(value2.expr.indexOf("equal(f:") + 17, value2.expr.indexOf(",", value2.expr.indexOf("equal(f:") + 8));
        var v = value2.expr.substring(value2.expr.indexOf("v:string(") + 9, value2.expr.indexOf(")", value2.expr.indexOf("v:string(") + 9))
        switch (f) {
            case "groupIds":
              //that.existingSubscriberList.push({"type":"group","id":v});
              var groupId = v;
                that.iotService.getCuiObjResponse().getEcosystemById({
                        'groupId': v
                    })
                    .then(function(response) {
                      that.subscribersList.push({"type":"group","value":response[0]});
                      let groups = [];
                      //groups=that.rulesService.getEcosystems();
                      groups.push(response);
                      //that.rulesService.setEcosystems(groups);
                      //that.triggerDeviceTemplates.push(response[0]);
                      that.ecosystemsService.onSelection(response);
                      that.refresh();
                    }).fail(function (err){
                      console.log("err msg: "+ JSON.stringify(err));
                      if(err.responseJSON.apiStatusCode){
                        that.rulesService.errorMessage = err.responseJSON.apiMessage;
                        alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
                          } else{
                            alert("Something went wrong. Please check chrome console or check with dev team");
                          }
                      });
                break;
            case "groupTemplateIds":
              //that.existingSubscriberList.push({"type":"ecosystemtemplate","id":v});
              var groupTemplateId = v;
              that.iotService.getCuiObjResponse().getEcosystemTemplateById({
                        'groupTemplateId':  v
                    })
                    .then(function(response) {
                      that.subscribersList.push({"type":"ecosystemtemplate","value":response[0]});
                      let groupTemplates = [];
                      //groupTemplates=that.rulesService.getEcosystemTemplates();
                      groupTemplates.push(response);
                      //that.rulesService.setEcosystemtemplates(groupTemplates);
                      //that.triggerDeviceTemplates.push(response[0]);
                      that.ecosystemTemplatesService.onSelection(response);
                      that.refresh();
                    }).fail(function (err){
                      console.log("err msg: "+ JSON.stringify(err));
                      if(err.responseJSON.apiStatusCode){
                        that.rulesService.errorMessage = err.responseJSON.apiMessage;
                        alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
                          } else{
                            alert("Something went wrong. Please check chrome console or check with dev team");
                          }
                      });
                break;
            }
          });
        }
   });
  }

  selectGateways(gateway, isOnLoad){
    const that =this;
    that.rulesService.loading = true;
    that.rulesService.selectGatewaysCount=1;
    that.iotService.createGatewayRule = true;
    //that.chkchanged();
    that.gatewayName = gateway.name[0].text;
    var selectedGatewayId = gateway.id;
    if(!isOnLoad){
      that.gatewaysGroupName = "Select Device";
      that.selectedGatewayDevices = null;
      that.obervableEvents = [];
      //that.gatewayEventName = "Select Event";
      that.supportedCommands = [];
      that.selectedGatewayEventTemp = {};
    }
    that.iotService.getCuiObjResponse().getGroups({
      qs:[['owner.id',gateway.backingDeviceId],['name',"Gateway device group"]] // Hardcoded as discussed with Sam (as per old ui comment)
    })
    .then(function(response) {
      that.gatewayGroup=response[0];
      that.gatewayGroups.push(response[0]);
      //that.subscribersList.push({"type":"ecosystemtemplate","value":response[0]});
      // let groupTemplates = [];
      // groupTemplates=that.rulesService.getEcosystemTemplates();
      // groupTemplates.push(response);
      // that.rulesService.setEcosystemtemplates(groupTemplates);
      //that.triggerDeviceTemplates.push(response[0])
      //that.refresh();
    

      // that.iot.data.currentFilter['groups'] = {
      //       qs: [
      //           ['pageSize', 8],
      //           ['page', that.iot.data.currentPage['groups']],
      //           ['sortBy', '-creation'],
      //           ['active', 'true'],
      //           ['owner.id', gateway.backingDeviceId],
      // ['name', 'Gateway device group']    // Hardcoded as discussed with Sam
      //       ]
      //   };
      // var gatewayDevices  = [];
      that.gatewayHasDevices = false;
      // that.iot.data.load('groups').then(function() {
      // that.gatewayGroups.forEach((value, index)=> {
      that.iotService.getCuiObjResponse().getEcosystemMemberships({
        ecosystemId: that.gatewayGroup.id
      })
      .then(function(response) {
        that.selectedGatewayGroupId = that.gatewayGroups[0].id;
        if(isOnLoad){
          var oldSelectedGatewayGroupId = that.gatewayGroups[0].id;
        }
        var count=0;
        response.forEach((value2, index)=> {
          that.obervableEvents = [];
          count++;
          if(value2.member.type == 'device'){            
            that.iotService.getCuiObjResponse().getDevice({
              deviceId: value2.member.id
            })
            .then(function(response2) {
              if(response2.name[0].text != gateway.name[0].text){
               // gatewayDevices.push(response2);
               if(that.rulesService.gatewayDevices!="")
               {
                that.rulesService.gatewayDevices= that.rulesService.gatewayDevices+"^^id::"+response2.id;
                that.gatewayDeviceList.push(response2);
               }
               else
               {
                that.rulesService.gatewayDevices= "id::"+response2.id;
                that.gatewayDeviceList.push(response2);
               }
               that.gatewayDeviceCount++;
              if(count==response.length){
                setTimeout(function(){
                  that.rulesService.showGatewayDevices=true;
                }, 3000);
              }
                that.gatewayHasDevices = true;
                if(isOnLoad){
                  if(response2.observableEvents!=undefined){
                  response2.observableEvents.forEach((value3, key3)=> {
                    that.obervableEvents.push(value3);
                    that.obervableEvents.push(value3, function(e) {
                      return e.id === value3.id;
                    });
                    //that.rulesService.showGatewayDevicesEvents=true;
                  });
                }
                if(response2.supportedCommands!=undefined){
                  response2.supportedCommands.forEach((value3, key3)=> {
                    that.supportedCommands.push(value3);
                    that.supportedCommands.push(value3, function(e) {
                      return e.id === value3.id;
                    });
                  });
                }
                }
              }
              setTimeout(function(){
                that.rulesService.loading = false;
              }, 4000);
              that.refresh();
            }).fail(function (err){
              console.log("err msg: "+ JSON.stringify(err));
              if(err.responseJSON.apiStatusCode){
                that.rulesService.errorMessage = err.responseJSON.apiMessage;
                alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
                  } else{
                    alert("Something went wrong. Please check chrome console or check with dev team");
                  }
              });
          }
        });
      }).fail(function (err){
        console.log("err msg: "+ JSON.stringify(err));
        if(err.responseJSON.apiStatusCode){
          that.rulesService.errorMessage = err.responseJSON.apiMessage;
          alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
            } else{
              alert("Something went wrong. Please check chrome console or check with dev team");
            }
        });
    }).fail(function (err){
      console.log("err msg: "+ JSON.stringify(err));
      if(err.responseJSON.apiStatusCode){
        that.rulesService.errorMessage = err.responseJSON.apiMessage;
        alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
          } else{
            alert("Something went wrong. Please check chrome console or check with dev team");
          }
      });
    
  }

  selectGroup(device, isOnLoad){
    const that=this;
    that.gatewaysGroupName = device.name[0].text;
    that.selectedGatewayDevices = device ;
    that.obervableEvents = [];
    that.supportedCommands = [];
    if(!isOnLoad){
      that.selectedGatewayEventList = [];
      that.selectedGatewayEventTemp = {};
      //that.gatewayEventName = "Select Event";
    }
    if( device.observableEvents){
      device.observableEvents.forEach((value, key)=> {
        that.obervableEvents.push(value);
        that.obervableEvents.push(value, function(e) {
          return e.id === value.id;
        });
      });
    }
    if( device.supportedCommands){
      device.supportedCommands.forEach((value, key)=> {
        that.supportedCommands.push(value);
        that.supportedCommands.push(value, function(e) {
          return e.id === value.id;
        });
      });
    }
    that.refresh();
  }

  selectedGatewayEvent(event){
    const that=this;
    that.selectedGatewayEventTemp = event;
    //that.gatewayEventName = event.name;
    that.chkchanged();
    that.samplegatewayinputjson = "";
    that.samplegatewayinputjson = '{';
    if(that.selectedGatewayDevices && that.selectedGatewayDevices !== 'null' && that.selectedGatewayDevices !== 'undefined'){
      that.samplegatewayinputjson += '\n\t "deviceId":"'+that.selectedGatewayDevices.id+'",';
    }
    that.samplegatewayinputjson = that.samplegatewayinputjson + '\n\t "eventTemplateId":"'+that.selectedGatewayEventTemp.id +'"';
    //Adding event fields
    event.eventFields.forEach((value,key)=>{
      that.samplegatewayinputjson = that.samplegatewayinputjson + ',\n\t "'+value.name+'":"Value here"';
    });
    that.samplegatewayinputjson = that.samplegatewayinputjson + '\n}'
    that.refresh();
  }

  removeGatewayEvents(gatewayEvent) {
    const that=this;
        var index = that.findById(that.selectedGatewayEventList, gatewayEvent);
        if (!index===undefined) {
          that.selectedGatewayEventList.splice(index, 1);
        }
      that.samplegatewayinputjson = '{\n  "deviceId":"'+that.selectedGatewayDevices.id+'"';
    that.selectedGatewayEventList.forEach((value, key)=> {
      that.samplegatewayinputjson = that.samplegatewayinputjson + ',\n "eventTemplateId":"'+value.id+'"';
    });
    that.samplegatewayinputjson = that.samplegatewayinputjson + '\n}'
  };

  findById(collection, source) {
    var index;
    collection.forEach((value,key) => {
      if (value.id === source.id) {
        index = key;
      }
    });
    return index;
  };

  findSubscriberById(collection, source) {
    var index;
    collection.forEach((value,key) => {
      if (value.value.id === source.id) {
        index = key;
      }
    });
    return index;
  };

  findDeviceByParentId(collection, source) {
      var index;
      collection.forEach((value,key) => {
        if (value.parentDeviceTemplateId === source.id) {
          index = key;
      }
      });
      return index;
  };

  activateRulePolicy(rulePolicyId: any){
    const that = this;
    that.resetdata();
    that.iotService.getCuiObjResponse().activateRulePolicy({
      rulePolicyId: rulePolicyId
    }).then(function (response) {
      that.loadRulePolicy(rulePolicyId);
      that.rulesService.successMessage="Rule Policy activated successfully";
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.rulesService.errorMessage = err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }

  deactivateRulePolicy(rulePolicyId: any){
    const that = this;
    that.resetdata();
    that.iotService.getCuiObjResponse().deactivateRulePolicy({
      rulePolicyId: rulePolicyId
    }).then(function (response) {
      that.loadRulePolicy(rulePolicyId);
      that.rulesService.successMessage="Rule Policy deactivated successfully";
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.rulesService.errorMessage = err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }
 
  deleteRule(rulePolicyId: any){
    const that = this;
    that.iotService.getCuiObjResponse().deleteRulePolicy({
      rulePolicyId: rulePolicyId,
    }).then(function (response) {
      that.router.navigateByUrl("/rules");
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.rulesService.errorMessage = err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }

  selectGateway(event){
    const that= this;
    that.chkBoxModel=true;
    that.gatewayEventName = 'generic_signal';
      that.chkchanged();
    if (event == true) {
      that.showGateway=true;
      that.showEventDeviceTab=false;      
    } else {
      that.showGateway=false;
      that.chkBoxModel=false;
      that.resetdata();
      this.rulesService.showEcosystemTemplates=true;
      that.rulesService.showDeviceTemplates=true;
      that.showEventDeviceTab=true;
    }
  }

  test(){
    const that = this;
    that.showTestRule=true;
  }

  closeTest(){
    const that = this;
    that.showTestRule=false;
  }

  runInSimulationMode(event){
    const that=this;
    if (event == true) {
      that.showGateway=true;
    } else {
      that.showGateway=false;
    }
  }

  testRule() {
    const that = this;
    that.testing = true;
    that.testResult = null;
    that.testError = null
    that.testJSONError = "";
      var testJson /// MIKE
      try {
        /*var o = JSON.parse($scope.simulatedata);*/
        var o = JSON.parse(that.testScript);
          if (o && typeof o === "object") {
            that.iotService.getCuiObjResponse().testPolicyRule({
                  rulePolicyId: that.rulePolicy.id,
                  simulate: that.simulate,
                  /*waittime: $scope.simulatetimeout,*/
                  waittime: that.rulePolicy.script.timeout,
                  data: o
              }).then(function(results) {
                console.log("test passed");
                that.testing = false;
                that.testResult = results;
                that.testResult.results[0].executionTimeNanos = that.testResult.results[0].executionTimeNanos/1000000;
                that.refresh();
              }).fail(function(err) {
                console.log("test failed");
                if (err.responseJSON.apiStatusCode) {
                  //that.rulesService.errorMessage = err.responseJSON.apiMessage;
                  that.testError = err.responseJSON.apiMessage;
                  console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
                } else {
                  console.log("Something went wrong. Please check chrome console or check with dev team");
                }
                that.testing = false;
              });
          } else {
            that.testing = false;
            that.testResult = "Failed";
          }
      } catch (e) {
        that.testing = false;
        that.testJSONError = "Invalid JSON"
          return false;
      }
  }
  
  initTestRuleScript(){
    const that = this;
    that.simulate = false;
    that.testJSONError = "";
    that.testResult = undefined;
    that.refresh();
  }

  toggleSampleInput(){
    const that=this;
    that.viewSampleinputjson = !that.viewSampleinputjson;
  }
}
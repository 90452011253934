import { Component, OnInit,Inject } from '@angular/core';
import { Router } from '@angular/router';
import { faAngleUp, faAngleDown, faTimes, faExclamationTriangle, faThumbsDown ,faExclamation} from '@fortawesome/free-solid-svg-icons';
import { faToggleOff, faToggleOn, faAngleLeft, faAngleRight, faCheck, faCopy, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { IotService } from '../service/iot.service';
import { MessageGovernancesService } from '../service/messageGovernances.service';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER, I } from '@angular/cdk/keycodes';
import { FormControl, FormGroup } from '@angular/forms';
import { DigitalTwinsService } from '../service/digitalTwins.service';
import { StreamsService } from '../service/streams.service';
import {ToTPCodesService} from '../service/toTPCodes.service';
import {FromTPCodesService} from '../service/fromTPCodes.service';
import {CommandsService} from '../service/commands.service';
import {EventsService} from '../service/events.service';
import {DocumentInfoService} from '../service/documentInfo.service';
import cronstrue from 'cronstrue';

@Component({
  selector: 'app-create-message-governance',
  templateUrl: './create-message-governance.component.html',
  styleUrls: ['./create-message-governance.component.scss']
})
export class CreateMessageGovernanceComponent implements OnInit {
  separatorKeysCodes: number[] = [ENTER, COMMA];
  messageGovernance: any;
  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;
  faCloseIcon = faTimes;
  faCriticalIcon = faExclamation;
  faWarningIcon = faExclamationTriangle;

  failed = faTimes;
  faToggleOffIcon = faToggleOff;
  faToggleOnIcon = faToggleOn;
  faAngleLeftIcon = faAngleLeft;
  faAngleRightIcon = faAngleRight;
  faCheckIcon = faCheck;
  faCopyIcon = faCopy;
  faAngleDoubleLeftIcon = faAngleDoubleLeft;
  faAngleDoubleRightIcon = faAngleDoubleRight;
  type = 'Device Event';
  messageStateType;
  showLogLevel = false;
  hasError: boolean = false;
  errorInName;
  errorInSchedule;
  errorInMessageCount;
  errorInGracePeriod;
  errorInMessagState;
  errorInEmailAddress;
  errorInSmsAddress;
  observableDeviceIds=[];
  observableDevicesTemplatesIds=[];
  observableEventsIds=[];
  observableSenderMessageInfoIds=[];
  observableSenderStreamsIds=[];
  observableReceivingStreamsIds=[];
  observableCommandTemplatesIds=[];
  observableSendingTPCodeIds=[];
  observableReceivingTPCodeIds=[];
  form: FormGroup;
  msgGovernanceId;
  messageGovernanceCreated=false;
  deliveryScheduleError;
  triggerTypes = [
    {
      id: 1,
      type: 'Device Event',
      value:'DEVICE_EVENT'
    },
    {
      id: 2,
      type: 'Command',
      value:'COMMAND'
    },
    {
      id: 3,
      type: 'Trading Partner Message',
      value:'TRADING_PARTNER_MESSAGE',
    }
  ];

  messageStateTypes = [
    {
      id: 1,
      type: 'Select',
      value: ''
    },
    {
      id: 2,
      type: 'Received',
      value:'RECEIVED'
    },
    {
      id: 3,
      type: 'Delivered',
      value:'DELIVERED'
    },
  ];

  tags = [];
  tagClass = 'tagInput';
  successMessage: string;
  warningMessage: string;
  isActive = true;



  supportLanguages = ['en'];
  authorityColumns = ["Name", "Type"];

  constructor(public iotService: IotService,public messageGovernancesService:MessageGovernancesService,
     private translateService: TranslateService, private sideNavComponent: SideNavComponent, private router: Router,public digitalTwinsService: DigitalTwinsService,public streamService: StreamsService,
     public toTPCodesService:ToTPCodesService, public fromTPCodesService:FromTPCodesService,  public commandsService:CommandsService,  public eventsService:EventsService,   public documentInfoService:DocumentInfoService, @Inject('streamsService1') public firstStreamsService: StreamsService,
     @Inject('streamsService2') public secondStreamsService: StreamsService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
    this.sideNavComponent.menuClear();
    this.sideNavComponent.menuChange('Dashboard', 'sub-type');
    this.sideNavComponent.menuChange('messageGovernances.header', 'sub-type');
    this.sideNavComponent.menuChange('messageGovernances.create.new', 'sub-type');

  }

  ngOnInit(): void {
    const that = this;
    that.messageGovernancesService.resetErrorMessage();
    that.resetdata();
    this.initMessageGovernance();
    this.iotService.createMessageGovernance=true;
  }
  resetErrorMessage() {
    this.messageGovernancesService.resetErrorMessage();
  }
  resetdata(){
    const that = this;
    that.messageGovernancesService.events=[];
    that.messageGovernancesService.devices=[];
    that.messageGovernancesService.senderStreams=[];
    that.messageGovernancesService.receiverStreams=[];
    that.messageGovernancesService.documentInfos=[];
    that.messageGovernancesService.commandTemplates=[];
    that.messageGovernancesService.fromCodeValue=[];
    that.messageGovernancesService.toCodeValue=[];
    that.digitalTwinsService.selection.clear();
    that.documentInfoService.selection.clear();
    that.toTPCodesService.selection.clear();
    that.fromTPCodesService.selection.clear();
    that.commandsService.selection.clear();
    that.eventsService.selection.clear();
    that.secondStreamsService.selection.clear();
    that.firstStreamsService.selection.clear();
    that.streamService.selection.clear();
    that.documentInfoService.selection.clear();
    that.streamService.selection.clear();
    that.deliveryScheduleError = '';
  }
  ngOnDestroy() {
   const that = this;
   that.tags = [];
   that.tagClass = '';
   that.iotService.createMessageGovernance = false;
   that.digitalTwinsService.selection.clear();
   that.documentInfoService.selection.clear();
   that.toTPCodesService.selection.clear();
   that.fromTPCodesService.selection.clear();
   that.commandsService.selection.clear();
   that.eventsService.selection.clear();
   that.secondStreamsService.selection.clear();
   that.firstStreamsService.selection.clear();
   that.streamService.selection.clear();
   that.documentInfoService.selection.clear();
   that.streamService.selection.clear();
   that.messageGovernancesService.events=[];
   that.messageGovernancesService.devices=[];
   that.messageGovernancesService.senderStreams=[];
   that.messageGovernancesService.receiverStreams=[];
   that.messageGovernancesService.documentInfos=[];
   that.messageGovernancesService.commandTemplates=[];
   that.messageGovernancesService.fromCodeValue=[];
   that.messageGovernancesService.toCodeValue=[];
   that.messageGovernance = {
    creator: "",
    creatorAppId: "",
    realm: "",
    name: [{
      lang: "",
      text: ""
    }],
    description: [{
      lang: "",
      text: ""
    }],
    state :"",
    graceTime: 0,
    quota:0,
    schedule:"",
    tags: [],
    trigger:"",
    messageState:"",
    userDefineMessage: "",
    emailAddress: "",
    generateTrackingMessage :false,
    smsAddress: "",
    observableDevices:[],
    observableDevicesTemplates: [],
    observableEvents: [],
    observableSenderMessageInfo: [],
    observableSenderStreams: [],
    observableReceivingStreams: [],
    observableCommandTemplates: [],
    observableSendingTPCode: [],
    observableReceivingTPCode: []
  };
  
  }

  resetWarningMessage() {
    this.warningMessage = '';
  }

  initMessageGovernance() {
    const that = this;
    that.messageGovernance = {
      creator: that.iotService.getCreator(),
      creatorAppId: that.iotService.getCreatorAppId(),
      realm: that.iotService.getRealm(),
      name: [{
        lang: "en_US",
        text: ""
      }],
      description: [{
        lang: "en_US",
        text: ""
      }],
      state :"INACTIVE",
      graceTime: 0,
      quota:0,
      schedule:"",
      tags: [],
      trigger:that.triggerTypes[0].value,
      messageState:"",
      userDefineMessage: "",
      emailAddress: "",
      generateTrackingMessage :false,
      smsAddress: "",
      observableDevices:[],
      observableDevicesTemplates: [],
      observableEvents: [],
      observableSenderMessageInfo: [],
      observableSenderStreams: [],
      observableReceivingStreams: [],
      observableCommandTemplates: [],
      observableSendingTPCode: [],
      observableReceivingTPCode: []
    };
  }

  logMode() {
    if (this.showLogLevel == false) {
      this.showLogLevel = true;
    } else {
      this.showLogLevel = false;
    }
  }
  getCron() {
    const that=this;
     var results = "";
     if (that.messageGovernance.schedule) {
         try {
             results = cronstrue.toString(that.messageGovernance.schedule);
         } catch (err) {
             results = "";
         }
 
 
         console.log(results)
     }
     return results;
 }
  createTag(value,messageGovernanceId){
    const that =this;
    that.iotService.getCuiObjResponse().createMsgGovTag({
      msgGovernanceId: messageGovernanceId,
      tag: value
    }).then(function(response) {
      console.log("added tag:"+value+" to msgGovernance :"+that.messageGovernance.id);
        that.messageGovernance.version = parseInt(that.messageGovernance.version) + 1;
    /*     if(that.messageGovernance.tags == undefined) {
          that.messageGovernance.tags = [];
        }
        const index = that.messageGovernance.tags.indexOf(value);
        if (index < 0) {
          that.messageGovernance.tags.push(value);
          input.value = '';
        } */
        // Reset the input value
        
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.messageGovernancesService.errorMessage= err.responseJSON.apiMessage;
        alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        alert("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }
  save() {
    const that = this;
    that.messageGovernancesService.resetErrorMessage();
    if(!that.errorValidations()){
      that.messageGovernance.observableDevices = that.getObservableDeviceIds();
      that.messageGovernance.observableEvents = that.getObservableEventsIds();
      that.messageGovernance.observableCommandTemplates =that.getObservableCommandTemplatesIds();
      that.messageGovernance.observableSenderStreams=that.getObservableSenderStreamsIds();
      that.messageGovernance.observableReceivingStreams=that.getObservableReciverStreamsIds();
      that.messageGovernance.observableSenderMessageInfo = that.getObservableSenderMessageInfoIds();
      that.messageGovernance.observableSendingTPCode=that.getObservableSendingTPCodeIds();
      that.messageGovernance.observableReceivingTPCod=that.getObservableReceivingTPCodeIds();
      that.messageGovernance.tags = that.tags;
      that.messageGovernanceCreated=true;
    that.iotService.getCuiObjResponse().createMessageGovernance({
      data: that.messageGovernance
    }).then(function (response) {
      console.log("Message Governance created succesfully. Id :", response.id);
      that.messageGovernance.tags.forEach(function (value) {
        console.log(value);
        that.createTag(value,response.id);
      });
      that.router.navigateByUrl("/messageGovernances");
    })
      .fail(function (err) {
        that.messageGovernanceCreated=false;
        if (err.responseJSON.apiStatusCode) {
          if (err.responseJSON.apiMessage == "The following properties contained illegal values: emailAddress") {
            that.messageGovernancesService.errorMessage = "Enter valid email for Email Address Field";
          } else if(err.responseJSON.apiMessage.includes("DEVICE_EVENT requires either  observableEvents")){
            that.messageGovernancesService.errorMessage = "DEVICE_EVENT requires either Devices, DocumentInfo, Evemts, SenderStreams or ReceivingStreams.";
          } else if(err.responseJSON.apiMessage.includes("COMMAND requires either  observableCommandTemplates")){
            that.messageGovernancesService.errorMessage = "COMMAND requires either CommandTemplates, Devices,SenderStreams or ReceivingStreams.";
          }
          else if(err.responseJSON.apiMessage.includes("TRADING_PARTNER_MESSAGE requires either  observableReceivingStreams")){
            that.messageGovernancesService.errorMessage = "TRADING_PARTNER_MESSAGE requires either SenderStreams, ReceivingStreams, DocumentInfo, SendingTPCode or ReceivingTPCode.";

          }else{
            that.messageGovernancesService.errorMessage = err.responseJSON.apiMessage;
          }
          console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
        } else {
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
        if (err === null) {
          that.warningMessage = "Error occured while creating token";
          console.log("Error occured while creating messaging governance info");
        }
      });
    }

  }


    getObservableEventsIds() {
    this.observableEventsIds = [];
    if (this.messageGovernancesService.getEvents() != undefined) {
      this.messageGovernancesService.getEvents().forEach((value) => {
        this.observableEventsIds.push(value.id);
      });
    }
    return this.observableEventsIds;
  }

    getObservableDeviceIds() {
      this.observableDeviceIds = [];
      if (this.messageGovernancesService.getDevices != undefined) {
        this.messageGovernancesService.getDevices().forEach((value) => {
          this.observableDeviceIds.push(value.id);
        });
      }
      return this.observableDeviceIds;
    }

    getObservableSenderMessageInfoIds(){
      this.observableSenderMessageInfoIds = [];
      if (this.messageGovernancesService.getDocumentInfos != undefined) {
        this.messageGovernancesService.getDocumentInfos().forEach((value) => {
          this.observableSenderMessageInfoIds.push(value.id);
        });
      }
      return this.observableSenderMessageInfoIds;
    }

    getObservableSenderStreamsIds(){
      this.observableSenderStreamsIds = [];
      if (this.messageGovernancesService.getSenderStreams() != undefined) {
        this.messageGovernancesService.getSenderStreams().forEach((value) => {
          this.observableSenderStreamsIds.push(value.id);
        });
      }
      return this.observableSenderStreamsIds;
    }

    getObservableReciverStreamsIds(){
      this.observableReceivingStreamsIds = [];
      if (this.messageGovernancesService.getReceiverStreams() != undefined) {
        this.messageGovernancesService.getReceiverStreams().forEach((value) => {
          this.observableReceivingStreamsIds.push(value.id);
        });
      }
      return this.observableReceivingStreamsIds;
    }


    getObservableCommandTemplatesIds(){
      this.observableCommandTemplatesIds = [];
      if (this.messageGovernancesService.getCommandTemplates() != undefined) {
        this.messageGovernancesService.getCommandTemplates().forEach((value) => {
          this.observableCommandTemplatesIds.push(value.id);
        });
      }
      return this.observableCommandTemplatesIds;
    }


    getObservableSendingTPCodeIds(){
      this.observableSendingTPCodeIds = [];
      if (this.messageGovernancesService.getFromCodeValue() != undefined) {
        this.messageGovernancesService.getFromCodeValue().forEach((value) => {
          this.observableSendingTPCodeIds.push(value.id);
        });
      }
      return this.observableSendingTPCodeIds;
    }


    getObservableReceivingTPCodeIds(){
      this.observableReceivingTPCodeIds = [];
      if (this.messageGovernancesService.getToCodeValue() != undefined) {
        this.messageGovernancesService.getToCodeValue().forEach((value) => {
          this.observableReceivingTPCodeIds.push(value.id);
        });
      }
      return this.observableReceivingTPCodeIds;
    }


  add(event: MatChipInputEvent): void {

    this.tagClass = 'tagInput';
    const input = event.input;
    const value = event.value;
    const index = this.tags.indexOf(value);
    if (index < 0) {
      if ((value || '').trim()) {
        if (this.tags != undefined && this.tags.includes(value)) {
          if (input) {
            input.value = '';
          }
        }
        else {
          this.tags.push(value);
          if (input) {
            input.value = '';
          }
        }
      }
    } else {
      this.tagClass = 'tagInput-duplicate';
    }
  }

  remove(tag: string): void {
    const that = this;
    that.tags.forEach((value, index) => {
      if (value == tag) that.tags.splice(index, 1);
    });

  }

  toggleActive() {
    this.isActive = !this.isActive;
  }

  messageGovernanceNameInput(event: any) {
    const that = this;
    that.messageGovernance.name[0].text = event.target.value;
    if (that.messageGovernance.name[0].text.length > 0) {
      that.errorInName = '';
    } else {
      that.messageGovernance.name[0].text = '';
    }
  }

  messageGovernanceScheduleInput(event: any) {
    const that = this;
    that.messageGovernance.schedule = event.target.value;
    if (that.messageGovernance.schedule.length > 0) {
      that.errorInSchedule = '';
    } else {
      that.messageGovernance.schedule = '';
    }
  }

  messageGovernanceQuotaInput(event: any) {
    const that = this;
    that.messageGovernance.quota = Number(event.target.value);
    if (that.messageGovernance.quota > 0) {
      that.errorInMessageCount = '';
    } else {
      that.messageGovernance.quota = '';
    }
  }

  messageGovernanceGracePeriodInput(event: any) {
    const that = this;
    that.messageGovernance.graceTime = Number(event.target.value);
    if (that.messageGovernance.graceTime > 0) {
      that.errorInGracePeriod = '';
    } else {
      that.messageGovernance.graceTime = '';
    }
  }

  messageGovernanceMessageStateInput(event: any) {
    const that = this;
    that.messageGovernance.messageState = event.target.value;
    if (that.messageGovernance.messageState.length > 0) {
      that.errorInMessagState = '';
    } else {
      that.messageGovernance.messageState = '';
    }
  }

  messageGovernanceEmailAddressInput(event: any) {
    const that = this;
    that.messageGovernance.emailAddress = event.target.value;
    if (that.messageGovernance.emailAddress.length > 0) {
      that.errorInEmailAddress = '';
    } else {
      that.messageGovernance.emailAddress = '';
    }
  }

  isPhoneNumber(phoneNo) {
    var regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    return regex.test(phoneNo);
  }
  errorValidations() {
    const that = this;
    that.hasError = false;
    that.errorInSmsAddress = '';

    if (
      that.messageGovernance.name[0].text == undefined ||
      that.messageGovernance.name[0].text == ''
    ) {
      that.errorInName = 'Name is mandatory';
      that.hasError = true;
    }

    if(that.messageGovernance.schedule == undefined || that.messageGovernance.schedule == ''){
      that.errorInSchedule = 'Schedule is mandatory';
      that.hasError = true;
    } else{
      var res = that.getCron();
      that.validateRegExpression(that.messageGovernance.schedule);
    }

    if(that.messageGovernance.quota == undefined || that.messageGovernance.quota <= 0){
      that.errorInMessageCount = "Message count should be greater than 0";
      that.hasError = true;
    }

    if(that.messageGovernance.graceTime == undefined || that.messageGovernance.graceTime <= 0){
      that.errorInGracePeriod = "Grace period should be greater than 0";
      that.hasError = true;
    }

    if(that.messageGovernance.messageState == undefined || that.messageGovernance.messageState == ''){
      that.errorInMessagState = 'Message State either recieved or delivered';
      that.hasError = true;
    }

    
    if(that.messageGovernance.smsAddress != undefined && that.messageGovernance.smsAddress != ''){
      if(!that.isPhoneNumber(that.messageGovernance.smsAddress)){
        that.errorInSmsAddress = 'Please enter a valid phone number';
        that.hasError = true;
      }else{
        that.errorInSmsAddress = '';
      }
     
    }

    if(that.messageGovernance.emailAddress == undefined || that.messageGovernance.emailAddress == '') {
      that.errorInEmailAddress = 'Email Address is mandatory';
      that.hasError = true;
    }else{
      if(!that.validateEmail(that.messageGovernance.emailAddress)){
        that.hasError = true;
        that.errorInEmailAddress = 'Enter valid Email Address';
      }

    }
	 return that.hasError;
	}

onChange(event){
  this.resetErrorMessage();
  this.resetdata();
}
validateEmail(email) {
  const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regularExpression.test(String(email).toLowerCase());
 }

validateRegExpression(expression) {
  const that = this;
console.log("validating deliverySchedule {} with regularExpression "+expression);
   var regex =
          "^\\s*($|#|\\w+\\s*=|(\\?|\\*|(?:[0-5]?\\d)(?:(?:-|\\/|\\,)(?:[0-5]?\\d))?(?:,(?:[0-5]?\\d)(?:(?:-|\\/|\\,)(?:[0-5]?\\d))?)*)\\s+(\\?|\\*|(?:[0-5]?\\d?|\\*)(?:(?:-|\\/|\\,)(?:[0-5]?\\d?|\\*))?(?:,(?:[0-5]?\\d?|\\*)(?:(?:-|\\/|\\,)(?:[0-5]?\\d?|\\*))?)*)\\s+(\\?|\\*|(?:[01]?\\d|2[0-3])(?:(?:-|\\/|\\,)(?:[01]?\\d|2[0-3]))?(?:,(?:[01]?\\d|2[0-3])(?:(?:-|\\/|\\,)(?:[01]?\\d|2[0-3]))?)*)\\s+(\\?|\\*|(?:0?[1-9]|[12]\\d|3[01])(?:(?:-|\\/|\\,)(?:0?[1-9]|[12]\\d|3[01]))?(?:,(?:0?[1-9]|[12]\\d|3[01])(?:(?:-|\\/|\\,)(?:0?[1-9]|[12]\\d|3[01]))?)*)\\s+(\\?|\\*|(?:[1-9]|1[012])(?:(?:-|\\/|\\,)(?:[1-9]|1[012]))?(?:L|W)?(?:,(?:[1-9]|1[012])(?:(?:-|\\/|\\,)(?:[1-9]|1[012]))?(?:L|W)?)*|\\?|\\*|(?:JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)(?:(?:-)(?:JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC))?(?:,(?:JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)(?:(?:-)(?:JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC))?)*)\\s+(\\?|\\*|(?:[0-6])(?:(?:-|\\/|\\,|#)(?:[0-6]))?(?:L)?(?:,(?:[0-6])(?:(?:-|\\/|\\,|#)(?:[0-6]))?(?:L)?)*|\\?|\\*|(?:MON|TUE|WED|THU|FRI|SAT|SUN)(?:(?:-)(?:MON|TUE|WED|THU|FRI|SAT|SUN))?(?:,(?:MON|TUE|WED|THU|FRI|SAT|SUN)(?:(?:-)(?:MON|TUE|WED|THU|FRI|SAT|SUN))?)*)(|\\s)+(\\?|\\*|(?:|\\d{4})(?:(?:-|\\/|\\,)(?:|\\d{4}))?(?:,(?:|\\d{4})(?:(?:-|\\/|\\,)(?:|\\d{4}))?)*))$";

         if(expression.match(regex) ){
            console.log("yes");
          }else{
            that.errorInSchedule = 'Please enter valid cron expression';
            that.hasError = true;
            console.log("No");
          }
}
}

<mat-sidenav-container class="new-governance" autosize>

    <mat-sidenav class="midNav-container" #sidenav mode="side" disableClose="true" opened="true">
        <div class="alert critical" *ngIf="messageGovernancesService.errorMessage">
            <h5>
                <fa-icon [icon]="faCriticalIcon"></fa-icon>
                <span>{{messageGovernancesService.errorMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=messageGovernancesService.resetErrorMessage() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>

        </div>
        <div class="data">
            <h1> {{ 'messagingGovernances.createMessagingGovernance' | translate}}</h1>

            <mat-nav-list class="without-template user-selections">

                <ng-container *ngIf="messageGovernance.trigger=='COMMAND'">
                    <div>
                        <h5>{{'command.template' | translate}}</h5>
                         <p *ngIf="messageGovernancesService.commandTemplates.length == 0">
                             {{'select.commandTemplates.fromlist' | translate}}
                        </p>
                        <ul>
                            <li *ngFor="let command of messageGovernancesService.commandTemplates">
                                <fa-icon [icon]="faCheckIcon"></fa-icon> {{command.name}}
                            </li>
                        </ul>
                    </div>
                </ng-container> 
                 <ng-container *ngIf="messageGovernance.trigger=='DEVICE_EVENT' || messageGovernance.trigger=='COMMAND'">
                    <div>
                        <h5>{{'devices' | translate}}</h5>
                         <p *ngIf="messageGovernancesService.devices.length == 0">
                             {{'select.devices.fromlist' | translate}}
                        </p>
                        <ul>
                            <li *ngFor="let device of messageGovernancesService.devices">
                                <fa-icon [icon]="faCheckIcon"></fa-icon> {{device.name[0].text}}
                            </li>
                        </ul>
                    </div>
                </ng-container> 

                <ng-container *ngIf="messageGovernance.trigger=='DEVICE_EVENT'">
                    <div>
                        <h5>{{'events' | translate}}</h5>
                        <p *ngIf="messageGovernancesService.getEvents().length == 0">
                            {{'select.events.fromlist' | translate}}
                        </p>
                        <ul>
                            <li *ngFor="let event of messageGovernancesService.events">
                                <fa-icon [icon]="faCheckIcon"></fa-icon> {{ event.name }}
                            </li>
                        </ul>
                    </div>
                </ng-container> 

                <ng-container *ngIf="messageGovernance.trigger=='DEVICE_EVENT' || messageGovernance.trigger=='TRADING_PARTNER_MESSAGE'">
                    <div>

                        <h5>{{'documentInfo' | translate}}</h5>
                        <p *ngIf="messageGovernancesService.documentInfos.length == 0">
                            {{'select.documentInfos.fromlist' | translate}}
                        </p>
                        <ul>
                            <li *ngFor="let documentInfo of messageGovernancesService.documentInfos">
                                <fa-icon [icon]="faCheckIcon"></fa-icon> {{ documentInfo.name[0].text }}
                            </li>
                        </ul>
                    </div>
                </ng-container> 

                <ng-container *ngIf="messageGovernance.trigger=='TRADING_PARTNER_MESSAGE'">
                    <div>
                        <h5>{{'sender.tpCode' | translate}}</h5>
                        <p *ngIf="messageGovernancesService.fromCodeValue.length == 0">
                            {{'select.senderTPcodes' | translate}}
                        </p>
                        <ul>
                            <li *ngFor="let fromCode of messageGovernancesService.fromCodeValue">
                                <fa-icon [icon]="faCheckIcon"></fa-icon> {{ fromCode.code }}
                            </li>
                        </ul>
                    </div>
                </ng-container> 


                <ng-container *ngIf="messageGovernance.trigger=='TRADING_PARTNER_MESSAGE'">
                    <div>
                        <h5>{{'receiver.tpCode' | translate}}</h5>
                        <p *ngIf="messageGovernancesService.toCodeValue.length == 0">
                            {{'select.receiverTPcodes' | translate}}
                        </p>
                        <ul>
                            <li *ngFor="let toCode of messageGovernancesService.toCodeValue">
                                <fa-icon [icon]="faCheckIcon"></fa-icon> {{ toCode.code }}
                            </li>
                        </ul>
                    </div>
                </ng-container> 


                <ng-container>
                    <div>
                        <h5>{{'sender.streams' | translate}}</h5>
                        <p *ngIf="messageGovernancesService.senderStreams.length == 0">
                            {{'select.senderStreams' | translate}}
                        </p>
                        <ul>
                            <li *ngFor="let senderStream of messageGovernancesService.senderStreams">
                                <fa-icon [icon]="faCheckIcon"></fa-icon> {{ senderStream.name[0].text }}
                            </li>
                        </ul>
                    </div>
                </ng-container> 


                <ng-container>
                    <div>
                        <h5>{{'receiver.streams' | translate}}</h5>
                        <p *ngIf="messageGovernancesService.receiverStreams.length == 0">
                            {{'select.receiverStreams' | translate}}
                        </p>
                        <ul>
                            <li *ngFor="let receiverStream of messageGovernancesService.receiverStreams">
                                <fa-icon [icon]="faCheckIcon"></fa-icon> {{ receiverStream.name[0].text }}
                            </li>
                        </ul>
                    </div>
                </ng-container> 

            </mat-nav-list>
            <div >
                <button [disabled]="messageGovernance.name[0].text=='' || messageGovernanceCreated" class="btn btn-primary"  (click)="save()">{{ 'save' | translate}}</button>
            </div>
        </div>  
    </mat-sidenav>
    <mat-sidenav-content class="example-sidenav-content" style="height: 450vh;">
        <div class="governance-data">

            <div class="form-data">
                <div class="row">
                    <div class="col-md-6">
                        <label for="name"> {{ 'messagingGovernances.name' | translate}}</label>
                        <input placeholder="required" id="name" type="text" aria-label="Name" (keyup)="messageGovernanceNameInput($event)"
                        [(ngModel)]="messageGovernance.name[0].text" ><br>
                        <p *ngIf="errorInName" style="color: red;">{{errorInName}}</p>

                        <label for="description">{{ 'messagingGovernances.description' | translate}}</label>
                        <textarea  placeholder="optional" id="description" aria-label="Description"
                        [(ngModel)]="messageGovernance.description[0].text" ></textarea><br>        
                        <label for="tags">{{ 'messagingGovernances.tags' | translate}}</label>
                        <mat-chip-list #tagList aria-label="Tags">
                            <mat-chip class="ot-chip" *ngFor="let tag of tags" [selectable]="true" [removable]="true"
                                (removed)="remove(tag)">
                                {{ tag }}
                                <button class="ot-chip__close" matChipRemove></button>
                            </mat-chip>
                            <input placeholder="add a tag" #tagInput [matChipInputFor]="tagList" 
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                                (matChipInputTokenEnd)="add($event)" />
                        </mat-chip-list>
                    </div>

                    <div class="col-md-6">
                        
                        <label for="shedule"> {{ 'messagingGovernances.schedule' | translate}}</label>
                        <input placeholder="required" id="schedule" type="text" aria-label="Schedule" (keyup)="messageGovernanceScheduleInput($event)"
                        [(ngModel)]="messageGovernance.schedule"><br>
                        <p>{{getCron()}}</p>
                        <p *ngIf="errorInSchedule" style="color: red;">{{errorInSchedule}}</p>
                        <label for="messageCount"> {{ 'messagingGovernances.messageCount' | translate}}</label>
                        <input  id="messageCount" type="number" aria-label="Message Count" (change)="messageGovernanceQuotaInput($event)"
                        [(ngModel)]="messageGovernance.quota"><br>
                        <p *ngIf="errorInMessageCount" style="color: red;">{{errorInMessageCount}}</p>

                        <label for="gracePeriod"> {{ 'messagingGovernances.gracePeriod' | translate}}</label>
                        <input id="gracePeriod" type="number" aria-label="Grace Period" (change)="messageGovernanceGracePeriodInput($event)"
                        [(ngModel)]="messageGovernance.graceTime" ><br>
                        <p *ngIf="errorInGracePeriod" style="color: red;">{{errorInGracePeriod}}</p>
                        
                        <label for="messageState"> {{ 'messagingGovernances.messageState' | translate}}</label>
                        <select name="messageState" [(ngModel)]="messageGovernance.messageState" (change)="messageGovernanceMessageStateInput($event)">
                            <option [value]="item.value" *ngFor="let item of messageStateTypes">
                                {{item.type}}
                            </option>
                        </select><br>
                        <p *ngIf="errorInMessagState" style="color: red;">{{errorInMessagState}}</p>
                        <label for="userDefinedMessage"> {{ 'messagingGovernances.userDefinedMessage' | translate}}</label>
                        <textarea  placeholder="optional" id="userDefinedMessage" aria-label="User Defined Message"
                        [(ngModel)]="messageGovernance.userDefineMessage" ></textarea><br>
                        
                        <label for="sms"> {{ 'messagingGovernances.sms' | translate}}</label>
                        <input placeholder="optional" id="sms" type="text" aria-label="SMS"
                        [(ngModel)]="messageGovernance.smsAddress"  ><br>
                        <p *ngIf="errorInSmsAddress" style="color:red;">{{errorInSmsAddress}}</p>

                        <label for="emailAddress"> {{ 'messagingGovernances.emailAddress' | translate}}</label>
                        <input placeholder="required" id="emailAddress" type="text" aria-label="Email Address" (keyup)="messageGovernanceEmailAddressInput($event)"
                        [(ngModel)]="messageGovernance.emailAddress" email #prmEmail="ngModel"><br>
                        <p *ngIf="errorInEmailAddress" style="color:red;">{{errorInEmailAddress}}</p>
                        <label for="generateTrackingMessage"> {{ 'messagingGovernances.generateTrackingMessage' | translate}}</label>
                            <select [(ngModel)]="messageGovernance.generateTrackingMessage">
                                <option [ngValue]=true>{{'yes' | translate}}</option>
                                <option [ngValue]=false>{{'no' | translate}}</option>
                            </select><br>
                       

                        <label for="triggerType"> {{ 'messagingGovernances.triggerType' | translate}}</label>
                        <select name="triggerType" [(ngModel)]="messageGovernance.trigger" >
                            <option [value]="item.value" *ngFor="let item of triggerTypes">
                                {{item.type}}
                            </option>
                        </select><br>
                    </div>
                </div>
                <div style="margin-left: -10px; margin-right: -15px;">
                    <app-commands *ngIf="messageGovernance.trigger=='COMMAND'" [pageSize]="5 " [showControlBar]=true [displayedColumns]="[ 'ADD', 'name','description','creation','ACTIVE'] " [showSelectColoumn]=true [showEnableColumn]=true [showNew]="false" [showHeaderToolTips]=false></app-commands> 
                     <app-digitalTwins *ngIf="messageGovernance.trigger=='DEVICE_EVENT' || messageGovernance.trigger=='COMMAND'" [pageSize]="5" [showSelectColoumn]=true
                     [showControlBar]=true [displayedColumns]="['ADD','name','description','parentDeviceTemplateName','creation','ACTIVE']" [showNew]="false" [showEnableColumn]=true [showHeaderToolTips]=false></app-digitalTwins>
                     <app-events *ngIf="messageGovernance.trigger=='DEVICE_EVENT'" [pageSize]="5" [showSelectColoumn]="true" [showControlBar]="true"  [showEnableColumn]=true [showNew]="false"
                    [displayedColumns]="['ADD', 'name', 'description','creation', 'ACTIVE']" [showHeaderToolTips]=false></app-events>
                    <app-fromTPCodes *ngIf="messageGovernance.trigger=='TRADING_PARTNER_MESSAGE'"[pageSize]="5 " [showControlBar]=true [displayedColumns]="[ 'ADD', 'code', 'qualifier','creation', 'ACTIVE'] " [showSelectColoumn]=true [showEnableColumn]=true [showNew]="false" [showHeaderToolTips]=false></app-fromTPCodes>
                    <app-toTPCodes *ngIf="messageGovernance.trigger=='TRADING_PARTNER_MESSAGE'" [pageSize]="5 " [showControlBar]=true [displayedColumns]="[ 'ADD', 'code', 'qualifier','creation', 'ACTIVE'] " [showSelectColoumn]=true [showEnableColumn]=true [showNew]="false" [showHeaderToolTips]=false></app-toTPCodes>
                    <app-documentInfo *ngIf="messageGovernance.trigger=='DEVICE_EVENT' || messageGovernance.trigger=='TRADING_PARTNER_MESSAGE'" [pageSize]="5 " [showControlBar]=true [displayedColumns]="[ 'ADD','name', 'supportedDocTemplateType', 'docTemplateVersion','creation', 'ACTIVE'] " [showSelectColoumn]=true [showEnableColumn]=true [showNew]="false" [showHeaderToolTips]=false>
                    </app-documentInfo>
                    <app-streams *ngIf="messageGovernance.trigger=='DEVICE_EVENT' " [pageSize]="5" [showNew]="false" [showControlBar]=true [displayedColumns]="['ADD','name','protocolType','direction','streamType','creation','ACTIVE']" showSelectColoumn=true showEnableColumn=true addnQueryParam=[streamType::DEVICE^^direction::ACTIVE_SENDING^^direction::PASSIVE_SENDING^^direction::BOTH]  pageTitle="Sending Streams" instance="1" [showHeaderToolTips]=false>
                    </app-streams>
                    <app-streams *ngIf="messageGovernance.trigger=='DEVICE_EVENT' " [pageSize]="5" [showNew]="false" [showControlBar]=true [displayedColumns]="['ADD','name','protocolType','direction','streamType','creation','ACTIVE']" showSelectColoumn=true showEnableColumn=true addnQueryParam=[streamType::APPLICATION^^direction::ACTIVE_RECEIVING^^direction::PASSIVE_RECEIVING^^direction::BOTH] pageTitle="Receiving Streams" instance="2" [showHeaderToolTips]=false>
                    </app-streams>
                    <app-streams *ngIf="messageGovernance.trigger=='COMMAND'" [pageSize]="5" [showNew]="false" [showControlBar]=true [displayedColumns]="['ADD','name','protocolType','direction','streamType','creation','ACTIVE']" showSelectColoumn=true showEnableColumn=true addnQueryParam=[streamType::APPLICATION^^direction::ACTIVE_SENDING^^direction::PASSIVE_SENDING^^direction::BOTH]  pageTitle="Sending Streams" instance="1" [showHeaderToolTips]=false>
                    </app-streams>
                    <app-streams *ngIf= "messageGovernance.trigger=='COMMAND'" [pageSize]="5" [showNew]="false" [showControlBar]=true [displayedColumns]="['ADD','name','protocolType','direction','streamType','creation','ACTIVE']" showSelectColoumn=true showEnableColumn=true addnQueryParam=[streamType::DEVICE^^direction::ACTIVE_RECEIVING^^direction::PASSIVE_RECEIVING^^direction::BOTH] pageTitle="Receiving Streams" instance="2" [showHeaderToolTips]=false>
                    </app-streams>
                     <app-streams *ngIf="messageGovernance.trigger=='TRADING_PARTNER_MESSAGE'" [pageSize]="5" [showNew]="false" [showControlBar]=true [displayedColumns]="['ADD','name','protocolType','direction','streamType','creation','ACTIVE']" showSelectColoumn=true showEnableColumn=true addnQueryParam=[streamType::MESSAGING^^direction::ACTIVE_SENDING^^direction::PASSIVE_SENDING^^direction::BOTH  pageTitle="Sending Streams" instance="1" [showHeaderToolTips]=false>
                    </app-streams>
                    <app-streams *ngIf="messageGovernance.trigger=='TRADING_PARTNER_MESSAGE'" [pageSize]="5" [showNew]="false" [showControlBar]=true [displayedColumns]="['ADD','name','protocolType','direction','streamType','creation','ACTIVE']" showSelectColoumn=true showEnableColumn=true addnQueryParam=[streamType::MESSAGING^^direction::ACTIVE_RECEIVING^^direction::PASSIVE_RECEIVING^^direction::BOTH] pageTitle="Receiving Streams" instance="2" [showHeaderToolTips]=false>
                    </app-streams>
                    <br>
                    <br> 
                </div> 
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IotService } from 'src/app/service/iot.service';
import { SecurtiyService } from 'src/app/service/security.service';
import { SideNavComponent } from 'src/app/side-nav/side-nav.component';
import { faAngleUp, faAngleDown  , faTimes,faExclamationTriangle, faExclamation} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-new-trusted-public-key',
  templateUrl: './new-trusted-public-key.component.html',
  styleUrls: ['./new-trusted-public-key.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  }
})
export class NewTrustedPublicKeyComponent implements OnInit {


  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;
  faCloseIcon=faTimes;
  faCriticalIcon=faExclamation;
  faWarningIcon = faExclamationTriangle;

  name='';
  description='';
  keyData='';
  warningMessage;
  errorMessage='';
  trustedPublicKey;
  nameWarningMessage = '';
  descriptionWarningMessage = '';
  nameValidationWarningMessage = '';
  keyWarningMessage = '';
  trustedPublicKeyCreated=false;

  supportLanguages = ['en'];

  constructor(private iotService: IotService, private translateService: TranslateService, private sideNavComponent: SideNavComponent, private router: Router, public securityService: SecurtiyService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
    this.sideNavComponent.menuClear();
    this.sideNavComponent.menuChange('Dashboard', 'sub-type');
		this.sideNavComponent.menuChange('sshKeys.header', 'sub-type');
    this.sideNavComponent.menuChange('trustedPublicKeys.header','sub-type');
    this.sideNavComponent.menuChange('trustedPublicKeys.create.new', 'sub-type');
  }

  ngOnInit(): void {
    this.initTrustedPublicKey();
  }

  
  onClick(event) {
    this.resetWarningMessage2();

  }

  resetWarningMessage2(){
    this.warningMessage = "";
    this.errorMessage='';
  }


  initTrustedPublicKey() {
    const that = this;
    that.trustedPublicKey = {
      "creator": that.iotService.getCreator(),
      "creatorAppId": that.iotService.getCreatorAppId(),
      "realm": that.iotService.getRealm(),
      "text": that.keyData,
      "name": that.name,
      "description": that.description,

    }

  }

  resetWarningMessage(event: any) {
    this.warningMessage = "";
    this.nameWarningMessage = '';
    this.descriptionWarningMessage = '';
    this.keyWarningMessage = '';
    this.nameValidationWarningMessage = '';
  }

  isEmpty(str) {
    if (str) {
      return (str.length === 0 || !str.trim());
    }
  };

  save() {

    const that = this;
    if (this.isEmpty(that.name) || that.name === '' || that.name === undefined) {
      that.nameWarningMessage = "Name is mandatory";
    }
    if (!this.isAlpha(this.name)) {
      that.nameValidationWarningMessage = "Name should only contain alphanumeric character example:[a-zA-Z0-9 _-]"
    }
    if ((that.description === '') || this.isEmpty(that.description) || !that.description) {
      that.descriptionWarningMessage = "Description is mandatory";
    }

    if ((that.keyData === '') || this.isEmpty(that.keyData) || !that.keyData) {
      that.keyWarningMessage = "Public Key is mandatory";
    }
    if (that.keyWarningMessage === '' && that.nameWarningMessage === '' && that.descriptionWarningMessage === ''&& that.nameValidationWarningMessage==='') {
      that.initTrustedPublicKey();
      that.trustedPublicKeyCreated=true;
      that.iotService.getCuiObjResponse().createTrustedPublicKey({
        data: that.trustedPublicKey
      }).then(function (response) {
        console.log("Trusted public key created successFully, Id: ", response.id);
        that.securityService.showSSHKeyPairs = false;
        that.securityService.showTrustedPublicKeys = true;
        that.router.navigateByUrl("/sshKeys");
      }).fail(function (err) {
        that.trustedPublicKeyCreated=false;
        if (err.responseJSON.apiStatusCode) {
          that.errorMessage = err.responseJSON.apiMessage;
          console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
        } else {
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
        if (err === null) {
          console.log("Error in createEventTemplate");
        }
      });


    }

  }


  isAlpha(str) {
    if (str) {
      let letter = /^([a-zA-Z0-9 _-]+)$/;
      if (str.match(letter)) {
        return true;
      }
      else {
        return false;
      }
    }
    return true;
  }

}

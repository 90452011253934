<div class="tab-holder">
    <mat-tab-group (selectedTabChange)="tabClick($event)" [selectedIndex]="0">
        <mat-tab label=" Add By Trading Partners"></mat-tab>
        <mat-tab label="Add By Trading Partners Codes"></mat-tab>
    </mat-tab-group>
</div>
<div class="newRoutingSource" style="overflow-y: hidden;">

    <div *ngIf="byTp">

        <label for="name">{{'name'|translate}}</label>
        <input [(ngModel)]="workingTradingPartnerRouteSource.name[0].text" placeholder="required" id="name"
            aria-label="Name" (click)="resetName()">
        <p *ngIf="nameError" style="color: red;">{{nameError}}</p>

        <app-documentInfo [pageSize]=" 5 " [showControlBar]=false
            [displayedColumns]="[ 'ADD', 'supportedDocTemplateType', 'docTemplateVersion', 'ACTIVE'] "
            [showSelectColoumn]=true [showEnableColumn]=true multiSelect="false" [showHeaderToolTips]=false>

        </app-documentInfo>

        <app-tradingPartners [pageSize]="5" [showNew]=false [showSelectColoumn]=true
            [displayedColumns]="[ 'ADD', 'name', 'description', 'ACTIVE'] " [showSelectColoumn]=true
            [showEnableColumn]=true multiSelect="false" addnQueryParam=[id::{{tpService.tradingPartnerIds}}] [showHeaderToolTips]=false>
        </app-tradingPartners>


        <div class="button-row pull-right" style="margin-top: 20px; margin-right: 10px;">
            <button class="btn btn-secondary" (click)="goBack()">{{'go.back' | translate}}</button>
            <button class="btn btn-primary" (click)="save()">{{'save' | translate}}</button>
        </div>
        <br>
        <br>
        <br>
        <br>

    </div>

    <div *ngIf="byTpCodes ">
        <label for="name ">{{'name'|translate}}</label>
        <input [(ngModel)]="workingTradingPartnerRouteSource.name[0].text " placeholder="required " id="name "
            aria-label="Name " (click)="resetName() ">
        <p *ngIf="nameError" style="color: red;">{{nameError}}</p>
        <app-documentInfo [pageSize]="5 " [showControlBar]=false
            [displayedColumns]="[ 'ADD', 'supportedDocTemplateType', 'docTemplateVersion', 'ACTIVE'] "
            [showSelectColoumn]=true [showEnableColumn]=true multiSelect="false" [showHeaderToolTips]=false>
        </app-documentInfo>

        <app-fromTPCodes [pageSize]="5 " [showControlBar]=false
            [displayedColumns]="[ 'ADD', 'code', 'qualifier', 'ACTIVE'] " [showSelectColoumn]=true
            [showEnableColumn]=true multiSelect="false"
            addnQueryParam=[tradingPartnerId::{{tpService.tradingPartnerIds}}] [showHeaderToolTips]=false></app-fromTPCodes>

        <app-toTPCodes [pageSize]="5 " [showControlBar]=false
            [displayedColumns]="[ 'ADD', 'code', 'qualifier', 'ACTIVE'] " [showSelectColoumn]=true
            [showEnableColumn]=true multiSelect="false"
            addnQueryParam=[tradingPartnerId::{{tpService.tradingPartnerIds}}] [showHeaderToolTips]=false></app-toTPCodes>
   
            <div class="button-row pull-right" style="margin-top: 20px; margin-right: 10px;">
                <button class="btn btn-secondary" (click)="goBack()">{{'go.back' | translate}}</button>
                <button class="btn btn-primary" (click)="save()">{{'save' | translate}}</button>
            </div>
    </div>
    
   
    <br>
    <br>
</div>
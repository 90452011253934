import { Component, OnInit } from '@angular/core';
import { ImportExportService } from '../service/import-export.service';
import { IotService } from '../service/iot.service';
import { faAngleUp, faAngleDown, faTimes, faExclamationTriangle, faExclamation,faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faToggleOff, faToggleOn, faAngleLeft, faAngleRight, faCheckCircle, faCheck, faCopy, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-create-import',
  templateUrl: './create-import.component.html',
  styleUrls: ['./create-import.component.scss']
})
export class CreateImportComponent implements OnInit {
  importInfo;
  importData;
  testInput;
  decodedFileContent;
  sourceStreamDirection = null;
  errorMsgFLag = false;
  successMsgFlag = false;
  showLogTab = false;
  showImportSuccessTab = false;
  authtypeCaptured = false;
  sourceAuthtypeCaptured = false;
  destAuthtypeCaptured = false;
  isAuthTypeProcessed = false;
  destinationStreamDestinationUrlRequired = false;
  sourceStreamDestinationUrlRequired = false;
  importworking = false;
  selectedProtSecTypeSource;
  selectedProtSecTypeDest;
  errorMsg;
  importJobId;
  streamJobMsg;
  destStreamDatas;
  sourceStreamDatas;
  successMsg;
  uploadedFile;
  executeImporterror;

  faCheckIcon = faCheck;
  faSuccessIcon = faCheckCircle;
  faCriticalIcon = faExclamation;
  faCloseIcon = faTimes;
  warningMessage: string;
  //sourceStreamDirection : any;
  destinationStreamDirection = null;
  showPassword=faEyeSlash;
  hidePassword=faEye;
  sourceStreamPasswordFlag :boolean= false;
  destinationStreamPasswordFlag :boolean= false;
  hide = true;
  passwordEntered;
  passwordError;
  importCreated=false;
  supportLanguages = ['en'];
  constructor(public iotService: IotService, public importExportService: ImportExportService,private translateService: TranslateService,) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
   }

  ngOnInit(): void {
    const that = this;
    that.initImportInfo();
    that.importExportService.errorMessage = "";
    that.importExportService.successMessage = "";
    that.executeImporterror = "";
  }
  ngOnDestroy(): void {
    const that = this;
    that.importExportService.errorMessage = "";
    that.importExportService.successMessage = "";
    that.executeImporterror = "";
  }
  initImportInfo() {
    const that = this;
    that.importInfo = {
      "destprotocolSecurityTypes": ["BASIC", "MUAUTH", "X509", "OAUTH2"],
      "sourceprotocolSecurityTypes": ["BASIC", "MUAUTH", "X509", "OAUTH2"],
      "jobName": "",
      "sourceStreamUsername": "",
      "sourceStreamPassword": "",
      "sourcePem": "",
      "sourceMuAuthKeyBundle": "",
      "sourceTokenId": "",
      "destinationStreamUsername": "",
      "destinationStreamPassword": "",
      "destinationPem": "",
      "destinationMuAuthKeyBundle": "",
      "destinationTokenId": "",
      "packageFileData": "",
      "destinationStreamDestinationUrl": "",
      "sourceStreamDestinationUrl": "",
      "fileName": ""
    };
    that.importData = that.importInfo;
  }

  reload() {
    const that = this;
    //that.refresh();
    that.errorMsgFLag = false;
    that.successMsgFlag = false;
    that.showLogTab = false;
    that.showImportSuccessTab = false;
    that.resetFormFields();
  }

  resetMsg() {
    const that = this;
    that.errorMsgFLag = false;
    that.successMsgFlag = false;
  }

  reloadImport() {
    const that = this;
    //that.refresh();
    that.errorMsgFLag = false;
    that.successMsgFlag = false;
    that.showLogTab = false;
    that.showImportSuccessTab = false;
    that.authtypeCaptured = false;
    that.sourceAuthtypeCaptured = false;
    that.destAuthtypeCaptured = false;
    that.isAuthTypeProcessed = false;
    that.resetFormFields();
  }
  password(event: any) {
    const that = this;
    that.passwordEntered = event.target.value;
    if (that.passwordEntered.length > 0) {
      that.passwordError = '';
    }

  }
  resetFormFields() {
    //reset form fields
    const that = this;
    // that.replicationGetLogs.getLogsJobId = "",
    //   that.replicationexport.resourceId = "",
    //   that.replicationexport.exportType = "msgid",
    //   that.replicationexport.batchMessageId = "",
    //   that.replicationexport.jobName = "",
    that.importInfo.jobName = "",
      that.importInfo.sourceStreamUsername = "",
      that.importInfo.sourceStreamPassword = "",
      that.importInfo.sourcePem = "",
      that.importInfo.sourceMuAuthKeyBundle = "",
      that.importInfo.sourceTokenId = "",
      that.importInfo.destinationStreamUsername = "",
      that.importInfo.destinationStreamPassword = "",
      that.importInfo.destinationPem = "",
      that.importInfo.destinationMuAuthKeyBundle = "",
      that.importInfo.destinationTokenId = "",
      that.importInfo.packageFileData = "",
      that.importInfo.fileName = "",
      that.selectedProtSecTypeSource = "",
      that.selectedProtSecTypeDest = "",
      that.importInfo.sourceStreamDestinationUrl = "",
      that.importInfo.destinationStreamDestinationUrl = "",
      that.sourceStreamDestinationUrlRequired = false,
      that.destinationStreamDestinationUrlRequired = false,
      that.authtypeCaptured = false,
      that.isAuthTypeProcessed = false,
      that.sourceStreamDirection = "",
      that.destinationStreamDirection = ""
    // var inputValue = (<HTMLInputElement>document.getElementById("uploadedfile")).value;
    // inputValue = "";

    //document.getElementById("uploadedfile").value() = ""

  }





  file: any;
  fileChanged(e) {
    this.file = e.target.files[0];
  }

  executeImport(file) {
    const that = this;
    that.errorMsgFLag = false;
    that.executeImporterror = "";
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      console.log(fileReader.result);
      that.populateData(file.name, fileReader.result);
      that.validateCall(fileReader.result);
      //that.makeCall(that.importInfo);

    }
    if (file == undefined) {
      that.errorMsgFLag = true;
      that.executeImporterror = "Please provide package file.";
    }
    fileReader.readAsText(this.file);
    that.importData.fileName = file.name;
    that.importData.jobName = that.importInfo.jobName;

    // console.log("fileReader.result"+fileReader.result);
    // fileReader.onerror = function (evt) {
    //   console.log("error reading file");
    // }
  }

  // that.reloadImport = function(){
  //   const that = this;
  //   that.refresh();
  //   that.errorMsgFLag=false;
  //   that.successMsgFlag=false;
  //   that.showLogTab=false;
  //   that.showImportSuccessTab=false;
  //   that.authtypeCaptured=false;
  //   that.sourceAuthtypeCaptured = false;
  //   that.destAuthtypeCaptured = false;
  //   that.isAuthTypeProcessed = false;
  //   resetFormFields();
  // }

  populateData(filename, fileData) {
    const that = this;
    var encodedFileContent = fileData;
    that.importInfo.packageFileData = fileData;
    that.importInfo.fileName = filename;
    //that.importData = that.importInfo;
    //that.validateCall(fileReader.result);
    //that.validateCall(that.importInfo.packageFileData);
  }

  executeProcessedImport() {
    const that = this;
    that.importworking = true;
    var processedImportData = that.importData;
    if (that.isAuthTypeProcessed && that.authtypeCaptured) {//user provided stream details
      switch (that.selectedProtSecTypeSource) {
        case "BASIC":
          processedImportData.sourceStreamUsername = that.importInfo.sourceStreamUsername;
          processedImportData.sourceStreamPassword = that.importInfo.sourceStreamPassword;
          if (!processedImportData.sourceStreamUsername || !processedImportData.sourceStreamPassword) {
            that.importworking = false;
            that.errorMsgFLag = true;
            that.showImportSuccessTab = false;
            // that.errorMsg = "Please provide Source Stream Username & Password";
            that.importExportService.errorMessage = "Please provide Source Stream Username & Password";

            //
            //document.getElementById("uploadForm").reset();
            //var dirtyFormID = 'something';
            // var resetForm = <HTMLFormElement>document.getElementById("uploadForm");
            // resetForm.reset();
            // resetForm.refresh();
            //(document.getElementById('uploadForm') as HTMLFormElement).reset();
            return false;
          } else {
            break;
          }
        case "MUAUTH":
          processedImportData.sourceMuAuthKeyBundle = that.importInfo.sourceMuAuthKeyBundle;
          if (!processedImportData.sourceMuAuthKeyBundle) {
            that.importworking = false;
            that.errorMsgFLag = true;
            that.showImportSuccessTab = false;
            //that.errorMsg = "Please provide Source Auth Key Bundle ID";
            that.importExportService.errorMessage = "Please provide Source Auth Key Bundle ID";
            //document.getElementById("uploadForm").reset();
            //(document.getElementById('uploadForm') as HTMLFormElement).reset();

            //that.refresh();
            return false;
          } else {
            break;
          }
        case "X509":
          processedImportData.sourcePem = that.importInfo.sourcePem;
          if (!processedImportData.sourcePem) {
            that.importworking = false;
            that.errorMsgFLag = true;
            that.showImportSuccessTab = false;
            //that.errorMsg = "Please provide Source PEM";
            that.importExportService.errorMessage = "Please provide Source PEM";

            // document.getElementById("uploadForm").reset();
            //(document.getElementById('uploadForm') as HTMLFormElement).reset();

            //that.refresh();
            return false;
          } else {
            break;
          }
        case "OAUTH2":
          processedImportData.sourceTokenId = that.importInfo.sourceTokenId;
          if (!processedImportData.sourceTokenId) {
            that.importworking = false;
            that.errorMsgFLag = true;
            that.showImportSuccessTab = false;
            //that.errorMsg = "Please provide Source Token ID";
            that.importExportService.errorMessage = "Please provide Source Token ID";

            //document.getElementById("uploadForm").reset();
            //(document.getElementById('uploadForm') as HTMLFormElement).reset();

            //that.refresh();
            return false;
          } else {
            break;
          }
      }
      switch (that.selectedProtSecTypeDest) {
        case "BASIC":
          processedImportData.destinationStreamUsername = that.importInfo.destinationStreamUsername;
          processedImportData.destinationStreamPassword = that.importInfo.destinationStreamPassword;
          if (!processedImportData.destinationStreamUsername || !processedImportData.destinationStreamPassword) {
            that.importworking = false;
            that.errorMsgFLag = true;
            that.showImportSuccessTab = false;
            //that.errorMsg = "Please provide Destination Stream Username & Password";
            that.importExportService.errorMessage = "Please provide Destination Stream Username & Password";

            //document.getElementById("uploadForm").reset();
            //(document.getElementById('uploadForm') as HTMLFormElement).reset();

            //that.refresh();
            return false;
          } else {
            break;
          }
        case "MUAUTH":
          processedImportData.destinationMuAuthKeyBundle = that.importInfo.destinationMuAuthKeyBundle;
          if (!processedImportData.destinationMuAuthKeyBundle) {
            that.importworking = false;
            that.errorMsgFLag = true;
            that.showImportSuccessTab = false;
            //that.errorMsg = "Please provide Destination MuAuth Key Bundle ID";
            that.importExportService.errorMessage = "Please provide Destination MuAuth Key Bundle ID";

            //document.getElementById("uploadForm").reset();
            //(document.getElementById('uploadForm') as HTMLFormElement).reset();

            //that.refresh();
            return false;
          } else {
            break;
          }
        case "X509":
          processedImportData.destinationPem = that.importInfo.destinationPem;
          if (!processedImportData.destinationPem) {
            that.importworking = false;
            that.errorMsgFLag = true;
            that.showImportSuccessTab = false;
            // that.errorMsg = "Please provide Destination PEM Key";
            that.importExportService.errorMessage = "Please provide Destination PEM Key";

            // document.getElementById("uploadForm").reset();
            //(document.getElementById('uploadForm') as HTMLFormElement).reset();

            //that.refresh();
            return false;
          } else {
            break;
          }
        case "OAUTH2":
          processedImportData.destinationTokenId = that.importInfo.destinationTokenId;
          if (!processedImportData.destinationTokenId) {
            that.importworking = false;
            that.errorMsgFLag = true;
            that.showImportSuccessTab = false;
            //that.errorMsg = "Please provide Destination Token ID";
            that.importExportService.errorMessage = "Please provide Destination Token ID";

            // document.getElementById("uploadForm").reset();
            //(document.getElementById('uploadForm') as HTMLFormElement).reset();

            //that.refresh();
            return false;
          } else {
            break;
          }
      }
      if (that.destinationStreamDestinationUrlRequired || that.sourceStreamDestinationUrlRequired) {//handle URLs
        var isURLProvided = false;
        if (that.destinationStreamDestinationUrlRequired && that.importInfo.destinationStreamDestinationUrl) {
          processedImportData.destinationStreamDestinationUrl = that.importInfo.destinationStreamDestinationUrl;
          isURLProvided = true;
        }
        if (that.sourceStreamDestinationUrlRequired && that.importInfo.sourceStreamDestinationUrl) {
          processedImportData.sourceStreamDestinationUrl = that.importInfo.sourceStreamDestinationUrl;
          isURLProvided = true;
        }
        if (!isURLProvided) {
          that.importworking = false;
          that.errorMsgFLag = true;
          that.showImportSuccessTab = false;
          // that.errorMsg = "Please provide Stream Destination URL";
          that.importExportService.errorMessage = "Please provide Stream Destination URL";
          //that.reloadImport();
          //document.getElementById("uploadForm").reset();
          // (document.getElementById('uploadForm') as HTMLFormElement).reset();

          // that.refresh();
          return false;
        }
      }
    }
    that.makeCall(processedImportData);
  }


  makeCall(importData) {
    const that = this;
    that.importworking = true;
    console.log("importData" + importData.packageFileData);
    that.importCreated=true;
    that.iotService.getCuiObjResponse().executeRepImport({
      data: importData
    })
      .then(function (response) {
        that.importworking = false;
        //console.log(response);
        //that.importInfo.packageFileData = response;
        that.importJobId = response.id;
        that.streamJobMsg = response.jobMessage;
        that.destStreamDatas = response.destinationStream;
        that.sourceStreamDatas = response.sourceStream;
        //that.successMsg = "Solution imported successfully.";
        that.importExportService.successMessage = "Solution imported successfully.";
        that.showImportSuccessTab = true;
        that.resetFormFields();
        //document.getElementById("uploadForm").reset();
        // (document.getElementById('uploadForm') as HTMLFormElement).reset();

        //that.refresh();
      })
      .fail(function (response) {
        that.importCreated=false;
        that.importworking = false;
        that.errorMsgFLag = true;
        that.showImportSuccessTab = false;
        that.importExportService.errorMessage = response.responseJSON.apiMessage;
        that.resetFormFields();
        that.authtypeCaptured = false;
        that.sourceAuthtypeCaptured = false;
        that.destAuthtypeCaptured = false;
        that.isAuthTypeProcessed = false;
        //document.getElementById("uploadForm").reset();
        //(document.getElementById('uploadForm') as HTMLFormElement).reset();

        //that.refresh();
      });

  }
  b64EncodeUnicode(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => {
      return String.fromCharCode(("0x" + p1) as any);
    }));
  }

  b64DecodeUnicode(str) {
    return decodeURIComponent(Array.prototype.map.call(atob(str), (c) => {
      return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(""));

  }
  //var reader = new FileReader();
  validateCall(eve) {
    const that = this;
    console.log("validateCall-file" + eve);
    var encodedFileContent = eve;
    console.log("encodedFileContent-file" + encodedFileContent);
    that.importworking = true;
    //decode payload
    try {
      // let data = encodedFileContent
      // let buff = new Buffer(data);
      // let base64data = buff.toString('base64');

      var contentArray = encodedFileContent.split("@");
      console.log("contentArray-file" + contentArray);
      var actualContent = contentArray[0];
      console.log("actualContent-file" + actualContent);
      // var decodedFileContent =angular.fromJson(atob(actualContent)); 
      var decodedFileContent = atob(actualContent);
      const decodedFileContentJson = JSON.parse(decodedFileContent);
      console.log("decodedFileContent-file" + decodedFileContent);
      console.log("json-type" + decodedFileContentJson.destinationStreamAuthType);
      //get destinationStreamAuthType,sourceStreamAuthType
      var destAuthType = decodedFileContentJson.destinationStreamAuthType;
      var sourceAuthType = decodedFileContentJson.sourceStreamAuthType;
      that.sourceStreamDirection = decodedFileContentJson.sourceStreamDirection;
      that.destinationStreamDirection = decodedFileContentJson.destinationStreamDirection;
      that.destinationStreamDestinationUrlRequired = decodedFileContentJson.destinationStreamDestinationUrlRequired;
      that.sourceStreamDestinationUrlRequired = decodedFileContentJson.sourceStreamDestinationUrlRequired;
      var jobData = encodedFileContent;
      that.importData.packageFileData = jobData;
    } catch (e) {//invalid pkg file or content
      that.importworking = false;
      that.errorMsgFLag = true;
      that.showImportSuccessTab = false;
      that.errorMsg = "Invalid pkg file provided."
      that.resetFormFields();
      that.authtypeCaptured = false;
      that.sourceAuthtypeCaptured = false;
      that.destAuthtypeCaptured = false;
      that.isAuthTypeProcessed = false;
      //document.getElementById("uploadForm").reset();
      //that.refresh();
      return false;
    }
    if (!destAuthType && !sourceAuthType) {//execute import
      if (that.destinationStreamDestinationUrlRequired || that.sourceStreamDestinationUrlRequired) {
        that.authtypeCaptured = true;
        that.isAuthTypeProcessed = true;
        //that.destAuthtypeCaptured = true;
        that.importworking = false;
        //that.selectedProtSecTypeSource = sourceAuthType;
        //that.selectedProtSecTypeDest = destAuthType;
        //that.refresh();
        return false;
      }
      that.makeCall(that.importData);
    } else if (destAuthType && !sourceAuthType) {//ask destination
      //show div
      that.authtypeCaptured = true;
      that.destAuthtypeCaptured = true;
      that.importworking = false;
      that.selectedProtSecTypeSource = sourceAuthType;
      that.selectedProtSecTypeDest = destAuthType;
      that.isAuthTypeProcessed = true;
      //that.refresh();
      return false;
    } else if (!destAuthType && sourceAuthType) {//ask source
      //show div
      that.authtypeCaptured = true;
      that.sourceAuthtypeCaptured = true;
      that.importworking = false;
      that.selectedProtSecTypeSource = sourceAuthType;
      that.selectedProtSecTypeDest = destAuthType;
      that.isAuthTypeProcessed = true;
      // that.refresh();
      return false;
    } else {//ask BOTH
      //show div
      that.authtypeCaptured = true;
      that.sourceAuthtypeCaptured = true;
      that.destAuthtypeCaptured = true;
      that.importworking = false;
      that.selectedProtSecTypeSource = sourceAuthType;
      that.selectedProtSecTypeDest = destAuthType;
      that.isAuthTypeProcessed = true;
      //that.refresh();
      return false;
    }
  }

}


import { Injectable } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { IotService } from '../service/iot.service';
import { ListRoutingSourceRelationshipsService } from './ListRoutingSourceRelationships.service';
import { TradingPartnersService } from './tradingPartners.service';
import { RoutesService } from './routes.service';

@Injectable({
	providedIn: 'root'
})

export class RoutingSourceRelationshipsService extends ListRoutingSourceRelationshipsService {
	selection: SelectionModel<String> = new SelectionModel<String>(true, []);
	constructor(public iotService: IotService, public tpService: TradingPartnersService, public routeService: RoutesService) {
		super(iotService);
		this.selection.clear();
		this.loadSelected();
	}

	fnOnSelectionSupport(results) {
		const that = this;
		if (that.iotService.createRoute == true) {
			this.routeService.setRoutingSourceRelationship(results);
		}
	}

	fnRemoveSelectionSupport(results) {
		const that = this;
		if (that.iotService.createRoute == true) {
			this.routeService.setRoutingSourceRelationship(undefined);
		}
	}

	createNew() {
		const that = this;
		that.tpService.createResource = false;
		that.tpService.createRouteSource = true;
	}

	viewDetails(selectedId) {
		const that = this;
		this.tpService.setRouteSourceRelationShipId(selectedId);
		that.tpService.createResource = false;
		that.tpService.viewRouteSource = true;
	}
	loadSelected() {
		if (this.routeService.getRoutingSourceRelationship()) {
			this.selection.select(this.routeService.getRoutingSourceRelationship().id);
		}
	}

	showErrorMessage(err){
		if(err.responseJSON.apiStatusCode){
			this.tpService.errorMessage=err.responseJSON.apiMessage;
		}
		else{
			this.tpService.errorMessage="Some thing went wrong, Please check console ";
		}
	 }

}

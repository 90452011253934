<main id="ecosystem-details">
    <div class="d-flex justify-content-center loader" *ngIf="!ecosystem">
        <div class="spinner-border" role="status">
            <span class="sr-only" id="loading"></span>
        </div>
    </div>
    <ng-container *ngIf="!iotService.showEcoviewer">
    <mat-sidenav-container autosize *ngIf="ecosystem">
        <mat-sidenav class="midNav-container" #sidenav mode="side" disableClose="true" opened="true">
            <div class="alert critical" *ngIf="ecosystemService.errorMessage">
                <h5>
                    <fa-icon [icon]="faCriticalIcon"></fa-icon>
                    <span>{{ecosystemService.errorMessage}}</span>
                    <button class="alert-close-btn">
                        <fa-icon (click)=ecosystemService.resetErrorMessage() [icon]="faCloseIcon"></fa-icon>
                    </button>
                </h5>
            </div>
            <div class="alert warning" *ngIf="ecosystemService.warningMessage">
                <h5 style="font-size: 12px;">
                    <fa-icon [icon]="faWarningIcon"></fa-icon>
                    <span>{{ecosystemService.warningMessage}}</span>
                    <button class="alert-close-btn">
                        <fa-icon (click)="ecosystemService.resetWarningMessage()" [icon]="faCloseIcon"></fa-icon>
                    </button>
                </h5>
            </div>
            <div class="alert success" *ngIf="ecosystemService.successMessage">
                <h5>
                    <fa-icon [icon]="faSuccessIcon"></fa-icon>
                    <span>{{ecosystemService.successMessage}}</span>
                    <button class="alert-close-btn">
                        <fa-icon (click)=ecosystemService.resetSuccessMessage() [icon]="faCloseIcon"></fa-icon>
                    </button>
                </h5>
            </div>
            <!--Added for Rule Policy-->
            <div *ngIf="iotService.viewRulePolicy">
                <div class="alert critical" *ngIf="rulesService.errorMessage">
                    <h5>
                        <fa-icon [icon]="faCriticalIcon"></fa-icon>
                        <span>{{rulesService.errorMessage}}</span>
                        <button class="alert-close-btn">
                            <fa-icon (click)=rulesService.resetMessage() [icon]="faCloseIcon"></fa-icon>
                        </button>
                    </h5>

                </div>
                <div class="alert success" *ngIf="rulesService.successMessage">
                    <h5>
                        <fa-icon [icon]="faSuccessIcon"></fa-icon>
                        <span>{{rulesService.successMessage}}</span>
                        <button class="alert-close-btn">
                            <fa-icon (click)=rulesService.resetMessage() [icon]="faCloseIcon"></fa-icon>
                        </button>
                    </h5>
                </div>
                <div class="alert warning" *ngIf="rulesService.warningMessage">
                    <h5 style="font-size: 12px;">
                        <fa-icon [icon]="faWarningIcon"></fa-icon>
                        <span>{{rulesService.warningMessage}}</span>
                        <button class="alert-close-btn">
                            <fa-icon (click)="rulesService.resetMessage()" [icon]="faCloseIcon"></fa-icon>
                        </button>
                    </h5>
                </div>
            </div>

            <div class="midNav-data">
                <div style="margin-left: -20px;">
                <app-copy-resource-id></app-copy-resource-id>
            </div>
                <label for="parent-template">{{'parent.template' | translate}}</label>
                <p>
                    <a (click)=viewEcosystemTemplate(ecosystem.parentGroupTemplateId)
                        title="View Template Details">{{ecosystemTemplateName}}</a>
                </p>

                <label for="creation">{{'created' | translate}}</label>
                <p>{{ecosystem.creation | date:'mediumDate' }}</p>

                <figure>
                    <figcaption>{{'lifecycle' | translate}}</figcaption>
                    <ng-container *ngIf="ecosystem.lifecycleState">
                        <mat-grid-list [cols]="breakpoint" rowHeight="4:1" [gutterSize]="0">
                            <mat-grid-tile [ngClass]="statusActiveClass">
                                <p>{{'active' | translate}}</p>
                            </mat-grid-tile>
                            <mat-grid-tile [ngClass]="statusSuspendedClass">
                                <p>{{'suspended' | translate}}</p>
                            </mat-grid-tile>
                        </mat-grid-list>
                    </ng-container>

                    <ng-container *ngIf="ecosystem.lifecycleState == 'ACTIVE'">
                        <p>{{'suspend.ecosystem.desc' | translate}}</p>
                        <button class="btn btn-secondary" [disabled]="!ecosystem.active"
                            (click)="suspendEcosystem(ecosystem.id)" type='submit'>{{'suspend' | translate}}</button>
                    </ng-container>

                    <ng-container *ngIf="ecosystem.lifecycleState == 'SUSPENDED'">
                        <p>{{'unsuspend.ecosystem.desc' | translate}}</p>
                        <button class="btn btn-secondary" [disabled]="!ecosystem.active"
                            (click)="unsuspendEcosystem(ecosystem.id)" type='submit'>{{'unsuspend' | translate}}</button>
                    </ng-container>
                </figure>

                <label>{{'enabled' | translate}}{{'?' | translate}}</label>
                <div *ngIf="ecosystem.active">
                    <button class="toggle-on toggle-switch" title="Disable" (click)="toggleActive(true)">
                        <fa-icon [icon]="faCheckIcon" class="fa-2x"></fa-icon>
                        <div class="toggle-on-btn"></div>
                    </button>
                </div>
                <div *ngIf="!ecosystem.active">
                    <button class="toggle-off toggle-switch" title="Enable" (click)="toggleActive(false)">
                        <div class="toggle-off-btn"></div>
                    </button>
                </div>
                <br/>
                <div style="margin-left: -3px;">
                    <button class="btn btn-secondary"  id="action-btn" *ngIf="ecosystemService.allowedMemberTypes.length>0" (click)="createMemberShip()">{{'add.membership' | translate}}</button>
                    <button class="btn btn-secondary" id="action-btn" (click)="createRuleFromEcosystem(ecosystem.id)">{{'add.rule.policy' | translate}}</button>
                    <button id="action-btn" class="btn btn-secondary" *ngIf="ecosystemService.ecosystemMemberShips.length>1"
                        (click)="createRelationShip()">{{'add.relationship' | translate}}</button>
                    <button *ngIf="ecosystemService.ecosystemMemberShips.length>0" class="btn btn-secondary" id="action-btn"
                        (click)="launchColumnView(ecosystem.id)">{{'ecosystem.viewer' | translate}}</button>
                    <button class="btn btn-primary" id="action-btn" (click)="updateEcosystem()" type='submit'>{{'update' | translate}} 
                    </button>
                </div>

                <div *ngIf="iotService.viewRulePolicy">
                    <!-- <h1>Create Rule</h1> -->
                    <mat-nav-list class="without-template user-selections">
                        <label>{{'trigger.types' | translate}}</label>
                        <p *ngIf="rulesService.triggerType=='Select Trigger'">
                            {{'select.triggerType' | translate}}
                        </p>
                        <p *ngIf="rulesService.triggerType!='Select Trigger'">{{rulesService.triggerType}}</p>

                        <label>{{'event.template' | translate}}</label>
                        <p *ngIf="!rulesService.selectedEvent">
                            {{'select.event.table' | translate}}
                        </p>
                        <div *ngIf="rulesService.selectedEvent">
                            <ul>
                                <li>
                                    <fa-icon [icon]="faCheckIcon"></fa-icon> {{rulesService.getEvents().name}}
                                </li>
                            </ul>
                        </div>
                        <label>{{'devices' | translate}}</label>
                        <p *ngIf="rulesService.devices.length == 0">
                            {{'select.device.table' | translate}}
                        </p>
                        <ul>
                            <li *ngFor="let device of rulesService.devices">
                                <fa-icon [icon]="faCheckIcon"></fa-icon> {{ device.name[0].text }}
                            </li>
                        </ul>
                        <div *ngIf="!rulesService.showGateway && rulesService.triggerType.includes('Device')">
                            <label>{{'device.templates' | translate}}</label>
                            <p *ngIf="rulesService.deviceTemplates.length == 0">
                                {{'select.deviceTemplate.table' | translate}}
                            </p>
                            <ul>
                                <li *ngFor="let deviceTemplate of rulesService.deviceTemplates">
                                    <fa-icon [icon]="faCheckIcon"></fa-icon> {{ deviceTemplate.name[0].text }}
                                </li>
                            </ul>
                        </div>
                        <div *ngIf="rulesService.triggerType=='Device Geofence'">
                            <label>{{'geofences' | translate}}</label>
                            <p *ngIf="rulesService.geofences.length == 0">
                                {{'select.geofence.table' | translate}}</p>
                            <ul>
                                <li *ngFor="let geofence of rulesService.geofences">
                                    <fa-icon [icon]="faCheckIcon"></fa-icon> {{ geofence.name[0].text }}
                                </li>
                            </ul>
                        </div>
                        <div *ngIf="rulesService.showGateway">
                            <label>{{'gateways' | translate}}</label>
                            <p *ngIf="rulesService.gateways == undefined">
                                {{'select.gateway.table' | translate}}</p>
                            <div *ngIf="rulesService.gateways != undefined">
                                <ul>
                                    <li>
                                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{rulesService.gateways.name[0].text}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            *ngIf="(rulesService.triggerType=='Device Event' || rulesService.triggerType.includes('Ecosystem')) && !rulesService.showGateway">
                            <label>{{'subscribers' | translate}}</label>
                            <label>{{'ecosystems.header' | translate}}</label>
                            <p *ngIf="rulesService.ecosystems.length == 0">
                                {{'select.ecosystem.table' | translate}}</p>
                            <ul>
                                <li *ngFor="let ecosystem of rulesService.ecosystems">
                                    <fa-icon [icon]="faCheckIcon"></fa-icon> {{ ecosystem.name[0].text }}
                                </li>
                            </ul>
                            <label>{{'ecosystems.templates' | translate}}</label>
                            <p *ngIf="rulesService.ecosystemTemplates.length == 0">
                                {{'select.ecosystemTemplate.table' | translate}}</p>
                            <ul>
                                <li *ngFor="let ecosystemTemplate of rulesService.ecosystemTemplates">
                                    <fa-icon [icon]="faCheckIcon"></fa-icon> {{ ecosystemTemplate.name[0].text }}
                                </li>
                            </ul>
                        </div>
                        <!-- <div>
                            <button class="btn btn-primary" [disabled]="!rulesService.rulePolicyNameInput || rulesService.triggerType=='Select Trigger'"
                                (click)="createRulePolicyComponent.save()">{{ 'save' | translate}}</button>
                            <button class="btn btn-secondary" (click)="back()" type='submit'>{{ 'go_back' |
                                translate}}</button>&nbsp;
                        </div> -->
                    </mat-nav-list>
                </div>
            </div>
        </mat-sidenav>

       
        <div *ngIf="!iotService.viewRulePolicy" style="height: 450vh;">
            <div class="container-data" *ngIf="ecosystemService.viewResource">
                <div class=form-data>
                    <label for="ecosystemName">{{ 'name' | translate}}</label>
                    <input [(ngModel)]="ecosystem.name[0].text" id="ecosystemName" type="text"
                        value={{ecosystem.name[0].text}} (keyup)="ecosystemNameInput($event)" aria-label="Name"
                        [(ngModel)]="ecosystem.name[0].text">
                    <p *ngIf="errorInName" style="color: red;">{{errorInName}}</p>

                    <label for="ecosystemDescription">{{ 'description' | translate}}</label>
                    <textarea id="ecosystemDescription" [(ngModel)]="ecosystem.description[0].text" type="text"
                        value={{ecosystem.description[0].text}} (keyup)="enableUpdate($event)">
                </textarea>

                    <label for="ecosystemTags">{{ 'tags' | translate}}</label>
                    <mat-chip-list #tagList aria-label="Tags">
                        <mat-chip class="ot-chip" *ngFor="let tag of ecosystem.tags" [selectable]="true"
                            [removable]="true" (removed)="remove(tag)">
                            {{ tag }}
                            <button class="ot-chip__close" matChipRemove></button>
                        </mat-chip>
                        <input [ngClass]="tagClass" placeholder="add a tag" #tagInput [matChipInputFor]="tagList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                            (matChipInputTokenEnd)="add($event)" />
                    </mat-chip-list>
                </div>

                <ng-container>
                    <app-ecosystemInstanceAttributes [pageSize]="5" [showControlBar]=true
                        [displayedColumns]="['name','description','type','value','ACTION']"
                        [showSelectColoumn]=true [showEnableColumn]=true [showSearch]=false [showPaginationComponent]=false [showNew]=true
                        [newWithRoute]=false addnQueryParam=[ecosystemId::{{ecosystem.id}}] [showHeaderToolTips]=false>
                    </app-ecosystemInstanceAttributes>

                    <app-ecosystemEvents [pageSize]="5" [showControlBar]=false
                        [displayedColumns]="['name','description']" [showSelectColoumn]=true
                        [showEnableColumn]=true
                        addnQueryParam=[ecosystemTemplateId::{{ecosystem.parentGroupTemplateId}}] [showHeaderToolTips]=false>
                    </app-ecosystemEvents>

                    <app-ecosystemInstanceMembers [pageSize]="5" [showControlBar]=false
                        [displayedColumns]="['memberType','memberName','memberKind','membershipAttributes','ACTION']"
                        addnQueryParam=[ecosystemId::{{ecosystem.id}}] [showSelectColoumn]=true [showEnableColumn]=true [showHeaderToolTips]=false>
                    </app-ecosystemInstanceMembers>

                    <app-ecosystemRelationships [pageSize]="5" [showControlBar]=false
                        [displayedColumns]="['source','relationship','target','effective','expires','relationshipAttributes','ACTION']"
                        addnQueryParam=[ecosystemId::{{ecosystem.id}}] [showSelectColoumn]=true [showEnableColumn]=true [showHeaderToolTips]=false>
                    </app-ecosystemRelationships>

                    <app-ecosystemInstanceRules [pageSize]="5" [showControlBar]=false
                        [displayedColumns]="['sourceEventKey','ruleName','sourceEventName','creation','ACTION']"
                        addnQueryParam=[ecosystemId::{{ecosystem.id}}] [showSelectColoumn]=true [showEnableColumn]=true [showHeaderToolTips]=false>
                    </app-ecosystemInstanceRules>
                </ng-container>
            </div>

            <ng-container *ngIf="ecosystemService.viewAttributes">
                <app-attributes [pageSize]="5" [showSelectColoumn]=true [showEnableColumn]=true [showControlBar]=true
                    [showNew]=false
                    [displayedColumns]="['ADD', 'name', 'description', 'type','constant','defaultValue', 'ACTIVE']" [showHeaderToolTips]=false>
                </app-attributes>

                <div class="button-row pull-right">
                    <button class="btn btn-secondary" (click)="goBackCustomAttributes()">{{ 'go.back' |
                        translate}}</button>
                    <button class="btn btn-secondary" (click)="addCustomAttributes()">{{'continue' | translate}}</button>
                </div>
            </ng-container>

            <ng-container *ngIf="ecosystemService.viewRelationShip" style="height: 200vh;">
                <div class="relationship-form">
                    <label>{{'source' | translate}}</label>
                    <select name="route-type" [(ngModel)]="relationship.sourceEntity.id"
                        (ngModelChange)="populateTargetList()" (click)="sourceEntityError=''">

                        <option value="" selected="selected">{{'select.source' | translate}}</option>
                        <option [value]="item.id" *ngFor="let item of ecosystemService.ecosystemMemberShips;">
                            {{item.memberName}} ({{item.memberType}})
                        </option>
                    </select>
                    <p style="color: red;">{{sourceEntityError}}</p>

                    <label>{{'ecosystemRelationships.relationName' | translate}}</label>
                    <input id="relationship" [(ngModel)]="relationship.relationName" type="text"
                        (click)="relationNameError=''">
                    <p style="color: red;">{{relationNameError}}</p>

                    <label>{{'ecosystemRelationships.targetEntity' | translate}}</label>
                    <select name="route-type" [(ngModel)]="relationship.targetEntity.id" (click)="targetEntityError=''">
                        <option value="" selected="selected">{{'select.target' | translate}}</option>
                        <option value="{{item.id}}" *ngFor="let item of targetEntityList;">
                            {{item.memberName}} ({{item.memberType}})
                        </option>
                    </select>
                    <p style="color: red;">{{targetEntityError}}</p>

                    <label>{{'ecosystemRelationships.effective' | translate}}</label>
                    <input placeholder="required" id="date" type="date" aria-label="date" [(ngModel)]="effectiveDate">
                    <input placeholder="required" id="time" type="time" step="1" aria-label="time"
                        [(ngModel)]="effectiveTime">

                    <label>{{'ecosystemRelationships.expires' | translate}}</label>

                    <input placeholder="required" id="date" type="date" aria-label="date" [(ngModel)]="expirationDate">
                    <input placeholder="required" id="time" type="time" step="1" aria-label="time"
                        [(ngModel)]="expirationTime">
                </div>

                <app-attributes [pageSize]="5" [showSelectColoumn]=true [showEnableColumn]=true [showControlBar]=true
                    [showNew]=false multiSelect="true"
                    [displayedColumns]="['ADD', 'name', 'description', 'type', 'value', 'constant','defaultValue', 'ACTIVE']" [showHeaderToolTips]=false>
                </app-attributes>

                <div class="button-row pull-left">
                    <button class="btn btn-secondary pull-left" (click)="goBackRelation()">{{ 'go.back' | translate}}</button>
                    <button class="btn btn-primary pull-left" (click)="saveRelationship()">{{'save' | translate}}</button>
                </div>
            </ng-container>

            <ng-container *ngIf="ecosystemService.viewMembership">
                <app-create-ecosystem-membership></app-create-ecosystem-membership>
            </ng-container>
        </div>
        <ng-container *ngIf="iotService.viewRulePolicy">
            <app-create-rule-policy></app-create-rule-policy>
        </ng-container>
    </mat-sidenav-container>
    
    </ng-container>
    <ng-container *ngIf="iotService.showEcoviewer">
        <app-column-view></app-column-view>
    </ng-container>
        
</main>
    
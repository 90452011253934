import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { IotService } from '../../service/iot.service';
import { DeviceService } from '../../service/device.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss']
})
export class DescriptionComponent implements OnInit {

  newDeviceName: string;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tags = [];
  tagList =[];
  tagClass = 'tagInput';
  supportLanguages = ['en'];
  constructor(private iotService:IotService,public deviceService:DeviceService,private translateService: TranslateService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en'); 
   }

  ngOnInit(): void {
    this.newDeviceName = '';
    // this.deviceService.setDeviceTags(undefined);
  }

  @Output() messageToEmit = new EventEmitter<boolean>();


  onClickContinue(message: boolean) {
    this.messageToEmit.emit(message);

  }

  deviceName(event: any) {
    const that = this;
    that.deviceService.setDeviceName(event.target.value);
    if (that.deviceService.getDeviceName().length > 0) {
      that.deviceService.deviceNameSelect = true;
    }
    else {
      that.deviceService.deviceNameSelect = false;
    }
  }

  deviceDescription(event: any) {
    const that = this;
    that.deviceService.setDeviceDescription(event.target.value);
  }

  deviceTags(event: any) {
    const that = this;
    that.deviceService.setDeviceTags(event.target.value);
  }

  templateName(event: any) {
    const that = this;
    that.deviceService.setTemplateName(event.target.value);
    if (that.deviceService.getTemplateName().length > 0) {
      that.deviceService.templateNameSelect = true;
    }
    else {
      that.deviceService.templateNameSelect = false;
    }
  }

  add(event: MatChipInputEvent): void {
   
    this.tagClass = 'tagInput';
    const input = event.input;
    const value = event.value;
    const index = this.tagList.indexOf(value);
    this.tagList.push(value);
    if(index<0){
    if ((value || '').trim()) {
      if(this.deviceService.getDeviceTags()!=undefined && this.deviceService.getDeviceTags().includes(value)){
        if (input) {
          input.value = '';
        }
      }
      else{
      this.tags.push(value);
      this.deviceService.setDeviceTags(this.tags);
      if (input) {
        input.value = '';
      }
    }
  }
  }else{
  this.tagClass = 'tagInput-duplicate';
  }
}

  remove(tag: string): void {
    const that = this;
    that.tags.forEach((value, index) => {
      if (value == tag) that.tags.splice(index, 1);
    });
    this.deviceService.setDeviceTags(this.tags);

  }


}

import { Injectable } from '@angular/core';
import { SelectionModel} from '@angular/cdk/collections';
import { IotService } from '../service/iot.service';
import { ListOrchestrationConfigsService } from './ListOrchestrationConfigs.service';
import { Subject } from 'rxjs';

@Injectable({
	  providedIn: 'root'
})

export class OrchestrationConfigsService extends ListOrchestrationConfigsService {
	 createTask:boolean=false;
	 preProcessorTasks = [];
	 sourceDocInfo;
	 targetDocInfo;
	 streamData = [];
	 taskList = [];
	 editableTask;
	 tradingPartner;
	 tradingPartnerRelationship;
	 tpCode;
	 tpCodeRelationship;

	 private createOrchconfigComponentSource = new Subject<boolean>();
	 private createOrchconfigComponentSource2 = new Subject<boolean>();

   // Observable string streams
   createOrchconfigComponent$ = this.createOrchconfigComponentSource.asObservable();
	 createOrchconfigComponent2$ = this.createOrchconfigComponentSource2.asObservable();

	 constructor(public iotService:IotService) {
		 super(iotService);
	 }

	 fnOnSelectionSupport(results){
		 const that = this;
	 }

	 fnRemoveSelectionSupport(results){
		 const that = this;
	 }

	 setSourceDocInfo(sourceDocInfo){
		this.sourceDocInfo=sourceDocInfo;
	 }

	 getSourceDocInfo(){
		return this.sourceDocInfo;
	 }

	 setTargetDocInfo(targetDocInfo){
		this.targetDocInfo=targetDocInfo;
	 }

	 getTargetDocInfo(){
		return this.targetDocInfo;
	 }

	 setStreamData(streamData:any){
     this.streamData = streamData;
	 }

	 getStreamData():any{
     return this.streamData;
   }

	 setEditableTask(editableTask:any){
		 this.editableTask = editableTask;
	 }

	 getEditableTask(){
		 return this.editableTask;
	 }

	 setTradingPartner(tradingPartner){
		this.tradingPartner=tradingPartner;
	 }

	 getTradingPartner(){
		return this.tradingPartner;
	 }

	 setTradingPartnerRelationship(tradingPartnerRelationship){
		this.tradingPartnerRelationship=tradingPartnerRelationship;
	 }

	 getTradingPartnerRelationship(){
		return this.tradingPartnerRelationship;
	 }

	 setTpCode(tpCode){
		this.tpCode=tpCode;
	 }

	 getTpCode(){
		return this.tpCode;
	 }

	 setTpCodeRelationship(tpCodeRelationship){
		this.tpCodeRelationship=tpCodeRelationship;
	 }

	 getTpCodeRelationship(){
		return this.tpCodeRelationship;
	 }

	 addStream(stream){
		 this.createOrchconfigComponentSource.next(stream);
	 }

	 removeStream(stream){
		 this.createOrchconfigComponentSource2.next(stream);
	 }
}

import { Component, OnInit, ɵɵqueryRefresh } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IotService } from 'src/app/service/iot.service';
import { SideNavComponent } from 'src/app/side-nav/side-nav.component';
import {faCopy} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-authority-detail',
  templateUrl: './authority-detail.component.html',
  styleUrls: ['./authority-detail.component.scss']
})
export class AuthorityDetailComponent implements OnInit {
  supportLanguages = ['en'];
  authority: any;
  faCopyIcon=faCopy;
  isRevoke=false;
  
  constructor(private router: Router, private route: ActivatedRoute, private iotService: IotService,
     private sideNavComponent: SideNavComponent, private translateService: TranslateService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }


  ngOnInit() {

    this.route.params.subscribe(params => {
      //console.log(params['id']);
      var authorityId = params['id'];
      this.loadAuthority(authorityId);
    });
     
  }
  loadAuthority(authorityId: any) {
    const that = this;
    this.iotService.getCuiObjResponse().getx509Authority({
      authorityId: authorityId
    }).then(function(response) {
      console.log(response);
      that.authority = response; 
      that.sideNavComponent.menuClear();
      that.sideNavComponent.menuChange('Dashboard','sub-type');
      that.sideNavComponent.menuChange('certificates.header','sub-type');
      that.sideNavComponent.menuChange('trustedAuthorities.header', 'sub-type');
      that.sideNavComponent.menuChange(that.authority.subject,'sub-type');
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        alert("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  revokex509Authority(authorityId: any){
    const that = this;
    this.iotService.getCuiObjResponse().revokex509Authority({
      authorityId: authorityId
    }).then(function(response) {
      console.log(response);
      that.authority = response; 
      window.location.reload();
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        alert("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }
  
  toggleRevoke(){
    this.isRevoke=!this.isRevoke;
  }

}

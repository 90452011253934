
<div class="adaptorDetail" *ngIf="adaptorDetailInfo">
    <div class=form >
        <label>{{'name' | translate}}{{':' | translate}}</label>
        <p>{{adaptorDetailInfo.name}}</p>
        <label>{{'announcements.topic' | translate}}</label>
        <p>{{adaptorDetailInfo.name}}{{'announcement' | translate}}</p>
        <label>{{'signals.topic' | translate}}</label>
        <p>{{adaptorDetailInfo.name}}{{'signal' | translate}}</p>
        <label>{{'services.topic' | translate}}</label>
        <p>{{adaptorDetailInfo.name}}{{'service' | translate}}</p>
        <label>{{'description' | translate}}{{':' | translate}}</label>
        <p *ngIf="adaptorDetailInfo.description">{{adaptorDetailInfo.description[0].text}}</p>
        <p *ngIf="!adaptorDetailInfo.description">-</p>
        <label>{{'created.on' | translate}}</label>
        <p>{{adaptorDetailInfo.creation| date:'mediumDate'}}</p>
        <label>{{'status' | translate}}{{':' | translate}}</label>
        <p>{{adaptorDetailInfo.status}}</p>
        <label>{{'download.uRL.order' | translate}}</label>
        <p>{{adaptorDetailInfo.location.url}}</p>
        <label>{{'sha1.checksum.on.adaptor.file' | translate}}{{':' | translate}}</label>
        <p>{{adaptorDetailInfo.location.sha1}}</p>
        <label>{{'md5.checksum.on.adaptor.file' | translate}}{{':' | translate}}</label>
        <p>{{adaptorDetailInfo.location.md5}}</p>
        <label>{{'adaptor.start.command' | translate}}{{':' | translate}}</label>
        <p>{{adaptorDetailInfo.controlCommands.start}}</p>
        <button class="btn btn-secondary" style="float: right;" title="Go Back to gateway detail page" (click)="goBack()">{{
            'go.back' | translate}}</button>
        <br>
        <br>
    </div>
  
</div>

<ng-container *ngIf="!adaptorDetailInfo ">
    <div>
        <br/><br/>
    </div>
    <div class="d-flex justify-content-center pageLoading">
        <div class="spinner-border" role="status">
            <span class="sr-only" id="loading"></span>
        </div>
    </div>
</ng-container>
<mat-sidenav-container class="new-document" autosize>

    <mat-sidenav class="midNav-container" #sidenav mode="side" disableClose="true" opened="true">


        <div class="alert warning" *ngIf="warningMessage">
            <h5>
                <fa-icon [icon]="faWarningIcon"></fa-icon>
                <span>{{warningMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)="resetWarningMessage()" [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>
        <div class="alert critical" *ngIf="errorMessage">
            <h5>
                <fa-icon [icon]="faCriticalIcon"></fa-icon>
                <span>{{errorMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=resetWarningMessage() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>

        </div>
        <div class="data">
            <h1> {{ 'create.document.info' | translate}}</h1>
            <p>{{'documentInfo.definition' | translate}}</p>
            <div>
                <button [disabled]="docinfo.name[0].text=='' || documentInfoCreated" class="btn btn-primary pull-left" (click)="save()">{{ 'save' | translate}}</button>
            </div>
        </div>



    </mat-sidenav>
    <mat-sidenav-content class="example-sidenav-content">
        <div class="document-data">
            <div class="form-data">
                <div class="row">
                    <div class="col-md-6">
                        <label for="name"> {{ 'name' | translate}}</label>
                        <input placeholder="required" id="name" type="text" aria-label="Name" [(ngModel)]="docinfo.name[0].text">

                        <label for="description">{{ 'description' | translate}}</label>
                        <textarea rows="2" placeholder="optional" id="description" aria-label="description" [(ngModel)]="docinfo.description[0].text"></textarea>

                        <div class="tags">

                            <label for="device-tags">{{ 'tags' | translate}}</label>
                            <mat-chip-list #tagList aria-label="tags">
    
                                <mat-chip class="ot-chip" *ngFor="let tag of tags" [selectable]="true" [removable]=true (removed)="remove(tag)">
                                    {{tag}}
                                    <button class="ot-chip__close" matChipRemove></button>
                                </mat-chip>
                                <input style="width: 50%;" [ngClass]="tagClass" placeholder="add a tag" #tagInput [matChipInputFor]="tagList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="add($event)">
    
                            </mat-chip-list>
                        </div>

                        <label>{{'enabled'|translate}}</label>
                        <div *ngIf="isActive">
                            <button class="toggle-on toggle-switch" (click)="toggleActive()">
                                <fa-icon [icon]="faCheckIcon" class="fa-2x"></fa-icon>
                                <div class="toggle-on-btn"></div>
                            </button>
                        </div>

                        <div *ngIf="!isActive">
                            <button class="toggle-off toggle-switch" (click)="toggleActive()">
                                <div class="toggle-off-btn"></div>
                            </button>
                        </div>

                    </div> 
                    <div class="col-md-6">
                    <label for="documentType"> {{ 'documentType' | translate}}</label>
                    <input placeholder="required" id="documentType" type="text" (click)="supportedDocTypeError=''" aria-label="documentType" [(ngModel)]="docinfo.supportedDocType">
                    <p *ngIf="supportedDocTypeError" style="color: red;">{{supportedDocTypeError}}</p>

                    <label for="templateType"> {{ 'templateType' | translate}}</label>
                    <input placeholder="required" id="templateType" type="text" (click)="supportedDocTemplateTypeError=''" aria-label="templateType" [(ngModel)]="docinfo.supportedDocTemplateType">
                    <p *ngIf="supportedDocTemplateTypeError" style="color: red;" >{{supportedDocTemplateTypeError}}</p>

                    <label for="templateVersion"> {{ 'templateVersion' | translate}}</label>
                    <input placeholder="required" id="templateVersion" (click)="templateVersionError=''" type="text" aria-label="templateVersion" [(ngModel)]="docinfo.docTemplateVersion">
                    <p *ngIf="templateVersionError" style="color: red;" >{{templateVersionError}}</p>


                    

                    
                </div>
            </div>    
                <br>
                <br>

        </div>
        </div>
    </mat-sidenav-content>

</mat-sidenav-container>
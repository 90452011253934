/**
 * This is an auto generated code for fromTPCodes listing page.
 * Please don't make any change to this file.
 * @version 1.0.0
 * @readonly
 * @author - System generated
 */

import { Component, OnInit, ViewChild, Injector, Inject} from '@angular/core'
import { IotService } from '../service/iot.service'
import { FromTPCodesService } from '../service/fromTPCodes.service'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { faCaretUp, faCaretDown, faInfoCircle, faQuestionCircle, faArrowUp, faArrowDown, faSearch } from '@fortawesome/free-solid-svg-icons'
import { SideNavComponent } from '../side-nav/side-nav.component'
import { BaseListComponent } from '../base-list/baselist.component'

interface TypeChange {
	value: string;
	viewValue: string;
}
@Component({
	selector: 'app-fromTPCodes',
	templateUrl: './fromTPCodes.component.html',
	styleUrls: ['../base-list/baselist.component.scss','./fromTPCodes.component.scss']
})
export class FromTPCodesComponent extends BaseListComponent {
	searchCriteria="qualifier";
	displayedColumns: string[] = ['qualifier','code','creation'];
	sortColumns: string[] = ['qualifier','creation'];
	pageTitle="fromTPCodes.header";
	ascQualifier:boolean = false;
	descQualifier:boolean = false;
	ascCreation:boolean = false;
	descCreation:boolean = false;
	countCallCompleted:boolean = false;
	getCallCompleted:boolean = false;
	showEnableColumn = true;
	fromTPCodesService:any;

	constructor( router: Router,
				iotService: IotService,
				translateService: TranslateService,
				sideNavComponent:SideNavComponent,
				@Inject('fromTPCodesService1') public firstFromTPCodesService: FromTPCodesService,
				@Inject('fromTPCodesService2') public secondFromTPCodesService: FromTPCodesService){
				super(router, iotService, translateService, sideNavComponent )
						this.translateService.addLangs(this.supportLanguages);
						this.translateService.setDefaultLang('en');
	}

	ngOnInit(): void {
		super.ngOnInit();
		if(this.instance=="1"){
			this.fromTPCodesService=this.firstFromTPCodesService;
		}
		else if(this.instance=="2"){
			this.fromTPCodesService=this.secondFromTPCodesService;
		}
		else{
			this.fromTPCodesService=this.firstFromTPCodesService;
		}
		this.fromTPCodesService.selection.clear();
		this.fromTPCodesService.instanceValue = this.instance;
		this.fromTPCodesService.multiSelectRows = this.multiSelect;
		this.fromTPCodesService.loadSelected();
		if(!this.showSelectColoumn){
			this.sideNavComponent.menuClear();
			this.sideNavComponent.menuChange('Dashboard','sub-type');
			this.sideNavComponent.menuChange('fromTPCodes.header','sub-type');
		}
	}
	getFilteredResults(value, columnName , sortOrder){
		const that = this;
		that.iotService.getCuiObjResponse().getFromTPCodes({
			qs: eval(that.fnGetQS(value, columnName, sortOrder))
		}).then(function (response) {
			that.fnSuccess(response);
			if(columnName == 'none' || columnName == '' || columnName == undefined){
				that.fnResetSortParam('creation', 'desc');
			}else{
				that.fnResetSortParam(columnName, sortOrder);
			}
			that.getCallCompleted = true;
		}).fail(function (err) {
			that.fnError(err);
			that.getCallCompleted = true;
		});
	}
	getCount() {
		const that = this;
		that.showPagination=false;
		let value = this.selected;
		that.iotService.getCuiObjResponse().countFromTPCodes({
			qs: eval(that.fnGetQS(value, '', ''))
		}).then(function (response) {
				that.totalCount = response;
				that.countCallCompleted = true;
			if(that.totalCount > 0)
				that.showPagination=true;
			}).fail(function (err) {
				that.fnError(err);
				that.countCallCompleted = true;
			});
	}
	activateFromTPCodes(resourceId){
		const that = this;
		that.iotService.getCuiObjResponse().activateFromTPCodes({
			tpCodeId: resourceId
		}).then(function (response) {
			that.getFilteredResults(that.selected, that.sortCriteria, that.sortDirection);
			that.fromTPCodesService.fnResetList();
		}).fail(function (err) {
			that.fnError(err);
		});
	}
	deactivateFromTPCodes(resourceId){
		const that = this;
		that.iotService.getCuiObjResponse().deactivateFromTPCodes({
			tpCodeId: resourceId
		}).then(function (response) {
			that.getFilteredResults(that.selected, that.sortCriteria, that.sortDirection);
			that.fromTPCodesService.fnResetList();
		}).fail(function (err) {
			that.fnError(err);
		});
	}
	deleteFromTPCodes(resourceId){
		const that = this;
		that.iotService.getCuiObjResponse().deleteFromTPCodes({
			tpCodeId: resourceId
		}).then(function (response) {
			that.getCount();
			that.getFilteredResults(that.selected, that.sortCriteria, that.sortDirection);
			that.fromTPCodesService.fnResetList();
		}).fail(function (err) {
			that.fnError(err);
			that.fromTPCodesService.showErrorMessage(err);
		});
	}
	createNew(){
		this.fromTPCodesService.createNew();
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}
	onSelection(results){
		this.fromTPCodesService.onSelection(results);
	}
	removeSelection(results){
		this.fromTPCodesService.removeSelection(results);
	}
	changeActive(resourceId, isActivate){
		if(isActivate == 'true'){
			this.deactivateFromTPCodes(resourceId);
		}else{
			this.activateFromTPCodes(resourceId);
		}
	}
}

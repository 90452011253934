import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DocumentInfoService } from '../service/documentInfo.service';
import { FromTPCodesService } from '../service/fromTPCodes.service';
import { IotService } from '../service/iot.service';
import { ToTPCodesService } from '../service/toTPCodes.service';
import { TradingPartnersService } from '../service/tradingPartners.service';

@Component({
  selector: 'app-new-route-resource-relationship',
  templateUrl: './new-route-resource-relationship.component.html',
  styleUrls: ['./new-route-resource-relationship.component.scss']
})
export class NewRouteResourceRelationshipComponent implements OnInit {

  byTp = true;
  byTpCodes = false;
  supportLanguages = ['en'];
  workingTradingPartnerRouteSource;
  nameError;

  constructor(public iotService: IotService, public tpService: TradingPartnersService,
    private translateService: TranslateService,
    public fromCodesService: FromTPCodesService,
    public toCodesService: ToTPCodesService,
    public docInfoService: DocumentInfoService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }

  ngOnInit(): void {
    this.initTpRouteResource();
  }

  initTpRouteResource() {
    const that = this;
    that.workingTradingPartnerRouteSource = {
      "creator": that.iotService.getCreator(),
      "creatorAppId": that.iotService.getCreatorAppId(),
      "realm": that.iotService.getRealm(),
      "senderOwnerId": '',
      "receiverOwnerId": that.tpService.tradingPartnerId,
      "fromCodeId": '',
      "toCodeId": '',
      "documentInfoId": '',
      "name": [{
        "lang": "en_us",
        "text": ""
      }]
    }
  }

  resetName(){
    this.nameError="";
  }
  save() {
    const that = this;

    if (that.byTp) {
      if (that.tpService.getTradingPartner() == undefined) {
        that.tpService.warningMessage = "Please select a trading partner  from the list";
      }
      else {
        that.populateData();
        that.addTPRoutResource();
      }
    }
    else if (this.byTpCodes) {
      if (that.tpService.getFromCode() == undefined ||  that.tpService.getToCode() == undefined) {
        that.tpService.warningMessage = "Please select a From code, To code from the list";
      }
      else {
        that.populateData();
        that.addTPRoutResource();
      }
    }

  }

  populateData() {
    if (this.byTp) {
      if(this.tpService.getTpDocInfo()!=undefined && this.tpService.getTradingPartner()!=undefined){
      this.workingTradingPartnerRouteSource.senderOwnerId = this.tpService.getTradingPartner().id;
      this.workingTradingPartnerRouteSource.documentInfoId = this.tpService.getTpDocInfo().id;
      } else if(this.tpService.getTradingPartner()!=undefined && this.tpService.getTpDocInfo()==undefined){
        this.workingTradingPartnerRouteSource.senderOwnerId = this.tpService.getTradingPartner().id;
      }
    }
    else {
      if(this.tpService.getTpDocInfo()!=undefined && this.tpService.getFromCode()!=undefined && this.tpService.getToCode()!=undefined){
      this.workingTradingPartnerRouteSource.documentInfoId = this.tpService.getTpDocInfo().id;
      this.workingTradingPartnerRouteSource.fromCodeId = this.tpService.getFromCode().id;
      this.workingTradingPartnerRouteSource.toCodeId = this.tpService.getToCode().id;
    } else if(this.tpService.getTpDocInfo()==undefined && this.tpService.getFromCode()!=undefined && this.tpService.getToCode()!=undefined){
      this.workingTradingPartnerRouteSource.fromCodeId = this.tpService.getFromCode().id;
      this.workingTradingPartnerRouteSource.toCodeId = this.tpService.getToCode().id;
    }
  }
}
  // updateTpRouteResource(routeResource) {
  //   const that = this;
  //   that.iotService.getCuiObjResponse().updateTPRouteSource({
  //     routingSourceId: routeResource.id,
  //     data: that.workingTradingPartnerRouteSource
  //   }).then(function (response) {
  //     console.log("Trading Partner application created successFully, Id: ", response.id);
  //     that.tpService.createResource = true;
  //     that.tpService.createRouteSource = false;
  //     that.tpService.resetMessage();

  //   }).fail(function (err) {
  //     if (err.responseJSON.apiStatusCode) {
  //       that.tpService.warningMessage = err.responseJSON.apiMessage;
  //       console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
  //     } else {
  //       console.log("Something went wrong. Please check chrome console or check with dev team");
  //     }
  //   });

  // }
  addTPRoutResource() {
    const that = this;

    if (that.workingTradingPartnerRouteSource.name[0].text==""||that.workingTradingPartnerRouteSource.name[0].text==undefined) {
      that.nameError="Name required";
    }
    else {
      that.iotService.getCuiObjResponse().saveTPRouteSource({
        data: that.workingTradingPartnerRouteSource
      }).then(function (response) {
        console.log("Trading Partner application created successFully, Id: ", response.id);
        that.tpService.createResource = true;
        that.tpService.createRouteSource = false;
        that.tpService.setTradingPartner(undefined);
        that.tpService.selection.clear();
        that.tpService.resetMessage();

      }).fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          that.tpService.errorMessage = err.responseJSON.apiMessage;
          console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
        } else {
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    }

  }

  goBack() {
    const that = this;
    that.tpService.createResource = true;
    that.tpService.createRouteSource = false;
    that.tpService.selection.clear();
    that.tpService.resetMessage();
    that.tpService.setTradingPartner(undefined);
    that.tpService.setFromCode(undefined);
    that.tpService.setToCode(undefined);
    that.tpService.setTpDocInfo(undefined);

  }

  tabClick(tab) {
    console.log(tab.index);
    if (tab.index == 0) {
      console.log("auth");
      this.byTp = true;
      this.byTpCodes = false;
      // this.docInfoService.selection.clear();
      this.tpService.setTpDocInfo(undefined);
      this.tpService.setTradingPartner(undefined);
      this.tpService.selection.clear();
    }
    else if (tab.index == 1) {
      console.log("cert");
      this.byTp = false;
      this.byTpCodes = true;
      this.tpService.setTpDocInfo(undefined);
      this.tpService.setFromCode(undefined);
      this.tpService.setToCode(undefined);
    }
  }


}

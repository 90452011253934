<div class="tab-holder">
    <mat-tab-group (selectedTabChange)="tabClick($event)" [selectedIndex]="0">
        <mat-tab label="Trusted Authorities"></mat-tab>
        <mat-tab label="Trusted Certificates"></mat-tab>
        <mat-tab label="Key Bundles"></mat-tab>
    </mat-tab-group>
</div>
<div>
    <app-trustedAuthorities *ngIf=securityService.showAuthorities>
    </app-trustedAuthorities>
    <app-trustedCertificates *ngIf=securityService.showCertificates></app-trustedCertificates>
    <app-keyBundles *ngIf="securityService.showKeyBundles"></app-keyBundles>
</div>
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { IotService } from '../../service/iot.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { TranslateService } from '@ngx-translate/core';
import { OrchestrationConfigsService } from '../../service/orchestrationConfigs.service';

@Component({
  selector: 'app-create-tasks',
  templateUrl: './create-tasks.component.html',
  styleUrls: ['./create-tasks.component.scss']
})
export class CreateTasksComponent implements OnInit {

  separatorKeysCodes: number[] = [ENTER, COMMA];
  supportLanguages = ['en'];
  task;
  utilityTasks = [{
        title: 'String Concatenation',
        type: 'java',
        className: "#stringConcat",
        taskType: ['PACKAGER'],
        order: 1
    },
    {
        title: 'Base64 Decode',
        type: 'java',
        className: "#base64Decode",
        taskType: ['PREPROCESSOR', 'MAPPER', 'POSTPROCESSOR'],
        order: 2
    },
    {
        title: 'Base64 Encode',
        type: 'java',
        className: "#base64Encode",
        taskType: ['MAPPER', 'POSTPROCESSOR'],
        order: 3
    },
    {
        title: 'CSV to XML',
        type: 'java',
        className: "#csvToXml",
        taskType: ['MAPPER'],
        order: 4
    },
    {
        title: 'JSON to XML',
        type: 'java',
        className: "#jsonToXml",
        taskType: ['MAPPER'],
        order: 5
    },
    {
        title: 'XML to JSON',
        type: 'java',
        className: "#xmlToJson",
        taskType: ['MAPPER'],
        order: 6
    },
    {
        title: 'Map in out',
        type: 'java',
        className: "#mapinout",
        taskType: ['MAPPER'],
        order: 7
    },
    {
        title: 'XSLT',
        type: 'XSLT',
        defaultCode: "",
        taskType: ['MAPPER'],
        editorMode: 'xml',
        order: 8,
        className: "PHhzbDpzdHlsZXNoZWV0IHZlcnNpb249IjEuMCIgeG1sbnM6eHNsPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L1hTTC9UcmFuc2Zvcm0iPg0KPHhzbDp0ZW1wbGF0ZSBtYXRjaD0ibm9kZSgpIHwgQCoiPg0KICAgIDx4c2w6Y29weT4NCiAgICAgICAgPHhzbDphcHBseS10ZW1wbGF0ZXMgc2VsZWN0PSJub2RlKCkgfCBAKiIvPg0KICAgIDwveHNsOmNvcHk+DQo8L3hzbDp0ZW1wbGF0ZT4NCg0KPHhzbDp0ZW1wbGF0ZSBtYXRjaD0iZGV2aWNlSWQiLz4NCjx4c2w6dGVtcGxhdGUgbWF0Y2g9ImV2ZW50VGVtcGxhdGVJZCIvPg0KPHhzbDp0ZW1wbGF0ZSBtYXRjaD0icmVjZWl2ZXJPd25lcklkIi8+DQo8eHNsOnRlbXBsYXRlIG1hdGNoPSJzZW5kZXJPd25lcklkIi8+DQo8eHNsOnRlbXBsYXRlIG1hdGNoPSJlbmNvZGluZ1R5cGUiLz4NCg0KPC94c2w6c3R5bGVzaGVldD4="
    },
    {
        title: 'XSD',
        type: 'XSD',
        className: "",
        taskType: ['MAPPER'],
        editorMode: 'xml',
        order: 9,
        defaultCode: ""
    },
    {
        title: 'Javascript',
        type: 'javascript',
        defaultCode: "",
        taskType: ['PARSER', 'PREPROCESSOR', 'MAPPER', 'PACKAGER', 'POSTPROCESSOR'],
        editorMode: 'javascript',
        order: 10,
        className: "Ly8gc2FtcGxlIHJlY2VpdmVyIHdpcmUgamF2YXNjcmlwdAp2YXIgcmVzdWx0ID0gZnVuY3Rpb24ob3JjaGVzdHJhdGlvbiwgZW52aXJvbm1lbnQpIHsKIC8vIGV4dHJhY3QgdGhlIEV2ZW50IG1lc3NhZ2UKIHZhciBldmVudE1lc3NhZ2UgPSBleHRyYWN0TWVzc2FnZShvcmNoZXN0cmF0aW9uKTsKIC8vIGVucmljaCB0aGUgZXZlbnQgZmllbGRzCiBldmVudE1lc3NhZ2UuZGF0YUVucmljaG1lbnRBdHRyMSA9ICJteV9hdHRyIjsKCiByZXR1cm4gSlNPTi5zdHJpbmdpZnkoZXZlbnRNZXNzYWdlKTsKfTsKLy8gZXh0cmFjdHMgdGhlIHJlY2VpdmVyIHdpcmUgZnJvbSB0aGUgbWVzc2FnZQp2YXIgZXh0cmFjdE1lc3NhZ2UgPSBmdW5jdGlvbihvcmNoZXN0cmF0aW9uKSB7CiB2YXIgb3JjaGVzdHJhdGlvbk9iaiA9IEpTT04ucGFyc2UoZW5jb2RlRGVjb2RlVXRpbC5kZWNvZGVNZXNzYWdlKG9yY2hlc3RyYXRpb24pKTsKIHZhciByZXR1cm5FdmVudE1lc3NhZ2UgPSBvcmNoZXN0cmF0aW9uT2JqLnJldHVyblBheWxvYWQ7CgogcmV0dXJuRXZlbnRNZXNzYWdlID0gSlNPTi5wYXJzZShyZXR1cm5FdmVudE1lc3NhZ2UpOwoKIHJldHVybiByZXR1cm5FdmVudE1lc3NhZ2U7Cn07Cgp2YXIgbG9hZENvbmZpZ3MgPSBmdW5jdGlvbihjb25maWdLZXkpIHsKIAogIHRyeSB7CiAgICAgdmFyIGdldEtWUmVzcG9uc2UgPSBhcGlQcm94eVV0aWwuZG9BcGlDYWxsKAogICAgICAgICJnZXRfa3ZfdjEiLCJrZXk6Iitjb25maWdLZXkKICAgICApOwogICAgIC8vREVCVUcud3JpdGUoImdldEtWUmVzcG9uc2UgaW4gbG9hZENvbmZpZ3MgPSAiICsgSlNPTi5zdHJpbmdpZnkoZ2V0S1ZSZXNwb25zZSkpOwogICAgIHJldHVybiBnZXRLVlJlc3BvbnNlOwogIH0gY2F0Y2ggKGUpIHsKICAgICBvdXQucHJpbnRsbihlKTsKICAgICBERUJVRy53cml0ZSgiPDw8PDw8IEV4Y2VwdGlvbiBpbiBsb2FkQ29uZmlncyA+Pj4+Pj4iKTsKICAgICByZXR1cm4geyJyZXNwb25zZUNvZGUiOiI1MDAiLCAicmVzcG9uc2VNZXNzYWdlIjogIkFuIHVua25vd24gc2VydmVyIGVycm9yIn07CiAgfQp9Ow=="
    },
    {
        title: 'Regular Expression',
        type: 'java',
        className: "#regex",
        taskType: ['PARSER'],
        order: 11
    },
    {
        title: 'EDI Parser/Splitter',
        type: 'java',
        className: "#ediParsing",
        taskType: ['PARSER'],
        order: 12
    },
    {
        title: 'HL7 Parser/Splitter',
        type: 'java',
        className: "#hl7Parsing",
        taskType: ['PARSER'],
        order: 13
    },
    {
        title: 'EDIFACT to XML',
        type: 'java',
        className: "#edifactToXml",
        taskType: ['MAPPER'],
        order: 14
    },
    {
        title: 'HL7 to XML',
        type: 'java',
        className: "#hl7ToXml",
        taskType: ['MAPPER'],
        order: 15
    },
    {
        title: 'AES256 Decryption',
        type: 'java',
        className: "#aes256PskDecrypt",
        taskType: ['PREPROCESSOR', 'MAPPER', 'POSTPROCESSOR'],
        order: 16
    },
    {
        title: 'AES256 Encryption',
        type: 'java',
        className: "#aes256PskEncrypt",
        taskType: ['POSTPROCESSOR', 'MAPPER'],
        order: 17
    }

  ]

  taskListUI;
  selectedTasktype;
  mode = 0;
  @ViewChild('editor') editor;

  @Input() taskType:string = "";

  constructor(public orchestrationConfigsService: OrchestrationConfigsService, private translateService: TranslateService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }

  ngOnInit(): void {
    console.log("Going to initializes task");
    this.taskListUI = this.utilityTasks.filter(x => x.taskType.includes(this.taskType));
    if(this.orchestrationConfigsService.getEditableTask()){
      this.mode = 1;
      this.task = this.orchestrationConfigsService.getEditableTask();
      if(this.task.type == 'javascript' || this.task.type == 'XSLT' || this.task.type == 'XSD'){
        var index = this.utilityTasks.findIndex(x => (x.taskType.includes(this.task.taskType) && x.type == this.task.type));
        this.selectedTasktype =  this.utilityTasks[index];
      } else{
        var index = this.utilityTasks.findIndex(x => (x.taskType.includes(this.task.taskType) && x.className == this.task.className));
        this.selectedTasktype =  this.utilityTasks[index];
      }
    } else{
      this.selectedTasktype = this.taskListUI[0];
      this.task = {
        "taskType":this.taskType,
        "name":this.selectedTasktype.title,
        "type":this.selectedTasktype.type,
        "order":this.getNextOrder(this.taskType),
        "className":this.selectedTasktype.className,
        "continueOnFailure":false,
        "title":this.selectedTasktype.title
      }
    }
    console.log("Task initializes successfully");
  }

  ngOnDestroy(): void{
    console.log("Going to destroy task window");
    this.orchestrationConfigsService.setEditableTask(undefined);
    this.task = undefined;
    this.selectedTasktype = undefined;
    console.log("Task window destroyed successfully");
  }

  initTask(event){
    if(this.mode == 0){
      this.task.name = event.title;
      //this.task.order = this.getNextOrder(this.task.taskType);
    }
    this.task.type = event.type;
    this.task.className = event.className;
    this.task.title = event.title;
  }

  back(){
    this.orchestrationConfigsService.createTask = false;
  }

  getNextOrder(taskType){
    var order = 1;
      this.orchestrationConfigsService.taskList.forEach( (task) => {
        if(task){
          if(task.taskType === taskType && task.order >= order){
            order = task.order + 1;
          }
        }
      });
    return order * 1;
  }

  save(){
    console.log("Going to save task");
    if(this.task.type == 'javascript' || this.task.type=='XSLT' || this.task.type=='XSD'){
      //Getting latest script from ace componenet
      this.task.className = this.editor.getEncodedAceEditorValue();
    }
    if(this.mode == 1){ //Edit mode
      var index = this.orchestrationConfigsService.taskList.findIndex(x => (x.taskType.includes(this.task.taskType) && x.order == this.task.order));
      if(index != -1){
        this.orchestrationConfigsService.taskList[index] = this.task;
      }
    } else{
      this.orchestrationConfigsService.taskList.push(this.task);
    }
    this.orchestrationConfigsService.createTask = false;
    console.log("Task saved successfully");
  }
}

import { Injectable } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { StreamService } from './stream.service';
import { IotService } from '../service/iot.service';
import { ListFromTPCodesService } from './ListFromTPCodes.service';
import { TradingPartnersService } from './tradingPartners.service';
import { threadId } from 'worker_threads';
import { MessageGovernancesService } from '../service/messageGovernances.service';

@Injectable({
	providedIn: 'root'
})

export class FromTPCodesService extends ListFromTPCodesService {

	selection1: SelectionModel<String> = new SelectionModel<String>(true, []);
	selection2: SelectionModel<String> = new SelectionModel<String>(true, []);

	constructor(public iotService: IotService, public streamService: StreamService, public tpService: TradingPartnersService, public messageGovernancesService: MessageGovernancesService) {
		super(iotService);
		if (this.instanceValue == "1") {
			this.selection = this.selection1;
		}
		else if (this.instanceValue == "2") {
			this.selection = this.selection2;
		}
		this.selection.clear();
		this.loadSelected();
	}

	loadSelected() {
		if (this.tpService.getFromCode()&& this.iotService.createTradingPartner==true) {
			this.onSelection(this.tpService.getFromCode());
		}
		else if(this.streamService.getFromCodeValue() &&( this.instanceValue == "1" ||this.instanceValue == "0") && this.iotService.createStream==true){
			this.selection.select(this.streamService.getFromCodeValue().id);
		}
		else if(this.streamService.getfromCodeValue2() && this.instanceValue == "2" && this.iotService.createStream==true){
			this.selection.select(this.streamService.getfromCodeValue2().id);
		}
		else if(this.messageGovernancesService.getFromCodeValue() && this.iotService.msgGovLoader==true){
			this.messageGovernancesService.getFromCodeValue().forEach(fromTPCode => {
				this.selection.select(fromTPCode.id);
			});	
		}
	}

	fnOnSelectionSupport(results) {
		const that = this;

		if (this.instanceValue == "1") {
			that.streamService.fromCode = true;
			that.streamService.setFromCodeValue(results);
		}
		else if (this.instanceValue == "2") {
			that.streamService.fromCode2 = true;
			that.streamService.setfromCodeValue2(results);
		}
		else {
			
			if (this.iotService.createStream == true) {
				that.streamService.fromCode = true;
				that.streamService.setFromCodeValue(results);
			}
			else if (that.iotService.createTradingPartner == true) {
				that.tpService.setFromCode(results);
			}
		}
		if (that.iotService.createMessageGovernance == true) {
			let fromCodeValue = that.messageGovernancesService.getFromCodeValue();
			fromCodeValue.push(results);
			that.messageGovernancesService.setFromCodeValue(fromCodeValue);
		}

	}

	fnRemoveSelectionSupport(results) {
		const that = this;
		if (this.instanceValue == "1") {
			that.streamService.fromCode = false;
			that.streamService.setFromCodeValue(undefined);
		}
		else if (this.instanceValue == "2") {
			that.streamService.fromCode2 = false;
			that.streamService.setfromCodeValue2(undefined);
		}
		else {
		
			if (this.iotService.createStream == true) {
				that.streamService.fromCode = false;
				that.streamService.setFromCodeValue(results);
			}
			else if (that.iotService.createTradingPartner == true) {
				that.tpService.setFromCode(results);
			}
		}

		if (that.iotService.createMessageGovernance == true) {
			let fromCodeValue = that.messageGovernancesService.getFromCodeValue();
			fromCodeValue.forEach((value, index) => {
				if (value.id == results.id) fromCodeValue.splice(index, 1);
			});
			that.messageGovernancesService.setFromCodeValue(fromCodeValue);

		}
	}

}

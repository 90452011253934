<main id="ecosystem-template-details">
    <div class="d-flex justify-content-center loader" *ngIf="!ecosystemTemplate">
        <div class="spinner-border" role="status">
            <span class="sr-only" id="loading"></span>
        </div>
    </div>

    <mat-sidenav-container autosize *ngIf="ecosystemTemplate">
        <mat-sidenav class="midNav-container" #sidenav mode="side" disableClose="true" opened="true">
            <div class="alert critical" *ngIf="ecosystemTemplateService.errorMessage">
                <h5>
                    <fa-icon [icon]="faCriticalIcon"></fa-icon>
                    <span>{{ecosystemTemplateService.errorMessage}}</span>
                    <button class="alert-close-btn">
                        <fa-icon (click)=ecosystemTemplateService.resetErrorMessage() [icon]="faCloseIcon"></fa-icon>
                    </button>
                </h5>
            </div>
            <div class="alert warning" *ngIf="ecosystemTemplateService.warningMessage">
                <h5 style="font-size: 12px;">
                    <fa-icon [icon]="faWarningIcon"></fa-icon>
                    <span>{{ecosystemTemplateService.warningMessage}}</span>
                    <button class="alert-close-btn">
                        <fa-icon (click)="ecosystemTemplateService.resetWarningMessage()" [icon]="faCloseIcon"></fa-icon>
                    </button>
                </h5>
            </div>
        <div *ngIf="iotService.viewRulePolicy">
          <div class="alert critical" *ngIf="rulesService.errorMessage">
              <h5>
                  <fa-icon [icon]="faCriticalIcon"></fa-icon>
                  <span>{{rulesService.errorMessage}}</span>
                  <button class="alert-close-btn">
                      <fa-icon (click)=rulesService.resetMessage() [icon]="faCloseIcon"></fa-icon>
                  </button>
              </h5>
          
          </div>
          <div class="alert success" *ngIf="rulesService.successMessage">
              <h5>
                  <fa-icon [icon]="faSuccessIcon"></fa-icon>
                  <span>{{rulesService.successMessage}}</span>
                  <button class="alert-close-btn">
                      <fa-icon (click)=rulesService.resetMessage() [icon]="faCloseIcon"></fa-icon>
                  </button>
              </h5>
          </div>
          <div class="alert warning" *ngIf="rulesService.warningMessage">
              <h5 style="font-size: 12px;">
                  <fa-icon [icon]="faWarningIcon"></fa-icon>
                  <span>{{rulesService.warningMessage}}</span>
                  <button class="alert-close-btn">
                      <fa-icon (click)="rulesService.resetMessage()" [icon]="faCloseIcon"></fa-icon>
                  </button>
              </h5>
          </div>
        </div>
        <div style="margin-left: -10px;">
            <app-copy-resource-id></app-copy-resource-id>
        </div>
            <div class="form-data" *ngIf="ecosystemTemplate">

                <label for="name"> {{ 'name' | translate}}</label>
                <p>{{ecosystemTemplate.name[0].text}}</p>

                <label for="description"> {{ 'description' | translate}}</label>
                <p *ngIf="ecosystemTemplate.description">{{ecosystemTemplate.description[0].text}} </p>
                <p *ngIf="!ecosystemTemplate.description">-</p>

                <div class="tags">
                    <label for="ecosystem-template-tags">{{ 'tags' | translate}}</label>
                    <mat-chip-list #tagList aria-label="tags">
                        <mat-chip class="ot-chip" *ngFor="let tag of ecosystemTemplate.tags" [selectable]="true"
                            [removable]=true (removed)="remove(tag)">
                            {{tag}}
                            <button class="ot-chip__close" matChipRemove></button>
                        </mat-chip>
                        <input style="width: 50%;" [ngClass]="tagClass" placeholder="add a tag" #tagInput
                            [matChipInputFor]="tagList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="add($event)">
                    </mat-chip-list>
                </div>

                <label for="creation">{{'created' | translate}}</label>
                <p>{{ecosystemTemplate.creation | date:'mediumDate' }}</p>

                <label>{{'enabled' | translate}}{{'?' | translate}}</label>
                <div *ngIf="ecosystemTemplate.active">
                    <button class="toggle-on toggle-switch" title="Disable" (click)="toggleActive(true)">
                        <fa-icon [icon]="faCheckIcon" class="fa-2x"></fa-icon>
                        <div class="toggle-on-btn"></div>
                    </button>
                </div>
                <div *ngIf="!ecosystemTemplate.active">
                    <button class="toggle-off toggle-switch" title="Enable" (click)="toggleActive(false)">
                        <div class="toggle-off-btn"></div>
                    </button>
                </div>
<br/>
                <div>
                    <button class="btn btn-secondary" (click)="createRuleFromEcosystemTemplate(ecosystemTemplate.id)">{{'add.rule.policy' | translate}}</button>&nbsp;
                    <button class="btn btn-secondary" (click)="auditEcosystemTemplate()">{{ 'audit' |translate}}</button>
                </div>

           

            </div>
            <div class="form-data" *ngIf="iotService.viewRulePolicy">
                <!-- <h1>Create Rule</h1> -->
                <div>
                    <label>{{'trigger.types' | translate}}</label>
                    <p *ngIf="rulesService.triggerType=='Select Trigger'">
                        {{'select.triggerType' | translate}}
                    </p>
                    <p class="rulesService.trigger" *ngIf="rulesService.triggerType!='Select Trigger'">{{rulesService.triggerType}}</p>
        
                    <label>{{'event.template' | translate}}</label>
                    <p *ngIf="!rulesService.selectedEvent">
                        {{'select.event.table' | translate}}
                    </p>
                    <div *ngIf="rulesService.selectedEvent">
                        <ul>
                            <li>
                                <fa-icon [icon]="faCheckIcon"></fa-icon> {{rulesService.getEvents().name}}
                            </li>
                        </ul>
                    </div>
                    <label>{{'devices' | translate}}</label>
                    <p *ngIf="rulesService.devices.length == 0">
                        {{'select.device.table' | translate}}
                    </p>
                    <ul>
                        <li *ngFor="let device of rulesService.devices">
                            <fa-icon [icon]="faCheckIcon"></fa-icon> {{ device.name[0].text }}
                        </li>
                    </ul>
                    <div *ngIf="!rulesService.showGateway && rulesService.triggerType.includes('Device')">
                        <label>{{'templates.header' | translate}}</label>
                        <p *ngIf="rulesService.deviceTemplates.length == 0">
                            {{'select.deviceTemplate.table' | translate}}
                        </p>
                        <ul>
                            <li *ngFor="let deviceTemplate of rulesService.deviceTemplates">
                                <fa-icon [icon]="faCheckIcon"></fa-icon> {{ deviceTemplate.name[0].text }}
                            </li>
                        </ul>
                    </div>
                    <div *ngIf="rulesService.triggerType=='Device Geofence'">
                        <label>{{'geofences' | translate}}</label>
                        <p *ngIf="rulesService.geofences.length == 0">
                            {{'select.geofence.table' | translate}}</p>
                        <ul>
                            <li *ngFor="let geofence of rulesService.geofences">
                                <fa-icon [icon]="faCheckIcon"></fa-icon> {{ geofence.name[0].text }}
                            </li>
                        </ul>
                    </div>
                    <div *ngIf="rulesService.showGateway">
                        <label>{{'gateways' | translate}}</label>
                        <p *ngIf="rulesService.gateways == undefined">
                            {{'select.gateway.table' | translate}}</p>
                        <div *ngIf="rulesService.gateways != undefined">
                            <ul>
                                <li>
                                    <fa-icon [icon]="faCheckIcon"></fa-icon> {{rulesService.gateways.name[0].text}}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div *ngIf="(rulesService.triggerType=='Device Event' || rulesService.triggerType.includes('Ecosystem')) && !rulesService.showGateway">
                        <label>{{'subscribers' | translate}}</label>
                        <label>{{'ecosystems.header' | translate}}</label>
                        <p *ngIf="rulesService.ecosystems.length == 0">
                            {{'select.ecosystem.table' | translate}}</p>
                        <ul>
                            <li *ngFor="let ecosystem of rulesService.ecosystems">
                                <fa-icon [icon]="faCheckIcon"></fa-icon> {{ ecosystem.name[0].text }}
                            </li>
                        </ul>
                        <label>{{'ecosystems.templates' | translate}}</label>
                        <p *ngIf="rulesService.ecosystemTemplates.length == 0">
                            {{'select.ecosystemTemplate.table' | translate}}</p>
                        <ul>
                            <li *ngFor="let ecosystemTemplate of rulesService.ecosystemTemplates">
                                <fa-icon [icon]="faCheckIcon"></fa-icon> {{ ecosystemTemplate.name[0].text }}
                            </li>
                        </ul>
                     </div>
                    </div>
           </div>
     
  
        </mat-sidenav>
        <div *ngIf="!iotService.viewRulePolicy">
            <div class="container-data">
            <div *ngIf="!auditFlag">
                <ng-container>
                    <app-ecosystemAttributes [pageSize]=" 5 " [showControlBar]=false
                        [displayedColumns]="['name', 'description', 'type','isActive'] " [showSelectColoumn]=true
                        [showEnableColumn]=true addnQueryParam=[ecosystemTemplateId::{{ecosystemTemplate.id}}] [showHeaderToolTips]=false>
                    </app-ecosystemAttributes>

                    <app-ecosystemEvents [pageSize]="5" [showControlBar]=false
                        [displayedColumns]="['name', 'description', 'isActive']" [showSelectColoumn]=true
                        [showEnableColumn]=true addnQueryParam=[ecosystemTemplateId::{{ecosystemTemplate.id}}] [showHeaderToolTips]=false>
                    </app-ecosystemEvents>

                    <app-ecosystemMembers [pageSize]="5" [showControlBar]=false [displayedColumns]="['type', 'kind']"
                        [showSelectColoumn]=true [showEnableColumn]=true
                        addnQueryParam=[ecosystemTemplateId::{{ecosystemTemplate.id}}] [showHeaderToolTips]=false>
                    </app-ecosystemMembers>

                    <app-ecosystemTemplateRules [pageSize]="5" [showControlBar]=false
                        [displayedColumns]="['sourceEventKey','ruleName','sourceEventName','creation','ACTION']"
                        [showSelectColoumn]=true [showEnableColumn]=true
                        addnQueryParam=[ecosystemTempId::{{ecosystemTemplate.id}}] [showHeaderToolTips]=false>
                    </app-ecosystemTemplateRules>

                    <app-ecosystemBytemplates [pageSize]="5" [showControlBar]=true [showSearch]=false
                        [displayedColumns]="['name', 'description', 'creation']" [showNew]=true [showSelectColoumn]=true
                        [showEnableColumn]=true addnQueryParam=[parentGroupTemplateId::{{ecosystemTemplate.id}}
                        [newWithRoute]=false [showHeaderToolTips]=false>
                    </app-ecosystemBytemplates>
                </ng-container>
            </div>

            <!--Added for Auit Integration-->
            <div style="height: 110vh;">

                <app-audits *ngIf="auditFlag && auditRefreshFlag && iotService.auditScriptFlag==false" [showNew]="false"
                    [showControlBar]=false [displayedColumns]="['creation','requestorId','actionType','id']"
                    showSelectColoumn=false showEnableColumn=false
                    addnQueryParam=[resourceId::{{ecosystemTemplate.id}}] [showHeaderToolTips]=false></app-audits>
                    <app-audits *ngIf="auditFlag && !auditRefreshFlag&& iotService.auditScriptFlag==false" [showNew]="false"
                    [showControlBar]=false [displayedColumns]="['creation','requestorId','actionType','id']"
                    showSelectColoumn=false showEnableColumn=false
                    addnQueryParam=[resourceId::{{ecosystemTemplate.id}}] [showHeaderToolTips]=false></app-audits>
                    <div  *ngIf="auditFlag && iotService.auditScriptFlag==false">
                        <br>
        
                        <button class="btn btn-secondary pull-right" style="margin-right: 15px;" (click)="closeAudit()">{{ 'go.back' | translate}}</button>
                        <br>
                        <br>
                    </div>
                    <br>
                    <div *ngIf="iotService.auditScriptFlag" style="margin: 0 5px 0 5px;">
                        <app-ace></app-ace>
                    </div>
                    <div *ngIf="iotService.auditScriptFlag" class="ecosystemTemplateAudit">
                        <button class="goBack" mat-raised-button color="primary" (click)="goBackAuditList()">{{ 'go.back' |
                            translate}}</button>
                        <br>
                        <br>
                    </div>
        
                    <br>
                    <br>
            </div>

            </div>
        </div>
        <ng-container *ngIf="iotService.viewRulePolicy">
            <app-create-rule-policy></app-create-rule-policy>
        </ng-container>

    </mat-sidenav-container>
</main>
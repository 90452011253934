<mat-sidenav-container class="mid-sidenav" autosize>
    <!-- For new device creation -->
    <mat-sidenav class="midNav-container" #sidenav mode="side" disableClose="true" opened="true">


        <div class="alert critical" *ngIf="deviceService.errorMessage">
            <h5>
                <fa-icon [icon]="faCriticalIcon"></fa-icon>
                <span>{{deviceService.errorMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=deviceService.resetErrorMessage() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>
        <div class="alert success" *ngIf="deviceService.successMessage">
            <h5>
                <fa-icon [icon]="faSuccessIcon"></fa-icon>
                <span>{{deviceService.successMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=deviceService.resetSuccessMessage() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>
        <div class="alert warning" *ngIf="deviceService.warningMessage">
            <h5>
                <fa-icon [icon]="faWarningIcon"></fa-icon>
                <span *ngIf="deviceService.duplicateAttrFlag">{{deviceService.warningMessage}} <a (click)="deactivateAttribute(deviceService.duplicateAttributeId)">{{'deactivate.attribute' | translate}}</a> {{'enable.this.one' | translate}}</span>
                <span *ngIf="deviceService.duplicateCommandFlag">{{deviceService.warningMessage}} <a (click)="disableCommand(deviceService.duplicateCommandId)">{{'deactivate.command' | translate}}</a> {{'enable.this.one' | translate}}</span>
                <span *ngIf="deviceService.duplicateEventFlag">{{deviceService.warningMessage}} <a (click)="disableEvent(deviceService.duplicateEventId)">{{'deactivate.event' | translate}}</a> {{'enable.this.one' | translate}}</span>
                <span *ngIf="!deviceService.duplicateEventFlag && !deviceService.duplicateCommandFlag && !deviceService.duplicateAttrFlag">{{deviceService.warningMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)="deviceService.resetWarningMessage() " [icon]="faCloseIcon "></fa-icon>
                </button>
            </h5>
        </div>
        <h1>{{'create.new.device' | translate}}</h1>
        <mat-radio-group class="radio-group" [(ngModel)]="deviceCreate">
            <mat-radio-button color="primary" value=1 class="radio-button" (click)=reset(1)>
                {{'create.existing.template' | translate}}
                    <span id="ot-tooltip-bottom" class="ot--is-width-px-200">
                        <a href="#" class="ot-tooltip ot-tooltip--is-bottom"><fa-icon [icon]="faQuestionCircleIcon"></fa-icon>
                          <span class="ot-tooltip__text">
                              <p>{{'template.existing.desc' | translate}}</p>
                              <p>{{'template.reuse.desc' | translate}}</p>
                            </span>
                        </a>
                      </span>
            </mat-radio-button>
            <mat-radio-button color="primary" value=2 class="radio-button" (click)=reset(2)>
                {{'create.without.template' | translate}}
                    <span id="ot-tooltip-bottom" class="ot--is-width-px-200">
                        <a href="#" class="ot-tooltip ot-tooltip--is-bottom"><fa-icon [icon]="faQuestionCircleIcon"></fa-icon>
                          <span class="ot-tooltip__text">
                              <p>{{'template.create.desc' | translate}}</p>
                              <p>{{'device.replica.desc' | translate}}</p>
                            </span>
                        </a>
                      </span>
            </mat-radio-button>
        </mat-radio-group>

        <mat-nav-list *ngIf="deviceCreate == '1'" class="from-template user-selections">
            <h2>{{'template' | translate}}</h2>
            <p *ngIf="!deviceService.templateSelect">{{'select.availableTemaplate.desc' | translate}}</p>
            <span *ngIf="deviceService.selectedTemplate">
                <p class="blue-highlight" (click)=viewDeviceTemplate(deviceService.selectedTemplate.id)> <fa-icon [icon]="faCheckIcon"></fa-icon> {{deviceService.selectedTemplate.name[0].text}}</p>
                <h2>{{'attributes' | translate}}</h2>
                <ul>
                    <li *ngFor="let attr of deviceService.selectedTemplate.attributeTypes">

                        <ng-container *ngIf="attr.isActive">
                            <fa-icon [icon]="faCheckIcon"></fa-icon>&nbsp;{{attr.name}}
                        </ng-container>
                        <ng-container *ngIf="!attr.isActive">
                            <a class="attrError" (click)=activateAttribute(attr)>
                                &#10005;&nbsp;{{attr.name}}
                            </a>
                        </ng-container>

                    </li>
                </ul>

                <h2>{{'events' | translate}}</h2>
                <ul>
                    <li *ngFor="let event of deviceService.selectedTemplate.eventTemplates"><fa-icon [icon]="faCheckIcon"></fa-icon> {{event.name}}</li>
                </ul>

                <h2>{{'commands' | translate}}</h2>
                <ul>
                    <li *ngFor="let command of deviceService.selectedTemplate.commandTemplates"><fa-icon [icon]="faCheckIcon"></fa-icon> {{command.name}}
                    </li>
                </ul>

                <h2>{{'geofences' | translate}}</h2>
                <ul>
                    <li *ngFor="let geofence of deviceService.associationData"><fa-icon [icon]="faCheckIcon"></fa-icon> {{geofence.geofenceName}}</li>
                </ul>

                <span *ngIf="deviceService.streamSelect">
                    <h2>{{'streams' | translate}}</h2>
                    <ul>
                        <li *ngFor="let stream of deviceService.streamData "><fa-icon [icon]="faCheckIcon"></fa-icon> {{stream.name[0].text}}</li>
                    </ul>
                </span>

            <br>    
            <button class="btn btn-secondary"  style="margin-left: 1px;" *ngIf="deviceService.deviceNameSelect" [disabled]="newDeviceCreated" (click)=createDeviceFromTemplate(deviceService.selectedTemplate.id,false) type="submit">{{'save.another' | translate}}</button>
            <button class="btn btn-primary"  *ngIf="deviceService.deviceNameSelect"  [disabled]="newDeviceCreated" (click)=createDeviceFromTemplate(deviceService.selectedTemplate.id,true)>{{'save.exit' | translate}}</button>
            <br><br>
            </span>

        </mat-nav-list>

        <mat-nav-list *ngIf="deviceCreate == '2'" class="without-template user-selections">
            <h2>{{'attributes' | translate}}</h2>
            <p *ngIf="!deviceService.attributeSelect">{{'select.availableAttributes.desc' | translate}}</p>
            <ul>
                <li *ngFor="let attribute of deviceService.attributedata"><fa-icon [icon]="faCheckIcon"></fa-icon> {{attribute.name}}</li>
            </ul>

            <h2>{{'events' | translate}}</h2>
            <p *ngIf="!deviceService.eventSelect">{{'select.availableEvents.desc' | translate}}</p>
            <ul>
                <li *ngFor="let event of deviceService.eventdata"><fa-icon [icon]="faCheckIcon"></fa-icon> {{event.name}}</li>
            </ul>

            <h2>{{'commands' | translate}}</h2>
            <p *ngIf="!deviceService.commandSelect">{{'select.availableCommands.desc' | translate}}</p>
            <ul>
                <li *ngFor="let command of deviceService.commanddata"><fa-icon [icon]="faCheckIcon"></fa-icon> {{command.name}}</li>
            </ul>

            <h2>{{'geofences' | translate}}</h2>
            <p *ngIf="!deviceService.geofenceSelect ">{{'select.availableGeofences.desc' | translate}}</p>
            <ul>
                <li *ngFor="let geofence of deviceService.geofenceData"><fa-icon [icon]="faCheckIcon"></fa-icon> {{geofence.name[0].text}}</li>
            </ul>

            <h2>{{'streams' | translate}}</h2>

            <p *ngIf="!deviceService.streamSelect">{{'select.availableConnections.desc' | translate}}
            </p>
            <ul>
                <li *ngFor="let stream of deviceService.streamData"><fa-icon [icon]="faCheckIcon"></fa-icon> {{stream.name[0].text}}</li>
            </ul>

            <br>
            <button *ngIf="deviceService.deviceNameSelect && deviceService.templateNameSelect" [disabled]="deviceCreatedWithoutTemplate" (click)="createDeviceFromScratch()" class="btn btn-primary" >{{'save' | translate}}</button>
            <br><br>
            <div class="success-msg" *ngIf="deviceCreated">
                <h2>{{'device.template.creation' | translate}}</h2>
                <p style="font-size: 14px;"><a title="View Device Details" (click)="viewDevice()">{{'view.device' | translate}}</a> {{'or' | translate}} <a [routerLink]="['/createNewDigitalTwin']" (click)="reset(1); resetRadio('1')">{{'create.new.one' | translate}}</a> </p>
            </div>
            <br><br>
        </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content class="example-sidenav-content">

        <!-- For new device creation -->
        <ng-container>

            <ng-container *ngIf="deviceCreate == '1'">
                <app-description></app-description>
                <app-template-list></app-template-list>
                <app-connection-list></app-connection-list>
            </ng-container>
            <ng-container *ngIf="deviceCreate == '2'">
                <ng-container *ngIf="deviceService.createGeofence">
                    <app-create-geofence></app-create-geofence>
                </ng-container>
                <ng-container *ngIf="deviceService.createAttribute">
                    <app-create-attribute></app-create-attribute>
                </ng-container>
                <ng-container *ngIf="deviceService.createEvent">
                    <app-create-event></app-create-event>
                </ng-container>
                <ng-container *ngIf="deviceService.createCommand">
                    <app-create-command></app-create-command>
                </ng-container>
                <ng-container *ngIf="deviceService.mapLoad">
                    <app-map-component></app-map-component>
                </ng-container>
                <ng-container *ngIf="!deviceService.createResource">
                    <app-description></app-description>
                    <app-attribute-list></app-attribute-list>
                    <app-event-list></app-event-list>
                    <app-command-list></app-command-list>
                    <app-geofence-list></app-geofence-list>
                    <app-connection-list></app-connection-list>
                </ng-container>
            </ng-container>
        </ng-container>
    </mat-sidenav-content>

</mat-sidenav-container>

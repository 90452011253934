import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IotService } from '../service/iot.service';
import { TradingPartnersService } from '../service/tradingPartners.service';
import { faAngleUp, faAngleDown, faTimes, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faToggleOff, faToggleOn, faAngleLeft, faAngleRight, faCheck, faCopy, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';



@Component({
  selector: 'app-code-relation-ship-detail',
  templateUrl: './code-relation-ship-detail.component.html',
  styleUrls: ['./code-relation-ship-detail.component.scss']
})
export class CodeRelationShipDetailComponent implements OnInit {

  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;
  faCloseIcon = faTimes;
  faWarningIcon = faExclamationTriangle;

  failed = faTimes;
  faToggleOffIcon = faToggleOff;
  faToggleOnIcon = faToggleOn;
  faAngleLeftIcon = faAngleLeft;
  faAngleRightIcon = faAngleRight;
  faCheckIcon = faCheck;
  faCopyIcon = faCopy;
  faAngleDoubleLeftIcon = faAngleDoubleLeft;
  faAngleDoubleRightIcon = faAngleDoubleRight;

  codeError;

  tradingPartnerCodeRelationShip;
  createAnother = false;
  supportLanguages = ['en'];
  controlNumberError;
  fromTpCodeLoaded=false;
  toTpCodeLoaded=false;

  isActive = true;

  constructor(public iotService: IotService, private translateService: TranslateService,
    private router: Router, public tpService: TradingPartnersService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }


  ngOnInit(): void {
    let codeRelationshipId=this.tpService.getCodeRelationshipId();
    this.loadTpCodeRelationship(codeRelationshipId);
  }

  loadTpCodeRelationship(codeRelationshipId){
    const that = this;
    that.iotService.getCuiObjResponse().getTPCodeRelationshipById({
      codeRelationshipId: codeRelationshipId
    }).then(function (response) {
      console.log("Trading Partner code relationship loaded successFully, Id: ",codeRelationshipId );
      that.loadFromCode(response.fromCodeId);
      that.loadToCode(response.toCodeId);
      that.tradingPartnerCodeRelationShip=response;

    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

 

  resetData(){
    this.codeError="";
    this.controlNumberError="";
  }

  save() {
    const that = this;
    if (that.tpService.getFromCode() == undefined || that.tpService.getToCode()==undefined) {
      that.tpService.warningMessage="From Code and TO code Value is Required";
    }
    else {
      that.tradingPartnerCodeRelationShip.fromCodeId=that.tpService.getFromCode().id;
      that.tradingPartnerCodeRelationShip.toCodeId=that.tpService.getToCode().id;
      that.iotService.getCuiObjResponse().updateTPCodeRelationship({
        codeRelationshipId:that.tradingPartnerCodeRelationShip.id,
        data: that.tradingPartnerCodeRelationShip
      }).then(function (response) {
        console.log("Trading Partner code relationship updated successFully, Id: ", response.id);
        that.tradingPartnerCodeRelationShip = response;

          that.tpService.createResource = true;
          that.tpService.viewCodeRelationShip = false;
          that.tpService.resetMessage();
        
      }).fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          that.tpService.errorMessage = err.responseJSON.apiMessage;
          console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
        } else {
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
        if (err === null) {
          console.log("Error occured while creating trading partner code relationship");
          that.tpService.warningMessage="Error occured while creating trading partner code relationship";
        }
      });
    }


  }

  toggleActive() {
    this.isActive = !this.isActive;
  }
  goBack() {
    const that=this;
    that.tpService.createResource = true;
    that.tpService.viewCodeRelationShip = false;
    that.tpService.resetMessage();
  }

  activateTPCodeRelationship(codeRelationshipId) {
    const that = this;
    that.iotService.getCuiObjResponse().activateTPCodeRelationship({
      codeRelationshipId: codeRelationshipId
    }).then(function (response) {
      console.log("Trading Partner code relationshipc activated successFully, Id: ",codeRelationshipId );
      that.loadTpCodeRelationship(codeRelationshipId);
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }

  deactivateTPCodeRelationship(codeRelationshipId) {
    const that = this;
    that.iotService.getCuiObjResponse().deactivateTPCodeRelationship({
      codeRelationshipId: codeRelationshipId
    }).then(function (response) {
      console.log("Trading Partner code relationshipc dectivated successFully, Id: ",codeRelationshipId );
      that.loadTpCodeRelationship(codeRelationshipId);
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }

  

  loadFromCode(fromCode){
    const that = this;
    that.iotService.getCuiObjResponse().getTradingPartnerCodeById({
      codeId: fromCode
    }).then(function (response) {

      that.tpService.setFromCode(response);
      that.fromTpCodeLoaded=true;
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }

  loadToCode(toCode){
    const that = this;
    that.iotService.getCuiObjResponse().getTradingPartnerCodeById({
      codeId: toCode
    }).then(function (response) {
      
      that.tpService.setToCode(response);
      that.toTpCodeLoaded=true;
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }

  ngOnDestroy(){
    const that=this;
    that.tpService.setToCode(undefined);
    that.tpService.setFromCode(undefined);
  }

  
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatChipInputEvent } from '@angular/material/chips';
import { TranslateService } from '@ngx-translate/core';
import { IotService } from 'src/app/service/iot.service';
import { SideNavComponent } from 'src/app/side-nav/side-nav.component';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { EcosystemTemplatesService } from '../service/ecosystemTemplates.service';
import { Router } from '@angular/router';
import{RulesService} from '../service/rules.service';
import {CreateRulePolicyComponent} from '../create-rule-policy/create-rule-policy.component';
import {
  faToggleOff,
  faToggleOn,
  faCheck,
  faQuestionCircle,
  faExclamationTriangle,
  faExclamation,
  faCheckCircle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-ecosystem-template-detail',
  templateUrl: './ecosystem-template-detail.component.html',
  styleUrls: ['./ecosystem-template-detail.component.scss'],
})
export class EcosystemTemplateDetailComponent implements OnInit {
  faCheckIcon = faCheck;
  faToggleOffIcon = faToggleOff;
  faToggleOnIcon = faToggleOn;
  faQuestionCircleIcon = faQuestionCircle;
  faWarningIcon = faExclamationTriangle;
  faCriticalIcon = faExclamation;
  faCloseIcon = faTimes;
  
  separatorKeysCodes: number[] = [ENTER, COMMA];
  supportLanguages = ['en'];
  allowedMemberColumns: string[] = ['Member Type', 'Kind'];
  tagClass = 'tagInput';
  ecosystemTemplate;
  ecosystemTemplateId;
  auditFlag = false;
  addRulePolicy=false;
  auditRefreshFlag=true;

  constructor(
    private route: ActivatedRoute,
    private iotService: IotService,
    private sideNavComponent: SideNavComponent,
    private translateService: TranslateService,
    public ecosystemTemplateService: EcosystemTemplatesService,
    private router: Router,public rulesService:RulesService
    // public createRulePolicyComponent: CreateRulePolicyComponent
  ) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      var ecosystemTemplateId = params['id'];
      this.ecosystemTemplateId = ecosystemTemplateId;
      this.iotService.parentTemplateIdForEcosystem = ecosystemTemplateId;
      this.loadEcosystemTemplate(ecosystemTemplateId);
    });
    this.iotService.viewEcosystemTemplate = true;
    this.iotService.viewRulePolicy = false;
  }

  loadEcosystemTemplate(ecosystemTemplateId: any) {
    const that = this;
    that.iotService
      .getCuiObjResponse()
      .getEcosystemTemplateById({
        groupTemplateId: ecosystemTemplateId,
      })
      .then(function (response) {
        console.log(response);
        that.ecosystemTemplate = response;
        if (!that.ecosystemTemplate.tags) {
          that.ecosystemTemplate['tags'] = [];
        }

        that.sideNavComponent.menuClear();
        that.sideNavComponent.menuChange('Dashboard', 'sub-type');
        that.sideNavComponent.menuChange(
          'ecosystemTemplates.header',
          'sub-type'
        );
        that.sideNavComponent.menuChange(
          that.ecosystemTemplate.name[0].text,
          'sub-type'
        );
      })
      .fail(function (err) {
        if (err.responseJSON && err.responseJSON.apiStatusCode) {
          console.log(
            'Error Occured: Status: ' +
              err.responseJSON.status +
              ' Msg: ' +
              err.responseJSON.apiStatusCode
          );
        } else {
          console.log(
            'Something went wrong. Please check chrome console or check with dev team'
          );
        }
      });
  }

  add(event: MatChipInputEvent): void {
    this.tagClass = 'tagInput';
    const input = event.input;
    const value = event.value;
    const index = this.ecosystemTemplate.tags.indexOf(value);
    if (index < 0) {
      if ((value || '').trim()) {
        if (
          this.ecosystemTemplate.tags != undefined &&
          this.ecosystemTemplate.tags.includes(value)
        ) {
          if (input) {
            input.value = '';
          }
        } else {
          this.ecosystemTemplate.tags.push(value);
          this.addTag(value);
          if (input) {
            input.value = '';
          }
        }
      }
    } else {
      this.tagClass = 'tagInput-duplicate';
    }
  }

  remove(tag: string): void {
    const that = this;
    that.ecosystemTemplate.tags.forEach((value, index) => {
      if (value == tag) that.ecosystemTemplate.tags.splice(index, 1);
      that.removeTag(tag);
    });
  }

  addTag(tag) {
    const that = this;
    that.iotService
      .getCuiObjResponse()
      .createEcosystemTemplateTag({
        groupTemplateId: that.ecosystemTemplate.id,
        tag: tag,
      })
      .then(function (response) {
        console.log('Tag added to ecosystem template successfully ' + tag);
      })
      .fail(function (err) {
        that.ecosystemTemplateService.errorMessage = err.responseJSON.apiMessage;
        if (err.responseJSON.apiStatusCode) {
          console.log(
            'Error Occured: Status: ' +
              err.responseJSON.status +
              ' Msg: ' +
              err.responseJSON.apiStatusCode
          );
        } else {
          console.log(
            'Something went wrong. Please check chrome console or check with dev team'
          );
        }
      });
  }

  removeTag(tag) {
    const that = this;
    that.iotService
      .getCuiObjResponse()
      .deleteEcosystemTemplateTag({
        groupTemplateId: that.ecosystemTemplate.id,
        tag: tag,
      })
      .then(function (response) {
        console.log('Tag removed from ecosystem template successfully ' + tag);
      })
      .fail(function (err) {
        that.ecosystemTemplateService.warningMessage = err.responseJSON.apiMessage;
        if (err.responseJSON.apiStatusCode) {
          console.log(
            'Error Occured: Status: ' +
              err.responseJSON.status +
              ' Msg: ' +
              err.responseJSON.apiStatusCode
          );
        } else {
          console.log(
            'Something went wrong. Please check chrome console or check with dev team'
          );
        }
      });
  }

  toggleActive(status) {
    this.ecosystemTemplate.active = status;
    if (this.ecosystemTemplate.active == true) {
      this.deactivateEcosystemTemplate(this.ecosystemTemplate.id);
    } else {
      this.activateEcosystemTemplate(this.ecosystemTemplate.id);
    }
  }

  activateEcosystemTemplate(id: any) {
    const that = this;
    that.iotService
      .getCuiObjResponse()
      .activateEcosystemTemplate({
        qs: [['groupTemplateId', id]],
      })
      .then(function (response) {
        that.loadEcosystemTemplate(id);
        console.log('Ecosystem template activated successfully, Id: ', id);
      })
      .fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          console.log(
            'Error Occured: Status: ' +
              err.responseJSON.status +
              ' Msg: ' +
              err.responseJSON.apiStatusCode
          );
        } else {
          console.log(
            'Something went wrong. Please check chrome console or check with dev team'
          );
        }
      });
  }

  deactivateEcosystemTemplate(id: any) {
    const that = this;
    that.iotService
      .getCuiObjResponse()
      .deactivateEcosystemTemplate({
        qs: [['groupTemplateId', id]],
      })
      .then(function (response) {
        that.loadEcosystemTemplate(id);
        console.log('Eventgroup deactivated successfully, Id: ', id);
      })
      .fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          console.log(
            'Error Occured: Status: ' +
              err.responseJSON.status +
              ' Msg: ' +
              err.responseJSON.apiStatusCode
          );
        } else {
          console.log(
            'Something went wrong. Please check chrome console or check with dev team'
          );
        }
      });
  }

  createRuleFromEcosystemTemplate(ecosystemtemplateId: any) {
   // this.iotService.ecosystemTemplateAddRulePolicy = true;
    // this.router.navigateByUrl(
    //   '/rules/subscriber/ecosystemtemplate/' + this.ecosystemTemplateId
    // );
    this.iotService.ecosystemTemplateId=ecosystemtemplateId;
    this.iotService.ecosystemTemplateAddRulePolicy = true;
    this.iotService.viewRulePolicy=true;
  }

  auditEcosystemTemplate() {
    this.auditFlag = true;
    this.auditRefreshFlag=!this.auditRefreshFlag;

  }
  closeAudit() {
    this.auditFlag = false;
  }

  goBackAuditList() {
    const that = this;
    that.iotService.auditScriptFlag = false;
    that.iotService.auditscript = '';
    that.auditFlag = true;
  }

  ngOnDestroy() {
    this.iotService.viewEcosystemTemplate = false;
    this.iotService.parentTemplateIdForEcosystem = '';
    this.auditFlag = false;
    this.iotService.auditScriptFlag = false;
    this.iotService.auditscript = '';
    this.ecosystemTemplateService.resetErrorMessage();
    this.ecosystemTemplateService.resetWarningMessage();
    this.iotService.ecosystemTemplateAddRulePolicy = false;
    this.iotService.viewRule =false;
    this.addRulePolicy=false;
    this.iotService.viewRulePolicy=false;
  }
}

import { Injectable } from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import { IotService } from '../service/iot.service'; 
import { ListTrustedPublicKeysService } from './ListTrustedPublicKeys.service';

@Injectable({ 
	  providedIn: 'root' 
})

export class TrustedPublicKeysService extends ListTrustedPublicKeysService{
	 
	 constructor(public iotService:IotService) {
		 super(iotService);
	 }
	 
	 fnOnSelectionSupport(results){ 
		 const that = this; 
		 alert('Implement this method'); 
	 } 
	 
	 fnRemoveSelectionSupport(results){ 
		 const that = this; 
		 alert('Implement this method'); 
	 } 
	 
} 

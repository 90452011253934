import { hasLifecycleHook } from '@angular/compiler/src/lifecycle_reflector';
import { Component, OnInit,Output,EventEmitter } from '@angular/core';
import { IotService } from '../../service/iot.service';
import { DeviceService } from '../../service/device.service';
import { faAngleDoubleLeft, faAngleDoubleRight,faToggleOff,faToggleOn,faCheck} from '@fortawesome/free-solid-svg-icons';
import {SelectionModel} from '@angular/cdk/collections';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-attribute-list',
  templateUrl: './attribute-list.component.html',
  styleUrls: ['./attribute-list.component.scss']
})
export class AttributeListComponent implements OnInit {


    dataSource: any;
    displayedColumns: string[] = [' ','Name', 'Description', 'Tags', 'DataType', 'Enabled'];
    faAngleDoubleLeftIcon = faAngleDoubleLeft;
    faAngleDoubleRightIcon = faAngleDoubleRight;
    faToggleOffIcon = faToggleOff;
    faToggleOnIcon = faToggleOn;
    faCheckIcon = faCheck;
    attributeSelected:string;
  
   
    attributeId;
    isExpanded = true;
    test="hhello";
    showSubmenu: boolean = false;
    isShowing = false;
    showSubSubMenu: boolean = false;
    pageSize = 5;
    currentPage =1;
    searchText;
    attributes = [];
    associations = [];
    attribute = [];
    showValue = false;
    private role: string;
    showSelect = true;
    HighlightRow : Number;
    display = "Select";
    selectedAttributes=[];
    isBoxChecked:boolean=false;
    supportLanguages = ['en'];

    selection: SelectionModel<Element> = new SelectionModel<Element>(true, []);
  
   /*  pageListOptions: number[] = [1];
    totalPages=0;
    firstPageElement:number=1;
    lastPage:boolean=true;
    firstPage:boolean=true;
    defaultPages = 10; */
    attributeCount:number=0;
    showPagination=false;
    constructor(private iotService:IotService,private deviceService:DeviceService,private translateService: TranslateService) { 
      this.translateService.addLangs(this.supportLanguages);
      this.translateService.setDefaultLang('en');
    }
  
    ngOnInit(): void {
      this.getAttributes();
      if(this.deviceService.getAttributeData()!=undefined){
        this.deviceService.getAttributeData().forEach((value,index)=>{
          this.selectedAttributes.push(value);
         });
         this.selection=new SelectionModel<Element>(true, this.selectedAttributes);
      }
      this.selection.isSelected = this.isChecked.bind(this);
      this.countAttributeTypes('');
    }

  
    isChecked(row: any): boolean {
      const found = this.selection.selected.find(el => el.id === row.id);
      if (found) {
        return true;
      }
      return false;
   }


    onAttributeSearch(event: any){
        this.attributeSelected = event.target.value;
        let name = '*'+event.target.value+'*';
        const that = this;
        that.countAttributeTypes(name);
        that.currentPage =1;
        that.iotService.getCuiObjResponse().getAttributeTypes({
          qs:[['pageSize',5],['page',that.currentPage],['name',name]]
        }).then(function(response) {
          // console.log(response);
          that.attributes = response;
          that.dataSource = that.attributes;
        }).fail(function (err){
          if(err.responseJSON.apiStatusCode){
            console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
          } else{
            console.log("Something went wrong. Please check chrome console or check with dev team");
          } 
        });
    }
  
    getAttributes(){
      const that = this;
      console.log("currentPage : "+that.currentPage);
      this.iotService.getCuiObjResponse().getAttributeTypes({
        qs:[['pageSize',5],['page',that.currentPage]]
      }).then(function(response) {
        // console.log(response);
        that.attributes = response;
        that.dataSource = that.attributes;
      }).fail(function (err){
        if(err.responseJSON.apiStatusCode){
          console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
        } else{
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    }

  
    OnSelection(event,attributeId){
      
      const that = this;
      console.log(event); //true or false
     
      that.iotService.getCuiObjResponse().getAttributeType({
        attributeTypeId: attributeId
      }).then(function(response) {
        // console.log(response);
        
        if(event === true){  
          that.selection.select(response);
          that.deviceService.attributeSelect=true;
          that.selectedAttributes.push(response);
        }else{
          that.selection.clear();
          that.selectedAttributes.forEach((value,index)=>{
            if(value.id==attributeId) that.selectedAttributes.splice(index,1);

        });
        that.selectedAttributes.forEach((value,index)=>{ 
          that.selection.select(value);
        });
        }
        that.deviceService.setAttributeData(that.selectedAttributes);
        if(that.deviceService.getAttributeData()!=undefined && that.deviceService.getAttributeData().length<1){
          that.deviceService.attributeSelect=false;
        }
    
      }).fail(function (err){
        if(err.responseJSON.apiStatusCode){
          console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
        } else{
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    }
  
    getSelectedAttributeForCurrentPage(attributeSelected){
      this.attributeSelected = attributeSelected;
      let name = '*'+attributeSelected+ '*';
      const that = this;
      this.iotService.getCuiObjResponse().getAttributeTypes({
        qs:[['pageSize',5],['page',this.currentPage],['name',name]]
      }).then(function(response) {
        // console.log(response);
        that.attributes = response;
        that.dataSource = that.attributes;
      }).fail(function (err){
        if(err.responseJSON.apiStatusCode){
          console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
        } else{
          console.log("Something went wrong. Please check chrome console or check with dev team");
        } 
      });
    }

    enableAttribute(attributeId : any){
      const that = this;
      this.iotService.getCuiObjResponse().activateAttributeType({
        attributeTypeId: attributeId
      }).then(function(response) {
        // console.log(response);
        if(that.attributeSelected){
          that.getSelectedAttributeForCurrentPage(that.attributeSelected)
        }
        else{        
           that.getAttributes();
        }
      }).fail(function (err){
        if(err.responseJSON.apiStatusCode){
          var errorStr=err.responseJSON.apiMessage;
          var error = [];
          error=errorStr.split(":");
        if(err.responseJSON.apiStatusCode=="framework:resource:attributeType:duplicate"){
          var attributeId = error[1].trim();
        that.deviceService.duplicateAttributeId = attributeId
        that.deviceService.duplicateAttrFlag = true;
        that.deviceService.duplicateCommandFlag=false;
        that.deviceService.duplicateEventFlag=false;

        that.deviceService.warningMessage="Attribute with same name is active."
          console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
        } 
      }else{
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    }
  
    disableAttribute(attribute :any,attributeId:any){
      const that = this;
      this.iotService.getCuiObjResponse().deactivateAttributeType({
        attributeTypeId: attributeId
      }).then(function(response) {
        // console.log(response);
        // if(that.isChecked(attribute)){
        //   that.selection.toggle(attribute ) ;
        // }
        that.selection.clear();
        that.selectedAttributes.forEach((value,index)=>{
          if(value.id==attributeId) that.selectedAttributes.splice(index,1);
        that.deviceService.setAttributeData(that.selectedAttributes);
       });
       that.selectedAttributes.forEach((value,index)=>{
         that.selection.select(value);
     });
       if(that.deviceService.getAttributeData()!=undefined&& that.deviceService.getAttributeData().length<1){
        that.deviceService.attributeSelect=false;
      }
        if(that.attributeSelected){
          that.getSelectedAttributeForCurrentPage(that.attributeSelected)
        }
        else{        
           that.getAttributes();
        }
      }).fail(function (err){
        if(err.responseJSON.apiStatusCode){
          console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
        } else{
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    }

    createAttribute(){
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.deviceService.createResource=true;
      this.deviceService.createAttribute=true;
    }

    countAttributeTypes(name) {
      const that = this;
      that.showPagination=false;
      if(name === "**") {
        name = '';
      }
      if(name === '') {
        that.iotService.getCuiObjResponse().countAttributeTypes({
        }).then(function (response) {
          console.log(response);
          that.attributeCount = response;
      //    that.getCountPages();
          that.showPagination=true;
        }).fail(function (err) {
          if (err.responseJSON.apiStatusCode) {
            console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
          } else {
            console.log("Something went wrong. Please check chrome console or check with dev team");
          }
        });
      } else {
        that.iotService.getCuiObjResponse().countAttributeTypes({
          qs:[['name',name]]
        }).then(function (response) {
          console.log(response);
          that.attributeCount = response;
      //    that.getCountPages();
          that.showPagination=true;
        }).fail(function (err) {
          if (err.responseJSON.apiStatusCode) {
            console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
          } else {
            console.log("Something went wrong. Please check chrome console or check with dev team");
          }
        });
      }
    }

    onPageChange(page) {
      const that = this;
      console.log("page : "+page);
      that.currentPage = page;
      
      if(this.attributeSelected) {
        this.getSelectedAttributeForCurrentPage(this.attributeSelected);
      } else {
        this.getAttributes();
      }
    }

    /* getCountPages() {
      const that = this;
      that.totalPages = Math.ceil(that.attributeCount / that.pageSize);
      that.firstPageElement = 1;

      console.log("******** getCountPages *********");
      console.log("totalPages : " + that.totalPages);
      if (that.totalPages == 0) {
        that.firstPageElement = 1;
        that.pageListOptions = [1];
        that.lastPage = true;
        that.firstPage = true;
      } else if (that.totalPages > that.defaultPages) {
        that.pageListOptions = Array.from({length:that.defaultPages},(v,k)=>that.firstPageElement++);
        that.lastPage = false;
        that.firstPage = true;
      } else {
        that.pageListOptions = Array.from({length:that.totalPages},(v,k)=>that.firstPageElement++);
        that.lastPage = true;
        that.firstPage = true;
      }
      console.log("that.pageListOptions : "+that.pageListOptions);
    }
  
  

    prevPage() {
      const that = this;
      that.lastPage=false;
      
      console.log("******** prevPage *********");
      console.log("that.firstPageElement : "+that.firstPageElement);
      console.log("that.totalPages : "+that.totalPages);
  
      if(that.firstPageElement > that.totalPages) {
        that.firstPageElement = that.firstPageElement-that.defaultPages-(that.totalPages % that.defaultPages);
      } else {
        that.firstPageElement = that.firstPageElement-(2*that.defaultPages);
      }
  
      if (that.firstPageElement < 1) {
        that.firstPageElement = 1;
        that.firstPage = true;
      } else if(that.firstPageElement === 1) {
        that.firstPage = true;
      }
      that.pageListOptions = Array.from({length:that.defaultPages},(v,k)=> that.firstPageElement++);
  
      console.log("that.pageListOptions : "+that.pageListOptions);
    }
  
    nextPage() {
      const that = this;
      that.firstPage = false;
      console.log("that.totalPages : "+that.totalPages);
      console.log("that.firstPageElement : "+that.firstPageElement);
      var last = that.totalPages - that.firstPageElement;
     
      console.log("last : "+last);
      if(last < 0) {
        that.firstPageElement = 1;
        that.firstPage = true;
        that.pageListOptions = Array.from({length:that.defaultPages},(v,k)=> that.firstPageElement++);
      } else {
        if(last < that.defaultPages) {
          that.pageListOptions = Array.from({length:last+1},(v,k)=> that.firstPageElement++);
          that.lastPage = true;
        } else {
          that.pageListOptions = Array.from({length:that.defaultPages},(v,k)=> that.firstPageElement++);
          that.lastPage = false;
        }  
      }
      console.log("that.pageListOptions : "+that.pageListOptions);
    } */

  }
  
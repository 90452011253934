import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IotService } from '../service/iot.service';
import { TradingPartnersService } from '../service/tradingPartners.service';
import { faAngleUp, faAngleDown, faTimes, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faToggleOff, faToggleOn, faAngleLeft, faAngleRight, faCheck, faCopy, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-new-trading-partner-code',
  templateUrl: './new-trading-partner-code.component.html',
  styleUrls: ['./new-trading-partner-code.component.scss']
})
export class NewTradingPartnerCodeComponent implements OnInit {

  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;
  faCloseIcon = faTimes;
  faWarningIcon = faExclamationTriangle;
  failed = faTimes;
  faToggleOffIcon = faToggleOff;
  faToggleOnIcon = faToggleOn;
  faAngleLeftIcon = faAngleLeft;
  faAngleRightIcon = faAngleRight;
  faCheckIcon = faCheck;
  faCopyIcon = faCopy;
  faAngleDoubleLeftIcon = faAngleDoubleLeft;
  faAngleDoubleRightIcon = faAngleDoubleRight;

  codeError;
  warningMessage;
  tradingPartnerCode;
  createAnother = false;
  supportLanguages = ['en'];

  isActive = false;

  constructor(public iotService: IotService, private translateService: TranslateService,
    private router: Router, public tpService: TradingPartnersService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }


  ngOnInit(): void {
    this.initTpcode();
  }


  initTpcode() {
    const that = this;

    this.tradingPartnerCode = {

      "creator": that.iotService.getCreator(),
      "creatorAppId": that.iotService.getCreatorAppId(),
      "realm": that.iotService.getRealm(),
      "code": "",
      "qualifier": "",
      "tradingPartnerId": that.tpService.tradingPartnerId,
      "description": [{
        "lang": "en_us",
        "text": ""
      }]
    }
  }

  resetData(){
    this.codeError="";
  }

  save() {
    const that = this;
    if (that.tradingPartnerCode.code == "" || that.tradingPartnerCode.code==undefined) {
      that.codeError="Code Value is Required";
    }
    else {
      that.iotService.getCuiObjResponse().createTradingPartnerCode({
        data: that.tradingPartnerCode
      }).then(function (response) {
        console.log("Trading Partner code created successFully, Id: ", response.id);
        that.tradingPartnerCode = response;
        if (that.isActive) {
          that.activateTradingPartnercode(response.id);
        }
        if (!that.createAnother&&!that.isActive) {
          that.tpService.createResource = true;
          that.tpService.createTpCode = false;
          that.tpService.resetMessage();
        }
        else {
          that.ngOnInit();
        }
      }).fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          that.tpService.errorMessage = err.responseJSON.apiMessage;
          console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
        } else {
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
        if (err === null) {
          console.log("Error occured while creating trading partner code");
        }
      });
    }


  }

  toggleActive() {
    this.isActive = !this.isActive;
  }
  goBack() {
    this.tpService.createResource = true;
    this.tpService.createTpCode = false;
    this.tpService.resetMessage();
  }

  activateTradingPartnercode(codeId) {
    const that = this;
    that.iotService.getCuiObjResponse().activateTradingPartnerCodes({
      codeId: codeId
    }).then(function (response) {
      console.log("Trading Partner code activated successFully, Id: ", codeId);
      if (!that.createAnother) {
        that.tpService.createResource = true;
        that.tpService.createTpCode = false;
        that.tpService.resetMessage();
      }
      else {
        that.ngOnInit();
      }
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.tpService.warningMessage = err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
      if (err === null) {
        console.log("Error occured while creating trading partner code");
      }
    });

  }


}

<mat-sidenav-container class="new-export" autosize>



    <mat-sidenav-content class="example-sidenav-content">
        <div class="export-data">

            <div class="form-data" *ngIf="downloadLogInfo">
                <div>
                    <div>
                        <form>
                            <div>
                                <label>{{'job.id'| translate}}</label>
                                <div>
                                    <input name="downloadLogInfo.getLogsJobId"
                                        [(ngModel)]="downloadLogInfo.getLogsJobId" type="text" placeholder="" autoFocus>
                                </div>
                                <p *ngIf="downloadLogError" style="color: red;">{{downloadLogError}}</p>
                            </div><br>
                            <div>
                                <div>
                                    <button class="btn btn-primary" style="margin-left: 3px;" (click)="save(downloadLogInfo)"
                                        [disabled]="downloadLogInfo.getLogsJobId==''">{{'download.logs' | translate}}<i
                                            [hidden]="!logsworking" style="margin-left:2px"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div [hidden]="!showLogTab" id="logdata" class="" style="overflow-x: auto; white-space: nowrap;">
                    <div>
                        <ul>
                            <li *ngFor="let logdata of logdatas">
                                {{logdata}}
                            </li>
                        </ul>
                    </div>
                </div>



            </div>
        </div>
    </mat-sidenav-content>

</mat-sidenav-container>
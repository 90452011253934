<mat-sidenav-container class="mid-sidenav" autosize  style="height: 450vh;">
    <mat-sidenav class="midNav-container" #sidenav mode="side" disableClose="true" opened="true">
        <div class="alert critical" *ngIf="ecosystemService.errorMessage">
            <h5>
                <fa-icon [icon]="faCriticalIcon"></fa-icon>
                <span>{{ecosystemService.errorMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=ecosystemService.resetErrorMessage() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>
        <div class="alert warning" *ngIf="ecosystemService.warningMessage">
            <h5 style="font-size: 12px;">
                <fa-icon [icon]="faWarningIcon"></fa-icon>
                <span>{{ecosystemService.warningMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)="ecosystemService.resetWarningMessage()" [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>
        
        <h1 *ngIf="!iotService.createEcosystemFromTemplate">{{'create.ecosystem' | translate}}</h1>
        <h1 *ngIf="iotService.createEcosystemFromTemplate">{{'create.ecosystem.from.template' | translate}}</h1>

        <mat-nav-list class="without-template user-selections" *ngIf="!iotService.createEcosystemFromTemplate">
            <h2>{{'ecosystem.template' | translate}}</h2>
            <p *ngIf=!ecosystemService.selectedEcosystemTemplate>
                {{'select.availableTemaplate.desc' | translate}}
            </p>
            <ng-container *ngIf="ecosystemService.selectedEcosystemTemplate">
                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{ ecosystemService.selectedEcosystemTemplate }}
                    </li>
                </ul>
            </ng-container>

            <ng-container *ngIf="ecosystemService.selectedAttributes.length>0">
                <h2>{{'attributes' | translate}}</h2>
                <ul>
                    <li *ngFor="let attribute of ecosystemService.selectedAttributes">
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{ attribute }}
                    </li>
                </ul>
            </ng-container>

            <ng-container *ngIf="ecosystemService.selectedEvents.length>0">
                <h2>{{'events' | translate}}</h2>
                <ul>
                    <li *ngFor="let event of ecosystemService.selectedEvents">
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{event}}
                    </li>
                </ul>
            </ng-container>

            <ng-container *ngIf="ecosystemService.selectedMembers.length>0">
                <h2>{{'members.allowed' | translate}}</h2>

                <table mat-table [dataSource]="ecosystemService.selectedMembers">
                    <ng-container matColumnDef="Member Type">
                        <th class="name-cell" mat-cell *matHeaderCellDef>
                            {{'name' | translate}}
                        </th>
                        <td class="name-cell" mat-cell *matCellDef="let selectedMember">
                            {{selectedMember.type}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Kind">
                        <th class="name-cell" mat-cell *matHeaderCellDef>
                            {{'ecosystemMembers.kind' | translate}}
                        </th>
                        <td class="name-cell" mat-cell *matCellDef="let selectedMember">
                            {{selectedMember.kind}}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="selectedMemberColumns"></tr>
                    <tr mat-row *matRowDef="let selectedMember; columns: selectedMemberColumns;" class="bg-message">
                    </tr>
                </table>

            </ng-container>

        </mat-nav-list>
    </mat-sidenav>

    <div class="container-data" *ngIf="ecosystemService.createResource">
        <div class=form-data>
            <label for="ecosystemName">{{ 'name' | translate}}</label>
            <input *ngIf="!iotService.createEcosystemFromTemplate" placeholder="required" id="ecosystemName" type="text"
                value="" (keyup)="ecosystemNameInput($event)" aria-label="Name" [(ngModel)]="ecosystem.name[0].text">
            <input *ngIf="iotService.createEcosystemFromTemplate" id="ecosystemName" type="text"
                value="" (keyup)="ecosystemNameInput($event)" aria-label="Name" [(ngModel)]="ecosystem.name[0].text">
            <p *ngIf="errorInName" style="color: red;">{{errorInName}}</p>

            <label for="ecosystemDescription">{{ 'description' | translate}}</label>
            <textarea placeholder="optional" id="ecosystemDescription" aria-label="Description"
                [(ngModel)]="ecosystem.description[0].text"></textarea>

            <label for="ecosystemTags">{{ 'tags' | translate}}</label>
            <mat-chip-list #tagList aria-label="Tags">
                <mat-chip class="ot-chip" *ngFor="let tag of tags" [selectable]="true" [removable]="true"
                    (removed)="remove(tag)">
                    {{ tag }}
                    <button class="ot-chip__close" matChipRemove></button>
                </mat-chip>
                <input [ngClass]="tagClass" placeholder="add a tag" #tagInput [matChipInputFor]="tagList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                    (matChipInputTokenEnd)="add($event)" />
            </mat-chip-list>
        </div>

        <ng-container *ngIf="!iotService.createEcosystemFromTemplate">
            <app-ecosystemTemplates [pageSize]="5" [showSelectColoumn]="true" [showEnableColumn]=true
                [showControlBar]="true" [showNew]="false"
                [displayedColumns]="['ADD', 'name', 'description', 'allowedMemberTypes', 'ACTIVE']" multiSelect="false" [showHeaderToolTips]=false>
            </app-ecosystemTemplates>
            <div>
                <button [disabled]="!ecosystemService.selectedEcosystemTemplate || ecosystem.name[0].text=='' || ecosyStemCreated" 
                    class="btn btn-primary pull-right" style="margin-right:15px;margin-top:20px;" (click)="save()">{{ 'save' | translate}}</button>
            </div>
        </ng-container>

        <ng-container *ngIf="iotService.createEcosystemFromTemplate">
            <app-ecosystemAttributes [pageSize]="5" [showControlBar]=false
                [displayedColumns]="['name', 'description', 'type','isActive'] " [showSelectColoumn]=true [showEnableColumn]=true
                addnQueryParam=[ecosystemTemplateId::{{ecosystemTemplateId}}] [showHeaderToolTips]=false>
            </app-ecosystemAttributes>

            <app-ecosystemEvents [pageSize]="5" [showControlBar]=false
                [displayedColumns]="['name', 'description', 'isActive']" [showSelectColoumn]=true
                [showEnableColumn]=true addnQueryParam=[ecosystemTemplateId::{{ecosystemTemplateId}}] [showHeaderToolTips]=false>
            </app-ecosystemEvents>

            <app-ecosystemMembers [pageSize]="5" [showControlBar]=false [displayedColumns]="['type', 'kind']"
                [showSelectColoumn]=true [showEnableColumn]=true
                addnQueryParam=[ecosystemTemplateId::{{ecosystemTemplateId}}] [showHeaderToolTips]=false>
            </app-ecosystemMembers>

            <app-ecosystemTemplateRules [pageSize]="5" [showControlBar]=false
                        [displayedColumns]="['sourceEventKey','ruleName','sourceEventName','creation']"
                        [showSelectColoumn]=true [showEnableColumn]=true
                        addnQueryParam=[ecosystemTempId::{{ecosystemTemplateId}}] [showHeaderToolTips]=false>
            </app-ecosystemTemplateRules>

            <div class="button-row pull-right">
                <button class="btn btn-secondary" (click)="goBackToTemplate()">{{ 'go.back' | translate}}</button>
                <button class="btn btn-primary" [disabled]="ecosystem.name[0].text==''" (click)="save()">{{ 'save' | translate}}</button>
            </div>
        </ng-container>
    </div>

</mat-sidenav-container>
/**
 * This is an auto generated code for toTPCodes listing page.
 * Please don't make any change to this file.
 * @version 1.0.0
 * @readonly
 * @author - System generated
 */

import { Component, OnInit, ViewChild, Injector, Inject} from '@angular/core'
import { IotService } from '../service/iot.service'
import { ToTPCodesService } from '../service/toTPCodes.service'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { faCaretUp, faCaretDown, faInfoCircle, faQuestionCircle, faArrowUp, faArrowDown, faSearch } from '@fortawesome/free-solid-svg-icons'
import { SideNavComponent } from '../side-nav/side-nav.component'
import { BaseListComponent } from '../base-list/baselist.component'

interface TypeChange {
	value: string;
	viewValue: string;
}
@Component({
	selector: 'app-toTPCodes',
	templateUrl: './toTPCodes.component.html',
	styleUrls: ['../base-list/baselist.component.scss','./toTPCodes.component.scss']
})
export class ToTPCodesComponent extends BaseListComponent {
	searchCriteria="qualifier";
	displayedColumns: string[] = ['qualifier','code','creation'];
	sortColumns: string[] = ['qualifier','creation'];
	pageTitle="toTPCodes.header";
	ascQualifier:boolean = false;
	descQualifier:boolean = false;
	ascCreation:boolean = false;
	descCreation:boolean = false;
	countCallCompleted:boolean = false;
	getCallCompleted:boolean = false;
	showEnableColumn = true;
	toTPCodesService:any;

	constructor( router: Router,
				iotService: IotService,
				translateService: TranslateService,
				sideNavComponent:SideNavComponent,
				@Inject('toTPCodesService1') public firstToTPCodesService: ToTPCodesService,
				@Inject('toTPCodesService2') public secondToTPCodesService: ToTPCodesService){
				super(router, iotService, translateService, sideNavComponent )
						this.translateService.addLangs(this.supportLanguages);
						this.translateService.setDefaultLang('en');
	}

	ngOnInit(): void {
		super.ngOnInit();
		if(this.instance=="1"){
			this.toTPCodesService=this.firstToTPCodesService;
		}
		else if(this.instance=="2"){
			this.toTPCodesService=this.secondToTPCodesService;
		}
		else{
			this.toTPCodesService=this.firstToTPCodesService;
		}
		this.toTPCodesService.selection.clear();
		this.toTPCodesService.instanceValue = this.instance;
		this.toTPCodesService.multiSelectRows = this.multiSelect;
		this.toTPCodesService.loadSelected();
		if(!this.showSelectColoumn){
			this.sideNavComponent.menuClear();
			this.sideNavComponent.menuChange('Dashboard','sub-type');
			this.sideNavComponent.menuChange('toTPCodes.header','sub-type');
		}
	}
	getFilteredResults(value, columnName , sortOrder){
		const that = this;
		that.iotService.getCuiObjResponse().getToTPCodes({
			qs: eval(that.fnGetQS(value, columnName, sortOrder))
		}).then(function (response) {
			that.fnSuccess(response);
			if(columnName == 'none' || columnName == '' || columnName == undefined){
				that.fnResetSortParam('creation', 'desc');
			}else{
				that.fnResetSortParam(columnName, sortOrder);
			}
			that.getCallCompleted = true;
		}).fail(function (err) {
			that.fnError(err);
			that.getCallCompleted = true;
		});
	}
	getCount() {
		const that = this;
		that.showPagination=false;
		let value = this.selected;
		that.iotService.getCuiObjResponse().countToTPCodes({
			qs: eval(that.fnGetQS(value, '', ''))
		}).then(function (response) {
				that.totalCount = response;
				that.countCallCompleted = true;
			if(that.totalCount > 0)
				that.showPagination=true;
			}).fail(function (err) {
				that.fnError(err);
				that.countCallCompleted = true;
			});
	}
	activateToTPCodes(resourceId){
		const that = this;
		that.iotService.getCuiObjResponse().activateToTPCodes({
			tpCodeId: resourceId
		}).then(function (response) {
			that.getFilteredResults(that.selected, that.sortCriteria, that.sortDirection);
			that.toTPCodesService.fnResetList();
		}).fail(function (err) {
			that.fnError(err);
		});
	}
	deactivateToTPCodes(resourceId){
		const that = this;
		that.iotService.getCuiObjResponse().deactivateToTPCodes({
			tpCodeId: resourceId
		}).then(function (response) {
			that.getFilteredResults(that.selected, that.sortCriteria, that.sortDirection);
			that.toTPCodesService.fnResetList();
		}).fail(function (err) {
			that.fnError(err);
		});
	}
	deleteToTPCodes(resourceId){
		const that = this;
		that.iotService.getCuiObjResponse().deleteToTPCodes({
			tpCodeId: resourceId
		}).then(function (response) {
			that.getCount();
			that.getFilteredResults(that.selected, that.sortCriteria, that.sortDirection);
			that.toTPCodesService.fnResetList();
		}).fail(function (err) {
			that.fnError(err);
			that.toTPCodesService.showErrorMessage(err);
		});
	}
	createNew(){
		this.toTPCodesService.createNew();
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}
	onSelection(results){
		this.toTPCodesService.onSelection(results);
	}
	removeSelection(results){
		this.toTPCodesService.removeSelection(results);
	}
	changeActive(resourceId, isActivate){
		if(isActivate == 'true'){
			this.deactivateToTPCodes(resourceId);
		}else{
			this.activateToTPCodes(resourceId);
		}
	}
}

/**
 * This is an auto generated code for trustedCertificates listing page.
 * Please don't make any change to this file.
 * @version 1.0.0
 * @readonly
 * @author - System generated
 */

import { Component, OnInit, ViewChild, Injector, Inject} from '@angular/core'
import { IotService } from '../service/iot.service'
import { TrustedCertificatesService } from '../service/trustedCertificates.service'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { faCaretUp, faCaretDown, faInfoCircle, faQuestionCircle, faArrowUp, faArrowDown, faSearch } from '@fortawesome/free-solid-svg-icons'
import { SideNavComponent } from '../side-nav/side-nav.component'
import { BaseListComponent } from '../base-list/baselist.component'

interface TypeChange {
	value: string;
	viewValue: string;
}
@Component({
	selector: 'app-trustedCertificates',
	templateUrl: './trustedCertificates.component.html',
	styleUrls: ['../base-list/baselist.component.scss','./trustedCertificates.component.scss']
})
export class TrustedCertificatesComponent extends BaseListComponent {
	searchCriteria="subject";
	displayedColumns: string[] = ['subject','issuerHash','serial','isRevoked','creation'];
	sortColumns: string[] = ['creation'];
	pageTitle="trustedCertificates.header";
	ascCreation:boolean = false;
	descCreation:boolean = false;
	countCallCompleted:boolean = false;
	getCallCompleted:boolean = false;

	constructor( router: Router,
				iotService: IotService,
				translateService: TranslateService,
				sideNavComponent:SideNavComponent,
				public trustedCertificatesService:TrustedCertificatesService) {
				super(router, iotService, translateService, sideNavComponent )
						this.translateService.addLangs(this.supportLanguages);
						this.translateService.setDefaultLang('en');
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.trustedCertificatesService.multiSelectRows = this.multiSelect;
		this.trustedCertificatesService.loadSelected();
		if(!this.showSelectColoumn){
			this.sideNavComponent.menuClear();
			this.sideNavComponent.menuChange('Dashboard','sub-type');
			this.sideNavComponent.menuChange('certificates.header','sub-type');
			this.sideNavComponent.menuChange('trustedCertificates.header','sub-type');
		}
	}
	getFilteredResults(value, columnName , sortOrder){
		const that = this;
		that.iotService.getCuiObjResponse().getx509Certificates({
			qs: eval(that.fnGetQS(value, columnName, sortOrder))
		}).then(function (response) {
			that.fnSuccess(response);
			if(columnName == 'none' || columnName == '' || columnName == undefined){
				that.fnResetSortParam('creation', 'desc');
			}else{
				that.fnResetSortParam(columnName, sortOrder);
			}
			that.getCallCompleted = true;
		}).fail(function (err) {
			that.fnError(err);
			that.getCallCompleted = true;
		});
	}
	getCount() {
		const that = this;
		that.showPagination=false;
		let value = this.selected;
		that.iotService.getCuiObjResponse().countX509Certificates({
			qs: eval(that.fnGetQS(value, '', ''))
		}).then(function (response) {
				that.totalCount = response;
				that.countCallCompleted = true;
			if(that.totalCount > 0)
				that.showPagination=true;
			}).fail(function (err) {
				that.fnError(err);
				that.countCallCompleted = true;
			});
	}
	viewDetails(selectedId){
		this.router.navigateByUrl("/trustedCertificates/"+selectedId);
		this.trustedCertificatesService.viewDetails(selectedId);
	}
	createNew(){
		this.trustedCertificatesService.createNew();
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}
	onSelection(results){
		this.trustedCertificatesService.onSelection(results);
	}
	removeSelection(results){
		this.trustedCertificatesService.removeSelection(results);
	}
}

import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Event } from '@angular/router';
import { IBreadCrumb } from './breadcrumb.interface';
import { filter, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  public breadcrumbs: IBreadCrumb[]

  bcrumbs = [];

  @Input() resourceName:string = "";

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    //this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
  }

  ngOnInit(): void {
    // this.router.events.pipe(
    //     filter((event: Event) => event instanceof NavigationEnd),
    //     distinctUntilChanged(),
    // ).subscribe(() => {
    //     this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
    // })
    console.log("######################################");
    console.log(this.resourceName);
 		 this.activatedRoute.data.subscribe(d => {
       console.log(d.breadcrumbs) // ['home']
       for (let key of Object.keys(d.breadcrumbs)) {
         const breadcrumb: IBreadCrumb = {
             label: key,
             url: d.breadcrumbs[key],
         };
         console.log(breadcrumb);
         this.bcrumbs.push(breadcrumb);
       }
       if(this.resourceName != undefined && this.resourceName != ""){
         const breadcrumb: IBreadCrumb = {
             label: this.resourceName,
             url: '',
         };
         this.bcrumbs.push(breadcrumb);
       }
       console.log(this.bcrumbs);
     })
  }

  buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadCrumb[] = []): IBreadCrumb[] {
    let label = route.routeConfig && route.routeConfig.data ? route.routeConfig.data.breadcrumb : "";
    let path = route.routeConfig && route.routeConfig.data ? route.routeConfig.path : "";

    // If the route is dynamic route such as ':id', remove it
    const lastRoutePart = path.split('/').pop();
    const isDynamicRoute = lastRoutePart.startsWith(':');
    if(isDynamicRoute && !!route.snapshot) {
      const paramName = lastRoutePart.split(':')[1];
      path = path.replace(lastRoutePart, route.snapshot.params[paramName]);
      label = route.snapshot.params[paramName];
    }

    //In the routeConfig the complete path is not available,
    //so we rebuild it each time
    const nextUrl = path ? `${url}/${path}` : url;

    const breadcrumb: IBreadCrumb = {
        label: label,
        url: nextUrl,
    };
    // Only adding route with non-empty label
    const newBreadcrumbs = breadcrumb.label ? [ ...breadcrumbs, breadcrumb ] : [ ...breadcrumbs];
    if (route.firstChild) {
        //If we are not on our current path yet,
        //there will be more children to look after, to build our breadcumb
        return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DevicesComponent } from './devices/devices.component';
import { TemplatesComponent } from './templates/templates.component';
import { GettingStartedComponent } from './getting-started/getting-started.component';
import { DeviceDetailComponent } from './device-detail/device-detail.component';
import { MidSideNavComponent } from './mid-side-nav/mid-side-nav.component';
import { DescriptionComponent } from './mid-side-nav/description/description.component';
import { TemplateListComponent } from './mid-side-nav/template-list/template-list.component';
import { PerfmatrixComponent } from './perfmatrix/perfmatrix.component';
import { ConnectionListComponent } from './mid-side-nav/connection-list/connection-list.component';
import { CreateDeviceTemplateComponent } from './new-device-template/new-device-template.component';
import { TemplateDetailComponent } from './template-detail/template-detail.component';
import { ApplicationsComponent } from './applications/applications.component';
import { CreateApplicationComponent } from './create-application/create-application.component';
import { CertificatesComponent } from './certificates/certificates.component';
import { NewTrustedAuthorityComponent } from './certificates/new-trusted-authority/new-trusted-authority.component';
import { NewTrustedCertificateComponent } from './certificates/new-trusted-certificate/new-trusted-certificate.component';
import { TrustedAuthoritiesComponent } from './trustedAuthorities/trustedAuthorities.component';
import { TrustedCertificatesComponent } from './trustedCertificates/trustedCertificates.component';
import { KeyBundlesComponent } from './keyBundles/keyBundles.component';
import { NewKeyBundleComponent } from './new-key-bundle/new-key-bundle.component';
import { AuthorityDetailComponent } from './certificates/authority-detail/authority-detail.component';
import { CerificateDetailComponent } from './certificates/cerificate-detail/cerificate-detail.component';
import { TrustedPublicKeysComponent } from './trustedPublicKeys/trustedPublicKeys.component';
import { SshKeyPairsComponent } from './sshKeyPairs/sshKeyPairs.component';
import { OAuthTokensComponent } from './oAuthTokens/oAuthTokens.component';
import { EventGroupsComponent } from './eventGroups/eventGroups.component';
import { KeyBundleDetailComponent } from './new-key-bundle/key-bundle-detail/key-bundle-detail.component';
import { SshKeysComponent } from './ssh-keys/ssh-keys.component';
import { NewSshKeyComponent } from './ssh-keys/new-ssh-key/new-ssh-key.component';
import { NewTrustedPublicKeyComponent } from './ssh-keys/new-trusted-public-key/new-trusted-public-key.component';
import { DocumentInfoComponent } from './documentInfo/documentInfo.component';
import { SshKeyDetailComponent } from './ssh-keys/ssh-key-detail/ssh-key-detail.component';
import { TrustedPublicKeyDetailComponent } from './ssh-keys/trusted-public-key-detail/trusted-public-key-detail.component';
import { EventGroupDetailComponent } from './eventGroup-detail/eventGroup-detail.component';
import { CreateEventGroupComponent } from './create-event-group/create-event-group.component';
import { NewOAuthTokenComponent } from './tokens/new-oAuth-token/new-oAuth-token.component';
import { OAuthTokenDetailComponent } from './tokens/oAuth-token-detail/oAuth-token-detail.component';
import { ToTPCodesComponent } from './toTPCodes/toTPCodes.component';
import { FromTPCodesComponent } from './fromTPCodes/fromTPCodes.component';
import { NewDocumentInfoComponent } from './documentInfo/new-document-info/new-document-info.component';
import { DocumentInfoDetailComponent } from './documentInfo/document-info-detail/document-info-detail.component';
import { ApplicationDetailsComponent } from './application-details/application-details.component';
import { OrchestrationConfigsComponent} from './orchestrationConfigs/orchestrationConfigs.component';
import { TradingPartnersComponent } from './tradingPartners/tradingPartners.component';
import { TradingPartnerCodesComponent } from './tradingPartnerCodes/tradingPartnerCodes.component';
import { TradingPartnerRelationshipsComponent } from './tradingPartnerRelationships/tradingPartnerRelationships.component';
import { RoutingSourceRelationshipsComponent } from './routingSourceRelationships/routingSourceRelationships.component';
import { RoutesComponent } from './routes/routes.component';
import { NewTradingPartnerComponent } from './new-trading-partner/new-trading-partner.component';
import { CodeRelationshipsComponent } from './codeRelationships/codeRelationships.component';
import { StreamsComponent} from './streams/streams.component';
import { TradingPartnerDetailComponent } from './trading-partner-detail/trading-partner-detail.component';
import { TpApplicationsComponent } from './tpApplications/tpApplications.component';
import {CreateStreamComponent} from './create-stream/create-stream.component';
import { DigitalTwinsComponent } from './digitalTwins/digitalTwins.component';
import { RouteComponent } from './new-route/new-route.component';
import { AttributesComponent } from './attributes/attributes.component';
import { EventsComponent } from './events/events.component';
import { EcosystemTemplatesComponent } from './ecosystemTemplates/ecosystemTemplates.component';
import { StreamDetailComponent } from './stream-detail/stream-detail.component';
import { CreateEcosystemTemplateComponent} from './create-ecosystem-template/create-ecosystem-template.component';
import { MessageGovernancesComponent } from './messageGovernances/messageGovernances.component';
import { CreateRulePolicyComponent } from './create-rule-policy/create-rule-policy.component';
import { RulesComponent } from './rules/rules.component';
import { RulePolicyDetailsComponent } from './rule-policy-details/rule-policy-details.component';
import { CreateMessageGovernanceComponent } from './create-message-governance/create-message-governance.component';
import { CommandsComponent } from './commands/commands.component';
import { CreateOrchestrationConfigComponent } from './create-orchestration-config/create-orchestration-config.component';
import { RouteDetailComponent } from './route-detail/route-detail.component';
import { EcosystemTemplateDetailComponent } from './ecosystem-template-detail/ecosystem-template-detail.component';
import { EcosystemAttributesComponent } from './ecosystemAttributes/ecosystemAttributes.component';
import { EcosystemEventsComponent } from './ecosystemEvents/ecosystemEvents.component';
import { EcosystemMembersComponent } from './ecosystemMembers/ecosystemMembers.component';
import { GatewaysComponent } from './gateways/gateways.component';
import { MessageGovernanceDetailComponent } from './message-governance-detail/message-governance-detail.component';
import { CreateGatewayAgentComponent } from './create-gateway-agent/create-gateway-agent.component';
import { GatewayAgentDetailComponent } from './gateway-agent-detail/gateway-agent-detail.component';
import { EcosystemsComponent } from './ecosystems/ecosystems.component';
import { StyleguideComponent } from './styleguide/styleguide.component';
import { CreateEcosystemComponent } from './create-ecosystem/create-ecosystem.component';
import { GatewayAdaptorsComponent } from './gatewayAdaptors/gatewayAdaptors.component';
import { LogRequestsComponent } from './logRequests/logRequests.component';
import { AuditsComponent } from './audits/audits.component';
import { EcosystemDetailComponent } from './ecosystem-detail/ecosystem-detail.component';
import { DigitalTwinTemplatesComponent } from './digitalTwinTemplates/digitalTwinTemplates.component';
import { GeofencesComponent } from './geofences/geofences.component';
import { ImportExportComponent } from './importExport/importExport.component';
import { EcosystemInstanceAttributesComponent } from './ecosystemInstanceAttributes/ecosystemInstanceAttributes.component';
import { EcosystemInstanceMembersComponent } from './ecosystemInstanceMembers/ecosystemInstanceMembers.component';
import { OrganizationsComponent } from './organizations/organizations.component';
import { PersonsComponent } from './persons/persons.component';
import { EcosystemRelationshipsComponent } from './ecosystemRelationships/ecosystemRelationships.component';
import { EcosystemTemplateRulesComponent } from './ecosystemTemplateRules/ecosystemTemplateRules.component';
import { EcosystemInstanceRulesComponent } from './ecosystemInstanceRules/ecosystemInstanceRules.component';
import { GeofenceDetailComponent } from './geofence-detail/geofence-detail.component';
import { EcosystemBytemplatesComponent } from './ecosystemBytemplates/ecosystemBytemplates.component';
import { NewGeofenceComponent } from './new-geofence/new-geofence.component';
import { GatewayAnnouncedDevicesComponent } from './gatewayAnnouncedDevices/gatewayAnnouncedDevices.component';
import { GatewayRulePolicesComponent } from './gatewayRulePolices/gatewayRulePolices.component';
import { TradingPartnerAuditsComponent } from './tradingPartnerAudits/tradingPartnerAudits.component';
import { DevicesByExceptionsComponent } from './devicesByExceptions/devicesByExceptions.component';
import { DevicesByEcosystemsComponent } from './devicesByEcosystems/devicesByEcosystems.component';
import { DashboardDeviceDetailComponent } from './dashboard-device-detail/dashboard-device-detail.component';


const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'getting-started', component: GettingStartedComponent },
  { path: 'dashboard', component: DashboardComponent},
  { path: 'devices', component: DevicesComponent },
  { path: 'deviceDetails/:id', component: DashboardDeviceDetailComponent },
  { path: 'templates', component: TemplatesComponent },
  { path: 'applications', component: ApplicationsComponent },
  { path: 'createNewApplication', component: CreateApplicationComponent },
  { path: 'devices/:id', component: DeviceDetailComponent },
  { path: 'createNewDigitalTwins', component: MidSideNavComponent },
  { path: 'description', component: DescriptionComponent },
  { path: 'template-side-nav', component: TemplateListComponent },
  { path: 'connections', component: ConnectionListComponent },
  { path: 'perfmatrix', component: PerfmatrixComponent },
  { path: 'createDigitalTwinTemplates', component: CreateDeviceTemplateComponent },
  { path: 'templates/:id', component: TemplateDetailComponent },
  { path: 'createNewDigitalTwinTemplate/copy/:id', component: CreateDeviceTemplateComponent },
  { path: 'certificates', component: CertificatesComponent },
  { path: 'createNewTrustedAuthorities', component: NewTrustedAuthorityComponent },
  { path: 'createNewTrustedCertificates', component: NewTrustedCertificateComponent },
  { path: 'trustedAuthorities', component: TrustedAuthoritiesComponent },
  { path: 'trustedCertificates', component: TrustedCertificatesComponent },
  { path: 'keyBundles', component: KeyBundlesComponent },
  { path: 'createNewKeyBundles', component: NewKeyBundleComponent },
  { path: 'trustedAuthorities/:id', component: AuthorityDetailComponent },
  { path: 'trustedCertificates/:id', component: CerificateDetailComponent },
  { path: 'keyBundles/:id', component: KeyBundleDetailComponent },
  { path: 'trustedPublicKeys', component: TrustedPublicKeysComponent },
  { path: 'sshKeyPairs', component: SshKeyPairsComponent },
  { path: 'oAuthTokens', component: OAuthTokensComponent },
  { path: 'eventGroups', component: EventGroupsComponent },
  { path: 'sshKeys', component: SshKeysComponent },
  { path: 'createSshKeyPairs', component: NewSshKeyComponent },
  { path: 'createNewTrustedPublicKeys', component: NewTrustedPublicKeyComponent },
  { path: 'documentInfo', component: DocumentInfoComponent },
  { path: 'sshKeyPairs/:id', component: SshKeyDetailComponent },
  { path: 'trustedPublicKeys/:id', component: TrustedPublicKeyDetailComponent },
  { path: 'eventGroups/:id', component: EventGroupDetailComponent },
  { path: 'createNewEventGroups', component: CreateEventGroupComponent },
  { path: 'createOAuthTokens', component: NewOAuthTokenComponent },
  { path: 'oAuthTokens/:id', component: OAuthTokenDetailComponent },
  { path: 'toTPCodes', component: ToTPCodesComponent },
  { path: 'fromTPCodes', component: FromTPCodesComponent },
  { path: 'createDocumentInfo', component: NewDocumentInfoComponent },
  { path: 'documentInfo/:id', component: DocumentInfoDetailComponent },
  { path: 'applications/:id', component: ApplicationDetailsComponent },
  { path: 'orchestrationConfigs', component: OrchestrationConfigsComponent },
  { path: 'tradingPartners', component: TradingPartnersComponent },
	{ path: 'tradingPartnerCodes', component: TradingPartnerCodesComponent },
	{ path: 'tradingPartnerRelationships', component: TradingPartnerRelationshipsComponent },
	{ path: 'routingSourceRelationships', component: RoutingSourceRelationshipsComponent },
	{ path: 'routes', component: RoutesComponent },
  { path: 'createTradingPartners', component: NewTradingPartnerComponent },
  { path: 'codeRelationships', component: CodeRelationshipsComponent },
  { path: 'streams', component: StreamsComponent },
  { path: 'tradingPartners/:id', component: TradingPartnerDetailComponent },
  { path: 'tpApplications', component: TpApplicationsComponent},
  { path: 'createNewStreams', component: CreateStreamComponent },
  { path: 'digitalTwins', component: DigitalTwinsComponent },
  { path: 'createRoutes', component: RouteComponent },
  { path: 'attributes', component: AttributesComponent },
  { path: 'events', component: EventsComponent },
  { path: 'ecosystemTemplates', component: EcosystemTemplatesComponent },
  { path: 'streams/:id',component:StreamDetailComponent},
  { path: 'createEcosystemTemplates', component: CreateEcosystemTemplateComponent },
  { path: 'messageGovernances', component: MessageGovernancesComponent },
  { path: 'createRules', component: CreateRulePolicyComponent },
  { path: 'rules', component: RulesComponent },
  { path: 'rules/:id', component: RulePolicyDetailsComponent },
  { path: 'createMessageGovernances', component: CreateMessageGovernanceComponent },
  { path: 'commands', component: CommandsComponent },
  { path: 'createOrchestrationConfigs', component: CreateOrchestrationConfigComponent },
  { path: 'routes/:id', component: RouteDetailComponent },
  { path: 'orchestrationConfigs/:id', component: CreateOrchestrationConfigComponent },
  { path: 'ecosystemTemplates/:id',component:EcosystemTemplateDetailComponent},
  { path: 'ecosystemAttributes', component: EcosystemAttributesComponent },
	{ path: 'ecosystemEvents', component: EcosystemEventsComponent },
  { path: 'ecosystemMembers', component: EcosystemMembersComponent },
  { path: 'gateways', component: GatewaysComponent },
  { path: 'messageGovernances/:id', component: MessageGovernanceDetailComponent },
  { path: 'ecosystemAttributes', component: EcosystemAttributesComponent },
	{ path: 'ecosystemEvents', component: EcosystemEventsComponent },
  { path: 'ecosystemMembers', component: EcosystemMembersComponent },
  { path: 'gateways', component: GatewaysComponent },
  { path: 'createGateway', component: CreateGatewayAgentComponent },
  { path: 'gateways/:id', component: GatewayAgentDetailComponent },
  { path: 'ecosystems', component: EcosystemsComponent },
  { path: 'styleguide', component: StyleguideComponent },
  { path: 'createEcosystems', component: CreateEcosystemComponent },
  { path: 'gatewayAdaptors', component: GatewayAdaptorsComponent },
	{ path: 'logRequests', component: LogRequestsComponent },
	{ path: 'audits', component: AuditsComponent },
  { path: 'createGatewayAdaptors', component:  GatewayAgentDetailComponent},
  { path: 'createLogRequests', component: GatewayAgentDetailComponent },
  { path: 'ecosystems/:id', component: EcosystemDetailComponent},
  { path: 'digitalTwinTemplates', component: DigitalTwinTemplatesComponent },
  { path: 'geofences', component: GeofencesComponent },
  { path: 'rules/subscriber/ecosystemtemplate/:id', component: CreateRulePolicyComponent },
  { path: 'rules/subscriber/group/:id', component: CreateRulePolicyComponent },
  { path: 'importExport', component: ImportExportComponent },
  { path: 'ecosystemTemplates/:id/createEcosystemFromTemplate', component: CreateEcosystemComponent},
  { path: 'ecosystemInstanceAttributes', component: EcosystemInstanceAttributesComponent },
	{ path: 'ecosystemInstanceMembers', component: EcosystemInstanceMembersComponent },
	{ path: 'organizations', component: OrganizationsComponent },
	{ path: 'persons', component: PersonsComponent },
  { path: 'rules/gateway/:id', component: CreateRulePolicyComponent },
  { path: 'ecosystemRelationships', component: EcosystemRelationshipsComponent }, 
  { path: 'ecosystemTemplateRules', component: EcosystemTemplateRulesComponent }, 
  { path: 'ecosystemInstanceRules', component: EcosystemInstanceRulesComponent },
  { path: 'geofences/:id', component: GeofenceDetailComponent },
  { path: 'ecosystemBytemplates', component: EcosystemBytemplatesComponent },
  { path: 'createGeofences', component: NewGeofenceComponent },
  { path: 'gatewayAnnouncedDevices', component: GatewayAnnouncedDevicesComponent }, 
  { path: 'gatewayRulePolices', component: GatewayRulePolicesComponent }, 
  { path: 'tradingPartnerAudits', component: TradingPartnerAuditsComponent }, 
  { path: 'devicesByExceptions', component: DevicesByExceptionsComponent }, 
  { path: 'devicesByEcosystems', component: DevicesByEcosystemsComponent }, 
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy'
  })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

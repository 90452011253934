import { Component, OnInit } from '@angular/core';
import { faLaptopHouse } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationsService } from '../service/applications.service';
import { DigitalTwinsService } from '../service/digitalTwins.service';
import { EcosystemMembersService } from '../service/ecosystemMembers.service';
import { EcosystemsService } from '../service/ecosystems.service';
import { IotService } from '../service/iot.service';
import { OrganizationsService } from '../service/organizations.service';
import { PersonsService } from '../service/persons.service';
import { SideNavComponent } from '../side-nav/side-nav.component';

@Component({
  selector: 'app-create-ecosystem-membership',
  templateUrl: './create-ecosystem-membership.component.html',
  styleUrls: ['./create-ecosystem-membership.component.scss'],
})
export class CreateEcosystemMembershipComponent implements OnInit {
  supportLanguages = ['en'];
  allowedMemberTypes = [];
  ecosystemMembership;
  attribute;
  showPersonFromSideNav = false;
  showDeviceFromSideNav = false;
  showEcosystemFromSideNav = false;
  showOrgFromSideNav = false;
  showAppFromSideNav = false;
  showAttributeFromSideNav = false;
  memberKind;
  allowedMemberName;
  selectedAttributesList;
  parentTemplateId;
  allowedMembers = [
    {
      type: 'Select Kind',
      kind: [],
    },
  ];
  errorInMemberKind: string;
  hasError: boolean = false;

  constructor(
    public iotService: IotService,
    private translateService: TranslateService,
    private sideNavComponent: SideNavComponent,
    public ecosystemService: EcosystemsService,
    public ecosystemMemberService: EcosystemMembersService,
    public digitalTwinService: DigitalTwinsService,
    public personService: PersonsService,
    public organizationService: OrganizationsService,
    public applicationService: ApplicationsService
  ) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }

  ngOnInit(): void {
    this.initEcosystemMembership();
    this.parentTemplateId = this.ecosystemService.getParentEcosystemTemplateId();
    this.loadMemberTypes(this.parentTemplateId);
    this.iotService.createEcosystemMembership = true;
    this.ecosystemMemberService.setSelectedAttributes(new Array());
    this.ecosystemMemberService.setMembershipAttributesForUpdate(new Array());
  }

  initEcosystemMembership() {
    const that = this;
    that.ecosystemMembership = {
      creator: that.iotService.getCreator(),
      creatorAppId: that.iotService.getCreatorAppId(),
      realm: that.iotService.getRealm(),
      group: {
        id: '',
        type: 'group',
        realm: that.iotService.getRealm(),
      },
      member: {
        id: '',
        type: '',
        realm: that.iotService.getRealm(),
      },
      membershipAttributes: [],
    };
  }

  loadMemberTypes(parentTemplateId: any) {
    const that = this;
    that.iotService
      .getCuiObjResponse()
      .getEcosystemMembers({
        ecosystemTemplateId: parentTemplateId,
      })
      .then(function (response) {
        console.log(response);
        that.allowedMemberTypes = response;
        that.allowedMemberTypes.forEach((allowedMember) => {
          that.allowedMembers.push({
            type: allowedMember.type,
            kind: allowedMember.kind,
          });
        });
      })
      .fail(function (err) {
        if (err.responseJSON && err.responseJSON.apiStatusCode) {
          console.log(
            'Error Occured: Status: ' +
              err.responseJSON.status +
              ' Msg: ' +
              err.responseJSON.apiStatusCode
          );
        } else {
          console.log(
            'Something went wrong. Please check chrome console or check with dev team'
          );
        }
      });
  }

  changeMemberType() {
    const that = this;
    that.showPersonFromSideNav = false;
    that.showDeviceFromSideNav = false;
    that.showEcosystemFromSideNav = false;
    that.showOrgFromSideNav = false;
    that.showAppFromSideNav = false;
    that.showAttributeFromSideNav = false;

    if (that.memberKind == 'person') {
      that.showPersonFromSideNav = true;
      that.showAttributeFromSideNav = true;
    }
    if (that.memberKind == 'device') {
      that.showDeviceFromSideNav = true;
      that.showAttributeFromSideNav = true;
    }
    if (that.memberKind == 'group') {
      that.showEcosystemFromSideNav = true;
      that.showAttributeFromSideNav = true;
    }
    if (that.memberKind == 'organization') {
      that.showOrgFromSideNav = true;
      that.showAttributeFromSideNav = true;
    }
    if (that.memberKind == 'application') {
      that.showAppFromSideNav = true;
      that.showAttributeFromSideNav = true;
    }
  }

  goBackMembership() {
    this.ecosystemService.viewMembership = false;
    this.ecosystemService.viewResource = true;
  }

  addMembership() {
    const that = this;
    if (!that.errorValidations()) {
      that.ecosystemMembership.group.id =
        that.ecosystemService.getEcosystemInstanceId();

      that.allowedMemberTypes.forEach((allowedMemberType) => {
        if (allowedMemberType.kind[0] == that.memberKind) {
          that.allowedMemberName = allowedMemberType.type;
        }
      });
      that.ecosystemMembership.member.type = that.allowedMemberName;

      if (that.memberKind == 'person') {
        if (
          that.ecosystemMemberService.selectedPersonId != undefined &&
          that.ecosystemMemberService.selectedPersonId != ''
        ) {
          that.ecosystemMembership.member.id = that.ecosystemMemberService.selectedPersonId;
          that.fetchAttributesAndAddMember();
        } else {
          that.ecosystemService.warningMessage =
            'Please select a person to create ecosystem membership';
        }
      }
      if (that.memberKind == 'device') {
        if (
          that.ecosystemMemberService.selectedDeviceId != undefined &&
          that.ecosystemMemberService.selectedDeviceId != ''
        ) {
          that.ecosystemMembership.member.id = that.ecosystemMemberService.selectedDeviceId;
          that.fetchAttributesAndAddMember();
        } else {
          that.ecosystemService.warningMessage =
            'Please select a device to create ecosystem membership';
        }
      }
      if (that.memberKind == 'group') {
        if (that.ecosystemMemberService.selectedEcosystemId != undefined && that.ecosystemMemberService.selectedEcosystemId != '') {
          that.ecosystemMembership.member.id =
            that.ecosystemMemberService.getSelectedEcosystemId();
            that.fetchAttributesAndAddMember();
        } else {
          that.ecosystemService.warningMessage =
            'Please select an ecosystem to create ecosystem membership';
        }
      }
      if (that.memberKind == 'organization') {
        if (
          that.ecosystemMemberService.selectedOrganizationId != undefined && that.ecosystemMemberService.selectedOrganizationId != '') {
          that.ecosystemMembership.member.id =
            that.ecosystemMemberService.getSelectedOrganizationId();
            that.fetchAttributesAndAddMember();
        } else {
          that.ecosystemService.warningMessage =
            'Please select an organization to create ecosystem membership';
        }
      }
      if (that.memberKind == 'application') {
        if (that.ecosystemMemberService.selectedApplicationId != undefined && that.ecosystemMemberService.selectedApplicationId != '') {
          that.ecosystemMembership.member.id =
            that.ecosystemMemberService.getSelectedApplicationId();
            that.fetchAttributesAndAddMember();
        } else {
          that.ecosystemService.warningMessage =
            'Please select an application to create ecosystem membership';
        }
      }
    }
  }

  fetchAttributesAndAddMember() {
    const that = this;
    that.ecosystemMembership.membershipAttributes = that.ecosystemMemberService.getSelectedAttributes();

    let attributesForUpdate = that.ecosystemMemberService.getMembershipAttributesForUpdate();
        if (attributesForUpdate.length > 0) {
          let existingAttributes = that.ecosystemMembership.membershipAttributes;
          existingAttributes.forEach((element) => {
            let result = attributesForUpdate.filter(
              (e) => e.attributeId == element.attributeTypeId
            );
            if (result.length > 0) {
              let attrValue = result[0].value;
    
              if (result[0].type == 'integer') {
                if (attrValue != '') {
                  element.value = parseInt(attrValue);
                  if (isNaN(attrValue)) {
                    that.ecosystemService.warningMessage =
                      'Please insert valid integer value in "' +
                      result[0].name +
                      '"';
                  }
                }
              } else if (result[0].type == 'decimal') {
                if (attrValue != '') {
                  if (attrValue % 1 == 0) {
                    attrValue = +(
                      parseFloat(attrValue) + parseFloat('0.00001')
                    ).toFixed(6);
                  }
                  element.value = parseFloat(attrValue);
                  if (isNaN(attrValue)) {
                    that.ecosystemService.warningMessage =
                      'Please insert valid decimal value in "' +
                      result[0].name +
                      '"';
                  }
                }
              } else if (result[0].type == 'bool') {
                if (attrValue == 'true') {
                  element.value = true;
                } else {
                  element.value = false;
                }
              }
            }
          });
          that.ecosystemMembership.membershipAttributes = existingAttributes;
        }

    // check if pre-existing attribute is there
    that.iotService
      .getCuiObjResponse()
      .getAttributeTypes({
        qs: [
          ['name', 'member_kind'],
          ['active', 'true'],
        ],
      })
      .then(function (response) {
        if (response.length > 0) {
          if (that.memberKind == undefined) {
            that.ecosystemMembership.membershipAttributes.push({
              attributeTypeId: response[0].id,
              value: '',
            });
          } else {
            that.ecosystemMembership.membershipAttributes.push({
              attributeTypeId: response[0].id,
              value: that.memberKind,
            });
          }
          // add ecosystem Membership
          that.iotService
            .getCuiObjResponse()
            .addEcosystemMembership({
              groupId: that.ecosystemService.getEcosystemInstanceId(),
              data: that.ecosystemMembership,
            })
            .then(function (response) {
              that.ecosystemService.successMessage =
                'Ecosystem membership created successfully!';
              console.log('Ecosystem Membership added :' + response.id);
              that.getEcosystemMemberships(that.ecosystemMembership.group.id);
              that.goBackMembership();
            })
            .fail(function (err) {
              if (err.responseJSON.apiStatusCode) {
                that.ecosystemService.errorMessage =
                  err.responseJSON.apiMessage;
                console.log(
                  'Error Occured: Status: ' +
                    err.responseJSON.status +
                    ' Msg: ' +
                    err.responseJSON.apiStatusCode
                );
              } else {
                console.log(
                  'Something went wrong. Please check chrome console or check with dev team'
                );
              }
            });
        } else {
          console.log('creating a new attribute');
          that.attribute = {
            creator: that.iotService.getCreator(),
            creatorAppId: that.iotService.getCreatorAppId(),
            realm: that.iotService.getRealm(),
            name: 'member_kind',
            description: [
              {
                lang: 'en_us',
                text: 'Used to store the name of the member type of ecosystem',
              },
            ],
            type: 'string',
            active: true,
          };

          that.iotService
            .getCuiObjResponse()
            .createAttributeType({
              data: that.attribute,
            })
            .then(function (results) {
              if (that.attribute.isActive === true) {
                that.iotService
                  .getCuiObjResponse()
                  .activateAttributeType({
                    attributeTypeId: response.id,
                  })
                  .then(function (activateResponse) {
                    console.log(
                      'Attribute id : ' + response.id + ' is activated'
                    );
                  });
                that.ecosystemMembership.membershipAttributes.push({
                  attributeTypeId: results.id,
                  value: that.memberKind,
                });

                // add ecosystem Membership
                that.iotService
                  .getCuiObjResponse()
                  .addEcosystemMembership({
                    groupId: that.ecosystemService.getEcosystemInstanceId(),
                    data: that.ecosystemMembership,
                  })
                  .then(function (response) {
                    that.ecosystemService.successMessage =
                      'Ecosystem membership created successfully!';
                    console.log('Ecosystem Membership added :' + response.id);
                    that.goBackMembership();
                  })
                  .fail(function (err) {
                    if (err.responseJSON.apiStatusCode) {
                      that.ecosystemService.errorMessage =
                        err.responseJSON.apiMessage;
                      console.log(
                        'Error Occured: Status: ' +
                          err.responseJSON.status +
                          ' Msg: ' +
                          err.responseJSON.apiStatusCode
                      );
                    } else {
                      console.log(
                        'Something went wrong. Please check chrome console or check with dev team'
                      );
                    }
                  });
              }
            });
        }
      })
      .fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          that.ecosystemService.errorMessage = err.responseJSON.apiMessage;
          console.log(
            'Error Occured: Status: ' +
              err.responseJSON.status +
              ' Msg: ' +
              err.responseJSON.apiStatusCode
          );
        } else {
          console.log(
            'Something went wrong. Please check chrome console or check with dev team'
          );
        }
      });
  }

  errorValidations() {
    const that = this;
    that.hasError = false;
    if (that.memberKind == undefined || that.memberKind == "undefined") {
      that.errorInMemberKind = 'Please select a valid member kind';
      that.hasError = true;
    }
    return that.hasError;
  }

  resetErrorFields() {
    this.errorInMemberKind = '';
  }

  resetData() {
    this.personService.selection.clear();
    this.digitalTwinService.selection.clear();
    this.ecosystemService.selection.clear();
    this.organizationService.selection.clear();
    this.applicationService.selection.clear();
  }

  getEcosystemMemberships(ecosystemId) {
    const that = this;
    that.iotService
      .getCuiObjResponse()
      .getEcosystemInstanceMembers({
        ecosystemId: ecosystemId,
      })
      .then(function (response) {
        that.ecosystemService.ecosystemMemberShips=response;
      })
      .fail(function (err) {
        if (err.responseJSON && err.responseJSON.apiStatusCode) {
          console.log(
            'Error Occured: Status: ' +
              err.responseJSON.status +
              ' Msg: ' +
              err.responseJSON.apiStatusCode
          );
        } else {
          console.log(
            'Something went wrong. Please check chrome console or check with dev team'
          );
        }
      });
  }

  ngOnDestroy() {
    this.iotService.createEcosystemMembership = false;
    this.resetErrorFields();
    this.resetData();
    this.ecosystemService.resetErrorMessage();
    this.ecosystemService.resetSuccessMessage();
    this.ecosystemService.resetWarningMessage();
  }
}

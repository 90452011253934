<mat-sidenav-container autosize *ngIf="geofence&&!geofencesService.showNotification">
    <mat-sidenav class="midNav-container" #sidenav mode="side" disableClose="true" opened="true">
        <div class="alert warning" *ngIf="geofencesService.warningMessage">
            <h5>
                <fa-icon [icon]="faWarningIcon"></fa-icon>
                <span>{{geofencesService.warningMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)="geofencesService.resetWarningMessage()" [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>
        <div class="alert critical" *ngIf="geofencesService.errorMessage">
            <h5>
                <fa-icon [icon]="faCriticalIcon"></fa-icon>
                <span>{{geofencesService.errorMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=geofencesService.resetErrorMessage() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>

        <div class="alert success" *ngIf="geofencesService.successMessage">
            <h5>
                <fa-icon [icon]="faSuccessIcon"></fa-icon>
                <span>{{geofencesService.successMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=geofencesService.resetSuccessMessage() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>
        <div class="form" *ngIf="geofence">
            <div style="margin-left: -20px;">
                <app-copy-resource-id></app-copy-resource-id>
            </div>
            <label for="name"> {{ 'name' | translate}}</label>
            <p>{{geofence.name[0].text}}</p>

            <label for="description"> {{ 'description' | translate}}</label>
            <p *ngIf="geofence.description">{{geofence.description[0].text}} </p>
            <p *ngIf="!geofence.description">- </p>
            <div class="tags">

                <label for="name"> {{ 'created' | translate}}</label>
                <p>{{geofence.creation | date:'mediumDate'}}</p>    

                <label for="device-tags">{{ 'tags' | translate}}</label>
                <mat-chip-list #tagList aria-label="tags">

                    <mat-chip class="ot-chip" *ngFor="let tag of geofence.tags" [selectable]="true" [removable]=true
                        (removed)="remove(tag)">
                        {{tag}}
                        <button class="ot-chip__close" matChipRemove></button>
                    </mat-chip>
                    <input style="width: 50%;" [ngClass]="tagClass" placeholder="add a tag" #tagInput
                        [matChipInputFor]="tagList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="add($event)">

                </mat-chip-list>
            </div>

            <label>{{ 'status' | translate}}</label>
            <div *ngIf="geofence.isActive">
                <button class="toggle-on toggle-switch" (click)="deactivateGeofence(geofence.id)">
                    <fa-icon [icon]="faCheckIcon" class="fa-2x"></fa-icon>
                    <div class="toggle-on-btn"></div>
                </button>
            </div>

            <div *ngIf="!geofence.isActive">
                <button class="toggle-off toggle-switch" (click)="activateGeofence(geofence.id)">
                    <div class="toggle-off-btn"></div>
                </button>
            </div>

            <br>
            <!-- <input type="number" [(ngModel)]="geofencesService.showNotification" (ngModelChange)="reloadBreadCrumbs($event)" /> -->

            <!-- <p>isNotificationCreated {{isNotificationCreated}}</p> -->

            <button class="btn btn-secondary" (click)="geofencesService.showNotification=true">{{'notification'|translate}}</button>


        </div>

    </mat-sidenav>
    <div class="geofence-data" style="height:450vh">
        <div class="table-data">
            <table>
                <thead>
                    <th>{{'latitude' | translate}}</th>
                    <th>{{'longitude' | translate}}</th>
                </thead>
                <tbody *ngFor="let poly of geofence.polygon">
                    <tr>
                        <td>{{poly.latitude}}</td>
                        <td>{{poly.longitude}}</td>
                    </tr>

                </tbody>
            </table>
        </div>
        <br>

        <div class="map-component">
            <button class="btn btn-secondary" *ngIf="showGeofence==false" (click)="showGeofence=!showGeofence">{{'show.map' |translate}} <fa-icon [icon]="showMap"></fa-icon> </button>
            <button class="btn btn-secondary pull-right" *ngIf="showGeofence==true"
                (click)="showGeofence=!showGeofence">{{'close.map' |translate}} <fa-icon [icon]="faCloseIcon"></fa-icon>
            </button>

            <br>
            <br>
            <app-map-component *ngIf="showGeofence"></app-map-component>
        </div>

    </div>
</mat-sidenav-container>
<div *ngIf="geofencesService.showNotification">
    <app-notification *ngIf="isNotificationCreated"></app-notification>
    <app-create-notification *ngIf="!isNotificationCreated"></app-create-notification>
</div>


<ng-container *ngIf="!geofence ">
    <div>
        <br /><br />
    </div>
    <div class="d-flex justify-content-center pageLoading">
        <div class="spinner-border" role="status">
            <span class="sr-only" id="loading"></span>
        </div>
    </div>
</ng-container>
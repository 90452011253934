import { Component, OnInit } from '@angular/core';
import {  faCopy } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { SideNavComponent } from '../side-nav/side-nav.component';



@Component({
  selector: 'app-create-gateway-agent',
  templateUrl: './create-gateway-agent.component.html',
  styleUrls: ['./create-gateway-agent.component.scss']
})
export class CreateGatewayAgentComponent implements OnInit {

  faCopyIcon = faCopy;
  supportLanguages = ['en'];

  constructor( private sideNavComponent: SideNavComponent,private translateService: TranslateService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }

  ngOnInit(): void {
    this.sideNavComponent.menuClear();
    this.sideNavComponent.menuChange('Dashboard', 'sub-type');
    this.sideNavComponent.menuChange('gateways.header', 'sub-type');
    this.sideNavComponent.menuChange('Create New Gateway', 'sub-type');

  }

}

<mat-sidenav-container class="mid-sidenav" autosize>

    <div class="container-data">
        <div class=form-data>

            <label for="member-type">Add Member</label>
            <select name="member-type" [(ngModel)]="memberKind" (change)="changeMemberType()"
                (click)="errorInMemberKind=''">
                <option [value]="item.kind[0]" *ngFor="let item of allowedMembers">
                    {{item.type}}
                </option>
            </select>
            <p *ngIf="errorInMemberKind" style="color: red;">{{errorInMemberKind}}</p>
        </div>

        <ng-container>
            <app-persons *ngIf="showPersonFromSideNav" [pageSize]="5" [showSelectColoumn]=true [showEnableColumn]=true
                [showControlBar]=true [showNew]=false
                [displayedColumns]="['ADD','username','email','status','creation']" multiSelect="false"></app-persons>

            <app-digitalTwins *ngIf="showDeviceFromSideNav" [pageSize]="5" [showSelectColoumn]=true
                [showEnableColumn]=true [showControlBar]=true [showNew]=false
                [displayedColumns]="['ADD','name','description','parentDeviceTemplateName','creation']"
                multiSelect="false"></app-digitalTwins>

            <app-ecosystems *ngIf="showEcosystemFromSideNav" [pageSize]="5" [showSelectColoumn]=true
                [showEnableColumn]=true [showControlBar]=true [showNew]=false
                [displayedColumns]="['ADD','name','description','creation']" multiSelect="false"></app-ecosystems>

            <app-organizations *ngIf="showOrgFromSideNav" [pageSize]="5" [showSelectColoumn]=true
                [showEnableColumn]=true [showControlBar]=true [showNew]=false
                [displayedColumns]="['ADD','name','status','creation']" multiSelect="false"></app-organizations>

            <app-applications *ngIf="showAppFromSideNav" [pageSize]="5" [showSelectColoumn]=true [showEnableColumn]=true
                [showControlBar]=true [showNew]=false [displayedColumns]="['ADD','name','description','creation']"
                multiSelect="false"></app-applications>
        </ng-container>

        <ng-container>
            <app-attributes *ngIf="showAttributeFromSideNav" [pageSize]="5" [showSelectColoumn]=true
                [showEnableColumn]=true [showControlBar]=true multiSelect=true [showNew]=false
                [displayedColumns]="['ADD', 'name', 'description', 'type', 'value','constant','defaultValue', 'ACTIVE']">
            </app-attributes>
        </ng-container>

        <ng-container>
            <div class="button-row" style="margin-right: 10px;">
                <button class="btn btn-secondary" (click)="goBackMembership()">{{ 'go_back' |
                    translate}}</button>&nbsp;
                <button class="btn btn-primary" (click)="addMembership()">{{'save'| translate}}</button>
            </div>
        </ng-container>

    </div>
</mat-sidenav-container>
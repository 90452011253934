<mat-sidenav-container class="new-token" autosize>

    <mat-sidenav class="midNav-container" #sidenav mode="side" disableClose="true" opened="true">


        <div class="alert warning" *ngIf="warningMessage">
            <h5>
                <fa-icon [icon]="faWarningIcon"></fa-icon>
                <span>{{warningMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)="resetWarningMessage()" [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>
        <div class="alert critical" *ngIf="errorMessage">
            <h5 style="font-size: 12px;">
                <fa-icon [icon]="faCriticalIcon"></fa-icon>
                <span>{{errorMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=resetWarningMessage() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>

        </div>
        <div class="data">
            <h1 style="margin-left: -15px;"> {{ 'create.oAuth.token' | translate}}</h1>
            <p>{{'oAuthTokens.definition' | translate}}</p>
            <div>
                <button [disabled]="token.name[0].text==''||oAuthTokenCreated" class="btn btn-primary" (click)="save()">{{ 'save' | translate}}</button>
            </div>
        </div>



    </mat-sidenav>
    <mat-sidenav-content class="example-sidenav-content">
        <div class="token-data">

            <div class="form-data">
                <div class="left">
                    <label for="name"> {{ 'name' | translate}}</label>
                    <input placeholder="required" [(ngModel)]="token.name[0].text" id="name" type="text" aria-label="Name">
                    <p *ngIf="nameError">{{clientIdError}}</p>

                    <label for="description"> {{ 'description' | translate}}</label>
                    <textarea rows="2" [(ngModel)]="token.description[0].text" placeholder="optional" id="description" aria-label="Description"></textarea>

                    <div class="tags">

                        <label for="device-tags">{{ 'tags' | translate}}</label>
                        <mat-chip-list #tagList aria-label="tags">

                            <mat-chip class="ot-chip" *ngFor="let tag of tags" [selectable]="true" [removable]=true (removed)="remove(tag)">
                                {{tag}}
                                <button class="ot-chip__close" matChipRemove></button>
                            </mat-chip>
                            <input style="width: 50%;" [ngClass]="tagClass" placeholder="add a tag" #tagInput [matChipInputFor]="tagList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="add($event)">

                        </mat-chip-list>
                    </div>

                    <br>
                    <br>

 

                </div>
                <div class="right">
                    <label for="clientId"> {{ 'clientId' | translate}}</label>
                    <input placeholder="required" [(ngModel)]="token.clientId" id="clientId" (click)="clientIdError=''" type="text" aria-label="clientId">
                    <p *ngIf="clientIdError">{{clientIdError}}</p>

                    <label for="clientSecret"> {{ 'clientSecret' | translate}}</label>
                    <input placeholder="required" [(ngModel)]="token.clientSecret" id="clientSecret" (click)="clientSecretError=''" type="text" aria-label="Client Secret">
                    <p *ngIf="clientSecretError">{{clientSecretError}}</p>

                    <label for="grantType"> {{ 'grantType' | translate}}</label>
                    <form appearance="outline" class="dropdown" id="grantType">
 
                        <select  [formControl]="typeChangeControl" name="typeChange">
                            <option *ngFor="let range of grantType" [value]="range.value">
                                {{range.viewValue}}
                            </option>
                        </select>
                    </form>


                    <label for="accessToken"> {{ 'accessToken' | translate}}</label>
                    <input placeholder="required" [(ngModel)]="token.accessToken" id="accessToken" (click)="accessTokenError=''" type="text" aria-label="AccessToken">
                    <p *ngIf="accessTokenError">{{accessTokenError}}</p>

                    <div *ngIf="typeChangeControl.value==0">
                        <label for="refreshToken"> {{ 'refreshToken' | translate}}</label>
                        <input placeholder="required" [(ngModel)]="token.refreshToken" id="refreshToken" (click)="refreshTokenError=''" type="text" aria-label="refreshToken">
                        <p *ngIf="refreshTokenError">{{refreshTokenError}}</p>
                    </div>

                    <label for="tokenEndpointURL"> {{ 'tokenEndpointURL' | translate}}</label>
                    <input placeholder="required" [(ngModel)]="token.tokenEndpointUrl" id="tokenEndpointURL" (click)="tokenEndpointURLError=''" type="text" aria-label="tokenEndpointURL">
                    <p *ngIf="tokenEndpointURLError">{{tokenEndpointURLError}}</p>

                    <label for="introspectionEndpointURL"> {{ 'introspectionEndpointURL' | translate}}</label>
                    <input placeholder="optional" [(ngModel)]="token.introspectionEndpointUrl" id="introspectionEndpointURL" (click)="introspectionEndpointUrlError=''" type="text" aria-label="introspectionEndpointURL">
                    <!-- <p *ngIf="introspectionEndpointUrlError">{{introspectionEndpointUrlError}}</p> -->
                    <br>
                    <div *ngIf=optionalParamsToggle>
                        <p class="blue-highlight" (click)="toggleExample()">{{ 'optionalParams' | translate}}
                            <fa-icon [icon]="faAngleDown"></fa-icon>
                        </p>
                    </div>
                    <div *ngIf=!optionalParamsToggle>
                        <p class="blue-highlight" (click)="toggleExample()">{{ 'optionalParams' | translate}}
                            <fa-icon [icon]="faAngleUp"></fa-icon>
                        </p>
                        <h6>NOTE: Headers and Form Data should be in "key" and "value" <br> {{'pairs.json.format' | translate}}</h6>

                        <label for="headers"> {{ 'headers' | translate}}</label>
                        <textarea rows="2" placeholder="optional" [(ngModel)]="token.customHeaders" id="headers" aria-label="Headers"></textarea>

                        <label for="formData"> {{ 'formdata' | translate}}</label>
                        <textarea rows="2" placeholder="optional" [(ngModel)]="token.customFormData" id="formData" aria-label="Form Data"></textarea>

                        <br>
                        <br>
                        <mat-checkbox [(ngModel)]="token.customDataInIntrospection">{{'use.introspection' | translate}}</mat-checkbox>
                    </div>
                    <br>
                    <br>
                </div>

                <br>
            </div>
        </div>
    </mat-sidenav-content>

</mat-sidenav-container>
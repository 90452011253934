<mat-sidenav-container class="mid-sidenav" autosize>

    <mat-sidenav *ngIf="iotService.sideNavStream && !iotService.deviceStream" class="midNav-container" #sidenav mode="side" disableClose="true" opened="true">
        <div class="alert critical" *ngIf="streamService.errorMessage">
            <h5>
                <fa-icon [icon]="faCriticalIcon"></fa-icon>
                <span>{{streamService.errorMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=streamService.resetErrorMessage() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>

        </div>
        <h1>{{'create.stream' | translate}}</h1>
        <mat-nav-list class="without-template user-selections">
        <p>{{'create.stream.desc' | translate}}</p>
        <ng-container *ngIf="streamService.streamNamesArray.length>0">
            <h5>{{'streams' | translate}}</h5>

            <ul>
                <li *ngFor="let stream of streamService.streamNamesArray">
                    <fa-icon [icon]="faCheckIcon"></fa-icon> {{stream}}</li>
            </ul>

        </ng-container>
        <ng-container *ngIf="showAppFromSideNav">
            <h5>{{'applications.header' | translate}}</h5>
            <p *ngIf="!streamService.selectedApplication"> {{'select.application.desc' | translate}}</p>
            <ng-container *ngIf="streamService.selectedApplication">

                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamService.applicationSelected}}</li>
                </ul>

            </ng-container>
        </ng-container>
        <ng-container *ngIf="showDeviceFromSideNav">
            <h5>{{'devices' | translate}}</h5>
            <p *ngIf="!streamService.selectedDevice"> {{'select.device.desc' | translate}}</p>
            <ng-container *ngIf="streamService.selectedDevice">

                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamService.deviceSelected}}</li>
                </ul>

            </ng-container>
        </ng-container>
        <ng-container *ngIf="streamService.docInfoList && streamService.getDirection()!='Active Receiving' && streamService.getDirection()!='Passive Receiving'
        &&streamService.getDirection()!='ACTIVE_RECEIVING' && streamService.getDirection()!='PASSIVE_RECEIVING'
        &&streamService.getDirection()!='Select Direction' && streamService.getProtocolType()!=undefined">
            <h5>{{'stream.document.info' | translate}}</h5>
            <p *ngIf="!streamService.selectedDocInfos"> {{'select.docInfo.list' | translate}}</p>
            <ng-container *ngIf="streamService.selectedDocInfos">
                <h6>{{'doc.type' | translate}}</h6>
                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamService.streamDocInfoDocType}}</li>
                </ul>
                <h6>{{'version' | translate}}</h6>
                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamService.streamDocInfoVersion}}</li>
                </ul>
                <h6>{{'template' | translate}}</h6>
                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamService.streamDocInfoTemplate}}</li>
                </ul>

            </ng-container>
            <ng-container *ngIf="streamService.fromCode">
                <h5>{{'from.code' | translate}}</h5>
                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamService.fromCodeValue.code}}</li>
                </ul>


            </ng-container>
            <ng-container *ngIf="streamService.toCode">
                <h5>{{'to.code' | translate}}</h5>
                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamService.toCodeValue.code}}</li>
                </ul>


            </ng-container>
        </ng-container>

        <ng-container *ngIf="streamService.docInfoList && (streamService.getDirection()=='Passive Receiving' 
        || streamService.getDirection()=='PASSIVE_RECEIVING') 
        && streamService.getProtocolType()=='BATCH'">
            <h5>{{'stream.document.info' | translate}}</h5>
            <p *ngIf="!streamService.selectedDocInfos"> Select Doc Info from the list on the right</p>
            <ng-container *ngIf="streamService.selectedDocInfos">
                <h6>{{'doc.type' | translate}}</h6>
                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamService.streamDocInfoDocType}}</li>
                </ul>
                <h6>{{'version' | translate}}</h6>
                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamService.streamDocInfoVersion}}</li>
                </ul>
                <h6>{{'template' | translate}}</h6>
                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamService.streamDocInfoTemplate}}</li>
                </ul>

            </ng-container>
            <ng-container *ngIf="streamService.fromCode">
                <h5>{{'from.code' | translate}}</h5>
                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamService.fromCodeValue.code}}</li>
                </ul>


            </ng-container>
            <ng-container *ngIf="streamService.toCode">
                <h5>{{'to.code' | translate}}</h5>
                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamService.toCodeValue.code}}</li>
                </ul>


            </ng-container>
        </ng-container>

        <ng-container *ngIf="streamService.docInfoList && streamService.getDirection()=='Active Sending' && streamService.getStreamType()=='Sync'">
            <h5 style="font-weight: bolder;">{{'response.mapping' | translate}}</h5>
            <h5>{{'stream.document.info' | translate}}</h5>
            <p *ngIf="!streamService.getDocInfo2()"> {{'select.docInfo.list' | translate}}</p>
            <ng-container *ngIf="streamService.getDocInfo2()">
                <h6>{{'doc.type' | translate}}</h6>
                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamService.getDocInfo2().supportedDocType}}</li>
                </ul>
                <h6>{{'version' | translate}}</h6>
                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamService.getDocInfo2().docTemplateVersion}}</li>
                </ul>
                <h6>{{'template' | translate}}</h6>
                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamService.getDocInfo2().supportedDocTemplateType}}</li>
                </ul>

            </ng-container>
            <ng-container *ngIf="streamService.fromCode2">
                <h5>{{'from.code' | translate}}</h5>
                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamService.fromCodeValue2.code}}</li>
                </ul>


            </ng-container>
            <ng-container *ngIf="streamService.toCode2">
                <h5>{{'to.code' | translate}}</h5>
                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamService.getToCodeValue2().code}}</li>
                </ul>


            </ng-container>
        </ng-container>
        <ng-container *ngIf="auth == 'Public Key' &&( direction == 'Active Receiving' || direction == 'Passive Sending')">
            <h5>{{'stream.sshKey.pairs' | translate}}</h5>
            <p> {{'select.sshKey.pairs' | translate}}</p>
            <ng-container *ngIf="streamService.getSSHKeyPair()">
                
                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamService.getSSHKeyPair()}}</li>
                </ul>


            </ng-container>

        </ng-container>

        <ng-container *ngIf="streamService.getProtocolSecurityType()=='Mutual Auth'">
            <h5>{{'mutual.auth.keyBundle' | translate}}</h5>
            <p *ngIf="!streamService.getMutualAuth()"> {{'select.mutual.auth.keyBundle' | translate}}</p>
            <ng-container *ngIf="streamService.getMutualAuth()">
                <!-- <h5>
                    Mutual Auth Key Bundle</h5> -->
                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamService.getMutualAuth()}}</li>
                </ul>


            </ng-container>

        </ng-container>

        <ng-container *ngIf="streamService.getProtocolSecurityType()=='OAuth Token'">
            <h5>{{'token' | translate}}</h5>
            <p *ngIf="!streamService.getOAuthToken()"> {{'select.token.list' | translate}}</p>
            <ng-container *ngIf="streamService.getOAuthToken()">
               
                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamService.getOAuthToken()}}</li>
                </ul>


            </ng-container>

        </ng-container>
    </mat-nav-list>
    </mat-sidenav>

    <div *ngIf="!streamService.streamCreated" class="streamData">


        <form>
            <label for="stream-name">{{ 'stream.name' | translate }}</label>
            <input placeholder="required" id="stream-name" type="text" value="" (keyup)="streamName($event)" aria-label="Stream Name">
            <p *ngIf="nameError" class="text-red">{{nameError}}</p>
            <label for="encryption-type">{{ 'encryption' | translate }}</label>

            <select name="encryption-type" [(ngModel)]="encryption" (change)="changePayloadSecurityType()">
            <option [value]="item.encryption" *ngFor="let item of encryptiontypes">
                {{item.encryption}}
            </option>
           </select>
           

            <label for="stream-type">{{ 'stream.type' | translate }}</label>

            <ng-container *ngIf="iotService.sideNavStream && !iotService.deviceStream">
                <select name="stream-type" [(ngModel)]="type" (change)="changeStreamType()">
            <option [value]="item.type" *ngFor="let item of sideNavstreamtypes">
                {{item.type}}
            </option>
        </select>
        <p *ngIf="streamTypeError" class="text-red">{{streamTypeError}}</p>

                <app-applications *ngIf="showAppFromSideNav" [pageSize]="5" [showSelectColoumn]=true [showControlBar]=false [displayedColumns]="['ADD','name','description','creation']" multiSelect="false"></app-applications>
                <app-digitalTwins *ngIf="showDeviceFromSideNav" [pageSize]="5" [showSelectColoumn]=true [showControlBar]=false [displayedColumns]="['ADD','name','description','creation']" multiSelect="false"></app-digitalTwins>
            </ng-container>

            <select *ngIf="!iotService.sideNavStream && !iotService.deviceStream" name="stream-type" [(ngModel)]="type" (change)="changeStreamType()">
            <option [value]="item.type" *ngFor="let item of streamtypes">
                {{item.type}}
            </option>
        </select>
            <select *ngIf="iotService.deviceStream" name="stream-type" [(ngModel)]="type" (change)="changeStreamType()">
            <option [value]="item.type" *ngFor="let item of devicestreamtypes">
                {{item.type}}
            </option>
        </select>

            <label for="stream-direction">{{ 'direction' | translate }}
        </label>


            <select name="stream-direction" [(ngModel)]="direction" (change)="changeDirection()">
            <option [value]="item.direction" *ngFor="let item of streamDirection">
                {{item.direction}}
            </option>
        </select>
        <p *ngIf="directionError" class="text-red">{{directionError}}</p>

            <p style=" margin-left: 15px;" *ngIf="toggleSettingFlag" class="blue-highlight" (click)="logMode()">
                {{'toggle.advanced.settings' | translate}}
            </p>

            <div *ngIf="showLogLevel" class="form-group">
                <div class="row">

                    <div class="component">
                        <label for="log-mode">{{ 'log.mode' | translate }}</label>
                        <select name="mode" [(ngModel)]="mode">
                        <option [value]="item.mode" *ngFor="let item of logModes">
                            {{item.mode}}
                        </option>
                    </select>
                    </div>

                    <div class="component">
                        <label for="log-expiration">{{ 'log.expiration' | translate }}</label>
                        <select name="expiration" [(ngModel)]="expiration">
                        <option [value]="item.expiration" *ngFor="let item of logExpirations">
                            {{item.expiration}}
                        </option>
                    </select>
                    </div>

                    <div class="component">
                        <label for="stream-quota">{{ 'stream.quota' | translate }}</label>
                        <input [(ngModel)]="quota" name="quota" id="stream-quota" type="text">
                    </div>
                </div>
                <div class="row">
                    <div class="component">
                        <label for="par-obfuscation">{{ 'stream.par.obfuscation' | translate }}</label>
                        <select name="obfuscation" [(ngModel)]="obfuscation">
                        <option [value]="item.obfuscation" *ngFor="let item of parObfuscation">
                            {{item.obfuscation}}
                        </option>
                    </select>
                    </div>

                    <div class="component">
                        <label for="par-compression">{{ 'stream.par.compression' | translate }}</label>
                        <select name="compression" [(ngModel)]="compression">
                        <option [value]="item.compression" *ngFor="let item of parCompression">
                            {{item.compression}}
                        </option>
                    </select>
                    </div>

                    <div class="component">
                        <label for="stream-throughput">{{ 'stream.throughput' | translate }}</label>
                        <select name="throughput" [(ngModel)]="throughput">
                        <option [value]="item.throughput" *ngFor="let item of throughputStratergy">
                            {{item.throughput}}
                        </option>
                    </select>
                    </div>
                </div>


                <div class="row">
                    <div class="component">
                        <label for="ack-strategy">{{ 'stream.ack.strategy' | translate }}</label>
                        <select name="strategy" [(ngModel)]="strategy">
                        <option [value]="item.strategy" *ngFor="let item of ackStrategy">
                            {{item.strategy}}
                        </option>
                    </select>
                    </div>
                    <div class="component">
                        <label for="par-persistence">{{ 'stream.par.persistence' | translate }}</label>
                        <select name="persistence" [(ngModel)]="persistence">
                        <option [value]="item.persistence" *ngFor="let item of parPersistence">
                            {{item.persistence}}
                        </option>
                    </select>
                    </div>
                    <div class="component">
                        <label for="par-digest">{{ 'stream.par.digest' | translate }}</label>
                        <select name="digest" [(ngModel)]="digest">
                        <option [value]="item.digest" *ngFor="let item of parDigest">
                            {{item.digest}}
                        </option>
                    </select>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="showProtocol">

                <label for="stream-protocol">{{ 'protocol' | translate }}</label>

                <select name="stream-protocol" [(ngModel)]="protocol" (change)="changeProtocol()">
                <option [value]="item.protocol" *ngFor="let item of streamProtocols">
                    {{item.protocol}}
                </option>

            </select>
            </ng-container>
            <p *ngIf="protocolError" class="text-red">{{protocolError}}</p>

            <ng-container *ngIf="customProtocol">
                <label for="stream-custom-protocol">{{ 'custom' | translate }}</label>
                <select *ngIf="showCustomFTP" name="custom-ftp-protocol" [(ngModel)]="ftp" (change)="changeFTPProtocol()">
                <option [value]="item.ftp" *ngFor="let item of customFTPProtocols">
                    {{item.ftp}}
                </option>
                <p *ngIf="customProtocolError" class="text-red">{{customProtocolError}}</p>

            </select>
                <select *ngIf="showCustomCAPS" name="custom-caps-protocol" [(ngModel)]="caps" (change)="changeCAPSProtocol()">
            <option [value]="item.caps" *ngFor="let item of customCAPSProtocols">
                {{item.caps}}
            </option>
        </select>
            </ng-container>

            <ng-container *ngIf="type=='Messaging' && protocol=='BATCH'">

                <!-- <label for="document-info">{{ 'document-info' | translate }}</label> -->


                <app-documentInfo [pageSize]="5" [showControlBar]=false [displayedColumns]="['ADD','supportedDocTemplateType','docTemplateVersion','ACTIVE']" [showSelectColoumn]=true [showEnableColumn]=true multiSelect="false">

                </app-documentInfo>

                <ng-container *ngIf="type=='Messaging'">
                    <!-- <label for="from-code">{{ 'from.code' | translate }}</label> -->
                    <app-fromTPCodes [pageSize]="5" [showControlBar]=false [displayedColumns]="['ADD','code','qualifier']" [showSelectColoumn]=true [showEnableColumn]=true multiSelect="false"></app-fromTPCodes>

                    <!-- <label for="to-code">{{ 'to.code' | translate }}</label> -->
                    <app-toTPCodes [pageSize]="5" [showControlBar]=false [displayedColumns]="['ADD','code','qualifier']" [showSelectColoumn]=true [showEnableColumn]=true multiSelect="false"></app-toTPCodes>
                </ng-container>
            </ng-container>
            <ng-container>
                <div *ngIf="batchConfig">
                    <label for="batch-config">{{ 'batch.config' | translate }}</label>
                    <hr style="margin-top:0px;margin-bottom:5px">
                    <form>
                        <div style="padding-top:10px">
                            <label for="schedule">{{ 'schedule' | translate }}</label>

                            <input [(ngModel)]="stream.batchConfig.schedule" type="text" name="stream.batchConfig.schedule" style="margin-bottom:5px"> {{'cronFormat.desc'}}
                            <!--  <br>{{getCron2()}} -->

                            <!-- <label for="geofenceDescription">{{'description' | translate}}</label>
          <input placeholder="{{'optional' | translate}}" [(ngModel)]="geofenceDescription" id="geofence_desc"
            type="text" name="geofenceDescription"> -->
                        </div>
                        <div>
                            <label for="handler">{{ 'handler' | translate }}</label>

                            <input [(ngModel)]="stream.batchConfig.handler" type="text" id="batchConfig_handler" name="stream.batchConfig.handler" placeholder="">

                        </div>
                        <div>
                            <label for="maxBytes">{{'maxBytes' | translate }}</label>

                            <input [(ngModel)]="stream.batchConfig.maxBytes" type="text" id="batchConfig_maxBytes" name="stream.batchConfig.maxBytes" placeholder="">

                        </div>
                        <div>
                            <label for="max-messages">{{'maxMessages' | translate }}</label>

                            <input [(ngModel)]="stream.batchConfig.maxMessages" type="text" name="stream.batchConfig.maxMessages" id="batchConfig_maxMessages" placeholder="">

                        </div>
                        <div>
                            <label for="delimiter">{{'delimiter' | translate }}</label>

                            <input [(ngModel)]="stream.batchConfig.handlerAttributes.delimiter" name="stream.batchConfig.handlerAttributes.delimiter" type="text" id="batchConfig_delimiter" placeholder="">

                        </div>
                    </form>


                </div>

            </ng-container>

            <ng-container *ngIf="showSecurity">
                <header>
                    <h2>{{ 'security' | translate }}</h2>
                </header>
                <label for="auth-type">{{ 'auth.type' | translate }}</label>
                <select name="auth-type" [(ngModel)]="auth" (change)="changeAuthType()">
                <option [value]="item.auth" *ngFor="let item of authTypes">
                    {{item.auth}}
                </option>
            </select>
                <ng-container *ngIf="auth == 'Public Key' && direction!='Active Receiving' && direction!='Passive Sending'">

                    <label for="name">{{ 'name' | translate }}</label>
                    <input id="name" name="publicKeyName" [(ngModel)]="publicKeyName" type="text" aria-label="User Name">

                    <label for="secret">{{ 'description' | translate }}</label>
                    <input id="description" name="publicKeyDesc" [(ngModel)]="publicKeyDesc" type="text" aria-label="Description">

                    <label for="public-key-data">{{'public.secsh.format' | translate}}</label>
                    <div class="public-key-data">
                        <textarea rows="13" placeholder="required" value="" (keyup)="publicKeyData($event)" aria-label="Certificate-data"></textarea>
                        <label for="example">{{'key.example.secsh' | translate}} </label>
                        <p>---- BEGIN SSH2 PUBLIC KEY ---- AAAAB3NzaC1kc3MAAACBAPY8ZOHY2yFSJA6XYC9HRwNHxaehvx5wOJ0rzZdzoSOXxbETW6 ToHv8D1UJ/z+zHo9Fiko5XybZnDIaBDHtblQ+Yp7StxyltHnXF1YLfKD1G4T6JYrdHYI14 Om1eg9e4NnCRleaqoZPF3UGfZia6bXrGTQf3gJq2e7Yisk/gF+1VAAAAFQDb8D5cvwHWTZ
                            DPfX0D2s9Rd7NBvQAAAIEAlN92+Bb7D4KLYk3IwRbXblwXdkPggA4pfdtW9vGfJ0/RHd+N jB4eo1D+0dix6tXwYGN7PKS5R/FXPNwxHPapcj9uL1Jn2AWQ2dsknf+i/FAAvioUPkmdMc 0zuWoSOEsSNhVDtX3WdvVcGcBq9cetzrtOKWOocJmJ80qadxTRHtUAAACBAN7CY+KKv1gH pRzFwdQm7HK9bb1LAo2KwaoXnadFgeptNBQeSXG1vO+JsvphVMBJc9HSn24VYtYtsMu74q
                            XviYjziVucWKjjKEb11juqnF0GDlB3VVmxHLmxnAz643WK42Z7dLM5sY29ouezv4Xz2PuM ch5VGPP+CDqzCM4loWgV ---- END SSH2 PUBLIC KEY ----
                            <label for="example">{{'key.openSSH.format' | translate}} </label>
                        </p>
                        <p>ecdsa-sha2-nistp521 AAAAE2VjZHNhLXNoYTItbmlzdHA1MjEAAAAIbmlzdHA1MjEAAACFBAE7tnyqfvMISNTQHj1x6MIponNdEs8J2DwNE0pH5nJ0nFvswB/AsQQbv3ZS08i7tQzZ+116Kw4gu03cxLwcSWVPPgBYLAGpd1Pd6ilfKaXrVvcNX3OBAOUfYRldlCHZgaw+uu2ZbUTYw1Bkg+uh5c4Axmm7f23gpvG8hnR9swKA5oEuDQ==
                            SampleECDSA521

                        </p>
                    </div>
                </ng-container>

                <ng-container *ngIf="auth == 'Public Key' &&( direction == 'Active Receiving' || direction == 'Passive Sending')">

                    <label for="name">{{ 'name' | translate }}</label>
                    <input id="name" type="text" value="" [(ngModel)]="sshName" name="sshName" (keyup)="sshusername($event)" aria-label="User Name">
                    <p *ngIf="sshNameError" class="text-red">{{sshNameError}}</p>
                    <br>
                    <!-- [pageSize]="5" [showControlBar]=false [displayedColumns]="['ADD','Name']" [showSelectColoumn]=true [showEnableColumn]=true -->
                    <p *ngIf="sshKeyError" class="text-red">{{sshKeyError}}</p>
                    <app-sshKeyPairs [pageSize]="5" [showControlBar]=false [displayedColumns]="['ADD','name','description','algorithm']" [showSelectColoumn]=true [showEnableColumn]=true multiSelect="false">
                    </app-sshKeyPairs>
                </ng-container>

                <ng-container *ngIf="showCredentials && auth!='Public Key' && auth=='Basic' &&
     (direction=='Active Receiving' || direction=='Passive Sending')">

                    <label for="user-name">{{ 'user.name' | translate }}</label>
                    <input id="user-name" placeholder="required" [(ngModel)]="usernameValue" name="usernameValue" type="text" (keyup)="username($event)" aria-label="User Name">
                    <p *ngIf="userNameError" class="text-red">{{userNameError}}</p>

                    <label for="password">{{ 'password' | translate }}</label>
                    <input id="password" placeholder="required" [(ngModel)]="passwordValue" name="passwordValue"  [type]="hide ? 'password' : 'text'" (keyup)="password($event)" aria-label="Password">                    
                    <fa-icon *ngIf="hide" (click)="hide = !hide" [icon]="hidePassWord"></fa-icon>
                    <fa-icon *ngIf="!hide" (click)="hide = !hide" [icon]="showPassword"></fa-icon>

                    <p *ngIf="passwordError" class="text-red">{{passwordError}}</p>

                </ng-container>
                <ng-container *ngIf="auth=='Mutual Auth'">
                    <div>
                        <mat-checkbox #c style="margin-left: 15px;"  (click)="reverseChaining(!c.checked)">{{'reverse.certificate.order' | translate}}</mat-checkbox>
                    </div>
                    <p *ngIf="mutualAuthError" class="text-red">{{mutualAuthError}}</p>
                    <app-keyBundles [pageSize]="5" [showControlBar]=false [displayedColumns]="['ADD','name']" [showSelectColoumn]=true [showEnableColumn]=true multiSelect="false" ></app-keyBundles>
                </ng-container>
                <ng-container *ngIf="auth=='OAuth Token'">
                    <p *ngIf="tokenError" class="text-red">{{tokenError}}</p>
                    <app-oAuthTokens [pageSize]="5" [showControlBar]=false [displayedColumns]="['ADD','name']" [showSelectColoumn]=true [showEnableColumn]=true multiSelect="false"></app-oAuthTokens>
                </ng-container>
                <ng-container *ngIf="showAccessToken">
                    <label for="access-key">{{ 'access.key' | translate }}</label>
                    <input id="access-key" placeholder="required" [(ngModel)]="accessTokenKey" name="accessTokenKey" type="text" aria-label="User Name">

                    <label for="secret">{{ 'secret' | translate }}</label>
                    <input id="secret" placeholder="required"  [type]="hide ? 'password' : 'text'" [(ngModel)]="accessTokenSecret" name="accessTokenSecret" aria-label="User Name">                    
                    <fa-icon *ngIf="hide" (click)="hide = !hide" [icon]="hidePassWord"></fa-icon>
                    <fa-icon *ngIf="!hide" (click)="hide = !hide" [icon]="showPassword"></fa-icon>
                </ng-container>
                <div *ngIf="showCertificate && auth!= 'Public Key' && protocol!='FTP' && auth!='Mutual Auth' && auth!='OAuth Token'" class="certificate-data">

                    <form>
                        <label for="certificate-data" style="margin-left: -10px;">{{'certificate.pem.content' | translate}}</label>
                        <textarea style="margin-left: -10px;" rows="13" placeholder="required" id="certificate-data" value="" (keyup)="certificateData($event)" aria-label="Certificate-data"></textarea>

                    </form>
                    <p *ngIf="!showCertificateExample" style="margin-left: -10px;" class="blue-highlight" (click)="toggleExample()">{{'example.pem.certificate' | translate}}
                        <fa-icon [icon]="faAngleDown"></fa-icon>
                    </p>
                    <p *ngIf="showCertificateExample" style="margin-left: -10px;" class="blue-highlight" (click)="toggleExample()">{{'example.pem.certificate' | translate}}
                        <fa-icon [icon]="faAngleUp"></fa-icon>
                    </p>

                    <p *ngIf="showCertificateExample">-----BEGIN CERTIFICATE----- MIIDXTCCAkWgAwIBAgIJAJC1HiIAZAiIMA0GCSqGSIb3Df BAYTAkFVMRMwEQYDVQQIDApTb21lLVN0YXRlMSEwHwYDVx aWRnaXRzIFB0eSBMdGQwHhcNMTExMjMxMDg1OTQ0WhcNMT jyzfN746vaInA1KxYEeI1Rx5KXY8zIdj6a7hhphpj2E04 C3Fayua4DRHyZOLmlvQ6tIChY0ClXXuefbmVSDeUHwc8Yu
                        B7xxt8BVc69rLeHV15A0qyx77CLSj3tCx2IUXVqRs5mlSbvA== -----END CERTIFICATE-----
                    </p>
                </div>
                <ng-container *ngIf=" direction!='Active Receiving' && direction!='Passive Receiving'">
                    <header>
                        <h2>{{ 'document.info' | translate }}</h2>
                    </header>
                    <label for="doc-type">{{ 'doc.type' | translate }}</label>
                    <select name="doc-type" [(ngModel)]="doc" (change)="changeDocType()">
                    <option [value]="item.doc" *ngFor="let item of docTypes">
                        {{item.doc}}
                    </option>
                </select>
                </ng-container>


                <ng-container *ngIf="streamService.docInfoList && direction!='Active Receiving' && direction!='Passive Receiving'">
                    <!-- <label for="document-info">{{ 'document-info' | translate }}</label> -->


                    <app-documentInfo [pageSize]="5" [showControlBar]=false [displayedColumns]="['ADD','supportedDocTemplateType','docTemplateVersion','ACTIVE']" [showSelectColoumn]=true [showEnableColumn]=true multiSelect="false">

                    </app-documentInfo>

                    <ng-container *ngIf="type=='Messaging'">
                        <!-- <label for="from-code">{{ 'from.code' | translate }}</label> -->
                        <app-fromTPCodes [pageSize]="5" [showControlBar]=false [displayedColumns]="['ADD','code','qualifier']" [showSelectColoumn]=true [showEnableColumn]=true multiSelect="false"></app-fromTPCodes>

                        <!-- <label for="to-code">{{ 'to.code' | translate }}</label> -->
                        <app-toTPCodes [pageSize]="5" [showControlBar]=false [displayedColumns]="['ADD','code','qualifier']" [showSelectColoumn]=true [showEnableColumn]=true multiSelect="false"></app-toTPCodes>
                    </ng-container>

                    <ng-container *ngIf="type=='Sync' && direction =='Active Sending'">
                        <!-- <label for="from-code">{{ 'from.code' | translate }}</label> -->
                        <app-fromTPCodes [pageSize]="5" [showControlBar]=true [displayedColumns]="['ADD','code','qualifier']" [showSelectColoumn]=true [showEnableColumn]=true multiSelect="false" [instance]="1"></app-fromTPCodes>

                        <!-- <label for="to-code">{{ 'to.code' | translate }}</label> -->
                        <app-toTPCodes [pageSize]="5" [showControlBar]=false [displayedColumns]="['ADD','code','qualifier']" [showSelectColoumn]=true [showEnableColumn]=true multiSelect="false" [instance]="1"></app-toTPCodes><br><br>

                        <label for="document-info">{{ 'response.mapping' | translate }}</label>

                        <app-documentInfo [pageSize]="5" [showControlBar]=false [displayedColumns]="['ADD','supportedDocTemplateType','docTemplateVersion','ACTIVE']" [showSelectColoumn]=true [showEnableColumn]=true multiSelect="false" [instance]="2">

                        </app-documentInfo>
                        <!-- <label for="from-code">{{ 'from.code' | translate }}</label> -->
                        <app-fromTPCodes [pageSize]="5" [showControlBar]=false [displayedColumns]="['ADD','code','qualifier']" [showSelectColoumn]=true [showEnableColumn]=true multiSelect="false" [instance]="2"></app-fromTPCodes>

                        <!-- <label for="to-code">{{ 'to.code' | translate }}</label> -->
                        <app-toTPCodes [pageSize]="5" [showControlBar]=false [displayedColumns]="['ADD','code','qualifier']" [showSelectColoumn]=true [showEnableColumn]=true multiSelect="false" [instance]="2"></app-toTPCodes>

                        <label id="sync">{{'sync.authorization.type' | translate}}</label>
                        <input for="sync" name="syncAuthType" [(ngModel)]="syncAuthType" style="width: 50%;">
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="!streamService.docInfoList && direction!='Passive Receiving' && direction!='Active Receiving'">
                    <div style="margin-left: 15px;">
                        <mat-checkbox #c (click)="showDRS(!c.checked)">{{'document.recognition.script' | translate}}{{':' | translate}}</mat-checkbox>
                    </div>
                    <ng-container *ngIf="drsScript">

                        <label for="stream-name">{{ 'description' | translate }}</label>
                        <input placeholder="required" name="drsDescription" [(ngModel)]="drsDescription" type="text" value="" aria-label="Description">
                        <br>
                        <!-- <br>
                    <button color="primary" [cdkCopyToClipboard]="getAceEditorValue()" (click)="copyToClipBoard()" mat-raised-button>
                        <fa-icon [icon]="faCopyIcon"></fa-icon> {{'cdkCopyToClipboard' | translate}}
                    </button>
                    <br>
                    <br> -->
                    <div style="margin: 0 5px 0 5px;">      
                         <app-ace></app-ace>
                    </div>


                    </ng-container>
                </ng-container>



                <ng-container *ngIf="showDeliveryScript && protocol!=S3">
                    <header>
                        <h2>{{ 'delivery.script' | translate }}</h2>
                    </header>

                    <label for="stream-name">{{ 'destination.url' | translate }}</label>
                   
                    <input placeholder="required" id="stream-name" type="text" name="deliveryUrlValue" [(ngModel)]="deliveryUrlValue" (keyup)="deliveryUrl($event)" aria-label="Destination Url">
                    <!-- <br> -->
                    <p *ngIf="deliveryUrlError" class="text-red">{{deliveryUrlError}}</p>

                    <ng-container *ngIf="direction == 'Active Receiving'">
                        <ng-container *ngIf="(ftp == 'VPN' || ftp == 'SSL' || ftp == 'SSH') && protocol!='CAPS'">
                            <!-- <button color="primary" [cdkCopyToClipboard]="getAceEditorValue()" (click)="copyToClipBoard()" mat-raised-button>
                            <fa-icon [icon]="faCopyIcon"></fa-icon> {{'cdkCopyToClipboard' | translate}}
                        </button>
                        <br> -->
                            <!-- <br> -->
                            <div style="margin: 0 5px 0 5px;">      
                                <app-ace></app-ace>
                           </div>

                        </ng-container>

                        <ng-container *ngIf="(ftp == 'VPN Schedule' || ftp == 'SSL Schedule' || ftp == 'SSH Schedule') && protocol!='CAPS'">
                            <!-- <button color="primary" [cdkCopyToClipboard]="getAceEditorValue()" (click)="copyToClipBoard()" mat-raised-button>
                            <fa-icon [icon]="faCopyIcon"></fa-icon> {{'cdkCopyToClipboard' | translate}}
                        </button>
                        <br> -->
                            <br>
                            <div style="margin: 0 5px 0 5px;">      
                                <app-ace></app-ace>
                           </div>

                        </ng-container>

                        <ng-container *ngIf="caps == 'HTTPS Blast' && protocol!='FTP'">
                            <!-- <button color="primary" [cdkCopyToClipboard]="getAceEditorValue()" (click)="copyToClipBoard()" mat-raised-button>
                            <fa-icon [icon]="faCopyIcon"></fa-icon> {{'cdkCopyToClipboard' | translate}}
                        </button>
                        <br> -->
                            <br>
                            <div style="margin: 0 5px 0 5px;">      
                                <app-ace></app-ace>
                           </div>

                        </ng-container>
                        <ng-container *ngIf="caps=='Scheduled HTTPS'  && protocol!='FTP'">

                        <div style="margin: 0 5px 0 5px;">      
                            <app-ace></app-ace>
                       </div>
                        </ng-container>
                        <ng-container *ngIf=" protocol == 'CAPS' && caps=='HTTPS Sync'">
 
                        <div style="margin: 0 5px 0 5px;">      
                            <app-ace></app-ace>
                       </div>
                        </ng-container>

                        <ng-container *ngIf=" protocol == 'AQ'">
                            
                        <div style="margin: 0 5px 0 5px;">      
                            <app-ace></app-ace>
                       </div>
                        </ng-container>

                    </ng-container>
                    <ng-container *ngIf="direction == 'Passive Sending'">
                        <ng-container *ngIf="(ftp == 'VPN' || ftp == 'SSL' || ftp == 'SSH') && protocol!='CAPS'">

                            <br>
                            <br>
                            <div style="margin: 0 5px 0 5px;">      
                                <app-ace></app-ace>
                           </div>

                        </ng-container>

                        <ng-container *ngIf=" protocol=='CAPS'">

                            <br>
                            <div style="margin: 0 5px 0 5px;">      
                                <app-ace></app-ace>
                           </div>

                        </ng-container>
                        <ng-container *ngIf=" protocol == 'AQ'">

                            <div style="margin: 0 5px 0 5px;">      
                                <app-ace></app-ace>
                           </div>
                        </ng-container>



                    </ng-container>
                    <label for="code-example">{{ 'code.example' | translate }}</label>
                    <select name="code-example" [(ngModel)]="streamService.example" (change)="changeCodeExample()">
                    <option [value]="item.example" *ngFor="let item of streamService.codeExamples">
                        {{item.example}}
                    </option>
                </select>
                    <br><br>
                    <ng-container *ngIf="streamService.example=='Active Receiving Sync Loop back request'">
                        <div style="margin: 0 5px 0 5px;">      
                            <app-ace></app-ace>
                       </div>
                    </ng-container>
                    <ng-container *ngIf="streamService.example=='Active Receiving Sync Post with request and response'">
                        <div style="margin: 0 5px 0 5px;">      
                            <app-ace></app-ace>
                       </div>
                    </ng-container>
                    <ng-container *ngIf="streamService.example=='Active Receiving Post with dynamic header values'">
                        <div style="margin: 0 5px 0 5px;">      
                            <app-ace></app-ace>
                       </div>
                    </ng-container>
                    <ng-container *ngIf="streamService.example=='Active Receiving Post with dynamic path params'">
                        <div style="margin: 0 5px 0 5px;">      
                            <app-ace></app-ace>
                       </div>
                    </ng-container>
                    <ng-container *ngIf="streamService.example=='Passive Sending GET with dynamic path params'">
                        <div style="margin: 0 5px 0 5px;">      
                            <app-ace></app-ace>
                       </div>
                    </ng-container>
                    <!-- <ng-container *ngIf="protocol=='S3' && (caps!='HTTPS Blast' || caps!='Scheduled HTTPS') && protocol!='ftp'">
         <app-ace></app-ace>
      </ng-container> -->
                    <header>
                        <h2>{{ 'retry.settings' | translate }}</h2>
                    </header>
                    <label for="retry-attempts">{{ 'retry.attempts' | translate }}</label>

                    <input id="retry-attempts" [(ngModel)]="retryAttempts" type="text" name="retryAttempts" style="margin-bottom:5px">
                    <label for="delay-retry-attempts">{{ 'delay.retry.attempts' | translate }}</label>

                    <input id="delay-retry-attempts" type="text" name="delayRetryAttempts" [(ngModel)]="delayRetryAttempts"> {{ 'seconds' | translate }}

                    <label for="retry-timeout">{{ 'retry.timeout' | translate }}</label>

                    <input id="retry-timeout" type="text" name="retryTimeout" [(ngModel)]="retryTimeout"> {{ 'seconds' | translate }}

                    <label for="delivery-enabled">{{ 'delivery.enabled' | translate }}</label>
                    <ng-container *ngIf="enableDelivery">
                        <mat-checkbox  style="margin-left:15px;" [checked]='true' #c (click)="deliveryEnabled(!c.checked)"></mat-checkbox>
                      </ng-container>
                      <ng-container *ngIf="!enableDelivery">
                      <mat-checkbox  style="margin-left:15px;" #c (click)="deliveryEnabled(!c.checked)"></mat-checkbox>
                      </ng-container>
                </ng-container>

                <ng-container *ngIf="protocol=='S3'">
                <header>
                    <h2>{{ 'retry.settings' | translate }}</h2>
                </header>
                <label for="retry-attempts">{{ 'retry.attempts' | translate }}</label>

                <input id="retry-attempts" [(ngModel)]="retryAttempts" type="text" name="retryAttempts" style="margin-bottom:5px">
                <label for="delay-retry-attempts">{{ 'delay.retry.attempts' | translate }}</label>

                <input id="delay-retry-attempts" type="text" name="delayRetryAttempts" [(ngModel)]="delayRetryAttempts"> {{ 'seconds' | translate }}

                <label for="retry-timeout">{{ 'retry.timeout' | translate }}</label>

                <input id="retry-timeout" type="text" name="retryTimeout" [(ngModel)]="retryTimeout"> {{ 'seconds' | translate }}

                <label for="delivery-enabled">{{ 'delivery.enabled' | translate }}</label>
                <ng-container *ngIf="enableDelivery">
                    <mat-checkbox  style="margin-left:15px;" [checked]='true' #c (click)="deliveryEnabled(!c.checked)"></mat-checkbox>
                  </ng-container>
                  <ng-container *ngIf="!enableDelivery">
                  <mat-checkbox  style="margin-left:15px;" #c (click)="deliveryEnabled(!c.checked)"></mat-checkbox>
                </ng-container>
                </ng-container>
                <ng-container *ngIf="showSchedule">
                    <header>
                        <h2>{{ 'delivery.scheduled' | translate }}</h2>
                    </header>
                    <label for="delivery-schedule">{{ 'delivery.schedule' | translate }}</label>

                    <input id="delivery-schedule" [(ngModel)]="cronValue" type="text" name="cronValue">{{'cron.format' | translate}}
                    <br>
                    <p>{{getCron()}}</p>
                    <p *ngIf="deliveryScheduleError" style="color: red;">{{deliveryScheduleError}}</p>

                </ng-container>
            </ng-container>

            <br>
            <!-- <div style="padding-left: 2%;"> -->
            <button class="btn btn-secondary" *ngIf="iotService.viewApplication" (click)="goback()">{{ 'go.back' | translate}}</button>
            <button class="btn btn-primary" *ngIf="!newStreamCreated" (click)="save()">{{ 'save' | translate}}</button>
            <br>
            <br>
            <!-- </div> -->
        </form>

    </div>

    <app-stream-credentials *ngIf="streamService.streamCreated"></app-stream-credentials>
</mat-sidenav-container>
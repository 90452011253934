import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-code-examples',
  templateUrl: './code-examples.component.html',
  styleUrls: ['./code-examples.component.scss']
})
export class CodeExamplesComponent implements OnInit {
  exampleDrodown = "Unselected";
  example;
  APIExample;
  comment;
  code;
  @Input() codeExampleType:string = "";

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    if(this.codeExampleType == "orchestration"){
      this.exampleDrodown = "Unselected";
      this.example = "View Snippets";
    } else if(this.codeExampleType == "rule"){
      this.exampleDrodown = "ActionScript";
      this.example = "Send Email";
    }
  }

  loadAPIJSON(){
    var filepath = "";
    if (this.exampleDrodown == "Device") {
        filepath = 'assets/json/device.json';
    } else if (this.exampleDrodown == "AttributeType") {
        filepath = 'assets/json/attribute-type.json';
    } else if (this.exampleDrodown == "DeviceTemplate") {
        filepath = 'assets/json/device-template.json';
    } else if (this.exampleDrodown == "EventTemplate") {
        filepath = 'assets/json/event-template.json';
    } else if (this.exampleDrodown == "CommandTemplate") {
        filepath = 'assets/json/command-template.json';
    } else if (this.exampleDrodown == "Group") {
        filepath = 'assets/json/group.json';
    } else if (this.exampleDrodown == "GroupEntitlement") {
        filepath = 'assets/json/group-entitlement.json';
    } else if (this.exampleDrodown == "GroupMembership") {
        filepath = 'assets/json/group-membership.json';
    } else if (this.exampleDrodown == "PersonApis") {
        filepath = 'assets/json/person.json';
    } else if (this.exampleDrodown == "SearchApis") {
        filepath = 'assets/json/search-apis.json';
    } else if (this.exampleDrodown == "GroupTemplate") {
        filepath = 'assets/json/groupTemplate.json';
    } else if (this.exampleDrodown == "MessageComposerApis") {
        filepath = 'assets/json/messageComposer.json';
    } else if (this.exampleDrodown == "KeyValueServiceApis") {
        filepath = 'assets/json/keyValueService.json';
    } else if (this.exampleDrodown == "OrganizationApis") {
        filepath = 'assets/json/organization.json';
    } else if (this.exampleDrodown == "GeofenceApis") {
        filepath = 'assets/json/geofencingApis.json';
    } else if (this.exampleDrodown == "DeviceHealthApis") {
        filepath = 'assets/json/deviceHealthApis.json';
    } else if (this.exampleDrodown == "JWTTokenApis") {
        filepath = 'assets/json/jwtTokenApis.json';
    }else if (this.exampleDrodown == "OAuthTokenApis") {
        filepath = 'assets/json/oauthTokenApis.json';
    }else if (this.exampleDrodown == "MFAApis") {
        filepath = 'assets/json/mfaApis.json';
    }else if (this.exampleDrodown == "TrackingApis") {
        filepath = 'assets/json/trackingApis.json';
    }else if (this.exampleDrodown == "ShipmentApis") {
        filepath = 'assets/json/shipment.json';
    }else if (this.exampleDrodown == "AssetApis") {
        filepath = 'assets/json/asset.json';
    }
    else if (this.exampleDrodown == "SCIMApis") {
        filepath = 'assets/json/scim.json';
    }
    else if (this.exampleDrodown == "ExAccApis") {
        filepath = 'assets/json/exacc.json';
    }
    else if (this.exampleDrodown == "KvstorageApis") {
        filepath = 'assets/json/kvstorage.json';
    }

    if (filepath != "") {
        this.loadJSONFile(filepath).subscribe(payload => {
        this.APIExample = payload;
        this.example = this.APIExample[0].cmd;
        this.exampleChange();
    });
    } else if(this.exampleDrodown == "Base64Utils"){
      // this.gatewayBase64UtilExamples = "Encode";
    } else{
       this.example = "Send Email";
		}
  }

  loadJSONFile(filepath){
    return this.http.get(filepath);
  }

  exampleChange(){
    this.APIExample.forEach(value => {
       if (value.cmd == this.example) {
           value.code = value.code.replace(/\'/g, "\"");
           this.comment = value.comment;
           var apiName = "utils."
           if (this.codeExampleType == "orchestration") {
               var apiName = "apiProxyUtil."
           }
           this.code = value.code.replace("utils.", apiName);
       }
     });
  }
}

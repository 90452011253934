import { Injectable } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { IotService } from '../service/iot.service';
import { ListRoutesService } from './ListRoutes.service';

@Injectable({
	providedIn: 'root'
})

export class RoutesService extends ListRoutesService {

	stream;
	eventGroup;
	device;
	documentInfo;
	routingSourceRelationship;
	routingTopic;
	errorMessage: string;
	successMessage: string;
	constructor(public iotService: IotService) {
		super(iotService);
	}

	fnOnSelectionSupport(results) {
		const that = this;
	}

	fnRemoveSelectionSupport(results) {
		const that = this;
	}

	setStream(stream) {
		const that = this;
		this.stream = stream;
		// this.streamName = streamName;
	}

	getStream() {
		return this.stream;
	}
	setDocumentInfo(documentInfo) {
		const that = this;
		this.documentInfo = documentInfo;
		// this.streamName = streamName;
	}

	getDocumentInfo() {
		return this.documentInfo;
	}
	setEventGroup(eventGroup) {
		const that = this;
		this.eventGroup = eventGroup;
		// this.streamName = streamName;
	}

	getEventGroup() {
		return this.eventGroup;
	}
	setDevice(device) {
		const that = this;
		this.device = device;
		// this.streamName = streamName;
	}


	getDevice() {
		return this.device;
	}
	setRoutingSourceRelationship(routingSourceRelationship) {
		const that = this;
		this.routingSourceRelationship = routingSourceRelationship;
		// this.streamName = streamName;
	}

	getRoutingSourceRelationship() {
		return this.routingSourceRelationship;
	}
	resetErrorMessage() {
		this.errorMessage = '';
	}
	resetSuccessMessage() {
		this.successMessage = '';
	}

}

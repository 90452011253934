import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  constructor() { }

  devices=[];

  pathCoordinates=[];
  pathTimes=[];
  latitude;
  longitude;
  geofence;

  setGeofence(geofence){
    this.geofence=geofence;
  }

  getGeofence(){
    return this.geofence;
  }

  setLatitude(lat){
    this.latitude=lat;
  }

  getLatitude(){
    return this.latitude;
  }

  setLongitude(lat){
    this.longitude=lat;
  }

  getLongitude(){
    return this.longitude;
  }

  setPathCoordinates(coordinates){
    this.pathCoordinates=coordinates;
  }

  getPathCoordinates(){
    return this.pathCoordinates;
  }

  setPathTimes(times){
    this.pathTimes=times;
  }

  getPathTimes(){
    return this.pathTimes;
  }

  setDevices(devices){
    this.devices=devices;
  }

  getDevices(){
    return this.devices;
  }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IotService } from '../service/iot.service';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { MatChipInputEvent } from '@angular/material/chips';
import { TranslateService } from '@ngx-translate/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {  faCheckCircle,faCheck,faCopy,faTimes, faExclamationTriangle, faExclamation, faMap } from '@fortawesome/free-solid-svg-icons';
import { GeofencesService } from '../service/geofences.service';


@Component({
  selector: 'app-geofence-detail',
  templateUrl: './geofence-detail.component.html',
  styleUrls: ['./geofence-detail.component.scss']
})
export class GeofenceDetailComponent implements OnInit,OnDestroy {

  faCloseIcon = faTimes;
  faWarningIcon = faExclamationTriangle;  
  faCheckIcon = faCheck;
  faCopyIcon = faCopy;
  faCriticalIcon=faExclamation;
  faSuccessIcon=faCheckCircle;
  showMap=faMap;
  
  tags=[];

  supportLanguages = ['en'];
  showGeofence=true;
  geofenceId: any;
  geofence;
  tagClass='tagInput';
  isNotificationCreated=false;
  
  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(private router: Router, private route: ActivatedRoute, private iotService: IotService,
    private sideNavComponent: SideNavComponent, private translateService: TranslateService,
     public geofencesService:GeofencesService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }


  ngOnInit(): void {
    this.iotService.viewGeofence=true;
    this.geofencesService.showNotification=false;
    this.route.params.subscribe(params => {
      this.geofenceId = params['id'];
      this.loadGeofence(this.geofenceId);
      this.getNotification();
      this.geofencesService.geofenceId=this.geofenceId;
    });
  }


  getNotification() {
    const that=this;
    that.iotService.getCuiObjResponse().getNotification({
      targetType: 'GEOFENCE',
      targetId: that.geofenceId
    }).then(function (response) {
      if(response.length>0){
        that.isNotificationCreated=true;
      }
      else{
        that.isNotificationCreated=false;
      }
      
    }).fail(function (err) {
        console.log("Error occured while fetching notification with geofence id - ", that.geofencesService.geofenceId);
  
      });

  }


  loadGeofence(geofenceId: any) {
    const that = this;
    that.iotService.getCuiObjResponse().getGeofence({
      id: geofenceId
    }).then(function (response) {
      that.geofence=response;
      that.geofencesService.geofence=that.geofence;
      if(that.geofence.tags==undefined){
        that.geofence['tags']=[];
      }
      that.geofencesService.polygon=that.geofence.polygon;
      that.geofencesService.maxSearchRadius=that.geofence.maxSearchRadius;
      that.sideNavComponent.menuClear();
      that.sideNavComponent.menuChange('Dashboard', 'sub-type');
      that.sideNavComponent.menuChange('geofences.header', 'sub-type');
      that.sideNavComponent.menuChange(that.geofence.name[0].text, 'sub-type');
    }).fail(function (err) {
      console.log("Error occured while fetching geofence with id - ",geofenceId);

    });
  }

  add(event: MatChipInputEvent): void {

    this.tagClass = 'tagInput';
    const input = event.input;
    const value = event.value;
    const index = this.geofence.tags.indexOf(value);
    if (index < 0) {
      if ((value || '').trim()) {
        if (  this.geofence.tags != undefined && this.geofence.tags.includes(value)) {
          if (input) {
            input.value = '';
          }
        }
        else {
          this.geofence.tags.push(value);
          this.addTag(value);
          if (input) {
            input.value = '';
          }
        }
      }
    } else {
      this.tagClass = 'tagInput-duplicate';
    }
  }

  remove(tag: string): void {
    const that = this;
    that.geofence.tags.forEach((value, index) => {
      if (value == tag) that.geofence.tags.splice(index, 1);
    });
    that.removeTag(tag);

  }
  addTag(tag){
    const that = this;
    that.iotService.getCuiObjResponse().addGeofenceTag({
      geofenceId:that.geofence.id,
      tag: tag
    }).then(function (response) {
      console.log("Tag added to geofence successfully "+ tag);

    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.geofencesService.errorMessage = err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }

  removeTag(tag){
    const that = this;
    that.iotService.getCuiObjResponse().deleteGeofenceTag({
      geofenceId:that.geofence.id,
      tag: tag
    }).then(function (response) {
      console.log("Tag removed from geofence successfully "+ tag);

    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.geofencesService.warningMessage = err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }


  activateGeofence(resourceId){
		const that = this;
		that.iotService.getCuiObjResponse().activateGeofence({
			geofenceId: resourceId
		}).then(function (response) {
			that.loadGeofence(that.geofenceId);
			that.geofencesService.fnResetList();
		}).fail(function (err) {
      console.log("Error occured while activating Geofence");
		});
	}
	deactivateGeofence(resourceId){
		const that = this;
		that.iotService.getCuiObjResponse().deactivateGeofence({
			geofenceId: resourceId
		}).then(function (response) {
      that.loadGeofence(that.geofenceId);
		}).fail(function (err) {
      console.log("Error occured while deactivating Geofence");
		});
	}



  ngOnDestroy(){
    this.iotService.viewGeofence=false;
  }

}

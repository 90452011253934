

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IotService } from '../service/iot.service';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { MatChipInputEvent } from '@angular/material/chips';
import { TranslateService } from '@ngx-translate/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
    faCheckCircle, faCheck, faCopy, faTimes, faExclamationTriangle, faExclamation,
    faMap, faExternalLinkAlt, faMobile, faAt
} from '@fortawesome/free-solid-svg-icons';
import { GeofencesService } from '../service/geofences.service';
import { NotificationService } from '../service/notification.service';

@Component({
    selector: 'app-create-notification',
    templateUrl: './create-notification.component.html',
    styleUrls: ['./create-notification.component.scss']
})
export class CreateNotificationComponent implements OnInit {

    faCloseIcon = faTimes;
    faWarningIcon = faExclamationTriangle;
    faCheckIcon = faCheck;
    faCopyIcon = faCopy;
    faCriticalIcon = faExclamation;
    faSuccessIcon = faCheckCircle;
    showMap = faMap;
    faExternalLink = faExternalLinkAlt;
    faMobile = faMobile;
    faAt = faAt;

    tags = [];

    authType = "NONE"


    authTypes = [

        {
            id: 1,
            auth: 'NONE'
        },
        {
            id: 2,
            auth: 'BASIC'
        }
    ];

    supportLanguages = ['en'];
    showGeofence = true;
    geofenceId: any;
    // geofence;
    notification;
    tagClass = 'tagInput';

    separatorKeysCodes: number[] = [ENTER, COMMA];
    dwellTimeCondition: boolean;
    enterCondition: boolean;
    targetType = 'GEOFENCE';
    exitCondition: boolean;
    notificationMethod = 'Email';
    email = {
        "to": '',
        "subject": '',
        "content": ''
    };
    smsNumbers = [];
    headers: any[];
    origWebhooks: any;
    method: any;
    username;
    password;
    targetId: any;

    webHooks = {
        "authentication": {
            "authType": "",
            "authContent": {
                "username": "",
                "password": ""
            }
        },
        "serviceCall": {
            "url": "",
            "method": "",
            "headers": "",
            "body": ""
        }
    }
    emails = [];
    sms = {
        "to": '',
        "content": ''
    };
    hasError: boolean = false;

    constructor(private router: Router, private route: ActivatedRoute, private iotService: IotService,
        private sideNavComponent: SideNavComponent, private translateService: TranslateService,
        public geofencesService: GeofencesService, public notificationService: NotificationService) {
        this.translateService.addLangs(this.supportLanguages);
        this.translateService.setDefaultLang('en');
    }


    ngOnInit(): void {
        // this.iotService.viewGeofence = true;
        this.route.params.subscribe(params => {
            this.geofenceId = params['id'];
            this.targetId = this.geofenceId;
            // this.loadNotification();
            this.initNotification();
            
        });
        this.sideNavComponent.menuClear();
        this.sideNavComponent.menuChange('Dashboard', 'sub-type');
        this.sideNavComponent.menuChange('geofences.header', 'sub-type');
        this.sideNavComponent.menuChange(this.geofencesService.geofence.name[0].text, 'sub-type');
        //this.sideNavComponent.menuChange('Notifications', 'sub-type');
        this.sideNavComponent.menuChange('Create Notification', 'sub-type');
    }
    loadGeofence(){
        const that=this;
        that.sideNavComponent.menuClear();
        that.sideNavComponent.menuChange('Dashboard', 'sub-type');
        that.sideNavComponent.menuChange('geofences.header', 'sub-type');
        that.sideNavComponent.menuChange(that.geofencesService.geofence.name[0].text, 'sub-type');
        that.geofencesService.showNotification=false
  
    }

    initNotification() {
        const that = this;
        that.notification = {
            "creator": that.iotService.getCreator(),
            "creatorAppId": that.iotService.getCreatorAppId(),
            "realm": that.iotService.getRealm(),
            "name": [
                {
                    "lang": "en_us",
                    "text": ''
                }
            ],
            "description": [
                {
                    "lang": "en_us",
                    "text": ''
                }
            ],
            "active": true,
            "tags": [],
            "frequency": 3


        }
    }



    convertToArray(str, delimeter) {

        var arr = str.split(delimeter);
        return arr;

    }

    addEmail(event: MatChipInputEvent) {
        const value = event.value;
        const input = event.input;
        const index = this.emails.indexOf(value);

        if (index < 0) {
            if ((value || '').trim()) {
                if (this.emails != undefined && this.emails.includes(value)) {
                    if (input) {
                        input.value = '';
                    }
                }
                else {
                    this.emails.push(value);
                    if (input) {
                        input.value = '';
                    }
                }
            }
        } else {
            this.tagClass = 'tagInput-duplicate';
        }



    }

    removeEmail(email) {
        const that = this;
        that.emails.forEach((value, index) => {
            if (value == email) that.emails.splice(index, 1);
        });
    }

    addSms(event: MatChipInputEvent) {
        const value = event.value;
        const input = event.input;
        const index = this.smsNumbers.indexOf(value);
        if (index < 0) {
            if ((value || '').trim()) {
                if (this.smsNumbers != undefined && this.smsNumbers.includes(value)) {
                    if (input) {
                        input.value = '';
                    }
                }
                else {
                    this.smsNumbers.push(value);
                    if (input) {
                        input.value = '';
                    }
                }
            }
        } else {
            this.tagClass = 'tagInput-duplicate';
        }

    }


    removeSms(sms) {
        const that = this;
        that.smsNumbers.forEach((value, index) => {
            if (value == sms) that.smsNumbers.splice(index, 1);
        });

    }
    add(event: MatChipInputEvent): void {

        this.tagClass = 'tagInput';
        const input = event.input;
        const value = event.value;
        const index = this.notification.tags.indexOf(value);
        if (index < 0) {
            if ((value || '').trim()) {
                if (this.notification.tags != undefined && this.notification.tags.includes(value)) {
                    if (input) {
                        input.value = '';
                    }
                }
                else {
                    this.notification.tags.push(value);
                    // this.addTag(value);
                    if (input) {
                        input.value = '';
                    }
                }
            }
        } else {
            this.tagClass = 'tagInput-duplicate';
        }
    }

    remove(tag: string): void {
        const that = this;
        that.notification.tags.forEach((value, index) => {
            if (value == tag) that.notification.tags.splice(index, 1);
        });
        // that.removeTag(tag);

    }

    removeRow(index) {
        this.headers.splice(index, 1);
    }

    addRow() {
        if (this.headers == undefined) {
            this.headers = [];
        }
        this.headers.push({
            Key: '',
            Value: ''
        });
    }



    save() {

        const that = this;
        if (that.notification.notificationMethods == undefined) {

            that.notification.notificationMethods = {}
        }


        if (that.email != undefined && that.emails.length > 0) {
            var email = {
                "to": '',
                "subject": '',
                "content": ''
            }

            email.to = that.addDelimeter(that.getArray(that.emails), ",");
            email.subject = that.email.subject;
            email.content = that.email.content;
            that.notification.notificationMethods.email = {};
            that.notification.notificationMethods.email = email;
        }
        else {
            that.notification.notificationMethods.email = undefined;

        }

        if (that.sms != undefined && that.smsNumbers.length > 0) {
            var sms = {
                "to": '',
                "content": ''
            }
            sms.to = that.addDelimeter(that.getArray(that.smsNumbers), ",");
            sms.content = that.sms.content;
            that.notification.notificationMethods.sms = sms;
        }
        else {
            that.notification.notificationMethods.sms = undefined;
        }


        that.notification.triggerPoints = [];

        if (that.dwellTimeCondition) {
            var condition = {
                "targetId": '',
                "targetType": '',
                "triggerType": ''
            }
            condition.targetType = that.targetType,
                condition.targetId = that.targetId,
                condition.triggerType = "DWELL_TIME"
            that.notification.triggerPoints.push(condition);

        }
        if (that.enterCondition) {
            var condition = {
                "targetId": '',
                "targetType": '',
                "triggerType": ''
            }
            condition.targetType = that.targetType,
                condition.targetId = that.targetId,
                condition.triggerType = "DEVICE_ENTERING_GEOFENCE"
            that.notification.triggerPoints.push(condition);
        }
        if (that.exitCondition) {
            var condition = {
                "targetId": '',
                "targetType": '',
                "triggerType": ''
            }
            condition.targetType = that.targetType,
                condition.targetId = that.targetId,
                condition.triggerType = "DEVICE_EXITING_GEOFENCE"
            that.notification.triggerPoints.push(condition);

        }

        if (that.webHooks.serviceCall.body || that.webHooks.serviceCall.url || that.headers != undefined || that.webHooks.serviceCall.method) {

            that.addExternalServiceCall();
        }
        that.validate();
        if (!that.hasError) {
            that.iotService.getCuiObjResponse().createNotification({
                data: that.notification
            }).then(function (response) {
                console.log("Notification created successfully!! "+response.id);
                that.notificationService.successMessage = 'Notification created successfully!';
                that.geofencesService.showNotification=false;
                window.location.reload();
            }).fail(function (err) {
                if (err.responseJSON&&err.responseJSON.apiStatusCode) {
                    that.notificationService.errorMessage = err.responseJSON.apiMessage;
                    console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
                } else {
                    that.notificationService.errorMessage ="Error Occured while creating notification";
                    console.log("Something went wrong. Please check chrome console or check with dev team");
                }
            });
        }
    }
    addExternalServiceCall() {
        const that = this;
        if (that.webHooks.serviceCall != undefined && that.headers != undefined) {
            that.notification.notificationMethods.webHooks=[];
            that.notification.notificationMethods.webHooks[0] = that.webHooks;

            that.notification.notificationMethods.webHooks[0].serviceCall.method = that.method;


            if (that.authType == 'NONE') {
                if (that.notification.notificationMethods.webHooks[0].authentication == undefined) {
                    that.notification.notificationMethods.webHooks[0].authentication = {};
                }
                that.notification.notificationMethods.webHooks[0].authentication.authContent = undefined
                that.notification.notificationMethods.webHooks[0].authentication.authType = that.authType
            }
            if (that.authType == 'BASIC') {
                if (that.notification.notificationMethods.webHooks[0].authentication == undefined) {
                    that.notification.notificationMethods.webHooks[0].authentication = {};
                }
                that.notification.notificationMethods.webHooks[0].authentication.authContent = {};
                that.notification.notificationMethods.webHooks[0].authentication.authContent.username = that.username;
                that.notification.notificationMethods.webHooks[0].authentication.authContent.password = that.password;

                that.notification.notificationMethods.webHooks[0].authentication.authType = that.authType
            }


            var externalcallout = {
                "serviceCallId": "",
                "url": "",
                "body": "",
                "method": "",
                "headers": "",

            }

            // var externalcallouts =[];
            externalcallout.url = that.notification.notificationMethods.webHooks[0].serviceCall.url;
            externalcallout.body = that.notification.notificationMethods.webHooks[0].serviceCall.body;
            externalcallout.method = that.notification.notificationMethods.webHooks[0].serviceCall.method;
            externalcallout.serviceCallId = that.notification.notificationMethods.webHooks[0].serviceCall.serviceCallId;

            var headerString = "";
            for (var i = 0; i < that.headers.length; i++) {
                if (that.headers[i].key != undefined && that.headers[i].key != " " && that.headers[i].key != "") {
                    headerString = headerString + that.headers[i].key + ":" + that.headers[i].value + ",";
                }
            }
            headerString = headerString.substring(0, (headerString.length - 1))
            if (headerString.trim() != "" && headerString.trim() != " ") {
                externalcallout.headers = headerString;
            }

            if (that.notification.notificationMethods.webHooks[0].serviceCall.method == "GET") {
                that.notification.notificationMethods.webHooks[0].serviceCall.body = undefined;

            }

            // externalcallouts.push(externalcallout);
            that.notification.notificationMethods.webHooks[0].serviceCall = externalcallout;
            that.notification.notificationMethods.webHooks[0].authentication.authType = that.authType
        }

    }


    validate() {
        const that = this;
        that.hasError = false;
        if (that.notification.notificationMethods.email == undefined && that.notification.notificationMethods.sms == undefined && that.notification.notificationMethods.webHooks == undefined) {
            that.notificationService.warningMessage = "Please provide at least one notification method 'email','sms', external call";
            that.hasError = true;
            return;
        }

        if (that.notification.notificationMethods.email != undefined) {

            if (that.notification.notificationMethods.email.to == undefined || that.notification.notificationMethods.email.to.length == 0) {
                that.notificationService.warningMessage = "Please provide email address.";
                that.hasError = true;
                return;
            }
            if (that.notification.notificationMethods.email.content == undefined) {
                that.notificationService.warningMessage = "Please provide content for the email.";
                that.hasError = true;
                return;
            }
            if (that.notification.notificationMethods.email.subject == undefined) {
                that.notificationService.warningMessage = "Please provide subject for the address.";
                that.hasError = true;
                return;
            }
        }
        if (that.notification.notificationMethods.sms != undefined) {

            if (that.notification.notificationMethods.sms.to == undefined || that.notification.notificationMethods.sms.to.length == 0) {
                that.notificationService.warningMessage = "Please provide sms number.";
                that.hasError = true;
                return;
            }
            if (that.notification.notificationMethods.sms.content == undefined) {
                that.notificationService.warningMessage = "Please provide sms content.";
                that.hasError = true;
                return;
            }

        }


        // check for valid emails
        if (that.notification.notificationMethods.email != undefined) {

            var emailArr = that.notification.notificationMethods.email.to.split(",")
            for (var i = 0; i < emailArr.length; i++) {
                if (!that.isEmail(emailArr[i])) {
                    that.notificationService.warningMessage = "Invalid email id : " + emailArr[i];
                    that.hasError = true;
                    return;
                }
            }
        }

        //check for valid sms
        if (that.notification.notificationMethods.sms != undefined) {

            var smsArr = that.notification.notificationMethods.sms.to.split(",")
            for (var i = 0; i < smsArr.length; i++) {
                if (!that.isPhoneNumber(smsArr[i])) {
                    that.notificationService.warningMessage = "Invalid sms number : " + smsArr[i];
                    that.hasError = true;
                     return;
                }
            }

        }
        //check for valid externalCallout
        if (that.notification.notificationMethods.webHooks != undefined && that.notification.notificationMethods.webHooks[0].serviceCall.url != undefined) {
            if (that.authType != 'NONE' && (that.notification.notificationMethods.webHooks[0].authentication.authContent.username == undefined || that.notification.notificationMethods.webHooks[0].authentication.authContent.password == undefined || that.notification.notificationMethods.webHooks[0].authentication.authContent.username == "" || that.notification.notificationMethods.webHooks[0].authentication.authContent.password == "")) {
                that.notificationService.warningMessage = "Username or Password missing in external api call";
                that.hasError = true;
                return;
            }

        }


    }


    isEmail(email) {
        var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        return regex.test(email);
    }

    isPhoneNumber(phoneNo) {
        var regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
        return regex.test(phoneNo);
    }

    getArray(array) {

        var tagArray = new Array();
        for (var i = 0; i < array.length; i++) {
            tagArray.push(array[i]);
        }
        return tagArray;
    }






    ngOnDestroy() {
        this.notificationService.resetErrorMessage();
        this.notificationService.resetSuccessMessage();
        this.notificationService.resetWarningMessage();


    }

    addDelimeter(arr, delimiter) {
        var str = ""
        for (var i = 0; i < arr.length; i++) {
            str = str + arr[i] + delimiter
        }
        return str.substring(0, str.length - 1);
    }

    tabClick(tab) {
        console.log(tab.index);
        if (tab.index == 0) {
            this.notificationMethod = 'Email';
        }
        else if (tab.index == 1) {
            this.notificationMethod = 'SMS';

        }
        else if (tab.index == 2) {
            this.notificationMethod = 'ExternalService';

        }

    }

}

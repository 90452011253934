import { Injectable } from '@angular/core';
import { SelectionModel} from '@angular/cdk/collections';
import { IotService } from '../service/iot.service';
import { ListTradingPartnersService } from './ListTradingPartners.service';
import { timeStamp } from 'console';
import { OrchestrationConfigsService } from './orchestrationConfigs.service';

@Injectable({
	  providedIn: 'root'
})

export class TradingPartnersService extends ListTradingPartnersService {
	selection: SelectionModel<String> = new SelectionModel<String>(true, []);
	// selection: SelectionModel<Element> = new SelectionModel<Element>(true, []);

	createTpCode=false;
	createResource=true;
	createCodeRelationShip=false;
	createTpRelationShip=false;
	createApplication=false;
	createRouteSource=false;
	viewTPCode=false;
	viewCodeRelationShip=false;
	viewRouteSource=false;
	codeId;
	codeRelationshipId;
	routeSourceRelationShipId;
	tradingPartnerId;
	fromCode;
	toCode;
	warningMessage;
	errorMessage;
	tradingPartner;
	tradingPartnersList=[];
	// tpApplication;
	tpApplications=[];
	tradingPartnerIds=[];
	tpDocInfo;


	resetMessage(){
		this.warningMessage='';
		this.errorMessage='';
	}

	 constructor(public iotService:IotService, public orchestrationConfigsService: OrchestrationConfigsService) {
		 super(iotService);
		 this.selection.clear();
		 this.loadSelected();
	 }

	 loadSelected(){
		 this.selection.clear();
		if(this.getTradingPartner()){
			this.onSelection(this.getTradingPartner());
		}
		// if (this.orchestrationConfigsService.getTradingPartner()) {
		// 	this.selection.select(this.orchestrationConfigsService.getTradingPartner().id);
		// }
	 }

	 fnOnSelectionSupport(results){
		 const that = this;
		 if(that.iotService.createTradingPartner==true){
			that.setTradingPartner(results);
			let tradingPartners=that.getTradingPartnersList();
			tradingPartners.push(results);
			that.setTradingPartnersList(tradingPartners);
		 } 
		 else{
			this.orchestrationConfigsService.setTradingPartner(results);
			if(this.orchestrationConfigsService.getTradingPartner() != undefined){
				this.orchestrationConfigsService.setTradingPartner(undefined);
			}
			setTimeout(()=>{
				this.orchestrationConfigsService.setTradingPartner(results);
			}, 500);
		}
	 }

	 fnRemoveSelectionSupport(results){
		 const that = this;
		 if(that.iotService.createTradingPartner==true){
			that.setTradingPartner(undefined);
			let tradingPartners=that.getTradingPartnersList();
			tradingPartners.forEach((element,index) => {
				if(element.id==results.id){
					tradingPartners.splice(index,1);
				}
			});
			that.setTradingPartnersList(tradingPartners);
		 } 
		 else{
		 this.orchestrationConfigsService.setTradingPartner(undefined);
		 }
	 }

	 setFromCode(code){
		this.fromCode=code;
	 }

	 getFromCode(){
		return this.fromCode;
	 }

	 setToCode(code){
		this.toCode=code;
	 }

	 getToCode(){
		return this.toCode;
	 }

	 setTradingPartner(tp){
		this.tradingPartner=tp;
	 }

	 getTradingPartner(){
		 return this.tradingPartner;
	 }

	 setTpApplications(tpApplications){
		 this.tpApplications=tpApplications;
	 }

	 getTpApplications(){
		 return this.tpApplications;
	 }

	 setTpDocInfo(tpDocInfo){
		this.tpDocInfo=tpDocInfo;
	 }

	 getTpDocInfo(){
		return this.tpDocInfo;
	 }

	 setCodeId(id){
		 this.codeId=id;
	 }

	 getCodeId(){
		 return this.codeId;
	 }

	 setCodeRelationshipId(id){
		 this.codeRelationshipId=id;
	 }

	 getCodeRelationshipId(){
		 return this.codeRelationshipId;
	 }

	 setRouteSourceRelationShipId(id){
		this.routeSourceRelationShipId=id;
	 }

	 getRouteSourceRelationShipId(){
		 return this.routeSourceRelationShipId;
	 }

	 getTradingPartnersList(){
		 return this.tradingPartnersList;
	 }

	 setTradingPartnersList(tradingPartnersList){
		 this.tradingPartnersList=tradingPartnersList;
	 }
}

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IotService } from 'src/app/service/iot.service';
import { SideNavComponent } from 'src/app/side-nav/side-nav.component';
import { FormControl, FormGroup } from '@angular/forms';
import { faAngleUp, faAngleDown, faTimes, faExclamationTriangle,faExclamation } from '@fortawesome/free-solid-svg-icons';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER, I } from '@angular/cdk/keycodes';
import { Router } from '@angular/router';

interface Range {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-new-oauth-token',
  templateUrl: './new-oAuth-token.component.html',
  styleUrls: ['./new-oAuth-token.component.scss'],
  // host: {
  //   '(document:click)': 'onClick($event)',
  // }
})
export class NewOAuthTokenComponent implements OnInit {

  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;
  faCloseIcon = faTimes;
  faWarningIcon = faExclamationTriangle;
  faCriticalIcon=faExclamation;

  optionalParamsToggle = true;
  token;
  form: FormGroup;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tags = [];
  tagList = [];
  tagClass = 'tagInput';

  supportLanguages = ['en'];

  successMessage: string;
  warningMessage: string;
  errorMessage: String;
  validCustomFormData: boolean = true;
  validCustomHeaders: boolean = true;
  clientIdError;
  clientSecretError;
  accessTokenError;
  refreshTokenError;
  tokenEndpointURLError;
  introspectionEndpointUrlError;
  nameError;
  oAuthTokenCreated=false;


  constructor(public iotService: IotService, private translateService: TranslateService, private sideNavComponent: SideNavComponent, private router: Router) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
    this.sideNavComponent.menuClear();
    this.sideNavComponent.menuChange('Dashboard', 'sub-type');
    this.sideNavComponent.menuChange('oAuthTokens.header', 'sub-type');
    this.sideNavComponent.menuChange('oAuthTokens.create.new', 'sub-type');
    this.form = new FormGroup({
      typeChange: this.typeChangeControl
    });
  }


  grantType: Range[] = [
    { value: '0', viewValue: 'password' },
    { value: '1', viewValue: 'client_credentials' }
  ];

  typeChangeControl = new FormControl(this.grantType[0].value);

  ngOnInit(): void {
    this.initToken();
  }
  initToken() {
    const that = this;
    this.token = {
      "creator": that.iotService.getCreator(),
      "creatorAppId": that.iotService.getCreatorAppId(),
      "realm": that.iotService.getRealm(),
      'name': [{
        'lang': 'en_us',
        'text': ''
      }],
      'description': [{
        'lang': 'en_us',
        'text': ''
      }],
      'clientId': '',
      'grantType': 'password',
      'tags': this.tags,
      'customDataInIntrospection': false
    };
  }

  onClick(event) {
    this.resetWarningMessage();

  }

  resetWarningMessage() {
    this.warningMessage = "";
    this.errorMessage='';
    this.successMessage='';
    // this.clientIdError='';
    // this.clientSecretError='';
    // this.accessTokenError='';
    // this.refreshTokenError='';
    // this.tokenEndpointURLError='';
    // this.introspectionEndpointUrlError='';
    // this.nameError='';
  }

  toggleExample() {
    this.optionalParamsToggle = !this.optionalParamsToggle;
  }

  add(event: MatChipInputEvent): void {

    this.tagClass = 'tagInput';
    const input = event.input;
    const value = event.value;
    const index = this.tags.indexOf(value);
    if (index < 0) {
      if ((value || '').trim()) {
        if (this.tags != undefined && this.tags.includes(value)) {
          if (input) {
            input.value = '';
          }
        }
        else {
          this.tags.push(value);
          if (input) {
            input.value = '';
          }
        }
      }
    } else {
      this.tagClass = 'tagInput-duplicate';
    }
  }

  remove(tag: string): void {
    const that = this;
    that.tags.forEach((value, index) => {
      if (value == tag) that.tags.splice(index, 1);
    });

  }

  save() {
    const that = this;
    if (that.typeChangeControl.value == 0) {
      that.token.grantType = 'password';
    }
    else {
      that.token.grantType = 'client_credentials';
    }
    if (that.token.customDataInIntrospection == true && (that.IsEmptyOrNull(that.token.customFormData) && that.IsEmptyOrNull(that.token.customHeaders))) {

      that.warningMessage = "Please provide custom params.";
    }
    else {
      if (!that.IsEmptyOrNull(that.token.customHeaders)) {

        try {
          that.token.customHeaders = JSON.parse(that.token.customHeaders);
        } catch (e) {
          that.warningMessage = "Error Occured while parsing custom headers";
          that.validCustomHeaders = false;

        }

      } else {
        that.token.customHeaders = undefined;
      }
      if (!that.IsEmptyOrNull(that.token.customFormData)) {

        try {
          that.token.customFormData = JSON.parse(that.token.customFormData);
        } catch (e) {
          that.warningMessage = "Error Occured while parsing custom form data";
          that.validCustomFormData = false;

        }
      } else {
        that.token.customFormData = undefined;
      }
      if (that.validCustomHeaders && that.validCustomFormData) {
        that.oAuthTokenCreated=true;
        that.iotService.getCuiObjResponse().createToken({
          data: that.token
        }).then(function (response) {
          console.log("oAuthToken created succesfully. ID :", response.id);
          that.router.navigateByUrl("/oAuthTokens");
        })
          .fail(function (err) {
            that.oAuthTokenCreated=false;
            that.token.customHeaders = "";
            that.token.customFormData = "";
            if (err.responseJSON.apiStatusCode) {
              that.errorMessage = err.responseJSON.apiMessage;
              if(that.token.clientId==''||that.token.clientId==undefined){
                that.clientIdError='Client Id required';
              }
              if(that.token.name[0].text==''||that.token.name[0].text==undefined){
                that.nameError='Name required';
              }
              if(that.token.clientSecret==''||that.token.clientSecre==undefined){
                that.clientSecretError='Client secret required';
              }
              if(that.token.accessToken==''||that.token.accessToken==undefined){
                that.accessTokenError='Access token required';
              }
              if(that.token.tokenEndpointUrl==''||that.token.tokenEndpointUrl==undefined){
                that.tokenEndpointURLError='Token endpoint URL required';
              }
              if(that.token.introspectionEndpointUrl==''||that.token.introspectionEndpointUrl==undefined){
                that.introspectionEndpointUrlError='Introspection endpoint URL required';
              }

              if(that.token.refreshToken==''||that.token.refreshToken==undefined){
                that.refreshTokenError='Refreshtoken required';
              }

              console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
            } else {
              console.log("Something went wrong. Please check chrome console or check with dev team");
            }
            if (err === null) {
              that.errorMessage = "Error occured while creating token";
              console.log("Error occured while creating token");
            }
          });
      }
    }
  }

  IsEmptyOrNull(str) {
    if (str!=undefined) {
      if (str.trim().length === 0) {
        return true;
      }
      else {
        return false;
      }
    }

    return true;
  }

}

import { Component, OnInit } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { IotService } from '../service/iot.service';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  faSignOutAlt = faSignOutAlt;

  host: any = {};

  constructor(platformLocation: PlatformLocation,
              private iotService:IotService,
              library: FaIconLibrary) {
    this.host = platformLocation;
  }

  ngOnInit(): void{

  }

  logout(){
    console.log("Logging out");
    this.iotService.getCuiObjResponse().covLogout();
    console.log("Logged out successfully");
  }

}

import { Component, OnInit,Input } from '@angular/core';
import { IotService } from '../service/iot.service';
import { DeviceService } from '../service/device.service';
import { faCheckCircle, faExclamation, faExclamationTriangle, faInfoCircle, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DigitalTwinTemplatesService } from '../service/digitalTwinTemplates.service';

@Component({
  selector: 'app-new-device-template',
  templateUrl: './new-device-template.component.html',
  styleUrls: ['./new-device-template.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  }
})
export class CreateDeviceTemplateComponent implements OnInit {

  @Input() data: any;
  
  faInfoIcon = faInfoCircle;
  faCloseIcon = faTimes;
  faCriticalIcon = faExclamation;
  faWarningIcon = faExclamationTriangle;
  faSuccessIcon = faCheckCircle;
  faCheckIcon = faCheck;

  deviceTemplateClone:any;
  deviceTemplate:any;
  createdDeviceTemplate:any;
  device: any;
  attributeIds = [];
  commandIds = [];
  eventIds = [];
  geofenceIds = [];
  tags=[];
  connectionIds = [];
  connectionEnabled=false;
  deviceCreated=false;
  deviceId: String;
  tagList = [];
  association:any;
  active=false;
  newDeviceTemplateCreated=false;
  supportLanguages = ['en'];

  constructor(public iotService:IotService, public deviceService:DeviceService,private router:Router,
    private sideNavComponent: SideNavComponent,private route: ActivatedRoute,public digitalTwinTemplatesService:DigitalTwinTemplatesService,
    private translateService: TranslateService) { 
    this.sideNavComponent.menuClear();
    this.sideNavComponent.menuChange('Dashboard','sub-type');
    this.sideNavComponent.menuChange('templates.header','sub-type');
    this.sideNavComponent.menuChange('templates.create.new', 'sub-type');
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }

  onClick(event) {
    this.deviceService.resetSuccessMessage();
    this.deviceService.resetWarningMessage();
    
 }


  ngOnDestroy(): void {
 //   this.iotService.digitalTwinTemplate=false;
    this.digitalTwinTemplatesService.setAttributes([]);
    this.digitalTwinTemplatesService.setCommands([]);
    this.digitalTwinTemplatesService.setEvents([]);
    this.digitalTwinTemplatesService.setGeofences([]);
    this.iotService.newDeviceTemplate=false;
    this.deviceService.setDeviceName(undefined);
    this.deviceService.setDeviceDescription(undefined);
    this.deviceService.setDeviceTags(undefined);
    this.deviceService.setTemplateName(undefined);
    this.deviceService.resetErrorMessage();
    this.deviceService.resetWarningMessage();
    
    this.deviceCreated=false;
    this.deviceId='';
    this.tags.length=0;
    this.tagList.length=0;
    this.deviceService.createFromScratch = false;
    if(this.deviceService.deviceTags!=undefined){
      this.deviceService.deviceTags.length=0;
    }
    this.deviceService.newTemplatename = '';
    this.deviceService.deviceDescription = '';
    this.deviceService.deviceNameSelect = false;
    this.deviceService.templateNameSelect = false;
    
    this.deviceService.setSelectedTemplate(undefined);
    this.deviceService.setTemplateName(undefined);
    this.deviceService.setSelectedTemplate(undefined);
    this.deviceService.setDeviceName(undefined);
    this.deviceService.setDeviceDescription(undefined);
    this.deviceService.setDeviceTags(undefined);
    this.deviceService.setTemplateName(undefined);
     this.deviceService.mapLoad = false;
      this.deviceService.createGeofence = false;
      this.deviceService.createAttribute = false;
      this.deviceService.createEvent = false;
      this.deviceService.createCommand = false;
      let event = this.digitalTwinTemplatesService.getEvents();
      console.log('evemtsss'+event)
  }

  ngOnInit(): void {
  //  this.iotService.digitalTwinTemplate=true;

    this.newDeviceTemplateCreated = false;
    var deviceTemplateId;
    this.iotService.newDeviceTemplate=true;
    this.digitalTwinTemplatesService.setEvents([]);
    this.route.params.subscribe(params => {
      console.log("deviceTemplateId ::: params :: "+params['id']);
      deviceTemplateId = params['id'];
    });
    if(deviceTemplateId != undefined) {
     
      console.log("Clone call")
     
    } else {
      this.deviceService.templateSelect = false;
      this.deviceService.createNewTemplate=true;

      this.deviceCreated=false;
      this.deviceService.resetErrorMessage();
      this.deviceService.resetSuccessMessage();
      this.deviceService.resetWarningMessage();
      this.deviceService.createResource = false;
      this.deviceService.attributeSelect = false;
      this.deviceService.eventSelect = false;
      this.deviceService.commandSelect = false;
      this.deviceService.templateSelect = false;
      this.deviceService.geofenceSelect = false;
      this.deviceService.createGeofence = false;
      this.deviceService.createAttribute = false;
      this.deviceService.createEvent = false;
      this.deviceService.createCommand = false;
      this.deviceService.createDeviceFromNewTemplate = false;
      this.deviceService.setNewTemplate(undefined);
      this.digitalTwinTemplatesService.setAttributes([]);
      this.digitalTwinTemplatesService.setCommands([]);
      this.digitalTwinTemplatesService.setEvents([]);
      this.digitalTwinTemplatesService.setGeofences([]);
    }
 
  }
  

  createNewDeviceTemplate(newTemplate,newDevice) {
    const that = this;
    if(newTemplate && newDevice){
      that.active=true;
    }
    that.deviceTemplate = {
      "creator": that.iotService.getCreator(),
      "creatorAppId": that.iotService.getCreatorAppId(),
      "realm": that.iotService.getRealm(),
      "name": [
        {
          "lang": "en_US",
          "text": that.deviceService.getDeviceName(),
        }
      ],
      "description": [
        {
          "lang": "en_US",
          "text":  that.deviceService.getDeviceDescription(),
        }
      ],
      "tags":that.getTags(),
      "attributeTypes": that.getAttributeIds(),
      "commandTemplates": that.getCommandIds(),
      "eventTemplates": that.getEventIds(),
      "geofences": that.getGeofenceIds(),
      "isActive": that.active
    }
    that.newDeviceTemplateCreated = true;
    console.log("template " ,that.deviceTemplate);
    that.iotService.getCuiObjResponse().createDeviceTemplate({
      data: that.deviceTemplate
    }).then(function (response) {
      that.createdDeviceTemplate=response;
     
      if(that.digitalTwinTemplatesService.getGeofences()!=undefined||!that.digitalTwinTemplatesService.getGeofences().isEmpty() ){
        that.addAssociationsToTemplate(response);
      }
      that.deviceService.successMessage="Device Template created successfully!";
      if (newTemplate && !newDevice) {
        that.router.navigateByUrl("/templates/"+response.id);
      } 
      else if(newTemplate && newDevice) {
        that.deviceService.createDeviceFromNewTemplate=true;
        that.deviceService.setNewTemplate(that.createdDeviceTemplate);
        that.router.navigateByUrl("/createNewDigitalTwins");
      }
      else{
        that.newDeviceTemplateCreated = false;
        that.ngOnDestroy();
      }

    }).fail(function (msg) {
      that.newDeviceTemplateCreated = false;
      console.log(msg.responseJSON.apiMessage);

    });
  }

  addAssociationsToTemplate(response){ 
    const that = this;
    that.digitalTwinTemplatesService.getGeofences().forEach(element => {
     that.association= {
        "creator" : that.iotService.getCreator(),
        "creatorAppId": that.iotService.getCreatorAppId(),
        "realm": that.iotService.getRealm(),
        "resourceId": response.id,
        "resourceType": "DEVICETEMPLATE",
        "geofenceId": element.id,
        "isActive":true
    }
      that.iotService.getCuiObjResponse().createGeofenceResourceAssociation({
        data: that.association,
      }).then(function (associationResponse) {
        console.log("geofence association added -- " + associationResponse);
      }).fail(function (msg) {
        console.log(msg.responseJSON.apiMessage)
      });
    });

  }

  deactivateAttribute(id : any){
    const that = this;

    that.iotService.getCuiObjResponse().deactivateAttributeType({
      attributeTypeId: id
    }).then(function(response) {
      // console.log(response);
      console.log("attr deactivation successful");
      that.deviceService.resetErrorMessage();
      that.ngOnInit();
   //   that.attributeList = new AttributeListComponent(that.deviceService);

  //    that.attributeList.getAttributes();

    }).fail(function (err){
      console.log("Error in attr activation")
    });
  }

  disableCommand(id : any) {
    const that = this;
    this.iotService.getCuiObjResponse().deactivateCommandTemplate({
      commandTemplateId: id
    }).then(function (response) {
      // console.log(response);
      console.log("command deactivation successful");
      that.deviceService.resetErrorMessage();
      that.ngOnInit();
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  disableEvent(id : any) {
    const that = this;
    this.iotService.getCuiObjResponse().deactivateEventTemplate({
      eventTemplateId: id
    }).then(function(response) {
      // console.log(response);
      console.log("event deactivation successful");
      that.deviceService.resetErrorMessage();
      that.ngOnInit();
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }


  getTags() {
    this.tags = [];
    if (this.deviceService.getDeviceTags() != undefined && this.deviceService.getDeviceTags().length > 0) {
      this.deviceService.getDeviceTags().forEach((value) => {
        this.tags.push(value);
      });
      // this.tags.push(this.iotService.getDeviceTags());
    }
    return this.tags;
  }

  getAttributeIds() {
    this.attributeIds = [];
    if (this.digitalTwinTemplatesService.getAttributes() != undefined) {
      this.digitalTwinTemplatesService.getAttributes().forEach((value) => {
        this.attributeIds.push(value.id);
      });
    }

    return this.attributeIds;
  }

  getCommandIds() {
    this.commandIds = [];
    if (this.digitalTwinTemplatesService.getCommands() != undefined) {
      this.digitalTwinTemplatesService.getCommands().forEach((value) => {
        this.commandIds.push(value.id);
      });
    }
    return this.commandIds;
  }

  getEventIds() {
    this.eventIds = [];
    if (this.digitalTwinTemplatesService.getEvents() != undefined) {
      this.digitalTwinTemplatesService.getEvents().forEach((value) => {
        this.eventIds.push(value.id);
      });
    }
    return this.eventIds;
  }

  getGeofenceIds() {
    this.geofenceIds = [];
    if (this.digitalTwinTemplatesService.getGeofences() != undefined) {
      this.digitalTwinTemplatesService.getGeofences().forEach((value) => {
        this.geofenceIds.push(value.id);
      });
    }
    return this.geofenceIds;
  }

}

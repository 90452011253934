<mat-sidenav-container class="new-docInfo" autosize>

    <mat-sidenav class="midNav-container" #sidenav mode="side" disableClose="true" opened="true">

        <!-- <p>test {{warningMessage}} test</p> -->
        <div class="alert warning" *ngIf="warningMessage">
            <h5>
                <fa-icon [icon]="faWarningIcon"></fa-icon>
                <span>{{warningMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)="reset()" [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>


        <div class="alert success" *ngIf="successMessage">
            <h5>
                <fa-icon [icon]="faSuccessIcon"></fa-icon>
                <span>{{successMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=reset() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>

        <div class="data" *ngIf="docinfo">
            <div style="margin-left: -20px;">
                <app-copy-resource-id></app-copy-resource-id>
            </div>
            <label for="name"> {{ 'name' | translate}}</label>
            <p id="name">{{docinfo.name[0].text}}</p>

            <label for="description"> {{ 'description' | translate}}</label>
            <p *ngIf="docinfo.description" id="description">{{docinfo.description[0].text}}</p>
            <p *ngIf="!docinfo.description" id="description">-</p>

            <label for="documentType"> {{ 'documentType' | translate}}</label>
            <p>{{docinfo.supportedDocType}}</p>


            <label for="templateType"> {{ 'templateType' | translate}}</label>
            <p>{{docinfo.supportedDocTemplateType}}</p>

            <label for="templateVersion"> {{ 'templateVersion' | translate}}</label>
            <p>{{docinfo.docTemplateVersion}}</p>


            <div class="tags">
                <label for="device-tags">{{ 'tags' | translate}}</label>
                <mat-chip-list #tagList aria-label="tags">
                    <mat-chip class="ot-chip" *ngFor="let tag of docinfo.tags" [selectable]="true" [removable]=true (removed)="remove(tag)">
                        {{tag}}
                        <button class="ot-chip__close" matChipRemove></button>
                    </mat-chip>
                    <input style="width: 50%;" [ngClass]="tagClass" placeholder="add a tag" #tagInput [matChipInputFor]="tagList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="add($event)">
                </mat-chip-list>
            </div>

            <label for="created"> {{ 'creation' | translate}}</label>
            <p id="created">{{docinfo.creation|date:'MMM d, y, h:mm:ss a'}}</p>

            <label>{{ 'activated' | translate}}</label>
            <div *ngIf="docinfo.active">
                <button class="toggle-on toggle-switch" title="Disable {{docinfo.name[0].text}}" (click)="deactivateDocInfo(docinfo.id)">
                    <fa-icon [icon]="faCheckIcon" class="fa-2x"></fa-icon>
                    <div class="toggle-on-btn"></div>
                </button>
            </div>
            <div *ngIf="!docinfo.active">
                <button class="toggle-off toggle-switch" title="Enable {{docinfo.name[0].text}}" (click)="activateDocInfo(docinfo.id)">
                    <div class="toggle-off-btn"></div>
                </button>
            </div>


        </div>
    </mat-sidenav>
    <mat-sidenav-content class="example-sidenav-content" *ngIf="docinfo">
        <app-streams [pageSize]="5" [showControlBar]=false [displayedColumns]="['name','protocolType','streamType','direction']" showSelectColoumn=true showEnableColumn=true addnQueryParam=[documentInfoId::{{docinfo.id}}] [showHeaderToolTips]=false>
        </app-streams>
        <app-orchestrationConfigs [pageSize]="5" [showControlBar]=false [displayedColumns]="['name','orchestrationType','serviceRunTime']" [showSelectColoumn]=true [showEnableColumn]=true addnQueryParam=[tagIds::{{docinfo.id}}] [showHeaderToolTips]=false>
        </app-orchestrationConfigs>
        <br>
        <br>
    </mat-sidenav-content>

</mat-sidenav-container>

<ng-container *ngIf="!docinfo ">
    <div>
        <br/><br/>
    </div>
    <div class="d-flex justify-content-center pageLoading">
        <div class="spinner-border" role="status">
            <span class="sr-only" id="loading"></span>
        </div>
    </div>
</ng-container>
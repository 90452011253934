import { Component, OnInit, ViewChild } from '@angular/core';
import { IotService } from '../service/iot.service';
import { Location } from '@angular/common';
import {Router} from '@angular/router';
import { faToggleOff,faToggleOn,faAngleLeft, faAngleRight, faCopy,faAngleUp, faAngleDown,faCheck ,faCheckCircle, faExclamation, faExclamationTriangle, faInfoCircle, faTimes} from '@fortawesome/free-solid-svg-icons';
import { SideNavComponent } from '../side-nav/side-nav.component';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { StreamService } from '../service/stream.service';
import { DeviceService } from '../service/device.service';
import { MatTable } from '@angular/material/table';



@Component({
  selector: 'app-device-detail',
  templateUrl: './device-detail.component.html',
  styleUrls: ['./device-detail.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class DeviceDetailComponent implements OnInit {

  separatorKeysCodes: number[] = [ENTER, COMMA];

  faToggleOffIcon = faToggleOff;
  faToggleOnIcon = faToggleOn;
  faAngleLeftIcon = faAngleLeft;
  faAngleRightIcon = faAngleRight;
  faCheckIcon = faCheck;
  faCopyIcon = faCopy;
  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;
  faInfoIcon = faInfoCircle;
  faCloseIcon = faTimes;
  faCriticalIcon = faExclamation;
  faWarningIcon = faExclamationTriangle;
  faSuccessIcon = faCheckCircle;
  icon = this.faToggleOnIcon;
  toggleEnable = 'toggle-on';
  deviceActive: boolean = true;

  matVersion: string = '5.1.0';
  breakpoint: number;
  device;
  streamDeviceResponse =[];
  deviceStream;
  currentDeviceStream=0;

  statusPendingClass = 'bg-lifecycle';
  statusActiveClass = 'bg-lifecycle';
  statusSuspendedClass = 'bg-lifecycle';
  statusDeletedClass = 'bg-lifecycle';
  attributeData=[];
  attributeColumns: string[] = ['Name', 'Data Type', 'Value'];
  eventData=[];
  eventColumns:string[] = ['Name','Description','Data Point','Data Type','Last Hour','Last 24 Hours','Last Week'];
  previousEventElement;
  previousCommandElement;
  commandData=[];
  commandColumns:string[] = ['Name','Description','Arguments','Last Hour','Last 24 Hours','Last Week'];
  geofenceData=[];
  geofenceColumns:string[] = ['Name','Device Status'];
  streamData=[];
  streamColumns: string[] = ['ADD','Name', 'Protocol', 'Security', 'Direction', 'Enabled'];
  currentPage =1;
  streamSelected:string;

  updateNameDesc:boolean=true;
  updateAttribute:boolean=true;

  pageSize=5;
  streamCount:number=0;
  showPagination:boolean=false;

  tagClass = 'tagInput';
  tagList =[];
  supportLanguages = ['en'];
  auditFlag=false;
  auditRefreshFlag=true;
  deviceId;
  geoTableData =[];
  geofencesLoaded=false;

  @ViewChild(MatTable) table: MatTable<any>;
  eventLength=0;
  commandLength=0;

  constructor(private router: Router, private route: ActivatedRoute, private iotService:IotService,
          private location:Location,private sideNavComponent:SideNavComponent,private translateService: TranslateService,
          private streamService:StreamService, public deviceService:DeviceService) { 
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en'); 
    this.streamService = streamService;
  }
  

  ngOnInit() {
   
    this.deviceService.resetErrorMessage();
    this.iotService.digitalTwin=true;
    if(window.innerWidth <= 1200 && window.innerWidth >650){
      this.breakpoint=2;
    } 
    else if(window.innerWidth <= 650){
      this.breakpoint=1;
    }
    else{
      this.breakpoint=4;
    }

    this.route.params.subscribe(params => {
      //console.log(params['id']);
      var deviceId = params['id'];
      this.deviceId = deviceId;
      this.getStreamDevices(deviceId);
      this.loadStream();
      this.countStreams('');
      this.deviceService.setDeviceId(deviceId);
      this.loadDevice(deviceId);
    });

    this.streamService.streamCreated=false;
    this.deviceService.resetErrorMessage();
    this.deviceService.resetSuccessMessage();
    this.deviceService.resetWarningMessage();
  }
  
  onResize(event) {
    if(event.target.innerWidth <= 1200 && event.target.innerWidth >650){
      this.breakpoint=2;
    } 
    else if(event.target.innerWidth <= 650){
      this.breakpoint=1;
    }
    else{
      this.breakpoint=4;
    }
  }

  enableUpdate(event:any) {
    this.updateNameDesc=false;
  }

  enableAttributeUpdate(event:any) {
    this.updateAttribute=false;
  }
  
  loadGeofence(device) {
    const that = this;
 //   that.geofenceData = [];
    that.iotService.getCuiObjResponse().searchGeofenceResourceAssociation({
      resourceId: device.id,
      resourceType: "DEVICE"
    }).then(function(deviceGeoResponse) {
      if(deviceGeoResponse.length != 0) {
        var length=deviceGeoResponse.length ;
          var itr=0;
        console.log(deviceGeoResponse);
        deviceGeoResponse.forEach(element => {
          that.deviceService.associationMap.set(element.geofenceId,element.id);
          that.iotService.getCuiObjResponse().getGeofenceNoPoly({
            id: element.geofenceId,
          }).then(function(geoResponse) {
            if(geoResponse) {
              itr++;
              var obj ={
                "name":geoResponse.name[0].text,
                "state":element.state
              }
              that.geofenceData.push(geoResponse);
              that.geofenceData.push(obj);
              //that.geofenceData=that.geoTableData;
              that.deviceService.setGeofences(that.geofenceData);
              if(itr==length){
                that.geofencesLoaded=true;
              }
            }
          })
        });
      }else{
        that.iotService.getCuiObjResponse().searchGeofenceResourceAssociation({
          resourceId: device.parentDeviceTemplateId,
          resourceType: "DEVICETEMPLATE"
        }).then(function(templateGeoResponse) {
          that.geofencesLoaded=true;
          if(templateGeoResponse.length != 0) {
            console.log(templateGeoResponse);
            templateGeoResponse.forEach(element => {
              that.iotService.getCuiObjResponse().getGeofenceNoPoly({
                id: element.geofenceId,
              }).then(function(geoResponse) {
                if(geoResponse) {
                  var obj ={
                    "name":geoResponse.name[0].text,
                    "state":element.state
                  }
                  that.geoTableData.push(obj);
                  that.deviceService.setGeofences(that.geoTableData);
                }
              })
            });
          }      
        }).fail(function (err){
          if(err.responseJSON.apiStatusCode){
            alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
          } else{
            alert("Something went wrong. Please check chrome console or check with dev team");
          }
        }); 
        setTimeout(()=>{ 
         that.geofenceData=that.geoTableData;
        }, 5000);
      }
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        alert("Something went wrong. Please check chrome console or check with dev team");
      }
    }); 

  
  }

  loadEventData(device){
    const that = this;
    that.eventData = [];
    if(device.observableEvents && device.observableEvents.length != 0) {
     that.eventLength=device.observableEvents.length;
      device.observableEvents.forEach(eventTemplate => {
        var endTime = new Date().getTime();
        var Range1 = new Date();
        var Range2 = new Date();
        var Range3 = new Date();
        var eventCount1 = 0;
        var eventCount2 = 0;
        var eventCount3 = 0;
        Range1.setHours(Range1.getHours() - 1);
        Range2.setHours(Range2.getHours() - 24);
        Range3.setHours(Range3.getHours() - 168);
        that.iotService.getCuiObjResponse().countEventAudits({
          startTime: Range1.getTime(),
          endTime: endTime,
          qs: [
              ['eventTemplateId', eventTemplate.id],
              ['deviceId', device.id],
              ['receivedTimestamp', '>' + Range1.getTime()],
              ['receivedTimestamp', '<' + endTime]
          ]
        }).then(function(eventCount1Response) {
          eventCount1 = eventCount1Response;
          that.table.renderRows();
        })
        that.iotService.getCuiObjResponse().countEventAudits({
          startTime: Range2.getTime(),
          endTime: endTime,
          qs: [
              ['eventTemplateId', eventTemplate.id],
              ['deviceId', device.id],
              ['receivedTimestamp', '>' + Range2.getTime()],
              ['receivedTimestamp', '<' + endTime]
          ]
        }).then(function(eventCount2Response) {
          eventCount2 = eventCount2Response;
        })
        that.iotService.getCuiObjResponse().countEventAudits({
          startTime: Range3.getTime(),
          endTime: endTime,
          qs: [
              ['eventTemplateId', eventTemplate.id],
              ['deviceId', device.id],
              ['receivedTimestamp', '>' + Range3.getTime()],
              ['receivedTimestamp', '<' + endTime]
          ]
        }).then(function(eventCount3Response) {
          eventCount3 = eventCount3Response;
        })

        setTimeout(()=>{ 
          var obj ={
            "eventTemplate":eventTemplate,
            "eventCount1":eventCount1,
            "eventCount2":eventCount2,
            "eventCount3":eventCount3,
            "messageClass": 'bg-message'
          }
          that.eventData.push(obj); 
          that.table.renderRows();
          
          
        }, 2000);
       
 
      });
    }
  } 

  loadCommandData(device){
    const that = this;
    that.commandData = [];
    if(device.supportedCommands && device.supportedCommands.length != 0) {
      that.commandLength=device.supportedCommands.length;
      device.supportedCommands.forEach(commandTemplate => {
        var endTime = new Date().getTime();
        var Range1 = new Date();
        var Range2 = new Date();
        var Range3 = new Date();
        var cmdCount1 = 0;
        var cmdCount2 = 0;
        var cmdCount3 = 0;
        Range1.setHours(Range1.getHours() - 1);
        Range2.setHours(Range2.getHours() - 24);
        Range3.setHours(Range3.getHours() - 168);
        that.iotService.getCuiObjResponse().countCommandAudits({
          startTime: Range1.getTime(),
          endTime: endTime,
          qs: [
              ['commandTemplateId', commandTemplate.id],
              ['deviceId', device.id],
              ['receivedTimestamp', '>' + Range1.getTime()],
              ['receivedTimestamp', '<' + endTime]
          ]
        }).then(function(count1Response) {
          cmdCount1 = count1Response;
        })
        that.iotService.getCuiObjResponse().countCommandAudits({
          startTime: Range2.getTime(),
          endTime: endTime,
          qs: [
              ['commandTemplateId', commandTemplate.id],
              ['deviceId', device.id],
              ['receivedTimestamp', '>' + Range2.getTime()],
              ['receivedTimestamp', '<' + endTime]
          ]
        }).then(function(count2Response) {
          cmdCount2 = count2Response;
        })
        that.iotService.getCuiObjResponse().countCommandAudits({
          startTime: Range3.getTime(),
          endTime: endTime,
          qs: [
              ['commandTemplateId', commandTemplate.id],
              ['deviceId', device.id],
              ['receivedTimestamp', '>' + Range3.getTime()],
              ['receivedTimestamp', '<' + endTime]
          ]
        }).then(function(count3Response) {
          cmdCount3 = count3Response;
        })
          
        setTimeout(()=>{ 
          var obj ={
            "commandTemplate":commandTemplate,
            "cmdCount1":cmdCount1,
            "cmdCount2":cmdCount2,
            "cmdCount3":cmdCount3,
            "messageClass": 'bg-message'
          }
          that.commandData.push(obj); 
          that.table.renderRows();
        }, 2000);
        
      });
    }
  } 

  loadDevice(deviceId) {
    const that = this;
    this.iotService.getCuiObjResponse().getDevice({
      deviceId: deviceId
    }).then(function(response) {
      console.log(response);
      that.device = response;

      that.sideNavComponent.menuClear();
      that.sideNavComponent.menuChange('Dashboard', 'sub-type');
      that.sideNavComponent.menuChange('devices.header', 'sub-type');
      that.sideNavComponent.menuChange(that.device.name[0].text,'sub-type');

      if(!that.device.description){
        that.device.description = [];
        that.device.description.push({ lang: 'en-us', text: "" });
      }
      if(that.device.state.lifecycleState == 'pending activation'){
        that.statusPendingClass = 'bg-lifecycle-selected';
        that.statusActiveClass = 'bg-lifecycle';
        that.statusSuspendedClass = 'bg-lifecycle';
        that.statusDeletedClass = 'bg-lifecycle';
      }
      if(that.device.state.lifecycleState == 'active'){
        that.statusPendingClass = 'bg-lifecycle';
        that.statusActiveClass = 'bg-lifecycle-selected';
        that.statusSuspendedClass = 'bg-lifecycle';
        that.statusDeletedClass = 'bg-lifecycle';
      }
      if(that.device.state.lifecycleState == 'suspended'){
        that.statusPendingClass = 'bg-lifecycle';
        that.statusActiveClass = 'bg-lifecycle';
        that.statusSuspendedClass = 'bg-lifecycle-selected';
        that.statusDeletedClass = 'bg-lifecycle';
      }
      if(that.device.state.lifecycleState == 'deleted'){
        that.statusPendingClass = 'bg-lifecycle';
        that.statusActiveClass = 'bg-lifecycle';
        that.statusSuspendedClass = 'bg-lifecycle';
        that.statusDeletedClass = 'bg-lifecycle-selected';
      }
      that.deviceActive = that.device.isActive;
      if(that.device.isActive) {
        that.icon = that.faToggleOnIcon;
        that.toggleEnable = 'toggle-on';
      } else {
        that.icon = that.faToggleOffIcon;
        that.toggleEnable = 'toggle-off';
      }
      that.attributeData = that.device.attributes.standard; 
      that.loadEventData(that.device);
      that.loadCommandData(that.device);
      that.loadGeofence(that.device);
      
      
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        alert("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  loadStream() {
    const that = this;
    this.iotService.getCuiObjResponse().getStreams({
      qs:[['pageSize',5],['page',this.currentPage],['sortBy', '-creation'],['streamType', 'DEVICE']]
    }).then(function(response): void {
      if(response) {
        that.streamData=[];
        that.streamData = response;
        that.checkStreamDevice();
      }
      console.log("streamData -- "+that.streamData)
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  private checkStreamDevice() {
    const that = this;
    if(that.streamDeviceResponse.length === 0) {
      that.getStreamDevices(that.deviceId);
      
      setTimeout(()=>{ 
        that.streamData.forEach(stream => {
          var added = false;
          that.streamDeviceResponse.forEach(streamDevice => {
            if (!added && streamDevice.id == stream.id) {
              added = true;
            }
          });
          if (added) {
            stream.isAdded = "Added";
          } else {
            stream.isAdded = "Add";
          }
        });
      }, 2000);

    } else {
      that.streamData.forEach(stream => {
        var added = false;
        that.streamDeviceResponse.forEach(streamDevice => {
          if (!added && streamDevice.id == stream.id) {
            added = true;
          }
        });
        if (added) {
          stream.isAdded = "Added";
        } else {
          stream.isAdded = "Add";
        }
      });
    }

  }

  onStreamSearch(event: any){
    console.log(event.target.value);
    this.streamSelected = event.target.value;
    
    let startValue = '*'+event.target.value;
    let endValue = event.target.value + '*';
    const that = this;
    that.countStreams('*'+event.target.value+'*');
    that.currentPage = 1;
    this.iotService.getCuiObjResponse().getStreams({
      qs:[['pageSize',5],['page',this.currentPage],['name',startValue],['name',endValue]]
    }).then(function(response) {
      // console.log(response);
      that.streamData = response;
      that.checkStreamDevice();
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      } 
    });
  }
  
  getSelectedStreamForCurrentPage(streamSelected){
    this.streamSelected = streamSelected;
    let name = '*'+streamSelected +'*';
    const that = this;
    this.iotService.getCuiObjResponse().getStreams({
      qs:[['pageSize',5],['page',this.currentPage],['name',name]]
    }).then(function(response) {
      that.streamData = response;
      that.checkStreamDevice();
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      } 
    });
  }

  disableStream(stream, streamId){
    const that = this;
    this.iotService.getCuiObjResponse().deactivateStream({
      streamId: streamId
    }).then(function(response) {
      console.log("disabled Stream");
      stream.streamState = 'INACTIVE';
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }
  
  enableStream(stream,streamId){
    const that = this;
    this.iotService.getCuiObjResponse().activateStream({
      streamId: streamId
    }).then(function(response) {
      console.log("enabled Stream");
      stream.streamState = 'ACTIVE';
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  registerDevice(deviceId) {
    const that = this;
    console.log("registered Device"+deviceId);
    that.iotService.getCuiObjResponse().registerDevice({
      deviceId: deviceId
    }).then(function(response) {
      console.log("registered Device");
      that.loadDevice(deviceId);
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        alert("Something went wrong. Please check chrome console or check with dev team");
      }
    }); 
  }

  suspendDevice(deviceId) {
    const that = this;
    that.iotService.getCuiObjResponse().suspendDevice({
      deviceId: deviceId
    }).then(function(response) {
      console.log("suspended Device");
      that.loadDevice(deviceId);
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        alert("Something went wrong. Please check chrome console or check with dev team");
      }
    }); 
  }

  unsuspendDevice(deviceId) {
    const that = this;
    that.iotService.getCuiObjResponse().unsuspendDevice({
      deviceId: deviceId
    }).then(function(response) {
      console.log("unsuspended Device");
      that.loadDevice(deviceId);
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        alert("Something went wrong. Please check chrome console or check with dev team");
      }
    }); 
  }

  deleteDevice(deviceId) {
    const that = this;
    that.iotService.getCuiObjResponse().deleteDevice({
      deviceId: deviceId
    }).then(function(response) {
      console.log("deleted Device");
      that.loadDevice(deviceId);
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        alert("Something went wrong. Please check chrome console or check with dev team");
      }
    }); 
  }

  resetDevice(deviceId) {
    const that = this;
    that.iotService.getCuiObjResponse().resetDevice({
      deviceId: deviceId
    }).then(function(response) {
      console.log("reseted Device");
      that.loadDevice(deviceId);
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        alert("Something went wrong. Please check chrome console or check with dev team");
      }
    }); 
  }
  
  unlockDevice(deviceId) {
    const that = this;
    that.iotService.getCuiObjResponse().unlockDevice({
      deviceId: deviceId
    }).then(function(response) {
      console.log("unlocked Device");
      that.loadDevice(deviceId);
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        alert("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  getStreamDevices(deviceId) {
    const that = this;
    that.streamDeviceResponse = [];
    that.currentDeviceStream = 0;
    that.deviceStream = '';
    that.iotService.getCuiObjResponse().getStreams({
      qs:[['deviceId',deviceId]]
    }).then(function(response) {
      if(response != null) {
        console.log("getStreamDevices "+response.length);
        that.streamDeviceResponse = response;
        that.deviceStream = that.streamDeviceResponse[0];
      }
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        alert("Something went wrong. Please check chrome console or check with dev team");
      }
    }); 
  }

  prevStreamPage(){
    //console.log("prevStreamPage");
    let currentDeviceStream = this.currentDeviceStream-1;
    if (currentDeviceStream < 0) { currentDeviceStream= 0; }  
    const that = this;
    that.currentDeviceStream = currentDeviceStream;
    that.deviceStream = that.streamDeviceResponse[currentDeviceStream];
  }

  nextStreamPage(){
    //console.log("nextStreamPage");
    let currentDeviceStream = this.currentDeviceStream+1;
    if (currentDeviceStream == this.streamDeviceResponse.length) { 
      currentDeviceStream= this.streamDeviceResponse.length-1;
     }  
    const that = this;
    console.log("nextStreamPage"+currentDeviceStream);
    that.currentDeviceStream = currentDeviceStream;
    that.deviceStream = that.streamDeviceResponse[currentDeviceStream];
  }

  selectStream(stream){
    const that = this;
    var obj = {
      "creator": that.device.creator,
      "creatorAppId": that.device.creatorAppId,
      "realm": that.device.realm,
      "streamId": stream.id, 
      "deviceId":that.device.id
    }
    that.iotService.getCuiObjResponse().addDeviceToStream({
      streamId: stream.id,
      data:obj
    }).then(function(streamDeviceResponse) {
      that.getStreamDevices(that.device.id);
      console.log("stream device added -- "+streamDeviceResponse.length);
      stream.isAdded = "Added";
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  deSelectStream(stream){
    const that=this;
    that.iotService.getCuiObjResponse().removeDeviceFromStream({
      streamId: stream.id,
      deviceId: that.device.id
    }).then(function(streamDeviceResponse) {
      that.getStreamDevices(that.device.id);
      console.log("Removed association");
      stream.isAdded = "Add";
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.deviceService.errorMessage=err.responseJSON.apiMessage;
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
    
  }

  toggleIcon(deviceId) {
    const that = this;
    if (that.icon === that.faToggleOffIcon) {
      that.icon = that.faToggleOnIcon;
      that.toggleEnable = 'toggle-on';
      that.iotService.getCuiObjResponse().activateDevice({
        deviceId: deviceId
      }).then(function(response) {
        console.log("activate Device");
        that.device.version = parseInt(that.device.version) + 1;
        that.device.isActive = true;
        //that.loadDevice(deviceId);
      }).fail(function (err){
        if(err.responseJSON.apiStatusCode){
          alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
        } else{
          alert("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    } else {
      that.icon = that.faToggleOffIcon;
      that.toggleEnable = 'toggle-off';
      that.iotService.getCuiObjResponse().deactivateDevice({
        deviceId: deviceId
      }).then(function(response) {
        console.log("deactivate Device");
        that.device.version = parseInt(that.device.version) + 1;
        that.device.isActive = false;
        //that.loadDevice(deviceId);
      }).fail(function (err){
        if(err.responseJSON.apiStatusCode){
          alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
        } else{
          alert("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    }
  }

  updateDeviceAttribute() {
    const that = this;
    var deviceForUpdate = JSON.parse(JSON.stringify(that.device));
    console.log("deviceForUpdate"+deviceForUpdate);

    //checking description object
    if (deviceForUpdate.description != null && deviceForUpdate.description[0].text != "") {
      if (deviceForUpdate.description[0].text != null && deviceForUpdate.description[0].lang == null) {
          delete deviceForUpdate.description;
          deviceForUpdate.description = [];
          deviceForUpdate.description.push({ lang: 'en-us', text: that.device.description[0].text });
      }
    }

    if (deviceForUpdate.attributes.standard != undefined) {
        for (var i = 0; i < deviceForUpdate.attributes.standard.length; i++) {
            var obj = deviceForUpdate.attributes.standard[i];
            //console.log(obj);
            if (obj.attributeType.type == "integer") {
                if (deviceForUpdate.attributes.standard[i].value == null || deviceForUpdate.attributes.standard[i].value == "") {
                    delete deviceForUpdate.attributes.standard[i].value;
                } else {
                    deviceForUpdate.attributes.standard[i].value = parseInt(deviceForUpdate.attributes.standard[i].value);
                }
            } else if (obj.attributeType.type == "decimal") {
                if (deviceForUpdate.attributes.standard[i].value == null || deviceForUpdate.attributes.standard[i].value == "") {
                    delete deviceForUpdate.attributes.standard[i].value;
                } else {
                    deviceForUpdate.attributes.standard[i].value = parseFloat(deviceForUpdate.attributes.standard[i].value);
                }
            } else if (obj.attributeType.type == "bool") {
                if (deviceForUpdate.attributes.standard[i].value == null || deviceForUpdate.attributes.standard[i].value == "") {
                    delete deviceForUpdate.attributes.standard[i].value;
                } else {
                    if (deviceForUpdate.attributes.standard[i].value == 'true') {
                        deviceForUpdate.attributes.standard[i].value
                    }
                }
            }
            obj.attributeTypeId = deviceForUpdate.attributes.standard[i].attributeType.id
            delete deviceForUpdate.attributes.standard[i].attributeType;
        }
    }

    var tempObservableEventsArray = [];
    //Removing observableEvents object and adding just id to the array
    if (deviceForUpdate.observableEvents != undefined) {
        for (var i = 0; i < deviceForUpdate.observableEvents.length; i++) {
            var obj = deviceForUpdate.observableEvents[i];
            tempObservableEventsArray.push(obj.id);
            delete deviceForUpdate.observableEvents[i];
        }
        deviceForUpdate.observableEvents = tempObservableEventsArray;
    }

    var tempSupportedCommandsArray = [];
    //Removing supportedCommands object and adding just id to the array
    if (deviceForUpdate.supportedCommands != undefined) {
        for (var i = 0; i < deviceForUpdate.supportedCommands.length; i++) {
            var obj = deviceForUpdate.supportedCommands[i];
            tempSupportedCommandsArray.push(obj.id);
            delete deviceForUpdate.supportedCommands[i];
        }
        deviceForUpdate.supportedCommands = tempSupportedCommandsArray;
    }

    that.iotService.getCuiObjResponse().updateDevice({
      deviceId: deviceForUpdate.id,
      data: deviceForUpdate
    }).then(function(response) {
      console.log("updated Device"+response);
      that.device.version=response.version;
      that.deviceService.successMessage = "Device Info updated succesfully";
      //console.log("device"+that.device);
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.deviceService.errorMessage = err.responseJSON.apiMessage;
        //alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        alert("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }
  
  add(event: MatChipInputEvent): void {
    this.tagClass = 'tagInput';
    const input = event.input;
    const value = event.value;
    var indice =0;
    if(this.device.tags != undefined){
      indice = this.device.tags.indexOf(value);
    }else{
      indice = -1;
    }
    if(indice<0){
    // Add our fruit
    if ((value || '').trim()) {
      const that = this;
      that.iotService.getCuiObjResponse().createDeviceTag({
        deviceId: that.device.id,
        tag: value
      }).then(function(response) {
        console.log("added tag:"+value+" to Device :"+that.device.id);
          that.device.version = parseInt(that.device.version) + 1;
          if(that.device.tags == undefined) {
            that.device.tags = [];
          }
          const index = that.device.tags.indexOf(value);
          if (index < 0) {
            that.device.tags.push(value);
            input.value = '';
          }
          // Reset the input value
          
      }).fail(function (err){
        if(err.responseJSON.apiStatusCode){
          alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
        } else{
          alert("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    }
  }else{
      this.tagClass = 'tagInput-duplicate';
    }

  }

  remove(tag: string): void {
    const that = this;
    if(tag != undefined){
      that.iotService.getCuiObjResponse().deleteDeviceTag({
        deviceId: that.device.id,
        tag: tag
      }).then(function (response) {
          console.log("removed tag:"+tag+" to Device :"+that.device.id);
          that.device.version = parseInt(that.device.version) + 1;

          const index = that.device.tags.indexOf(tag);

          if (index >= 0) {
            that.device.tags.splice(index, 1);
          }

      }).fail(function (err){
        if(err.responseJSON.apiStatusCode){
          alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
        } else{
          alert("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    }
  }

  base64String:string;
  
  commandMessage(command,expandedElement) {
    //console.log("command message -- "+expandedElement);
    const that = this;
    if(that.previousCommandElement != undefined && that.previousCommandElement.commandTemplate.id != command.commandTemplate.id) {
      that.previousCommandElement.messageClass = 'bg-message';
    }
    var base64 = {
        "messageId": "Device's message id here",
        "deviceId": that.device.id,
        "commandId": command.commandTemplate.id,
        "latitude": "valid latitude",
        "longitude": "valid longitude"
    };
    command.commandTemplate.args.forEach(value => {
        if (value.type == 'string') { base64[value.name] = "string" };
        if (value.type == 'decimal') { base64[value.name] = 1.1 };
        if (value.type == 'bool') { base64[value.name] = true };
        if (value.type == 'integer') { base64[value.name] = 1 };
    });
    if(expandedElement === null) {
      command.messageClass = 'bg-message';
    } else {
      command.messageClass = 'bg-message-selected';
    }
    that.previousCommandElement = command;
    that.base64String = JSON.stringify(base64, null, 2);
  }

  eventMessage(event,expandedElement) {
    //console.log("event message -- "+expandedElement);
    const that = this;
    if(that.previousEventElement != undefined && that.previousEventElement.eventTemplate.id != event.eventTemplate.id) {
      that.previousEventElement.messageClass = 'bg-message';
    }
    var base64 = {
        "messageId": "Device's message id here",
        "deviceId": that.device.id,
        "eventTemplateId": event.eventTemplate.id,
        "latitude": "valid latitude",
        "longitude": "valid longitude"
    };

    event.eventTemplate.eventFields.forEach(value => {
        if (value.type == 'string') { base64[value.name] = "string" };
        if (value.type == 'decimal') { base64[value.name] = 1.1 };
        if (value.type == 'bool') { base64[value.name] = true };
        if (value.type == 'integer') { base64[value.name] = 1 };
    });
    if(expandedElement === null) {
      event.messageClass = 'bg-message';
    } else {
      event.messageClass = 'bg-message-selected';
    }
    that.previousEventElement = event;
    that.base64String = JSON.stringify(base64, null, 2);
  }

  //viewDeviceTemplate
  viewDeviceTemplate(parentDeviceTemplateId){
    this.router.navigateByUrl("/templates/"+parentDeviceTemplateId);
  }

  viewStream(streamId){
    this.router.navigateByUrl("/streams/"+streamId);
  }

  countStreams(val) {
    const that = this;
    that.showPagination =false;
    if(val === "**") {
      val = '';
    }
    if(val === '') {
      that.iotService.getCuiObjResponse().countStreams({
      }).then(function (response) {
        console.log("countStreams : "+response);
        that.streamCount = response;
        that.showPagination = true;
      }).fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
        } else {
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    } else {
      that.iotService.getCuiObjResponse().countStreams({
        qs:[['name',val]]
      }).then(function (response) {
        console.log("countStreams : "+response);
        that.streamCount = response;
        that.showPagination = true;
      }).fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
        } else {
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    }
  }

  deviceDetail(){
    this.router.navigateByUrl("/deviceDetails/"+this.device.id);

  }

  onPageChange(page) {
    const that = this;
    console.log("page : "+page);
    that.currentPage = page;
    
    if(this.streamSelected) {
      console.log("streamSelected"+this.streamSelected);
      this.getSelectedStreamForCurrentPage(this.streamSelected);
    } else {
      console.log("else not streamSelected");
      this.loadStream();
    }
  }

  createStreamForDevice(){
    const that = this;
    this.iotService.deviceStream = true;
    this.streamService.setOwnerId(this.deviceId);
  }

  ngOnDestroy(){
    this.iotService.deviceStream = false;
    this.iotService.viewApplication=false;
    this.iotService.sideNavStream=true;
    this.auditFlag=false;
    this.iotService.auditScriptFlag = false;
    this.iotService.auditscript="";
    this.deviceService.deviceId="";
    this.deviceService.setGeofences([]);
    this.deviceService.resetErrorMessage();
    this.iotService.digitalTwin=false;
  }

  auditDevice(){
 
    this.auditFlag=true;
    this.auditRefreshFlag=!this.auditRefreshFlag;
     
     
  }
  closeAudit(){
    this.auditFlag=false;
  }

  goBackAuditList() {
    const that=this;
    that.iotService.auditScriptFlag = false;
    that.iotService.auditscript="";
    that.auditFlag = true;
  }
}

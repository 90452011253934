import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IotService } from 'src/app/service/iot.service';
import { SideNavComponent } from 'src/app/side-nav/side-nav.component';
import { faToggleOff, faToggleOn, faAngleLeft, faAngleRight, faCheck, faCopy, faTimes, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-trusted-public-key-detail',
  templateUrl: './trusted-public-key-detail.component.html',
  styleUrls: ['./trusted-public-key-detail.component.scss']
})
export class TrustedPublicKeyDetailComponent implements OnInit {

  failed = faTimes;
  faToggleOffIcon = faToggleOff;
  faToggleOnIcon = faToggleOn;
  faAngleLeftIcon = faAngleLeft;
  faAngleRightIcon = faAngleRight;
  faCheckIcon = faCheck;
  faCopyIcon = faCopy;
  faAngleDoubleLeftIcon = faAngleDoubleLeft;
  faAngleDoubleRightIcon = faAngleDoubleRight;

  trustedPublicKey;


  supportLanguages = ['en'];

  constructor(private router: Router, private route: ActivatedRoute, private iotService: IotService,
    private sideNavComponent: SideNavComponent, private translateService: TranslateService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }



  ngOnInit() {

    this.route.params.subscribe(params => {
      //console.log(params['id']);
      var trustedPublicKeyId = params['id'];
      this.loadTrustedPublicKey(trustedPublicKeyId);
    });

  }
  loadTrustedPublicKey(trustedPublicKeyId: any) {
    const that = this;
    this.iotService.getCuiObjResponse().getTrustedPublicKey({
      trustedPublicKeyId: trustedPublicKeyId
    }).then(function (response) {
      console.log(response);
      that.trustedPublicKey = response;
      that.sideNavComponent.menuClear();
      that.sideNavComponent.menuChange('Dashboard', 'sub-type');
      that.sideNavComponent.menuChange('sshKeys.header','sub-type');
      that.sideNavComponent.menuChange('trustedPublicKeys.header','sub-type');
      that.sideNavComponent.menuChange(that.trustedPublicKey.name, 'sub-type');
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        alert("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        alert("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  disableTrustedPublicKey(id) {
    const that = this;
    that.iotService.getCuiObjResponse().deactivateTrustedPublicKey({
      trustedPublicKeyId: id
    }).then(function (response) {
      // console.log(response);
      that.loadTrustedPublicKey(id);
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }

  enablesTrustedPublicKey(id) {
    const that = this;
    that.iotService.getCuiObjResponse().activateTrustedPublicKey({
      trustedPublicKeyId: id
    }).then(function (response) {
      // console.log(response);
      that.loadTrustedPublicKey(id);
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }

  viewStream(streamId){
    console.log("strem Id "+streamId);
    this.router.navigateByUrl("/streams/" + streamId);
  }
}


/**
 * This is an auto generated code for ecosystems service listing page.
 * This file can be customized and will not be overwritten.
 * @version 1.0.0
 * @readonly
 * @author - System generated
 */

import { Injectable } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { IotService } from '../service/iot.service';
import { ListEcosystemsService } from './ListEcosystems.service';
import { RulesService } from '../service/rules.service';
import { EcosystemTemplatesService } from './ecosystemTemplates.service';
import { Router } from '@angular/router';
import { EcosystemMembersService } from './ecosystemMembers.service';
import { DigitalTwinsService } from './digitalTwins.service';

@Injectable({
  providedIn: 'root',
})
export class EcosystemsService extends ListEcosystemsService {
  selection: SelectionModel<String> = new SelectionModel<String>(true, []);
  private ecosystemTemplateService: EcosystemTemplatesService;
  

  constructor(
    public iotService: IotService,
    public ruleService: RulesService,
    private ecosystemMemberService: EcosystemMembersService,
    private router: Router,
  ) {
    super(iotService);
    this.selection.clear();
    this.loadSelected();
  }

  selectedEcosystemTemplate;
  parentEcosystemTemplateId;
  selectedAttributes = [];
  selectedEvents = [];
  selectedMembers = [];
  relationshipAttributes = [];
  customAttributes = [];
  createResource = true;
  createAttribute = false;
  viewResource = true;
  viewAttributes = false;
  viewMembership = false;
  viewRelationShip = false;
  ecosystemInstanceId;
  successMessage = '';
  errorMessage = '';
  warningMessage = '';
  customAttributesForUpdate = [];
  relationshipAttributesForUpdate = [];
  ecosystemMemberShips=[];
  allowedMemberTypes = [];

  fnOnSelectionSupport(results) {
    const that = this;
    if (that.iotService.createRule == true) {
      let ecosystems = that.ruleService.getEcosystems();
      ecosystems.push(results);
      that.ruleService.setEcosystems(ecosystems);
    }
    if (that.iotService.createEcosystemMembership == true) {
      that.ecosystemMemberService.setSelectedEcosystemId(results.id);
    }
  }

  fnRemoveSelectionSupport(results) {
    const that = this;
    if (that.iotService.createRule == true) {
      let ecosystems = that.ruleService.getEcosystems();
      ecosystems.forEach((value, index) => {
        if (value.id == results.id) ecosystems.splice(index, 1);
      });
      that.ruleService.setEcosystems(ecosystems);
    }
    if (that.iotService.createEcosystemMembership == true) {
      that.ecosystemMemberService.setSelectedEcosystemId(undefined);
    }
  }

  getSelectedEcosystemTemplate() {
    return this.selectedEcosystemTemplate;
  }

  setSelectedEcosystemTemplate(selectedEcosystemTemplate) {
    this.selectedEcosystemTemplate = selectedEcosystemTemplate;
  }

  getSelectedAttributes(): any {
    return this.selectedAttributes;
  }

  setSelectedAttributes(selectedAttributes: any) {
    this.selectedAttributes = selectedAttributes;
  }

  getSelectedEvents(): any {
    return this.selectedEvents;
  }

  setSelectedEvents(selectedEvents: any) {
    this.selectedEvents = selectedEvents;
  }

  getSelectedMembers(): any {
    return this.selectedMembers;
  }

  setSelectedMembers(selectedMembers: any) {
    this.selectedMembers = selectedMembers;
  }

  getCustomAttributes(): any {
    return this.customAttributes;
  }

  setCustomAttributes(customAttributes: any) {
    this.customAttributes = customAttributes;
  }

  setParentEcosystemTemplateId(parentEcosystemTemplateId) {
    this.parentEcosystemTemplateId = parentEcosystemTemplateId;
  }

  getParentEcosystemTemplateId() {
    return this.parentEcosystemTemplateId;
  }

  setEcosystemInstanceId(ecosystemInstanceId) {
    this.ecosystemInstanceId = ecosystemInstanceId;
  }

  getEcosystemInstanceId() {
    return this.ecosystemInstanceId;
  }

  getEcosystemTemplateService(): any {
    return this.ecosystemTemplateService;
  }

  setEcosystemTemplateService(ecosystemTemplateService: any) {
    this.ecosystemTemplateService = ecosystemTemplateService;
  }

  setRelationShipAttributes(attributes) {
    this.relationshipAttributes = attributes;
  }

  getRelationShipAttributes() {
    return this.relationshipAttributes;
  }

  setCustomAttributesForUpdate(customAttributesForUpdate) {
    this.customAttributesForUpdate = customAttributesForUpdate;
  }

  getCustomAttributesForUpdate() {
    return this.customAttributesForUpdate;
  }

  setRelationshipAttributesForUpdate(relationshipAttributesForUpdate) {
    this.relationshipAttributesForUpdate = relationshipAttributesForUpdate;
  }

  getRelationshipAttributesForUpdate() {
    return this.relationshipAttributesForUpdate;
  }

  resetErrorMessage() {
    this.errorMessage = '';
  }

  resetWarningMessage() {
    this.warningMessage = '';
  }

  resetSuccessMessage() {
    this.successMessage = '';
  }

  loadSelected() {
    if (
      this.ruleService.getEcosystems().length > 0 &&
      this.iotService.viewRule == true 
    ) {
      this.ruleService.getEcosystems().forEach((ecosystem) => {
        this.selection.select(ecosystem.id);
      });
    }
  }
}

import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  pageListOptions: number[] = [1];
  totalPages=0;
  firstPageElement:number=1;
  lastPage:boolean=true;
  firstPage:boolean=true;

  pageClass='ot-pagination';

  constructor() { }

  ngOnInit(): void {
    this.getCountPages(this.count,this.pageSize,this.currentPage,this.defaultPages,this.pageSizeOptions);
  }

  @Input() count: number;
  @Input() pageSize: number;
  @Input() currentPage:number;
  @Input() pageSizeOptions:number[];
  @Input() defaultPages:number;
  @Output() currentPageChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() currentPageSizeChange: EventEmitter<number> = new EventEmitter<number>();

  getCountPages(count,pageSize,currentPage,defaultPages,pageSizeOptions) {
    const that = this;
    that.totalPages = Math.ceil(count / pageSize);
    that.firstPageElement = 1;

    if(defaultPages === undefined) {
      that.defaultPages = 10; 
    } else {
      that.defaultPages = defaultPages; 
    }

   /* console.log("******** PaginationComponent :: getCountPages *********");
    console.log("count : " + count);
    console.log("pageSize : " + pageSize);
    console.log("currentPage : " + currentPage);
    console.log("defaultPages : " + that.defaultPages);
    console.log("pageSizeOptions : " + pageSizeOptions);
    console.log("totalPages : " + that.totalPages);*/

    if (that.totalPages == 0) {
      that.firstPageElement = 1;
      that.pageListOptions = [1];
      that.lastPage = true;
      that.firstPage = true;
    } else if (that.totalPages > that.defaultPages) {
      that.pageListOptions = Array.from({length:that.defaultPages},()=>that.firstPageElement++);
      that.lastPage = false;
      that.firstPage = true;
    } else {
      that.pageListOptions = Array.from({length:that.totalPages},()=>that.firstPageElement++);
      that.lastPage = true;
      that.firstPage = true;
    }
    console.log("that.pageListOptions : "+that.pageListOptions);
  }

  prevPage() {
    const that = this;
    that.lastPage=false;
    if(that.firstPageElement > that.totalPages) {
      that.firstPageElement = that.firstPageElement-that.defaultPages-(that.totalPages % that.defaultPages);
    } else {
      that.firstPageElement = that.firstPageElement-(2*that.defaultPages);
    }

    console.log("******** PaginationComponent :: prevPage *********");
    console.log("firstPageElement : "+that.firstPageElement);
    console.log("totalPages : "+that.totalPages);

    if (that.firstPageElement < 1) {
      that.firstPageElement = 1;
      that.firstPage = true;
    } else if(that.firstPageElement === 1) {
      that.firstPage = true;
    }
    if(that.totalPages < that.defaultPages) {
      that.pageListOptions = Array.from({length:that.totalPages},()=> that.firstPageElement++);
      that.lastPage = true;
    } else {
      that.pageListOptions = Array.from({length:that.defaultPages},()=> that.firstPageElement++);
    }
    console.log("that.pageListOptions : "+that.pageListOptions);
  }

  nextPage() {
    const that = this;
    that.firstPage = false;
    var last = that.totalPages - that.firstPageElement;

    console.log("******** PaginationComponent :: nextPage *********");
    console.log("totalPages : "+that.totalPages);
    console.log("firstPageElement : "+that.firstPageElement);
    console.log("last : "+last);

    if(last < 0) {
      that.firstPageElement = 1;
      that.firstPage = true;
      that.pageListOptions = Array.from({length:that.defaultPages},()=> that.firstPageElement++);
    } else {
      if(last < that.defaultPages) {
        that.pageListOptions = Array.from({length:last+1},()=> that.firstPageElement++);
        that.lastPage = true;
      } else {
        that.pageListOptions = Array.from({length:that.defaultPages},()=> that.firstPageElement++);
        that.lastPage = false;
      }  
    }
    console.log("that.pageListOptions : "+that.pageListOptions);
  }

  onPageChange(page) {
    const that = this;
    console.log("******** PaginationComponent :: onPageChange *********");
    console.log("page : "+page);
    that.currentPage = page;
    this.currentPageChange.emit(page);
  }

  selectPage(currentPageClass) {
    const that = this;
    if(currentPageClass === 'ot-pagination') {
      that.pageClass='ot-pagination--is-active'; 
    } else {
      that.pageClass='ot-pagination'
    }
  }

  onPageSizeChange(pageSize) {
    const that = this;
    console.log("******** PaginationComponent :: onPageSizeChange *********");
    console.log("pageSize : "+pageSize);
    that.pageSize = pageSize;
    that.getPages();
    if(!that.pageListOptions.includes(that.currentPage)){
      console.log("current page -- "+that.pageListOptions[0]);
      that.currentPage = that.pageListOptions[0];
      that.currentPageChange.emit(that.pageListOptions[0]);
      setTimeout(()=>{ 
        that.currentPageSizeChange.emit(pageSize);
      }, 1000);
    } else {
      that.currentPageSizeChange.emit(pageSize);
    }
  }

  getPages() {
    const that = this;
    that.totalPages = Math.ceil(that.count / that.pageSize);
    that.firstPageElement = that.firstPageElement - that.defaultPages;

    if (that.firstPageElement < 1) {
      that.firstPageElement = 1;
      that.firstPage = true;
    } else if(that.firstPageElement === 1) {
      that.firstPage = true;
    }
    var lastPageNumber = that.totalPages-that.firstPageElement;
    
    console.log("******** PaginationComponent :: getPages *********");
    console.log("totalPages : " + that.totalPages);
    console.log("firstPageElement : "+that.firstPageElement);
    console.log("lastPageNumber : " + lastPageNumber);

    if(lastPageNumber < 0) {
      that.firstPageElement = 1;
      that.firstPage = true;
      that.pageListOptions = Array.from({length:that.totalPages},(v,k)=> that.firstPageElement++);
    } else {
      if(lastPageNumber < that.defaultPages) {
        that.pageListOptions = Array.from({length:lastPageNumber+1},(v,k)=> that.firstPageElement++);
        that.lastPage = true;
      } else {
        that.pageListOptions = Array.from({length:that.defaultPages},(v,k)=> that.firstPageElement++);
        that.lastPage = false;
      }
    }
  }

}

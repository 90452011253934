import { Injectable } from '@angular/core';
import { SelectionModel} from '@angular/cdk/collections';
import { IotService } from '../service/iot.service';
import { ListCodeRelationshipsService } from './ListCodeRelationships.service';
import { TradingPartnersService } from './tradingPartners.service';
import { OrchestrationConfigsService } from './orchestrationConfigs.service';

@Injectable({
	  providedIn: 'root'
})

export class CodeRelationshipsService extends ListCodeRelationshipsService {

	selection: SelectionModel<String> = new SelectionModel<String>(true, []);

	constructor(public iotService:IotService, public tpService: TradingPartnersService,
		 public orchestrationConfigsService: OrchestrationConfigsService) {
		super(iotService);
		this.selection.clear();
		this.loadSelected();
	}

	loadSelected(){
	 if (this.orchestrationConfigsService.getTpCodeRelationship()) {
		 this.selection.select(this.orchestrationConfigsService.getTpCodeRelationship().id);
	 }
	}

	 fnOnSelectionSupport(results){
		 const that = this;
		 if(this.orchestrationConfigsService.getTpCodeRelationship() != undefined){
			 this.orchestrationConfigsService.setTpCodeRelationship(undefined);
		 }
		 setTimeout(()=>{
			 this.orchestrationConfigsService.setTpCodeRelationship(results);
		 }, 500);
	 }

	 fnRemoveSelectionSupport(results){
		 const that = this;
		 this.orchestrationConfigsService.setTpCodeRelationship(undefined);
	 }

	 createNew(){
		this.tpService.createCodeRelationShip=true;
		this.tpService.createResource=false;
	}

	viewDetails(id){
		this.tpService.setCodeRelationshipId(id);
		this.tpService.viewCodeRelationShip=true;
		this.tpService.createResource=false;
	}

	showErrorMessage(err){
		if(err.responseJSON.apiStatusCode){
			this.tpService.errorMessage=err.responseJSON.apiMessage;
		}
		else{
			this.tpService.errorMessage="Some thing went wrong, Please check console ";
		}
	 }

}

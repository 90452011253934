import { Injectable } from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import { BaseListService } from './BaseList.service';
import { IotService } from '../service/iot.service';
@Injectable({ 
	  providedIn: 'root' 
})

export abstract class ListDocumentInfoService extends BaseListService{
	 
	 constructor(public iotService:IotService) {
		 super(iotService);
	 }
	 
	 //All functions can be moved here so that it can be used by other services
   //Get Count, Get Details, activate and deativate
   //Component ts can call these function with service refrences
} 

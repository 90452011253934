import { Injectable } from '@angular/core';
@Injectable({
	providedIn: 'root'
}) export class SecurtiyService {
	constructor() { }


	certificateData;
	showAuthorities = true;
	showCertificates = false;
	showSSHKeyPairs = true;
	showTrustedPublicKeys = false;
	showKeyBundles=false;
	privateKeyLoaded=true;
	warningMessage;
	trustedAuthority;
	currentAuthority;

	getAuthority(){
		return this.trustedAuthority;
	}

	setAuthority(authority){
		this.trustedAuthority=authority;
	}

	resetWarningMessages(){
		this.warningMessage='';
	}

	keyBundle;

	public setKeyBundle(data){
		this.keyBundle=data;
	}

	public getKeyBundle(){
		return this.keyBundle;
	}

	public setCertificateData(data: any) {
		this.certificateData = data;

	}

	public getCertificateData() {
		return this.certificateData;
	}
}

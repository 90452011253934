import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ImportExportService } from '../service/import-export.service';
import { IotService } from '../service/iot.service';

@Component({
  selector: 'app-download-logs',
  templateUrl: './download-logs.component.html',
  styleUrls: ['./download-logs.component.scss']
})
export class DownloadLogsComponent implements OnInit {
  downloadLogInfo;
  errorMsg;
  successMsg;
  errorMsgFLag = false;
  successMsgFlag = false;
  showLogTab = false;
  importworking = false;
  logsworking = false;
  logdatas = [];
  hasError: boolean = false;
  downloadLogError;
  supportLanguages = ['en'];
  constructor(public iotService: IotService, public importExportService: ImportExportService, private translateService: TranslateService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
   }

  ngOnInit(): void {
    const that = this;
    that.initDownloadLogInfo();
    that.importExportService.errorMessage = "";
    that.importExportService.successMessage = "";
    that.downloadLogError = "";
  }
  ngOnDestroy(): void {
    const that = this;
    that.importExportService.errorMessage = "";
    that.importExportService.successMessage = "";
    that.downloadLogError = "";

  }
  initDownloadLogInfo() {
    const that = this;
    that.downloadLogInfo = {
      "getLogsJobId": ""
    };
  }

  save(replicationGetLogs) {
    const that = this;
    that.errorMsgFLag = false;
    that.successMsgFlag = false;
    that.showLogTab = false;


    that.errorMsg = "";
    var errorMsg = null;
    that.successMsg = "";
    that.downloadLogError = "";
    if (that.downloadLogInfo.getLogsJobId == "") {
      that.errorMsgFLag = true;
      that.downloadLogError = "Job ID is required to get logs.";
    }

    if (that.downloadLogError == "") {
      const that = this;
      that.logsworking = true;
      that.iotService.getCuiObjResponse().getReplicationLogs({
        'jobId': that.downloadLogInfo.getLogsJobId
      })
        .then(function (response) {
          that.logsworking = false;
          //console.log(response);
          that.successMsgFlag = true;
          that.showLogTab = true;
          that.importExportService.successMessage = "logs retrieved successfully."
          that.logdatas = response.jobMessage.split('~');
          //that.refresh();
        })
        .fail(function (response) {
          that.logsworking = false;
          that.errorMsgFLag = true;
          that.importExportService.errorMessage = response.responseJSON.apiMessage;
          // that.saving = false;
          // that.refresh();
        });

    } else {
      that.errorMsg = errorMsg;
    }

  }


}

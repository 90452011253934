/** 
 * This is an auto generated code for geofences service listing page.
 * This file can be customized and will not be overwritten.
 * @version 1.0.0 
 * @readonly 
 * @author - System generated 
 */

import { Injectable } from '@angular/core';
import { SelectionModel} from '@angular/cdk/collections';
import { IotService } from '../service/iot.service'; 
import { ListGeofencesService } from './ListGeofences.service';
import { RulesService } from '../service/rules.service';
import { DigitalTwinTemplatesService } from './digitalTwinTemplates.service';
import { DigitalTwinsService } from './digitalTwins.service';
import { DeviceService } from './device.service';

@Injectable({ 
	  providedIn: 'root' 
})

export class GeofencesService extends ListGeofencesService {
 
  


	 constructor(public iotService:IotService,
		 public ruleService: RulesService,
		 public digitalTwinTemplatesService:DigitalTwinTemplatesService,
		 public digitalTwinsService:DigitalTwinsService,
		 public deviceService:DeviceService) {
		 super(iotService);
		 this.selection.clear();
		 this.loadSelected();
	 }

	 successMessage='';
	 warningMessage='';
	 errorMessage: string;
	 geofenceId: any;
	 polygon: any;
	 maxSearchRadius: any;
	 mapLoad: boolean=false;
	 showNotification:boolean=false;
	 geofence: any;
	 association: any;
	 isUpdateSuccessful=true;
   
	 resetWarningMessage(){
	   this.warningMessage='';
	 }
	 resetSuccessMessage(){
	   this.successMessage='';
	 }
   
	 resetErrorMessage() {
	   this.errorMessage = '';
	 }

	createNew() {
		if(this.iotService.newDeviceTemplate == true){
		this.deviceService.createResource=true;
		this.deviceService.createGeofence=true;
		
		}
	}

	playRecord(element) {
		if(this.isUpdateSuccessful){
		let flag = this.multiSelectRows;
		if (this.selection.isSelected(element.id)) {
				 this.selection.deselect(element.id);
		}else{
			if(flag == "false"){
				this.selection.clear();
				}
		   this.selection.select(element.id);
			}
		}
		
		}

	 fnOnSelectionSupport(results){
		 const that = this;
		 if (that.iotService.createRule == true) {
			let geofence = that.ruleService.getGeofences();
			geofence.push(results);
			that.ruleService.setGeofences(geofence);
		  }
		  else if (this.iotService.digitalTwinTemplate == true) {
			that.associateGeofenceToDeviceTemplate(results);
		  }
		  else if (this.iotService.digitalTwin == true) {
			that.associateGeofenceToDevice(results);
		  }
		  else if (this.iotService.newDeviceTemplate == true) {
			let geofences = that.digitalTwinTemplatesService.getGeofences();
			geofences.push(results);
			that.digitalTwinTemplatesService.setGeofences(geofences);
		  }
	 } 

	 fnRemoveSelectionSupport(results){ 
		 const that = this;
		 if (that.iotService.createRule == true) {
			let geofence = that.ruleService.getGeofences();
			geofence.forEach((value, index) => {
				if (value.id == results.id) geofence.splice(index, 1);
			  });
			that.ruleService.setGeofences(geofence);
		  }
		  else if (this.iotService.digitalTwinTemplate == true) {
			that.dissociateGeofenceFromDeviceTemplate(results);
		  }
		  else if (this.iotService.digitalTwin == true) {
			that.dissociateGeofenceFromDevice(results);
		  }
		  else if (this.iotService.newDeviceTemplate == true) {
			let geofences = that.digitalTwinTemplatesService.getGeofences();
			geofences.forEach((value, index) => {
			  if (value.id == results.id) geofences.splice(index, 1);
			});
			that.digitalTwinTemplatesService.setGeofences(geofences);
		  }
	 } 

	 loadSelected() {
		const that = this;
		that.selection.clear();
		if(that.ruleService.getGeofences().length>0  && that.iotService.viewRule==true){
			that.ruleService.getGeofences().forEach(geofence => {
				that.selection.select(geofence.id);
			});	
		}	
		if(that.digitalTwinTemplatesService.getGeofences().length>0){
			that.digitalTwinTemplatesService.getGeofences().forEach((geofences) => {
			  this.selection.select(geofences.id);
			});
		  }

		  if(that.digitalTwinsService.getGeofences().length>0){
			that.digitalTwinsService.getGeofences().forEach((geofences) => {
			  this.selection.select(geofences.id);
			});
		  }

		  if(that.deviceService.getGeofences().length>0){
			that.deviceService.getGeofences().forEach((geofences) => {
			  this.selection.select(geofences.id);
			});
		  }
	}

	associateGeofenceToDeviceTemplate(results: any) {
		const that = this;
		that.association = {
		  creator: that.iotService.getCreator(),
		  creatorAppId: that.iotService.getCreatorAppId(),
		  realm: that.iotService.getRealm(),
		  resourceId: that.digitalTwinTemplatesService.getDigitalTwinTemplateId(),
		  resourceType: 'DEVICETEMPLATE',
		  geofenceId: results.id,
		  isActive: true,
		};
	
		that.iotService
		  .getCuiObjResponse()
		  .createGeofenceResourceAssociation({
			data: that.association,
		  })
		  .then(function (associationResponse) {
			that.isUpdateSuccessful=true;
			that.digitalTwinTemplatesService.associationMap.set(associationResponse.geofenceId,associationResponse.id);
			let geofences = that.digitalTwinTemplatesService.getGeofences();
			geofences.push(results);
			that.digitalTwinTemplatesService.setGeofences(geofences);
			that.digitalTwinTemplatesService.successMessage='Device geofence association created successfully!!';
			console.log('geofence association added -- ' + associationResponse);
			window.scrollTo({ top: 0, behavior: 'smooth' });
		  })
		  .fail(function (msg) {
			that.isUpdateSuccessful=false;
			if(msg.responseJSON.apiStatusCode){
				that.selection.deselect(results.id);
				that.digitalTwinTemplatesService.errorMessage = msg.responseJSON.apiMessage;
				window.scrollTo({ top: 0, behavior: 'smooth' });
			  } else{
				alert("Something went wrong. Please check chrome console or check with dev team");
			  }
			console.log(msg.responseJSON.apiMessage);
		  });
	  }
	
	  dissociateGeofenceFromDeviceTemplate(results: any) {
		const that = this;
		var id=that.digitalTwinTemplatesService.associationMap.get(results.id);
		console.log("remove geofence association with Id "+id);
		that.iotService
		  .getCuiObjResponse()
		  .deleteGeofenceResourceAssociation({
			associationId: id,
		  })
		  .then(function (dissociationResponse) {
			that.isUpdateSuccessful=true;
			that.digitalTwinTemplatesService.associationMap.delete(results.id);
			let geofences = that.digitalTwinTemplatesService.getGeofences();
			geofences.forEach((value, index) => {
			  if (value.id == results.id) geofences.splice(index, 1);
			});
			that.digitalTwinTemplatesService.setGeofences(geofences);
			that.digitalTwinTemplatesService.successMessage='Device geofence association removed successfully!!';
			window.scrollTo({ top: 0, behavior: 'smooth' });

			console.log('association of geofence removed from device template -- ' + dissociationResponse);
		  })
		  .fail(function (msg) {
			that.isUpdateSuccessful=false;
			if(msg.responseJSON.apiStatusCode){
				that.selection.select(results.id);
				that.digitalTwinTemplatesService.errorMessage = msg.responseJSON.apiMessage;
				window.scrollTo({ top: 0, behavior: 'smooth' });
			  } else{
				alert("Something went wrong. Please check chrome console or check with dev team");
			  }

			console.log(msg.responseJSON.apiMessage);
		  });
	  }

	  associateGeofenceToDevice(results: any) {
		const that = this;
		that.association = {
		  creator: that.iotService.getCreator(),
		  creatorAppId: that.iotService.getCreatorAppId(),
		  realm: that.iotService.getRealm(),
		  resourceId: that.deviceService.getDeviceId(),
		  resourceType: 'DEVICE',
		  geofenceId: results.id,
		  isActive: true,
		};
	
		that.iotService
		  .getCuiObjResponse()
		  .createGeofenceResourceAssociation({
			data: that.association,
		  })
		  .then(function (associationResponse) {
			that.deviceService.associationMap.set(associationResponse.geofenceId,associationResponse.id);
			let geofences = that.deviceService.getGeofences();
			geofences.push(results);
			that.deviceService.setGeofences(geofences);
			that.deviceService.successMessage='Device geofence association created successfully!!';
			console.log('geofence association added -- ' + associationResponse);
			window.scrollTo({ top: 0, behavior: 'smooth' });
		  })
		  .fail(function (msg) {
			console.log(msg.responseJSON.apiMessage);
			if (msg.responseJSON.apiStatusCode) {
				that.selection.deselect(results.id);
				that.digitalTwinTemplatesService.errorMessage = msg.responseJSON.apiMessage;
				window.scrollTo({ top: 0, behavior: 'smooth' });
				that.deviceService.errorMessage = msg.responseJSON.apiMessage;
				console.log("Error Occured: Status: " + msg.responseJSON.status + " Msg: " + msg.responseJSON.apiStatusCode);
			  } else {
				that.deviceService.errorMessage = "Error occured while assasociating geofence from device"
				console.log("Something went wrong. Please check chrome console or check with dev team");
			  }
		  });
	  }
	
	  dissociateGeofenceFromDevice(results: any) {
		const that = this;
		var id=that.deviceService.associationMap.get(results.id);
		console.log("remove geofence association with Id "+id);
		that.iotService
		  .getCuiObjResponse()
		  .deleteGeofenceResourceAssociation({
			associationId: id,
		  })
		  .then(function (dissociationResponse) {
			that.deviceService.associationMap.delete(results.id);
			let geofences = that.deviceService.getGeofences();
			geofences.forEach((value, index) => {
			  if (value.id == results.id) geofences.splice(index, 1);
			});
			that.deviceService.setGeofences(geofences);
			that.deviceService.successMessage='Device geofence association removed successfully!!';
			window.scrollTo({ top: 0, behavior: 'smooth' });

			console.log('association of geofence removed from device template -- ' + dissociationResponse);
		  })
		  .fail(function (msg) {
			console.log(msg.responseJSON.apiMessage);
			if (msg.responseJSON.apiStatusCode) {
				that.selection.select(results.id);
				that.digitalTwinTemplatesService.errorMessage = msg.responseJSON.apiMessage;
				window.scrollTo({ top: 0, behavior: 'smooth' });
				that.deviceService.errorMessage = msg.responseJSON.apiMessage;
				console.log("Error Occured: Status: " + msg.responseJSON.status + " Msg: " + msg.responseJSON.apiStatusCode);
			  } else {
				that.deviceService.errorMessage = "Error occured while dissasociating geofence from device"
				console.log("Something went wrong. Please check chrome console or check with dev team");
			  }
		  });
	  }
} 

<div class="heading">


    <table class="table" *ngIf="deviceService.displayCoordinates">
        <thead>
            <tr>
                <th *ngFor="let column of displayedColumns">
                    {{column}}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let coord of deviceService.coordinateList">


                <td style="width: 50%;"> {{coord.latitude}} </td>
                <td style="width: 50%;"> {{coord.longitude}} </td>


            </tr>
        </tbody>
    </table>


    <div *ngIf="iotService.deviceDashboard">
        <div id="deviceDashboardMap">
        </div>

    </div>
    <div id="map" *ngIf="!deviceService.displayCoordinates&&(!iotService.viewGeofence)&&(!iotService.viewDashboard)">

    </div>
    <div id="map" *ngIf="iotService.viewGeofence"></div>

    <div *ngIf="iotService.viewDashboard">

        <div id="dashboardMap">
        </div>


    </div>




</div>
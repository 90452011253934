<div class="newRoutingSource" style="overflow-y: hidden;" *ngIf=workingTradingPartnerRouteSource>

    <div *ngIf="byTp">

        <label for="name">{{'name'|translate}}</label>
        <input [(ngModel)]="workingTradingPartnerRouteSource.name[0].text" placeholder="optional" id="name" aria-label="Name">

        <app-documentInfo *ngIf=docInfoLoaded [pageSize]="5" [showControlBar]=false [displayedColumns]="['ADD','supportedDocTemplateType','docTemplateVersion','ACTIVE']" 
        [showSelectColoumn]=true [showEnableColumn]=true multiSelect="false" [showHeaderToolTips]=false>

        </app-documentInfo>

        <app-tradingPartners *ngIf="senderOwnerLoaded" [pageSize]="5" [showSelectColoumn]=true
         [displayedColumns]="['ADD','name','description','status']" [showSelectColoumn]=true 
         multiSelect="false" addnQueryParam=[id::{{tpService.tradingPartnerIds}}] [showHeaderToolTips]=false>
        </app-tradingPartners>


       
        <div class="button-row pull-right" style="margin-top: 20px; margin-right: 10px;">
            <button class="btn btn-secondary " (click)="goBack() ">{{'go.back' | translate}}</button>
            <button class="btn btn-primary " (click)="save() ">{{'save' | translate}}</button>
        </div>
        <br>
        <br>
        <br>
        <br>

    </div>

    <div *ngIf="byTpCodes">
        <label for="name">{{'name'|translate}}</label>
        <input [(ngModel)]="workingTradingPartnerRouteSource.name[0].text" placeholder="optional" id="name" aria-label="Name">

        <app-documentInfo *ngIf=docInfoLoaded [pageSize]="5" [showControlBar]=false 
        [displayedColumns]="['ADD','supportedDocTemplateType','docTemplateVersion','ACTIVE']" 
        [showSelectColoumn]=true [showEnableColumn]=true multiSelect="false" [showHeaderToolTips]=false>
        </app-documentInfo>

        <app-fromTPCodes *ngIf=fromTpCodeLoaded [pageSize]="5" [showControlBar]=false 
        [displayedColumns]="['ADD','code','qualifier','ACTIVE']" [showSelectColoumn]=true
         [showEnableColumn]=true multiSelect="false" 
         addnQueryParam=[tradingPartnerId::{{tpService.tradingPartnerIds}}] [showHeaderToolTips]=false></app-fromTPCodes>

        <app-toTPCodes *ngIf=toTpCodeLoaded [pageSize]="5" [showControlBar]=false 
        [displayedColumns]="['ADD','code','qualifier','ACTIVE']" [showSelectColoumn]=true 
        [showEnableColumn]=true multiSelect="false" 
        addnQueryParam=[tradingPartnerId::{{tpService.tradingPartnerIds}}] [showHeaderToolTips]=false></app-toTPCodes>

       
        <div class="button-row pull-right" style="margin-top: 20px; margin-right: 10px;">
            <button class="btn btn-secondary" (click)="goBack()">{{'go.back' | translate}}</button>
            <button class="btn btn-primary" (click)="save()">{{'save' | translate}}</button>
        </div>
        <br>
        <br>
        <br>
        <br>

    </div>

    <br>
    <br>
    <br>
    <br>
</div>
<div *ngIf="byTp">
    <ng-container *ngIf="!workingTradingPartnerRouteSource || !docInfoLoaded||!senderOwnerLoaded">
        <div>
            <br/><br/>
        </div>
        <div class="d-flex justify-content-center pageLoading">
            <div class="spinner-border" role="status">
                <span class="sr-only" id="loading"></span>
            </div>
        </div>
    </ng-container>

</div>

<div *ngIf="byTpCodes">
    <ng-container *ngIf="!workingTradingPartnerRouteSource || !fromTpCodeLoaded||!toTpCodeLoaded || !docInfoLoaded">
        <div>
            <br/><br/>
        </div>
        <div class="d-flex justify-content-center pageLoading">
            <div class="spinner-border" role="status">
                <span class="sr-only" id="loading"></span>
            </div>
        </div>
    </ng-container>

</div>

<ng-container *ngIf="!workingTradingPartnerRouteSource">
    <div>
        <br/><br/>
    </div>
    <div class="d-flex justify-content-center pageLoading">
        <div class="spinner-border" role="status">
            <span class="sr-only" id="loading"></span>
        </div>
    </div>
</ng-container>
<mat-sidenav-container class="new-key-bundle" autosize>

    <mat-sidenav class="midNav-container" #sidenav mode="side" disableClose="true" opened="true">


        <div class="alert warning" *ngIf="warningMessage">
            <h5>
                <fa-icon [icon]="faWarningIcon"></fa-icon>
                <span>{{warningMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)="resetWarningMessage2()" [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>
        <div class="alert critical" *ngIf="errorMessage">
            <h5>
                <fa-icon [icon]="faCriticalIcon"></fa-icon>
                <span>{{errorMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=resetWarningMessage2() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>

        </div>
        <div class="data">
            <h1 style="margin-left: -15px;"> {{ 'create Key Bundle' | translate}}</h1>
            <p>{{'keyBundles.definition' | translate}}</p>
            <div >
                <button [disabled]="name==''||pemChainData=='' ||keyBundleCreated" class="btn btn-primary" (click)="save()">{{ 'save' | translate}}</button>
            </div>
        </div>



    </mat-sidenav>
    <mat-sidenav-content class="example-sidenav-content">
        <div class="key-bundle-data">

            <div class="form-data">
                <label for="name"> {{ 'name' | translate}}</label>
                <input placeholder="required" id="name" type="text" aria-label="Name" [(ngModel)]="name" (keyup)="resetWarningMessage($event)">
                <label for="certificate-data">{{ 'concatenated PEM Chain (PKCS#8 encoded)' | translate}}</label>
                <textarea rows="13" placeholder="required" id="certificate-data" aria-label="Certificate-data" [(ngModel)]="pemChainData" (keyup)="resetWarningMessage($event)"></textarea>
                <br>
                <!-- <p *ngIf="warningMessage" style="color: red;">{{warningMessage}}</p> -->
                <div *ngIf=exampleToggle>
                    <p class="blue-highlight" (click)="toggleExample()">{{ 'example' | translate}}
                        <fa-icon [icon]="faAngleDown"></fa-icon>
                    </p>
                </div>
                <div *ngIf=!exampleToggle>
                    <p class="blue-highlight" (click)="toggleExample()">{{ 'example' | translate}}
                        <fa-icon [icon]="faAngleUp"></fa-icon>
                    </p>
                    <p style="font-size: 12px;">-----BEGIN CERTIFICATE----- MIIDQDCCAiigAwIBAgIEWsS0kDANBgkqhkiG9w0BAQsFADBiMQswCQYDVQQGEwJV UzEQMA4GA1UECAwHRGV0cm9pdDERMA8GA1UEBwwITWljaGlnYW4xETAPBgNVBAoM ... H+kWSO7zyGAvCHojiYIzhCY5U8QL0Hdc86ZlYlrQxRW+uwuJs8Cb4Si9O+zmAdfR ADCCAQoCggEBAJP0UwWB4zvhYL5QhgKn0bP9ru9RuUTYPdqugrSg/lyMpva2qVzD
                        -----END CERTIFICATE----- -----BEGIN CERTIFICATE----- MIIDQDCCAiigAwIBAgIEWsS0kDANBgkqhkiG9w0BAQsFADBiMQswCQYDVQQGEwJV UzEQMA4GA1UECAwHRGV0cm9pdDERMA8GA1UEBwwITWljaGlnYW4xETAPBgNVBAoM ... H+kWSO7zyGAvCHojiYIzhCY5U8QL0Hdc86ZlYlrQxRW+uwuJs8Cb4Si9O+zmAdfR
                        ADCCAQoCggEBAJP0UwWB4zvhYL5QhgKn0bP9ru9RuUTYPdqugrSg/lyMpva2qVzD -----END CERTIFICATE----- -----BEGIN PRIVATE KEY----- MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCT9FMFgeM74WC+ UIYCp9Gz/a7vUblE2D3aroK0oP5cjKb2tqlcw2V9s8fD3oFmRy3yAdCiThC4+y/N
                        ... hAUnaLUVyq2QPimCwOWKBERVPH5GQHZ3SV3GakPz+5Xxcjp+kJM95HVUveibrG0O 0pFtY/+Qgl44qSvyKe0vYuCyYkucropDuHyvEMGZHZgKZiKwjXCNk9pufl9ZbD/2 -----END PRIVATE KEY-----
                    </p>
                </div>


            </div>
        </div>
    </mat-sidenav-content>

</mat-sidenav-container>
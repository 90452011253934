<div class="description">
    <!-- <h1 *ngIf=!deviceService.createNewTemplate>Device </h1>
    <h1 *ngIf=deviceService.createNewTemplate>Device Template</h1> -->

    <form>
        <div style="margin-left: -10px;">
            <label for="device-name"> {{'name' | translate}}</label>
            <input placeholder="required" id="device-name" type="text" value={{deviceService.deviceName}}
                (keyup)="deviceName($event)" aria-label="Name">

            <label for="device-description">{{'description' | translate}}</label>
            <textarea placeholder="optional" id="device-description" value={{deviceService.deviceDescription}}
                (keyup)="deviceDescription($event)" aria-label="Description"></textarea>


            <br />

            <label for="device-tags">{{'tags' | translate}}</label>
            <mat-chip-list #tagList aria-label="tags">

                <mat-chip class="ot-chip" *ngFor="let tag of deviceService.getDeviceTags()" [selectable]="true"
                    [removable]=true (removed)="remove(tag)">
                    {{tag}}
                    <button class="ot-chip__close" matChipRemove></button>
                </mat-chip>
                <input [ngClass]="tagClass" placeholder="add a tag" #tagInput [matChipInputFor]="tagList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                    (matChipInputTokenEnd)="add($event)">

            </mat-chip-list>

        <label *ngIf=deviceService.createFromScratch  for="device-template">{{'template.name' | translate}}</label>
        <input *ngIf=deviceService.createFromScratch  placeholder="required" id="device-template" type="text"
            value={{deviceService.newTemplatename}} (keyup)="templateName($event)" aria-label="Template Name">
        </div>
    </form>
</div>

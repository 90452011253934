<ng-container>
    <div class="container" >
        <!-- <div style="text-align: center;">
            <p style=" font-size: medium;">Performance Matrix Report</p>
        </div> -->

        <div class="row">
            <!-- <p>{{iotService.viewDashboard}}</p> -->
            <h5 *ngIf="iotService.viewDashboard" style="font-size: 14px;font-weight: 550;margin-top: 5px;" >Platform Performance &nbsp;</h5>
            <p  *ngIf="!iotService.viewDashboard" style="padding-top: 5pt;">Date Range: &nbsp;</p>
                <ng-container  *ngIf="!iotService.viewDashboard">
                    <select name="typeChange" [(ngModel)]="typeChangeControl" (ngModelChange)="getStatistics()">
                        <option [value]="item.value" *ngFor="let item of dateRanges" >
                            {{item.viewValue}}
                        </option>
                    </select>
                </ng-container>
            <ng-container *ngIf="iotService.viewDashboard">
                <select name="graph-type" [(ngModel)]="viewValue">
                    <option [value]="item.viewValue" *ngFor="let item of graphTypes">
                        {{item.viewValue}}
                    </option>
                </select>
            </ng-container>
        </div>
     



        <!-- Responsive with normal div -->
        <ng-container *ngIf="iotService.viewDashboard" class="insideDashboard">
            <br>
           
            <div class="row" *ngIf=isDataAvailable>
                <div class="totalMessageChart" *ngIf="viewValue=='Total Messages'">
                    <canvas baseChart *ngIf="mbarChartLabels.length>0" [routerLink]="['/perfmatrix']" [datasets]="barChartData" [labels]="mbarChartLabels" [options]="barChartOptions" [colors]="barChartColors" [legend]="barChartLegend" [chartType]="barChartType"></canvas>
                </div>
                <div class="pieChart" *ngIf="pieChartData.length>0 && viewValue=='Message Tracking'">
                    <canvas baseChart *ngIf="pieChartData.length>0" [routerLink]="['/perfmatrix']" [data]=" pieChartData " [labels]="pieChartLabels " [chartType]="pieChartType " [colors]="pieChartColors " [options]="pieChartOptions " [legend]="pieChartLegend " [plugins]="pieChartPlugins" (chartClick)="chartClicked($event)"></canvas>
                </div>
                <div class="row" >
                    <div class="processingTimeChart" *ngIf="viewValue=='Processing Time'">
                        <canvas baseChart *ngIf="mbarChartLabels2.length>0" [routerLink]="['/perfmatrix']" [datasets]="barChartData2" [labels]="mbarChartLabels2" [options]="barChartOptions2" [colors]="barChartColors2" [legend]="barChartLegend2" [chartType]="barChartType2"></canvas>                        </div>

                    <div *ngIf="viewValue=='98th Percentile'" class="minMaxChart"> 
                        <canvas baseChart *ngIf="mbarChartLabels3.length>0" [routerLink]="['/perfmatrix']" [datasets]="barChartData3 " [labels]="mbarChartLabels3 " [options]="barChartOptions3 " [colors]="barChartColors3 " [legend]="barChartLegend3 " [chartType]="barChartType "></canvas></div>
                </div>
            </div>

            <div *ngIf=!isDataAvailable class="nodata">
                <p style=" font-size: medium;">No Data found</p>
            </div>
        </ng-container>

        <ng-container *ngIf="!iotService.viewDashboard" class="Performance">
            <br>
           
            <div class="row" *ngIf=isDataAvailable>
                <div class="totalMessageChart1">
                    <canvas baseChart *ngIf="mbarChartLabels.length>0" [datasets]="barChartData" [labels]="mbarChartLabels" [options]="barChartOptions" [colors]="barChartColors" [legend]="barChartLegend" [chartType]="barChartType"></canvas>
                </div>
                <div class="pieChart1" *ngIf="pieChartData.length>0 ">
                    <canvas baseChart *ngIf="pieChartData.length>0" [data]=" pieChartData " [labels]="pieChartLabels " [chartType]="pieChartType " [colors]="pieChartColors " [options]="pieChartOptions " [legend]="pieChartLegend " [plugins]="pieChartPlugins" (chartClick)="chartClicked($event)"></canvas>
                </div>
                <div class="row" >
                    <div class="processingTimeChart1" >
                        <canvas baseChart *ngIf="mbarChartLabels2.length>0" [datasets]="barChartData2" [labels]="mbarChartLabels2" [options]="barChartOptions2" [colors]="barChartColors2" [legend]="barChartLegend2" [chartType]="barChartType2"></canvas>                        </div>

                    <div  class="minMaxChart1"> <canvas baseChart *ngIf="mbarChartLabels3.length>0" [datasets]="barChartData3 " [labels]="mbarChartLabels3 " [options]="barChartOptions3 " [colors]="barChartColors3 " [legend]="barChartLegend3 " [chartType]="barChartType "></canvas></div>
                </div>
            </div>

            <div *ngIf=!isDataAvailable class="nodata">
                <p style=" font-size: medium;">No Data found for selected time range</p>
            </div>
        </ng-container>
    </div>


    <div *ngIf="showTrackingData && !iotService.viewDashboard" id="tracking">
        <br>
        <br>
        <app-tracking *ngIf="showTrackingData&&trackingEnabled" [processingState]="processingStateValue" [startDateValue]="startDateValue"></app-tracking>
        <app-tracking *ngIf="showTrackingData&&!trackingEnabled" [processingState]="processingStateValue" [startDateValue]="startDateValue"></app-tracking>
    </div>

    <div  *ngIf="showTrackingData && !iotService.viewDashboard" style="float: right;">
        <br>

        <button class="btn btn-secondary" (click)="closeTracking()">close</button>
        <br>
        <br>
    </div>
</ng-container>
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { faAngleUp, faAngleDown , faTimes,faExclamationTriangle,faExclamation} from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { IotService } from '../service/iot.service';
import { SideNavComponent } from '../side-nav/side-nav.component';

@Component({
  selector: 'app-new-key-bundle',
  templateUrl: './new-key-bundle.component.html',
  styleUrls: ['./new-key-bundle.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  }
})
export class NewKeyBundleComponent implements OnInit {


  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;
  faCloseIcon=faTimes;
  faWarningIcon = faExclamationTriangle;
  faCriticalIcon=faExclamation;



  exampleToggle = true;
  name='';
  pemChainData='';
  pemChainJson;

  supportLanguages = ['en'];
  Authority;
  certData;
  successMessage: string;
  warningMessage: string;
  errorMessage:string;
  keyBundleCreated=false;

  constructor(public iotService: IotService, private translateService: TranslateService, private sideNavComponent: SideNavComponent, private router: Router) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
    this.sideNavComponent.menuClear();
    this.sideNavComponent.menuChange('Dashboard', 'sub-type');
    this.sideNavComponent.menuChange('certificates.header', 'sub-type');
    this.sideNavComponent.menuChange('keyBundles.header', 'sub-type');
    this.sideNavComponent.menuChange('keyBundles.create.new', 'sub-type');
  }

  ngOnInit(): void {
  }

  onClick(event) {
    this.resetWarningMessage(event);

  }

  resetWarningMessage(event: any){
    this.warningMessage="";
    this.errorMessage=''
  }

  save() {
    const that = this;
    that.keyBundleCreated=true;
    that.warningMessage="";
    that.pemChainJson = { "pemChain": that.pemChainData };
    that.iotService.getCuiObjResponse().createKeyBundle({
      data: that.pemChainJson,
      name: that.name
    }).then(function (response) {
      console.log("KeyBundle created succesfully");
      that.router.navigateByUrl("/keyBundles");
    })
    .fail(function (err) {
      that.keyBundleCreated=false;
      if (err.responseJSON.apiStatusCode) {
        that.errorMessage = err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
      if (err === null) {
        console.log("Error in create key bundle");
      }
    });
    }

  toggleExample(){
        this.exampleToggle = !this.exampleToggle;
      }

}

import { Component, OnInit } from '@angular/core';
import { faAngleUp, faAngleDown, faTimes, faExclamationTriangle, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { faToggleOff, faToggleOn, faAngleLeft, faAngleRight, faCheckCircle, faCheck, faCopy, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { IotService } from '../service/iot.service';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER, I } from '@angular/cdk/keycodes';
import { RouteDetailsService } from '../service/route-details.service';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RoutesService } from '../service/routes.service';
import { StreamsService } from '../service/streams.service';
import { DigitalTwinsService } from '../service/digitalTwins.service';
import { EventGroupsService } from '../service/eventGroups.service';
import { DocumentInfoService } from '../service/documentInfo.service';
import { RoutingSourceRelationshipsService } from '../service/routingSourceRelationships.service';

@Component({
  selector: 'app-route-detail',
  templateUrl: './route-detail.component.html',
  styleUrls: ['./route-detail.component.scss']
})
export class RouteDetailComponent implements OnInit {

  routetypess = [
    { value: 1, viewValue: 'EVENT' },
    { value: 2, viewValue: 'COMMAND' },
    { value: 3, viewValue: 'MESSAGE_INFO' },
    { value: 4, viewValue: 'TP_RELATIONSHIP' }
  ];
  faWarningIcon = faExclamationTriangle;
  viewValue;
  routeDetailInfo;
  streamRouteInfo;
  deviceRouteInfo;
  eventGroupRouteInfo;
  documentRouteInfo;
  routeSourceRouteInfo;
  streamId;
  deviceId;
  docId;
  eventGroupId;
  routingSourceId;
  routeSourceId;
  routingTopic;
  streamLoaded = false;
  eventGrouploaded = false;
  deviceLoaded = false;
  documentLoaded = false;
  routeSourceLoaded = false;
  updatedRouteInfo;
  faCheckIcon = faCheck;
  faSuccessIcon = faCheckCircle;
  faCriticalIcon = faExclamation;
  faCloseIcon = faTimes;
  warningMessage: string;
  // isRevoke = false;
  routeInfoId;
  dropDownVar;
  eventFlag = false;
  docInfoFlag = false;
  commandFlag = false;
  routingSourceFlag = false;
  hasError: boolean = false;
  streamError;
  docInfoError;
  deviceError;
  eventGroupError;
  routingSourceRelError;
  supportLanguages = ['en'];
  constructor(private router: Router, private route: ActivatedRoute, private iotService: IotService,
    private sideNavComponent: SideNavComponent, private translateService: TranslateService, public routeService: RoutesService, public routeDetailsService: RouteDetailsService, public streamService: StreamsService, public digitalTwinsService: DigitalTwinsService, public eventGroupsService: EventGroupsService, public documentInfoService: DocumentInfoService, public routingSourceRelationshipsService: RoutingSourceRelationshipsService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.routeInfoId = params['id'];
      this.loadRouteInfo(this.routeInfoId);
    });
    this.iotService.createRoute = true;
    this.streamService.selection.clear();
    this.eventGroupsService.selection.clear();
    this.digitalTwinsService.selection.clear();
    this.documentInfoService.selection.clear();
    this.routingSourceRelationshipsService.selection.clear();
  }
  ngOnDestroy() {
    const that = this;
    that.iotService.createRoute = false;
    that.routeService.setStream(undefined);
    that.routeService.setEventGroup(undefined);
    that.routeService.setDevice(undefined);
    that.routeService.setDocumentInfo(undefined);
    that.routeService.setRoutingSourceRelationship(undefined);
    this.streamService.selection.clear();
    this.eventGroupsService.selection.clear();
    this.digitalTwinsService.selection.clear();
    this.documentInfoService.selection.clear();
    this.routingSourceRelationshipsService.selection.clear();
    that.routeService.successMessage = "";

  }
  resetdata() {
    // this.routeService.setStream(undefined);
    this.routeService.setEventGroup(undefined);
    this.routeService.setDevice(undefined);
    this.routeService.setDocumentInfo(undefined);
    this.routeService.setRoutingSourceRelationship(undefined);

  }
  loadRouteInfo(routeInfoId: any) {
    const that = this;
    that.iotService.getCuiObjResponse().getRoute({
      routeId: routeInfoId
    }).then(function (response) {
      console.log(response);
      that.routeDetailInfo = response;
      if(!that.routeDetailInfo.description){
        that.routeDetailInfo.description = [];
        that.routeDetailInfo.description.push({ lang: 'en-us', text: "" });
      }
      that.viewValue = that.routeDetailInfo.routeType;
      that.streamId = that.routeDetailInfo.streamId;
      that.routeSourceId = that.routeDetailInfo.routeSourceId;
      that.routingTopic = that.routeDetailInfo.routingTopic;
      that.loadStreamRouteInfo(that.streamId);
      if (that.viewValue == 'EVENT') {
        that.loadEventGroupRouteInfo(that.routeSourceId);
      } else if (that.viewValue == 'COMMAND') {
        console.log("deviceloadedLoaded ViewValue" + that.viewValue);
        that.loadDeviceRouteInfo(that.routeSourceId);
      } else if (that.viewValue == 'MESSAGE_INFO') {
        that.loadDocumentRouteInfo(that.routeSourceId);
      } else {
        that.loadRoutingSourceRouteInfo(that.routeSourceId);
      }
      console.log(response);
      that.sideNavComponent.menuClear();
      that.sideNavComponent.menuChange('Dashboard', 'sub-type');
      that.sideNavComponent.menuChange('routes.header', 'sub-type');
      that.sideNavComponent.menuChange(that.routeDetailInfo.name[0].text, 'sub-type');
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  loadStreamRouteInfo(routeStreamInfoId: any) {
    const that = this;
    that.iotService.getCuiObjResponse().getStream({
      streamId: routeStreamInfoId
    }).then(function (response) {
      console.log(response);
      that.streamRouteInfo = response;
      that.routeService.setStream(response);
      that.streamLoaded = true;
    }).fail(function (err) {
      that.streamLoaded = true;
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  loadEventGroupRouteInfo(routeEventInfoId: any) {
    const that = this;
    that.iotService.getCuiObjResponse().getEventSource({
      eventSourceId: routeEventInfoId
    }).then(function (response) {
      console.log(response);
      that.eventGroupRouteInfo = response;
      that.routeService.setEventGroup(response);
      that.eventGrouploaded = true;
    }).fail(function (err) {
      that.eventGrouploaded = true;
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }


  loadDeviceRouteInfo(routeDeviceInfoId: any) {
    const that = this;
    that.iotService.getCuiObjResponse().getDevice({
      deviceId: routeDeviceInfoId
    }).then(function (response) {
      console.log(response);
      that.deviceRouteInfo = response;
      that.routeService.setDevice(response);
      that.deviceLoaded = true;
      console.log("deviceloaded" + that.deviceLoaded);
    }).fail(function (err) {
      that.deviceLoaded = true;
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  loadDocumentRouteInfo(routeDocumentInfoId: any) {
    const that = this;
    that.iotService.getCuiObjResponse().getDocumentInfoById({
      docInfoId: routeDocumentInfoId
    }).then(function (response) {
      console.log(response);
      that.documentRouteInfo = response;
      that.routeService.setDocumentInfo(response);
      that.documentLoaded = true;
    }).fail(function (err) {
      that.documentLoaded = true;
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  loadRoutingSourceRouteInfo(routeRoutingSourceInfoId: any) {
    const that = this;
    that.iotService.getCuiObjResponse().getRoutingSourceRelationship({
      routingSourceId: routeRoutingSourceInfoId
    }).then(function (response) {
      console.log(response);
      that.routeSourceRouteInfo = response;
      that.routeService.setRoutingSourceRelationship(response);
      that.routeSourceLoaded = true;
    }).fail(function (err) {
      that.routeSourceLoaded = true;
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }
  handleDropDown(dropDownVar) {
    console.log("printing dropDownVar" + dropDownVar);
    const that = this;
    if (dropDownVar == 'EVENT') {
      that.eventGrouploaded = false;
      that.eventFlag = true;
      that.eventGroupsService.selection.clear();
    } else if (dropDownVar == 'COMMAND') {
      that.deviceLoaded = false;
      that.commandFlag = true;
      that.digitalTwinsService.selection.clear();
    } else if (dropDownVar == 'MESSAGE_INFO') {
      that.documentLoaded = false;
      that.docInfoFlag = true;
      that.documentInfoService.selection.clear();
    } else {
      that.routeSourceLoaded = false;
      that.routingSourceFlag = true;
      that.routingSourceRelationshipsService.selection.clear();
    }

  }
  onChange(event) {
    this.handleDropDown(event);
    this.resetErrorMessage();
    this.resetdata();
  }

  updateRoute(routeUpdateId: any) {
    const that = this;
    if (!that.errorValidations()) {
      that.populateData();
      // that.iotService.getCuiObjResponse().updateRoute({
      //   routeId: routeUpdateId,
      //   data: that.routeDetailInfo
      // }).then(function (response) {
      //   // console.log(response);
      //   that.routeDetailInfo = response;
      //   that.loadRouteInfo(routeUpdateId);
      //   that.routeService.successMessage = "Route Info updated succesfully";
      //   console.log("Route Info updated succesfully. ID :", response.id);
      // }).fail(function (err) {
      //   if (err.responseJSON.apiStatusCode) {
      //     that.routeService.errorMessage = err.responseJSON.apiMessage;
      //     console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      //   } else {
      //     console.log("Something went wrong. Please check chrome console or check with dev team");
      //   }
      // });
    }
  }
  create() {
    const that = this;
    that.iotService.getCuiObjResponse().updateRoute({
      routeId: that.routeInfoId,
      data: that.routeDetailInfo
    }).then(function (response) {
      // console.log(response);
      that.routeDetailInfo = response;
      that.loadRouteInfo(that.routeInfoId);
      that.routeService.successMessage = "Route Info updated succesfully";
      console.log("Route Info updated succesfully. ID :", response.id);
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.routeService.errorMessage = err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }
  populateData() {
    this.routeDetailInfo.streamId = this.routeService.getStream().id;
    if (this.viewValue == 'EVENT') {
      this.routeDetailInfo.routeSourceId = this.routeService.getEventGroup().id;
      this.routeDetailInfo.routeType = "EVENT";
    }
    if (this.viewValue == 'COMMAND') {
      this.routeDetailInfo.routeSourceId = this.routeService.getDevice().id;
      this.routeDetailInfo.routeType = "COMMAND";
    }
    if (this.viewValue == 'MESSAGE_INFO') {
      this.routeDetailInfo.routeSourceId = this.routeService.getDocumentInfo().id;
      this.routeDetailInfo.routeType = "MESSAGE_INFO";
    }
    if (this.viewValue == 'TP_RELATIONSHIP') {
      this.routeDetailInfo.routeSourceId = this.routeService.getRoutingSourceRelationship().id;
      this.routeDetailInfo.routeType = "TP_RELATIONSHIP";
    }
    this.getStreamValidation(this.routeDetailInfo.streamId);

  }
  getStreamValidation(streamvarId: any) {
    // this.routeinfo.streamId = this.routeService.getStream().id;
    const that = this;
    that.iotService.getCuiObjResponse().getStream({
      streamId: streamvarId
    }).then(function (response) {
      if (response.consumerTopic) {
        that.routeDetailInfo.routingTopic = response.consumerTopic;
      }
      // that.routeService.setRoutingTopic(response.);
      console.log("Route Info created succesfully. ID :", response.id);
      that.create();
    })
      .fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          that.routeService.errorMessage = err.responseJSON.apiMessage;
          console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
        } else {
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
        if (err === null) {
          that.warningMessage = "Error occured while creating token";
          console.log("Error occured while creating document info");
        }
      });
  }
  deleteRoute(routeUpdateId: any) {
    const that = this;
    that.iotService.getCuiObjResponse().deleteRoute({
      routeId: routeUpdateId,
    }).then(function (response) {
      // console.log(response);
      // that.routeDetailInfo = response;
      // that.loadRouteInfo(routeUpdateId);
      that.router.navigateByUrl("/routes");
      console.log("Route Info updated succesfully. ID :", response.id);
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.routeService.errorMessage = err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }
  resetErrorMessage() {
    this.routeService.resetErrorMessage();
    this.routeService.resetSuccessMessage();
    this.streamError = "";
    this.docInfoError = "";
    this.routingSourceRelError = "";
    this.deviceError = "";
    this.eventGroupError = "";
  }
  // resetdata(){
  //   this.routeService.setStream(undefined);
  //   this.routeService.setEventGroup(undefined);
  //   this.routeService.setDevice(undefined);
  //   this.routeService.setDocumentInfo(undefined);
  //   this.routeService.setRoutingSourceRelationship(undefined);

  // }
  errorValidations() {
    const that = this;
    that.hasError = false;
    if (that.routeService.getStream() == undefined) {
      that.streamError = 'Please Select Streams';
      that.hasError = true;
    }
    if (that.viewValue == 'EVENT') {
      if (that.routeService.getEventGroup() == undefined) {
        that.eventGroupError = 'Please Select Event Groups';
        that.hasError = true;
      }
    } if (that.viewValue == 'COMMAND') {
      if (that.routeService.getDevice() == undefined) {
        that.deviceError = 'Please Select Devices';
        that.hasError = true;
      }
    }
    if (that.viewValue == 'MESSAGE_INFO') {
      if (that.routeService.getDocumentInfo() == undefined) {
        that.docInfoError = 'Please Select DocumentInfo';
        that.hasError = true;
      }
    }
    if (that.viewValue == 'TP_RELATIONSHIP') {
      if (that.routeService.getRoutingSourceRelationship() == undefined) {
        that.routingSourceRelError = 'Please Select Routing Source Relationships';
        that.hasError = true;
      }
    }
    return that.hasError;


  }
  // toggleRevoke() {
  //   this.isRevoke = !this.isRevoke;
  // }
}

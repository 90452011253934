import { hasLifecycleHook } from '@angular/compiler/src/lifecycle_reflector';
import { Component, OnInit,Output,EventEmitter } from '@angular/core';
import { IotService } from '../../service/iot.service';
import { DeviceService } from '../../service/device.service';
import { faAngleDoubleLeft, faAngleDoubleRight,faToggleOff,faToggleOn,faCheck} from '@fortawesome/free-solid-svg-icons';
import {SelectionModel} from '@angular/cdk/collections';
import { element } from 'protractor';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.scss']
})
export class TemplateListComponent implements OnInit {

  dataSource: any;
  displayedColumns: string[] = [' ','Name', 'Description', 'Tags', 'Enabled'];
  length = 1000;
  faAngleDoubleLeftIcon = faAngleDoubleLeft;
  faAngleDoubleRightIcon = faAngleDoubleRight;
  faToggleOffIcon = faToggleOff;
  faToggleOnIcon = faToggleOn;
  faCheckIcon = faCheck;
  templateSelected:string;

 

  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;
  pageSize = 5;
  currentPage =1;
  searchText;
  templateName;
  deviceTemplates = [];
  associations = [];
  deviceTemplate = [];
  showValue = false;
  private role: string;
  showSelect = true;
  HighlightRow : Number;
  display = "Select"
  savedTemplate = []
  found = false;
  arrs = [9, 2, 5];
  toSelect = 'select'
  previousId ='';
  displaySelected = '';
  isPlaying=[];
  prevElement;
  supportLanguages = ['en'];
  selection: SelectionModel<Element> = new SelectionModel<Element>(true, []);

  templateCount:number=0;
  showPagination:boolean=false;

  constructor(private iotService:IotService,private deviceService:DeviceService,private translateService: TranslateService) { 
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
}

  ngOnInit(): void {
    this.deviceService.setSelectedTemplate(undefined);
    this.deviceService.setStreamData(undefined);
    this.getDeviceTemplates();
    this.selection.isSelected = this.isChecked.bind(this);
    if(this.deviceService.createDeviceFromNewTemplate){
      this.OnSelection(this.deviceService.newTemplate.id);
      this.playRecord(this.deviceService.newTemplate,true);
    }
    this.countTemplates('');
  }
  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }

  
    isChecked(row: any): boolean {
      const found = this.selection.selected.find(el => el.id === row.id);
      if (found) {
        return true;
      }
      return false;
   }

  onTemplateSearch(event: any){
      this.templateSelected = event.target.value;
      let val = '*'+event.target.value+'*';
      const that = this;
      that.countTemplates(val);
      that.currentPage=1;
      that.iotService.getCuiObjResponse().getDeviceTemplateWithoutChildren({
        qs:[['pageSize',5],['page',that.currentPage],['name',val]]
      }).then(function(response) {
        // console.log(response);
        that.deviceTemplates = response;
        that.dataSource = that.deviceTemplates;
      }).fail(function (err){
        if(err.responseJSON.apiStatusCode){
          console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
        } else{
          console.log("Something went wrong. Please check chrome console or check with dev team");
        } 
      });
  }

  getDeviceTemplates(){

    const that = this;
    this.iotService.getCuiObjResponse().getDeviceTemplateWithoutChildren({
      qs:[['pageSize',5],['page',this.currentPage]]
    }).then(function(response) {
      // console.log(response);
      that.deviceTemplates = response;
      that.dataSource = that.deviceTemplates;
     
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  enableDisableRule(button) {
    if(this.display=='Select'){
      this.display='Deselect'
    }else{
      this.display='Select'
    }
    
}

  loadGeofence(templateId : any){
    const that = this;
    this.iotService.getCuiObjResponse().searchGeofenceResourceAssociation({
      resourceId: templateId,
      resourceType: "DEVICETEMPLATE"
    }).then(function(response) {
      // console.log(response);
      that.associations = response;
      let geofenceAssociations=[];
      that.deviceService.setAssociationData(geofenceAssociations);

      for (let i = 0; i < that.associations.length;i++)
      {
        that.iotService.getCuiObjResponse().getGeofenceNoPoly({
              id: that.associations[i].geofenceId
          }).then(function(geofence){
            geofenceAssociations.push({
                  "geofenceId": geofence.id,          
                  "geofenceName": geofence.name[0].text
              })
           //   $scope.deviceTemplate.geofences = $scope.geofenceAssociations
           that.deviceService.setAssociationData(geofenceAssociations);
          }).fail(function(err){

          })

      }
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  playRecord(element,flag) {

    // console.log(" is selected "+this.selection.isSelected(element)+"  id " +element.id);
    
    if(this.selection.isSelected(element)){
      this.selection.clear();
    }
    else{
      this.selection.toggle(element);
    }
    // console.log(" is selected "+this.selection.isSelected(element)+"  id " +element.id);
    const that = this;
    element.isPlaying = flag;
    if(that.prevElement != undefined && that.prevElement.id != element.id) {
      that.prevElement.isPlaying = false;
      this.toSelect = 'select';

    }
    // console.log(" is selected "+that.selection.isSelected(element)+"  id" +element.id);
    that.prevElement = element;
  }

  removeSelection (deviceTemplateId: any,flag:boolean) {
    this.deviceService.resetErrorMessage();
    var index = null;
    this.toSelect = 'select';
    this.prevElement.isPlaying = flag;
    // this.selection.toggle(this.deviceService.getSelectedTemplate());
    this.deviceService.setSelectedTemplate(undefined);
  };


  OnSelection(deviceTemplateId: any){
    this.selection.clear();
    this.deviceService.resetErrorMessage();
    this.deviceService.resetSuccessMessage();
    this.deviceService.resetWarningMessage();
    // console.log(event);
    const that = this;
    this.iotService.getCuiObjResponse().getDeviceTemplate({
      deviceTemplateId: deviceTemplateId
    }).then(function(response) {
      // console.log(response);
      that.savedTemplate = response;
      // let stream = []
      // that.deviceService.setStreamData(stream);
      that.deviceTemplate = response;
     
      that.loadGeofence(deviceTemplateId);
      that.deviceService.templateName = response.name[0].text;
      that.deviceService.templateSelect = true;
      that.deviceService.setSelectedTemplate(response);
      console.log(" is selected ", that.toSelect);
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  enableTemplate(deviceTemplateId : any){
    const that = this;
    this.iotService.getCuiObjResponse().activateDeviceTemplate({
      deviceTemplateId: deviceTemplateId
    }).then(function(response) {
      // console.log(response);
      that.getDeviceTemplates();
      
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.deviceService.warningMessage=err.responseJSON.apiMessage;
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  disableTemplate(deviceTemplate:any,deviceTemplateId : any){
    const that = this;
    this.iotService.getCuiObjResponse().deactivateDeviceTemplate({
      deviceTemplateId: deviceTemplateId
    }).then(function(response) {
      // console.log(response);
      if(that.selection.isSelected(deviceTemplate)){
        that.selection.clear();
      }
      that.deviceService.setSelectedTemplate(undefined);
      that.getDeviceTemplates();
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  getSelectedTemplateForCurrentPage(templateSelected){
    this.templateSelected = templateSelected;
    let startValue = '*'+templateSelected;
    let endValue = templateSelected + '*';
    const that = this;
    this.iotService.getCuiObjResponse().getDeviceTemplateWithoutChildren({
      qs:[['pageSize',5],['page',this.currentPage],['name',startValue],['name',endValue]]
    }).then(function(response) {
      // console.log(response);
      that.deviceTemplates = response;
      that.dataSource = that.deviceTemplates;
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      } 
    });
  }

  createNewTemplate(){
    this.deviceService.createNewTemplate=true;
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  countTemplates(name) {
    const that = this;
    that.showPagination=false;
    if(name === "**") {
      name = '';
    }
    if(name === '') {
      that.iotService.getCuiObjResponse().countDeviceTemplates({
        qs:[['name',name]]
      }).then(function (response) {
        console.log("count : "+response);
        that.templateCount = response;
        that.showPagination=true;
      }).fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
        } else {
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    } else {
      that.iotService.getCuiObjResponse().countDeviceTemplates({
        qs:[['name',name]]
      }).then(function (response) {
        console.log("count : "+response);
        that.templateCount = response;
        that.showPagination=true;
      }).fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
        } else {
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    }
  }

  onPageChange(page) {
    const that = this;
    console.log("page : "+page);
    that.currentPage = page;
    if(this.templateSelected) {
      this.getSelectedTemplateForCurrentPage(this.templateSelected);
    } else {
      this.getDeviceTemplates();
    }
  }

  

}

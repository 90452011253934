import { Inject, Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PlatformLocation } from '@angular/common';
import { Observable, of } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { IotService } from '../app/service/iot.service';

declare var cui: any
var host: any;
var iotCui: any;

@Injectable()
export class AppConfig {

    private config: Object = null;
    private env:    Object = null;
    private _jsonURL = 'assets/env.json';
    private _configjsonURL = 'assets/appConfig.json';

    constructor(private http: Http, private iotService:IotService,
      platformLocation: PlatformLocation) {
        host = platformLocation;
    }

    /**
     * Use to get the data found in the second file (config file)
     */
    public getConfig(key: any) {
        return this.config[key];
    }

    /**
     * Use to get the data found in the first file (env file)
     */
    public getEnv(key: any) {
        return this.env[key];
    }

    callCui(callback, responseData){
      const that = this;
      var personObj;
      cui.api({
            retryUnseured: false,
            serviceUrl: responseData.serviceURL,
            defs: [
               responseData.cuijsCalls
            ]
        }).then(function (obj) {
          iotCui = obj;
          that.iotService.setCuiObjResponse(obj);
          obj.handleCovAuthResponse({
             selfRedirect: true
          }).then(function (response) {
              console.log("auth --- "+response);   
               console.log(response);
                that.iotService.setRealm(response.authInfo[0].realmId);
                

        //cui.log('here');
        obj.getPerson({personId: response.cuid})
        .then(function(response) {
            //cui.log('getPerson success');
            personObj = response;
           console.log("Person"+personObj);
           that.iotService.setCreator('{person}' + personObj.id);
           that.iotService.setCreatorAppId("{person}" + personObj.id);
           that.iotService.setCreatorAppId('cov-iot-build');
           that.iotService.setdisplayUserName(personObj.username);
        })
        .fail(function(err) {
            cui.log('getPerson failed', cui.parseError(err));
            
        });

           }).fail(function (err) {
              console.log(err);
           });
          obj.setAuthHandler(function(){
             var originUri="";
             //console.log(host);
             if (host.location.hostname === 'localhost') {
                // Get it from a config file...
                originUri = responseData.originUri;
             } else {
                // Need to get it dynamically...
                originUri = host.location.host;
             }

             obj.covAuth({
               originUri: originUri,
               authRedirect: host.location.href,  //"http://localhost:4200/",
               appRedirect:  host.location.hash  //"#/dashboard"
               //originUri: 'q-iot1-qa3.iot-build.qa.covapp.io',
               //authRedirect: "http://localhost:4200/",
               //appRedirect: "#/dashboard"
             }).then(function (response) {
               console.log(response);
             }).fail(function (err) {
               console.log(err);
             });
          });

          callback();

        });
    }

    /**
     * This method:
     *   a) Loads "env.json" to get the current working environment (e.g.: 'production', 'development')
     *   b) Loads "config.[env].json" to get all env's variables (e.g.: 'config.development.json')
     */
    public load() {

        return new Promise((resolve, reject) => {
            this.http.get(this._jsonURL).map( res => res.json() ).catch((error: any):any => {
                console.log('Configuration file "env.json" could not be read');
                resolve(true);
                return Observable.throw(error.json().error || 'Server error');
            }).subscribe( (envResponse) => {
                this.env = envResponse;
                let request:any = null;

                switch (envResponse.env) {
                    case 'production': {
                        request = this.http.get('config.' + envResponse.env + '.json');
                    } break;

                    case 'development': {
                        request = this.http.get(this._configjsonURL);
                    } break;

                    case 'default': {
                        console.error('Environment file is not set or invalid');
                        resolve(true);
                    } break;
                }

                if (request) {
                    request
                        .map( res => res.json() )
                        .catch((error: any) => {
                            console.error('Error reading ' + envResponse.env + ' configuration file');
                            resolve(error);
                            return Observable.throw(error.json().error || 'Server error');
                        })
                        .subscribe((responseData) => {
                            this.config = responseData;
                            this.callCui(resolve,responseData);

                            /*const promise = new Promise(function(resolve, reject) {
                              setTimeout(function(iotService) {
                                resolve();
                              }, 5000);
                            });

                            promise.then(function() {
                              resolve();
                            });*/
                      });
                } else {
                    console.error('Env config file "env.json" is not valid');
                    resolve(true);
                }
            });

        });
    }
}

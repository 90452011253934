import { Injectable } from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import { StreamService } from './stream.service';
import { IotService } from '../service/iot.service'; 
import { ListOAuthTokensService } from './ListOAuthTokens.service';

@Injectable({ 
	  providedIn: 'root' 
})

export class OAuthTokensService extends ListOAuthTokensService {
	 
	selection: SelectionModel<String> = new SelectionModel<String>(true, []);
	 constructor(public iotService:IotService, public streamService:StreamService) {
		super(iotService);
		this.selection.clear();
	}

	loadSelected(){
		this.selection.clear();
		if(this.streamService.getOAuthTokenObject()){
			this.selection.select(this.streamService.getOAuthTokenObject().id);
		}
	}
	 
	 fnOnSelectionSupport(results){ 
		 const that = this; 
		 that.streamService.setOAuthTokenObject(results);
		 that.streamService.setOAuthToken(results.name[0].text);
		 that.streamService.setOAuthTokenId(results.id);
	 } 
	
	 fnRemoveSelectionSupport(results){ 
		 const that = this; 
		 that.streamService.setOAuthTokenObject(undefined);
		 that.streamService.setOAuthToken(undefined);
		 that.streamService.setOAuthTokenId(undefined);

	 } 
} 

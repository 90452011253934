import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { IotService } from '../service/iot.service';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faBell, faFire, faExclamationCircle, faWindowClose, faExclamationTriangle, faExclamation, faTimes, faCheck, faCircle} from '@fortawesome/free-solid-svg-icons';
import {FormControl, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';

interface TypeChange {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  showPageLoading = true;
  showSubPageLoading = true;
  breakpoint: number=3;
  detailbreakpoint: number=3;
  statusCriticalClass = 'bg-red-exception';
  statusWarningClass = 'bg-orange-exception';
  statusAlertClass = 'bg-blue-exception';
  statusClass = 'bg-grey-exception-selected';
  faBellIcon = faBell;
  faFireIcon = faFire;
  faExclamationCircleIcon = faExclamationCircle;
  faWindowCloseIcon = faWindowClose;
  faExclamationTriangleIcon = faExclamationTriangle;
  faExclamationIcon = faExclamation;
  faTimesIcon = faTimes;
  faCheckIcon = faCheck;
  faCircleIcon = faCircle;
  
  currentPage =1;
  currentEcoPage = 1;
  currentPageSizeViolations = 25;
  currentPageSizeCriticalViolations =25;
  currentPageSizeWarningViolations = 25;
  currentPageSizeAlertViolations =25;
  currentPageViolations =1;
  currentPageCriticalViolations=1;
  currentPageWarningViolations=1;
  currentPageAlertViolations=1;

  //pageSize=15;
  pageSizeOptions: number[] = [5, 10, 15, 25, 100];
  pageClass='ot-pagination';

  // critical Violation count & pagination attributes
  criticalViolationCount = 0;
  showCriticalPagination:boolean=false;

  // Warning Violation count & pagination attributes
  warningViolationCount =0;
  showWarningPagination:boolean=false;

  // Alert Violation count & pagination attributes.
  alertViolationCount =0;
  showAlertPagination:boolean=false;

  // Total Violation count & pagination attributes.
  totalViolationCount =0;
  showTotalPagination:boolean=false;

  //Tag Pagination
  tagPageListOptions : number[] = [1];
  tagCount:number=0;

  criticalViolations = [];
  warningViolations = [];
  alertViolations = [];
  violations = [];
  ecosystemsForExceptions = [];
  selectedEcosystemDevices = [];
  tagDetailedResponse = [];
  customTagDetailedResponse = [];
  searchTags = [];
  tagSelected:string;
  tagPagination:boolean=false;
  ecosystemSelected:string;

  deviceName:string;
  attributesList = []; 
  totalResponse = false;
  showCritical = false;
  showWarning = false;
  showAlert = false;
  isShow = true;
  violatedDeviceIdArg:string;

  form: FormGroup;
  typeChanges: TypeChange[] = [
    {value: '0', viewValue: 'Exception'},
   // {value: '1', viewValue: 'Ecosystem'},
    {value: '2', viewValue: 'Custom Tag'}
  ];
  typeChangeControl = new FormControl(this.typeChanges[0].value);
  currentPageSizeControl = new FormControl(this.pageSizeOptions[2]);
  currentCriticalpageSizeControl = new FormControl(this.pageSizeOptions[2]);
  currentWarningpageSizeControl = new FormControl(this.pageSizeOptions[2]);
  currentAlertpageSizeControl = new FormControl(this.pageSizeOptions[2]);

  constructor(private iotService:IotService,library: FaIconLibrary,private router: Router) {
    this.form = new FormGroup({
      typeChange: this.typeChangeControl
    });
   }
  ngOnDestroy(): void {
    this.iotService.viewDashboard=false;
  }

  ngOnInit(): void {

    this.iotService.viewDashboard=true;
    //this.breakpoint = (window.innerWidth <= 900) ? 1 : 4;
    //this.detailbreakpoint = (window.innerWidth <= 900) ? 3 : 10;
    // console.log("Testing realm"+this.iotService.getRealm());
    // console.log("Testing realm"+this.iotService.getCreator());
    // console.log("Testing realm"+this.iotService.getCreatorAppId());
    // console.log("Testing realm"+this.iotService.getdisplayUserName());

    if(window.innerWidth <= 1200 && window.innerWidth >650){
      this.breakpoint=2;
      this.detailbreakpoint =2;
    } 
    else if(window.innerWidth <= 650){
      this.breakpoint=1;
      this.detailbreakpoint=2;
    }
    else{
      this.breakpoint=4;
      this.detailbreakpoint=5;
    }
    // this.getDeviceExceptionsCriticalCount();
    // this.getDeviceExceptionsWarningCount();
    // this.getDeviceExceptionsTotalCount();
    // this.getDeviceExceptionsAlertCount();
    // this.getDeviceViolations();
    // this.getEcosystemsForExceptions();
    // this.getAllTags();
  }

  onResize(event) {
    if(event.target.innerWidth <= 1200 && event.target.innerWidth >650){
      this.breakpoint=2;
      this.detailbreakpoint =2;
    } 
    else if(event.target.innerWidth <= 650){
      this.breakpoint=1;
      this.detailbreakpoint=2;
    }
    else{
      this.breakpoint=4;
      this.detailbreakpoint=5;
    }
    console.log("width "+event.target.innerWidth +" breakpoint " + this.breakpoint+" detailbreakpoint "+this.detailbreakpoint);
  }

  getAllTags() {
    const that = this;
    that.tagDetailedResponse = [];
    this.iotService.getCuiObjResponse().getUniqueTags({
    }).then(function(response) {
      console.log("getAllTags");
      console.log(response);
      that.searchTags = response;
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  getTagDetailedResponse(value,page){
    const that = this;
    that.tagSelected = value;
    that.tagPagination= false;
    that.currentPage = page;
    that.tagDetailedResponse = [];
    that.customTagDetailedResponse = [];
    that.showSubPageLoading=true;

    that.iotService.getCuiObjResponse().getTagDetails({
      tagName: value,
      qs:[['pageSize',4],['page',that.currentPage]]
    }).then(function(response) {
      console.log(response);
      // if(response.deviceViolations != undefined ) {
      //   that.tagPagination= true;
      // }
      that.tagDetailedResponse = response;
      that.showSubPageLoading=false;
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

    // pagination calculations.
    that.tagPaginationCheck(value);
  }

  // pagination calculations.
  tagPaginationCheck(value: any) {
    const that = this;
    that.iotService.getCuiObjResponse().getTagDetails({
      tagName: value
    }).then(function (response) {
      console.log(response);
      if (response.deviceViolations != undefined) {
        that.tagCount=response.deviceViolations.length;
        that.tagPagination = true;
        console.log("tagCount :: "+that.tagCount);
      }
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  getCustomTagDetail(criteriaName,criteriaValue) {
    const that = this;
    that.showSubPageLoading=true;
    that.iotService.getCuiObjResponse().getCustomTagSelectedDetails({
      tagName: criteriaName,
      tagValue: criteriaValue
    }).then(function(response) {
      that.customTagDetailedResponse = response;
      that.showSubPageLoading=false;
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  getSelectedEcosystemsForExceptions(event:any) {
    this.ecosystemsForExceptions = [];
    this.selectedEcosystemDevices = [];
    console.log(event.target.value);
    if(event.keyCode === 13){
      const that = this;
      this.ecosystemSelected = event.target.value;
      if(event.target.value === '') {
        that.currentEcoPage=1;
        this.getEcosystemsForExceptions();
        return;
      }
      this.iotService.getCuiObjResponse().getEcosystemsForExceptions({
        qs:[['criteriaName',event.target.value]]
      }).then(function(response) {
        console.log(response);
        that.ecosystemsForExceptions = response;
      }).fail(function (err){
        if(err.responseJSON.apiStatusCode){
          console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
        } else{
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    }    
  }

  getEcosystemsForExceptions() {
    const that = this;
    that.iotService.getCuiObjResponse().getEcosystemsForExceptions({
      qs:[['pageSize',4],['page',that.currentEcoPage]]
    }).then(function(response) {
      console.log(response);
      that.ecosystemsForExceptions = response;
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  getEcosystemDetail(criteriaName) {
    console.log(criteriaName)
    const that = this;
    that.iotService.getCuiObjResponse().getSingleEcosystemExceptions({
      ecosystemName: criteriaName
    }).then(function(response) {
      console.log("getEcosystemDetail response");
      console.log(response);
      that.selectedEcosystemDevices = response;
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  getCriticalDevices(){
    const that = this;
    that.showSubPageLoading=true;
    that.iotService.getCuiObjResponse().getDeviceCriticalViolations({
      qs:[['pageSize',that.currentPageSizeCriticalViolations],['page',that.currentPageCriticalViolations]]
    }).then(function(response) {
      console.log(response);
      that.statusWarningClass = 'bg-orange-exception';
      that.statusAlertClass = 'bg-blue-exception';
      that.statusClass = 'bg-grey-exception';
      that.statusCriticalClass = 'bg-red-exception-selected';
      that.criticalViolations = response;
      that.showCritical = true;
      that.totalResponse = false;
      that.showWarning = false;
      that.showAlert = false;
      that.showSubPageLoading=false;
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  getWarningDevices(){   
    const that = this;
    that.showSubPageLoading=true;
    that.iotService.getCuiObjResponse().getDeviceWarningViolations({
      qs:[['pageSize',that.currentPageSizeWarningViolations],['page',that.currentPageWarningViolations]]
    }).then(function(response) {
      console.log(response);
      that.statusCriticalClass = 'bg-red-exception';
      that.statusAlertClass = 'bg-blue-exception';
      that.statusClass = 'bg-grey-exception';
      that.statusWarningClass = 'bg-orange-exception-selected';
      that.warningViolations=response;
      that.showWarning = true;
      that.totalResponse = false;
      that.showCritical = false;
      that.showAlert = false;
      that.showSubPageLoading=false;
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  getAlertDevices(){
    const that = this;
    that.showSubPageLoading=true;
    that.iotService.getCuiObjResponse().getDeviceAlertViolations({
      qs:[['pageSize',that.currentPageSizeAlertViolations],['page',that.currentPageAlertViolations]]
     }).then(function(response) {
       console.log(response);
       that.statusCriticalClass = 'bg-red-exception';
       that.statusWarningClass = 'bg-orange-exception';
       that.statusClass = 'bg-grey-exception';
       that.statusAlertClass = 'bg-blue-exception-selected';
       that.alertViolations = response;
       that.showAlert = true;
       that.totalResponse = false;
       that.showCritical = false;
       that.showWarning = false;
       that.showSubPageLoading=false;
     }).fail(function (err){
       if(err.responseJSON.apiStatusCode){
         console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
       } else{
         console.log("Something went wrong. Please check chrome console or check with dev team");
       }
     });
  }

  getDeviceViolations(){
    const that = this;
    that.showSubPageLoading = true;
    that.iotService.getCuiObjResponse().getDeviceViolations({
      qs:[['pageSize',that.currentPageSizeViolations],['page',that.currentPageViolations]]
     }).then(function(response) {
       console.log("getDeviceViolations :: "+response);
       that.violations = response;
       that.statusCriticalClass = 'bg-red-exception';
       that.statusWarningClass = 'bg-orange-exception';
       that.statusAlertClass = 'bg-blue-exception';
       that.statusClass = 'bg-grey-exception-selected';
       that.totalResponse = true;
       that.showCritical = false;
       that.showWarning = false;
       that.showAlert = false;
       that.showPageLoading = false;
       that.showSubPageLoading = false;
     }).fail(function (err){
       if(err.responseJSON.apiStatusCode){
         console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
       } else{
         console.log("Something went wrong. Please check chrome console or check with dev team");
       }
     });
  }

  // criticalViolationCount
  getDeviceExceptionsCriticalCount(){
    const that = this;
    this.iotService.getCuiObjResponse().getDeviceExceptionsCriticalCount({
    }).then(function(response) {
      console.log(response);
      that.criticalViolationCount = response;
      that.showCriticalPagination=true;
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  // warningViolationCount
  getDeviceExceptionsWarningCount(){
    const that = this;
    this.iotService.getCuiObjResponse().getDeviceExceptionsWarningCount({
    }).then(function(response) {
      console.log(response);
      that.warningViolationCount = response;
      that.showWarningPagination=true;
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  // totalViolationCount
  getDeviceExceptionsTotalCount(){
    const that = this;
    this.iotService.getCuiObjResponse().getDeviceExceptionsTotalCount({
    }).then(function(response) {
      console.log(response);
      that.totalViolationCount = response;
      that.showTotalPagination=true;
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  // alertViolationCount
  getDeviceExceptionsAlertCount(){
    const that = this;
    this.iotService.getCuiObjResponse().getDeviceExceptionsAlertCount({
    }).then(function(response) {
      console.log(response);
      that.alertViolationCount = response;
      that.showAlertPagination=true;
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  // viewDevice
  viewDevice(deviceId){
    this.router.navigateByUrl("/devices/"+deviceId);
  }

  // deviceViolationReport
  deviceViolationReport(violatedDeviceId,deviceViolationStatus) {
    this.deviceName = '';
    this.attributesList = [];
    const that = this;
    this.violatedDeviceIdArg = violatedDeviceId;
    this.isShow= true;
    //console.log(this.violatedDeviceIdArg);
    //console.log(that.violatedDeviceIdArg);
    this.iotService.getCuiObjResponse().getDeviceDetailedViolations({
      violatedDeviceId: violatedDeviceId
    }).then(function(response) {
      console.log("response -- deviceViolationReport");
      console.log(response);
      that.deviceName = response.deviceViolations[0].devices[0].violatedDeviceName;
      that.attributesList = response.deviceViolations[0].devices[0].attributes;
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });  
  }

  //closePopUp
  closePopUp(popup){
    this.violatedDeviceIdArg = '';
    this.isShow= false;
  }

  nextTaggedDevicePage() {
    let currentPage = this.currentPage+1;
    const that = this;
    that.currentPage = currentPage;
    that.getTagDetailedResponse(this.tagSelected,currentPage);
  }

  prevTaggedDevicePage() {
    let currentPage = this.currentPage-1;
    if (currentPage < 1) { currentPage= 1; }  
    const that = this;
    that.currentPage = currentPage;
    that.getTagDetailedResponse(this.tagSelected,currentPage);
  }

  onTaggedPageChange(page) {
    let currentPage = page;
    const that = this;
    that.currentPage = currentPage;
    that.getTagDetailedResponse(this.tagSelected,currentPage);
  }

  nextEcoDevicePage() {
    let currentEcoPage = this.currentEcoPage+1;
    const that = this;
    this.currentEcoPage = currentEcoPage;
    if(this.ecosystemSelected) {
      console.log("ecosystemSelected"+this.ecosystemSelected);
      this.getSelectedEcsosytemForCurrentPage(this.ecosystemSelected);
    } else {
      console.log("else not ecosystemSelected");
      this.getEcosystemsForExceptions();
    }
  }

  prevEcoDevicePage() {
    let currentEcoPage = this.currentEcoPage-1;
    if (currentEcoPage < 1) { currentEcoPage= 1; }  
    const that = this;
    this.currentEcoPage = currentEcoPage;
    if(this.ecosystemSelected) {
      console.log("ecosystemSelected"+this.ecosystemSelected);
      this.getSelectedEcsosytemForCurrentPage(this.ecosystemSelected);
    } else {
      console.log("else not ecosystemSelected");
      this.getEcosystemsForExceptions();
    }
  }

  getSelectedEcsosytemForCurrentPage(ecosystemSelected) {
    const that = this;
    this.iotService.getCuiObjResponse().getEcosystemsForExceptions({
      qs:[['criteriaName',ecosystemSelected],['pageSize',4],['page',this.currentEcoPage]]
    }).then(function(response) {
      console.log(response);
      that.ecosystemsForExceptions = response;
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  nextPage() {
    if(this.totalResponse == true){
      this.currentPageViolations++;
      this.getDeviceViolations();
    }
    else if(this.showCritical == true){
      this.currentPageCriticalViolations++;
      this.getCriticalDevices();
    }
    else if(this.showWarning == true){
      this.currentPageWarningViolations++;
      this.getWarningDevices();
    }
    else if(this.showAlert == true){
      this.currentPageAlertViolations++;
      this.getAlertDevices();
    }
  }

  prevPage() {
    if(this.totalResponse == true){
      this.currentPageViolations--;
      if (this.currentPageViolations < 1) { this.currentPageViolations= 1; }   
      this.getDeviceViolations();
    }
    else if(this.showCritical == true){
      this.currentPageCriticalViolations--;
      if (this.currentPageCriticalViolations < 1) { this.currentPageCriticalViolations= 1; }
      this.getCriticalDevices();
    }
    else if(this.showWarning == true){   
      this.currentPageWarningViolations--;
      if (this.currentPageWarningViolations < 1) { this.currentPageWarningViolations= 1; }
      this.getWarningDevices();
    }
    else if(this.showAlert == true){   
      this.currentPageAlertViolations--;
      if (this.currentPageAlertViolations < 1) { this.currentPageAlertViolations= 1; }
      this.getAlertDevices();
    }
  }

  selectPage(currentPageClass) {
    if(currentPageClass === 'ot-pagination') {
      this.pageClass='ot-pagination--is-active'; 
    } else {
      this.pageClass='ot-pagination'
    }
  }

  onViolationPageChange(page) {
    const that = this;
    if(that.totalResponse == true){
      that.currentPageViolations=page;

      that.iotService.getCuiObjResponse().getDeviceViolations({
        qs:[['pageSize',that.currentPageSizeViolations],['page',that.currentPageViolations]]
       }).then(function(response) {
         console.log("getDeviceViolations :: "+response);
         that.violations = response;
         that.statusCriticalClass = 'bg-red-exception';
         that.statusWarningClass = 'bg-orange-exception';
         that.statusAlertClass = 'bg-blue-exception';
         that.statusClass = 'bg-grey-exception-selected';
         that.totalResponse = true;
         that.showCritical = false;
         that.showWarning = false;
         that.showAlert = false;
         that.showPageLoading = false;
         that.showSubPageLoading = false;
       }).fail(function (err){
         if(err.responseJSON.apiStatusCode){
           console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
         } else{
           console.log("Something went wrong. Please check chrome console or check with dev team");
         }
       });
    }
  }

  onCriticalPageChange(page) {
    const that = this;
    if(that.showCritical == true){
      that.currentPageCriticalViolations=page;
      
      this.iotService.getCuiObjResponse().getDeviceCriticalViolations({
        qs:[['pageSize',this.currentPageSizeCriticalViolations],['page',this.currentPageCriticalViolations]]
      }).then(function(response) {
        console.log(response);
        that.statusWarningClass = 'bg-orange-exception';
        that.statusAlertClass = 'bg-blue-exception';
        that.statusClass = 'bg-grey-exception';
        that.statusCriticalClass = 'bg-red-exception-selected';
        that.criticalViolations = response;
        that.showCritical = true;
        that.totalResponse = false;
        that.showWarning = false;
        that.showAlert = false;
        that.showSubPageLoading=false;
      }).fail(function (err){
        if(err.responseJSON.apiStatusCode){
          console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
        } else{
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    }
  }

  onWarningPageChange(page) {
    const that = this;
    if(that.showWarning == true){
      that.currentPageWarningViolations=page;
      
      this.iotService.getCuiObjResponse().getDeviceWarningViolations({
        qs:[['pageSize',this.currentPageSizeWarningViolations],['page',this.currentPageWarningViolations]]
      }).then(function(response) {
        console.log(response);
        that.statusCriticalClass = 'bg-red-exception';
        that.statusAlertClass = 'bg-blue-exception';
        that.statusClass = 'bg-grey-exception';
        that.statusWarningClass = 'bg-orange-exception-selected';
        that.warningViolations=response;
        that.showWarning = true;
        that.totalResponse = false;
        that.showCritical = false;
        that.showAlert = false;
        that.showSubPageLoading=false;
      }).fail(function (err){
        if(err.responseJSON.apiStatusCode){
          console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
        } else{
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    }
  }

  onAlertPageChange(page) {
    const that = this;
    if(that.showAlert == true){
      that.currentPageAlertViolations=page;
      
      that.iotService.getCuiObjResponse().getDeviceAlertViolations({
        qs:[['pageSize',that.currentPageSizeAlertViolations],['page',that.currentPageAlertViolations]]
       }).then(function(response) {
         console.log(response);
         that.statusCriticalClass = 'bg-red-exception';
         that.statusWarningClass = 'bg-orange-exception';
         that.statusClass = 'bg-grey-exception';
         that.statusAlertClass = 'bg-blue-exception-selected';
         that.alertViolations = response;
         that.showAlert = true;
         that.totalResponse = false;
         that.showCritical = false;
         that.showWarning = false;
         that.showSubPageLoading=false;
       }).fail(function (err){
         if(err.responseJSON.apiStatusCode){
           console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
         } else{
           console.log("Something went wrong. Please check chrome console or check with dev team");
         }
       });
    }
  }

  onViolationPageSizeChange(value) {
    const that = this;
    if(that.totalResponse == true){
      that.currentPageSizeViolations = value; 

      that.iotService.getCuiObjResponse().getDeviceViolations({
        qs:[['pageSize',that.currentPageSizeViolations],['page',that.currentPageViolations]]
       }).then(function(response) {
         console.log("getDeviceViolations :: "+response);
         that.violations = response;
         that.statusCriticalClass = 'bg-red-exception';
         that.statusWarningClass = 'bg-orange-exception';
         that.statusAlertClass = 'bg-blue-exception';
         that.statusClass = 'bg-grey-exception-selected';
         that.totalResponse = true;
         that.showCritical = false;
         that.showWarning = false;
         that.showAlert = false;
         that.showPageLoading = false;
         that.showSubPageLoading = false;
       }).fail(function (err){
         if(err.responseJSON.apiStatusCode){
           console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
         } else{
           console.log("Something went wrong. Please check chrome console or check with dev team");
         }
       });
    }
  }

  onCriticalPageSizeChange(value) {
    const that = this;
    if(that.showCritical == true){
      that.currentPageSizeCriticalViolations=value;
      
      this.iotService.getCuiObjResponse().getDeviceCriticalViolations({
        qs:[['pageSize',this.currentPageSizeCriticalViolations],['page',this.currentPageCriticalViolations]]
      }).then(function(response) {
        console.log(response);
        that.statusWarningClass = 'bg-orange-exception';
        that.statusAlertClass = 'bg-blue-exception';
        that.statusClass = 'bg-grey-exception';
        that.statusCriticalClass = 'bg-red-exception-selected';
        that.criticalViolations = response;
        that.showCritical = true;
        that.totalResponse = false;
        that.showWarning = false;
        that.showAlert = false;
        that.showSubPageLoading=false;
      }).fail(function (err){
        if(err.responseJSON.apiStatusCode){
          console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
        } else{
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    }
  }

  onWarningPageSizeChange(value) {
    const that = this;
    if(that.showWarning == true){
      that.currentPageSizeWarningViolations=value;
      
      this.iotService.getCuiObjResponse().getDeviceWarningViolations({
        qs:[['pageSize',this.currentPageSizeWarningViolations],['page',this.currentPageWarningViolations]]
      }).then(function(response) {
        console.log(response);
        that.statusCriticalClass = 'bg-red-exception';
        that.statusAlertClass = 'bg-blue-exception';
        that.statusClass = 'bg-grey-exception';
        that.statusWarningClass = 'bg-orange-exception-selected';
        that.warningViolations=response;
        that.showWarning = true;
        that.totalResponse = false;
        that.showCritical = false;
        that.showAlert = false;
        that.showSubPageLoading=false;
      }).fail(function (err){
        if(err.responseJSON.apiStatusCode){
          console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
        } else{
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    }
  }

  onAlertPageSizeChange(value) {
    const that = this;
    if(that.showAlert == true){
      that.currentPageSizeAlertViolations=value;
      
      that.iotService.getCuiObjResponse().getDeviceAlertViolations({
        qs:[['pageSize',that.currentPageSizeAlertViolations],['page',that.currentPageAlertViolations]]
       }).then(function(response) {
         console.log(response);
         that.statusCriticalClass = 'bg-red-exception';
         that.statusWarningClass = 'bg-orange-exception';
         that.statusClass = 'bg-grey-exception';
         that.statusAlertClass = 'bg-blue-exception-selected';
         that.alertViolations = response;
         that.showAlert = true;
         that.totalResponse = false;
         that.showCritical = false;
         that.showWarning = false;
         that.showSubPageLoading=false;
       }).fail(function (err){
         if(err.responseJSON.apiStatusCode){
           console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
         } else{
           console.log("Something went wrong. Please check chrome console or check with dev team");
         }
       });
    }
  }

}
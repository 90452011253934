<mat-sidenav-container class="new-export" autosize>

    <mat-sidenav class="midNav-container" #sidenav mode="side" disableClose="true" opened="true">

        <!-- <hr> -->
        <!-- <button *ngIf="routeinfo.name[0].text"color="primary" (click)="save()">{{ 'save' | translate}}</button> -->
        <!-- <button [disabled]="routeinfo.name[0].text===''" mat-raised-button (click)="save()">{{ 'save' |
                    translate}}</button> -->
        <div class="alert success" *ngIf="importExportService.successMessage">
            <h5>
                <fa-icon [icon]="faSuccessIcon"></fa-icon>
                <span>{{importExportService.successMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=importExportService.resetSuccessMessage() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>
        <div class="alert critical" *ngIf="importExportService.errorMessage">
            <h5>
                <fa-icon [icon]="faCriticalIcon"></fa-icon>
                <span>{{importExportService.errorMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=importExportService.resetErrorMessage() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>

        <div class="description">
            <div *ngIf="importExportService.showExport && importExportState==2">
                <div *ngIf="viewValue=='Message ID'">
                    <b>{{'description' | translate}}{{':' | translate}}</b>
                    <p>{{'select.message.config.desc' | translate}}
                    </p>
                </div>
                <div *ngIf="viewValue=='Device Template ID'">
                    <b>{{'description' | translate}}{{':' | translate}}</b>
                    <p>{{'copy.deviceTemplateId.desc' | translate}}
                    </p>
                </div>
                <div *ngIf="viewValue=='Application ID'">
                    <b>{{'description' | translate}}{{':' | translate}}</b>
                    <p>{{'copy.applicationId.desc' | translate}}
                    </p>
                </div>
            </div>
            <div *ngIf="importExportState==1">
                <b>{{'description' | translate}}{{':' | translate}}</b>
                <p>{{'select.generatedconfigurationfile.desc' | translate}}
                </p>
            </div>
            <div *ngIf="importExportState==3">
                <b>{{'note' | translate}}{{':' | translate}}</b>
                <p>{{'jobId.logs.needed' | translate}}</p>

            </div>

        </div>


        <div class="row" style="margin-left:15px;">
            <div>
                <button class="btn" [ngClass]="importExportState==1 ? 'btn-primary': 'btn-secondary'"
                    (click)="importExportState=1; onButtonToggle('Import')" value="Import">{{'import' | translate}}</button>&nbsp;&nbsp;
                <button class="btn" [ngClass]="importExportState==2 ? 'btn-primary': 'btn-secondary'"
                    (click)="importExportState=2; onButtonToggle('Export')" value="Export">{{'export' | translate}}</button>&nbsp;&nbsp;
                <button class="btn" [ngClass]="importExportState==3 ? 'btn-primary': 'btn-secondary'"
                    (click)="importExportState=3; onButtonToggle('Download Logs')" value="Download Logs">{{'logs' | translate}}
                </button>&nbsp;&nbsp;
            </div>
        </div>

    </mat-sidenav>

    <mat-sidenav-content class="example-sidenav-content">
        <div class="export-data">

            <!-- <div class="tab-holder">
                <mat-tab-group (selectedTabChange)="tabClick($event)" [selectedIndex]="0">
                    <mat-tab label="Export"></mat-tab>
                    <mat-tab label="Import"></mat-tab>
                    <mat-tab label="Download Logs"></mat-tab>
                </mat-tab-group>
            </div> -->

            <div class="form-data" *ngIf="importExportService.showExport && importExportState==2">

                <label>{{'export.by' | translate}}</label>
                <ng-container>
                    <!-- <select name="Export By" [(ngModel)]="viewValue" (ngModelChange)="onChange($event)">
                        <option [value]="item.viewValue" *ngFor="let item of routetypess">
                            {{item.viewValue}}
                        </option>
                    </select> -->
                    <select (change)="resetMsg();" [(ngModel)]="exportInfo.exportType">
                        <option value="msgid">{{'message.id' | translate}}</option>
                        <option value="deviceTempId">{{'deviceTemplate.id' | translate}}</option>
                        <option value="applicationId">{{'application.id' | translate}}</option>
                    </select>
                </ng-container>
                <!-- {{viewValue}} -->

                <div *ngIf="exportInfo.exportType=='msgid'">
                    <label for="Message ID"> {{ 'message.id' | translate}}</label>
                    <input placeholder="required" id="Message ID" type="text" aria-label="Name"
                        name="exportInfo.resourceId" [(ngModel)]="exportInfo.resourceId"><br>

                    <label for="Batch Message ID"> {{ 'batch.message.id' | translate}}</label>
                    <input placeholder="required" id="Batch Message ID" type="text" aria-label="Name"
                        name="exportInfo.batchMessageId" [(ngModel)]="exportInfo.batchMessageId"><br>

                    <label for="Job Name"> {{ 'job.name' | translate}}</label>
                    <input placeholder="optional" id="Job Name" type="text" aria-label="Name" name="exportInfo.jobName"
                        [(ngModel)]="exportInfo.jobName"><br><br>

                    <button class="btn btn-primary" style="margin-left: 3px;" (click)="save(exportInfo)" [disabled]="exportInfo.resourceId=='' || importExportCreated">{{'execute.export' | translate}}</button>
                </div>

                <div *ngIf="exportInfo.exportType=='deviceTempId'">
                    <label for="Device Template ID"> {{ 'device.template.id' | translate}}</label>
                    <input placeholder="required" id="Device Template ID" type="text" aria-label="Name"
                        name="exportInfo.resourceId" [(ngModel)]="exportInfo.resourceId"><br>

                    <label for="Job Name"> {{ 'job.name' | translate}}</label>
                    <input placeholder="optional" id="Job Name" type="text" aria-label="Name" name="exportInfo.jobName"
                        [(ngModel)]="exportInfo.jobName"><br><br>

                    <button class="btn btn-primary" style="margin-left: 3px;" (click)="save(exportInfo)" [disabled]="exportInfo.resourceId=='' || importExportCreated">{{'execute.export' | translate}}</button>
                </div>

                <div *ngIf="exportInfo.exportType=='applicationId'">
                    <label for="Application ID"> {{ 'application.id' | translate}}</label>
                    <input placeholder="required" id="Application ID" type="text" aria-label="Name"
                        name="exportInfo.resourceId" [(ngModel)]="exportInfo.resourceId"><br>

                    <label for="Job Name"> {{ 'job.name' | translate}}</label>
                    <input placeholder="optional" id="Job Name" type="text" aria-label="Name" name="exportInfo.jobName"
                        [(ngModel)]="exportInfo.jobName"><br><br>

                    <button class="btn btn-primary" style="margin-left: 3px;" (click)="save(exportInfo)" [disabled]="exportInfo.resourceId=='' || importExportCreated">{{'execute.export' | translate}}</button>
                </div>

                <!-- <label for="Job Name"> {{ 'Job Name' | translate}}</label>
                <input placeholder="optional" id="Job Name" type="text" aria-label="Name"
                    [(ngModel)]="exportInfo.jobName"><br><br> -->
                <!-- <button class="btn btn-primary" (click)="save(exportInfo)" [disabled]="exportInfo.resourceId==''">{{ 'Execute
                    Export' |
                    translate}}</button> -->
                <!-- <p *ngIf="errorMsg" style="color: red;">{{errorMsg}}</p> -->
            </div>
            <div *ngIf="importExportState==1">
                <app-create-import></app-create-import>
            </div>
            <div *ngIf="importExportState==3">
                <app-download-logs></app-download-logs>
            </div>

        </div>
    </mat-sidenav-content>

</mat-sidenav-container>
import { Component, OnInit } from '@angular/core';
import { StreamService } from '../service/stream.service';
import { Router } from '@angular/router';
import {faCopy} from '@fortawesome/free-solid-svg-icons';
import { IotService } from '../service/iot.service';

@Component({
  selector: 'app-stream-credentials',
  templateUrl: './stream-credentials.component.html',
  styleUrls: ['./stream-credentials.component.scss']
})
export class StreamCredentialsComponent implements OnInit {

  constructor(public streamService:StreamService,private router: Router,public iotService:IotService) { 
    this.iotService = iotService;
  }


  copyToclipboardFlag = false;
  faCopyIcon=faCopy;
  showCopyToClipBoard = false;
  streamCredentials =[];

  ngOnInit(): void {
    this.streamCredentials = [{
      "username" : this.streamService.getStreamResponseUsername(),
      "password" :  this.streamService.getStreamResponsePassword(),
      "producer topic" : this.streamService.getStreamResponseProducerTopic(),
      "Client Id" : this.streamService.getStreamResponseClientId(),
      "Host" : this.streamService.getStreamResponseHost(),
      "Port" : this.streamService.getStreamResponsePort(),
      "Authentication Host" : this.streamService.getStreamResponseBasicAuthnHost(),
      "Authentication Port" : this.streamService.getStreamResponseBasicAuthnPort(),
      "Sending Post Message URI" : this.streamService.getStreamResponseSendingPostMessageUri(),
      "Consumer Topic" : this.streamService.getConsumerTopic()
    }
  ];

  if(this.streamService.getStreamResponseUsername()||this.streamService.getStreamResponsePassword()||
  this.streamService.getStreamResponseProducerTopic()||this.streamService.getStreamResponseClientId()||
  this.streamService.getStreamResponseHost()||this.streamService.getStreamResponsePort()||
  this.streamService.getStreamResponseBasicAuthnHost()||this.streamService.getStreamResponseBasicAuthnPort()||
  this.streamService.getStreamResponseSendingPostMessageUri()||this.streamService.getConsumerTopic()
  ){
    this.showCopyToClipBoard=true
  }
  else{
    this.showCopyToClipBoard=false;
    this.copyToclipboardFlag=true;
  }

  }
  copyToClipBoard(){
    this.copyToclipboardFlag = true
  }

  navigateToApplicationListing(){
    if(this.iotService.sideNavStream == true){
      this.router.navigateByUrl("/streams");
    }else{
      this.router.navigateByUrl("/applications");
    }

  }

  getStreamCredentials(){
    return JSON.stringify(this.streamCredentials);
  }

  ngOnDestroy(){
    this.streamService.setStreamResponseUsername(undefined);
    this.streamService.setStreamResponsePassword(undefined);
    this.streamService.setStreamResponseProducerTopic(undefined);
    this.streamService.setStreamResponseClientId(undefined);
    this.streamService.setStreamResponseHost(undefined);
    this.streamService.setStreamResponsePort(undefined);
    this.streamService.setStreamResponseBasicAuthnHost(undefined);
    this.streamService.setStreamResponseBasicAuthnPort(undefined);
    this.streamService.setStreamResponseSendingPostMessageUri(undefined)
    this.streamService.setConsumerTopic(undefined);

  }
}

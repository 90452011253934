<mat-sidenav-container class="new-export" autosize>

    <mat-sidenav class="midNav-container" #sidenav mode="side" disableClose="true" opened="true">

        <div class="alert success" *ngIf="importExportService.successMessage">
            <h5>
                <fa-icon [icon]="faSuccessIcon"></fa-icon>
                <span>{{importExportService.successMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=importExportService.resetSuccessMessage() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>
        <div class="alert critical" *ngIf="importExportService.errorMessage">
            <h5>
                <fa-icon [icon]="faCriticalIcon"></fa-icon>
                <span>{{importExportService.errorMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=importExportService.resetErrorMessage() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>
        <div class="alert warning" *ngIf="importExportService.warningMessage">
            <h5 style="font-size: 12px;">
                <fa-icon [icon]="faWarningIcon"></fa-icon>
                <span>{{importExportService.warningMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)="importExportService.resetWarningMessage()" [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>
        <div class="description">
            <div *ngIf="importExportService.orchestrationImport && importExportOrcheState==1">
                <b>{{'description' | translate}}{{':' | translate}}</b>
                <p>{{'select.configFile.exportOption' | translate}}
                </p>
            </div>
            <div *ngIf="importExportOrcheState==2">
                <b>{{'description' | translate}}{{':' | translate}}</b>
                <p>{{'orchestration.comparable.desc' | translate}} </p>
                <p><b>{{'compare.analyze.desc' | translate}} </b></p>

            </div>
            <div *ngIf="importExportOrcheState==3">
                <b>{{'description' | translate}}{{':' | translate}}</b>
                <p>{{'export.orchestration.resource.id' | translate}}
                </p>
            </div>
        </div>

        <div style="margin-left:15px;">
            <button class="btn" [ngClass]="importExportOrcheState==1 ? 'btn-primary': 'btn-secondary'"
                (click)="importExportOrcheState=1; onButtonToggle('Import')" value="Import">{{'import'| translate}}</button>&nbsp;&nbsp;
            <button class="btn" [ngClass]="importExportOrcheState==2 ? 'btn-primary': 'btn-secondary'"
                (click)="importExportOrcheState=2; onButtonToggle('Compare')" value="Import">{{'compare' | translate}}</button>&nbsp;&nbsp;
            <button class="btn" [ngClass]="importExportOrcheState==3 ? 'btn-primary': 'btn-secondary'"
                (click)="importExportOrcheState=3; onButtonToggle('Export')" value="Download Logs">{{'export'| translate}}
            </button>&nbsp;&nbsp;
        </div>

    </mat-sidenav>

    <mat-sidenav-content class="example-sidenav-content">
        <div class="export-data">

            <!-- <div class="tab-holder">
                <mat-tab-group (selectedTabChange)="tabClick($event)" [selectedIndex]="0">

                    <mat-tab label="Import"></mat-tab>
                    <mat-tab label="Compare"></mat-tab>
                    <mat-tab label="Export"></mat-tab>
                </mat-tab-group>
            </div> -->

            <div class="form-data" *ngIf="importExportService.orchestrationImport && importExportOrcheState==1">
                <label for="Package File"> {{ 'package.file' | translate}}</label>
                <input type="file" name="files" id="file" class="inputfile" (change)="createFile($event.target.files)">

                <label for="Job Name"> {{ 'job.name' | translate}}</label>
                <input placeholder="optional" id="Job Name" type="text" aria-label="Name"
                    [(ngModel)]="orchestrationimport.jobName">
                <br>
                <br>

                <button class="btn btn-primary" style="margin-left: 3px;" [disabled]="uploadedFile==undefined"
                    (click)="executeImport(uploadedFile)">{{ 'execute.import' |
                    translate}}</button>
            </div>

            <div class="form-data" *ngIf="importExportOrcheState==2">
                <label for="Package File"> {{ 'file.choose.comparision' | translate}}</label>
                <input type="file" name="files" id="file" class="inputfile" (change)="createFile($event.target.files)">

                <br>
                <br>

                <button class="btn btn-primary" style="margin-left: 3px;" [disabled]="uploadedFile==undefined"
                    (click)="executeImport(uploadedFile)">{{ 'compare.orchestration' |
                    translate}}</button>





                <div id="comparisonresults" *ngIf="showComparisonResult">
                    <div>
                        <br>
                        <br>
                        <h6 style="color:green;">
                            <fa-icon [icon]="faSuccessIcon"></fa-icon>
                            {{'comparable.orchestrationConfig' | translate}}{{':' | translate}} <b>{{foundOrchestrationName}}</b>
                        </h6>
                        <br>
                    </div>
                    <div>
                        <div *ngIf="preprocessors">
                            <div>
                                <h6><b>{{'preprocessor.comparison.results' | translate}}{{':' | translate}}</b></h6>
                                <br>
                            </div>
                            <div>
                                <table style="margin-top: -15px;">
                                    <thead>
                                        <tr>
                                            <th>{{'task.order' | translate}}</th>
                                            <th>{{'taskName.Package.file' | translate}}</th>
                                            <th>{{'taskName.current.setup' | translate}}</th>
                                            <th>{{'result' | translate}}</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let preprocessor of preprocessors">
                                            <td>{{preprocessor.sequenceOrder}}</td>
                                            <td>{{preprocessor.sourcePKGTask}}</td>
                                            <td>{{preprocessor.destinationEnvTask}}</td>
                                            <td *ngIf=(!preprocessor.mismatchExists) style="color:green">{{'perfect.match' | translate}}
                                            </td>
                                            <td *ngIf=(preprocessor.mismatchExists)>
                                                <p style="color:red;"><b>{{'mismatch.found.in' | translate}}{{':' | translate}}</b></p>
                                                <p>{{preprocessor.mismatchType}}</p>
                                                <p *ngIf="preprocessor.lineNumbers" style="color:red;"><b>{{'script.diff' | translate}}{{':' | translate}}</b></p>
                                                <p *ngIf="preprocessor.lineNumbers">{{preprocessor.lineNumbers}}</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div *ngIf="parsers">
                            <div>
                                <h6><b>{{'parser.comparison.results' | translate}}{{':' | translate}}</b></h6>
                                <br>
                            </div>
                            <div>
                                <table style="margin-top: -15px;">
                                    <thead>
                                        <tr>
                                            <th>{{'task.order' | translate}}</th>
                                            <th>{{'taskName.Package.file' | translate}}</th>
                                            <th>{{'taskName.current.setup' | translate}}</th>
                                            <th>{{'result' | translate}}</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let parser of parsers">
                                            <td>{{parser.sequenceOrder}}</td>
                                            <td>{{parser.sourcePKGTask}}</td>
                                            <td>{{parser.destinationEnvTask}}</td>
                                            <td *ngIf=(!parser.mismatchExists) style="color:green">{{'perfect.match' | translate}}</td>
                                            <td *ngIf=(parser.mismatchExists)>
                                                <p style="color:red;"><b>{{'mismatch.found.in' | translate}}{{':' | translate}}</b></p>
                                                <p>{{parser.mismatchType}}</p>
                                                <p *ngIf="parser.lineNumbers" style="color:red;"><b>{{'script.diff' | translate}}{{':' | translate}}</b></p>
                                                <p *ngIf="parser.lineNumbers">{{parser.lineNumbers}}</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div *ngIf="packagers">
                            <div>
                                <h6><b>{{'parser.comparison.results' | translate}}{{':' | translate}}</b></h6>
                                <br>
                            </div>
                            <div>
                                <table style="margin-top: -15px;">
                                    <thead>
                                        <tr>
                                            <th>{{'task.order' | translate}}</th>
                                            <th>{{'taskName.Package.file' | translate}}</th>
                                            <th>{{'taskName.current.setup' | translate}}</th>
                                            <th>{{'result' | translate}}</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let packager of packagers">
                                            <td>{{packager.sequenceOrder}}</td>
                                            <td>{{packager.sourcePKGTask}}</td>
                                            <td>{{packager.destinationEnvTask}}</td>
                                            <td *ngIf=(!packager.mismatchExists) style="color:green">{{'perfect.match' | translate}}</td>
                                            <td *ngIf=(packager.mismatchExists)>
                                                <p style="color:red;"><b>{{'mismatch.found.in' | translate}}{{':' | translate}}</b></p>
                                                <p>{{packager.mismatchType}}</p>
                                                <p *ngIf="packager.lineNumbers" style="color:red;"><b>{{'script.diff' | translate}}{{':' | translate}}</b>
                                                </p>
                                                <p *ngIf="packager.lineNumbers">{{packager.lineNumbers}}</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div *ngIf="mappers">
                            <div>
                                <h6><b>{{'mapper.comparison.results' | translate}}{{':' | translate}}</b></h6>
                                <br>
                            </div>
                            <div>
                                <table style="margin-top: -15px;">
                                    <thead>
                                        <tr>
                                            <th>{{'task.order' | translate}}</th>
                                            <th>{{'taskName.Package.file' | translate}}</th>
                                            <th>{{'taskName.current.setup' | translate}}</th>
                                            <th>{{'result' | translate}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let mapper of mappers">
                                            <td>{{mapper.sequenceOrder}}</td>
                                            <td>{{mapper.sourcePKGTask}}</td>
                                            <td>{{mapper.destinationEnvTask}}</td>
                                            <td *ngIf=(!mapper.mismatchExists) style="color:green">{{'perfect.match' | translate}}</td>
                                            <td *ngIf=(mapper.mismatchExists)>
                                                <p style="color:red;"><b>{{'mismatch.found.in' | translate}}{{':' | translate}}</b></p>
                                                <p>{{mapper.mismatchType}}</p>
                                                <p *ngIf="mapper.lineNumbers" style="color:red;"><b>{{'script.diff' | translate}}{{':' | translate}}</b></p>
                                                <p *ngIf="mapper.lineNumbers">{{mapper.lineNumbers}}</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div *ngIf="postprocessors">
                            <div>
                                <h6><b>{{'postProcessor.comparison.results' | translate}}{{':' | translate}}</b></h6>
                                <br>
                            </div>
                            <div>
                                <table style="margin-top: -15px;">
                                    <thead>
                                        <tr>
                                            <th>{{'task.order' | translate}}</th>
                                            <th>{{'taskName.Package.file' | translate}}</th>
                                            <th>{{'taskName.current.setup' | translate}}</th>
                                            <th>{{'result' | translate}}</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let postprocessor of postprocessors">
                                            <td>{{postprocessor.sequenceOrder}}</td>
                                            <td>{{postprocessor.sourcePKGTask}}</td>
                                            <td>{{postprocessor.destinationEnvTask}}</td>
                                            <td *ngIf=(!postprocessor.mismatchExists) style="color:green">{{'perfect.match' | translate}}
                                            </td>
                                            <td *ngIf=(postprocessor.mismatchExists)>
                                                <p style="color:red;"><b>{{'mismatch.found.in' | translate}}{{':' | translate}}</b></p>
                                                <p>{{postprocessor.mismatchType}}</p>
                                                <p *ngIf="postprocessor.lineNumbers" style="color:red;"><b>{{'script.diff' | translate}}{{':' | translate}}</b></p>
                                                <p *ngIf="postprocessor.lineNumbers">{{postprocessor.lineNumbers}}</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div>

                        <div class="pull-right" style="margin-right: 15px;">
                            <button class="btn btn-secondary" (click)="executeOverwrite();" [disabled]="!showOvrWrite"
                                style="margin-bottom:10px">{{'overwrite' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-data" *ngIf="importExportOrcheState==3">
                <label for="Job Name"> {{ 'orchestration.id' | translate}}</label>
                <input placeholder="required" id="Job Name" [(ngModel)]="orchestrationexport.resourceId" type="text"
                    aria-label="Name">

                <label for="Job Name"> {{ 'export.job.name' | translate}}</label>
                <input placeholder="optional" id="Job Name" type="text" [(ngModel)]="orchestrationexport.jobName"
                    aria-label="Name">

                <br>
                <br>

                <button class="btn btn-primary" style="margin-left: 3px;" [disabled]="orchestrationexport.resourceId==''"
                    (click)="executeOrchExport()">{{ 'execute.export' |
                    translate}}</button>
            </div>




        </div>
    </mat-sidenav-content>

</mat-sidenav-container>
<header class="table-header">
    <h2>{{ 'select_templates' | translate }}</h2>
</header>
<div class="control-bar">
    <input placeholder="search" type="text" (keyup)="onTemplateSearch($event)">
    <button mat-raised-button [routerLink]="['/createDigitalTwinTemplates']" (click)="createNewTemplate()" aria-label="Create new template">New</button>
</div>
<div class="table-container">
    <ngx-skeleton-loader *ngIf="!dataSource" count="5" [theme]="{'border-radius': '0px',
          height: '48px',
          width: '100%',
          margin: '1px',
          position: 'relative',
          top: '20px'
        }"></ngx-skeleton-loader>
    <table mat-table [dataSource]="dataSource">

        <!-- Button Column -->
            <ng-container matColumnDef=" ">
                <th mat-header-cell *matHeaderCellDef >  </th>
            <td mat-cell *matCellDef="let deviceTemplate;let i = index" class="checkbox-cell selectCol">
    
    
                <ng-container *ngIf="deviceTemplate.isActive">
    
                    <div class="btn-col">
                        <ng-container *ngIf="selection.isSelected(deviceTemplate)">
                            <button class="btn btn-micro btn-secondary" [ngClass]="toSelect" (click)="removeSelection();playRecord(deviceTemplate,false)"><fa-icon [icon]="faCheckIcon"></fa-icon></button>&nbsp;
                        </ng-container>
                        <ng-container *ngIf="!selection.isSelected(deviceTemplate)">
                            <button class="btn btn-micro btn-secondary" [ngClass]="toSelect" (click)="OnSelection(deviceTemplate.id);playRecord(deviceTemplate,true)">Select</button>&nbsp;
                        </ng-container>
                    </div>
                    
                </ng-container>
                <ng-container *ngIf="!deviceTemplate.isActive">
                    <div class="btn-col">
                        <ng-container *ngIf="deviceTemplate.isPlaying">
                            <button class="btn btn-micro btn-secondary" [disabled]="deviceTemplate.isActive" (click)="removeSelection();playRecord(deviceTemplate,false)"><fa-icon [icon]="faCheckIcon"></fa-icon></button>&nbsp;
                        </ng-container>
                        <ng-container *ngIf="!deviceTemplate.isPlaying">
                            <button class="btn btn-micro btn-secondary" [disabled]="!deviceTemplate.isActive" (click)="OnSelection(deviceTemplate.id);playRecord(deviceTemplate,true)">Select</button>&nbsp;
                        </ng-container>
                    </div>
                    
                </ng-container>
    
            </td>
    
        </ng-container>
    
        <!-- Name Column -->
        <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef class="nameCol"> {{'name' | translate}} </th>
            
            <td mat-cell *matCellDef="let deviceTemplate" class="name-cell">
                <div class="cell-col">
                    {{deviceTemplate.name[0].text}}
                </div>
            </td>
            
        </ng-container>
    
    
        <!-- Description Column -->
        <ng-container matColumnDef="Description">
            <th mat-header-cell *matHeaderCellDef class="descCol"> {{'description' | translate}} </th>
            <ng-container *matCellDef="let deviceTemplate" style="font-size: small;">
                <td class="name-cell" mat-cell *ngIf="deviceTemplate.description">{{deviceTemplate.description[0].text}}
                </td>
                <td class="name-cell" mat-cell *ngIf="!deviceTemplate.description">
                -
                </td>
            </ng-container>
        </ng-container>
    
        <!-- Tags Column -->
        <ng-container matColumnDef="Tags">
            <th mat-header-cell *matHeaderCellDef class="tagsCol"> {{ 'tags' | translate}} </th>
            <ng-container *matCellDef="let deviceTemplate" style="font-size: small;">
                <td mat-cell *ngIf="deviceTemplate.description" class="name-cell">
                    <div class="cell-col">{{deviceTemplate.tags}}</div>
                </td>
                <td mat-cell *ngIf="!deviceTemplate.description" class="name-cell">
                    <div class="cell-col">-</div>
                </td>
            </ng-container>
        </ng-container>
    
    
    
        <!-- Enabled Column -->
        <ng-container matColumnDef="Enabled">
            <th mat-header-cell *matHeaderCellDef class="enabledCol"> {{ 'enabled' | translate}} </th>
            <ng-container *matCellDef="let deviceTemplate">
    
                <td mat-cell *ngIf="deviceTemplate.isActive" class="tag-cell">
                    <div class="cell-col">
                        <button class="toggle-on toggle-switch" (click)="disableTemplate(deviceTemplate,deviceTemplate.id)" title="Disable {{deviceTemplate.name[0].text}}">
                            <fa-icon [icon]="faCheckIcon" class="fa-2x"></fa-icon>
                            <div class="toggle-on-btn"></div>
                        </button>
                    </div>
                </td>
                <td mat-cell *ngIf="!deviceTemplate.isActive" class="tag-cell">
                    <div class="cell-col">
                        <button class="toggle-off toggle-switch" (click)="enableTemplate(deviceTemplate.id)" title="Enable {{deviceTemplate.name[0].text}}">
                            <div class="toggle-off-btn"></div>
                        </button>
                    </div>
                </td>
            </ng-container>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let deviceTemplate; columns: displayedColumns;"></tr>
    
        <!-- !row.excluded will prevent those rows from being clicked -->
    
    </table>
</div>

<!-- OT Pagination component -->
<div class="table-container">
    <app-pagination *ngIf="showPagination" [count]="templateCount" [pageSize]="pageSize" [currentPage]="currentPage" (currentPageChange)="onPageChange($event)"></app-pagination>
</div>
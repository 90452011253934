import { Injectable } from '@angular/core';
import { SelectionModel} from '@angular/cdk/collections';
import { IotService } from '../service/iot.service'; 
import { ListRulesService } from './ListRules.service';

@Injectable({ 
	  providedIn: 'root' 
})

export class RulesService extends ListRulesService {
	 constructor(public iotService:IotService) {
		 super(iotService);
	 }
	 selectedGatewayDevice=false;
	 selectedEvent=false;
	 reload=false;
	 loading=false;
	 selectedGateway=false;
	 selectGatewaysCount=0
	 devices = [];
	 device;
  	 deviceTemplates = [];
	 events;
	 ecosystems =[];
	 ecosystemTemplates =[];
	 geofences =[];
	 gateways;
	 createResource=true;
	 warningMessage: string;
	 errorMessage: string;
	 successMessage:string;
	 showDevices=false;
	 showDeviceTemplates=false;
	 showEcosystems=false;
	 showEcosystemTemplates=false;
	 showGeofences=false;
	 gatewayDevices="";
	 showGatewayDevices=false;
	 showGateway=false;
	 rulePolicyNameInput:string;
	 triggerType:string='Select Trigger';
	 rulePolicyNameSelect=false;
	 
	 
	 resetMessage(){
		this.warningMessage='';
		this.errorMessage='';
		this.successMessage='';
	 }

	 fnOnSelectionSupport(results){
		 const that = this; 
	 } 

	 fnRemoveSelectionSupport(results){ 
		 const that = this; 
	 } 
	 getEvents(): any {
		return this.events;
	  }
	
	  setEvents(events) {
		this.events = events;
	  }

	  getDevices(): any {
		return this.devices;
	  }
	
	  setDevices(devices: any) {
		this.devices = devices;
	  }

	  getGatewayDevice(): any {
		return this.device;
	  }
	
	  setGatewayDevice(device: any) {
		this.device = device;
	  }

	  getDeviceTemplates(): any {
		return this.deviceTemplates;
	  }
	
	  setDeviceTemplates(deviceTemplates: any) {
		this.deviceTemplates = deviceTemplates;
	  }

	  getEcosystems(): any {
		return this.ecosystems;
	  }
	
	  setEcosystems(ecosystems: any) {
		this.ecosystems = ecosystems;
	  }

	  getEcosystemTemplates(): any {
		return this.ecosystemTemplates;
	  }
	
	  setEcosystemtemplates(ecosystemTemplates: any) {
		this.ecosystemTemplates = ecosystemTemplates;
	  }

	  getGeofences(): any {
		return this.geofences;
	  }
	
	  setGeofences(geofences: any) {
		this.geofences = geofences;
	  }

	  getGateways(): any {
		return this.gateways;
	  }
	
	  setGateways(gateways: any) {
		this.gateways = gateways;
	  }

} 

<mat-sidenav-container class="mid-sidenav" autosize  style="height: 450vh;">


    <mat-sidenav class="midNav-container" #sidenav mode="side" disableClose="true" opened="true" scrollable="true"
        *ngIf="!iotService.viewRulePolicy">

        <div class="alert critical" *ngIf="rulesService.errorMessage">
            <h5>
                <fa-icon [icon]="faCriticalIcon"></fa-icon>
                <span>{{rulesService.errorMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=rulesService.resetMessage() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>

        </div>
        <div class="alert success" *ngIf="rulesService.successMessage">
            <h5>
                <fa-icon [icon]="faSuccessIcon"></fa-icon>
                <span>{{rulesService.successMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=rulesService.resetMessage() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>
        <div class="alert warning" *ngIf="rulesService.warningMessage">
            <h5 style="font-size: 12px;">
                <fa-icon [icon]="faWarningIcon"></fa-icon>
                <span>{{rulesService.warningMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)="rulesService.resetMessage()" [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>
        <h1>{{'rules.createRule' | translate}}</h1>
        <mat-nav-list class="without-template user-selections">
            <h2>{{'trigger.types' | translate}}</h2>
            <p *ngIf="triggerType=='Select Trigger'">
                {{'select.triggerType' | translate}}
            </p>
            <div *ngIf="triggerType!='Select Trigger'">
                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{triggerType}}
                    </li>
                </ul>
            </div>

            <h2>{{'event.template' | translate}}</h2>
            <p *ngIf="!rulesService.selectedEvent">
                {{'select.event.table' | translate}}
            </p>
            <div *ngIf="rulesService.selectedEvent">
                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{rulesService.getEvents().name}}
                    </li>
                </ul>
            </div>
            <div *ngIf="triggerType.includes('Device ')">
                <h2>{{'devices' | translate}}</h2>
                <p *ngIf="rulesService.devices.length == 0">
                    {{'select.device.table' | translate}}
                </p>
                <ul>
                    <li *ngFor="let device of rulesService.devices">
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{ device.name[0].text }}
                    </li>
                </ul>
            </div>
            <div *ngIf="!showGateway && triggerType.includes('Device ')">
                <h2>{{'templates.header' | translate}}</h2>
                <p *ngIf="rulesService.deviceTemplates.length == 0">
                    {{'select.deviceTemplate.table' | translate}}
                </p>
                <ul>
                    <li *ngFor="let deviceTemplate of rulesService.deviceTemplates">
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{ deviceTemplate.name[0].text }}
                    </li>
                </ul>
            </div>
            <div *ngIf="triggerType=='Device Geofence'">
                <h2>{{'geofences' | translate}}</h2>
                <p *ngIf="rulesService.geofences.length == 0">
                    {{'select.geofence.table' | translate}}</p>
                <ul>
                    <li *ngFor="let geofence of rulesService.geofences">
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{ geofence.name[0].text }}
                    </li>
                </ul>
            </div>
            <div *ngIf="showGateway">
                <h2>{{'gateways' | translate}}</h2>
                <p *ngIf="rulesService.gateways == undefined">
                    {{'select.gateway.table' | translate}}</p>
                <div *ngIf="rulesService.gateways != undefined">
                    <ul>
                        <li>
                            <fa-icon [icon]="faCheckIcon"></fa-icon> {{rulesService.gateways.name[0].text}}
                        </li>
                    </ul>
                </div>
            </div>
            <div *ngIf="(triggerType=='Device Event' || triggerType.includes('Ecosystem')) && !showGateway">
                <h2>{{'subscribers' | translate}}</h2>
                <h2>{{'ecosystems.header' | translate}}</h2>
                <p *ngIf="rulesService.ecosystems.length == 0">
                    {{'select.ecosystem.table' | translate}}</p>
                <div *ngIf="rulesService.ecosystems.length>0">
                    <ul>
                        <li *ngFor="let ecosystem of rulesService.ecosystems">
                            <fa-icon [icon]="faCheckIcon"></fa-icon> {{ ecosystem.name[0].text }}
                        </li>
                    </ul>
                </div>
                <h2>{{'ecosystems.templates'| translate}}</h2>
                <p *ngIf="rulesService.ecosystemTemplates.length == 0">
                    {{'select.ecosystemTemplate.table' | translate}}</p>
                <div *ngIf="rulesService.ecosystemTemplates.length>0">
                    <ul>
                        <li *ngFor="let ecosystemTemplate of rulesService.ecosystemTemplates">
                            <fa-icon [icon]="faCheckIcon"></fa-icon> {{ ecosystemTemplate.name[0].text }}
                        </li>
                    </ul>
                </div>
            </div>
            <div style="margin-left: -4px;">
                <button class="btn btn-primary"
                    [disabled]="!rulePolicyNameInput || triggerType=='Select Trigger' || rulePolicyCreated"
                    (click)="save()">{{ 'save' | translate}}</button>
                <!--button class="btn btn-secondary" (click)="back()" type='submit'>{{ 'go_back' |
                    translate}}</button-->&nbsp;
            </div>
        </mat-nav-list>




    </mat-sidenav>

    <div class="document-data">
        <div class="row">
            <div class="form-data col-md-5">
                <label for="rulePolicyName">{{ 'name' | translate}}</label>
                <input placeholder="required" id="rulePolicyName" type="text" aria-label="Name" type="text" value=""
                    (keyup)="rulePolicyName($event)">

                <label for="rulePolicyDescription">{{ 'description' | translate}}</label>
                <textarea placeholder="optional" id="rulePolicyDescription" aria-label="Description" value=""
                    (keyup)="rulePolicyDescription($event)"></textarea>
                
                <label for="rulePolicyTags">{{ 'tags' | translate}}</label>
                <mat-chip-list #tagList aria-label="Tags">
                    <mat-chip class="ot-chip" *ngFor="let tag of tags" [selectable]="true" [removable]="true"
                        (removed)="remove(tag)">
                        {{ tag }}
                        <button class="ot-chip__close" matChipRemove></button>
                    </mat-chip>
                    <input [ngClass]="tagClass" placeholder="add a tag" #tagInput [matChipInputFor]="tagList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                        (matChipInputTokenEnd)="add($event)" />
                </mat-chip-list>

                
                <br>
                <div *ngIf="iotService.viewRulePolicy">
                    <div>
                        <button class="btn btn-secondary" (click)="back()" type='submit'>{{ 'go.back' |
                            translate}}</button>&nbsp;
                        <button class="btn btn-primary"
                            [disabled]="!rulePolicyNameInput || triggerType=='Select Trigger'" (click)="save()">{{
                            'save' | translate}}</button>&nbsp;
                        
                    </div>
                </div>
            </div>
            <div class="form-data col-md-5">
                <label for="rule-log-level">{{ 'rulePolicies.logLevel' | translate }}</label>
                <ng-container class="dropdown">
                    <select name="rule-log-level" id="logLevel" [(ngModel)]="viewLogLevel"
                        (change)="ruleLogLevelInput($event)">
                        <option [value]="item.viewLogLevel" *ngFor="let item of logLevels">
                            {{item.viewLogLevel}}
                        </option>
                    </select><br>
                </ng-container>
                <label for="rule-timeout"> {{ 'rulePolicies.timeOut' | translate}}</label>

                <input class="ot--is-width-px-100" id="ruleTimeout" type="number" aria-label="Rule Timeout"
                    (change)="ruleTimeoutInput($event)" [(ngModel)]="rulePolicy.script.timeout" placeholder="required">
                <br>
                <p *ngIf="errorInRulePolicyTimeout" style="color: red;">{{errorInRulePolicyTimeout}}</p>
           
                <label for="ruleViolationText">{{ 'rules.violationText' | translate}}</label>
                <textarea placeholder="optional" id="ruleViolationText" aria-label="Description"
                    [(ngModel)]="rulePolicy.customMessage"></textarea>

                    <label for="rule-trigger-type">{{ 'rulePolicies.triggerType' | translate }}</label>
                <select name="rule-trigger-type" [(ngModel)]="triggerType" (change)="ruleTriggerTypeInput($event)">
                    <option [value]="item.triggerType" *ngFor="let item of triggerTypes">
                        {{item.triggerType}}
                    </option>
                </select>
                <br>
                <mat-checkbox *ngIf="triggerType=='Device Event'" #c (click)="selectGateway(!c.checked)">{{
                    'rules.runRuleOnGateway' | translate}}</mat-checkbox>
            </div>
        </div>

        <div *ngIf="triggerType=='Device Lifecycle'">
            <label for="rule-lifeCycle">{{ 'rulePolicies.lifecycle' | translate }}</label> 
            <div class="row row-margin-zero">
                <div>
                    <button class="btn" [ngClass]="lifecyclestate==1 ? 'btn-primary': 'btn-secondary'"
                        (click)="lifecyclestate=1; onButtonToggle('Created')"
                        value="Created">{{'created' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="lifecyclestate==2 ? 'btn-primary': 'btn-secondary'"
                        (click)="lifecyclestate=2; onButtonToggle('Registered')"
                        value="Registered">{{'registered' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="lifecyclestate==3 ? 'btn-primary': 'btn-secondary'"
                        (click)="lifecyclestate=3; onButtonToggle('Suspended')"
                        value="Suspended">{{'suspended.order' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="lifecyclestate==4 ? 'btn-primary': 'btn-secondary'"
                        (click)="lifecyclestate=4; onButtonToggle('Unsuspended')"
                        value="Unsuspended">{{'unsuspended' | translate}}</button>&nbsp;&nbsp;
                </div>
            </div>
            <br>
            <div class="row row-margin-zero">
                <div>
                    <button class="btn" [ngClass]="lifecyclestate==5 ? 'btn-primary': 'btn-secondary'"
                        (click)="lifecyclestate=5; onButtonToggle('Locked')" value="Locked">{{'locked.order' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="lifecyclestate==6 ? 'btn-primary': 'btn-secondary'"
                        (click)="lifecyclestate=6; onButtonToggle('Unlocked')"
                        value="Unlocked">{{'unlocked' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="lifecyclestate==7 ? 'btn-primary': 'btn-secondary'"
                        (click)="lifecyclestate=7; onButtonToggle('Deleted')"
                        value="Deleted">{{'deleted.order' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="lifecyclestate==8 ? 'btn-primary': 'btn-secondary'"
                        (click)="lifecyclestate=8; onButtonToggle('Reset')" value="Reset">{{'reset' | translate}}</button>&nbsp;&nbsp;
                </div>
            </div>
            <br>
        </div>

        <div *ngIf="triggerType=='Device Presence'">
            <label for="rule-lifeCycle">{{ 'rulePolicies.operationalState' | translate }}</label>
            <div class="row row-margin-zero" >
                <div>
                    <button class="btn" [ngClass]="presencestate==1 ? 'btn-primary': 'btn-secondary'"
                        (click)="presencestate=1; onButtonToggle('Initially Connected')"
                        value="Initially Connected">{{'initially.connected' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="presencestate==2 ? 'btn-primary': 'btn-secondary'"
                        (click)="presencestate=2; onButtonToggle('Connected')"
                        value="Connected">{{'connected' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="presencestate==3 ? 'btn-primary': 'btn-secondary'"
                        (click)="presencestate=3; onButtonToggle('Disconnected')"
                        value="Disconnected">{{'disconnected' | translate}}</button>&nbsp;&nbsp;
                </div>
            </div>
            <br>
        </div>

        <div *ngIf="triggerType=='Device Geofence'">
            <label for="rule-lifeCycle">{{ 'rulePolicies.geofenceState' | translate }}</label>
            <div class="row row-margin-zero" >
                <div>
                    <button class="btn" [ngClass]="geofencestate==1 ? 'btn-primary': 'btn-secondary'"
                        (click)="geofencestate=1; onButtonToggle('Entering')"
                        value="Entering">{{'entering' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="geofencestate==2 ? 'btn-primary': 'btn-secondary'"
                        (click)="geofencestate=2; onButtonToggle('Exiting')"
                        value="Exiting">{{'exiting' | translate}}</button>&nbsp;&nbsp;
                </div>
            </div>
            <br>
        </div>

        <div *ngIf="triggerType=='Device Health'">
            <label for="rule-lifeCycle">{{ 'rulePolicies.healthState' | translate }}</label>
            <div class="row row-margin-zero" >
                <div>
                    <button class="btn" [ngClass]="healthstate==1 ? 'btn-primary': 'btn-secondary'"
                        (click)="healthstate=1; onButtonToggle('OK')" value="OK">{{'ok' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="healthstate==2 ? 'btn-primary': 'btn-secondary'"
                        (click)="healthstate=2; onButtonToggle('WARNING')" value="WARNING">{{'warning' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="healthstate==3 ? 'btn-primary': 'btn-secondary'"
                        (click)="healthstate=3; onButtonToggle('CRITICAL')"
                        value="CRITICAL">{{'critical' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="healthstate==4 ? 'btn-primary': 'btn-secondary'"
                        (click)="healthstate=4; onButtonToggle('FAILURE')" value="FAILURE">{{'failure' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="healthstate==5 ? 'btn-primary': 'btn-secondary'"
                        (click)="healthstate=5; onButtonToggle('SUSPENDED')"
                        value="SUSPENDED">{{'suspended' | translate}}</button>&nbsp;&nbsp;
                </div>
            </div>
            <br>
        </div>

        <div *ngIf="triggerType=='Device Tracking'">
            <label for="rule-lifeCycle">{{ 'rulePolicies.deviceTrackingState' | translate }}</label>
            <div class="row row-margin-zero" >
                <div>
                    <button class="btn" [ngClass]="deviceTrackingState==1 ? 'btn-primary': 'btn-secondary'"
                        (click)="deviceTrackingState=1; onButtonToggle('IDLE')" value="IDLE">{{'idle' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="deviceTrackingState==2 ? 'btn-primary': 'btn-secondary'"
                        (click)="deviceTrackingState=2; onButtonToggle('NONIDLE')"
                        value="NONIDLE">{{'nonidle' | translate}}</button>&nbsp;&nbsp;
                </div>
            </div>
            <br>
        </div>

        <div *ngIf="triggerType=='Ecosystem Devices'">
            <label for="rule-lifeCycle">{{ 'rulePolicies.ecosystemDevices' | translate }}</label>
            <div class="row row-margin-zero">
                <div>
                    <button class="btn" [ngClass]="ecosystemdevicestate==1 ? 'btn-primary': 'btn-secondary'"
                        (click)="ecosystemdevicestate=1; onButtonToggle('Created')"
                        value="Created">{{'created' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="ecosystemdevicestate==2 ? 'btn-primary': 'btn-secondary'"
                        (click)="ecosystemdevicestate=2; onButtonToggle('Registration')"
                        value="Registration">{{'registration' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="ecosystemdevicestate==3 ? 'btn-primary': 'btn-secondary'"
                        (click)="ecosystemdevicestate=3; onButtonToggle('Updated')"
                        value="Updated">{{'updated' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="ecosystemdevicestate==4 ? 'btn-primary': 'btn-secondary'"
                        (click)="ecosystemdevicestate=4; onButtonToggle('Deleted')"
                        value="Deleted">{{'deleted.order' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="ecosystemdevicestate==5 ? 'btn-primary': 'btn-secondary'"
                        (click)="ecosystemdevicestate=5; onButtonToggle('Activated')"
                        value="Activated">{{'activated' | translate}}</button>
                </div>
            </div>
            <br>
            <div class="row row-margin-zero">
                <div>
                    <button class="btn" [ngClass]="ecosystemdevicestate==6 ? 'btn-primary': 'btn-secondary'"
                        (click)="ecosystemdevicestate=6; onButtonToggle('Deactivated')"
                        value="Deactivated">{{'deactivated' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="ecosystemdevicestate==7 ? 'btn-primary': 'btn-secondary'"
                        (click)="ecosystemdevicestate=7; onButtonToggle('Reset')"
                        value="Reset">{{'reset' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="ecosystemdevicestate==8 ? 'btn-primary': 'btn-secondary'"
                        (click)="ecosystemdevicestate=8; onButtonToggle('Lock')" value="Lock">{{'rulePolicy.lock' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="ecosystemdevicestate==9 ? 'btn-primary': 'btn-secondary'"
                        (click)="ecosystemdevicestate=9; onButtonToggle('Unlock')"
                        value="Unlock">{{'rulePolicy.unlock' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="ecosystemdevicestate==10 ? 'btn-primary': 'btn-secondary'"
                        (click)="ecosystemdevicestate=10; onButtonToggle('Suspended')"
                        value="Suspended">{{'suspended.order' | translate}}</button>
                </div>
            </div>
            <br>
            <div class="row row-margin-zero">
                <div>
                    <button class="btn" [ngClass]="ecosystemdevicestate==11 ? 'btn-primary': 'btn-secondary'"
                        (click)="ecosystemdevicestate=11; onButtonToggle('Unsuspended')"
                        value="Unsuspended">{{'unsuspended' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="ecosystemdevicestate==12 ? 'btn-primary': 'btn-secondary'"
                        (click)="ecosystemdevicestate=12; onButtonToggle('Online')"
                        value="Online">{{'online' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="ecosystemdevicestate==13 ? 'btn-primary': 'btn-secondary'"
                        (click)="ecosystemdevicestate=13; onButtonToggle('Offline')"
                        value="Offline">{{'offline' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="ecosystemdevicestate==14 ? 'btn-primary': 'btn-secondary'"
                        (click)="ecosystemdevicestate=14; onButtonToggle('Migrate')" value="Migrate">{{'migrate' | translate}}</button>
                </div>
            </div>
            <br>
        </div>

        <div *ngIf="triggerType=='Ecosystem Persons'">
            <label for="rule-lifeCycle">{{ 'rulePolicies.ecosystemPersons' | translate }}</label>

            <div class="row row-margin-zero">
                <div>
                    <button class="btn" [ngClass]="ecosystempersonstate==1 ? 'btn-primary': 'btn-secondary'"
                        (click)="ecosystempersonstate=1; onButtonToggle('Activated')"
                        value="Activated">{{'activated' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="ecosystempersonstate==2 ? 'btn-primary': 'btn-secondary'"
                        (click)="ecosystempersonstate=2; onButtonToggle('Updated')"
                        value="Updated">{{'updated' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="ecosystempersonstate==3 ? 'btn-primary': 'btn-secondary'"
                        (click)="ecosystempersonstate=3; onButtonToggle('Suspended')"
                        value="Suspended">{{'suspended.order' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="ecosystempersonstate==4 ? 'btn-primary': 'btn-secondary'"
                        (click)="ecosystempersonstate=4; onButtonToggle('Unsuspended')"
                        value="Unsuspended">{{'unsuspended' | translate}}</button>
                </div>
            </div>
            <br>
        </div>

        <div *ngIf="triggerType=='Ecosystem Applications'">
            <label for="rule-lifeCycle">{{ 'rulePolicies.ecosystemApplications' | translate }}</label>
            <div class="row row-margin-zero">
                <div>
                    <button class="btn" [ngClass]="ecosystemapplicationstate==1 ? 'btn-primary': 'btn-secondary'"
                        (click)="ecosystemapplicationstate=1; onButtonToggle('Created')"
                        value="Created">{{'created' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="ecosystemapplicationstate==2 ? 'btn-primary': 'btn-secondary'"
                        (click)="ecosystemapplicationstate=2; onButtonToggle('Registered')"
                        value="Registered">{{'registered' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="ecosystemapplicationstate==3 ? 'btn-primary': 'btn-secondary'"
                        (click)="ecosystemapplicationstate=3; onButtonToggle('Updated')"
                        value="Updated">{{'updated' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="ecosystemapplicationstate==4 ? 'btn-primary': 'btn-secondary'"
                        (click)="ecosystemapplicationstate=4; onButtonToggle('Deleted')"
                        value="Deleted">{{'deleted.order' | translate}}</button>
                </div>
            </div>
            <br>
        </div>

        <div *ngIf="triggerType=='Ecosystem Groups'">
            <label for="rule-lifeCycle">{{ 'rulePolicies.ecosystemGroups' | translate }}</label>

            <div class="row row-margin-zero" >
                <div>
                    <button class="btn" [ngClass]="ecosystemgroupstate==1 ? 'btn-primary': 'btn-secondary'"
                        (click)="ecosystemgroupstate=1; onButtonToggle('Created')"
                        value="Created">{{'created' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="ecosystemgroupstate==2 ? 'btn-primary': 'btn-secondary'"
                        (click)="ecosystemgroupstate=2; onButtonToggle('Activated')"
                        value="Activated">{{'activated' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="ecosystemgroupstate==3 ? 'btn-primary': 'btn-secondary'"
                        (click)="ecosystemgroupstate=3; onButtonToggle('Deactivated')"
                        value="Deactivated">{{'deactivated' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="ecosystemgroupstate==4 ? 'btn-primary': 'btn-secondary'"
                        (click)="ecosystemgroupstate=4; onButtonToggle('Updated')"
                        value="Updated">{{'updated' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="ecosystemgroupstate==5 ? 'btn-primary': 'btn-secondary'"
                        (click)="ecosystemgroupstate=5; onButtonToggle('Suspended')"
                        value="Suspended">{{'suspended.order' | translate}}</button>
                </div>
            </div>
            <br>
            <div class="row row-margin-zero" >
                <div>
                    <button class="btn" [ngClass]="ecosystemgroupstate==6 ? 'btn-primary': 'btn-secondary'"
                        (click)="ecosystemgroupstate=6; onButtonToggle('Unsuspended')"
                        value="Unsuspended">{{'unsuspended' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="ecosystemgroupstate==7 ? 'btn-primary': 'btn-secondary'"
                        (click)="ecosystemgroupstate=7; onButtonToggle('AddEntitlement')"
                        value="AddEntitlement">{{'addEntitlement' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="ecosystemgroupstate==8 ? 'btn-primary': 'btn-secondary'"
                        (click)="ecosystemgroupstate=8; onButtonToggle('RemoveEntitlement')"
                        value="RemoveEntitlement">{{'removeEntitlement' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="ecosystemgroupstate==9 ? 'btn-primary': 'btn-secondary'"
                        (click)="ecosystemgroupstate=9; onButtonToggle('Deleted')"
                        value="Deleted">{{'deleted' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="ecosystemgroupstate==10 ? 'btn-primary': 'btn-secondary'"
                        (click)="ecosystemgroupstate=10; onButtonToggle('AddMember')"
                        value="AddMember">{{'addMember' | translate}}</button>
                </div>
            </div>
            <br>
            <div class="row row-margin-zero" >
                <div>
                    <button class="btn" [ngClass]="ecosystemgroupstate==11 ? 'btn-primary': 'btn-secondary'"
                        (click)="ecosystemgroupstate=11; onButtonToggle('AddRelationship')"
                        value="AddRelationship">{{'addRelationship' | translate}}</button>&nbsp;&nbsp;
                    <button class="btn" [ngClass]="ecosystemgroupstate==12 ? 'btn-primary': 'btn-secondary'"
                        (click)="ecosystemgroupstate=12; onButtonToggle('RemoveRelationship')"
                        value="RemoveRelationship">{{'removeRelationship' | translate}}</button>&nbsp;&nbsp;
                </div>
            </div>
            <br>
        </div>
    </div>

    <div>
        <div *ngIf="!setDefault">
            <div *ngIf="!showGateway">
                <div>
                    <app-events *ngIf="triggerType=='Device Event' || triggerType=='Ecosystem Event'" [pageSize]="5"
                        [showSelectColoumn]="true" [showControlBar]=true [showNew]="false" multiSelect="false"
                        [showEnableColumn]=true
                        [displayedColumns]="['ADD', 'name', 'description','creation', 'ACTIVE']" [showHeaderToolTips]=false>
                    </app-events>
                </div>

                <div class="tab-holder" *ngIf="getEventIds() && triggerType.includes('Device ')">
                    <mat-tab-group (selectedTabChange)="tabClick($event)">
                        <mat-tab label="Device Templates"></mat-tab>
                        <mat-tab label="Devices"></mat-tab>
                    </mat-tab-group>
                </div>
                <div>
                    <div *ngIf="rulesService.reload">
                        <app-digitalTwins *ngIf="showDeviceFromSideNav && triggerType=='Device Event' && getEventIds()
            && rulesService.showDevices" [pageSize]="5" [showSelectColoumn]=true [showControlBar]=true
                            [displayedColumns]="['ADD','name','description','creation','ACTIVE']" multiSelect="true"
                            [showEnableColumn]=true addnQueryParam=[observableEvents.id::{{eventId}}] [showHeaderToolTips]=false>
                        </app-digitalTwins>

                        <app-digitalTwinTemplates *ngIf="showDeviceFromSideNav && triggerType=='Device Event' && getEventIds()
            && rulesService.showDeviceTemplates" [pageSize]="5" [showSelectColoumn]=true [showControlBar]=true
                            [displayedColumns]="['ADD','name','description','creation','ACTIVE']" multiSelect="true"
                            [showEnableColumn]=true addnQueryParam=[eventTemplates.id::{{eventId}}] [showHeaderToolTips]=false>
                        </app-digitalTwinTemplates>
                    </div>
                    <div *ngIf="!rulesService.reload">
                        <app-digitalTwins *ngIf="showDeviceFromSideNav && triggerType=='Device Event' && getEventIds()
            && rulesService.showDevices" [pageSize]="5" [showSelectColoumn]=true [showControlBar]=true
                            [displayedColumns]="['ADD','name','description','creation','ACTIVE']" multiSelect="true"
                            [showEnableColumn]=true addnQueryParam=[observableEvents.id::{{eventId}}] [showHeaderToolTips]=false>
                        </app-digitalTwins>

                        <app-digitalTwinTemplates *ngIf="showDeviceFromSideNav && triggerType=='Device Event' && getEventIds()
            && rulesService.showDeviceTemplates" [pageSize]="5" [showSelectColoumn]=true [showControlBar]=true
                            [displayedColumns]="['ADD','name','description','creation','ACTIVE']" multiSelect="true"
                            [showEnableColumn]=true addnQueryParam=[eventTemplates.id::{{eventId}}] [showHeaderToolTips]=false>
                        </app-digitalTwinTemplates>
                    </div>
                </div>
                <div class="tab-holder"
                    *ngIf="triggerType=='Device Lifecycle' || triggerType=='Device Presence' || triggerType=='Device Geofence' || triggerType=='Device Health' || triggerType=='Device Tracking'">
                    <mat-tab-group (selectedTabChange)="tabClick($event)">
                        <mat-tab label="Device Templates"></mat-tab>
                        <mat-tab label="Devices"></mat-tab>
                    </mat-tab-group>
                </div>

                <div>
                    <div *ngIf="rulesService.showDevices">
                        <app-digitalTwins *ngIf="showDeviceFromSideNav && triggerType=='Device Lifecycle' || triggerType=='Device Presence' || triggerType=='Device Geofence' || triggerType=='Device Health' || triggerType=='Device Tracking'  
        && rulesService.showDevices" [pageSize]="5" [showSelectColoumn]=true [showControlBar]=true
                            [displayedColumns]="['ADD','name','description','creation','ACTIVE']" multiSelect="true"
                            [showEnableColumn]=true addnQueryParam=[observableEvents.id::{{eventId}}] [showHeaderToolTips]=false>
                        </app-digitalTwins>
                    </div>
                    <div *ngIf="rulesService.showDeviceTemplates">
                        <app-digitalTwinTemplates *ngIf="showDeviceFromSideNav && triggerType=='Device Lifecycle' || triggerType=='Device Presence' || triggerType=='Device Geofence' || triggerType=='Device Health' || triggerType=='Device Tracking'
        && rulesService.showDeviceTemplates" [pageSize]="5" [showSelectColoumn]=true [showControlBar]=true
                            [displayedColumns]="['ADD','name','description','creation','ACTIVE']" multiSelect="true"
                            [showEnableColumn]=true addnQueryParam=[eventTemplates.id::{{eventId}}] [showHeaderToolTips]=false>
                        </app-digitalTwinTemplates>
                    </div>
                </div>
                <div class="tab-holder" *ngIf="getEventIds() && triggerType=='Ecosystem Event'">
                    <mat-tab-group (selectedTabChange)="subscribersTabClick($event)">
                        <mat-tab label="Ecosystem templates"></mat-tab>
                        <mat-tab label="Ecosystems"></mat-tab>
                    </mat-tab-group>
                </div>
                <div>
                    <div *ngIf="rulesService.reload">
                        <div *ngIf="ecosystemRulePolicy && !iotService.viewRulePolicy">
                            <app-ecosystems *ngIf="rulesService.showEcosystems && getEventIds()" [pageSize]="5"
                                [showSelectColoumn]="true" [showEnableColumn]=true [showControlBar]=true
                                [showNew]="false" multiSelect="true"
                                [displayedColumns]="['ADD', 'name', 'description','creation', 'ACTIVE' ]"
                                addnQueryParam=[standardSyntheticEvent::{{eventId}}] [showHeaderToolTips]=false>
                            </app-ecosystems>

                            <app-ecosystemTemplates *ngIf="rulesService.showEcosystemTemplates && getEventIds()"
                                [pageSize]="5" [showSelectColoumn]=true [showEnableColumn]=true [showControlBar]=true
                                [showNew]=false multiSelect="true"
                                [displayedColumns]="['ADD', 'name', 'description','creation', 'ACTIVE' ]"
                                addnQueryParam=[syntheticEvent::{{eventId}}] [showHeaderToolTips]=false>
                            </app-ecosystemTemplates>
                        </div>
                    </div>
                    <div *ngIf="!rulesService.reload">
                        <div *ngIf="ecosystemRulePolicy && !iotService.viewRulePolicy">
                            <app-ecosystems *ngIf="rulesService.showEcosystems && getEventIds()" [pageSize]="5"
                                [showSelectColoumn]="true" [showEnableColumn]=true [showControlBar]=true
                                [showNew]="false" multiSelect="true"
                                [displayedColumns]="['ADD', 'name', 'description','creation', 'ACTIVE' ]"
                                addnQueryParam=[standardSyntheticEvent::{{eventId}}] [showHeaderToolTips]=false>
                            </app-ecosystems>

                            <app-ecosystemTemplates *ngIf="rulesService.showEcosystemTemplates && getEventIds()"
                                [pageSize]="5" [showSelectColoumn]=true [showEnableColumn]=true [showControlBar]=true
                                [showNew]=false multiSelect="true"
                                [displayedColumns]="['ADD', 'name', 'description','creation', 'ACTIVE' ]"
                                addnQueryParam=[syntheticEvent::{{eventId}}] [showHeaderToolTips]=false>
                            </app-ecosystemTemplates>
                        </div>

                    </div>
                    <div *ngIf="iotService.viewRulePolicy">
                        <div *ngIf="rulesService.reload">
                            <div *ngIf="ecosystemRulePolicy">
                                <app-ecosystems *ngIf="rulesService.showEcosystems && getEventIds()" [pageSize]="5"
                                    [showSelectColoumn]="true" [showEnableColumn]=true [showControlBar]=true
                                    [showNew]="false" multiSelect="true"
                                    [displayedColumns]="['ADD', 'name', 'description','creation', 'ACTIVE' ]"
                                    addnQueryParam=[standardSyntheticEvent::{{eventId}}] [showHeaderToolTips]=false>
                                </app-ecosystems>
    
                                <app-ecosystemTemplates *ngIf="rulesService.showEcosystemTemplates && getEventIds()"
                                    [pageSize]="5" [showSelectColoumn]=true [showEnableColumn]=true [showControlBar]=true
                                    [showNew]=false multiSelect="true"
                                    [displayedColumns]="['ADD', 'name', 'description','creation', 'ACTIVE' ]"
                                    addnQueryParam=[syntheticEvent::{{eventId}}] [showHeaderToolTips]=false>
                                </app-ecosystemTemplates>
                            </div>
                        </div>
                        <div *ngIf="!rulesService.reload">
                            <div *ngIf="ecosystemRulePolicy">
                                <app-ecosystems *ngIf="rulesService.showEcosystems && getEventIds()" [pageSize]="5"
                                    [showSelectColoumn]="true" [showEnableColumn]=true [showControlBar]=true
                                    [showNew]="false" multiSelect="true"
                                    [displayedColumns]="['ADD', 'name', 'description','creation', 'ACTIVE' ]"
                                    addnQueryParam=[standardSyntheticEvent::{{eventId}}] [showHeaderToolTips]=false>
                                </app-ecosystems>
    
                                <app-ecosystemTemplates *ngIf="rulesService.showEcosystemTemplates && getEventIds()"
                                    [pageSize]="5" [showSelectColoumn]=true [showEnableColumn]=true [showControlBar]=true
                                    [showNew]=false multiSelect="true"
                                    [displayedColumns]="['ADD', 'name', 'description','creation', 'ACTIVE' ]"
                                    addnQueryParam=[syntheticEvent::{{eventId}}] [showHeaderToolTips]=false>
                                </app-ecosystemTemplates>
                            </div>  
                       </div>    
                    </div>
                    <div *ngIf="triggerType=='Device Event'">
                        <div class="tab-holder">
                            <mat-tab-group (selectedTabChange)="subscribersTabClick($event)">
                                <mat-tab label="Ecosystem templates"></mat-tab>
                                <mat-tab label="Ecosystems"></mat-tab>
                            </mat-tab-group>
                        </div>
                        <div *ngIf="!ecosystemRulePolicy">
                            <app-ecosystems *ngIf="rulesService.showEcosystems" [pageSize]="5"
                                [showSelectColoumn]="true" [showEnableColumn]=true [showControlBar]=true
                                [showNew]="false" multiSelect="true"
                                [displayedColumns]="['ADD', 'name', 'description','creation', 'ACTIVE' ]" [showHeaderToolTips]=false>
                            </app-ecosystems>

                            <app-ecosystemTemplates *ngIf="rulesService.showEcosystemTemplates" [pageSize]="5"
                                [showSelectColoumn]=true [showEnableColumn]=true [showControlBar]=true [showNew]=false
                                multiSelect="true"
                                [displayedColumns]="['ADD', 'name', 'description','creation', 'ACTIVE' ]" [showHeaderToolTips]=false>
                            </app-ecosystemTemplates>
                        </div>
                        <div *ngIf="iotService.viewRulePolicy">
                            <app-ecosystems *ngIf="rulesService.showEcosystems" [pageSize]="5"
                                [showSelectColoumn]="true" [showEnableColumn]=true [showControlBar]=true
                                [showNew]="false" multiSelect="true"
                                [displayedColumns]="['ADD', 'name', 'description','creation', 'ACTIVE' ]" [showHeaderToolTips]=false>
                            </app-ecosystems>

                            <app-ecosystemTemplates *ngIf="rulesService.showEcosystemTemplates" [pageSize]="5"
                                [showSelectColoumn]=true [showEnableColumn]=true [showControlBar]=true [showNew]=false
                                multiSelect="true"
                                [displayedColumns]="['ADD', 'name', 'description','creation', 'ACTIVE' ]" [showHeaderToolTips]=false>
                            </app-ecosystemTemplates>
                        </div>
                    </div>
                    <div
                        *ngIf="triggerType=='Ecosystem Devices' || triggerType=='Ecosystem Persons' ||triggerType=='Ecosystem Applications' || triggerType=='Ecosystem Groups'">
                        <div class="tab-holder">
                            <mat-tab-group (selectedTabChange)="subscribersTabClick($event)">
                                <mat-tab label="Ecosystem templates"></mat-tab>
                                <mat-tab label="Ecosystems"></mat-tab>
                            </mat-tab-group>
                        </div>
                        <div *ngIf="!ecosystemRulePolicy">
                            <app-ecosystems *ngIf="rulesService.showEcosystems" [pageSize]="5"
                                [showSelectColoumn]="true" [showEnableColumn]=true [showControlBar]=true
                                [showNew]="false" multiSelect="true"
                                [displayedColumns]="['ADD', 'name', 'description','creation', 'ACTIVE' ]" [showHeaderToolTips]=false>
                            </app-ecosystems>

                            <app-ecosystemTemplates *ngIf="rulesService.showEcosystemTemplates" [pageSize]="5"
                                [showSelectColoumn]=true [showEnableColumn]=true [showControlBar]=true [showNew]=false
                                multiSelect="true"
                                [displayedColumns]="['ADD', 'name', 'description','creation', 'ACTIVE' ]" [showHeaderToolTips]=false>
                            </app-ecosystemTemplates>
                        </div>
                        <div *ngIf="iotService.viewRulePolicy">
                            <app-ecosystems *ngIf="rulesService.showEcosystems" [pageSize]="5"
                                [showSelectColoumn]="true" [showEnableColumn]=true [showControlBar]=true
                                [showNew]="false" multiSelect="true"
                                [displayedColumns]="['ADD', 'name', 'description','creation', 'ACTIVE' ]" [showHeaderToolTips]=false>
                            </app-ecosystems>

                            <app-ecosystemTemplates *ngIf="rulesService.showEcosystemTemplates" [pageSize]="5"
                                [showSelectColoumn]=true [showEnableColumn]=true [showControlBar]=true [showNew]=false
                                multiSelect="true"
                                [displayedColumns]="['ADD', 'name', 'description','creation', 'ACTIVE' ]" [showHeaderToolTips]=false>
                            </app-ecosystemTemplates>
                        </div>
                    </div>
                </div>


            </div>
        </div>
        <div class="example-sidenav-content" *ngIf="!setDefault">
            <ng-container>
                <app-geofences *ngIf="triggerType=='Device Geofence'" [pageSize]="5" [showSelectColoumn]="true"
                    [showControlBar]=true [showNew]="false" multiSelect="true"
                    [displayedColumns]="['ADD', 'name', 'description','creation', 'ACTIVE']" [showHeaderToolTips]=false></app-geofences>
                <div *ngIf="showGateway">

                    <app-gateways [pageSize]="5" [showSelectColoumn]="true" [showControlBar]=true [showNew]="false"
                        multiSelect="false" [displayedColumns]="['ADD', 'name', 'description','creation']" [showHeaderToolTips]=false>
                    </app-gateways>

                    <div *ngIf="rulesService.reload">
                        <div *ngIf="getGateways()">
                            <ng-container *ngIf="!rulesService.showGatewayDevices && rulesService.loading">
                                <br /><br />
                                <div class="d-flex justify-content-center subpageLoading">
                                    <div class="spinner-border" role="status">
                                        <span class="sr-only" id="loading"></span>
                                    </div>
                                </div>
                            </ng-container>
                            <span style="display: flex; justify-content: center;"
                                *ngIf="!rulesService.showGatewayDevices && !rulesService.loading">{{'no.devices.gateway' | translate}}</span>
                            <app-digitalTwins *ngIf="rulesService.showGatewayDevices"
                                [pageSize]="5" [showSelectColoumn]=true [showControlBar]=true
                                [displayedColumns]="['ADD','name','description','creation','ACTIVE']"
                                multiSelect="false" [showEnableColumn]=true
                                addnQueryParam=[{{rulesService.gatewayDevices}}] [showHeaderToolTips]=false>
                            </app-digitalTwins>
                            <app-events *ngIf="showGatewayDeviceEvents || rulesService.devices.length>0" [pageSize]="5"
                                [showSelectColoumn]="true" [showControlBar]=true [showNew]="false" multiSelect="false"
                                [showEnableColumn]=true
                                [displayedColumns]="['ADD', 'name', 'description','creation', 'ACTIVE']"
                                addnQueryParam=[id::{{rulesService.devices[0].observableEvents[0]}}] [showHeaderToolTips]=false>
                            </app-events>
                        </div>
                    </div>
                    <div *ngIf="!rulesService.reload">
                        <div *ngIf="getGateways()">
                            <ng-container *ngIf="!rulesService.showGatewayDevices && rulesService.loading">
                                <br /><br />
                                <div class="d-flex justify-content-center subpageLoading">
                                    <div class="spinner-border" role="status">
                                        <span class="sr-only" id="loading"></span>
                                    </div>
                                </div>
                            </ng-container>
                            <span style="display: flex; justify-content: center;"
                                *ngIf="!rulesService.showGatewayDevices && !rulesService.loading">{{'no.devices.gateway' | translate}}</span>
                            <app-digitalTwins *ngIf="rulesService.showGatewayDevices"
                                [pageSize]="5" [showSelectColoumn]=true [showControlBar]=true
                                [displayedColumns]="['ADD','name','description','creation','ACTIVE']"
                                multiSelect="false" [showEnableColumn]=true
                                addnQueryParam=[{{rulesService.gatewayDevices}}] [showHeaderToolTips]=false>
                            </app-digitalTwins>
                            <app-events *ngIf="showGatewayDeviceEvents || rulesService.devices.length>0" [pageSize]="5"
                                [showSelectColoumn]="true" [showControlBar]=true [showNew]="false" multiSelect="false"
                                [showEnableColumn]=true
                                [displayedColumns]="['ADD', 'name', 'description','creation', 'ACTIVE']"
                                addnQueryParam=[id::{{rulesService.devices[0].observableEvents[0]}}] [showHeaderToolTips]=false>
                            </app-events>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="col-md-10 row-margin-zero" style="margin-left: -10px;" *ngIf="triggerType!='Select Trigger' && !setDefault && !showGateway">
            <app-ace from="rulePolicy" editorMode='javascript' editorValue={{defaultScript}} #editor></app-ace>
        </div>
        <div *ngIf="showGateway" class="col-md-10" style="margin-left: -10px">
            <app-ace from="rulePolicy" editorMode='javascript' editorValue={{script}} #editor></app-ace>
        </div>
        <div class="col-md-7" *ngIf="triggerType!='Select Trigger' && !setDefault && !chkBoxModel">
            <app-code-examples codeExampleType="rule"></app-code-examples>
        </div>
        <div class="col-md-6" style="margin-left: 5px;" *ngIf="chkBoxModel && rulesService.showGatewayDevices">
            <label for="gatewaySampleScripts">{{'rulePolicies.sampleCode' | translate}}</label>
            <ng-container class="dropdown">
                <select  name="gatewaySampleScripts" id="gatewaySampleScripts" [(ngModel)]="gtwRuleSample"
                    (change)="gatewayExamples($event)">
                    <option [value]="item.gtwRuleSample" *ngFor="let item of gatewayRuleSampleType">
                        {{item.gtwRuleSample}}
                    </option>
                </select><br>
            </ng-container>
            <div *ngIf="gatewayCodeExamples == 'Invoke Device Command'">
                <br>
                <b>{{'device' | translate}} </b>
                <br>
                <select style="min-width: 300px;" *ngIf="gatewayDeviceList.length==0" [(ngOptions)]="gatewayDeviceList" [(ngModel)]="gatewayDeviceExamples" (change)="updateGWDevExample();updateSupportedCommands();" >
                </select>
                <select style="min-width: 300px;" name="gatewayDeviceList" id="gatewayDeviceList" *ngIf="rulesService.showGatewayDevices && gatewayDeviceList.length>0"
                    [(ngModel)]="gatewayDeviceExamples" (change)="updateGWDevExample()">
                    <option [value]="device.id" *ngFor="let device of gatewayDeviceList">{{device.name[0].text}}</option>
                </select>
            </div>
            <div *ngIf="gatewayCodeExamples == 'Invoke Device Command' || gatewayCodeExamples == 'Broadcast Command' ">
                <br>
                <b>{{'command' | translate}} </b>
                <br>
                <select style="min-width: 300px;" [(ngOptions)]="supportedCommands" [(ngModel)]="gatewayCommandExamples"
                 (change)="updateGWDevExample();">
                </select>
            </div>
            <div *ngIf="gatewayCodeExamples == 'Misc' ">
                <br>
                <b></b>
                <br>
                <select style="min-width: 300px;" [(ngModel)]="gatewayUtilExamples" (change)="updateUtilExample();">
                    <option value="keyValue">{{'set.keyvalue.pairs' | translate}}</option>
                    <option value="ManageCounters">{{'manage.counters' | translate}}</option>
                    <option value="GPSLocation">{{'get.gateways.location' | translate}}</option>
                </select>
            </div>
            <br>
            <br>
            <div>
                <pre
                    *ngIf="gatewayCodeExamples == 'Upload Event'"><code><font face="courier">{{gatewayCode}}</font></code></pre>
                <pre
                    *ngIf="gatewayCodeExamples == 'Invoke Device Command'"><code><font face="courier">{{GWIDCCodeExample}}{{gatewayCode}}</font></code></pre>
                <pre
                    *ngIf="gatewayCodeExamples == 'Broadcast Command'"><code><font face="courier">{{GWBCCodeExample}}{{gatewayCode}}</font></code></pre>
                <pre
                    *ngIf="gatewayCodeExamples == 'Misc' && gatewayCode != ''"><code><font face="courier">{{gatewayCode}}</font></code></pre>
            </div>
        </div>
    </div>
</mat-sidenav-container>
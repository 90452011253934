<mat-sidenav-container class="new-export" autosize>



    <mat-sidenav-content class="example-sidenav-content">
        <div class="export-data">

            <div class="form-data" *ngIf="importInfo">
                <div>
                    <label>{{'package.file' | translate}}</label>
                    <div id="uploadForm">
                        <div>
                            <input type='file' (click)="reloadImport()" (change)="fileChanged($event)" id="fileUpload">
                        </div>
                        <!-- <input type="file" name="file" id="file" class="inputfile" (change)="reloadImport()" />  -->
                        <p *ngIf="executeImporterror" style="color: red;">{{executeImporterror}}</p>
                    </div>
                </div>


                <div>
                    <label>{{'job.name' | translate}}</label>
                    <div>
                        <input [(ngModel)]="importInfo.jobName" name="importInfo.jobName" type="text"
                            placeholder="optional" autoFocus>
                    </div>
                </div>


                <div [hidden]="!errorMsgFLag">
                    <p style="color: red;margin-left: 5px;">{{errorMsg}}</p>
                </div>


                <div [hidden]="!successMsgFlag">{{successMsg}}</div>

                <div [hidden]="!showImportSuccessTab">
                    <h1 style="color:green;">
                        <fa-icon [icon]="faSuccessIcon"></fa-icon>
                        <b>{{'import.details' | translate}}{{':' | translate}}</b>
                    </h1>
                    <div style="margin-left: 5px;">
                        <b>{{'import.job.id' | translate}}{{':' | translate}}</b> {{importJobId}}<br />
                        <b>{{'note' | translate}}{{':' | translate}}</b> {{streamJobMsg}}<br />
                        <div [ngSwitch]="destStreamDatas || '_undefined_'">
                            <div *ngSwitchCase="_undefined_"></div>
                            <div ng-importInfo-default>
                                <div>
                                    <b>{{'destination.stream.details' | translate}}{{':' | translate}}</b>
                                    <li *ngFor="let destStreamData of destStreamDatas">
                                        {{destStreamData.name}} {{':' | translate}} {{destStreamData.value}}
                                    </li>
                                </div>
                                <div>
                                    <b>{{'source.stream.details' | translate}}{{':' | translate}}</b>
                                    <li *ngFor="let sourceStreamData of sourceStreamDatas">
                                        {{sourceStreamData.name}} {{':' | translate}} {{sourceStreamData.value}}
                                    </li>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>

                <div id="authtypeParams" [hidden]="!authtypeCaptured">
                    <div>
                        <h1>
                            {{'additional.streamParameters.required' | translate}}{{':' | translate}}
                        </h1>
                        <hr style="margin-top:0px;margin-bottom:15px">
                    </div>
                    <br>
                    <!-- source section -->
                    <div>
                        <form *ngIf="sourceStreamDirection">
                            <div>
                                <p>{{'detected.sourceStream.direction' | translate}}{{':' |
                                    translate}}<b>{{sourceStreamDirection}}</b></p>
                            </div>
                        </form>
                        <form [hidden]="!sourceStreamDestinationUrlRequired">
                            <div>
                                <label>{{'source.stream.destination.url' | translate}}{{':' | translate}}</label>
                                <div>
                                    <input [(ngModel)]="importInfo.sourceStreamDestinationUrl"
                                        name="importInfo.sourceStreamUsername" type="text" autoFocus>
                                </div>
                            </div>
                        </form>
                        <form [hidden]="!sourceAuthtypeCaptured">
                            <!-- <fa-icon style="padding-left: 5px;" *ngIf="!clientSecretFlag" (click)="clientSecretFlag=!clientSecretFlag" [icon]="hidePassword"></fa-icon>
                            <fa-icon style="padding-left: 5px;" *ngIf="clientSecretFlag" (click)="clientSecretFlag=!clientSecretFlag" [icon]="showPassword"></fa-icon> -->
                            <div *ngIf=" selectedProtSecTypeSource=='BASIC'">
                                <label>{{'source.stream.username' | translate}}</label>
                                <div>
                                    <div>
                                        <input name="importInfo.sourceStreamUsername"
                                            name="importInfo.sourceStreamUsername"
                                            [(ngModel)]="importInfo.sourceStreamUsername" type="text" autoFocus>
                                    </div>
                                </div>
                                <label>{{'source.stream.password' | translate}}</label>
                                <div>
                                    <!-- <input name="importInfo.sourceStreamPassword"
                                        [(ngModel)]="importInfo.sourceStreamPassword" type="password" autoFocus> -->

                                    <input *ngIf="sourceStreamPasswordFlag" name="importInfo.sourceStreamPassword"
                                        [(ngModel)]="importInfo.sourceStreamPassword" type="text"
                                        aria-label="sourceStreamPassword">
                                    <input *ngIf="!sourceStreamPasswordFlag" name="importInfo.sourceStreamPassword"
                                        [(ngModel)]="importInfo.sourceStreamPassword" type="password"
                                        aria-label="sourceStreamPassword">
                                    <!-- <mat-checkbox style="margin-left: 10px;" [(ngModel)]="clientSecretFlag">Edit</mat-checkbox> -->
                                    <fa-icon style="padding-left: 5px;" *ngIf="!sourceStreamPasswordFlag"
                                        (click)="sourceStreamPasswordFlag=!sourceStreamPasswordFlag"
                                        [icon]="hidePassword"></fa-icon>
                                    <fa-icon style="padding-left: 5px;" *ngIf="sourceStreamPasswordFlag"
                                        (click)="sourceStreamPasswordFlag=!sourceStreamPasswordFlag"
                                        [icon]="showPassword"></fa-icon>
                                </div>


                            </div>
                            <div *ngIf="selectedProtSecTypeSource=='MUAUTH'">

                                <label>{{'source.muAuth.keyBundle.id' | translate}}</label>
                                <div>
                                    <input name="importInfo.sourceMuAuthKeyBundle"
                                        [(ngModel)]="importInfo.sourceMuAuthKeyBundle" type="text" autoFocus>
                                </div>
                            </div>
                            <div *ngIf=" selectedProtSecTypeSource=='OAUTH2'">

                                <label>{{'source.oauth2.token.id' | translate}}</label>
                                <div>
                                    <input name="importInfo.sourceTokenId" [(ngModel)]="importInfo.sourceTokenId"
                                        type="text" autoFocus>
                                </div>
                            </div>
                            <div *ngIf=" selectedProtSecTypeSource=='X509'">
                                <label>{{'source.pem' | translate}}</label>
                                <div>
                                    <textarea msd-elastic="\n" name="importInfo.sourcePem"
                                        [(ngModel)]="importInfo.sourcePem" style="height: 200px"></textarea>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- source section end -->

                    <!-- destination section -->
                    <div>
                        <form *ngIf="destinationStreamDirection">
                            <div>
                                <p>{{'detected.destination.stream.direction' | translate}}{{':' |
                                    translate}}<b>{{destinationStreamDirection}}</b></p>
                            </div>
                        </form>
                        <form [hidden]="!destinationStreamDestinationUrlRequired">
                            <div>
                                <label>{{'destination.stream.destination.url' | translate}}{{':' | translate}}</label>
                                <div>
                                    <input name="importInfo.destinationStreamDestinationUrl"
                                        [(ngModel)]="importInfo.destinationStreamDestinationUrl" type="text" autoFocus>
                                </div>
                            </div>
                        </form>
                        <form [hidden]="!destAuthtypeCaptured">


                            <div *ngIf=" selectedProtSecTypeDest=='BASIC'">
                                <label>{{'destination.stream.username' | translate}}</label>
                                <div>
                                    <input name="importInfo.destinationStreamUsername"
                                        [(ngModel)]="importInfo.destinationStreamUsername" type="text" autoFocus>
                                </div>
                                <label>{{'destination.stream.password' | translate}}</label>
                                <div>
                                    <!-- <input name="importInfo.destinationStreamPassword"
                                        [(ngModel)]="importInfo.destinationStreamPassword" type="password" autoFocus> -->

                                    <input *ngIf="destinationStreamPasswordFlag"
                                        name="importInfo.destinationStreamPassword"
                                        [(ngModel)]="importInfo.destinationStreamPassword" type="text"
                                        aria-label="destinationStreamPassword">
                                    <input *ngIf="!destinationStreamPasswordFlag"
                                        name="importInfo.destinationStreamPassword"
                                        [(ngModel)]="importInfo.destinationStreamPassword" type="password"
                                        aria-label="destinationStreamPassword">
                                    <!-- <mat-checkbox style="margin-left: 10px;" [(ngModel)]="clientSecretFlag">Edit</mat-checkbox> -->
                                    <fa-icon style="padding-left: 5px;" *ngIf="!destinationStreamPasswordFlag"
                                        (click)="destinationStreamPasswordFlag=!destinationStreamPasswordFlag"
                                        [icon]="hidePassword"></fa-icon>
                                    <fa-icon style="padding-left: 5px;" *ngIf="destinationStreamPasswordFlag"
                                        (click)="destinationStreamPasswordFlag=!destinationStreamPasswordFlag"
                                        [icon]="showPassword"></fa-icon>

                                </div>
                            </div>
                            <div *ngIf=" selectedProtSecTypeDest=='MUAUTH'">
                                <label>{{'destination.muAuth.keyBundle.id' | translate}}</label>
                                <div>
                                    <input name="importInfo.destinationMuAuthKeyBundle"
                                        [(ngModel)]="importInfo.destinationMuAuthKeyBundle" type="text" autoFocus>
                                </div>
                            </div>
                            <div *ngIf=" selectedProtSecTypeDest=='OAUTH2'">
                                <label>{{'destination.oauth2.token.id' | translate}}</label>
                                <div>
                                    <input name="importInfo.destinationTokenId"
                                        [(ngModel)]="importInfo.destinationTokenId" type="text" autoFocus>
                                </div>
                            </div>
                            <div *ngIf=" selectedProtSecTypeDest=='X509'">
                                <label>{{'destination.pem' | translate}}</label>
                                <div>
                                    <div>
                                        <textarea msd-elastic="\n" name="importInfo.destinationPem"
                                            [(ngModel)]="importInfo.destinationPem" style="height: 200px"></textarea>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- dest-section end -->
                </div>
                <br>
                <button class="btn btn-primary" style="margin-left: 3px;"
                    (click)="isAuthTypeProcessed ? executeProcessedImport() : executeImport(file)"
                    [disabled]="file==undefined || importCreated">{{ 'execute.import' |
                    translate}}</button><br>

            </div>
        </div>
    </mat-sidenav-content>

</mat-sidenav-container>
<div class="authority-detail" *ngIf="authority">
    <div style="margin-left: -20px;">
        <app-copy-resource-id></app-copy-resource-id>
    </div>
    <h1>{{'view.authority' | translate}}</h1>
    <form >
        <label for="subject">  {{'subject' | translate}}</label>
        <p id="subject">{{authority.subject}}</p>
        <label for="expiration">   {{'expiration' | translate}}</label>
        <p id="expiration">{{authority.expiration| date:'MMM d, y, h:mm:ss a' }}</p>
        <label for="Creation">   {{'creation' | translate}}</label>
        <p id="Creation">{{authority.creation| date:'MMM d, y, h:mm:ss a' }}</p>
        <label for="data">   {{'certificateData' | translate}}</label>
        <p id="data" style="overflow-wrap: anywhere; ">{{authority.certData }}</p>
        <p *ngIf="authority.isRevoked" style="color:red"> {{'this.authority.has.been.revoked' | translate}}</p>
        <button class="copy" [cdkCopyToClipboard]=authority.certData class="btn btn-secondary"><fa-icon [icon]="faCopyIcon"></fa-icon> {{'cdkCopyToClipboard' | translate}}</button>
        <button *ngIf="!authority.isRevoked && !isRevoke" class="btn btn-delete pull-right" (click)="toggleRevoke()"> {{'revoke' | translate}}</button>
        <div *ngIf="isRevoke" style="float: right; ">
            <h6>{{'are.sure.you.want.to.revoke.authority' | translate}}{{'?' | translate}}</h6>
            <button class="new-btn " mat-raised-button (click)="revokex509Authority(authority.id)">{{'yes' | translate}}</button>
            <button class="new-btn " mat-raised-button (click)="toggleRevoke()">{{'no' | translate}}</button>
        </div>
        <br>
        <br>
        
    </form>
</div>

<ng-container *ngIf="!authority">
    <div>
        <br/><br/>
    </div>
    <div class="d-flex justify-content-center pageLoading">
        <div class="spinner-border" role="status">
            <span class="sr-only" id="loading"></span>
        </div>
    </div>
</ng-container>
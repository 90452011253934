import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { IotService } from '../service/iot.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { TranslateService } from '@ngx-translate/core';
import { OrchestrationConfigsService } from '../service/orchestrationConfigs.service';
import { faTimes, faArrowUp, faArrowDown, faCheck, faPencilAlt, faCheckCircle, faExclamation, faLock, faUnlock, faMinus, faPlus, faSpinner, faAngleLeft, faAngleRight} from '@fortawesome/free-solid-svg-icons';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { OrchestrationConfigsComponent } from '../orchestrationConfigs/orchestrationConfigs.component';

@Component({
  selector: 'app-create-orchestration-config',
  templateUrl: './create-orchestration-config.component.html',
  styleUrls: ['./create-orchestration-config.component.scss']
})
export class CreateOrchestrationConfigComponent implements OnInit {
  mode = 0;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  supportLanguages = ['en'];
  tags = [];
  tagList = [];
  tagClass = 'tagInput';
  displayedColumns: string[] = ['Name', 'ServiceParms', 'ContinueOnFailure', 'Actions'];
  displayedVersionsColumns: string[] = ['Version Tag', 'Version Comments', 'Action', 'State', 'Delete'];
  displayedCodeRelationshipColumns:string[] = ['From Code', 'To Code', 'Delete'];
  displayedTpRelationshipColumns:string[] = ['Requestor Trading Partner', 'Approver Trading Partner' ,'Delete']
  faTimes = faTimes;
  faArrowUp = faArrowUp;
  faArrowDown = faArrowDown;
  faCheck = faCheck;
  faPencilAlt = faPencilAlt;
  faClose = faTimes;
  faSuccess = faCheckCircle;
  faCritical = faExclamation;
  faLock = faLock;
  faUnlock = faUnlock;
  faMinus = faMinus;
  faPlus = faPlus;
  faSpinner = faSpinner;
  faAngleLeft = faAngleLeft;
  faAngleRight = faAngleRight;
  taskType = "";
  task;
  submitted = false;
  saveErrorMsg = [];
  testInput;
  testResults;
  sourceDocLoaded = false;
  targetDocLoaded = false;
  streamDataLoaded = false;
  successMessage;
  createVersion = false;
  listVersion = false;
  startNewTP = false;
  loading = true;
  loadingVersionList = false;
  versionList = [];
  loadingRelationshipList = true;
  //tpCodeRelationship = false;
  tpRelationship = false;
  tpSelectedVal = "1";
  orchConfigComponent: any;
  saving = false;
  lockingVersion = false;
  unlockingVersion = false;
  auditFlag=false;
  auditRefreshFlag=true;
  testing = false;
  private addSubscription: Subscription;
  private removeSubscription: Subscription;
  orchestration = {
    "id": "",
    "creator": "",
    "creatorAppId": "",
    "realm": "",
    "name":[
        {
            "lang":"en",
            "text":""
        }
    ],
    "description":[
        {
            "lang":"en",
            "text":""
        }
    ],
    "orchestrationType":'Select Type',
    "serviceRunTime":"",
    "tagId":[], //Source Document Info
    "targetDocumentInfo":"",
    "status": "ACTIVE",
    "task":[],
    "encoding":"MESSAGE",
    "versionTag": "",
    "versionLock" : false,
    "versionComments": "",
    "tagTpRelationship": [],
    "tagTpCodeRelationship": []
  };

  orchestrationVersion = {
    "creator": "",
    "creatorAppId": "",
    "realm": "",
    "orchestrationConfigId": "",
    "versionTag": "",
    "versionLock" : true,
    "versionComments": ""
  }

  orchestrationConfigTypes = [
    {
      name: 'Select Type',
      type: 'Select Type'
    },
    {
      name: 'Common',
      type: 'common'
    },
    {
      name: 'Compound',
      type: 'compound'
    },
    {
      name: 'SenderWire',
      type: 'senderWire'
    },
    {
      name: 'ReceiverWire',
      type: 'receiverWire'
    }
  ];

  sections = [
    {
        title: 'Preprocessor',
        type: 'PREPROCESSOR',
        order: 1,
        limit: 0,
        hideTask: false
    }, // Limit 0 = Unlimited
    {
        title: 'Parser',
        type: 'PARSER',
        order: 2,
        limit: 1,
        hideTask: false
    },
    {
        title: 'Mapper',
        type: 'MAPPER',
        order: 3,
        limit: 0,
        hideTask: false
    },
    {
        title: 'Packager',
        type: 'PACKAGER',
        order: 4,
        limit: 1,
        hideTask: false
    },
    {
        title: 'Postprocessor',
        type: 'POSTPROCESSOR',
        order: 5,
        limit: 0,
        hideTask: false
    }
  ];

  @ViewChild('sourceDocInfoService') sourceDocInfoService;
  @ViewChild('targetDocInfoService') targetDocInfoService;
  @ViewChild('streamService') streamService;
  @ViewChild('tradingPartners') tradingPartners;
  @ViewChild('tradingPartnerRelationship') tradingPartnerRelationship;
  @ViewChild('tpCodes') tpCodes;
  @ViewChild('tpCodeRelationship') tpCodeRelationship;

  constructor(private router: Router, private route: ActivatedRoute, public iotService: IotService,
    public orchestrationConfigsService: OrchestrationConfigsService,
    private translateService: TranslateService, private formBuilder: FormBuilder, public sideNavComponent: SideNavComponent) {
      this.translateService.addLangs(this.supportLanguages);
      this.translateService.setDefaultLang('en');
      this.addSubscription = this.orchestrationConfigsService.createOrchconfigComponent$.subscribe(stream => {
        this.addStreamDocInfo(stream);
      })
      this.removeSubscription = this.orchestrationConfigsService.createOrchconfigComponent2$.subscribe(stream => {
        this.removeStreamDocInfo(stream, 1);
      })
  }

  ngOnInit(): void {
    console.log("In init");
    this.auditFlag=false;
    this.orchConfigComponent = new OrchestrationConfigsComponent(this.router, this.iotService,
      this.translateService, this.sideNavComponent, this.orchestrationConfigsService);
    this.route.params.subscribe(params => {
      if(params['id']){
        this.mode = 1;
        this.loadOrchestrationConfig(params['id']);
      } else {
        this.mode = 0;
        this.sourceDocLoaded = true;
        this.targetDocLoaded = true;
        this.streamDataLoaded = true;
        this.sideNavComponent.menuClear();
        this.sideNavComponent.menuChange('Dashboard', 'sub-type');
        this.sideNavComponent.menuChange('orchestrationConfigs.header', 'sub-type');
        this.sideNavComponent.menuChange('orchestrationConfigs.create.new', 'sub-type');
      }
    });
    if(this.orchestrationConfigsService.taskList){
      this.orchestrationConfigsService.taskList.sort((a, b) => (a.order > b.order) ? 1 : -1);
    }
  }

  ngOnDestroy(): void{
    this.orchestrationConfigsService.createTask = false;
    this.orchestrationConfigsService.taskList = [];
    this.orchestrationConfigsService.sourceDocInfo = undefined;
    this.orchestrationConfigsService.targetDocInfo = undefined;
    this.orchestrationConfigsService.streamData = [];
    this.auditFlag=false;
    this.addSubscription.unsubscribe();
    this.removeSubscription.unsubscribe();
  }

  add(event: MatChipInputEvent): void {

    this.tagClass = 'tagInput';
    const input = event.input;
    const value = event.value;
    const index = this.tagList.indexOf(value);
    this.tagList.push(value);
    if (index < 0) {
      if ((value || '').trim()) {
        const that = this;
        that.tags.push(value);
        // Reset the input value
        if (input) {
          input.value = '';
        }
      }
    } else {
      this.tagClass = 'tagInput-duplicate';
    }
  }

  remove(tag: string): void {
    const that = this;
    const index = that.tags.indexOf(tag);
    if (index >= 0) {
      that.tags.splice(index, 1);
    }

  }

  loadOrchestrationConfig(id){
    console.log("Going to load Orchestration with id : " + id);
    const that = this;

    this.iotService.getCuiObjResponse().getOrchestrationConfig({
      id: id
    }).then(function (orchestrationResult) {
      console.log("Orchestration loaded successfully");
      if(orchestrationResult.description == undefined){
        orchestrationResult.description = [
            {
                "lang":"en",
                "text":""
            }
        ];
      }
      orchestrationResult.task.forEach( x => {
        x.order = x.order * 1; //Converting to number
      });
      that.orchestration = orchestrationResult;
      that.orchestrationConfigsService.taskList = orchestrationResult.task;
      if(that.orchestrationConfigsService.taskList){
        that.orchestrationConfigsService.taskList.sort((a, b) => (a.order > b.order) ? 1 : -1);
      }
      if(that.orchestration.tagTpRelationship == undefined){
        that.orchestration.tagTpRelationship = [];
      }
      if(that.orchestration.tagTpCodeRelationship == undefined){
        that.orchestration.tagTpCodeRelationship = [];
      }
      that.orchestration.status = orchestrationResult.active.toString();
      console.log("Going to load source documentinfo")
      that.iotService.getCuiObjResponse().getDocumentInfoById({
        docInfoId: orchestrationResult.tagId[0].id
      }).then( function(sourceDocInfo){
        console.log("Source document info loaded successfully");
        that.orchestrationConfigsService.sourceDocInfo = sourceDocInfo;
        that.sourceDocLoaded = true;
      }).fail( function(err){
        console.log(err);
      });

      console.log("Going to load target documentinfo")
      that.iotService.getCuiObjResponse().getDocumentInfoById({
        docInfoId: orchestrationResult.targetDocumentInfo
      }).then( function(targetDocInfo){
        console.log("Target document info loaded successfully");
        that.orchestrationConfigsService.targetDocInfo = targetDocInfo;
        that.targetDocLoaded = true;
      }).fail( function(err){
        console.log(err);
      });

      console.log("Going to load connected streams")
      that.iotService.getCuiObjResponse().getStreams({
        qs: [
              ['documentInfoId', orchestrationResult.tagId[0].id]
          ]
      }).then( function(streamResult){
        console.log("Streams loaded successfully");
        that.orchestrationConfigsService.setStreamData(streamResult);
        that.streamDataLoaded = true;
        that.displayStream = streamResult[0];
      }).fail( function(err){
        console.log(err);
      });

      console.log("Checking if type is common");
      if(that.orchestration.orchestrationType == "common"){
        console.log("Type is common");
        console.log("Going to load TP relationship")
        that.orchestration.tagTpRelationship.forEach(relationship => {
          that.iotService.getCuiObjResponse().getTPRelationshipById({
            tradingPartnerCodeId: relationship.id
          }).then( function(result){
            console.log("TP Relationship loaded successfully");
            var index = that.orchestration.tagTpRelationship.findIndex(x => x.id == relationship.id);
            if(index != -1){
              that.orchestration.tagTpRelationship[index].requestorTradingPartnerName = result.requestorTradingPartnerName[0].text;
              that.orchestration.tagTpRelationship[index].approverTradingPartnerName = result.approverTradingPartnerName[0].text;
            }
          }).fail( function(err){
            console.log(err);
          });
        });

        console.log("Going to load TP code relationship")
        that.orchestration.tagTpCodeRelationship.forEach(relationship => {
          that.iotService.getCuiObjResponse().getTPCodeRelationshipById({
            codeRelationshipId: relationship.id
          }).then( function(result){
            console.log("TP code relationship loaded successfully");
            var index = that.orchestration.tagTpCodeRelationship.findIndex(x => x.id == relationship.id);
            if(index != -1){
              that.orchestration.tagTpCodeRelationship[index].fromCode = result.fromCode;
              that.orchestration.tagTpCodeRelationship[index].toCode = result.toCode;
            }
          }).fail( function(err){
            console.log(err);
          });
        });
      }
      that.sideNavComponent.menuClear();
      that.sideNavComponent.menuChange('Dashboard', 'sub-type');
      that.sideNavComponent.menuChange('orchestrationConfigs.header', 'sub-type');
      that.sideNavComponent.menuChange(that.orchestration.name[0].text, 'sub-type');
    }).fail( function(err){
      console.log(err);
    });

  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  changeOrchType(){
    if(this.orchestration.orchestrationType != 'common'){
      this.orchestration.tagTpRelationship = [];
      this.orchestration.tagTpCodeRelationship = [];
    }
  }

  getTaskList(taskType){
    var taskList = [];
    this.orchestrationConfigsService.taskList.forEach( value => {
      if (value) {
        if (value.taskType.toUpperCase() == taskType.toUpperCase()) {
          taskList.push(value);
        }
      }
    });
    return taskList;
  }

  getTaskListCount(taskType){
    var taskList = this.getTaskList(taskType);
    return taskList.length;
  }

  createTask(taskType){
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.orchestrationConfigsService.createTask=true;
    this.taskType = taskType;
  }

  editTask(task){
      this.orchestrationConfigsService.createTask=true;
      this.taskType = task.taskType;
      this.orchestrationConfigsService.setEditableTask(task);
  }

  removeTask(task){
    if(this.orchestrationConfigsService.taskList.length > 1){
      var index = this.orchestrationConfigsService.taskList.findIndex( x => (x.taskType === task.taskType && x.order == task.order));
      if(index != -1){
        this.orchestrationConfigsService.taskList.splice(index, 1);
        this.orchestrationConfigsService.taskList.forEach( value => {
          if (task.taskType == value.taskType && value.order > task.order) {
              value.order--;
          }
        });
        this.orchestrationConfigsService.taskList = [...this.orchestrationConfigsService.taskList];
        if(this.mode != 0 && task.taskId != undefined){
          const that = this;
          this.iotService.getCuiObjResponse().deleteOrchestrationTask({
            configId: this.orchestration.id,
            taskId: task.taskId
          }).then(function (response){
            that.successMessage = "Task deleted successfully";
          }).fail(function (err){
            that.saveErrorMsg = [];
            that.saveErrorMsg.push(err.responseJSON.apiMessage);
          });
        }
      }
    } else{
      this.saveErrorMsg = [];
      this.saveErrorMsg.push("Minimum 1 task is required");
    }

  }

  taskUp(task) {
     var currentOrder = task.order;
     var newOrder = currentOrder - 1;
     if (newOrder > 0) {
       this.orchestrationConfigsService.taskList.forEach( value => {
       if (value) {
         if (value.taskType.toUpperCase() == task.taskType.toUpperCase()) {
           if (value.order == currentOrder) {
             value.order = newOrder * 1;
           } else if (value.order == newOrder) {
             value.order = currentOrder * 1;
           }
         }
       }
       });
     }
     this.orchestrationConfigsService.taskList.sort((a, b) => (a.order > b.order) ? 1 : -1);
     this.orchestrationConfigsService.taskList = [...this.orchestrationConfigsService.taskList];
  }

  taskDown(task) {
     // Get Current Highest Order
     var highest = this.getNextOrder(task.taskType);
     var currentOrder = task.order * 1;
     var newOrder = currentOrder + 1;
     if (newOrder < highest) {
       this.orchestrationConfigsService.taskList.forEach( value => {
       if (value) {
         if (value.taskType.toUpperCase() == task.taskType.toUpperCase()) {
           if (value.order == currentOrder * 1) {
             value.order = newOrder;
           } else if (value.order == newOrder) {
             value.order = currentOrder * 1;
           }
         }
       }
       });
     }
     this.orchestrationConfigsService.taskList.sort((a, b) => (a.order > b.order) ? 1 : -1);
     this.orchestrationConfigsService.taskList = [...this.orchestrationConfigsService.taskList];console.log(this.orchestrationConfigsService.taskList);
  }

  getNextOrder(taskType){
    var order = 1;
    this.orchestrationConfigsService.taskList.forEach( (task) => {
    if(task){
      if(task.taskType === taskType && Number(task.order) >= order){
        order = Number(task.order) + 1;
      }
    }
    });
    return order * 1;
  }

  allowAddTask(section){
    var results = true;
    if (section.limit > 0) {
      var Total = 0
      if (this.orchestrationConfigsService.taskList) {
        this.orchestrationConfigsService.taskList.forEach( (task) => {
          if(task){
            if (task.taskType == section.type) {
                Total++;
            }
          }
        });
      }
      if (Total >= section.limit) {
          results = false;
      }
    }
    return results
  }

  changeActive(id, currState){
    this.orchConfigComponent.changeActive(id, currState);
    if(currState == 'true'){
      this.orchestration.status = 'false';
    } else {
      this.orchestration.status = 'true';
    }
  }

  resetErrorMsg(){
    this.saveErrorMsg = [];
  }

  resetSuccessMessage(){
    this.successMessage = undefined;
  }

  save(){
    console.log("Goint to validate before creation/updation");
    this.orchestration.creator = this.iotService.getCreator();
    this.orchestration.creatorAppId = this.iotService.getCreatorAppId();
    this.orchestration.realm = this.iotService.getRealm();
    this.orchestration.task = this.orchestrationConfigsService.taskList;
    this.saveErrorMsg = [];
    if (this.orchestration.name[0].text == "") {
        this.saveErrorMsg.push('Name is required');
    }
    if (this.orchestration.orchestrationType == "Select Type") {
        this.saveErrorMsg.push('Type is required');
    }
    if (this.orchestration.serviceRunTime == "") {
        this.saveErrorMsg.push('Runtime is required');
    }
    if (this.orchestration.encoding == "") {
        this.saveErrorMsg.push('Encoding is required');
    }
    if (this.orchestration.task.length == 0) {
        this.saveErrorMsg.push('At least 1 task is required');
    }
    if (this.orchestrationConfigsService.sourceDocInfo == undefined) {
        this.saveErrorMsg.push('Must select a Origin Document Info');
    } else{
        this.orchestration.tagId =[{"id": this.orchestrationConfigsService.sourceDocInfo.id}];
    }
    if (this.orchestrationConfigsService.targetDocInfo == undefined) {
        this.saveErrorMsg.push('Must select a Target Document Info');
    } else{
        this.orchestration.targetDocumentInfo = this.orchestrationConfigsService.targetDocInfo.id;
    }

    if(this.saveErrorMsg.length > 0){
      return;
    }

    this.orchestration.task.forEach( x => {
      x.order = x.order.toString(); //Converting to string
    });

    const that = this;
    this.saving = true;
    if(this.mode == 0){
      console.log("Creating a new orchestration");
      this.iotService.getCuiObjResponse().createOrchestration({
        data: this.orchestration
      }).then(function (results) {
        console.log("New Orchestration created successfully");
        that.mode = 1;
        that.orchestration.id = results.id;
        that.orchestration["version"] = results.version;
        that.orchestration.task.forEach( x => {
          x.order = x.order * 1; //Converting to number
        });
        console.log("Going to save streams");
        that.orchestrationConfigsService.getStreamData().forEach( stream => {
          console.log("Going to save stream with id: " + stream.id);
          that.iotService.getCuiObjResponse().createStreamDocInfo({
            streamId: stream.id,
            data: {
                "creator": that.iotService.getCreator(),
                "creatorAppId": that.iotService.getCreatorAppId(),
                "realm": that.iotService.getRealm(),
                "documentInfoId": results.tagId[0].id,
                "streamId": stream.id
            }
          }).then(function (results) {
              console.log("StreamDocInfo with stream id : "+ stream.id +" created successfully.")
          }).fail(function (err){
              console.log(err);
          });
        });
        //that.successMessage = "Orchestration created successfully.";
        that.saving = false;
        //that.loadOrchestrationConfig(results.id);
        that.router.navigateByUrl('/orchestrationConfigs/'+that.orchestration.id);
      }).fail(function(err){
        that.saving = false;
        that.saveErrorMsg.push("Status:" + err.responseJSON.status);
        that.saveErrorMsg.push("Message" + err.responseJSON.apiMessage);
      });
    } else { //Update Mode
      console.log("Goint to update the orchestration");
      this.iotService.getCuiObjResponse().updateOrchestration({
          id: that.orchestration.id,
          data: that.orchestration
      }).then(function (results2) {
        console.log("Orchestration updated successfully");
          that.orchestration["version"] = results2.version;
          // Convert Order from string to int.
          that.orchestration.task.forEach( x => {
            x.order = x.order * 1; //Converting to number
          });
          that.successMessage = "Orchestration updated successfully.";
          that.saving = false;
      }).fail(function (msg) {
          console.log(msg);
          that.orchestration.task.forEach( x => {
            x.order = x.order * 1; //Converting to number
          });
          that.saveErrorMsg = [];
          that.saveErrorMsg.push(msg.responseJSON.apiMessage);
          that.saving = false;
      });
    }

  }

  addStreamDocInfo(stream){
    console.log("Going to add stream, checking mode");
    if(this.mode == 1){
      console.log("Going to save stream with id: " + stream.id);
      this.saveErrorMsg = [];
      if (this.orchestration.tagId.length < 1) {
          this.saveErrorMsg.push('Must select a Origin Document Info');
          return;
      }
      this.iotService.getCuiObjResponse().createStreamDocInfo({
        streamId: stream.id,
        data: {
            "creator": this.iotService.getCreator(),
            "creatorAppId": this.iotService.getCreatorAppId(),
            "realm": this.iotService.getRealm(),
            "documentInfoId": this.orchestration.tagId[0].id,
            "streamId": stream.id
        }
      }).then(function (results) {
          console.log("StreamDocInfo with stream id : "+ stream.id +" created successfully.")
      }).fail(function (err){
          console.log(err);
      });
    } else{
      console.log("Mode not equal to 1 , so skipping it");
    }
  }

  removeSourceDocInfo(docInfo){
    console.log(docInfo);
    this.sourceDocInfoService.removeSelection(docInfo);
    this.orchestration.tagId = [];
  }

  removeTargetDocInfo(docInfo){
    console.log(docInfo);
    this.targetDocInfoService.removeSelection(docInfo);
    this.orchestration.targetDocumentInfo = "";
  }

  removeStreamDocInfo(stream, status){
    if(this.mode == 0){
      var index = this.orchestrationConfigsService.streamData.findIndex(x => x.id == stream.id);
      if(index != -1){
        this.orchestrationConfigsService.streamData.splice(index, 1);
      }
      if(status == 0){ //Called from orchestration Page. Status == 1 is called from Stream Table
        this.streamService.removeSelection(stream);
      }
    } else {
      console.log("Going to remove stream");
      const that = this;
      this.iotService.getCuiObjResponse().getStreamDocInfos({
        streamId: stream.id
      }).then(function(result){
        var id;
        result.forEach(strDocInfo => {
          if(strDocInfo.documentInfoId == that.orchestration.tagId[0].id){
            id = strDocInfo.id;
          }
        })
        if(id != undefined){
          that.iotService.getCuiObjResponse().deleteStreamDocInfo({
            streamId: stream.id,
            documentInfoId: id    //this is strDocInfo Id not documentInfoId
          }).then(function (results2) {
            console.log("Stream removed successfully");
            var index = that.orchestrationConfigsService.streamData.findIndex(x => x.id == stream.id);
            if(index != -1){
              that.orchestrationConfigsService.streamData.splice(index, 1);
            }
            if(status == 0){ //Called from orchestration Page. Status == 1 is called from Stream Table
              that.streamService.removeSelection(stream);
            }
          }).fail(function (err){
            console.log(err);
          });
        } else{
            var index = that.orchestrationConfigsService.streamData.findIndex(x => x.id == stream.id);
            if(index != -1){
              that.orchestrationConfigsService.streamData.splice(index, 1);
            }
            if(status == 0){ //Called from orchestration Page. Status == 1 is called from Stream Table
              that.streamService.removeSelection(stream);
            }
        }
      }).fail(function(err){
        console.log(err);
      });
    }
  }

  importFile(files: FileList){
    var reader = new FileReader();
    const that = this;
    reader.onload = function () {
      that.testInput = this.result;
    }
    reader.readAsText(files[0]);
  }

  test(){
    console.log("Going to Test the orchestration");
    if(this.mode != 1){
      return;
    }
    if(this.testInput != undefined && this.testInput.trim() != ""){
      const that = this;
      console.log("Calling test orchestration endpoint");
      this.testing = true;
      this.iotService.getCuiObjResponse().testOrchestration({
        data: {
               "id": this.orchestration.id,
               "version": "1.0",
               "creator": "integrationTester",
               "creatorApplicationId": "integrationTesterApp",
               "orchestrationId": this.orchestration.id,
               "payload": this.testInput,
               "messageId": "testOrchFromBuildUI",
               "orchestrationResponse": null,
               "creationInstant": 1461257635330
           }
      }).then( function(result){
          console.log("Test orchestration succesfull");
          that.testing = false;
          that.testResults = result;
          try {
              that.testResults._payload = atob(result.payload);
          } catch (err) {
              that.testResults._payload = result.payload;
          }
      }).fail( function(msg){
        console.log(msg);
        that.testing = false;
        that.saveErrorMsg = [];
        that.saveErrorMsg.push(msg.responseJSON.apiMessage);
      })
    }
  }

  back(){
    this.listVersion = false;
    this.createVersion = false;
    this.startNewTP = false
  }

  creteVersion(){
    this.listVersion = false;
    this.orchestrationConfigsService.createTask = false;
    this.createVersion = true;
  }

  listVersions(){
    this.createVersion = false;
    this.listVersion = true;
    this.startNewTP = false;
    this.orchestrationConfigsService.createTask = false;
    this.saveErrorMsg = [];
    const that = this;
    this.iotService.getCuiObjResponse().getOrchestrationVersionsList({
        configId: this.orchestration.id
    }).then(function (results2) {
        console.log('list version success');
        that.versionList = results2
        console.log(that.versionList);
        that.loadingVersionList = true;
    }).fail(function (msg) {
        console.log('list version failure');
        that.saveErrorMsg = [];
        that.saveErrorMsg.push(msg.responseJSON.apiMessage);
        that.loadingVersionList = true;
    });
  }

  saveVersion(){
    this.saveErrorMsg = [];
    this.orchestrationVersion.orchestrationConfigId = this.orchestration.id;
    if(this.orchestrationVersion.versionTag.length >255){
       this.saveErrorMsg.push('Version tag should not be greater than 255 characters');
    }
    if(this.orchestrationVersion.versionTag == ""){
      this.saveErrorMsg.push('Version tag is mandatory');
    }
    if(this.orchestrationVersion.versionComments.length >255){
      this.saveErrorMsg.push('Version comments should not be greater than 255 characters');
    }
    if(this.orchestrationVersion.versionComments == ""){
      this.saveErrorMsg.push('Version comments are mandatory');
    }

    if(this.saveErrorMsg.length > 0){
      return;
    }

    this.orchestrationVersion.creator = this.iotService.getCreator();
    this.orchestrationVersion.creatorAppId = this.iotService.getCreatorAppId();
    this.orchestrationVersion.realm = this.iotService.getRealm();

    const that = this;
    this.iotService.getCuiObjResponse().createOrchestrationVersion({
        configId: this.orchestration.id,
        tag:this.orchestrationVersion.versionTag,
        data: this.orchestrationVersion
    }).then(function (results2) {
        console.log('version success');
        window.location.reload();
    }).fail(function (msg) {
        console.log('version fail');
        that.saveErrorMsg.push(msg.responseJSON.apiMessage);
    });
  }

  applyVersion(version){
    const that = this;
    this.iotService.getCuiObjResponse().applyOrchestrationVersion({
        configId: this.orchestration.id,
        tag: version.versionTag,
        data: this.orchestration.id
    }).then(function (results2) {
        console.log('apply version success');
        window.location.reload();
    }).fail(function (msg) {
        console.log('apply version failure');
        that.saveErrorMsg = [];
        that.saveErrorMsg.push(msg.responseJSON.apiMessage);
    });
  }

  deleteVersion(version){
    const that = this;
    console.log("Going to delete version");
    this.iotService.getCuiObjResponse().deleteOrchestrationVersion({
        configId: this.orchestration.id,
        tag: version.versionTag,
        data: this.orchestration.id
    }).then(function (results2) {
        console.log('delete version success');
        that.successMessage = "Orchestration version deleted successfully.";
        window.location.reload();
    }).fail(function (msg) {
        console.log('delete version failure');
        that.saveErrorMsg = [];
        that.saveErrorMsg.push(msg.responseJSON.apiMessage);
    });
  }

  lockVersion(versionTag){
    const that = this;
    this.lockingVersion = true;
    this.iotService.getCuiObjResponse().lockOrchestrationVersion({
        configId: this.orchestration.id,
        tag: versionTag,
        data: this.orchestration.id
    }).then(function (results2) {
        console.log('lock version success');
        that.lockingVersion = false;
        window.location.reload();
    }).fail(function (msg) {
        console.log('lock version failure');
        that.lockingVersion = false;
        that.saveErrorMsg = [];
        that.saveErrorMsg.push(msg.responseJSON.apiMessage);
    });

  }
  unlockVersion(versionTag){
    const that = this;
    this.unlockingVersion = true;
    this.iotService.getCuiObjResponse().unlockOrchestrationVersion({
        configId: this.orchestration.id,
        tag: versionTag,
        data: this.orchestration.id
    }).then(function (results2) {
        console.log('unlock version success');
        that.unlockingVersion = false;
        window.location.reload();
    }).fail(function (msg) {
        console.log('unlock version failure');
        that.unlockingVersion = false;
        that.saveErrorMsg = [];
        that.saveErrorMsg.push(msg.responseJSON.apiMessage);
    });
  }

  newRelationship(event){
    this.startNewTP = true;
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  relationshipSelection(event){
    if(event.target.value == "1"){
      this.tpSelectedVal = "1";
    } else{
      this.tpSelectedVal = "2";
    }
  }

  saveTpRelationship(){
    if(this.orchestration.tagTpCodeRelationship.length <= 0){
      var index = this.orchestration.tagTpRelationship.findIndex(x => x.id == this.orchestrationConfigsService.getTradingPartnerRelationship().id);
      if(index == -1){
        var temp = {"id":this.orchestrationConfigsService.getTradingPartnerRelationship().id,
          "requestorTradingPartnerName": this.orchestrationConfigsService.getTradingPartnerRelationship().requestorTradingPartnerName[0].text,
          "approverTradingPartnerName": this.orchestrationConfigsService.getTradingPartnerRelationship().approverTradingPartnerName[0].text};
        this.orchestration.tagTpRelationship.push(temp);
        this.tradingPartners.removeSelection(this.orchestrationConfigsService.getTradingPartner());
        this.orchestrationConfigsService.setTradingPartner(undefined);
        this.tradingPartnerRelationship.removeSelection(this.orchestrationConfigsService.getTradingPartnerRelationship());
        this.orchestrationConfigsService.setTradingPartnerRelationship(undefined);
        this.back();
      } else {
          this.saveErrorMsg = [];
          this.saveErrorMsg.push("Relationship already added.");
      }
    } else {
      this.saveErrorMsg = [];
      this.saveErrorMsg.push("You can not add relationship for Trading Partner and Trading Partner Code both.");
    }
  }

  saveCodeRelationship(){
    if(this.orchestration.tagTpRelationship.length <= 0){
      var index = this.orchestration.tagTpCodeRelationship.findIndex(x => x.id == this.orchestrationConfigsService.getTpCodeRelationship().id);
      if(index == -1){
        var temp = {"id":this.orchestrationConfigsService.getTpCodeRelationship().id,
          "fromCode": this.orchestrationConfigsService.getTpCodeRelationship().fromCode,
          "toCode": this.orchestrationConfigsService.getTpCodeRelationship().toCode};
        this.orchestration.tagTpCodeRelationship.push(temp);
        this.tpCodes.removeSelection(this.orchestrationConfigsService.getTpCode());
        this.orchestrationConfigsService.setTpCode(undefined);
        this.tpCodeRelationship.removeSelection(this.orchestrationConfigsService.getTpCodeRelationship());
        this.orchestrationConfigsService.setTpCodeRelationship(undefined);
        this.back();
      } else {
        this.saveErrorMsg = [];
        this.saveErrorMsg.push("Relationship already added.");
      }

    } else {
      this.saveErrorMsg = [];
      this.saveErrorMsg.push("You can not add relationship for Trading Partner and Trading Partner Code both.");
    }
  }

  deleteTpRelationship(tpRelationship){
    var index = this.orchestration.tagTpRelationship.findIndex(x => x.id == tpRelationship.id);
    if(index != -1){
      this.orchestration.tagTpRelationship.splice(index, 1);
      this.orchestration.tagTpRelationship = [...this.orchestration.tagTpRelationship];
      console.log("Deleted TpRelationship");
    }
  }

  deleteCodeRelationship(codeRelationship){
    var index = this.orchestration.tagTpCodeRelationship.findIndex(x => x.id == codeRelationship.id);
    if(index != -1){
      this.orchestration.tagTpCodeRelationship.splice(index, 1);
      this.orchestration.tagTpCodeRelationship = [...this.orchestration.tagTpCodeRelationship];
      console.log("Deleted CodeTpRelationship");
    }
  }

  delete(){
    console.log("Going to delete orchestration");
    const that = this;
    this.iotService.getCuiObjResponse().deleteOrchestration({
        configId: this.orchestration.id
    })
    .then(function(response) {
        console.log("Orchestration deleted successfully");
        that.router.navigateByUrl("/orchestrationConfigs");
    })
    .fail(function(err) {
      console.log(err);
      that.saveErrorMsg = [];
      that.saveErrorMsg.push(err);
    });
  }

  hideRelatioshipTbl = false;
  toggleRelationshipTbl(){
    console.log(this.hideRelatioshipTbl);
    if(this.hideRelatioshipTbl){
      console.log("In If");
      this.hideRelatioshipTbl = false;
    } else{
      console.log("In Else");
      this.hideRelatioshipTbl = true;
    }
    console.log(this.hideRelatioshipTbl);
  }

  toggleTasksHide(section){
    var index = this.sections.findIndex(x => x.title == section.title);
    if(index != -1){
      if(section.hideTask){
        this.sections[index].hideTask = false;
      } else{
        this.sections[index].hideTask = true;
      }
    }
  }

  showOriginDocInfo = false;
  toggleOriginDocInfo(){
    if(this.showOriginDocInfo){
      this.showOriginDocInfo = false;
    } else{
      this.showOriginDocInfo = true;
    }
  }

  showTargetDocInfo = false;
  toggleTargetDocInfo(){
    if(this.showTargetDocInfo){
      this.showTargetDocInfo = false;
    } else{
      this.showTargetDocInfo = true;
    }
  }

  selectedStream;
  showStream(stream){
    if(this.selectedStream && this.selectedStream.id === stream.id){
      this.selectedStream = undefined;
    } else{
      this.selectedStream = stream;
    }
  }

  currentStreamIndex = 0;
  displayStream;
  prevStreamPage(){
    let currentStreamIndex = this.currentStreamIndex-1;
    if (currentStreamIndex < 0) { currentStreamIndex= 0; }
    this.currentStreamIndex = currentStreamIndex;
    var streamData = this.orchestrationConfigsService.getStreamData();
    this.displayStream = streamData[currentStreamIndex];
  }

  nextStreamPage(){
    let currentStreamIndex = this.currentStreamIndex+1;
    var streamData = this.orchestrationConfigsService.getStreamData();
    if (currentStreamIndex == streamData.length) { currentStreamIndex= 0; }
    console.log("nextStreamPage"+currentStreamIndex);
    this.currentStreamIndex = currentStreamIndex;
    this.displayStream = streamData[currentStreamIndex];
  }

  auditOrchestration(){
    this.auditFlag=true;
    this.auditRefreshFlag=!this.auditRefreshFlag;
  }

  closeAudit(){
    this.auditFlag=false;
  }

  goBackAuditList() {
    const that=this;
    that.iotService.auditScriptFlag = false;
    that.iotService.auditscript="";
    that.auditFlag = true;
  }

  timeout: any;
  getSearchResults(event){
    clearTimeout(this.timeout);
    const that = this;
    this.timeout = setTimeout(function () {
      if(event.target.value == ''){
        that.listVersions();
      } else {
        that.iotService.getCuiObjResponse().getOrchestrationVersion({
            configId: that.orchestration.id,
            tag: event.target.value
        }).then(function (results2) {
            console.log('list version success');
            that.versionList = [];
            that.versionList.push(results2);
            that.loadingVersionList = true;
        }).fail(function (msg) {
            console.log('list version failure');
            that.versionList = [];
            //that.saveErrorMsg = [];
            //that.saveErrorMsg.push(msg.responseJSON.apiMessage);
            that.loadingVersionList = true;
        });
      }
    }, 1000);
  }
}

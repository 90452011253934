import { Injectable } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { IotService } from '../service/iot.service';
import { ListEventsService } from './ListEvents.service';
import { EcosystemTemplatesService } from './ecosystemTemplates.service';
import { MessageGovernancesService } from '../service/messageGovernances.service';
import { I } from '@angular/cdk/keycodes';
import { RulesService } from '../service/rules.service';
import { EcosystemsService } from '../service/ecosystems.service';
import { DigitalTwinsService } from '../service/digitalTwins.service';
import { DigitalTwinTemplatesService } from '../service/digitalTwinTemplates.service';
import { DeviceService } from './device.service';

@Injectable({
  providedIn: 'root',
})
export class EventsService extends ListEventsService {
  selection: SelectionModel<String> = new SelectionModel<String>(true, []);

  constructor(
    public iotService: IotService,
    public ecosystemTemplateService: EcosystemTemplatesService,
    public messageGovernancesService: MessageGovernancesService,
    public ruleService: RulesService,
    public ecosystemTemplatesService:EcosystemTemplatesService,
    public ecosystemsService:EcosystemsService,
    public digitalTwinsService:DigitalTwinsService,
    public digitalTwinTemplatesService:DigitalTwinTemplatesService,
    public deviceService:DeviceService
  ) {
    super(iotService);
    this.selection.clear();
    this.loadSelected();
  }

  fnOnSelectionSupport(results) {
    const that = this;
    if (that.iotService.createEcosystemTemplate == true) {
      let events = that.ecosystemTemplateService.getEvents();
      events.push(results);
      that.ecosystemTemplateService.setEvents(events);
    }
    else if (this.iotService.createMessageGovernance == true) {
      let events = that.messageGovernancesService.getEvents();
      events.push(results);
      that.messageGovernancesService.setEvents(events);
    }
    else if (this.iotService.digitalTwinTemplate == true) {
      let events = that.digitalTwinTemplatesService.getEvents();
      events.push(results);
      that.digitalTwinTemplatesService.setEvents(events);
    }
    else if (this.iotService.newDeviceTemplate == true) {
      let events = that.digitalTwinTemplatesService.getEvents();
      events.push(results);
      that.digitalTwinTemplatesService.setEvents(events);
    }
    else if (that.iotService.createRule == true || that.iotService.viewRule == true) {
      that.ruleService.setEvents(results);
      that.ruleService.selectedEvent=true;
      that.ruleService.setDeviceTemplates([]);
      if (!that.ruleService.showGateway)
      {
        that.ruleService.setDevices([]);
        that.digitalTwinsService.selection.clear();
      }
      if(!that.iotService.viewRulePolicy){
      that.ruleService.setEcosystemtemplates([]);
      that.ruleService.setEcosystems([]);
      }
      that.digitalTwinTemplatesService.selection.clear();
      that.ecosystemsService.selection.clear();
      that.ecosystemTemplatesService.selection.clear();
      if (!that.iotService.createGatewayRule) {
        if(that.ruleService.reload){ 
          that.ruleService.reload=false;
        }else{
          that.ruleService.reload=true;
        }
      }
    }
  }

  fnRemoveSelectionSupport(results) {
    const that = this;
    if (that.iotService.createEcosystemTemplate == true) {
      let events = that.ecosystemTemplateService.getEvents();
      events.forEach((value, index) => {
        if (value.id == results.id) events.splice(index, 1);
      });
      that.ecosystemTemplateService.setEvents(events);
    }

    if (this.iotService.createMessageGovernance == true) {
      let events = that.messageGovernancesService.getEvents();
      events.forEach((value, index) => {
        if (value.id == results.id) events.splice(index, 1);
      });
      that.messageGovernancesService.setEvents(events);
    }

    if (this.iotService.digitalTwinTemplate == true) {
      let events = that.digitalTwinTemplatesService.getEvents();
      events.forEach((value, index) => {
        if (value.id == results.id) events.splice(index, 1);
      });
      that.digitalTwinTemplatesService.setEvents(events);
    }

    if (that.iotService.createRule == true || that.iotService.viewRule == true) {
      that.ruleService.setEvents(undefined);
      that.ruleService.setDeviceTemplates([]);
      if (!that.ruleService.showGateway)
      {
        that.ruleService.setDevices([]);
        that.digitalTwinsService.selection.clear();
      }
      if(!that.iotService.viewRulePolicy){
      that.ruleService.setEcosystemtemplates([]);
      that.ruleService.setEcosystems([]);
      }
      that.digitalTwinTemplatesService.selection.clear();
      that.ecosystemsService.selection.clear();
      that.ecosystemTemplatesService.selection.clear();
      that.ruleService.selectedEvent=false;
    }
  }

  createNew() {

    if (this.iotService.createEcosystemTemplate == true) {
      this.ecosystemTemplateService.createEvent = true;
    this.ecosystemTemplateService.createResource = false;
    }
    else if(this.iotService.newDeviceTemplate == true){
      this.deviceService.createResource=true;
      this.deviceService.createEvent=true;
     
    }
   
  }

  showErrorMessage(err){
    const that=this;
    if (err.responseJSON.apiStatusCode) {
 
      var errorStr=err.responseJSON.apiMessage;
        var error = [];
        error=errorStr.split(":");
      if(err.responseJSON.apiStatusCode=="framework:resource:eventTemplate:duplicate"){
        var eventId = error[1].trim();
      that.deviceService.duplicateEventId = eventId;
      that.deviceService.duplicateEventFlag = true;
      that.deviceService.duplicateCommandFlag = false;
      that.deviceService.duplicateAttrFlag = false;
      that.deviceService.warningMessage="Event template with same name is active. Click here to "
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      }
    } else{
     console.log("Something went wrong. Please check chrome console or check with dev team");
   }
  }

  loadSelected() {
    const that = this;
    that.selection.clear();
    if (
      that.messageGovernancesService.getEvents().length>0 &&
      that.iotService.msgGovLoader == true
    ) {
      this.messageGovernancesService.getEvents().forEach((events) => {
        this.selection.select(events.id);
      });
    }

    if(that.digitalTwinTemplatesService.getEvents().length>0){
      that.digitalTwinTemplatesService.getEvents().forEach((events) => {
        that.selection.select(events.id);
      });
    }

    if(that.digitalTwinsService.getEvents().length>0){
      that.digitalTwinsService.getEvents().forEach((events) => {
        that.selection.select(events.id);
      });
    }
    if (
      that.ecosystemTemplateService.getEvents() &&
      that.iotService.createEcosystemTemplate == true
    ) {
      this.ecosystemTemplateService.getEvents().forEach((event) => {
        this.selection.select(event.id);
      });
    }
    if(that.ruleService.getEvents()!=undefined && that.iotService.viewRule==true){
				that.selection.select(that.ruleService.events.id);
        that.ruleService.selectedEvent=true;
    }
  }
}

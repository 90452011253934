import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ImportExportService } from '../service/import-export.service';
import { IotService } from '../service/iot.service';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { faToggleOff, faToggleOn, faCheck, faExclamation, faExclamationTriangle, faTimes, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { GeofencesService } from '../service/geofences.service';
import { DeviceService } from '../service/device.service';

@Component({
  selector: 'app-new-geofence',
  templateUrl: './new-geofence.component.html',
  styleUrls: ['./new-geofence.component.scss']
})
export class NewGeofenceComponent implements OnInit, OnDestroy {

  faCheckIcon = faCheck;
  faToggleOffIcon = faToggleOff;
  faToggleOnIcon = faToggleOn;
  faCloseIcon = faTimes;
  faWarningIcon = faExclamationTriangle;
  faCriticalIcon = faExclamation;
  faSuccessIcon = faCheckCircle;

  supportLanguages = ['en'];
  


  constructor(public iotService: IotService, private translateService: TranslateService,
    private sideNavComponent: SideNavComponent, public geofenceService: GeofencesService,public deviceService:DeviceService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
    this.sideNavComponent.menuChange('Dashboard', 'sub-type');
    this.sideNavComponent.menuChange('geofences.header', 'sub-type');
      this.sideNavComponent.menuChange('Create New Geofence', 'sub-type');

  }
  ngOnDestroy(): void {
    this.iotService.createGeofence=false;
    this.geofenceService.errorMessage='';
  }


  ngOnInit(){
    this.iotService.createGeofence=true;
    this.geofenceService.mapLoad=false;
    this.geofenceService.errorMessage='';

  }

}





<header>
    <h2>{{ 'select_device_templates' | translate }}</h2>
</header>
<div class="control-bar">
    <input placeholder="search" type="text" (keyup)="onTemplateSearch($event)">
</div>

<div>
    <!-- <ngx-skeleton-loader 
    *ngIf="!dataSource" 
    count="5" 
    [theme]="{'border-radius': '0px',
      height: '48px',
      width: '100%',
      margin: '1px',
      position: 'relative',
      top: '20px'
    }"></ngx-skeleton-loader> -->
    <div style="margin: 0 15px 0 15px">
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows>

   <!-- Name Column -->
   <ng-container matColumnDef="Name">
    <th mat-header-cell *matHeaderCellDef> {{'name' | translate}} </th>
    <td mat-cell *matCellDef="let deviceTemplate" class="name-cell">
        <div class="cell-col">
            <a (click)="deviceTemplateMessage(deviceTemplate,expandedElement === deviceTemplate ? null : deviceTemplate);expandedElement = expandedElement === deviceTemplate ? null : deviceTemplate">{{deviceTemplate.name[0].text}}</a>
        </div>
    </td>
    </ng-container>

    <!-- Description Column -->
    <ng-container matColumnDef="Description">
        <th mat-header-cell *matHeaderCellDef> {{'description' | translate}} </th>
        <ng-container *matCellDef="let deviceTemplate" style="font-size: small;">
            <td class="name-cell" mat-cell *ngIf="deviceTemplate.description">{{deviceTemplate.description[0].text}}
            </td>
            <td class="name-cell" mat-cell *ngIf="!deviceTemplate.description">
            -
            </td>
        </ng-container>
    </ng-container>

    <!-- Enabled Column -->
    <ng-container matColumnDef="Enabled">
        <th mat-header-cell *matHeaderCellDef> {{ 'enabled' | translate}} </th>
        <ng-container *matCellDef="let deviceTemplate">

            <td mat-cell *ngIf="deviceTemplate.isActive" class="tag-cell">
                <div class="cell-col">
                    <button class="toggle-on toggle-switch" (click)="disableTemplate(deviceTemplate,deviceTemplate.id)" title="Disable {{deviceTemplate.name[0].text}}">
                        <fa-icon [icon]="faCheckIcon" class="fa-2x"></fa-icon>
                        <div class="toggle-on-btn"></div>
                    </button>
                </div>
            </td>
            <td mat-cell *ngIf="!deviceTemplate.isActive" class="tag-cell">
                <div class="cell-col">
                    <button class="toggle-off toggle-switch" (click)="enableTemplate(deviceTemplate.id)" title="Enable {{deviceTemplate.name[0].text}}">
                        <div class="toggle-off-btn"></div>
                    </button>
                </div>
            </td>
        </ng-container>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let deviceTemplate" [attr.colspan]="displayedColumns.length">
          <div class="message-element-detail" [@detailExpand]="deviceTemplate === expandedElement  ? 'expanded' : 'collapsed'">
            <div class="message-element-description" style= "width: 100%">
              <div>
                
                <table style= "width:45% ; float:left" *ngIf="deviceTemplate.eventTemplates" >
                    <p> Events </p>
                    <tbody>
                        <tr *ngFor="let event of deviceTemplate.eventTemplates">      
                            <td mat-cell>
                                <ng-container *ngIf="event.isActive">
                                    <div>
                                        <ng-container *ngIf="selection.isSelected(event)">
                                            <button class="selected btn btn-micro btn-secondary" (click)="OnEventSelection(false,deviceTemplate, event)"><fa-icon [icon]="faCheckIcon"></fa-icon></button>&nbsp;
                                        </ng-container>
                                        <ng-container *ngIf="!selection.isSelected(event)">
                                            <button class="select btn btn-micro btn-secondary" (click)="OnEventSelection(true,deviceTemplate, event)">Add</button>&nbsp;
                                        </ng-container>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="!event.isActive">
                                    <div>
                                        <ng-container *ngIf="selection.isSelected(event)">
                                            <button class="btn btn-micro btn-secondary" [disabled]="event.isActive"><fa-icon [icon]="faCheckIcon"></fa-icon></button>&nbsp;
                                        </ng-container>
                                        <ng-container *ngIf="!selection.isSelected(event)">
                                            <button class="btn btn-micro btn-secondary" [disabled]="!event.isActive">Add</button>&nbsp;
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </td>
                            <td >{{event.name}} </td>
                            <td *ngIf="event.isActive" class="tag-cell">
                                <div class="cell-col">
                                <button class="toggle-on toggle-switch" (click)="disableEventTemplate(event, event.id)"
                                    title="Disable {{event.name}}">
                                    <fa-icon [icon]="faCheckIcon" class="fa-2x"></fa-icon>
                                    <div class="toggle-on-btn"></div>
                                </button>
                            </div>
                        </td>
                        <td *ngIf="!event.isActive" class="tag-cell">
                            <div class="cell-col">
                                <button class="toggle-off toggle-switch" (click)="enableEventTemplate(event.id)" title="Enable {{event.name}}">
                                    <div class="toggle-off-btn"></div>
                                </button>
                            </div>
                        </td>
                        </tr>
                        <tr *ngIf="!deviceTemplate.eventTemplates">
                            <td>
                                -
                            </td>
                        </tr>
                    </tbody>
                </table>
                
                <table style= "width:45% ; float:left"  *ngIf="deviceTemplate.commandTemplates" >
                    <p> Commands </p>
                    <tbody>
                        <tr *ngFor="let command of deviceTemplate.commandTemplates">  
                            <td mat-cell>
                                <ng-container *ngIf="command.isActive">
                                    <div>
                                        <ng-container *ngIf="selection1.isSelected(command)">
                                            <button class="selected btn btn-micro btn-secondary" (click)="OnCommandSelection(false,deviceTemplate, command)"><fa-icon [icon]="faCheckIcon"></fa-icon></button>&nbsp;
                                        </ng-container>
                                        <ng-container *ngIf="!selection1.isSelected(command)">
                                            <button class="select btn btn-micro btn-secondary" (click)="OnCommandSelection(true,deviceTemplate, command)">Add</button>&nbsp;
                                        </ng-container>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="!command.isActive">
                                    <div>
                                        <ng-container *ngIf="selection1.isSelected(command)">
                                            <button class="btn btn-micro btn-secondary" [disabled]="command.isActive"><fa-icon [icon]="faCheckIcon"></fa-icon></button>&nbsp;
                                        </ng-container>
                                        <ng-container *ngIf="!selection1.isSelected(command)">
                                            <button class="btn btn-micro btn-secondary" [disabled]="!command.isActive">Add</button>&nbsp;
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </td>
                            <td>{{command.name}} </td>
                            <td *ngIf="command.isActive" class="tag-cell">
                                <div class="cell-col">
                                    <button class="toggle-on toggle-switch" (click)="disableCommandTemplate(command, command.id)"
                                        title="Disable {{command.name}}">
                                        <fa-icon [icon]="faCheckIcon" class="fa-2x"></fa-icon>
                                        <div class="toggle-on-btn"></div>
                                    </button>
                                </div>
                            </td>
                            <td *ngIf="!command.isActive" class="tag-cell">
                                <div class="cell-col">
                                    <button class="toggle-off toggle-switch" (click)="enableCommandTemplate(command.id)" title="Enable {{command.name}}">
                                        <div class="toggle-off-btn"></div>
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="!deviceTemplate.commandTemplates">
                            <td>
                                -
                            </td>
                        </tr>
                    </tbody>
                </table>
              </div>
            </div>
          </div>
        </td>
      </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let deviceTemplate; columns: displayedColumns;" [ngClass]="deviceTemplate.messageClass"
        [class.message-expanded-row]="expandedElement === deviceTemplate"></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="message-detail-row"></tr>
    <!-- !row.excluded will prevent those rows from being clicked -->


</table>
</div>
<!-- OT Pagination component -->
<div style="margin: 0 15px 0 15px">
<app-pagination  *ngIf="showPagination" [count]="templateCount" [pageSize]="pageSize" [currentPage]="currentPage" 
    (currentPageChange)="onPageChange($event)"></app-pagination>
</div>
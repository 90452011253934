<main id="device-details">
  <div class="d-flex justify-content-center loader" *ngIf="!device">
    <div class="spinner-border" role="status">
      <span class="sr-only" id="loading"></span>
    </div>
  </div>

  <mat-sidenav-container autosize *ngIf="device" class="example-sidenav-content">

   
    <mat-sidenav class="midNav-container" #sidenav mode="side" disableClose="true" opened="true">
      <div class="alert success" *ngIf="deviceService.successMessage">
        <h5>
          <fa-icon [icon]="faSuccessIcon"></fa-icon>
          <span>{{deviceService.successMessage}}</span> <button class="alert-close-btn">
            <fa-icon (click)=deviceService.resetSuccessMessage() [icon]="faCloseIcon"></fa-icon>
          </button>
        </h5>
      </div>
      <div class="alert critical" *ngIf="streamService.errorMessage">
        <h5>
            <fa-icon [icon]="faCriticalIcon"></fa-icon>
            <span>{{streamService.errorMessage}}</span>
            <button class="alert-close-btn">
                <fa-icon (click)=streamService.resetErrorMessage() [icon]="faCloseIcon"></fa-icon>
            </button>
        </h5>
  
    </div>
    <div class="alert critical" *ngIf="deviceService.errorMessage">
      <h5>
          <fa-icon [icon]="faCriticalIcon"></fa-icon>
          <span>{{deviceService.errorMessage}}</span>
          <button class="alert-close-btn"><fa-icon (click)=deviceService.resetErrorMessage() [icon]="faCloseIcon"></fa-icon></button>
      </h5>
  </div>

      <section class="left-col">
        <app-copy-resource-id></app-copy-resource-id>
        <dl>
          <dt>{{'parent.template' | translate}}</dt>
          <dd>
            <a (click)=viewDeviceTemplate(device.parentDeviceTemplateId) title="View Template Details">{{device.parentDeviceTemplateName}}</a>
          </dd>
        </dl>
        <dl>
          <dt>{{'created' | translate}}</dt>
          <dd>{{device.creation | date:'mediumDate' }}</dd>
        </dl>
        <hr>
        <dl class="pull-left">
          <dt>{{'enabled' | translate}}{{'?' | translate}}</dt>
          <dd>
            <button *ngIf="device.isActive" class="toggle-on toggle-switch" (click)="toggleIcon(device.id)">
              <fa-icon [icon]="faCheckIcon" class="fa-2x"></fa-icon>
              <div class="toggle-on-btn"></div>
            </button>
            <button *ngIf="!device.isActive" class="toggle-off toggle-switch" (click)="toggleIcon(device.id)">
              <div class="toggle-off-btn"></div>
            </button>
          </dd>
        </dl>
        <dl class="pull-right">
          <dt>{{'presence.state' | translate}}</dt>
          <dd class="text-orange">{{device.state.operationalState}}</dd>
        </dl>

        <figure>
          <div style="margin-left: 10px;">
          <figcaption style="margin-left: 10px;">{{'lifecycle' | translate}}</figcaption>
          <ng-container *ngIf="device.state.lifecycleState != 'locked'">
            <mat-grid-list [cols]="breakpoint" rowHeight="4:1" [gutterSize]="0">
              <mat-grid-tile [ngClass]="statusPendingClass">
                <p>{{'pending' | translate}}</p>
              </mat-grid-tile>  
              <mat-grid-tile [ngClass]="statusActiveClass">
                <p>{{'active' | translate}}</p>
              </mat-grid-tile>
              <mat-grid-tile [ngClass]="statusSuspendedClass">		
                <p>{{'suspended' | translate}}</p>					
              </mat-grid-tile>
              <mat-grid-tile [ngClass]="statusDeletedClass">
                <p>{{'deleted' | translate}}</p>
              </mat-grid-tile>
            </mat-grid-list>
          </ng-container>
          <ng-container *ngIf="device.state.lifecycleState == 'locked'">
            <mat-grid-list [cols]="4" rowHeight="4:1" [gutterSize]="0">
              <mat-grid-tile class="bg-lifecycle-selected">
                <p>{{'locked' | translate}}</p>
              </mat-grid-tile>
            </mat-grid-list>
          </ng-container>
        </div>
        <div style="margin-left: 6px;">
          <ng-container *ngIf="device.state.lifecycleState == 'pending activation'">
            <p>{{'register.device' | translate}}</p>
            <button class="btn btn-secondary margin-left-15" [disabled]="!device.isActive" (click)="registerDevice(device.id)" type="submit">{{'register' | translate}}</button>
          </ng-container>
          <ng-container *ngIf="device.state.lifecycleState == 'active'">
            <p>{{'suspend.device' | translate}}</p>
            <button class="btn btn-secondary margin-left-15" [disabled]="!device.isActive" (click)="suspendDevice(device.id)" type="submit">{{'suspend' | translate}}</button>
          </ng-container>

          <ng-container *ngIf="device.state.lifecycleState == 'suspended'">
            <p>{{'unsuspend.device' | translate}}</p>
            <button class="btn btn-secondary margin-left-15" [disabled]="!device.isActive" (click)="unsuspendDevice(device.id)" type="submit">{{'unsuspend' | translate}}</button>
            &nbsp;<button class="btn btn-secondary" [disabled]="!device.isActive" (click)="deleteDevice(device.id)" type="submit">{{'delete' | translate}}</button>
          </ng-container>

          <ng-container *ngIf="device.state.lifecycleState == 'deleted'">
            <p>{{'reset.device' | translate}}</p>
            <button class="btn btn-secondary margin-left-15" [disabled]="!device.isActive" (click)="resetDevice(device.id)" type="submit">{{'reset' | translate}}</button>
          </ng-container>

          <ng-container *ngIf="device.state.lifecycleState == 'locked'">
            <p>{{'unlock.device' | translate}}</p>
            <button class="btn btn-secondary margin-left-15" [disabled]="!device.isActive" (click)="unlockDevice(device.id)" type="submit">{{'unlock.device.details' | translate}}</button>
          </ng-container>

          <ng-container>
           <button class="btn btn-secondary margin-left-15"
              (click)="auditDevice()">{{ 'audit' |
              translate}}</button>   
          </ng-container>
         </div> 
        </figure>
      </section>
      <div>
        <hr>
        <a style="font-size: 14px; margin: 15px;" (click)="deviceDetail()">View device dashboard</a>
      </div>
     

      <aside>
        <hr>
        <p>{{'streams.header' | translate}}</p>
        <ng-container *ngIf="device.state.lifecycleState != 'active'">
          <p>{{'device.active.desc' | translate}}</p>
        </ng-container>
        <ng-container *ngIf="!deviceStream && device.state.lifecycleState == 'active'">
          <p>{{'device.activate.desc' | translate}}</p>
          <p>{{'stream.connections.desc' | translate}}</p>
        </ng-container>
        <ng-container *ngIf="deviceStream && device.state.lifecycleState == 'active'">
          <dl>
            <dt>{{'stream.name' | translate}}</dt>
            <dd><a title="View Stream Details" (click)="viewStream(deviceStream.id)">{{deviceStream.name[0].text}}</a></dd>
          </dl>

          <dl>
            <dt>{{'protocol.type' | translate}}</dt>
            <dd>{{deviceStream.protocolType}}</dd>
          </dl>

          <dl>
            <dt>{{'protocol.security.type' | translate}}</dt>
            <dd>{{deviceStream.protocolSecurityType}}</dd>
          </dl>

          <dl>
            <dt>{{'encryption' | translate}}</dt>
            <dd>{{deviceStream.payloadSecurityType}}</dd>
          </dl>

          <dl>
            <dt>{{'producer.topic' | translate}}</dt>
            <dd>
              <ng-container *ngIf="deviceStream.consumerTopic">{{deviceStream.producerTopic}}</ng-container>
              <ng-container *ngIf="!deviceStream.consumerTopic">-</ng-container>
            </dd>
          </dl>

          <dl>
            <dt>{{'consumer.topic' | translate}}</dt>
            <dd>
              <ng-container *ngIf="deviceStream.consumerTopic">{{deviceStream.consumerTopic}}</ng-container>
              <ng-container *ngIf="!deviceStream.consumerTopic">-</ng-container>
            </dd>
          </dl>

          <nav class="connectionDevice">
            <a class="pull-left" (click)="prevStreamPage();" ng-disabled="1 == 2">
              <fa-icon [icon]="faAngleLeftIcon"></fa-icon>
            </a>
            <span>{{'stream' | translate}} {{currentDeviceStream+1}} {{'of' | translate}} {{streamDeviceResponse.length}}</span>
            <a class="pull-right" (click)="nextStreamPage();" ng-disabled="1 == 2">
              <fa-icon [icon]="faAngleRightIcon"></fa-icon>
            </a>
          </nav>
        </ng-container>
      </aside>
    </mat-sidenav>
    
    <article>
      <div *ngIf="!auditFlag">
      <form>
        <div style="margin-left: -4px;">
        <label for="deviceName">{{'name' | translate}}</label>
        <input [(ngModel)]="device.name[0].text" name="deviceName" type="text" value={{device.name[0].text}} (keyup)="enableUpdate($event)">
  
        <label for="device-description">{{'description' | translate}}</label>
        <textarea name="device-description" [(ngModel)]="device.description[0].text" type="text" value={{device.description[0].text}} (keyup)="enableUpdate($event)"></textarea>
        
        <button class="btn btn-secondary pull-right" [disabled]="updateNameDesc" (click)="updateDeviceAttribute()" type='submit'>{{'update' | translate}}</button>

        <label for="device-tags">{{'tags' | translate}}</label>
        
        <mat-chip-list #tagList aria-label="tags">
          <mat-chip class="ot-chip" *ngFor="let tag of device.tags" [selectable]="selectable" [removable]=true (removed)="remove(tag)">
            {{tag}}
            <button class="ot-chip__close" matChipRemove></button>
          </mat-chip>
          <input [ngClass]="tagClass" placeholder="add a tag" #tagInput [matChipInputFor]="tagList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="add($event)">
        </mat-chip-list>
      </div>
      </form>
      
      <!-- <hr> -->
      <ng-container *ngIf="!iotService.deviceStream">
      <ng-container *ngIf="(device.attributes | json) != '{}'">
        <header class="table-header">
          <h2>{{'attributes' | translate}}</h2>
        </header>

        <div class="table-container">
          <table mat-table [dataSource]="device.attributes.standard">

            <!-- Name Column -->
            <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef style="width: 30%;"> {{'name' | translate}} </th>     
              <td mat-cell *matCellDef="let attribute"> {{attribute.attributeType.name}} </td>
            </ng-container>
          
            <!-- Data Type Column -->
            <ng-container matColumnDef="Data Type">
            <th mat-header-cell *matHeaderCellDef style="width: 20%;"> {{'data.type' | translate}} </th>
            <td mat-cell *matCellDef="let attribute"> {{attribute.attributeType.type}} </td>
            </ng-container>
          
            <!-- Value Column -->
            <ng-container matColumnDef="Value">
            <th mat-header-cell *matHeaderCellDef style="width: 5%;"> {{'value' | translate}} </th>
            <td mat-cell *matCellDef="let attribute">  
              <div *ngIf="attribute.attributeType.type === 'bool'" ng-init="attribute.value">
                <label><input [(ngModel)]="attribute.value" (click)="enableAttributeUpdate($event)" id="radio-boolTrue" ng-value="true" [value]="true" name="boolValue" type="radio">{{'true' | translate}}</label>
                <label><input [(ngModel)]="attribute.value" (click)="enableAttributeUpdate($event)" id="radio-boolFalse" ng-value="false" [value]="false" name="boolValue" type="radio"> {{'false' | translate}}</label>
              </div>
              <div *ngIf="attribute.attributeType.type != 'bool'">
                <input [(ngModel)]="attribute.value" (keyup)="enableAttributeUpdate($event)" name="attributeValue" type="text" style="width: 100%" value={{attribute.value}}>
              </div>                    
            </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="attributeColumns"></tr>
            <tr mat-row *matRowDef="let attribute; columns: attributeColumns;" class="bg-message"></tr>
          </table>
          <button class="btn btn-secondary pull-right" [disabled]="updateAttribute" (click)="updateDeviceAttribute()" type='submit'>{{'update' | translate}}</button>
        </div>
      </ng-container>
      
      <ng-container *ngIf="eventData.length == eventLength && eventData.length>0">
        <header class="table-header">
          <h2>{{'events' | translate}}</h2>
        </header>

        <div class="table-container">
          <table mat-table [dataSource]="eventData" multiTemplateDataRows>
            
            <!-- Name Column -->
            <ng-container matColumnDef="Name">
              <th mat-header-cell *matHeaderCellDef>{{'name' | translate}}</th>
              <td mat-cell *matCellDef="let event">
                <a (click)="eventMessage(event,expandedElement === event ? null : event);expandedElement = expandedElement === event ? null : event">
                {{event.eventTemplate.name}}
               </a> 
            </td>
            </ng-container>
        
            <!-- Description Column -->
            <ng-container matColumnDef="Description">
              <th mat-header-cell *matHeaderCellDef>{{'description' | translate}}</th>
              <td mat-cell *matCellDef="let event">
                <a (click)="eventMessage(event,expandedElement === event ? null : event);expandedElement = expandedElement === event ? null : event">
                <ng-container *ngFor="let eventField of event.eventTemplate.eventFields">
                  <ng-container *ngIf="!eventField.description">-</ng-container>
                  <ng-container *ngIf="eventField.description">{{eventField.description[0].text}}</ng-container>  
                </ng-container>
                 </a> 
              </td>
            </ng-container>
  
            <!-- Data Point Column -->
            <ng-container matColumnDef="Data Point">
              <th mat-header-cell *matHeaderCellDef>{{'data.points' | translate}}</th>     
              <td mat-cell *matCellDef="let event">
                <a (click)="eventMessage(event,expandedElement === event ? null : event);expandedElement = expandedElement === event ? null : event">
                <ng-container *ngFor="let eventField of event.eventTemplate.eventFields">{{eventField.name}}<br/>
                </ng-container> 
                  </a>
              </td>
            </ng-container>
          
            <!-- Data Type Column -->
            <ng-container matColumnDef="Data Type">
              <th mat-header-cell *matHeaderCellDef>{{'data.type' | translate}}</th>
              <td mat-cell *matCellDef="let event">  
                <ng-container *ngFor="let eventField of event.eventTemplate.eventFields">{{eventField.type}}<br/></ng-container>
              </td>
            </ng-container>
  
            <!-- Last Hour Column -->
            <ng-container matColumnDef="Last Hour">
              <th mat-header-cell *matHeaderCellDef>{{'last.hour' | translate}}</th>
              <td mat-cell *matCellDef="let event">{{event.eventCount1}}</td>
            </ng-container>
  
            <!-- Last 24 Hours Column -->
            <ng-container matColumnDef="Last 24 Hours">
              <th mat-header-cell *matHeaderCellDef>{{'last.twentyfour.hours' | translate}}  </th>
              <td mat-cell *matCellDef="let event">{{event.eventCount2}}</td>
            </ng-container>
  
            <!-- Last Week -->
            <ng-container matColumnDef="Last Week">
              <th mat-header-cell *matHeaderCellDef> {{'last.week | translate'}} </th>
              <td mat-cell *matCellDef="let event">{{event.eventCount3}}</td>
            </ng-container>
  
            <!-- more containers -->
            <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let event" [attr.colspan]="eventColumns.length">
                <div class="message-element-detail" [@detailExpand]="event === expandedElement  ? 'expanded' : 'collapsed'">
                  <div class="message-element-description">
                    <div>
                      <pre>{{base64String}}</pre>
                    </div>
                    <div class="message-element-close">
                      <button [cdkCopyToClipboard]="base64String"><fa-icon [icon]="faCopyIcon"></fa-icon> {{'cdkCopyToClipboard' | translate}} </button>&nbsp;
                      <button (click)="eventMessage(event,expandedElement === event ? null : event);expandedElement = expandedElement === event ? null : event">{{'close' | translate}}</button>
                    </div>
                  </div>
                </div>
              </td>
            </ng-container>
  
              <tr mat-header-row *matHeaderRowDef="eventColumns"></tr>
              <tr mat-row *matRowDef="let event; columns: eventColumns;" [ngClass]="event.messageClass"
              [class.message-expanded-row]="expandedElement === event"></tr>
              <!-- Extra row to show detail content column -->
              <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="message-detail-row"></tr>
          </table>
        </div>
      </ng-container>

      <ng-container *ngIf="commandData.length == commandLength && commandData.length>0">
        <header class="table-header">
          <h2>{{'commands' | translate}}</h2>
        </header>
        <div class="table-container">
          <table mat-table [dataSource]="commandData" multiTemplateDataRows>

            <!-- Name Column -->
            <ng-container matColumnDef="Name">
              <th mat-header-cell *matHeaderCellDef> {{'name' | translate}} </th>
              <td mat-cell *matCellDef="let command">
                <ng-container *ngIf="command.commandTemplate.name">{{command.commandTemplate.name}}</ng-container>  
                <ng-container *ngIf="!command.commandTemplate.name">-</ng-container>  
              </td>
            </ng-container>
  
            <!-- Description Column -->
            <ng-container matColumnDef="Description">
              <th mat-header-cell *matHeaderCellDef> {{'description' | translate}} </th>
              <td mat-cell *matCellDef="let command">
                <ng-container *ngIf="command.commandTemplate.description">{{command.commandTemplate.description[0].text}}</ng-container>  
                <ng-container *ngIf="!command.commandTemplate.description">-</ng-container>  
              </td>
            </ng-container>
  
            <!-- Data Point Column -->
            <ng-container matColumnDef="Arguments">
              <th mat-header-cell *matHeaderCellDef> {{'arguments' | translate}} </th>    
              <td mat-cell *matCellDef="let command">
                <a (click)="commandMessage(command,expandedCommand === command ? null : command);expandedCommand = expandedCommand === command ? null : command">
                  <ng-container *ngFor="let arg of command.commandTemplate.args">
                      {{arg.name}}<br/>
                  </ng-container>
                </a>
              </td>
            </ng-container>
            
            <!-- Last Hour Column -->
            <ng-container matColumnDef="Last Hour">
              <th mat-header-cell *matHeaderCellDef> {{'last.hour' | translate}} </th>
              <td mat-cell *matCellDef="let command"> {{command.cmdCount1}} </td>
            </ng-container>
  
            <!-- Last 24 Hours Column -->
            <ng-container matColumnDef="Last 24 Hours">
              <th mat-header-cell *matHeaderCellDef> {{'last.twentyfour.hours' | translate}} </th>
              <td mat-cell *matCellDef="let command"> {{command.cmdCount2}} </td>
            </ng-container>
  
            <!-- Last Week -->
            <ng-container matColumnDef="Last Week">
              <th mat-header-cell *matHeaderCellDef> {{'last.week' | translate}} </th>
              <td mat-cell *matCellDef="let command"> {{command.cmdCount3}} </td>
            </ng-container>
  
            <!-- more containers -->
            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedCommand">
              <td mat-cell *matCellDef="let command" [attr.colspan]="commandColumns.length">
                <div class="message-element-detail" [@detailExpand]="command === expandedCommand  ? 'expanded' : 'collapsed'">
                  <div class="message-element-description">
                    <div>
                      <pre>{{base64String}}</pre>
                    </div>
                    <div class="message-element-close">
                      <button [cdkCopyToClipboard]="base64String"><fa-icon [icon]="faCopyIcon"></fa-icon> {{'cdkCopyToClipboard' | translate}} </button>&nbsp;
                      <button (click)="commandMessage(command,expandedCommand === command ? null : command);expandedCommand = expandedCommand === command ? null : command">{{'close' | translate}}</button>
                    </div>
                  </div>
                </div>
              </td>
            </ng-container>
  
            <tr mat-header-row *matHeaderRowDef="commandColumns"></tr>
            <tr mat-row *matRowDef="let command; columns: commandColumns;" [ngClass]="command.messageClass"
            [class.message-expanded-row]="expandedCommand === command"></tr>
            <!-- Extra row to show detail content column -->
            <tr mat-row *matRowDef="let row; columns: ['expandedCommand']" class="message-detail-row"></tr>
          </table>
        </div>
      </ng-container>
      
      <!-- <ng-container *ngIf="geofenceData.length != 0">
        <header class="table-header">
          <h2>{{'geofences' | translate}}</h2>
        </header>
        <div class="table-container">
          <table mat-table [dataSource]="geofenceData">

            <!-- Name Column -->
            <!-- <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef> {{'name' | translate}} </th>     
              <td mat-cell *matCellDef="let geoDevice"> {{geoDevice.name}} </td>
            </ng-container> -->
        
            <!-- Device Status Column -->
            <!-- <ng-container matColumnDef="Device Status">
            <th mat-header-cell *matHeaderCellDef> {{'device.status' | translate}} </th>
            <td mat-cell *matCellDef="let geoDevice">
              <ng-container *ngIf="geoDevice.state">{{geoDevice.state}}</ng-container>  
              <ng-container *ngIf="!geoDevice.state">-</ng-container>     
            </td>
            </ng-container>
  
            <tr mat-header-row *matHeaderRowDef="geofenceColumns"></tr>
            <tr mat-row *matRowDef="let geoDevice; columns: geofenceColumns;" class="bg-message"></tr>
          </table>
        </div>
      </ng-container> -->
    
      <ng-container>  
        <!-- {{geofencesLoaded}} -->
        <app-geofences *ngIf="geofencesLoaded" [pageSize]="5" [showSelectColoumn]="true"
        [showControlBar]=true [showNew]="false" multiSelect="true"
        [displayedColumns]="['ADD', 'name', 'description','creation', 'ACTIVE']" [showHeaderToolTips]=false></app-geofences>
      </ng-container>
      
      <ng-container *ngIf="device.state.lifecycleState == 'active' && streamData.length != 0">
        <header class="table-header">
          <h2>{{'streams.header' | translate}}</h2>
        </header>
        <div class="control-bar">
          <input placeholder="search connections" type="text" (keyup)="onStreamSearch($event)">
          <button class="btn btn-micro btn-primary" (click)="createStreamForDevice()" aria-label="Create new stream">{{'new' | translate}}</button>
        </div>
        <div class="table-container">
          <table mat-table [dataSource]="streamData">

            <!-- Add Column -->
            <ng-container matColumnDef="ADD">
            <th mat-header-cell *matHeaderCellDef style="width: 10%;"> {{'add' | translate}}</th>     
              <td mat-cell *matCellDef="let stream" style="width: 10%;"> 
                <ng-container *ngIf="stream.streamState === 'INACTIVE'">
                  <ng-container *ngIf="stream.isAdded === 'Add'">
                    <button class="btn btn-micro btn-secondary" [disabled]=true type="submit">{{stream.isAdded}}</button>
                  </ng-container>
                  <ng-container *ngIf="stream.isAdded === 'Added'">
                    <button class="btn btn-micro btn-secondary" [disabled]=true type="submit"><fa-icon [icon]="faCheckIcon" ></fa-icon></button>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="stream.streamState === 'ACTIVE'">
                  <ng-container *ngIf="stream.isAdded === 'Added'">
                    <button class="btn btn-micro btn-secondary" (click)="deSelectStream(stream)"><fa-icon [icon]="faCheckIcon"></fa-icon></button>
                  </ng-container>
                  <ng-container *ngIf="stream.isAdded === 'Add'">
                    <button class="btn btn-micro btn-secondary" (click)="selectStream(stream)" type="submit">{{stream.isAdded}}</button>
                  </ng-container>
                </ng-container>
              </td>
            </ng-container>
        
            <!-- Name Column -->
            <ng-container matColumnDef="Name">
              <th mat-header-cell *matHeaderCellDef style="width: 30%;"> {{'name' | translate}} </th>
              <td mat-cell style="width: 10%;" *matCellDef="let stream">
              {{stream.name[0].text}}
              </td> 
            </ng-container> 
  
            <!-- Protocol Column -->
            <ng-container matColumnDef="Protocol" >
              <th mat-header-cell *matHeaderCellDef style="width: 10%;"> {{'protocol' | translate}} </th>
              <td mat-cell style="width: 10%;" *matCellDef="let stream"> {{stream.protocolType}} </td>
            </ng-container>
  
            <!-- Security Column -->
            <ng-container matColumnDef="Security">
              <th mat-header-cell *matHeaderCellDef style="width: 10%;"> {{'security' | translate}} </th>
              <td mat-cell *matCellDef="let stream" style="width: 10%;"> {{stream.protocolSecurityType}} </td>
            </ng-container>
  
            <!-- Direction Column -->
            <ng-container matColumnDef="Direction">
              <th mat-header-cell *matHeaderCellDef style="width: 15%;"> {{'direction' | translate}} </th>
              <td mat-cell *matCellDef="let stream" style="width: 15%;"> {{stream.direction}} </td>
            </ng-container>
  
            <!-- Enabled Column -->
            <ng-container matColumnDef="Enabled">
              <th mat-header-cell *matHeaderCellDef style="width: 10%;"> {{'enabled' | translate}} </th>
              <ng-container *matCellDef="let stream">
                <td mat-cell style="width: 10%;" *ngIf="stream.streamState == 'ACTIVE'"> 
                <button class="toggle-on toggle-switch" (click)="disableStream(stream,stream.id)" title="Disable {{stream.name[0].text}}">
                  <fa-icon [icon]="faCheckIcon" class="fa-2x"></fa-icon>
                  <div class="toggle-on-btn"></div>
                </button>    
                </td>
                <td mat-cell style="width: 10%;" *ngIf="stream.streamState != 'ACTIVE'">
                <button class="toggle-off toggle-switch" (click)="enableStream(stream,stream.id)" title="Enable {{stream.name[0].text}}">
                  <div class="toggle-off-btn"></div>
                </button>
                </td>
              </ng-container>
            </ng-container>
  
            <tr mat-header-row *matHeaderRowDef="streamColumns"></tr>
            <tr mat-row *matRowDef="let stream; columns: streamColumns;" class="bg-message"></tr>
          </table>
          <!-- OT CSS Pagination -->
          <app-pagination *ngIf="showPagination" [count]="streamCount" [pageSize]="pageSize" [pageSizeOptions]="true" [currentPage]="currentPage" (currentPageChange)="onPageChange($event)"></app-pagination>
        </div>

      </ng-container>
    </ng-container>
    <ng-container *ngIf="iotService.deviceStream">
      <app-create-stream></app-create-stream>

    </ng-container>
      <ng-container *ngIf="device.state.lifecycleState != 'active'">
        <div style="height: 30vh;">
          <br/>
        </div>
      </ng-container>
    </div>
    </article>
        <!--Added for Auit Integration-->
        <div style="height: 110vh;" >
                 
          <app-audits *ngIf="auditFlag && auditRefreshFlag && iotService.auditScriptFlag==false" [showNew]="false" [showControlBar]=false [displayedColumns]="['creation','requestorId','actionType','id']" showSelectColoumn=false showEnableColumn=false addnQueryParam=[resourceId::{{device.id}}] ></app-audits>
          <app-audits *ngIf="auditFlag && !auditRefreshFlag && iotService.auditScriptFlag==false" [showNew]="false" [showControlBar]=false [displayedColumns]="['creation','requestorId','actionType','id']" showSelectColoumn=false showEnableColumn=false addnQueryParam=[resourceId::{{device.id}}] ></app-audits>
          <div *ngIf="auditFlag && iotService.auditScriptFlag==false">
            <br>
    
            <button class="btn btn-secondary pull-right" style="margin-right:15px;" (click)="closeAudit()">{{ 'go.back' | translate}}</button>
            <br>
            <br>
        </div>
            <br>
            <div  *ngIf="iotService.auditScriptFlag" style="margin: 0 15px 0 15px;">      
                <app-ace></app-ace>
           </div>
           <div *ngIf="iotService.auditScriptFlag" class="deviceAudit" style="margin-top: 10px;margin-right: 15px;">
            <button class="btn btn-secondary pull-right" (click)="goBackAuditList()">{{ 'go.back' | translate}}</button>
            <br>
            <br>
        </div>
        </div>
        <br>
        <br>
  </mat-sidenav-container>
</main>

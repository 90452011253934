import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IotService } from '../service/iot.service';
import { SecurtiyService } from '../service/security.service';
import { SideNavComponent } from '../side-nav/side-nav.component';

@Component({
  selector: 'app-ssh-keys',
  templateUrl: './ssh-keys.component.html',
  styleUrls: ['./ssh-keys.component.scss']
})
export class SshKeysComponent implements OnInit {

  supportLanguages = ['en'];

  constructor(private translateService: TranslateService,public  securityService: SecurtiyService, private sideNavComponent: SideNavComponent) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
      this.sideNavComponent.menuClear();
      this.sideNavComponent.menuChange('Dashboard','sub-type');
      this.sideNavComponent.menuChange('sshKeys.header','sub-type');
  }



  ngOnInit(): void {
    this.securityService.showSSHKeyPairs=true;
    this.securityService.showTrustedPublicKeys = false;
  }

  tabClick(tab) {
		console.log(tab.index);
		if (tab.index == 0) {
			console.log("sshkeypair");
			this.securityService.showSSHKeyPairs = true;
			this.securityService.showTrustedPublicKeys = false;
		}
		else if (tab.index == 1) {
			console.log("trusted public key");
			this.securityService.showSSHKeyPairs = false;
			this.securityService.showTrustedPublicKeys = true;
		}
	}
}

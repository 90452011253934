<div class="adaptor-data">

    <div class="form-data">
        <label for="name"> {{ 'name' | translate}}</label>
        <input placeholder="required" id="name" type="text" aria-label="Name" [(ngModel)]="adaptorInfo.name">
        <div *ngIf="adaptorInfo.name">
            <label>{{'announcements.topic' | translate}}
                <p> {{adaptorInfo.name}}{{'announcement' | translate}}</p>
            </label>
            <label>{{'signals.topic' | translate}}
                <p>{{adaptorInfo.name}}{{'signal' | translate}}</p>
            </label>
            <label>{{'services.topic' | translate}}
                <p>{{adaptorInfo.name}}{{'service' | translate}}</p>
            </label>
        </div>
        <label for="description"> {{ 'description' | translate}}</label>
        <input placeholder="required" id="description" type="text" aria-label="description"
            [(ngModel)]="adaptorInfo.description[0].text"><br>

        <label for="download uRL:"> {{ 'download.uRL' | translate}}</label>
        <input placeholder="required" id="Download URL" type="text" aria-label="Name"
            [(ngModel)]="adaptorInfo.location.url"><br>

        <label for="sHA1 checksum on adaptor file:"> {{ 'sha1.checksum.on.adaptor.file' | translate}}</label>
        <input placeholder="required" id="SHA1 checksum on Adaptor File" type="text" aria-label="Name"
            [(ngModel)]="adaptorInfo.location.sha1"><br>

        <label for="mD5 checksum on adaptor file:"> {{ 'md5.checksum.on.adaptor.file' | translate}}</label>
        <input placeholder="required" id="MD5 checksum on Adaptor File" type="text" aria-label="Name"
            [(ngModel)]="adaptorInfo.location.md5"><br>

        <label for="Adaptor Start Command:"> {{ 'adaptor.start.command' | translate}}</label>
        <input placeholder="required" id="Adaptor Start Command:" type="text" aria-label="Name"
            [(ngModel)]="adaptorInfo.controlCommands.start"><br>
      
        <button class="btn btn-secondary" (click)="goBack()">{{ 'go.back' | translate}}</button>&nbsp;
        <button calss="btn btn-primary"  [disabled]="adaptorInfo.name==''" (click)="save()">{{ 'save' | translate}}</button>
        <br>
    </div>
/**
 * This is an auto generated code for tpApplications listing page.
 * Please don't make any change to this file.
 * @version 1.0.0
 * @readonly
 * @author - System generated
 */

import { Component, OnInit, ViewChild, Injector, Inject} from '@angular/core'
import { IotService } from '../service/iot.service'
import { TpApplicationsService } from '../service/tpApplications.service'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { faCaretUp, faCaretDown, faInfoCircle, faQuestionCircle, faArrowUp, faArrowDown, faSearch } from '@fortawesome/free-solid-svg-icons'
import { SideNavComponent } from '../side-nav/side-nav.component'
import { BaseListComponent } from '../base-list/baselist.component'

interface TypeChange {
	value: string;
	viewValue: string;
}
@Component({
	selector: 'app-tpApplications',
	templateUrl: './tpApplications.component.html',
	styleUrls: ['../base-list/baselist.component.scss','./tpApplications.component.scss']
})
export class TpApplicationsComponent extends BaseListComponent {
	displayedColumns: string[] = ['name','creation'];
	sortColumns: string[] = ['creation'];
	pageTitle="tpApplications.header";
	ascCreation:boolean = false;
	descCreation:boolean = false;
	countCallCompleted:boolean = false;
	getCallCompleted:boolean = false;

	constructor( router: Router,
				iotService: IotService,
				translateService: TranslateService,
				sideNavComponent:SideNavComponent,
				public tpApplicationsService:TpApplicationsService) {
				super(router, iotService, translateService, sideNavComponent )
						this.translateService.addLangs(this.supportLanguages);
						this.translateService.setDefaultLang('en');
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.tpApplicationsService.multiSelectRows = this.multiSelect;
		this.tpApplicationsService.loadSelected();
		if(!this.showSelectColoumn){
			this.sideNavComponent.menuClear();
			this.sideNavComponent.menuChange('Dashboard','sub-type');
			this.sideNavComponent.menuChange('tpApplications.header','sub-type');
		}
	}
	getFilteredResults(value, columnName , sortOrder){
		const that = this;
		that.iotService.getCuiObjResponse().getTpApplications({
			tradingPartnerId: that.extraParamsMap.get('tradingPartnerId'),
			qs: eval(that.fnGetQS(value, columnName, sortOrder))
		}).then(function (response) {
			that.fnSuccess(response);
			if(columnName == 'none' || columnName == '' || columnName == undefined){
				that.fnResetSortParam('creation', 'desc');
			}else{
				that.fnResetSortParam(columnName, sortOrder);
			}
			that.getCallCompleted = true;
		}).fail(function (err) {
			that.fnError(err);
			that.getCallCompleted = true;
		});
	}
	getCount() {
		const that = this;
		that.showPagination=false;
		let value = this.selected;
		that.iotService.getCuiObjResponse().countTpApplications({
			tradingPartnerId: that.extraParamsMap.get('tradingPartnerId'),
			qs: eval(that.fnGetQS(value, '', ''))
		}).then(function (response) {
				that.totalCount = response;
				that.countCallCompleted = true;
			if(that.totalCount > 0)
				that.showPagination=true;
			}).fail(function (err) {
				that.fnError(err);
				that.countCallCompleted = true;
			});
	}
	deleteTpApplications(resourceId){
		const that = this;
		that.iotService.getCuiObjResponse().deleteTpApplications({
			tradingPartnerId: that.extraParamsMap.get('tradingPartnerId'),
			applicationId: resourceId
		}).then(function (response) {
			that.getCount();
			that.getFilteredResults(that.selected, that.sortCriteria, that.sortDirection);
			that.tpApplicationsService.fnResetList();
		}).fail(function (err) {
			that.fnError(err);
			that.tpApplicationsService.showErrorMessage(err);
		});
	}
	viewDetails(selectedId){
		this.router.navigateByUrl("/applications/"+selectedId);
		this.tpApplicationsService.viewDetails(selectedId);
	}
	createNew(){
		this.tpApplicationsService.createNew();
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}
	onSelection(results){
		this.tpApplicationsService.onSelection(results);
	}
	removeSelection(results){
		this.tpApplicationsService.removeSelection(results);
	}
}

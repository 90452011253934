<!-- 
 * This is an auto generated code for side nav page.
 * Please don't make any change to this file.
 * @version 1.0.0 
 * @readonly 
 * @author - System generated 
 -->

<div class="side-nav-container">
	<mat-sidenav-container class="sidenav-navigation">
		<mat-sidenav #sidenav mode="side" opened="true" (mouseenter)="mouseenter()" (mouseleave)="mouseleave()">
			<mat-nav-list>
				<mat-list-item [routerLink]="['/getting-started']" (click)="onGettingStarted(false)">
					 {{'menu.getting.started' | translate}}
				</mat-list-item>
				<mat-list-item (click)="menuChange('Dashboard','main')" [routerLink]="['/dashboard']" [routerLinkActive]="['is-active']" (click)="showSubmenu = !showSubmenu" class="parent">
					 {{'breadcrumb_dashboard' | translate}}
				</mat-list-item>
				<div class="submenu" [ngClass]="{'expanded' : showSubmenu}">
					<h1> {{'menu.parent.things.header' | translate}}</h1>
				<mat-list-item (click)="menuChange('devices.header','main')" [routerLink]="['/devices']" [routerLinkActive]="['is-active']" [ngClass]="menuActiveClassDevices" (click)="onGettingStarted(true)">
					 {{'devices.header' | translate}}
				</mat-list-item>
				<mat-list-item (click)="menuChange('templates.header','main')" [routerLink]="['/templates']" [routerLinkActive]="['is-active']" [ngClass]="menuActiveClassTemplates" (click)="onGettingStarted(true)">
					 {{'templates.header' | translate}}
				</mat-list-item>
				<mat-list-item (click)="menuChange('applications.header','main')" [routerLink]="['/applications']" [routerLinkActive]="['is-active']" [ngClass]="menuActiveClassApplications" (click)="onGettingStarted(true)">
					 {{'applications.header' | translate}}
				</mat-list-item>
				<mat-list-item (click)="menuChange('ecosystemTemplates.header','main')" [routerLink]="['/ecosystemTemplates']" [routerLinkActive]="['is-active']" [ngClass]="menuActiveClassEcosystemTemplates" (click)="onGettingStarted(true)">
					 {{'ecosystemTemplates.header' | translate}}
				</mat-list-item>
				<mat-list-item (click)="menuChange('ecosystems.header','main')" [routerLink]="['/ecosystems']" [routerLinkActive]="['is-active']" [ngClass]="menuActiveClassEcosystems" (click)="onGettingStarted(true)">
					 {{'ecosystems.header' | translate}}
				</mat-list-item>
				<mat-list-item (click)="menuChange('gateways.header','main')" [routerLink]="['/gateways']" [routerLinkActive]="['is-active']" [ngClass]="menuActiveClassGateways" (click)="onGettingStarted(true)">
					 {{'gateways.header' | translate}}
				</mat-list-item>
				<mat-list-item (click)="menuChange('tradingPartners.header','main')" [routerLink]="['/tradingPartners']" [routerLinkActive]="['is-active']" [ngClass]="menuActiveClassTradingPartners" (click)="onGettingStarted(true)">
					 {{'tradingPartners.header' | translate}}
				</mat-list-item>
				<mat-list-item (click)="menuChange('geofences.header','main')" [routerLink]="['/geofences']" [routerLinkActive]="['is-active']" [ngClass]="menuActiveClassGeofences" (click)="onGettingStarted(true)">
					 {{'geofences.header' | translate}}
				</mat-list-item>
				</div>
				<div class="submenu" [ngClass]="{'expanded' : showSubmenu}">
					<h1> {{'menu.parent.connections.header' | translate}}</h1>
				<mat-list-item (click)="menuChange('streams.header','main')" [routerLink]="['/streams']" [routerLinkActive]="['is-active']" [ngClass]="menuActiveClassStreams" (click)="onGettingStarted(true)">
					 {{'streams.header' | translate}}
				</mat-list-item>
				<mat-list-item (click)="menuChange('eventGroups.header','main')" [routerLink]="['/eventGroups']" [routerLinkActive]="['is-active']" [ngClass]="menuActiveClassEventGroups" (click)="onGettingStarted(true)">
					 {{'eventGroups.header' | translate}}
				</mat-list-item>
				<mat-list-item (click)="menuChange('documentInfo.header','main')" [routerLink]="['/documentInfo']" [routerLinkActive]="['is-active']" [ngClass]="menuActiveClassDocumentInfo" (click)="onGettingStarted(true)">
					 {{'documentInfo.header' | translate}}
				</mat-list-item>
				<mat-list-item (click)="menuChange('routes.header','main')" [routerLink]="['/routes']" [routerLinkActive]="['is-active']" [ngClass]="menuActiveClassRoutes" (click)="onGettingStarted(true)">
					 {{'routes.header' | translate}}
				</mat-list-item>
				<mat-list-item (click)="menuChange('messageGovernances.header','main')" [routerLink]="['/messageGovernances']" [routerLinkActive]="['is-active']" [ngClass]="menuActiveClassMessageGovernances" (click)="onGettingStarted(true)">
					 {{'messageGovernances.header' | translate}}
				</mat-list-item>
				</div>
				<div class="submenu" [ngClass]="{'expanded' : showSubmenu}">
					<h1> {{'menu.parent.security.header' | translate}}</h1>
				<mat-list-item (click)="menuChange('certificates.header','main')" [routerLink]="['/certificates']" [routerLinkActive]="['is-active']" [ngClass]="menuActiveClassCertificates" (click)="onGettingStarted(true)">
					 {{'certificates.header' | translate}}
				</mat-list-item>
				<mat-list-item (click)="menuChange('sshKeys.header','main')" [routerLink]="['/sshKeys']" [routerLinkActive]="['is-active']" [ngClass]="menuActiveClassSshKeys" (click)="onGettingStarted(true)">
					 {{'sshKeys.header' | translate}}
				</mat-list-item>
				<mat-list-item (click)="menuChange('oAuthTokens.header','main')" [routerLink]="['/oAuthTokens']" [routerLinkActive]="['is-active']" [ngClass]="menuActiveClassOAuthTokens" (click)="onGettingStarted(true)">
					 {{'oAuthTokens.header' | translate}}
				</mat-list-item>
				</div>
				<div class="submenu" [ngClass]="{'expanded' : showSubmenu}">
					<h1> {{'menu.parent.control.header' | translate}}</h1>
				<mat-list-item (click)="menuChange('rules.header','main')" [routerLink]="['/rules']" [routerLinkActive]="['is-active']" [ngClass]="menuActiveClassRules" (click)="onGettingStarted(true)">
					 {{'rules.header' | translate}}
				</mat-list-item>
				<mat-list-item (click)="menuChange('orchestrationConfigs.header','main')" [routerLink]="['/orchestrationConfigs']" [routerLinkActive]="['is-active']" [ngClass]="menuActiveClassOrchestrationConfigs" (click)="onGettingStarted(true)">
					 {{'orchestrationConfigs.header' | translate}}
				</mat-list-item>
				<mat-list-item (click)="menuChange('importExport.header','main')" [routerLink]="['/importExport']" [routerLinkActive]="['is-active']" [ngClass]="menuActiveClassImportExport" (click)="onGettingStarted(true)">
					 {{'importExport.header' | translate}}
				</mat-list-item>
				</div>
				<div class="submenu" [ngClass]="{'expanded' : showSubmenu}">
					<h1> {{'menu.parent.monitoring.header' | translate}}</h1>
				<mat-list-item (click)="menuChange('perfmatrix.header','main')" [routerLink]="['/perfmatrix']" [routerLinkActive]="['is-active']" [ngClass]="menuActiveClassPerfmatrix" (click)="onGettingStarted(true)">
					 {{'perfmatrix.header' | translate}}
				</mat-list-item>
				</div>
				<span style="height: 0px; width: 200px; display: inline-block;"></span>
			</mat-nav-list>
		</mat-sidenav>
		<div class="body-content" id="maincontent">
			<mat-toolbar class="breadcrumb-navbar">
				<ol class="breadcrumb" aria-label="Breadcrumb">
					<ng-container *ngIf="menuHeader.length == 0">
						<li class="page-title">{{'breadcrumb_dashboard' | translate}}</li>
					</ng-container>
					<ng-container *ngIf="menuHeader.length != 0">
						<li><a class="" (click)="menuChange('Dashboard','main')" [routerLink]="['/dashboard']">{{'breadcrumb_dashboard' | translate}}</a></li>
						<ng-container *ngFor="let menu of menuHeader,let last = last;">
							<ng-container *ngIf="!last else onlyDisplay">
								<ng-container *ngIf="(menu == 'devices.header')">
										<li><a class="" (click)="menuChange('devices.header','main')" [routerLink]="['/devices']">{{'devices.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'templates.header')">
										<li><a class="" (click)="menuChange('templates.header','main')" [routerLink]="['/templates']">{{'templates.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'applications.header')">
										<li><a class="" (click)="menuChange('applications.header','main')" [routerLink]="['/applications']">{{'applications.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'streams.header')">
										<li><a class="" (click)="menuChange('streams.header','main')" [routerLink]="['/streams']">{{'streams.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'certificates.header')">
										<li><a class="" (click)="menuChange('certificates.header','main')" [routerLink]="['/certificates']">{{'certificates.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'trustedAuthorities.header')">
										<li><a class="" (click)="menuChange('trustedAuthorities.header','main')" [routerLink]="['/trustedAuthorities']">{{'trustedAuthorities.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'trustedCertificates.header')">
										<li><a class="" (click)="menuChange('trustedCertificates.header','main')" [routerLink]="['/trustedCertificates']">{{'trustedCertificates.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'keyBundles.header')">
										<li><a class="" (click)="menuChange('keyBundles.header','main')" [routerLink]="['/keyBundles']">{{'keyBundles.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'trustedPublicKeys.header')">
										<li><a class="" (click)="menuChange('trustedPublicKeys.header','main')" [routerLink]="['/trustedPublicKeys']">{{'trustedPublicKeys.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'sshKeys.header')">
										<li><a class="" (click)="menuChange('sshKeys.header','main')" [routerLink]="['/sshKeys']">{{'sshKeys.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'sshKeyPairs.header')">
										<li><a class="" (click)="menuChange('sshKeyPairs.header','main')" [routerLink]="['/sshKeyPairs']">{{'sshKeyPairs.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'oAuthTokens.header')">
										<li><a class="" (click)="menuChange('oAuthTokens.header','main')" [routerLink]="['/oAuthTokens']">{{'oAuthTokens.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'eventGroups.header')">
										<li><a class="" (click)="menuChange('eventGroups.header','main')" [routerLink]="['/eventGroups']">{{'eventGroups.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'documentInfo.header')">
										<li><a class="" (click)="menuChange('documentInfo.header','main')" [routerLink]="['/documentInfo']">{{'documentInfo.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'toTPCodes.header')">
										<li><a class="" (click)="menuChange('toTPCodes.header','main')" [routerLink]="['/toTPCodes']">{{'toTPCodes.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'fromTPCodes.header')">
										<li><a class="" (click)="menuChange('fromTPCodes.header','main')" [routerLink]="['/fromTPCodes']">{{'fromTPCodes.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'rules.header')">
										<li><a class="" (click)="menuChange('rules.header','main')" [routerLink]="['/rules']">{{'rules.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'orchestrationConfigs.header')">
										<li><a class="" (click)="menuChange('orchestrationConfigs.header','main')" [routerLink]="['/orchestrationConfigs']">{{'orchestrationConfigs.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'importExport.header')">
										<li><a class="" (click)="menuChange('importExport.header','main')" [routerLink]="['/importExport']">{{'importExport.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'ecosystemTemplates.header')">
										<li><a class="" (click)="menuChange('ecosystemTemplates.header','main')" [routerLink]="['/ecosystemTemplates']">{{'ecosystemTemplates.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'ecosystems.header')">
										<li><a class="" (click)="menuChange('ecosystems.header','main')" [routerLink]="['/ecosystems']">{{'ecosystems.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'gateways.header')">
										<li><a class="" (click)="menuChange('gateways.header','main')" [routerLink]="['/gateways']">{{'gateways.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'tradingPartners.header')">
										<li><a class="" (click)="menuChange('tradingPartners.header','main')" [routerLink]="['/tradingPartners']">{{'tradingPartners.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'tradingPartnerCodes.header')">
										<li><a class="" (click)="menuChange('tradingPartnerCodes.header','main')" [routerLink]="['/tradingPartnerCodes']">{{'tradingPartnerCodes.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'tradingPartnerRelationships.header')">
										<li><a class="" (click)="menuChange('tradingPartnerRelationships.header','main')" [routerLink]="['/tradingPartnerRelationships']">{{'tradingPartnerRelationships.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'codeRelationships.header')">
										<li><a class="" (click)="menuChange('codeRelationships.header','main')" [routerLink]="['/codeRelationships']">{{'codeRelationships.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'routingSourceRelationships.header')">
										<li><a class="" (click)="menuChange('routingSourceRelationships.header','main')" [routerLink]="['/routingSourceRelationships']">{{'routingSourceRelationships.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'routes.header')">
										<li><a class="" (click)="menuChange('routes.header','main')" [routerLink]="['/routes']">{{'routes.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'tpApplications.header')">
										<li><a class="" (click)="menuChange('tpApplications.header','main')" [routerLink]="['/tpApplications']">{{'tpApplications.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'perfmatrix.header')">
										<li><a class="" (click)="menuChange('perfmatrix.header','main')" [routerLink]="['/perfmatrix']">{{'perfmatrix.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'attributes.header')">
										<li><a class="" (click)="menuChange('attributes.header','main')" [routerLink]="['/attributes']">{{'attributes.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'events.header')">
										<li><a class="" (click)="menuChange('events.header','main')" [routerLink]="['/events']">{{'events.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'messageGovernances.header')">
										<li><a class="" (click)="menuChange('messageGovernances.header','main')" [routerLink]="['/messageGovernances']">{{'messageGovernances.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'commands.header')">
										<li><a class="" (click)="menuChange('commands.header','main')" [routerLink]="['/commands']">{{'commands.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'ecosystemAttributes.header')">
										<li><a class="" (click)="menuChange('ecosystemAttributes.header','main')" [routerLink]="['/ecosystemAttributes']">{{'ecosystemAttributes.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'ecosystemEvents.header')">
										<li><a class="" (click)="menuChange('ecosystemEvents.header','main')" [routerLink]="['/ecosystemEvents']">{{'ecosystemEvents.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'ecosystemMembers.header')">
										<li><a class="" (click)="menuChange('ecosystemMembers.header','main')" [routerLink]="['/ecosystemMembers']">{{'ecosystemMembers.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'gatewayAdaptors.header')">
										<li><a class="" (click)="menuChange('gatewayAdaptors.header','main')" [routerLink]="['/gatewayAdaptors']">{{'gatewayAdaptors.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'logRequests.header')">
										<li><a class="" (click)="menuChange('logRequests.header','main')" [routerLink]="['/logRequests']">{{'logRequests.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'audits.header')">
										<li><a class="" (click)="menuChange('audits.header','main')" [routerLink]="['/audits']">{{'audits.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'geofences.header')">
										<li><a class="" (click)="menuChange('geofences.header','main')" [routerLink]="['/geofences']">{{'geofences.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'ecosystemInstanceAttributes.header')">
										<li><a class="" (click)="menuChange('ecosystemInstanceAttributes.header','main')" [routerLink]="['/ecosystemInstanceAttributes']">{{'ecosystemInstanceAttributes.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'ecosystemInstanceMembers.header')">
										<li><a class="" (click)="menuChange('ecosystemInstanceMembers.header','main')" [routerLink]="['/ecosystemInstanceMembers']">{{'ecosystemInstanceMembers.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'organizations.header')">
										<li><a class="" (click)="menuChange('organizations.header','main')" [routerLink]="['/organizations']">{{'organizations.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'persons.header')">
										<li><a class="" (click)="menuChange('persons.header','main')" [routerLink]="['/persons']">{{'persons.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'ecosystemRelationships.header')">
										<li><a class="" (click)="menuChange('ecosystemRelationships.header','main')" [routerLink]="['/ecosystemRelationships']">{{'ecosystemRelationships.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'ecosystemTemplateRules.header')">
										<li><a class="" (click)="menuChange('ecosystemTemplateRules.header','main')" [routerLink]="['/ecosystemTemplateRules']">{{'ecosystemTemplateRules.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'ecosystemInstanceRules.header')">
										<li><a class="" (click)="menuChange('ecosystemInstanceRules.header','main')" [routerLink]="['/ecosystemInstanceRules']">{{'ecosystemInstanceRules.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'ecosystemBytemplates.header')">
										<li><a class="" (click)="menuChange('ecosystemBytemplates.header','main')" [routerLink]="['/ecosystemBytemplates']">{{'ecosystemBytemplates.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'gatewayAnnouncedDevices.header')">
										<li><a class="" (click)="menuChange('gatewayAnnouncedDevices.header','main')" [routerLink]="['/gatewayAnnouncedDevices']">{{'gatewayAnnouncedDevices.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'gatewayRulePolices.header')">
										<li><a class="" (click)="menuChange('gatewayRulePolices.header','main')" [routerLink]="['/gatewayRulePolices']">{{'gatewayRulePolices.header' | translate}}</a></li>
								</ng-container>
								<ng-container *ngIf="(menu == 'tradingPartnerAudits.header')">
										<li><a class="" (click)="menuChange('tradingPartnerAudits.header','main')" [routerLink]="['/tradingPartnerAudits']">{{'tradingPartnerAudits.header' | translate}}</a></li>
								</ng-container>
							</ng-container>
							<ng-template #onlyDisplay>
								<li class="page-title" aria-current="page">{{menu | translate}}</li>
							</ng-template>
						</ng-container>
					</ng-container>
				</ol>
			</mat-toolbar>
			<div>
				<router-outlet></router-outlet>
			</div>
		</div>
	</mat-sidenav-container>
</div>

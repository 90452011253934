<div class="ssh-detail" *ngIf="sshKeyPair">
    <div style="margin-left: -20px;">
        <app-copy-resource-id></app-copy-resource-id>
    </div>
    <h1>{{ 'view.sshKeyPair' | translate}}</h1>
    <form >
        <label for="name"> {{ 'name' | translate}}</label>
        <p id="name">{{sshKeyPair.name}}</p>

        <label for="description"> {{ 'description' | translate}}</label>
        <p id="description">{{sshKeyPair.description }}</p>

        <label for="Creation"> {{ 'creation' | translate}}</label>
        <p id="Creation">{{sshKeyPair.creation| date:'MMM d, y, h:mm:ss a' }}</p>

        <label for="algorithm"> {{ 'algorithm' | translate}}</label>
        <p id="algorithm">{{sshKeyPair.algorithm }}</p>

        <label for="bitLength">{{ 'bitLength' | translate}}</label>
        <p id="bitLength">{{sshKeyPair.bitLength }}</p>

        <label for="sha256Fingerprint"> {{ 'sha256Fingerprint' | translate}}</label>
        <p id="sha256Fingerprint">{{sshKeyPair.sha256Fingerprint }}</p>

        <label for="md5Fingerprint"> {{ 'md5Fingerprint' | translate}}</label>
        <p id="md5Fingerprint">{{sshKeyPair.md5Fingerprint }}</p>

        <label for="publicKey">{{ 'public.key.text' | translate}}</label>
        <p id="publicKey" style="overflow-wrap: anywhere; ">{{sshKeyPair.publicKeyText }}</p>

        <label for="publicKeyFormat"> {{ 'public.key.format' | translate}} </label>
        <p id="publicKeyFormat">{{sshKeyPair.publicKeyFormat }}</p>

        <label for="Activated">  {{ 'activated' | translate}} </label>
        <div *ngIf="sshKeyPair.active">
            <button class="toggle-on toggle-switch" title="Disable {{sshKeyPair.name}}" (click)="disablesshKeyPair(sshKeyPair.id)">
                <fa-icon [icon]="faCheckIcon" class="fa-2x"></fa-icon>
                <div class="toggle-on-btn"></div>
            </button>
        </div>
        <div *ngIf="!sshKeyPair.active">
            <button class="toggle-off toggle-switch" title="Enable {{sshKeyPair.name}}" (click)="enablesshKeyPair(sshKeyPair.id)">
                <div class="toggle-off-btn"></div>
            </button>
        </div>
        <br>
        <br>
    </form>
</div>

<ng-container *ngIf="!sshKeyPair ">
    <div>
        <br/><br/>
    </div>
    <div class="d-flex justify-content-center pageLoading">
        <div class="spinner-border" role="status">
            <span class="sr-only" id="loading"></span>
        </div>
    </div>
</ng-container>
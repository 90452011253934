import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ImportExportService } from '../service/import-export.service';
import { SideNavComponent } from '../side-nav/side-nav.component';


@Component({
  selector: 'app-importExport',
  templateUrl: './importExport.component.html',
  styleUrls: ['./importExport.component.scss']
})
export class ImportExportComponent implements OnInit {

  supportLanguages = ['en'];

  constructor(private translateService: TranslateService, private sideNavComponent: SideNavComponent, public importExportService: ImportExportService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
    this.sideNavComponent.menuClear();
    this.sideNavComponent.menuChange('Dashboard', 'sub-type');
    this.sideNavComponent.menuChange('importExport.header', 'sub-type');
  }

  ngOnInit(): void {
    this.importExportService.showImportExportSolution = true;
    this.importExportService.showOrchIMpExp = false;
    this.importExportService.showRuleImpExp = false;
    this.importExportService.showEventGroup = false;
  }




  tabClick(tab) {
    console.log(tab.index);
    if (tab.index == 0) {
      console.log("export");
      this.importExportService.showImportExportSolution = true;
      this.importExportService.showOrchIMpExp = false;
      this.importExportService.showRuleImpExp = false;
      this.importExportService.showEventGroup = false;
    }
    else if (tab.index == 1) {
      console.log("orchestration");
      this.importExportService.showImportExportSolution = false;
      this.importExportService.showOrchIMpExp = true;
      this.importExportService.showRuleImpExp = false;
      this.importExportService.showEventGroup = false;
    }
    else if (tab.index == 2) {
      console.log("rule");
      this.importExportService.showImportExportSolution = false;
      this.importExportService.showOrchIMpExp = false;
      this.importExportService.showRuleImpExp = true;
      this.importExportService.showEventGroup = false;
    }
    else if (tab.index == 3) {
      console.log("eventGroup");
      this.importExportService.showImportExportSolution = false;
      this.importExportService.showOrchIMpExp = false;
      this.importExportService.showRuleImpExp = false;
      this.importExportService.showEventGroup = true;
    }
  }
}

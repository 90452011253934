<mat-sidenav-container class="new-export" autosize>

    <mat-sidenav class="midNav-container" #sidenav mode="side" disableClose="true" opened="true">

        <div class="alert success" *ngIf="geofenceService.successMessage">
            <h5>
                <fa-icon [icon]="faSuccessIcon"></fa-icon>
                <span>{{geofenceService.successMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=geofenceService.resetSuccessMessage() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>
        <div class="alert critical" *ngIf="geofenceService.errorMessage">
            <h5>
                <fa-icon [icon]="faCriticalIcon"></fa-icon>
                <span>{{geofenceService.errorMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=geofenceService.resetErrorMessage() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>
        <div class="alert warning" *ngIf="geofenceService.warningMessage">
            <h5 style="font-size: 12px;">
                <fa-icon [icon]="faWarningIcon"></fa-icon>
                <span>{{geofenceService.warningMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)="geofenceService.resetWarningMessage()" [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>
        <div class="description">
            <div>
                <!-- <b>Description:</b> -->
                <h1 style="margin-left: -10px;">{{'create.geofence' | translate}}</h1>
                <p>{{'geofences.definition' | translate}}</p>

            </div>


        </div>

    </mat-sidenav>

    <mat-sidenav-content class="example-sidenav-content">
        <div class="export-data">

            <app-create-geofence></app-create-geofence>
            <ng-container *ngIf="geofenceService.mapLoad">
                <app-map-component></app-map-component>
            </ng-container>

        </div>
    </mat-sidenav-content>

</mat-sidenav-container>
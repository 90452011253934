/** 
 * This is an auto generated code for devices service listing page.
 * This file can be customized and will not be overwritten.
 * @version 1.0.0 
 * @readonly 
 * @author - System generated 
 */

import { Injectable } from '@angular/core';
import { SelectionModel} from '@angular/cdk/collections';
import { IotService } from '../service/iot.service'; 
import { ListDevicesService } from './ListDevices.service';
import { Router } from '@angular/router';

@Injectable({ 
	  providedIn: 'root' 
})

export class DevicesService extends ListDevicesService {
	 constructor(private router: Router,
			public iotService:IotService) {
		 super(iotService);
	 }

	 fnOnSelectionSupport(results){
		 const that = this; 
	 } 

	 fnRemoveSelectionSupport(results){ 
		 const that = this; 
	 } 

	 viewDeviceTemplate(parentDeviceTemplateId){
		this.router.navigateByUrl("/templates/"+parentDeviceTemplateId);
	  }

	  viewDeviceDetails(selectedId){}
} 

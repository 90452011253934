<mat-sidenav-container autosize>
    <mat-sidenav class="midNav-container" #sidenav mode="side" disableClose="true" opened="true">
        <div class="alert warning" *ngIf="notificationService.warningMessage">
            <h5>
                <fa-icon [icon]="faWarningIcon"></fa-icon>
                <span>{{notificationService.warningMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)="notificationService.resetWarningMessage()" [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>
        <div class="alert critical" *ngIf="notificationService.errorMessage">
            <h5>
                <fa-icon [icon]="faCriticalIcon"></fa-icon>
                <span>{{notificationService.errorMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=notificationService.resetErrorMessage() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>

        <div class="alert success" *ngIf="notificationService.successMessage">
            <h5>
                <fa-icon [icon]="faSuccessIcon"></fa-icon>
                <span>{{notificationService.successMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=notificationService.resetSuccessMessage() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>
        <div class="form" *ngIf="notification">
            <h1>Create Notification</h1>

            <br>

            <label>{{'Geofence Name'|translate}}</label>
            <p>{{geofencesService.geofence.name[0].text}}</p>


            <button class="btn btn-primary" style="margin-left:-2px;" [disabled]="notification.name[0].text==''"
            (click)="save()">{{'save'|translate}}</button>
            <br>
            <br>
            <button class="btn btn-secondary" style="margin-left:-2px;" (click)="loadGeofence()">{{'Geofence'|translate}}</button>
           

           


        </div>

    </mat-sidenav>
    <div class="notification-data" *ngIf="notification">
        <div>
            <div class="left">

                <label for="name"> {{ 'name' | translate}}</label>
                <input [(ngModel)]="notification.name[0].text" type="text">

                <label for="description"> {{ 'description' | translate}}</label>
                
                <textarea placeholder="optional" [(ngModel)]="notification.description[0].text" aria-label="Description"></textarea>



                <div class="tags">

                    <label for="device-tags">{{ 'tags' | translate}}</label>
                    <mat-chip-list #tagList aria-label="tags">

                        <mat-chip class="ot-chip" *ngFor="let tag of notification.tags" [selectable]="true"
                            [removable]=true (removed)="remove(tag)">
                            {{tag}}
                            <button class="ot-chip__close" matChipRemove></button>
                        </mat-chip>


                    </mat-chip-list>
                    <input [ngClass]="tagClass" placeholder="add a tag" #tagInput [matChipInputFor]="tagList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                        (matChipInputTokenEnd)="add($event)">
                </div>
            </div>

            <div class="right">

                <label>Number of times notification to be triggered</label>
                <input [disabled]="true" [(ngModel)]="notification.frequency" type="number">

                <label>Trigger Points</label>
                <mat-checkbox
                    title="Notification will be triggered if device is inside a geofence for more the dwell time"
                    [(ngModel)]="dwellTimeCondition">
                    Dwell time</mat-checkbox>
                <br>
                <mat-checkbox title="Notification will be triggered if device is entering the geofence."
                    [(ngModel)]="enterCondition">
                    Entering</mat-checkbox>
                <br>
                <mat-checkbox title="Notification will be triggered if device is exiting the geofence."
                    [(ngModel)]="exitCondition">
                    Exiting</mat-checkbox>
            </div>
        </div>
        <br>
        <br>
        <div class="clear">
            <br>
            <br>
            <br>
            <!-- <h6><b>Notification Methods</b></h6> -->
            <label>Notification Methods</label>
            <div class="tab-holder">
                <mat-tab-group (selectedTabChange)="tabClick($event)" [selectedIndex]="0">
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <fa-icon [icon]="faAt"></fa-icon>
                            <span> Email</span>
                        </ng-template>

                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <fa-icon [icon]="faMobile"></fa-icon>
                            <span> SMS</span>
                        </ng-template>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <fa-icon [icon]="faExternalLink"></fa-icon>
                            <span> External Service</span>
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
            </div>
            <div *ngIf="notificationMethod=='Email'" class="email">
                <div class="left">
                    <label>To</label>

                    <div class="tags">
                        <mat-chip-list #tagList aria-label="tags">
                            <mat-chip class="ot-chip" *ngFor="let tag of emails" [selectable]="true" [removable]=true
                                (removed)="removeEmail(tag)">
                                {{tag}}
                                <button class="ot-chip__close" matChipRemove></button>
                            </mat-chip>
                        </mat-chip-list>
                        <input [ngClass]="tagClass" placeholder="add email" #tagInput [matChipInputFor]="tagList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                            (matChipInputTokenEnd)="addEmail($event)">
                    </div>

                    <label>Subject</label>
                    <textarea rows=2 type="text" [(ngModel)]="email.subject"></textarea>

                    <label>Content</label>
                    <textarea rows=2 type="text" [(ngModel)]="email.content"></textarea>
                </div>


                <div class="right">
                    <div>


                        <label for="exampleDrodown"><b>Sample:</b></label>
                        <select [(ngModel)]="exampleDrodown">
                            <option value="geofence-entering" title="Geofence Enter Params">Geofence Enter Params
                            </option>
                            <option value="geofence-exiting" title="Geofence Exiting Params">Geofence Exiting Params
                            </option>
                            <option value="geofence-dwell" title="Geofence Dwell Time Params">Geofence Dwell Time Params
                            </option>
                        </select>

                        <div *ngIf="exampleDrodown=='geofence-entering'">
                            <br>
                            <ul>
                                <li>DEVICEID</li>
                                <li>LATITUDE</li>
                                <li>LONGITUDE</li>
                                <li>DEVICENAME</li>
                                <li>TIMESTAMP</li>
                                <li>GEOFENCENAME</li>
                                <li>GEOFENCEID</li>
                                <li>FROMSTATE</li>
                                <li>TOSTATE</li>
                            </ul>
                        </div>
                        <div *ngIf="exampleDrodown=='geofence-exiting'">
                            <br>
                            <ul>

                                <li>DEVICEID</li>
                                <li>LATITUDE</li>
                                <li>LONGITUDE</li>
                                <li>DEVICENAME</li>
                                <li>TIMESTAMP</li>
                                <li>GEOFENCENAME</li>
                                <li>GEOFENCEID</li>
                                <li>FROMSTATE</li>
                                <li>TOSTATE</li>
                            </ul>
                        </div>
                        <div *ngIf="exampleDrodown=='geofence-dwell'">
                            <br>
                            <ul>
                                <li>DEVICEID</li>
                                <li>LATITUDE</li>
                                <li>LONGITUDE</li>
                                <li>DEVICENAME</li>
                                <li>TIMESTAMP</li>
                                <li>DWELLTIME</li>
                                <li>GEOFENCENAME</li>
                                <li>GEOFENCEID</li>
                                <li>FROMSTATE</li>
                                <li>TOSTATE</li>
                            </ul>
                        </div>
                        <hr>
                        <div>
                            <b>sample usage:</b>

                            <i>The above variables can be used as appropriate fillers at runtime.</i>
                            <br>
                            <b>eg</b> : The device <mark>DEVICENAME</mark> with <mark>DEVICEID</mark> is entering a
                            geofence
                            <mark>GEOFENCENAME</mark>. Current location is <mark>LATITUDE</mark>,
                            <mark>LONGITUDE</mark>.


                            <p>
                                <b><i>this converts to:</i></b>
                            </p>

                            The device <mark>foodtruck</mark> with <mark>1234-2344-1233-2334</mark> is entering a
                            geofence
                            <mark>foodfactory-geofence</mark>. Current location is <mark>12.2</mark>,
                            <mark>12.32</mark>.

                        </div>
                        <hr>
                        <div>
                            <b><i>Sample content:</i></b>
                            <p *ngIf="exampleDrodown=='geofence-entering'">
                                The device DEVICENAME with DEVICEID is entering a geofence (GEOFENCENAME). Current
                                location
                                is (LATITUDE,
                                LONGITUDE).
                            </p>
                            <p *ngIf="exampleDrodown=='geofence-exiting'">
                                The device DEVICENAME with DEVICEID is exiting a geofence (GEOFENCENAME). Current
                                location
                                is (LATITUDE,
                                LONGITUDE).
                            </p>
                            <p *ngIf="exampleDrodown=='geofence-dwell'">
                                The device DEVICENAME with DEVICEID is idle inside the geofence (GEOFENCENAME) for more
                                than
                                configured
                                dwellTime.
                            </p>
                        </div>
                    </div>
                </div>
            </div>



            <div *ngIf="notificationMethod=='SMS'" class="email">

                <div class="left">
                    <label>SMS Number</label>
                    <div class="tags">
                        <mat-chip-list #tagList aria-label="tags">
                            <mat-chip class="ot-chip" *ngFor="let tag of smsNumbers" [selectable]="true"
                                [removable]=true (removed)="removeSms(tag)">
                                {{tag}}
                                <button class="ot-chip__close" matChipRemove></button>
                            </mat-chip>
                        </mat-chip-list>
                        <input [ngClass]="tagClass" placeholder="add number" #tagInput [matChipInputFor]="tagList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                            (matChipInputTokenEnd)="addSms($event)">
                    </div>


                    <label>Message</label>
                    <textarea rows=2 type="text" [(ngModel)]="sms.content"></textarea>
                </div>



                <div class="right">
                    <div>


                        <label for="exampleDrodown"><b>Sample:</b></label>
                        <select [(ngModel)]="exampleDrodown">
                            <option value="geofence-entering" title="Geofence Enter Params">Geofence Enter Params
                            </option>
                            <option value="geofence-exiting" title="Geofence Exiting Params">Geofence Exiting Params
                            </option>
                            <option value="geofence-dwell" title="Geofence Dwell Time Params">Geofence Dwell Time Params
                            </option>
                        </select>

                        <div *ngIf="exampleDrodown=='geofence-entering'">
                            <br>
                            <ul>
                                <li>DEVICEID</li>
                                <li>LATITUDE</li>
                                <li>LONGITUDE</li>
                                <li>DEVICENAME</li>
                                <li>TIMESTAMP</li>
                                <li>GEOFENCENAME</li>
                                <li>GEOFENCEID</li>
                                <li>FROMSTATE</li>
                                <li>TOSTATE</li>
                            </ul>
                        </div>
                        <div *ngIf="exampleDrodown=='geofence-exiting'">
                            <br>
                            <ul>

                                <li>DEVICEID</li>
                                <li>LATITUDE</li>
                                <li>LONGITUDE</li>
                                <li>DEVICENAME</li>
                                <li>TIMESTAMP</li>
                                <li>GEOFENCENAME</li>
                                <li>GEOFENCEID</li>
                                <li>FROMSTATE</li>
                                <li>TOSTATE</li>
                            </ul>
                        </div>
                        <div *ngIf="exampleDrodown=='geofence-dwell'">
                            <br>
                            <ul>
                                <li>DEVICEID</li>
                                <li>LATITUDE</li>
                                <li>LONGITUDE</li>
                                <li>DEVICENAME</li>
                                <li>TIMESTAMP</li>
                                <li>DWELLTIME</li>
                                <li>GEOFENCENAME</li>
                                <li>GEOFENCEID</li>
                                <li>FROMSTATE</li>
                                <li>TOSTATE</li>
                            </ul>
                        </div>
                        <hr>
                        <div>
                            <b>sample usage:</b>

                            <i>The above variables can be used as appropriate fillers at runtime.</i>
                            <br>
                            <b>eg</b> : The device <mark>DEVICENAME</mark> with <mark>DEVICEID</mark> is entering a
                            geofence
                            <mark>GEOFENCENAME</mark>. Current location is <mark>LATITUDE</mark>,
                            <mark>LONGITUDE</mark>.


                            <p>
                                <b><i>this converts to:</i></b>
                            </p>

                            The device <mark>foodtruck</mark> with <mark>1234-2344-1233-2334</mark> is entering a
                            geofence
                            <mark>foodfactory-geofence</mark>. Current location is <mark>12.2</mark>,
                            <mark>12.32</mark>.

                        </div>
                        <hr>
                        <div>
                            <b><i>Sample content:</i></b>
                            <p *ngIf="exampleDrodown=='geofence-entering'">
                                The device DEVICENAME with DEVICEID is entering a geofence (GEOFENCENAME). Current
                                location
                                is (LATITUDE,
                                LONGITUDE).
                            </p>
                            <p *ngIf="exampleDrodown=='geofence-exiting'">
                                The device DEVICENAME with DEVICEID is exiting a geofence (GEOFENCENAME). Current
                                location
                                is (LATITUDE,
                                LONGITUDE).
                            </p>
                            <p *ngIf="exampleDrodown=='geofence-dwell'">
                                The device DEVICENAME with DEVICEID is idle inside the geofence (GEOFENCENAME) for more
                                than
                                configured
                                dwellTime.
                            </p>
                        </div>
                    </div>
                </div>


            </div>


            <div *ngIf="notificationMethod=='ExternalService'">
                <label>Auth Type</label>
                <select name="auth-type" [(ngModel)]="authType">
                    <option [value]="item.auth" *ngFor="let item of authTypes">
                        {{item.auth}}
                    </option>
                </select>

                <div *ngIf="authType=='BASIC'" style="margin-left: 10px;">
                    <label>UserName</label>
                    <input type="text" [(ngModel)]="username">

                    <label>Password</label>
                    <input type="text" [(ngModel)]="password">

                </div>

                <label>URL</label>
                <textarea rows=2 type="text" [(ngModel)]="webHooks.serviceCall.url"></textarea>

                <label>Method</label>
                <select [(ngModel)]="method">
                    <option value="POST">POST</option>
                </select>

                <label>Headers</label>
                <div class="headers">
                    <table style="width:50%">
                        <thead>
                            <tr>
                                <th>Key</th>
                                <th>Value</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr *ngFor="let header of headers">
                                <td>
                                    <input [(ngModel)]="header.key" type="text" placeholder=""
                                        style="width:98%; margin:1px">
                                </td>
                                <td>
                                    <input [(ngModel)]="header.value" type="text" placeholder=""
                                        style="width:98%;margin:1px">
                                </td>
                                <td>
                                    <span style="width:100%;cursor: pointer;">
                                        <fa-icon (click)="removeRow($index)" [icon]="faCloseIcon"></fa-icon>
                                    </span>

                                </td>
                            </tr>
                        </tbody>


                    </table>
                    <button class="btn btn-secondary" (click)="addRow()">Add Row</button>
                </div>


                <label>Body</label>
                <textarea rows=3 type="text" [(ngModel)]="webHooks.serviceCall.body"></textarea>



            </div>
        </div>



    </div>
</mat-sidenav-container>
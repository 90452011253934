import { Component, OnInit,Inject } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { faAngleUp, faAngleDown, faTimes, faExclamationTriangle, faThumbsDown ,faExclamation,faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import { faToggleOff, faToggleOn, faAngleLeft, faAngleRight, faCheck, faCopy, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { IotService } from '../service/iot.service';
import { MessageGovernancesService } from '../service/messageGovernances.service';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER, I } from '@angular/cdk/keycodes';
import { FormControl, FormGroup } from '@angular/forms';
import { DigitalTwinsService } from '../service/digitalTwins.service';
import { StreamsService } from '../service/streams.service';
import {ToTPCodesService} from '../service/toTPCodes.service';
import {FromTPCodesService} from '../service/fromTPCodes.service';
import {CommandsService} from '../service/commands.service';
import {EventsService} from '../service/events.service';
import {DocumentInfoService} from '../service/documentInfo.service';
import { AuditsService } from '../service/audits.service'
import cronstrue from 'cronstrue';


@Component({
  selector: 'app-message-governance-detail',
  templateUrl: './message-governance-detail.component.html',
  styleUrls: ['./message-governance-detail.component.scss']
})
export class MessageGovernanceDetailComponent implements OnInit {
  separatorKeysCodes: number[] = [ENTER, COMMA];
  messageGovernanceResponse: any;
  messageGovernance:any;
  
  auditFlag=false;
  messageGovernanceId;
  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;
  faCloseIcon = faTimes;
  faCriticalIcon = faExclamation;
  faWarningIcon = faExclamationTriangle;
  faSuccessIcon = faCheckCircle;
  failed = faTimes;
  faToggleOffIcon = faToggleOff;
  faToggleOnIcon = faToggleOn;
  faAngleLeftIcon = faAngleLeft;
  faAngleRightIcon = faAngleRight;
  faCheckIcon = faCheck;
  faCopyIcon = faCopy;
  faAngleDoubleLeftIcon = faAngleDoubleLeft;
  faAngleDoubleRightIcon = faAngleDoubleRight;
  type = 'Device Event';
  messageStateType;
  showLogLevel = false;
  hasError: boolean = false;
  errorInName;
  errorInSchedule;
  errorInMessageCount;
  errorInGracePeriod;
  errorInMessagState;
  errorInEmailAddress;
  documentLoaded = false;
  documentCount=0;
  routeSourceLoaded = false;
  senderStreamCount=0;
  senderStreamLoad=false;
  receiverStreamCount=0;
  receiverStreamLoad=false;
  documentInfoCount=0
  senderFromTPCount=0;
  senderFromTPCodeLoad=false;
  receiverTOTPCount=0;
  tOTPCodeLoad=false;
  deviceCount=0;
  digitalTwin=false;
  eventCount=0;
  eventsFlag=false;
  commandCount=0;
  commandFlag=false;
  deviceTriggerFlag=true;
  commandTriggerFlag=true;
  tradingPartner=true;
  errorInSmsAddress;

  observableDeviceIds=[];
  observableDevicesTemplatesIds=[];
  observableEventsIds=[];
  observableSenderMessageInfoIds=[];
  observableSenderStreamsIds=[];
  observableReceivingStreamsIds=[];
  observableCommandTemplatesIds=[];
  observableSendingTPCodeIds=[];
  observableReceivingTPCodeIds=[];
  form: FormGroup;
  
  triggerTypes = [
    {
      id: 1,
      type: 'Device Event',
      value:'DEVICE_EVENT'
    },
    {
      id: 2,
      type: 'Command',
      value:'COMMAND'
    },
    {
      id: 3,
      type: 'Trading Partner Message',
      value:'TRADING_PARTNER_MESSAGE',
    }
  ];

  messageStateTypes = [
    {
      id: 1,
      type: 'Select',
      value: ''
    },
    {
      id: 2,
      type: 'Received',
      value:'RECEIVED'
    },
    {
      id: 3,
      type: 'Delivered',
      value:'DELIVERED'
    },
  ];

  tags = [];
  tagClass = 'tagInput';
  successMessage: string;
  warningMessage: string;
  isActive = true;



  supportLanguages = ['en'];
  authorityColumns = ["Name", "Type"];

  constructor(public iotService: IotService,public messageGovernancesService:MessageGovernancesService,
     private translateService: TranslateService, private sideNavComponent: SideNavComponent, private router: Router,  private route: ActivatedRoute,public digitalTwinsService: DigitalTwinsService,public streamService: StreamsService,
     public toTPCodesService:ToTPCodesService, public fromTPCodesService:FromTPCodesService,  public commandsService:CommandsService,  public eventsService:EventsService,   public documentInfoService:DocumentInfoService, @Inject('streamsService1') public firstStreamsService: StreamsService,
     @Inject('streamsService2') public secondStreamsService: StreamsService ,public auditsService:AuditsService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }

  ngOnInit(): void {
    this.documentLoaded = false;
    this.documentCount=0;
    this.routeSourceLoaded = false;
    this.senderStreamCount=0;
    this.senderStreamLoad=false;
    this.receiverStreamCount=0;
    this.receiverStreamLoad=false;
    this.documentInfoCount=0
    this.senderFromTPCount=0;
    this.senderFromTPCodeLoad=false;
    this.receiverTOTPCount=0;
    this.tOTPCodeLoad=false;
    this.deviceCount=0;
    this.digitalTwin=false;
    this.eventCount=0;
    this.eventsFlag=false;
    this.commandCount=0;
    this.commandFlag=false;
    this.auditFlag=false;
    this.iotService.msgGovLoader=true;
    this.iotService.createMessageGovernance=true;
    this.route.params.subscribe(params => {
    this.messageGovernanceId = params['id'];
    this.loadMessageGovernance(this.messageGovernanceId);
    });
    this.messageGovernancesService.resetErrorMessage();
    this.messageGovernancesService.resetSuccessMessage();
  
  }
  resetErrorMessage() {
    this.messageGovernancesService.resetErrorMessage();
  }
  resetdata(){
    const that = this;
    that.iotService.msgGovLoader=false;
    that.documentLoaded = false;
    that.documentCount=0;
    that.routeSourceLoaded = false;
    that.senderStreamCount=0;
    that.senderStreamLoad=false;
    that.receiverStreamCount=0;
    that.receiverStreamLoad=false;
    that.documentInfoCount=0
    that.senderFromTPCount=0;
    that.senderFromTPCodeLoad=false;
    that.receiverTOTPCount=0;
    that.tOTPCodeLoad=false;
    that.deviceCount=0;
    that.digitalTwin=false;
    that.eventCount=0;
    that.eventsFlag=false;
    that.commandCount=0;
    that.commandFlag=false;
    that.auditFlag=false;
    that.messageGovernancesService.events=[];
 //   that.messageGovernancesService.devices=[];
    that.messageGovernancesService.senderStreams=[];
    that.messageGovernancesService.receiverStreams=[];
    that.messageGovernancesService.documentInfos=[];
    that.messageGovernancesService.commandTemplates=[];
    that.messageGovernancesService.fromCodeValue=[];
    that.messageGovernancesService.toCodeValue=[];
  }
  ngOnDestroy() {
    const that = this;
    that.documentLoaded = false;
    that.documentCount=0;
    that.routeSourceLoaded = false;
    that.senderStreamCount=0;
    that.senderStreamLoad=false;
    that.receiverStreamCount=0;
    that.receiverStreamLoad=false;
    that.documentInfoCount=0
    that.senderFromTPCount=0;
    that.senderFromTPCodeLoad=false;
    that.receiverTOTPCount=0;
    that.tOTPCodeLoad=false;
    that.deviceCount=0;
    that.digitalTwin=false;
    that.eventCount=0;
    that.eventsFlag=false;
    that.commandCount=0;
    that.commandFlag=false;
    that.tags = [];
    that.tagClass = '';
    that.auditFlag=false;
    that.iotService.createMessageGovernance = false;
    this.iotService.msgGovLoader=false;
    that.messageGovernancesService.events=[];
    that.messageGovernancesService.devices=[];
    that.digitalTwinsService.selection.clear();
    that.documentInfoService.selection.clear();
    that.toTPCodesService.selection.clear();
    that.fromTPCodesService.selection.clear();
    that.commandsService.selection.clear();
    that.eventsService.selection.clear();
    that.secondStreamsService.selection.clear();
    that.firstStreamsService.selection.clear();
    that.documentInfoService.selection.clear();
    that.messageGovernancesService.senderStreams=[];
    that.messageGovernancesService.receiverStreams=[];
    that.messageGovernancesService.documentInfos=[];
    that.messageGovernancesService.commandTemplates=[];
    that.messageGovernancesService.fromCodeValue=[];
    that.messageGovernancesService.toCodeValue=[];
    this.messageGovernancesService.resetSuccessMessage();
  
  }

  resetWarningMessage() {
    this.warningMessage = '';
  }

  logMode() {
    if (this.showLogLevel == false) {
      this.showLogLevel = true;
    } else {
      this.showLogLevel = false;
    }
  }

  loadMessageGovernance(messageGovernanceId: any) {
    const that = this;
    that.iotService.msgGovLoader=true;
    that.iotService.getCuiObjResponse().getMesageGovernance({
      msgGovernanceId: messageGovernanceId
    }).then(function (response) {
      that.messageGovernanceResponse = response;
      that.messageGovernance=that.messageGovernanceResponse;
      
      if(!that.messageGovernance.description){
        that.messageGovernance.description = [];
        that.messageGovernance.description.push({ lang: 'en-us', text: "" });
      }
      that.messageGovernance.userDefineMessage = that.messageGovernanceResponse.userDefineMessage ? that.messageGovernanceResponse.userDefineMessage : "";
      that.messageGovernance.smsAddress = that.messageGovernanceResponse.smsAddress ? that.messageGovernanceResponse.smsAddress : "";

      if(that.messageGovernance.trigger == 'DEVICE_EVENT') {
        that.digitalTwinsService.selection.clear();
        that.eventsService.selection.clear(); 
        that.documentInfoService.selection.clear();
        that.streamService.selection.clear();
        that.initializeLoaderFlag();
      
       if(that.messageGovernance.observableDevices.length != 0){
        that.messageGovernancesService.devices=[];
          that.messageGovernance.observableDevices.forEach(deviceId => {
          that.loadDevice(deviceId); 
          });
       }
       if(that.messageGovernance.observableEvents.length != 0){
          that.messageGovernancesService.events=[];
          that.messageGovernance.observableEvents.forEach(eventId => {
          that.loadEvent(eventId); 
         });
  
       }
       if(that.messageGovernance.observableSenderMessageInfo.length != 0){
          that.messageGovernancesService.documentInfos=[];
          that.messageGovernance.observableSenderMessageInfo.forEach(documentInfoId => {
          that.loadDocumentInfo(documentInfoId); 
          });
       }
       if(that.messageGovernance.observableSenderStreams.length != 0){
          that.messageGovernancesService.senderStreams=[];
          that.messageGovernance.observableSenderStreams.forEach(streamId => {
          that.loadSenderStream(streamId); 
        });
        
       }

       if(that.messageGovernance.observableReceivingStreams.length != 0){
        that.messageGovernancesService.receiverStreams=[];
        that.messageGovernance.observableReceivingStreams.forEach(streamId => {
        that.loadReceiverStream(streamId); 
        });
   
       }

      }else if (that.messageGovernance.trigger == 'COMMAND') {
        that.commandsService.selection.clear();
        that.digitalTwinsService.selection.clear();
        that.eventsService.selection.clear(); 
        that.documentInfoService.selection.clear();
        that.streamService.selection.clear();
        that.initializeLoaderFlag();

        if(that.messageGovernance.observableCommandTemplates.length != 0){
           that.messageGovernancesService.commandTemplates=[];
           that.messageGovernance.observableCommandTemplates.forEach(commandId => {
           that.loadCommands(commandId); 
           });

         }
        if(that.messageGovernance.observableDevices.length != 0){
          that.messageGovernancesService.devices=[];
          that.messageGovernance.observableDevices.forEach(deviceId => {
          that.loadDevice(deviceId); 
        });
    
         }
       if(that.messageGovernance.observableSenderStreams.length != 0){
        that.messageGovernancesService.senderStreams=[];
         that.messageGovernance.observableSenderStreams.forEach(streamId => {
          that.loadSenderStream(streamId); 
        });
        

        }

       if(that.messageGovernance.observableReceivingStreams.length != 0){
        that.messageGovernancesService.receiverStreams=[];
        that.messageGovernance.observableReceivingStreams.forEach(streamId => {
        that.loadReceiverStream(streamId); 
        });

       }

      }else if (that.messageGovernance.trigger == 'TRADING_PARTNER_MESSAGE'){
        that.commandsService.selection.clear();
        that.digitalTwinsService.selection.clear();
        that.eventsService.selection.clear(); 
        that.documentInfoService.selection.clear();
        that.streamService.selection.clear();
        that.toTPCodesService.selection.clear();
        that.fromTPCodesService.selection.clear();
        that.initializeLoaderFlag();
        if(that.messageGovernance.observableSendingTPCode.length != 0){
          that.messageGovernancesService.fromCodeValue=[];
          that.messageGovernance.observableSendingTPCode.forEach(fromTPCodeId => {
            that.loadSenderTPCode(fromTPCodeId); 
          });
         }
        if(that.messageGovernance.observableReceivingTPCode.length != 0){
          that.messageGovernancesService.toCodeValue=[];
          that.messageGovernance.observableReceivingTPCode.forEach(toTPCodeId => {
            that.loadReceiverTPCode(toTPCodeId); 
          });
         }

         if(that.messageGovernance.observableSenderMessageInfo.length != 0){
          that.messageGovernancesService.documentInfos=[];
          that.messageGovernance.observableSenderMessageInfo.forEach(documentInfoId => {
            that.loadDocumentInfo(documentInfoId); 
          });
       
         }
         
       if(that.messageGovernance.observableSenderStreams.length != 0){
        that.messageGovernancesService.senderStreams=[];
        that.messageGovernance.observableReceivingStreams.forEach(streamId => {
          that.loadReceiverStream(streamId); 
          });

        }

       if(that.messageGovernance.observableReceivingStreams.length != 0){
        that.messageGovernancesService.receiverStreams=[];
        that.messageGovernance.observableReceivingStreams.forEach(streamId => {
          that.loadReceiverStream(streamId); 
          });
  

       }
      }

      that.sideNavComponent.menuClear();
      that.sideNavComponent.menuChange('Dashboard', 'sub-type');
      that.sideNavComponent.menuChange('messageGovernances.header', 'sub-type');
      that.sideNavComponent.menuChange(that.messageGovernance.name[0].text, 'sub-type');
  
      
    }).fail(function (err) {
      if (err != undefined && err != null && err.responseJSON != undefined && err.responseJSON != null) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }



  updateMessageGovernance(messageGovernanceId: any) {
    const that = this;
    let observableDevice = [];
		if (that.messageGovernancesService.getDevices().length > 0) {
      let devices = that.messageGovernancesService.getDevices();
      for(let i=0; i<devices.length; i++){
        console.log("devices id "+ devices[i]); //use i instead of 0
        observableDevice.push(devices[i].id);
      }
      that.messageGovernance.observableDevices=	observableDevice;
    }else{
      that.messageGovernance.observableDevices=	observableDevice;
    }
      let observableEvent = [];
      if (that.messageGovernancesService.getEvents().length > 0) {
				let events = that.messageGovernancesService.getEvents();
				for(let i=0; i<events.length; i++){
					console.log("events id "+ events[i]); //use i instead of 0
          observableEvent.push(events[i].id); 
				}
        that.messageGovernance.observableEvents=	observableEvent;
			}else{
        that.messageGovernance.observableEvents=	observableEvent;
      }

      let observableCommandTemplate = [];
      if (that.messageGovernancesService.getCommandTemplates().length > 0) {
				let CommandTemplates = that.messageGovernancesService.getCommandTemplates();
				for(let i=0; i<CommandTemplates.length; i++){
					console.log("CommandTemplate id "+ CommandTemplates[i]); //use i instead of 0
          observableCommandTemplate.push(CommandTemplates[i].id);
        }
          that.messageGovernance.observableCommandTemplates=	observableCommandTemplate;
			}else{
        that.messageGovernance.observableCommandTemplates=	observableCommandTemplate;
      }

      let observableSenderMessageInfo = [];
      if (that.messageGovernancesService.getDocumentInfos().length > 0 ) {
				let documentInfos = that.messageGovernancesService.getDocumentInfos();
				for(let i=0; i<documentInfos.length; i++){
					console.log("documentInfo id "+ documentInfos[i]); //use i instead of 0
          observableSenderMessageInfo.push(documentInfos[i].id);  
				}
        that.messageGovernance.observableSenderMessageInfo=	observableSenderMessageInfo;
			}else{
        that.messageGovernance.observableSenderMessageInfo=	observableSenderMessageInfo;
      }

      let observableSenderStream = [];
      if (that.messageGovernancesService.getSenderStreams().length > 0) {
				let senderStreams = that.messageGovernancesService.getSenderStreams();
       
				for(let i=0; i<senderStreams.length; i++){
					console.log("senderStream id "+ senderStreams[i]); //use i instead of 0
          observableSenderStream.push(senderStreams[i].id);
        }
          that.messageGovernance.observableSenderStreams=	observableSenderStream;
			}else{
        that.messageGovernance.observableSenderStreams=	observableSenderStream;
      }


      let observableReceivingStream = [];
      if (that.messageGovernancesService.getReceiverStreams().length > 0) {
				let receiverStreams = that.messageGovernancesService.getReceiverStreams();
				for(let i=0; i<receiverStreams.length; i++){
					console.log("receiverStream id "+ receiverStreams[i]); //use i instead of 0
          observableReceivingStream.push(receiverStreams[i].id);
        }
          that.messageGovernance.observableReceivingStreams=	observableReceivingStream;
			}else{
        that.messageGovernance.observableReceivingStreams=	observableReceivingStream;
      }
      let observableSendingTPCode = [];
      if (that.messageGovernancesService.getFromCodeValue().length > 0) {
				let sendingTPCode = that.messageGovernancesService.getFromCodeValue();
				for(let i=0; i<sendingTPCode.length; i++){
					console.log("sendingTPCode id "+ sendingTPCode[i]); //use i instead of 0
          observableSendingTPCode.push(sendingTPCode[i].id);
        }
          that.messageGovernance.observableSendingTPCode=	observableSendingTPCode;
			}else{
        that.messageGovernance.observableSendingTPCode=	observableSendingTPCode;
      }

      let observableReceivingTPCode = [];
      if (that.messageGovernancesService.getToCodeValue().length > 0) {
				let receivingTPCode = that.messageGovernancesService.getToCodeValue();
				for(let i=0; i<receivingTPCode.length; i++){
					console.log("ReceivingTPCode id "+ receivingTPCode[i]); //use i instead of 0
          observableReceivingTPCode.push(receivingTPCode[i].id);
        }
        that.messageGovernance.observableReceivingTPCode=	observableReceivingTPCode;	
			}else{
        that.messageGovernance.observableReceivingTPCode=	observableReceivingTPCode;
      }
    
    if (!that.errorValidations()) {
      that.iotService.getCuiObjResponse().updateMessageGovernance({
        msgGovernanceId: messageGovernanceId,
        data: that.messageGovernance
      }).then(function (response) {
        // console.log(response);
        that.loadMessageGovernanceInfo(messageGovernanceId);
        that.messageGovernancesService.successMessage = "MessageGovernance updated succesfully";
        console.log("MessageGovernance updated succesfully. ID :", response.id);
      }).fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          that.messageGovernancesService.errorMessage = err.responseJSON.apiMessage;
          console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
        } else {
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    }
  }


  deleteMessageGovernance(messageGovernanceId: any){
    const that = this;
    that.iotService.getCuiObjResponse().deleteMessageGovernances({
      msggovId: messageGovernanceId,
    }).then(function (response) {
      that.router.navigateByUrl("/messageGovernances");
      console.log("Message Governance deleted succesfully. Id :", response.id);
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.messageGovernancesService.errorMessage = err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }
 

  activateMessageGovernance(messageGovernanceId: any){
    const that = this;
    that.iotService.getCuiObjResponse().activateMessageGovernance({
      msgGovernanceId: messageGovernanceId
    }).then(function (response) {
      // console.log(response);
      that.loadMessageGovernanceInfo(messageGovernanceId);
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }

  deactivateMessageGovernance(messageGovernanceId: any){
    const that = this;
    that.iotService.getCuiObjResponse().deactivateMessageGovernance({
      msgGovernanceId: messageGovernanceId
    }).then(function (response) {
      // console.log(response);
      that.loadMessageGovernanceInfo(messageGovernanceId);
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }
  


  loadMessageGovernanceInfo(messageGovernanceId: any) {
    const that = this;
    that.iotService.getCuiObjResponse().getMesageGovernance({
      msgGovernanceId: messageGovernanceId
    }).then(function (response) {
      console.log(response);
      that.messageGovernance = response;
      if(!that.messageGovernance.description){
        that.messageGovernance.description = [];
        that.messageGovernance.description.push({ lang: 'en-us', text: "" });
      }
      that.sideNavComponent.menuClear();
      that.sideNavComponent.menuClear();
      that.sideNavComponent.menuChange('Dashboard', 'sub-type');
      that.sideNavComponent.menuChange('messageGovernances.header', 'sub-type');
      that.sideNavComponent.menuChange(that.messageGovernance.name[0].text, 'sub-type');
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  } 

loadDevice(deviceIds: any){
  let devices = [];
  const that = this; 
    that.iotService.getCuiObjResponse().getDevice({
      deviceId: deviceIds
    }).then(function (deviceResponse) { 
        devices=that.messageGovernancesService.getDevices();
        devices.push(deviceResponse);
        that.messageGovernancesService.setDevices(devices);
        that.deviceCount++;
        if(that.deviceCount==that.messageGovernance.observableDevices.length){
          that.digitalTwin=true;
        }    
    }).fail(function (err) {
      if (err != undefined && err != null && err.responseJSON != undefined && err.responseJSON != null) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
      that.deviceCount++;
    });
 

}


loadEvent(eventIds: any){
  let events = [];
  const that = this;

    that.iotService.getCuiObjResponse().getEventTemplate({
      eventTemplateId: eventIds
    }).then(function (eventResponse) {
             events=that.messageGovernancesService.getEvents();
            events.push(eventResponse);
            that.messageGovernancesService.setEvents(events);
            that.eventCount++;
            if(that.eventCount==that.messageGovernance.observableEvents.length){
              that.eventsFlag=true;
            }      
      
    }).fail(function (err) {
      if (err != undefined && err != null && err.responseJSON != undefined && err.responseJSON != null) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
      that.eventCount++;
    });

}


loadCommands(commandsId: any){
  let commands = [];
  const that = this;
 
    that.iotService.getCuiObjResponse().getCommandTemplate({
      commandTemplateId: commandsId
    }).then(function (commandResponse) {
  

      commands=that.messageGovernancesService.getCommandTemplates();
      commands.push(commandResponse);
      that.messageGovernancesService.setCommandTemplates(commands);
      that.commandCount++;
      if(that.commandCount==that.messageGovernance.observableCommandTemplates.length){
        that.commandFlag=true;
      }      
      
    }).fail(function (err) {
      if (err != undefined && err != null && err.responseJSON != undefined && err.responseJSON != null) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
      that.commandCount++;
    });
  
}



loadDocumentInfo(documentInfoId: any){
  let documentInfos = [];
  const that = this;
    that.iotService.getCuiObjResponse().getDocumentInfoById({
      docInfoId: documentInfoId
    }).then(function (documentInfosResponse) {
          documentInfos=that.messageGovernancesService.getDocumentInfos();
          documentInfos.push(documentInfosResponse);
          that.messageGovernancesService.setDocumentInfos(documentInfos);   
          that.documentInfoCount++;
          if(that.messageGovernance.observableSenderMessageInfo.length==that.documentInfoCount)
            { 
            that.documentLoaded=true;

           }

    }).fail(function (err) {
      if (err != undefined && err != null && err.responseJSON != undefined && err.responseJSON != null) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
      that.documentInfoCount++;
    });
}







loadSenderStream(senderStreamId: any){
   let senderStreams=[];
  const that = this; 
    that.iotService.getCuiObjResponse().getStream({
      streamId: senderStreamId
    }).then(function (senderStreamResults) {
        senderStreams=  that.messageGovernancesService.getSenderStreams();
        senderStreams.push(senderStreamResults);
          that.messageGovernancesService.setSenderStreams(senderStreams);
          that.senderStreamCount++;
          if(that.messageGovernance.observableSenderStreams.length==that.senderStreamCount){
            that.senderStreamLoad=true;
          }
    }).fail(function (err) {
      if (err != undefined && err != null && err.responseJSON != undefined && err.responseJSON != null) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
      that.senderStreamCount++;
    });  
  }
  



loadReceiverStream(receiverStreamId: any){
  let receiverStreams = [];
  const that = this;    
    that.iotService.getCuiObjResponse().getStream({
      streamId: receiverStreamId
    }).then(function (receiverStreamsResponse) {
          receiverStreams= that.messageGovernancesService.getReceiverStreams();
          receiverStreams.push(receiverStreamsResponse);
          that.messageGovernancesService.setReceiverStreams(receiverStreams);
          that.receiverStreamCount++;
          if(that.messageGovernance.observableReceivingStreams.length==that.receiverStreamCount){
            that.receiverStreamLoad=true;
          }
    }).fail(function (err) {
      if (err != undefined && err != null && err.responseJSON != undefined && err.responseJSON != null) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
      that.receiverStreamCount++;
    });
  }




      loadSenderTPCode(senderTPCodeId: any){
        let senderTPCodes = [];
        const that = this;   
          that.iotService.getCuiObjResponse().getTradingPartnerCodeById({
            codeId: senderTPCodeId
          }).then(function (senderTPCodeResponse) {
          
            senderTPCodes=  that.messageGovernancesService.getFromCodeValue();
            senderTPCodes.push(senderTPCodeResponse);
            that.messageGovernancesService.setFromCodeValue(senderTPCodes);
              
                that.senderFromTPCount++;
                if(that.messageGovernance.observableSendingTPCode.length==that.senderFromTPCount){
                that.senderFromTPCodeLoad=true;

                }
            
          }).fail(function (err) {
            if (err != undefined && err != null && err.responseJSON != undefined && err.responseJSON != null) {
              console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
            } else {
              console.log("Something went wrong. Please check chrome console or check with dev team");
            }
            that.senderFromTPCount++;
          });
        
      }


loadReceiverTPCode(receiverTPCodeId: any){
  let reciverTPCodes = [];
  const that = this;
    that.iotService.getCuiObjResponse().getTradingPartnerCodeById({
      codeId: receiverTPCodeId
    }).then(function (receiverTPCodeResponse) {
      reciverTPCodes=  that.messageGovernancesService.getToCodeValue();
      reciverTPCodes.push(receiverTPCodeResponse);
      that.messageGovernancesService.setToCodeValue(reciverTPCodes);
          that.receiverTOTPCount++;
          if(that.messageGovernance.observableReceivingTPCode.length==that.receiverTOTPCount){
          that.tOTPCodeLoad=true;
          }
      
    }).fail(function (err) {
      if (err != undefined && err != null && err.responseJSON != undefined && err.responseJSON != null) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
      that.receiverTOTPCount++;
    });
  
}

onChange(){
  this.resetErrorMessage();
  this.resetdata();
}
isPhoneNumber(phoneNo) {
  var regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
  return regex.test(phoneNo);
}
getCron() {
  const that=this;
   var results = "";
   if (that.messageGovernance.schedule) {
       try {
           results = cronstrue.toString(that.messageGovernance.schedule);
       } catch (err) {
           results = "";
       }


       console.log(results)
   }
   return results;
}
errorValidations() {
  const that = this;
  that.hasError = false;
  that.errorInSmsAddress = '';
  if (
    that.messageGovernance.name[0].text == undefined ||
    that.messageGovernance.name[0].text == ''
  ) {
    that.errorInName = 'Name is mandatory';
    that.hasError = true;
  }

  if(that.messageGovernance.schedule == undefined || that.messageGovernance.schedule == ''){
    that.errorInSchedule = 'Schedule is mandatory';
    that.hasError = true;
  } else{
    var res = that.getCron();
    if(res==""){
      that.errorInSchedule = 'Please enter valid cron expression';
      that.hasError = true;
    }
  } 

  if(that.messageGovernance.quota == undefined || that.messageGovernance.quota <= 0){
    that.errorInMessageCount = "Message count should be greater than 0";
    that.hasError = true;
  }

  if(that.messageGovernance.graceTime == undefined || that.messageGovernance.graceTime <= 0){
    that.errorInGracePeriod = "Grace period should be greater than 0";
    that.hasError = true;
  }

  if(that.messageGovernance.messageState == undefined || that.messageGovernance.messageState == ''){
    that.errorInMessagState = 'Message State either recieved or delivered';
    that.hasError = true;
  }

  if(that.messageGovernance.smsAddress != undefined && that.messageGovernance.smsAddress != ''){
    if(!that.isPhoneNumber(that.messageGovernance.smsAddress)){
      that.errorInSmsAddress = 'Please enter a valid phone number';
      that.hasError = true;
    }else{
      that.errorInSmsAddress = '';
    }
   
  }

  if(that.messageGovernance.emailAddress == undefined || that.messageGovernance.emailAddress == '') {
    that.errorInEmailAddress = 'Email Address is mandatory';
    that.hasError = true;
  }else{
    if(!that.validateEmail(that.messageGovernance.emailAddress)){
      that.hasError = true;
      that.errorInEmailAddress = 'Enter valid Email Address';
    }

  }
 return that.hasError;
}
add(event: MatChipInputEvent): void {

  this.tagClass = 'tagInput';
  const input = event.input;
  const value = event.value;
  var indice =0;
  if(this.messageGovernance.tags != undefined){
    indice = this.messageGovernance.tags.indexOf(value);
  }else{
    indice = -1;
  }
  if(indice<0){
  // Add our fruit
  if ((value || '').trim()) {
    const that = this;
    that.iotService.getCuiObjResponse().createMsgGovTag({
      msgGovernanceId: that.messageGovernance.id,
      tag: value
    }).then(function(response) {
      console.log("added tag:"+value+" to msgGovernanceId :"+that.messageGovernance.id);
        that.messageGovernance.version = parseInt(that.messageGovernance.version) + 1;
        if(that.messageGovernance.tags == undefined) {
          that.messageGovernance.tags = [];
        }
        const index = that.messageGovernance.tags.indexOf(value);
        if (index < 0) {
          that.messageGovernance.tags.push(value);
          input.value = '';
        }
        // Reset the input value
        
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        alert("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }
}else {
    this.tagClass = 'tagInput-duplicate';
  }
}

remove(tag: string): void {
  const that = this;
  if(tag != undefined){
    that.iotService.getCuiObjResponse().deleteMsgGovTag({
      msgGovernanceId: that.messageGovernance.id,
      tag: tag
    }).then(function (response) {
        console.log("removed tag:"+tag+" to messageGovernance :"+that.messageGovernance.id);
        that.messageGovernance.version = parseInt(that.messageGovernance.version) + 1;

        const index = that.messageGovernance.tags.indexOf(tag);

        if (index >= 0) {
          that.messageGovernance.tags.splice(index, 1);
        }

    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        alert("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

}

auditMessagegovernance(){
 
  this.auditFlag=true;
   
   
}
closeAudit(){
  this.auditFlag=false;
}

validateEmail(email) {
  const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regularExpression.test(String(email).toLowerCase());
 }
 initializeLoaderFlag(){

  this.documentLoaded = false;
  this.documentCount=0;
  this.routeSourceLoaded = false;
  this.senderStreamCount=0;
  this.senderStreamLoad=false;
  this.receiverStreamCount=0;
  this.receiverStreamLoad=false;
  this.documentInfoCount=0
  this.senderFromTPCount=0;
  this.senderFromTPCodeLoad=false;
  this.receiverTOTPCount=0;
  this.tOTPCodeLoad=false;
  this.deviceCount=0;
  this.digitalTwin=false;
  this.eventCount=0;
  this.eventsFlag=false;
  this.commandCount=0;
  this.commandFlag=false;
 }


 goBackAuditList() {
  const that=this;
  that.iotService.auditScriptFlag = false;
  that.iotService.auditscript="";
  that.auditFlag = true;
}



}



import { Component, OnInit } from '@angular/core';
import { IotService } from '../service/iot.service';
import { Location } from '@angular/common';
import {Router} from '@angular/router';
import { faAngleUp, faAngleDown, faTimes, faExclamationTriangle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faToggleOff, faToggleOn, faAngleLeft, faAngleRight, faCheck, faCopy,faExclamation } from '@fortawesome/free-solid-svg-icons';
import { SideNavComponent } from '../side-nav/side-nav.component';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { StreamService } from '../service/stream.service'
import { ApplicationsService } from '../service/applications.service';
import { EventGroupsService } from '../service/eventGroups.service';

@Component({
  selector: 'app-application-details',
  templateUrl: './application-details.component.html',
  styleUrls: ['./application-details.component.scss']
})
export class ApplicationDetailsComponent implements OnInit {

  separatorKeysCodes: number[] = [ENTER, COMMA];

  faWarningIcon = faExclamationTriangle;
  faCriticalIcon=faExclamation;
  faSuccessIcon = faCheckCircle;
  faAngleLeftIcon = faAngleLeft;
  faAngleRightIcon = faAngleRight;
  faToggleOffIcon = faToggleOff;
  faToggleOnIcon = faToggleOn;
  faCheckIcon = faCheck;
  faTimesIcon = faTimes;
  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;
  faCloseIcon = faTimes;

  icon = this.faToggleOnIcon;
  toggleEnable = 'toggle-on';
  applicationActive: boolean = true;

  matVersion: string = '5.1.0';
  breakpoint: number;
  application;
  streamApplicationResponse =[];
  applicationStream;
  currentApplicationStream=0;

  statusPendingClass = 'bg-lifecycle';
  statusActiveClass = 'bg-lifecycle';
  statusSuspendedClass = 'bg-lifecycle';
  statusDeletedClass = 'bg-lifecycle';
  streamData=[];
  routeData=[];
  showStreamCreationPage:boolean=false;
  showEventGroupCreationPage:boolean=false;
  streamColumns: string[] = [' ','Name', 'Protocol', 'Encryption', 'Subscriptions'];
  currentPage =1;
  streamSelected:string;

  updateNameDesc:boolean=true;

  pageSize=5;
  streamCount:number=0;
  showPagination:boolean=false;

  tagClass = 'tagInput';
  tagList =[];
  supportLanguages = ['en'];
  eventGroups=[];
  
  applicationId;
  constructor(private router: Router, private route: ActivatedRoute, private iotService:IotService,
    private location:Location,private sideNavComponent:SideNavComponent,private translateService: TranslateService,
    public streamService:StreamService,private applicationsService: ApplicationsService, public eventGroupService: EventGroupsService) { 
    this.translateService.addLangs(this.supportLanguages)
    this.translateService.setDefaultLang('en'); 
  }

  ngOnInit(): void {
    const that= this;
    that.applicationsService.resetMessage();
    that.iotService.viewApplication=true;
    that.streamService.streamCreated=false;
    if(window.innerWidth <= 1200 && window.innerWidth >650){
      that.breakpoint=2;
    } 
    else if(window.innerWidth <= 650){
      that.breakpoint=1;
    }
    else{
      that.breakpoint=4;
    }
    that.route.params.subscribe(params => {
      var applicationId = params['id'];
      that.applicationId = applicationId;
      that.getStreamApplications(applicationId);
      that.loadStream();
      that.countStreams('');
      that.loadApplication(applicationId);
    });
  }

  ngOnDestroy(){
    const that = this;
    that.iotService.viewApplication=false;
    that.iotService.deviceStream=false;
    that.iotService.sideNavStream=true;
    that.applicationsService.resetMessage();
  }
  
  onResize(event) {
    const that = this;
    if(event.target.innerWidth <= 1200 && event.target.innerWidth >650){
      that.breakpoint=2;
    } 
    else if(event.target.innerWidth <= 650){
      that.breakpoint=1;
    }
    else{
      that.breakpoint=4;
    }
  }

  enableUpdate(event:any) {
    const that = this;
    that.updateNameDesc=false;
  }

  loadApplication(applicationId) {
    const that = this;
    that.iotService.getCuiObjResponse().getApplication({
      applicationId: applicationId
    }).then(function(response) {
      console.log(response);
      that.application = response;   
      if(!that.application.description){
        that.application.description = [];
        that.application.description.push({ lang: 'en-us', text: "" });
      }
      that.sideNavComponent.menuClear();
      that.sideNavComponent.menuChange('Dashboard','sub-type');
      that.sideNavComponent.menuChange('applications.header','sub-type');
      that.sideNavComponent.menuChange(that.application.name[0].text,'sub-type');
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.applicationsService.errorMessage= err.responseJSON.apiMessage;
        alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        alert("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  loadStream() {
    const that = this;
    that.iotService.getCuiObjResponse().getStreams({
      qs:[['pageSize',5],['page',that.currentPage],['sortBy', '-creation'],['streamType', 'APPLICATION']]
    }).then(function(response): void {
      if(response) {
        that.streamData=[];
        that.streamData = response;
        that.checkStreamApplication();
      }
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.applicationsService.errorMessage= err.responseJSON.apiMessage;
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  loadRoutes(applicationStream) {
    const that = this;
    that.iotService.getCuiObjResponse().getRoutes({
      qs:[['streamId',applicationStream.id],['sortBy', '-creation']]
    }).then(function(response): void {
      if(response) {
        that.routeData=[];
        that.routeData = response;
        that.loadEventGroups(that.routeData);
      }
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.applicationsService.errorMessage= err.responseJSON.apiMessage;
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  loadEventGroups(routeData) {
    const that = this;
    that.applicationsService.subscriptionsData=[];
    that.applicationsService.eventGroupData=[];
    routeData.forEach(route => {
      that.iotService.getCuiObjResponse().getEventSource({
        eventSourceId: route.routeSourceId
    }).then(function(response): void {
      if(response) {
        that.applicationsService.eventGroupData.push(response);
        that.eventGroupService.eventGroupsData.push(response);
        let subscriptionData ={
          "eventGroupName":response.name[0].text,
          "eventGroupId":response.id,
          "routeId":route.id
        }
        that.applicationsService.subscriptionsData.push(subscriptionData);
        if(response)
        {
          that.eventGroupService.selection.select(response.id);
        }
      }
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        //that.applicationsService.errorMessage= err.responseJSON.apiMessage;
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  });
  }

  private checkStreamApplication() {
    const that = this;
    if(that.streamApplicationResponse.length === 0) {
      that.getStreamApplications(that.applicationId);
      
      setTimeout(()=>{ 
        that.streamData.forEach(stream => {
          var added = false;
          that.streamApplicationResponse.forEach(streamApplication => {
            if (!added && streamApplication.id == stream.id) {
              added = true;
            }
          });
          if (added) {
            stream.isAdded = "Added";
          } else {
            stream.isAdded = "Add";
          }
        });
      }, 2000);

    } else {
      that.streamData.forEach(stream => {
        var added = false;
        that.streamApplicationResponse.forEach(streamApplication => {
          if (!added && streamApplication.id == stream.id) {
            added = true;
          }
        });
        if (added) {
          stream.isAdded = "Added";
        } else {
          stream.isAdded = "Add";
        }
      });
    }

  }

  onStreamSearch(event: any){
    const that = this;
    console.log(event.target.value);
    that.streamSelected = event.target.value;
    
    let startValue = '*'+event.target.value;
    let endValue = event.target.value + '*';
    that.countStreams('*'+event.target.value+'*');
    that.currentPage = 1;
    that.iotService.getCuiObjResponse().getStreams({
      qs:[['pageSize',5],['page',that.currentPage],['name',startValue],['name',endValue]]
    }).then(function(response) {
      that.streamData = response;
      that.checkStreamApplication();
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.applicationsService.errorMessage= err.responseJSON.apiMessage;
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      } 
    });
  }

  getSelectedStreamForCurrentPage(streamSelected){
    const that = this;
    that.streamSelected = streamSelected;
    let name = '*'+streamSelected +'*';
    that.iotService.getCuiObjResponse().getStreams({
      qs:[['pageSize',5],['page',that.currentPage],['name',name]]
    }).then(function(response) {
      that.streamData = response;
      that.checkStreamApplication();
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.applicationsService.errorMessage= err.responseJSON.apiMessage;
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      } 
    });
  }

  disableStream(stream, streamId){
    const that = this;
    that.iotService.getCuiObjResponse().deactivateStream({
      streamId: streamId
    }).then(function(response) {
      console.log("disabled Stream");
      stream.streamState = 'INACTIVE';
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.applicationsService.errorMessage= err.responseJSON.apiMessage;
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }
  
  enableStream(stream,streamId){
    const that = this;
    that.iotService.getCuiObjResponse().activateStream({
      streamId: streamId
    }).then(function(response) {
      console.log("enabled Stream");
      stream.streamState = 'ACTIVE';
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.applicationsService.errorMessage= err.responseJSON.apiMessage;
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  deleteApplication(applicationId) {
    const that = this;
    that.iotService.getCuiObjResponse().deleteApplication({
      applicationId: applicationId
    }).then(function(response) {
      console.log("deleted Application");
      that.applicationsService.successMessage="Application deleted with id: "+ applicationId;
      that.router.navigateByUrl("/applications");
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.applicationsService.warningMessage=err.responseJSON;
        alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        alert("Something went wrong. Please check chrome console or check with dev team");
      }
    }); 
  }

  getStreamApplications(applicationId) {
    const that = this;
    that.streamApplicationResponse = [];
    that.currentApplicationStream = 0;
    that.applicationStream = '';
    that.iotService.getCuiObjResponse().getStreams({
      qs:[['ownerId',applicationId]]
    }).then(function(response) {
      if(response != null) {
        that.streamApplicationResponse = response;
        that.applicationStream = that.streamApplicationResponse[0];
        if(that.applicationStream){
          that.showEventGroupCreationPage=true;
          that.loadRoutes(that.applicationStream);
        }
      }
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.applicationsService.errorMessage= err.responseJSON.apiMessage;
        alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        alert("Something went wrong. Please check chrome console or check with dev team");
      }
    }); 
  }

  prevStreamPage(){
    const that = this;
    let currentApplicationStream = that.currentApplicationStream-1;
    if (currentApplicationStream < 0){
       currentApplicationStream= 0; 
    }
    that.currentApplicationStream = currentApplicationStream;
    that.applicationStream = that.streamApplicationResponse[currentApplicationStream];
    that.loadRoutes(that.applicationStream);
  }

  nextStreamPage(){
    const that = this;
    let currentApplicationStream = that.currentApplicationStream+1;
    if (currentApplicationStream == that.streamApplicationResponse.length) {
       currentApplicationStream= that.streamApplicationResponse.length-1; 
    }
    console.log("nextStreamPage"+currentApplicationStream);
    that.currentApplicationStream = currentApplicationStream;
    that.applicationStream = that.streamApplicationResponse[currentApplicationStream];
    that.loadRoutes(that.applicationStream);
  }

  selectStream(stream){
    const that = this;
    var obj = {
      "creator": that.application.creator,
      "creatorAppId": that.application.creatorAppId,
      "realm": that.application.realm,
      "streamId": stream.id, 
      "deviceId":that.application.id
    }
    that.iotService.getCuiObjResponse().addDeviceToStream({
      streamId: stream.id,
      data:obj
    }).then(function(streamApplicationResponse) {
      that.getStreamApplications(that.application.id);
      console.log("stream application added -- "+streamApplicationResponse.length);
      stream.isAdded = "Added";
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.applicationsService.errorMessage= err.responseJSON.apiMessage;
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  toggleIcon(applicationId) {
    const that = this;
    if (that.icon === that.faToggleOffIcon) {
      that.icon = that.faToggleOnIcon;
      that.toggleEnable = 'toggle-on';
      that.iotService.getCuiObjResponse().activateApplications({
        deviceId: applicationId
      }).then(function(response) {
        that.application.version = parseInt(that.application.version) + 1;
        that.application.isActive = true;
      }).fail(function (err){
        if(err.responseJSON.apiStatusCode){
          that.applicationsService.errorMessage= err.responseJSON.apiMessage;
          alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
        } else{
          alert("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    } else {
      that.icon = that.faToggleOffIcon;
      that.toggleEnable = 'toggle-off';
      that.iotService.getCuiObjResponse().deactivateApplications({
        applicationId: applicationId
      }).then(function(response) {
        that.application.version = parseInt(that.application.version) + 1;
        that.application.isActive = false;
      }).fail(function (err){
        if(err.responseJSON.apiStatusCode){
          that.applicationsService.errorMessage= err.responseJSON.apiMessage;
          alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
        } else{
          alert("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    }
  }

  updateApplication() {
    const that = this;
    var applicationForUpdate = JSON.parse(JSON.stringify(that.application));
    console.log("applicationForUpdate"+applicationForUpdate);

    //checking name object
    if (applicationForUpdate.name != null && applicationForUpdate.name[0].text != "") {
      if (applicationForUpdate.name[0].text != null && applicationForUpdate.name[0].lang == null) {
          delete applicationForUpdate.name;
          applicationForUpdate.name = [];
          applicationForUpdate.name.push({ lang: 'en-us', text: that.application.name[0].text });
      }
    }

    //checking description object
    if (applicationForUpdate.description != null && applicationForUpdate.description[0].text != "") {
      if (applicationForUpdate.description[0].text != null && applicationForUpdate.description[0].lang == null) {
          delete applicationForUpdate.description;
          applicationForUpdate.description = [];
          applicationForUpdate.description.push({ lang: 'en-us', text: that.application.description[0].text });
      }
    }

    that.iotService.getCuiObjResponse().updateApplication({
      applicationId: applicationForUpdate.id,
      data: applicationForUpdate
    }).then(function(response) {
      that.application.version=response.version;
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.applicationsService.errorMessage= err.responseJSON.apiMessage;
        alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        alert("Something went wrong. Please check chrome console or check with dev team");
      }
    });

    }

  add(event: MatChipInputEvent): void {
    const that = this;
    that.tagClass = 'tagInput';
    const input = event.input;
    const value = event.value;
    var indice =0;
    if(that.application.tags != undefined){
      indice = that.application.tags.indexOf(value);
    }else{
      indice = -1;
    }
    if(indice<0){
    // Add our fruit
    if ((value || '').trim()) {
      const that = this;
      that.iotService.getCuiObjResponse().createApplicationTag({
        applicationId: that.application.id,
        tag: value
      }).then(function(response) {
        console.log("added tag:"+value+" to Application :"+that.application.id);
          that.application.version = parseInt(that.application.version) + 1;
          if(that.application.tags == undefined) {
            that.application.tags = [];
          }
          const index = that.application.tags.indexOf(value);
          if (index < 0) {
            that.application.tags.push(value);
            input.value = '';
          }
          // Reset the input value
          
      }).fail(function (err){
        if(err.responseJSON.apiStatusCode){
          that.applicationsService.errorMessage= err.responseJSON.apiMessage;
          alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
        } else{
          alert("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    }
  }else{
      that.tagClass = 'tagInput-duplicate';
    }

  }

  remove(tag: string): void {
    const that = this;
    if(tag != undefined){
      that.iotService.getCuiObjResponse().deleteApplicationTag({
        applicationId: that.application.id,
        tag: tag
      }).then(function (response) {
          console.log("removed tag:"+tag+" to Application :"+that.application.id);
          that.application.version = parseInt(that.application.version) + 1;

          const index = that.application.tags.indexOf(tag);

          if (index >= 0) {
            that.application.tags.splice(index, 1);
          }

      }).fail(function (err){
        if(err.responseJSON.apiStatusCode){
          that.applicationsService.errorMessage= err.responseJSON.apiMessage;
          alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
        } else{
          alert("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    }
  }

  removeSubscriptions(subscriptionData): void {
    const that = this;
    that.iotService.getCuiObjResponse().deleteRoute({
      routeId: subscriptionData.routeId
    }).then(function(response): void {
        that.getStreamApplications(that.applicationId);
        that.eventGroupService.selection.deselect(subscriptionData.eventGroupId);
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.applicationsService.errorMessage= err.responseJSON.apiMessage;
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  getEventGroup(resourceId) {
    const that = this;
    that.iotService.getCuiObjResponse().getEventSource({
        eventSourceId: resourceId
    }).then(function(response): void {
      if(response) {
        return response;
      }
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.applicationsService.errorMessage= err.responseJSON.apiMessage;
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  countStreams(val) {
    const that = this;
    that.showPagination =false;
    if(val === "**") {
      val = '';
    }
    if(val === '') {
      that.iotService.getCuiObjResponse().countStreams({
      }).then(function (response) {
        console.log("countStreams : "+response);
        that.streamCount = response;
        that.showPagination = true;
      }).fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          that.applicationsService.errorMessage= err.responseJSON.apiMessage;
          console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
        } else {
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    } else {
      that.iotService.getCuiObjResponse().countStreams({
        qs:[['name',val]]
      }).then(function (response) {
        console.log("countStreams : "+response);
        that.streamCount = response;
        that.showPagination = true;
      }).fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          that.applicationsService.errorMessage= err.responseJSON.apiMessage;
          console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
        } else {
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    }
  }

  onPageChange(page) {
    const that = this;
    console.log("page : "+page);
    that.currentPage = page;
    
    if(that.streamSelected) {
      console.log("streamSelected"+that.streamSelected);
      that.getSelectedStreamForCurrentPage(that.streamSelected);
    } else {
      console.log("else not streamSelected");
      that.loadStream();
    }
  }

  createNewStream(){ 
    const that = this;
    that.showStreamCreationPage=true;
    that.applicationStream=undefined;
    that.showEventGroupCreationPage=false;
    that.streamService.setOwnerId(that.applicationId);
    that.iotService.sideNavStream = false;
  }

  getSubscriptions(applicationStream){
    const that = this;
    that.applicationsService.sourceStream=applicationStream;
    return that.applicationsService.subscriptionsData;
  }
}
import { Injectable } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { IotService } from '../service/iot.service';
import { ListDigitalTwinsService } from './ListDigitalTwins.service';
import { StreamService } from './stream.service';
import { RoutesService } from './routes.service';
import { MessageGovernancesService } from '../service/messageGovernances.service';
import { throwMatDuplicatedDrawerError } from '@angular/material/sidenav';
import { RulesService } from '../service/rules.service';
import { EcosystemMembersService } from './ecosystemMembers.service';

@Injectable({
	providedIn: 'root'
})

export class DigitalTwinsService extends ListDigitalTwinsService {
	selection: SelectionModel<String> = new SelectionModel<String>(true, []);

	events = [];
	commands = [];
	geofences = [];
	attributes = [];

	constructor(public iotService: IotService, public streamService: StreamService, public routeService: RoutesService, public messageGovernancesService: MessageGovernancesService, public ruleService: RulesService, public ecosystemMemberService:EcosystemMembersService) {
		super(iotService);
		this.selection.clear();
		this.loadSelected();
	}

	
	getEvents(): any {
		return this.events;
	  }
	
	  setEvents(events: any) {
		this.events = events;
	  }

	  getCommands(): any {
		return this.commands;
	  }
	
	  setCommands(commands: any) {
		this.commands = commands;
	  }
	  getAttributes(): any {
		return this.attributes;
	  }
	
	  setAttributes(attributes: any) {
		this.attributes = attributes;
	  }
	  getGeofences(): any {
		return this.geofences;
	  }
	
	  setGeofences(geofences: any) {
		this.geofences = geofences;
	  }

	fnOnSelectionSupport(results) {
		const that = this;
		if (that.iotService.createRoute == false) {
			that.streamService.setOwnerId(results.id);
			that.streamService.selectedDevice = true;
			that.streamService.setDeviceSelected(results.name[0].text)
		}
		if (that.iotService.createRoute == true) {
			this.routeService.setDevice(results);
		}
		if (that.iotService.createMessageGovernance == true) {
			let devices = that.messageGovernancesService.getDevices();
			devices.push(results);
			that.messageGovernancesService.setDevices(devices);
		}
		if (that.iotService.createRule == true) {
			if(that.iotService.createGatewayRule)
			{
				let devices = [];
				devices.push(results);
				that.ruleService.setDevices(devices);
			}
			else{
				let devices = that.ruleService.getDevices();
				devices.push(results);
				that.ruleService.setDevices(devices);
			}	
		}

		if (that.iotService.createGatewayRule) {
			that.ruleService.setGatewayDevice(results);
      		that.ruleService.selectedGatewayDevice=false;
		}

		if(that.iotService.createEcosystemMembership == true) {
			that.ecosystemMemberService.setSelectedDeviceId(results.id);
		}
	}

	fnRemoveSelectionSupport(results) {
		const that = this;
		if (that.iotService.createRoute == false) {
			that.streamService.setOwnerId(undefined);
			that.streamService.selectedDevice = false;
			that.streamService.setDeviceSelected(undefined);
		}
		if (that.iotService.createRoute == true) {
			this.routeService.setDevice(undefined);
		}

		if (that.iotService.createMessageGovernance == true) {
			let devices = that.messageGovernancesService.getDevices();
			devices.forEach((value, index) => {
				if (value.id == results.id) devices.splice(index, 1);
			});
			that.messageGovernancesService.setDevices(devices);

		}

		if (that.iotService.createRule == true) {
			let devices = that.ruleService.getDevices();
			devices.forEach((value, index) => {
				if (value.id == results.id) devices.splice(index, 1);
			});
			that.ruleService.setDevices(devices);
		}

		if (that.iotService.createGatewayRule == true) {
			that.ruleService.setGatewayDevice(undefined);
      		that.ruleService.selectedGatewayDevice=false;
		}

		if(that.iotService.createEcosystemMembership == true) {
			that.ecosystemMemberService.setSelectedDeviceId(undefined);
		}
	}
	loadSelected() {
		const that =this;
		if (this.routeService.getDevice() && this.iotService.createRoute==true) {
			this.selection.select(this.routeService.getDevice().id);
		}
		if(this.messageGovernancesService.getDevices().length>0 && this.iotService.msgGovLoader==true){
			this.messageGovernancesService.getDevices().forEach(device => {
				this.selection.select(device.id);
			});	
			
		}
		if(this.ruleService.getDevices().length>0 && this.iotService.viewRule==true){
			this.ruleService.getDevices().forEach(device => {
				this.selection.select(device.id);
			});		
		}

		if(that.ruleService.getGatewayDevice()!=undefined && that.iotService.viewRule==true){
			that.selection.select(that.ruleService.device.id);
		}
	}
}

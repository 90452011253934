import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IotService } from '../service/iot.service';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { TranslateService } from '@ngx-translate/core';
import { faCopy } from '@fortawesome/free-solid-svg-icons';



@Component({
  selector: 'app-copy-resource-id',
  templateUrl: './copy-resource-id.component.html',
  styleUrls: ['./copy-resource-id.component.scss']
})
export class CopyResourceIdComponent implements OnInit {
  supportLanguages = ['en'];
  faCopyIcon = faCopy;
  deviceId;

  constructor(private router: Router, private route: ActivatedRoute,  private iotService:IotService,private sideNavComponent:SideNavComponent,private translateService: TranslateService) { 
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      //console.log(params['id']);
      this.deviceId = params['id'];
    });
  }

}

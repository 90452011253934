<div class="certificate-detail" *ngIf="certificate">
    <div style="margin-left: -20px;">
        <app-copy-resource-id></app-copy-resource-id>
    </div>
    <h1>{{'view certificate' | translate}}</h1>
    <form >
        <label for="subject">  {{'subject' | translate}}</label>
        <p id="subject">{{certificate.subject}}</p>
        <label for="expiration">  {{'expiration' | translate}}</label>
        <p id="expiration">{{certificate.expiration| date:'MMM d, y, h:mm:ss a' }}</p>
        <label for="Creation">  {{'creation' | translate}}</label>
        <p id="Creation">{{certificate.creation| date:'MMM d, y, h:mm:ss a' }}</p>
        <label for="data"> {{'certificateData' | translate}}</label>
        <p id="data" style="overflow-wrap: anywhere; ">{{certificate.certData }}</p>
        <p *ngIf="certificate.isRevoked" style="color:red"> {{'this authority has been revoked' | translate}} </p>
        <button [cdkCopyToClipboard]=certificate.certData class="btn btn-secondary"><fa-icon [icon]="faCopyIcon"></fa-icon> {{'cdkCopyToClipboard' | translate}}</button>
        <button *ngIf="!privateKeyLoaded" class="btn btn-secondary" (click)="addPrivateKey()"> {{'add private key' | translate}}</button>
        <button *ngIf="!certificate.isRevoked && !isRevoke" class="btn btn-delete pull-right"(click)="toggleRevoke()"> {{'revoke' | translate}}</button>
        <div *ngIf="isRevoke" style="float: right;">
            <h6>{{'are sure you want to revoke certificate?' | translate}}</h6>
            <button class="new-btn" mat-raised-button (click)="revokex509Certificate(certificate.id)">{{'yes' | translate}}</button>
            <button class="new-btn" mat-raised-button (click)="toggleRevoke()">{{'no' | translate}}</button>
        </div>
        <div *ngIf="isPrivateKey">
            <br>
            <textarea rows="10" value={{privateKey2}} (keyup)="addPrivateKeyData($event)"></textarea>
            <p *ngIf="errorMessage" style="color: red;">{{errorMessage}}</p>
            <p *ngIf="successMessage" style="color: green;">{{successMessage}}</p>
            <button class="btn btn-secondary" (click)="addTrustedPrivateKey(certificate.id)">{{'create' | translate}}</button>
        </div>
        <div *ngIf="privateKeyLoaded">
            <label for="privateKey"> {{'private key' | translate}}</label>
            <p id="privateKey" style="overflow-wrap: anywhere; ">{{trustedPrivateKey.privateKey }}</p>
        </div>
    </form>
</div>

<ng-container *ngIf="!certificate">
    <div>
        <br/><br/>
    </div>
    <div class="d-flex justify-content-center pageLoading">
        <div class="spinner-border" role="status">
            <span class="sr-only" id="loading"></span>
        </div>
    </div>
</ng-container>
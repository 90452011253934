import { Component, OnInit } from '@angular/core';
import { IotService } from '../service/iot.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { faHeartbeat,faExclamationTriangle,faTimes} from '@fortawesome/free-solid-svg-icons';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { StreamService } from '../service/stream.service';
import { ChartOptions, ChartType } from 'chart.js';
import { ChartsModule } from 'ng2-charts';
import 'chartjs-plugin-labels';
import { SingleDataSet, Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import { MapService } from '../service/map.service';
import { threadId } from 'worker_threads';


@Component({
  selector: 'app-dashboard-device-detail',
  templateUrl: './dashboard-device-detail.component.html',
  styleUrls: ['./dashboard-device-detail.component.scss']
})
export class DashboardDeviceDetailComponent implements OnInit {

  separatorKeysCodes: number[] = [ENTER, COMMA];

  faHeartBeat = faHeartbeat;
  faExclamationTriangle=faExclamationTriangle;
  faClose=faTimes;

  toggleEnable = 'toggle-on';
  deviceActive: boolean = true;
  errorMessage='';
  errorMessage2='';

  matVersion: string = '5.1.0';
  breakpoint: number;
  device;
  streamDeviceResponse = [];
  deviceStream;
  currentDeviceStream = 0;

  pieChart_data = [8, 10, 12];
  pieChartbackgroundColor = ['gray', 'skyblue', 'blue'];
  pieChartHoverbackgroundColor = ['gray', 'skyblue', 'blue'];
  pieChartborderColor = ['gray', 'skyblue', 'blue'];
  public pieChartData: SingleDataSet = [8, 10, 12];
  // public pieChartType: ChartType = 'pie';
  public doughnutChartType: ChartType = 'doughnut';

  public pieChartLabels: Label[] = ['LastHour', 'LastWeek', 'Last 24 hours'];



  pieChart_data2 = [18, 62, 85];
  pieChartbackgroundColor2 = ['blue', 'yellow', 'green'];
  pieChartHoverbackgroundColor2 = ['blue', 'yellow', 'green'];
  pieChartborderColor2 = ['blue', 'yellow', 'green'];
  public pieChartData2: SingleDataSet = [18, 62, 85];
  // public pieChartType: ChartType = 'pie';

  public pieChartLabels2: Label[] = ['LastHour', 'LastWeek', 'Last 24 hours'];

  startTime: any;
  startDate: any;
  endTime: any;
  endDate: any;

  dateTimeRange = "MINUTE";
  groups = [
    { id: "MINUTE", name: "Minute" },
    { id: "HOUR", name: "Hour" },
    { id: "DAY", name: "Day" },
    { id: "WEEK", name: "Week" },
    { id: "MONTH", name: "Month" },
    { id: "YEAR", name: "Year" }
  ];

  // statusPendingClass = 'bg-lifecycle';
  // statusActiveClass = 'bg-lifecycle';
  // statusSuspendedClass = 'bg-lifecycle';
  // statusDeletedClass = 'bg-lifecycle';
  // attributeData=[];
  // attributeColumns: string[] = ['Name', 'Data Type', 'Value'];
  eventData = [];
  // previousEventElement;
  // previousCommandElement;
  commandData = [];

  currentPage = 1;
  streamSelected: string;

  updateNameDesc: boolean = true;
  updateAttribute: boolean = true;

  public pieChartOptions: any = {
    segmentShowStroke: false,
    scaleShowVerticalLines: false,
    // maintainAspectRatio: false,
    // responsive: true,
  }

  public pieChartOptions2: any = {
    segmentShowStroke: false,
    scaleShowVerticalLines: false,
    // maintainAspectRatio: false,
    // responsive: true,
  }

  pageSize = 5;
  streamCount: number = 0;
  showPagination: boolean = false;

  tagClass = 'tagInput';
  tagList = [];
  supportLanguages = ['en'];
  auditFlag = false;
  auditRefreshFlag = true;
  deviceId;
  geoTableData = [];
  endDateTime: number;
  startDateTime: number;
  pathCoordinates = [];
  pathTime = [];
  loadDeviceTrackingMap = false;
  lastHourCount = 0;
  last24HoursCount = 0;
  lastWeekCount = 0;
  totalEventCount = 0;
  loadEventCount = false;
  loadCommandCount = false;
  public doughnutChartLabels: Label[] = ['LastHour', 'Last 24 hours', 'LastWeek'];
  doughnutColors = [
    {
      backgroundColor:['gray', 'skyblue','rgba(70, 130, 180, 0.938)'],
      hoverBackgroundColor: ['gray', 'skyblue','rgba(70, 130, 180, 0.938)'],
      borderWidth: [0, 0, 0, 0],
      hoverBorderWidth: [5, 5, 5, 5],
      hoverBorderColor: ['gray', 'skyblue','rgba(70, 130, 180, 0.938)'],

    }
   
  ];

  public doughnutChartData: SingleDataSet = [
    this.lastHourCount, this.last24HoursCount, this.lastWeekCount

  ];

  public doughnutChartOptions: any = {
    segmentShowStroke: false,
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      position: 'bottom',
      display: true,
      onClick: null,


      labels: {
        boxWidth: 20,
        fontSize: 15,
        // data:10,
        fontColor: 'black',
        padding: 10
      }
    },
    // no percentage

    // plugins: {
    //   labels: false
    // }


    plugins: {
      labels: {
        //change the value to percentage inorder to render percentage
        render: 'value',
        responsive: true,
        fontSize: 20,
        fontColor: ["white", "white", "white"],
        precision: 1,
        overlap: false,
      }
    }
  }
  attributesList: any[];

  lastHourCountCommands = 0;
  last24HoursCountCommands = 0;
  lastWeekCountCommands = 0;
  totalEventCountCommands = 0;

  public doughnutChartLabels1: Label[] = ['LastHour', 'Last 24 hours', 'LastWeek'];
  doughnutColors1 = [
    {
      backgroundColor:['gray', 'skyblue','rgba(70, 130, 180, 0.938)'],
      hoverBackgroundColor: ['gray', 'skyblue','rgba(70, 130, 180, 0.938)'],
      borderWidth: [0, 0, 0, 0],
      hoverBorderWidth: [5, 5, 5, 5],
      hoverBorderColor: ['gray', 'skyblue','rgba(70, 130, 180, 0.938)'],

    }
  ];

  public doughnutChartData1: SingleDataSet = [
    this.lastHourCountCommands, this.last24HoursCountCommands, this.lastWeekCountCommands

  ];

  public doughnutChartOptions1: any = {
    segmentShowStroke: false,
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      position: 'bottom',
      display: true,
      onClick: null,


      labels: {
        boxWidth: 20,
        fontSize: 15,
        // data:10,
        fontColor: 'black',
        padding: 10
      }
    },
    // no percentage

    // plugins: {
    //   labels: false
    // }


    plugins: {
      labels: {
        //change the value to percentage inorder to render percentage
        render: 'value',
        responsive: true,
        fontSize: 20,
        fontColor: ["white", "white", "white"],
        precision: 1,
        overlap: false,
      }
    }
  }

  eventArray = [];
  commandArray = [];
  currentDeviceId;
  eventValue = '--select--';
  commandValue = '--select--';
  onEventDataSet = false;
  constructor(private router: Router, private route: ActivatedRoute, private iotService: IotService,
    private location: Location, private sideNavComponent: SideNavComponent, private translateService: TranslateService,
    private mapService: MapService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');

  }


  ngOnInit() {
    this.iotService.deviceDashboard = true;

    this.route.params.subscribe(params => {
      //console.log(params['id']);
      var deviceId = params['id'];
      this.deviceId = deviceId;
      this.loadDevice(deviceId);
      this.deviceViolationReport(deviceId);
    });
    const that=this;
    that.mapService.setLatitude(undefined);
    that.mapService.setLongitude(undefined);
    that.mapService.setPathCoordinates([]);
    that.mapService.setPathTimes(undefined);

  }


  // deviceViolationReport
  deviceViolationReport(violatedDeviceId) {
    this.attributesList = [];
    const that = this;
    //console.log(this.violatedDeviceIdArg);
    //console.log(that.violatedDeviceIdArg);
    this.iotService.getCuiObjResponse().getDeviceDetailedViolations({
      violatedDeviceId: violatedDeviceId
    }).then(function (response) {
      console.log("response -- deviceViolationReport");
      console.log(response);
      if (response.deviceViolations[0].devices.length > 0) {
        that.attributesList = response.deviceViolations[0].devices[0].attributes;
      }
      else {
        that.attributesList = [];

      }
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  getTimezoneOffset() {
    function z(n) { return (n < 10 ? '0' : '') + n }
    var offset = new Date().getTimezoneOffset();
    var sign = offset < 0 ? '+' : '-';
    offset = Math.abs(offset);
    return sign + z(offset / 60 | 0) + z(offset % 60);
  }

  getDeviceTracking() {
    const that = this;
    that.errorMessage='';
    that.errorMessage2='';
    // that.loadDeviceTrackingMap=false;
    that.mapService.setLatitude(undefined);
    that.mapService.setLongitude(undefined);
    that.mapService.setPathCoordinates([]);
    that.mapService.setPathTimes(undefined);
    that.pathCoordinates = [];
    that.pathTime = [];
    if (!that.validateData()) {

      var group = that.dateTimeRange;
      var timeZoneOffSet = that.getTimezoneOffset();

        that.iotService.getCuiObjResponse().getDeviceGeolocationTracking({
          deviceId: that.device.id,
          startTime: that.startDateTime,
          endTime: that.endDateTime,
          group: group,
          timeZone: timeZoneOffSet

      })
          .then(function (resp) {
            var response;
      // response=response;
      response=resp;
      console.log('response geolocation = ' + JSON.stringify(resp));
      var locations = response.location;
      var lat;
      var lng;
      for (var i = 0; i < locations.length; i++) {

        var tempLoc = {
          lat: 0,
          lng: 0
        };
        tempLoc.lat = parseFloat(locations[i].latitude);
        tempLoc.lng = parseFloat(locations[i].longitude);
        that.pathCoordinates.push(tempLoc);
        that.pathTime.push(locations[i].datetimereceived);
      }
      that.mapService.setLatitude(that.device.latitude);
      that.mapService.setLongitude(that.device.longitude);
      that.mapService.setPathCoordinates(that.pathCoordinates);
      that.mapService.setPathTimes(that.pathTime);

      that.loadDeviceTrackingMap = !that.loadDeviceTrackingMap;

      // that.loadTrackingMap(that.device.latitude, that.device.longitude, that.pathCoordinates, that.pathTime);
      })
      .fail(function (err) {
        that.loadDeviceTrackingMap = !that.loadDeviceTrackingMap;
        if (err.responseJSON.apiStatusCode) {
          // alert(err.responseJSON.apiMessage);
        
          if(err.responseJSON.apiMessage=='No records found for the selected time range and interval. Please choose different time range or interval.'){
            that.errorMessage2=err.responseJSON.apiMessage;
          }
          else{
            that.errorMessage=err.responseJSON.apiMessage;
          }
        } else {
          alert("Something went wrong. Please check chrome console or check with dev team");
        }
      });

    }
  }

  activateHealth() {
    const that = this;
    this.iotService.getCuiObjResponse().activateHealth({
      deviceId: that.device.id
    }).then(function (response) {
      console.log("activated device health");
      that.device.deviceHealthState.isActive = true;

    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }


  deactivateHealth() {
    const that = this;
    this.iotService.getCuiObjResponse().deactivateHealth({
      deviceId: that.device.id
    }).then(function (response) {
      console.log("deactivated deviceHealth ");
      that.device.deviceHealthState.isActive = false;

    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }

  validateData() {
    const that = this;
    var hasError = false;
    if (that.startDate != 0 && that.startDate != null && that.startDate != undefined) {

      var date = new Date(that.startDate);
      var day = date.getDate();
      var month = date.getMonth();
      var year = date.getFullYear();
    }
    else {
      hasError = true;
      that.errorMessage="Please enter start date";
      // that.gatewaysService.warningMessage="Start Date is required";
      return hasError;
    }

    if (that.startTime != 0 && that.startTime != null && that.startTime != undefined) {
      var hours = that.startTime.split(":")[0];
      var minutes = that.startTime.split(":")[1];
      var seconds = that.startTime.split(":")[2];

    }
    else {
      // hasError = true;
      // that.deviceService.warningMessage="Start Time is required";
      // return hasError;
    }
    if (that.startDate != 0 && that.startDate != null && that.startDate != undefined ) {

      var startDateTime = new Date(new Date(year, month, day)).getTime();

      that.startDateTime = startDateTime;
    }


    if (that.startDate != 0 && that.startDate != null && that.startDate != undefined && that.startTime != 0 && that.startTime != null && that.startTime != undefined) {

      var startDateTime = new Date(new Date(year, month, day,
        hours, minutes, seconds)).getTime();

      that.startDateTime = startDateTime;
    }


    if (that.endDate != 0 && that.endDate != null && that.endDate != undefined) {

      var date = new Date(that.endDate);
      var day = date.getDate();
      var month = date.getMonth();
      var year = date.getFullYear();
    }
    else {
      that.errorMessage="Please enter end date";
      hasError = true;
      return hasError;
    }

    if (that.endTime != 0 && that.endTime != null && that.endTime != undefined) {
      var hours = that.endTime.split(":")[0];
      var minutes = that.endTime.split(":")[1];
      var seconds = that.endTime.split(":")[2];

    }
    
    if (that.endDate != 0 && that.endDate != null && that.endDate != undefined) {

      var endDateTime = new Date(new Date(year, month, day)).getTime();

      that.endDateTime = endDateTime;
    }

    if (that.endDate != 0 && that.endDate != null && that.endDate != undefined && that.endTime != 0 && that.endTime != null && that.endTime != undefined) {

      var endDateTime = new Date(new Date(year, month, day,
        hours, minutes, seconds)).getTime();

      that.endDateTime = endDateTime;
    }

    return hasError;

  }

  loadEventData(device) {
    const that = this;
    that.eventData = [];
    if (device.observableEvents && device.observableEvents.length != 0) {
      device.observableEvents.forEach(eventTemplate => {
        var endTime = new Date().getTime();
        var Range1 = new Date();
        var Range2 = new Date();
        var Range3 = new Date();
        var eventCount1 = 0;
        var eventCount2 = 0;
        var eventCount3 = 0;
        Range1.setHours(Range1.getHours() - 1);
        Range2.setHours(Range2.getHours() - 24);
        Range3.setHours(Range3.getHours() - 168);
        that.iotService.getCuiObjResponse().countEventAudits({
          startTime: Range1.getTime(),
          endTime: endTime,
          qs: [
            ['eventTemplateId', eventTemplate.id],
            ['deviceId', device.id],
            ['receivedTimestamp', '>' + Range1.getTime()],
            ['receivedTimestamp', '<' + endTime]
          ]
        }).then(function (eventCount1Response) {
          eventCount1 = eventCount1Response;
        })
        that.iotService.getCuiObjResponse().countEventAudits({
          startTime: Range2.getTime(),
          endTime: endTime,
          qs: [
            ['eventTemplateId', eventTemplate.id],
            ['deviceId', device.id],
            ['receivedTimestamp', '>' + Range2.getTime()],
            ['receivedTimestamp', '<' + endTime]
          ]
        }).then(function (eventCount2Response) {
          eventCount2 = eventCount2Response;
        })
        that.iotService.getCuiObjResponse().countEventAudits({
          startTime: Range3.getTime(),
          endTime: endTime,
          qs: [
            ['eventTemplateId', eventTemplate.id],
            ['deviceId', device.id],
            ['receivedTimestamp', '>' + Range3.getTime()],
            ['receivedTimestamp', '<' + endTime]
          ]
        }).then(function (eventCount3Response) {
          eventCount3 = eventCount3Response;
        })

        setTimeout(() => {
          var obj = {
            "eventTemplate": eventTemplate,
            "eventCount1": eventCount1,
            "eventCount2": eventCount2,
            "eventCount3": eventCount3,
            "messageClass": 'bg-message'
          }
          that.eventData.push(obj);
        }, 2000);

      });
    }
  }

  loadCommandData(device) {
    const that = this;
    that.commandData = [];
    if (device.supportedCommands && device.supportedCommands.length != 0) {
      device.supportedCommands.forEach(commandTemplate => {
        var endTime = new Date().getTime();
        var Range1 = new Date();
        var Range2 = new Date();
        var Range3 = new Date();
        var cmdCount1 = 0;
        var cmdCount2 = 0;
        var cmdCount3 = 0;
        Range1.setHours(Range1.getHours() - 1);
        Range2.setHours(Range2.getHours() - 24);
        Range3.setHours(Range3.getHours() - 168);
        that.iotService.getCuiObjResponse().countCommandAudits({
          startTime: Range1.getTime(),
          endTime: endTime,
          qs: [
            ['commandTemplateId', commandTemplate.id],
            ['deviceId', device.id],
            ['receivedTimestamp', '>' + Range1.getTime()],
            ['receivedTimestamp', '<' + endTime]
          ]
        }).then(function (count1Response) {
          cmdCount1 = count1Response;
        })
        that.iotService.getCuiObjResponse().countCommandAudits({
          startTime: Range2.getTime(),
          endTime: endTime,
          qs: [
            ['commandTemplateId', commandTemplate.id],
            ['deviceId', device.id],
            ['receivedTimestamp', '>' + Range2.getTime()],
            ['receivedTimestamp', '<' + endTime]
          ]
        }).then(function (count2Response) {
          cmdCount2 = count2Response;
        })
        that.iotService.getCuiObjResponse().countCommandAudits({
          startTime: Range3.getTime(),
          endTime: endTime,
          qs: [
            ['commandTemplateId', commandTemplate.id],
            ['deviceId', device.id],
            ['receivedTimestamp', '>' + Range3.getTime()],
            ['receivedTimestamp', '<' + endTime]
          ]
        }).then(function (count3Response) {
          cmdCount3 = count3Response;
        })

        setTimeout(() => {
          var obj = {
            "commandTemplate": commandTemplate,
            "cmdCount1": cmdCount1,
            "cmdCount2": cmdCount2,
            "cmdCount3": cmdCount3,
            "messageClass": 'bg-message'
          }
          that.commandData.push(obj);
        }, 2000);

      });
    }
  }

  loadDevice(deviceId) {
    const that = this;
    this.iotService.getCuiObjResponse().getDevice({
      deviceId: deviceId
    }).then(function (response) {
      console.log(response);
      that.device = response;

      that.sideNavComponent.menuClear();
      that.sideNavComponent.menuChange('Dashboard', 'sub-type');
      that.sideNavComponent.menuChange('devices.header', 'sub-type');
      that.sideNavComponent.menuChange(that.device.name[0].text, 'sub-type');

      if (!that.device.description) {
        that.device.description = [];
        that.device.description.push({ lang: 'en-us', text: "" });
      }

      that.deviceActive = that.device.isActive;

      // that.attributeData = that.device.attributes.standard; 
      that.loadEventData(that.device);
      that.loadCommandData(that.device);
      that.currentDeviceId = that.device.id;
      that.getDeviceEventData(that.device);
      that.getDeviceCommandData(that.device);

    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        alert("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        alert("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  base64String: string;

  commandMessage(command, expandedElement) {
    //console.log("command message -- "+expandedElement);
    const that = this;
    // if(that.previousCommandElement != undefined && that.previousCommandElement.commandTemplate.id != command.commandTemplate.id) {
    //   that.previousCommandElement.messageClass = 'bg-message';
    // }
    var base64 = {
      "messageId": "Device's message id here",
      "deviceId": that.device.id,
      "commandId": command.commandTemplate.id,
      "latitude": "valid latitude",
      "longitude": "valid longitude"
    };
    command.commandTemplate.args.forEach(value => {
      if (value.type == 'string') { base64[value.name] = "string" };
      if (value.type == 'decimal') { base64[value.name] = 1.1 };
      if (value.type == 'bool') { base64[value.name] = true };
      if (value.type == 'integer') { base64[value.name] = 1 };
    });
    if (expandedElement === null) {
      command.messageClass = 'bg-message';
    } else {
      command.messageClass = 'bg-message-selected';
    }
    // that.previousCommandElement = command;
    that.base64String = JSON.stringify(base64, null, 2);
  }

  eventMessage(event, expandedElement) {
    //console.log("event message -- "+expandedElement);
    const that = this;
    // if(that.previousEventElement != undefined && that.previousEventElement.eventTemplate.id != event.eventTemplate.id) {
    //   that.previousEventElement.messageClass = 'bg-message';
    // }
    var base64 = {
      "messageId": "Device's message id here",
      "deviceId": that.device.id,
      "eventTemplateId": event.eventTemplate.id,
      "latitude": "valid latitude",
      "longitude": "valid longitude"
    };

    event.eventTemplate.eventFields.forEach(value => {
      if (value.type == 'string') { base64[value.name] = "string" };
      if (value.type == 'decimal') { base64[value.name] = 1.1 };
      if (value.type == 'bool') { base64[value.name] = true };
      if (value.type == 'integer') { base64[value.name] = 1 };
    });
    if (expandedElement === null) {
      event.messageClass = 'bg-message';
    } else {
      event.messageClass = 'bg-message-selected';
    }
    // that.previousEventElement = event;
    that.base64String = JSON.stringify(base64, null, 2);
  }

  //viewDeviceTemplate
  viewDeviceTemplate(parentDeviceTemplateId) {
    this.router.navigateByUrl("/templates/" + parentDeviceTemplateId);
  }

  viewDevice(deviceId) {
    // console.log("**************************device Id",deviceId)
    this.router.navigateByUrl("/devices/" + deviceId);
  }


  viewRule(ruleId) {
    this.router.navigateByUrl("/rules/" + ruleId);
  }


  ngOnDestroy() {

    this.iotService.deviceDashboard = false;
  }


  onChangeEvent(eve) {
    const that = this;
    var tempId = eve.target.value;
    that.getDeviceEventLast24HoursCount(tempId);
    that.getDeviceEventLastHourCount(tempId);
    that.getDeviceEventLastWeekCount(tempId);

  }
  onChangeCommand(comd) {
    const that = this;
    var cmdId = comd.target.value;
    that.getDeviceCommandLast24HoursCount(cmdId);
    that.getDeviceCommandLastHourCount(cmdId);
    that.getDeviceCommandLastWeekCount(cmdId);

  }
  getDeviceCommandData(device) {
    const that = this;
    var i = 0;
    device.supportedCommands.forEach(commandTemplate => {
      if (i == 0) {
        that.commandValue = commandTemplate.id;
        that.getDeviceCommandLast24HoursCount(that.commandValue);
        that.getDeviceCommandLastHourCount(that.commandValue);
        that.getDeviceCommandLastWeekCount(that.commandValue);
      }
      var command = {
        value: '',
        viewValue: ''
      }
      command.value = commandTemplate.id;
      command.viewValue = commandTemplate.name;
      that.commandArray.push(command);
      i++;
    });
    that.onEventDataSet = true;
  }

  getDeviceEventData(device) {
    const that = this;
    var i = 0;
    device.observableEvents.forEach(eventTemplate => {
      if (i == 0) {
        that.eventValue = eventTemplate.id;
        that.getDeviceEventLast24HoursCount(that.eventValue);
        that.getDeviceEventLastHourCount(that.eventValue);
        that.getDeviceEventLastWeekCount(that.eventValue);
      }
      var event = {
        value: '',
        viewValue: ''
      }
      event.value = eventTemplate.id;
      event.viewValue = eventTemplate.name;
      that.eventArray.push(event);
      i++;
    });
    that.onEventDataSet = true;
  }

  getDeviceEventLast24HoursCount(eventTemplateId) {
    const that = this;
    var Range1 = new Date();
    var Range2 = new Date();
    var Range3 = new Date();
    Range1.setHours(Range1.getHours() - 1);
    Range2.setHours(Range2.getHours() - 24);
    Range3.setHours(Range3.getHours() - 168);
    var endTime = new Date().getTime();
    this.iotService.getCuiObjResponse().countEventAudits({
      startTime: Range2.getTime(),
      endTime: endTime,
      qs: [
        ['eventTemplateId', eventTemplateId],
        ['deviceId', that.device.id],
        ['receivedTimestamp', '>' + Range2.getTime()],
        ['receivedTimestamp', '<' + endTime]
      ]
    }).then(function (response) {
      that.last24HoursCount = response;
      that.doughnutChartData = [that.lastHourCount, that.last24HoursCount, that.lastWeekCount];
      that.loadEventCount = true;
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }

  getDeviceEventLastHourCount(eventTemplateId) {
    const that = this;
    var Range1 = new Date();
    var Range2 = new Date();
    var Range3 = new Date();
    Range1.setHours(Range1.getHours() - 1);
    Range2.setHours(Range2.getHours() - 24);
    Range3.setHours(Range3.getHours() - 168);
    var endTime = new Date().getTime();
    this.iotService.getCuiObjResponse().countEventAudits({
      startTime: Range1.getTime(),
      endTime: endTime,
      qs: [
        ['eventTemplateId', eventTemplateId],
        ['deviceId', that.device.id],
        ['receivedTimestamp', '>' + Range1.getTime()],
        ['receivedTimestamp', '<' + endTime]
      ]
    }).then(function (response) {
      that.lastHourCount = response;
      that.doughnutChartData = [that.lastHourCount, that.last24HoursCount, that.lastWeekCount];
      that.loadEventCount = true;

    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }

  getDeviceEventLastWeekCount(eventTemplateId) {
    const that = this;
    var Range1 = new Date();
    var Range2 = new Date();
    var Range3 = new Date();
    Range1.setHours(Range1.getHours() - 1);
    Range2.setHours(Range2.getHours() - 24);
    Range3.setHours(Range3.getHours() - 168);
    var endTime = new Date().getTime();
    this.iotService.getCuiObjResponse().countEventAudits({
      startTime: Range3.getTime(),
      endTime: endTime,
      qs: [
        ['eventTemplateId', eventTemplateId],
        ['deviceId', that.device.id],
        ['receivedTimestamp', '>' + Range3.getTime()],
        ['receivedTimestamp', '<' + endTime]
      ]
    }).then(function (response) {
      that.lastWeekCount = response;
      that.doughnutChartData = [that.lastHourCount, that.last24HoursCount, that.lastWeekCount];
      that.loadEventCount = true;

    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }
  getDeviceCommandLast24HoursCount(commandTemplateId) {
    const that = this;
    var Range1 = new Date();
    var Range2 = new Date();
    var Range3 = new Date();
    Range1.setHours(Range1.getHours() - 1);
    Range2.setHours(Range2.getHours() - 24);
    Range3.setHours(Range3.getHours() - 168);
    var endTime = new Date().getTime();
    this.iotService.getCuiObjResponse().countCommandAudits({
      startTime: Range2.getTime(),
      endTime: endTime,
      qs: [
        ['commandTemplateId', commandTemplateId],
        ['deviceId', that.device.id],
        ['creation', '>' + Range2.getTime()],
        ['creation', '<' + endTime]
      ]
    }).then(function (response) {
      that.last24HoursCountCommands = response;
      that.doughnutChartData1 = [that.lastHourCountCommands, that.last24HoursCountCommands, that.lastWeekCountCommands];
      that.loadCommandCount = true;

    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }

  getDeviceCommandLastHourCount(commandTemplateId) {
    const that = this;
    var Range1 = new Date();
    var Range2 = new Date();
    var Range3 = new Date();
    Range1.setHours(Range1.getHours() - 1);
    Range2.setHours(Range2.getHours() - 24);
    Range3.setHours(Range3.getHours() - 168);
    var endTime = new Date().getTime();
    this.iotService.getCuiObjResponse().countCommandAudits({
      startTime: Range1.getTime(),
      endTime: endTime,
      qs: [
        ['commandTemplateId', commandTemplateId],
        ['deviceId', that.device.id],
        ['creation', '>' + Range1.getTime()],
        ['creation', '<' + endTime]
      ]
    }).then(function (response) {
      that.lastHourCountCommands = response;
      that.doughnutChartData1 = [that.lastHourCountCommands, that.last24HoursCountCommands, that.lastWeekCountCommands];
      that.loadCommandCount = true;

    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }
  getDeviceCommandLastWeekCount(commandTemplateId) {
    const that = this;
    var Range1 = new Date();
    var Range2 = new Date();
    var Range3 = new Date();
    Range1.setHours(Range1.getHours() - 1);
    Range2.setHours(Range2.getHours() - 24);
    Range3.setHours(Range3.getHours() - 168);
    var endTime = new Date().getTime();
    this.iotService.getCuiObjResponse().countCommandAudits({
      startTime: Range3.getTime(),
      endTime: endTime,
      qs: [
        ['commandTemplateId', commandTemplateId],
        ['deviceId', that.device.id],
        ['creation', '>' + Range3.getTime()],
        ['creation', '<' + endTime]
      ]
    }).then(function (response) {
      that.lastWeekCountCommands = response;
      that.doughnutChartData1 = [that.lastHourCountCommands, that.last24HoursCountCommands, that.lastWeekCountCommands];
      that.loadCommandCount = true;

    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });


  }
}





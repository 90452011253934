import { Injectable } from '@angular/core';
import { SelectionModel} from '@angular/cdk/collections';
import { IotService } from '../service/iot.service'; 
import { ListTpApplicationsService } from './ListTpApplications.service';
import { TradingPartnersService } from './tradingPartners.service';

@Injectable({ 
	  providedIn: 'root' 
})

export class TpApplicationsService extends ListTpApplicationsService {
	 constructor(public iotService:IotService, public tpService:TradingPartnersService) {
		 super(iotService);
	 }

	 fnOnSelectionSupport(results){
		 const that = this; 
	 } 

	 fnRemoveSelectionSupport(results){ 
		 const that = this; 
	 } 

	 createNew(){
		 this.tpService.createApplication=true;
		 this.tpService.createResource=false;

	 }

	 showErrorMessage(err){
		if(err.responseJSON.apiStatusCode){
			this.tpService.errorMessage=err.responseJSON.apiMessage;
		}
		else{
			this.tpService.errorMessage="Some thing went wrong, Please check console ";
		}
	 }
	 

} 

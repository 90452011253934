<main id="device-details">
    <div class="d-flex justify-content-center loader" *ngIf="!device">
        <div class="spinner-border" role="status">
            <span class="sr-only" id="loading"></span>
        </div>
    </div>




</main>
<div *ngIf="device">

    <dl style="margin-left: 90px">
        <dt>{{'Device metadata' | translate}}</dt>
    </dl>
    <div class="metadata-box" style="margin-left: 90px" *ngIf="device">
        <div class="row" style="margin-left: -40px;">
            <dl>
                <dt>{{'device.name' | translate}}</dt>
                <dd>
                    <a (click)=viewDevice(device.id) title="View Device Details">{{device.name[0].text}}</a>
                </dd>
            </dl>
            <dl>
                <dt>{{'parent.template' | translate}}</dt>
                <dd>
                    <a (click)=viewDeviceTemplate(device.parentDeviceTemplateId)
                        title="View Template Details">{{device.parentDeviceTemplateName}}</a>
                </dd>
            </dl>
            <dl>
                <dt>{{'created' | translate}}</dt>
                <dd>{{device.creation | date:'mediumDate' }}</dd>
            </dl>
            <!-- <div class = "vertical2"></div> -->
            <dl>
                <dt>{{'presence.state' | translate}}</dt>
                <dd >{{device.state.operationalState}}</dd>
            </dl>

            <dl>
                <dt>{{'lifecycle' | translate}}</dt>
                <dd >{{device.state.lifecycleState}}</dd>
            </dl>

            <dl>
                <dt>{{'Operational State' | translate}}</dt>
                <dd >{{device.state.operationalState}}</dd>
            </dl>


        </div>


    </div>

    <dl style="margin-left: 90px">
        <dt>{{'Device Health' | translate}}</dt>
    </dl>
    <div class="health-box row" style="margin-left: 90px" *ngIf="device">
        <div class="pull-left ;align-items: center;" style="width:38%;">

            <div  style="align-items: center;">
               <div class="row" style="margin-left: 0px;font-size: 14px;margin-top: 15px">
                    <dt>{{'Device Health' | translate}}</dt> &nbsp;
                    <fa-icon class="fa-1x" style="color: green;" [icon]="faHeartBeat"></fa-icon>
                </div>
                <br>
                <!-- uncomment if required to provise enable/disable heath criteria -->
                <!-- <h5>
                    &nbsp;<button *ngIf="device.deviceHealthState.isActive" class="btn btn-secondary"
                        (click)="deactivateHealth();">Disable</button>
                    <button *ngIf="!device.deviceHealthState.isActive" class="btn btn-secondary"
                        (click)="activateHealth();">Enable</button>
                </h5> -->


                <table style="width: 100%;margin-right: 10px;margin-top: 0px;margin-bottom: 10px;">
                    <thead>
                        <th>Health State</th>
                        <th> Time(Last Message Received)</th>
                        <th>Age(Last Successful Message</th>
                    </thead>
                    <tbody>
                        <tr *ngIf="device.deviceHealthState">
                            <td *ngIf="device.deviceHealthState.healthState">{{device.deviceHealthState.healthState}}
                            </td>
                            <td *ngIf="!device.deviceHealthState.healthState">-</td>

                            <td *ngIf="device.deviceHealthState.lastMessageReceived==0">No message received</td>

                            <td *ngIf="device.deviceHealthState.lastMessageReceived!=0">
                                {{device.deviceHealthState.lastMessageReceived| date:'mediumDate'}}</td>
                            <td>{{device.deviceHealthState.lastSuccessfulHealthMessageAge}} minutes</td>
                        </tr>
                    </tbody>
                </table>
                <p style="text-align: center;" *ngIf="!device.deviceHealthState"><b>There is no health criteria for this
                    device</b></p>
            </div>

        </div>
        <br>
        <br>
        <div class = "vertical"></div>
        <div class="pull-right" style="width: 58%;align-items: center;">
            <div class="row" style="margin-left: 15px;font-size: 14px;margin-top: 15px;" >
                <dt>{{'Device Violations' | translate}}</dt> &nbsp;
                <fa-icon class="fa-1x" style="color: rgb(153, 12, 12);" [icon]="faExclamationTriangle"></fa-icon>
            </div>
            <br>
            
            <table style="width: 100%;margin: 10px;margin-top: 0px;">
                <thead>
                    <th>Attribute Name</th>
                    <th>Attribute Value</th>
                    <th>Level</th>
                    <th>Message</th>
                    <th>Date</th>
                    <th>Rule</th>
                </thead>
                <tbody>
                    <tr *ngFor="let attribute of attributesList">
                        <td>{{attribute.violatedAttributeName}}</td>
                        <td>{{attribute.attributeViolations[0].violatedAttributeValue}}</td>
                        <td>{{attribute.attributeViolations[0].attributeViolationStatus}}</td>
                        <td>{{attribute.attributeViolations[0].violationMessage}}</td>
                        <td>{{attribute.attributeViolations[0].creation|date:'mediumDate'}}</td>
                        <td (click)="viewRule(attribute.attributeViolations[0].ruleId)">
                            <a *ngIf="attribute.attributeViolations[0].ruleName">{{attribute.attributeViolations[0].ruleName}}</a>
                            <a *ngIf="!attribute.attributeViolations[0].ruleName">-</a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div *ngIf="attributesList.length==0">
                <p style="text-align: center;"><b>There are no violations for this device</b></p>
            </div>
        </div>
    </div>


    <dl style="margin-left: 90px">
        <dt>{{'Device Tracking' | translate}}</dt>
    </dl>
    <div class="map-box clear" style="margin-left: 90px;background-color: rgb(236, 240, 227);" *ngIf="device">
        <p class="text-red"  *ngIf="errorMessage" style="font-size: 14px;font-weight: 500;margin-left: 20px;margin-bottom: 0px;">
            {{errorMessage}}
            <fa-icon class="fa-1x" style="color: rgb(126, 9, 9);" [icon]="faClose" (click)="errorMessage=''"></fa-icon>
        </p>
        <p *ngIf="errorMessage2" style="font-size: 14px;font-weight: 500;margin-left: 20px;margin-bottom: 0px;">
            {{errorMessage2}}
            <fa-icon class="fa-1x"  [icon]="faClose" (click)="errorMessage2=''"></fa-icon>
        </p>
        <div class="row" >
            <dl class="row">
                <dt>{{'Interval:' | translate}}</dt>
                <select name="typeChange" [(ngModel)]="dateTimeRange">
                    <option [value]="item.id" *ngFor="let item of groups">
                        {{item.name}}
                    </option>
                </select>
            </dl>
            <dl class="row">
                <dt>{{'start.time' | translate}}&nbsp;</dt>
                <input placeholder="required" id="date" type="date" aria-label="date" (click)="errorMessage=''" [(ngModel)]="startDate">
                <input placeholder="required" id="time" type="time" step="1" aria-label="time" [(ngModel)]="startTime">
            </dl>
            <dl class="row">
                <dt>{{'end.date' | translate}}&nbsp;</dt>
                <input placeholder="required" id="date" type="date" aria-label="date" (click)="errorMessage=''" [(ngModel)]="endDate">
                <input placeholder="required" id="time" type="time" step="1" aria-label="time" [(ngModel)]="endTime">
            </dl>
            <button class="btn-micro" (click)="getDeviceTracking()">Map</button>

        </div>
        <br>
        <div>
            <!-- <p>{{loadDeviceTrackingMap}}</p> -->
            <app-map-component *ngIf="loadDeviceTrackingMap"></app-map-component>
            <app-map-component *ngIf="!loadDeviceTrackingMap"></app-map-component>
        </div>
    </div>

    <dl style="margin-left: 90px">
        <dt>{{'Events and commands' | translate}}</dt>
    </dl>
    <div *ngIf="eventArray.length==0 && device" class="event-box" style="float: left;margin-left: 90px;">
        <h5 style="text-align: center;padding-top: 200px;font-size: 14px;">No Events for this Device</h5>
    </div>
<div *ngIf="eventArray.length>0">

    <div class="event-box" style="float: left;margin-left: 90px;" *ngIf="device">
        <ng-container>
            <div *ngIf="onEventDataSet" style="margin-left: 170px;margin-top: 10px;">
                <h5 style="margin-left: -100px;font-size: 14px;font-weight: 550;">Show Events:
                <select name="typeChange" [(ngModel)]="eventValue" (change)="onChangeEvent($event)">
                    <option [value]="event.value" *ngFor="let event of eventArray">
                        {{event.viewValue}}
                    </option>
                </select></h5>
            </div>
        </ng-container>
        <div style="height: 300px;width:400px ; margin-top: 50px;margin-left: 70px;">
            <canvas style="height:400px;width:500px" baseChart [data]="doughnutChartData" [labels]="doughnutChartLabels"
                [colors]="doughnutColors" [chartType]="doughnutChartType" [options]="doughnutChartOptions">
            </canvas>
        </div>
    </div>
</div>
<div *ngIf="commandArray.length==0 && device" class="event-box" style="float: right;margin-right: 50px">
    <h5 style="text-align: center;padding-top: 200px;font-size: 14px;">No Commands for this Device</h5>
</div>
<div *ngIf="commandArray.length>0">
    <div class="event-box" style="float: right;margin-right: 50px" *ngIf="device">
        <ng-container>
            <div *ngIf="onEventDataSet" style="margin-left: 170px;margin-top: 10px;">
                <h5 style="margin-left: -100px;font-size: 14px;font-weight: 550;">Show Commands:
                <select name="typeChange" [(ngModel)]="commandValue" (change)="onChangeCommand($event)">
                    <option [value]="command.value" *ngFor="let command of commandArray">
                        {{command.viewValue}}
                    </option>
                </select></h5>
            </div>
        </ng-container>
        <div style="height: 300px;width:400px ; margin-top: 50px;margin-left: 70px;">
            <canvas baseChart [data]="doughnutChartData1" [labels]="doughnutChartLabels1" [colors]="doughnutColors1"
                [chartType]="doughnutChartType" [options]="doughnutChartOptions1 ">
            </canvas>
        </div>

    </div>

</div>
</div>
/** 
 * This is an auto generated code for persons service listing page.
 * This file can be customized and will not be overwritten.
 * @version 1.0.0 
 * @readonly 
 * @author - System generated 
 */

import { Injectable } from '@angular/core';
import { SelectionModel} from '@angular/cdk/collections';
import { IotService } from '../service/iot.service'; 
import { ListPersonsService } from './ListPersons.service';
import { EcosystemMembersService } from './ecosystemMembers.service';

@Injectable({ 
	  providedIn: 'root' 
})

export class PersonsService extends ListPersonsService {
	 constructor(public iotService:IotService, public ecosystemMemberService:EcosystemMembersService) {
		 super(iotService);
	 }

	 fnOnSelectionSupport(results){
		 const that = this; 
		 if(that.iotService.createEcosystemMembership == true) {
			 that.ecosystemMemberService.setSelectedPersonId(results.id);
		 }
	 } 

	 fnRemoveSelectionSupport(results){ 
		 const that = this; 
		 if(that.iotService.createEcosystemMembership == true) {
			that.ecosystemMemberService.setSelectedPersonId(undefined);
		}
	 } 

} 

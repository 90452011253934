/**
 * This is an auto generated code for ecosystemInstanceAttributes listing page.
 * Please don't make any change to this file.
 * @version 1.0.0
 * @readonly
 * @author - System generated
 */

import { Component, OnInit, ViewChild, Injector, Inject} from '@angular/core'
import { IotService } from '../service/iot.service'
import { EcosystemInstanceAttributesService } from '../service/ecosystemInstanceAttributes.service'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { faCaretUp, faCaretDown, faInfoCircle, faQuestionCircle, faArrowUp, faArrowDown, faSearch } from '@fortawesome/free-solid-svg-icons'
import { SideNavComponent } from '../side-nav/side-nav.component'
import { BaseListComponent } from '../base-list/baselist.component'
import { EcosystemDetailComponent } from '../ecosystem-detail/ecosystem-detail.component'

interface TypeChange {
	value: string;
	viewValue: string;
}
@Component({
	selector: 'app-ecosystemInstanceAttributes',
	templateUrl: './ecosystemInstanceAttributes.component.html',
	styleUrls: ['../base-list/baselist.component.scss','./ecosystemInstanceAttributes.component.scss']
})
export class EcosystemInstanceAttributesComponent extends BaseListComponent {
	displayedColumns: string[] = ['name','description','type','value','creation','ACTIVE','ACTION'];
	sortColumns: string[] = ['creation'];
	pageTitle="ecosystemInstanceAttributes.header";
	ascCreation:boolean = false;
	descCreation:boolean = false;
	countCallCompleted:boolean = false;
	getCallCompleted:boolean = false;
	showEnableColumn = true;
	showActionColumn = true;

	constructor( router: Router,
				iotService: IotService,
				translateService: TranslateService,
				sideNavComponent:SideNavComponent,
				private ecosystemDetailComponent: EcosystemDetailComponent,
				public ecosystemInstanceAttributesService:EcosystemInstanceAttributesService,
				) {
				super(router, iotService, translateService, sideNavComponent )
						this.translateService.addLangs(this.supportLanguages);
						this.translateService.setDefaultLang('en');
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.ecosystemInstanceAttributesService.multiSelectRows = this.multiSelect;
		this.ecosystemInstanceAttributesService.loadSelected();
		if(!this.showSelectColoumn){
			this.sideNavComponent.menuClear();
			this.sideNavComponent.menuChange('Dashboard','sub-type');
			this.sideNavComponent.menuChange('ecosystemInstanceAttributes.header','sub-type');
		}
	}
	getFilteredResults(value, columnName , sortOrder){
		const that = this;
		that.iotService.getCuiObjResponse().getEcosystemInstanceAttributes({
			ecosystemId: that.extraParamsMap.get('ecosystemId'),
			qs: eval(that.fnGetQS(value, columnName, sortOrder))
		}).then(function (response) {
			that.fnSuccess(response);
			if(columnName == 'none' || columnName == '' || columnName == undefined){
				that.fnResetSortParam('creation', 'desc');
			}else{
				that.fnResetSortParam(columnName, sortOrder);
			}
			that.getCallCompleted = true;
		}).fail(function (err) {
			that.fnError(err);
			that.getCallCompleted = true;
		});
	}
	getCount() {
		const that = this;
		that.showPagination=false;
		let value = this.selected;
		that.iotService.getCuiObjResponse().countEcosystemInstanceAttributes({
			ecosystemId: that.extraParamsMap.get('ecosystemId'),
			qs: eval(that.fnGetQS(value, '', ''))
		}).then(function (response) {
				that.totalCount = response;
				that.countCallCompleted = true;
			if(that.totalCount > 0)
				that.showPagination=true;
			}).fail(function (err) {
				that.fnError(err);
				that.countCallCompleted = true;
			});
	}
	deleteCommands(resourceId){
		const that = this;
		that.iotService.getCuiObjResponse().deleteCommands({
			ecosystemId: that.extraParamsMap.get('ecosystemId'),
			attributeId: resourceId
		}).then(function (response) {
			that.getCount();
			that.getFilteredResults(that.selected, that.sortCriteria, that.sortDirection);
			that.ecosystemInstanceAttributesService.fnResetList();
			that.ecosystemDetailComponent.loadEcosystem(that.extraParamsMap.get('ecosystemId'));
		}).fail(function (err) {
			that.fnError(err);
			that.ecosystemInstanceAttributesService.showErrorMessage(err);
		});
	}
	activateAttributes(resourceId) {
		const that = this;
		that.iotService
		  .getCuiObjResponse()
		  .activateAttributes({
			attrId: resourceId,
		  })
		  .then(function (response) {
			that.getFilteredResults(
			  that.selected,
			  that.sortCriteria,
			  that.sortDirection
			);
			that.ecosystemInstanceAttributesService.fnResetList();
		  })
		  .fail(function (err) {
			that.fnError(err);
		  });
	  }
	  deactivateAttributes(resourceId) {
		const that = this;
		that.iotService
		  .getCuiObjResponse()
		  .deactivateAttributes({
			attrId: resourceId,
		  })
		  .then(function (response) {
			that.getFilteredResults(
			  that.selected,
			  that.sortCriteria,
			  that.sortDirection
			);
			that.ecosystemInstanceAttributesService.fnResetList();
		  })
		  .fail(function (err) {
			that.fnError(err);
		  });
	  }
	createNew(){
		this.ecosystemInstanceAttributesService.createNew();
	}
	onSelection(results){
		this.ecosystemInstanceAttributesService.onSelection(results);
	}
	removeSelection(results){
		this.ecosystemInstanceAttributesService.removeSelection(results);
	}
	changeActive(resourceId, isActivate) {
		if (isActivate == 'true') {
		  this.deactivateAttributes(resourceId);
		} else {
		  this.activateAttributes(resourceId);
		}
	  }
	onValueUpdate(results){
		this.ecosystemInstanceAttributesService.onValueUpdate(results);
	}
}

/**
 * This is an auto generated code for organizations service listing page.
 * This file can be customized and will not be overwritten.
 * @version 1.0.0
 * @readonly
 * @author - System generated
 */

import { Injectable } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { IotService } from '../service/iot.service';
import { ListOrganizationsService } from './ListOrganizations.service';
import { EcosystemMembersService } from './ecosystemMembers.service';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService extends ListOrganizationsService {
  constructor(
    public iotService: IotService,
    public ecosystemMemberService: EcosystemMembersService
  ) {
    super(iotService);
  }

  fnOnSelectionSupport(results) {
    const that = this;
    if (that.iotService.createEcosystemMembership == true) {
      that.ecosystemMemberService.setSelectedOrganizationId(results.id);
    }
  }

  fnRemoveSelectionSupport(results) {
    const that = this;
    if (that.iotService.createEcosystemMembership == true) {
      that.ecosystemMemberService.setSelectedOrganizationId(undefined);
    }
  }
}

import { Injectable } from '@angular/core';
import { SelectionModel} from '@angular/cdk/collections';
import { IotService } from '../service/iot.service';
import { ListTradingPartnerCodesService } from './ListTradingPartnerCodes.service';
import { TradingPartnersService } from './tradingPartners.service';
import { OrchestrationConfigsService } from './orchestrationConfigs.service';

@Injectable({
	  providedIn: 'root'
})

export class TradingPartnerCodesService extends ListTradingPartnerCodesService {

	selection: SelectionModel<String> = new SelectionModel<String>(true, []);


	 constructor(public iotService:IotService, public tpService: TradingPartnersService,
	  	public orchestrationConfigsService: OrchestrationConfigsService) {
		 super(iotService);
		 this.selection.clear();
		 this.loadSelected();
	 }

	 loadSelected(){
		if (this.orchestrationConfigsService.getTpCode()) {
			this.selection.select(this.orchestrationConfigsService.getTpCode().id);
		}
	 }

	 fnOnSelectionSupport(results){
		 const that = this;
		 if(this.orchestrationConfigsService.getTpCode() != undefined){
			 this.orchestrationConfigsService.setTpCode(undefined);
		 }
		 setTimeout(()=>{
			 this.orchestrationConfigsService.setTpCode(results);
		 }, 500);
	 }

	 fnRemoveSelectionSupport(results){
		 const that = this;
		 this.orchestrationConfigsService.setTpCode(undefined);
	 }


	 createNew(){
		 this.tpService.createTpCode=true;
		 this.tpService.createResource=false;
	 }

	 viewDetails(selectedId){
		this.tpService.codeId=selectedId;
		this.tpService.viewTPCode=true;
		this.tpService.createResource=false;
	 }

	 showErrorMessage(err){
		if(err.responseJSON.apiStatusCode){
			this.tpService.errorMessage=err.responseJSON.apiMessage;
		}
		else{
			this.tpService.errorMessage="Some thing went wrong, Please check console ";
		}
	 }

}

<ng-container *ngIf="showLoading">
    <div>
        <br /><br />
    </div>
    <div class="d-flex justify-content-center loader">
        <div class="spinner-border" role="status">
            <span class="sr-only" id="loading"></span>
        </div>
    </div>
</ng-container>
<div *ngIf="!showLoading" class="eventForm">
    <header>
        <h1>{{ 'create.new.event' | translate}}</h1>
        <p *ngIf="errorMsg" class="alert">{{errorMsg}}</p>
    </header>
    <div class="col">
        <label for="eventName">{{'name' | translate}}</label>
        <input [(ngModel)]="eventTemplate.name" id="eventName" (click)="nameError=''"
            placeholder="{{'required' | translate}}" name="eventName" type="text">
        <p style="color:red">{{nameError}}</p>

        <label for="eventDescription">{{'description' | translate}}</label>
        <textarea [(ngModel)]="eventTemplate.description[0].text" placeholder="{{'optional' | translate}}"
            type="text"></textarea>

        <label for="eventTags">{{ 'tags' | translate}}</label>
        <mat-chip-list #tagList aria-label="tags">

            <mat-chip class="ot-chip" *ngFor="let tag of tags" [selectable]="selectable" [removable]=true
                (removed)="remove(tag)">
                {{tag}}
                <button class="ot-chip__close" matChipRemove></button>
            </mat-chip>
            <input [ngClass]="tagClass" placeholder="{{'add_a_tag' | translate}}" #tagInput [matChipInputFor]="tagList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                (matChipInputTokenEnd)="add($event)">

        </mat-chip-list>
    </div>
    <div class="col">
        <label for="docInfo">{{ 'document.info' | translate}}</label>
        <select [disabled]=true id="docInfo">
            <option value="true">{{'select'| translate}}</option>
        </select>

        <label>{{ 'enabled' | translate}}?</label>
        <div class="activateEvent">

            <button *ngIf="eventTemplate.isActive" class="toggle-on toggle-switch" (click)=toggleActivate(false);
                title="Disable" aria-label="Enabled" title="Enabled">
                <fa-icon [icon]="faCheckIcon" class="fa-2x"></fa-icon>
                <div class="toggle-on-btn"></div>
            </button>

            <button *ngIf="!eventTemplate.isActive" class="toggle-off toggle-switch" (click)=toggleActivate(true);
                aria-label="Disabled" title="Disabled">
                <div class="toggle-off-btn"></div>
            </button>

        </div>
    </div>

    <h2>{{ 'data.points' | translate}}</h2>
   
    <table style="width:90%">
        <thead>
            <tr>
                <th *ngFor="let column of displayedColumns">
                    {{column}}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let parameter of parameters">
                <!-- Name Column -->
                <td style="width: 25%;">
                    <input [(ngModel)]="parameter.name" placeholder="" style="width:100%; height: 32px; margin-left: 0px;" (click)="dataPointNameError=''">
 
                </td>
                <!-- Description Column -->
                <td style="width: 25%;">
                    <input [(ngModel)]="parameter.description" type="text" placeholder="" style="width:100%; height: 32px; margin-left: 0px;">
                </td>

                <!-- Data Type Column -->
                <td style="width: 25%; font-size: 14px;">
                    <select [(ngModel)]="parameter.type" id="parameterDataType" style="width:100%; height: 32px; margin-left: 0px;" (change)="dataPointTypeError=''">
                        <option value="">{{ 'select.data.type' | translate}}</option>
                        <option value="string"> {{ 'string' | translate}}</option>
                        <option value="integer"> {{ 'integer' | translate}}</option>
                        <option value="decimal"> {{ 'decimal' | translate}}</option>
                        <option value="bool"> {{ 'boolean' | translate}}</option>
                    </select>
                    
                </td>


                <!-- Binding Column -->
                <td style="width: 20%;">
                    <button class="binding" (click)="getAttributes(1)"
                        [matMenuTriggerFor]="menu">{{parameter.bindingname}}</button>
                    <mat-menu #menu="matMenu" xPosition="before" yPosition="above">
                        <ng-container *ngIf="attributes">

                            <table mat-table [dataSource]="attributes">
                                <!-- Name Column -->
                                <ng-container matColumnDef="Name">
                                    <th mat-header-cell *matHeaderCellDef> {{'name' | translate}} </th>
                                    <td mat-cell *matCellDef="let attribute">
                                        {{attribute.name}}
                                    </td>
                                </ng-container>

                                <!-- Type Column -->
                                <ng-container matColumnDef="Type">
                                    <th mat-header-cell *matHeaderCellDef> {{'type' | translate}} </th>
                                    <ng-container *matCellDef="let attribute">
                                        <td mat-cell *ngIf="attribute.type">
                                            {{attribute.type}}
                                        </td>
                                        <td mat-cell *ngIf="!attribute.type">-</td>
                                    </ng-container>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="attrColumns" style="height: 30pt;"></tr>
                                <tr mat-row *matRowDef="let attribute; columns: attrColumns;"
                                    (click)="(parameter.type === attribute.type && setBinding(parameter,attribute)) || (parameter.type !== attribute.type && $event.stopPropagation())"
                                    [ngStyle]="(parameter.type !== attribute.type && {'background-color': 'rgba(232, 232, 232, 1)'}) || (parameter.type === attribute.type && {'cursor': 'pointer'})">
                                </tr>
                            </table>
                            <div (click)="$event.stopPropagation();$event.preventDefault();" style="font-size: small;">
                                <a (click)="prevPage();" ng-disabled="1 == 2">
                                    <fa-icon [icon]="faAngleDoubleLeftIcon"></fa-icon>
                                </a>
                                {{'page'|translate}} {{currentPage}}
                                <a (click)=nextPage(); ng-disabled="1 == 2">
                                    <fa-icon [icon]="faAngleDoubleRightIcon"></fa-icon>
                                </a>
                            </div>
                        </ng-container>
                    </mat-menu>
                </td>

                <td style="cursor: pointer;">
                    <fa-icon (click)="removeDataPoint(parameter)" [icon]="faTimesIcon"></fa-icon>
                </td>
            </tr>
        </tbody>
    </table>
    <!-- </div> -->
    <br />
    <button class="btn btn-secondary margin-left-15" (click)="addDataPoint()" type="submit">{{ 'add.data.point' |
        translate}}</button>
      

    <div class="pull-right margin-right-15">
        <button class="btn btn-secondary" (click)="createEvent()" type="submit">{{ 'go.back' |
            translate}}</button>&nbsp;
        <button class="btn btn-primary" [disabled]="eventCreated" (click)="save()" type='submit'>{{ 'save' | translate}}</button>
        <br>
        <br>
    </div>
    <!-- <div style="height: 10vmax;"></div> -->
</div>
<header class="table-header" >
  <h2>{{'remote.configuration'|translate}} </h2>
</header>
<div *ngIf="loadTable && rows.length>0" class="remote-config-data">

 <input style="float:right; width: 20%;" placeholder="search" type="text" [(ngModel)]="filterString">
 <br>
 <br>
<p style="float:right">{{'frozen.fields' | translate}}</p>
<br>
<p>{{'configuration.report.desc' | translate}} {{gatewaysService.creation| date:'mediumDate'}}. 
  {{'configuration.prop.desc' | translate}}</p>
  <table>
    <tr>
      <th *ngFor="let column of headers">
        {{column}}
      </th>
    </tr>

    <tr *ngFor="let row of rows|sort:'name'|filter:filterString:'name'">
      <td style="text-align: right;" title="{{row.tooltip}}" >
        {{row.name}} <span *ngIf="!row.isEditable">*</span>
      </td>
      <td>
        <input [disabled]="!row.isEditable" value="{{row.value}}" [(ngModel)]="row.value"
          [ngClass]="{'red': row._value != row.value}">

      </td>

    </tr>

  </table>
  <br>
  <br>
  <div style="margin-top: -30px;">
  <button class="btn btn-primary" style="float: right;" title="Update remote configuration" (click)="update()">{{ 'update' | translate}}</button> &nbsp;&nbsp;
  <button class="btn btn-secondary" style="float: right; margin-left: 5px;" title="Go Back to gateway detail page" (click)="goBack()">{{ 'go.back' | translate}}</button>
  </div>
  <br>
  <br>

</div>

<ng-container *ngIf="!loadTable ">
  <div>
      <br/><br/>
  </div>
  <div class="d-flex justify-content-center pageLoading">
      <div class="spinner-border" role="status">
          <span class="sr-only" id="loading"></span>
      </div>
  </div>
</ng-container>
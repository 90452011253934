/**
 * This is an auto generated code for ecosystemInstanceAttributes service listing page.
 * This file can be customized and will not be overwritten.
 * @version 1.0.0
 * @readonly
 * @author - System generated
 */

import { Injectable } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { IotService } from '../service/iot.service';
import { ListEcosystemInstanceAttributesService } from './ListEcosystemInstanceAttributes.service';
import { EcosystemsService } from './ecosystems.service';

@Injectable({
  providedIn: 'root',
})
export class EcosystemInstanceAttributesService extends ListEcosystemInstanceAttributesService {
  constructor(
    public iotService: IotService,
    public ecosystemService: EcosystemsService
  ) {
    super(iotService);
  }

  fnOnSelectionSupport(results) {
    const that = this;
  }

  fnRemoveSelectionSupport(results) {
    const that = this;
  }

  createNew() {
    if (this.iotService.viewEcosystem == true) {
      this.ecosystemService.viewAttributes = true;
      this.ecosystemService.viewResource = false;
    }
  }

  onValueUpdate(results) {
    const that = this;
    let attributesForUpdate =
      that.ecosystemService.getCustomAttributesForUpdate();
    let attribute = {
      attributeId: results.attributeType.id,
      name: results.attributeType.name,
      value: results.value,
      type: results.attributeType.type,
    };
    attributesForUpdate.push(attribute);
    that.ecosystemService.setCustomAttributesForUpdate(attributesForUpdate);
  }

}

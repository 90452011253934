import { Injectable } from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import { IotService } from '../service/iot.service';

@Injectable({ 
	  providedIn: 'root' 
})

export abstract class BaseListService {
	 selection: SelectionModel<String> = new SelectionModel<String>(true, []);
     multiSelectRows:string = "true";
     instanceValue:string='0';
   constructor(public iotService:IotService) {
		 this.selection.isSelected = this.isChecked.bind(this);
		
	 }
	 onSelection(results){ 
		 const that = this; 
		 that.fnOnSelectionSupport(results);
		 that.playRecord(results);
	 }

   removeSelection(results){ 
		 const that = this; 
		 that.fnRemoveSelectionSupport(results);
		 this.playRecord(results);
	 }
   playRecord(element) {
      let flag = this.multiSelectRows;
      if (this.selection.isSelected(element.id)) {
  			 this.selection.deselect(element.id);
      }else{
          if(flag == "false"){
              this.selection.clear();
  		    }
         this.selection.select(element.id);
  		}
      
  	}
   
	 isChecked(row: any): boolean { 
		const found = this.selection.selected.find(el => el === row);
		 if (found) { 
			 return true; 
		 } 
		 return false; 
	 }
   abstract fnOnSelectionSupport(results);

   abstract fnRemoveSelectionSupport(results);

   createNew(){}

	fnResetList(){} 

	loadSelected(){}

	viewDetails(selectedId){}

	showErrorMessage(errorObject){}

	onValueUpdate(results: any) {}
} 

<ng-container>
    <div style="float:right; margin-right: 10px;margin-top: 30px;">
        <p>Show :<a (click)="showMap=true">Map</a> | <a (click)="showMap=false">List</a></p>
    </div>
    <div class="row mapWidget" id="map" style="margin-top: 30px;margin-left: 0px;">
        <h5 style="padding-left: 15pt;font-size: 14px;font-weight: 550;margin-top: 5px;">Device Locations &nbsp;</h5>
        <!-- (ngModelChange) -->
        <ng-container>
            <select name="typeChange" [(ngModel)]="typeChangeControl" (change)="onChange()">
                <option [value]="item.value" *ngFor="let item of options">
                    {{item.viewValue}}
                </option>
            </select>
        </ng-container>

        <br>
        <ng-container *ngIf="typeChangeControl==1">
            <select name="typeChange" [(ngModel)]="exceptionType" (change)="onChangeException()">
                <option [value]="item.value" *ngFor="let item of exceptions">
                    {{item.viewValue}}
                </option>
            </select>
        </ng-container>
        <form class="example-form" *ngIf="typeChangeControl==2">
            <input type="text" placeholder="Search for a Ecosystem" [formControl]="myControl" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let option of filteredEcosystemOptions | async" [value]="option"
                    (click)="getEcosystemDevices(option.id)">
                    {{option.name}}
                </mat-option>
            </mat-autocomplete>
        </form>

        <form class="example-form" *ngIf="typeChangeControl==3">
            <input type="text" placeholder="Search for a Tag name" [formControl]="myControl" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let option of filteredCustomTagOptions | async" [value]="option"
                    (click)="loadCustomTagValues(option.name)">
                    {{option.name}}
                </mat-option>
            </mat-autocomplete>
        </form>

        <ng-container *ngIf="typeChangeControl==3 && tagNameSelected">
            <select name="typeChange" [(ngModel)]="customTagItem" (change)="selectChangeHandler($event)">
                <option [value]="customTagItem" *ngFor="let customTagItem of customTagValues">
                    {{customTagItem}}
                </option>
            </select>
        </ng-container>

        <form class="example-form" *ngIf="typeChangeControl==4">
            <input type="text" placeholder="Search for a Device template" [formControl]="myTemplateControl"
                [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let option of filteredTemplateOptions | async" [value]="option"
                    (click)="fetchDevices(option.id)">
                    {{option.name}}
                </mat-option>
            </mat-autocomplete>
        </form>

        <ng-container *ngIf="typeChangeControl==5">
            <select name="typeChange" [(ngModel)]="lifeCycleState" (change)="onChnageLifeCycle()">
                <option [value]="item.value" *ngFor="let item of lifeCycles">
                    {{item.viewValue}}
                </option>
            </select>
        </ng-container>

        <form class="example-form" *ngIf="typeChangeControl==6">
            <input type="text" placeholder="Search for a Geofence" [formControl]="myGeofenceControl"
                [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let option of filteredGeofenceOptions | async" [value]="option"
                    (click)="getGeofenceDevices(option.id)">
                    {{option.name}}
                </mat-option>
            </mat-autocomplete>
        </form>
        <!-- <div style="margin-left: 450px;">
            <p>show :<a (click)="showMap=true">Map</a> | <a (click)="showMap=false">List</a></p>
        </div> -->


    </div>


    <div *ngIf="showMap" style="width:1280px;margin-top: 50px;" >
        <app-map-component *ngIf="!loadedDevice"></app-map-component>
        <app-map-component *ngIf="loadedDevice"></app-map-component>
    </div>

    <div *ngIf="!showMap " style="width: 1280px;" id=deviceList>


        
        <div *ngIf="typeChangeControl==1 && loadedDevice==true && exceptionType==1" style="margin-top: -30px;">
            <app-devicesByExceptions [pageSize]="5" [showSelectColoumn]=true
                [displayedColumns]="['name','description','lifecycleState','operationalState','latitude','longitude','creation','isActive']"
                addnQueryParam=[deviceViolationStatus::3] [showNew]="false" [showHeaderToolTips]=false></app-devicesByExceptions>
        </div>
        <div *ngIf="typeChangeControl==1 && loadedDevice==true && exceptionType==2">
            <app-devicesByExceptions [pageSize]="5" [showSelectColoumn]=true
                [displayedColumns]="['name','description','lifecycleState','operationalState','latitude','longitude','creation','isActive']"
                addnQueryParam=[deviceViolationStatus::2] [showNew]="false" [showHeaderToolTips]=false></app-devicesByExceptions>
        </div>
        <div *ngIf="typeChangeControl==1 && loadedDevice==true && exceptionType==3">
            <app-devicesByExceptions [pageSize]="5" [showSelectColoumn]=true
                [displayedColumns]="['name','description','lifecycleState','operationalState','latitude','longitude','creation','isActive']"
                addnQueryParam=[deviceViolationStatus::1] [showNew]="false" [showHeaderToolTips]=false></app-devicesByExceptions>
        </div>

        <div *ngIf="typeChangeControl==2 && loadedDevice==true">
            <app-devicesByEcosystems [pageSize]="5" [showSelectColoumn]=true
            [displayedColumns]="['name','description','lifecycleState','operationalState','latitude','longitude','creation','isActive']"
            addnQueryParam=[ecosystemId::{{currentEcosystemId}} [showNew]="false" [showHeaderToolTips]=false></app-devicesByEcosystems>
        </div>

         <div *ngIf="typeChangeControl==3 && loadedDevice==true">
            <app-devices [pageSize]="5" [showSelectColoumn]=true
            [displayedColumns]="['name','description','parentDeviceTemplateName','lifecycleState','operationalState','creation']"
            addnQueryParam=[tagName::{{currentTagName}}^^tagValue::{{currentTagValue}}] [showNew]="false" [showHeaderToolTips]=false [showEnableColumn]=false></app-devices>
        </div>

        <div *ngIf="typeChangeControl==4 && loadedDevice==true">
            <app-devices [pageSize]="5" [showSelectColoumn]=true
                [displayedColumns]="['name','description','parentDeviceTemplateName','lifecycleState','operationalState','creation']"
                addnQueryParam=[parentDeviceTemplateId::{{deviceTemplateId}}] [showNew]="false" [showHeaderToolTips]=false></app-devices>
        </div>



        <div *ngIf="typeChangeControl==5 && loadedDevice==true && deviceLifeCycleState=='PENDING' ">
            <app-devices [pageSize]="5" [showSelectColoumn]=true
                [displayedColumns]="['name','description','parentDeviceTemplateName','lifecycleState','operationalState','creation']"
                addnQueryParam=[state.lifecycleState::PENDING] [showNew]="false" [showHeaderToolTips]=false></app-devices>
        </div>
        <div *ngIf="typeChangeControl==5 && loadedDevice==true && deviceLifeCycleState=='ACTIVE' ">
            <app-devices [pageSize]="5" [showSelectColoumn]=true
                [displayedColumns]="['name','description','parentDeviceTemplateName','lifecycleState','operationalState','creation']"
                addnQueryParam=[state.lifecycleState::ACTIVE] [showNew]="false" [showHeaderToolTips]=false></app-devices>
        </div>
        <div *ngIf="typeChangeControl==5 && loadedDevice==true && deviceLifeCycleState=='SUSPENDED' ">
            <app-devices [pageSize]="5" [showSelectColoumn]=true
                [displayedColumns]="['name','description','parentDeviceTemplateName','lifecycleState','operationalState','creation']"
                addnQueryParam=[state.lifecycleState::SUSPENDED] [showNew]="false" [showHeaderToolTips]=false></app-devices>
        </div>
        <div *ngIf="typeChangeControl==5 && loadedDevice==true && deviceLifeCycleState=='DELETED' ">
            <app-devices [pageSize]="5" [showSelectColoumn]=true
                [displayedColumns]="['name','description','parentDeviceTemplateName','lifecycleState','operationalState','creation']"
                addnQueryParam=[state.lifecycleState::DELETED] [showNew]="false" [showHeaderToolTips]=false></app-devices>
        </div>


        <div *ngIf="typeChangeControl==6 && loadedDevice==true">
            <app-devices [pageSize]="5" [showSelectColoumn]=true
                [displayedColumns]="['name','description','parentDeviceTemplateName','lifecycleState','operationalState','creation']"
                addnQueryParam=[geofenceId::{{geofenceId}}] [showNew]="false" [showHeaderToolTips]=false></app-devices>
        </div>


        <!-- <app-devices [pageSize]="5"></app-devices> -->
    </div>
</ng-container>
/** 
 * This is an auto generated code for side nav page.
 * Please don't make any change to this file.
 * @version 1.0.0 
 * @readonly 
 * @author - System generated 
 */

import { Component, Input, OnInit,ViewChild,Output, EventEmitter } from '@angular/core';
import { MatSidenav, throwMatDuplicatedDrawerError } from '@angular/material/sidenav';
import { MatSidenavModule } from  '@angular/material/sidenav';
import {Router} from '@angular/router'; 
import { Location } from "@angular/common";
import { TranslateService } from '@ngx-translate/core';

@Component({
	 selector: 'app-side-nav',
	 templateUrl: './side-nav.component.html',
	 styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit { 
	 @ViewChild('sidenav') sidenav: MatSidenav;
	 menuHeader: any = [];
	 supportLanguages = ['en']; 
	 menus: String[] = ['devices','templates','applications','streams','certificates','sshKeys','oAuthTokens','eventGroups','documentInfo','rules','orchestrationConfigs','importExport','ecosystemTemplates','ecosystems','gateways','tradingPartners','routes','perfmatrix','messageGovernances','geofences'	 ]; 
	 menuActiveClassDevices = '';
	 menuActiveClassTemplates = '';
	 menuActiveClassApplications = '';
	 menuActiveClassStreams = '';
	 menuActiveClassCertificates = '';
	 menuActiveClassSshKeys = '';
	 menuActiveClassOAuthTokens = '';
	 menuActiveClassEventGroups = '';
	 menuActiveClassDocumentInfo = '';
	 menuActiveClassRules = '';
	 menuActiveClassOrchestrationConfigs = '';
	 menuActiveClassImportExport = '';
	 menuActiveClassEcosystemTemplates = '';
	 menuActiveClassEcosystems = '';
	 menuActiveClassGateways = '';
	 menuActiveClassTradingPartners = '';
	 menuActiveClassRoutes = '';
	 menuActiveClassPerfmatrix = '';
	 menuActiveClassMessageGovernances = '';
	 menuActiveClassGeofences = '';

	 constructor(private router: Router,private location: Location, private translateService: TranslateService) { 
		 this.translateService.addLangs(this.supportLanguages);
		 this.translateService.setDefaultLang('en'); 
	 } 
	 ngOnInit(): void { 
	 }
	 breadCrumb(menu) { 
		 const that = this;
		 console.log('sub breadCrumb');
		 that.router.navigateByUrl("/"+menu);
	 }

	 menuChange(menuChange,menuType) {
		 window.scrollTo({ top: 0, behavior: 'smooth' });
		 console.log(menuChange);
		 const that = this;
		 if(menuType == 'main') {
			 that.menuHeader = [];
			 that.menuHeader.push(menuChange);
		} 
		 else { 
			 that.menuHeader.push(menuChange);
		}  
		 if(menuChange == 'Dashboard') {
			 this.menuHeader = [];
			 return;
		 }
		 this.menus.forEach(element => {
			 let appNameCaps = element.substring(0, 1).toUpperCase()+element.substring(1);
			 if(element == menuChange){
				 eval("that.menuActiveClass"+appNameCaps+"='active';");
			 }else{
				 eval("that.menuActiveClass"+appNameCaps+"='';");
			 }
		 });
	}

	 menuClear() { 
		 this.menuHeader = [];
	 }

	 isExpanded = true;
	 showSubmenu: boolean = false;
	 isShowing = false;
	 showSubSubMenu: boolean = false;
	 @Output() messageToEmit = new EventEmitter<boolean>();

	 mouseenter() {
		 if (!this.isExpanded) {
			 this.isShowing = true;
		 }
	 }

	 mouseleave() {
		 if (!this.isExpanded) {
			 this.isShowing = false;
		 }
	  }

	 onGettingStarted(message:boolean){
		 this.messageToEmit.emit(message);
	 }
}

<!--
* This is an auto generated code for ecosystemTemplateRules listing page.
* Please don't make any change to this file.
* @version 1.0.0 
* @readonly 
* @author - System generated 
-->
<div class="container"> 
	<header class="table-header">
		<h2>
		 {{pageTitle | translate}}
			 <span *ngIf="showHeaderToolTips" id="ot-tooltip-bottom" class="ot--is-width-px-200">
				  <a class="ot-tooltip ot-tooltip--is-bottom"><fa-icon [icon]="faQuestionCircleIcon"></fa-icon>
					 <span class="ot-tooltip__text">{{'ecosystemTemplateRules.definition' | translate}}</span>
				</a>
			 </span>
		</h2>
	</header>
	<div *ngIf="showControlBar" class="control-bar">
		<input *ngIf="showSearch" type="text" placeholder="{{'ecosystemTemplateRules.search' | translate}}" (keyup)="getSearchResults($event)"> 
		<span *ngIf="newWithRoute">
			<button *ngIf="showNew" class="newBtn btn-primary" [routerLink]="['null']" (click)="createNew()" aria-label="">{{'ecosystemTemplateRules.new' | translate }}</button> 
		</span> 
		<span *ngIf="!newWithRoute">
			<button  class="newBtn btn-primary" (click)="createNew()" aria-label="">{{'ecosystemTemplateRules.new' | translate }}</button> 
		</span>
	</div>
	<div class="table-container">
	<ngx-skeleton-loader *ngIf="!dataSource" count="12" [theme]="{'border-radius': '0px',
		height: '48px', 
		width: '100%', 
		margin: '1px', 
		position: 'relative', 
		top: '20px' }"> 
	</ngx-skeleton-loader> 
	<ng-container *ngIf="totalCount > 0">
		<table mat-table [dataSource]="dataSource" class="ecosystemTemplateRulesTable">
		<ng-container *ngIf="showSelectColoumn">
			<ng-container matColumnDef="ADD">
				<th mat-header-cell *matHeaderCellDef class="checkbox-cell addCol"> {{addSelectButtonLabel | translate}}</th>
					<td mat-cell *matCellDef="let results" class="checkbox-cell addCol">
						<ng-container> 
							<div class="btn-col">
								<ng-container *ngIf="ecosystemTemplateRulesService.selection.isSelected(results.id)">
									<button class="selected btn btn-micro btn-secondary"  (click)="removeSelection(results)">
									<fa-icon [icon]="faCheckIcon"></fa-icon></button>
								</ng-container>
								<ng-container *ngIf="!ecosystemTemplateRulesService.selection.isSelected(results.id)">
								 <button class="select btn btn-micro btn-secondary"  (click)="onSelection(results)">{{addSelectButtonLabel | translate}}</button>
								</ng-container>
							</div>
						</ng-container> 
					</td>
			</ng-container>
		</ng-container>
		<ng-container matColumnDef="sourceEventKey">
			<th class="name-cell sourceEventKeyCol" mat-header-cell *matHeaderCellDef>
				{{'ecosystemTemplateRules.sourceEventKey' | translate}} 
			</th>
			<ng-container *matCellDef="let results">
				<td class="name-cell sourceEventKeyCol" mat-cell *ngIf="results.sourceEventKey">
					{{results.sourceEventKey}} 
				</td> 
				<td mat-cell *ngIf="!results.sourceEventKey"> - </td> 
			</ng-container>
		</ng-container>
		<ng-container matColumnDef="ruleName">
			<th class="name-cell ruleNameCol" mat-header-cell *matHeaderCellDef>
				{{'ecosystemTemplateRules.ruleName' | translate}} 
			</th>
			<ng-container *matCellDef="let results">
				<td class="name-cell ruleNameCol" mat-cell *ngIf="results.ruleName">
					{{results.ruleName}} 
				</td> 
				<td mat-cell *ngIf="!results.ruleName"> - </td> 
			</ng-container>
		</ng-container>
		<ng-container matColumnDef="sourceEventName">
			<th class="name-cell sourceEventNameCol" mat-header-cell *matHeaderCellDef>
				{{'ecosystemTemplateRules.sourceEventName' | translate}} 
			</th>
			<ng-container *matCellDef="let results">
				<td class="name-cell sourceEventNameCol" mat-cell *ngIf="results.sourceEventName">
					{{results.sourceEventName}} 
				</td> 
				<td mat-cell *ngIf="!results.sourceEventName"> - </td> 
			</ng-container>
		</ng-container>
		<ng-container matColumnDef="creation">
			<th class="date-cell creationCol" mat-header-cell *matHeaderCellDef>
				{{'ecosystemTemplateRules.creation' | translate}} 
			</th>
			<ng-container *matCellDef="let results">
				<td class="date-cell creationCol" mat-cell *ngIf="results.creation">
					{{results.creation | date:'mediumDate' }} 
				</td> 
				<td mat-cell *ngIf="!results.creation"> - </td> 
			</ng-container>
		</ng-container>
		<ng-container *ngIf="showActionColumn" matColumnDef="ACTION">
			<th class="action-cell actionCol" mat-header-cell *matHeaderCellDef> {{'generic.column.title.actions' | translate}} </th>
				<ng-container *matCellDef="let results">
					<td class="action-cell actionCol" mat-cell >
							<a (click)="deleteEcosystemTemplateRules(results.rulePolicyId, results.sourceEventKey)"> 
								<fa-icon title="{{'method.delete.tooltip.ecosystemTemplateRules' | translate}}" style="color: red;font-size: 20px;" [icon]="faCloseIcon"></fa-icon> 
							</a> 
					</td>
				</ng-container>
		</ng-container> 
		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let results; columns: displayedColumns;"></tr>
	</table>
	<app-pagination *ngIf="showPagination" [count]="totalCount" [pageSize]="pageSize" [currentPage]="currentPage" 
			[pageSizeOptions]="pageSizeOptions" (currentPageChange)="onPageChange($event)" 
			(currentPageSizeChange)="onPageSizeChange($event)"> 		</app-pagination>
	</ng-container>
	</div>
	<ng-container *ngIf="!countCallCompleted && !getCallCompleted && totalCount == 0" >
		<div class="table-container text-center"></div>
	</ng-container>
	<ng-container *ngIf="countCallCompleted && getCallCompleted && totalCount == 0" >
		<div class="table-container text-center">{{'generic.no.records.found' | translate}}</div>
	</ng-container>
</div>

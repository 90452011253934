<label class="uk-form-label" for="exampleDrodown">Sample Code</label>
    <ng-container>
      <select [(ngModel)]="exampleDrodown" (change) ="loadAPIJSON();">
          <option value="Unselected">What kinds of examples are you looking for?</option>
          <option value="ActionScript" *ngIf="codeExampleType == 'rule'">Action Script Examples</option>
          <option value="Device">Device API Callout Examples</option>
          <option value="DeviceTemplate">Device Template API Callout Examples</option>
          <option value="AttributeType">Attribute Type API Callout Examples</option>
          <option value="EventTemplate">Event Template API Callout Examples</option>
          <option value="CommandTemplate">Command Template API Callout Examples</option>
          <option value="Group">Group API Callout Examples</option>
          <option value="GroupEntitlement">Group Entitlement API Callout Examples</option>
          <option value="GroupMembership">Group Membership API Callout Examples</option>
          <option value="GroupTemplate">Group Template API Callout Examples</option>
          <!-- <option value="MemberRelationship">Member Relationship API Callout Examples</option> -->
          <option value="SearchApis">Search API Callout Examples</option>
          <option value="PersonApis">Person API Callout Examples</option>
          <option value="MessageComposerApis">Message Composition API Callout Examples</option>
          <option value="KeyValueServiceApis">Key Value Service API Callout Examples</option>
          <option value="OrganizationApis">Organization API Callout Examples</option>
          <option value="GeofenceApis">Geofencing API Callout Examples</option>
          <option value="DeviceHealthApis">Device Health API Callout Examples</option>
          <option value="JWTTokenApis">JWT API Callout Examples</option>
          <option value="OAuthTokenApis">OAuth API Callout Examples</option>
          <option value="MFAApis">MFA API Callout Examples</option>
  		    <option value="TrackingApis">Tracking API Callout Examples</option>
  		    <option value="ShipmentApis">Shipment API Callout Examples</option>
  		    <option value="AssetApis">Asset API Callout Examples</option>
              <option value="SCIMApis">SCIM API Callout Examples</option>
		<option value="ExAccApis">External Account API Callout Examples</option>
		<option value="KvstorageApis">KVSTORAGE API Callout Examples</option>
        </select>
    </ng-container>

    <ng-container>
      <div *ngIf="exampleDrodown=='ActionScript'">
        <br>
        <select [(ngModel)]="example">
            <option value="Send Email" selected="Selected">Send Email</option>
            <option value="Send SMS">Send SMS</option>
            <option value="Send Command"ng-show="ecosystemEvent">Send Command</option>
            <option value="Forward Event" ng-show="ecosystemEvent">Forward Event</option>
            <option value="Send Violation" ng-show="ecosystemEvent">Send Violation</option>
        </select>
    </div>
    </ng-container>

    <ng-container>
      <div *ngIf="exampleDrodown !='ActionScript' && exampleDrodown !='Base64Utils' && exampleDrodown !='Unselected'">

          <label for="example">Available Snippets</label>
          <select id="select" [(ngModel)]="example" (change)="exampleChange();">
              <!-- <option selected="selected" value="View Snippets">View Snippets</option> -->
              <option *ngFor="let temp of APIExample" (selected)="$first" value="{{temp.cmd}}">{{temp.cmd}}</option>
          </select>
      </div>
    </ng-container>


    <ng-container>
      <div *ngIf="exampleDrodown !='ActionScript' && exampleDrodown !='Base64Utils' && exampleDrodown !='Unselected'">
          <pre style="height:240px;resize:vertical;overflow:scroll;margin-top:25px;">
              <button style="margin: 0 15px" class="copy-btn" [cdkCopyToClipboard]="code">Copy</button>
              <code style="display:inherit">{{code}}</code>
          </pre>
      </div>

      <div *ngIf="exampleDrodown == 'ActionScript' && example=='Send Email'" style="overflow-wrap: break-word">
          <!-- <b>Send Email:</b><br> -->
          <pre><code><font face="courier">utils.doAction(<br> <label>"SEND_EMAIL",<br> "recipients:alert@noemail.com",<br> "subject:Temperature Alert!",<br> "message:Temperature is high!"</label> <br>);</font></code></pre>
      </div>
      <div *ngIf="exampleDrodown == 'ActionScript' && example=='Send SMS'" style="overflow-wrap: break-word">
          <!-- <b>Send SMS:</b><br> -->
          <pre><code><font face="courier">utils.doAction(<br> <label>"SEND_SMS",<br> "recipients:+12345678901",<br> "message:Temperature is high!"</label> <br>);</font></code></pre>

      </div>
      <div *ngIf="exampleDrodown == 'ActionScript' && example=='Send Command'" style="overflow-wrap: break-word">
          <!-- <b>Send Command:</b><br> -->
          <pre><code><font face="courier">var command = {{'{'}} <br>&emsp; <label>"messageId": "Device's message id here",<br>&emsp; "deviceId":"2bb5b498-43fe-4c1a-b434-e1348bc62076",<br>&emsp; "commandId":"20e20528-0bd0-4ef8-b8d2-407c580aca83",<br>&emsp; "change_temp": 72.0 </label><br> };<br><br>utils.doAction(<br><label> "SEND_COMMAND",<br> "command:"</label> + JSON.stringify(command)<br>);</font></code></pre>
      </div>
      <div *ngIf="exampleDrodown == 'ActionScript' && example=='Forward Event'" style="overflow-wrap: break-word">
          <!-- <b>Forward Event:</b><br> -->
          <pre><code><font face="courier">var event = {{'{'}} <br>&emsp; <label>"messageId": "Device's message id here",<br>&emsp; "deviceId":"2bb5b498-43fe-4c1a-b434-e1348bc62076",<br>&emsp; "eventTemplateId": "29435c92-4092-4c15-ae2b-422f12514c3f",<br>&emsp; "temp": 72.0 </label><br> };<br><br>utils.doAction(<br><label> "FORWARD_EVENT",<br> "destinationStreamId:2c20bcae-6468-46cc-8f77-e8d4340317c8",<br> "sourceStreamId:7293b2ec-de6e-469a-acfa-46da39647b8d",<br> "event:"</label> + JSON.stringify(event)<br>);</font></code></pre>
      </div>
      <!-- <div *ngIf="exampleDrodown == 'Base64Utils' && gatewayBase64UtilExamples=='Encode'" style="overflow-wrap: break-word">
          <pre><code><font face="courier">var encoded = utils.base64.encode("a string to encode"); <br>// "YSBzdHJpbmcgdG8gZW5jb2Rl"</font></code></pre>
      </div>
      <div *ngIf="exampleDrodown == 'Base64Utils' && gatewayBase64UtilExamples=='Decode'" style="overflow-wrap: break-word">
          <pre><code><font face="courier">var decoded = utils.base64.decode("YSBzdHJpbmcgdG8gZW5jb2Rl"); <br>// "a string to encode"</font></code></pre>
      </div> -->
      <div *ngIf="exampleDrodown == 'ActionScript' && example=='Send Violation'" style="overflow-wrap: break-word">
          <!-- <b>Send SMS:</b><br> -->
          <pre><code><font face="courier">utils.doAction(<br> <label>"SEND_VIOLATION",<br> "violatedAttributeName:signal sensor",<br> "violatedAttributeValue:20",<br> "attributeViolationStatus:2",<br> "violatedDeviceId:deviceId",<br> "expressionValue:40",<br> "expressionOperator:"<br> "violationMessage:20"
          </label> <br>);</font></code></pre>

      </div>
    </ng-container>

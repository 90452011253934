<mat-sidenav-container class="new-public-key" autosize>

    <mat-sidenav class="midNav-container" #sidenav mode="side" disableClose="true" opened="true">

        <div class="alert warning" *ngIf="warningMessage">
            <h5>
                <fa-icon [icon]="faWarningIcon"></fa-icon>
                <span>{{warningMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)="resetWarningMessage()" [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>
        <div class="alert critical" *ngIf="errorMessage">
            <h5>
                <fa-icon [icon]="faCriticalIcon"></fa-icon>
                <span>{{errorMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=resetWarningMessage() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>

        </div>
        <div class="data">
            <h1 style="margin-left: -15px;"> {{ 'add.trusted.sshPublicKey' | translate}}</h1>
            <p>{{'trustedPublicKeys.definition' | translate}}</p>
            <div>
                <button [disabled]="name==''||keyData==''||description==''||trustedPublicKeyCreated" class="btn btn-primary" (click)="save()">{{ 'save' | translate}}</button>
            </div>
        </div>




    </mat-sidenav>
    <mat-sidenav-content class="example-sidenav-content">
        <div class="public-key-data">

            <div class="form-data">

                <label for="name"> {{ 'name' | translate}}</label>
                <input placeholder="required" id="name" type="text" aria-label="Name" [(ngModel)]="name" (keyup)="resetWarningMessage($event)">
                <p *ngIf="nameWarningMessage" style="color: red;">{{nameWarningMessage}}</p>
                <p *ngIf="nameValidationWarningMessage" style="color: red;">{{nameValidationWarningMessage}}</p>

                <label for="description"> {{ 'description' | translate}}</label>
                <textarea rows="2" placeholder="required" id="description" aria-label="description" [(ngModel)]="description" (keyup)="resetWarningMessage($event)"></textarea>
                <p *ngIf="descriptionWarningMessage" style="color: red;">{{descriptionWarningMessage}}</p>
                <label for="certificate-data">{{ 'public.key.openssh.format' | translate}}</label>
                <textarea rows="13" placeholder="required" id="certificate-data" aria-label="Certificate-data" [(ngModel)]="keyData" (keyup)="resetWarningMessage($event)"></textarea>
                <p *ngIf="keyWarningMessage" style="color: red;">{{keyWarningMessage}}</p>
                <!-- <p *ngIf="warningMessage" style="color: red;">{{warningMessage}}</p> -->
            </div>
        </div>
    </mat-sidenav-content>

</mat-sidenav-container>
import { Injectable } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { IotService } from '../service/iot.service';
import { ListEcosystemTemplatesService } from './ListEcosystemTemplates.service';
import { EcosystemsService } from './ecosystems.service';
import { RulesService } from '../service/rules.service';

@Injectable({
  providedIn: 'root',
})
export class EcosystemTemplatesService extends ListEcosystemTemplatesService {
  selection: SelectionModel<String> = new SelectionModel<String>(true, []);

  constructor(
    public iotService: IotService,
    public ecosystemService: EcosystemsService,
    public ruleService: RulesService
  ) {
    super(iotService);
    ecosystemService.setEcosystemTemplateService(this.ecosystemService);
    this.selection.clear();
    this.loadSelected();
  }

  createResource = true;
  createAttribute = false;
  createEvent = false;
  attributes = [];
  events = [];
  attributeTypes = [];
  syntheticEvents = [];
  allowedMembers = [];
  errorMessage = '';
  warningMessage = '';

  fnOnSelectionSupport(results) {
    const that = this;
    if (that.iotService.createEcosystem == true) {
      that.ecosystemService.selectedAttributes = [];
      that.ecosystemService.selectedEvents = [];
      that.ecosystemService.selectedMembers = [];
      that.ecosystemService.setSelectedEcosystemTemplate(results.name[0].text);
      that.ecosystemService.setParentEcosystemTemplateId(results.id);
      that.fetchAttributes(results);
      that.fetchEvents(results);
      that.fetchMembers(results);
    }
    if (that.iotService.createRule == true) {
      let ecosystemTemplates = that.ruleService.getEcosystemTemplates();
      ecosystemTemplates.push(results);
      that.ruleService.setEcosystemtemplates(ecosystemTemplates);
    }
  }

  fnRemoveSelectionSupport(results) {
    const that = this;
    if (that.iotService.createEcosystem == true) {
      that.ecosystemService.setSelectedEcosystemTemplate(undefined);
      that.ecosystemService.setParentEcosystemTemplateId(undefined);
      that.ecosystemService.selectedAttributes = [];
      that.ecosystemService.selectedEvents = [];
      that.ecosystemService.selectedMembers = [];
    }
    if (that.iotService.createRule == true) {
      let ecosystemTemplates = that.ruleService.getEcosystemTemplates();
      ecosystemTemplates.forEach((value, index) => {
        if (value.id == results.id) ecosystemTemplates.splice(index, 1);
      });
      that.ruleService.setEcosystemtemplates(ecosystemTemplates);
    }
  }

  fetchEvents(results: any) {
    const that = this;
    that.iotService
      .getCuiObjResponse()
      .getEcosystemEvents({
        ecosystemTemplateId: results.id,
      })
      .then(function (response) {
        that.syntheticEvents = response;
        let ecosystemTemplateEvents = that.ecosystemService.getSelectedEvents();
        for (let i = 0; i < that.syntheticEvents.length; i++) {
          ecosystemTemplateEvents.push(that.syntheticEvents[i].name);
        }
        that.ecosystemService.setSelectedEvents(ecosystemTemplateEvents);
      })
      .fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          console.log(
            'Error Occured: Status: ' +
              err.responseJSON.status +
              ' Msg: ' +
              err.responseJSON.apiStatusCode
          );
        } else {
          console.log(
            'Something went wrong. Please check chrome console or check with dev team'
          );
        }
      });
  }

  fetchAttributes(results: any) {
    const that = this;
    that.iotService
      .getCuiObjResponse()
      .getEcosystemAttributes({
        ecosystemTemplateId: results.id,
      })
      .then(function (response) {
        that.attributeTypes = response;
        let ecosystemTemplateAttributes =
          that.ecosystemService.getSelectedAttributes();
        for (let i = 0; i < that.attributeTypes.length; i++) {
          ecosystemTemplateAttributes.push(that.attributeTypes[i].name);
        }
        that.ecosystemService.setSelectedAttributes(
          ecosystemTemplateAttributes
        );
      })
      .fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          console.log(
            'Error Occured: Status: ' +
              err.responseJSON.status +
              ' Msg: ' +
              err.responseJSON.apiStatusCode
          );
        } else {
          console.log(
            'Something went wrong. Please check chrome console or check with dev team'
          );
        }
      });
  }

  fetchMembers(results: any) {
    const that = this;
    that.iotService
      .getCuiObjResponse()
      .getEcosystemMembers({
        ecosystemTemplateId: results.id,
      })
      .then(function (response) {
        that.allowedMembers = response;
        let ecosystemTemplateMembers =
          that.ecosystemService.getSelectedMembers();
        for (let i = 0; i < that.allowedMembers.length; i++) {
          ecosystemTemplateMembers.push({
            type: that.allowedMembers[i].type,
            kind: that.allowedMembers[i].kind,
          });
        }
        that.ecosystemService.setSelectedMembers(ecosystemTemplateMembers);
      })
      .fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          console.log(
            'Error Occured: Status: ' +
              err.responseJSON.status +
              ' Msg: ' +
              err.responseJSON.apiStatusCode
          );
        } else {
          console.log(
            'Something went wrong. Please check chrome console or check with dev team'
          );
        }
      });
  }

  getAttributes(): any {
    return this.attributes;
  }

  setAttributes(attributes: any) {
    this.attributes = attributes;
  }

  getEvents(): any {
    return this.events;
  }

  setEvents(events: any) {
    this.events = events;
  }

  getEcosystemService(): any {
    return this.ecosystemService;
  }

  setEcosystemService(ecosystemService: any) {
    this.ecosystemService = ecosystemService;
  }

  resetErrorMessage() {
    this.errorMessage = '';
  }

  resetWarningMessage() {
    this.warningMessage = '';
  }

  loadSelected() {
    if (
      this.ruleService.getEcosystemTemplates().length > 0 &&
      this.iotService.viewRule == true
    ) {
      this.ruleService.getEcosystemTemplates().forEach((ecosystemTemplate) => {
        this.selection.select(ecosystemTemplate.id);
      });
    }
  }
}

import { Injectable } from '@angular/core';
import { SelectionModel} from '@angular/cdk/collections';
import { IotService } from '../service/iot.service'; 
import { ListMessageGovernancesService } from './ListMessageGovernances.service';

@Injectable({ 
	  providedIn: 'root' 
})

export class MessageGovernancesService extends ListMessageGovernancesService {
	 constructor(public iotService:IotService) {
		 super(iotService);
	 }

	errorMessage: string;
	successMessage:string;
	devices = [];
	commandTemplates = [];
	events = [];
	senderStreams=[];
	receiverStreams=[];
	documentInfos=[];
	fromCodeValue=[];
	toCodeValue=[];


	 fnOnSelectionSupport(results){
		 const that = this; 
	 } 

	 fnRemoveSelectionSupport(results){ 
		 const that = this; 
	 } 
	

	resetErrorMessage() {
		this.errorMessage = '';
	  }

	  getDevices(): any {
		return this.devices;
	  }
	
	  setDevices(devices: any) {
		this.devices = devices;
	  }
	
	  getEvents(): any {
		return this.events;
	  }
	
	  setEvents(events: any) {
		this.events = events;
	  }

	  getSenderStreams(): any {
		return this.senderStreams;
	  }
	
	  setSenderStreams(senderStreams: any) {
		this.senderStreams = senderStreams;
	  }

	  getReceiverStreams(): any {
		return this.receiverStreams;
	  }
	
	  setReceiverStreams(receiverStreams: any) {
		this.receiverStreams = receiverStreams;
	  }
	
	  getDocumentInfos(): any {
		return this.documentInfos;
	  }
	
	  setDocumentInfos(documentInfos: any) {
		this.documentInfos = documentInfos;
	  }

	  getCommandTemplates(): any {
		return this.commandTemplates;
	  }
	
	  setCommandTemplates(commandTemplates: any) {
		this.commandTemplates = commandTemplates;
	  }

	  setFromCodeValue(fromCodeValue) {
		this.fromCodeValue = fromCodeValue;
	  }
	  getFromCodeValue() {
		return this.fromCodeValue;
	  }
	
	  setToCodeValue(toCodeValue) {
		this.toCodeValue = toCodeValue;
	  }
	  getToCodeValue() {
		return this.toCodeValue;
	  }
	  resetSuccessMessage() {
		this.successMessage = '';
	}

	  

} 

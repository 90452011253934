import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IotService } from '../../service/iot.service';
import { DeviceService } from '../../service/device.service';
import { faAngleDoubleLeft, faAngleDoubleRight, faToggleOff, faToggleOn,faCheck } from '@fortawesome/free-solid-svg-icons';
import {SelectionModel} from '@angular/cdk/collections';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-command-list',
  templateUrl: './command-list.component.html',
  styleUrls: ['./command-list.component.scss']
})
export class CommandListComponent implements OnInit {


  dataSource: any;
  displayedColumns: string[] = [' ','Name', 'Description','Tags', 'Enabled'];
  length = 1000;
  faAngleDoubleLeftIcon = faAngleDoubleLeft;
  faAngleDoubleRightIcon = faAngleDoubleRight;
  faToggleOffIcon = faToggleOff;
  faToggleOnIcon = faToggleOn;
  faCheckIcon = faCheck;
  commandSelected: string;
  selectedCommands = [];
  commandId;
  args=[];


  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;
  pageSize = 5;
  currentPage = 1;
  searchText;
  templateName;
  attributes = [];
  associations = [];
  attribute = [];
  showValue = false;
  private role: string;
  showSelect = true;
  HighlightRow: Number;
  types=[]
  supportLanguages = ['en'];
  selection: SelectionModel<Element> = new SelectionModel<Element>(true, []);

  commandCount:number=0;
  /* pageListOptions: number[] = [1];
  totalPages=0;
  firstPageElement:number=1;
  lastPage:boolean=true;
  firstPage:boolean=true;
  defaultPages = 10; */
  showPagination=false;
  constructor(private iotService:IotService,private deviceService:DeviceService,private translateService: TranslateService) {
    this.translateService.addLangs(this.supportLanguages);
      this.translateService.setDefaultLang('en');
  }

  ngOnInit(): void {
    this.getCommands();
    if(this.deviceService.getCommandData()!=undefined){
      this.deviceService.getCommandData().forEach((value,index)=>{
        this.selectedCommands.push(value);
       });
       this.selection=new SelectionModel<Element>(true, this.selectedCommands);
    }
    // this.deviceService.setCommandData([]);
    // this.deviceService.commandSelect=false;
    this.selection.isSelected = this.isChecked.bind(this);
    this.countCommands('');
  }
  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }

    
  isChecked(row: any): boolean {
    const found = this.selection.selected.find(el => el.id === row.id);
    if (found) {
      return true;
    }
    return false;
 }


  onCommandSearch(event: any) {
    this.commandSelected = event.target.value;
    let name = '*'+event.target.value+'*';
    const that = this;
    that.currentPage =1;
    that.countCommands(name);
    this.iotService.getCuiObjResponse().getCommandTemplates({
      qs: [['pageSize', 5], ['page', this.currentPage], ['name', name]]
    }).then(function (response) {
      // console.log(response);
      that.attributes = response;
      that.dataSource = that.attributes;
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  getCommands() {
    const that = this;
    this.iotService.getCuiObjResponse().getCommandTemplates({
      qs: [['pageSize', 5], ['page', this.currentPage]]
    }).then(function (response) {
      // console.log(response);
      that.attributes = response;
      that.dataSource = that.attributes;
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  getSelectedCommandForCurrentPage(commandSelected) {
    this.commandSelected = commandSelected;
    let name = '*' + commandSelected +'*';
    const that = this;
    this.iotService.getCuiObjResponse().getCommandTemplates({
      qs: [['pageSize', 5], ['page', this.currentPage], ['name',  name]]
    }).then(function (response) {
      // console.log(response);
      that.attributes = response;
      that.dataSource = that.attributes;
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  OnSelection(event, commandTemplateId) {

    const that = this;
    console.log(event); //true or false

    this.iotService.getCuiObjResponse().getCommandTemplate({
      commandTemplateId: commandTemplateId
    }).then(function (response) {
      // console.log(response);

      if (event == true) {
        that.selection.select(response);
        that.deviceService.commandSelect = true;
        that.selectedCommands.push(response);
      } else {
        that.selection.clear();
        that.selectedCommands.forEach((value, index) => {
          if (value.id == commandTemplateId) that.selectedCommands.splice(index, 1);
        });
        that.selectedCommands.forEach((value, index) => {
          that.selection.select(value);
        });
       
      }
      that.deviceService.setCommandData(that.selectedCommands);
      if (that.deviceService.getCommandData().length < 1) {
        that.deviceService.commandSelect = false;
      }

    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  enableCommand(commandTemplateId: any) {
    const that = this;
    this.iotService.getCuiObjResponse().activateCommandTemplate({
      commandTemplateId: commandTemplateId
    }).then(function (response) {
      // console.log(response);
      if (that.commandSelected) {
        that.getSelectedCommandForCurrentPage(that.commandSelected)
      }
      else {
        that.getCommands();
      }
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
       /*  that.deviceService.warningMessage=err.responseJSON.apiMessage;
        console.log("Hello"+err.responseJSON.apiMessage.split(":")[0]);
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode); */

        var errorStr=err.responseJSON.apiMessage;
          var error = [];
          error=errorStr.split(":");
        if(err.responseJSON.apiStatusCode=="framework:resource:commandTemplate:duplicate"){
          var commandId = error[1].trim();
        that.deviceService.duplicateCommandId = commandId;
        that.deviceService.duplicateCommandFlag = true;
        that.deviceService.duplicateAttrFlag = false;
        that.deviceService.duplicateEventFlag=false;
        that.deviceService.warningMessage="Command template with same name is active. Click here to "
          console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
        }
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  disableCommand(command:any,commandTemplateId: any) {
    const that = this;
    this.iotService.getCuiObjResponse().deactivateCommandTemplate({
      commandTemplateId: commandTemplateId
    }).then(function (response) {
      // console.log(response);

      that.selection.clear();
      that.selectedCommands.forEach((value, index) => {
        if (value.id == commandTemplateId) that.selectedCommands.splice(index, 1);
      });
      that.selectedCommands.forEach((value, index) => {
        that.selection.select(value);
      });
      that.deviceService.setCommandData(that.selectedCommands);
      if (that.deviceService.getCommandData()!=undefined && that.deviceService.getCommandData().length < 1) {
        that.deviceService.commandSelect = false;
      }
      if (that.commandSelected) {
        that.getSelectedCommandForCurrentPage(that.commandSelected)
      }
      else {
        that.getCommands();
      }
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }


  getArgs(commandObject){
    this.args=[]
    if(commandObject.args!=undefined){
      commandObject.args.forEach(ele => {
        // console.log(ele);
        this.args.push(ele.name);
      });
    }
    return this.args;    
  }

  getTypes(commandObject){
    this.types=[]
    if(commandObject.args!=undefined){
      commandObject.args.forEach(ele => {
        // console.log(ele);
        this.types.push(ele.type);
      });
    }
   
    return this.types;    
  }

  createCommand(){
    this.deviceService.createResource=true;
    this.deviceService.createCommand=true;
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  countCommands(val) {
    const that = this;
    that.showPagination=false;
    if(val === "**") {
      val = '';
    }
    if(val === '') {
      that.iotService.getCuiObjResponse().countCommandTemplates({
      }).then(function (response) {
        console.log(response);
        that.commandCount = response;
       // that.getCountPages();
       that.showPagination=true;

      }).fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
        } else {
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    } else {
      that.iotService.getCuiObjResponse().countCommandTemplates({
        qs:[['name',val]]
      }).then(function (response) {
        console.log(response);
        that.commandCount = response;
      //  that.getCountPages();
        that.showPagination=true;
      }).fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
        } else {
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    }
  }

  onPageChange(page) {
    const that = this;
    console.log("page : "+page);
    that.currentPage = page;
    if (this.commandSelected) {
      this.getSelectedCommandForCurrentPage(this.commandSelected);
    } else {
      this.getCommands();
    }
  }

  /* getCountPages() {
    const that = this;
    that.totalPages = Math.ceil(that.commandCount / that.pageSize);
    that.firstPageElement = 1;

    console.log("******** getCountPages *********");
    console.log("totalPages : " + that.totalPages);
    if (that.totalPages == 0) {
      that.firstPageElement = 1;
      that.pageListOptions = [1];
      that.lastPage = true;
      that.firstPage = true;
    } else if (that.totalPages > that.defaultPages) {
      that.pageListOptions = Array.from({length:that.defaultPages},(v,k)=>that.firstPageElement++);
      that.lastPage = false;
      that.firstPage = true;
    } else {
      that.pageListOptions = Array.from({length:that.totalPages},(v,k)=>that.firstPageElement++);
      that.lastPage = true;
      that.firstPage = true;
    }
    console.log("that.pageListOptions : "+that.pageListOptions);
  }

  onPageChange(page) {
    const that = this;
    console.log("page : "+page);
    that.currentPage = page;
    if (this.commandSelected) {
      this.getSelectedCommandForCurrentPage(this.commandSelected);
    } else {
      this.getCommands();
    }
  }

  prevPage() {
    const that = this;
    that.lastPage=false;
    
    console.log("******** prevPage *********");
    console.log("that.firstPageElement : "+that.firstPageElement);
    console.log("that.totalPages : "+that.totalPages);

    if(that.firstPageElement > that.totalPages) {
      that.firstPageElement = that.firstPageElement-that.defaultPages-(that.totalPages % that.defaultPages);
    } else {
      that.firstPageElement = that.firstPageElement-(2*that.defaultPages);
    }

    if (that.firstPageElement < 1) {
      that.firstPageElement = 1;
      that.firstPage = true;
    } else if(that.firstPageElement === 1) {
      that.firstPage = true;
    }
    that.pageListOptions = Array.from({length:that.defaultPages},(v,k)=> that.firstPageElement++);

    console.log("that.pageListOptions : "+that.pageListOptions);
  }

  nextPage() {
    const that = this;
    that.firstPage = false;
    console.log("that.totalPages : "+that.totalPages);
    console.log("that.firstPageElement : "+that.firstPageElement);
    var last = that.totalPages - that.firstPageElement;
   
    console.log("last : "+last);
    if(last < 0) {
      that.firstPageElement = 1;
      that.firstPage = true;
      that.pageListOptions = Array.from({length:that.defaultPages},(v,k)=> that.firstPageElement++);
    } else {
      if(last < that.defaultPages) {
        that.pageListOptions = Array.from({length:last+1},(v,k)=> that.firstPageElement++);
        that.lastPage = true;
      } else {
        that.pageListOptions = Array.from({length:that.defaultPages},(v,k)=> that.firstPageElement++);
        that.lastPage = false;
      }  
    }
    console.log("that.pageListOptions : "+that.pageListOptions);
  }
 */
}



        <div class="adaptor-data">

            <label ><span style="color:red;">*</span>{{'start.date' | translate}}</label>
            <input placeholder="required" id="date" type="date" aria-label="date" [(ngModel)]="startDate">
                        <input placeholder="required" id="time" type="time" step="1" aria-label="time"
                            [(ngModel)]="startTime">

            <label >{{'end.date' | translate}}</label>
            <input placeholder="required" id="date" type="date" aria-label="date" [(ngModel)]="endDate">
            <input placeholder="required" id="time" type="time" step="1" aria-label="time"
                [(ngModel)]="endTime">

            <label >{{'log.level' | translate}}</label>
            <ng-container>
                <select name="logLevel" [(ngModel)]="logLevel" (ngModelChange)="onChange($event)">
                    <option [value]="item.logLevel" *ngFor="let item of logLevels">
                        {{item.logLevel}}
                    </option>
                </select>
                <!-- {{logLevel}} -->
            </ng-container>     
            
            
            <label >{{'max.records' | translate}}</label>
            <input class="maxRecords" [(ngModel)]="logRequestInfo.noOfLogRecords" type="number"
            placeholder="Max Records">
                
                

            <!-- <table>
                <tr>
                    <td><br>
                        <b><span style="color:red;">*</span>Start Date:</b>
                    </td>
                    <td><br>
                        <input placeholder="required" id="date" type="date" aria-label="date" [(ngModel)]="startDate">
                        <input placeholder="required" id="time" type="time" step="1" aria-label="time"
                            [(ngModel)]="startTime">
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>End Date:</b>
                    </td>
                    <td>
                        <input placeholder="required" id="date" type="date" aria-label="date" [(ngModel)]="endDate">
                        <input placeholder="required" id="time" type="time" step="1" aria-label="time"
                            [(ngModel)]="endTime">
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>Log Level:</b>
                    </td>
                    <td>
                        <ng-container>
                            <select name="logLevel" [(ngModel)]="logLevel" (ngModelChange)="onChange($event)">
                                <option [value]="item.logLevel" *ngFor="let item of logLevels">
                                    {{item.logLevel}}
                                </option>
                            </select>
                        </ng-container>

                    </td>
                </tr>
                <tr>
                    <td>
                        <b>Max Records:</b>
                    </td>
                    <td>
                        <input [(ngModel)]="logRequestInfo.noOfLogRecords" type="number"
                            placeholder="Max Records">
                    </td>
                </tr>
            </table> -->
            <br>
            <button class="btn btn-secondary"  (click)="goBack()">{{ 'go.back' | translate}}</button>&nbsp;
            <button color="btn btn primary" (click)="save()">{{ 'save' | translate}}</button>
            
           <br>
        </div>

import { Injectable } from '@angular/core';
import { IotService } from './iot.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {



  constructor(public iotService:IotService) {
  
  }

  successMessage='';
  warningMessage='';
  errorMessage: string;
  
  resetWarningMessage(){
    this.warningMessage='';
  }
  resetSuccessMessage(){
    this.successMessage='';
  }
  
  resetErrorMessage() {
    this.errorMessage = '';
  }
}

<!--
* This is an auto generated code for ecosystemInstanceMembers listing page.
* Please don't make any change to this file.
* @version 1.0.0 
* @readonly 
* @author - System generated 
-->
<div class="container">
	<header class="table-header">
		<h2>
			{{pageTitle | translate}}
			<span *ngIf="showHeaderToolTips" id="ot-tooltip-bottom" class="ot--is-width-px-200">
				<a class="ot-tooltip ot-tooltip--is-bottom">
					<fa-icon [icon]="faQuestionCircleIcon"></fa-icon>
					<span class="ot-tooltip__text">{{'ecosystemInstanceMembers.definition' | translate}}</span>
				</a>
			</span>
		</h2>
	</header>
	<div *ngIf="showControlBar" class="control-bar">
		<input *ngIf="showSearch" type="text" placeholder="{{'ecosystemInstanceMembers.search' | translate}}"
			(keyup)="getSearchResults($event)">
		<span *ngIf="newWithRoute">
			<button *ngIf="showNew" class="newBtn btn-primary" [routerLink]="['/createEcosystemMembers']"
				(click)="createNew()" aria-label="">{{'ecosystemInstanceMembers.new' | translate }}</button>
		</span>
		<span *ngIf="!newWithRoute">
			<button class="newBtn btn-primary" (click)="createNew()" aria-label="">{{'ecosystemInstanceMembers.new' |
				translate }}</button>
		</span>
	</div>
	<div class="table-container">
		<ngx-skeleton-loader *ngIf="!dataSource" count="12" [theme]="{'border-radius': '0px',
		height: '48px', 
		width: '100%', 
		margin: '1px', 
		position: 'relative', 
		top: '20px' }">
		</ngx-skeleton-loader>
		<ng-container *ngIf="totalCount > 0">
			<table mat-table [dataSource]="dataSource" multiTemplateDataRows class="ecosystemInstanceMembersTable">
				<ng-container *ngIf="showSelectColoumn">
					<ng-container matColumnDef="ADD">
						<th mat-header-cell *matHeaderCellDef class="checkbox-cell addCol"> {{addSelectButtonLabel |
							translate}}</th>
						<td mat-cell *matCellDef="let results" class="checkbox-cell addCol">
							<ng-container>
								<div class="btn-col">
									<ng-container
										*ngIf="ecosystemInstanceMembersService.selection.isSelected(results.id)">
										<button class="selected btn btn-micro btn-secondary"
											(click)="removeSelection(results)">
											<fa-icon [icon]="faCheckIcon"></fa-icon>
										</button>
									</ng-container>
									<ng-container
										*ngIf="!ecosystemInstanceMembersService.selection.isSelected(results.id)">
										<button class="select btn btn-micro btn-secondary"
											(click)="onSelection(results)">{{addSelectButtonLabel | translate}}</button>
									</ng-container>
								</div>
							</ng-container>
						</td>
					</ng-container>
				</ng-container>
				<ng-container matColumnDef="memberType">
					<th class="name-cell memberTypeCol" mat-header-cell *matHeaderCellDef>
						{{'ecosystemInstanceMembers.memberType' | translate}}
					</th>
					<ng-container *matCellDef="let results">
						<td class="name-cell memberTypeCol" mat-cell *ngIf="results.memberType">
							{{results.memberType}}
						</td>
						<td mat-cell *ngIf="!results.memberType"> - </td>
					</ng-container>
				</ng-container>
				<ng-container matColumnDef="memberName">
					<th class="name-cell memberNameCol" mat-header-cell *matHeaderCellDef>
						{{'ecosystemInstanceMembers.memberName' | translate}}
					</th>
					<ng-container *matCellDef="let results">
						<td class="name-cell memberNameCol" mat-cell *ngIf="results.memberName">
							{{results.memberName}}
						</td>
						<td mat-cell *ngIf="!results.memberName"> - </td>
					</ng-container>
				</ng-container>
				<ng-container matColumnDef="memberKind">
					<th class="name-cell memberKindCol" mat-header-cell *matHeaderCellDef>
						{{'ecosystemInstanceMembers.memberKind' | translate}}
					</th>
					<ng-container *matCellDef="let results">
						<td class="name-cell memberKindCol" mat-cell *ngIf="results.memberKind">
							{{results.memberKind}}
						</td>
						<td mat-cell *ngIf="!results.memberKind"> - </td>
					</ng-container>
				</ng-container>
				<ng-container matColumnDef="creation">
					<th class="date-cell creationCol" mat-header-cell *matHeaderCellDef mat-sort-header="creation">
						<span class="left"> {{'ecosystemInstanceMembers.creation' | translate}} </span>
						<span class="right">
							<a (click)="getSortResults('creation', 'asc')" [class.active]="ascCreation === true">
								<fa-icon title="{{'ascending' | translate}}" [icon]="faArrowUpIcon"></fa-icon>
							</a>
							<a (click)="getSortResults('creation', 'desc')" [class.active]="descCreation === true">
								<fa-icon title="{{'descending' | translate}}" [icon]="faArrowDownIcon"></fa-icon>
							</a>
						</span>
					</th>
					<td class="date-cell creationCol" mat-cell *matCellDef="let results">
						{{results.creation | date:'mediumDate' }}
					</td>
				</ng-container>

				<ng-container matColumnDef="membershipAttributes">
					<th class="desc-cell membershipAttributesCol" mat-header-cell *matHeaderCellDef>
						{{'ecosystemInstanceMembers.membershipAttributes' | translate}}
					</th>
					<ng-container *matCellDef="let results">
						<td class="desc-cell membershipAttributesCol" mat-cell *ngIf="results.membershipAttributes.length > 1">
							<a
								(click)="viewAttributes(results,expandedElement === results ? null : results);expandedElement = expandedElement === results ? null : results">
								View
							</a>
						</td>
						<!-- Skip default attribute "Member_kind" from ui display -->
						<td mat-cell *ngIf="results.membershipAttributes.length <= 1"> - </td>
					</ng-container>
				</ng-container>

				<ng-container *ngIf="showActionColumn" matColumnDef="ACTION">
					<th class="action-cell actionCol" mat-header-cell *matHeaderCellDef>
						{{'generic.column.title.actions' | translate}} </th>
					<ng-container *matCellDef="let results">
						<td class="action-cell actionCol" mat-cell>
							<a (click)="deleteEcosystemInstanceMembers(results.id)">
								<fa-icon title="{{'method.delete.tooltip.ecosystemInstanceMembers' | translate}}"
									style="color: red;font-size: 20px;" [icon]="faCloseIcon"></fa-icon>
							</a>
						</td>
					</ng-container>
				</ng-container>
				<!-- details container -->
				<ng-container matColumnDef="expandedDetail">
					<td mat-cell *matCellDef="let results" [attr.colspan]="displayedColumns.length">
						<div class="message-element-detail"
							[@detailExpand]="results === expandedElement  ? 'expanded' : 'collapsed'">
							<div class="message-element-description">
								<div>
									<table *ngIf="membershipAttributes.length" mat-table
										[dataSource]="membershipAttributes">
										<ng-container matColumnDef="attributeName">
											<th class="name-cell attrNameCol" mat-header-cell *matHeaderCellDef>
												Name
											</th>
											<td class="name-cell attrNameCol" mat-cell
												*matCellDef="let membershipAttribute">
												{{membershipAttribute.name}}
											</td>
										</ng-container>

										<ng-container matColumnDef="attributeType">
											<th class="name-cell attrTypeCol" mat-header-cell *matHeaderCellDef>
												Data Type
											</th>
											<td class="name-cell attrTypeCol" mat-cell
												*matCellDef="let membershipAttribute">
												{{membershipAttribute.type}}
											</td>
										</ng-container>

										<ng-container matColumnDef="value">
											<th class="name-cell attrValueCol" mat-header-cell *matHeaderCellDef>
												Value
											</th>
											<ng-container *matCellDef="let membershipAttribute">
												<td class="name-cell attrValueCol" mat-cell
													*ngIf="membershipAttribute.value">
													{{membershipAttribute.value}}
												</td>
												<td mat-cell *ngIf="!membershipAttribute.value"> - </td>
											</ng-container>
										</ng-container>

										<tr mat-header-row *matHeaderRowDef="membershipAttributeColumns"></tr>
										<tr mat-row
											*matRowDef="let membershipAttribute; columns: membershipAttributeColumns;"
											class="bg-message"></tr>
									</table>
								</div>
								<div class="message-element-close">
									<button
										(click)="viewAttributes(results,expandedElement === results ? null : results);expandedElement = expandedElement === results ? null : results">Close</button>
								</div>
							</div>
						</div>
					</td>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let results; columns: displayedColumns;" [ngClass]="results.messageClass"
					[class.message-expanded-row]="expandedElement === results"></tr>
				<!-- Extra row to show detail content column -->
				<tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="message-detail-row"></tr>
			</table>
			<app-pagination *ngIf="showPagination" [count]="totalCount" [pageSize]="pageSize"
				[currentPage]="currentPage" [pageSizeOptions]="pageSizeOptions"
				(currentPageChange)="onPageChange($event)" (currentPageSizeChange)="onPageSizeChange($event)">
			</app-pagination>
		</ng-container>
	</div>
	<ng-container *ngIf="!countCallCompleted && !getCallCompleted && totalCount == 0">
		<div class="table-container text-center"></div>
	</ng-container>
	<ng-container *ngIf="countCallCompleted && getCallCompleted && totalCount == 0">
		<div class="table-container text-center">{{'generic.no.records.found' | translate}}</div>
	</ng-container>
</div>
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IotService } from 'src/app/service/iot.service';
import { SideNavComponent } from 'src/app/side-nav/side-nav.component';
import { faCheckCircle, faExclamation, faExclamationTriangle, faInfoCircle, faTimes, faQuestionCircle, faCheck } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { SecurtiyService } from 'src/app/service/security.service';


@Component({
  selector: 'app-new-trusted-certificate',
  templateUrl: './new-trusted-certificate.component.html',
  styleUrls: ['./new-trusted-certificate.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  }
})
export class NewTrustedCertificateComponent implements OnInit {


  faInfoIcon = faInfoCircle;
  faCloseIcon = faTimes;
  faCriticalIcon = faExclamation;
  faWarningIcon = faExclamationTriangle;
  faSuccessIcon = faCheckCircle;
  faQuestionCircleIcon = faQuestionCircle;
  faCheckIcon = faCheck;

  supportLanguages = ['en'];
  Certificate;
  certData;
  successMessage: string;
  warningMessage: string;
  errorMessage:string;
  trustedCertificateCreated=false;
  constructor(public iotService: IotService,public securityService: SecurtiyService, private translateService: TranslateService, private sideNavComponent: SideNavComponent, private router: Router) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
    this.sideNavComponent.menuClear();
    this.sideNavComponent.menuChange('Dashboard', 'sub-type');
    this.sideNavComponent.menuChange('certificates.header', 'sub-type');
    this.sideNavComponent.menuChange('trustedCertificates.header', 'sub-type');
    this.sideNavComponent.menuChange('trustedCertificates.create.new', 'sub-type');
  }

  ngOnInit(): void {
    this.securityService.setCertificateData("");
    this.initCertificate();
  }

  onClick(event) {
    this.resetSuccessMessage();
    this.resetWarningMessage();
    this.errorMessage='';

  }
  resetWarningMessage() {
    this.warningMessage = "";
  }
  resetSuccessMessage() {
    this.successMessage = "";
  }

  initCertificate() {
    const that = this;
    that.Certificate = {
      "creator": that.iotService.getCreator(),
      "creatorAppId": that.iotService.getCreatorAppId(),
      "realm": that.iotService.getRealm(),
      "certData": that.securityService.getCertificateData()

    }
  }
  save() {

    const that = this;
    let Cert = that.securityService.certificateData + "";
    // Remove BEGIN and END 
    Cert = Cert.replace("-----BEGIN CERTIFICATE-----", "");
    Cert = Cert.replace("-----END CERTIFICATE-----", "");
    // Remove All Line Breaks
    Cert = Cert.replace(/(\r\n|\n|\r)/gm, "");

    that.Certificate.certData = Cert;
    that.trustedCertificateCreated=true;
    that.iotService.getCuiObjResponse().createx509Cetrificate({
      data: that.Certificate
    })
      .then(function (response) {
        console.log("New trusted authority created - " + response.id);
        that.securityService.showCertificates=true;
        that.securityService.showAuthorities=false;
        that.router.navigateByUrl("/certificates");
        // that.successMessage="Certificate created succesfully"
      })
      .fail(function (err) {
        that.trustedCertificateCreated=false;
        if (err.responseJSON.apiStatusCode) {
          that.errorMessage = "Error Occured while creating trusted certificate";
          console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
        } else {
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
        if (err === null) {
          console.log("Error Occured while creating trusted certificate");
        }
      });


  }

}

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IotService } from 'src/app/service/iot.service';
import { SecurtiyService } from 'src/app/service/security.service';

@Component({
  selector: 'app-certificate-data',
  templateUrl: './certificate-data.component.html',
  styleUrls: ['./certificate-data.component.scss']
})
export class CertificateDataComponent implements OnInit {

  supportLanguages = ['en'];


  constructor(public iotService:IotService,public securityService: SecurtiyService, private translateService: TranslateService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }

  ngOnInit(): void {
  }
  
  
  certificateData(event: any) {
    const that = this;
    that.securityService.setCertificateData(event.target.value);
  }


}

<!-- <ol class="breadcrumb">
  <li *ngFor="let breadcrumb of breadcrumbs">
    <span [routerLink]="breadcrumb.url" routerLinkActive="router-link-active">
      {{ breadcrumb.label }}
    </span>
  </li>
</ol> -->
<ol class="breadcrumb">
  <li *ngFor="let breadcrumb of bcrumbs">
    <span *ngIf="breadcrumb.url != ''" [routerLink]="breadcrumb.url" routerLinkActive="router-link-active">
      {{ breadcrumb.label }}
    </span>
    <span *ngIf="breadcrumb.url == ''">
      {{ breadcrumb.label }}
    </span>
  </li>
</ol>

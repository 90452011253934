import { Component, OnInit } from '@angular/core';
import {  ChartType,Chart } from 'chart.js';
import { SingleDataSet, Label } from 'ng2-charts';
import { IotService } from '../service/iot.service';

@Component({
  selector: 'app-widgets',
  templateUrl: './widgets.component.html',
  styleUrls: ['./widgets.component.scss']
})
export class WidgetsComponent implements OnInit {
  chart: any;

  constructor(private iotService: IotService) {
   
  }

  ngOnInit(): void {
   this.getDeviceExceptionsCriticalCount();
   this.getDeviceExceptionsWarningCount();
   this.getDeviceExceptionsAlertCount();
  //  this.getDeviceExceptionsTotalCount();

  
  }



    criticalViolationCount = 0;

    warningViolationCount =0;

    alertViolationCount =0;
  
    totalViolationCount =0;
  
  
    public doughnutChartLabels: Label[] = ['Critical', 'Warning', 'Alert'];
    doughnutColors=[
      {
       backgroundColor: [' #a7261b','#f05822',' #111b58'],
        hoverBackgroundColor:   [' #a7261b','#f05822',' #111b58'],
        borderWidth: [0, 0, 0,0],
        hoverBorderWidth:[5,5,5,5],
        hoverBorderColor: [' #a7261b','#f05822',' #111b58'],
    
       }
       ];
    public doughnutChartData: SingleDataSet = [
       this.criticalViolationCount,this.warningViolationCount,this.alertViolationCount
      
    ];


    public doughnutChartType: ChartType = 'doughnut';
  
    public doughnutChartOptions: any = {
      segmentShowStroke: false,
      maintainAspectRatio: false,
      responsive: true,
      legend: {
        position: 'bottom',
        display: true,
        onClick: null,
        
        
        labels: {      
          boxWidth:20,
          fontSize:15,
          // data:10,
          fontColor:'black',
          padding:10          
        }
      },
      // no percentage
      
      // plugins: {
      //   labels: false
      // }

      
      plugins: {
        labels: {
          //change the value to percentage inorder to render percentage
          render: 'value',
          responsive: true,
          fontSize:20,
          fontColor: ["white", "white", "white"],
          precision: 1,
          overlap: false,
        }
      }
    }



    
  // criticalViolationCount
  getDeviceExceptionsCriticalCount(){
    const that = this;
    this.iotService.getCuiObjResponse().getDeviceExceptionsCriticalCount({
    }).then(function(response) {
      console.log(response);
      that.criticalViolationCount = response;
      that.doughnutChartData=[that.criticalViolationCount,that.warningViolationCount,that.alertViolationCount];
       
      
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  // warningViolationCount
  getDeviceExceptionsWarningCount(){
    const that = this;
    this.iotService.getCuiObjResponse().getDeviceExceptionsWarningCount({
    }).then(function(response) {
      console.log(response);
      that.warningViolationCount = response;
      that.doughnutChartData=[that.criticalViolationCount,that.warningViolationCount,that.alertViolationCount];

      // that.showWarningPagination=true;
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  // totalViolationCount
  getDeviceExceptionsTotalCount(){
    const that = this;
    this.iotService.getCuiObjResponse().getDeviceExceptionsTotalCount({
    }).then(function(response) {
      console.log(response);
      that.totalViolationCount = response;
      that.doughnutChartData=[that.criticalViolationCount,that.warningViolationCount,that.alertViolationCount];

      // that.showTotalPagination=true;
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  // alertViolationCount
  getDeviceExceptionsAlertCount(){
    const that = this;
    this.iotService.getCuiObjResponse().getDeviceExceptionsAlertCount({
    }).then(function(response) {
      console.log(response);
      that.alertViolationCount = response;
      that.doughnutChartData=[that.criticalViolationCount,that.warningViolationCount,that.alertViolationCount];

      // that.showAlertPagination=true;
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }
}

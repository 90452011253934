<main id="eventGroup-details">
  <div class="d-flex justify-content-center loader" *ngIf="!eventGroup">
    <div class="spinner-border" role="status">
      <span class="sr-only" id="loading"></span>
    </div>
  </div>

  <mat-sidenav-container autosize *ngIf="eventGroup">
    <mat-sidenav class="midNav-container" #sidenav mode="side" disableClose="true" opened="true">
      <section class="left-col">
        <div style="margin-left: -5px;">
          <app-copy-resource-id></app-copy-resource-id>
      </div>
        <h1>{{eventGroup.name[0].text}}</h1>
        <dl>
          <dt>{{ 'name' | translate}}</dt>
          <dd>
            <p >{{eventGroup.name[0].text}}</p>
          </dd>
        </dl>
        <dl>
          <dt>{{ 'description' | translate}}</dt>
          <dd *ngIf="eventGroup.description[0].text==''">
            <p >-</p>
          </dd>
          <dd>
            <p >{{eventGroup.description[0].text}}</p>
          </dd>
        </dl>
        <div style="margin: 0 15px 0 15px">
        <label for="event-group-tags">{{ 'tags' | translate}}</label>
        <mat-chip-list #tagList aria-label="Tags">
          <mat-chip class="ot-chip" *ngFor="let tag of tags" [selectable]="true" [removable]="true"
              (removed)="remove(tag)">
              {{ tag }}
              <button class="ot-chip__close" matChipRemove></button>
          </mat-chip>
          <input [ngClass]="tagClass" placeholder="add a tag" #tagInput [matChipInputFor]="tagList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="add($event)" />
        </mat-chip-list>
       </div>
        <dl>
          <dt>{{ 'created' | translate}}</dt>
          <dd>{{eventGroup.creation | date:'mediumDate' }}</dd>
        </dl>
        <dl>
          <dt>{{ 'status' | translate}}</dt>
          <button *ngIf="eventGroup.active" class="toggle-on toggle-switch" (click)="toggleActive(false)">
            <fa-icon [icon]="faCheckIcon" class="fa-2x"></fa-icon>
            <div class="toggle-on-btn"></div>
          </button>
          <button *ngIf="!eventGroup.active" class="toggle-off toggle-switch" (click)="toggleActive(true)">
            <div class="toggle-off-btn"></div>
          </button>
        </dl>
        <hr>
      </section>
      
      <div>
        <!-- <button class="btn btn-primary" style="margin: 5px;" (click)="auditEventGroup()">{{ 'Audit'
            |translate}}</button> -->
        <button class="btn btn-delete pull-right" style="margin: 15px" (click)="deleteEventGroup()">
            {{'delete' | translate}}</button>
      </div>
    </mat-sidenav>
    
    <div style="margin: 0 15px 0 15px; height: 450vh">
    <ng-container>
      <ng-container *ngIf="dataSourceDevices">
      <ng-container>
        <header>
          <h3>{{'events.from.devices' | translate}}</h3>
        </header>
        <table mat-table [dataSource]="dataSourceDevices">
          <!-- Name Column -->
          <ng-container matColumnDef="Device">
            <th mat-header-cell *matHeaderCellDef>{{'device' | translate}}</th>
            <ng-container *matCellDef="let results" style="font-size: small;">
              <td class="name-cell" mat-cell *ngIf="results.deviceName">{{results.deviceName}}</td>
            </ng-container>
          </ng-container>
          <!-- Description Column -->
          <ng-container matColumnDef="EventTemplate">
            <th mat-header-cell *matHeaderCellDef>{{'event.template' | translate}}</th>
            <ng-container *matCellDef="let results" style="font-size: small;">
              <td class="name-cell" mat-cell *ngIf="results.eventTemplateName">{{results.eventTemplateName}}</td>
            </ng-container>
         </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
        <tr mat-row *matRowDef="let results; columns: displayedColumns1;"></tr>
        </table>
      </ng-container>
      </ng-container>

      <!-- <ng-container *ngIf="eventGroup.sourceCommandDevices"> -->
        <ng-container *ngIf="dataSourceCommandDevices">
        <header>
          <h3>{{'commands.from.devices'}}</h3>
        </header>
        <table mat-table [dataSource]="dataSourceCommandDevices">
        <!-- Name Column -->
          <ng-container matColumnDef="Device">
            <th mat-header-cell *matHeaderCellDef>{{'device' | translate}}</th>
            <ng-container *matCellDef="let results" style="font-size: small;">
              <td class="name-cell" mat-cell *ngIf="results.deviceName">{{results.deviceName}}</td>
              <td class="name-cell" mat-cell *ngIf="!results">
                  -
              </td>
            </ng-container>
          </ng-container>
          <!-- Description Column -->
          <ng-container matColumnDef="CommandTemplate">
            <th mat-header-cell *matHeaderCellDef>{{'command.template' | translate}}</th>
            <ng-container *matCellDef="let results" style="font-size: small;">
              <td class="name-cell" mat-cell *ngIf="results.commandTemplateName">{{results.commandTemplateName}}</td>
              <td class="name-cell" mat-cell *ngIf="!results">
                  -
              </td>
            </ng-container>
         </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
        <tr mat-row *matRowDef="let results; columns: displayedColumns2;"></tr>
        </table>
      </ng-container>
      <ng-container *ngIf="dataSourceDeviceTemplates">
      <ng-container>
        <header>
          <h3>{{'events.from.deviceTemplates' | translate}}</h3>
        </header>
        <table mat-table [dataSource]="dataSourceDeviceTemplates">
        <!-- Name Column -->
          <ng-container matColumnDef="DeviceTemplate">
            <th mat-header-cell *matHeaderCellDef>{{'device.template' | translate}}</th>
            <ng-container *matCellDef="let results" style="font-size: small;">
              <td class="name-cell" mat-cell *ngIf="results.deviceTemplateName">{{results.deviceTemplateName}}</td>
              <td class="name-cell" mat-cell *ngIf="!results">
                  -
              </td>
            </ng-container>
          </ng-container>
          <!-- Description Column -->
          <ng-container matColumnDef="EventTemplate">
            <th mat-header-cell *matHeaderCellDef>{{'event.template' | translate}}</th>
            <ng-container *matCellDef="let results" style="font-size: small;">
              <td class="name-cell" mat-cell *ngIf="results.eventTemplateName">{{results.eventTemplateName}}</td>
              <td class="name-cell" mat-cell *ngIf="!results">
                  -
              </td>
            </ng-container>
         </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns3"></tr>
        <tr mat-row *matRowDef="let results; columns: displayedColumns3;"></tr>
        </table>
      </ng-container>
      </ng-container>
      <ng-container *ngIf="dataSourceCommandDeviceTemplates">
        <ng-container>
          <header>
            <h3>{{'commands.from.deviceTemplates' | translate}}</h3>
          </header>
          <table mat-table [dataSource]="dataSourceCommandDeviceTemplates">
           
          <!-- Name Column -->
            <ng-container matColumnDef="DeviceTemplate">
              <th mat-header-cell *matHeaderCellDef >{{'device.template' | translate}}</th>
              <ng-container *matCellDef="let results" style="font-size: small;">
                <td class="name-cell" mat-cell *ngIf="results.deviceTemplateName">{{results.deviceTemplateName}}</td>
                <!-- <td class="name-cell" mat-cell *ngIf="results.deviceTemplateName">{{results.length}}</td> -->
                <td class="name-cell" mat-cell *ngIf="!results">
                    -
                </td>
              </ng-container>
            </ng-container>
            <!-- Description Column -->
            <ng-container matColumnDef="CommandTemplate">
              <th mat-header-cell *matHeaderCellDef >{{'command.template' | translate}}</th>
              <ng-container *matCellDef="let results" style="font-size: small;">
                <td class="name-cell" mat-cell *ngIf="results.commandTemplateName">{{results.commandTemplateName}}</td>
                <td class="name-cell" mat-cell *ngIf="!results">
                    -
                </td>
              </ng-container>
           </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns4"></tr>
          <tr mat-row *matRowDef="let results; columns: displayedColumns4;"></tr>
          </table>
        </ng-container>
      </ng-container>  
    </ng-container>
  </div>
  </mat-sidenav-container>
</main>
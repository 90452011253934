/**
 * This is an auto generated code for templates listing page.
 * Please don't make any change to this file.
 * @version 1.0.0
 * @readonly
 * @author - System generated
 */

import { Component, OnInit, ViewChild, Injector, Inject} from '@angular/core'
import { IotService } from '../service/iot.service'
import { TemplatesService } from '../service/templates.service'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { faCaretUp, faCaretDown, faInfoCircle, faQuestionCircle, faArrowUp, faArrowDown, faSearch } from '@fortawesome/free-solid-svg-icons'
import { SideNavComponent } from '../side-nav/side-nav.component'
import { BaseListComponent } from '../base-list/baselist.component'

interface TypeChange {
	value: string;
	viewValue: string;
}
@Component({
	selector: 'app-templates',
	templateUrl: './templates.component.html',
	styleUrls: ['../base-list/baselist.component.scss','./templates.component.scss']
})
export class TemplatesComponent extends BaseListComponent {
	searchCriteria="name";
	displayedColumns: string[] = ['name','description','creation','ACTIVE'];
	sortColumns: string[] = ['name','creation'];
	pageTitle="templates.header";
	ascName:boolean = false;
	descName:boolean = false;
	ascCreation:boolean = false;
	descCreation:boolean = false;
	countCallCompleted:boolean = false;
	getCallCompleted:boolean = false;
	showEnableColumn = true;

	constructor( router: Router,
				iotService: IotService,
				translateService: TranslateService,
				sideNavComponent:SideNavComponent,
				public templatesService:TemplatesService) {
				super(router, iotService, translateService, sideNavComponent )
						this.translateService.addLangs(this.supportLanguages);
						this.translateService.setDefaultLang('en');
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.templatesService.multiSelectRows = this.multiSelect;
		this.templatesService.loadSelected();
		if(!this.showSelectColoumn){
			this.sideNavComponent.menuClear();
			this.sideNavComponent.menuChange('Dashboard','sub-type');
			this.sideNavComponent.menuChange('templates.header','sub-type');
		}
	}
	getFilteredResults(value, columnName , sortOrder){
		const that = this;
		that.iotService.getCuiObjResponse().getDigitalTwinTemplates({
			qs: eval(that.fnGetQS(value, columnName, sortOrder))
		}).then(function (response) {
			that.fnSuccess(response);
			if(columnName == 'none' || columnName == '' || columnName == undefined){
				that.fnResetSortParam('creation', 'desc');
			}else{
				that.fnResetSortParam(columnName, sortOrder);
			}
			that.getCallCompleted = true;
		}).fail(function (err) {
			that.fnError(err);
			that.getCallCompleted = true;
		});
	}
	getCount() {
		const that = this;
		that.showPagination=false;
		let value = this.selected;
		that.iotService.getCuiObjResponse().countDigitalTwinTemplates({
			qs: eval(that.fnGetQS(value, '', ''))
		}).then(function (response) {
				that.totalCount = response;
				that.countCallCompleted = true;
			if(that.totalCount > 0)
				that.showPagination=true;
			}).fail(function (err) {
				that.fnError(err);
				that.countCallCompleted = true;
			});
	}
	activateDigitalTwinTemplates(resourceId){
		const that = this;
		that.iotService.getCuiObjResponse().activateDigitalTwinTemplates({
			deviceTemplateId: resourceId
		}).then(function (response) {
			that.getFilteredResults(that.selected, that.sortCriteria, that.sortDirection);
			that.templatesService.fnResetList();
		}).fail(function (err) {
			that.fnError(err);
		});
	}
	deactivateDigitalTwinTemplates(resourceId){
		const that = this;
		that.iotService.getCuiObjResponse().deactivateDigitalTwinTemplates({
			deviceTemplateId: resourceId
		}).then(function (response) {
			that.getFilteredResults(that.selected, that.sortCriteria, that.sortDirection);
			that.templatesService.fnResetList();
		}).fail(function (err) {
			that.fnError(err);
		});
	}
	viewTemplateDetails(selectedId){
		this.router.navigateByUrl("/templates/"+selectedId);
		this.templatesService.viewTemplateDetails(selectedId);
	}
	createNew(){
		this.templatesService.createNew();
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}
	onSelection(results){
		this.templatesService.onSelection(results);
	}
	removeSelection(results){
		this.templatesService.removeSelection(results);
	}
	changeActive(resourceId, isActivate){
		if(isActivate == 'true'){
			this.deactivateDigitalTwinTemplates(resourceId);
		}else{
			this.activateDigitalTwinTemplates(resourceId);
		}
	}
}

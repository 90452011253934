import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { IotService } from '../service/iot.service';
import { TranslateService } from '@ngx-translate/core';
import { SideNavComponent } from '../side-nav/side-nav.component';
import {
  faTimes,
  faCheck,
  faExclamationTriangle,
  faExclamation,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import { EcosystemTemplatesService } from '../service/ecosystemTemplates.service';
import { MatTable } from '@angular/material/table';

@Component({
  selector: 'app-create-ecosystem-template',
  templateUrl: './create-ecosystem-template.component.html',
  styleUrls: [
    '../base-list/baselist.component.scss',
    './create-ecosystem-template.component.scss',
  ],
})
export class CreateEcosystemTemplateComponent implements OnInit {
  faCloseIcon = faTimes;
  faWarningIcon = faExclamationTriangle;
  faCheckIcon = faCheck;
  faTimesIcon = faTimes;
  faCriticalIcon = faExclamation;
  faQuestionCircleIcon = faQuestionCircle;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  ecosystemTemplate;
  tags = [];
  tagClass = 'tagInput';
  errorMessage: string;
  hasError: boolean = false;
  errorInName;
  errorInAllowedMember;
  errorInAllowedMemberKind;
  supportLanguages = ['en'];
  allowedMemberColumns: string[] = ['Member Type', 'Kind', 'Actions'];
  ecosystemTemplateName;
  attributeIds = [];
  eventIds = [];
  allowedMembers = [];
  allowedMemberName;
  memberKinds = [];
  @ViewChild(MatTable) table: MatTable<any>;
  allowedMemberKind = '';
  ecosystemTemplateCreated=false;
  allowedMemberOptions = [
    {
      id: 1,
      type: 'Select Kind',
      value: '',
    },
    {
      id: 2,
      type: 'Person',
      value: 'person',
    },
    {
      id: 3,
      type: 'Device',
      value: 'device',
    },
    {
      id: 4,
      type: 'Group',
      value: 'group',
    },
    {
      id: 5,
      type: 'Organization',
      value: 'organization',
    },
    {
      id: 6,
      type: 'Application',
      value: 'application',
    },
    {
      id: 7,
      type: 'Other',
      value: 'other',
    },
  ];

  constructor(
    public iotService: IotService,
    private translateService: TranslateService,
    private sideNavComponent: SideNavComponent,
    private router: Router,
    public ecosystemTemplateService: EcosystemTemplatesService
  ) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
    this.sideNavComponent.menuClear();
    this.sideNavComponent.menuChange('Dashboard', 'sub-type');
    this.sideNavComponent.menuChange('ecosystemTemplates.header', 'sub-type');
    this.sideNavComponent.menuChange(
      'ecosystemTemplates.create.new',
      'sub-type'
    );
  }

  ngOnInit(): void {
    this.initEcosystemTemplate();
    this.iotService.createEcosystemTemplate = true;
    this.ecosystemTemplateService.createEvent = false;
    this.ecosystemTemplateService.createAttribute = false;
    this.ecosystemTemplateService.createResource = true;
  }

  initEcosystemTemplate() {
    const that = this;
    that.ecosystemTemplate = {
      creator: that.iotService.getCreator(),
      creatorAppId: that.iotService.getCreatorAppId(),
      realm: that.iotService.getRealm(),
      name: [
        {
          lang: 'en',
          text: '',
        },
      ],
      description: [
        {
          lang: 'en',
          text: '',
        },
      ],
      allowedMemberTypes: [],
      attributeTypes: [],
      syntheticEvents: [],
      active: true,
      tags: [],
    };
  }

  save() {
    const that = this;
    that.ecosystemTemplateCreated=true;
    if (!that.errorValidations()) {
      that.ecosystemTemplate.name[0].text = that.ecosystemTemplateName;
      that.ecosystemTemplate.allowedMemberTypes = that.allowedMembers;
      that.ecosystemTemplate.attributeTypes = that.getAttributeIds();
      that.ecosystemTemplate.syntheticEvents = that.getEventIds();
      that.ecosystemTemplate.tags = that.tags;
      console.log('Request:', that.ecosystemTemplate);
      that.iotService
        .getCuiObjResponse()
        .createEcosystemTemplates({
          data: that.ecosystemTemplate,
        })
        .then(function (response) {
          console.log(
            'Ecosystem Template created succesfully. ID :',
            response.id
          );
          that.router.navigateByUrl('/ecosystemTemplates');
        })
        .fail(function (err) {
          that.ecosystemTemplateCreated=false;
          if (err.responseJSON.apiStatusCode) {
            that.errorMessage = err.responseJSON.apiMessage;
            console.log(
              'Error Occured: Status: ' +
                err.responseJSON.status +
                ' Msg: ' +
                err.responseJSON.apiStatusCode
            );
          } else {
            that.errorMessage =
              'Error occured while creating ecosystem template';
            console.log(
              'Something went wrong. Please check chrome console or check with dev team'
            );
          }
        });
    }
  }

  getAttributeIds() {
    this.attributeIds = [];
    if (this.ecosystemTemplateService.getAttributes != undefined) {
      this.ecosystemTemplateService.getAttributes().forEach((value) => {
        this.attributeIds.push(value.id);
      });
    }
    return this.attributeIds;
  }

  getEventIds() {
    this.eventIds = [];
    if (this.ecosystemTemplateService.getEvents() != undefined) {
      this.ecosystemTemplateService.getEvents().forEach((value) => {
        this.eventIds.push(value.id);
      });
    }
    return this.eventIds;
  }

  add(event: MatChipInputEvent): void {
    this.tagClass = 'tagInput';
    const input = event.input;
    const value = event.value;
    const index = this.tags.indexOf(value);
    if (index < 0) {
      if ((value || '').trim()) {
        if (this.tags != undefined && this.tags.includes(value)) {
          if (input) {
            input.value = '';
          }
        } else {
          this.tags.push(value);
          if (input) {
            input.value = '';
          }
        }
      }
    } else {
      this.tagClass = 'tagInput-duplicate';
    }
  }

  remove(tag: string): void {
    const that = this;
    that.tags.forEach((value, index) => {
      if (value == tag) that.tags.splice(index, 1);
    });
  }

  addMember(): void {
    const that = this;
    const data = that.allowedMembers;
    if (
      that.allowedMemberName != undefined &&
      that.allowedMemberName != '' &&
      that.isAlpha(that.allowedMemberName)
    ) {
      if (that.allowedMemberKind != undefined && that.allowedMemberKind != '') {
        if (that.allowedMemberKind == that.allowedMemberOptions[6].value) {
          that.memberKinds = [];
        } else {
          that.memberKinds.push(that.allowedMemberKind);
        }
        data.push({
          type: that.allowedMemberName,
          kind: that.memberKinds,
        });
        that.allowedMembers = data;
        that.allowedMemberName = '';
        that.allowedMemberKind = '';
        that.memberKinds = [];
        that.table.renderRows();
      } else {
        that.errorInAllowedMemberKind = 'Please select a valid member kind';
      }
    } else {
      if(that.allowedMemberName==''||that.allowedMemberName==undefined){
        that.errorInAllowedMember =
        'Please add a name to the allowed member';
      }
      else{
        that.errorInAllowedMember =
        'Special Characters not allowed. Only A-Z, a-z, 0-9, ".", "-", "_" are allowed.';
      }
     
    }
  }

  removeMember(element) {
    const that = this;
    const index = that.allowedMembers.indexOf(element);
    if (index >= 0) {
      that.allowedMembers.splice(index, 1);
      that.table.renderRows();
    }
    console.log('allowedMembers :: ' + that.allowedMembers);
  }

  ecosystemTemplateNameInput(event: any) {
    const that = this;
    that.ecosystemTemplateName = event.target.value;
    if (that.ecosystemTemplateName.length > 0) {
      that.errorInName = '';
    } else {
      that.ecosystemTemplateName = '';
    }
  }

  allowedMemberNameInput(event: any) {
    const that = this;
    that.allowedMemberName = event.target.value;
    if (that.allowedMemberName.length > 0) {
      that.errorInAllowedMember = '';
    } else {
      that.allowedMemberName = '';
    }
  }

  allowedMemberKindInput(event: any) {
    const that = this;
    that.allowedMemberKind = event.target.value;
    if (that.allowedMemberKind != '') {
      that.errorInAllowedMemberKind = '';
    } else {
      that.allowedMemberKind = '';
    }
    console.log('Member received', that.allowedMemberKind);
  }

  isAlpha(str) {
    if (str) {
      let letter = /^[\w ._-]*$/;
      if (str.match(letter)) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  errorValidations() {
    const that = this;
    that.hasError = false;
    if (
      that.ecosystemTemplateName == undefined ||
      that.ecosystemTemplateName == ''
    ) {
      that.errorInName = 'Name is mandatory';
      that.hasError = true;
    }
    return that.hasError;
  }

  resetErrorFields() {
    this.errorInName = '';
    this.errorInAllowedMember = '';
    this.errorInAllowedMemberKind = '';
  }

  ngOnDestroy() {
    const that = this;
    that.tags = [];
    that.tagClass = 'tagInput';
    that.attributeIds = [];
    that.eventIds = [];
    that.allowedMembers = [];
    that.ecosystemTemplateName = '';
    that.allowedMemberName = '';
    that.allowedMemberKind = '';
    that.memberKinds = [];
    that.ecosystemTemplateService.attributes = [];
    that.ecosystemTemplateService.events = [];
    that.resetErrorMessage();
    that.resetErrorFields();
    that.iotService.createEcosystemTemplate = false;
    that.ecosystemTemplate = {
      creator: that.iotService.getCreator(),
      creatorAppId: that.iotService.getCreatorAppId(),
      realm: that.iotService.getRealm(),
      name: [
        {
          lang: 'en',
          text: '',
        },
      ],
      description: [
        {
          lang: 'en',
          text: '',
        },
      ],
      allowedMemberTypes: [],
      attributeTypes: [],
      syntheticEvents: [],
      active: true,
      tags: [],
    };
  }

  resetErrorMessage() {
    this.errorMessage = '';
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IotService } from 'src/app/service/iot.service';
import { SideNavComponent } from 'src/app/side-nav/side-nav.component';
import { faToggleOff, faToggleOn, faAngleLeft, faAngleRight, faCheck, faCopy,
   faTimes, faAngleDoubleLeft, faAngleDoubleRight, faCheckCircle,
   faEye, faEyeSlash, faExclamationTriangle,faExclamation } from '@fortawesome/free-solid-svg-icons';

import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER, I } from '@angular/cdk/keycodes';


@Component({
  selector: 'app-oAuth-token-detail',
  templateUrl: './oAuth-token-detail.component.html',
  styleUrls: ['./oAuth-token-detail.component.scss']
})
export class OAuthTokenDetailComponent implements OnInit {

  failed = faTimes;
  faToggleOffIcon = faToggleOff;
  faToggleOnIcon = faToggleOn;
  faAngleLeftIcon = faAngleLeft;
  faAngleRightIcon = faAngleRight;
  faCheckIcon = faCheck;
  faCopyIcon = faCopy;
  faCloseIcon = faTimes;
  faSuccessIcon = faCheckCircle;
  faAngleDoubleLeftIcon = faAngleDoubleLeft;
  faAngleDoubleRightIcon = faAngleDoubleRight;
  faWarningIcon = faExclamationTriangle;
  faCriticalIcon=faExclamation;
  showPassword=faEyeSlash;
  hidePassword=faEye;

  token;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  warningMessage;
  successMessage;
  errorMessage;
  supportLanguages = ['en'];
  accessTokenFlag:boolean = false;
  refreshTokenFlag:boolean = false;
  clientSecretFlag :boolean= false;
  tagClass = 'tagInput';



  constructor(private router: Router, private route: ActivatedRoute, private iotService: IotService,
    private sideNavComponent: SideNavComponent, private translateService: TranslateService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }

  reset() {
    this.successMessage = "";
    this.warningMessage = "";
    this.errorMessage = ";"
  }



  ngOnInit() {

    this.route.params.subscribe(params => {
      var tokenId = params['id'];
      this.loadTokenData(tokenId);
    });

  }
  loadTokenData(tokenId: any) {
    const that = this;
    that.iotService.getCuiObjResponse().getToken({
      tokenId: tokenId
    }).then(function (response) {
      console.log(response);
      that.token = response;
      that.token.customHeaders = "customHeaders" in response ? JSON.stringify(that.token.customHeaders) : undefined;
      that.token.customFormData = "customFormData" in response ? JSON.stringify(that.token.customFormData) : undefined;
      that.sideNavComponent.menuClear();
      that.sideNavComponent.menuChange('Dashboard', 'sub-type');
      that.sideNavComponent.menuChange('oAuthTokens.header', 'sub-type');
      that.sideNavComponent.menuChange(that.token.name[0].text, 'sub-type');
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  add(event: MatChipInputEvent): void {

    this.tagClass = 'tagInput';
    if (this.token.tags == undefined) {
      this.token.tags = [];
    }
    const input = event.input;
    const value = event.value;
    const index = this.token.tags.indexOf(value);
    // this.token.tags.push(value);
    console.log("index " + index);
    if (index < 0) {
      if ((value || '').trim()) {
        if (this.token.tags != undefined && this.token.tags.includes(value)) {
          if (input) {
            input.value = '';
          }
        }
        else {
          this.token.tags.push(value);
          this.addTag(value);
          if (input) {
            input.value = '';
          }
        }
      }
    } else {
      this.tagClass = 'tagInput-duplicate';
    }
  }

  remove(tag: string): void {
    const that = this;
    that.token.tags.forEach((value, index) => {
      if (value == tag) that.token.tags.splice(index, 1);
    });
    that.removeTag(tag);
  }

  addTag(tag){
    const that = this;
    that.iotService.getCuiObjResponse().createTokenTag({
      id:that.token.id,
      tags: tag
    }).then(function (response) {
      console.log("Tag added to oAuth-token successfully "+ tag);

    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.errorMessage = err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  removeTag(tag){
    const that = this;
    that.iotService.getCuiObjResponse().deleTokenTag({
      id:that.token.id,
      tags: tag
    }).then(function (response) {
      console.log("Tag removed from oAuth-token successfully "+ tag);

    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.warningMessage = err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }

  update() {
    console.log(this.token);
    const that = this;
    if (!that.IsEmptyOrNull(that.token.customHeaders)) {
      that.token.customHeaders = JSON.parse(that.token.customHeaders);
    } else {
      that.token.customHeaders = undefined;
    }
    if (!that.IsEmptyOrNull(that.token.customFormData)) {
      that.token.customFormData = JSON.parse(that.token.customFormData);
    } else {
      that.token.customFormData = undefined;
    }
    that.iotService.getCuiObjResponse().updateToken({
      data: that.token,
      tokenId: that.token.id
    }).then(function (response) {
      that.loadTokenData(that.token.id); console.log("Token created. Id :" + response.id);
      that.successMessage = "Token updated sucessfully!";
    }).fail(function (err) {
      that.loadTokenData(that.token.id);
      if (err.responseJSON.apiStatusCode) {
        that.warningMessage = err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        that.warningMessage = "Error occured while updating token"
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }

  disableToken(id) {
    const that = this;
    that.iotService.getCuiObjResponse().deactivateToken({
      tokenId: id
    }).then(function (response) {
      // console.log(response);
      that.loadTokenData(id);
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }

  enableToken(id) {
    const that = this;
    that.iotService.getCuiObjResponse().activateToken({
      tokenId: id
    }).then(function (response) {
      // console.log(response);
      that.loadTokenData(id);
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }

  IsEmptyOrNull(str) {
    if (str) {
      if (str.length === 0) {
        return false;
      }
      else {
        return false;
      }
    }

    return true;
  }

}

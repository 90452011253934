/**
 * This is an auto generated code for ecosystemInstanceMembers listing page.
 * Please don't make any change to this file.
 * @version 1.0.0
 * @readonly
 * @author - System generated
 */

import { Component, OnInit, ViewChild, Injector, Inject } from '@angular/core';
import { IotService } from '../service/iot.service';
import { EcosystemInstanceMembersService } from '../service/ecosystemInstanceMembers.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  faCaretUp,
  faCaretDown,
  faInfoCircle,
  faQuestionCircle,
  faArrowUp,
  faArrowDown,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { BaseListComponent } from '../base-list/baselist.component';
import { EcosystemsService } from '../service/ecosystems.service';

interface TypeChange {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-ecosystemInstanceMembers',
  templateUrl: './ecosystemInstanceMembers.component.html',
  styleUrls: [
    '../base-list/baselist.component.scss',
    './ecosystemInstanceMembers.component.scss',
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class EcosystemInstanceMembersComponent extends BaseListComponent {
  displayedColumns: string[] = [
    'memberType',
    'memberName',
    'memberKind',
    'creation',
    'membershipAttributes',
    'ACTION',
  ];
  membershipAttributeColumns: string[] = ['attributeName','attributeType','value'];
  sortColumns: string[] = ['creation'];
  pageTitle = 'ecosystemInstanceMembers.header';
  ascCreation: boolean = false;
  descCreation: boolean = false;
  countCallCompleted: boolean = false;
  getCallCompleted: boolean = false;
  showActionColumn = true;
  previousAttributeElement;
  membershipAttributes = [];

  constructor(
    router: Router,
    iotService: IotService,
    translateService: TranslateService,
    sideNavComponent: SideNavComponent,
    public ecosystemInstanceMembersService: EcosystemInstanceMembersService,
    public ecosystemService: EcosystemsService
  ) {
    super(router, iotService, translateService, sideNavComponent);
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.ecosystemInstanceMembersService.multiSelectRows = this.multiSelect;
    this.ecosystemInstanceMembersService.loadSelected();
    if (!this.showSelectColoumn) {
      this.sideNavComponent.menuClear();
      this.sideNavComponent.menuChange('Dashboard', 'sub-type');
      this.sideNavComponent.menuChange(
        'ecosystemInstanceMembers.header',
        'sub-type'
      );
    }
  }
  getFilteredResults(value, columnName, sortOrder) {
    const that = this;
    that.iotService
      .getCuiObjResponse()
      .getEcosystemInstanceMembers({
        ecosystemId: that.extraParamsMap.get('ecosystemId'),
        qs: eval(that.fnGetQS(value, columnName, sortOrder)),
      })
      .then(function (response) {
        that.fnSuccess(response);
        if (
          columnName == 'none' ||
          columnName == '' ||
          columnName == undefined
        ) {
          that.fnResetSortParam('creation', 'desc');
        } else {
          that.fnResetSortParam(columnName, sortOrder);
        }
        that.getCallCompleted = true;
      })
      .fail(function (err) {
        that.fnError(err);
        that.getCallCompleted = true;
      });
  }
  getCount() {
    const that = this;
    that.showPagination = false;
    let value = this.selected;
    that.iotService
      .getCuiObjResponse()
      .countEcosystemInstanceMembers({
        ecosystemId: that.extraParamsMap.get('ecosystemId'),
        qs: eval(that.fnGetQS(value, '', '')),
      })
      .then(function (response) {
        that.totalCount = response;
        that.countCallCompleted = true;
        if (that.totalCount > 0) that.showPagination = true;
      })
      .fail(function (err) {
        that.fnError(err);
        that.countCallCompleted = true;
      });
  }
  deleteEcosystemInstanceMembers(resourceId) {
    const that = this;
    let exists = false;
    that.iotService
      .getCuiObjResponse()
      .getEcosystemRelationships({
        ecosystemId: that.extraParamsMap.get('ecosystemId'),
      })
      .then(function (response) {
        response.forEach((value) => {
          if (
            value.sourceEntity.id == resourceId ||
            value.targetEntity.id == resourceId
          ) {
            exists = true;
          }
        });
        if (!exists) {
          that.iotService
            .getCuiObjResponse()
            .deleteEcosystemInstanceMembers({
              ecosystemId: that.extraParamsMap.get('ecosystemId'),
              membershipId: resourceId,
            })
            .then(function (response) {
              that.getCount();
              that.getFilteredResults(
                that.selected,
                that.sortCriteria,
                that.sortDirection
              );
              that.ecosystemInstanceMembersService.fnResetList();
              that.getEcosystemMemberships(that.extraParamsMap.get('ecosystemId'));
            })
            .fail(function (err) {
              that.fnError(err);
              that.ecosystemInstanceMembersService.showErrorMessage(err);
            });
        } else {
          that.ecosystemService.warningMessage =
            'This member is part of one or more relationships.  The associated relationships must be deleted first.';
        }
      });
  }

  getEcosystemMemberships(ecosystemId) {
    const that = this;
    that.iotService
      .getCuiObjResponse()
      .getEcosystemInstanceMembers({
        ecosystemId: ecosystemId,
      })
      .then(function (response) {
        that.ecosystemService.ecosystemMemberShips=response;
      })
      .fail(function (err) {
        if (err.responseJSON && err.responseJSON.apiStatusCode) {
          console.log(
            'Error Occured: Status: ' +
              err.responseJSON.status +
              ' Msg: ' +
              err.responseJSON.apiStatusCode
          );
        } else {
          console.log(
            'Something went wrong. Please check chrome console or check with dev team'
          );
        }
      });
  }
  createNew() {
    this.ecosystemInstanceMembersService.createNew();
  }
  onSelection(results) {
    this.ecosystemInstanceMembersService.onSelection(results);
  }
  removeSelection(results) {
    this.ecosystemInstanceMembersService.removeSelection(results);
  }

  viewAttributes(results, expandedElement) {
    const that = this;
    if (
      that.previousAttributeElement != undefined &&
      that.previousAttributeElement.id != results.id
    ) {
      that.previousAttributeElement.messageClass = 'bg-message';
    }

    let memberAttributes = [];
    results.membershipAttributes.forEach(element => {
      //Skipping attributes to show on ui without attribute "Member_kind"
      if(element.attributeType.name == "member_kind") {
        return;
      }
      let attribute = {
        attributeId: element.attributeType.id,
        name: element.attributeType.name,
        type: element.attributeType.type,
        value: element.value,
      };
      memberAttributes.push(attribute);
    });
    that.membershipAttributes = memberAttributes;

    if (expandedElement === null) {
      results.messageClass = 'bg-message';
    } else {
      results.messageClass = 'bg-message-selected';
    }
    that.previousAttributeElement = results;
  }
}

<header class="table-header">
    <h2>{{ 'select_events' | translate }}</h2>
</header>
<div class="control-bar">
    <input placeholder="search" type="text" (keyup)="onEventSearch($event)">
    <button class="btn btn-primary" (click)="createEvent()" aria-label="Create new event">New</button>
</div>
<div class="table-container">
    <ngx-skeleton-loader *ngIf="!dataSource" count="5" [theme]="{'border-radius': '0px',
        height: '48px',
        width: '100%',
        margin: '1px',
        position: 'relative',
        top: '20px'
    }"></ngx-skeleton-loader>
    <table mat-table [dataSource]="dataSource">
    
        <!-- checkbox Column -->
        <ng-container matColumnDef=" ">
            <th mat-header-cell *matHeaderCellDef class="checkbox-cell addCol"> Add</th>
            <td mat-cell *matCellDef="let event" class="checkbox-cell addCol">
                <!-- <mat-checkbox #checkbox [disabled]="!event.isActive" (click)="OnSelection(checkbox,event.id)" [checked]="selection.isSelected(event)" class="example-margin"></mat-checkbox> -->
                <ng-container *ngIf="event.isActive">
                    <div class="btn-col">
                        <ng-container *ngIf="selection.isSelected(event)">
                            <button class="selected btn btn-micro btn-secondary" (click)="OnSelection(false,event.id)"><fa-icon [icon]="faCheckIcon"></fa-icon></button>&nbsp;
                        </ng-container>
                        <ng-container *ngIf="!selection.isSelected(event)">
                            <button class="select btn btn-micro btn-secondary"  (click)="OnSelection(true,event.id)">Add</button>&nbsp;
                        </ng-container>
                    </div>
                </ng-container>
                <ng-container *ngIf="!event.isActive">
                    <div class="btn-col">
                        <ng-container *ngIf="selection.isSelected(event)">
                            <button class="btn btn-micro btn-secondary" [disabled]="event.isActive"><fa-icon [icon]="faCheckIcon"></fa-icon></button>&nbsp;
                        </ng-container>
                        <ng-container *ngIf="!selection.isSelected(event)">
                            <button class="btn btn-micro btn-secondary" [disabled]="!event.isActive">Add</button>&nbsp;
                        </ng-container>
                    </div>
                </ng-container>
            </td>
        </ng-container>
    
        <!-- Name Column -->
        <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef>{{'name' | translate}} </th>
            <td mat-cell *matCellDef="let event" class="name-cell">
                {{event.name}}
            </td>
        </ng-container>
    
        <!-- Description Column -->
        <ng-container matColumnDef="Description">
            <th mat-header-cell *matHeaderCellDef> {{'description' | translate}} </th>
            <ng-container *matCellDef="let event">
                <td mat-cell *ngIf="event.description" class="name-cell">
                    {{event.description[0].text}}
                </td>
                <td mat-cell *ngIf="!event.description" class="name-cell">-</td>
            </ng-container>
        </ng-container>
    
        <!-- Tags Column -->
        <ng-container matColumnDef="Tags">
            <th mat-header-cell *matHeaderCellDef> {{ 'tags' | translate}}</th>
            <ng-container *matCellDef="let event">
                <td mat-cell *ngIf="event.tags" class="name-cell">{{event.tags}}
                </td>
                <td mat-cell *ngIf="!event.tags" class="name-cell">-</td>
            </ng-container>
        </ng-container>
    
        <ng-container matColumnDef="Enabled">
            <th mat-header-cell *matHeaderCellDef> {{ 'enabled' | translate}} </th>
            <ng-container *matCellDef="let event">
    
                <td mat-cell *ngIf="event.isActive" class="tag-cell">
                    <button class="toggle-on toggle-switch" (click)="disableEvent(event,event.id)" title="Disable {{event.name}}">
                            <fa-icon [icon]="faCheckIcon" class="fa-2x"></fa-icon>
                            <div class="toggle-on-btn"></div>
                        </button>
                </td>
                <td mat-cell *ngIf="!event.isActive" class="tag-cell">
                    <button class="toggle-off toggle-switch" (click)="enableEvent(event.id)" title="Enable {{event.name}}">
                            <div class="toggle-off-btn"></div>
                        </button>
                </td>
            </ng-container>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let event; columns: displayedColumns;"></tr>
    </table>
</div>
<!-- OT Pagination component -->
<div class="table-container">
    <app-pagination *ngIf="showPagination" [count]="eventCount" [pageSize]="pageSize" [currentPage]="currentPage" (currentPageChange)="onPageChange($event)"></app-pagination>
</div>
<mat-sidenav-container class="new-ssh-key" autosize>

    <mat-sidenav class="midNav-container" #sidenav mode="side" disableClose="true" opened="true">

        <div class="alert warning" *ngIf="warningMessage">
            <h5>
                <fa-icon [icon]="faWarningIcon"></fa-icon>
                <span>{{warningMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)="resetWarningMessage()" [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>
        <div class="alert critical" *ngIf="errorMessage">
            <h5>
                <fa-icon [icon]="faCriticalIcon"></fa-icon>
                <span>{{errorMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=resetWarningMessage() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>

        </div>
        <div class="data">
            <h1 style="margin-left: -15px;"> {{ 'add.trustedSSHKey.Pair' | translate}}</h1>
            <p>{{'sshKeyPairs.definition' | translate}}</p>
            <div>
                <button [disabled]="name=='' || privateCertData=='' ||description==''|| publicCertData==''||sshKeyCreated" class="btn btn-primary" (click)="save()">{{ 'save' | translate}}</button>
            </div>
        </div>
        <!-- *ngIf="name&&privateCertData&&description" -->



    </mat-sidenav>
    <mat-sidenav-content class="example-sidenav-content">
        <div class="ssh-key-data">

            <div class="form-data">

                <label for="name"> {{ 'name' | translate}}</label>
                <input placeholder="required" id="name" type="text" aria-label="Name" [(ngModel)]="name" (keyup)="resetWarningMessage($event)">
                <p *ngIf="nameWarningMessage" style="color: red;">{{nameWarningMessage}}</p>
                <p *ngIf="nameValidationWarningMessage" style="color: red;">{{nameValidationWarningMessage}}</p>
                <label for="description"> {{ 'description' | translate}}</label>
                <textarea rows="2" placeholder="required" id="description" aria-label="description" [(ngModel)]="description" (keyup)="resetWarningMessage($event)"></textarea>
                <p *ngIf="descriptionWarningMessage" style="color: red;">{{descriptionWarningMessage}}</p>
                <label for="certificate-data">{{ 'ssh.privateKey.pemFormat' | translate}}</label>
                <textarea rows="13" placeholder="required" id="certificate-data" aria-label="Certificate-data" [(ngModel)]="privateCertData" (keyup)="resetWarningMessage($event)"></textarea>
                <p *ngIf="keyWarningMessage" style="color: red;">{{keyWarningMessage}}</p>
                <div *ngIf=exampleToggle>
                    <p class="blue-highlight" (click)="toggleExample(1)">{{ 'example' | translate}}
                        <fa-icon [icon]="faAngleDown"></fa-icon>
                    </p>
                </div>
                <div *ngIf=!exampleToggle>
                    <p class="blue-highlight" (click)="toggleExample(1)">{{ 'example' | translate}}
                        <fa-icon [icon]="faAngleUp"></fa-icon>
                    </p>
                    <p style="font-size: 12px;  overflow-wrap: anywhere;">
                        {{'sshEncryptedKey' | translate}}
                    </p>
                </div>
                <label for="public-key"> {{ 'ssh.publicKey.openSSH' | translate}}</label>
                <textarea rows="13" placeholder="required" id="public-key" aria-label="public-key" [(ngModel)]="publicCertData" (keyup)="resetWarningMessage($event)"></textarea>
                <p *ngIf="keyWarningMessage2" style="color: red;">{{keyWarningMessage2}}</p>
                <div *ngIf=exampleToggle2>
                    <p class="blue-highlight" (click)="toggleExample(2)">{{ 'example' | translate}}
                        <fa-icon [icon]="faAngleDown"></fa-icon>
                    </p>
                </div>
                <div *ngIf=!exampleToggle2>
                    <p class="blue-highlight" (click)="toggleExample(2)">{{ 'example' | translate}}
                        <fa-icon [icon]="faAngleUp"></fa-icon>
                    </p>
                    <p style="font-size: 12px;  overflow-wrap: anywhere;">
                        {{'sshEncryptedKey' | translate}}
                    </p>
                </div>


                <!-- <p *ngIf="warningMessage" style="color: red;">{{warningMessage}}</p> -->
            </div>
        </div>
    </mat-sidenav-content>

</mat-sidenav-container>
import { Component, OnInit } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { IotService } from '../../service/iot.service';
import { DeviceService } from '../../service/device.service';
import { FormControl, FormGroup } from '@angular/forms';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { EcosystemTemplatesService } from 'src/app/service/ecosystemTemplates.service';

interface TypeChange {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-create-attribute',
  templateUrl: './create-attribute.component.html',
  styleUrls: ['./create-attribute.component.scss'],
})
export class CreateAttributeComponent implements OnInit {
  form: FormGroup;
  typeChanges: TypeChange[] = [
    { value: '0', viewValue: 'Select Data Type' },
    { value: '1', viewValue: 'String' },
    { value: '2', viewValue: 'Integer' },
    { value: '3', viewValue: 'Decimal' },
    { value: '4', viewValue: 'Boolean' },
  ];
  typeChangeControl = new FormControl(this.typeChanges[0].value);

  attributeDisabledClass;
  attributeEnabledClass = 'bg-enabled-selected';

  attribute;
  attributetype: string;
  attributeValue: any;
  boolValue: boolean = false;
  boolValue2;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tags: string[] = [];
  faCheckIcon = faCheck;
  showLoading: boolean = false;
  supportLanguages = ['en'];
  tagList = [];
  tagClass = 'tagInput';
  nameError;
  dataTypeError;
  fixedValueError;
  attributeCreated=false;
  constructor(
    private iotService: IotService,
    private deviceService: DeviceService,
    private translateService: TranslateService,
    private ecosystemTemplateService: EcosystemTemplatesService
  ) {
    this.form = new FormGroup({
      typeChange: this.typeChangeControl,
    });
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }

  ngOnInit(): void {
    this.attributetype = '1';
    this.initAttribute();
  }

  initAttribute() {
    const that = this;
    that.attribute = {
      creator: that.iotService.getCreator(),
      creatorAppId: that.iotService.getCreatorAppId(),
      realm: that.iotService.getRealm(),
      name: '',
      description: [
        {
          lang: 'en_us',
          text: '',
        },
      ],
      type: '',
      isActive: true,
    };
  }

  add(event: MatChipInputEvent): void {
    this.tagClass = 'tagInput';
    const input = event.input;
    const value = event.value;
    const index = this.tagList.indexOf(value);
    this.tagList.push(value);
    if (index < 0) {
      if ((value || '').trim()) {
        const that = this;
        that.tags.push(value);
        // Reset the input value
        if (input) {
          input.value = '';
        }
      }
    } else {
      this.tagClass = 'tagInput-duplicate';
    }
  }

  remove(tag: string): void {
    const that = this;
    const index = that.tags.indexOf(tag);
    if (index >= 0) {
      that.tags.splice(index, 1);
    }
  }

  toggleActivate(active) {
    const that = this;
    if (active) {
      that.attributeDisabledClass = 'bg-disabled';
      that.attributeEnabledClass = 'bg-enabled-selected';
      that.attribute.isActive = true;
    } else {
      that.attributeDisabledClass = 'bg-disabled-selected';
      that.attributeEnabledClass = 'bg-enabled';
      that.attribute.isActive = false;
    }
    console.log('isActive:', that.attribute.isActive);
  }

  // that.deviceService.errorMessage;
  save() {
    const that = this;
    that.deviceService.errorMessage = null;
    console.log('Printing the attribute : ' + that.attribute);
    if (that.attribute.name === '') {
      that.nameError = 'Name Required';
      that.deviceService.errorMessage = 'Please enter all required values';
    }
    if (that.attribute.type === '') {
      that.dataTypeError = 'Data Type Required';
      that.deviceService.errorMessage = 'Please enter all required values';
    }
    if (
      that.attributetype === '1' &&
      !that.attributeValue &&
      that.attribute.type != 'bool'
    ) {
      that.deviceService.errorMessage = 'Please enter all required values.';
      that.fixedValueError = 'Please enter a fixed value.';
    }

    if (that.attribute.type == 'string') {
      if (that.attributetype === '1') {
        that.attribute.constant = that.attributeValue;
        //alert('string attributeValue: '+$scope.attributeValue);
      } else if (that.attributetype === '2') {
        if (that.attributeValue) {
          that.attribute.defaultValue = that.attributeValue;
        }
      }
    }

    if (that.attribute.type == 'integer') {
      if (that.attributetype === '1') {
        if (that.attributeValue) {
          console.log(that.attributeValue);
          if (typeof parseInt(that.attributeValue) === 'number') {
            // yes it is numeric
            console.log('AAAA');
            if (that.attributeValue % 1 === 0) {
              that.attribute.constant = parseInt(that.attributeValue);
            } else {
              that.deviceService.errorMessage = 'Please provide valid integer value.';
            }
          } else {
            that.deviceService.errorMessage = 'Please provide valid integer value.';
          }
        }
      } else if (that.attributetype === '2') {
        if (that.attributeValue) {
          console.log(that.attributeValue);
          if (typeof parseInt(that.attributeValue) === 'number') {
            // yes it is numeric
            console.log('AAAA');
            if (that.attributeValue % 1 === 0) {
              that.attribute.defaultValue = parseInt(that.attributeValue);
            } else {
              that.deviceService.errorMessage = 'Please provide valid integer value.';
            }
          } else {
            that.deviceService.errorMessage = 'Please provide valid integer value.';
          }
        }
        //alert('integer attributeValue: '+$scope.attributeValue);
      }
    }

    if (that.attribute.type == 'decimal') {
      if (that.attributetype === '1') {
        that.attribute.constant = parseFloat(that.attributeValue);
        //alert('decimal attributeValue: '+$scope.attributeValue);
      } else if (that.attributetype === '2') {
        if (that.attributeValue) {
          if (typeof parseFloat(that.attributeValue) == 'number') {
            that.attribute.defaultValue = parseFloat(that.attributeValue);
          } else {
            that.deviceService.errorMessage = 'Please provide valid decimal value.';
            console.log('Please provide valid decimal value. ');
          }
        } else {
          // Unknown reason constant is preset to null sometimes. Remove it just to make sure.
          delete that.attribute.constant;
        }
        //alert('decimal attributeValue: '+$scope.attributeValue);
      }
    }

    if (that.attribute.type === 'bool') {
      if (that.attributetype === '1') {
        // Fixed
        if (that.boolValue) {
          that.attribute.constant = true;
        } else {
          that.attribute.constant = false;
        }
      }

      if (that.attributetype === '2') {
        // Dynamic
        if (that.boolValue2 === 1) {
          that.attribute.defaultValue = true;
        }
        if (that.boolValue2 === 0) {
          that.attribute.defaultValue = false;
        }
        if (that.boolValue2 === 2) {
          // $scope.attribute.defaultValue = null;
        }
      }
    }

    if (that.deviceService.errorMessage == '' || that.deviceService.errorMessage == null) {
      that.attributeCreated=true;
      that.iotService
        .getCuiObjResponse()
        .createAttributeType({
          data: that.attribute,
        })
        .then(function (response) {
          that.showLoading = true;
          console.log('New attribute created - ' + response.id);
          // activate for newly created Attribute
          if (that.attribute.isActive === true) {
            that.iotService
              .getCuiObjResponse()
              .activateAttributeType({
                attributeTypeId: response.id,
              })
              .then(function (activateResponse) {
                console.log('Attribute id : ' + response.id + ' is activated');
              })
              .fail(function (err) {
                that.attributeCreated=false;
                if (err.responseJSON.apiStatusCode) {
                  var apiStatusCode = err.responseJSON.apiStatusCode;

                  if (
                    apiStatusCode ===
                    'framework:resource:attributeType:duplicate'
                  ) {
                    that.deviceService.warningMessage =
                      'Attribute created but disabled, duplicate attribute exists.';
                  } else {
                    that.deviceService.warningMessage =
                      'Attribute created but disabled. Error:Msg:' +
                      err.responseJSON.apiStatusCode;
                    console.log(
                      'Error Occured: Status: ' +
                      err.responseJSON.status +
                      ' Msg: ' +
                      err.responseJSON.apiStatusCode
                    );
                  }
                } else {
                  console.log(
                    'Something went wrong. Please check chrome console or check with dev team'
                  );
                }
              });
          }
          // Save Tags for newly created Attribute
          if (that.tags != []) {
            that.tags.forEach((value) => {
              that.iotService
                .getCuiObjResponse()
                .createAttributeTypeTag({
                  attributeTypeId: response.id,
                  tag: value,
                })
                .then(function (response) {
                  console.log('Added tag : ' + value);
                });
            });
          }

          setTimeout(() => {
            that.showLoading = false;
            that.deviceService.createResource = false;
            that.deviceService.createAttribute = false;
            if (that.iotService.createEcosystemTemplate == true) {
              that.ecosystemTemplateService.createResource = true;
              that.ecosystemTemplateService.createAttribute = false;
            }
          }, 5000);
        })
        .fail(function (err) {
          that.showLoading = false;
          if (err.responseJSON.apiStatusCode) {
            that.deviceService.errorMessage = err.responseJSON.apiMessage;
            console.log(
              'Error Occured: Status: ' +
              err.responseJSON.status +
              ' Msg: ' +
              err.responseJSON.apiStatusCode
            );
          } else {
            console.log(
              'Something went wrong. Please check chrome console or check with dev team'
            );
          }
          if (err === null) {
            console.log('Error in createAttributeType');
          }
        });
    } else {
      that.deviceService.errorMessage = that.deviceService.errorMessage;
    }
  }

  createAttribute() {
    this.deviceService.createResource = false;
    this.deviceService.createAttribute = false;
    this.deviceService.errorMessage = null;
    if (this.iotService.createEcosystemTemplate == true) {
      this.ecosystemTemplateService.createResource = true;
      this.ecosystemTemplateService.createAttribute = false;
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ImportExportService } from '../service/import-export.service';
import { IotService } from '../service/iot.service';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { faToggleOff, faToggleOn, faCheck, faExclamation, faExclamationTriangle, faTimes, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-orchestration-import-export',
  templateUrl: './orchestration-import-export.component.html',
  styleUrls: ['./orchestration-import-export.component.scss']
})
export class OrchestrationImportExportComponent implements OnInit {

  faCheckIcon = faCheck;
  faToggleOffIcon = faToggleOff;
  faToggleOnIcon = faToggleOn;
  faCloseIcon = faTimes;
  faWarningIcon = faExclamationTriangle;
  faCriticalIcon = faExclamation;
  faSuccessIcon = faCheckCircle;

  supportLanguages = ['en'];
  orchestrationimport: any;
  testInput;
  uploadedFile: FileList;
  preprocessors: any;
  parsers: any;
  packagers: any;
  mappers: any;
  foundOrchestrationName: any;
  foundOrchestrationId: any;
  postprocessors: any;
  retainedComparedOrchId: any;
  showOvrWrite: boolean;
  orchestrationexport;
  retainedComparisonPkg: any;
  retainedComparisonPkgName: any;
  showComparisonResult = false;
  importExportOrcheState = 1;


  constructor(public iotService: IotService, private translateService: TranslateService,
    private sideNavComponent: SideNavComponent, public importExportService: ImportExportService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
    this.sideNavComponent.menuChange('Dashboard', 'sub-type');
    this.sideNavComponent.menuChange('importExport.header', 'sub-type');
    this.sideNavComponent.menuChange('orchestrationConfigs.header', 'sub-type');

  }

  ngOnInit(): void {
    this.importExportService.orchestrationImport = true;
    this.importExportService.orchestrationCompare = false;
    this.importExportService.orchestrationExport = false;
    this.initOrchestrationImport();
    this.initOrchestrationExport();
    this.importExportService.resetErrorMessage();
    this.importExportService.resetSuccessMessage();
    this.importExportService.resetWarningMessage();
  }
  initOrchestrationExport() {
    this.orchestrationexport = {
      resourceId: "",
      jobName: ""
    };
  }

  createFile(files: FileList) {
    this.uploadedFile = files;
  }

  initOrchestrationImport() {
    this.orchestrationimport = {
      impJobName: "",
      packageFileData: "",
      fileName: ""
    }
  }

  executeImport(files) {
    this.importExportService.resetErrorMessage();
    this.importExportService.resetSuccessMessage();
    this.importExportService.resetWarningMessage();

    const that = this;

    if (files === "" || files.name === "" || files.size <= 0) {
      that.showComparisonResult = false;

    } else {
      var reader = new FileReader();
      reader.onload = function () {
        that.testInput = this.result;
        console.log("testcontent" + that.testInput);
        that.populateData(files[0].name, that.testInput);
        that.retainedComparisonPkg = that.orchestrationimport.packageFileData
        that.retainedComparisonPkgName = that.orchestrationimport.fileName

        if (that.importExportOrcheState==1) {
          that.makeImportCall(that.orchestrationimport);
        }
        else if (that.importExportOrcheState==2) {
          that.makeCompareCall(that.orchestrationimport);
        }

      }
      reader.readAsText(files[0]);
    }

  }



  taskSorter(a, b) {
    if (a.sequenceOrder == b.sequenceOrder) {
      return 0;
    } else {
      return a.sequenceOrder < b.sequenceOrder ? -1 : 1;
    }
  }

  makeCompareCall(orchestrationimport: any) {

    const that = this;
    that.showComparisonResult = false;
    that.iotService.getCuiObjResponse().executeOrchestrationCompare({
      data: orchestrationimport
    })
      .then(function (response) {
        console.log(response);
        that.showComparisonResult = true;
        var comparisonData = response;
        that.preprocessors = "PREPROCESSOR" in comparisonData ? comparisonData.PREPROCESSOR.sort(that.taskSorter) : null;
        that.parsers = "PARSER" in comparisonData ? comparisonData.PARSER.sort(that.taskSorter) : null;
        that.postprocessors = "POSTPROCESSOR" in comparisonData ? comparisonData.POSTPROCESSOR.sort(that.taskSorter) : null;
        that.packagers = "PACKAGER" in comparisonData ? comparisonData.PACKAGER.sort(that.taskSorter) : null;
        that.mappers = "MAPPER" in comparisonData ? comparisonData.MAPPER.sort(that.taskSorter) : null;
        var foundOrchestration = "foundOrchestration" in comparisonData ? comparisonData.foundOrchestration[0] : null;
        that.foundOrchestrationName = foundOrchestration.destinationEnvTask;
        that.foundOrchestrationId = foundOrchestration.sourcePKGTask;
        that.retainedComparedOrchId = foundOrchestration.sourcePKGTask;
        var preProcMismatchFound = false;
        var parsersMismatchFound = false;
        var postprocessorsMismatchFound = false;
        var packagersMismatchFound = false;
        var mappersMismatchFound = false;
        if (that.preprocessors) {
          for (var preProc in that.preprocessors) {
            preProcMismatchFound = that.preprocessors[preProc].mismatchExists;
            if (preProcMismatchFound) {
              break;
            }
          }
        }
        if (that.parsers) {
          for (var parser in that.parsers) {
            parsersMismatchFound = that.parsers[parser].mismatchExists;
            if (parsersMismatchFound) {
              break;
            }
          }
        }
        if (that.postprocessors) {
          for (var postprocessor in that.postprocessors) {
            postprocessorsMismatchFound = that.postprocessors[postprocessor].mismatchExists;
            if (postprocessorsMismatchFound) {
              break;
            }
          }
        }
        if (that.packagers) {
          for (var packager in that.packagers) {
            packagersMismatchFound = that.packagers[packager].mismatchExists;
            if (packagersMismatchFound) {
              break;
            }
          }
        }
        if (that.mappers) {
          for (var mapper in that.mappers) {
            mappersMismatchFound = that.mappers[mapper].mismatchExists;
            if (mappersMismatchFound) {
              break;
            }
          }
        }
        that.showOvrWrite = (preProcMismatchFound || parsersMismatchFound || postprocessorsMismatchFound || packagersMismatchFound || mappersMismatchFound);//foundOrchestration.mismatchExists;
        that.importExportService.successMessage = "Orchestration compared successfully.";

        // resetFormFields();
        // that.refresh();
      })
      .fail(function (response) {
        that.showComparisonResult = false;
        if (response.responseJSON.apiMessage) {
          that.importExportService.errorMessage = response.responseJSON.apiMessage;
        } else {
          that.importExportService.errorMessage = response.status + response.statusText;
        }
        // resetFormFields();
        // that.refresh();


      });
  }


  makeImportCall(orchestrationimport: any) {
    const that = this;
    that.iotService.getCuiObjResponse().executeOrchestrationImport({
      data: orchestrationimport
    })
      .then(function (response) {
        var resp = response;
        that.importExportService.successMessage = "File Imported successfully.";
      })
      .fail(function (err) {
        if (err.responseJSON && err.responseJSON.apiStatusCode && err.responseJSON.apiMessage) {
          that.importExportService.errorMessage = err.responseJSON.apiMessage;
       
          console.log(
            'Error Occured: Status: ' +
            err.responseJSON.status +
            ' Msg: ' +
            err.responseJSON.apiStatusCode
          );

        } else {
          that.importExportService.errorMessage = "Error occured while Importing";
          console.log(
            'Something went wrong. Please check chrome console or check with dev team'
          );
        }
      });
  }





  executeOrchExport() {
    const that = this;
    that.iotService.getCuiObjResponse().executeOrchestrationExport({
      data: that.orchestrationexport
    })
      .then(function (response) {

        var resp = response;
      })
      .fail(function (err) {

        if (err.status == 200) {

          var fileName = that.orchestrationexport.resourceId + ".pkg";

          that.saveTextAsFile(err.responseText, fileName);
          that.importExportService.successMessage = "File downloaded successfully.";
          //  that.payloadSuccess(response,that.orchestrationexport.resourceId);
        }
        else if (err.responseJSON && err.responseJSON.apiStatusCode && err.responseJSON.apiMessage) {
          that.importExportService.errorMessage = err.responseJSON.apiMessage;
          console.log(
            'Error Occured: Status: ' +
            err.responseJSON.status +
            ' Msg: ' +
            err.responseJSON.apiStatusCode
          );

        }
      });

  }

  saveTextAsFile(data, filename) {

    if (!data) {
      console.error('Console.save: No data')
      return;
    }

    if (!filename) filename = 'console.json'

    var blob = new Blob([data], { type: 'text/plain' }),
      e = document.createEvent('MouseEvents'),
      a = document.createElement('a')
    // FOR IE:

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    }
    else {
      var e = document.createEvent('MouseEvents'),
        a = document.createElement('a');

      a.download = filename;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/plain', a.download, a.href].join(':');
      e.initEvent('click', true, false);
      a.dispatchEvent(e);
    }
  }


  populateData(filename, fileData) {
    const that = this;
    var encodedFileContent = fileData;
    that.orchestrationimport.packageFileData = fileData;
    that.orchestrationimport.fileName = filename;
  }

  executeOverwrite() {
    this.importExportService.resetErrorMessage();
    this.importExportService.resetSuccessMessage();
    this.importExportService.resetWarningMessage();
    const that = this;
    var overwriteData = that.orchestrationimport
    overwriteData.packageFileData = that.retainedComparisonPkg;
    overwriteData.fileName = that.retainedComparisonPkgName;
    that.iotService.getCuiObjResponse().executeOrchestrationOverwrite({
      orchestrationid: that.retainedComparedOrchId,
      data: overwriteData
    })
      .then(function (response) {
        var resp = response;
        that.importExportService.successMessage = resp.jobMessage + " OrchestrationConfig ID: " + resp.resourceId;

      })
      .fail(function (response) {
        var respJson = response;
        if (respJson.responseJSON.apiMessage) {
          that.importExportService.errorMessage = respJson.responseJSON.apiMessage;
        } else {
          that.importExportService.errorMessage = respJson.status + respJson.statusText;
        }

      });


  }
  onButtonToggle(event) {
    const that = this;
    console.log("onButtonToggle: " + event)
    if (event == "Import") {
      that.importExportOrcheState = 1;
      console.log("onButtonToggle: " + that.importExportOrcheState)
    }
    else if (event == "Compare") {
      that.importExportOrcheState = 2;
      console.log("onButtonToggle: " + that.importExportOrcheState)
    }
    else if (event == "Export") {
      that.importExportOrcheState = 3;
      console.log("onButtonToggle: " + that.importExportOrcheState)
    }
    this.uploadedFile = undefined;
    this.initOrchestrationImport();
    this.initOrchestrationExport();
    this.showComparisonResult = false;
    this.importExportService.resetErrorMessage();
    this.importExportService.resetSuccessMessage();
    this.importExportService.resetWarningMessage();

  }


  tabClick(tab) {
    console.log(tab.index);
    if (tab.index == 0) {
      console.log("Import");
      this.importExportService.orchestrationImport = true;
      this.importExportService.orchestrationCompare = false;
      this.importExportService.orchestrationExport = false;
    }
    else if (tab.index == 1) {
      console.log("Compare");
      this.importExportService.orchestrationImport = false;
      this.importExportService.orchestrationCompare = true;
      this.importExportService.orchestrationExport = false;
    }
    else if (tab.index == 2) {
      console.log("Export");
      this.importExportService.orchestrationImport = false;
      this.importExportService.orchestrationCompare = false;
      this.importExportService.orchestrationExport = true;
    }
    this.uploadedFile = undefined;
    this.initOrchestrationImport();
    this.initOrchestrationExport();
    this.showComparisonResult = false;
    this.importExportService.resetErrorMessage();
    this.importExportService.resetSuccessMessage();
    this.importExportService.resetWarningMessage();

  }

}

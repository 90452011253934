<mat-sidenav-container class="new-export" autosize>

    <mat-sidenav class="midNav-container" #sidenav mode="side" disableClose="true" opened="true">
        <div style="height:115h">
            <div class="alert success" *ngIf="importExportService.successMessage">
                <h5>
                    <fa-icon [icon]="faSuccessIcon"></fa-icon>
                    <span>{{importExportService.successMessage}}</span>
                    <button class="alert-close-btn">
                        <fa-icon (click)=importExportService.resetSuccessMessage() [icon]="faCloseIcon"></fa-icon>
                    </button>
                </h5>
            </div>
            <div class="alert critical" *ngIf="importExportService.errorMessage">
                <h5>
                    <fa-icon [icon]="faCriticalIcon"></fa-icon>
                    <span>{{importExportService.errorMessage}}</span>
                    <button class="alert-close-btn">
                        <fa-icon (click)=importExportService.resetErrorMessage() [icon]="faCloseIcon"></fa-icon>
                    </button>
                </h5>
            </div>
            <div class="description">
            <div *ngIf="importExportService.eventGroupImport && importExportEventState==1">
                <b>{{'description' | translate}}{{':' | translate}}</b>
                <p>{{'select.configFile.exportOption' | translate}}
                </p>
            </div>
            <div *ngIf="importExportEventState==2">
                <b>{{'description' | translate}}{{':' | translate}}</b>
                <p>{{'export.eventGroup.resourceId' | translate}}
                </p>
            </div>
            <div *ngIf="successMsgFlag">
                <h1 style="color:green;">
                    <fa-icon [icon]="faSuccessIcon"></fa-icon>
                    <b>{{successMsg}}</b>
                </h1>
            </div>
            </div>
        </div>
        <div class="row"  style="margin-left:15px;">
            <div>
                <button class="btn" [ngClass]="importExportEventState==1 ? 'btn-primary': 'btn-secondary'"
                    (click)="importExportEventState=1; onButtonToggle('Import')"
                    value="Export">{{'import' | translate}}</button>&nbsp;&nbsp;
                <button class="btn" [ngClass]="importExportEventState==2 ? 'btn-primary': 'btn-secondary'"
                    (click)="importExportEventState=2; onButtonToggle('Export')"
                    value="Import">{{'export' | translate}}</button>&nbsp;&nbsp;
            </div>
        </div>
    </mat-sidenav>

    <mat-sidenav-content class="example-sidenav-content">
        <div class="export-data">

            <!-- <div class="tab-holder">
                <mat-tab-group (selectedTabChange)="tabClick($event)" [selectedIndex]="0">
                    <mat-tab label="Import"></mat-tab>
                    <mat-tab label="Export"></mat-tab>
                </mat-tab-group>
            </div> -->

            <div class="form-data" *ngIf="importExportService.eventGroupImport && importExportEventState==1">

                <label>{{'package.file' | translate}}</label>
                <div>
                    <input type='file' (change)="fileChanged($event)" id="fileUpload">
                </div>

                <!-- {{viewValue}} -->

                <label for="Job Name"> {{ 'job.name' | translate}}</label>
                <input placeholder="optional" id="Job Name" type="text" aria-label="Name"
                    [(ngModel)]="eventGroupImportInfo.jobName"><br><br>

                <button class="btn btn-primary" style="margin-left: 3px;" (click)=" executeEventGroupImport(file)" [disabled]="file==undefined">{{
                    'execute.import' |
                    translate}}</button>
                <p *ngIf="errorMsg" style="color: red;">{{errorMsg}}</p>
            </div>

            <div *ngIf="importExportEventState==2">
                <app-eventgroup-export></app-eventgroup-export>
            </div>

        </div>
    </mat-sidenav-content>

</mat-sidenav-container>
<header class="table-header">
    <h2>{{ 'streams.header' | translate }}</h2>
</header>
<div class="control-bar">
    <input placeholder="search" type="text" (keyup)="onStreamSearch($event)">
</div>
<div class="table-container">
    <ngx-skeleton-loader
    *ngIf="!dataSource"
    count="5"
    [theme]="{'border-radius': '0px',
      height: '48px',
      width: '100%',
      margin: '1px',
      position: 'relative',
      top: '20px'
    }"></ngx-skeleton-loader>
    <table mat-table [dataSource]="dataSource" class="streamsTable">
        <!-- Button Column -->
        <ng-container matColumnDef="ADD">
            <th mat-header-cell *matHeaderCellDef >  </th>
            <td mat-cell *matCellDef="let stream">
                <div class="btn-col">
                    <!-- <mat-checkbox #checkbox [disabled]="stream.streamState != 'ACTIVE'" (click)="selectStream(checkbox,stream.id)" [checked]="selection.isSelected(stream)" class="example-margin"></mat-checkbox> -->
                    <ng-container *ngIf="stream.streamState === 'ACTIVE'">
                        <div>
                            <ng-container *ngIf="selection.isSelected(stream)">
                                <button class="selected btn btn-micro btn-secondary" (click)="selectStream(false,stream.id)"><fa-icon [icon]="faCheckIcon"></fa-icon></button>&nbsp;
                            </ng-container>
                            <ng-container *ngIf="!selection.isSelected(stream)">
                                <button class="select btn btn-micro btn-secondary" (click)="selectStream(true,stream.id)">Add</button>&nbsp;
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="stream.streamState != 'ACTIVE'">
                        <div>
                            <ng-container *ngIf="selection.isSelected(stream)">
                                <button class="btn btn-micro btn-secondary" [disabled]="stream.streamState === 'ACTIVE'"><fa-icon [icon]="faCheckIcon"></fa-icon></button>&nbsp;
                            </ng-container>
                            <ng-container *ngIf="!selection.isSelected(stream)">
                                <button class="btn btn-micro btn-secondary" [disabled]="stream.streamState != 'ACTIVE'">Add</button>&nbsp;
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef class="nameCol"> {{'name' | translate}} </th>
          
            <td mat-cell *matCellDef="let stream">
                <div class="name-cell">
                 {{stream.name[0].text}}
                </div>
            </td>
            
        </ng-container>

         <!-- Encryption Column -->
         <ng-container matColumnDef="Encryption">
            <th mat-header-cell *matHeaderCellDef class="encryptionCol"> {{'encryption' | translate}} </th>
            <ng-container *matCellDef="let stream" style="font-size: small;">
                <td mat-cell *ngIf="stream.payloadSecurityType">
                    <div class="cell-col">{{stream.payloadSecurityType}}</div>
                </td>
                <td mat-cell *ngIf="!stream.payloadSecurityType">
                    <div class="cell-col">-</div>
                </td>
            </ng-container>
        </ng-container>

        <!-- Stream Type Column -->
        <ng-container matColumnDef="Stream Type">
            <th mat-header-cell *matHeaderCellDef class="streamTypeCol"> {{'stream_type' | translate}} </th>
            <ng-container *matCellDef="let stream" style="font-size: small;">
                <td mat-cell *ngIf="stream.streamType">
                    <div class="cell-col">{{stream.streamType}}</div>
                </td>
                <td mat-cell *ngIf="!stream.streamType">
                    <div class="cell-col">-</div>
                </td>
            </ng-container>
        </ng-container>

        <!-- Direction Column -->
        <ng-container matColumnDef="Direction">
            <th mat-header-cell *matHeaderCellDef class="directionCol"> {{'direction' | translate}} </th>
            <ng-container *matCellDef="let stream" style="font-size: small;">
                <td mat-cell *ngIf="stream.direction">
                    <div class="cell-col">{{stream.direction}}</div>
                </td>
                <td mat-cell *ngIf="!stream.direction">
                    <div class="cell-col">-</div>
                </td>
            </ng-container>
        </ng-container>

        <!-- Protocol Column -->
        <ng-container matColumnDef="Protocol">
            <th mat-header-cell *matHeaderCellDef class="protocolCol"> {{'protocol' | translate}} </th>
            <ng-container *matCellDef="let stream" style="font-size: small;">
                <td mat-cell *ngIf="stream.protocolType">
                    <div class="cell-col">{{stream.protocolType}}</div>
                </td>
                <td mat-cell *ngIf="!stream.protocolType">
                    <div class="cell-col">-</div>
                </td>
            </ng-container>
        </ng-container>

        <!-- Enabled Column -->
        <ng-container matColumnDef="Enabled">
            <th mat-header-cell *matHeaderCellDef class="enabledCol"> {{'enabled' | translate}} </th>
            <ng-container *matCellDef="let stream; let btnNum = index">
              
                

                <td mat-cell *ngIf="stream.streamState == 'ACTIVE'">
                    <div class="cell-col">
                        <button class="toggle-on toggle-switch" (click)="disableStream(stream,stream.id,btnNum)" title="Disable {{stream.name[0].text}}">
                            <fa-icon [icon]="faCheckIcon" class="fa-2x"></fa-icon>
                            <div class="toggle-on-btn"></div>
                        </button>
                    </div>
                </td>
                <td mat-cell *ngIf="stream.streamState != 'ACTIVE'">
                    <div class="cell-col">
                        <button class="toggle-off toggle-switch" (click)="enableStream(stream.id,btnNum)" title="Enable {{stream.name[0].text}}">
                            <div class="toggle-off-btn"></div>
                        </button>
                    </div>
                </td>

            </ng-container>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let stream; columns: displayedColumns;"></tr>
    </table>
</div>
<!-- OT Pagination component -->
<div class="table-container">
    <app-pagination *ngIf="showPagination" [count]="streamCount" [pageSize]="pageSize" [currentPage]="currentPage" (currentPageChange)="onPageChange($event)"></app-pagination>
</div>
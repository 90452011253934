/** 
 * This is an auto generated code for gatewayAdaptors service listing page.
 * This file can be customized and will not be overwritten.
 * @version 1.0.0 
 * @readonly 
 * @author - System generated 
 */

import { Injectable } from '@angular/core';
import { SelectionModel} from '@angular/cdk/collections';
import { IotService } from '../service/iot.service'; 
import { ListGatewayAdaptorsService } from './ListGatewayAdaptors.service';
import { GatewaysService } from './gateways.service';

@Injectable({ 
	  providedIn: 'root' 
})

export class GatewayAdaptorsService extends ListGatewayAdaptorsService {
	 constructor(public iotService:IotService, public gatewaysService: GatewaysService) {
		 super(iotService);
	 }

	 fnOnSelectionSupport(results){
		 const that = this; 
	 } 

	 fnRemoveSelectionSupport(results){ 
		 const that = this; 
	 } 
	 createNew(){
		this.gatewaysService.createNewAdaptor=true;
		this.gatewaysService.createResource=false;
	}

	viewDetails(selectedId){
		this.gatewaysService.selectedAdaptorId=selectedId;
		this.gatewaysService.createResource=false;
		this.gatewaysService.showAdaptorDetail=true;
	}
} 

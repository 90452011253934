/** 
 * This is an auto generated code for ecosystemRelationships service listing page.
 * This file can be customized and will not be overwritten.
 * @version 1.0.0 
 * @readonly 
 * @author - System generated 
 */

import { Injectable } from '@angular/core';
import { SelectionModel} from '@angular/cdk/collections';
import { IotService } from '../service/iot.service'; 
import { ListEcosystemRelationshipsService } from './ListEcosystemRelationships.service';

@Injectable({ 
	  providedIn: 'root' 
})

export class EcosystemRelationshipsService extends ListEcosystemRelationshipsService {
	 constructor(public iotService:IotService) {
		 super(iotService);
	 }

	 fnOnSelectionSupport(results){
		 const that = this; 
	 } 

	 fnRemoveSelectionSupport(results){ 
		 const that = this; 
	 } 

} 

<mat-sidenav-container class="new-route" autosize>

    <mat-sidenav class="midNav-container" #sidenav mode="side" disableClose="true" opened="true">
        <div class="alert critical" *ngIf="routeService.errorMessage">
            <h5>
                <fa-icon [icon]="faCriticalIcon"></fa-icon>
                <span>{{routeService.errorMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=routeService.resetErrorMessage() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>
        <div class="alert success" *ngIf="routeService.successMessage">
            <h5>
                <fa-icon [icon]="faSuccessIcon"></fa-icon>
                <span>{{routeService.successMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=routeService.resetSuccessMessage() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>
        <div style="margin-left: -10px;">
            <app-copy-resource-id></app-copy-resource-id>
        </div>
        <div *ngIf=routeDetailInfo>
            <h1> {{ 'view.route.info' | translate}}</h1>
            <mat-nav-list class="without-template user-selections">
                <!-- <ng-container *ngIf="typeChangeControl.value==1">
                    <div>
                        <h2>Event Groups</h2>
                        <p> Select Event Group from the Available Event Groups on the right.</p>
                    </div>
                </ng-container> -->
                <ng-container *ngIf="!routeService.getStream()">
                    <h2>{{'streams' | translate}}</h2>
                    <p> {{'select.stream.fromlist' | translate}}</p>
                </ng-container>
                <ng-container *ngIf="routeService.getStream()">
                    <h2>{{'streams' | translate}}</h2>
                    <ul>
                        <li>
                            <fa-icon [icon]="faCheckIcon"></fa-icon> {{routeService.getStream().name[0].text}}
                        </li>
                    </ul>
                </ng-container>
                <ng-container *ngIf="!routeService.getEventGroup() && viewValue=='EVENT'">
                    <h2>{{'eventGroups.header' | translate}}</h2>
                    <p> {{'select.eventGroup.fromlist' | translate}}</p>
                </ng-container>
                <ng-container *ngIf="routeService.getEventGroup()">
                    <h2>{{'eventGroups.header' | translate}}</h2>
                    <ul>
                        <li>
                            <fa-icon [icon]="faCheckIcon"></fa-icon> {{routeService.getEventGroup().name[0].text}}
                        </li>
                    </ul>
                </ng-container>
                <ng-container *ngIf="!routeService.getDevice() && viewValue=='COMMAND'">
                    <h2>{{'devices' | translate}}</h2>
                    <p> {{'select.device.fromlist' | translate}}</p>
                </ng-container>
                <ng-container *ngIf="routeService.getDevice()">
                    <h2>{{'devices' | translate}}</h2>
                    <ul>
                        <li>
                            <fa-icon [icon]="faCheckIcon"></fa-icon> {{routeService.getDevice().name[0].text}}
                        </li>
                    </ul>
                </ng-container>
                <ng-container *ngIf="!routeService.getRoutingSourceRelationship() && viewValue=='TP_RELATIONSHIP'">
                    <h2>{{'routingSourceRelationships.header' | translate}}</h2>
                    <p> {{'select.routingSourceRelationship' | translate}}
                    </p>
                </ng-container>
                <ng-container *ngIf="routeService.getRoutingSourceRelationship()">
                    <h2>{{'routingSourceRelationships.header' | translate}}</h2>
                    <ul>
                        <li>
                            <fa-icon [icon]="faCheckIcon"></fa-icon>
                            {{routeService.getRoutingSourceRelationship().name[0].text}}
                        </li>
                    </ul>
                </ng-container>
                <ng-container *ngIf="!routeService.getDocumentInfo() && viewValue=='MESSAGE_INFO'">
                    <h2>{{'documentInfo' | translate}}</h2>
                    <p> {{'select.documentInfo.fromlist' | translate}}</p>
                </ng-container>
                <ng-container *ngIf="routeService.getDocumentInfo()!=undefined">
                    <h2>{{'documentInfo' | translate}}</h2>
                    <ul>
                        <li>
                            <fa-icon [icon]="faCheckIcon"></fa-icon> {{routeService.getDocumentInfo().name[0].text}}
                        </li>
                    </ul>
                </ng-container>

            </mat-nav-list>
            <br>
            <br>
            <div class="button-row pull-left">
                <button class="btn btn-primary" style="margin: 5px; margin-left: 10px;" (click)="updateRoute(routeDetailInfo.id)">{{ 'update' |
                    translate}}</button>
            </div>
            <div class="button-row pull-right">
                <button class="btn btn-delete" style="margin: 5px;" (click)="deleteRoute(routeDetailInfo.id)()">
                    {{'delete' | translate}}</button>
            </div>
            <!-- </div> -->
        </div>

    </mat-sidenav>
    <mat-sidenav-content class="example-sidenav-content" *ngIf=routeDetailInfo>
        <div class="route-data">
            <div class="form-data">
                <label for="name"> {{ 'name' | translate}}</label>
                <input placeholder="required" id="route-name" type="text" aria-label="Name"
                    [(ngModel)]="routeDetailInfo.name[0].text"><br>

                <label for="description">{{ 'description' | translate}}</label>
                <textarea *ngIf="routeDetailInfo.description" rows="2" placeholder="optional" id="description"
                    aria-label="description" [(ngModel)]="routeDetailInfo.description[0].text"></textarea>
                <br>

                <!-- <label for="description"> {{ 'description' | translate}}</label>
                <p *ngIf="routeDetailInfo.description" id="description">{{routeDetailInfo.description[0].text}}</p>
                <p *ngIf="!routeDetailInfo.description" id="description">-</p> -->

                <label for="route-type">{{ 'route.type' | translate }}</label>
                <ng-container>
                    <select name="route-type" [(ngModel)]="viewValue" (ngModelChange)="onChange($event)">
                        <option [value]="item.viewValue" *ngFor="let item of routetypess">
                            {{item.viewValue}}
                        </option>
                    </select>
                </ng-container>
                <!-- <p class="text-red" *ngIf="streamError && !routeService.getStream()">{{streamError}}</p> -->
            </div>
            <div>
                <p *ngIf="streamError && !routeService.getStream()" style="color: red;margin-left: 15px;">{{streamError}}</p>
                <div *ngIf=streamLoaded>
                    <app-streams *ngIf=" viewValue=='EVENT' " [pageSize]=" 5" [showControlBar]=true [showNew]="false"
                        [displayedColumns]="['ADD','name','protocolType','streamType','direction','creation','ACTIVE']"
                        showSelectColoumn=true showEnableColumn=true multiSelect="false"
                        addnQueryParam=[streamType::APPLICATION] [showHeaderToolTips]=false>
                    </app-streams>
                    <app-streams *ngIf="viewValue=='COMMAND'" [pageSize]="5" [showControlBar]=true [showNew]="false"
                        [displayedColumns]="['ADD','name','protocolType','streamType','direction','creation','ACTIVE']"
                        showSelectColoumn=true showEnableColumn=true multiSelect="false"
                        addnQueryParam=[streamType::DEVICE] [showHeaderToolTips]=false>
                    </app-streams>
                    <app-streams *ngIf="viewValue=='MESSAGE_INFO' || viewValue=='TP_RELATIONSHIP'" [pageSize]="5"
                        [showControlBar]=true
                        [displayedColumns]="['ADD','name','protocolType','streamType','direction','creation','ACTIVE']"
                        showSelectColoumn=true showEnableColumn=true multiSelect="false" [showNew]="false" [showHeaderToolTips]=false>
                    </app-streams>
                </div>
                <p *ngIf="eventGroupError && !routeService.getEventGroup()" style="color: red;margin-left: 15px;">{{eventGroupError}}</p>
                <div *ngIf="!eventGrouploaded">
                    <app-eventGroups *ngIf="viewValue=='EVENT'" [pageSize]="5" [showControlBar]=true
                        [displayedColumns]="['ADD','name','description','creation']" showSelectColoumn=true showEnableColumn=false
                        multiSelect="false" [showNew]="false" [showHeaderToolTips]=false>
                    </app-eventGroups>
                </div>

                <div *ngIf="eventGrouploaded">
                    <app-eventGroups *ngIf="viewValue=='EVENT'" [pageSize]="5" [showControlBar]=true
                        [displayedColumns]="['ADD','name','description','creation']" showSelectColoumn=true showEnableColumn=false
                        multiSelect="false" [showNew]="false" [showHeaderToolTips]=false>
                    </app-eventGroups>
                </div>
                <p *ngIf="deviceError && !routeService.getDevice()" style="color: red;margin-left: 15px;">{{deviceError}}</p>
                <div *ngIf="!deviceLoaded">
                    <app-digitalTwins *ngIf="viewValue=='COMMAND'" [pageSize]="5" [showSelectColoumn]=true
                        [showControlBar]=true [displayedColumns]="['ADD','name','description','parentDeviceTemplateName','creation','ACTIVE']"
                        multiSelect="false" [showNew]="false" [showHeaderToolTips]=false>
                    </app-digitalTwins>
                </div>
                <div *ngIf="deviceLoaded">
                    <app-digitalTwins *ngIf="viewValue=='COMMAND'" [pageSize]="5" [showSelectColoumn]=true
                        [showControlBar]=true [displayedColumns]="['ADD','name','description','parentDeviceTemplateName','creation','ACTIVE']"
                        multiSelect="false" [showNew]="false" [showHeaderToolTips]=false>
                    </app-digitalTwins>
                </div>
                <p *ngIf="docInfoError && !routeService.getDocumentInfo()" style="color: red;margin-left: 15px;">{{docInfoError}}</p>
                <div *ngIf="!documentLoaded">
                    <app-documentInfo *ngIf="viewValue=='MESSAGE_INFO'" [pageSize]="5" [showControlBar]=true
                        [displayedColumns]="['ADD','name','supportedDocTemplateType','docTemplateVersion','creation','ACTIVE']"
                        [showSelectColoumn]=true [showEnableColumn]=true multiSelect="false" [showNew]="false" [showHeaderToolTips]=false>
                    </app-documentInfo>
                </div>
                <div *ngIf="documentLoaded">
                    <app-documentInfo *ngIf="viewValue=='MESSAGE_INFO'" [pageSize]="5" [showControlBar]=true
                        [displayedColumns]="['ADD','name','supportedDocTemplateType','docTemplateVersion','creation','ACTIVE']"
                        [showSelectColoumn]=true [showEnableColumn]=true multiSelect="false" [showNew]="false" [showHeaderToolTips]=false>
                    </app-documentInfo>
                </div>
                <p *ngIf="routingSourceRelError && !routeService.getRoutingSourceRelationship()" style="color: red;margin-left: 15px;">
                    {{routingSourceRelError}}</p>
                <div *ngIf=" !routeSourceLoaded">
                    <app-routingSourceRelationships *ngIf="viewValue=='TP_RELATIONSHIP'" [pageSize]="5"
                        [showSelectColoumn]=true [showEnableColumn]=true [showActionColumn]=true multiSelect="false"
                        [showNew]="false"
                        [displayedColumns]="['ADD','name','fromCode', 'toCode', 'documentInfoName', 'senderOwnerName','ACTIVE']" [showHeaderToolTips]=false>
                    </app-routingSourceRelationships>
                </div>
                <div *ngIf="routeSourceLoaded">
                    <app-routingSourceRelationships *ngIf="viewValue=='TP_RELATIONSHIP'" [pageSize]="5"
                        [showSelectColoumn]=true [showEnableColumn]=true [showActionColumn]=true multiSelect="false"
                        [showNew]="false"
                        [displayedColumns]="['ADD','name','fromCode', 'toCode', 'documentInfoName', 'senderOwnerName','ACTIVE']" [showHeaderToolTips]=false>
                    </app-routingSourceRelationships>
                </div>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IotService } from 'src/app/service/iot.service';
import { SideNavComponent } from 'src/app/side-nav/side-nav.component';
import { faToggleOff, faToggleOn, faAngleLeft, faAngleRight, faCheck, faCopy, faTimes, faAngleDoubleLeft, faAngleDoubleRight, faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER, I } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-document-info-detail',
  templateUrl: './document-info-detail.component.html',
  styleUrls: ['./document-info-detail.component.scss']
})
export class DocumentInfoDetailComponent implements OnInit {

  failed = faTimes;
  faToggleOffIcon = faToggleOff;
  faToggleOnIcon = faToggleOn;
  faAngleLeftIcon = faAngleLeft;
  faAngleRightIcon = faAngleRight;
  faCheckIcon = faCheck;
  faCopyIcon = faCopy;
  faCloseIcon = faTimes;
  faSuccessIcon = faCheckCircle;
  faAngleDoubleLeftIcon = faAngleDoubleLeft;
  faAngleDoubleRightIcon = faAngleDoubleRight;
  faWarningIcon = faExclamationTriangle;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  tags = [];
  tagClass = 'tagInput';
  successMessage: string;
  warningMessage: string;
  isActive = true;
  docinfo;

  supportLanguages = ['en'];
  constructor(private router: Router, private route: ActivatedRoute, private iotService: IotService,
    private sideNavComponent: SideNavComponent, private translateService: TranslateService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }

  reset() {
    this.successMessage = "";
    this.warningMessage = "";
  }


  ngOnInit() {

    this.route.params.subscribe(params => {
      var docInfoId = params['id'];
      this.loadDocumentInfo(docInfoId);
    });

  }
  loadDocumentInfo(docInfoId: any) {
    const that = this;
    that.iotService.getCuiObjResponse().getDocumentInfoById({
      docInfoId: docInfoId
    }).then(function (response) {
      console.log(response);
      that.docinfo = response;
      that.sideNavComponent.menuClear();
      that.sideNavComponent.menuChange('Dashboard', 'sub-type');
      that.sideNavComponent.menuChange('documentInfo.header', 'sub-type');
      that.sideNavComponent.menuChange(that.docinfo.name[0].text, 'sub-type');
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }


  deactivateDocInfo(id){
    const that = this;
    that.iotService.getCuiObjResponse().deactivateDocInfo({
      id: id
    }).then(function (response) {
      // console.log(response);
      that.loadDocumentInfo(id);
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }

  activateDocInfo(id){
    const that = this;
    that.iotService.getCuiObjResponse().activateDocInfo({
      id: id
    }).then(function (response) {
      // console.log(response);
      that.loadDocumentInfo(id);
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }


  add(event: MatChipInputEvent): void {

    this.tagClass = 'tagInput';
    if (this.docinfo.tags == undefined) {
      this.docinfo.tags = [];
    }
    const input = event.input;
    const value = event.value;
    const index = this.docinfo.tags.indexOf(value);
    // this.docinfo.tags.push(value);
    console.log("index " + index);
    if (index < 0) {
      if ((value || '').trim()) {
        if (this.docinfo.tags != undefined && this.docinfo.tags.includes(value)) {
          if (input) {
            input.value = '';
          }
        }
        else {
          this.docinfo.tags.push(value);
          this.createDocInfoTag(value);
          if (input) {
            input.value = '';
          }
        }
      }
    } else {
      this.tagClass = 'tagInput-duplicate';
    }
  }
  createDocInfoTag(tag) {
    const that=this;
    that.iotService.getCuiObjResponse().createDocInfoTag({
      id: that.docinfo.id,
      tags:tag
    }).then(function (response) {
      // console.log(response);
      that.loadDocumentInfo(that.docinfo.id);
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  remove(tag: string): void {
    const that = this;
    that.docinfo.tags.forEach((value, index) => {
      if (value == tag) that.docinfo.tags.splice(index, 1);
      this.deleDocInfoTag(value);
    });

  }
  deleDocInfoTag(tag) {
    const that=this;
    that.iotService.getCuiObjResponse().deleDocInfoTag({
      id: that.docinfo.id,
      tags:tag
    }).then(function (response) {
      // console.log(response);
      that.loadDocumentInfo(that.docinfo.id);
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

}

import { hasLifecycleHook } from '@angular/compiler/src/lifecycle_reflector';
import { Component, OnInit,Output,EventEmitter } from '@angular/core';
import { IotService } from '../../service/iot.service';
import { DeviceService } from '../../service/device.service';
import { EventGroupsService } from '../../service/eventGroups.service';
import { faAngleDoubleLeft, faAngleDoubleRight,faToggleOff,faToggleOn,faCheck} from '@fortawesome/free-solid-svg-icons';
import {SelectionModel} from '@angular/cdk/collections';
import { TranslateService } from '@ngx-translate/core';
import { JsonPipe } from '@angular/common';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styleUrls: ['./device-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class DeviceListComponent implements OnInit {

  dataSource: any;
  displayedColumns: string[] = ['Name', 'Description', 'Enabled'];
  length = 1000;
  faAngleDoubleLeftIcon = faAngleDoubleLeft;
  faAngleDoubleRightIcon = faAngleDoubleRight;
  faToggleOffIcon = faToggleOff;
  faToggleOnIcon = faToggleOn;
  faCheckIcon = faCheck;
  deviceSelected:string;
  eventSelected:string;
  showEventTemplates=false;
  showCommandTemplates=false;
  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;
  pageSize = 5;
  currentPage =1;
  searchText;
  devices = [];
  events = [];
  associations = [];
  device = [];
  showValue = false;
  showSelect = true;
  HighlightRow : Number;
  display = "Select"
  savedDevice = []
  found = false;
  arrs = [9, 2, 5];
  toSelect = 'select'
  previousId ='';
  displaySelected = '';
  isPlaying=[];
  prevElement;
  supportLanguages = ['en'];
  selection: SelectionModel<Element> = new SelectionModel<Element>(true, []);
  selection1: SelectionModel<Element> = new SelectionModel<Element>(true, []);
  selectedDevices=[];
  selectedDeviceEvents=[];
  selectedDeviceCommands=[];
  deviceCount:number=0;
  showPagination:boolean=false;
  previousDeviceElement;
  nextDeviceElement;
  eventTemplate=[];
  eventTemplateStatus:boolean=false;

  constructor(private iotService:IotService,private deviceService:DeviceService,private translateService: TranslateService,
    private eventGroupService:EventGroupsService) { 
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }
  
  ngOnInit(): void {
    this.getDevices()
    if(this.deviceService.getDeviceData()!=undefined){
      this.deviceService.getDeviceData().forEach((value,index)=>{
        this.selectedDeviceEvents.push(value);
        this.selectedDeviceCommands.push(value);
       });
       this.selection=new SelectionModel<Element>(true, this.selectedDevices);
       this.selection.isSelected = this.isChecked.bind(this);
      }
    this.countDevices('');
  }

  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }
  
  isChecked(row: any): boolean {
    const found = this.selection.selected.find(el => el.id === row.id);
    if (found) {
      return true;
    }
    return false;
   }

   isChecked1(row: any): boolean {
    const found = this.selection1.selected.find(el => el.id === row.id);
    if (found) {
      return true;
    }
    return false;
   }

  onDeviceSearch(event: any){
      this.deviceSelected = event.target.value;
      let val = '*'+event.target.value+'*';
      const that = this;
      that.countDevices(val);
      that.currentPage=1;
      that.iotService.getCuiObjResponse().getDevices({
        qs:[['pageSize',5],['page',that.currentPage],['name',val]]
      }).then(function(response) {
        that.devices = response;
        that.dataSource = that.devices;
      }).fail(function (err){
        if(err.responseJSON.apiStatusCode){
          that.eventGroupService.errorMessage=err.responseJSON.apiMessage;
          console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
        } else{
          console.log("Something went wrong. Please check chrome console or check with dev team");
        } 
      });
  }

  getDevices(){

    const that = this;
    this.iotService.getCuiObjResponse().getDevicesAndChildren({
      qs:[['pageSize',5],['page',this.currentPage]]
    }).then(function(response) {
      that.devices = response;
      that.dataSource = that.devices;
     
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.eventGroupService.errorMessage=err.responseJSON.apiMessage;
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  enableDisableRule(button) {
    if(this.display=='Select'){
      this.display='Deselect'
    }else{
      this.display='Select'
    }
  }

  playRecord(element,flag) {
    if(this.selection.isSelected(element)){
      this.selection.clear();
    }
    else{
      this.selection.toggle(element);
    }
    const that = this;
    element.isPlaying = flag;
    if(that.prevElement != undefined && that.prevElement.id != element.id) {
      that.prevElement.isPlaying = false;
      this.toSelect = 'select';
    }
    that.prevElement = element;
  }

  removeSelection (deviceId: any,flag:boolean) {
    this.eventGroupService.resetErrorMessage();
    var index = null;
    this.toSelect = 'select';
    this.prevElement.isPlaying = flag;
    this.eventGroupService.setSelectedEventTemplate(undefined);
  };

  OnSelection(event, deviceId, eventTemplateId){
    const that = this;    
    this.iotService.getCuiObjResponse().getDevice({
      deviceId: deviceId
    }).then(function(response) {      
      if(event === true){  
        that.selection.select(response);
        that.deviceService.deviceSelect = true;
        that.selectedDevices.push(response);
        that.selectedDeviceEvents.push(eventTemplateId)
        that.selectedDeviceCommands.push(response.supportedCommands[0].id)
      }else{
        that.selection.clear();
        that.selectedDevices.forEach((value,index)=>{
          if(value.id==deviceId) that.selectedDevices.splice(index,1);

      });
      that.selectedDevices.forEach((value,index)=>{ 
        that.selection.select(value);
      });
    }
    that.deviceService.setDeviceData(that.selectedDevices);
    if(that.deviceService.getDeviceData()!=undefined && that.deviceService.getDeviceData().length<1){
      that.deviceService.deviceSelect=false;
    }

  }).fail(function (err){
    if(err.responseJSON.apiStatusCode){
      that.eventGroupService.errorMessage=err.responseJSON.apiMessage;
      console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
    } else{
      console.log("Something went wrong. Please check chrome console or check with dev team");
    }
  });
  }

  OnEventSelection(event, device, eventTemaplate){
    const that = this;      
      if(event === true){  
        that.selection.select(eventTemaplate);
        that.eventGroupService.eventSelect = true;
        // that.selectedDevices.push(response);
        that.selectedDeviceEvents.push(eventTemaplate);
        that.addDeviceEvents(device, eventTemaplate);
      }else{
        that.selection.clear();
        that.removeDeviceEvents(device, eventTemaplate);
        that.selectedDeviceEvents.forEach((value,index)=>{
          if(value.id==eventTemaplate.id) {
            that.selectedDeviceEvents.splice(index,1);
          }
          
        });
        that.selectedDeviceEvents.forEach((value,index)=>{
          that.selection.select(value);
          });
        }

    // that.eventGroupService.setEventId(eventTemaplate.id);
    // //*
    // that.eventGroupService.setEventGroupSourceDevices(device.id, eventTemaplate.id);
    
    //that.setEventGroupSourceDeviceEvents(device.id, eventTemaplate.id);

    if(that.eventGroupService.getSelectedEventTemplateData()!=undefined && that.eventGroupService.getSelectedEventTemplateData().length<1){
      that.eventGroupService.eventSelect=false;
    }
  
  }

  OnCommandSelection(event, device, commandTemplate){
    const that = this;
      if(event === true){  
        that.selection1.select(commandTemplate);
        that.eventGroupService.commandSelect = true;
        that.selectedDeviceCommands.push(commandTemplate);
        that.addDeviceCommands(device, commandTemplate);
      }else{        
        that.selection1.clear();
        that.removeDeviceCommands(device, commandTemplate);
        that.selectedDeviceCommands.forEach((value,index)=>{
          if(value.id==commandTemplate.id) {
            that.selectedDeviceCommands.splice(index,1);
          }
        });
        that.selectedDeviceCommands.forEach((value,index)=>{
          that.selection1.select(value);
          });
        }

        if(that.eventGroupService.getSelectedCommandTemplateData()!=undefined && that.eventGroupService.getSelectedCommandTemplateData().length<1){
          that.eventGroupService.commandSelect=false;
        }
  }

  enableDevice(deviceId : any){
    const that = this;
    this.iotService.getCuiObjResponse().activateDevice({
      deviceId: deviceId
    }).then(function(response) {
      that.getDevices();
      
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.eventGroupService.errorMessage=err.responseJSON.apiMessage;
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  disableDevice(device:any,deviceId : any){
    const that = this;
    this.iotService.getCuiObjResponse().deactivateDevice({
      deviceId: deviceId
    }).then(function(response) {
      if(that.selection.isSelected(device)){
        that.selection.clear();
      }
      that.deviceService.setSelectedDevice(undefined);
      that.getDevices();
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.eventGroupService.errorMessage=err.responseJSON.apiMessage;
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  enableEventTemplate(eventTemplateId : any){
    const that = this;
    this.iotService.getCuiObjResponse().activateEventTemplate({
      eventTemplateId: eventTemplateId
    }).then(function(response) {
     that.getDevices();
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.eventGroupService.errorMessage=err.responseJSON.apiMessage;
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  disableEventTemplate(event: any, eventTemplateId : any){
    const that = this;
    this.iotService.getCuiObjResponse().deactivateEventTemplate({
      eventTemplateId: eventTemplateId
    }).then(function(response) {
      if(that.selection.isSelected(event)){
        that.selection.clear();
      }
      that.eventGroupService.setSelectedEventTemplate(undefined);
      that.getDevices();
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.eventGroupService.errorMessage=err.responseJSON.apiMessage;
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  enableCommandTemplate(commandTemplateId : any){
    const that = this;
    this.iotService.getCuiObjResponse().activateCommandTemplate({
      commandTemplateId: commandTemplateId
    }).then(function(response) {
      that.getDevices();
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.eventGroupService.errorMessage=err.responseJSON.apiMessage;
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  disableCommandTemplate(command: any, commandTemplateId : any){
    const that = this;
    this.iotService.getCuiObjResponse().deactivateCommandTemplate({
      commandTemplateId: commandTemplateId
    }).then(function(response) {
      if(that.selection.isSelected(command)){
        that.selection.clear();
      }
      that.eventGroupService.setSelectedCommandTemplate(undefined);
     that.getDevices();
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.eventGroupService.errorMessage=err.responseJSON.apiMessage;
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  getSelectedDeviceForCurrentPage(deviceSelected){
    this.deviceSelected = deviceSelected;
    let startValue = '*'+deviceSelected;
    let endValue = deviceSelected + '*';
    const that = this;
    this.iotService.getCuiObjResponse().getDevice({
      qs:[['pageSize',5],['page',this.currentPage],['name',startValue],['name',endValue]]
    }).then(function(response) {
      that.devices = response;
      that.dataSource = that.devices;
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.eventGroupService.errorMessage=err.responseJSON.apiMessage;
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      } 
    });
  }

  getSelectedEventsForCurrentPage(eventSelected){
    this.eventSelected = eventSelected;
    let startValue = '*'+eventSelected;
    let endValue = eventSelected + '*';
    const that = this;
    this.iotService.getCuiObjResponse().getEventTemplate({
      qs:[['pageSize',5],['page',this.currentPage],['name',startValue],['name',endValue]]
    }).then(function(response) {
      that.events = response;
      that.dataSource = that.events;
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.eventGroupService.errorMessage=err.responseJSON.apiMessage;
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      } 
    });
  }

  countDevices(name) {
    const that = this;
    that.showPagination=false;
    if(name === "**") {
      name = '';
    }
    if(name === '') {
      that.iotService.getCuiObjResponse().countDevices({
        qs:[['name',name]]
      }).then(function (response) {
        that.deviceCount = response;
        that.showPagination=true;
      }).fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          that.eventGroupService.errorMessage=err.responseJSON.apiMessage;
          console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
        } else {
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    } else {
      that.iotService.getCuiObjResponse().countDevices({
        qs:[['name',name]]
      }).then(function (response) {
        that.deviceCount = response;
        that.showPagination=true;
      }).fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          that.eventGroupService.errorMessage=err.responseJSON.apiMessage;
          console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
        } else {
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    }
  }

  onPageChange(page) {
    const that = this;
    that.currentPage = page;
    if(this.deviceSelected) {
      this.getSelectedEventsForCurrentPage(this.eventSelected);
    } else {
      this.getDevices();
    }
  }

  //viewDevices
  viewEvnetsAndCommands(deviceId){
    if(!this.showEventTemplates){
    this.showEventTemplates=true
    }
    else{
      this.showEventTemplates=false
    }
    if(!this.showCommandTemplates){

      this.showCommandTemplates=true
    }
  
    else{
      this.showCommandTemplates=false
    }
  }

  addDeviceEvents(device, eventTemplate) {
    const that = this;
    let selectedDeviceEventTemplates={
      'deviceId': device.id,
      'deviceName': device.name[0].text,
      'eventId': eventTemplate.id,
      'eventName': eventTemplate.name
    }

    let selectedDeviceEventTemplates1={
      'deviceId': device.id,
      'eventTemplateId': eventTemplate.id
    }
    that.eventGroupService.setSelectedEventTemplateData(selectedDeviceEventTemplates);
    that.eventGroupService.setEventGroupSourceDevices(selectedDeviceEventTemplates1);
  }

    
  removeDeviceEvents(device, eventTemplate) {
      const that=this
     let  previousEventTemplateData= [];
     previousEventTemplateData= that.eventGroupService.getSelectedEventTemplateData();

     previousEventTemplateData.forEach((value,index)=>{
      if(value.eventId==eventTemplate.id){
        previousEventTemplateData.splice(index,1);
      }
   });

    that.eventGroupService.sourceDevices.forEach((value,index)=>{
      if(value.eventTemplateId==eventTemplate.id){
        that.eventGroupService.sourceDevices.splice(index,1);
      }
      });

      that.eventGroupService.selectedEventTemplateData.forEach((value,index)=>{
        if(value.eventId==eventTemplate.id){
          that.eventGroupService.selectedCommandTemplateData.splice(index,1);
        }
        });
    }

    addDeviceCommands(device, command) {
      const that = this;
      let selectedDeviceCommandTemplates={
        'deviceId': device.id,
        'deviceName': device.name[0].text,
        'commandId': command.id,
        'commandName': command.name
      }

      let selectedDeviceCommandTemplates1={
        'deviceId': device.id,
        'commandTemplateId': command.id
      }

      that.eventGroupService.setSelectedCommandTemplateData(selectedDeviceCommandTemplates);
      that.eventGroupService.setEventGroupSourceCommandDevices(selectedDeviceCommandTemplates1);
    }

      removeDeviceCommands(device, commandTemplate) {
        const that=this
        let  previousCommandTemplateData= []
        previousCommandTemplateData= that.eventGroupService.getSelectedCommandTemplateData();
   
        previousCommandTemplateData.forEach((value,index)=>{
         if(value.commandId==commandTemplate.id){
          previousCommandTemplateData.splice(index,1);
         }
      });
      that.eventGroupService.sourceCommandDevices.forEach((value,index)=>{
        if(value.commandTemplateId==commandTemplate.id){
          that.eventGroupService.sourceCommandDevices.splice(index,1);
        }
        });

        that.eventGroupService.selectedCommandTemplateData.forEach((value,index)=>{
          if(value.commandId==commandTemplate.id){
            that.eventGroupService.selectedCommandTemplateData.splice(index,1);
          }
          });

      }

      checkDeviceEvents(deviceId, event) {
      
      }

      checkDeviceCommands(deviceId, command) {
      
      }
      
      deviceMessage(device,expandedElement) {
        const that = this;
        if(that.previousDeviceElement != undefined && that.previousDeviceElement.id != device.id) {
          that.previousDeviceElement.messageClass = 'bg-message';
        }
    
        if(expandedElement === null) {
          device.messageClass = 'bg-message';
        } else {
          device.messageClass = 'bg-message-selected';
        }
        that.previousDeviceElement = device;
      }


      getEventTemplate(eventTemplateId){
        const that = this;
        this.iotService.getCuiObjResponse().getEventTemplate({
          eventTemplateId : eventTemplateId
        }).then(function(response) {
          that.eventTemplate.push(response);
        }).fail(function (err){
          if(err.responseJSON.apiStatusCode){
            that.eventGroupService.errorMessage=err.responseJSON.apiMessage;
            console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
          } else{
            console.log("Something went wrong. Please check chrome console or check with dev team");
          }
        });
      }

      getEventTemplateStatus(eventTemplateId){
        const that = this;
        that.eventTemplate.forEach((value,index)=>{
          if(value.id==eventTemplateId){
            that.eventTemplateStatus=value.isActive;
          }
        });
        return that.eventTemplateStatus;
      }
    
}

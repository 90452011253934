<div class="tab-holder">
    <mat-tab-group (selectedTabChange)="tabClick($event)" [selectedIndex]="0">
        <mat-tab label="Export/Import Solution"></mat-tab>
        <mat-tab label="Orchestration"></mat-tab>
        <mat-tab label="Rules"></mat-tab>
        <mat-tab label="Event Group "></mat-tab>
    </mat-tab-group>
</div>

<div>
    <app-create-import-export *ngIf="importExportService.showImportExportSolution"></app-create-import-export>
    <app-orchestration-import-export *ngIf="importExportService.showOrchIMpExp"></app-orchestration-import-export>
    <app-rules-import-export *ngIf="importExportService.showRuleImpExp"></app-rules-import-export>
    <app-eventgroup-import-export *ngIf="importExportService.showEventGroup"></app-eventgroup-import-export>

</div>
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(value: any, filterString:String,name:string): unknown {
    if(value.length==0||filterString==''||filterString==undefined){
      return value;
    }
    else{
      let resultArray=[];
      value.forEach(element => {
        if(element[name].includes(filterString)){
          resultArray.push(element);
        }
      });
      return resultArray;
      
    }
  }

}

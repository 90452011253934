import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IotService } from '../service/iot.service';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { TranslateService } from '@ngx-translate/core';
import { GatewaysService } from '../service/gateways.service';
import { Http } from '@angular/http';
import { Observable, of, interval } from 'rxjs';
import { faAngleUp, faAngleDown, faTimes, faExclamationTriangle, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { faToggleOff, faToggleOn, faAngleLeft, faAngleRight, faCheckCircle, faCheck, faCopy, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import {CreateRulePolicyComponent} from '../create-rule-policy/create-rule-policy.component';
import{RulesService} from '../service/rules.service';

@Component({
  selector: 'app-gateway-agent-detail',
  templateUrl: './gateway-agent-detail.component.html',
  styleUrls: ['./gateway-agent-detail.component.scss']
})
export class GatewayAgentDetailComponent implements OnInit {

  gatewayInfoId;
  gatewayDetailInfo;
  env;
  conf;
  deviceName;
  faCheckIcon = faCheck;
  faSuccessIcon = faCheckCircle;
  faCriticalIcon = faExclamation;
  faWarningIcon = faExclamationTriangle;
  faCloseIcon = faTimes;
  validateAdaptor: boolean = false;
  supportLanguages = ['en'];
  remoteConfigInfo: any;
  remoteConfigExists: boolean;
  fileUrl = 'assets/json/conf.json';
  showUpgrade = false;
  pollingData: any;
  updateAvailble = false;
  updateAgentAvailable;
  updateStatus:String;
  showUpdate: boolean = false;
  releaseNotes: any;
  showNextDiv=false;

  constructor(private router: Router, private route: ActivatedRoute, public iotService: IotService,
    private sideNavComponent: SideNavComponent, private translateService: TranslateService,
    public gatewaysService: GatewaysService, public http: Http,
    // public createRulePolicyComponent: CreateRulePolicyComponent,
    public rulesService:RulesService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }

  ngOnInit(): void {
    this.gatewaysService.createResource = true;
    this.gatewaysService.showSeedConfiguration = false;
    this.gatewaysService.showRemoteConfig = false;
    this.gatewaysService.showAdaptorDetail = false;
    this.gatewaysService.createNewAdaptor = false;
    this.gatewaysService.createNewLogRequest = false;
    this.gatewaysService.createNewRulePolicy = false;
    this.route.params.subscribe(params => {
      this.gatewayInfoId = params['id'];
      this.loadGateWayInfo(this.gatewayInfoId);
    });
    this.checkForUpdate(this.gatewayInfoId);
    this.startPolling();

  }
  startPolling() {


    this.pollingData = interval(5000)
      .subscribe(
        data => {
          this.poll();
        },
        error => {
          console.log(error);
        }
      );
  }

  poll() {
    console.log("In Poll");
    this.getGatewayById(this.gatewayInfoId);
    this.getRemoteConfiguration(this.gatewayInfoId);
    this.checkForUpdate(this.gatewayInfoId);
    console.log("Poll completed");
  }


  getGatewayById(gatewayInfoId) {
    const that = this;
    that.iotService.getCuiObjResponse().getGatewayAgent({
      gatewayId: gatewayInfoId
    })
      .then(function (response) {
        if (response.agentVersion != that.gatewayDetailInfo.agentVersion) {
          that.updateStatus = "";
          that.gatewayDetailInfo.agentVersion = response.agentVersion;
        }
        if (response.updateStatus != undefined) {
          console.log(response.updateStatus.code);
          if (response.updateStatus.code == 'failed') {
            that.updateStatus = 'failed';
          } else if (response.updateStatus.code == 'succeeded') {
            that.updateStatus = 'succeeded';
          } else if (response.updateStatus.code == 'requested') {
            that.updateStatus = 'requested';
          }
          that.gatewayDetailInfo.updateStatus = response.updateStatus;
        } else {
          that.updateStatus = '';
        }

      });
  }
  checkForUpdate(gatewayInfoId) {

    const that = this;
    that.iotService.getCuiObjResponse().checkForAgentUpgrade({
      qs: [['gatewayId', gatewayInfoId]]
    })
      .then(function (response) {
        if (response.agentVersion != undefined) {
          if (that.remoteConfigInfo != undefined) {
            var result = that.remoteConfigInfo.properties.filter(function (element) { return element.name == 'core_agent_auto_updates_enabled'; });
            console.log(that.updateAvailble);
            console.log(result);
            if (result.length > 0) {
              console.log(result[0].value);
              if (result[0].value != undefined && result[0].value == 'false') {
                that.updateAvailble = true;
              }
            }
          } else {
            that.updateAvailble = true;
          }
          console.log(that.updateAvailble);
          that.updateAgentAvailable = response;
          that.releaseNotes = response.releaseNotes;
          var elem = document.getElementById("releaseNotes");
          if (typeof elem !== 'undefined' && elem !== null) {
            elem.innerHTML = response.releaseNotes;
          }
        } else {
          console.log("No update available.");
          that.updateAvailble = false;
          if (that.updateAgentAvailable != undefined) {
            that.gatewayDetailInfo.agentVersion = that.updateAgentAvailable.agentVersion;
          }
        }
      })
      .fail(function (response) {
        console.log("No update available.");
        that.updateAvailble = false;
      });
  }

  ngOnDestroy() {
    this.gatewaysService.successMessage = "";
    this.gatewaysService.errorMessage = "";
    this.gatewaysService.resetErrorMessage();
    this.gatewaysService.resetSuccessMessage();
    console.log("On Destroy");
    this.pollingData.unsubscribe();
    this.iotService.gatewayInfoId="";
    this.iotService.gatewayAgentAddRulePolicy = false;
    this.iotService.viewRulePolicy=false;
  }
  loadGateWayInfo(gatewayInfoId: any) {
    const that = this;
    that.iotService.getCuiObjResponse().getGatewayAgent({
      gatewayId: gatewayInfoId
    }).then(function (response) {
      that.gatewayDetailInfo = response;
      that.getDevice(that.gatewayDetailInfo.backingDeviceId);
      that.gatewaysService.setGateWayAgentId(response.id);
      that.gatewaysService.creation = response.creation;
      that.sideNavComponent.menuClear();
      that.sideNavComponent.menuChange('Dashboard', 'sub-type');
      that.sideNavComponent.menuChange('gateways.header', 'sub-type');
      that.sideNavComponent.menuChange(that.gatewayDetailInfo.name[0].text, 'sub-type');
      that.getRemoteConfiguration(gatewayInfoId);


    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }
  getRemoteConfiguration(gatewayInfoId: any) {
    const that = this;
    that.iotService.getCuiObjResponse().getGatewayRemoteConfig({
      gatewayId: gatewayInfoId
    }).then(function (response) {
      if (response != null) {
        that.remoteConfigExists = true;
        that.remoteConfigInfo = response;
      }
    }).fail(function (err) {
      console.log("This gateway has not sent any configuration data.");
      that.remoteConfigExists = false;

    });
  }


  public loadforData(element) {

    return new Promise((resolve, reject) => {
      const that = this;
      that.http.get(that.fileUrl).map(res => res.json()).catch((error: any): any => {
        resolve(true);
        return Observable.throw(error.json().error || 'Server error');
      }).subscribe((envResponse) => {
        that.env = envResponse;
        that.conf = envResponse;
        if (that.conf != undefined) {
          if (that.conf[element.name] != undefined) {
            element.tooltip = that.conf[element.name];
          }
        }

      });
    });
  }



  getDeviceDetail(deviceId) {
    this.router.navigateByUrl("/devices/" + deviceId);
  }
  getDevice(Id) {
    const that = this;
    that.iotService.getCuiObjResponse().getDevice({
      deviceId: Id
    })
      .then(function (response) {
        that.deviceName = response.name[0].text;
      }).fail(function (response) {
        console.log("Unable to get device");
      });
  }
  loadAdaptor() {
    this.gatewaysService.showAdaptor = true;
  }
  loadLogRequest() {
    this.gatewaysService.showLogRequest = true;

  }

  loadRemoteConfig() {
    this.gatewaysService.createResource = false;
    this.gatewaysService.showRemoteConfig = true;
    this.gatewaysService.createNewAdaptor=false;
    this.gatewaysService.createNewLogRequest=false;
    this.gatewaysService.createNewRulePolicy = false;
    this.showUpdate = false;
  }

  loadSeedConfiguration() {
    this.gatewayDetailInfo.seedConfiguration.forEach((element) => {
      this.loadforData(element);
    });
    this.gatewaysService.createResource = false;
    this.gatewaysService.showSeedConfiguration = true;
    this.gatewaysService.createNewAdaptor=false;
    this.gatewaysService.createNewLogRequest=false;
    this.gatewaysService.createNewRulePolicy = false;
    this.showUpdate = false;
  }
  goBack() {
    this.gatewaysService.createResource = true;
    this.gatewaysService.showSeedConfiguration = false;
  }

  resetErrorMessage() {
    this.gatewaysService.resetErrorMessage();
  }
  resetSuccessMessage() {
    this.gatewaysService.resetSuccessMessage();
  }

  upgradeNow() {
    this.showUpdate = true;
    this.gatewaysService.createResource = false;
    this.gatewaysService.showSeedConfiguration = false;
    this.gatewaysService.showRemoteConfig = false;
    this.gatewaysService.createNewAdaptor=false;
    this.gatewaysService.createNewLogRequest=false;
    this.gatewaysService.createNewRulePolicy = false;
  }

  updateAgent() {
    const that = this;
    that.iotService.getCuiObjResponse().agentUpdateRequest({
      qs: [
        ['gatewayId', that.gatewayInfoId],
        ['targetVersion', that.updateAgentAvailable.agentVersion]
      ]
    }).then(function (response) {
        that.updateStatus = "Agent Update" + that.updateAgentAvailable.agentVersion + " requested ";
        that.updateAvailble = false;
        that.getGatewayById(that.gatewayInfoId);
      })
      .fail(function (msg) {
        console.log("Error updating agent.");
        if (msg.responseJSON.apiMessage != undefined && msg.responseJSON.apiMessage != '') {
          that.gatewaysService.errorMessage = msg.responseJSON.apiMessage;
        } else {
          that.gatewaysService.errorMessage = "Error updating agent.";
        }
      });
  }

  createRuleFromGatewayagent(gatewayId: any) {
    this.iotService.gatewayInfoId=gatewayId;
    this.iotService.gatewayAgentAddRulePolicy = true;
    this.iotService.viewRulePolicy=true;
    this.gatewaysService.showSeedConfiguration = false;
    // this.router.navigateByUrl('/rules/gateway/' + this.gatewayInfoId);
  }

}


import { Component, OnInit } from '@angular/core';
import { IotService } from '../service/iot.service';
import { Location } from '@angular/common';
import {Router} from '@angular/router';
import { faToggleOff,faToggleOn,faAngleLeft, faAngleRight, faCheck, faCopy} from '@fortawesome/free-solid-svg-icons';
import { SideNavComponent } from '../side-nav/side-nav.component';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EventGroupsService } from '../service/eventGroups.service';

@Component({
  selector: 'app-eventGroup-detail',
  templateUrl: './eventGroup-detail.component.html',
  styleUrls: ['./eventGroup-detail.component.scss']
})
export class EventGroupDetailComponent implements OnInit {

  separatorKeysCodes: number[] = [ENTER, COMMA];

  faToggleOffIcon = faToggleOff;
  faToggleOnIcon = faToggleOn;
  faAngleLeftIcon = faAngleLeft;
  faAngleRightIcon = faAngleRight;
  faCheckIcon = faCheck;
  faCopyIcon = faCopy;
  
  dataSourceDevices: any;
  dataSourceDeviceTemplates: any;
  dataSourceCommandDevices: any;
  dataSourceCommandDeviceTemplates: any;

  displayedColumns: string[] = ['Device', 'EventTemplate'];
  displayedColumns1: string[] = ['Device', 'EventTemplate'];
  displayedColumns2: string[] = ['Device', 'CommandTemplate'];
  displayedColumns3: string[] = ['DeviceTemplate', 'EventTemplate'];
  displayedColumns4: string[] = ['DeviceTemplate', 'CommandTemplate'];
  tags = [];
  icon = this.faToggleOnIcon;
  toggleEnable = 'toggle-on';
  eventGroupActive: boolean = true;

  matVersion: string = '5.1.0';
  breakpoint: number;
  eventGroup;

  currentPage =1;

  updateNameDesc:boolean=true;

  pageSize=5;
  showPagination:boolean=false;

  tagClass = 'tagInput';
  tagList =[];
  supportLanguages = ['en'];
  
  eventGroupId;

  constructor(private router: Router, private route: ActivatedRoute, private iotService:IotService,
    private location:Location,private sideNavComponent:SideNavComponent,private translateService: TranslateService,public eventGroupService:EventGroupsService) { 
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en'); 
  }

   ngOnInit(): void {
    if(window.innerWidth <= 1200 && window.innerWidth >650){
      this.breakpoint=2;
    } 
    else if(window.innerWidth <= 650){
      this.breakpoint=1;
    }
    else{
      this.breakpoint=4;
    }

    this.route.params.subscribe(params => {
      //console.log(params['id']);
      var eventGroupId = params['id'];
      this.eventGroupId = eventGroupId;
      this.loadEventGroup(eventGroupId);
    });
    
  }

  onResize(event) {
    if(event.target.innerWidth <= 1200 && event.target.innerWidth >650){
      this.breakpoint=2;
    } 
    else if(event.target.innerWidth <= 650){
      this.breakpoint=1;
    }
    else{
      this.breakpoint=4;
    }
  }

  enableUpdate(event:any) {
    this.updateNameDesc=false;
  }

  loadEventGroup(eventGroupId) {
    const that = this;
    this.iotService.getCuiObjResponse().getEventSourceWithResourceNames({
      eventSourceId: eventGroupId
    }).then(function(response) {
      console.log(response);
      that.eventGroup = response;
      if(!that.eventGroup.description){
        that.eventGroup.description = [];
        that.eventGroup.description.push({ lang: 'en-us', text: "" });        
      }
      if(typeof response.tags != 'undefined'){
        that.tags = response.tags;
      }
      if(that.eventGroup.sourceDevices){
        that.dataSourceDevices=that.eventGroup.sourceDevices;
      }
      if(that.eventGroup.sourceDeviceTemplates){
        that.dataSourceDeviceTemplates=that.eventGroup.sourceDeviceTemplates;
      }
      if(that.eventGroup.sourceCommandDevices){
        that.dataSourceCommandDevices=that.eventGroup.sourceCommandDevices;
      }
      if(that.eventGroup.sourceCommandDeviceTemplates){
        that.dataSourceCommandDeviceTemplates=that.eventGroup.sourceCommandDeviceTemplates;
      }

      that.sideNavComponent.menuClear();
      that.sideNavComponent.menuChange('Dashboard','sub-type');
      that.sideNavComponent.menuChange('eventGroups.header','sub-type');
      that.sideNavComponent.menuChange(that.eventGroup.name[0].text,'sub-type');
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.eventGroupService.errorMessage = err.responseJSON.apiMessage;
        alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        alert("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  toggleActive(status){
    console.log("status: "+status)
    this.eventGroup.active=status;
      if(this.eventGroup.active==true){
        this.activateEventGroup(this.eventGroup.id);
      }
      else{
        this.deactivateEventGroup(this.eventGroup.id);
      }
  }

  deactivateEventGroup(id: any) {
    const that=this;
    that.iotService.getCuiObjResponse().deactivateEventSource({
      eventSourceId: id
    }).then(function (response) {
      that.loadEventGroup(id);
      console.log("Eventgroup deactivated successfully, Id: ", id);
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.eventGroupService.errorMessage = err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  activateEventGroup(id: any) {
    const that=this;
    that.iotService.getCuiObjResponse().activateEventSource({
      eventSourceId: id
    }).then(function (response){
      that.loadEventGroup(id);
      console.log("Eventgroup activated successfully, Id: ", id);
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.eventGroupService.errorMessage = err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  deleteEventGroup() {
    const that = this;
    that.iotService.getCuiObjResponse().deleteEventSource({
      eventSourceId: that.eventGroupId
    }).then(function(response) {
      console.log("deleted Event Group");
      that.router.navigateByUrl("/eventGroups");
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.eventGroupService.errorMessage = err.responseJSON.apiMessage;
        alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        alert("Something went wrong. Please check chrome console or check with dev team");
      }
    }); 
  }

  toggleIcon(eventGroupId) {
    const that = this;
    if (that.icon === that.faToggleOffIcon) {
      that.icon = that.faToggleOnIcon;
      that.toggleEnable = 'toggle-on';
      that.iotService.getCuiObjResponse().activateEventGroups({
        eventSourceId: eventGroupId
      }).then(function(response) {
        console.log("activate Event Group");
        that.eventGroup.version = parseInt(that.eventGroup.version) + 1;
        that.eventGroup.isActive = true;
      }).fail(function (err){
        if(err.responseJSON.apiStatusCode){
          alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
        } else{
          alert("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    } else {
      that.icon = that.faToggleOffIcon;
      that.toggleEnable = 'toggle-off';
      that.iotService.getCuiObjResponse().deactivateEventGroups({
        eventSourceId: eventGroupId
      }).then(function(response) {
        console.log("deactivate Event Group");
        that.eventGroup.version = parseInt(that.eventGroup.version) + 1;
        that.eventGroup.isActive = false;
      }).fail(function (err){
        if(err.responseJSON.apiStatusCode){
          that.eventGroupService.errorMessage = err.responseJSON.apiMessage;
          alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
        } else{
          alert("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    }
  }

  // add(event: MatChipInputEvent): void {
  //   this.tagClass = 'tagInput';
  //   const input = event.input;
  //   const value = event.value;
  //   var indice =0;
  //   if(this.eventGroup.tags != undefined){
  //     indice = this.eventGroup.tags.indexOf(value);
  //   }else{
  //     indice = -1;
  //   }
  //   if(indice<0){
  //   // Add our fruit
  //   if ((value || '').trim()) {
  //     const that = this;
  //     that.iotService.getCuiObjResponse().createEventSourceTag({
  //       eventSourceId: that.eventGroup.id,
  //       tag: value
  //     }).then(function(response) {
  //       console.log("added tag:"+value+" to Application :"+that.eventGroup.id);
  //         that.eventGroup.version = parseInt(that.eventGroup.version) + 1;
  //         if(that.eventGroup.tags == undefined) {
  //           that.eventGroup.tags = [];
  //         }
  //         const index = that.eventGroup.tags.indexOf(value);
  //         if (index < 0) {
  //           that.eventGroup.tags.push(value);
  //           this.addTag(value);
  //           input.value = '';
  //         }
  //         // Reset the input value
          
  //     }).fail(function (err){
  //       if(err.responseJSON.apiStatusCode){
  //         alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
  //       } else{
  //         alert("Something went wrong. Please check chrome console or check with dev team");
  //       }
  //     });
  //   }
  // }else{
  //     this.tagClass = 'tagInput-duplicate';
  //   }

  // }

  add(event: MatChipInputEvent): void {
    const that=this;
    that.tagClass = 'tagInput';
    const input = event.input;
    const value = event.value;
    const index = that.tags.indexOf(value);
    if (index < 0) {
      if ((value || '').trim()) {
        if (that.tags != undefined && that.tags.includes(value)) {
          if (input) {
            input.value = '';
          }
        } else {
          that.tags.push(value);
          this.addTag(value);
          if (input) {
            input.value = '';
          }
        }
      }
    } else {
      that.tagClass = 'tagInput-duplicate';
    }
  }

  remove(tag: string): void {
    const that = this;
    that.tags.forEach((value, index) => {
      if (value == tag) that.tags.splice(index, 1);
      that.removeTag(tag);
    });
  }

  // remove(tag: string): void {
  //   const that = this;
  //   if(tag != undefined){
  //     that.iotService.getCuiObjResponse().deleteEventSourceTag({
  //       eventSourceId: that.eventGroup.id,
  //       tag: tag
  //     }).then(function (response) {
  //         console.log("removed tag:"+tag+" to Event Group :"+that.eventGroup.id);
  //         that.eventGroup.version = parseInt(that.eventGroup.version) + 1;

  //         const index = that.eventGroup.tags.indexOf(tag);

  //         if (index >= 0) {
  //           that.eventGroup.tags.splice(index, 1);
  //           that.removeTag(tag);
  //         }

  //     }).fail(function (err){
  //       if(err.responseJSON.apiStatusCode){
  //         alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
  //       } else{
  //         alert("Something went wrong. Please check chrome console or check with dev team");
  //       }
  //     });
  //   }
  // }

  addTag(tag) {
    const that = this;
    that.iotService
      .getCuiObjResponse().createEventSourceTag({
        eventSourceId: that.eventGroup.id,
        tag: tag,
      })
      .then(function (response) {
        console.log('Tag added to rule successfully ' + tag);
      })
      .fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          that.eventGroupService.errorMessage = err.responseJSON.apiMessage;
          console.log(
            'Error Occured: Status: ' +
              err.responseJSON.status +
              ' Msg: ' +
              err.responseJSON.apiStatusCode
          );
        } else {
          console.log(
            'Something went wrong. Please check chrome console or check with dev team'
          );
        }
      });
  }

  removeTag(tag) {
    const that = this;
    that.iotService
      .getCuiObjResponse().deleteEventSourceTag({
        eventSourceId: that.eventGroup.id,
        tag: tag,
      })
      .then(function (response) {
        console.log('Tag removed from rule successfully ' + tag);
      })
      .fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          that.eventGroupService.errorMessage = err.responseJSON.apiMessage;
          console.log(
            'Error Occured: Status: ' +
              err.responseJSON.status +
              ' Msg: ' +
              err.responseJSON.apiStatusCode
          );
        } else {
          console.log(
            'Something went wrong. Please check chrome console or check with dev team'
          );
        }
      });
  }
}
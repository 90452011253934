import { Injectable } from '@angular/core';
import { SelectionModel} from '@angular/cdk/collections';
import { IotService } from '../service/iot.service'; 
import { ListCommandsService } from './ListCommands.service';
import { MessageGovernancesService } from '../service/messageGovernances.service';
import { DigitalTwinTemplatesService } from './digitalTwinTemplates.service';
import { DigitalTwinsService } from './digitalTwins.service';
import { DeviceService } from './device.service';

@Injectable({ 
	  providedIn: 'root' 
})

export class CommandsService extends ListCommandsService {
	selection: SelectionModel<String> = new SelectionModel<String>(true, []);
	 constructor(public iotService:IotService, public messageGovernancesService:MessageGovernancesService,
		public digitalTwinTemplatesService:DigitalTwinTemplatesService,
		public digitalTwinsService:DigitalTwinsService,
		public deviceService: DeviceService
		) {
		 super(iotService);
		 this.selection.clear();
		 this.loadSelected();
	 }

	 createNew() {
		 if(this.iotService.newDeviceTemplate == true){
		  this.deviceService.createResource=true;
		  this.deviceService.createCommand=true;
		 }
	  }

	 fnOnSelectionSupport(results){
		 const that = this; 
		 if (this.iotService.digitalTwinTemplate == true) {
			let commands = that.digitalTwinTemplatesService.getCommands();
			commands.push(results);
			that.digitalTwinTemplatesService.setCommands(commands);
		  }
		 else if(that.iotService.createMessageGovernance== true)
		{
			let commands = that.messageGovernancesService.getCommandTemplates();
			commands.push(results);
			that.messageGovernancesService.setCommandTemplates(commands);
		}
		else if(that.iotService.newDeviceTemplate== true)
		{
			let commands = that.digitalTwinTemplatesService.getCommands();
			commands.push(results);
			that.digitalTwinTemplatesService.setCommands(commands);
		}
	 } 

	 fnRemoveSelectionSupport(results){ 
		 const that = this; 
		 if(that.iotService.createMessageGovernance== true)
		 {
			 let commands = that.messageGovernancesService.getCommandTemplates();
			 commands.forEach((value, index) => {
			   if (value.id == results.id) commands.splice(index, 1);});
				 that.messageGovernancesService.setCommandTemplates(commands);
			 
		 } 
		 if (this.iotService.digitalTwinTemplate == true) {
			let commands = that.digitalTwinTemplatesService.getCommands();
			commands.forEach((value, index) => {
			  if (value.id == results.id) commands.splice(index, 1);
			});
			that.digitalTwinTemplatesService.setCommands(commands);
		  }
	 } 

	 showErrorMessage(err){
		 const that=this;
		if (err.responseJSON.apiStatusCode) {
	 
			 var errorStr=err.responseJSON.apiMessage;
			   var error = [];
			   error=errorStr.split(":");
			 if(err.responseJSON.apiStatusCode=="framework:resource:commandTemplate:duplicate"){
			   var commandId = error[1].trim();
			 that.deviceService.duplicateCommandId = commandId;
			 that.deviceService.duplicateCommandFlag = true;
			 that.deviceService.duplicateAttrFlag = false;
			 that.deviceService.duplicateEventFlag=false;
			 that.deviceService.warningMessage="Command template with same name is active. Click here to "
			   console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
			 }
		   } else {
			 console.log("Something went wrong. Please check chrome console or check with dev team");
		   }
	 }

	 loadSelected() {
		this.selection.clear();
		if(this.messageGovernancesService.getCommandTemplates() && 	this.iotService.msgGovLoader==true){
			this.messageGovernancesService.getCommandTemplates().forEach(command=> {
				this.selection.select(command.id);
			});	
	}
	if(this.digitalTwinTemplatesService.getCommands().length>0){
		this.digitalTwinTemplatesService.getCommands().forEach((commands) => {
		  this.selection.select(commands.id);
		});
	  }

	  if(this.digitalTwinsService.getCommands().length>0){
		this.digitalTwinsService.getCommands().forEach((commands) => {
		  this.selection.select(commands.id);
		});
	  }
	}

} 

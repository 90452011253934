/**
 * This is an auto generated code for attributes listing page.
 * Please don't make any change to this file.
 * @version 1.0.0
 * @readonly
 * @author - System generated
 */

import { Component, OnInit, ViewChild, Injector, Inject } from '@angular/core';
import { IotService } from '../service/iot.service';
import { AttributesService } from '../service/attributes.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  faCaretUp,
  faCaretDown,
  faInfoCircle,
  faQuestionCircle,
  faArrowUp,
  faArrowDown,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { BaseListComponent } from '../base-list/baselist.component';
import { EcosystemsService } from '../service/ecosystems.service';

interface TypeChange {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-attributes',
  templateUrl: './attributes.component.html',
  styleUrls: [
    '../base-list/baselist.component.scss',
    './attributes.component.scss',
  ],
})
export class AttributesComponent extends BaseListComponent {
  searchCriteria = 'name';
  displayedColumns: string[] = [
    'name',
    'description',
    'type',
    'value',
    'constant',
    'defaultValue',
    'creation',
    'ACTIVE',
  ];
  sortColumns: string[] = ['name', 'creation'];
  pageTitle = 'attributes.header';
  ascName: boolean = false;
  descName: boolean = false;
  ascCreation: boolean = false;
  descCreation: boolean = false;
  countCallCompleted: boolean = false;
  getCallCompleted: boolean = false;
  showEnableColumn = true;

  constructor(
    router: Router,
    iotService: IotService,
    translateService: TranslateService,
    sideNavComponent: SideNavComponent,
    public attributesService: AttributesService,
  ) {
    super(router, iotService, translateService, sideNavComponent);
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.attributesService.multiSelectRows = this.multiSelect;
    this.attributesService.loadSelected();
    if (!this.showSelectColoumn) {
      this.sideNavComponent.menuClear();
      this.sideNavComponent.menuChange('Dashboard', 'sub-type');
      this.sideNavComponent.menuChange('attributes.header', 'sub-type');
    }
  }
  getFilteredResults(value, columnName, sortOrder) {
    const that = this;
    that.iotService
      .getCuiObjResponse()
      .getAttributes({
        qs: eval(that.fnGetQS(value, columnName, sortOrder)),
      })
      .then(function (response) {
		that.attributesService.populateValues(response);
        that.fnSuccess(response);
        if (
          columnName == 'none' ||
          columnName == '' ||
          columnName == undefined
        ) {
          that.fnResetSortParam('creation', 'desc');
        } else {
          that.fnResetSortParam(columnName, sortOrder);
        }
        that.getCallCompleted = true;
      })
      .fail(function (err) {
        that.fnError(err);
        that.getCallCompleted = true;
      });
  }
  getCount() {
    const that = this;
    that.showPagination = false;
    let value = this.selected;
    that.iotService
      .getCuiObjResponse()
      .countAttributes({
        qs: eval(that.fnGetQS(value, '', '')),
      })
      .then(function (response) {
        that.totalCount = response;
        that.countCallCompleted = true;
        if (that.totalCount > 0) that.showPagination = true;
      })
      .fail(function (err) {
        that.fnError(err);
        that.countCallCompleted = true;
      });
  }
  activateAttributes(resourceId) {
    const that = this;
    that.iotService
      .getCuiObjResponse()
      .activateAttributes({
        attrId: resourceId,
      })
      .then(function (response) {
        that.getFilteredResults(
          that.selected,
          that.sortCriteria,
          that.sortDirection
        );
        that.attributesService.fnResetList();
      })
      .fail(function (err) {
        that.fnError(err);
        that.attributesService.showErrorMessage(err);
      });
  }
  deactivateAttributes(resourceId) {
    const that = this;
    that.iotService
      .getCuiObjResponse()
      .deactivateAttributes({
        attrId: resourceId,
      })
      .then(function (response) {
        that.getFilteredResults(
          that.selected,
          that.sortCriteria,
          that.sortDirection
        );
        that.attributesService.fnResetList();
      })
      .fail(function (err) {
        that.fnError(err);
      });
  }
  deleteAttributes(resourceId) {
    const that = this;
    that.iotService
      .getCuiObjResponse()
      .deleteAttributes({
        attrId: resourceId,
      })
      .then(function (response) {
        that.getCount();
        that.getFilteredResults(
          that.selected,
          that.sortCriteria,
          that.sortDirection
        );
        that.attributesService.fnResetList();
      })
      .fail(function (err) {
        that.fnError(err);
        that.attributesService.showErrorMessage(err);
      });
  }
  viewDetails(selectedId) {
    this.router.navigateByUrl('/attributes/' + selectedId);
    this.attributesService.viewDetails(selectedId);
  }
  createNew() {
    this.attributesService.createNew();
  }
  onSelection(results) {
    this.attributesService.onSelection(results);
  }
  removeSelection(results) {
    this.attributesService.removeSelection(results);
  }
  changeActive(resourceId, isActivate) {
    if (isActivate == 'true') {
      this.deactivateAttributes(resourceId);
    } else {
      this.activateAttributes(resourceId);
    }
  }
  onValueUpdate(results) {
    this.attributesService.onValueUpdate(results);
  }
}

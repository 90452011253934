<div class="public-key-detail" *ngIf="trustedPublicKey">
    <div style="margin-left: -20px;">
        <app-copy-resource-id></app-copy-resource-id>
    </div>
    <h1>{{ 'view.public.key' | translate}}</h1>
    <form >
        <label for="name">  {{ 'name' | translate}}</label>
        <p id="name">{{trustedPublicKey.name}}</p>

        <label for="description"> {{ 'description' | translate}}</label>
        <p id="description">{{trustedPublicKey.description }}</p>

        <label for="Creation"> {{ 'creation' | translate}}</label>
        <p id="Creation">{{trustedPublicKey.creation| date:'MMM d, y, h:mm:ss a' }}</p>

        <label for="algorithm"> {{ 'algorithm' | translate}}</label>
        <p id="algorithm">{{trustedPublicKey.algorithm }}</p>

        <label for="bitLength">{{ 'bitLength' | translate}}</label>
        <p id="bitLength">{{trustedPublicKey.bitLength }}</p>

        <label for="sha256Fingerprint"> {{ 'sha256Fingerprint' | translate}}</label>
        <p id="sha256Fingerprint">{{trustedPublicKey.sha256Fingerprint }}</p>

        <label for="publicKey"> {{ 'text.representation.key' | translate}}</label>
        <p id="publicKey" style="overflow-wrap: anywhere; ">{{trustedPublicKey.text }}</p>

        <label for="KeyFormat"> {{ 'key.format' | translate}}</label>
        <p id="KeyFormat">{{trustedPublicKey.format }}</p>

        <label for="ownerStream"> {{ 'owner.stream.id' | translate}}</label>
        <div *ngIf="trustedPublicKey.owner!=undefined" id="ownerStream">
            <button class="btn btn-secondary" style="margin-top:5px" (click)="viewStream(trustedPublicKey.owner.id)"> {{ 'view.stream' | translate}}</button>
            <!-- <p>{{trustedPublicKey.owner.id }}</p> -->
        </div>

        <p *ngIf="trustedPublicKey.owner===undefined" id="ownerStream"> {{ 'not.assigned' | translate}}</p>

        <label for="Activated">   {{ 'activated' | translate}} </label>
        <div *ngIf="trustedPublicKey.active">
            <button class="toggle-on toggle-switch" title="Disable {{trustedPublicKey.name}}" (click)="disableTrustedPublicKey(trustedPublicKey.id)">
                <fa-icon [icon]="faCheckIcon" class="fa-2x"></fa-icon>
                <div class="toggle-on-btn"></div>
            </button>
        </div>
        <div *ngIf="!trustedPublicKey.active">
            <button class="toggle-off toggle-switch" title="Enable {{trustedPublicKey.name}}" (click)="enablesTrustedPublicKey(trustedPublicKey.id)">
                <div class="toggle-off-btn"></div>
            </button>
        </div>
        <br>
        <br>
    </form>
</div>

<ng-container *ngIf="!trustedPublicKey ">
    <div>
        <br/><br/>
    </div>
    <div class="d-flex justify-content-center pageLoading">
        <div class="spinner-border" role="status">
            <span class="sr-only" id="loading"></span>
        </div>
    </div>
</ng-container>
<main id="stream-details">
  <div class="d-flex justify-content-center loader" *ngIf="!stream">
    <div class="spinner-border" role="status">
      <span class="sr-only" id="loading"></span>
    </div>
  </div>

  <mat-sidenav-container *ngIf="stream">
    <mat-sidenav class="midNav-container" #sidenav mode="side" disableClose="true" opened="true">
      <div class="alert success" *ngIf="streamService.successMessage">
        <h5>
          <fa-icon [icon]="faSuccessIcon"></fa-icon>
          <span>{{streamService.successMessage}}</span> <button class="alert-close-btn">
            <fa-icon (click)=streamService.resetSuccessMessage() [icon]="faCloseIcon"></fa-icon>
          </button>
        </h5>
      </div>
      <div class="alert warning" *ngIf="streamService.warningMessage">
        <h5>
          <fa-icon [icon]="faWarningIcon"></fa-icon>
          <span>{{streamService.warningMessage}}</span>
          <button class="alert-close-btn">
            <fa-icon (click)="streamService.resetWarningMessage()" [icon]="faCloseIcon"></fa-icon>
          </button>
        </h5>
      </div>
      <div class="alert errorMessage" *ngIf="streamService.errorMessage">
        <h5>
          <fa-icon [icon]="faCriticalIcon"></fa-icon>
          <span>{{streamService.errorMessage}}</span>
          <button class="alert-close-btn">
            <fa-icon (click)="streamService.resetErrorMessage()" [icon]="faCloseIcon"></fa-icon>
          </button>
        </h5>
      </div>
      <section class="left-col">
        <div style="margin-left: -5px;">
          <app-copy-resource-id></app-copy-resource-id>
      </div>
        <dl>
          <dt>{{'name' | translate}}</dt>
          <dd>
            <dd>{{stream.name[0].text}}<dd>
          </dd>
        </dl>

        <dl>
          <dt>{{'created' | translate}}</dt>
          <dd>{{stream.creation | date:'mediumDate' }}</dd>

        </dl>
        <hr>
        <dl>
          <dt>{{'stream.type' | translate}}</dt>
          <dd>{{stream.streamType }}</dd>
        </dl>
        <dl *ngIf="stream.streamType!='DEVICE'">
          <dt>{{'application' | translate}}</dt>
          <dd><a class="hyperlink" (click)="getApplicationDetail(stream.ownerId)" title="View Details">{{applicationName
              }}</a></dd>
        </dl>
        <hr>
        <dl *ngIf="stream.streamType=='DEVICE'">
          <dt>{{'device'| translate}}</dt>
          <dd><a class="hyperlink" (click)="getDeviceDetail(stream.ownerId)" title="View Details">{{deviceName }}</a>
          </dd>
        </dl>
        <dl>
          <dt>{{'encryption' | translate}}</dt>
          <dd>{{encryption }}</dd>
        </dl>

        <dl>
          <dt>{{'direction' | translate}}</dt>
          <dd>{{stream.direction }}</dd>
        </dl>



        <hr>

        <dl class="pull-left">
          <dt>{{'Protocol' | translate}}</dt>
          <dd>{{stream.protocolType }}</dd>
        </dl>

        <dl class="pull-right" *ngIf='stream.customProtocolType'>
          <dt>{{'custom.protocol.type' | translate}}</dt>
          <dd>{{getCustomProtocol() }}</dd>
        </dl>
        <br>
        <br>
        <br>
        <br>

        <hr *ngIf=" stream.direction!='ACTIVE_RECEIVING' && stream.direction!='PASSIVE_RECEIVING' && !drsScript ">
        <dl *ngIf=" stream.direction!='ACTIVE_RECEIVING' && stream.direction!='PASSIVE_RECEIVING' && !drsScript ">
          <dt>{{'stream.document.info' | translate}}</dt>
          <dd *ngIf="!streamSer.getDocinfoResponse()">{{'select.docInfo.list' | translate}}</dd>
          <dd *ngIf="streamSer.getDocinfoResponse()">
            <fa-icon [icon]="faCheckIcon"></fa-icon>
            {{streamSer.getDocinfoResponse().supportedDocType}}:{{streamSer.getDocinfoResponse().supportedDocTemplateType}}:{{streamSer.getDocinfoResponse().docTemplateVersion}}
          </dd>
        </dl>
        <dl
          *ngIf="(stream.streamType=='MESSAGING'||stream.streamType=='SYNC')&&(stream.direction!='ACTIVE_RECEIVING' && stream.direction!='PASSIVE_RECEIVING' && !drsScript)">
          <dt>{{'from.code' | translate}}</dt>
          <dd *ngIf="streamSer.getFromCodeValue()">
            <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamSer.getFromCodeValue().code}}
          </dd>
          <dd *ngIf="!streamSer.getFromCodeValue()">
            {{'select.fromcode.desc' | translate}}
          </dd>
          <dt>{{'to.code' | translate}}</dt>
          <dd *ngIf="streamSer.getToCodeValue()">
            <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamSer.getToCodeValue().code}}
          </dd>
          <dd *ngIf="!streamSer.getToCodeValue()">
            {{'select.tocode.desc' | translate}}
          </dd>
        </dl>
        <hr *ngIf=" stream.direction!='ACTIVE_RECEIVING'  && stream.streamType=='SYNC'">
        <dl *ngIf=" stream.direction!='ACTIVE_RECEIVING'  && stream.streamType=='SYNC'">

          <dt style="font-weight: bolder; font-size: 14px;">{{'response.mapping' | translate}}</dt>
          <br>
          <dt>{{'stream.document.info' | translate}}</dt>
          <dd *ngIf="!streamSer.getDocInfo2()">{{'select.docInfo.list' | translate}}</dd>
          <dd *ngIf="streamSer.getDocInfo2()">
            <fa-icon [icon]="faCheckIcon"></fa-icon>
            {{streamSer.getDocInfo2().supportedDocType}}:{{streamSer.getDocInfo2().supportedDocTemplateType}}:{{streamSer.getDocInfo2().docTemplateVersion}}
          </dd>
          <dt>{{'from.code' | translate}}</dt>
          <dd *ngIf="streamSer.getfromCodeValue2()">
            <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamSer.getfromCodeValue2().code}}
          </dd>
          <dd *ngIf="!streamSer.getfromCodeValue2()">
            {{'select.fromcode.desc' | translate}}
          </dd>
          <dt>{{'to.code' | translate}}</dt>
          <dd *ngIf="streamSer.getToCodeValue2()">
            <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamSer.getToCodeValue2().code}}
          </dd>
          <dd *ngIf="!streamSer.getToCodeValue2()">
            {{'select.tocode.desc' | translate}}
          </dd>
        </dl>
        <hr
          *ngIf="stream.protocolSecurityType == 'PUBLICKEY' &&( stream.direction == 'ACTIVE_RECEIVING' || stream.direction == 'PASSIVE_SENDING')">
        <dl
          *ngIf="stream.protocolSecurityType == 'PUBLICKEY' &&( stream.direction == 'ACTIVE_RECEIVING' || stream.direction == 'PASSIVE_SENDING')">

          <dt>{{'ssh.key.pair' | translate}}</dt>
          <dd *ngIf="!streamSer.getSshKeyPairObject()">{{'select.sshkey.from.list' | translate}}</dd>
          <dd *ngIf="streamSer.getSshKeyPairObject()">
            <fa-icon [icon]="faCheckIcon"></fa-icon>{{streamSer.getSshKeyPairObject().name}}
          </dd>
        </dl>
        <hr *ngIf="stream.protocolSecurityType == 'OAUTH2' ">
        <dl *ngIf="stream.protocolSecurityType == 'OAUTH2' ">

          <dt>{{'token' | translate}}</dt>
          <dd *ngIf="!streamSer.getOAuthTokenObject()">{{'select.token.list' | translate}}</dd>
          <dd *ngIf="streamSer.getOAuthTokenObject()">
            <fa-icon [icon]="faCheckIcon"></fa-icon>{{streamSer.getOAuthTokenObject().name[0].text}}
          </dd>
        </dl>
        <hr *ngIf="stream.protocolSecurityType == 'MUAUTH' ">
        <dl *ngIf="stream.protocolSecurityType == 'MUAUTH' ">

          <dt>{{'mutual.auth.keyBundle' | translate}}</dt>
          <dd *ngIf="!streamSer.getMutualAuthObject()">{{'select.keybundle.from.list' | translate}}</dd>
          <dd *ngIf="streamSer.getMutualAuthObject()">
            <fa-icon [icon]="faCheckIcon"></fa-icon>{{streamSer.getMutualAuthObject().name}}
          </dd>
        </dl>

        <div class="button-row" style="margin-left:10px;">
          
            <button *ngIf="streamLockState == 'UNLOCKED'" class="btn btn-secondary" (click)="lockStream(stream.id)">
              <fa-icon [icon]="faLockIcon"></fa-icon> {{ 'lock' | translate}}
            </button>
            <button *ngIf="streamLockState == 'LOCKED'" class="btn btn-secondary" (click)="unlockStream(stream.id)">
              <fa-icon [icon]="faUnLockIcon"></fa-icon> {{ 'unlock' | translate}}
            </button>
            <button class="btn btn-delete pull-right" style="margin-right:15px;" (click)="deleteStream(stream.id)">{{ 'delete' | translate}}</button>
            <button class="btn btn-secondary pull-right"  style="margin-right:5px;" (click)="auditStream()">{{ 'audit' | translate}}</button>
        </div>

      </section>


      

    </mat-sidenav>

    <article class="stream-data">
      <form>
        <div *ngIf="!auditFlag"  style="margin-left: 5px; margin-right: 3px;">
          <header>
            <h2>{{ 'security' | translate }}</h2>
          </header>
          <label for="auth-type">{{ 'auth.type' | translate }}</label>

          <ng-container *ngIf="disableSecurity">
            <select name="auth-type" [disabled]=true [(ngModel)]="auth" (change)="changeAuthType()">
              <option [value]="item.auth" *ngFor="let item of authTypes">
                {{item.auth}}
              </option>
            </select>

            <br>

            <div *ngIf="stream.protocolSecurityType=='X509'" class="certificate-data">

              <form>
                <label for="certificate-data">{{'certificate.pem.content' | translate}}</label>
                <textarea rows="13" [disabled]=true placeholder="required" id="certificate-data" [(ngModel)]="X509"
                  [ngModelOptions]="{standalone: true}" aria-label="Certificate-data"></textarea>

              </form>
              <p *ngIf="!showCertificateExample" class="blue-highlight" (click)="toggleExample()">{{'example.pem.certificate' | translate}}
                <fa-icon [icon]="faAngleDown"></fa-icon>
              </p>
              <p *ngIf="showCertificateExample" class="blue-highlight" (click)="toggleExample()">{{'example.pem.certificate' | translate}}
                <fa-icon [icon]="faAngleUp"></fa-icon>
              </p>

              <p *ngIf="showCertificateExample">-----BEGIN CERTIFICATE-----
                MIIDXTCCAkWgAwIBAgIJAJC1HiIAZAiIMA0GCSqGSIb3Df
                BAYTAkFVMRMwEQYDVQQIDApTb21lLVN0YXRlMSEwHwYDVx aWRnaXRzIFB0eSBMdGQwHhcNMTExMjMxMDg1OTQ0WhcNMT
                jyzfN746vaInA1KxYEeI1Rx5KXY8zIdj6a7hhphpj2E04 C3Fayua4DRHyZOLmlvQ6tIChY0ClXXuefbmVSDeUHwc8Yu
                B7xxt8BVc69rLeHV15A0qyx77CLSj3tCx2IUXVqRs5mlSbvA== -----END CERTIFICATE-----
              </p>
            </div>

            <ng-container
              *ngIf="stream.protocolSecurityType == 'PUBLICKEY' && stream.direction!='ACTIVE_RECEIVING' && stream.direction!='PASSIVE_SENDING'">

              <label for="name">{{ 'name' | translate }}</label>
              <input id="name" name="publicKeyName" [disabled]=true [(ngModel)]="publicKeyName" type="text"
                aria-label="User Name">

              <label for="secret">{{ 'description' | translate }}</label>
              <input id="description" name="publicKeyDesc" [disabled]=true [(ngModel)]="publicKeyDesc" type="text"
                aria-label="Description">

              <label for="public-key-data">{{'public.secsh.format' | translate}}</label>
              <div class="public-key-data">
                <textarea rows="13" placeholder="required" [disabled]=true [(ngModel)]="publicKeyText"
                  name="publicKeyText" aria-label="Certificate-data"></textarea>
                <label for="example">{{'key.example.secsh' | translate}} </label>
                <p style="overflow-wrap: anywhere; ">---- BEGIN SSH2 PUBLIC KEY ----
                  AAAAB3NzaC1kc3MAAACBAPY8ZOHY2yFSJA6XYC9HRwNHxaehvx5wOJ0rzZdzoSOXxbETW6
                  ToHv8D1UJ/z+zHo9Fiko5XybZnDIaBDHtblQ+Yp7StxyltHnXF1YLfKD1G4T6JYrdHYI14
                  Om1eg9e4NnCRleaqoZPF3UGfZia6bXrGTQf3gJq2e7Yisk/gF+1VAAAAFQDb8D5cvwHWTZ
                  DPfX0D2s9Rd7NBvQAAAIEAlN92+Bb7D4KLYk3IwRbXblwXdkPggA4pfdtW9vGfJ0/RHd+N
                  jB4eo1D+0dix6tXwYGN7PKS5R/FXPNwxHPapcj9uL1Jn2AWQ2dsknf+i/FAAvioUPkmdMc
                  0zuWoSOEsSNhVDtX3WdvVcGcBq9cetzrtOKWOocJmJ80qadxTRHtUAAACBAN7CY+KKv1gH
                  pRzFwdQm7HK9bb1LAo2KwaoXnadFgeptNBQeSXG1vO+JsvphVMBJc9HSn24VYtYtsMu74q
                  XviYjziVucWKjjKEb11juqnF0GDlB3VVmxHLmxnAz643WK42Z7dLM5sY29ouezv4Xz2PuM ch5VGPP+CDqzCM4loWgV ---- END
                  SSH2 PUBLIC KEY ----
                  <label for="example">{{'key.openSSH.format' | translate}} </label>
                </p>
                <p style="overflow-wrap: anywhere; ">{{'encrypted.key' | translate}}
                </p>
              </div>
            </ng-container>



            <div class="reset_button"
              *ngIf="(stream.direction!='ACTIVE_RECEIVING' && stream.direction!='PASSIVE_RECEIVING') && stream.protocolSecurityType == 'BASIC'">
              <button class="btn btn-secondary" (click)="resetPassword(stream.id)">
                {{ 'reset.password' | translate}}
              </button>
            </div>
            <div class="reset_button" *ngIf="resetPasswordResult">
              <p style="font-weight:bold"> {{resetPasswordResult}}</p>
              <button class="btn btn-secondary" [cdkCopyToClipboard]="getStreamCredentials()">
                <fa-icon [icon]="faCopyIcon"></fa-icon> {{'cdkCopyToClipboard' | translate}}
              </button>
              <br>
              <br>
            </div>
            <div style="margin-right: -10px;margin-left: -10px;">
            <ng-container *ngIf="auth=='OAuth Token'">
              <app-oAuthTokens [pageSize]="5" [showControlBar]=false [displayedColumns]="['ADD','name']"
                [showSelectColoumn]=true [showEnableColumn]=true multiSelect="false" [showHeaderToolTips]=false></app-oAuthTokens>
              <p *ngIf="tokenError&&!streamSer.getOAuthTokenObject()" style="color: red;">{{tokenError}}</p>
              <br>
              <br>
            </ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="!disableSecurity">
            <select name="auth-type" [(ngModel)]="auth" (change)="changeAuthType()">
              <option [value]="item.auth" *ngFor="let item of authTypes">
                {{item.auth}}
              </option>
            </select>
            <ng-container
              *ngIf="stream.protocolSecurityType == 'BASIC'||stream.protocolSecurityType == 'S3' &&( stream.direction == 'ACTIVE_RECEIVING' || stream.direction == 'PASSIVE_SENDING')">


              <label for="user-name">{{ 'user.name' | translate }}</label>
              <input id="name" placeholder="Hidden for security reason" [(ngModel)]="usernameValue" name="usernameValue"
                type="text" (keyup)="resetUsername($event)" aria-label="User Name">
              <p *ngIf="userNameError" style="color: red;">{{userNameError}}</p>


              <label for="password">{{ 'password' | translate }}</label>
              <input id="password" placeholder="Hidden for security reason" [(ngModel)]="passwordValue"
                name="passwordValue" type="password" (keyup)="resetPasswordData($event)" aria-label="Password">
              <p *ngIf="passwordError" style="color: red;">{{passwordError}}</p>
            </ng-container>

            <ng-container
              *ngIf="stream.protocolSecurityType == 'PUBLICKEY' &&( stream.direction == 'ACTIVE_RECEIVING' || stream.direction == 'PASSIVE_SENDING')">


              <label for="name">{{ 'name' | translate }}</label>
              <input id="name" placeholder="Hidden for security reason" type="text" value="" [(ngModel)]="sshName"
                name="sshName" aria-label="User Name">

              <!-- <p *ngIf="sshKeyError" style="color: red;">{{sshKeyError}}</p> -->
              <div *ngIf="sshLoaded && stream.protocolSecurityType == 'PUBLICKEY'">
                <app-sshKeyPairs [pageSize]="5" [showControlBar]=false
                  [displayedColumns]="['ADD','name','description','algorithm']" [showSelectColoumn]=true
                  [showEnableColumn]=true multiSelect="false" [showHeaderToolTips]=false>
                </app-sshKeyPairs>
              </div>



              <br>
            </ng-container>
            <div style="margin-right: -12px;margin-left: -12px;">
            <ng-container *ngIf="auth=='OAuth Token'">

              <app-oAuthTokens *ngIf=tokenLoaded [pageSize]="5" [showControlBar]=false
                [displayedColumns]="['ADD','name']" [showSelectColoumn]=true [showEnableColumn]=true
                multiSelect="false" [showHeaderToolTips]=false></app-oAuthTokens>
              <p *ngIf="tokenError&&!streamSer.getOAuthTokenObject()" style="color: red;">{{tokenError}}</p>
            </ng-container>
            </div>
            <ng-container *ngIf="stream.protocolSecurityType == 'MUAUTH'">

              <div>
                <mat-checkbox #c (click)="reverseChaining(!c.checked)">{{'reverse.certificate.order' | translate}}</mat-checkbox>
              </div>
              <div style="margin-right: -12px;margin-left: -12px;">
              <app-keyBundles *ngIf="mutualAuthLoaded" [pageSize]="5" [showControlBar]=false
                [displayedColumns]="['ADD','name']" [showSelectColoumn]=true [showEnableColumn]=true
                multiSelect="false" [showHeaderToolTips]=false>
              </app-keyBundles>
              </div>
              <p *ngIf="mutualAuthError&&!streamSer.getMutualAuthObject()" style="color: red;">{{mutualAuthError}}</p>
            </ng-container>

            <div class="reset_button">
              <button class="btn btn-secondary" (click)="updateSecurity(stream.id)">
                {{ 'update.security' | translate}}
              </button>
            </div>
          </ng-container>

          <ng-container
            *ngIf="(docinfo||drsScript)&& (stream.direction!='ACTIVE_RECEIVING' && stream.direction!='PASSIVE_RECEIVING')">
            <header>
              <h2>{{ 'document.info' | translate }}</h2>
            </header>
            <label for="doc-type">{{ 'doc.type' | translate }}</label>
            <select name="doc-type" [(ngModel)]="doc" (change)="changeDocType()">
              <option [value]="item.doc" *ngFor="let item of docTypes">
                {{item.doc}}
              </option>
            </select>
          </ng-container>

          <ng-container
            *ngIf="docinfo&& stream.direction!='ACTIVE_RECEIVING' && stream.direction!='PASSIVE_RECEIVING'&&!drsScript">
            <div style="margin-left: -15px;">
            <app-documentInfo [pageSize]=" 5 " [showControlBar]=true
              [displayedColumns]="[ 'ADD', 'supportedDocTemplateType', 'docTemplateVersion', 'ACTIVE'] "
              [showSelectColoumn]=true [showEnableColumn]=true multiSelect="false" [showSearch]=true [showNew]=false [showHeaderToolTips]=false> 
            </app-documentInfo>
          </div>
            <ng-container *ngIf="stream.streamType=='MESSAGING'">
              <!-- <label for="from-code">{{ 'from.code' | translate }}</label> -->
              <app-fromTPCodes *ngIf="fromTpCodeLoaded" [pageSize]="5" [showControlBar]=true
                [displayedColumns]="['ADD','code','qualifier','ACTIVE']" [showSelectColoumn]=true
                [showEnableColumn]=true multiSelect="false" [showHeaderToolTips]=false></app-fromTPCodes>

              <!-- <label for="to-code">{{ 'to.code' | translate }}</label> -->
              <app-toTPCodes *ngIf='toTpCodeLoaded' [pageSize]="5" [showControlBar]=true
                [displayedColumns]="['ADD','code','qualifier','ACTIVE']" [showSelectColoumn]=true
                [showEnableColumn]=true multiSelect="false" [showHeaderToolTips]=false></app-toTPCodes>
            </ng-container>

            <ng-container *ngIf="stream.streamType=='SYNC' && stream.direction =='ACTIVE_SENDING'">


              <!-- <label for="from-code">{{ 'from.code' | translate }}</label> -->
              <app-fromTPCodes *ngIf="fromTpCodeLoaded" [pageSize]="5" [showControlBar]=true
                [displayedColumns]="['ADD','code','qualifier','ACTIVE']" [showSelectColoumn]=true
                [showEnableColumn]=true multiSelect="false" [instance]="1" [showHeaderToolTips]=false></app-fromTPCodes>

              <!-- <label for="to-code">{{ 'to.code' | translate }}</label> -->
              <app-toTPCodes *ngIf='toTpCodeLoaded' [pageSize]="5" [showControlBar]=true
                [displayedColumns]="['ADD','code','qualifier','ACTIVE']" [showSelectColoumn]=true
                [showEnableColumn]=true multiSelect="false" [instance]="1" [showHeaderToolTips]=false></app-toTPCodes>

              <br>
              <br>
              <!-- <label for="document-info">{{ 'response Mapping' | translate }}</label> -->

              <app-documentInfo *ngIf="docInfoLoaded2" [pageSize]="5" [showControlBar]=true
                [displayedColumns]="['ADD','supportedDocTemplateType','docTemplateVersion','ACTIVE']"
                [showSelectColoumn]=true [showEnableColumn]=true multiSelect="false" [instance]="2" [showHeaderToolTips]=false>

              </app-documentInfo>
              <!-- <label for="from-code">{{ 'from.code' | translate }}</label> -->
              <app-fromTPCodes *ngIf="fromTpCodeLoaded2" [pageSize]="5" [showControlBar]=true
                [displayedColumns]="['ADD','code','qualifier','ACTIVE']" [showSelectColoumn]=true
                [showEnableColumn]=true multiSelect="false" [instance]="2" [showHeaderToolTips]=false></app-fromTPCodes>

              <!-- <label for="to-code">{{ 'to.code' | translate }}</label> -->
              <app-toTPCodes *ngIf='toTpCodeLoaded2' [pageSize]="5" [showControlBar]=true
                [displayedColumns]="['ADD','code','qualifier','ACTIVE']" [showSelectColoumn]=true
                [showEnableColumn]=true multiSelect="false" [instance]="2" [showHeaderToolTips]=false></app-toTPCodes>

              <label id="sync">{{'sync.authorization.type' | translate}}</label>
              <input for="sync" name="syncAuthType" [(ngModel)]="syncAuthType" style="width: 50%;">
              <br>
            </ng-container>

            <div class="reset_button">
              <button *ngIf="docinfo&&(stream.direction!='ACTIVE_RECEIVING' && stream.direction!='PASSIVE_RECEIVING')"
              class="btn btn-secondary" (click)="updateDocumentInfo(stream.id)">{{'update.document.info' | translate}}
              </button>
              <br>
              <br>
            </div>

          </ng-container>


          <div *ngIf=" drsScript && stream.direction!='ACTIVE_RECEIVING' && stream.direction!='PASSIVE_RECEIVING'">


            <div *ngIf="drsScript">
              <label for="stream-name">{{ 'description' | translate }}</label>
              <input placeholder="required" name="drsDescription" [(ngModel)]="drsDescription" type="text" value=""
                aria-label="Description">
              <br>
              <app-ace></app-ace>
              <br>
              <br>
              <button class="btn btn-secondary" style="margin-left: -5px;" (click)="UpdateDRS(stream.id)">{{'update.document.recognition' | translate}}
              </button>

              <button class="btn btn-delete" (click)="deleteDRS(stream.id)">{{'delete.document.recognition' | translate}}
              </button>
              <br>
              <br>
            </div>

          </div>




          <ng-container *ngIf="stream.deliveryConfig&&stream.deliveryConfig.destinationURI!=''">
            <header>
              <h2>{{ 'delivery.script' | translate }}</h2>
            </header>

            <label for="stream-name">{{ 'destination.url' | translate }}</label>
            <input placeholder="required" id="stream-name" type="text" name="stream.deliveryConfig.destinationURI"
              [(ngModel)]="stream.deliveryConfig.destinationURI" (keyup)="deliveryUrl($event)"
              aria-label="Destination Url"><br><br>
            <ng-container *ngIf="streamSer.viewDeliveryScript">
              <app-ace></app-ace>
              <label for="code-example">{{ 'code.example' | translate }}</label>
              <select name="code-example" [(ngModel)]="streamSer.example" (change)="changeCodeExample()">
                <option [value]="item.example" *ngFor="let item of streamSer.codeExamples">
                  {{item.example}}
                </option>
              </select>
              <br><br>
              <ng-container *ngIf="streamSer.example=='Active Receiving Sync Loop back request'">
                <app-ace></app-ace>
              </ng-container>
              <ng-container *ngIf="streamSer.example=='Active Receiving Sync Post with request and response'">
                <app-ace></app-ace>
              </ng-container>
              <ng-container *ngIf="streamSer.example=='Active Receiving Post with dynamic header values'">
                <app-ace></app-ace>
              </ng-container>
              <ng-container *ngIf="streamSer.example=='Active Receiving Post with dynamic path params'">
                <app-ace></app-ace>
              </ng-container>
              <ng-container *ngIf="streamSer.example=='Passive Sending GET with dynamic path params'">
                <app-ace></app-ace>
              </ng-container>
              <br>
            </ng-container>

            <header>
              <h2>{{ 'retry.settings' | translate }}</h2>
            </header>
            <label for="retry-attempts">{{ 'retry.attempts' | translate }}</label>

            <input id="retry-attempts" [(ngModel)]="stream.deliveryConfig.retryInterval" type="number"
              name="retryAttempts" style="margin-bottom:5px">
            <label for="delay-retry-attempts">{{ 'delay.retry.attempts' | translate }}</label>

            <input id="delay-retry-attempts" type="number" name="delayRetryAttempts"
              [(ngModel)]="stream.deliveryConfig.retryNumber"> {{ 'seconds' | translate }}

            <label for="retry-timeout">{{ 'retry.timeout' | translate }}</label>

            <input id="retry-timeout" type="number" name="retryTimeout"
              [(ngModel)]="stream.deliveryConfig.retryTimeOut"> {{
            'seconds' | translate }}

            <label for="delivery-enabled">{{ 'delivery.enabled' | translate }}</label>
            <ng-container *ngIf="stream.deliveryConfig.deliveryEnabled == true">
              <mat-checkbox [checked]='true' #c (click)="deliveryEnabled(!c.checked)"></mat-checkbox>
            </ng-container>
            <ng-container *ngIf="stream.deliveryConfig.deliveryEnabled == false">
              <mat-checkbox #c (click)="deliveryEnabled(!c.checked)"></mat-checkbox>
            </ng-container>
            <div class="reset_button">
              <button class="btn btn-secondary" (click)="updateDelivery(stream.id)">
                {{ 'update.delivery' | translate}}
              </button>
            </div>
          </ng-container>
          <ng-container *ngIf="stream.deliveryConfig &&  stream.deliveryConfig.deliverySchedule">
            <header>
              <h2>{{ 'delivery.scheduled' | translate }}</h2>
            </header>
            <p *ngIf="deliveryScheduleError" style="color: red;">{{deliveryScheduleError}}</p>
            <label for="delivery-schedule">{{ 'delivery.schedule' | translate }}</label>

            <input id="delivery-schedule" [(ngModel)]="stream.deliveryConfig.deliverySchedule" type="text"
              name="cronValue">{{'cron.format' | translate}}
            <div class="reset_button">
              <button class="btn btn-secondary" (click)="updateDeliverySchedule(stream.id)">
                {{ 'update.schedule' | translate}}
              </button>
            </div>
          </ng-container>
          <p style="margin-left: -2px;"   *ngIf="toggleSettingFlag" class="blue-highlight" (click)="logMode()">
            {{'toggle.advanced.settings' | translate}}
          </p>

          <div *ngIf="showLogLevel" class="form-group" style="margin-left: 15px;">
            <div class="row">

              <div class="component">
                <label for="log-mode">{{ 'log.mode' | translate }}</label>
                <select name="mode" [(ngModel)]="stream.streamConfiguration.logMode">
                  <option [value]="item.mode" *ngFor="let item of logModes">
                    {{item.mode}}
                  </option>
                </select>
              </div>

              <div class="component">
                <label for="log-expiration">{{ 'log.expiration' | translate }}</label>
                <select name="expiration" [(ngModel)]="expiration">
                  <option [value]="item.expiration" *ngFor="let item of logExpirations">
                    {{item.expiration}}
                  </option>
                </select>
              </div>

              <div class="component">
                <label for="stream-quota">{{ 'stream.quota' | translate }}</label>
                <input [(ngModel)]="stream.streamConfiguration.quota" name="quota" id="stream-quota" type="text">
              </div>
            </div>
            <div class="row">
              <div class="component">
                <label for="par-obfuscation">{{ 'stream.par.obfuscation' | translate }}</label>
                <select name="obfuscation" [disabled]=true [(ngModel)]="obfuscation">
                  <option [value]="item.obfuscation" *ngFor="let item of parObfuscation">
                    {{item.obfuscation}}
                  </option>
                </select>
              </div>

              <div class="component">
                <label for="par-compression">{{ 'stream.par.compression' | translate }}</label>
                <select name="compression" [disabled]=true [(ngModel)]="compression">
                  <option [value]="item.compression" *ngFor="let item of parCompression">
                    {{item.compression}}
                  </option>
                </select>
              </div>

              <div class="component">
                <label for="stream-throughput">{{ 'stream.throughput' | translate }}</label>
                <select name="throughput" [disabled]=true [(ngModel)]="throughput">
                  <option [value]="item.throughput" *ngFor="let item of throughputStratergy">
                    {{item.throughput}}
                  </option>
                </select>

              </div>
            </div>

            <div class="row">
              <div class="component">
                <label for="ack-strategy">{{ 'stream.ack.strategy' | translate }}</label>
                <select name="strategy" [disabled]=true [(ngModel)]="strategy">
                  <option [value]="item.strategy" *ngFor="let item of ackStrategy">
                    {{item.strategy}}
                  </option>
                </select>
              </div>
              <div class="component">
                <label for="par-persistence">{{ 'stream.par.persistence' | translate }}</label>
                <select name="persistence" [disabled]=true [(ngModel)]="persistence">
                  <option [value]="item.persistence" *ngFor="let item of parPersistence">
                    {{item.persistence}}
                  </option>
                </select>
              </div>
              <div class="component">
                <label for="par-digest">{{ 'stream.par.digest' | translate }}</label>
                <select name="digest" [disabled]=true [(ngModel)]="digest">
                  <option [value]="item.digest" *ngFor="let item of parDigest">
                    {{item.digest}}
                  </option>
                </select>
              </div>
            </div>
            
          </div>
          <div *ngIf="showLogLevel" class="reset_button">
            <button class="btn btn-secondary" (click)="updateStreamConf(stream.id)">
              {{ 'update' | translate}}
            </button>
          </div>
        </div>
        <ng-container *ngIf="streamId">
          <div style="height: 110vh;">
            <app-audits *ngIf="auditFlag && auditRefreshFlag && iotService.auditScriptFlag==false" [showNew]="false"
              [showControlBar]=false [displayedColumns]="['creation','requestorId','actionType','id']"
              showSelectColoumn=false showEnableColumn=false addnQueryParam=[resourceId::{{streamId}}] [showHeaderToolTips]=false></app-audits>
            <app-audits *ngIf="auditFlag && !auditRefreshFlag && iotService.auditScriptFlag==false" [showNew]="false"
              [showControlBar]=false [displayedColumns]="['creation','requestorId','actionType','id']"
              showSelectColoumn=false showEnableColumn=false addnQueryParam=[resourceId::{{streamId}}] [showHeaderToolTips]=false></app-audits>
            <div class="close-btn" *ngIf="auditFlag && iotService.auditScriptFlag==false">
              <br>
              <button (click)="closeAudit()">{{'close' | translate}}</button>
            </div>
            <div *ngIf="iotService.auditScriptFlag" style="margin: 0 5px 0 5px;">
              <app-ace></app-ace>
            </div>
            <div *ngIf="iotService.auditScriptFlag" class="streamAudit">
              <button class="goBack" mat-raised-button color="primary" (click)="goBackAuditList()">{{ 'go.back' |
                translate}}</button>
              <br>
            </div>

          </div>

          <br>
        </ng-container>

      </form>
    </article>

  </mat-sidenav-container>
</main>
<!-- <ng-container *ngIf="!docinfo">
  <div>
      <br/><br/>
  </div>
  <div class="d-flex justify-content-center pageLoading">
      <div class="spinner-border" role="status">
          <span class="sr-only" id="loading"></span>
      </div>
  </div>
</ng-container -->
<div class="tradingPartnerCodeData" *ngIf="tradingPartnerCode">
    <div class=form>
        <label for="code"> {{ 'code' | translate}}</label>
        <input placeholder="required" [(ngModel)]="tradingPartnerCode.code" (click)="resetData()" id="code" type="text" aria-label="code">
        <p *ngIf="codeError" style="color: red;">{{codeError}}</p>

        <label for="qualifier"> {{ 'qualifier' | translate}}</label>
        <textarea rows="2" [(ngModel)]="tradingPartnerCode.qualifier" placeholder="optional" id="qualifier" aria-label="qualifier"></textarea>

        <label for="description"> {{ 'description' | translate}}</label>
        <textarea *ngIf="tradingPartnerCode.description" rows="2" [(ngModel)]="tradingPartnerCode.description[0].text" placeholder="optional" id="description" aria-label="Description"></textarea>
        <textarea *ngIf="!tradingPartnerCode.description" rows="2" placeholder="optional" id="description" aria-label="Description">-</textarea>


        <label>{{'enabled'|translate}}</label>
        <div *ngIf="tradingPartnerCode.status=='ACTIVE'">
            <button class="toggle-on toggle-switch" (click)="toggleActive()">
                <fa-icon [icon]="faCheckIcon" class="fa-2x"></fa-icon>
                <div class="toggle-on-btn"></div>
            </button>
        </div>

        <div *ngIf="tradingPartnerCode.status=='INACTIVE'">
            <button class="toggle-off toggle-switch" (click)="toggleActive()">
                <div class="toggle-off-btn"></div>
            </button>
        </div>

        <br>
        <div class="button-row pull-right">
            <button class="btn btn-secondary"  (click)="goBack()">{{ 'go.back' | translate}}</button>   
            <button class="btn btn-primary"  (click)="save()">{{ 'save' | translate}}</button>
         
        </div>
       <br>
        <br>
    </div>
</div>

<ng-container *ngIf="!tradingPartnerCode">
    <div>
        <br/><br/>
    </div>
    <div class="d-flex justify-content-center pageLoading">
        <div class="spinner-border" role="status">
            <span class="sr-only" id="loading"></span>
        </div>
    </div>
</ng-container>
<header class="table-header">
    <h2>{{ 'select_geofences' | translate }}</h2>
</header>
<div class="control-bar">
    <input placeholder="search" type="text" (keyup)="onGeofenceSearch($event)">
    <button mat-raised-button (click)="createGeofence()" aria-label="Create new geofence">New</button>
</div>
<div class="table-container">
    <ngx-skeleton-loader
    *ngIf="!dataSource"
    count="5"
    [theme]="{'border-radius': '0px',
      height: '48px',
      width: '100%',
      margin: '1px',
      position: 'relative',
      top: '20px'
    }"></ngx-skeleton-loader>
    <table mat-table [dataSource]="dataSource">

        <ng-container matColumnDef=" ">
            <th mat-header-cell *matHeaderCellDef class="checkbox-cell addCol"> </th>
            <td mat-cell *matCellDef="let geofence" class="checkbox-cell addCol">
                <!-- <mat-checkbox #checkbox [disabled]="!geofence.isActive" (click)="selectGeofence(checkbox,geofence.id)" [checked]="selection.isSelected(geofence)" class="example-margin"></mat-checkbox> -->
                <ng-container *ngIf="geofence.isActive">
                    <div class="btn-col">
                        <ng-container *ngIf="selection.isSelected(geofence)">
                            <button class="selected btn btn-micro btn-secondary" (click)="selectGeofence(false,geofence.id)"><fa-icon [icon]="faCheckIcon"></fa-icon></button>&nbsp;
                        </ng-container>
                        <ng-container *ngIf="!selection.isSelected(geofence)">
                            <button class="select btn btn-micro btn-secondary" (click)="selectGeofence(true,geofence.id)">Add</button>&nbsp;
                        </ng-container>
                    </div>
                </ng-container>
                <ng-container *ngIf="!geofence.isActive">
                    <div class="btn-col">
                        <ng-container *ngIf="selection.isSelected(geofence)">
                            <button class="btn btn-micro btn-secondary" [disabled]="geofence.isActive"><fa-icon [icon]="faCheckIcon"></fa-icon></button>&nbsp;
                        </ng-container>
                        <ng-container *ngIf="!selection.isSelected(geofence)">
                            <button class="btn btn-micro btn-secondary" [disabled]="!geofence.isActive">Add</button>&nbsp;
                        </ng-container>
                    </div>
                </ng-container>
            </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef>{{'name' | translate}} </th>
            <td mat-cell *matCellDef="let geofence" class="name-cell">
                {{geofence.name[0].text}}
            </td>
        </ng-container>
     
           <!-- Description Column -->
           <ng-container matColumnDef="Description">
            <th mat-header-cell *matHeaderCellDef> {{'description' | translate}} </th>
            <ng-container *matCellDef="let geofence">
                <td mat-cell *ngIf="geofence.description" class="name-cell">
                    {{geofence.description[0].text}}
                </td>
                <td mat-cell *ngIf="!geofence.description" class="name-cell">-</td>
            </ng-container>
        </ng-container>

         <!-- Tags Column -->
         <ng-container matColumnDef="Tags">
            <th mat-header-cell *matHeaderCellDef> {{'tags' | translate}} </th>
            <ng-container *matCellDef="let geofence">
                <td mat-cell *ngIf="geofence.tags" class="name-cell">{{geofence.tags}}
                </td>
                <td mat-cell *ngIf="!geofence.tags" class="name-cell">-</td>
            </ng-container>
        </ng-container>


        <ng-container matColumnDef="Enabled">
            <th mat-header-cell *matHeaderCellDef> {{'enabled' | translate}} </th>
            <ng-container *matCellDef="let geofence">
    
                <td mat-cell *ngIf="geofence.isActive" class="tag-cell">
                    
                    <button class="toggle-on toggle-switch" (click)="disableGeofence(geofence,geofence.id)" title="Disable {{geofence.name[0].text}}">
                        <fa-icon [icon]="faCheckIcon" class="fa-2x"></fa-icon>
                        <div class="toggle-on-btn"></div>
                    </button>
                    
                </td>
                <td mat-cell *ngIf="!geofence.isActive" class="tag-cell">
                    
                    <button class="toggle-off toggle-switch" (click)="enableGeofence(geofence.id)" title="Enable {{geofence.name[0].text}}">
                        <div class="toggle-off-btn"></div>
                    </button>
                    
                </td>
            </ng-container>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let geofence; columns: displayedColumns;"></tr>
    </table>
</div>
<!-- OT Pagination component -->
<div class="table-container">
    <app-pagination *ngIf="showPagination" [count]="geofenceCount" [pageSize]="pageSize" [currentPage]="currentPage" (currentPageChange)="onPageChange($event)"></app-pagination>
</div>
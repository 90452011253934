import { Component, OnInit } from '@angular/core';
import { IotService } from '../service/iot.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GatewaysService } from '../service/gateways.service';

@Component({
  selector: 'app-create-log-request',
  templateUrl: './create-log-request.component.html',
  styleUrls: ['./create-log-request.component.scss']
})
export class CreateLogRequestComponent implements OnInit {

  logRequestInfo;
  logLevels = [
    { logLevel: 'ALL' },
    { logLevel: 'INFO' },
    { logLevel: 'DEBUG' },
    { logLevel: 'ERROR' },
    { logLevel: 'FATAL' },
    { logLevel: 'WARN' },
    { logLevel: 'TRACE' }
  ];


  startTime: any;
  startDate: any;
  endTime: any;
  endDate: any;
  logLevel = 'ALL';
  // gatewayLogRequest.logLevel = "ALL";
  // gatewayLogRequest.noOfLogRecords = 10000;

  supportLanguages = ['en'];
  authorityColumns = ["Name", "Type"];
  constructor(public iotService: IotService, private translateService: TranslateService,
    private router: Router, public gatewaysService: GatewaysService) {
    this.iotService = iotService;
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }

  ngOnInit(): void {
    const that = this;
    that.initLogRequestInfo();
  }
  initLogRequestInfo() {
    const that = this;
    that.logRequestInfo = {
      "creator": that.iotService.getCreator(),
      "creatorAppId": that.iotService.getCreatorAppId(),
      "realm": that.iotService.getRealm(),
      "parentGatewayId": that.gatewaysService.getGateWayAgentId(),
      "startTime": 0,
      "endTime":0,
      "logLevel": 'ALL',
      "noOfLogRecords": 10000
    };
  }

  save() {
    const that = this;
    that.logRequestInfo.parentGatewayId = that.gatewaysService.getGateWayAgentId();
    if(!that.validateData()){
    that.iotService.getCuiObjResponse().createLogFetchRequest({
      data: that.logRequestInfo,
      gatewayId: that.logRequestInfo.parentGatewayId
    }).then(function (response) {
      // that.gatewaysService.createNewAdaptor = false;
      console.log("Adaptor  created succesfully:", response.id);
      that.gatewaysService.successMessage = "logRequestInfo  created succesfully";
      that.goBack();
      //that.router.navigateByUrl("/routes");
    })
      .fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          //that.routeService.errorMessage = err.responseJSON.apiMessage;
          that.gatewaysService.errorMessage =  err.responseJSON.apiMessage;
          console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
        } else {
          that.gatewaysService.errorMessage = "Adaptor  creation failed"
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
        if (err === null) {
          that.gatewaysService.errorMessage = "Adaptor  creation failed"
          //that.warningMessage = "Error occured while creating token";
          console.log("Error occured while creating document info");
        }
      });
    }


  }
  validateData() {
    const that=this;
    var hasError=false;
    if (that.startDate != 0 && that.startDate != null&&that.startDate != undefined ) {
 
      var date = new Date(that.startDate);
      var day = date.getDate();
      var month = date.getMonth();
      var year = date.getFullYear();
    }
    else{
      hasError=true;
      that.gatewaysService.warningMessage="Start Date is required";
      return hasError;
    }
 
    if ( that.startTime != 0 && that.startTime != null &&that.startTime != undefined) {
      var hours = that.startTime.split(":")[0];
      var minutes = that.startTime.split(":")[1];
      var seconds = that.startTime.split(":")[2];
 
    }
    else{
      hasError=true;
      that.gatewaysService.warningMessage="Start Time is required";
      return hasError;
    }
 
    if (that.startDate != 0 && that.startDate != null&&that.startDate != undefined && that.startTime != 0 && that.startTime != null&&that.startTime != undefined) {
 
      var startDateTime = new Date(new Date(year, month, day,
        hours, minutes, seconds)).getTime();
 
        that.logRequestInfo.startTime=startDateTime;
    }


      if (that.endDate != 0 && that.endDate != null &&that.endDate != undefined ) {
 
        var date = new Date(that.endDate);
        var day = date.getDate();
        var month = date.getMonth();
        var year = date.getFullYear();
      }
   
      if ( that.endTime != 0 && that.endTime != null && that.endTime!=undefined) {
        var hours = that.endTime.split(":")[0];
        var minutes = that.endTime.split(":")[1];
        var seconds = that.endTime.split(":")[2];

      }
   
      if (that.endDate != 0 && that.endDate != null&&that.endDate !=undefined && that.endTime != 0 && that.endTime != null&&that.endTime !=undefined) {
   
        var endDateTime = new Date(new Date(year, month, day,
          hours, minutes, seconds)).getTime();
   
          that.logRequestInfo.endTime=endDateTime;
      }

      return hasError;

  }
  goBack() {
    this.gatewaysService.createResource = true;
    this.gatewaysService.createNewLogRequest = false;
    //this.tpService.resetMessage();
  }
  onChange(event){
    this.logRequestInfo.logLevel=event;

  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IotService } from 'src/app/service/iot.service';
import { SideNavComponent } from 'src/app/side-nav/side-nav.component';
import { faToggleOff, faToggleOn, faAngleLeft, faAngleRight, faCheck, faCopy, faTimes, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-ssh-key-detail',
  templateUrl: './ssh-key-detail.component.html',
  styleUrls: ['./ssh-key-detail.component.scss']
})
export class SshKeyDetailComponent implements OnInit {

  failed = faTimes;
  faToggleOffIcon = faToggleOff;
  faToggleOnIcon = faToggleOn;
  faAngleLeftIcon = faAngleLeft;
  faAngleRightIcon = faAngleRight;
  faCheckIcon = faCheck;
  faCopyIcon = faCopy;
  faAngleDoubleLeftIcon = faAngleDoubleLeft;
  faAngleDoubleRightIcon = faAngleDoubleRight;

  sshKeyPair;


  supportLanguages = ['en'];

  constructor(private router: Router, private route: ActivatedRoute, private iotService: IotService,
    private sideNavComponent: SideNavComponent, private translateService: TranslateService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }



  ngOnInit() {
    const that = this;
    this.route.params.subscribe(params => {
      //console.log(params['id']);
      var sshKeyPairId = params['id'];
      this.loadSShKeyData(sshKeyPairId);
    });
   
  }
  loadSShKeyData(sshKeyPairId: any) {
    const that = this;
    that.iotService.getCuiObjResponse().getSSHKeyPair({
      sshKeyPairId: sshKeyPairId
    }).then(function (response) {
      console.log("success-"+response);
      that.sshKeyPair = response;
      that.sideNavComponent.menuClear();
      that.sideNavComponent.menuChange('Dashboard', 'sub-type');
      that.sideNavComponent.menuChange('sshKeys.header', 'sub-type');
      that.sideNavComponent.menuChange('sshKeyPairs.header','sub-type')
      that.sideNavComponent.menuChange(that.sshKeyPair.name, 'sub-type');
    }).fail(function (err) {
      console.log("error-"+err.responseJSON);
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  disablesshKeyPair(id) {
    const that = this;
    that.iotService.getCuiObjResponse().deactivateSshKeyPair({
      sshKeyPairId: id
    }).then(function (response) {
      // console.log(response);
      that.loadSShKeyData(id);
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }

  enablesshKeyPair(id) {
    const that = this;
    that.iotService.getCuiObjResponse().activateSshKeyPair({
      sshKeyPairId: id
    }).then(function (response) {
      // console.log(response);
      that.loadSShKeyData(id);
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }
}

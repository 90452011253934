/**
 * This is an auto generated code for ecosystems listing page.
 * Please don't make any change to this file.
 * @version 1.0.0
 * @readonly
 * @author - System generated
 */

import { Component, OnInit, ViewChild, Injector, Inject} from '@angular/core'
import { IotService } from '../service/iot.service'
import { EcosystemsService } from '../service/ecosystems.service'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { faCaretUp, faCaretDown, faInfoCircle, faQuestionCircle, faArrowUp, faArrowDown, faSearch } from '@fortawesome/free-solid-svg-icons'
import { SideNavComponent } from '../side-nav/side-nav.component'
import { BaseListComponent } from '../base-list/baselist.component'

interface TypeChange {
	value: string;
	viewValue: string;
}
@Component({
	selector: 'app-ecosystems',
	templateUrl: './ecosystems.component.html',
	styleUrls: ['../base-list/baselist.component.scss','./ecosystems.component.scss']
})
export class EcosystemsComponent extends BaseListComponent {
	searchCriteria="name";
	displayedColumns: string[] = ['name','description','creation'];
	sortColumns: string[] = ['creation'];
	pageTitle="ecosystems.header";
	ascCreation:boolean = false;
	descCreation:boolean = false;
	countCallCompleted:boolean = false;
	getCallCompleted:boolean = false;
	showEnableColumn = true;

	constructor( router: Router,
				iotService: IotService,
				translateService: TranslateService,
				sideNavComponent:SideNavComponent,
				public ecosystemsService:EcosystemsService) {
				super(router, iotService, translateService, sideNavComponent )
						this.translateService.addLangs(this.supportLanguages);
						this.translateService.setDefaultLang('en');
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.ecosystemsService.multiSelectRows = this.multiSelect;
		this.ecosystemsService.loadSelected();
		if(!this.showSelectColoumn){
			this.sideNavComponent.menuClear();
			this.sideNavComponent.menuChange('Dashboard','sub-type');
			this.sideNavComponent.menuChange('ecosystems.header','sub-type');
		}
	}
	getFilteredResults(value, columnName , sortOrder){
		const that = this;
		that.iotService.getCuiObjResponse().getEcosystems({
			qs: eval(that.fnGetQS(value, columnName, sortOrder))
		}).then(function (response) {
			that.fnSuccess(response);
			if(columnName == 'none' || columnName == '' || columnName == undefined){
				that.fnResetSortParam('creation', 'desc');
			}else{
				that.fnResetSortParam(columnName, sortOrder);
			}
			that.getCallCompleted = true;
		}).fail(function (err) {
			that.fnError(err);
			that.getCallCompleted = true;
		});
	}
	getCount() {
		const that = this;
		that.showPagination=false;
		let value = this.selected;
		that.iotService.getCuiObjResponse().countEcosystems({
			qs: eval(that.fnGetQS(value, '', ''))
		}).then(function (response) {
				that.totalCount = response;
				that.countCallCompleted = true;
			if(that.totalCount > 0)
				that.showPagination=true;
			}).fail(function (err) {
				that.fnError(err);
				that.countCallCompleted = true;
			});
	}
	activateEcosystems(resourceId){
		const that = this;
		that.iotService.getCuiObjResponse().activateEcosystems({
			ecosystemId: resourceId
		}).then(function (response) {
			that.getFilteredResults(that.selected, that.sortCriteria, that.sortDirection);
			that.ecosystemsService.fnResetList();
		}).fail(function (err) {
			that.fnError(err);
		});
	}
	deactivateEcosystems(resourceId){
		const that = this;
		that.iotService.getCuiObjResponse().deactivateEcosystems({
			ecosystemId: resourceId
		}).then(function (response) {
			that.getFilteredResults(that.selected, that.sortCriteria, that.sortDirection);
			that.ecosystemsService.fnResetList();
		}).fail(function (err) {
			that.fnError(err);
		});
	}
	viewDetails(selectedId){
		this.router.navigateByUrl("/ecosystems/"+selectedId);
		this.ecosystemsService.viewDetails(selectedId);
	}
	createNew(){
		this.ecosystemsService.createNew();
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}
	onSelection(results){
		this.ecosystemsService.onSelection(results);
	}
	removeSelection(results){
		this.ecosystemsService.removeSelection(results);
	}
	changeActive(resourceId, isActivate){
		if(isActivate == 'true'){
			this.deactivateEcosystems(resourceId);
		}else{
			this.activateEcosystems(resourceId);
		}
	}
}

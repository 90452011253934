import { Component, OnInit } from '@angular/core';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { IotService } from '../service/iot.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GatewaysService } from '../service/gateways.service';
import { AdaptorsService } from '../service/adaptors.service';


@Component({
  selector: 'app-create-adaptor',
  templateUrl: './create-adaptor.component.html',
  styleUrls: ['./create-adaptor.component.scss']
})
export class CreateAdaptorComponent implements OnInit {
  adaptorInfo;

  supportLanguages = ['en'];
  authorityColumns = ["Name", "Type"];

  constructor(public iotService: IotService, private translateService: TranslateService,
  private router: Router, public gatewaysService: GatewaysService, public adaptorsService: AdaptorsService) {
      this.iotService = iotService;
      this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');

  }

  ngOnInit(): void {
    const that = this;
    that.initAdaptorInfo();
  }

  initAdaptorInfo() {
    const that = this;
    that.adaptorInfo = {
      "creator": that.iotService.getCreator(),
      "creatorAppId": that.iotService.getCreatorAppId(),
      "realm": that.iotService.getRealm(),
      "name": "",
      "description": [{
        "lang": "en_US",
        "text": ""
      }],
     "parentGatewayId": that.gatewaysService.getGateWayAgentId(),
			"location": {
				"url": "",
				"sha1": "",
				"md5": ""
			},
			"controlCommands": {
				"start": ""
			}
    };
  }



  save() {
    const that = this;
    that.adaptorInfo.parentGatewayId=that.gatewaysService.getGateWayAgentId();
    that.iotService.getCuiObjResponse().addAdapter({
      data: that.adaptorInfo,
      gatewayId: that.adaptorInfo.parentGatewayId
    }).then(function (response) {
      console.log("Adaptor  created succesfully:", response.id);
       that.gatewaysService.successMessage="Adaptor  created succesfully";
       that.goBack();
    })
      .fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          that.gatewaysService.errorMessage="Adaptor creation failed";
          console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
        } else {
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
        if (err === null) {
          that.gatewaysService.errorMessage="Error occured while creating Adaptor";
          console.log("Error occured while creating Adaptor");
        }
      });

   
  }
  goBack() {
    this.gatewaysService.createResource = true;
    this.gatewaysService.createNewAdaptor= false;
    //this.tpService.resetMessage();
  }
  deleteAdaptor(){
    this.gatewaysService.showAdaptor=false;
  }
}

<mat-sidenav-container class="new-gateway" autosize *ngIf="gatewayDetailInfo">
    <mat-sidenav class="midNav-container" #sidenav mode="side" disableClose="true" opened="true">
        <div class="alert warning" *ngIf="gatewaysService.warningMessage">
            <h5>
                <fa-icon [icon]="faWarningIcon"></fa-icon>
                <span>{{gatewaysService.warningMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)="gatewaysService.resetWarningMessage()" [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>
        <div class="alert critical" *ngIf="gatewaysService.errorMessage">
            <h5>
                <fa-icon [icon]="faCriticalIcon"></fa-icon>
                <span>{{gatewaysService.errorMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=gatewaysService.resetErrorMessage() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>

        <div class="alert success" *ngIf="gatewaysService.successMessage">
            <h5>
                <fa-icon [icon]="faSuccessIcon"></fa-icon>
                <span>{{gatewaysService.successMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=gatewaysService.resetSuccessMessage() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>
        <div *ngIf="iotService.viewRulePolicy">
            <div class="alert critical" *ngIf="rulesService.errorMessage">
                <h5>
                    <fa-icon [icon]="faCriticalIcon"></fa-icon>
                    <span>{{rulesService.errorMessage}}</span>
                    <button class="alert-close-btn">
                        <fa-icon (click)=rulesService.resetMessage() [icon]="faCloseIcon"></fa-icon>
                    </button>
                </h5>

            </div>
            <div class="alert success" *ngIf="rulesService.successMessage">
                <h5>
                    <fa-icon [icon]="faSuccessIcon"></fa-icon>
                    <span>{{rulesService.successMessage}}</span>
                    <button class="alert-close-btn">
                        <fa-icon (click)=rulesService.resetMessage() [icon]="faCloseIcon"></fa-icon>
                    </button>
                </h5>
            </div>
            <div class="alert warning" *ngIf="rulesService.warningMessage">
                <h5 style="font-size: 12px;">
                    <fa-icon [icon]="faWarningIcon"></fa-icon>
                    <span>{{rulesService.warningMessage}}</span>
                    <button class="alert-close-btn">
                        <fa-icon (click)="rulesService.resetMessage()" [icon]="faCloseIcon"></fa-icon>
                    </button>
                </h5>
            </div>
        </div>
        <section>
            <div style="margin-left: -10px;">
                <app-copy-resource-id></app-copy-resource-id>
            </div>
            <div *ngIf="gatewayDetailInfo">
                <dl>
                    <dt>{{'name' | translate}}</dt>
                    <dd>
                        <p>{{gatewayDetailInfo.name[0].text}}</p>
                    </dd>
                </dl>
                <dl>
                    <dt>{{'description' | translate}}</dt>
                    <dd>
                        <!-- <p>{{gatewayDetailInfo.description[0].text}}</p> -->
                        <p *ngIf="gatewayDetailInfo.description" id="description">
                            {{gatewayDetailInfo.description[0].text}}</p>
                        <p *ngIf="!gatewayDetailInfo.description" id="description"></p>
                    </dd>
                </dl>
                <dl>
                    <dt>{{'gateWay.device' | translate}}</dt>
                    <dd><a class="hyperlink" (click)="getDeviceDetail(gatewayDetailInfo.backingDeviceId)"
                            title="View Details">{{deviceName }} Device</a>
                </dl>
                <dl>
                    <dt>{{'gateways.model' | translate}}</dt>
                    <dd>
                        <p>{{gatewayDetailInfo.model}}</p>
                    </dd>
                </dl>
                <dl>
                    <dt>{{'gateways.serial' | translate}}</dt>
                    <dd>
                        <p>{{gatewayDetailInfo.serial}}</p>
                    </dd>
                </dl>
                <dl>
                    <dt>{{'agent.version' | translate}}</dt>
                    <dd>
                        <p>{{gatewayDetailInfo.agentVersion}}
                            <span
                                *ngIf="updateAvailble == true && updateStatus != 'requested' && updateStatus != 'failed'">&nbsp;&nbsp;&nbsp;<a
                                    (click)="upgradeNow()">{{'upgrade.now' | translate}}</a></span>
                            <span *ngIf="updateStatus == 'failed'">{{gatewayDetailInfo.updateStatus.summary}}
                                &nbsp;&nbsp;&nbsp; <a
                                    (click)="gatewaysService.createResource=false; showNextDiv=true"><u>{{'what.next' | translate}}{{'?' | translate}}</u></a></span>
                            <span *ngIf="updateStatus == 'succeeded'">{{gatewayDetailInfo.updateStatus.summary}}</span>
                            <span *ngIf="updateStatus == 'requested'"> {{gatewayDetailInfo.updateStatus.summary}}</span>
                            <span
                                *ngIf="updateStatus != 'succeeded' && updateStatus != 'failed' && updateStatus != 'requested'"
                                style="font-size: small;">{{updateStatus}}</span>

                        </p>
                    </dd>
                </dl>
                <dl>
                    <dt>{{'created' | translate}}</dt>
                    <dd>{{gatewayDetailInfo.creation | date:'medium' }}</dd>
                </dl>

                <!-- <button  color="primary" (click)="loadAdaptor()">{{ 'addAdaptor' | translate}}</button>
                <button  color="primary" (click)="loadLogRequest()">{{ 'loadLogRequest' | translate}}</button> -->

            </div>
        </section>
        <div style="margin-left: 10px;">
        <button *ngIf="remoteConfigExists==true" class="btn btn-secondary" (click)="loadRemoteConfig()">{{
            'loadRemoteConfig' | translate}}</button>
        <button *ngIf="remoteConfigExists==false" class="btn btn-secondary" (click)="loadSeedConfiguration()">{{
            'seedConfiguration' | translate}}</button>
        &nbsp;<button class="btn btn-secondary" (click)="createRuleFromGatewayagent(gatewayDetailInfo.id)">{{
            'AddRulePolicy' | translate}}</button>
        </div>
        <section>
            <div *ngIf="iotService.viewRulePolicy">
                <br>
                <br>
                <dt> {{'rules.createRule' | translate}}</dt>
                <mat-nav-list>
                    <dl>
                        <dt>{{'trigger.types' | translate}}</dt>
                        <p *ngIf="rulesService.triggerType=='Select Trigger'">
                            {{'select.triggerType' | translate}}
                        </p>
                    </dl>
                    <dl>
                        <dd class="rulesService.trigger" *ngIf="rulesService.triggerType!='Select Trigger'">
                            {{rulesService.triggerType}}</dd>

                        <dt>{{'event.template' | translate}}</dt>
                        <p *ngIf="!rulesService.selectedEvent">
                            {{'select.event.table' | translate}}
                        </p>

                        <div *ngIf="rulesService.selectedEvent">
                            <dd>
                                <fa-icon [icon]="faCheckIcon"></fa-icon> {{rulesService.getEvents().name}}
                            </dd>
                        </div>
                    </dl>
                    <dl>
                        <dt>{{'devices' | translate}}</dt>
                        <p *ngIf="rulesService.devices.length == 0">
                            {{'select.device.table' | translate}}
                        </p>
                        <dd *ngFor="let device of rulesService.devices">
                            <fa-icon [icon]="faCheckIcon"></fa-icon> {{ device.name[0].text }}
                        </dd>
                    </dl>
                    <div *ngIf="!rulesService.showGateway && rulesService.triggerType.includes('Device')">
                        <dl>
                            <dt>{{'templates.header' | translate}}</dt>
                            <p *ngIf="rulesService.deviceTemplates.length == 0">
                                {{'select.deviceTemplate.table' | translate}}
                            </p>
                            <dd *ngFor="let deviceTemplate of rulesService.deviceTemplates">
                                <fa-icon [icon]="faCheckIcon"></fa-icon> {{ deviceTemplate.name[0].text }}
                            </dd>
                        </dl>
                    </div>
                    <div *ngIf="rulesService.triggerType=='Device Geofence'">
                        <dl>
                            <dt>{{'geofences' | translate}}</dt>
                            <p *ngIf="rulesService.geofences.length == 0">
                                {{'select.geofence.table' | translate}}</p>
                            <dd *ngFor="let geofence of rulesService.geofences">
                                <fa-icon [icon]="faCheckIcon"></fa-icon> {{ geofence.name[0].text }}
                            </dd>
                        </dl>
                    </div>
                    <div *ngIf="rulesService.showGateway">
                        <dl>
                            <dt>{{'gateways' | translate}}</dt>
                            <p *ngIf="rulesService.gateways == undefined">
                                {{'select.gateway.table' | translate}}</p>
                            <div *ngIf="rulesService.gateways != undefined">
                                <dd>
                                    <fa-icon [icon]="faCheckIcon"></fa-icon> {{rulesService.gateways.name[0].text}}
                                </dd>

                            </div>
                        </dl>
                    </div>
                    <div
                        *ngIf="(rulesService.triggerType=='Device Event' || rulesService.triggerType.includes('Ecosystem')) && !rulesService.showGateway">
                        <dl>
                            <dt>{{'subscribers' | translate}}</dt>
                            <dt>{{'ecosystems.header' | translate}}</dt>
                            <p *ngIf="rulesService.ecosystems.length == 0">
                                {{'select.ecosystem.table' | translate}}</p>

                            <dd *ngFor="let ecosystem of rulesService.ecosystems">
                                <fa-icon [icon]="faCheckIcon"></fa-icon> {{ ecosystem.name[0].text }}
                            </dd>
                            <dt>{{'ecosystems.templates' | translate}}</dt>
                            <p *ngIf="rulesService.ecosystemTemplates.length == 0">
                                {{'select.ecosystemTemplate.table' | translate}}</p>
                            <dd *ngFor="let ecosystemTemplate of rulesService.ecosystemTemplates">
                                <fa-icon [icon]="faCheckIcon"></fa-icon> {{ ecosystemTemplate.name[0].text }}
                            </dd>
                        </dl>
                    </div>

                </mat-nav-list>
            </div>
        </section>
        <!-- <button  color="primary" (click)="removeAdaptor()">{{ 'removeAdaptor' | translate}}</button> -->
    </mat-sidenav>


    <div style="height:450vh">
        <div *ngIf="!iotService.viewRulePolicy">
            <div *ngIf="gatewaysService.createResource && gatewayDetailInfo">
                <app-gatewayAdaptors addnQueryParam=[gatewayId::{{gatewayDetailInfo.id}}] [showControlBar]=true
                    [showSearch]=false [showSelectColoumn]=true [newWithRoute]=false [showHeaderToolTips]=false></app-gatewayAdaptors>
                <app-logRequests
                    [displayedColumns]="['startTime','endTime','logLevel','noOfLogRecords','requestStatus','creation','ACTION']"
                    [showActionColumn]=true addnQueryParam=[gatewayId::{{gatewayDetailInfo.id}}] [showControlBar]=true
                    [showSearch]=false [showSelectColoumn]=true [newWithRoute]=false [showHeaderToolTips]=false></app-logRequests>
                <app-gatewayAnnouncedDevices [displayedColumns]="['name','description','state']" [showActionColumn]=true
                    [showNew]=false
                    addnQueryParam=[gatewayId::{{gatewayDetailInfo.id}}^^gatewayDeviceId::{{gatewayDetailInfo.backingDeviceId}}]
                    [showControlBar]=true [showSearch]=false [showSelectColoumn]=true [newWithRoute]=false [showHeaderToolTips]=false>
                </app-gatewayAnnouncedDevices>
                <app-gatewayRulePolices [displayedColumns]="['name','description','state']" [showActionColumn]=true
                    [showNew]=false
                    addnQueryParam=[gatewayId::{{gatewayDetailInfo.id}}^^gatewayDeviceId::{{gatewayDetailInfo.backingDeviceId}}]
                    [showControlBar]=true [showSearch]=false [showSelectColoumn]=true [newWithRoute]=false [showHeaderToolTips]=false>
                </app-gatewayRulePolices>
            </div>

        </div>
        <ng-container *ngIf="iotService.viewRulePolicy">
            <app-create-rule-policy></app-create-rule-policy>
        </ng-container>
        <div *ngIf="showUpdate" class="upgrade">

            <label>{{'version' | translate}}</label>
            <p>{{updateAgentAvailable.agentVersion}}</p>
            <label>{{'released' | translate}}</label>
            <p>{{updateAgentAvailable.availableSince|date:'medium'}}</p>
            <div id="releaseNotes"></div>

            <button class="btn btn-secondary" style="float: right;"
                (click)="gatewaysService.createResource=true ; showUpdate=false  ">{{'go.back' | translate}}</button>
            <button class="btn btn-primary" style="float: right;margin-right: 5px;"
                (click)="updateAgent()">{{'update' | translate}}</button>

        </div>


        <div *ngIf="showNextDiv" class="upgrade">

            <div>
                <a class="uk-modal-close uk-close"></a>
                <b>{{'rollBack.previous.version' | translate}}</b><br>
                {{'ssh.gateway.host' | translate}} <br>
                {{'sh.rollback.desc' | translate}}
            </div>

            <button class="btn btn-secondary" style="float: right;"
                (click)="gatewaysService.createResource=true ; showNextDiv=false  ">{{'go.back' | translate}}</button>
            <!-- <button class="btn btn-primary" style="float: right;margin-right: 5px;" (click)="updateAgent()">Update</button> -->

        </div>
        <!-- <app-create-adaptor *ngIf="deleteAdaptor"></app-create-adaptor> -->
        <div *ngIf="gatewaysService.createNewAdaptor" style="height:100vh">
            <app-create-adaptor></app-create-adaptor>
        </div>
        <div *ngIf="gatewaysService.createNewLogRequest" style="height:100vh">
            <app-create-log-request></app-create-log-request>
        </div>
        <div *ngIf="gatewaysService.showRemoteConfig">
            <app-remote-configuration-detail></app-remote-configuration-detail>
        </div>

        <div *ngIf="gatewaysService.showAdaptorDetail">
            <app-gateway-adaptor-detail></app-gateway-adaptor-detail>
        </div>

        <div *ngIf="gatewaysService.showSeedConfiguration">
            <header class="table-header">
                <h2>{{'seedConfiguration'|translate}} </h2>
            </header>
            <div class="seedConfiguration">
                <span><b>{{'note' | translate}}</b> {{'seed.configuration.desc' | translate}}</span><br>
                <table>
                    <tbody>
                        <tr *ngFor="let seed of gatewayDetailInfo.seedConfiguration |sort:'name'">
                            <td style="text-align: right;" title="{{seed.tooltip}}">{{seed.name}}

                            </td>
                            <td>{{seed.value}}</td>
                        </tr>
                    </tbody>
                </table>
                <button class="btn btn-secondary pull-right" style="margin-top: 20px; margin-right: 10px;" title="Go Back to gateway detail page"
                    (click)="goBack()">{{ 'go.back' | translate}}</button>
            </div>
            <br>
            <br>
        </div>
    </div>
</mat-sidenav-container>


<ng-container *ngIf="!gatewayDetailInfo ">
    <div>
        <br /><br />
    </div>
    <div class="d-flex justify-content-center pageLoading">
        <div class="spinner-border" role="status">
            <span class="sr-only" id="loading"></span>
        </div>
    </div>
</ng-container>
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IotService } from '../service/iot.service';
import { TradingPartnersService } from '../service/tradingPartners.service';
import { faAngleUp, faAngleDown, faTimes, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faToggleOff, faToggleOn, faAngleLeft, faAngleRight, faCheck, faCopy, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FromTPCodesService } from '../service/fromTPCodes.service';
import { ToTPCodesService } from '../service/toTPCodes.service';



@Component({
  selector: 'app-new-code-relationships',
  templateUrl: './new-code-relationships.component.html',
  styleUrls: ['./new-code-relationships.component.scss']
})
export class NewCodeRelationshipsComponent implements OnInit {

  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;
  faCloseIcon = faTimes;
  faWarningIcon = faExclamationTriangle;

  failed = faTimes;
  faToggleOffIcon = faToggleOff;
  faToggleOnIcon = faToggleOn;
  faAngleLeftIcon = faAngleLeft;
  faAngleRightIcon = faAngleRight;
  faCheckIcon = faCheck;
  faCopyIcon = faCopy;
  faAngleDoubleLeftIcon = faAngleDoubleLeft;
  faAngleDoubleRightIcon = faAngleDoubleRight;

  codeError;
  warningMessage;
  tradingPartnerCodeRelationShip;
  createAnother = false;
  supportLanguages = ['en'];
  controlNumberError;
  loadCodes=true;

  isActive = false;

  constructor(public iotService: IotService, private translateService: TranslateService,
    private router: Router, public tpService: TradingPartnersService,
    public fromCodesService: FromTPCodesService, public toCodesService:ToTPCodesService
    ) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }


  ngOnInit(): void {
    this.initTpcode();
    this.tpService.setFromCode(undefined);
    this.tpService.setToCode(undefined);
    this.fromCodesService.selection.clear();
    this.toCodesService.selection.clear();
    // this.loadCodes=true;
  }


  initTpcode() {
    const that = this;

    this.tradingPartnerCodeRelationShip = 
    {
      "creator": that.iotService.getCreator(),
      "creatorAppId": that.iotService.getCreatorAppId(),
      "realm": that.iotService.getRealm(),
      'fromCodeId': '',
      'toCodeId': '',
      'controlNumber': '',
      'description': [{
          'lang': 'en_us',
          'text': ''
          }],
        }
  }

  resetData(){
    this.codeError="";
    this.controlNumberError="";
  }

  save() {
    const that = this;
    that.loadCodes=true;
    if (that.tpService.getFromCode() == undefined || that.tpService.getToCode()==undefined) {
      that.tpService.warningMessage="From Code and TO code Value is Required";
    }
    else {
      that.tradingPartnerCodeRelationShip.fromCodeId=that.tpService.getFromCode().id;
      that.tradingPartnerCodeRelationShip.toCodeId=that.tpService.getToCode().id;
      that.iotService.getCuiObjResponse().saveTPCodeRelationship({
        data: that.tradingPartnerCodeRelationShip
      }).then(function (response) {
        console.log("Trading Partner code created successFully, Id: ", response.id);
        that.tradingPartnerCodeRelationShip = response;
        if (that.isActive) {
          that.activateTPCodeRelationship(response.id);
        }
        if (!that.createAnother&&!that.isActive ) {
          that.tpService.createResource = true;
          that.tpService.createCodeRelationShip = false;
          that.tpService.resetMessage();
        }
        else {
          that.loadCodes=false;
          that.ngOnInit();
        }
      }).fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          that.tpService.errorMessage = err.responseJSON.apiMessage;
          console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
        } else {
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
        if (err === null) {
          console.log("Error occured while creating trading partner code relationship");
          that.tpService.errorMessage="Error occured while creating trading partner code relationship";
        }
      });
    }


  }

  toggleActive() {
    this.isActive = !this.isActive;
  }
  goBack() {
    this.tpService.createResource = true;
    this.tpService.createCodeRelationShip = false;
    this.tpService.resetMessage();
  }

  activateTPCodeRelationship(codeRelationshipId) {
    const that = this;
    that.iotService.getCuiObjResponse().activateTPCodeRelationship({
      codeRelationshipId: codeRelationshipId
    }).then(function (response) {
      console.log("Trading Partner code relationshipc activated successFully, Id: ",codeRelationshipId );
      if (!that.createAnother ) {
        that.tpService.createResource = true;
        that.tpService.createCodeRelationShip = false;
        that.tpService.resetMessage();
      }
      else {
        that.ngOnInit();
      }
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.tpService.errorMessage = err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faAngleUp, faAngleDown, faTimes, faExclamationTriangle, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { faToggleOff, faToggleOn, faAngleLeft, faAngleRight, faCheckCircle, faCheck, faCopy, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { ImportExportService } from '../service/import-export.service';
import { IotService } from '../service/iot.service';
import { SideNavComponent } from '../side-nav/side-nav.component';

@Component({
  selector: 'app-eventgroup-export',
  templateUrl: './eventgroup-export.component.html',
  styleUrls: ['./eventgroup-export.component.scss']
})
export class EventgroupExportComponent implements OnInit {
  eventGroupExportInfo;
  errorMsg;
  errorMsgFLag = false;
  successMsgFlag = false;
  successMsg = "";
  exportworking = false;
  importworking = false;
  supportLanguages = ['en'];
  constructor(public iotService: IotService, private translateService: TranslateService,
    private sideNavComponent: SideNavComponent, private router: Router, public importExportService: ImportExportService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }

  ngOnInit(): void {
    const that = this;
    that.initEventGroupExportInfo();
    that.importExportService.successMessage = "";
    that.importExportService.errorMessage = "";
  }

  ngOnDestroy(): void {
    const that = this;
    that.importExportService.successMessage = "";
    that.importExportService.errorMessage = "";
  }

  initEventGroupExportInfo() {
    const that = this;
    that.eventGroupExportInfo = {
      "resourceId": "",
      "jobName": "",
    };
  }

  populateData() {
    const that = this;
    // this.exportInfo.routeSourceId = this.routeService.getEventGroup().id;
    this.eventGroupExportInfo.resourceType = "EVENT_SOURCE";

  }
  save() {
    const that = this;
    that.errorMsg = "";
    var errorMsg = null;

    that.successMsg = "";
    var successMsg = null;

    if (that.eventGroupExportInfo.resourceId == "") {
      that.errorMsgFLag = true;
      errorMsg = "Resource ID is required for Export.";
      console.log("errorMessage-check" + errorMsg);
    }
    if (errorMsg === null) {
      that.populateData();
      that.iotService.getCuiObjResponse().executeRepExport({
        data: that.eventGroupExportInfo
      }).then(function (response) {
        // that.routeService.setRoutingTopic(response.);
        //idd = response.streamId;
        console.log("Route Info created succesfully. ID :", response.id);
        //that.router.navigateByUrl("/routes");
      })
        .fail(function (response) {
          if (response.status == 200) {
            that.saveTextAsFile(response.responseText, that.eventGroupExportInfo.resourceId + ".pkg")
            // console.log("yesss-p-response.responseText"+response.responseText);
            that.errorMsgFLag = false;
            that.successMsgFlag = true;
            that.importExportService.successMessage = "File downloaded successfully.";
            that.exportworking = false;
            //that.contained_progressbar.complete();
            //that.refresh();
          } else {
            that.successMsgFlag = false;
            that.errorMsgFLag = true;
            that.importExportService.errorMessage = response.responseJSON.apiMessage;
            that.exportworking = false;
            //that.contained_progressbar.complete();
            //that.refresh();
          }
        });
    } else {
      that.errorMsg = errorMsg;

    }
  }

  saveTextAsFile(data, filename) {

    if (!data) {
      console.error('Console.save: No data')
      return;
    }

    if (!filename) filename = 'console.json'

    var blob = new Blob([data], { type: 'text/plain' }),
      e = document.createEvent('MouseEvents'),
      a = document.createElement('a')
    // FOR IE:

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    }
    else {
      var e = document.createEvent('MouseEvents'),
        a = document.createElement('a');

      a.download = filename;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/plain', a.download, a.href].join(':');
      e.initEvent('click', true, false);
      a.dispatchEvent(e);
    }
  }


}

import { Attribute, Injectable } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { IotService } from '../service/iot.service';
import { ListAttributesService } from './ListAttributes.service';
import { EcosystemTemplatesService } from './ecosystemTemplates.service';
import { EcosystemsService } from './ecosystems.service';
import { EcosystemMembersService } from './ecosystemMembers.service';
import { DigitalTwinTemplatesService } from './digitalTwinTemplates.service';
import { DigitalTwinsService } from './digitalTwins.service';
import { DeviceService } from '../service/device.service';


@Injectable({
  providedIn: 'root',
})
export class AttributesService extends ListAttributesService {
  selection: SelectionModel<String> = new SelectionModel<String>(true, []);
  attributes = [];
  constructor(
    public iotService: IotService,
    public ecosystemTemplateService: EcosystemTemplatesService,
    public ecosystemService: EcosystemsService,
    public ecosystemMemberService: EcosystemMembersService,
    public digitalTwinTemplatesService:DigitalTwinTemplatesService,
    public digitalTwinsService:DigitalTwinsService,
    public deviceService:DeviceService,
  ) {
    super(iotService);
    this.selection.clear();
    this.loadSelected();
  }

  getAttributes(): any {
    return this.attributes;
  }

  setAttributes(attributes: any) {
    this.attributes = attributes;
  }
  fnOnSelectionSupport(results) {
    const that = this;
     if (this.iotService.digitalTwinTemplate == true) {
      let attributes = that.digitalTwinTemplatesService.getAttributes();
      attributes.push(results);
      that.digitalTwinTemplatesService.setAttributes(attributes);
    }
    else if (this.iotService.newDeviceTemplate == true) {
      let attributes = that.digitalTwinTemplatesService.getAttributes();
      attributes.push(results);
      that.digitalTwinTemplatesService.setAttributes(attributes);
    }

    else if (that.iotService.createEcosystemTemplate == true) {
      let attributes = that.ecosystemTemplateService.getAttributes();
      attributes.push(results);
      that.ecosystemTemplateService.setAttributes(attributes);
    } else if (that.iotService.viewEcosystem == true) {
      if (that.ecosystemService.viewRelationShip) {
        let attributes = that.ecosystemService.getRelationShipAttributes();
        let attribute = {};
        if (results.constant == null || results.constant == undefined) {
          if (results.defaultValue != undefined) {
            attribute = {
              attributeTypeId: results.id,
              value: results.defaultValue,
            };
          } else {
            attribute = {
              attributeTypeId: results.id,
            };
          }
        } else {
          attribute = {
            attributeTypeId: results.id,
            value: results.constant,
          };
        }
        attributes.push(attribute);
        that.ecosystemService.setRelationShipAttributes(attributes);
      } else if (that.ecosystemService.viewAttributes) {
        let attributes = that.ecosystemService.getCustomAttributes();
        let attribute = {};
        if (results.constant == null || results.constant == undefined) {
          if (results.defaultValue != undefined) {
            attribute = {
              attributeTypeId: results.id,
              value: results.defaultValue,
            };
          } else {
            attribute = {
              attributeTypeId: results.id,
            };
          }
        } else {
          attribute = {
            attributeTypeId: results.id,
            value: results.constant,
          };
        }
        attributes.push(attribute);
        that.ecosystemService.setCustomAttributes(attributes);
      } else if (that.iotService.createEcosystemMembership == true) {
        let attributes = that.ecosystemMemberService.getSelectedAttributes();
        let attribute = {};
        if (results.constant == null || results.constant == undefined) {
          if (results.defaultValue != undefined) {
            attribute = {
              attributeTypeId: results.id,
              value: results.defaultValue,
            };
          } else {
            attribute = {
              attributeTypeId: results.id,
            };
          }
        } else {
          attribute = {
            attributeTypeId: results.id,
            value: results.constant,
          };
        }
        attributes.push(attribute);
        that.ecosystemMemberService.setSelectedAttributes(attributes);
      }
    
    }
   
  }

  fnRemoveSelectionSupport(results) {
    const that = this;

     if (this.iotService.digitalTwinTemplate == true) {
      let attributes = that.digitalTwinTemplatesService.getAttributes();
      attributes.forEach((value, index) => {
        if (value.id == results.id) attributes.splice(index, 1);
      });        
      that.digitalTwinTemplatesService.setAttributes(attributes);
    }

    if (that.iotService.createEcosystemTemplate == true) {
      let attributes = that.ecosystemTemplateService.getAttributes();
      attributes.forEach((value, index) => {
        if (value.id == results.id) attributes.splice(index, 1);
      });
      that.ecosystemTemplateService.setAttributes(attributes);
    } else if (that.iotService.viewEcosystem == true) {
      if (that.ecosystemService.viewRelationShip) {
        that.resetValues(results);
        let attributes = that.ecosystemService.getRelationShipAttributes();
        attributes.forEach((element, index) => {
          if (element.attributeTypeId == results.id) {
            attributes.splice(index, 1);
          }
        });
        that.ecosystemService.setRelationShipAttributes(attributes);
      } else if (that.ecosystemService.viewAttributes) {
        let attributes = that.ecosystemService.getCustomAttributes();
        attributes.forEach((element, index) => {
          if (element.attributeTypeId == results.id) {
            attributes.splice(index, 1);
          }
        });
        that.ecosystemService.setCustomAttributes(attributes);
      } else if (that.iotService.createEcosystemMembership == true) {
        that.resetValues(results);
        let attributes = that.ecosystemMemberService.getSelectedAttributes();
        attributes.forEach((value, index) => {
          if (value.id == results.id) attributes.splice(index, 1);
        });
        that.ecosystemMemberService.setSelectedAttributes(attributes);
      } 
    }
   
  }

  createNew() {
    if (this.iotService.createEcosystemTemplate == true) {
      this.ecosystemTemplateService.createAttribute = true;
      this.ecosystemTemplateService.createResource = false;
    }
    else if(this.iotService.newDeviceTemplate == true){
      this.deviceService.createResource=true;
      this.deviceService.createAttribute=true;
     
    }
  }

  onValueUpdate(results) {
    const that = this;
    if (that.iotService.viewEcosystem == true) {
      if (that.ecosystemService.viewRelationShip) {
        let relationshipAttributesForUpdate =
          that.ecosystemService.getRelationshipAttributesForUpdate();
        let attribute = {
          attributeId: results.id,
          name: results.name,
          value: results.value,
          type: results.type,
        };
        relationshipAttributesForUpdate.push(attribute);
        that.ecosystemService.setRelationshipAttributesForUpdate(
          relationshipAttributesForUpdate
        );
      } else if (that.iotService.createEcosystemMembership == true) {
        let membershipAttributesForUpdate =
          that.ecosystemMemberService.getMembershipAttributesForUpdate();
        let attribute = {
          attributeId: results.id,
          name: results.name,
          value: results.value,
          type: results.type,
        };
        membershipAttributesForUpdate.push(attribute);
        that.ecosystemMemberService.setMembershipAttributesForUpdate(
          membershipAttributesForUpdate
        );
      }
    }
  }

  populateValues(response: any) {
    const that = this;
    if (that.iotService.viewEcosystem == true) {
      if (that.ecosystemService.viewRelationShip) {
        let updatedAttributes =
          that.ecosystemService.getRelationshipAttributesForUpdate();
        that.updateValues(response, updatedAttributes);
      } else if (that.iotService.createEcosystemMembership == true) {
        let updatedAttributes =
          that.ecosystemMemberService.getMembershipAttributesForUpdate();
        that.updateValues(response, updatedAttributes);
      }
    } else {
      that.updateValues(response, new Array());
    }
  }

  updateValues(response: any, updatedAttributes: any[]) {
    response.forEach((element) => {
      let retainExistingValues: boolean = true;
      if (updatedAttributes.length > 0) {
        let result = updatedAttributes.filter(
          (e) => e.attributeId == element.id
        );
        if (result.length > 0) {
          let attrValue = result[0].value;

          if (attrValue != '') {
            element['value'] = attrValue;
            retainExistingValues = false;
          }
        }
      }

      if (retainExistingValues) {
        if (element.constant == null || element.constant == undefined) {
          if (element.defaultValue != undefined) {
            element['value'] = element.defaultValue;
          } else {
            element['value'] = '';
          }
        } else {
          element['value'] = element.constant;
        }
      }
    });
  }

  resetValues(results: any) {
    if (results.constant == null || results.constant == undefined) {
      if (results.defaultValue != undefined) {
        results.value = results.defaultValue;
      } else {
        results.value = '';
      }
    } else {
      results.value = results.constant;
    }
  }

  showErrorMessage(err){
    const that=this;
    if(err.responseJSON.apiStatusCode){
      var errorStr=err.responseJSON.apiMessage;
      var error = [];
      error=errorStr.split(":");
    if(err.responseJSON.apiStatusCode=="framework:resource:attributeType:duplicate"){
      var attributeId = error[1].trim();
    that.deviceService.duplicateAttributeId = attributeId
    that.deviceService.duplicateAttrFlag = true;
    that.deviceService.duplicateCommandFlag=false;
    that.deviceService.duplicateEventFlag=false;

    that.deviceService.warningMessage="Attribute with same name is active."
      console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
    } 
  }else{
      console.log("Something went wrong. Please check chrome console or check with dev team");
    }
  }

  loadSelected() {
    const that = this;
    console.log("that.attribiutes"+that.attributes)
    that.selection.clear();

    if(that.digitalTwinTemplatesService.getAttributes().length>0){
      that.digitalTwinTemplatesService.getAttributes().forEach((attributes) => {
        this.selection.select(attributes.id);
      });
    }

    if(that.digitalTwinsService.getAttributes().length>0){
      that.digitalTwinsService.getAttributes().forEach((attributes) => {
        this.selection.select(attributes.attributeType.id);
      });
    }

    if (
      that.ecosystemTemplateService.getAttributes() &&
      that.iotService.createEcosystemTemplate == true
    ) {
      this.ecosystemTemplateService.getAttributes().forEach((attribute) => {
        this.selection.select(attribute.id);
      });
    }
    if (
      that.ecosystemMemberService.getSelectedAttributes &&
      that.iotService.createEcosystemMembership == true
    ) {
      this.ecosystemMemberService
        .getSelectedAttributes()
        .forEach((attribute) => {
          this.selection.select(attribute.id);
        });
    }

    that.getAttributes().forEach((attribute) => {
      that.selection.select(attribute.id);
    });
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RouteDetailsService {
  streamId;

  constructor() { }
  getStream() {

  }
  setStreamId(id) {
    this.streamId = id;
  }
  getstreamId(){
    return this.streamId;
  }
}

import { Injectable } from '@angular/core';
import { IotService } from '../service/iot.service';
import { GatewaysService } from './gateways.service';


@Injectable({
  providedIn: 'root'
})
export class AdaptorsService {
location;
controlCommands

  constructor(public gatewaysService: GatewaysService) { }

  createNew(){
	this.gatewaysService.createNewAdaptor=true;
	this.gatewaysService.createResource=false;
}


}

<mat-sidenav-container autosize>
    <mat-sidenav class="midNav-container" #sidenav mode="side" disableClose="true" opened="true">
        <div style="margin-left: 10px;">
            <h1 style="margin-left: -15px;">{{'create.gateWay.agent' | translate}}</h1>
            <p>{{'gateways.definition' | translate}}</p>
        </div>
    </mat-sidenav>

    <div style="height:200vh" class="new-gateway">

        <p>{{'gatewayagent.setup' | translate}}</p>
        <!-- <br> -->
        <div class="row" style="margin-left: 10px;">
            <pre>{{'source.install' | translate}} </pre>
            <button class="btn btn-secondary"
                [cdkCopyToClipboard]="'source <(curl -s https://s3-us-west-2.amazonaws.com/iot-gateway-agent/latest/qa/install.sh)'">
                <fa-icon [icon]="faCopyIcon"></fa-icon> {{'cdkCopyToClipboard' | translate}}
            </button>
        </div>
        <!-- <br> -->
        <p> {{'check.gatewayAgent' | translate}}</p>
    </div>

</mat-sidenav-container>
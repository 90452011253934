import { Component, OnInit } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER, I } from '@angular/cdk/keycodes';
import { faAngleUp, faAngleDown, faTimes, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faToggleOff, faToggleOn, faAngleLeft, faAngleRight, faCheck, faCopy,faExclamation, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { IotService } from '../service/iot.service';
import { TranslateService } from '@ngx-translate/core';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { Router } from '@angular/router';
import { TradingPartnersService } from '../service/tradingPartners.service';
import { FromTPCodesService } from '../service/fromTPCodes.service';
import { ToTPCodesService } from '../service/toTPCodes.service';
import { ApplicationsService } from '../service/applications.service';
import { DocumentInfoService } from '../service/documentInfo.service';


@Component({
  selector: 'app-new-trading-partner',
  templateUrl: './new-trading-partner.component.html',
  styleUrls: ['./new-trading-partner.component.scss']
})
export class NewTradingPartnerComponent implements OnInit {

  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;
  faCloseIcon = faTimes;
  faWarningIcon = faExclamationTriangle;

  failed = faTimes;
  faToggleOffIcon = faToggleOff;
  faToggleOnIcon = faToggleOn;
  faAngleLeftIcon = faAngleLeft;
  faAngleRightIcon = faAngleRight;
  faCheckIcon = faCheck;
  faCopyIcon = faCopy;
  faAngleDoubleLeftIcon = faAngleDoubleLeft;  
  faCriticalIcon=faExclamation;

  faAngleDoubleRightIcon = faAngleDoubleRight;

  warningMessage;
  tradingPartnerCreated = false;
  tagClass = 'tagInput';
  tradingPartner;
  name = '';
  description;
  tags = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  supportLanguages = ['en'];
  tpRelationShip;
  tpApplication;
  newTradingPartnerCreated=false;
  constructor(public iotService: IotService, private translateService: TranslateService,
    private sideNavComponent: SideNavComponent,
    private router: Router,
    public tpService: TradingPartnersService,
    public fromCodesService: FromTPCodesService,
    public toCodesService: ToTPCodesService,
    public applicationService: ApplicationsService,
    public docInfoService: DocumentInfoService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
    this.sideNavComponent.menuClear();
    this.sideNavComponent.menuChange('Dashboard', 'sub-type');
    this.sideNavComponent.menuChange('tradingPartners.header', 'sub-type');
    this.sideNavComponent.menuChange('tradingPartners.create.new', 'sub-type');
  }

  ngOnInit(): void {
   
    this.resetData();
    this.iotService.createTradingPartner=true;
    this.initTradingPartner();

  }
  resetData() {
    const that = this;
    that.tpService.createTpCode = false;
    that.tpService.createResource = true;
    that.tpService.createCodeRelationShip = false;
    that.tpService.createTpRelationShip = false;
    that.tpService.createApplication = false;
    that.tpService.createRouteSource = false;
    that.tpService.viewCodeRelationShip=false;
    that.tpService.viewRouteSource=false;
    that.tpService.viewTPCode=false;
    that.tpService.resetMessage();
    that.tpService.setFromCode(undefined);
    that.tpService.setToCode(undefined);
    that.tpService.setTpApplications(new Array);
    that.tpService.setTradingPartner(undefined);
    that.tpService.setTpDocInfo(undefined);
    that.docInfoService.selection.clear();
    that.fromCodesService.selection.clear();
    that.toCodesService.selection.clear();
    that.applicationService.selection.clear();
    that.tpService.selection.clear();
    that.tpService.tradingPartnerIds=[];

  }

  ngOnDestroy(){
    this.resetData();
    this.iotService.createTradingPartner=false;
  }
  
  initTradingPartner() {
    const that = this;
    that.tradingPartner = {
      "creator": that.iotService.getCreator(),
      "creatorAppId": that.iotService.getCreatorAppId(),
      "realm": that.iotService.getRealm(),
      "name": [{
        "lang": "en_us",
        "text": ""
      }],
      "description": [{
        "lang": "en_us",
        "text": ""
      }],
      "tags": that.tags,
      "status": "INACTIVE"
    };
  }

  delete() {
    const that = this;
    that.iotService.getCuiObjResponse().deleteTradingPartner({
      tradingPartnerId: that.tradingPartner.id
    }).then(function (response) {
      console.log("Trading Partner deleted successfully, Id: ", that.tradingPartner.id);
      that.router.navigateByUrl("/tradingPartners");
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.tpService.warningMessage = err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }

  save() {
    const that = this;
    that.newTradingPartnerCreated=true;
    that.iotService.getCuiObjResponse().createTradingPartner({
      data: that.tradingPartner
    }).then(function (response) {
      console.log("Trading Partner created successFully, Id: ", response.id);
      that.tradingPartner = response;
      that.tradingPartnerCreated = true;
      that.tpService.tradingPartnerId = response.id;
      that.tpService.tradingPartnerIds.push(response.id);
      that.tpService.resetMessage();
      that.getUniqueTpIds();
    }).fail(function (err) {
      that.newTradingPartnerCreated=false;
      if (err.responseJSON.apiStatusCode) {
        that.tpService.errorMessage = err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
      if (err === null) {
        that.tpService.warningMessage = "Error occured while creating trading partner";
        console.log("Error occured while creating trading partner");
      }
    });
  }
  getUniqueTpIds() {
    const that = this;
    that.iotService.getCuiObjResponse().getTPRelationships({
      qs: [['tradingPartnerId', that.tpService.tradingPartnerId]]
    }).then(function (response) {
      if (response.length > 1) {
        let tpIds = new Set();
        response.forEach(element => {
          tpIds.add(element.approverTradingPartnerId);
          tpIds.add(element.requestorTradingPartnerId);
        });
        that.tpService.tradingPartnerIds = Array.from(tpIds);
      }
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.warningMessage = err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
      if (err === null) {
        console.log("Error occured while creating trading partner");
      }
    });
  }

  getTradingPartner() {
    const that = this;
    that.iotService.getCuiObjResponse().getTradingPartner({
      tradingPartnerId: that.tradingPartner.id
    }).then(function (response) {
      that.tradingPartner = response;
      console.log("Trading Partner activated successfully, Id: ", response.id);

    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.tpService.warningMessage = err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }

  add(event: MatChipInputEvent): void {

    this.tagClass = 'tagInput';
    const input = event.input;
    const value = event.value;
    const index = this.tags.indexOf(value);
    if (index < 0) {
      if ((value || '').trim()) {
        if (this.tags != undefined && this.tags.includes(value)) {
          if (input) {
            input.value = '';
          }
        }
        else {
          this.tags.push(value);
          if (input) {
            input.value = '';
          }
        }
      }
    } else {
      this.tagClass = 'tagInput-duplicate';
    }
  }

  remove(tag: string): void {
    const that = this;
    that.tags.forEach((value, index) => {
      if (value == tag) that.tags.splice(index, 1);
    });

  }



  initTpRelationShip(tpId) {
    const that = this;
    that.tpRelationShip = {
      "creator": that.iotService.getCreator(),
      "creatorAppId": that.iotService.getCreatorAppId(),
      "realm": that.iotService.getRealm(),
      "requestorTradingPartnerId": that.tpService.tradingPartnerId,
      "approverTradingPartnerId": tpId
    }
  }


  toggleActive(status) {
    // this.tradingPartner.status = status;
    if (this.tradingPartnerCreated) {
      if (status == 'ACTIVE') {
        this.activateTradingPartner(this.tradingPartner.id);
      }
      else {
        this.deactivateTradingPartner(this.tradingPartner.id);
      }
    }
  }
  deactivateTradingPartner(id: any) {
    const that = this;
    that.iotService.getCuiObjResponse().deactivateTradingPartner({
      tradingPartnerId: id
    }).then(function (response) {
      that.getTradingPartner();
      console.log("Trading Partner activated successfully, Id: ", id);
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.tpService.errorMessage= err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }
  activateTradingPartner(id: any) {
    const that = this;
    that.iotService.getCuiObjResponse().activateTradingPartner({
      tradingPartnerId: id
    }).then(function (response) {
      that.getTradingPartner();
      console.log("Trading Partner deactivated successfully, Id: ", id);
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.tpService.errorMessage = err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }



  saveTpRelationship() {

    const that = this;
    let count=0;

    if (that.tpService.getTradingPartnersList().length==0) {
      that.tpService.warningMessage = 'Please select a trading partner to create Trading Partner relationship';
    }
    else {
      that.tpService.getTradingPartnersList().forEach(element => {
        that.initTpRelationShip(element.id);
        that.iotService.getCuiObjResponse().saveTPRelationship({
          data: that.tpRelationShip
        }).then(function (response) {
          console.log("Trading Partner code relationshipc created successFully, Id: ", response.id);
          count++;
          if(count==that.tpService.getTradingPartnersList().length){
            that.tpService.createTpRelationShip = false;
            that.tpService.createResource = true;
            that.tpService.setTradingPartner(undefined);
            that.tpService.selection.clear();
            that.tpService.resetMessage();
          }

  
        }).fail(function (err) {
          if (err.responseJSON.apiStatusCode) {
            that.tpService.errorMessage = err.responseJSON.apiMessage;
            console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
          } else {
            console.log("Something went wrong. Please check chrome console or check with dev team");
          }
        });
      });

    }

  }


  goBack() {
    const that = this;
    that.tpService.createTpRelationShip = false;
    that.tpService.createResource = true;
    that.tpService.setTradingPartner(undefined);
    that.tpService.selection.clear();
    that.tpService.resetMessage();
  }

  saveTpApplication() {

    const that = this;
    that.initTpApplication();
    that.addTpApplication();
    // that.iotService.getCuiObjResponse().getTpApplications({
    //   qs: [['tradingPartnerId', that.tpService.tradingPartnerId]]
    // }).then(function (response) {
    //   // that.tpApplication = response;
    //   if (response.length == 0) {
    //     that.addTpApplication();
    //     return;
    //   }
    //   else {
    //     // that.updateTpApplication(response[0]);
    //     return;
    //   }
    // }).fail(function (err) {
    //   that.addTpApplication();
    // });


  }


  addTpApplication() {
    const that = this;
    that.iotService.getCuiObjResponse().saveTPApplication({
      tradingPartnerId:that.tpService.tradingPartnerId,
      data: that.tpApplication
    }).then(function (response) {
      console.log("Trading Partner application created successFully, Id: ", response.id);
      that.tpService.createApplication = false;
      that.tpService.createResource = true;
      that.applicationService.selection.clear();
      that.tpService.setTpApplications(new Array);
      that.tpService.resetMessage();

    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.tpService.errorMessage = err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  // updateTpApplication(tpApplication) {
  //   const that = this;
  //   let applicationId = that.tpService.getTpApplication().id;
  //   tpApplication.applications.push(applicationId);
  //   that.iotService.getCuiObjResponse().updateApplicationFromTP({
  //     applicationId: tpApplication.id,
  //     data: tpApplication
  //   }).then(function (response) {
  //     console.log("Trading Partner application updated successFully, Id: ", response.id);
  //     that.tpService.createApplication = false;
  //     that.tpService.createResource = true;
  //     that.applicationService.selection.clear();
  //     that.tpService.setTpApplication(undefined);
  //     that.tpService.resetMessage();

  //   }).fail(function (err) {
  //     if (err.responseJSON.apiStatusCode) {
  //       that.tpService.warningMessage = err.responseJSON.apiMessage;
  //       console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
  //     } else {
  //       console.log("Something went wrong. Please check chrome console or check with dev team");
  //     }
  //   });

  // }



  initTpApplication() {
    const that = this;
    let tpApplications = that.tpService.getTpApplications();
    let tpIds=[];
    tpApplications.forEach(element => {
      tpIds.push(element.id);
    });
    that.tpApplication = {
      "creator": that.iotService.getCreator(),
      "creatorAppId": that.iotService.getCreatorAppId(),
      "realm": that.iotService.getRealm(),
      "tradingPartnerId": that.tpService.tradingPartnerId,
      "applications": tpIds

    }
  }



  goBackApplication() {
    const that = this;
    that.tpService.createApplication = false;
    that.tpService.createResource = true;
    that.applicationService.selection.clear();
    that.tpService.setTpApplications(new Array);
    that.tpService.resetMessage();
  }

}

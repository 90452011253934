<div class="example-box" style="float: left;margin-left: 30px">

  <div>
    <app-perfmatrix></app-perfmatrix>
  </div>

</div>

<div class="example-box" style="float: right;margin-right: 30px">
  <h6 style="margin: 25px;margin-left: 10px; font-size: 14px;font-weight: 550;">Device Exceptions</h6>

  <div>

    <br>

    <div style="margin-top: 30px;">
      <canvas style="height:400px;width:450px" baseChart [data]="doughnutChartData" [labels]="doughnutChartLabels"
        [colors]="doughnutColors" [chartType]="doughnutChartType" [options]="doughnutChartOptions ">
      </canvas>
    </div>
  </div>

</div>
<div class="map-box" style="margin-left: 30px;">
  <app-map-widget></app-map-widget>
</div>
/**
 * This is an auto generated code for ecosystemBytemplates service listing page.
 * This file can be customized and will not be overwritten.
 * @version 1.0.0
 * @readonly
 * @author - System generated
 */

import { Injectable } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { IotService } from '../service/iot.service';
import { ListEcosystemBytemplatesService } from './ListEcosystemBytemplates.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class EcosystemBytemplatesService extends ListEcosystemBytemplatesService {
  constructor(public iotService: IotService, private router: Router) {
    super(iotService);
  }

  fnOnSelectionSupport(results) {
    const that = this;
  }

  fnRemoveSelectionSupport(results) {
    const that = this;
  }

  createNew() {
    if (this.iotService.viewEcosystemTemplate == true) {
      this.router.navigateByUrl(
        'ecosystemTemplates/' +
          this.iotService.parentTemplateIdForEcosystem +
          '/createEcosystemFromTemplate'
      );
    }
  }
  
}

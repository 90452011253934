<!--
* This is an auto generated code for gatewayAnnouncedDevices listing page.
* Please don't make any change to this file.
* @version 1.0.0 
* @readonly 
* @author - System generated 
-->
<div class="container"> 
	<header class="table-header">
		<h2>
		 {{pageTitle | translate}}
			 <span *ngIf="showHeaderToolTips" id="ot-tooltip-bottom" class="ot--is-width-px-200">
				  <a class="ot-tooltip ot-tooltip--is-bottom"><fa-icon [icon]="faQuestionCircleIcon"></fa-icon>
					 <span class="ot-tooltip__text">{{'gatewayAnnouncedDevices.definition' | translate}}</span>
				</a>
			 </span>
		</h2>
	</header>
	<div *ngIf="showControlBar" class="control-bar">
		<input *ngIf="showSearch" type="text" placeholder="{{'gatewayAnnouncedDevices.search' | translate}}" (keyup)="getSearchResults($event)"> 
		<span *ngIf="newWithRoute">
			<button *ngIf="showNew" class="newBtn btn-primary" [routerLink]="['/createGatewayDevices']" (click)="createNew()" aria-label="">{{'gatewayAnnouncedDevices.new' | translate }}</button> 
		</span> 
		<span *ngIf="!newWithRoute">
			<button *ngIf="showNew" class="newBtn btn-primary" (click)="createNew()" aria-label="">{{'gatewayAnnouncedDevices.new' | translate }}</button> 
		</span>
	</div>
	<div class="table-container">
	<ngx-skeleton-loader *ngIf="!dataSource" count="12" [theme]="{'border-radius': '0px',
		height: '48px', 
		width: '100%', 
		margin: '1px', 
		position: 'relative', 
		top: '20px' }"> 
	</ngx-skeleton-loader> 
	<ng-container *ngIf="totalCount > 0">
		<table mat-table [dataSource]="dataSource" class="gatewayAnnouncedDevicesTable">
		<ng-container *ngIf="showSelectColoumn">
			<ng-container matColumnDef="ADD">
				<th mat-header-cell *matHeaderCellDef class="checkbox-cell addCol"> {{addSelectButtonLabel | translate}}</th>
					<td mat-cell *matCellDef="let results" class="checkbox-cell addCol">
						<ng-container> 
							<div class="btn-col">
								<ng-container *ngIf="gatewayAnnouncedDevicesService.selection.isSelected(results.id)">
									<button class="selected btn btn-micro btn-secondary"  (click)="removeSelection(results)">
									<fa-icon [icon]="faCheckIcon"></fa-icon></button>
								</ng-container>
								<ng-container *ngIf="!gatewayAnnouncedDevicesService.selection.isSelected(results.id)">
								 <button class="select btn btn-micro btn-secondary"  (click)="onSelection(results)">{{addSelectButtonLabel | translate}}</button>
								</ng-container>
							</div>
						</ng-container> 
					</td>
			</ng-container>
		</ng-container>
		<ng-container matColumnDef="name">
			<th class="name-cell nameCol" mat-header-cell *matHeaderCellDef>
				{{'gatewayAnnouncedDevices.name' | translate}} 
			</th>
			<ng-container *matCellDef="let results">
				<td class="name-cell nameCol" mat-cell *ngIf="results.name">
					{{results.name}} 
				</td> 
				<td mat-cell *ngIf="!results.name"> - </td> 
			</ng-container>
		</ng-container>
		<ng-container matColumnDef="description">
			<th class="desc-cell descriptionCol" mat-header-cell *matHeaderCellDef>
				{{'gatewayAnnouncedDevices.description' | translate}} 
			</th>
			<ng-container *matCellDef="let results">
				<td class="desc-cell descriptionCol" mat-cell *ngIf="results.description">
					{{results.description}} 
				</td> 
				<td mat-cell *ngIf="!results.description"> - </td> 
			</ng-container>
		</ng-container>
		<ng-container matColumnDef="lifecycleState">
			<th class="status-cell lifecycleStateCol" mat-header-cell *matHeaderCellDef>
				{{'gatewayAnnouncedDevices.lifecycleState' | translate}} 
			</th>
			<ng-container *matCellDef="let results">
				<td class="status-cell lifecycleStateCol" mat-cell *ngIf="results.lifecycleState">
					{{results.lifecycleState}} 
				</td> 
				<td mat-cell *ngIf="!results.lifecycleState"> - </td> 
			</ng-container>
		</ng-container>
		<ng-container matColumnDef="operationalState">
			<th class="status-cell operationalStateCol" mat-header-cell *matHeaderCellDef>
				{{'gatewayAnnouncedDevices.operationalState' | translate}} 
			</th>
			<ng-container *matCellDef="let results">
				<td class="status-cell operationalStateCol" mat-cell *ngIf="results.operationalState">
					{{results.operationalState}} 
				</td> 
				<td mat-cell *ngIf="!results.operationalState"> - </td> 
			</ng-container>
		</ng-container>
		<ng-container matColumnDef="state">
			<th class="status-cell stateCol" mat-header-cell *matHeaderCellDef>
				{{'gatewayAnnouncedDevices.state' | translate}} 
			</th>
			<ng-container *matCellDef="let results">
				<td class="status-cell stateCol" mat-cell *ngIf="results.state">
					{{results.state}} 
				</td> 
				<td mat-cell *ngIf="!results.state"> - </td> 
			</ng-container>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let results; columns: displayedColumns;"></tr>
	</table>
	<app-pagination *ngIf="showPagination" [count]="totalCount" [pageSize]="pageSize" [currentPage]="currentPage" 
			[pageSizeOptions]="pageSizeOptions" (currentPageChange)="onPageChange($event)" 
			(currentPageSizeChange)="onPageSizeChange($event)"> 		</app-pagination>
	</ng-container>
	</div>
	<ng-container *ngIf="!countCallCompleted && !getCallCompleted && totalCount == 0" >
		<div class="table-container text-center"></div>
	</ng-container>
	<ng-container *ngIf="countCallCompleted && getCallCompleted && totalCount == 0" >
		<div class="table-container text-center">{{'generic.no.records.found' | translate}}</div>
	</ng-container>
</div>

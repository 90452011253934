import { Injectable } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { StreamService } from './stream.service';
import { ListDocumentInfoService } from './ListDocumentInfo.service';
import { IotService } from '../service/iot.service';
import { TradingPartnersService } from './tradingPartners.service';
import { RoutesService } from './routes.service';
import { OrchestrationConfigsService } from './orchestrationConfigs.service';
import { MessageGovernancesService } from '../service/messageGovernances.service';
@Injectable({
	providedIn: 'root'
})

export class DocumentInfoService extends ListDocumentInfoService {
	selection: SelectionModel<String> = new SelectionModel<String>(true, []);
	selection5: SelectionModel<String> = new SelectionModel<String>(true, []);
	selection6: SelectionModel<String> = new SelectionModel<String>(true, []);


	constructor(public iotService: IotService, public streamService: StreamService, public tpService: TradingPartnersService, public routeService: RoutesService, public orchestrationConfigsService: OrchestrationConfigsService, public messageGovernancesService: MessageGovernancesService) {
		super(iotService);
		if (this.instanceValue == "1") {
			this.selection = this.selection5;
		}
		else if (this.instanceValue == "2") {
			this.selection = this.selection6;
		}
		this.selection.clear();
		this.loadSelected();
	}

	loadSelected() {
		this.selection.clear();
		if (this.tpService.getTpDocInfo() && this.iotService.createTradingPartner==true) {
			this.onSelection(this.tpService.getTpDocInfo());
		}
		else if (this.streamService.getDocinfoResponse() && ( this.instanceValue == "1" ||this.instanceValue == "0") && this.iotService.createStream==true) {
			this.selection.select(this.streamService.getDocinfoResponse().id);
		}
		else if (this.streamService.getDocInfo2() && this.instanceValue == "2"&& this.iotService.createStream==true) {
			this.selection.select(this.streamService.getDocInfo2().id);
		}
		else if (this.routeService.getDocumentInfo()&& this.iotService.createRoute==true) {
			this.selection.select(this.routeService.getDocumentInfo().id);
		}
		else if (this.orchestrationConfigsService.getSourceDocInfo() && this.instanceValue == "1"&& this.iotService.createTradingPartner==false) {
			this.selection.select(this.orchestrationConfigsService.getSourceDocInfo().id);
		}
		else if (this.orchestrationConfigsService.getTargetDocInfo() && this.instanceValue == "2"&& this.iotService.createTradingPartner==false) {
			this.selection.select(this.orchestrationConfigsService.getTargetDocInfo().id);
		}

		else if(this.messageGovernancesService.getDocumentInfos() && 	this.iotService.msgGovLoader==true){
			this.messageGovernancesService.getDocumentInfos().forEach(documentInfo => {
				this.selection.select(documentInfo.id);
			});	
		 

	}
	}

	fnOnSelectionSupport(results) {
		const that = this;
		if (this.instanceValue == "1") {
			that.streamService.selectedDocInfos = true;
			that.streamService.setStreamDocInfoDocType(results.supportedDocType);
			that.streamService.setStreamDocInfoTemplate(results.supportedDocTemplateType);
			that.streamService.setStreamDocInfoVersion(results.docTemplateVersion);
			that.streamService.setDocInfoId(results.id);

			that.orchestrationConfigsService.setSourceDocInfo(results);
		}
		else if (this.instanceValue == "2") {
			that.streamService.selectedDocInfos2 = true;
			that.streamService.setDocInfo2(results);
			that.orchestrationConfigsService.setTargetDocInfo(results);
		}
		else {

			if (that.iotService.createRoute == true) {
				this.routeService.setDocumentInfo(results);
			} else if (that.iotService.createStream == true) {
				that.streamService.selectedDocInfos = true;
				that.streamService.setStreamDocInfoDocType(results.supportedDocType);
				that.streamService.setStreamDocInfoTemplate(results.supportedDocTemplateType);
				that.streamService.setStreamDocInfoVersion(results.docTemplateVersion);
				that.streamService.setDocInfoId(results.id);
				that.streamService.setDocinfoResponse(results);
			}
			else if (that.iotService.createTradingPartner == true) {
				that.tpService.setTpDocInfo(results);

			}
			else if (that.iotService.createMessageGovernance == true) {
				let documentInfos = that.messageGovernancesService.getDocumentInfos();
				documentInfos.push(results);
				that.messageGovernancesService.setDocumentInfos(documentInfos);
			}
		}


	}

	fnRemoveSelectionSupport(results) {
		const that = this;
		if (this.instanceValue == "1") {
			var index = null;
			that.streamService.setStreamDocInfoTemplate(undefined);
			that.streamService.setStreamDocInfoDocType(undefined);
			that.streamService.setStreamDocInfoVersion(undefined);
			that.streamService.setDocInfoId(undefined);
			that.streamService.selectedDocInfos = false;
			that.orchestrationConfigsService.setSourceDocInfo(undefined);
		}
		else if (this.instanceValue == "2") {

			that.streamService.selectedDocInfos2 = false;
			that.streamService.setDocInfo2(undefined);
			that.orchestrationConfigsService.setTargetDocInfo(undefined);
		}
		else {
			var index = null;


			if (that.iotService.createRoute == true) {
				this.routeService.setDocumentInfo(undefined);
			}
			else if (that.iotService.createStream == true) {
				that.streamService.setStreamDocInfoTemplate(undefined);
				that.streamService.setStreamDocInfoDocType(undefined);
				that.streamService.setStreamDocInfoVersion(undefined);
				that.streamService.setDocInfoId(undefined);
				that.streamService.selectedDocInfos = false;
				that.streamService.setDocinfoResponse(undefined);

			}
			else if (that.iotService.createTradingPartner == true) {

				that.tpService.setTpDocInfo(undefined);

			}
			else if (that.iotService.createMessageGovernance == true) {
				let documentInfos = that.messageGovernancesService.getDocumentInfos();
				documentInfos.forEach((value, index) => {
					if (value.id == results.id) documentInfos.splice(index, 1);
				});
				that.messageGovernancesService.setDocumentInfos(documentInfos);

			}
		}
	}
}

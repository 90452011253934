<header>
    <h1>{{ 'create.new.command' | translate}}</h1>
    <p *ngIf="errorMsg" class="alert">{{errorMsg}}</p>
</header>
<ng-container *ngIf="showLoading">
    <div>
        <br/><br/>
    </div>
    <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
            <span class="sr-only" id="loading"></span>
        </div>
    </div>
</ng-container>
<div *ngIf="!showLoading" class="commandForm">
    <div class="col">
        <label for="commandName">{{'name' | translate}}</label>
        <input [(ngModel)]="commandTemplate.name" id="commandName" placeholder="{{'required' | translate}}" (click)="nameError=''" name="commandName" type="text">
        <p style="color:red">{{nameError}}</p>   

        <label for="commandDescription">{{'description' | translate}}</label>
        <textarea [(ngModel)]="commandTemplate.description[0].text" placeholder="{{'optional' | translate}}" type="text"></textarea>

        <label for="commandTags">{{ 'tags' | translate}}</label>
        <mat-chip-list #tagList aria-label="tags">
            
            <mat-chip class="ot-chip" *ngFor="let tag of tags" [selectable]="selectable" [removable]=true (removed)="remove(tag)">
                {{tag}}
                <button class="ot-chip__close" matChipRemove></button>
            </mat-chip>
            <input [ngClass]="tagClass" placeholder="{{'add_a_tag' | translate}}" #tagInput [matChipInputFor]="tagList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="add($event)">
            
        </mat-chip-list>
    </div>
    <div class="col">
        <label for="docInfo">{{ 'document.info' | translate}}</label>
        <select [disabled]=true id="docInfo">
            <option value="true">{{'select'| translate}}</option>
        </select>

        <label for="acknowledgement">{{ 'requires.acknowledgement' | translate}}</label>
        <select [(ngModel)]="commandTemplate.requiresAck">
            <option value="true">{{ 'yes' | translate}}</option>
            <option value="false">{{ 'no' | translate}}</option>
        </select>

        <label></label>
        <div class="activateCommand">
            <label>{{ 'enabled' | translate}}?</label>
            <div class="activateEvent">

                <button *ngIf="commandTemplate.isActive" class="toggle-on toggle-switch" (click)=toggleActivate(false); title="Disable" aria-label="Enabled" title="Enabled">
                <fa-icon [icon]="faCheckIcon" class="fa-2x"></fa-icon>
                <div class="toggle-on-btn"></div>
            </button>

                <button *ngIf="!commandTemplate.isActive" class="toggle-off toggle-switch" (click)=toggleActivate(true); aria-label="Disabled" title="Disabled">
                <div class="toggle-off-btn"></div>
            </button>
                <!-- <mat-grid-list [cols]="4" rowHeight="4:1" [gutterSize]="0">
                <mat-grid-tile [ngClass]="commandDisabledClass" (click)=toggleActivate(false);>
                    <h3>Disabled x</h3>
                </mat-grid-tile>  
                <mat-grid-tile [ngClass]="commandEnabledClass" (click)=toggleActivate(true);>
                    <h3>Enabled &#10003;</h3>
                </mat-grid-tile>
            </mat-grid-list> -->
            </div>
        </div>
    </div>
    <div>
        <h2>{{ 'data.points' | translate}}</h2>
        <table>
            <thead>
                <tr>
                    <th *ngFor="let column of displayedColumns">
                        {{column}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let value of arguments">
                    <!-- Name Column -->
                    <td style="width: 35%;">
                        <input [(ngModel)]="value.name" placeholder="" style="width:100%;  margin-left: -5px; height: 32px;">
                    </td>
                    <!-- Description Column -->
                    <td style="width: 35%;">
                        <input [(ngModel)]="value.description" type="text" placeholder="" style="width:100%;  margin-left: -5px; height: 32px;">
                    </td>

                    <!-- Data Type Column -->
                    <td style="width: 30%; font-size: 14px;">
                        <select [(ngModel)]="value.type" id="argDataType" style="width:100%;  margin-left: -5px; height: 32px;">
                            <option value="">{{ 'select.data.type' | translate}}</option>
                            <option value="string"> {{ 'string' | translate}}</option>
                            <option value="integer"> {{ 'integer' | translate}}</option>
                            <option value="decimal"> {{ 'decimal' | translate}}</option>
                            <option value="bool"> {{ 'boolean' | translate}}</option>
                    </select>
                    </td>

                    <td style="cursor: pointer;">
                        <fa-icon (click)="removeDataPoint(arg)" [icon]="faTimesIcon"></fa-icon>
                    </td>
                </tr>
            </tbody>
        </table>
        <button class="btn btn-secondary margin-left-15" (click)="addDataPoint()" type="submit">{{ 'add.data.point' | translate}}</button>
        <div class="pull-right margin-right-15">
            <button class="btn btn-secondary" (click)="createCommand()" type="submit">{{ 'go.back' | translate}}</button>&nbsp;
            <button class="btn btn-primary" [disabled]="commandCreated" (click)="save()" type="submit">{{ 'save' | translate}}</button>
        </div>
    </div>
import { Component, OnInit, ViewChild, Input, Inject } from '@angular/core';
import { IotService } from '../service/iot.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { faCaretUp, faCaretDown, faInfoCircle, faQuestionCircle, faArrowUp, faArrowDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { faAngleDoubleLeft, faAngleDoubleRight,faToggleOff,faToggleOn,faCheck} from '@fortawesome/free-solid-svg-icons';
import { faHourglass, faHandPaper} from '@fortawesome/free-regular-svg-icons';

interface TypeChange {
  value: string;
  viewValue: string;
}

@Component({
  selector: '',
  template: '<p></p>',
  styleUrls: ['../base-list/baselist.component.scss', '../app.component.scss']
})

export abstract class BaseListComponent implements OnInit {
  sortColumns: string[] = [];
  dataSource: any;
  length = 1000;
  currentPage = 1;
  pageSizeOptions: number[] = [10, 15, 25, 100];
  results = [];
  supportLanguages = ['en'];
  sortDirection = "none";
  sortCriteria = "none";
  searchCriteria="";
  SortAscIcon = faCaretUp;
  sortDescIcon = faCaretDown;
  faInfoIcon = faInfoCircle;
  faQuestionCircleIcon = faQuestionCircle;
  faArrowUpIcon = faArrowUp;
  faArrowDownIcon = faArrowDown;
  selected: string;
  totalCount:number=0;
  pageClass='ot-pagination';
  showPagination:boolean=false;
  faToggleOffIcon = faToggleOff;
  faToggleOnIcon = faToggleOn;
  faCheckIcon = faCheck;
  faCloseIcon = faTimes;
  faApprove=faHandPaper;
  faWaiting=faHourglass;
  extraParamsMap = new Map();
  addSelectButtonLabel = "generic.column.title.select";
  timeout: any ;

  @Input() pageSize = 10;
  @Input() displayedColumns: string[] = [];
  @Input() showControlBar:boolean = true;
  @Input() showSelectColoumn:boolean = false;
  @Input() showEnableColumn:boolean = false;
  @Input() showActionColumn:boolean = false;
  @Input() addnQueryParam:string = "";
  @Input() multiSelect:string = "true";
  @Input() instance:string='0';
  @Input() pageTitle:string;
  @Input() showSearch:boolean = true;
  @Input() showNew:boolean = true;
  @Input() newWithRoute:boolean = true;
  @Input() showHeaderToolTips:boolean = true;
  @Input() showPaginationComponent:boolean = true;
  constructor(protected router: Router,
              public iotService: IotService, 
              protected translateService: TranslateService,
              protected sideNavComponent:SideNavComponent) {

    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }

  ngOnInit(): void {
    if(this.multiSelect == 'true'){
      this.addSelectButtonLabel = "generic.button.title.add";
    }
    this.prepareAddnQSMap();
    this.getCount();
    this.getResults();
  }
  
  getSearchResults(event: any){
   
    clearTimeout(this.timeout);
    const that = this;
    that.currentPage=1
    that.selected = '*' + event.target.value + '*';
    that.timeout = setTimeout(function () {    
      that.getFilteredResults(that.selected, that.sortCriteria, that.sortDirection);
      that.getCount();
      
    }, 1000);
   
  }

  getSortResults(columnName, sortOrder){
    const that = this;
    that.sortCriteria = columnName;
    that.sortDirection = sortOrder;
    that.getFilteredResults(that.selected, columnName, sortOrder);
   }

  getResults(){
    this.getFilteredResults('', 'none', 'none');
  }

  abstract getFilteredResults(value, columnName , sortOrder);

  abstract getCount();

  fnGetQS(value, columnName, sortOrder){
    const that = this;
    let extraQS = that.addnQueryParam;
    let qs = "[";
    qs = qs + "['pageSize', "+that.pageSize+"]";
    qs = qs + ", ['page', "+that.currentPage+"]";
    let orderSymbol = "+";
    if(sortOrder != 'none' && sortOrder == 'desc'){
      orderSymbol = "-";
    }
    if(value != "**" && value != "" && value != undefined) {
      qs = qs + ", ['"+that.searchCriteria+"', '"+value+"']";
    }
    if(columnName != 'none' && columnName != undefined && columnName != ''){
      qs = qs + ", ['sortBy', '"+orderSymbol+''+columnName+"']";
    }
    if(extraQS != "" && extraQS != undefined){
      //console.log(extraQS);
      qs = qs + that.getAdditionalQueryParams(extraQS);
    }
    qs = qs + "]";
    //console.log("qs -"+qs);
    return qs;
  }

  getAdditionalQueryParams(extraQS){
    let queryParams = "";
    if(extraQS != "" && extraQS != undefined){
      extraQS = extraQS.replace("[","").replace("]","");
      let qsParameters = extraQS.split("^^"); // Splitting multiple key-value 
      for(let qsIndex = 0; qsIndex < qsParameters.length; qsIndex++){
        let keyvaluePairArray = qsParameters[qsIndex].split("::"); //splitting key and its value
        let fieldName = keyvaluePairArray[0];
        let fieldvalue = keyvaluePairArray[1];
        if(fieldvalue.includes(",")){
           let qaArray = fieldvalue.split(","); // splitting multiple values for same key
           for (let index = 0; index < qaArray.length; index++) {
              queryParams = queryParams + ", ['"+fieldName+"','"+qaArray[index]+"']";
            }
        }else{
          queryParams = queryParams + ", ['"+fieldName+"','"+fieldvalue+"']";
        }
      }
    }
    return queryParams;
  }

  fnSuccess(response){
      const that = this;
      that.results = response;
      that.dataSource = response;
      //console.log("response from -"+response);
  }

  fnError(err){
    if (err.responseJSON.apiStatusCode) {
      console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
    } else {
      console.log("Something went wrong. Please check chrome console or check with dev team");
    }
  }

  fnResetSortParam(columnName, sortOrder){
    const that = this;
    let columns = that.sortColumns;
    for (var col of columns) {
      if(col == columnName){
        if(columnName != 'none' && columnName != '' && columnName != undefined){
          let fieldName = columnName.charAt(0).toUpperCase()+columnName.substring(1, columnName.length);
          //console.log('that.'+fieldName+' = true');
          eval('that.'+fieldName+' = true;');
          if(sortOrder == 'asc'){
            eval('that.asc'+fieldName+' = true;');
            eval('that.desc'+fieldName+' = false;');
            eval('this.asc'+fieldName+' = true;');
          }else{
            eval('that.desc'+fieldName+' = true;');
            eval('this.desc'+fieldName+' = true;');
            eval('that.asc'+fieldName+' = false;');
          }
        }
      }else{
        let fieldName = col.charAt(0).toUpperCase()+col.substring(1, col.length);
       // console.log('that.asc'+fieldName+' = false;');
        //console.log('that.desc'+fieldName+' = false;');
        eval('that.asc'+fieldName+' = false;');
        eval('that.desc'+fieldName+' = false;');
      }
    }
  }
   
  // on page change.
  onPageChange(page) {
    const that = this;
    that.currentPage = page;
    that.getFilteredResults(that.selected, that.sortCriteria, that.sortDirection);
  }

  // on page size change.
  onPageSizeChange(pageSize) {
    const that = this;
    that.pageSize = pageSize;
    that.getFilteredResults(that.selected, that.sortCriteria, that.sortDirection);
  }

  prepareAddnQSMap(){
    let extraQS = this.addnQueryParam;
    if(extraQS != "" && extraQS != undefined){
      extraQS = extraQS.replace("[","").replace("]","");
      
      let qsParameters = extraQS.split("^^"); // Splitting multiple key-value 
      for(let qsIndex = 0; qsIndex < qsParameters.length; qsIndex++){
        let keyvaluePairArray = qsParameters[qsIndex].split("::"); //splitting key and its value
        let fieldName = keyvaluePairArray[0];
        let fieldvalue = keyvaluePairArray[1];
        this.extraParamsMap.set(fieldName,fieldvalue);
        if(fieldvalue.includes(",")){
            let commaSeperatedValue;
            let qaArray = fieldvalue.split(","); // splitting multiple values for same key
            for (let index = 0; index < qaArray.length; index++) {
            commaSeperatedValue = commaSeperatedValue + ','+qaArray[index];
            }
            this.extraParamsMap.set(fieldName,commaSeperatedValue);
        }else{
          this.extraParamsMap.set(fieldName,fieldvalue);
        }
      }
    }
  }

  camelize(input){
    input = input.split(" ");
    var output = "";
    input = input.forEach(function(value ,index , array){
        return output += value.charAt(0).toUpperCase() + value.slice(1 , value.length);
    });
    
    return output;
   }
}

/**
 * This is an auto generated code for documentInfo listing page.
 * Please don't make any change to this file.
 * @version 1.0.0
 * @readonly
 * @author - System generated
 */

import { Component, OnInit, ViewChild, Injector, Inject} from '@angular/core'
import { IotService } from '../service/iot.service'
import { DocumentInfoService } from '../service/documentInfo.service'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { faCaretUp, faCaretDown, faInfoCircle, faQuestionCircle, faArrowUp, faArrowDown, faSearch } from '@fortawesome/free-solid-svg-icons'
import { SideNavComponent } from '../side-nav/side-nav.component'
import { BaseListComponent } from '../base-list/baselist.component'

interface TypeChange {
	value: string;
	viewValue: string;
}
@Component({
	selector: 'app-documentInfo',
	templateUrl: './documentInfo.component.html',
	styleUrls: ['../base-list/baselist.component.scss','./documentInfo.component.scss']
})
export class DocumentInfoComponent extends BaseListComponent {
	searchCriteria="name";
	displayedColumns: string[] = ['name','supportedDocType','supportedDocTemplateType','docTemplateVersion','creation','ACTIVE'];
	sortColumns: string[] = ['name','creation'];
	pageTitle="documentInfo.header";
	ascName:boolean = false;
	descName:boolean = false;
	ascCreation:boolean = false;
	descCreation:boolean = false;
	countCallCompleted:boolean = false;
	getCallCompleted:boolean = false;
	showEnableColumn = true;
	documentInfoService:any;

	constructor( router: Router,
				iotService: IotService,
				translateService: TranslateService,
				sideNavComponent:SideNavComponent,
				@Inject('documentInfoService1') public firstDocumentInfoService: DocumentInfoService,
				@Inject('documentInfoService2') public secondDocumentInfoService: DocumentInfoService){
				super(router, iotService, translateService, sideNavComponent )
						this.translateService.addLangs(this.supportLanguages);
						this.translateService.setDefaultLang('en');
	}

	ngOnInit(): void {
		super.ngOnInit();
		if(this.instance=="1"){
			this.documentInfoService=this.firstDocumentInfoService;
		}
		else if(this.instance=="2"){
			this.documentInfoService=this.secondDocumentInfoService;
		}
		else{
			this.documentInfoService=this.firstDocumentInfoService;
		}
		this.documentInfoService.selection.clear();
		this.documentInfoService.instanceValue = this.instance;
		this.documentInfoService.multiSelectRows = this.multiSelect;
		this.documentInfoService.loadSelected();
		if(!this.showSelectColoumn){
			this.sideNavComponent.menuClear();
			this.sideNavComponent.menuChange('Dashboard','sub-type');
			this.sideNavComponent.menuChange('documentInfo.header','sub-type');
		}
	}
	getFilteredResults(value, columnName , sortOrder){
		const that = this;
		that.iotService.getCuiObjResponse().getDocumentInfo({
			qs: eval(that.fnGetQS(value, columnName, sortOrder))
		}).then(function (response) {
			that.fnSuccess(response);
			if(columnName == 'none' || columnName == '' || columnName == undefined){
				that.fnResetSortParam('creation', 'desc');
			}else{
				that.fnResetSortParam(columnName, sortOrder);
			}
			that.getCallCompleted = true;
		}).fail(function (err) {
			that.fnError(err);
			that.getCallCompleted = true;
		});
	}
	getCount() {
		const that = this;
		that.showPagination=false;
		let value = this.selected;
		that.iotService.getCuiObjResponse().countDocumentInfo({
			qs: eval(that.fnGetQS(value, '', ''))
		}).then(function (response) {
				that.totalCount = response;
				that.countCallCompleted = true;
			if(that.totalCount > 0)
				that.showPagination=true;
			}).fail(function (err) {
				that.fnError(err);
				that.countCallCompleted = true;
			});
	}
	activateDocumentInfo(resourceId){
		const that = this;
		that.iotService.getCuiObjResponse().activateDocumentInfo({
			docInfoId: resourceId
		}).then(function (response) {
			that.getFilteredResults(that.selected, that.sortCriteria, that.sortDirection);
			that.documentInfoService.fnResetList();
		}).fail(function (err) {
			that.fnError(err);
		});
	}
	deactivateDocumentInfo(resourceId){
		const that = this;
		that.iotService.getCuiObjResponse().deactivateDocumentInfo({
			docInfoId: resourceId
		}).then(function (response) {
			that.getFilteredResults(that.selected, that.sortCriteria, that.sortDirection);
			that.documentInfoService.fnResetList();
		}).fail(function (err) {
			that.fnError(err);
		});
	}
	viewDetails(selectedId){
		this.router.navigateByUrl("/documentInfo/"+selectedId);
		this.documentInfoService.viewDetails(selectedId);
	}
	createNew(){
		this.documentInfoService.createNew();
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}
	onSelection(results){
		this.documentInfoService.onSelection(results);
	}
	removeSelection(results){
		this.documentInfoService.removeSelection(results);
	}
	changeActive(resourceId, isActivate){
		if(isActivate == 'true'){
			this.deactivateDocumentInfo(resourceId);
		}else{
			this.activateDocumentInfo(resourceId);
		}
	}
}

import { Injectable } from '@angular/core';

@Injectable({
providedIn: 'root'
})
export class IotService {

constructor() { }

private cuiObjResponse;

realm:string='';

creator:string='';

creatorAppId:string='';

displayUserName:string='';

deviceDashboard: boolean=false;

newDeviceTemplate: boolean=false;

viewDeviceTemplate:boolean=false;

sideNavStream:boolean = true;

deviceStream:boolean = false;

createRoute:boolean = false;

viewGeofence: boolean=false;

createTradingPartner:boolean=false;

createStream:boolean=false;

createMessageGovernance:boolean = false;

viewApplication:boolean = false;

createApplication:boolean = false;

viewEventGroup:boolean = false;

createEventGroup:boolean = false;

viewRule:boolean = false;

createRule:boolean = false;

createGatewayRule:boolean = false;

createEcosystemTemplate:boolean = false;

viewEcosystemTemplate:boolean = false;

createEcosystem:boolean = false;

createGeofence: boolean=false;

viewEcosystem:boolean = false;

viewDashboard:boolean=false;

createEcosystemFromTemplate = false;

parentTemplateIdForEcosystem:string='';

createEcosystemMembership = false;

msgGovLoader:boolean=false;
auditScriptFlag:boolean=false;
auditscript:String="";
ecosystemTemplateAddRulePolicy=false;
ecosystemAddRulePolicy=false;
gatewayAgentAddRulePolicy=false;
ecosystemId:string="";
ecosystemTemplateId:string="";
gatewayInfoId:string="";
viewRulePolicy:boolean=false;
ecoviewerId;
showEcoviewer:boolean=false;
digitalTwinTemplate:boolean = false;
digitalTwin:boolean = false;

/**
 * Use to get the realm
 */
setRealm(realm:any){
  this.realm = realm;
}

/**
 * Use to set the realm
 */
getRealm():any{
  return this.realm;
}

/**
 * Use to set the Creater
 */
getCreator():any{
  return this.creator;
}
/**
 * Use to get the Creater
 */
setCreator(creator:any){
  this.creator = creator;
}

/**
 * Use to get the Creater App Id
 */
getCreatorAppId():any{
  return this.creatorAppId;
}
/**
 * Use to set the Creater App Id
 */
setCreatorAppId(creatorAppId:any){
  this.creatorAppId = creatorAppId;
}

/**
 * Use to get the user name for logged in user
 */
getdisplayUserName():any{
  return this.displayUserName;
}
/**
 * Use to set the user name for logged in user
 */
setdisplayUserName(displayUserName:any){
  this.displayUserName = displayUserName;
}

/**
 * Use to set the data found in the second file (config file)
 */
public setCuiObjResponse(cuiObj){
this.cuiObjResponse = cuiObj;
}

/**
 * Use to get the data found in the second file (config file)
 */
public getCuiObjResponse() {
  return this.cuiObjResponse;
}

}

<div class="tradingPartnerCodeData">
    <div class=form>
        <label for="code"> {{ 'code' | translate}}</label>
        <input placeholder="required" [(ngModel)]="tradingPartnerCode.code" (click)="resetData()" id="code" type="text" aria-label="code">
        <p *ngIf="codeError" style="color: red;">{{codeError}}</p>

        <label for="qualifier"> {{ 'qualifier' | translate}}</label>
        <textarea rows="2" [(ngModel)]="tradingPartnerCode.qualifier" placeholder="optional" id="qualifier" aria-label="qualifier"></textarea>

        <label for="description"> {{ 'description' | translate}}</label>
        <textarea rows="2" [(ngModel)]="tradingPartnerCode.description[0].text" placeholder="optional" id="description" aria-label="Description"></textarea>

        <label>{{'enabled'|translate}}</label>
        <div *ngIf="isActive" style="margin-left: -5px;">
            <button class="toggle-on toggle-switch" (click)="toggleActive()">
                <fa-icon [icon]="faCheckIcon" class="fa-2x"></fa-icon>
                <div class="toggle-on-btn"></div>
            </button>
        </div>

        <div *ngIf="!isActive" style="margin-left: -5px;">
            <button class="toggle-off toggle-switch" (click)="toggleActive()">
                <div class="toggle-off-btn"></div>
            </button>
        </div>

        <br>
        <div class="button-row pull-right" >
          
            <button class="btn-secondary" (click)="goBack()">{{ 'go.back' | translate}}</button>
            <button class="btn-primary" (click)="save()">{{ 'save' | translate}}</button>
            <mat-checkbox  [(ngModel)]="createAnother">{{'create.another' | translate}}</mat-checkbox>

        </div>
        
        <br>
        <br>
    </div>
</div>
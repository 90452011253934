import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SecurtiyService } from '../service/security.service';
import { SideNavComponent } from '../side-nav/side-nav.component';

@Component({
  selector: 'app-certificates',
  templateUrl: './certificates.component.html',
  styleUrls: ['./certificates.component.scss']
})
export class CertificatesComponent implements OnInit {

  supportLanguages = ['en'];

  constructor(private translateService: TranslateService,public  securityService: SecurtiyService, private sideNavComponent: SideNavComponent) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
      this.sideNavComponent.menuClear();
      this.sideNavComponent.menuChange('Dashboard','sub-type');
      this.sideNavComponent.menuChange('certificates.header','sub-type');
  }

  ngOnInit(): void {
    this.securityService.showAuthorities = true;
    this.securityService.showCertificates = false;
    this.securityService.showKeyBundles=false;
  }

  createNewTrustedAuthority(){
    console.log("Navigate to authority page")
  }

  
  createNewTrustedCertificate(){
    console.log("Navigate to certificate page")
  }


  tabClick(tab) {
		console.log(tab.index);
		if (tab.index == 0) {
			console.log("auth");
			this.securityService.showAuthorities = true;
			this.securityService.showCertificates = false;
      this.securityService.showKeyBundles=false;
		}
		else if (tab.index == 1) {
			console.log("cert");
			this.securityService.showCertificates = true;
			this.securityService.showAuthorities = false;
      this.securityService.showKeyBundles=false;
		}
    else if (tab.index == 2) {
			console.log("key bundles");
			this.securityService.showCertificates = false;
			this.securityService.showAuthorities = false;
      this.securityService.showKeyBundles=true;
		}
	}
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IotService } from '../../service/iot.service';
import { DeviceService } from '../../service/device.service';
import { faAngleDoubleLeft, faAngleDoubleRight,faToggleOff,faToggleOn,faCheck} from '@fortawesome/free-solid-svg-icons';
import {SelectionModel} from '@angular/cdk/collections';
import { TranslateService } from '@ngx-translate/core';
import { OrchestrationConfigsService } from '../../service/orchestrationConfigs.service';


@Component({
  selector: 'app-connection-list',
  templateUrl: './connection-list.component.html',
  styleUrls: ['./connection-list.component.scss']
})
export class ConnectionListComponent implements OnInit {

  dataSource: any;
  displayedColumns: string[] = ['ADD','Name', 'Encryption', 'Stream Type', 'Direction', 'Protocol', 'Enabled'];
  length = 1000;
  faAngleDoubleLeftIcon = faAngleDoubleLeft;
  faAngleDoubleRightIcon = faAngleDoubleRight;
  faToggleOffIcon = faToggleOff;
  faToggleOnIcon = faToggleOn;
  faCheckIcon = faCheck;
  pageSize = 5;
  currentPage =1;
  streams = [];
  streamSelected:string;
  selectedStreams=[];
  selection: SelectionModel<Element> = new SelectionModel<Element>(true, []);

  streamCount:number=0;
  showPagination=false;

  supportLanguages = ['en'];

  constructor(private iotService:IotService,private deviceService:DeviceService,private translateService: TranslateService,
    private orchestrationConfigsService: OrchestrationConfigsService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
   }

  ngOnInit(): void {
    this.getStreams();
    if(this.deviceService.getStreamData()!=undefined){
      this.deviceService.getStreamData().forEach((value,index)=>{
        this.selectedStreams.push(value);
       });
       this.selection=new SelectionModel<Element>(true, this.selectedStreams);
    }
    this.selection.isSelected = this.isChecked.bind(this);
    this.countStreams('');
  }

  isChecked(row: any): boolean {
    const found = this.selection.selected.find(el => el.id === row.id);
    if (found) {
      return true;
    }
    return false;
 }


  @Output() messageToEmit = new EventEmitter<boolean>();


  onClickContinue(message:boolean){
    this.messageToEmit.emit(message);

  }

  getStreams() {
    const that = this;
    this.iotService.getCuiObjResponse().getStreams({
      qs:[['pageSize',5],['page',this.currentPage],['sortBy', '-creation'],['streamType', 'DEVICE']]
    }).then(function(response) {
      // console.log(response);
      that.streams = response;
      that.dataSource = that.streams;
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  onStreamSearch(event: any){
    console.log('onTemplateSearch');
    console.log(event.target.value);
    this.streamSelected = event.target.value;
    let val = '*'+event.target.value+'*';
    const that = this;
    that.countStreams(val);
    that.currentPage =1;
    this.iotService.getCuiObjResponse().getStreams({
      qs:[['pageSize',5],['page',this.currentPage],['name',val]]
    }).then(function(response) {
      // console.log(response);
      that.streams = response;
      that.dataSource = that.streams;
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  getSelectedStreamForCurrentPage(streamSelected){
    this.streamSelected = streamSelected;
    let name = '*'+streamSelected +'*';
    const that = this;
    this.iotService.getCuiObjResponse().getStreams({
      qs:[['pageSize',5],['page',this.currentPage],['name',name]]
    }).then(function(response) {
      // console.log(response);
      that.streams = response;
      that.dataSource = that.streams;
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  selectStream(event, streamId) {
    const that = this;
    console.log(event); //true or false

    this.iotService.getCuiObjResponse().getStream({
      streamId: streamId
    }).then(function (response) {
      // console.log(response);
      // that.deviceService.streamSelect=true;
      if (event == true) {
        that.selection.select(response);
        that.deviceService.streamSelect = true;
        that.selectedStreams.push(response);
      }
      else {
        that.selection.clear();
        that.selectedStreams.forEach((value, index) => {
          if (value.id == streamId) that.selectedStreams.splice(index, 1);
        });
        that.selectedStreams.forEach((value, index) => {
          that.selection.select(value);
        });
      }
      that.deviceService.setStreamData(that.selectedStreams);
      if (that.deviceService.getStreamData().length < 1) {
        that.deviceService.streamSelect = false;
      }
      that.orchestrationConfigsService.setStreamData(that.selectedStreams);

    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  disableStream(stream:any,streamId : any){
    const that = this;
    this.iotService.getCuiObjResponse().deactivateStream({
      streamId: streamId
    }).then(function(response) {
      // console.log(response);
      that.selection.clear();

      that.selectedStreams.forEach((value,index)=>{
        if(value.id==streamId) that.selectedStreams.splice(index,1);
      that.deviceService.setStreamData(that.selectedStreams);
      that.orchestrationConfigsService.setStreamData(that.selectedStreams);
    });
    that.selectedStreams.forEach((value, index) => {
      that.selection.select(value);
    });
    if(that.deviceService.getStreamData()!=undefined && that.deviceService.getStreamData().length<1){
      that.deviceService.streamSelect=false;
    }
      if(that.streamSelected){
        that.getSelectedStreamForCurrentPage(that.streamSelected)
      }
      else{
        that.getStreams();
      }
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  enableStream(streamId : any){
    const that = this;
    this.iotService.getCuiObjResponse().activateStream({
      streamId: streamId
    }).then(function(response) {
      // console.log(response);
      that.getStreams();
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  countStreams(val) {
    const that = this;
    that.showPagination=false;
    if(val === "**") {
      val = '';
    }
    if(val === '') {
      that.iotService.getCuiObjResponse().countStreams({
      }).then(function (response) {
        console.log(response);
        that.streamCount = response;
        that.showPagination=true;
      }).fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
        } else {
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    } else {
      that.iotService.getCuiObjResponse().countStreams({
        qs:[['name',val]]
      }).then(function (response) {
        console.log(response);
        that.streamCount = response;
        that.showPagination=true;
      }).fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
        } else {
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    }
  }

  onPageChange(page) {
    const that = this;
    console.log("page : "+page);
    that.currentPage = page;
    if(this.streamSelected) {
      console.log("streamSelected"+this.streamSelected);
      this.getSelectedStreamForCurrentPage(this.streamSelected);
    } else {
      console.log("else not streamSelected");
      this.getStreams();
    }
  }

}

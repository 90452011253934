import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import * as _ from 'node_modules/lodash';
import { AppConfig } from './../config/AppConfig';
import {Router} from '@angular/router';
import { IotService } from './service/iot.service';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Location } from "@angular/common";
import { TranslateService } from '@ngx-translate/core'

declare var cui: any

var appConf: any
var host: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Opentext IOT Cloud Console';
  cuiObj : any = [];

  constructor(
    private appconfig: AppConfig,
    platformLocation: PlatformLocation,
    private httpClient: HttpClient,
    private iotService:IotService,
    private router: Router,
    private location: Location,
    private translateService: TranslateService) {
      appConf = appconfig;
      host = platformLocation;
    }

  ngOnInit(){
    console.log("Console Logging router link -> "+window.location.href);
    console.log("platformLocation -- "+this.location.path());
    var location = this.location.path();
    if(!location) {
      this.getDevices();
    }
    // else if(location.includes('xsrfToken') || location.includes('cuid')) {
    //   this.getDevices();
    // }
    else if(location.includes('getting-started')) {
      this.router.navigateByUrl("/getting-started");
      this.showSidenav=false;
      this.showPageLoading=false;
    }
    else {
      this.showPageLoading=false; 
      this.showSidenav=true;
    }
  }

  showSidenav;
  devices=[];
  showPageLoading=true;

  getMessage(message: boolean) {
    console.log("get message -- "+message);
    this.showSidenav = message;
  }

  getDevices(){
    const that = this;
    this.iotService.getCuiObjResponse().getDevices({
      qs:[['pageSize',1],['page',1]]
    }).then(function(response) {
      console.log(response);
      that.devices = response;
      if(that.devices.length>0){
        console.log(" device length "+ that.devices.length)
        that.showSidenav=true;
        that.router.navigateByUrl("/dashboard");
      }
      else{
        that.showSidenav=false;
        that.router.navigateByUrl("/getting-started");
      }
      that.showPageLoading=false; 
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        alert("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }
    
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IotService } from 'src/app/service/iot.service';
import { SideNavComponent } from 'src/app/side-nav/side-nav.component';
import { faCopy } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-cerificate-detail',
  templateUrl: './cerificate-detail.component.html',
  styleUrls: ['./cerificate-detail.component.scss']
})
export class CerificateDetailComponent implements OnInit {

  supportLanguages = ['en'];
  certificate: any;
  faCopyIcon = faCopy;
  isRevoke = false;
  isPrivateKey = false;
  trustedPrivateKey;
  privateKey="";
  PrivateKey;
  privateKeyLoaded=false;
  successMessage;
  errorMessage;

  constructor(private router: Router, private route: ActivatedRoute, private iotService: IotService,
    private sideNavComponent: SideNavComponent, private translateService: TranslateService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }


  ngOnInit() {
    this.route.params.subscribe(params => {
      var certificateId = params['id'];
      this.loadCertificate(certificateId);
      this.loadPrivateKey(certificateId);
      this.initPrivateKey(certificateId);
    });
   
  }


  loadPrivateKey(certificateId: any) {
    const that=this;
    that.iotService.getCuiObjResponse().getTrustedPrivateKeys({
      qs:[['parentCertificateId',certificateId]]
    }).then(function (response) {
      console.log(response);
      if(response.length>0){
        that.trustedPrivateKey = response[0];
        that.privateKeyLoaded=true;
      }

    })
   
  }

  loadCertificate(certificateId: any) {
    const that = this;
    this.iotService.getCuiObjResponse().getx509Certificate({
      certificateId: certificateId
    }).then(function (response) {
      console.log(response);
      that.certificate = response;
      that.sideNavComponent.menuClear();
      that.sideNavComponent.menuChange('Dashboard', 'sub-type');
      that.sideNavComponent.menuChange('certificates.header', 'sub-type');
      that.sideNavComponent.menuChange('trustedCertificates.header', 'sub-type');
      that.sideNavComponent.menuChange(that.certificate.subject, 'sub-type');
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }


  initPrivateKey(certificateId: any){
    const that = this;
    that.PrivateKey = {
      "creator": that.iotService.getCreator(),
      "creatorAppId": that.iotService.getCreatorAppId(),
      "realm": that.iotService.getRealm(),
      "parentCertificateId": certificateId,
      "privateKey":''

    }

  }

  revokex509Certificate(certificateId: any) {
    const that = this;
    this.iotService.getCuiObjResponse().revokex509Certificate({
      certificateId: certificateId
    }).then(function (response) {
      console.log(response);
      that.certificate = response;
      window.location.reload();
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  toggleRevoke() {
    this.isRevoke = !this.isRevoke;
  }

  addPrivateKey() {
    this.isPrivateKey = true;
  }

  addPrivateKeyData(event:any){
    this.privateKey=event.target.value
      this.errorMessage=""
  }

  addTrustedPrivateKey(certificateId) {
    const that = this;
    that.initPrivateKey(certificateId);
    if(!that.privateKey){
     that.errorMessage="Private Key PEM Content Required";
    }
    else{

      let Cert = that.privateKey+"";
      Cert = Cert.replace(/(\r\n|\n|\r)/gm,"");
      // Remove BEGIN and END 
      Cert = Cert.replace("-----BEGIN PRIVATE KEY-----","");
      Cert = Cert.replace("-----END PRIVATE KEY-----","");
      // Remove All Line Breaks
   
      console.log(Cert);
      that.PrivateKey.privateKey = Cert;
      
  
    that.iotService.getCuiObjResponse().createTrustedPrivateKey({
      data: that.PrivateKey
    }).then(function (response) {
      console.log(response);
      that.trustedPrivateKey = response;
      that.successMessage="Private Key Added successfully";
      that.isPrivateKey = false;
      window.location.reload();
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.errorMessage=err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        that.errorMessage="Error occured while adding private key";
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
      if(that.errorMessage==''){
         that.errorMessage="Error occured while adding private key";
      }
    });
  }
}

}

<div class="ot-container iot-pagination">
    <nav id="pagination-wrapper" aria-label="Page navigation" class="ot-pagination iot-pagination-nav" [ngClass]="pageClass">
        <div class="ot-pagination-menu" (click)="selectPage(pageClass)" *ngIf="pageSizeOptions">
            <div class="ot-pagination-menu__container">
                <a class="ot-pagination-menu-content__item">{{pageSize}} per page</a>
                <div class="ot-pagination-menu-content">
                    <ng-container *ngFor="let element of pageSizeOptions">
                        <ng-container *ngIf="element === pageSize">
                            <a (click)="onPageSizeChange(element)" class="ot-pagination-menu-content__item ot-pagination-menu-content__item--is-active">{{element}} per page</a>
                        </ng-container>
                        <ng-container *ngIf="element !== pageSize">
                            <a (click)="onPageSizeChange(element)" class="ot-pagination-menu-content__item">{{element}} per page</a>
                        </ng-container>  
                    </ng-container>
                </div>
            </div>
        </div>
        <ul class="ot-pagination-list">
            &nbsp;&nbsp;&nbsp;
            <ng-container *ngIf="!firstPage">
                <li (click)="prevPage()" class="ot-pagination-list__item ot-pagination-list__item--is-nav">
                    <a>{{ 'previous' | translate}}</a>
                </li>
            </ng-container>
            <ng-container *ngFor="let element of pageListOptions">
                <ng-container *ngIf="element === currentPage">
                    <li class="ot-pagination-list__item ot-pagination-list__item--is-active">
                        <a>{{element}}</a>
                    </li>
                </ng-container>
                <ng-container *ngIf="element !== currentPage">
                    <li (click)="onPageChange(element)" class="ot-pagination-list__item">
                        <a>{{element}}</a>
                    </li>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="!lastPage">
                <li (click)="nextPage()" class="ot-pagination-list__item ot-pagination-list__item--is-nav">
                    <a>{{ 'next' | translate}}</a>
                </li>
            </ng-container>
        </ul>
        <div class="ot-pagination__sum">
            <span>{{count}} items </span>
        </div>
    </nav>
</div>
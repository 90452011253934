<main id="application-details">
  <div class="d-flex justify-content-center loader" *ngIf="!application">
    <div class="spinner-border" role="status">
      <span class="sr-only" id="loading"></span>
    </div>
  </div>
</main>
<mat-sidenav-container autosize *ngIf="application">
  <mat-sidenav class="midNav-container" #sidenav mode="side" disableClose="true" opened="true">
 
    <div class="alert critical" *ngIf="applicationsService.errorMessage">
      <h5>
          <fa-icon [icon]="faCriticalIcon"></fa-icon>
          <span>{{applicationsService.errorMessage}}</span>
          <button class="alert-close-btn">
              <fa-icon (click)=applicationsService.resetMessage() [icon]="faCloseIcon"></fa-icon>
          </button>
      </h5>
    </div>
    <div class="alert success" *ngIf="applicationsService.successMessage">
        <h5>
            <fa-icon [icon]="faSuccessIcon"></fa-icon>
            <span>{{applicationsService.successMessage}}</span>
            <button class="alert-close-btn">
                <fa-icon (click)=applicationsService.resetMessage() [icon]="faCloseIcon"></fa-icon>
            </button>
        </h5>
    </div>
    <div class="alert warning" *ngIf="applicationsService.warningMessage">
        <h5 style="font-size: 12px;">
            <fa-icon [icon]="faWarningIcon"></fa-icon>
            <span>{{applicationsService.warningMessage}}</span>
            <button class="alert-close-btn">
                <fa-icon (click)="applicationsService.resetMessage()" [icon]="faCloseIcon"></fa-icon>
            </button>
        </h5>
    </div>

    <section>
      <app-copy-resource-id></app-copy-resource-id>
      <dl>
        <dt>{{'name' | translate}}</dt>
        <dd>
          <p style="font-size: 12px;">{{application.name[0].text}}</p>
        </dd>
      </dl>
      <dl>
        <dt>{{'description' | translate}}</dt>
        <dd>
          <p>{{application.description[0].text}}</p>
        </dd>
      </dl>
      <label for="application-tags">{{'tags' | translate}}</label>
      <mat-chip-list #tagList aria-label="tags">
        <mat-chip class="ot-chip" *ngFor="let tag of application.tags" [selectable]="selectable" [removable]=true (removed)="remove(tag)">
          {{tag}}
          <button matChipRemove></button>
        </mat-chip>
        <input [ngClass]="tagClass" placeholder="add a tag" #tagInput [matChipInputFor]="tagList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="add($event)">
      </mat-chip-list>
      <dl>
        <dt>{{'created' | translate}}</dt>
        <dd>{{application.creation | date:'mediumDate' }}</dd>
      </dl>
      <hr>
      <br>
      <button class="btn btn-secondary" style="margin-left: 20px;" (click)="createNewStream()" aria-label="Create stream">{{'create.stream' | translate}}</button>
      <button class="btn btn-delete pull-right margin-right-15" (click)="deleteApplication(application.id)">{{'delete' | translate}}</button>
    </section>
    <aside>
      <hr *ngIf="applicationStream">
      <!-- <p>Streams</p>
      <ng-container *ngIf="!applicationStream">
        <p>Select a stream to add from the Streams table on the right.</p>
      </ng-container> -->
      <ng-container *ngIf="applicationStream">
        <p style="font-size: 14px; font-weight: 600;">{{'streams.header' | translate}}</p>
        <dl>
          <dt>{{'stream.name' | translate}}</dt>
          <dd><a title="View Stream Details">{{applicationStream.name[0].text}}</a></dd>
        </dl>

        <dl>
          <dt>{{'protocol.type' | translate}}</dt>
          <dd>{{applicationStream.protocolType}}</dd>
        </dl>

        <dl>
          <dt>{{'protocol.security.type' | translate}}</dt>
          <dd>{{applicationStream.protocolSecurityType}}</dd>
        </dl>

        <dl>
          <dt>{{'encryption' | translate}}</dt>
          <dd>{{applicationStream.payloadSecurityType}}</dd>
        </dl>

        <dl>
          <dt>{{'producer.topic' | translate}}</dt>
          <dd>
            <ng-container *ngIf="applicationStream.producerTopic">{{applicationStream.producerTopic}}</ng-container>
            <ng-container *ngIf="!applicationStream.producerTopic">-</ng-container>
          </dd>
        </dl>

        <dl>
          <dt>{{'consumer.topic' | translate}}</dt>
          <dd>
            <ng-container *ngIf="applicationStream.consumerTopic">{{applicationStream.consumerTopic}}</ng-container>
            <ng-container *ngIf="!applicationStream.consumerTopic">-</ng-container>
          </dd>
        </dl>
        <label for="application-subscriptions">{{'eventGroups.header' | translate}}</label>
        <mat-chip-list aria-label="EventGroups">
          <mat-chip class="ot-chip" *ngFor="let eventGroup of getSubscriptions(applicationStream)" [selectable]="selectable" [removable]=true (removed)="removeSubscriptions(eventGroup)">
            {{eventGroup.eventGroupName}}
            <button matChipRemove></button>
          </mat-chip>
        </mat-chip-list>
        <br>
        <nav class="connectionApplication">
          <a class="pull-left" (click)="prevStreamPage();" ng-disabled="1 == 2">
            <fa-icon [icon]="faAngleLeftIcon"></fa-icon>
          </a>
          <span>{{'stream' | translate}} {{currentApplicationStream+1}} {{'of' | translate}} {{streamApplicationResponse.length}}</span>
          <a class="pull-right" (click)="nextStreamPage();" ng-disabled="1 == 2">
            <fa-icon [icon]="faAngleRightIcon"></fa-icon>
          </a>
        </nav>
      </ng-container>
    </aside>
  </mat-sidenav>
  <mat-sidenav-content class="right-column" style="padding-right: 10px;">
    <ng-container>
      <app-eventGroups  *ngIf="showEventGroupCreationPage"
      [pageSize]="5"
      [showControlBar]=true
      [displayedColumns]="['ADD','name','description','ACTIVE']"
      showSelectColoumn=true
      showEnableColumn=true
      multiSelect="true" [showHeaderToolTips]=false>
      </app-eventGroups>
      <app-create-stream *ngIf=showStreamCreationPage></app-create-stream>
    </ng-container>

  </mat-sidenav-content>
</mat-sidenav-container>

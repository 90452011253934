import { Injectable } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { SecurtiyService } from './security.service';
import { IotService } from '../service/iot.service';
import { ListTrustedAuthoritiesService } from './ListTrustedAuthorities.service';

@Injectable({
	providedIn: 'root'
})

export class TrustedAuthoritiesService extends ListTrustedAuthoritiesService {

	constructor(public iotService: IotService, public securityService: SecurtiyService) {
		super(iotService);
		this.loadSelected();
	}

	index = -1;

	loadSelected() {
		if (this.securityService.getKeyBundle()) {
			let keyBundle = this.securityService.getKeyBundle();
			let authorities = keyBundle.trustedAuthorities;
			authorities.forEach((authority) => {
				this.onSelection(authority);
			});
		}
	}

	onSelection(results) {
		const that = this;
		that.fnOnSelectionSupport(results);
		// that.playRecord(results);
	}
	fnOnSelectionSupport(authority) {
		const that = this;

		let keyBundle = that.securityService.getKeyBundle();
		if (keyBundle) {
			that.index = that.findById(keyBundle.trustedAuthorities, authority);
			if (that.index < 0) {
				keyBundle.trustedAuthorities.push(authority);
				that.securityService.setKeyBundle(keyBundle);
			}
			that.selection.select(authority.id);
			that.securityService.currentAuthority = 0;
			if (that.securityService.getKeyBundle() && that.securityService.getKeyBundle().trustedAuthorities) {
				that.securityService.trustedAuthority = that.securityService.getKeyBundle().trustedAuthorities[0];
			}
		}
	}
	removeSelection(results) {
		const that = this;
		that.fnRemoveSelectionSupport(results);
		// this.playRecord(results);
	}
	fnRemoveSelectionSupport(authority) {
		const that = this;
		let keyBundle = that.securityService.getKeyBundle();
		if (keyBundle) {
			that.index = that.findById(keyBundle.trustedAuthorities, authority);
			if (that.index >= 0) {
				keyBundle.trustedAuthorities.splice(that.index, 1);
				that.securityService.setKeyBundle(keyBundle);
			}
			that.selection.clear();
			let authorites = that.securityService.getKeyBundle().trustedAuthorities;
			authorites.forEach(authority => {
				that.selection.select(authority.id);

			});
			if (authorites.length == 0) {
				that.securityService.setAuthority(undefined);
			}
			that.securityService.currentAuthority = 0;
			if (that.securityService.getKeyBundle() && that.securityService.getKeyBundle().trustedAuthorities) {
				that.securityService.trustedAuthority = that.securityService.getKeyBundle().trustedAuthorities[0];
			}
		}

	}
	playRecord(element) {
		this.selection.toggle(element.id);
	}

	findById(collection, value) {
		let index = -1;
		let filteredObj = collection.find(function (element, i) {
			if (element.id === value.id) {
				index = i;
				return i;
			}
		});
		return index;

	}
}

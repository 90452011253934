import { Component, OnInit } from '@angular/core';
import { GoogleMap } from '@agm/core/services/google-maps-types';
import { MapLoaderService } from './map.loader'
declare var google: any;
import { IotService } from '../../service/iot.service';
import { DeviceService } from '../../service/device.service';
import { MapService } from '../../service/map.service';
import { GeofencesService } from '../../service/geofences.service';
import { Router } from '@angular/router';

export interface User {
  name: string;
  id: number;
}


@Component({
  selector: 'app-map-component',
  templateUrl: './map-component.component.html',
  styleUrls: ['./map-component.component.scss']
})
export class MapComponentComponent implements OnInit {
  /*   lat = 28.704060;
    long = 77.102493;
    googleMapType = 'satellite';
    private map: GoogleMap;
    mapHeight: String */
  map: any;
  drawingManager: any;
  coordinateList = [];
  mapList = [];
  newCoordinateArray = [];
  displayedColumns: string[] = ['Latitude', 'Longitude'];
  maxSearchRadius: any;
  longAvailable: boolean = false;;
  latAvailable: boolean = false;;

  ngOnInit(): void {


  }

  constructor(public iotService: IotService, public deviceService: DeviceService,
    public geofenceService: GeofencesService, public mapService: MapService, private router: Router) {

  }

  ngAfterViewInit() {
    this.deviceService.displayCoordinates = false;
    MapLoaderService.load().then(() => {
      if (this.iotService.viewGeofence == true) {
        this.viewGeofence();
      }
      else if (this.iotService.viewDashboard == true) {

        if (this.mapService.getDevices().length > 0) {
          this.markDevices(this.mapService.getDevices());
        }
        else {
          this.markDevices([]);
        }

      }
      else if (this.iotService.deviceDashboard == true) {
        // console.log("#################################" + this.mapService.getPathCoordinates())
        if (this.mapService.getPathCoordinates() != undefined && this.mapService.getPathTimes() != undefined
          && this.mapService.getLatitude() != undefined && this.mapService.getLongitude() != undefined) {
          this.deviceTracking(this.mapService.getLatitude(), this.mapService.getLongitude(),
            this.mapService.getPathCoordinates(), this.mapService.getPathTimes());
        }
        else {
          this.initDeviceTracking();
        }

      }
      else {
        this.drawPolygon();
      }

    })
  }


  initDeviceTracking() {
    // console.log("init******************************************");
    const that = this;
    if (this.iotService.deviceDashboard) {
      that.map = new google.maps.Map(document.getElementById('deviceDashboardMap'), {
        center: { lat: 12.8628, lng: 30.2176 },
        zoom: 2.2
      });
    }
  }

  deviceTracking(latitude, longitude, pathCoordinates, pathTimes) {
    // console.log("init deviceTracking******************************************");
    const that = this;
    if (this.iotService.deviceDashboard) {
      that.map = new google.maps.Map(document.getElementById('deviceDashboardMap'), {
        center: { lat: 12.8628, lng: 30.2176 },
        zoom: 2.2
      });
    }

    this.trackPath(pathCoordinates, that.map);
    this.putMarkers(pathCoordinates, that.map, pathTimes);
  }

  trackPath(pathCoordinates, trackingMap) {
    var lineSymbol = {
      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW
    };

    var trackPathOptions = {
      path: pathCoordinates,
      geodesic: true,
      strokeColor: '#000000',
      strokeOpacity: 1,
      strokeWeight: 1,
      icons: [{
        icon: lineSymbol,
        offset: '100%'
      }],
      map: trackingMap
    };
    var trackPath = this.getPolylinePath(trackPathOptions);
    if (trackPath != undefined && trackPath != null) {
      this.animateCircle(trackPath);
    }
  }




  markDevices(devices) {



    console.log("Inside mark devices");
    const that = this;
    that.map = new google.maps.Map(document.getElementById('dashboardMap'), {
      center: { lat: 12.8628, lng: 30.2176 },
      zoom: 2.2
    });

    if(this.mapService.getGeofence()){
      that.maxSearchRadius = that.geofenceService.maxSearchRadius;
      var locations = that.geofenceService.polygon;
      var pathCoordinates = new Array();
      for (var i = 0; i < locations.length; i++) {
        var tempLocation = {
          lat: 0,
          lng: 0
  
        };
        tempLocation.lat = parseFloat(locations[i].latitude);
        tempLocation.lng = parseFloat(locations[i].longitude);
        pathCoordinates.push(tempLocation);
  
  
      }
  
  
      that.drawGeofence(that.map, pathCoordinates, that.maxSearchRadius);
      if (pathCoordinates.length < 20) {
        for (var i = 0; i < pathCoordinates.length; i++) {
          that.setMarker(that.map, pathCoordinates[i].lat, pathCoordinates[i].lng, "lat: " + pathCoordinates[i].lat + " lng: " + pathCoordinates[i].lng);
        }
      }

    }

    that.maxSearchRadius = that.geofenceService.maxSearchRadius;
    var locations = devices;


    var pathCoordinates = new Array();
    for (var i = 0; i < locations.length; i++) {
      this.latAvailable = false;
      this.longAvailable = false;
      var tempLoc = {
        lat: 0,
        lng: 0,
        device: ''

      };

      if (locations[i].latitude != undefined) {
        tempLoc.lat = locations[i].latitude;
        this.latAvailable = true;
      }
      if (locations[i].longitude != undefined) {
        tempLoc.lng = locations[i].longitude;
        this.longAvailable = true;
      }
      tempLoc.device = locations[i];



      // console.log("latitude ########################### "+tempLoc.lat);
      // console.log("longitude ########################## "+tempLoc.lng);

      if (this.latAvailable && this.longAvailable) {
       if(tempLoc.lat!=0.0&&tempLoc.lng!=0.0){
        pathCoordinates.push(tempLoc);
       }
      }




    }


    // that.drawGeofence(that.map, pathCoordinates, that.maxSearchRadius);
    if (pathCoordinates.length < 20) {
      for (var i = 0; i < pathCoordinates.length; i++) {

        that.setMarkerForDevices(that.map, pathCoordinates[i].lat, pathCoordinates[i].lng, "lat: " + pathCoordinates[i].lat + " lng: " + pathCoordinates[i].lng, pathCoordinates[i].device);
      }
    }

  }


  makeInfoWindowEvent(map, infowindow, device, marker) {
    google.maps.event.addListener(marker, 'click', function () {

      var content = "<div class=popup>" +
        "<h6>&nbsp;Device Name:</h6>" +
        "<h5><a  href=\"#/deviceDetails/" + device.id + "\"  title=\"View Device Details\"  (click)=viewDevice('" + device.id + "')>&nbsp;" + device.name[0].text + "</a></h5>" +
        "<h6>&nbsp;Lat: " + device.latitude + "</h6>" +
        "<h6>&nbsp;Long: " + device.longitude + "</h6>" +
        "</div>";
      infowindow.setContent(content);
      infowindow.open(map, marker);
    });
  }

  viewDevice(deviceId) {
    console.log("**************************device Id", deviceId)
    this.router.navigateByUrl("/devices/" + deviceId);
  }


  calculateCoordinates(mapList) {
    var x = mapList.toString().trim();
    var mapArr = [];
    mapArr = x.split(")");
    console.log("Mapp Array" + mapArr)
    var coordinateArray = [];
    for (var m = 0; m < mapArr.length - 1; m++) {
      var coordinate = {

        "latitude": "",
        "longitude": ""
      }
      var ele = mapArr[m];
      if (ele.includes('(')) {
        var index = ele.indexOf('(');
        ele = ele.substring(index + 1);
        var coorArray = [];
        coorArray = ele.split(",");
        coordinate.latitude = coorArray[0];
        coordinate.longitude = coorArray[1];
      }
      mapArr[m] = ele;
      console.log("Array element" + mapArr[m])
      coordinateArray.push(coordinate);

    }
    this.deviceService.setCoordinateList(coordinateArray);



    //   console.log()

    return coordinateArray;
  }

  drawPolygon() {
    this.map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: -34.397, lng: 150.644 },
      zoom: 8
    });

    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: ['polygon']
      }
    });




    this.drawingManager.setMap(this.map);
    google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (event) => {
      // Polygon drawn
      if (event.type === google.maps.drawing.OverlayType.POLYGON) {
        //this is the coordinate, you can assign it to a variable or pass into another function.
        this.deviceService.displayCoordinates = true
        this.coordinateList = event.overlay.getPath().getArray();
        this.newCoordinateArray = this.calculateCoordinates(this.coordinateList);
        var requestCoordinateArray = [];
        var len = this.newCoordinateArray.length;

        requestCoordinateArray = this.newCoordinateArray.slice();
        console.log("length is" + len);
        if (this.newCoordinateArray.length > 1) {
          requestCoordinateArray.length = len + 1;
          requestCoordinateArray[len] = requestCoordinateArray[0];
        }

        this.deviceService.setRequestCoordinateList(requestCoordinateArray);
        console.log("New array" + this.newCoordinateArray)
        //   alert(event.overlay.getPath().getArray());
      }
    });
  }


  viewGeofence() {
    const that = this;
    that.map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: -34.397, lng: 150.644 },
      zoom: 8
    });
    that.maxSearchRadius = that.geofenceService.maxSearchRadius;
    var locations = that.geofenceService.polygon;
    var pathCoordinates = new Array();
    for (var i = 0; i < locations.length; i++) {
      var tempLoc = {
        lat: 0,
        lng: 0

      };
      tempLoc.lat = parseFloat(locations[i].latitude);
      tempLoc.lng = parseFloat(locations[i].longitude);
      pathCoordinates.push(tempLoc);


    }


    that.drawGeofence(that.map, pathCoordinates, that.maxSearchRadius);
    if (pathCoordinates.length < 20) {
      for (var i = 0; i < pathCoordinates.length; i++) {
        that.setMarker(that.map, pathCoordinates[i].lat, pathCoordinates[i].lng, "lat: " + pathCoordinates[i].lat + " lng: " + pathCoordinates[i].lng);
      }
    }
  }

  drawGeofence(map: any, path: any[], radius: any) {
    //check for circular geofence.
    if (path.length > 1) {
      var geofence = new google.maps.Polygon({
        paths: path,
        strokeColor: '#000000',
        strokeOpacity: 0.8,
        strokeWeight: 2,

      });
      geofence.setMap(map);
      this.setBounds(map, path);
    }
    else {
      // it is a circular geofence

      var options = {
        zoom: 10,
        center: { lat: path[0].lat, lng: path[0].lng },
        mapTypeControl: false,
        zoomControl: true,
        streetViewControl: false,
        fullscreenControl: true
      }

      // reset the map

      var circleOptions = {
        strokeColor: '#000000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        zoom: 8,
        map: map,
        center: { lat: path[0].lat, lng: path[0].lng },
        radius: Math.sqrt(radius) * 100
      }
      var cityCircle = new google.maps.Circle(circleOptions);
      this.setMarker(map, path[0].lat, path[0].lng, "lat: " + path[0].lat + " lng: " + path[0].lng);

    }
  }

  setMarker(map: any, lat: any, lng: any, title: string) {
    if (title == null) {
      var options = {
        map: map,
        draggable: false,
        animation: google.maps.Animation.DROP,
        position: { lat: lat, lng: lng },

      }
      var marker = new google.maps.Marker(options);
    }
    else {
      var options2 = {
        map: map,
        draggable: false,
        animation: google.maps.Animation.DROP,
        position: { lat: lat, lng: lng },
        title: title
      }
      var marker = new google.maps.Marker(options2);

    }


  }


  setMarkerForDevices(map: any, lat: any, lng: any, title: string, device: any) {
    if (title == null) {
      var options = {
        map: map,
        draggable: false,
        animation: google.maps.Animation.DROP,
        position: { lat: lat, lng: lng },

      }
      var marker = new google.maps.Marker(options);

    }
    else {
      var options2 = {
        map: map,
        draggable: false,
        animation: google.maps.Animation.DROP,
        position: { lat: lat, lng: lng },
        title: title
      }
      var marker = new google.maps.Marker(options2);
      var infowindow = new google.maps.InfoWindow({
        maxWidth: 350,
        pixelOffset: new google.maps.Size(-10, -25)
      });

      this.makeInfoWindowEvent(this.map, infowindow, device, marker);

    }


  }


  setBounds(map, locations) {
    var bounds = new google.maps.LatLngBounds();
    for (var i = 0; i < locations.length; i++) {
      var position = new google.maps.LatLng(locations[i].lat, locations[i].lng);
      bounds.extend(position);
    }
    map.fitBounds(bounds);

  }


  // function draw polylines

  getPolylinePath(options) {
    return new google.maps.Polyline(options);
  }




  // Use the DOM setInterval() function to change the offset of the symbol
  // at fixed intervals.
  animateCircle(line) {
    var count = 0;
    window.setInterval(function () {
      count = (count + 1) % 200;

      var icons = line.get('icons');
      if (icons != undefined && icons[0] != undefined) {
        icons[0].offset = (count / 2) + '%';
        line.set('icons', icons);
      }
    }, 100);
  }

  putMarkers(pathCoordinates, trackingMap, pathTime) {
    var info;
    var icon = {
    
      url: "assets/images/icon/truck.png", // url
      scaledSize: new google.maps.Size(12, 12), // scaled size
      origin: new google.maps.Point(0, 0), // origin
      anchor: new google.maps.Point(5, 5) // anchor
    };
    var bounds = new google.maps.LatLngBounds();
    for (var i = 0; i < pathCoordinates.length; i++) {
      var infoStr = "lat: " + pathCoordinates[i].lat + "</br>lng: " + pathCoordinates[i].lng;

      var position = new google.maps.LatLng(pathCoordinates[i].lat, pathCoordinates[i].lng);
      bounds.extend(position);

      info = new google.maps.InfoWindow({
        content: infoStr
      });

      var marker = new google.maps.Marker({
        map: trackingMap,
        draggable: false,
        animation: google.maps.Animation.DROP,
        position: { lat: pathCoordinates[i].lat, lng: pathCoordinates[i].lng },
        icon: icon
      });

      google.maps.event.addListener(marker, 'click', (function (marker, i) {
        return function () {
          // console.log("******************* path",pa)
          info.setContent("lat: " + pathCoordinates[i].lat + "</br>lng: " + pathCoordinates[i].lng + "</br>time:" + dateToString(pathTime[i]));
          info.open(trackingMap, marker);
        }
      })(marker, i));

      trackingMap.fitBounds(bounds);
    }


  }

  dateToString(datetime) {
    var myDate = new Date(datetime);
  var d = myDate.toLocaleString();
  const monthNames = ["Jan", "Feb", "Mar", "April", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
  var monthName = monthNames[myDate.getMonth()];
  var dateString = monthName + " " + myDate.getDate() + ", " + myDate.getFullYear() + " " + myDate.getHours() + ":" + myDate.getMinutes() + ":" + myDate.getSeconds() + ":" + myDate.getMilliseconds();
  // var monthName = monthNames[d.getMonth()];
  // var dateString = monthName + " " + d.getDate() + ", " + d.getFullYear() + " " + d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds() + ":" + d.getMilliseconds();

  return dateString;

  }


}





function dateToString(datetime) {
  var myDate = new Date(datetime);
  var d = myDate.toDateString();
  const monthNames = ["Jan", "Feb", "Mar", "April", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
  var monthName = monthNames[myDate.getMonth()];
  var dateString = monthName + " " + myDate.getDate() + ", " + myDate.getFullYear() + " " + myDate.getHours() + ":" + myDate.getMinutes() + ":" + myDate.getSeconds() + ":" + myDate.getMilliseconds();
  // var monthName = monthNames[d.getMonth()];
  // var dateString = monthName + " " + d.getDate() + ", " + d.getFullYear() + " " + d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds() + ":" + d.getMilliseconds();

  return dateString;
}


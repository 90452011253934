import { Injectable } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { ListApplicationsService } from './ListApplications.service';
import { IotService } from '../service/iot.service';
import { StreamService } from './stream.service';
import { TradingPartnersService } from './tradingPartners.service';
import { EcosystemMembersService } from './ecosystemMembers.service';
@Injectable({
	providedIn: 'root'
})

export class ApplicationsService extends ListApplicationsService {

	sourceStream: any;
	eventGroupData = [];
	subscriptionsData = [];
	warningMessage: string;
	errorMessage: string;
	successMessage: string;
	selection: SelectionModel<String> = new SelectionModel<String>(true, []);

	constructor(public iotService: IotService, public streamService: StreamService, public tpService: TradingPartnersService, public ecosystemMemberService: EcosystemMembersService) {
		super(iotService);
		this.selection.clear();
	}

	loadSelected(){
		this.selection.clear();
	}

	resetWarningMessage() {
		this.warningMessage = '';
	}

	resetSuccessMessage() {
		this.successMessage = '';
	}

	resetErrorMessage() {
		this.errorMessage = '';
	}

	resetMessage(){
		this.warningMessage = '';
		this.successMessage = '';
		this.errorMessage = '';
	}
	
	createNew() {
		this.streamService.streamNamesArray = [];
		this.streamService.createStreamFlag = false;
		this.streamService.showListStream = false;
		this.streamService.streamCreated = false;

	}

	fnOnSelectionSupport(results) {
		const that = this;
		if (that.iotService.createStream == true) {
			that.streamService.setOwnerId(results.id);
			that.streamService.selectedApplication = true;
			that.streamService.setApplicationSelected(results.name[0].text)
		}
		else if (that.iotService.createTradingPartner == true) {
			let applications = that.tpService.getTpApplications();
			applications.push(results);
			that.tpService.setTpApplications(applications);
		}

		if(that.iotService.createEcosystemMembership == true) {
			that.ecosystemMemberService.setSelectedApplicationId(results.id);
		}
	}

	fnRemoveSelectionSupport(results) {
		const that = this;
		if (that.iotService.createStream == true) {
			that.streamService.setOwnerId(undefined)
			that.streamService.selectedApplication = false;
			that.streamService.setApplicationSelected(undefined)

		}
		else if(that.iotService.createTradingPartner == true){
			let applications = that.tpService.getTpApplications();
			applications.forEach((element,index) => {
				if(element.id==results.id){
					applications.splice(index,1);
				}	
			});
			that.tpService.setTpApplications(applications);
		}

		if(that.iotService.createEcosystemMembership == true) {
			that.ecosystemMemberService.setSelectedApplicationId(undefined);
		}
	}
}
<ng-container *ngIf="showLoading">
    <div>
        <br /><br />
    </div>
    <div class="d-flex justify-content-center loader">
        <div class="spinner-border" role="status">
            <span class="sr-only" id="loading"></span>
        </div>
    </div>
</ng-container>
<div *ngIf="!showLoading" class="attributeForm">
    <header>
        <h1> {{ 'create.new.attribute' | translate}} </h1>
        <p *ngIf="errorMsg" class="alert">{{errorMsg}}</p>
    </header>
    <div class="col">
        <label for="attributeName">{{'name' | translate}}</label>
        <input [(ngModel)]="attribute.name" id="attrName" (click)="nameError=''" placeholder="{{'required' | translate}}" name="attributeName"
            type="text">
        <p style="color:red">{{nameError}}</p>    

        <label for="attributeDescription">{{'description' | translate}}</label>
        <textarea [(ngModel)]="attribute.description[0].text" placeholder="{{'optional' | translate}}"
            type="text"></textarea>

        <label for="attributeTags">{{ 'tags' | translate}}</label>
        <mat-chip-list #tagList aria-label="tags">

            <mat-chip class="ot-chip" *ngFor="let tag of tags" [selectable]="selectable" [removable]=true
                (removed)="remove(tag)">
                {{tag}}
                <button class="ot-chip__close" matChipRemove></button>
            </mat-chip>
            <input [ngClass]="tagClass" placeholder="{{'add_a_tag' | translate}}" #tagInput [matChipInputFor]="tagList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                (matChipInputTokenEnd)="add($event)">

        </mat-chip-list>
    </div>
    <div class="col">
        <label for="attributeDataType">{{ 'datatype' | translate}} </label>
        <!-- <mat-form-field appearance="outline" class="dropdown">
            <mat-select [(ngModel)]="attribute.type" [formControl]="typeChangeControl" name="typeChange">
                <mat-option style="font-size: 14px;" *ngFor="let typeChange of typeChanges" [value]="typeChange.value">
                {{typeChange.viewValue}}
                </mat-option>
            </mat-select>
        </mat-form-field> -->
        <select [(ngModel)]="attribute.type" id="attrDataType" (change)="dataTypeError=''">
            <option value="">{{ 'select.data.type' | translate}}</option>
            <option value="string"> {{ 'string' | translate}}</option>
            <option value="integer"> {{ 'integer' | translate}}</option>
            <option value="decimal"> {{ 'decimal' | translate}}</option>
            <option value="bool"> {{ 'boolean' | translate}}</option>
        </select>
        <p style="color:red">{{dataTypeError}}</p>

        <label></label>
        <mat-radio-group class="radio-group" [(ngModel)]="attributetype" (click)="fixedValueError=''">
            <mat-radio-button color="primary" value=1 class="radio-button">{{ 'fixed' | translate}}</mat-radio-button>
            <mat-radio-button color="primary" value=2 class="radio-button">{{ 'dynamic' | translate}}&nbsp;
            </mat-radio-button>
        </mat-radio-group>

        <br />

        <div>
            <ng-container *ngIf="(attribute.type !='bool')">
                <h6>
                    <input style="width: 60%;" *ngIf="attributetype == '1'" (click)="fixedValueError=''" placeholder="{{ 'fixed_value' | translate}}"
                        [(ngModel)]="attributeValue" type="text">
                        <p style="color:red">{{fixedValueError}}</p>    

                    <input style="width: 60%;" *ngIf="attributetype == '2'"
                        placeholder="{{ 'dynamic_value' | translate}}" [(ngModel)]="attributeValue" type="text">
                </h6>
            </ng-container>

            <ng-container *ngIf="(attribute.type==='bool' && attributetype== '2')">
                <h6>
                    <mat-radio-group [(ngModel)]="boolValue">
                        <mat-radio-button color="primary" value=2 class="radio-button">{{'none' | translate}}&nbsp;&nbsp;
                        </mat-radio-button>
                        <mat-radio-button color="primary" value=1 class="radio-button">{{'true' | translate}}&nbsp;&nbsp;
                        </mat-radio-button>
                        <mat-radio-button color="primary" value=0 class="radio-button">{{'false' | translate}}</mat-radio-button>
                    </mat-radio-group>
                </h6>
            </ng-container>

            <ng-container *ngIf="(attribute.type==='bool' && attributetype== '1')">
                <h6>
                    <mat-radio-group [(ngModel)]="boolValue">
                        <mat-radio-button color="primary" value=1 class="radio-button">{{'true' | translate}}&nbsp;&nbsp;
                        </mat-radio-button>
                        <mat-radio-button color="primary" value=0 class="radio-button">{{'false' | translate}}</mat-radio-button>
                    </mat-radio-group>
                </h6>
            </ng-container>
        </div>
        <br />
        <div class="activateAttribute">
            <label> {{ 'enabled' | translate}}?</label>
            <div class="activateEvent">

                <button *ngIf="attribute.isActive" class="toggle-on toggle-switch" (click)=toggleActivate(false);
                    title="Disable" aria-label="Enabled" title="Enabled">
                    <fa-icon [icon]="faCheckIcon" class="fa-2x"></fa-icon>
                    <div class="toggle-on-btn"></div>
                </button>

                <button *ngIf="!attribute.isActive" class="toggle-off toggle-switch" (click)=toggleActivate(true);
                    aria-label="Disabled" title="Disabled">
                    <div class="toggle-off-btn"></div>
                </button>
                <!-- <mat-grid-list [cols]="4" rowHeight="4:1" [gutterSize]="0">
                <mat-grid-tile [ngClass]="attributeDisabledClass" (click)=toggleActivate(false);>
                    <h3>Disabled x</h3>
                </mat-grid-tile>  
                <mat-grid-tile [ngClass]="attributeEnabledClass" (click)=toggleActivate(true);>
                    <h3>Enabled &#10003;</h3>
                </mat-grid-tile>
            </mat-grid-list> -->
            </div>
            <br /><br />
            <button class="btn btn-secondary" (click)="createAttribute()" type="submit">{{ 'go.back' |
                translate}}</button>&nbsp;
            <button class="btn btn-primary" [disabled]="attributeCreated" (click)="save()" type="submit">{{ 'save' | translate}}</button>
        </div>

        <div style="height: 30vmax;">

        </div>
    </div>
</div>
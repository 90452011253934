import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GatewaysService } from '../service/gateways.service';
import { IotService } from '../service/iot.service';

@Component({
  selector: 'app-gateway-adaptor-detail',
  templateUrl: './gateway-adaptor-detail.component.html',
  styleUrls: ['./gateway-adaptor-detail.component.scss']
})
export class GatewayAdaptorDetailComponent implements OnInit {

  supportLanguages = ['en'];
  adaptorDetailInfo;

  constructor(private iotService: IotService, private translateService: TranslateService, 
    public gatewaysService: GatewaysService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }

  ngOnInit(): void {
    this.getGateWayAdaptor(this.gatewaysService.selectedAdaptorId);
  }
  getGateWayAdaptor(selectedAdaptorId: any) {
    const that = this;
    that.iotService.getCuiObjResponse().getAdapter({
      adaptorId: selectedAdaptorId,
      gatewayId:that.gatewaysService.gateWayAgentId
    }).then(function (response) {
      that.adaptorDetailInfo=response; 
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    
    });
  }

  goBack(){
    this.gatewaysService.createResource=true;
		this.gatewaysService.showAdaptorDetail=false;
  }

}

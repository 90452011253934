import { Injectable } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { IotService } from '../service/iot.service';
import { ListEcosystemAttributesService } from './ListEcosystemAttributes.service';
import { EcosystemsService } from './ecosystems.service';

@Injectable({
  providedIn: 'root',
})
export class EcosystemAttributesService extends ListEcosystemAttributesService {
  constructor(
    public iotService: IotService,
    public ecosystemService: EcosystemsService
  ) {
    super(iotService);
  }

  fnOnSelectionSupport(results) {
    const that = this;
  }

  fnRemoveSelectionSupport(results) {
    const that = this;
  }

  createNew() {
    if (this.iotService.createEcosystem == true) {
      this.ecosystemService.createAttribute = true;
      this.ecosystemService.createResource = false;
    }
  }
}

import { Injectable } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { throwMatDuplicatedDrawerError } from '@angular/material/sidenav';
import { StreamService } from './stream.service';
import { IotService } from '../service/iot.service';
import { ListStreamsService } from './ListStreams.service';
import { RoutesService } from './routes.service';
import { MessageGovernancesService } from '../service/messageGovernances.service';
import { OrchestrationConfigsService } from './orchestrationConfigs.service';

@Injectable({
	providedIn: 'root'
})

export class StreamsService extends ListStreamsService {
	selection: SelectionModel<String> = new SelectionModel<String>(true, []);

	warningMessage: string;
	errorMessage: string;
	successMessage: string;

	constructor(public iotService: IotService, public streamService: StreamService, public routeService: RoutesService, public messageGovernancesService: MessageGovernancesService, public orchestrationConfigsService: OrchestrationConfigsService) {
		super(iotService);
		this.selection.clear();
		this.loadSelected();
	}

	resetWarningMessage() {
		this.warningMessage = '';
	}

	resetSuccessMessage() {
		this.successMessage = '';
	}

	resetErrorMessage() {
		this.errorMessage = '';
	}
	createNew() {
		if (this.iotService.sideNavStream == true) {
			this.iotService.sideNavStream = true;
		} else {
			this.iotService.sideNavStream = false;
		}


		this.streamService.streamCreated = false;
		this.streamService.showListStream = false;
		this.iotService.createRoute = false;
		this.streamService.createStreamFlag = true;
	}

	fnOnSelectionSupport(results) {
		const that = this;
		if (that.iotService.createRoute == true) {
			this.routeService.setStream(results);
		}
		else if (that.iotService.createMessageGovernance == true) {
			if (this.instanceValue == "1") {
				let streams = that.messageGovernancesService.getSenderStreams();
				streams.push(results);
				that.messageGovernancesService.setSenderStreams(streams);
			} else if (this.instanceValue == "2") {
				let streams = that.messageGovernancesService.getReceiverStreams();
				streams.push(results);
				that.messageGovernancesService.setReceiverStreams(streams);
			}
		}
		this.orchestrationConfigsService.addStream(results);
		var orchStreamData = this.orchestrationConfigsService.getStreamData();
		var index = orchStreamData.findIndex( x => (x.id == results.id));
		if(index != -1){
			orchStreamData[index] = results;
		} else {
			orchStreamData.push(results);
		}
		this.orchestrationConfigsService.setStreamData(orchStreamData);
	}

	fnRemoveSelectionSupport(results) {
		const that = this;
		if (that.iotService.createRoute == true) {
			this.routeService.setStream(undefined);
		}
		else if (that.iotService.createMessageGovernance == true) {
			if (this.instanceValue == "1") {
				let streams = that.messageGovernancesService.getSenderStreams();
				streams.forEach((value, index) => {
					if (value.id == results.id) streams.splice(index, 1);
				});
				that.messageGovernancesService.setSenderStreams(streams);
			}
			else if (this.instanceValue == "2") {
				let streams = that.messageGovernancesService.getReceiverStreams();
				streams.forEach((value, index) => {
					if (value.id == results.id) streams.splice(index, 1);
				});
				that.messageGovernancesService.setReceiverStreams(streams);

			}

		}
		this.orchestrationConfigsService.removeStream(results);
		var orchStreamData = this.orchestrationConfigsService.getStreamData();
		var index = orchStreamData.findIndex( x => (x.id == results.id));
		if(index != -1){
			orchStreamData.splice(index, 1);
			this.orchestrationConfigsService.setStreamData(orchStreamData);
		}
	}
	loadSelected() {
		if (this.routeService.getStream() && this.iotService.createRoute==true) {
			this.selection.select(this.routeService.getStream().id);
		}
		else if (this.orchestrationConfigsService.getStreamData() && this.iotService.createRoute==false && this.iotService.createMessageGovernance == false) {
			this.orchestrationConfigsService.getStreamData().forEach(stream => {
				this.selection.select(stream.id);
			});
		}
		else if(this.iotService.msgGovLoader==true){
			
			if(this.messageGovernancesService.getSenderStreams().length>0 ){
				this.messageGovernancesService.getSenderStreams().forEach(senderStream => {
					this.selection.select(senderStream.id);
				});
			}
			if(this.messageGovernancesService.getReceiverStreams().length>0 ){
				this.messageGovernancesService.getReceiverStreams().forEach(receiverStream => {
					this.selection.select(receiverStream.id);
				});
			}
			 

		}
	}

}

<mat-sidenav-container class="mid-sidenav" autosize style="height: 450vh;">
    <mat-sidenav class="midNav-container" #sidenav mode="side" disableClose="true" opened="true" scrollable="true">
        <div class="alert critical" *ngIf="streamService.errorMessage">
            <h5>
                <fa-icon [icon]="faCriticalIcon"></fa-icon>
                <span>{{streamService.errorMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=streamService.resetErrorMessage() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>

        </div>
        <div class="alert critical" *ngIf="applicationService.errorMessage">
            <h5>
                <fa-icon [icon]="faCriticalIcon"></fa-icon>
                <span>{{applicationService.errorMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=applicationService.resetErrorMessage() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>

        </div>
        <div class="alert success" *ngIf="applicationService.successMessage">
            <h5>
                <fa-icon [icon]="faSuccessIcon"></fa-icon>
                <span>{{applicationService.successMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=applicationService.resetSuccessMessage() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>

        </div>
        <h1>{{'create.application' | translate}}</h1>
        <p>{{'applications.definition' | translate}}</p>


        <ng-container *ngIf="streamService.streamNamesArray.length>0">
            <h5>{{'streams' | translate}}</h5>

            <ul>
                <li *ngFor="let stream of streamService.streamNamesArray">
                    <fa-icon [icon]="faCheckIcon"></fa-icon> {{stream}}</li>
            </ul>

        </ng-container>
        <ng-container *ngIf="streamService.docInfoList && streamService.getDirection()!='Active Receiving' && streamService.getDirection()!='Passive Receiving'
        &&streamService.getDirection()!='ACTIVE_RECEIVING' && streamService.getDirection()!='PASSIVE_RECEIVING'
        &&streamService.getDirection()!='Select Direction' && streamService.getProtocolType()!=undefined">
            <h5>{{'stream.document.info' | translate}}</h5>
            <p *ngIf="!streamService.selectedDocInfos"> {{'select.docInfo.list' | translate}}</p>
            <ng-container *ngIf="streamService.selectedDocInfos">
                <h6>{{'doc.type' | translate}}</h6>
                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamService.streamDocInfoDocType}}</li>
                </ul>
                <h6>{{'version' | translate}}</h6>
                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamService.streamDocInfoVersion}}</li>
                </ul>
                <h6>{{'template' | translate}}</h6>
                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamService.streamDocInfoTemplate}}</li>
                </ul>

            </ng-container>
            <ng-container *ngIf="streamService.fromCode">
                <h5>{{'from.code' | translate}}</h5>
                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamService.fromCodeValue.code}}</li>
                </ul>


            </ng-container>
            <ng-container *ngIf="streamService.toCode">
                <h5>{{'to.code' | translate}}</h5>
                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamService.toCodeValue.code}}</li>
                </ul>


            </ng-container>
        </ng-container>
        <ng-container *ngIf="streamService.docInfoList && streamService.getDirection()=='Passive Receiving'
        && streamService.getDirection()=='PASSIVE_RECEIVING'
        && streamService.getProtocolType()=='BATCH'">
            <h5>{{'stream.document.info' | translate}}</h5>
            <p *ngIf="!streamService.selectedDocInfos"> {{'select.docInfo.list' | translate}}</p>
            <ng-container *ngIf="streamService.selectedDocInfos">
                <h6>{{'doc.type' | translate}}</h6>
                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamService.streamDocInfoDocType}}</li>
                </ul>
                <h6>{{'version' | translate}}</h6>
                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamService.streamDocInfoVersion}}</li>
                </ul>
                <h6>{{'template' | translate}}</h6>
                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamService.streamDocInfoTemplate}}</li>
                </ul>

            </ng-container>
            <ng-container *ngIf="streamService.fromCode">
                <h5>{{'from.code' | translate}}</h5>
                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamService.fromCodeValue.code}}</li>
                </ul>


            </ng-container>
            <ng-container *ngIf="streamService.toCode">
                <h5>{{'to.code' | translate}}</h5>
                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamService.toCodeValue.code}}</li>
                </ul>


            </ng-container>
        </ng-container>

        <ng-container *ngIf="streamService.docInfoList && streamService.getDirection()=='Active Sending' && streamService.getStreamType()=='Sync'">
            <h5 style="font-weight: bolder;">{{'response Mapping' | translate}}</h5>
            <h5>{{'stream.document.info' | translate}}</h5>
            <p *ngIf="!streamService.getDocInfo2()"> {{'select.docInfo.list' | translate}}</p>
            <ng-container *ngIf="streamService.getDocInfo2()">
                <h6>{{'doc.type' | translate}}</h6>
                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamService.getDocInfo2().supportedDocType}}</li>
                </ul>
                <h6>{{'version' | translate}}</h6>
                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamService.getDocInfo2().docTemplateVersion}}</li>
                </ul>
                <h6>{{'template' | translate}}</h6>
                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamService.getDocInfo2().supportedDocTemplateType}}</li>
                </ul>

            </ng-container>
            <ng-container *ngIf="streamService.fromCode2">
                <h5>{{'from.code' | translate}}</h5>
                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamService.fromCodeValue2.code}}</li>
                </ul>


            </ng-container>
            <ng-container *ngIf="streamService.toCode2">
                <h5>{{'to.code' | translate}}</h5>
                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamService.getToCodeValue2().code}}</li>
                </ul>


            </ng-container>
        </ng-container>
        <ng-container *ngIf="streamService.getProtocolSecurityType() == 'Public Key' &&( streamService.getDirection() == 'Active Receiving' || streamService.getDirection() == 'Passive Sending')">
            <h5>{{'stream.sshKey.pairs' | translate}}</h5>
            <p *ngIf="!streamService.getSSHKeyPair()"> {{'select.sshKey.pairs' | translate}}</p>
            <ng-container *ngIf="streamService.getSSHKeyPair()">
                <!-- <h5>
                    SSH Key Pair</h5> -->
                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamService.getSSHKeyPair()}}</li>
                </ul>


            </ng-container>

        </ng-container>

        <ng-container *ngIf="streamService.getProtocolSecurityType()=='Mutual Auth'">
            <h5>{{'mutual.auth.keyBundle' | translate}}</h5>
            <p *ngIf="!streamService.getMutualAuth()"> {{'select.mutual.auth.keyBundle' | translate}}</p>
            <ng-container *ngIf="streamService.getMutualAuth()">

                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamService.getMutualAuth()}}</li>
                </ul>


            </ng-container>

        </ng-container>

        <ng-container *ngIf="streamService.getProtocolSecurityType()=='OAuth Token'">
            <h5>{{'token' | translate}}</h5>
            <p *ngIf="!streamService.getOAuthToken()"> {{'select.token.list' | translate}}</p>
            <ng-container *ngIf="streamService.getOAuthToken()">
                <ul>
                    <li>
                        <fa-icon [icon]="faCheckIcon"></fa-icon> {{streamService.getOAuthToken()}}</li>
                </ul>


            </ng-container>

        </ng-container>
    </mat-sidenav>
    <ng-container *ngIf="!streamService.createStreamFlag">


        <div class="applicationData">
            <form>

                <label for="application-name">{{'name' | translate}}</label>
                <input placeholder="required" id="application-name" type="text" value="" (keyup)="applicationName($event)" aria-label="Name">

                <label for="application-description">{{'description' | translate}}</label>
                <textarea placeholder="optional" id="application-description" value="" (keyup)="applicationDescription($event)" aria-label="Description"></textarea>


                <br/>

                <label for="application-tags">{{'tags' | translate}}</label>
                <mat-chip-list #tagList aria-label="tags">

                    <mat-chip class="ot-chip" *ngFor="let tag of tags" [selectable]="true" [removable]=true (removed)="remove(tag)">
                        {{tag}}
                        <button class="ot-chip__close" matChipRemove></button>
                    </mat-chip>
                    <input [ngClass]="tagClass" placeholder="add a tag" #tagInput [matChipInputFor]="tagList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="add($event)">

                </mat-chip-list>

            </form>

        </div>
    </ng-container>
    <div class="save_button">
        <app-create-stream *ngIf="streamService.createStreamFlag"></app-create-stream>

        <div>
            <button class="btn btn-primary" [disabled]="applicationNameSelect==false || applicationCreated" *ngIf="!streamService.createStreamFlag" (click)="createApplication($event)">{{ 'save' | translate}}</button>
            <app-streams *ngIf='streamService.showListStream && !iotService.sideNavStream' [pageSize]="5" showControlBar=false [displayedColumns]="['name','protocolType','streamType','direction']" showSelectColoumn=true showEnableColumn=true addnQueryParam=[ownerId::{{streamService.ownerId}}] [showSearch]=false [newWithRoute]=false>
            </app-streams>
        </div>
    </div>
</mat-sidenav-container>

<ng-container>

    <hr>
    <h3 style="font-weight: 700;font-size: 16px;">Tracking</h3>

    <table mat-table [dataSource]="dataSource" multiTemplateDataRows>

        <!-- Timestamp Column -->
        <ng-container matColumnDef='Timestamp'>
            <th class="name-cell" mat-header-cell *matHeaderCellDef mat-sort-header="device_name">
                <h6>Timestamp</h6>
            </th>
            <td mat-cell *matCellDef="let tracking">
                {{tracking.dateTimeReceived | date:'medium'}}
            </td>
        </ng-container>

        <!-- Type Column -->
        <ng-container matColumnDef='Type'>
            <th class="date-cell" mat-header-cell *matHeaderCellDef>
                <h6>Type</h6>
            </th>
            <td mat-cell *matCellDef="let value">
                <span *ngIf="(value.relationType | uppercase)==='MESSAGE' && (value.sourceType | uppercase)==='IOT'">IOT-M</span>
                <span *ngIf="(value.relationType | uppercase)==='MESSAGE' && (value.sourceType | uppercase)==='UNI'">UNI-M</span>
                <span *ngIf="(value.relationType | uppercase)==='MESSAGE' && (value.sourceType | uppercase)==='SYNC'">SYNC-M</span>
                <span *ngIf="(value.sourceType | uppercase)==='GEN'">GEN</span>
                <span *ngIf="(value.relationType | uppercase)==='SYNCHRONOUS'">SYNC-S</span>
                <span *ngIf="(value.relationType | uppercase)==='COMPOUND'">C</span>
                <span *ngIf="(value.relationType | uppercase)==='CARBON_COPY'">CC</span>
                <span *ngIf="(value.relationType | uppercase)==='RULES'">R</span>
                <span *ngIf="(value.relationType | uppercase)==='ANALYTICS'">A</span>
                <span *ngIf="(value.relationType | uppercase)==='NACK'">NACK</span>
                <span *ngIf="(value.relationType | uppercase)==='ACK'">ACK</span>
            </td>
        </ng-container>

        <!-- Message ID -->
        <ng-container matColumnDef='MessageID'>
            <th class="desc-cell" mat-header-cell *matHeaderCellDef>
                <h6>Message ID</h6>
            </th>
            <td mat-cell *matCellDef="let tracking">
                <a (click)="getMessageDetails(tracking);expandMessage(tracking,expandedMessage === tracking ? null : tracking);expandedMessage = expandedMessage === tracking ? null : tracking">{{getMessageId(tracking.messageId)}}</a>
            </td>
        </ng-container>

        <!-- Owner Name -->
        <ng-container matColumnDef='OwnerName'>
            <th class="date-cell" mat-header-cell *matHeaderCellDef>
                <h6>Owner Name</h6>
            </th>
            <td mat-cell *matCellDef="let value">
                <span *ngIf="value.sourceType==='IOT' && value.deviceNames!=undefined && value.deviceNames[0]!=undefined" title={{value.deviceNames[0].value}}>&nbsp;{{value.deviceNames[0].value | limitTo : 15 }}</span>
                <!-- <span *ngIf="value.sourceType==='IOT' && value.eventTemplateNames!=undefined && value.eventTemplateNames[0]!=undefined" title={{value.eventTemplateNames[0].value}}> &nbsp;{{value.eventTemplateNames[0].value | limitTo : 15  }}</span>
                <span *ngIf="value.sourceType==='UNI' || value.sourcetype==='SYNC'" title={{value.senderTradingPartnerNames[0].value}}>&nbsp; {{value.senderTradingPartnerNames[0].value | limitTo : 15 }}</span> -->
                <span *ngIf="value.sourceType==='RULES'" title="{{value.streamName}}">&nbsp; {{value.streamName| limitTo
                    : 15 }}</span>
                <span *ngIf="value.sourceType==='GEN'">&nbsp; System</span>
            </td>
        </ng-container>

        <!-- DocumentType Column -->
        <ng-container matColumnDef='DocumentType'>
            <th class="date-cell" mat-header-cell *matHeaderCellDef>
                <h6>Document Type</h6>
            </th>
            <td mat-cell *matCellDef="let value">

                <span *ngIf="value.sourceType==='IOT' && value.eventTemplateNames!=undefined && value.eventTemplateNames[0]!=undefined" title={{value.eventTemplateNames[0].value}}>&nbsp;{{value.eventTemplateNames[0].value | limitTo :
                    15}}</span>
                <span *ngIf="value.sourceType==='IOT' && value.commandTemplateNames!=undefined && value.commandTemplateNames[0]!=undefined" title={{value.senderTradingPartnerNames[0].value}}>&nbsp;{{value.commandTemplateNames[0].value |
                    limitTo : 15}}</span>
                <span *ngIf="value.sourceType==='UNI' || value.sourcetype==='SYNC'"><i
                        ></i>&nbsp;{{value.senderMessageInfo| limitTo : 15}}</span>
                <span *ngIf="value.sourcetype==='RULES'">&nbsp;{{value.streamName | limitTo : 15}}}</span>

            </td>
        </ng-container>


        <!-- Customer Control Column -->
        <ng-container matColumnDef='CustomerControl'>
            <th class="date-cell" mat-header-cell *matHeaderCellDef>
                <h6>Customer Control</h6>
            </th>
            <td mat-cell *matCellDef="let value">
                <span><i  title={{value.customerMessageId}}> {{value.customerMessageId | limitTo :
                        15}}</i></span>
            </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef='Status'>
            <th class="status-cell" mat-header-cell *matHeaderCellDef>
                <h6>Status</h6>
            </th>
            <td mat-cell *matCellDef="let value">
                <span *ngIf="value.processingState==='COMPLETE'" class="ng-scope">&nbsp;<fa-icon
                        style="color: green;font-size: 20px;" [icon]="envelope"></fa-icon></span>
                <span *ngIf="value.processingState==='PENDING_BATCH'">&nbsp;<fa-icon
                        style="color: blue;font-size: 20px;" [icon]="envelope"></fa-icon></span>
                <span *ngIf="value.processingState==='AWAITING_PICKUP'">&nbsp;<fa-icon
                        style="color: yellow;font-size: 20px;" [icon]="envelope"></fa-icon></span>
                <span *ngIf="value.processingState==='FAIL'">&nbsp;<fa-icon
                        style="color: red;font-size: 20px;" [icon]="failed"></fa-icon></span>
                <span *ngIf="!value.processingState||value.status===''">
                    <fa-icon style="color: gray; font-size: 20px;" [icon]="envelope"></fa-icon>
                </span>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedMessage">

            <td mat-cell *matCellDef="let tracking" [attr.colspan]="displayedColumns.length">

                <div class="message-element-detail" [@detailExpand]="tracking === expandedMessage  ? 'expanded' : 'collapsed'">
                    <!-- <div class="message-element-description"> -->
                    <!-- <div class="message-element-close"> -->
                    <br>
                    <br>
                    <ng-container *ngIf="!loaded" class="loading">
                        <div class="d-flex justify-content-center" style="padding-left: 45%;">
                            <div class="spinner-border" role="status">
                                <span class="sr-only"></span>
                            </div>
                        </div>
                    </ng-container>
                    <div>
                        <mat-tab-group animationDuration="0ms" *ngIf="loaded">
                            <mat-tab label="Message Details">
                                <mat-expansion-panel [togglePosition]="'before'" [expanded]="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <h6>Tracking Information</h6>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <div class="expanded" *ngIf="message!=undefined && message[0]!=undefined">
                                        <div *ngIf="message[0].sourceType==='IOT'||message[0].sourceType==='GEN'">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Date Time Received</td>
                                                        <td>{{message[0].dateTimeReceived |date:'MMM dd yyyy h:mm:ss:sss a'}} </td>
                                                    </tr>
                                                    <tr *ngIf="message[0].processingState==='FAIL'">
                                                        <td>Date Time Delivered</td>
                                                        <td>Message Not Delivered</td>
                                                    </tr>
                                                    <tr *ngIf="message[0].dateTimeDelivered!='0' && message[0].processingState!='FAIL'">
                                                        <td>Date Time Delivered</td>
                                                        <td>{{message[0].dateTimeDelivered | date:'MMM dd yyyy h:mm:ss:sss a'}} </td>
                                                    </tr>
                                                    <tr *ngIf="message[0].dateTimeDelivered ==='0' && message[0].processingState!='FAIL'">
                                                        <td>Date Time Delivered</td>
                                                        <td>- </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Device Name(Id)</td>
                                                        <td><span *ngIf="message[0].deviceNames">{{message[0].deviceNames[0].value}}</span>({{message[0].deviceId}})
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Source Stream Name(Id)</td>
                                                        <td><span *ngIf="message[0].sourceStreamNames">{{message[0].sourceStreamNames[0].value}}</span>({{message[0].sourceStreamId}})
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="message[0].destinationStreamName">
                                                        <td>Destination Stream Name(Id)</td>
                                                        <td><span *ngIf="message[0].destinationStreamName">{{
                                                            message[0].destinationStreamName
                                                            }}</span>({{message[0].destinationStreamId}})</td>
                                                    </tr>
                                                    <tr *ngIf="message[0].eventId">
                                                        <td>Event Template Name(Id)</td>
                                                        <td>{{message[0].eventTemplateNames[0].value}} ({{message[0].eventId}})
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="message[0].commandId">
                                                        <td>Command Template Name(Id)</td>
                                                        <td>{{message[0].commandTemplateNames[0].value}} ({{message[0].commandId}})
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Customer Message Id</td>
                                                        <td>{{ message[0].customerMessageId }}</td>
                                                    </tr>
                                                    <tr *ngIf="message[0].commonMessageInfo">
                                                        <td>Common Message Info</td>
                                                        <td>{{message[0].commonMessageInfo}}</td>
                                                    </tr>
                                                    <tr *ngIf="message[0].senderMessageInfo">
                                                        <td>Sender Message Info</td>
                                                        <td>{{message[0].senderMessageInfo}}</td>
                                                    </tr>
                                                    <tr *ngIf="message[0].receiverMessageInfo">
                                                        <td>Receiver Message Info</td>
                                                        <td>{{message[0].receiverMessageInfo}}</td>
                                                    </tr>
                                                    <tr *ngIf="message[0].relationType">
                                                        <td>Relation Type</td>
                                                        <td>{{message[0].relationType}}</td>
                                                    </tr>
                                                    <tr *ngIf="message[0].processingState=='UNKNOWN'||message[0].processingState==''||!message[0].processingState">
                                                        <td>Processing State</td>
                                                        <td>Under Processing</td>
                                                    </tr>
                                                    <tr *ngIf="message[0].processingState != 'UNKNOWN' && message[0].processingState != '' && message[0].processingState ">
                                                        <td>Processing State</td>
                                                        <td>{{message[0].processingState}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div *ngIf="message[0].sourceType == 'UNI' || message[0].sourceType == 'SYNC'">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Source Stream Name(Id)</td>
                                                        <td><span *ngIf="message[0].sourceStreamNames">{{message[0].sourceStreamNames[0].value}}</span>({{message[0].sourceStreamId}})
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Destination Stream Name(Id)</td>
                                                        <td><span *ngIf="message[0].destinationStreamName">{{
                                                            message[0].destinationStreamName }}</span> ({{ message[0].destinationStreamId }})</td>
                                                    </tr>
                                                    <tr *ngIf="message[0].senderTradingPartnerId">
                                                        <td>Sender TradingPartner Id/Name</td>
                                                        <td> <span *ngIf="message[0].senderTradingPartnerName">{{
                                                            message[0].senderTradingPartnerName}}</span> ({{ message[0].senderTradingPartnerId }})
                                                        </td>
                                                    </tr>

                                                    <tr *ngIf="message[0].receiverTradingPartnerId">
                                                        <td>Receiver TradingPartner Id/Name</td>
                                                        <td><span *ngIf="message[0].receiverTradingPartnerName">{{
                                                            message[0].receiverTradingPartnerName }}</span> ({{ message[0].receiverTradingPartnerId }} )
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Sender Id/Value/Qualifier</td>
                                                        <td><span *ngIf="message[0].senderCodeValue">{{
                                                            message[0].senderCodeValue }} </span><span *ngIf="message[0].senderCodeQualifier">{{
                                                            message[0].senderCodeQualifier}}</span> ({{ message[0].senderCodeId }} )</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Receiver Id/Value/Qualifier</td>
                                                        <td> <span *ngIf="message[0].receiverCodeValue">{{
                                                            message[0].receiverCodeValue }} </span><span *ngIf="message[0].receiverCodeQualifier">
                                                            {{ message[0].receiverCodeQualifier}}</span> ({{ message[0].receiverCodeId }})</td>
                                                    </tr>
                                                    <tr *ngIf="message[0].senderMessageInfo">
                                                        <td>Sender Message Info</td>
                                                        <td>{{ message[0].senderMessageInfo }}</td>
                                                    </tr>
                                                    <tr *ngIf="message[0].commonMessageInfo">
                                                        <td>Common Message Info</td>
                                                        <td>{{ message[0].commonMessageInfo }}</td>
                                                    </tr>
                                                    <tr *ngIf="message[0].receiverMessageInfo">
                                                        <td>Receiver Message Info</td>
                                                        <td>{{ message[0].receiverMessageInfo }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Date Time Received</td>
                                                        <td>{{ message[0].dateTimeReceived | date:'MMM dd yyyy h:mm:ss:sss a'}} </td>
                                                    </tr>
                                                    <tr *ngIf="message[0].processingState == 'FAIL'">
                                                        <td>Date Time Delivered</td>
                                                        <td>Message Not delivered</td>
                                                    </tr>
                                                    <tr *ngIf="message[0].dateTimeDelivered != '0' && message[0].processingState != 'FAIL'">
                                                        <td>Date Time Delivered</td>
                                                        <td>{{ message[0].dateTimeDelivered | date:'MMM dd yyyy h:mm:ss:sss a'}} </td>
                                                    </tr>
                                                    <tr *ngIf="message[0].dateTimeDelivered == '0' && message[0].processingState != 'FAIL'">
                                                        <td>Date Time Delivered</td>
                                                        <td> - </td>
                                                    </tr>
                                                    <tr *ngIf="message[0].relationType">
                                                        <td>Relation Type</td>
                                                        <td>{{ message[0].relationType }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Customer Message Id</td>
                                                        <td>{{ message[0].customerMessageId }}</td>
                                                    </tr>
                                                    <tr *ngIf="message[0].processingState == 'UNKNOWN' || message[0].processingState == '' || !message[0].processingState ">
                                                        <td>Processing State</td>
                                                        <td>Under Processing</td>
                                                    </tr>
                                                    <tr *ngIf="message[0].processingState != 'UNKNOWN' && message[0].processingState != '' && message[0].processingState ">
                                                        <td>Processing State</td>
                                                        <td>{{message[0].processingState}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div *ngIf="message[0].sourceType == 'RULES'">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Date Time Received</td>
                                                        <td> {{ message[0].dateTimeReceived | date:'MMM dd yyyy h:mm:ss:sss a'}} </td>
                                                    </tr>
                                                    <tr *ngIf="message[0].processingState == 'FAIL'">
                                                        <td>Date Time Delivered</td>
                                                        <td>Message Not delivered</td>
                                                    </tr>
                                                    <tr *ngIf="message[0].dateTimeDelivered != '0' && message[0].processingState != 'FAIL'">
                                                        <td>Date Time Delivered</td>
                                                        <td>{{ message[0].dateTimeDelivered | date:'MMM dd yyyy h:mm:ss:sss a'}} </td>
                                                    </tr>
                                                    <tr *ngIf="message[0].dateTimeDelivered == '0' && message[0].processingState != 'FAIL'">
                                                        <td>Date Time Delivered</td>
                                                        <td> - </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Stream Name(Id)</td>
                                                        <td><span *ngIf="message[0].sourceStreamNames">{{message[0].sourceStreamNames[0].value}}</span>({{message[0].sourceStreamId}})
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Destination Stream Name(Id)</td>
                                                        <td><span *ngIf="message[0].destinationStreamName">{{
                                                            message[0].destinationStreamName }}</span> ({{ message[0].destinationStreamId }})</td>
                                                    </tr>
                                                    <tr *ngIf="message[0].customerMessageId">
                                                        <td>Customer Message Id</td>
                                                        <td>{{ message[0].customerMessageId }}</td>
                                                    </tr>
                                                    <tr *ngIf="message[0].commonMessageInfo">
                                                        <td>Common Message Info</td>
                                                        <td>{{ message[0].commonMessageInfo}}</td>
                                                    </tr>
                                                    <tr *ngIf="message[0].senderMessageInfo">
                                                        <td>Sender Message Info</td>
                                                        <td>{{ message[0].senderMessageInfo}}</td>
                                                    </tr>
                                                    <tr *ngIf="message[0].receiverMessageInfo">
                                                        <td>Receiver Message Info</td>
                                                        <td>{{ message[0].receiverMessageInfo}}</td>
                                                    </tr>
                                                    <tr *ngIf="message[0].relationType">
                                                        <td>Relation Type</td>
                                                        <td>{{ message[0].relationType }}</td>
                                                    </tr>
                                                    <tr *ngIf="message[0].processingState == 'UNKNOWN' || message[0].processingState == '' || !message[0].processingState ">
                                                        <td>Processing State</td>
                                                        <td>Under Processing</td>
                                                    </tr>
                                                    <tr *ngIf="message[0].processingState != 'UNKNOWN' && message[0].processingState != '' && message[0].processingState ">
                                                        <td>Processing State</td>
                                                        <td>{{message[0].processingState}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <mat-accordion [togglePosition]="'before'">
                                            <mat-expansion-panel *ngFor="let auditProcessor of processorAudits; let i = index">
                                                <mat-expansion-panel-header>
                                                    <mat-panel-title style="width: 30%; ">
                                                        <span>{{ auditProcessor.name }} </span>
                                                    </mat-panel-title>
                                                    <mat-panel-description style="width: 70%;">
                                                        <small> -
                                                        <span *ngIf="auditProcessor.processingState==='FAIL'">
                                                            <fa-icon style="color: red;font-size: 20px;"
                                                                [icon]="failed"></fa-icon>
                                                        </span>
                                                        <span *ngIf="auditProcessor.processingState==='COMPLETE'">
                                                            <fa-icon style="color: green;font-size: 20px;"
                                                                [icon]="envelope"></fa-icon>
                                                        </span> - {{ auditProcessor.startTime | date:'MMM dd
                                                        yyyy h:mm:ss:sss a'}} - {{ auditProcessor.startTime --
                                                        (auditProcessor.processTimeMs != 'null' ?
                                                        auditProcessor.processTimeMs : 0) | date:'MMM dd yyyy
                                                        h:mm:ss:sss a'}} - {{ (auditProcessor.processTimeMs !=
                                                        'null' ? auditProcessor.processTimeMs : 0) }} MS
                                                    </small>
                                                    </mat-panel-description>
                                                </mat-expansion-panel-header>
                                                <div>

                                                    <div>
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Creation</td>
                                                                    <td>{{ auditProcessor.startTime | date:'MMM dd yyyy h:mm:ss:sss a'}} </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="audit">Connector Process Time
                                                                    </td>
                                                                    <td>{{ auditProcessor.startTime -- (auditProcessor.processTimeMs != 'null' ? auditProcessor.processTimeMs : 0) | date:'MMM dd yyyy h:mm:ss:sss a'}} </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="audit">Connector Service</td>
                                                                    <td>{{ auditProcessor.name }}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="audit">Creator</td>
                                                                    <td *ngIf="message[0].creator!='UNKNOWN'">{{ message[0].creator }}</td>
                                                                    <td *ngIf="message[0].creator==='UNKNOWN'"></td>
                                                                </tr>
                                                                <tr *ngIf="auditProcessor.startTime != 0">
                                                                    <td class="audit">Pulled From Queue</td>
                                                                    <td>{{ auditProcessor.startTime | date:'MMM dd yyyy h:mm:ss:sss a'}} </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="audit">Process Time MS</td>
                                                                    <td>{{ auditProcessor.processTimeMs }}</td>
                                                                </tr>
                                                                <tr *ngIf="message[0].destinationQueue">
                                                                    <td class="audit">Destination Queue</td>
                                                                    <td>{{ message[0].destinationQueue }}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="audit">Processing State</td>
                                                                    <td>{{auditProcessor.processingState}}</td>
                                                                </tr>
                                                                <tr *ngIf="message[0].EventSource">
                                                                    <td class="audit">Event Source</td>
                                                                    <td>{{ message[0].EventSource }}</td>
                                                                    <tr *ngIf="auditProcessor.customMessage">
                                                                        <td class="audit">Custom Message</td>
                                                                        <td>{{ auditProcessor.customMessage }}</td>
                                                                    </tr>
                                                                    <tr *ngIf="auditProcessor.exceptionIn">
                                                                        <td class="audit">Exception In</td>
                                                                        <td>{{ auditProcessor.exceptionIn }}</td>
                                                                    </tr>
                                                                    <tr *ngIf="auditProcessor.exceptionType">
                                                                        <td class="audit">Exception Type</td>
                                                                        <td>{{ auditProcessor.exceptionType }}</td>
                                                                    </tr>
                                                                    <tr *ngIf="auditProcessor.throwTrace">
                                                                        <td class="audit">Throw Trace</td>
                                                                        <td>{{ auditProcessor.throwTrace }}</td>
                                                                    </tr>
                                                                    <tr *ngIf="auditProcessor.nestedTrace">
                                                                        <td class="audit">Nested Trace</td>
                                                                        <td>{{ auditProcessor.nestedTrace }}</td>
                                                                    </tr>
                                                                    <tr *ngFor="let auditInfo of auditInfos; let i = index">
                                                                        <ng-container *ngIf="auditInfo.processorName === auditProcessor.name">
                                                                            <td class="audit">Audit Infos</td>
                                                                            <td>
                                                                                <table>
                                                                                    <thead>
                                                                                        <th>DateTime</th>
                                                                                        <th>Message</th>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td style="width:30%;">{{ auditInfo.dateTime | date:'MMM dd yyyy h:mm:ss:sss a'}}</td>
                                                                                            <td style="width:70%;">{{ auditInfo.text }}</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </ng-container>
                                                                    </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>


                                                </div>
                                            </mat-expansion-panel>
                                        </mat-accordion>
                                    </div>
                                </mat-expansion-panel>

                            </mat-tab>
                            <mat-tab label="Message Payloads" *ngIf="!!senderFilePayload||!!senderFilePathToPayload||!!receiveFilePayload||!!receiveFilePathToPayload||!!senderWirePayload||!!senderWirePathToPayload||!!receiverWirePayload||!!receiverWirePathToPayload ">

                                <mat-accordion [togglePosition]="'before'">
                                    <mat-expansion-panel [expanded]="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                <h6>SELF: {{tracking.messageId}}</h6>
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div class="expanded">
                                            <table>
                                                <tboday>
                                                    <!-- <tr>
                                                        <td><b>Direction</b></td>
                                                        <td> <b>Content</b></td>
                                                        <td><b>Size</b></td>
                                                    </tr> -->
                                                    <thead>
                                                        <th class="direction-cell">Direction</th>
                                                        <th class="content-cell">Content</th>
                                                        <th class="size-cell">Size</th>
                                                    </thead>

                                                    <tr *ngIf="senderWirePathToPayload===true ">
                                                        <td>Sender Wire Path To Payload</td>
                                                        <td class="payload-data">
                                                            <a (click)="downloadFile(senderWirePathToPayload,message[0].realm,'senderWirePathToPayload','','SENDER_WIRE', message[0].messageId,senderWirePayload )">Download
                                                            file</a>
                                                        </td>
                                                        <td *ngIf="message[0].senderWireAttributes!=undefined">{{message[0].senderWireAttributes.size}}</td>
                                                    </tr>
                                                    <tr *ngIf="senderWirePayload && senderWirePathToPayload===false ">
                                                        <td style=" width: 230px;">Sender Wire Payload</td>
                                                        <td class="payload-data">
                                                            {{senderWirePayload}}</td>
                                                        <td *ngIf="message[0].senderWireAttributes!=undefined">{{message[0].senderWireAttributes.size}}</td>
                                                    </tr>
                                                    <tr *ngIf="senderWirePayload && senderWirePathToPayload===false">
                                                        <td></td>
                                                        <td>
                                                            <button class="ot-btn ot-btn--is-secondary" (click)="reset('senderPayload')">Reset Payload</button>&nbsp;
                                                            <button class="ot-btn ot-btn--is-secondary" (click)="encodePayload('senderPayload')">Encode Payload</button>&nbsp;
                                                            <button class="ot-btn ot-btn--is-secondary" (click)="decodePayload('senderPayload')">Decode Payload</button>&nbsp;
                                                            <button class="ot-btn ot-btn--is-secondary" (click)="downloadPayload('senderPayload')">Download</button>&nbsp;
                                                        </td>
                                                        <td></td>
                                                    </tr>

                                                    <tr *ngIf="senderFilePathToPayload && senderFilePathToPayload === true ">
                                                        <td>Sender File Path To Payload</td>
                                                        <td *ngIf="senderFilePathToPayload" class="payload-data">
                                                            <a (click)="downloadFile(senderFilePathToPayload, message[0].realm, 'senderFilePathToPayload','', 'SENDER_FILE' , message[0].messageId , senderFilePayload)">Download
                                                            file</a></td>
                                                        <td *ngIf="message[0].senderWireAttributes!=undefined">{{ message[0].senderFileAttributes.size }}</td>
                                                    </tr>
                                                    <tr *ngIf="senderFilePayload && senderFilePathToPayload === false && message[0].senderWireAttributes!=undefined">
                                                        <td>Sender File Payload</td>
                                                        <td class="payload-data">
                                                            {{senderFilePayload }}</td>
                                                        <td *ngIf="message[0].senderWireAttributes!=undefined">{{ message[0].senderFileAttributes.size }}</td>
                                                    </tr>
                                                    <tr *ngIf="senderFilePayload && senderFilePathToPayload === false">
                                                        <td></td>
                                                        <td>
                                                            <button class="ot-btn ot-btn--is-secondary" (click)="reset('senderPayloadFile')">Reset Payload</button>&nbsp;
                                                            <button class="ot-btn ot-btn--is-secondary" (click)="encodePayload('senderPayloadFile')">Encode Payload</button>&nbsp;
                                                            <button class="ot-btn ot-btn--is-secondary" (click)="decodePayload('senderPayloadFile')">Decode Payload</button>&nbsp;
                                                            <button class="ot-btn ot-btn--is-secondary" (click)="downloadPayload('senderPayloadFile')">Download</button>&nbsp;
                                                        </td>
                                                        <td></td>
                                                    </tr>

                                                    <tr *ngIf="commonFilePathToPayload === true ">
                                                        <td>Common File Path To Payload</td>
                                                        <td *ngIf="commonFilePathToPayload" class="payload-data">
                                                            <a href="" (click)="downloadFile(commonFilePathToPayload, message[0].realm, 'commonFilePathToPayload','', 'COMMON' , message[0].messageId , commonFilePayload)">Download
                                                            file</a></td>
                                                        <td *ngIf="message[0].commonFileAttributes!=undefined">{{ message[0].commonFileAttributes.size }}</td>
                                                    </tr>
                                                    <tr *ngIf="commonFilePayload && commonFilePathToPayload === false">
                                                        <td>Common File Payload</td>
                                                        <td class="payload-data">
                                                            {{commonFilePayload }}</td>
                                                        <td *ngIf="message[0].commonFileAttributes!=undefined">{{ message[0].commonFileAttributes.size }}</td>
                                                    </tr>
                                                    <tr *ngIf="commonFilePayload && commonFilePathToPayload === false">
                                                        <td></td>
                                                        <td>
                                                            <button class="ot-btn ot-btn--is-secondary" (click)="reset('commonPayloadFile')">Reset Payload</button>&nbsp;
                                                            <button class="ot-btn ot-btn--is-secondary" (click)="encodePayload('commonPayloadFile')">Encode Payload</button>&nbsp;
                                                            <button class="ot-btn ot-btn--is-secondary" (click)="decodePayload('commonPayloadFile')">Decode Payload</button>&nbsp;
                                                            <button class="ot-btn ot-btn--is-secondary" (click)="downloadPayload('commonPayloadFile')">Download</button>&nbsp;
                                                        </td>
                                                        <td></td>
                                                    </tr>

                                                    <tr *ngIf="receiverFilePathToPayload === true">
                                                        <td>Receiver File Path To Payload</td>
                                                        <td *ngIf="receiverFilePathToPayload" class="payload-data">
                                                            <a href="" (click)="downloadFile(receiverFilePathToPayload, message[0].realm, 'receiverFilePathToPayload','', 'RECEIVER_FILE' , message[0].messageId ,receiverFilePayload)">Download
                                                            file</a></td>
                                                        <td *ngIf="message[0].receiverFileAttributes!=undefined">{{ message[0].receiverFileAttributes.size }}</td>
                                                    </tr>
                                                    <tr *ngIf="receiverFilePayload && receiverFilePathToPayload === false">
                                                        <td>Receiver File Payload</td>
                                                        <td class="payload-data">
                                                            {{ receiverFilePayload }}</td>
                                                        <td *ngIf="message[0].receiverFileAttributes!=undefined">{{ message[0].receiverFileAttributes.size }}</td>
                                                    </tr>
                                                    <tr *ngIf="receiverFilePayload && receiverFilePathToPayload === false">
                                                        <td></td>
                                                        <td>
                                                            <button class="ot-btn ot-btn--is-secondary" (click)="reset('receivePayloadFile')">Reset Payload</button>&nbsp;
                                                            <button class="ot-btn ot-btn--is-secondary" (click)="encodePayload('receivePayloadFile')">Encode Payload</button>&nbsp;
                                                            <button class="ot-btn ot-btn--is-secondary" (click)="decodePayload('receivePayloadFile')">Decode Payload</button>&nbsp;
                                                            <button class="ot-btn ot-btn--is-secondary" (click)="downloadPayload('receivePayloadFile')">Download</button>&nbsp;
                                                        </td>
                                                        <td></td>
                                                    </tr>

                                                    <tr *ngIf="receiverWirePathToPayload === true">
                                                        <td>Receiver Wire Path To Payload</td>
                                                        <td *ngIf="receiverWirePathToPayload" class="payload-data">
                                                            <a href="" (click)="downloadFile(receiverWirePathToPayload, message[0].realm, 'receiverWirePathToPayload','', 'RECEIVER_WIRE' , message[0].messageId , receiverWirePayload)">Download
                                                            file</a></td>
                                                        <td *ngIf="message[0].receiverWireAttributes!=undefined">{{ message[0].receiverWireAttributes.size }}</td>
                                                    </tr>
                                                    <tr *ngIf="receiverWirePayload && receiverWirePathToPayload === false">
                                                        <td>Receiver Wire Payload</td>
                                                        <td class="payload-data">
                                                            {{ receiverWirePayload }}</td>
                                                        <td *ngIf="message[0].receiverWireAttributes!=undefined">{{ message[0].receiverWireAttributes.size }}</td>
                                                    </tr>
                                                    <tr *ngIf="receiverWirePayload && receiverWirePathToPayload === false">
                                                        <td></td>
                                                        <td>
                                                            <button class="ot-btn ot-btn--is-secondary" (click)="reset('receivePayload')">Reset Payload</button>&nbsp;
                                                            <button class="ot-btn ot-btn--is-secondary" (click)="encodePayload('receivePayload')">Encode Payload</button>&nbsp;
                                                            <button class="ot-btn ot-btn--is-secondary" (click)="decodePayload('receivePayload')">Decode Payload</button>&nbsp;
                                                            <button class="ot-btn ot-btn--is-secondary" (click)="downloadPayload('senderPayloadFile')">Download</button>&nbsp;
                                                        </td>
                                                        <td></td>
                                                    </tr>

                                                </tboday>
                                            </table>
                                        </div>
                                    </mat-expansion-panel>

                                </mat-accordion>


                            </mat-tab>
                        </mat-tab-group>

                        <!-- <button class="close-btn" (click)="expandMessage(tracking,expandedMessage === tracking ? null : tracking);expandedMessage = expandedMessage === tracking ? null : tracking">Close</button> -->
                    </div>
                    <br>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let tracking; columns: displayedColumns;" [ngClass]="tracking.messageClass" [class.message-expanded-row]="expandedMessage === value"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedMessage']" class="message-detail-row"></tr>
    </table>


    <div>
        <ngx-skeleton-loader *ngIf="!dataSource" count="10" [theme]="{'border-radius': '0px',
            height: '48px',
            width: '100%',
            margin: '1px',
            position: 'relative',
            top: '20px'}">
        </ngx-skeleton-loader>
    </div>
    
    <!-- OT Css pagination -->
    <app-pagination *ngIf="showPagination" [count]="trackingCount" [pageSize]="pageSize" [currentPage]="currentPage" 
        [pageSizeOptions]="pageSizeOptions" (currentPageChange)="onPageChange($event)" 
        (currentPageSizeChange)="onPageSizeChange($event)">
    </app-pagination>
    <br/>

</ng-container>
/**
 * This is an auto generated code for ecosystemRelationships listing page.
 * Please don't make any change to this file.
 * @version 1.0.0
 * @readonly
 * @author - System generated
 */

import { Component, OnInit, ViewChild, Injector, Inject } from '@angular/core';
import { IotService } from '../service/iot.service';
import { EcosystemRelationshipsService } from '../service/ecosystemRelationships.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  faCaretUp,
  faCaretDown,
  faInfoCircle,
  faQuestionCircle,
  faArrowUp,
  faArrowDown,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { BaseListComponent } from '../base-list/baselist.component';

interface TypeChange {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-ecosystemRelationships',
  templateUrl: './ecosystemRelationships.component.html',
  styleUrls: [
    '../base-list/baselist.component.scss',
    './ecosystemRelationships.component.scss',
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class EcosystemRelationshipsComponent extends BaseListComponent {
  displayedColumns: string[] = [
    'source',
    'relationship',
    'target',
    'effective',
    'expires',
    'relationshipAttributes',
    'ACTION',
  ];
  relationshipAttributeColumns: string[] = [
    'attributeName',
    'attributeType',
    'value',
  ];
  sortColumns: string[] = [];
  pageTitle = 'ecosystemRelationships.header';
  countCallCompleted: boolean = false;
  getCallCompleted: boolean = false;
  showActionColumn = true;
  previousAttributeElement;
  relationshipAttributes = [];

  constructor(
    router: Router,
    iotService: IotService,
    translateService: TranslateService,
    sideNavComponent: SideNavComponent,
    public ecosystemRelationshipsService: EcosystemRelationshipsService
  ) {
    super(router, iotService, translateService, sideNavComponent);
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.ecosystemRelationshipsService.multiSelectRows = this.multiSelect;
    this.ecosystemRelationshipsService.loadSelected();
    if (!this.showSelectColoumn) {
      this.sideNavComponent.menuClear();
      this.sideNavComponent.menuChange('Dashboard', 'sub-type');
      this.sideNavComponent.menuChange(
        'ecosystemRelationships.header',
        'sub-type'
      );
    }
  }
  getFilteredResults(value, columnName, sortOrder) {
    const that = this;
    that.iotService
      .getCuiObjResponse()
      .getRelationships({
        ecosystemId: that.extraParamsMap.get('ecosystemId'),
        qs: eval(that.fnGetQS(value, columnName, sortOrder)),
      })
      .then(function (response) {
        that.fnSuccess(response);
        if (
          columnName == 'none' ||
          columnName == '' ||
          columnName == undefined
        ) {
          that.fnResetSortParam('creation', 'desc');
        } else {
          that.fnResetSortParam(columnName, sortOrder);
        }
        that.getCallCompleted = true;
      })
      .fail(function (err) {
        that.fnError(err);
        that.getCallCompleted = true;
      });
  }
  getCount() {
    const that = this;
    that.showPagination = false;
    let value = this.selected;
    that.iotService
      .getCuiObjResponse()
      .countRelationships({
        ecosystemId: that.extraParamsMap.get('ecosystemId'),
        qs: eval(that.fnGetQS(value, '', '')),
      })
      .then(function (response) {
        that.totalCount = response;
        that.countCallCompleted = true;
        if (that.totalCount > 0) that.showPagination = true;
      })
      .fail(function (err) {
        that.fnError(err);
        that.countCallCompleted = true;
      });
  }
  deleteRelationships(resourceId) {
    const that = this;
    that.iotService
      .getCuiObjResponse()
      .deleteRelationships({
        ecosystemId: that.extraParamsMap.get('ecosystemId'),
        releationshipId: resourceId,
      })
      .then(function (response) {
        that.getCount();
        that.getFilteredResults(
          that.selected,
          that.sortCriteria,
          that.sortDirection
        );
        that.ecosystemRelationshipsService.fnResetList();
      })
      .fail(function (err) {
        that.fnError(err);
        that.ecosystemRelationshipsService.showErrorMessage(err);
      });
  }
  createNew() {
    this.ecosystemRelationshipsService.createNew();
  }
  onSelection(results) {
    this.ecosystemRelationshipsService.onSelection(results);
  }
  removeSelection(results) {
    this.ecosystemRelationshipsService.removeSelection(results);
  }

  viewAttributes(results, expandedElement) {
    const that = this;
    if (
      that.previousAttributeElement != undefined &&
      that.previousAttributeElement.id != results.id
    ) {
      that.previousAttributeElement.messageClass = 'bg-message';
    }

    let relationAttributes = [];
    results.relationshipAttributes.forEach((element) => {
      let attribute = {
        attributeId: element.attributeType.id,
        name: element.attributeType.name,
        type: element.attributeType.type,
        value: element.value,
      };
      relationAttributes.push(attribute);
    });
    that.relationshipAttributes = relationAttributes;

    if (expandedElement === null) {
      results.messageClass = 'bg-message';
    } else {
      results.messageClass = 'bg-message-selected';
    }
    that.previousAttributeElement = results;
  }
}

<mat-sidenav-container class="new-template" autosize>

    <!-- For new template creation -->
    <mat-sidenav class="midNav-container" #sidenav mode="side" disableClose="true" opened="true">
        <div class="alert critical" *ngIf="deviceService.errorMessage">
            <h5>
                <fa-icon [icon]="faCriticalIcon"></fa-icon>
                <span>{{deviceService.errorMessage}}</span>
                <button class="alert-close-btn"><fa-icon (click)=deviceService.resetErrorMessage() [icon]="faCloseIcon"></fa-icon></button>
            </h5>
        </div>
        <div class="alert success" *ngIf="deviceService.successMessage">
            <h5>
                <fa-icon [icon]="faSuccessIcon"></fa-icon>
                <span>{{deviceService.successMessage}}</span>
                <button class="alert-close-btn"><fa-icon (click)=deviceService.resetSuccessMessage() [icon]="faCloseIcon"></fa-icon></button>
            </h5>
        </div>
        <div class="alert warning" *ngIf="deviceService.warningMessage">
            <h5>
                <fa-icon [icon]="faWarningIcon"></fa-icon>
                <span *ngIf="deviceService.duplicateAttrFlag">{{deviceService.warningMessage}} <a (click)="deactivateAttribute(deviceService.duplicateAttributeId)">{{'deactivate.attribute' | translate }}</a> {{'enable.this.one' | translate}}</span>
                <span *ngIf="deviceService.duplicateCommandFlag">{{deviceService.warningMessage}} <a (click)="disableCommand(deviceService.duplicateCommandId)">{{'deactivate.command' | translate }}</a> {{'enable.this.one' | translate}}</span>
                <span *ngIf="deviceService.duplicateEventFlag">{{deviceService.warningMessage}} <a (click)="disableEvent(deviceService.duplicateEventId)">{{'deactivate.event' | translate }}</a> {{'enable.this.one' | translate}}.</span>
                <span *ngIf="!deviceService.duplicateEventFlag && !deviceService.duplicateCommandFlag && !deviceService.duplicateAttrFlag">{{deviceService.warningMessage}}</span>

                <button class="alert-close-btn">
                    <fa-icon (click)="deviceService.resetWarningMessage()" [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>
        <h1>{{'create.new.template' | translate}}</h1>

        <mat-nav-list class="without-template user-selections">
            <h2>{{'attributes' | translate}}</h2>
            <p *ngIf="digitalTwinTemplatesService.getAttributes().length==0">{{'select.availableAttributes.desc' | translate}}</p>
            <ul>
                <li *ngFor="let attribute of digitalTwinTemplatesService.getAttributes()"><fa-icon [icon]="faCheckIcon"></fa-icon> {{attribute.name}}</li>
            </ul>

            <h2>{{'events' | translate}}</h2>
            <p *ngIf="digitalTwinTemplatesService.getEvents().length==0">{{'select.availableEvents.desc' | translate}}</p>
            <ul>
                <li *ngFor="let event of digitalTwinTemplatesService.getEvents()"><fa-icon [icon]="faCheckIcon"></fa-icon> {{event.name}}</li>
            </ul>

            <h2>{{'commands' | translate}}</h2>
            <p *ngIf="digitalTwinTemplatesService.getCommands().length==0">{{'select.availableCommands.desc' | translate}}</p>
            <ul>
                <li *ngFor="let command of digitalTwinTemplatesService.getCommands()"><fa-icon [icon]="faCheckIcon"></fa-icon> {{command.name}}</li>
            </ul>

            <h2>{{'geofences' | translate}}</h2>
            <p *ngIf="digitalTwinTemplatesService.getGeofences().length==0">{{'select.availableGeofences.desc' | translate}}</p>
            <ul>
                <li *ngFor="let geofence of digitalTwinTemplatesService.getGeofences()"><fa-icon [icon]="faCheckIcon"></fa-icon> {{geofence.name[0].text}}</li>
            </ul>

            <br>
            <button *ngIf="deviceService.deviceNameSelect"  style="margin-left: 15px;"  [disabled]="newDeviceTemplateCreated" (click)=createNewDeviceTemplate(false,false) class="btn btn-primary" >{{'save.another' | translate}}</button>
            <button *ngIf="deviceService.deviceNameSelect" [disabled]="newDeviceTemplateCreated" (click)=createNewDeviceTemplate(true,false) class="btn btn-primary" >{{'save.exit' | translate}}</button>
            <button  *ngIf="deviceService.deviceNameSelect" style="margin-left: 15px;" [disabled]="newDeviceTemplateCreated" (click)=createNewDeviceTemplate(true,true) class="btn btn-primary" >{{'save.template' | translate}}</button>
            <br><br>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="example-sidenav-content">



        <!-- for new template creation -->
        <ng-container>

            <ng-container>
                <ng-container *ngIf="deviceService.createGeofence">
                    <app-create-geofence></app-create-geofence>
                </ng-container>
                <ng-container *ngIf="deviceService.createAttribute">
                    <app-create-attribute></app-create-attribute>
                </ng-container>
                <ng-container *ngIf="deviceService.createEvent">
                    <app-create-event></app-create-event>
                </ng-container>
                <ng-container *ngIf="deviceService.createCommand">
                    <app-create-command></app-create-command>
                </ng-container>
                <ng-container *ngIf="deviceService.mapLoad">
                    <app-map-component></app-map-component>
                </ng-container>
                <ng-container *ngIf="!deviceService.createResource">
                    <app-description></app-description>
                    <app-attributes [pageSize]="5" [showSelectColoumn]=true [showEnableColumn]=true [showControlBar]=true
                    [showNew]=true [displayedColumns]="['ADD', 'name', 'description', 'type','creation', 'ACTIVE']"
                    [newWithRoute]=false [showHeaderToolTips]=false></app-attributes>
                    <app-events [pageSize]="5" [showSelectColoumn]=true [showEnableColumn]=true [showControlBar]=true
                    [showNew]=true [displayedColumns]="['ADD', 'name', 'description','creation', 'ACTIVE']" [newWithRoute]=false [showHeaderToolTips]=false>
                    </app-events>
                    <app-commands  [pageSize]="5 " [showControlBar]=true
                    [displayedColumns]="[ 'ADD', 'name','description','creation','ACTIVE'] " [showSelectColoumn]=true
                    [showEnableColumn]=true [showNew]="true" [showHeaderToolTips]=false></app-commands>
                    <app-geofences [pageSize]="5" [showSelectColoumn]="true"
                    [showControlBar]=true [showNew]="true" multiSelect="true"
                    [displayedColumns]="['ADD', 'name', 'description','creation', 'ACTIVE']" [newWithRoute]=false [showHeaderToolTips]=false></app-geofences>
                </ng-container>
            </ng-container>
        </ng-container>
    </mat-sidenav-content>

</mat-sidenav-container>
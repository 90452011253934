<header>
    <div class="container">
      <div class="row">
        <!-- <div class="col-sm-3">
        </div> -->
        <div class="col-sm-6 offset-xs-1 offset-sm-2 offset-md-3 logo">
          <h1>
            <img class="ot-logo" alt="OpenText" src="assets/i/logo/opentext.svg" title="OpenText | IoT Platform">
            <img class="logo-pipe" alt="" src="assets/i/logo/divider.svg">
            <span class="logo-name">IoT Platform</span>
          </h1>
        </div>
        <div class="col-sm-3 text-right desktop-buttons">
          <a class="d-none d-xs-none d-sm-none d-md-none d-lg-block d-xl-block btn-logout-desktop btn" (click)=logout()>Log Out</a>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-4 col-sm-4 offset-xs-8 offset-sm-8 d-lg-none d-xl-none text-right mobile-buttons">
          <a class="btn-logout-mobile btn" (click)=logout() title="Log Out"><fa-icon [icon]="faSignOutAlt"></fa-icon></a>
        </div>
      </div>
    </div>
</header>
import { Injectable } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { IotService } from '../service/iot.service';
import { ListEcosystemMembersService } from './ListEcosystemMembers.service';
import { EcosystemsService } from './ecosystems.service';

@Injectable({
  providedIn: 'root',
})
export class EcosystemMembersService extends ListEcosystemMembersService {
  selection: SelectionModel<String> = new SelectionModel<String>(true, []);

  constructor(
    public iotService: IotService,
  ) {
    super(iotService);
    this.selection.clear();
  }

  selectedPersonId;
  selectedDeviceId;
  selectedEcosystemId;
  selectedOrganizationId;
  selectedApplicationId;
  selectedAttributes = [];
  membershipAttributesForUpdate = [];

  fnOnSelectionSupport(results) {
    const that = this;
  }

  fnRemoveSelectionSupport(results) {
    const that = this;
  }

  getSelectedPersonId(): any {
    return this.selectedPersonId;
  }

  setSelectedPersonId(selectedPersonId: any) {
    this.selectedPersonId = selectedPersonId;
  }

  getSelectedDeviceId(): any {
    return this.selectedDeviceId;
  }

  setSelectedDeviceId(selectedDeviceId: any) {
    this.selectedDeviceId = selectedDeviceId;
  }

  getSelectedEcosystemId(): any {
    return this.selectedEcosystemId;
  }

  setSelectedEcosystemId(selectedEcosystemId: any) {
    this.selectedEcosystemId = selectedEcosystemId;
  }

  getSelectedOrganizationId(): any {
    return this.selectedOrganizationId;
  }

  setSelectedOrganizationId(selectedOrganizationId: any) {
    this.selectedOrganizationId = selectedOrganizationId;
  }

  getSelectedApplicationId(): any {
    return this.selectedApplicationId;
  }

  setSelectedApplicationId(selectedApplicationId: any) {
    this.selectedApplicationId = selectedApplicationId;
  }

  getSelectedAttributes(): any {
    return this.selectedAttributes;
  }

  setSelectedAttributes(selectedAttributes: any) {
    this.selectedAttributes = selectedAttributes;
  }

  setMembershipAttributesForUpdate(membershipAttributesForUpdate) {
    this.membershipAttributesForUpdate = membershipAttributesForUpdate;
  }

  getMembershipAttributesForUpdate() {
    return this.membershipAttributesForUpdate;
  }
}

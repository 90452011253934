import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IotService } from 'src/app/service/iot.service';
import { SecurtiyService } from 'src/app/service/security.service';
import { SideNavComponent } from 'src/app/side-nav/side-nav.component';
import { faAngleUp, faAngleDown  , faTimes,faExclamationTriangle,faExclamation} from '@fortawesome/free-solid-svg-icons';



@Component({
  selector: 'app-new-ssh-key',
  templateUrl: './new-ssh-key.component.html',
  styleUrls: ['./new-ssh-key.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  }
})
export class NewSshKeyComponent implements OnInit {

  name='';
  description='';
  privateCertData='';
  warningMessage='';
  errorMessage='';
  sshKeyPair;
  nameWarningMessage = '';
  nameValidationWarningMessage = '';
  descriptionWarningMessage = '';
  keyWarningMessage = '';
  keyWarningMessage2 = '';
  publicCertData='';

  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;
  faCloseIcon=faTimes;
  faWarningIcon = faExclamationTriangle;
  faCriticalIcon=faExclamation;
  exampleToggle = true;
  exampleToggle2 = true;
  sshKeyCreated=false;

  supportLanguages = ['en'];

  constructor(public iotService: IotService, private translateService: TranslateService, private sideNavComponent: SideNavComponent, private router: Router, public securityService: SecurtiyService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
    this.sideNavComponent.menuClear();
    this.sideNavComponent.menuChange('Dashboard', 'sub-type');
    this.sideNavComponent.menuChange('sshKeys.header', 'sub-type');
    this.sideNavComponent.menuChange('sshKeyPairs.header','sub-type');
    this.sideNavComponent.menuChange('sshKeyPairs.create.new', 'sub-type');
  }

  ngOnInit(): void {
    this.loadSSHKeyPair();
  }

  onClick(event) {
    this.resetWarningMessage2();

  }

  resetWarningMessage2(){
    this.warningMessage = "";
    this.errorMessage='';
  }

  loadSSHKeyPair() {
    const that = this;
    that.sshKeyPair = {
      "creator": that.iotService.getCreator(),
      "creatorAppId": that.iotService.getCreatorAppId(),
      "realm": that.iotService.getRealm(),
      "privateKeyText": that.privateCertData,
      "publicKeyText": that.publicCertData,
      "name": that.name,
      "description": that.description

    }

  }

  resetWarningMessage(event: any) {
    this.warningMessage = "";
    this.nameWarningMessage = '';
    this.descriptionWarningMessage = '';
    this.keyWarningMessage = '';
    this.keyWarningMessage2 = '';
    this.nameValidationWarningMessage = '';
  }

  isEmpty(str) {
    if (str) {
      return (str.length === 0 || !str.trim());
    }
  };

  save() {

    const that = this;
    if (that.isEmpty(that.name) || that.name === '' || that.name === undefined) {
      that.nameWarningMessage = "Name is mandatory";
    }
    if (!that.isAlpha(that.name)) {
      that.nameValidationWarningMessage = "Name should only contain alphanumeric character example:[a-zA-Z0-9 _-]"
    }
    if ((that.description === '') || that.isEmpty(that.description) || !that.description) {
      that.descriptionWarningMessage = "Description is mandatory";
    }

    if ((that.privateCertData === '') || that.isEmpty(that.privateCertData) || !that.privateCertData) {
      that.keyWarningMessage = "SSH Private Key is mandatory";
    }
    if ((that.publicCertData === '') || that.isEmpty(that.publicCertData) || !that.publicCertData) {
      that.keyWarningMessage2 = "SSH Public Key is mandatory";
    }
    if (that.keyWarningMessage === '' && that.nameWarningMessage === '' && that.descriptionWarningMessage === '' && that.keyWarningMessage2 === '' && that.nameValidationWarningMessage === '') {
      that.loadSSHKeyPair();
      
      let PublicCertDate =that.publicCertData + "";
      let PrivateCertDate = that.privateCertData+ "";



      // Remove BEGIN and END 
      PublicCertDate = PublicCertDate.replace("-----BEGIN CERTIFICATE-----", "");
      PublicCertDate = PublicCertDate.replace("-----END CERTIFICATE-----", "");

      PrivateCertDate = PrivateCertDate.replace("-----BEGIN CERTIFICATE-----", "");
      PrivateCertDate = PrivateCertDate.replace("-----END CERTIFICATE-----", "");
      // Remove All Line Breaks

      that.sshKeyPair.publicKeyText = PublicCertDate;
      that.sshKeyPair.privateKeyText = PrivateCertDate;
      that.sshKeyCreated=true;
      that.iotService.getCuiObjResponse().createSSHKeyPair({
        data: that.sshKeyPair
      }).then(function (response) {
        console.log("Trusted public key created successFully, Id: ", response.id);
        that.securityService.showSSHKeyPairs = true;
        that.securityService.showTrustedPublicKeys = false;
        that.router.navigateByUrl("/sshKeys");
      }).fail(function (err) {
        that.sshKeyCreated=false;
        if (err.responseJSON.apiStatusCode) {
          that.errorMessage = err.responseJSON.apiMessage;
          console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
        } else {
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
        if (err === null) {
          console.log("Error in createEventTemplate");
        }
      });


    }

  }

  toggleExample(toggle) {
    if (toggle === 1) {
      this.exampleToggle = !this.exampleToggle;
    }
    else if (toggle === 2) {
      this.exampleToggle2 = !this.exampleToggle2;
    }

  }

  isAlpha(str) {
    if (str) {
      let letter = /^([a-zA-Z0-9 _-]+)$/;
      if (str.match(letter)) {
        return true;
      }
      else {
        return false;
      }
    }
    return true;
  }


}

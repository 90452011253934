import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IotService } from '../service/iot.service';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { faToggleOff, faToggleOn, faCheck, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faAngleUp, faAngleDown, faTimes, faExclamationTriangle, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { ImportExportService } from '../service/import-export.service';


@Component({
  selector: 'app-eventgroup-import-export',
  templateUrl: './eventgroup-import-export.component.html',
  styleUrls: ['./eventgroup-import-export.component.scss']
})
export class EventgroupImportExportComponent implements OnInit {
  supportLanguages = ['en'];
  eventGroupImportInfo;
  errorMsgFLag = false;
  successMsgFlag = false;
  importworking = false;
  executeImporterror;
  showImportSuccessTab = false;
  faCheckIcon = faCheck;
  faSuccessIcon = faCheckCircle;
  faCriticalIcon = faExclamation;
  faCloseIcon = faTimes;
  faWarningIcon = faExclamationTriangle;
  successMsg;
  importExportEventState = 1;

  constructor(public iotService: IotService, private translateService: TranslateService,
    private sideNavComponent: SideNavComponent, public importExportService: ImportExportService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
    this.sideNavComponent.menuChange('Dashboard', 'sub-type');
    this.sideNavComponent.menuChange('importExport.header', 'sub-type');
    this.sideNavComponent.menuChange('eventGroups.header', 'sub-type');

  }

  ngOnInit(): void {
    const that = this;
    that.initEventGroupImportInfo();
    this.importExportService.eventGroupImport = true;
    this.importExportService.eventGroupExport = false;
    that.importExportService.successMessage = "";
    that.importExportService.errorMessage = "";
  }
  ngOnDestroy(): void {
    const that = this;
    that.importExportService.successMessage = "";
    that.importExportService.errorMessage = "";
  }

  initEventGroupImportInfo() {
    const that = this;
    that.eventGroupImportInfo = {
      "impJobName": "",
      "packageFileData": "",
      "fileName": ""
    };
  }
  resetFormFields() {
    //reset form fields
    const that = this;
    that.eventGroupImportInfo.packageFileData = "",
      that.eventGroupImportInfo.fileName = "";
    //document.getElementById("uploadedfile").value = "";
  }
  reloadEventGroup() {
    const that = this;
    that.errorMsgFLag = false;
    that.successMsgFlag = false;
    that.resetFormFields();
    //$scope.refresh();
  }

  executeEventGroupImport(file) {
    const that = this;
    that.errorMsgFLag = false;
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      console.log("likhith" + fileReader.result);
      console.log("likhithvarma" + file.name);
      that.populateData(file.name, fileReader.result);
      that.makeCall(that.eventGroupImportInfo);
    }
    that.executeImporterror = "";
    if (file == undefined) {
      that.errorMsgFLag = true;
      that.executeImporterror = "Please provide package file.";
    }
    fileReader.readAsText(that.file);
    console.log("reading a file" + file);
  }

  file: any;
  fileChanged(e) {
    this.file = e.target.files[0];
  }
  populateData(filename, fileData) {
    const that = this;
    var encodedFileContent = fileData;
    that.eventGroupImportInfo.packageFileData = fileData;
    that.eventGroupImportInfo.fileName = filename;
  }
  makeCall(importData) {
    const that = this;
    that.importworking = true;
    if (importData.packageFileData == undefined) {
      that.executeEventGroupImport(that.file);
    } else {
      console.log("importData" + importData.packageFileData);
      that.iotService.getCuiObjResponse().executeEventGroupImport({
        data: importData
      })
        .then(function (response) {

          //console.log(response);
          var resp = response;
          that.successMsg = resp.jobMessage + " Event Group ID: " + resp.resourceId;
          that.successMsgFlag = true;
          that.resetFormFields();
          that.importworking = false;
          //console.log(response);
          //that.importInfo.packageFileData = response;
          //that.successMsg = "Solution imported successfully.";
          that.importExportService.successMessage = "Solution imported successfully.";
          that.showImportSuccessTab = true;
          that.resetFormFields();
          //document.getElementById("uploadForm").reset();
          // (document.getElementById('uploadForm') as HTMLFormElement).reset();

          //that.refresh();
        })
        .fail(function (response) {
          that.importworking = false;
          that.errorMsgFLag = true;
          that.showImportSuccessTab = false;
          that.importExportService.errorMessage = response.responseJSON.apiMessage;
          that.resetFormFields();
          //document.getElementById("uploadForm").reset();
          //(document.getElementById('uploadForm') as HTMLFormElement).reset();

          //that.refresh();
        });
    }

  }

  onButtonToggle(event) {
    const that = this;
    console.log("onButtonToggle: " + event)
    if (event == "Import") {
      that.importExportEventState = 1;
      console.log("onButtonToggle: " + that.importExportEventState)
    }
    else if (event == "Export") {
      that.importExportEventState = 2;
      console.log("onButtonToggle: " + that.importExportEventState)
    }
  }

  tabClick(tab) {
    console.log(tab.index);
    if (tab.index == 0) {
      console.log("Import");
      this.importExportService.eventGroupImport = true;
      this.importExportService.eventGroupExport = false;
    }
    else if (tab.index == 1) {
      console.log("export");
      this.importExportService.eventGroupImport = false;
      this.importExportService.eventGroupExport = true;
    }
    // this.uploadedFile=undefined;
    // this.initOrchestrationImport();
    // this.initOrchestrationExport();
    this.importExportService.resetErrorMessage();
    this.importExportService.resetSuccessMessage();
    this.importExportService.resetWarningMessage();

  }



}

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { TranslateService } from '@ngx-translate/core';
import { IotService } from '../service/iot.service';
import { StreamService } from '../service/stream.service';
import { faAngleUp, faAngleDown, faCheck, faCheckCircle, faExclamation, faExclamationTriangle, 
  faInfoCircle, faTimes, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import { DocumentInfoService } from '../service/documentInfo.service';
import { SshKeyPairsService } from '../service/sshKeyPairs.service';
import { KeyBundlesService } from '../service/keyBundles.service';
import { OAuthTokensService } from '../service/oAuthTokens.service';
import { throwMatDuplicatedDrawerError } from '@angular/material/sidenav';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FromTPCodesService } from '../service/fromTPCodes.service';
import { ToTPCodesService } from '../service/toTPCodes.service';
import { exists } from 'fs';
import { ApplicationsService } from '../service/applications.service';
import { DigitalTwinsService } from '../service/digitalTwins.service';
import { I } from '@angular/cdk/keycodes';
import { Router } from '@angular/router';
import cronstrue from 'cronstrue';




@Component({
  selector: 'app-create-stream',
  templateUrl: './create-stream.component.html',
  styleUrls: ['./create-stream.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  }
})
export class CreateStreamComponent implements OnInit {

  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;
  faCopyIcon = faCopy;
  faCheckIcon = faCheck;
  faInfoIcon = faInfoCircle;
  faCloseIcon = faTimes;
  faCriticalIcon = faExclamation;
  faWarningIcon = faExclamationTriangle;
  faSuccessIcon = faCheckCircle;
  hidePassWord=faEye;
  showPassword=faEyeSlash

  docInfoColumns: string[] = ['Doc Type', 'Template', 'Version'];
  currentPage = 1;
  documentInfo = [];
  direction = 'Select Direction';
  selectedValue: any;
  showLogLevel = false;
  mode = 'ERROR';
  compression = 'No';
  obfuscation = 'No'
  expiration = '0';
  throughput = 'Round Robin';
  strategy = '0';
  persistence = 'Yes';
  digest = 'No';
  protocol = 'Select Protocol Type';
  ftp = 'Select';
  caps = 'Select';
  auth = 'Basic';
  doc = 'Document information default';
  type = 'Application';
  requestEncryption = 'NONE';
  showCustomCAPS = false;
  showCustomFTP = false;
  customProtocol = false;
  showSecurity = false;
  encryption = 'NONE';
  quota = '3';
  showCertificate = false;
  showCertificateExample = false;
  docInfoList = true;
  drsScript = false;
  showProtocol = false;
  showCredentials = false;
  batchConfig = false;
  showDeliveryScript = false;
  showCode = false;
  showAccessToken = true;
  retryAttempts = 2;
  delayRetryAttempts = 2;
  retryTimeout = 3;
  example = 'Code Examples';
  showSchedule = false;
  nameError;
  drsDescription;
  publicKeyName;
  publicKeyDesc;
  sshName;
  deliverySchedule;
  cronValue;
  sshNameError;
  sshKeyError;
  mutualAuthError;
  tokenError;
  reverseChain = false;
  syncAuthType = "";
  accessTokenKey = "";
  accessTokenSecret = "";
  script;
  streamDocInfo;
  hasError: boolean = false;
  showAppFromSideNav = false;
  showDeviceFromSideNav = false;
  directionError;
  streamTypeError;
  protocolError;
  hide = true;
  streamDirection = [
    {
      id: 1,
      direction: 'Select Direction'
    },
    {
      id: 2,
      direction: 'Active Sending'
    },
    {
      id: 3,
      direction: 'Active Receiving'
    },
    {
      id: 4,
      direction: 'Passive Sending'
    },
    {
      id: 5,
      direction: 'Passive Receiving'
    },
    {
      id: 6,
      direction: 'Both'
    }
  ];
  logModes = [
    {
      id: 1,
      mode: 'DEBUG'
    },
    {
      id: 2,
      mode: 'ERROR'
    },
    {
      id: 3,
      mode: 'INFO'
    }
  ];
  logExpirations = [
    {
      id: 1,
      expiration: '0'
    },
    {
      id: 2,
      expiration: '1'
    },
    {
      id: 3,
      expiration: '5'
    },
    {
      id: 4,
      expiration: '15'
    }, {
      id: 5,
      expiration: '20'
    }
  ];
  parObfuscation = [
    {
      id: 1,
      obfuscation: 'Yes'
    },
    {
      id: 2,
      obfuscation: 'No'
    }
  ];
  parCompression = [
    {
      id: 1,
      compression: 'Yes'
    },
    {
      id: 2,
      compression: 'No'
    }
  ];
  throughputStratergy = [
    {
      id: 1,
      throughput: 'Round Robin'
    },
    {
      id: 2,
      throughput: 'By Stream'
    }
  ];
  ackStrategy = [
    {
      id: 1,
      strategy: '0'
    },
    {
      id: 2,
      strategy: '1'
    },
    {
      id: 3,
      strategy: '2'
    }
  ];
  parPersistence = [
    {
      id: 1,
      persistence: 'Yes'
    },
    {
      id: 2,
      persistence: 'No'
    }
  ];
  parDigest = [
    {
      id: 1,
      digest: 'Yes'
    },
    {
      id: 2,
      digest: 'No'
    }
  ];
  streamProtocols = [
    {
      id: 1,
      protocol: 'Select Protocol Type'
    },
    {
      id: 2,
      protocol: 'MQTT'
    },
    {
      id: 3,
      protocol: 'AMQP'
    },
    {
      id: 4,
      protocol: 'STOMP'
    },
    {
      id: 5,
      protocol: 'Web Sockets'
    },
    {
      id: 6,
      protocol: 'FTP'
    },
    {
      id: 7,
      protocol: 'CAPS'
    }
  ];
  customCAPSProtocols = [
    {
      id: 1,
      caps: 'Select'
    },
    {
      id: 1,
      caps: 'HTTPS'
    }
  ];
  customFTPProtocols = [
    {
      id: 1,
      ftp: 'Select'
    },
    {
      id: 2,
      ftp: 'VPN'
    },
    {
      id: 3,
      ftp: 'SSL'
    },
    {
      id: 4,
      ftp: 'SSH'
    }
  ];
  authTypes = [

    {
      id: 1,
      auth: 'Basic'
    },
    {
      id: 2,
      auth: 'X509'
    }
  ];
  docTypes = [
    {
      id: 1,
      doc: 'Document information default'
    },
    {
      id: 2,
      doc: 'Document auto discovery,provided by Sender'
    }
  ];

  streamtypes = [
    {
      id: 1,
      type: 'Select'
    },
    {
      id: 2,
      type: 'Application'
    },
    {
      id: 3,
      type: 'Messaging'
    },
    {
      id: 4,
      type: 'Sync'
    }
  ];
  devicestreamtypes = [
    {
      id: 1,
      type: 'Device'
    }
  ];
  sideNavstreamtypes = [
    {
      id: 1,
      type: 'Select'
    },
    {
      id: 2,
      type: 'Device'
    },
    {
      id: 3,
      type: 'Application'
    },
    {
      id: 4,
      type: 'Messaging'
    },
    {
      id: 5,
      type: 'Sync'
    }
  ];
  encryptiontypes = [
    {
      id: 1,
      encryption: 'NONE'
    },
    {
      id: 2,
      encryption: 'AES 256bit PSK'
    },
    {
      id: 3,
      encryption: 'AES 128bit PSK'
    }
  ]

  streamNameInput;
  certificateDataText;
  publicKeyDataText;
  streamNameSelect = false;
  selectedItem = 'None';
  supportLanguages = ['en'];
  selected = 'option0';
  toggleSettingFlag = false;
  value: string;
  enableDelivery = false;
  stream;
  copiedEditorValue;
  usernameEntered;
  userNameError;
  passwordEntered;
  passwordError;
  deliveryUrlEntered;
  deliveryUrlError;
  customProtocolError;
  usernameValue = "";
  passwordValue = "";
  deliveryUrlValue = "";
  deliveryScheduleError;
  codeError;
  newStreamCreated=false;

  teams: any[] = [
    { name: 'None' },
    { name: 'AES 256bit PSK' },
    { name: 'AES 128bit PSK' }
  ];
  constructor(private translateService: TranslateService,
    public iotService: IotService,
    public router: Router,
    public streamService: StreamService,
    private applicationService: ApplicationsService,
    private digitaltwinService: DigitalTwinsService,
    public documentInfoService: DocumentInfoService,
    public sshKeyService: SshKeyPairsService,
    public keyBundleService: KeyBundlesService,
    public oAuthTokenService: OAuthTokensService,
    public fromTpCodeService: FromTPCodesService,
    public toTpCodeService: ToTPCodesService,
    public sideNavComponent: SideNavComponent) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
    this.iotService = iotService;
  }

  onClick(event) {
    // this.resetErrorMessage();

  }

  resetErrorMessage() {
    this.streamService.resetErrorMessage();
  }

  reset() {
    this.nameError = '';
    this.userNameError = '';
    this.passwordError = '';
    this.deliveryUrlError = '';
    this.userNameError = '';
    this.passwordError = '';
    this.customProtocolError = '';
    this.deliveryScheduleError = '';
    this.sshNameError = '';
    this.sshKeyError = '';
    this.tokenError = '';
    this.mutualAuthError = '';
    this.directionError = '';
    this.streamTypeError ='';
    this.protocolError='';
  }

  ngOnInit(): void {
    const that = this;
    that.iotService.createStream = true;
    that.streamService.streamCreated=false;

    if (that.iotService.sideNavStream == true) {
      that.sideNavComponent.menuClear();
      that.sideNavComponent.menuChange('Dashboard', 'sub-type');
      that.sideNavComponent.menuChange('streams.header', 'sub-type');
      that.sideNavComponent.menuChange('streams.create.new', 'sub-type');
    }
    if (that.iotService.sideNavStream == true) {
      that.type = 'Select'
    } else if (that.iotService.sideNavStream == false) {
      that.type = 'Application'
    }

    if (that.iotService.deviceStream == true) {
      that.type = 'Device'
    }
    that.streamService.resetErrorMessage();
    that.reset();
    that.stream = {
      "creator": that.iotService.getCreator(),
      "creatorAppId": that.iotService.getCreatorAppId(),
      "realm": that.iotService.getRealm(),
      'name': [{
        'lang': 'en_us',
        'text': ''
      }],
      'description': [{
        'lang': 'en_us',
        'text': ''
      }],
      'protocolSecurityAttributes': [

      ],
      "encryptionAlgorithmAttributes": [

      ],
      'documentRecognition': {

      },
      'streamConfiguration': {

      },
      'direction': '',
      'isActive': true

    }
    that.streamService.example = 'Code Examples';
    that.streamService.showCodeExample = false;
    that.streamService.selectedDevice = false;
    that.streamService.setDeviceSelected(undefined);
    that.streamService.setMutualAuthObject(undefined);
    that.streamService.setOAuthTokenObject(undefined);
    that.streamService.setSshKeyPairObject(undefined);
    that.streamService.selectedApplication = false;
    that.streamService.setApplicationSelected(undefined);
  }


  streamName(event: any) {
    const that = this;
    that.streamNameInput = event.target.value;
    if (that.streamNameInput.length > 0) {
      that.nameError = '';
      that.streamNameSelect = true;
      that.streamService.setStreamName(that.streamNameInput);

    }
    else {
      that.streamNameSelect = false;
      that.streamService.setStreamName(undefined);
    }
  }

  username(event: any) {
    const that = this;
    that.usernameEntered = event.target.value;
    if (that.usernameEntered.length > 0) {
      that.userNameError = '';
    }

  }

  password(event: any) {
    const that = this;
    that.passwordEntered = event.target.value;
    if (that.passwordEntered.length > 0) {
      that.passwordError = '';
    }

  }
  deliveryUrl(event: any) {
    const that = this;
    that.deliveryUrlEntered = event.target.value;
    if (that.deliveryUrlEntered.length > 0) {
      that.deliveryUrlError = '';
    }

  }

  sshusername(event: any) {
    const that = this;
    if (event.target.value.length > 0) {
      that.sshNameError = '';
    }

  }


  reloadprotocols() {
    this.streamProtocols = [
      {
        id: 1,
        protocol: 'Select Protocol Type'
      },
      {
        id: 2,
        protocol: 'MQTT'
      },
      {
        id: 3,
        protocol: 'AMQP'
      },
      {
        id: 4,
        protocol: 'STOMP'
      },
      {
        id: 5,
        protocol: 'Web Sockets'
      },
      {
        id: 6,
        protocol: 'FTP'
      },
      {
        id: 7,
        protocol: 'CAPS'
      }
    ];
  }

  reloadmessagingprotocols() {
    this.streamProtocols = [
      {
        id: 1,
        protocol: 'Select Protocol Type'
      },
      {
        id: 2,
        protocol: 'AQ'
      },
      {
        id: 3,
        protocol: 'FTP'
      },
      {
        id: 4,
        protocol: 'CAPS'
      },
      {
        id: 5,
        protocol: 'S3'
      }
    ];
  }

  reloadmessagingcustomprotocols() {
    this.streamProtocols = [
      {
        id: 1,
        protocol: 'Select Protocol Type'
      },
      {
        id: 2,
        protocol: 'MQTT'
      },
      {
        id: 3,
        protocol: 'AMQP'
      },
      {
        id: 4,
        protocol: 'STOMP'
      },
      {
        id: 5,
        protocol: 'Web Sockets'
      },
      {
        id: 6,
        protocol: 'FTP'
      },
      {
        id: 7,
        protocol: 'CAPS'
      },
      {
        id: 8,
        protocol: 'BATCH'
      }
    ];
  }
  reloadCustomProtocols() {
    this.streamProtocols = [
      {
        id: 1,
        protocol: 'Select Protocol Type'
      },
      {
        id: 2,
        protocol: 'FTP'
      },
      {
        id: 3,
        protocol: 'CAPS'
      },
      {
        id: 4,
        protocol: 'S3'
      }
    ]
  }

  checkForToggleProtocol() {
    if (this.direction == 'Active Sending' || this.direction == 'Passive Sending' || this.direction == 'Both') {
      this.streamService.additionalSettingFlag = true
      this.toggleSettingFlag = true;
      this.showProtocol = true;
    } else {
      this.streamService.additionalSettingFlag = false
      this.toggleSettingFlag = false;
    }
  }
  changeDirection() {
    console.log(this.direction);
    this.clearSelection();
    this.streamService.setProtocolSecurityType('');
    this.showCredentials = false;
    this.showProtocol = false;
    this.showSecurity = false;
    this.showCustomCAPS = false;
    this.customProtocol = false;
    this.showCustomFTP = false;
    this.showCode = false;
    this.streamService.docInfoList = false;
    this.doc = 'Document information default'
    this.protocol = "Select Protocol Type";
    if (this.type == 'Application' || this.type == 'Device') {
      if (this.direction == 'Active Sending' || this.direction == 'Passive Sending' || this.direction == 'Both') {
        this.streamService.docInfoList = true;
        this.reloadprotocols();
        if (this.direction == 'Passive Sending') {
          this.reloadCustomProtocols();
          this.showCredentials = true;
        }
      } else {

        if (this.direction == 'Active Receiving') {
          this.showCredentials = true;
        }


        if (this.direction == 'Passive Receiving') {
          this.reloadprotocols();
        } else {
          this.reloadCustomProtocols();
        }
        this.streamService.additionalSettingFlag = false
        this.toggleSettingFlag = false;
        this.showLogLevel = false;
        this.showProtocol = true;
      }
    } else if (this.type == 'Messaging') {
      if (this.direction == 'Active Sending' || this.direction == 'Both') {
        this.showCode = true;
        this.reloadprotocols();

      } else if (this.direction == 'Active Receiving' || this.direction == 'Passive Sending') {
        this.showCredentials = true;
        this.reloadmessagingprotocols();
        this.streamService.additionalSettingFlag = false
        this.toggleSettingFlag = false;
        this.showLogLevel = false;
        this.showProtocol = true;
      }
      else if (this.direction == 'Passive Receiving') {
        this.reloadmessagingcustomprotocols();
        this.streamService.additionalSettingFlag = false
        this.toggleSettingFlag = false;
        this.showLogLevel = false;
        this.showProtocol = true;
      }
    }
    else if (this.type == 'Sync') {
      if (this.direction == 'Active Sending') {
        this.showProtocol = true;
        this.protocol = 'Select';
        this.streamProtocols = [
          {
            id: 1,
            protocol: 'Select'
          },
          {
            id: 2,
            protocol: 'HTTPS'
          }
        ];

        this.authTypes = [

          {
            id: 1,
            auth: 'Basic'
          },
          {
            id: 2,
            auth: 'OAuth Token'
          }
        ];

      } else if (this.direction == 'Active Receiving') {
        this.showProtocol = true;
        this.showCredentials = true;
        this.protocol = 'Select';
        this.streamProtocols = [
          {
            id: 1,
            protocol: 'Select'
          },
          {
            id: 1,
            protocol: 'CAPS'
          }
        ];
        this.authTypes = [

          {
            id: 1,
            auth: 'Basic'
          },
          {
            id: 2,
            auth: 'Mutual Auth'
          },
          {
            id: 3,
            auth: 'None'
          },
          {
            id: 4,
            auth: 'OAuth Token'
          }
        ];
      }
    }
    this.streamService.setDirection(this.direction);
    this.checkForToggleProtocol();

  }

  changeStreamType() {
    this.clearSelection();
    this.streamService.docInfoList = false;
    this.showAppFromSideNav = false;
    this.showDeviceFromSideNav = false;
    this.showProtocol = false;
    this.customProtocol = false;
    this.direction = 'Select Direction';
    this.toggleSettingFlag = false;
    this.streamService.setStreamType(this.type);
    this.showSecurity = false;
    if (this.type == 'Application') {
      if (this.iotService.sideNavStream == true) {
        this.showAppFromSideNav = true;
      }
      this.streamDirection = [
        {
          id: 1,
          direction: 'Select Direction'
        },
        {
          id: 2,
          direction: 'Active Sending'
        },
        {
          id: 3,
          direction: 'Active Receiving'
        },
        {
          id: 4,
          direction: 'Passive Sending'
        },
        {
          id: 5,
          direction: 'Passive Receiving'
        },
        {
          id: 6,
          direction: 'Both'
        }
      ];
    }
    else if (this.type == 'Device') {
      this.showDeviceFromSideNav = true
      this.streamDirection = [
        {
          id: 1,
          direction: 'Select Direction'
        },
        {
          id: 2,
          direction: 'Active Sending'
        },
        {
          id: 3,
          direction: 'Active Receiving'
        },
        {
          id: 4,
          direction: 'Passive Sending'
        },
        {
          id: 5,
          direction: 'Passive Receiving'
        },
        {
          id: 6,
          direction: 'Both'
        }
      ];
    }
    else if (this.type == 'Messaging') {
      if (this.iotService.sideNavStream == true) {
        this.showAppFromSideNav = true;
      }
      this.streamProtocols = [
        {
          id: 1,
          protocol: 'Select Protocol Type'
        },
        {
          id: 2,
          protocol: 'AQ'
        },
        {
          id: 3,
          protocol: 'FTP'
        },
        {
          id: 4,
          protocol: 'CAPS'
        },
        {
          id: 5,
          protocol: 'S3'
        }
      ];
      this.streamDirection = [
        {
          id: 1,
          direction: 'Select Direction'
        },
        {
          id: 2,
          direction: 'Active Sending'
        },
        {
          id: 3,
          direction: 'Active Receiving'
        },
        {
          id: 4,
          direction: 'Passive Sending'
        },
        {
          id: 5,
          direction: 'Passive Receiving'
        },
        {
          id: 6,
          direction: 'Both'
        }
      ];
    }
    else if (this.type == 'Sync') {
      if (this.iotService.sideNavStream == true) {
        this.showAppFromSideNav = true;
      }
      this.streamDirection = [
        {
          id: 1,
          direction: 'Select Direction'
        },
        {
          id: 2,
          direction: 'Active Sending'
        },
        {
          id: 3,
          direction: 'Active Receiving'
        }
      ];
      this.streamProtocols = [
        {
          id: 1,
          protocol: 'Select Protocol Type'
        },
        {
          id: 2,
          protocol: 'HTTPS'
        }
      ];

    }
  }

  toggleExample() {
    if (this.showCertificateExample == false) {
      this.showCertificateExample = true;
    } else {
      this.showCertificateExample = false;
    }
  }

  changeCodeExample() {
    this.streamService.showCodeExample = false;
    if (this.streamService.example != 'Code Example') {
      this.streamService.showCodeExample = true;
    }
    this.streamService.setExample(this.streamService.example);
  }
  certificateData(event: any) {
    const that = this;
    that.certificateDataText = (event.target.value);

  }
  publicKeyData(event: any) {
    const that = this;
    that.publicKeyDataText = (event.target.value);

  }
  changePayloadSecurityType() {
    if (this.encryption == 'NONE') {
      this.requestEncryption = this.encryption;
      this.streamService.setPayloadSecurityType(this.encryption);
    } else {
      this.requestEncryption = 'ENCRYPTED';
      this.streamService.setPayloadSecurityType('ENCRYPTED');

    }
  }

  logMode() {
    if (this.showLogLevel == false) {
      this.showLogLevel = true;
    } else {
      this.showLogLevel = false;
    }
  }

  reloadcustomftpProtocol() {
    this.customFTPProtocols = [
      {
        id: 1,
        ftp: 'Select'
      },
      {
        id: 2,
        ftp: 'VPN'
      },
      {
        id: 3,
        ftp: 'SSL'
      },
      {
        id: 4,
        ftp: 'SSH'
      },
      {
        id: 2,
        ftp: 'VPN Schedule'
      },
      {
        id: 3,
        ftp: 'SSL Schedule'
      },
      {
        id: 4,
        ftp: 'SSH Schedule'
      }
    ];
  }

  reloadftpProtocol() {
    this.customFTPProtocols = [
      {
        id: 1,
        ftp: 'Select'
      },
      {
        id: 2,
        ftp: 'VPN'
      },
      {
        id: 3,
        ftp: 'SSL'
      },
      {
        id: 4,
        ftp: 'SSH'
      }
    ];
  }

  reloadcapsProtocol() {
    this.customCAPSProtocols = [
      {
        id: 1,
        caps: 'Select'
      },
      {
        id: 2,
        caps: 'HTTPS'
      }
    ]
  }

  reloadcustomcapsProtocol() {
    this.customCAPSProtocols = [
      {
        id: 1,
        caps: 'Select'
      },
      {
        id: 2,
        caps: 'HTTPS Blast'
      },
      {
        id: 3,
        caps: 'Scheduled HTTPS'
      }
    ]
  }

  reloadcustomauthType() {
    this.authTypes = [

      {
        id: 1,
        auth: 'Basic'
      },
      {
        id: 2,
        auth: 'Mutual Auth'
      },
      {
        id: 3,
        auth: 'OAuth Token'
      }
    ];
  }

  reloadauthType() {
    this.authTypes = [

      {
        id: 1,
        auth: 'Basic'
      },
      {
        id: 2,
        auth: 'X509'
      }
    ];
  }
  changeProtocol() {
    if (this.protocol == 'FTP' || this.protocol == 'CAPS') {
      this.clearSelection();
    }
    this.showCertificate = false;
    this.streamService.setProtocolSecurityType('Basic');
    this.showCertificateExample = false;
    this.showDeliveryScript = false;
    this.showSecurity = false;
    this.batchConfig = false;
    this.showAccessToken = false;
    this.showSchedule = false;
    this.auth = 'Basic';
    this.doc = 'Document information default'
    this.streamService.docInfoList = false;
    console.log(this.protocol);

    if (this.protocol == 'FTP') {
      this.showCustomCAPS = false;
      this.customProtocol = true;
      this.showCustomFTP = true;
      this.ftp = 'Select';

      if (this.direction == 'Active Receiving' || this.direction == 'Passive Sending') {
        this.reloadcustomftpProtocol();

        if (this.direction == 'Passive Sending') {
          this.streamService.docInfoList = true;
          this.reloadftpProtocol();
        } else {
          this.streamService.docInfoList = false;
        }
        this.showDeliveryScript = true;

      } else {
        this.reloadftpProtocol();
        this.streamService.docInfoList = true;
      }
    } else if (this.protocol == 'CAPS') {
      this.showCustomFTP = false;
      this.customProtocol = true;
      this.showCustomCAPS = true;
      this.caps = 'Select';

      if (this.direction == 'Active Receiving') {
        if (this.type == 'Sync') {
          this.caps = 'Select';
          this.customCAPSProtocols = [
            {
              id: 1,
              caps: 'Select'
            },
            {
              id: 1,
              caps: 'HTTPS Sync'
            }
          ]
        }
        else {
          this.reloadcustomcapsProtocol();
          this.reloadcustomauthType();

          this.streamService.docInfoList = false;
          this.showDeliveryScript = true;
        }
      } else if (this.direction == 'Passive Sending') {
        this.reloadcapsProtocol();
        this.reloadcustomauthType();
      } else {
        this.streamService.docInfoList = true;
        this.reloadcapsProtocol();
        this.reloadauthType();
      }
    } else if (this.protocol == 'S3') {

      if (this.direction == 'Passive Sending') {
        this.showCustomFTP = false;
        this.customProtocol = false;
        this.showCustomCAPS = false;
        this.showSecurity = true;
        this.showCredentials = false;
        this.showAccessToken = true;
        this.streamService.docInfoList = true;
        this.showDeliveryScript = false;
        this.showSchedule = true;
      }
      else {
        this.showCustomFTP = false;
        this.customProtocol = false;
        this.showCustomCAPS = false;
        this.showSecurity = true;
        this.showCredentials = false;
        this.showAccessToken = true;
        this.streamService.docInfoList = false;
        this.showDeliveryScript = false;
      }
      this.auth = 'S3 Credentials';
      this.authTypes = [
        {
          id: 1,
          auth: 'S3 Credentials'
        }
      ];
    }
    else if (this.protocol == 'AQ') {
      this.showCustomFTP = false;
      this.customProtocol = false;
      this.showCustomCAPS = false;
      this.showSecurity = true;
      this.showDeliveryScript = true;
      this.auth = 'None';
      if (this.direction == 'Active Receiving') {
        this.authTypes = [
          {
            id: 1,
            auth: 'None'
          },
          {
            id: 1,
            auth: 'Mutual Auth'
          }
        ];
      }
      else if (this.direction == 'Passive Sending') {
        this.showSchedule = true;
        this.docInfoList = true;
        this.streamService.docInfoList = true;
        this.authTypes = [
          {
            id: 1,
            auth: 'None'
          }
        ];

      }
    } else if (this.protocol == 'BATCH') {
      this.showCustomFTP = false;
      this.customProtocol = false;
      this.showCustomCAPS = false;
      this.showSecurity = false;
      this.showCredentials = false;
      this.batchConfig = true;
      this.stream = {
        "creator": this.iotService.getCreator(),
        "creatorAppId": this.iotService.getCreatorAppId(),
        "realm": this.iotService.getRealm(),
        'name': [{
          'lang': 'en_us',
          'text': ''
        }],
        'description': [{
          'lang': 'en_us',
          'text': ''
        }],
        'direction': '',
        'isActive': true,
        'batchConfig': {
          'schedule': "0 0/5 * * * ?",
          'handler': "com.covisint.platform.messaging.batch.processor.handler.ConcatHandler",
          'handlerAttributes': {
            "delimiter": ""
          },
          'maxBytes': 1024,
          'maxMessages': 2
        }

      }
      if (this.stream.batchConfig.maxBytes == "") {
        this.stream.batchConfig.maxBytes = 1024;
      }
      this.stream.batchConfig.maxBytes = this.stream.batchConfig.maxBytes * 1;

      if (this.stream.batchConfig.maxMessages == "") {
        this.stream.batchConfig.maxMessages = 2;
      }
      this.stream.batchConfig.maxMessages = this.stream.batchConfig.maxMessages * 1;
      this.streamService.docInfoList = true;

    }
    else if (this.type == 'Sync' && this.protocol == 'HTTPS') {

      this.showCustomFTP = false;
      this.customProtocol = false;
      this.showCustomCAPS = false;
      this.showSecurity = true;
      this.drsScript = false;
      this.doc = 'Document information default';
      this.streamService.docInfoList = true;
      this.authTypes = [

        {
          id: 1,
          auth: 'Basic'
        },
        {
          id: 2,
          auth: 'OAuth Token'
        }
      ];
    }
    else {
      this.showCustomFTP = false;
      this.customProtocol = false;
      this.showCustomCAPS = false;
      this.showSecurity = true;
      this.drsScript = false;
      this.doc = 'Document information default';
      this.streamService.docInfoList = true;
      this.authTypes = [

        {
          id: 1,
          auth: 'Basic'
        },
        {
          id: 2,
          auth: 'X509'
        }
      ];
    }
    this.streamService.streamDocInfo = true
    this.streamService.setProtocolType(this.protocol);
  }

  showDRS(event) {
    if (event == true) {
      this.drsScript = true;
      this.streamService.drsScriptFlag = true;
    } else {
      this.drsScript = false;
      this.streamService.drsScriptFlag = false;

    }
  }

  reverseChaining(event) {
    if (event == true) {
      this.reverseChain = true;
    } else {
      this.reverseChain = true;
    }
  }
  changeFTPProtocol() {
    this.clearSelection();
    this.customProtocolError = false;
    this.showSchedule = false;
    this.drsScript = false;
    this.doc = 'Document information default';
    this.streamService.setProtocolSecurityType('Basic');
    this.streamService.docInfoList = true;
    this.streamService.drsScriptFlag = false;
    if (this.direction == 'Passive Sending') {
      this.showSchedule = true;
    }
    if (this.ftp == 'VPN' || this.ftp == 'SSL' || this.ftp == 'SSH' || this.ftp == 'VPN Schedule'
      || this.ftp == 'SSL Schedule' || this.ftp == 'SSH Schedule') {
      if (this.ftp == 'VPN Schedule' || this.ftp == 'SSL Schedule' || this.ftp == 'SSH Schedule') {
        this.showSchedule = true;
      }
      this.showSecurity = true;
      this.auth = 'Basic'
      this.authTypes = [

        {
          id: 1,
          auth: 'Basic'
        }
      ];
      if (this.ftp == 'SSH') {
        this.authTypes = [

          {
            id: 1,
            auth: 'Basic'
          },
          {
            id: 2,
            auth: 'Public Key'
          }
        ];
      }
      this.streamService.setCustomFTPprotocolType(this.ftp);
    } else {
      this.showSecurity = false;
    }
  }

  changeCAPSProtocol() {
    this.clearSelection();
    this.customProtocolError = false;
    this.showSchedule = false;
    this.streamService.setProtocolSecurityType('Basic');
    if (this.caps == 'HTTPS' || this.caps == 'HTTPS Blast' || this.caps == 'Scheduled HTTPS') {
      this.auth = 'Basic'
      this.showSecurity = true;
      if (this.direction == 'Active Receiving' || this.direction == 'Passive Sending') {
        if (this.caps == 'Scheduled HTTPS') {
          this.showSchedule = true;
        }
        if (this.caps == 'HTTPS') {
          this.showSchedule = true;
          this.streamService.docInfoList = true;
          this.drsScript = false;
          this.showDeliveryScript = true;
        }
        else {
          this.streamService.docInfoList = false;
          this.drsScript = false;
          this.showDeliveryScript = true;
        }
      }
      this.streamService.setCustomCAPSprotocolType(this.caps);
    } else if (this.caps == 'HTTPS Sync') {
      // this.streamService.setProtocolSecurityType('HTTPS');
      this.stream.customProtocolType = 'HTTPS'
      this.showSecurity = true;
      this.showDeliveryScript = true;
    }
    else {
      this.showSecurity = false;
    }
  }
  changeAuthType() {
    // if(this.direction!='Active Sending'&&this.direction=='Passive Receiving'){
    this.clearSelection();

    this.streamService.setProtocolSecurityType(this.auth);
    console.log("in auth" + this.streamService.getDirection() + this.streamService.getProtocolSecurityType())
    if (this.auth == 'Basic') {
      this.showCertificate = false;
      this.showCertificateExample = false;
      if (this.type == 'Sync' && this.direction == 'Active Receiving') {
        this.showCredentials = true;
      }
    } else {
      if (this.type == 'Sync' && this.direction == 'Active Receiving' && this.auth == 'None') {
        this.showCertificate = false;
      }
      else if (this.auth == 'None') {
        this.showCertificate = false;
      }
      else {
        this.showCertificate = true;
      }
    }
  }
  clearSelection() {
    const that = this;
    that.streamService.setDocInfoId(undefined);
    that.streamService.setStreamDocInfoDocType(undefined);
    that.streamService.setStreamDocInfoTemplate(undefined);
    that.streamService.setStreamDocInfoVersion(undefined);
    that.streamService.setDocinfoResponse(undefined);
    that.streamService.setSSHKeyPair(undefined);
    that.streamService.setOAuthToken(undefined);
    that.streamService.setMutualAuth(undefined);
    that.streamService.setFromCodeValue(undefined);
    that.streamService.setfromCodeValue2(undefined);
    that.streamService.setToCodeValue(undefined);
    that.streamService.setToCodeValue2(undefined);
    that.streamService.selectedDocInfos = false;
    that.streamService.fromCode = false;
    that.streamService.toCode = false;

    that.documentInfoService.selection.clear();
    that.sshKeyService.selection.clear();
    that.keyBundleService.selection.clear();
    that.oAuthTokenService.selection.clear();
    //    that.applicationService.selection.clear();
    //    that.digitaltwinService.selection.clear();
    that.fromTpCodeService.selection.clear();
    that.toTpCodeService.selection.clear();

  }

  changeDocType() {
    if (this.doc == "Document information default") {
      this.streamService.docInfoList = true;
      this.drsScript = false;
      this.streamService.drsScriptFlag = false;
    } else {
      this.streamService.docInfoList = false;
      this.drsScript = false;
      this.streamService.drsScriptFlag = true;

    }
  }

  getDocInfos(currentPage) {
    const that = this;
    this.currentPage = currentPage;
    this.iotService.getCuiObjResponse().getDocumentInfos({
      qs: [['pageSize', 5], ['page', currentPage]]
    }).then(function (response) {
      that.documentInfo = response;
      console.log("documentInfo :: " + that.documentInfo);
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  ngOnDestroy() {
    const that = this;
    /*    const that = this;
       that.streamService.streamDocInfo = false;
       that.streamService.selectedDocInfos = false;
       that.direction = 'Select Direction';
       that.protocol = 'Select Protocol Type';
       that.streamService.setDirection('Select Direction');
       that.streamService.setProtocolType('Select Protocol Type');
       that.streamService.setProtocolSecurityType(undefined);
       that.streamService.setStreamDocInfoTemplate(undefined);
       that.streamService.setStreamDocInfoDocType(undefined);
       that.streamService.setStreamDocInfoVersion(undefined);

       that.streamService.setStreamEncryptionKey(undefined);
       that.streamService.streamDocInfo = false;
       that.streamService.drsScriptFlag = false; */
    that.streamService.setDocInfoId(undefined);
    that.streamService.setSSHKeyPair(undefined);
    that.streamService.setOAuthToken(undefined);
    that.streamService.setMutualAuth(undefined);
    that.documentInfoService.selection.clear();
    that.sshKeyService.selection.clear();
    that.keyBundleService.selection.clear();
    that.oAuthTokenService.selection.clear();
    that.applicationService.selection.clear();
    that.digitaltwinService.selection.clear();
    that.streamService.streamDocInfo = false;
    that.streamService.selectedDocInfos = false;
    that.streamService.setDirection(undefined);
    that.streamService.setProtocolType(undefined);
    that.streamService.setProtocolSecurityType(undefined);
    that.streamService.setStreamDocInfoTemplate(undefined);
    that.streamService.setStreamDocInfoDocType(undefined);
    that.streamService.setStreamDocInfoVersion(undefined);
    that.streamService.setDocInfoId(undefined);
    that.streamService.setSSHKeyPair(undefined);
    that.streamService.setOAuthToken(undefined);
    that.streamService.setMutualAuth(undefined);
    that.streamService.streamNamesArray = [];
    this.iotService.createStream = false;

  }

  deliveryEnabled(event) {
    if (event == true) {
      this.enableDelivery = true;
    } else {
      this.enableDelivery = false;
    }
  }

  // mapKey:EXTENDED_KEY

  addStreamDocInfo(streamId) {
    const that = this;
    let newStreamDoc;
    newStreamDoc = {
      "creator": that.iotService.getCreator(),
      "creatorAppId": that.iotService.getCreatorAppId(),
      "realm": that.iotService.getRealm(),
      "documentInfoId": that.streamService.getDocInfoId(),
      "streamId": streamId
    }
    if (that.type == 'Sync' && that.protocol == 'HTTPS') {
      newStreamDoc = {
        "creator": that.iotService.getCreator(),
        "creatorAppId": that.iotService.getCreatorAppId(),
        "realm": that.iotService.getRealm(),
        "documentInfoId": that.streamService.getDocInfoId(),
        "streamId": streamId,
        "senderCodeId": '',
        "receiverCodeId": '',
        "mapKey": "EXTENDED_KEY",
        "mapAttributeName": [
          {
            "key": "syncRequestActionType",
            "value": that.syncAuthType
          }
        ],
        "responseMapping": {
          "senderCodeId":'' ,
          "receiverCodeId": '',
          "documentInfoId":''
        }
      }
      if(that.streamService.getFromCodeValue()){
        newStreamDoc.senderCodeId=that.streamService.getFromCodeValue().id;
      }
      if(that.streamService.getToCodeValue()){
        newStreamDoc.receiverCodeId=that.streamService.getToCodeValue().id;
      }
      if(that.streamService.getfromCodeValue2()){
        newStreamDoc.responseMapping.senderCodeId=that.streamService.getfromCodeValue2().id;
      }
      if(that.streamService.getToCodeValue2()){
        newStreamDoc.responseMapping.receiverCodeId=that.streamService.getToCodeValue2().id;
      }
      if(that.streamService.getDocInfo2()){
        newStreamDoc.responseMapping.documentInfoId=that.streamService.getDocInfo2().id;
      }
    }

    if (that.type == 'Messaging') {
      newStreamDoc = {
        "creator": that.iotService.getCreator(),
        "creatorAppId": that.iotService.getCreatorAppId(),
        "realm": that.iotService.getRealm(),
        "documentInfoId": that.streamService.getDocInfoId(),
        "streamId": streamId,
        "senderCodeId": that.streamService.getFromCodeValue().id,
        "receiverCodeId": that.streamService.getToCodeValue().id
      }
    }
    that.iotService.getCuiObjResponse().createStreamDocInfo({
      'streamId': streamId,
      'data': newStreamDoc
    }).then(function (results) {
      that.streamDocInfo = results;


    }).fail(function (err) {

      if (err.responseJSON.apiStatusCode) {
        that.streamService.errorMessage = err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        that.streamService.errorMessage = "Error occured while creating docinfo"
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
      that.resetData();
    });
  }

  prepareRabbitProtocolsModel() {
    const that = this;
    if (that.streamService.getProtocolType() == 'Web Sockets') {
      that.streamService.setProtocolType('WSS');
    }
    if (that.streamService.drsScriptFlag) {
      that.stream.documentRecognition = {
        "description": that.drsDescription,
        "type": "JAVASCRIPT",
        "classScript": that.streamService.getDRSscriptBase64Encoded()
      }
      that.stream = {
        "creator": that.iotService.getCreator(),
        "creatorAppId": that.iotService.getCreatorAppId(),
        "realm": that.iotService.getRealm(),
        'name': [{
          "lang": "en_us",
          "text": that.streamService.getStreamName(),
        }],
        "direction": that.streamService.getDirection(),
        "ownerId": that.streamService.getOwnerId(),
        "streamType": that.streamService.getStreamType().toUpperCase(),
        "protocolType": that.streamService.getProtocolType(),
        "protocolSecurityType": that.auth.toUpperCase(),
        "protocolSecurityAttributes": that.stream.protocolSecurityAttributes,
        "documentRecognition": that.stream.documentRecognition,
        "streamConfiguration": that.stream.streamConfiguration,
        "payloadSecurityType": that.requestEncryption,
        "streamState": "ACTIVE",
        "ackTimeout": 0,
        "ackStrategy": parseInt(that.strategy),
        "payloadAtRestObfuscation": that.obfuscation.toUpperCase(),
        "payloadAtRestCompression": that.compression.toUpperCase(),
        "payloadAtRestPersistence": that.persistence.toUpperCase(),
        "payloadAtRestDigest": that.digest.toUpperCase(),
        "throughputStrategy": that.throughput,
        "encryptionAlgorithmAttributes": [

        ]
      }
    } else {
      that.stream = {
        "creator": that.iotService.getCreator(),
        "creatorAppId": that.iotService.getCreatorAppId(),
        "realm": that.iotService.getRealm(),
        'name': [{
          "lang": "en_us",
          "text": that.streamService.getStreamName(),
        }],
        "direction": that.streamService.getDirection(),
        "ownerId": that.streamService.getOwnerId(),
        "streamType": that.streamService.getStreamType().toUpperCase(),
        "protocolType": that.streamService.getProtocolType(),
        "protocolSecurityType": that.auth.toUpperCase(),
        "protocolSecurityAttributes": that.stream.protocolSecurityAttributes,
        "streamConfiguration": that.stream.streamConfiguration,
        "payloadSecurityType": that.requestEncryption,
        "streamState": "ACTIVE",
        "ackTimeout": 0,
        "ackStrategy": parseInt(that.strategy),
        "payloadAtRestObfuscation": that.obfuscation.toUpperCase(),
        "payloadAtRestCompression": that.compression.toUpperCase(),
        "payloadAtRestPersistence": that.persistence.toUpperCase(),
        "payloadAtRestDigest": that.digest.toUpperCase(),
        "throughputStrategy": that.throughput,
        "encryptionAlgorithmAttributes": [

        ]
      }
    }
  }

  populateDeliverySchedule() {
    const that = this;

    if (that.ftp == 'VPN-AQS' || that.ftp == 'SSL-AQS' || that.ftp == 'SSH-AQS' || that.caps == 'HTTPS-AQS' || that.direction == 'Passive Sending') {
      this.deliverySchedule = that.cronValue;
    } else {
      this.deliverySchedule = "";
    }
  }
  prepareFTPcustomProtocolsModel() {
    const that = this;

    that.populateDeliverySchedule();

    if (that.direction == 'Active Receiving' || that.direction == 'Passive Sending') {
      that.stream = {
        "creator": that.iotService.getCreator(),
        "creatorAppId": that.iotService.getCreatorAppId(),
        "realm": that.iotService.getRealm(),
        'name': [{
          "lang": "en_us",
          "text": that.streamService.getStreamName(),
        }],
        "direction": that.streamService.getDirection(),
        "ownerId": that.streamService.getOwnerId(),
        "streamType": that.streamService.getStreamType().toUpperCase(),
        "protocolType": that.streamService.getProtocolType(),
        "protocolSecurityType": that.auth.toUpperCase(),
        "protocolSecurityAttributes": that.stream.protocolSecurityAttributes,
        "customProtocolType": that.ftp,
        "streamConfiguration": that.stream.streamConfiguration,
        "payloadSecurityType": that.requestEncryption,
        "streamState": "ACTIVE",
        "ackTimeout": 0,
        "ackStrategy": parseInt(that.strategy),
        "payloadAtRestObfuscation": that.obfuscation.toUpperCase(),
        "payloadAtRestCompression": that.compression.toUpperCase(),
        "payloadAtRestPersistence": that.persistence.toUpperCase(),
        "payloadAtRestDigest": that.digest.toUpperCase(),
        "throughputStrategy": that.throughput,
        "encryptionAlgorithmAttributes": [

        ],
        "deliveryConfig": {
          "commandRollbackAttempts": 0,
          "ackWaitTimeoutLimit": 5,
          "retryInterval": that.retryAttempts,
          "retryNumber": that.delayRetryAttempts,
          "retryTimeOut": that.retryTimeout,
          "deliverySchedule": that.deliverySchedule,
          "deliveryScript": that.streamService.getDeliveryScriptBase64Encoded(),
          "deliveryEnabled": this.enableDelivery,
          "destinationURI": that.deliveryUrlValue
        }
      }
      if (that.type == 'Messaging') {
        if (that.streamService.getFromCodeValue() != undefined) {
          that.stream.senderCodeId = that.streamService.getFromCodeValue().id;
        }
        if (that.streamService.getToCodeValue() != undefined) {
          that.stream.senderCodeId = that.streamService.getToCodeValue().id;
        }
      }
      if (that.streamService.drsScriptFlag) {
      that.stream.documentRecognition = {
        "description": that.drsDescription,
        "type": "JAVASCRIPT",
        "classScript": that.streamService.getDRSscriptBase64Encoded()
      }
    }


    } else {
      if (that.streamService.drsScriptFlag) {
        that.stream.documentRecognition = {
          "description": that.drsDescription,
          "type": "JAVASCRIPT",
          "classScript": that.streamService.getDRSscriptBase64Encoded()
        }
        that.stream = {
          "creator": that.iotService.getCreator(),
          "creatorAppId": that.iotService.getCreatorAppId(),
          "realm": that.iotService.getRealm(),
          'name': [{
            "lang": "en_us",
            "text": that.streamService.getStreamName(),
          }],
          "direction": that.streamService.getDirection(),
          "ownerId": that.streamService.getOwnerId(),
          "streamType": that.streamService.getStreamType().toUpperCase(),
          "protocolType": that.streamService.getProtocolType(),
          "protocolSecurityType": that.auth.toUpperCase(),
          "protocolSecurityAttributes": that.stream.protocolSecurityAttributes,
          "documentRecognition": that.stream.documentRecognition,
          "customProtocolType": that.ftp,
          "streamConfiguration": that.stream.streamConfiguration,
          "payloadSecurityType": that.requestEncryption,
          "streamState": "ACTIVE",
          "ackTimeout": 0,
          "ackStrategy": parseInt(that.strategy),
          "payloadAtRestObfuscation": that.obfuscation.toUpperCase(),
          "payloadAtRestCompression": that.compression.toUpperCase(),
          "payloadAtRestPersistence": that.persistence.toUpperCase(),
          "payloadAtRestDigest": that.digest.toUpperCase(),
          "throughputStrategy": that.throughput,
          "encryptionAlgorithmAttributes": [

          ]
        }
      } else {
        that.stream = {
          "creator": that.iotService.getCreator(),
          "creatorAppId": that.iotService.getCreatorAppId(),
          "realm": that.iotService.getRealm(),
          'name': [{
            "lang": "en_us",
            "text": that.streamService.getStreamName(),
          }],
          "direction": that.streamService.getDirection(),
          "ownerId": that.streamService.getOwnerId(),
          "streamType": that.streamService.getStreamType().toUpperCase(),
          "protocolType": that.streamService.getProtocolType(),
          "protocolSecurityType": that.auth.toUpperCase(),
          "protocolSecurityAttributes": that.stream.protocolSecurityAttributes,
          "customProtocolType": that.ftp,
          "streamConfiguration": that.stream.streamConfiguration,
          "payloadSecurityType": that.requestEncryption,
          "streamState": "ACTIVE",
          "ackTimeout": 0,
          "ackStrategy": parseInt(that.strategy),
          "payloadAtRestObfuscation": that.obfuscation.toUpperCase(),
          "payloadAtRestCompression": that.compression.toUpperCase(),
          "payloadAtRestPersistence": that.persistence.toUpperCase(),
          "payloadAtRestDigest": that.digest.toUpperCase(),
          "throughputStrategy": that.throughput,
          "encryptionAlgorithmAttributes": [

          ]
        }
      }
    }
    console.log(that.stream)
  }

  prepareCAPScustomProtocolsModel() {
    const that = this;

    that.populateDeliverySchedule();

    if (that.direction == 'Active Receiving' || that.direction == 'Passive Sending') {
      that.stream = {
        "creator": that.iotService.getCreator(),
        "creatorAppId": that.iotService.getCreatorAppId(),
        "realm": that.iotService.getRealm(),
        'name': [{
          "lang": "en_us",
          "text": that.streamService.getStreamName(),
        }],
        "direction": that.streamService.getDirection(),
        "ownerId": that.streamService.getOwnerId(),
        "streamType": that.streamService.getStreamType().toUpperCase(),
        "protocolType": that.streamService.getProtocolType(),
        "protocolSecurityType": that.auth.toUpperCase(),
        "protocolSecurityAttributes": that.stream.protocolSecurityAttributes,
        "customProtocolType": that.caps,
        "streamConfiguration": that.stream.streamConfiguration,
        "payloadSecurityType": that.requestEncryption,
        "streamState": "ACTIVE",
        "ackTimeout": 0,
        "ackStrategy": parseInt(that.strategy),
        "payloadAtRestObfuscation": that.obfuscation.toUpperCase(),
        "payloadAtRestCompression": that.compression.toUpperCase(),
        "payloadAtRestPersistence": that.persistence.toUpperCase(),
        "payloadAtRestDigest": that.digest.toUpperCase(),
        "throughputStrategy": that.throughput,
        "encryptionAlgorithmAttributes": [

        ],
        "deliveryConfig": {
          "commandRollbackAttempts": 0,
          "ackWaitTimeoutLimit": 5,
          "retryInterval": that.retryAttempts,
          "retryNumber": that.delayRetryAttempts,
          "retryTimeOut": that.retryTimeout,
          "deliverySchedule": that.deliverySchedule,
          "deliveryScript": that.streamService.getDeliveryScriptBase64Encoded(),
          "deliveryEnabled": this.enableDelivery,
          "destinationURI": that.deliveryUrlValue
        }
      }

      if (that.type == 'Messaging') {
        if(that.streamService.getFromCodeValue()){
          that.stream.senderCodeId = that.streamService.getFromCodeValue().id;
        }
        if(that.streamService.getToCodeValue()){
        that.stream.receiverCodeId = that.streamService.getToCodeValue().id;
        }
      }
    }
    else {
      if (that.streamService.drsScriptFlag) {
        that.stream.documentRecognition = {
          "description": that.drsDescription,
          "type": "JAVASCRIPT",
          "classScript": that.streamService.getDRSscriptBase64Encoded()
        }
        that.stream = {
          "creator": that.iotService.getCreator(),
          "creatorAppId": that.iotService.getCreatorAppId(),
          "realm": that.iotService.getRealm(),
          'name': [{
            "lang": "en_us",
            "text": that.streamService.getStreamName(),
          }],
          "direction": that.streamService.getDirection(),
          "ownerId": that.streamService.getOwnerId(),
          "streamType": that.streamService.getStreamType().toUpperCase(),
          "protocolType": that.streamService.getProtocolType(),
          "protocolSecurityType": that.auth.toUpperCase(),
          "protocolSecurityAttributes": that.stream.protocolSecurityAttributes,
          "documentRecognition": that.stream.documentRecognition,
          "customProtocolType": that.caps,
          "streamConfiguration": that.stream.streamConfiguration,
          "payloadSecurityType": that.requestEncryption,
          "streamState": "ACTIVE",
          "ackTimeout": 0,
          "ackStrategy": parseInt(that.strategy),
          "payloadAtRestObfuscation": that.obfuscation.toUpperCase(),
          "payloadAtRestCompression": that.compression.toUpperCase(),
          "payloadAtRestPersistence": that.persistence.toUpperCase(),
          "payloadAtRestDigest": that.digest.toUpperCase(),
          "throughputStrategy": that.throughput,
        }
      } else {
        that.stream = {
          "creator": that.iotService.getCreator(),
          "creatorAppId": that.iotService.getCreatorAppId(),
          "realm": that.iotService.getRealm(),
          'name': [{
            "lang": "en_us",
            "text": that.streamService.getStreamName(),
          }],
          "direction": that.streamService.getDirection(),
          "ownerId": that.streamService.getOwnerId(),
          "streamType": that.streamService.getStreamType().toUpperCase(),
          "protocolType": that.streamService.getProtocolType(),
          "protocolSecurityType": that.auth.toUpperCase(),
          "protocolSecurityAttributes": that.stream.protocolSecurityAttributes,
          "customProtocolType": that.caps,
          "streamConfiguration": that.stream.streamConfiguration,
          "payloadSecurityType": that.requestEncryption,
          "streamState": "ACTIVE",
          "ackTimeout": 0,
          "ackStrategy": parseInt(that.strategy),
          "payloadAtRestObfuscation": that.obfuscation.toUpperCase(),
          "payloadAtRestCompression": that.compression.toUpperCase(),
          "payloadAtRestPersistence": that.persistence.toUpperCase(),
          "payloadAtRestDigest": that.digest.toUpperCase(),
          "throughputStrategy": that.throughput,
          "encryptionAlgorithmAttributes": [

          ]
        }
      }

    }
  }

  prepareS3customProtocolsModel() {

    const that = this;

    if (that.direction == 'Passive Sending') {
      that.populateDeliverySchedule();
    }
    that.auth = "S3";
    that.stream = {
      "creator": that.iotService.getCreator(),
      "creatorAppId": that.iotService.getCreatorAppId(),
      "realm": that.iotService.getRealm(),
      'name': [{
        "lang": "en_us",
        "text": that.streamService.getStreamName(),
      }],
      "direction": that.streamService.getDirection(),
      "ownerId": that.streamService.getOwnerId(),
      "streamType": that.streamService.getStreamType().toUpperCase(),
      "protocolType": that.streamService.getProtocolType(),
      "protocolSecurityType": that.auth.toUpperCase(),
      "protocolSecurityAttributes": that.stream.protocolSecurityAttributes,
      "streamConfiguration": that.stream.streamConfiguration,
      "payloadSecurityType": that.requestEncryption,
      "streamState": "ACTIVE",
      "ackTimeout": 0,
      "ackStrategy": parseInt(that.strategy),
      "payloadAtRestObfuscation": that.obfuscation.toUpperCase(),
      "payloadAtRestCompression": that.compression.toUpperCase(),
      "payloadAtRestPersistence": that.persistence.toUpperCase(),
      "payloadAtRestDigest": that.digest.toUpperCase(),
      "throughputStrategy": that.throughput,
      "encryptionAlgorithmAttributes": [

      ],
      "deliveryConfig": {
        "commandRollbackAttempts": 0,
        "ackWaitTimeoutLimit": 5,
        "retryInterval": that.retryAttempts,
        "retryNumber": that.delayRetryAttempts,
        "retryTimeOut": that.retryTimeout,
        "deliverySchedule": that.deliverySchedule,
        "deliveryScript": "",
        "deliveryEnabled": this.enableDelivery,
        "destinationURI": that.deliveryUrlValue
      }
    }
    if (that.type == 'Messaging' && that.direction == 'Passive Sending') {
      that.stream.senderCodeId = that.streamService.getFromCodeValue().id;
      that.stream.receiverCodeId = that.streamService.getToCodeValue().id;
    }
  }

  prepareBatchcustomProtocolsModel() {

    const that = this;
    that.stream = {
      "creator": that.iotService.getCreator(),
      "creatorAppId": that.iotService.getCreatorAppId(),
      "realm": that.iotService.getRealm(),
      'name': [{
        "lang": "en_us",
        "text": that.streamService.getStreamName(),
      }],
      "direction": that.streamService.getDirection(),
      "ownerId": that.streamService.getOwnerId(),
      "streamType": that.streamService.getStreamType().toUpperCase(),
      "protocolType": that.streamService.getProtocolType(),
      "protocolSecurityType": 'NONE',
      "protocolSecurityAttributes": that.stream.protocolSecurityAttributes,
      "streamConfiguration": that.stream.streamConfiguration,
      "payloadSecurityType": that.requestEncryption,
      "streamState": "ACTIVE",
      "ackTimeout": 0,
      "ackStrategy": parseInt(that.strategy),
      "payloadAtRestObfuscation": that.obfuscation.toUpperCase(),
      "payloadAtRestCompression": that.compression.toUpperCase(),
      "payloadAtRestPersistence": that.persistence.toUpperCase(),
      "payloadAtRestDigest": that.digest.toUpperCase(),
      "throughputStrategy": that.throughput,
      'isActive': true,
      'batchConfig': {
        "schedule": "0 0/5 * * * ?",
        "handler": "com.covisint.platform.messaging.batch.processor.handler.ConcatHandler",
        "handlerAttributes": {
          "delimiter": ""
        },
        "maxBytes": 1024,
        "maxMessages": 2
      }
    }

  }

  prepareAQProtocolsModel() {

    const that = this;

    that.populateDeliverySchedule();
    that.stream = {
      "creator": that.iotService.getCreator(),
      "creatorAppId": that.iotService.getCreatorAppId(),
      "realm": that.iotService.getRealm(),
      'name': [{
        "lang": "en_us",
        "text": that.streamService.getStreamName(),
      }],
      "direction": that.streamService.getDirection(),
      "ownerId": that.streamService.getOwnerId(),
      "streamType": that.streamService.getStreamType().toUpperCase(),
      "protocolType": that.streamService.getProtocolType(),
      "protocolSecurityType": that.auth.toUpperCase(),
      "protocolSecurityAttributes": that.stream.protocolSecurityAttributes,
      "streamConfiguration": that.stream.streamConfiguration,
      "payloadSecurityType": that.requestEncryption,
      "streamState": "ACTIVE",
      "ackTimeout": 0,
      "ackStrategy": parseInt(that.strategy),
      "payloadAtRestObfuscation": that.obfuscation.toUpperCase(),
      "payloadAtRestCompression": that.compression.toUpperCase(),
      "payloadAtRestPersistence": that.persistence.toUpperCase(),
      "payloadAtRestDigest": that.digest.toUpperCase(),
      "throughputStrategy": that.throughput,
      "encryptionAlgorithmAttributes": [

      ],
      "deliveryConfig": {
        "commandRollbackAttempts": 0,
        "ackWaitTimeoutLimit": 5,
        "retryInterval": that.retryAttempts,
        "retryNumber": that.delayRetryAttempts,
        "retryTimeOut": that.retryTimeout,
        "deliverySchedule": that.deliverySchedule,
        "deliveryScript": that.streamService.getDeliveryScriptBase64Encoded(),
        "deliveryEnabled": this.enableDelivery,
        "destinationURI": that.deliveryUrlValue
      }
    }

    if (that.direction == 'Passive Sending') {
      that.stream.senderCodeId = that.streamService.getFromCodeValue().id;
      that.stream.receiverCodeId = that.streamService.getToCodeValue().id;
    }

  }


  getLogLevelMode() {
    const that = this;
    if (that.direction == 'Active Sending' || that.direction == 'Both' || that.direction == 'Passive Sending') {
      that.stream.streamConfiguration = {
        "logMode": that.mode,
        "pullingThreads": 10,
        "sleepTime": 600,
        "quota": parseInt(that.quota),
        "logExpiration": Date.now() + 60000 * parseInt(that.expiration)
      }
    } else {
      that.stream.streamConfiguration = {
        "logMode": "INFO",
        "pullingThreads": 10,
        "sleepTime": 600,
        "quota": 100
      }
    }
  }
  prepareDataModel() {
    const that = this;
    that.getLogLevelMode();
    if (that.auth == 'X509') {
      that.stream.protocolSecurityAttributes.push({
        name: 'x509Pem',
        value: that.certificateDataText
      })
    }

    if (that.auth == 'Public Key' && that.direction != 'Active Receiving') {
      that.auth = 'PublicKey';

      that.stream.protocolSecurityAttributes.push({
        name: 'publicKeyName',
        value: that.publicKeyName
      })

      that.stream.protocolSecurityAttributes.push({
        name: 'publicKeyDesc',
        value: that.publicKeyDesc
      })
      that.stream.protocolSecurityAttributes.push({
        name: 'publicKeyText',
        value: that.publicKeyDataText
      })


    }
    if (that.protocol == 'MQTT' || that.protocol == 'AMQP' || that.protocol == 'STOMP' || that.protocol == 'Web Sockets') {
      that.prepareRabbitProtocolsModel()
    }
    else if (this.protocol == 'FTP') {
      that.prepareFTPcustomProtocolsModel();

    } else if (this.protocol == 'CAPS' && that.type != 'Sync') {
      that.prepareCAPScustomProtocolsModel();
    }
    else if (this.protocol == 'S3') {

      //S3 call
      that.prepareS3customProtocolsModel();
    }
    else if (this.protocol == 'AQ') {
      that.prepareAQProtocolsModel();
    }
    else if (this.protocol == 'BATCH') {
      that.prepareBatchcustomProtocolsModel();
    }
    else {
      if (that.type == 'Sync' && that.protocol == 'HTTPS') {
        that.prepareCAPScustomProtocolsModel();
        delete that.stream['customProtocolType'];
        if (that.streamService.getFromCodeValue() && that.streamService.getToCodeValue()) {
          that.stream.senderCodeId = that.streamService.getFromCodeValue().id;
          that.stream.receiverCodeId = that.streamService.getToCodeValue().id;

        }
        if (that.auth == 'OAuth Token') {
          that.stream.protocolSecurityType = 'OAUTH2';
          if (that.streamService.getOAuthTokenId() != undefined && that.streamService.getOAuthTokenId() != '') {
            if (that.stream.protocolSecurityAttributes.length == 0) {
              that.stream.protocolSecurityAttributes.push({
                "name": "tokenId",
                "value": that.streamService.getOAuthTokenId()
              });
            }
          }
        }
      }
      else if (that.type == 'Sync' && that.protocol == 'CAPS') {
        that.prepareCAPScustomProtocolsModel();
        that.stream.customProtocolType = 'HTTPS'
      }
    }
    if (this.requestEncryption == 'ENCRYPTED') {
      this.streamService.setPayloadSecurityType('ENCRYPTED');
      let value = "128";
      if (this.encryption == 'AES 256bit PSK') {
        value = "256";
      }
      this.stream.encryptionAlgorithmAttributes.push({
        "name": "securityStandards",
        "value": "PSK"
      },
        {
          "name": "algorithmKeySize",
          "value": value
        },
        {
          "name": "algorithmName",
          "value": "AES"
        });
    }

  }



  resetData() {
    const that = this;
    that.userNameError = '';
    that.passwordError = '';
    that.deliveryUrlError = '';
    that.customProtocolError = '';
    that.deliveryScheduleError = '';
    that.sshNameError = '';
    that.directionError ='';
    that.streamTypeError ='';
    that.protocolError='';
    that.sshKeyError = '';
    that.mutualAuthError = '';
    that.tokenError = '';
    that.accessTokenKey = '';
    that.accessTokenSecret = '';
    that.streamService.streamDocInfo = false;
    that.streamService.selectedDocInfos = false;
    that.direction = 'Select Direction';
    that.protocol = 'Select Protocol Type';
    that.streamService.setDirection('Select Direction');
    that.streamService.setProtocolType('Select Protocol Type');
    that.streamService.setProtocolSecurityType(undefined);
    that.streamService.setStreamDocInfoTemplate(undefined);
    that.streamService.setStreamDocInfoDocType(undefined);
    that.streamService.setStreamDocInfoVersion(undefined);
    that.streamService.setDocInfoId(undefined);
    that.streamService.setSSHKeyPair(undefined);
    that.streamService.setOAuthToken(undefined);
    that.streamService.setMutualAuth(undefined);
    that.streamService.setStreamName(undefined);
    // that.documentInfoService.selection5.clear();
    // that.documentInfoService.selection6.clear();
    // that.documentInfoService.selection.clear();
    // that.fromTpCodeService.selection.clear();
    // that.fromTpCodeService.selection1.clear();
    // that.fromTpCodeService.selection2.clear();
    // that.toTpCodeService.selection.clear();
    // that.toTpCodeService.selection3.clear();
    // that.toTpCodeService.selection4.clear();
    // that.sshKeyService.selection.clear();
    // that.keyBundleService.selection.clear();
    // that.oAuthTokenService.selection.clear();
    that.streamService.streamDocInfo = false;
    that.streamService.drsScriptFlag = false;
    that.direction = 'Select Direction';
    that.showLogLevel = false;
    that.mode = 'ERROR';
    that.compression = 'No';
    that.obfuscation = 'No'
    that.expiration = '0';
    that.strategy = '0';
    that.persistence = 'Yes';
    that.digest = 'No';
    that.protocol = 'Select Protocol Type';
    that.ftp = 'Select';
    that.caps = 'Select';
    that.auth = 'Basic';
    that.doc = 'Document information default';
    that.type = 'Application';
    that.requestEncryption = 'NONE';
    that.showCustomCAPS = false;
    that.showCustomFTP = false;
    that.customProtocol = false;
    that.showSecurity = false;
    that.encryption = 'NONE';
    that.quota = '3';
    that.showCertificate = false;
    that.showCertificateExample = false;
    that.docInfoList = true;
    that.drsScript = false;
    that.showProtocol = false;
    that.showCredentials = false;
    that.batchConfig = false;
    that.showDeliveryScript = false;
    that.showCode = false;
    that.showAccessToken = true;
    that.retryAttempts = 2;
    that.delayRetryAttempts = 2;
    that.retryTimeout = 3;
    that.example = 'Code Examples';
    that.showSchedule = false;
    that.certificateDataText = undefined;
    that.usernameValue = '';
    that.passwordValue = '';
    that.deliveryUrlValue = '';
    that.throughput == 'Round Robin'
    that.streamService.setDocInfo2(undefined);
    that.streamService.fromCode2 = false;
    that.streamService.toCode2 = false;
    that.streamService.fromCode = false;
    that.streamService.toCode = false;
    that.syncAuthType = '';
    that.streamService.setfromCodeValue2(undefined);
    that.streamService.setToCodeValue2(undefined);
    that.streamService.setFromCodeValue(undefined);
    that.streamService.setToCodeValue(undefined);
    that.streamService.streamDocInfo = false;
    that.streamService.selectedDocInfos = false;
    that.showSchedule = false;
    that.cronValue = '';
    that.showDeviceFromSideNav = false;
    that.showAppFromSideNav = false;
    //  that.streamService.setOwnerId(undefined);
    that.streamService.selectedDevice = false;
    that.streamService.setDeviceSelected(undefined)
    that.streamService.selectedApplication = false;
    that.streamService.setApplicationSelected(undefined);
    that.stream = {
      "creator": that.iotService.getCreator(),
      "creatorAppId": that.iotService.getCreatorAppId(),
      "realm": that.iotService.getRealm(),
      'name': [{
        'lang': 'en_us',
        'text': ''
      }],
      'description': [{
        'lang': 'en_us',
        'text': ''
      }],
      'protocolSecurityAttributes': [

      ],
      "encryptionAlgorithmAttributes": [

      ],
      'documentRecognition': {

      },
      'streamConfiguration': {

      },
      'direction': '',
      'isActive': true

    }

    if (that.auth == 'S3') {
      that.auth = 'S3 Credentials'
    }
    this.stream.protocolSecurityAttributes = [];

    // that.streamService.setStreamDocInfoDocType('');
    // that.streamService.setStreamDocInfoTemplate('');
    // that.streamService.setStreamDocInfoVersion('');



  }

  getAceEditorValue() {
    if (this.drsScript) {
      this.copiedEditorValue = this.streamService.getDRSscript();
    } else {
      this.copiedEditorValue = this.streamService.getDeliveryScript();

    }
    return this.copiedEditorValue;
  }

  copyToClipBoard() {
    //  this.getAceEditorValue();
  }

  customisingProtocols() {
    const that = this;
    if (that.protocol == 'FTP' && that.direction != 'Passive Sending') {

      if (that.ftp == 'VPN') {
        that.ftp = 'VPN-KAFKA'
      } else if (that.ftp == 'SSL') {
        that.ftp = 'SSL-KAFKA'
      } else if (that.ftp == 'SSH') {
        that.ftp = 'SSH-KAFKA'
      } else if (that.ftp == 'VPN Schedule') {
        that.ftp = 'VPN-AQS'
      } else if (that.ftp == 'SSL Schedule') {
        that.ftp = 'SSL-AQS'
      } else if (that.ftp == 'SSH Schedule') {
        that.ftp = 'SSH-AQS'
      }


    } else if (that.protocol == 'CAPS') {
      if (that.caps == 'HTTPS Blast') {
        that.caps = 'HTTPS-KAFKA'
      } else if (that.caps == 'Scheduled HTTPS') {
        that.caps = 'HTTPS-AQS'
      }
    }

    /*  else if (that.protocol == 'S3') {
       if (that.caps == 'HTTPS Blast') {
         that.caps = 'HTTPS-KAFKA'
       } else if (that.caps == 'Scheduled HTTPS') {
         that.caps = 'HTTPS-AQS'
       }
     } */
    that.submitProtocols()
  }

  submitProtocols() {
    const that = this;
    if (that.auth == 'Basic') {
      if ((that.usernameValue != undefined && that.usernameValue != '') && (that.passwordValue != undefined && that.passwordValue != '')) {
        that.stream.protocolSecurityAttributes.push({
          "name": "username",
          "value": that.usernameValue
        },
          {
            "name": "password",
            "value": that.passwordValue
          });
      }
    } else if (that.auth == 'Mutual Auth') {
      that.auth = 'MUAUTH';
      if (that.streamService.getMutualAuthId() != undefined && that.streamService.getMutualAuthId() != '') {
        that.stream.protocolSecurityAttributes.push({
          "name": "mutualAuthKeyBundle",
          "value": that.streamService.getMutualAuthId()
        },
          {
            "name": "reverseCertChainOrder",
            "value": that.reverseChain.toString()
          });
      }
    } else if (that.auth == 'OAuth Token') {
      that.auth = 'OAUTH2';
      if (that.streamService.getOAuthTokenId() != undefined && that.streamService.getOAuthTokenId() != '') {
        that.stream.protocolSecurityAttributes.push({
          "name": "tokenId",
          "value": that.streamService.getOAuthTokenId()
        });
      }
    }
    else if (that.auth == 'Public Key') {
      that.auth = 'PublicKey'
      if ((that.sshName != undefined && that.sshName != '') && (that.streamService.getsshKeyPairId() != undefined && that.streamService.getsshKeyPairId() != '')) {
        that.stream.protocolSecurityAttributes.push({
          "name": "sshKeyPairId",
          "value": that.streamService.getsshKeyPairId()
        },
          {
            "name": "username",
            "value": that.sshName
          })
      }


    } else if (that.auth == 'S3 Credentials') {
      if ((that.accessTokenKey != undefined && that.accessTokenKey != '') && (that.accessTokenSecret != undefined && that.accessTokenSecret != '')) {
        that.stream.protocolSecurityAttributes.push({
          "name": "username",
          "value": that.accessTokenKey
        },
          {
            "name": "password",
            "value": that.accessTokenSecret
          });
      }
    }
  }
  errorValidations() {
    
    const that = this;
    that.hasError = false;
    if(that.type=='Select'){
    that.streamTypeError='Please select an application or device';
    that.hasError = true;
    }

    if(that.direction=='Select Direction'){
      that.directionError='Please select a direction';
      that.hasError = true;
    }

    if(that.showProtocol && that.protocol=='Select Protocol Type'){
      that.protocolError='Please select protocol type';
      that.hasError = true;
    }

    if (that.streamService.getStreamName() == undefined || that.streamService.getStreamName() == '') {
      that.nameError = 'Name is mandatory';
      that.hasError = true;
    }

    if (that.customProtocol == true) {
      if (that.protocol == 'FTP' || that.ftp == 'Select') {
        if (that.ftp == 'Select') {
          that.customProtocolError = 'Custom protocol is mandatory';
        } else {
          that.customProtocolError = '';
        }
      }

      if (that.protocol == 'CAPS' || that.ftp == 'Select') {
        if (that.caps == 'Select') {
          that.customProtocolError = 'Custom protocol is mandatory';
        } else {
          that.customProtocolError = '';
        }
      }
    }

    if (this.direction == 'Active Receiving' || this.direction == 'Passive Sending') {
      if ((that.deliveryUrlValue == undefined || that.deliveryUrlValue == '') && that.protocol != 'S3') {
        that.deliveryUrlError = 'Delivery url is mandatory';
        that.hasError = true;
      }
      if (that.auth == 'Basic') {
        if (that.usernameValue == undefined || that.usernameValue == '') {
          that.userNameError = 'Username is mandatory';
          that.hasError = true;
        }

        if (that.passwordValue == undefined || that.passwordValue == '') {
          that.passwordError = 'Password is mandatory';
          that.hasError = true;
        }
      } else if (that.auth == 'Public Key') {
        if (that.sshName == undefined || that.sshName == '') {

          that.sshNameError = 'Username is mandatory';
          that.hasError = true;
        }
        if (that.streamService.getsshKeyPairId() == undefined || that.streamService.getsshKeyPairId() == '') {
          that.sshKeyError = 'Please select ssh key';
          that.hasError = true;

        }
      } else if (that.auth == 'Mutual Auth') {
        if (that.streamService.getMutualAuthId() == undefined || that.streamService.getMutualAuthId() == '') {
          that.mutualAuthError = 'Please select key bundle';
          that.hasError = true;
        }
      }
      else if (that.auth == 'OAuth Token') {
        if (that.streamService.getOAuthTokenId() == undefined || that.streamService.getOAuthTokenId() == '') {
          that.tokenError = 'Please select oauth token';
          that.hasError = true;
        }
      }

    }
    if (that.streamService.fromCode && that.streamService.toCode) {
      if (that.streamService.getFromCodeValue() != undefined && that.streamService.getToCodeValue() != undefined) {
        if (that.streamService.getFromCodeValue().id == that.streamService.getToCodeValue().id) {
          that.streamService.errorMessage = "Sender Code and Receiver Code can't be same.";
          that.hasError = true;
        }
      }
      else {
        that.streamService.errorMessage = "Please select from code and to code value";
        that.hasError = true;
      }
    }

    if (that.showSchedule) {
      if (that.cronValue == '' || that.cronValue == undefined) {
        that.deliveryScheduleError = 'Please enter delivery schedule';
        that.hasError = true;
      }
    }

    return that.hasError;
  }
  save() {
    const that = this;
    that.streamTypeError='';
    that.protocolError='';
    that.directionError='';
    that.streamService.setStreamEncryptionKey(undefined);
    that.streamService.resetErrorMessage();
    if (!that.errorValidations()) {
      if (that.direction == 'Active Sending') {
        that.streamService.setDirection('ACTIVE_SENDING');
      } else if (that.direction == 'Active Receiving') {
        that.streamService.setDirection('ACTIVE_RECEIVING');
        that.customisingProtocols();
      } else if (that.direction == 'Passive Sending') {
        that.streamService.setDirection('PASSIVE_SENDING');
        that.customisingProtocols();
      } else if (that.direction == 'Passive Receiving') {
        that.streamService.setDirection('PASSIVE_RECEIVING');
      } else {
        that.streamService.setDirection('BOTH');
      }

      if (that.throughput == 'Round Robin') {
        that.throughput = 'ROUND_ROBIN';
      } else {
        that.throughput = 'BY_STREAM'
      }
      that.streamService.setStreamType(that.type);

      that.prepareDataModel();
      that.newStreamCreated=true;
      that.iotService.getCuiObjResponse().createStream({
        data: that.stream
      }).then(function (results) {
        that.streamService.streamCreated = true;
        that.streamService.setStreamResponseProducerTopic(results.producerTopic);
        if (results.protocolSecurityAttributes != undefined) {
          for (var k = 0; k < results.protocolSecurityAttributes.length; k++) {
            var res = results.protocolSecurityAttributes[k];
            if (res.name == 'clientId') {
              that.streamService.setStreamResponseClientId(res.value);
            }
            if (res.name == 'port') {
              that.streamService.setStreamResponsePort(res.value);
            }
            if (res.name == 'host') {
              that.streamService.setStreamResponseHost(res.value);
            }
            if (res.name == 'username') {
              that.streamService.setStreamResponseUsername(res.value);
            }
            if (res.name == 'password') {
              that.streamService.setStreamResponsePassword(res.value);
            }
            if (res.name == 'basicAuthenticationHost') {
              that.streamService.setStreamResponseBasicAuthnHost(res.value);
            }
            if (res.name == 'basicAuthenticationPort') {
              that.streamService.setStreamResponseBasicAuthnPort(res.value);
            }
            if (res.name == 'activeSendingPostMessageUri') {
              that.streamService.setStreamResponseSendingPostMessageUri(res.value);
            }

            if (that.caps == 'HTTPS' && that.auth == 'X509') {
              if (res.name == 'mutualAuthenticationPort') {
                that.streamService.setStreamResponsePort(res.value);
              }
              if (res.name == 'mutualAuthenticationHost') {
                that.streamService.setStreamResponseHost(res.value);
              }
            }



          }
        }

        if (results.encryptionKey) {
          that.streamService.setStreamEncryptionKey(results.encryptionKey);
        }

        if (that.streamService.selectedDocInfos == true) {
          that.addStreamDocInfo(results.id)
        }
        if (results.consumerTopic != undefined) {
          that.streamService.setConsumerTopic(results.consumerTopic);
        }

        that.streamService.showListStream = true;
        that.streamService.streamNamesArray.push(results.name[0].text);
        that.resetData();
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }).fail(function (msg) {
        that.newStreamCreated=false;
        that.streamService.streamCreated = false;
        if (that.streamService.getDirection() == 'ACTIVE_SENDING') {
          that.streamService.setDirection('Active Sending');
        } else if (that.streamService.getDirection() == 'ACTIVE_RECEIVING') {
          that.streamService.setDirection('Active Receiving');
        } else if (that.streamService.getDirection() == 'PASSIVE_SENDING') {
          that.streamService.setDirection('Passive Sending');
        } else if (that.streamService.getDirection() == 'PASSIVE_RECEIVING') {
          that.streamService.setDirection('Passive Receiving');
        } else {
          that.streamService.setDirection('Both');
        }
        if (that.customProtocol == true) {
          that.customProtocol = true;
        }
        if (that.showCustomFTP == true && that.direction == 'Active Receiving') {
          that.showCustomFTP = true;
          if (that.ftp == 'SSH-KAFKA') {
            that.ftp = 'SSH';
          } if (that.ftp == 'SSL-KAFKA') {
            that.ftp = 'SSL';
          } if (that.ftp == 'VPN-KAFKA') {
            that.ftp = 'VPN';
          } if (that.ftp == 'SSH-AQS') {
            that.ftp = 'SSH Schedule';
          } if (that.ftp == 'SSL-AQS') {
            that.ftp = 'SSL Schedule';
          } if (that.ftp == 'VPN-AQS') {
            that.ftp = 'VPN Schedule';
          }
        }
        if (that.showCustomCAPS == true && that.direction == 'Active Receiving') {
          that.showCustomCAPS = true;
          if (that.caps == 'HTTPS-KAFKA') {
            that.caps = 'HTTPS Blast'
          } else if (that.caps == 'HTTPS-AQS') {
            that.caps = 'Scheduled HTTPS'
          }
        }
        if (that.auth == 'PublicKey') {
          that.auth = 'Public Key'
        } else if (that.auth == 'OAUTH2') {
          that.auth = 'OAuth Token'
        } else if (that.auth == 'MUAUTH') {
          that.auth = 'Mutual Auth'
        }
        else if (that.auth == 'S3') {
          that.auth = 'S3 Credentials'
        }
        that.throughput == 'Round Robin'

        console.log(msg.responseJSON.apiMessage);
        if (msg.responseJSON.apiMessage == "The following properties contained illegal values: A trusted certificate already exists with the issuer hash and serial.") {
          that.streamService.errorMessage = "A trusted certificate already exists with the issuer hash and serial.";
        } else if (msg.responseJSON.apiMessage == "The following information was missing from the resource- ownerId") {

          if (that.type == 'Device') {
            that.streamService.errorMessage = "The following information was missing from the resource- deviceId";
          } else {
            that.streamService.errorMessage = "The following information was missing from the resource- applicationId";
          }
        } else {
          that.streamService.errorMessage = msg.responseJSON.apiMessage;
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
      });

    }
  }

  goback(){
    window.location.reload();
  }

 getCron() {
   const that=this;
    var results = "";
    if (that.cronValue) {
        try {
            results = cronstrue.toString(that.cronValue);
        } catch (err) {
            results = "";
        }


        console.log(results)
    }
    return results;
}



}

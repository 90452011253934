import { Injectable } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { IotService } from '../service/iot.service'; 
import { ListTrustedCertificatesService } from './ListTrustedCertificates.service';
import { SecurtiyService } from './security.service';
import { Certificate } from 'crypto';
@Injectable({
	providedIn: 'root'
})

export class TrustedCertificatesService extends ListTrustedCertificatesService{
	
	constructor(public iotService: IotService, public securityService: SecurtiyService) {
		super(iotService);
		this.loadSelected();
	}
	loadSelected(){
		if (this.securityService.getKeyBundle()) {
			let keyBundle = this.securityService.getKeyBundle();
			this.onSelection(keyBundle.trustedCertificate);
		}
	}
  
	onSelection(results) {
		const that = this;
		that.fnOnSelectionSupport(results);
		// that.playRecord(results);
	}
	fnOnSelectionSupport(certificate) {
		const that = this;
		that.loadPrivateKey(certificate);
	}
	removeSelection(results) {
		const that = this;
		// that.fnRemoveSelectionSupport(results);
		// this.playRecord(results);
	}
	fnRemoveSelectionSupport(results) {
		const that = this;
		// alert('Implement this method');
	}
	playRecord(element) {
		const that = this;
		that.selection.clear();
		that.selection.select(element.id);
  }
	
	loadPrivateKey(certificate: any) {
		const that = this;
		var certificateId = certificate.id;
		that.securityService.privateKeyLoaded = true;
		that.iotService.getCuiObjResponse().getTrustedPrivateKeys({
			qs: [['parentCertificateId', certificateId]]
		}).then(function (response) {
			console.log(response);
			if (response.length > 0) {
				var trustedPrivateKey = response[0];
				that.securityService.privateKeyLoaded = trustedPrivateKey.id;
				let keyBundle = that.securityService.getKeyBundle();
				if (keyBundle) {
					keyBundle.trustedCertificate = certificate;
					console.log(keyBundle);
					that.securityService.setKeyBundle(keyBundle);
				}
				that.playRecord(certificate);

			}
			else {
				that.securityService.privateKeyLoaded = false;
				that.securityService.warningMessage="Cannot Select this Cert. No Private Key Associated with this";
			}

		});

	}
}

<main id="template-details">
  <div class="d-flex justify-content-center loader" *ngIf="!deviceTemplate">
    <div class="spinner-border" role="status" >
      <span class="sr-only" id="loading"></span>
    </div>
  </div>


</main>

        
<mat-sidenav-container autosize *ngIf="deviceTemplate" class="example-sidenav-content">
  <mat-sidenav class="midNav-container" #sidenav mode="side" disableClose="true" opened="true">
    <div class="alert success" *ngIf="digitalTwinTemplatesService.successMessage">
      <h5>
        <fa-icon [icon]="faSuccessIcon"></fa-icon>
        <span>{{digitalTwinTemplatesService.successMessage}}</span> <button class="alert-close-btn">
          <fa-icon (click)=digitalTwinTemplatesService.resetSuccessMessage() [icon]="faCloseIcon"></fa-icon>
        </button>
      </h5>
    </div>
    <div class="alert warning" *ngIf="digitalTwinTemplatesService.warningMessage">
      <h5>
        <fa-icon [icon]="faWarningIcon"></fa-icon>
        <span>{{digitalTwinTemplatesService.warningMessage}}</span>
        <button class="alert-close-btn">
          <fa-icon (click)="digitalTwinTemplatesService.resetWarningMessage()" [icon]="faCloseIcon"></fa-icon>
        </button>
      </h5>
    </div>
    <div class="alert critical" *ngIf="digitalTwinTemplatesService.errorMessage">
      <h5>
        <fa-icon [icon]="faCriticalIcon"></fa-icon>
        <span>{{digitalTwinTemplatesService.errorMessage}}</span>
        <button class="alert-close-btn">
          <fa-icon (click)="digitalTwinTemplatesService.resetErrorMessage()" [icon]="faCloseIcon"></fa-icon>
        </button>
      </h5>
    </div>
    <section>
      <!-- <dl>
        <dt>{{'name' | translate}}</dt>
        <dd>{{deviceTemplate.name[0].text}}</dd>
      </dl>
      <dl>
        <dt>{{'created' | translate}}</dt>
        <dd>{{deviceTemplate.creation | date:'mediumDate' }}</dd>
      </dl>
        <dl>
          <dt>{{'created' | translate}}</dt>
          <dd>{{deviceTemplate.creation | date:'mediumDate' }}</dd>
        </dl>
      <dl>
        <dt>{{'description' | translate}}</dt>
        <dd>
          
          <ng-container *ngIf="deviceTemplate.description">
          {{deviceTemplate.description[0].text}}
          </ng-container>
          <ng-container *ngIf="!deviceTemplate.description">
          -
          </ng-container>
      </dd>
      </dl>
      

      <label for="device-tags">{{'tags' | translate}}</label>
      <mat-chip-list #tagList aria-label="tags">
        <mat-chip class="ot-chip" *ngFor="let tag of deviceTemplate.tags" [selectable]="selectable" [removable]=true (removed)="remove(tag)">
          {{tag}}
          <button matChipRemove></button>
        </mat-chip>
        <input [ngClass]="tagClass" placeholder="add a tag" #tagInput [matChipInputFor]="tagList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="add($event)">
      </mat-chip-list> -->

      <mat-nav-list class="without-template user-selections">
        <div style="margin-left: -5px;">
        <app-copy-resource-id></app-copy-resource-id>
      </div>
        <h2>{{'attributes' | translate}}</h2>
        <p *ngIf="digitalTwinTemplatesService.getAttributes().length==0">{{'select.availableAttributes.desc' | translate}}</p>
        <ul>
            <li *ngFor="let attribute of digitalTwinTemplatesService.getAttributes()"><fa-icon [icon]="faCheckIcon"></fa-icon> {{attribute.name}}</li>
        </ul>

        <h2>{{'events' | translate}}</h2>
        <p *ngIf="digitalTwinTemplatesService.getEvents().length==0">{{'select.availableEvents.desc' | translate}}</p>
        <ul>
            <li *ngFor="let event of digitalTwinTemplatesService.getEvents()"><fa-icon [icon]="faCheckIcon"></fa-icon> {{event.name}}</li>
        </ul>

        <h2>{{'commands' | translate}}</h2>
        <p *ngIf="digitalTwinTemplatesService.getCommands().length==0">{{'select.availableCommands.desc' | translate}}</p>
        <ul>
            <li *ngFor="let command of digitalTwinTemplatesService.getCommands()"><fa-icon [icon]="faCheckIcon"></fa-icon> {{command.name}}</li>
        </ul>

        <h2>{{'geofences' | translate}}</h2>
        <p *ngIf="digitalTwinTemplatesService.getGeofences().length==0">{{'select.availableGeofences.desc' | translate}}</p>
        <ul>
            <li *ngFor="let geofence of digitalTwinTemplatesService.getGeofences()"><fa-icon [icon]="faCheckIcon"></fa-icon> {{geofence.name[0].text}}</li>
        </ul>

        <br>
        <!-- <button *ngIf="deviceService.deviceNameSelect"  style="margin-left: 15px;"  [disabled]="newDeviceTemplateCreated" (click)=createNewDeviceTemplate(false,false) class="btn btn-primary" >{{'save.another' | translate}}</button>
        <button *ngIf="deviceService.deviceNameSelect" [disabled]="newDeviceTemplateCreated" (click)=createNewDeviceTemplate(true,false) class="btn btn-primary" >{{'save.exit' | translate}}</button>
        <button  *ngIf="deviceService.deviceNameSelect" style="margin-left: 15px;" [disabled]="newDeviceTemplateCreated" (click)=createNewDeviceTemplate(true,true) class="btn btn-primary" >{{'save.template' | translate}}</button>
        <br><br> -->
    </mat-nav-list>
    <dl>
      <dt>{{'created' | translate}}</dt>
      <dd>{{deviceTemplate.creation | date:'mediumDate' }}</dd>
    </dl>
    <hr style="margin: 15px;">
      <dl>
        <dt>{{'enabled' | translate}}{{'?' | translate}}</dt>
        <dd>
          <button *ngIf="deviceTemplate.isActive" class="toggle-on toggle-switch" (click)="toggleIcon(deviceTemplate.id)">
            <fa-icon [icon]="faCheckIcon" class="fa-2x"></fa-icon>
            <div class="toggle-on-btn"></div>
          </button>
          <button *ngIf="!deviceTemplate.isActive" class="toggle-off toggle-switch" (click)="toggleIcon(deviceTemplate.id)">
            <div class="toggle-off-btn"></div>
          </button>
        </dd>
      </dl>
      <hr style="margin: 15px;">
      <!-- <br>
      <br> -->

      <div class="button-row" style="margin: 20px;">
          <button class="btn btn-primary pull-left" (click)="loadDeviceTemplateWithoutChildren(deviceTemplate.id)" >{{'update' | translate}}</button>
          <button class="btn btn-secondary pull-right" (click)="cloneDeviceTemplate()" >{{'clone' | translate}}</button>

      </div>
      <ng-container>
        <button class="btn btn-secondary pull-right" style="margin-right: 5px;"
           (click)="auditDeviceTemplate()">{{ 'audit' |
           translate}}</button>   
       </ng-container>
    </section>
  </mat-sidenav>
  <article>
    <div *ngIf="!auditFlag">
    <ng-container *ngIf="!showReferences">
      <div style="margin: 15px;">
      <label for="device-name"> {{'name' | translate}}</label>
      <input placeholder="required" id="device-name" type="text" [(ngModel)]="deviceTemplate.name[0].text" aria-label="Name">

      <label for="device-description">{{'description' | translate}}</label>
      <textarea placeholder="optional" id="device-description" [(ngModel)]="deviceTemplate.description[0].text" aria-label="Description"></textarea>  
      <label for="device-tags">{{'tags' | translate}}</label>
          
      <mat-chip-list #tagList aria-label="tags" style="width: 60%;">
        <mat-chip class="ot-chip" *ngFor="let tag of deviceTemplate.tags" [selectable]="selectable" [removable]=true (removed)="remove(tag)">
          {{tag}}
          <button matChipRemove></button>
        </mat-chip>
        <input [ngClass]="tagClass" placeholder="add a tag" #tagInput [matChipInputFor]="tagList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="add($event)">
      </mat-chip-list>
     
    </div> 

      <!-- {{attributesLoaded}} -->
    <app-attributes *ngIf="attributesLoaded" [pageSize]="5" [showSelectColoumn]=true [showEnableColumn]=true [showControlBar]=true
    [showNew]=true [displayedColumns]="['ADD', 'name', 'description', 'type']"
    [newWithRoute]=false [showHeaderToolTips]=false></app-attributes>


    <!-- {{eventsLoaded}} -->
   <app-events *ngIf="eventsLoaded" [pageSize]="5" [showSelectColoumn]=true [showEnableColumn]=true [showControlBar]=true
    [showNew]=true [displayedColumns]="['ADD', 'name', 'description']" [newWithRoute]=false [showHeaderToolTips]=false>
  </app-events>

  <!-- {{commandsLoaded}} -->
  <app-commands *ngIf="commandsLoaded" [pageSize]="5 " [showControlBar]=true
  [displayedColumns]="[ 'ADD', 'name','description','creation','ACTIVE'] " [showSelectColoumn]=true
  [showEnableColumn]=true [showNew]="false" [showHeaderToolTips]=false></app-commands> 

  <!-- {{geofencesLoaded}} -->
  <app-geofences *ngIf="geofencesLoaded" [pageSize]="5" [showSelectColoumn]="true"
  [showControlBar]=true [showNew]="false" multiSelect="true"
  [displayedColumns]="['ADD', 'name', 'description','creation', 'ACTIVE']" [showHeaderToolTips]=false></app-geofences>
</ng-container>
<ng-container *ngIf="showReferences">
  <header class="table-header" style="background: #A76900;">
    <h2>{{'impacted_ref' | translate}}</h2>


  </header>

  <table mat-table style="margin-left: 14px;" [dataSource]="dataSource">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
  
    <!-- Position Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.resourceName}} </td>
    </ng-container>
  
    <!-- Name Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef> Type </th>
      <td mat-cell *matCellDef="let element"> {{element.resourceType}} </td>
    </ng-container>
  
   
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
  </table>
  <dl>
    
    <dd style="margin-left: 14px; color:#A76900;">{{'note.mutability' | translate}}</dd>
    <dd style="margin-left: 780px;">
      <button class="btn btn-primary" (click)="confirmMutate(deviceTemplate.id)" >{{'confirm' | translate}}</button>
      <button style="margin-left: 12px;" class="btn btn-secondary" (click)="cancel()" >{{'cancel' | translate}}</button>
    </dd>
  </dl>
  
</ng-container>

   
    
  
    
    <!-- <ng-container *ngIf="geofenceData.length === 0">
      <div style="height: 30vh;">
        <br/>
      </div>
    </ng-container>
    <ng-container *ngIf="commandData.length === 0">
      <div style="height: 30vh;">
        <br/>
      </div>
    </ng-container>
    <ng-container *ngIf="eventData.length === 0">
      <div style="height: 30vh;">
        <br/>
      </div>
    </ng-container> -->
  </div>
  </article>
<!-- this is for check audit -->
<div style="height: 110vh;" >
                 
  <app-audits *ngIf="auditFlag && auditRefreshFlag && iotService.auditScriptFlag==false" [showNew]="false" [showControlBar]=false [displayedColumns]="['creation','requestorId','actionType','id']" showSelectColoumn=false showEnableColumn=false addnQueryParam=[resourceId::{{deviceTemplate.id}}] ></app-audits>
  <app-audits *ngIf="auditFlag && !auditRefreshFlag && iotService.auditScriptFlag==false" [showNew]="false" [showControlBar]=false [displayedColumns]="['creation','requestorId','actionType','id']" showSelectColoumn=false showEnableColumn=false addnQueryParam=[resourceId::{{deviceTemplate.id}}] ></app-audits>
  <div *ngIf="auditFlag && iotService.auditScriptFlag==false">
    <br>

    <button class="btn btn-secondary pull-right" style="margin-right:15px;" (click)="closeAudit()">{{ 'go.back' | translate}}</button>
    <br>
    <br>
</div>
    <br>
    <div  *ngIf="iotService.auditScriptFlag" style="margin: 0 15px 0 15px;">      
        <app-ace></app-ace>
   </div>
   <div *ngIf="iotService.auditScriptFlag" class="deviceAudit" style="margin-top: 10px;margin-right: 15px;">
    <button class="btn btn-secondary pull-right" (click)="goBackAuditList()">{{ 'go.back' | translate}}</button>
    <br>
    <br>
</div>
</div>
  <!-- this is for check audit -->

</mat-sidenav-container>
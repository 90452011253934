import { Component, OnInit } from '@angular/core';
import { IotService } from '../service/iot.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CreateStreamComponent } from '../create-stream/create-stream.component';
import { TranslateService } from '@ngx-translate/core';
import { StreamService } from '../service/stream.service';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { faCheckCircle, faExclamation, faExclamationTriangle, faInfoCircle, faTimes, faQuestionCircle, faCheck } from '@fortawesome/free-solid-svg-icons';
import { DocumentInfoService } from '../service/documentInfo.service';
import { SshKeyPairsService } from '../service/sshKeyPairs.service';
import { KeyBundlesService } from '../service/keyBundles.service';
import { OAuthTokensService } from '../service/oAuthTokens.service';
import { FromTPCodesService } from '../service/fromTPCodes.service';
import { ToTPCodesService } from '../service/toTPCodes.service';
import { ApplicationsService } from '../service/applications.service';
import { DigitalTwinsService } from '../service/digitalTwins.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-application',
  templateUrl: './create-application.component.html',
  styleUrls: ['./create-application.component.scss']
})
export class CreateApplicationComponent implements OnInit {

  separatorKeysCodes: number[] = [ENTER, COMMA];
  supportLanguages = ['en'];
  application;
  tags = [];
  tagList = [];
  tagClass = 'tagInput';
  constructor(private translateService: TranslateService, public iotService: IotService,
    public streamService: StreamService,public applicationService: ApplicationsService,
    private digitaltwinService: DigitalTwinsService,
    public documentInfoService: DocumentInfoService,
    public sshKeyService: SshKeyPairsService,
    public keyBundleService: KeyBundlesService,
    public oAuthTokenService: OAuthTokensService,
    public fromTpCodeService: FromTPCodesService,
    public toTpCodeService: ToTPCodesService,
    public sideNavComponent: SideNavComponent,
    public router:Router
    ) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }
  applicationNameInput;
  applicationNameSelect = false;
  createStreamFlag = false;
  faCheckIcon = faCheck;
  faInfoIcon = faInfoCircle;
  faCloseIcon = faTimes;
  faCriticalIcon = faExclamation;
  faWarningIcon = faExclamationTriangle;
  faSuccessIcon = faCheckCircle;
  applicationCreated = false;
  ngOnInit(): void {
    const that = this
    that.streamService.setDirection(undefined);
    that.streamService.setProtocolType(undefined);
    that.streamService.setProtocolSecurityType(undefined);
    that.applicationService.resetSuccessMessage();
    that.application = {

      "creator": that.iotService.getCreator(),
      "creatorAppId": that.iotService.getCreatorAppId(),
      "realm": that.iotService.getRealm(),
      'name': [{
        'lang': 'en_us',
        'text': ''
      }],
      'description': [{
        'lang': 'en_us',
        'text': ''
      }],
      'type': ''
    };
    this.sideNavComponent.menuClear();
    this.sideNavComponent.menuChange('Dashboard', 'sub-type');
    this.sideNavComponent.menuChange('applications.header', 'sub-type');
    this.sideNavComponent.menuChange('applications.create.new', 'sub-type');
  }

  applicationName(event: any) {
    const that = this;
    that.applicationNameInput = event.target.value;
    if (that.applicationNameInput.length > 0) {
      that.applicationNameSelect = true;
      that.application.name[0].text = that.applicationNameInput;
    }
    else {
      that.applicationNameSelect = false;
    }
  }

  applicationDescription(event: any) {
    const that = this;
    if(event.target.value != undefined){
      that.application.description[0].text = event.target.value;
    }
  }

  createApplication(event: any) {
    console.log("Create Application");
    
    const that = this;
    that.applicationCreated=true;

   
      that.iotService.getCuiObjResponse().createApplication({
        data: that.application
    }).then(function (response) {
      console.log("Application id"+response.id)
      that.applicationService.successMessage = "Application created successfully."
      that.streamService.setOwnerId(response.id);
      that.streamService.showListStream = true;
      that.iotService.sideNavStream = false;
   //   that.callingStreamComponent();
    }).fail(function (msg) {
      that.applicationCreated=false;
      that.applicationService.errorMessage=msg.responseJSON.apiMessage;
      console.log(msg.responseJSON.apiMessage);
    });
    
 
  }

  add(event: MatChipInputEvent): void {

    this.tagClass = 'tagInput';
    const input = event.input;
    const value = event.value;
    const index = this.tagList.indexOf(value);
    this.tagList.push(value);
    if (index < 0) {
      if ((value || '').trim()) {
        const that = this;
        that.tags.push(value);
        // Reset the input value
        if (input) {
          input.value = '';
        }
      }
    } else {
      this.tagClass = 'tagInput-duplicate';
    }
  }

  remove(tag: string): void {
    const that = this;
    const index = that.tags.indexOf(tag);
    if (index >= 0) {
      that.tags.splice(index, 1);
    }

  }

  ngOnDestroy(): void {
    const that=this;
    that.streamService.streamDocInfo=false;
    that.streamService.selectedDocInfos = false;
    // that.streamService.setStreamDocInfoDocType('');
    // that.streamService.setStreamDocInfoTemplate('');
  	// that.streamService.setStreamDocInfoVersion('');
    that.streamService.setDirection(undefined);
    that.streamService.setProtocolType(undefined);
    that.streamService.setProtocolSecurityType(undefined);
    that.streamService.setStreamDocInfoTemplate(undefined);
		that.streamService.setStreamDocInfoDocType(undefined);
		that.streamService.setStreamDocInfoVersion(undefined);
		that.streamService.setDocInfoId(undefined);
    that.streamService.setSSHKeyPair(undefined);
    that.streamService.setOAuthToken(undefined);
    that.streamService.setMutualAuth(undefined);
    that.iotService.sideNavStream = true;
    that.iotService.createRoute = true;

  }
}

<mat-sidenav-container class="new-route" autosize>

    <mat-sidenav class="midNav-container" #sidenav mode="side" disableClose="true" opened="true">

        <div class="alert critical" *ngIf="routeService.errorMessage">
            <h5>
                <fa-icon [icon]="faCriticalIcon"></fa-icon>
                <span>{{routeService.errorMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=routeService.resetErrorMessage() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>
        <div class="data">
            <h1> {{ 'create.route.info' | translate}}</h1>
            <mat-nav-list class="without-template user-selections">
                <!-- <ng-container *ngIf="typeChangeControl.value==1">
                    <div>
                        <h2>Event Groups</h2>
                        <p> Select Event Group from the Available Event Groups on the right.</p>
                    </div>
                </ng-container> -->
                <ng-container *ngIf="!routeService.getStream()">
                    <h2>{{'streams' | translate}}</h2>
                    <p> {{'select.stream.fromlist' | translate}}</p>
                </ng-container>
                <ng-container *ngIf="routeService.getStream()">
                    <h2>{{'streams' | translate}}</h2>
                    <ul>
                        <li>
                            <fa-icon [icon]="faCheckIcon"></fa-icon> {{routeService.getStream().name[0].text}}
                        </li>
                    </ul>
                </ng-container>
                <ng-container *ngIf="!routeService.getEventGroup() && viewValue=='EVENT'">
                    <h2>{{'eventGroups.header' | translate}}</h2>
                    <p> {{'select.eventGroup.fromlist' | translate}}</p>
                </ng-container>
                <ng-container *ngIf="routeService.getEventGroup()">
                    <h2>{{'eventGroups.header' | translate}}</h2>
                    <ul>
                        <li>
                            <fa-icon [icon]="faCheckIcon"></fa-icon> {{routeService.getEventGroup().name[0].text}}
                        </li>
                    </ul>
                </ng-container>
                <ng-container *ngIf="!routeService.getDevice() && viewValue=='COMMAND'">
                    <h2>{{'devices' | translate}}</h2>
                    <p> {{'select.device.fromlist' | translate}}</p>
                </ng-container>
                <ng-container *ngIf="routeService.getDevice()">
                    <h2>{{'devices' | translate}}</h2>
                    <ul>
                        <li>
                            <fa-icon [icon]="faCheckIcon"></fa-icon> {{routeService.getDevice().name[0].text}}
                        </li>
                    </ul>
                </ng-container>
                <ng-container *ngIf="!routeService.getRoutingSourceRelationship() && viewValue=='TP_RELATIONSHIP'">
                    <h2>{{'routingSourceRelationships.header' | translate}}</h2>
                    <p> {{'select.routingSourceRelationship' | translate}}</p>
                </ng-container>
                <ng-container *ngIf="routeService.getRoutingSourceRelationship()">
                    <h2>{{'routingSourceRelationships.header' | translate}}</h2>
                    <ul>
                        <li>
                            <fa-icon [icon]="faCheckIcon"></fa-icon>
                            {{routeService.getRoutingSourceRelationship().name[0].text}}
                        </li>
                    </ul>
                </ng-container>
                <ng-container *ngIf="!routeService.getDocumentInfo() && viewValue=='MESSAGE_INFO'">
                    <h2>{{'documentInfo' | translate}}</h2>
                    <p> {{'select.documentInfo.fromlist' | translate}}</p>
                </ng-container>
                <ng-container *ngIf="routeService.getDocumentInfo()">
                    <h2>{{'documentInfo' | translate}}</h2>
                    <ul>
                        <li>
                            <fa-icon [icon]="faCheckIcon"></fa-icon> {{routeService.getDocumentInfo().name[0].text}}
                        </li>
                    </ul>
                </ng-container>
                <!-- <hr> -->
                <!-- <button *ngIf="routeinfo.name[0].text"color="primary" (click)="save()">{{ 'save' | translate}}</button> -->
                <button [disabled]="routeinfo.name[0].text==='' || routeCreated" class="btn btn-primary" (click)="save()">{{ 'save' | translate}}</button>
            </mat-nav-list>
        </div>
    </mat-sidenav>

    <mat-sidenav-content class="example-sidenav-content">
        <div class="route-data">

            <div class="form-data">
                <label for="name"> {{ 'name' | translate}}</label>
                <input placeholder="required" id="route-name" type="text" aria-label="Name"
                    [(ngModel)]="routeinfo.name[0].text"><br>

                <label for="description">{{ 'description' | translate}}</label>
                <textarea rows="2" placeholder="optional" id="description" aria-label="description"
                    [(ngModel)]="routeinfo.description[0].text"></textarea><br>
                <label for="route-type">{{ 'route.type' | translate }}</label>
                <ng-container>
                    <select name="route-type" [(ngModel)]="viewValue" (ngModelChange)="onChange($event)">
                        <option [value]="item.viewValue" *ngFor="let item of routetypess">
                            {{item.viewValue}}
                        </option>
                    </select>
                </ng-container>
             
                <div>
                <p *ngIf="streamError && !routeService.getStream()" style="color: red;margin-left: 15px;">{{streamError}}</p>
                <!-- <p class="text-ride" *ngIf="streamError && !routeService.getStream()">{{streamError}}</p> -->
                <app-streams *ngIf="viewValue=='EVENT' " [pageSize]="5" [showControlBar]=true [showNew]="false"
                    [displayedColumns]="['ADD','name','protocolType','streamType','direction','creation','ACTIVE']" showSelectColoumn=true
                    showEnableColumn=true multiSelect="false" addnQueryParam=[streamType::APPLICATION] [showHeaderToolTips]=false>
                </app-streams>
                <app-streams *ngIf="viewValue=='COMMAND'" [pageSize]="5" [showControlBar]=true [showNew]="false"
                    [displayedColumns]="['ADD','name','protocolType','streamType','direction','creation','ACTIVE']" showSelectColoumn=true
                    showEnableColumn=true multiSelect="false" addnQueryParam=[streamType::DEVICE] [showHeaderToolTips]=false>
                </app-streams>
                <app-streams *ngIf="viewValue=='MESSAGE_INFO' || viewValue=='TP_RELATIONSHIP'" [pageSize]="5"
                    [showControlBar]=true [displayedColumns]="['ADD','name','protocolType','streamType','direction','creation','ACTIVE']"
                    showSelectColoumn=true showEnableColumn=true multiSelect="false" [showNew]="false" [showHeaderToolTips]=false>
                </app-streams>
                <p *ngIf="eventGroupError && !routeService.getEventGroup()" style="color: red;margin-left: 15px;">{{eventGroupError}}</p>
                <app-eventGroups *ngIf="viewValue=='EVENT'" [pageSize]="5" [showControlBar]=true
                    [displayedColumns]="['ADD','name','description','creation']" showSelectColoumn=true showEnableColumn=false
                    multiSelect="false" [showNew]="false" [showHeaderToolTips]=false>
                </app-eventGroups>
                <p *ngIf="deviceError && !routeService.getDevice()" style="color: red;margin-left: 15px;">{{deviceError}}</p>
                <app-digitalTwins *ngIf="viewValue=='COMMAND'" [pageSize]="5" [showSelectColoumn]=true
                    [showControlBar]=true [displayedColumns]="['ADD','name','description','parentDeviceTemplateName','creation','ACTIVE']"
                    multiSelect="false" [showNew]="false" [showHeaderToolTips]=false></app-digitalTwins>
                <p *ngIf="docInfoError && !routeService.getDocumentInfo()" style="color: red;margin-left: 15px;">{{docInfoError}}</p>
                <app-documentInfo *ngIf="viewValue=='MESSAGE_INFO'" [pageSize]="5" [showControlBar]=true
                    [displayedColumns]="['ADD','name','supportedDocTemplateType','docTemplateVersion','creation','ACTIVE']"
                    [showSelectColoumn]=true [showEnableColumn]=true multiSelect="false" [showNew]="false" [showHeaderToolTips]=false>
                </app-documentInfo>
                <p *ngIf="routingSourceRelError && !routeService.getRoutingSourceRelationship()" style="color: red;margin-left: 15px;">
                    {{routingSourceRelError}}</p>
                <app-routingSourceRelationships *ngIf="viewValue=='TP_RELATIONSHIP'" [pageSize]="5"
                    [showSelectColoumn]=true [showEnableColumn]=true [showActionColumn]=true multiSelect="false"
                    [showNew]="false"
                    [displayedColumns]="['ADD','name','fromCode', 'toCode', 'documentInfoName', 'senderOwnerName','ACTIVE']" [showHeaderToolTips]=false>
                </app-routingSourceRelationships>
                </div>
                <br>
                <br>

            </div>

            <!-- <app-streams *ngIf="viewValue=='EVENT' " [pageSize]="5" [showControlBar]=true [showNew]="false"
                [displayedColumns]="['ADD','name','protocolType','streamType','direction']" showSelectColoumn=true
                showEnableColumn=true multiSelect="false" addnQueryParam=[streamType::APPLICATION]>
            </app-streams>
            <app-streams *ngIf="viewValue=='COMMAND'" [pageSize]="5" [showControlBar]=true [showNew]="false"
                [displayedColumns]="['ADD','name','protocolType','streamType','direction']" showSelectColoumn=true
                showEnableColumn=true multiSelect="false" addnQueryParam=[streamType::DEVICE]>
            </app-streams>
            <app-streams *ngIf="viewValue=='MESSAGE_INFO' || viewValue=='TP_RELATIONSHIP'" [pageSize]="5"
                [showControlBar]=true [displayedColumns]="['ADD','name','protocolType','streamType','direction']"
                showSelectColoumn=true showEnableColumn=true multiSelect="false" [showNew]="false">
            </app-streams>
            <p *ngIf="eventGroupError && !routeService.getEventGroup()" style="color: red;">{{eventGroupError}}</p>
            <app-eventGroups *ngIf="viewValue=='EVENT'" [pageSize]="5" [showControlBar]=true
                [displayedColumns]="['ADD','name','description']" showSelectColoumn=true showEnableColumn=false
                multiSelect="false" [showNew]="false">
            </app-eventGroups>
            <p *ngIf="deviceError && !routeService.getDevice()" style="color: red;">{{deviceError}}</p>
            <app-digitalTwins *ngIf="viewValue=='COMMAND'" [pageSize]="5" [showSelectColoumn]=true
                [showControlBar]=true [displayedColumns]="['ADD','name','description','creation']"
                multiSelect="false" [showNew]="false"></app-digitalTwins>
            <p *ngIf="docInfoError && !routeService.getDocumentInfo()" style="color: red;">{{docInfoError}}</p>
            <app-documentInfo *ngIf="viewValue=='MESSAGE_INFO'" [pageSize]="5" [showControlBar]=true
                [displayedColumns]="['ADD','name','supportedDocTemplateType','docTemplateVersion','ACTIVE']"
                [showSelectColoumn]=true [showEnableColumn]=true multiSelect="false" [showNew]="false">
            </app-documentInfo>
            <p *ngIf="routingSourceRelError && !routeService.getRoutingSourceRelationship()" style="color: red;">
                {{routingSourceRelError}}</p>
            <app-routingSourceRelationships *ngIf="viewValue=='TP_RELATIONSHIP'" [pageSize]="5"
                [showSelectColoumn]=true [showEnableColumn]=true [showActionColumn]=true multiSelect="false"
                [showNew]="false"
                [displayedColumns]="['ADD','name','fromCode', 'toCode', 'documentInfoName', 'senderOwnerName','ACTIVE','ACTION']">
            </app-routingSourceRelationships> -->
        </div>
    </mat-sidenav-content>

</mat-sidenav-container>

import { Component, Inject, OnInit } from '@angular/core';
import { IotService } from '../service/iot.service';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { FormControl, FormGroup } from '@angular/forms';
import { ChartOptions, ChartType } from 'chart.js';
import { ChartsModule } from 'ng2-charts';
import 'chartjs-plugin-labels';
import { SingleDataSet, Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import { DatePipe } from '@angular/common';
import * as pluginLabels from "chartjs-plugin-labels";

//  import * as ChartPiece from '../../../node_modules/chart.piecelabel.js';
// import * as ChartPiece from '../../@node-modules/chart.piecelabel.js'


var totalData=0;



@Component({
  selector: 'app-perfmatrix',
  templateUrl: './perfmatrix.component.html',
  styleUrls: ['./perfmatrix.component.scss']
})
export class PerfmatrixComponent implements OnInit {
  startDateValue: any;
  processingStateValue: string;

  
  graphTypes = [
    { value: 1, viewValue: '98th Percentile' },
    { value: 2, viewValue: 'Processing Time' },
    { value: 3, viewValue: 'Total Messages' },
    { value: 4, viewValue: 'Message Tracking' }
  ];

 
  dateRanges= [
    { value: '3', viewValue: 'Last Hour' },
    { value: '6', viewValue: 'Last 2 Hours' },
    { value: '9', viewValue: 'Last 3 Hours' }
  ];

  constructor(public iotService: IotService, library: FaIconLibrary, public datepipe: DatePipe) {
    // this.form = new FormGroup({
    //   typeChange: this.typeChangeControl
    // });

    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }

  viewValue='Message Tracking';
  averageTime = [];
  totalMessageCount = [];
  creationTime = [];
  minValues = [];
  maxValues = [];
  pieChart_data = [];
  per98 = [];
  pieChartbackgroundColor=[];
  pieChartHoverbackgroundColor=[];
  pieChartborderColor=[];
  delivered: number = 0;
  failed: number = 0;
  awaitingPickup: number = 0;
  unkown:number=0;
  form: FormGroup;
  statistics = [];
  latest_date;
  breakpoint: number=2;
  public pieChartLabels: Label[] = [ ];
  isDataAvailable=true;
  trackingEnabled=false;
  processingState:any;
  startDate:any;
  showTrackingData=false;


  ngOnInit(): void {
    this.getStatistics();
    
  }

    
  onResize(event) {
    if(event.target.innerWidth >1100){
      this.breakpoint=2;
    } 
    else {
      this.breakpoint=1;
    }
    // console.log("width "+ event.target.innerWidth +"breakpoint "+this.breakpoint );
  }



  // dateRanges = [
  //   { value: '3', viewValue: 'Last Hour' },
  //   { value: '6', viewValue: 'Last 2 Hours' },
  //   { value: '9', viewValue: 'Last 3 Hours' }
  // ];

  typeChangeControl = 3;

  getStatistics() {
    this.reset();
    const that = this;
    this.iotService.getCuiObjResponse().searchIotStatistics({
      qs: [
        ['dateRangeFromUI', that.typeChangeControl]
      ]
    }).then(function (response) {
     
     
      that.statistics = response;
      that.statistics = that.statistics.reverse();
      // that.statistics=that.testData
      if (that.statistics.length > 1) {
        that.statistics.forEach(val => {
          if (val.timestamp != undefined) {
            that.latest_date = that.datepipe.transform(val.timestamp, 'h:mm a ');
              that.creationTime.push(that.latest_date);
          }
       
            if (val.average != undefined) {
              that.averageTime.push(Number(val.average));

            }
            if (val.messageCount != undefined) {
              that.totalMessageCount.push(Number(val.messageCount));
            }

            if (val.maxVal != undefined) {
              that.maxValues.push(Number(val.maxVal));
            }
            if (val.minVal != undefined) {
              that.minValues.push(Number(val.minVal));
            }
            if (val.per98 != undefined) {
              that.per98.push(Number(val.per98));
            }
          if (val.FAIL != undefined) {
            that.failed = Number(val.FAIL);
            totalData += Number(val.FAIL);
            that.pieChart_data.push(that.failed);
            that.pieChartLabels.push('Failed');
            that.pieChartbackgroundColor.push('#a7261b');
            that.pieChartborderColor.push('#a7261b');
            that.pieChartHoverbackgroundColor.push('#a7261b');
          }
          if (val.COMPLETE != undefined) {
            that.delivered = Number(val.COMPLETE);
            totalData += Number(val.COMPLETE);
            that.pieChart_data.push(that.delivered);
            that.pieChartLabels.push('Delivered');
            that.pieChartbackgroundColor.push('#006353');
            that.pieChartborderColor.push('#006353');
            that.pieChartHoverbackgroundColor.push('#006353');
            // console.log(that.delivered + " delivered")
          }

          if (val.AWAITING_PICKUP != undefined) {
            that.awaitingPickup = Number(val.AWAITING_PICKUP);
            totalData += Number(val.AWAITING_PICKUP);
            that.pieChart_data.push(that.awaitingPickup);
            that.pieChartLabels.push('Awaiting Pickup');
            that.pieChartbackgroundColor.push('rgb(44, 160, 44)');
            that.pieChartborderColor.push('rgb(44, 160, 44)');
            that.pieChartHoverbackgroundColor.push('rgb(44, 160, 44)');

          }
          if (val.UNKNOWN != undefined) {
            that.unkown = Number(val.UNKNOWN);
            totalData += Number(val.UNKNOWN);
            that.pieChart_data.push(that.unkown);
            that.pieChartLabels.push('Under Processing');
            that.pieChartbackgroundColor.push('#F6C600');
            that.pieChartborderColor.push('#F6C600');
            that.pieChartHoverbackgroundColor.push('#F6C600');

          }

        });

        // that.creationTime = that.creationTime.reverse();
        // that.averageTime = that.averageTime.reverse();
        // that.totalMessageCount = that.totalMessageCount.reverse();
        // that.maxValues = that.maxValues.reverse();
        // that.minValues = that.minValues.reverse();
        // that.per98 = that.per98.reverse();
        // console.log("total message count " + that.totalMessageCount + " " + that.totalMessageCount.length);


      }
      else {
        that.isDataAvailable=false;
        console.log("No Data Found");
        // that.openDialog();
      }
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      } 
    });
  }

  reset() {

    totalData=0;  
    this.averageTime.length = 0;
    this.totalMessageCount.length = 0;
    this.creationTime.length = 0;
    this.minValues.length = 0;
    this.maxValues.length = 0;
    this.pieChart_data.length = 0;
    this.per98.length = 0;
    this.delivered = 0;
    this.failed = 0;
    this.awaitingPickup = 0;
    this.pieChartLabels.length=0;
    this.pieChartbackgroundColor.length=0;
    this.pieChartHoverbackgroundColor.length=0;
    this.pieChartborderColor.length=0;
    this.isDataAvailable=true;
  }

  //chart for totalmessage count
  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      fontColor:"black",
      position: 'bottom',
      labels: {
        boxWidth:20,
        fontSize:15,
        fontColor:'black'
      }
    },plugins: {
      labels: false
    },
    tooltips: {
      cornerRadius:0,
      backgroundColor: 'rgba(255,255,255,0.9)',
      bodyFontColor: 'black',
      borderColor: '#999',
      borderWidth: 1,
      caretSize:0,
      titleFontColor: '#999',
      titleMarginBottom: 10,
      xPadding: 15,
      yPadding: 15,
      mode: 'index',
      intersect: false
  },
    scales: {
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Avg time in MS',
          fontColor:"black",
        },
        gridLines: {
          color: "black",
          drawOnChartArea: false,
          zeroLineColor:"black"
        },
        ticks: {
         beginAtZero: true,
         fontColor:"black"
       }
      }],
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Hour',
          fontColor:"black",
          padding:0
        },
        gridLines: {
          color: "black",
          drawOnChartArea: false,
          zeroLineColor:"black"
        },
        ticks: {
         beginAtZero: true,
         fontColor:"black",
         stepSize: 1,
         min: 0,
         maxRotation: 0,
         minRotation: 0,
         autoSkip:false,
         callback: function(label) {
          if (/\s/.test(label)) {
            return label.split(" ");
          }else{
            return label;
          }              
        }
       }
      }]
    }
  };

  public mbarChartLabels: string[] = this.creationTime;
  public barChartType: string = 'bar';
  public barChartLegend: boolean = true;

  public barChartColors: Array<any> = [
    {
      backgroundColor: '#007599',
      borderColor: 'rgba(105,159,177,1)',
      hoverBackgroundColor: 'rgb(132, 200, 223)',
      pointBackgroundColor: 'rgba(105,159,177,1)',
      pointBorderColor: '#fafafa',
      pointHoverBackgroundColor: '#fafafa',
      pointHoverBorderColor: 'rgba(105,159,177)'
    }
  ];


  public barChartData: any[] = [
    { data: this.totalMessageCount, label: 'TotalMessages' }
  ];




  //chart for processing time
  public barChartOptions2: any = {
    scaleShowVerticalLines: false,
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      fontColor:"black",
      position: 'bottom',
      labels: {
        boxWidth:20,
        fontSize:15,
        fontColor:'black'
      }
    },plugins: {
      labels: false
    },
    tooltips: {
      cornerRadius:0,
      backgroundColor: 'rgba(255,255,255,0.9)',
      bodyFontColor: 'black',
      borderColor: '#999',
      borderWidth: 1,
      caretSize:0,
      titleFontColor: '#999',
      titleMarginBottom: 10,
      xPadding: 15,
      yPadding: 15,
      mode: 'index',
      intersect: false
  },
    scales: {
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Avg time in MS',
          fontColor:"black",
        },
        gridLines: {
          color: "black",
          drawOnChartArea: false,
          zeroLineColor:"black"
        },
        ticks: {
         beginAtZero: true,
         fontColor:"black"
       }
      }],
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Hour',
          fontColor:"black",
          padding:0
        },
        gridLines: {
          color: "black",
          drawOnChartArea: false,
          zeroLineColor:"black"
        },
        ticks: {
         beginAtZero: true,
         fontColor:"black",
         stepSize: 1,
         min: 0,
         maxRotation: 0,
         minRotation: 0,
         autoSkip:false,
         callback: function(label) {
          if (/\s/.test(label)) {
            return label.split(" ");
          }else{
            return label;
          }              
        }
       }
      }]
    }
  };

  public mbarChartLabels2: string[] = this.creationTime;
  public barChartType2: string = 'bar';
  public barChartLegend2: boolean = true;

  public barChartColors2: Array<any> = [
    {
      backgroundColor: '#007599',
      borderColor: 'rgba(105,159,177,1)',
      hoverBackgroundColor: 'rgb(132, 200, 223)',
      pointBackgroundColor: 'rgba(105,159,177,1)',
      pointBorderColor: '#fafafa',
      pointHoverBackgroundColor: '#fafafa',
      pointHoverBorderColor: 'rgba(105,159,177)'
    }
  ];
  public barChartData2: any[] = [
    { data: this.averageTime, label: 'ProcessingTime' }
  ];



  //chart for delivery state
  public pieChartOptions: any = {
    segmentShowStroke: false,
    scaleShowVerticalLines: false,
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      cornerRadius:0,
      backgroundColor: 'rgba(255,255,255,0.9)',
      bodyFontColor: 'black',
      borderColor: '#999',
      borderWidth: 1,
        callbacks: {
          label: function(tooltipItem, data) {
            //get the concerned dataset
            var dataset = data.datasets[tooltipItem.datasetIndex];
            //calculate the total of this data set
            var total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
              return previousValue + currentValue;
            });
            //get the current items value
            var currentValue = dataset.data[tooltipItem.index];
            //calculate the precentage based on the total and current item, also this does a rough rounding to give a whole number
            var percentage =(currentValue/total) * 100;
            var value=Math.round(percentage*10)/10;
      
            return value + "%";
          }
        }
  },
      
  // pieceLabel: {
  //   render: function (args) {
  //     console.log(args+"args");
    
  //     const label = args.label,
  //           value = args.value;
  //           var percentage =(value/totalData) * 100;
  //           var data=Math.round(percentage*10)/10;
      
  //           return data + "%";
            
            
  //   },
  //   fontColor:'white'
  // },

    // legend: {
    //   position: 'bottom',
      
    //   labels: {      
    //     boxWidth:15,
    //     fontColor:'black',
    //     // padding:30
    //   }
    // },
    legend: {
      position: 'bottom',
      display: true,
      onClick: null,
      
      
      labels: {      
        boxWidth:20,
        fontSize:15,
        // data:10,
        fontColor:'black',
        padding:10          
      }
    },
    plugins: {
      labels: {
        render: "percentage",
        fontSize:20,
        responsive: true,
        fontColor: ["white", "white", "white"],
        precision: 1,
        overlap: false,
      }
    }
    
  };

  // public pieChartPlugins = [{
  //    function (chart) {
  //     chart.legend.legendItems.forEach(
  //       (label) => {
  //         let value = chart.data.datasets[0].data[label.index];
  //         if(value===undefined){
  //           this.pieChartLabels.delete(label);
  //         return label;
  //         }
  //       }
  //     )
  //   }
  // }];

  public pieChartColors: Array<any> = [{
    backgroundColor:  this.pieChartbackgroundColor,
    
    // backgroundColor: ['#a7261b', '#006353', 'rgb(44, 160, 44)','#F6C600',],
    hoverBackgroundColor:  this.pieChartHoverbackgroundColor,
    borderWidth: [0, 0, 0,0],
    hoverBorderWidth:[5,5,5,5],
    hoverBorderColor: this.pieChartborderColor

  }];

  pluginLabels2=pluginLabels;

  public pieChartData: SingleDataSet = this.pieChart_data;
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  // public pieChartPlugins = [pluginLabels];




  //min max  98th percentile
  public barChartOptions3: any = {
    scaleShowVerticalLines: false,
    segmentShowStroke: false,
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      fontColor:"black",
      position: 'bottom',
      labels: {
        boxWidth:20,
        fontSize:15,
        fontColor:'black'
      }
    },plugins: {
      labels: false
    },tooltips: {
      cornerRadius:0,
      backgroundColor: 'rgba(255,255,255,0.9)',
      bodyFontColor: 'black',
      borderColor: '#999',
      borderWidth: 1,
      caretSize:0,
      titleFontColor: '#999',
      titleMarginBottom: 10,
      xPadding: 15,
      yPadding: 15,
      mode: 'index',
      intersect: false
  },
    scales: {
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Avg time in MS',
          fontColor:"black"
        },
        gridLines: {
          color: "black",
          drawOnChartArea: false,
          zeroLineColor:"black"
        },
        ticks: {
         beginAtZero: true,
         fontColor:"black"
       }
      }],
      xAxes: [{
        // barPercentage: 1.0,
        scaleLabel: {
          display: true,
          labelString: 'Hour',
          fontColor:"black",
          categoryPercentage: 0,
          padding:0
        },
        gridLines: {
          color: "black",
          drawOnChartArea: false,
          zeroLineColor:"black"
        },
        ticks: {
         beginAtZero: true,
         fontColor:"black",
         stepSize: 2,
         min: 0,
         precision:2,
         maxRotation: 0,
         minRotation: 0,
         autoSkip:false,
         callback: function(label) {
          if (/\s/.test(label)) {
            return label.split(" ");
          }else{
            return label;
          }              
        }
       }
      }]
    }
  };


  public mbarChartLabels3: string[] = this.creationTime;
  public barChartType3: string = 'bar';
  public barChartLegend3: boolean = true;

  public barChartColors3: Array<any> = [
    {
      backgroundColor: '#007599',
      borderColor: '#007599',
      pointBackgroundColor: 'rgba(105,159,177,1)',
      pointBorderColor: '#fafafa',
      pointHoverBackgroundColor: '#fafafa',
      pointHoverBorderColor: 'rgba(105,159,177)',
      borderWidth: 0
    },
    {
      backgroundColor: '#df3324',
      borderColor: '#df3324',
      pointBackgroundColor: 'rgba(105,159,177,1)',
      pointBorderColor: '#fafafa',
      pointHoverBackgroundColor: '#fafafa',
      pointHoverBorderColor: 'rgba(105,159,177)',
      borderWidth: 0
    },
    {
      backgroundColor: '#006353',
      borderColor: '#006353',
      pointBackgroundColor: 'rgba(105,159,177,1)',
      pointBorderColor: '#fafafa',
      pointHoverBackgroundColor: '#fafafa',
      pointHoverBorderColor: 'rgba(105,159,177)',
      borderWidth: 0
    }

  ];


  public barChartData3: any[] = [
    { data: this.minValues, label: 'Min' , barPercentage: 1.0 },
    { data: this.maxValues, label: 'Max' , barPercentage: 1.0 },
    { data: this.per98, label: '98thPer' , barPercentage: 1.0 }
  ];


    // events
    public chartClicked(e: any): void {
      // this.trackingEnabled=false;
      console.log("state "+e.active[0]._view.label+ " value "+Number(this.typeChangeControl)/3);
      this.processingState=e.active[0]._view.label;
      if(this.processingState==='Delivered'){
        this.processingStateValue='COMPLETE';
      }
      else if(this.processingState==='Failed'){
        this.processingStateValue='FAIL';
      }
      else if(this.processingState==='Awaiting Pickup'){
        this.processingStateValue='AWAITING_PICKUP';
      }
      else if(this.processingState==='Under Processing'){
        this.processingStateValue='UNKNOWN';
      }
      this.startDate=this.typeChangeControl;
      this.startDateValue=Number(this.typeChangeControl)/3;
      this.showTracking();

    }

    showTracking(){

      this.trackingEnabled=!this.trackingEnabled;
      this.showTrackingData=true;
      if(this.showTrackingData){
        // window.scrollTo(0, 500); 
        
        setTimeout(() => {  
          console.log('scroll');
          document.getElementById('tracking').scrollIntoView({behavior: "smooth"});
        }, 250);
      }
    }
   

    closeTracking(){
      this.showTrackingData=false;
    }


}




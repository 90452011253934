<mat-sidenav-container class="mid-sidenav" autosize>
    <mat-sidenav class="midNav-container" #sidenav mode="side" disableClose="true" opened="true" scrollable="true">
        <div class="alert critical" *ngIf="tpService.errorMessage">
            <h5 style="font-size: 12px;">
                <fa-icon [icon]="faCriticalIcon"></fa-icon>
                <span>{{tpService.errorMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=tpService.resetMessage() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>

        </div>

        <div class="alert warning" *ngIf="tpService.warningMessage">
            <h5 style="font-size: 12px;">
                <fa-icon [icon]="faWarningIcon"></fa-icon>
                <span>{{tpService.warningMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)="tpService.resetMessage()" [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>
        <h1>{{'create.trading.partner' | translate}}</h1>
        <p>{{'tradingPartners.definition' | translate}}</p>

    </mat-sidenav>
    <mat-sidenav-content class="example-sidenav-content">
        <div class="tradingPartnerData" *ngIf="tpService.createResource">
            <div class=form>
                <label for="name"> {{ 'name' | translate}}</label>
                <input placeholder="required" [(ngModel)]="tradingPartner.name[0].text" id="name" type="text" aria-label="Name">

                <label for="description"> {{ 'description' | translate}}</label>
                <textarea *ngIf="tradingPartner.description" rows="2" [(ngModel)]="tradingPartner.description[0].text" placeholder="optional" id="description" aria-label="Description"></textarea>
                <textarea *ngIf="!tradingPartner.description" rows="2" value="-" placeholder="optional" id="description" aria-label="Description"></textarea>


                <div class="tags">

                    <label for="device-tags">{{ 'tags' | translate}}</label>
                    <mat-chip-list #tagList aria-label="tags">

                        <mat-chip class="ot-chip" *ngFor="let tag of tags" [selectable]="true" [removable]=true (removed)="remove(tag)">
                            {{tag}}
                            <button class="ot-chip__close" matChipRemove></button>
                        </mat-chip>
                        <input style="width: 50%;" [ngClass]="tagClass" placeholder="add a tag" #tagInput [matChipInputFor]="tagList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="add($event)">

                    </mat-chip-list>
                </div>


                <div *ngIf="tradingPartnerCreated">
                    <label>{{ 'Status' | translate}}</label>
                    <div *ngIf="tradingPartner.status=='ACTIVE'">
                        <button class="toggle-on toggle-switch" (click)="toggleActive('INACTIVE')">
                            <fa-icon [icon]="faCheckIcon" class="fa-2x"></fa-icon>
                            <div class="toggle-on-btn"></div>
                        </button>
                    </div>

                    <div *ngIf="tradingPartner.status=='INACTIVE'">
                        <button class="toggle-off toggle-switch" (click)="toggleActive('ACTIVE')">
                            <div class="toggle-off-btn"></div>
                        </button>
                    </div>
                </div>
                <!-- <button *ngIf="tradingPartner.name[0].text && !tradingPartnerCreated" class="save" mat-raised-button color="primary" (click)="save()">{{ 'save' | translate}}</button> -->
                <button *ngIf="!tradingPartnerCreated" [disabled]="tradingPartner.name[0].text==='' || newTradingPartnerCreated" class="save" mat-raised-button color="primary" (click)="save()">{{ 'save' | translate}}</button>
                <!-- <button *ngIf="tradingPartnerCreated" class="delete" mat-raised-button color="primary" (click)="delete()">{{ 'delete' | translate}}</button> -->

                <br>
            </div>
            <ng-container *ngIf="tradingPartnerCreated">
                <app-tradingPartnerCodes [pageSize]="5" [showSearch]=true [showSelectColoumn]=true [showEnableColumn]=true [showActionColumn]=true [displayedColumns]="['code','qualifier', 'ACTIVE','ACTION']" addnQueryParam=[tradingPartnerId::{{tradingPartner.id}}] [newWithRoute]=false>
                </app-tradingPartnerCodes>
                <app-codeRelationships [pageSize]="5" [showSelectColoumn]=true [showEnableColumn]=true [showActionColumn]=true [displayedColumns]="['fromCode','toCode','controlNumber','description', 'ACTIVE','ACTION']" addnQueryParam=[tradingPartnerId::{{tradingPartner.id}}]
                    [newWithRoute]=false>
                </app-codeRelationships>
                <app-tradingPartnerRelationships [pageSize]="5" [showSelectColoumn]=true [showEnableColumn]=true [showActionColumn]=true [displayedColumns]="['requestorTradingPartnerName','approverTradingPartnerName', 'ACTIVE','ACTION']" addnQueryParam=[tradingPartnerId::{{tradingPartner.id}}]
                    [newWithRoute]=false>
                </app-tradingPartnerRelationships>
                <app-tpApplications [pageSize]="5" [showSelectColoumn]=true [showEnableColumn]=true [showActionColumn]=true [displayedColumns]="['name', 'ACTION']" addnQueryParam=[tradingPartnerId::{{tradingPartner.id}}] [newWithRoute]=false>
                </app-tpApplications>
                <app-routingSourceRelationships [pageSize]="5" [showSelectColoumn]=true [showEnableColumn]=true [showActionColumn]=true [displayedColumns]="['name','fromCode', 'toCode', 'documentInfoName', 'senderOwnerName','ACTIVE','ACTION']" addnQueryParam=[receiverOwnerId::{{tradingPartner.id}}]
                    [newWithRoute]=false>
                </app-routingSourceRelationships>
            </ng-container>


        </div>

        <div *ngIf="tpService.createTpCode">
            <app-new-trading-partner-code></app-new-trading-partner-code>
        </div>

        <div *ngIf="tpService.createCodeRelationShip">
            <app-new-code-relationships></app-new-code-relationships>
        </div>

        <div *ngIf="tpService.createTpRelationShip" class="tradingPartnerRelationships">
            <app-tradingPartners [pageSize]="5" [showSelectColoumn]=true [displayedColumns]="['ADD','name','description','ACTIVE']" [showSelectColoumn]=true [showEnableColumn]=true ></app-tradingPartners>
            <button class="floatRight" [disabled]="tpService.getTradingPartnersList().length==0" mat-raised-button color="primary" (click)="saveTpRelationship()">{{ 'save' | translate}}</button>
            <button class="goBack" mat-raised-button color="primary" (click)="goBack()">{{ 'go.back' | translate}}</button>
            <br>
            <br>
        </div>


        <div *ngIf="tpService.createApplication" class="tradingPartnerRelationships">
            <app-applications [pageSize]="5" [showSelectColoumn]=true [displayedColumns]="['ADD','name','description','creation']" [showSelectColoumn]=true ></app-applications>
            <button class="floatRight" [disabled]="tpService.getTpApplications()==undefined" mat-raised-button color="primary" (click)="saveTpApplication()">{{ 'save' | translate}}</button>
            <button class="goBack" mat-raised-button color="primary" (click)="goBackApplication()">{{ 'go.back' | translate}}</button>
            <br>
            <br>
        </div>

        <div *ngIf="tpService.createRouteSource">
            <app-new-route-resource-relationship></app-new-route-resource-relationship>
        </div>

        <div *ngIf="tpService.viewTPCode" style="height:115vh">
            <app-trading-partner-code-detail></app-trading-partner-code-detail>
        </div>

        <div *ngIf="tpService.viewCodeRelationShip" style="height:115vh">
            <app-code-relation-ship-detail></app-code-relation-ship-detail>
        </div>

        <div *ngIf="tpService.viewRouteSource" style="height:115vh">
            <app-route-relationship-detail></app-route-relationship-detail>
        </div>
    </mat-sidenav-content>

</mat-sidenav-container>
import { Component, OnInit } from '@angular/core';
import { faToggleOff, faToggleOn, faAngleDown, faAngleUp, faAngleLeft, faAngleRight, faCheck, faCopy, faLock, faLockOpen, faTimes, faExclamation, faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { IotService } from '../service/iot.service';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { StreamsService } from '../service/streams.service';
import { StreamService } from '../service/stream.service';
import { throwMatDuplicatedDrawerError } from '@angular/material/sidenav';
import { DocumentInfoService } from '../service/documentInfo.service';
import { SshKeyPairsService } from '../service/sshKeyPairs.service';
import { KeyBundlesService } from '../service/keyBundles.service';
import { OAuthTokensService } from '../service/oAuthTokens.service';
import { FromTPCodesService } from '../service/fromTPCodes.service';
import { ToTPCodesService } from '../service/toTPCodes.service';

@Component({
  selector: 'app-stream-detail',
  templateUrl: './stream-detail.component.html',
  styleUrls: ['./stream-detail.component.scss'], host: {
    '(document:click)': 'onClick($event)',
  }
})
export class StreamDetailComponent implements OnInit {

  faToggleOffIcon = faToggleOff;
  faToggleOnIcon = faToggleOn;
  faAngleLeftIcon = faAngleLeft;
  faAngleRightIcon = faAngleRight;
  faCheckIcon = faCheck;
  faCopyIcon = faCopy;
  faLockIcon = faLock;
  faUnLockIcon = faLockOpen;
  faCloseIcon = faTimes;
  faCriticalIcon = faExclamation;
  faWarningIcon = faExclamationTriangle;
  faSuccessIcon = faCheckCircle;
  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;
  supportLanguages = ['en'];
  breakpoint: number;
  streamId
  stream;
  streamActive
  icon = this.faToggleOnIcon;
  updateNameDesc: boolean = true;
  encryption = 'None';
  streamLockState = 'UNLOCKED';
  streamLockingInstanceId;
  applicationName;
  deviceName;
  docinfo: boolean = false;
  disableSecurity = false;
  auth;
  selecteddocInfoId;
  toggleSettingFlag = false;
  showLogLevel = false;
  compression = 'No';
  obfuscation = 'No'
  expiration = '0';
  throughput = 'Round Robin';
  strategy = '0';
  persistence = 'Yes';
  digest = 'No';
  resetPasswordResult = "";
  showCertificateExample = false;
  drsScript = false;
  updateStreamConfFlag;
  deliveryUrlEntered;
  deliveryUrlError;
  showUpdateDocInfoButton = true;
  streamDocInfo;
  syncAuthType = '';
  cronValue = '';
  successMsg = '';
  errorMsg = '';
  mutualAuthKeyBundle;
  mutualAuthLoaded: boolean = false;
  publicKeyName;
  publicKeyDesc;
  publicKeyText;
  publicKeyAuth;
  X509;
  sshName = '';
  sshNameValue = '';
  sshKeyPairId;
  sshLoaded = false;
  tokenLoaded = false;
  username: any;
  password: any;
  reverseCertChainOrder: any;
  tokenId: any;
  token: string;
  tokenError = '';
  usernameValue;
  passwordValue;
  auditFlag=false;
  auditRefreshFlag=true;
  doc = "Document information default";
  drsDescription='';

  docTypes = [
    {
      id: 1,
      doc: 'Document information default'
    },
    {
      id: 2,
      doc: 'Document auto discovery,provided by Sender'
    }
  ];

  logModes = [
    {
      id: 1,
      mode: 'DEBUG'
    },
    {
      id: 2,
      mode: 'ERROR'
    },
    {
      id: 3,
      mode: 'INFO'
    }
  ];
  logExpirations = [
    {
      id: 1,
      expiration: '0'
    },
    {
      id: 2,
      expiration: '1'
    },
    {
      id: 3,
      expiration: '5'
    },
    {
      id: 4,
      expiration: '15'
    }, {
      id: 5,
      expiration: '20'
    }
  ];
  parObfuscation = [
    {
      id: 1,
      obfuscation: 'Yes'
    },
    {
      id: 2,
      obfuscation: 'No'
    }
  ];
  parCompression = [
    {
      id: 1,
      compression: 'Yes'
    },
    {
      id: 2,
      compression: 'No'
    }
  ];
  throughputStratergy = [
    {
      id: 1,
      throughput: 'Round Robin'
    },
    {
      id: 2,
      throughput: 'By Stream'
    }
  ];
  ackStrategy = [
    {
      id: 1,
      strategy: '0'
    },
    {
      id: 2,
      strategy: '1'
    },
    {
      id: 3,
      strategy: '2'
    }
  ];
  parPersistence = [
    {
      id: 1,
      persistence: 'Yes'
    },
    {
      id: 2,
      persistence: 'No'
    }
  ];
  parDigest = [
    {
      id: 1,
      digest: 'Yes'
    },
    {
      id: 2,
      digest: 'No'
    }
  ];


  userNameError: string;
  passwordError: string;
  mutualAuthKeyBundleId: any;
  reverseChain: boolean;
  mutualAuthError: string;
  // doc: string;
  fromTpCodeLoaded: boolean=false;
  toTpCodeLoaded: boolean=false;
  fromTpCodeLoaded2:boolean=false;
  toTpCodeLoaded2:boolean=false;
  docInfoLoaded2:boolean=false;

  onClick(event) {
    this.resetPasswordResult = '';

  }


  constructor(private router: Router, private route: ActivatedRoute, private iotService: IotService,
    private location: Location, private sideNavComponent: SideNavComponent, private translateService: TranslateService,
    private streamService: StreamsService, private streamSer: StreamService,
    public documentInfoService: DocumentInfoService,
    public sshKeyService: SshKeyPairsService,
    public keyBundleService: KeyBundlesService,
    public oAuthTokenService: OAuthTokensService,
    public fromTpCodeService: FromTPCodesService,
    public toTpCodeService: ToTPCodesService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }
  authTypes = [

    {
      id: 1,
      auth: 'BASIC'
    },
    {
      id: 2,
      auth: 'X509'
    }
  ];
  ngOnInit() {
    this.streamService.resetErrorMessage();
    this.streamService.resetWarningMessage();
    this.streamService.resetSuccessMessage();
    this.streamSer.drsScriptFlag=false;
    this.streamSer.viewStreamFlag = true;
    this.iotService.createStream = true;
    this.auditFlag=false;
    this.iotService.auditScriptFlag = false;
    this.route.params.subscribe(params => {
      var streamId = params['id'];
      this.streamId = streamId;
      this.loadStream(streamId);
      this.getStreamLockState(streamId);
    

    });

  }

  getStreamCredentials() {

    return JSON.stringify(this.resetPasswordResult);
  }

  toggleExample() {
    this.showCertificateExample = !this.showCertificateExample;
  }
  fetchAuthTypes(stream) {
    if (this.stream.streamType == 'SYNC') {
      if (this.stream.direction == 'ACTIVE_SENDING') {
        this.authTypes = [

          {
            id: 1,
            auth: 'BASIC'
          },
          {
            id: 2,
            auth: 'OAuth Token'
          }
        ];
      } else if (this.stream.direction == 'ACTIVE_RECEIVING') {
        this.authTypes = [

          {
            id: 1,
            auth: 'BASIC'
          },
          {
            id: 2,
            auth: 'Mutual Auth'
          },
          {
            id: 3,
            auth: 'NONE'
          },
          {
            id: 4,
            auth: 'OAuth Token'
          }
        ];
      }
    }
    if (this.stream.direction == 'PASSIVE_SENDING') {
      this.authTypes = [

        {
          id: 1,
          auth: 'BASIC'
        },
        {
          id: 2,
          auth: 'Mutual Auth'
        },
        {
          id: 3,
          auth: 'OAuth Token'
        }
      ];
    }
    else if (this.stream.direction == 'ACTIVE_RECEIVING'&& this.stream.streamType!='MESSAGING') {
      this.authTypes = [

        {
          id: 1,
          auth: 'BASIC'
        },
        {
          id: 2,
          auth: 'Mutual Auth'
        },
        {
          id: 3,
          auth: 'NONE'
        },
        {
          id: 4,
          auth: 'OAuth Token'
        }
      ];
    }
    else if (this.stream.direction == 'ACTIVE_RECEIVING'&& this.stream.streamType=='MESSAGING') {
      this.authTypes = [

        {
          id: 1,
          auth: 'BASIC'
        },
        {
          id: 2,
          auth: 'Mutual Auth'
        },
        {
          id: 3,
          auth: 'OAuth Token'
        }
      ];
    }

    if (this.stream.protocolType == 'S3') {
      this.auth = 'S3 Credentials';
      this.authTypes = [
        {
          id: 1,
          auth: 'S3 Credentials'
        }
      ];
    } else if (this.stream.protocolType == 'AQ') {
      if (this.stream.direction == 'ACTIVE_RECEIVING') {
        this.authTypes = [
          {
            id: 1,
            auth: 'NONE'
          },
          {
            id: 1,
            auth: 'Mutual Auth'
          }
        ];
      }
      else if (this.stream.direction == 'PASSIVE_SENDING') {

        this.authTypes = [
          {
            id: 1,
            auth: 'NONE'
          }
        ];

      }
    } else if (this.stream.protocolType == 'FTP') {
      this.authTypes = [

        {
          id: 1,
          auth: 'BASIC'
        }
      ]
      if (this.stream.protocolSecurityType == 'PUBLICKEY') {
        this.auth = 'PUBLIC KEY'
        this.authTypes = [

          {
            id: 1,
            auth: 'BASIC'
          },
          {
            id: 2,
            auth: 'PUBLIC KEY'
          }
        ];
      }
    }
  }
  // onResize(event) {
  //   if (event.target.innerWidth <= 1200 && event.target.innerWidth > 650) {
  //     this.breakpoint = 2;
  //   }
  //   else if (event.target.innerWidth <= 650) {
  //     this.breakpoint = 1;
  //   }
  //   else {
  //     this.breakpoint = 4;
  //   }
  // }

  changeDocType() {
    if (this.doc == "Document information default") {
      this.docinfo=true;
      this.drsScript = false;
      this.streamSer.drsScriptFlag = false;
    } else {
      if(this.stream.documentRecognition){
        this.streamSer.drsScriptFlag = true;
        this.streamSer.setViewDRSScript(this.stream.documentRecognition.classScript)

      }
      this.docinfo=false;
      // this.streamSer.drsScriptFlag = true;
      this.drsScript = true;
      
      
    }
  }

  getCustomProtocol(){
    const that=this;
    
    if(that.stream.customProtocolType.includes('HTTPS')){
      if(that.stream.customProtocolType=='HTTPS-KAFKA'){
        return 'HTTPS Blast';
        
  
      }
      return 'HTTPS';
      

    }
    else if(that.stream.customProtocolType.includes('SSH')){
      return 'SSH';
    }else if(that.stream.customProtocolType.includes('SSL')){
      return 'SSL';
    }else if(that.stream.customProtocolType.includes('VPN')){
      return 'VPN';
    }
    return that.stream.customProtocolType;
  }

  loadStream(streamId) {
    const that = this;
    that.iotService.getCuiObjResponse().getStream({
      streamId: streamId
    }).then(function (response) {
     
      console.log(response);
      that.stream = response;

      that.sideNavComponent.menuClear();
      that.sideNavComponent.menuChange('Dashboard', 'sub-type');
      that.sideNavComponent.menuChange('streams.header', 'sub-type');
      that.sideNavComponent.menuChange(that.stream.name[0].text, 'sub-type');

     
      that.getStreamDocInfo(streamId);

      if (that.stream.documentRecognition&&that.stream.documentRecognition.classScript != "") {
        that.drsScript = true;
        that.doc="Document auto discovery,provided by Sender";
        that.drsScript=true;
        // that.streamSer.drsScriptFlag=true;
        that.streamSer.viewDRSFlag = true;
        that.streamSer.drsScriptFlag=true;
        that.streamSer.setViewDRSScript(that.stream.documentRecognition.classScript);
        that.drsDescription=that.stream.documentRecognition.description;
      }else{
        that.doc = "Document information default";
      }

      that.fetchAuthTypes(that.stream);
      that.auth = that.stream.protocolSecurityType;
      if (that.stream.protocolSecurityType == 'OAUTH2') {
        that.auth = 'OAuth Token';
      }
      if (that.stream.protocolSecurityType == 'MUAUTH') {
        that.auth = 'Mutual Auth';
      }
      if (that.stream.protocolSecurityType == 'PUBLICKEY') {
        that.auth = 'PUBLIC KEY';
      }
      if (that.stream.protocolSecurityType == 'S3') {
        that.auth = 'S3 Credentials';
      }
      
      if (that.stream.streamType == 'DEVICE') {
        that.getDevice(that.stream.ownerId)
      } else {
        that.getApplication(that.stream.ownerId);
      }
      if (that.stream.protocolSecurityAttributes != undefined && that.stream.protocolSecurityAttributes.length > 0) {
        that.mutualAuthKeyBundle = response.protocolSecurityAttributes[0].value;
        for (var k = 0; k < response.protocolSecurityAttributes.length; k++) {
          if (response.protocolSecurityAttributes[k].name == "publicKeyName") {
            that.publicKeyName = response.protocolSecurityAttributes[k].value;
          }
          if (response.protocolSecurityAttributes[k].name == "publicKeyDesc") {
            that.publicKeyDesc = response.protocolSecurityAttributes[k].value;
          }
          if (response.protocolSecurityAttributes[k].name == "publicKeyText") {
            that.publicKeyText = response.protocolSecurityAttributes[k].value;
          }
          if (response.protocolSecurityAttributes[k].name == "sshKeyPairId") {
            that.stream.protocolSecurityAttributes.sshKeyPairId = response.protocolSecurityAttributes[k].value;

          }
          if (response.protocolSecurityAttributes[k].name == "username") {
            that.sshNameValue = response.protocolSecurityAttributes[k].value;

          }
          if (response.protocolSecurityAttributes[k].name == "sshKeyPairId") {
            that.sshKeyPairId = response.protocolSecurityAttributes[k].value;
            that.loadSSHKeyPairs(that.sshKeyPairId);

          }
          if (response.protocolSecurityAttributes[k].name == "x509Pem") {
            that.X509 = response.protocolSecurityAttributes[k].value;

          }
          if (response.protocolSecurityAttributes[k].name == "authenticationToken") {
            that.tokenId = response.protocolSecurityAttributes[k].value;
            that.loadToken(that.tokenId);

          }
          if (response.protocolSecurityAttributes[k].name == "mutualAuthKeyBundle") {
            that.mutualAuthKeyBundleId = response.protocolSecurityAttributes[k].value;
            that.loadKeyBundle(that.mutualAuthKeyBundleId);

          }
        }
        that.publicKeyAuth = response.protocolSecurityAttributes[0].value;

      }
      that.calculateDirection();
      if (that.stream.deliveryConfig != undefined) {
        that.streamSer.showCodeExample = false;
        that.streamSer.setViewDeliveryScript(that.stream.deliveryConfig.deliveryScript);
      }
      //    that.getStreamLockState(response.id);

      if (response.encryptionAlgorithmAttributes != undefined) {


        response.encryptionAlgorithmAttributes.forEach((name, value) => {

          if (name.value == '256') {
            that.encryption = 'AES 256bit PSK';
          } else if (name.value == '128') {
            that.encryption = 'AES 128bit PSK';
          }
        });
      }

      //  that.getStreamDocInfo(that.stream.id);

    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  changeCodeExample() {
    this.streamSer.showCodeExample = false;
    if (this.streamSer.example != 'Code Example') {
      this.streamSer.showCodeExample = true;
    }
    this.streamSer.setExample(this.streamSer.example);
  }

  loadKeyBundle(keyBundleId) {
    const that = this;
    this.iotService.getCuiObjResponse().getKeyBundle({
      keyBundleId: keyBundleId
    }).then(function (response) {
      console.log(response);
      that.mutualAuthKeyBundle = response;
      that.streamSer.setMutualAuthObject(response);
      that.mutualAuthLoaded = true;
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }

  loadSSHKeyPairs(sshKeyPairId) {
    const that = this;
    that.iotService.getCuiObjResponse().getSSHKeyPair({
      sshKeyPairId: sshKeyPairId
    }).then(function (response) {
      console.log(response);
      that.streamSer.setSshKeyPairObject(response);
      that.sshLoaded = true;
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }


  loadToken(tokenId: any) {
    const that = this;
    that.iotService.getCuiObjResponse().getToken({
      tokenId: tokenId
    }).then(function (response) {
      console.log(response);
      that.token = response;
      that.streamSer.setOAuthTokenObject(response);
      that.tokenLoaded = true;
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }

  showDRS(event) {
    const that = this;
    if (event == true) {
      that.streamSer.drsScriptFlag=true;
      
      // that.streamSer.viewDRSFlag = true;
    } else {
      that.streamSer.drsScriptFlag=false;
      // that.streamSer.viewDRSFlag = false;

    }
  }

  reverseChaining(event) {
    if (event == true) {
      this.reverseChain = true;
    } else {
      this.reverseChain = true;
    }
  }

  getStreamLockState(streamId) {
    const that = this;
    that.iotService.getCuiObjResponse().getLockStateStream({
      streamId: streamId
    })
      .then(function (response) {
        if (response.lockState) {
          console.log("test" + response.lockState);
          that.streamLockState = response.lockState;
          that.streamLockingInstanceId = response.lockingInstanceId;
        }
      }).fail(function (response) {
        console.log("Unable to get the stream lock state");
      });
  }

  getApplication(ownerId) {
    const that = this;
    that.iotService.getCuiObjResponse().getApplication({
      applicationId: ownerId
    })
      .then(function (response) {
        that.applicationName = response.name[0].text;
      }).fail(function (response) {
        console.log("Unable to get the stream lock state");
      });
  }

  getDevice(ownerId) {
    const that = this;
    that.iotService.getCuiObjResponse().getDevice({
      deviceId: ownerId
    })
      .then(function (response) {
        that.deviceName = response.name[0].text;
      }).fail(function (response) {
        console.log("Unable to get the stream lock state");
      });
  }

  getApplicationDetail(applicationId) {
    this.router.navigateByUrl("/applications/" + applicationId);
  }

  getDeviceDetail(deviceId) {
    this.router.navigateByUrl("/devices/" + deviceId);
  }
  calculateDirection() {
    const that = this;
    console.log("in direction")
    if (that.stream.direction == 'ACTIVE_SENDING') {
      //  that.stream.direction = 'ACTIVE_SENDING';
      that.toggleSettingFlag = true;
      that.disableSecurity = true;
    } else if (that.stream.direction == 'ACTIVE_RECEIVING') {
      //  that.stream.direction = 'ACTIVE_RECEIVING';
      that.disableSecurity = false;
    } else if (that.stream.direction == 'PASSIVE_SENDING') {
      //  that.stream.direction = 'PASSIVE_SENDING';
      that.toggleSettingFlag = true;
      that.disableSecurity = false;
    } else if (that.stream.direction == 'PASSIVE_RECEIVING') {
      //  that.stream.direction = 'PASSIVE_RECEIVING';
      that.disableSecurity = true;
    } else {
      //   that.stream.direction = 'Both';
      that.toggleSettingFlag = true;
      that.disableSecurity = true;
    }
  }
  enableUpdate(event: any) {
    this.updateNameDesc = false;
  }
  getCurrentTime() {
    let date = new Date();
    let _utc = date.getUTCFullYear() + "-" + date.getUTCMonth() + "-" + date.getUTCDate() + "T" + date.getUTCHours() + ":" + date.getUTCMinutes() + ":" + date.getUTCSeconds() + "." + date.getUTCMilliseconds();
    return _utc;
  }

  lockStream(streamId: any) {
    const that = this;
    let data = {
      "dateTime": that.getCurrentTime(),
      "lockingInstanceId": "buildUI"
    }
    that.iotService.getCuiObjResponse().lockLockStateStream({
      streamId: streamId,
      data: data
    })
      .then(function (response) {
        that.streamLockState = 'LOCKED';
      }).fail(function (response) {
        console.log("Unable to LOCK stream");
      });
  }
  unlockStream(streamId: any) {
    const that = this;
    let data = {
      "dateTime": that.getCurrentTime(),
      "lockingInstanceId": "buildUI"
    }
    that.iotService.getCuiObjResponse().unlockLockStateStream({
      streamId: streamId,
      data: data
    })
      .then(function (response) {
        that.streamLockState = 'UNLOCKED';
      }).fail(function (response) {
        console.log("Unable to UNLOCK stream");
      });
  }

  deleteStream(streamId: any) {
    const that = this;
    that.iotService.getCuiObjResponse().deleteStream({
      streamId: streamId
    }).then(function (response) {
      console.log("deleted Stream");
      that.streamService.successMessage = "Stream deleted with id: " + streamId;
      that.router.navigateByUrl("/streams");
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.streamService.warningMessage = err.responseJSON;
        alert("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        alert("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  getStreamDocInfo(streamId: any) {
    const that = this;
    that.iotService.getCuiObjResponse().getStreamDocInfo({
      streamId: streamId
    }).then(function (response) {

      if (response.length > 0) {
        // that.doc = "Document information default";
        // that.docinfo=true;

        // that.drsScript = false;
        // that.drsScript=false;
        // // that.streamSer.drsScriptFlag=true;
        // that.streamSer.drsScriptFlag=false;


        let element = response[0];
        console.log(element.documentInfoId);
        that.getDocInfoObject(element.documentInfoId,1);
        that.streamDocInfo = element;
        if (element.senderCodeId) {
          that.loadFromCode(element.senderCodeId,1);
        }
        if (element.receiverCodeId) {
          that.loadToCode(element.receiverCodeId,1);
        }
        if(element.mapAttributeName){
          console.log(element.mapAttributeName[0])
          that.syncAuthType=element.mapAttributeName[0].value
        }
        if(element.responseMapping){
          if (element.responseMapping.senderCodeId) {
            that.loadFromCode(element.responseMapping.senderCodeId,2);
          }
          if (element.responseMapping.receiverCodeId) {
            that.loadToCode(element.responseMapping.receiverCodeId,2);
          }
          if (element.responseMapping.documentInfoId) {
            that.getDocInfoObject(element.responseMapping.documentInfoId,2);
          }
        }
        
      }
      else {
        if (that.stream.documentRecognition != undefined) {
          that.drsScript = true;
          that.doc="Document auto discovery,provided by Sender";
          that.drsScript=true;
          // that.streamSer.drsScriptFlag=true;
          that.streamSer.viewDRSFlag = true;
          that.streamSer.drsScriptFlag=true;
          that.streamSer.setViewDRSScript(that.stream.documentRecognition.classScript);
          that.drsDescription=that.stream.documentRecognition.description;
        }
        if(!that.drsScript){
          that.docinfo = true;
        }
        
        if (that.stream.streamType == 'MESSAGING') {
          that.fromTpCodeLoaded = true;
          that.toTpCodeLoaded = true;
        }
        else if(that.stream.streamType == 'SYNC'){
          that.fromTpCodeLoaded = true;
          that.toTpCodeLoaded = true;
          that.docInfoLoaded2=true;
          that.fromTpCodeLoaded2 = true;
          that.toTpCodeLoaded2 = true;
        }
      }

    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.streamService.warningMessage = err.responseJSON;
        alert("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        alert("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  loadFromCode(fromCode,code) {
    const that = this;
    that.iotService.getCuiObjResponse().getTradingPartnerCodeById({
      codeId: fromCode
    }).then(function (response) {
      if(code==1){
        that.streamSer.setFromCodeValue(response);
        that.fromTpCodeLoaded = true;
      }
      else{
        that.streamSer.setfromCodeValue2(response);
        that.fromTpCodeLoaded2 = true;
      }
     
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }

  loadToCode(toCode,code) {
    const that = this;
    that.iotService.getCuiObjResponse().getTradingPartnerCodeById({
      codeId: toCode
    }).then(function (response) {
      if(code==1){
        that.streamSer.setToCodeValue(response);
        that.toTpCodeLoaded = true;
      }
      else if(code==2){
        that.streamSer.setToCodeValue2(response);
        that.toTpCodeLoaded2 = true;
      }
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }

  clearSelection() {
    const that = this;
    that.streamSer.setDocInfoId(undefined);
    that.streamSer.setSSHKeyPair(undefined);
    that.streamSer.setOAuthToken(undefined);
    that.streamSer.setMutualAuth(undefined);
    that.documentInfoService.selection.clear();
    that.sshKeyService.selection.clear();
    that.keyBundleService.selection.clear();
    that.oAuthTokenService.selection.clear();
    // that.applicationService.selection.clear();
    // that.digitaltwinService.selection.clear();

  }


  updateDocumentInfo(streamId) {
    const that = this;
    if (that.streamDocInfo) {
      //delete existing stream docInfo
      that.iotService.getCuiObjResponse().deleteStreamDocInfo({
        streamId: that.streamDocInfo.streamId,
        documentInfoId: that.streamDocInfo.id
      }).then(function (response) {
        //then create new stream docInfo
        that.addStreamDocInfo(streamId);
      }).fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          that.streamService.errorMessage = err.responseJSON.apiMessage;
          alert("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiMessage);
        } else {
          alert("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    }
    else {
      that.addStreamDocInfo(streamId);
    }
    that.showUpdateDocInfoButton = true;

  }

  UpdateDRS(streamId){
    const that=this;
    that.streamService.warningMessage='';
    that.streamService.errorMessage='';
    that.streamService.successMessage='';
    that.stream.documentRecognition = {
      "description": that.drsDescription,
      "type": "JAVASCRIPT",
      "classScript": ""
  }
  that.stream.documentRecognition.classScript = that.streamSer.getDRSscriptBase64Encoded();

    if (that.stream.protocolSecurityAttributes != null) {
      that.stream.protocolSecurityAttributes.push({
          name: "password",
          value: "temp"
      })
  }
    that.iotService.getCuiObjResponse().updatedocumentRecognition({
      streamId: streamId,
      data:that.stream
    }).then(function (response) {
     that.streamService.successMessage='DRS updated successfully!'
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.streamService.warningMessage = err.responseJSON;
        alert("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        alert("Something went wrong. Please check chrome console or check with dev team");
      }
    });

  }

  deleteDRS(streamId){
    const that=this;
    that.streamService.warningMessage='';
    that.streamService.errorMessage='';
    that.streamService.successMessage='';
    if (that.stream.protocolSecurityAttributes != null) {
      that.stream.protocolSecurityAttributes.push({
          name: "password",
          value: "temp"
      })
  }
    that.iotService.getCuiObjResponse().deletedocumentRecognition({
      streamId: streamId
    }).then(function (response) {
     that.streamService.successMessage='DRS deleted successfully!';
     that.docinfo=true;
     that.drsScript=false;
     that.streamSer.drsScriptFlag=false;
     that.doc = "Document information default"

    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.streamService.warningMessage = err.responseJSON;
        alert("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        alert("Something went wrong. Please check chrome console or check with dev team");
      }
    });



  }

  updateSecurity(streamId) {
    const that = this;
    that.streamService.successMessage = '';
    that.streamService.warningMessage = '';
    that.streamService.errorMessage = '';
    // Auth Type

    if (!that.validateprotocolSecurityAttributes()) {
      that.stream.protocolSecurityAttributes = [];
      if (that.stream.protocolSecurityType == "BASIC") {

        that.stream.protocolSecurityAttributes.push({
          name: 'username',
          value: that.usernameValue
        })
        that.stream.protocolSecurityAttributes.push({
          name: 'password',
          value: that.passwordValue
        })
      }
      if (that.stream.protocolSecurityType == "S3") {

        that.stream.protocolSecurityAttributes.push({
          name: 'username',
          value: that.usernameValue
        })
        that.stream.protocolSecurityAttributes.push({
          name: 'password',
          value: that.passwordValue
        })
      }
      if (that.stream.protocolSecurityType == "OAUTH2") {
        that.stream.protocolSecurityAttributes.push({
          name: 'tokenId',
          value: that.streamSer.getOAuthTokenObject().id
        })

      }
      if (that.stream.protocolSecurityType == "CERTIFICATE") {
        that.stream.protocolSecurityAttributes.push({
          name: 'username',
          value: that.username
        })
        that.stream.protocolSecurityAttributes.push({
          name: 'password',
          value: that.password
        })
      }
      if (that.stream.protocolSecurityType == "MUAUTH") {
        that.stream.protocolSecurityAttributes.push({
          name: 'mutualAuthKeyBundle',
          value: that.streamSer.getMutualAuthObject().id
        })
        if (that.reverseChain) {
          that.stream.protocolSecurityAttributes.push({
            name: 'reverseCertChainOrder',
            value: 'true'
          })
        } else {
          that.stream.protocolSecurityAttributes.push({
            name: 'reverseCertChainOrder',
            value: 'false'
          })
        }
      }

      if (that.stream.protocolSecurityType == 'PUBLICKEY') {
        let name = that.sshName;
        if (that.sshName == undefined || that.sshName == '') {
          name = that.sshNameValue
        }
        that.stream.protocolSecurityAttributes.push({
          name: 'sshKeyPairId',
          value: that.streamSer.getSshKeyPairObject().id
        })
        that.stream.protocolSecurityAttributes.push({
          name: 'username',
          value: name
        })
      }
      that.stream.streamConfiguration.quota = parseInt(that.stream.streamConfiguration.quota);
      that.stream.ackStrategy = parseInt(that.stream.ackStrategy);
      that.iotService.getCuiObjResponse().updateStreamSecurityType({
        streamId: that.stream.id,
        data: that.stream
      })
        .then(function (response) {

          that.iotService.getCuiObjResponse().updateStreamSecurityAttributes({
            streamId: that.stream.id,
            data: that.stream
          })
            .then(function (response) {
              that.streamService.successMessage = "Successfully updated security settings";
            })
            .fail(function (err) {
              if (err.responseJSON.apiStatusCode) {
                that.streamService.warningMessage = err.responseJSON;
                alert("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
              } else {
                alert("Something went wrong. Please check chrome console or check with dev team");
              }
            });

        }).fail(function (err) {
          if (err.responseJSON.apiStatusCode) {
            that.streamService.warningMessage = err.responseJSON;
            alert("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
          } else {
            alert("Something went wrong. Please check chrome console or check with dev team");
          }
        });
    }
    else {
      that.streamService.warningMessage = "Cannot Update security settings please provide all necessary fields";
    }
  }

  addStreamDocInfo(streamId) {
    const that = this;
    let newStreamDoc;
    if (that.streamSer.getDocInfoId()) {
      newStreamDoc = {
        "creator": that.iotService.getCreator(),
        "creatorAppId": that.iotService.getCreatorAppId(),
        "realm": that.iotService.getRealm(),
        "documentInfoId": that.streamSer.getDocInfoId(),
        "streamId": streamId
      }
      if (that.stream.streamType == 'SYNC' && that.stream.protocolType == 'HTTPS') {
        newStreamDoc = {
          "creator": that.iotService.getCreator(),
          "creatorAppId": that.iotService.getCreatorAppId(),
          "realm": that.iotService.getRealm(),
          "documentInfoId": that.streamSer.getDocInfoId(),
          "streamId": streamId,
          "senderCodeId": '',
          "receiverCodeId": '',
          "mapKey": "EXTENDED_KEY",
          "mapAttributeName": [
            {
              "key": "syncRequestActionType",
              "value": that.syncAuthType
            }
          ],
          "responseMapping": {
            "senderCodeId": '',
            "receiverCodeId": '',
            "documentInfoId": ''
          }
        }
        if (that.streamSer.getFromCodeValue() && that.streamSer.getToCodeValue()) {
          newStreamDoc.senderCodeId = that.streamSer.getFromCodeValue().id,
            newStreamDoc.receiverCodeId = that.streamSer.getToCodeValue().id
        }
        if (that.streamSer.getDocInfo2()) {
          newStreamDoc.responseMapping.documentInfoId = that.streamSer.getDocInfo2().id;
        }
        if (that.streamSer.getfromCodeValue2() && that.streamSer.getToCodeValue2()) {
          newStreamDoc.responseMapping.senderCodeId = that.streamSer.getfromCodeValue2().id;
          newStreamDoc.responseMapping.receiverCodeId = that.streamSer.getToCodeValue2().id;

        }
      }

      if (that.stream.streamType == 'MESSAGING') {


        newStreamDoc = {
          "creator": that.iotService.getCreator(),
          "creatorAppId": that.iotService.getCreatorAppId(),
          "realm": that.iotService.getRealm(),
          "documentInfoId": that.streamSer.getDocInfoId(),
          "streamId": streamId,
          "senderCodeId": '',
          "receiverCodeId": ''
        }
        if (that.streamSer.getFromCodeValue() && that.streamSer.getToCodeValue()) {
            newStreamDoc.senderCodeId = that.streamSer.getFromCodeValue().id,
            newStreamDoc.receiverCodeId = that.streamSer.getToCodeValue().id
        }
      }
      that.iotService.getCuiObjResponse().createStreamDocInfo({
        'streamId': streamId,
        'data': newStreamDoc
      }).then(function (results) {
        that.streamDocInfo = results;
        that.streamService.successMessage = "Stream Document Info Updated successfully!";
      }).fail(function (err) {

        if (err.responseJSON.apiStatusCode) {
          that.streamService.warningMessage = err.responseJSON.apiMessage;
          console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiMessage);
        } else {
          that.streamService.errorMessage = "Error occured while creating docinfo"
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    }
    else {
      that.streamService.warningMessage = " Please select a new document to update stream document Info";
    }
  }

  updateDoc() {
    this.showUpdateDocInfoButton = !this.showUpdateDocInfoButton;
  }


  getDocInfoObject(docInfoId: any, docInfoNum) {
    const that = this;
    that.iotService.getCuiObjResponse().getDocumentInfoById({
      docInfoId: docInfoId
    }).then(function (response) {
      if(docInfoNum==1){
        that.streamSer.setDocinfoResponse(response);
        that.docinfo = true;
      }
      else if(docInfoNum==2){
        that.streamSer.setDocInfo2(response);
        that.docInfoLoaded2 = true;
      }
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.streamService.warningMessage = err.responseJSON;
        alert("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        alert("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  resetPassword(streamId: any) {
    const that = this;
    that.iotService.getCuiObjResponse().resetStreamPassword({
      streamId: streamId
    }).then(function (response) {
      that.stream = response;
      if (that.stream.protocolSecurityAttributes != undefined && that.stream.protocolSecurityAttributes.length > 0) {
        that.mutualAuthKeyBundle = response.protocolSecurityAttributes[0].value;
        for (var k = 0; k < response.protocolSecurityAttributes.length; k++) {
          if (response.protocolSecurityAttributes[k].name == "password") {
            that.resetPasswordResult = "New Password: " + response.protocolSecurityAttributes[k].value;
          }
        }


      }
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.streamService.warningMessage = err.responseJSON;
        alert("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        alert("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  logMode() {
    if (this.showLogLevel == false) {
      this.showLogLevel = true;
    } else {
      this.showLogLevel = false;
    }
  }

  updateStreamConf = function (streamId) {
    var errorMsg = null;

    const that = this;
    if (that.stream.customProtocolType == "none") {
      delete that.stream.customProtocolType;
    }

    if (that.stream.protocolSecurityType == 'BASIC') {
      //console.log("updateBasic")
      if (that.stream.protocolSecurityAttributes != undefined) {
      that.stream.protocolSecurityAttributes.push({
        name: "password",
        value: "dummyvalue"
      })
    }

    else{
      that.stream['protocolSecurityAttributes'] = [{
        name: "password",
        value: "dummyvalue"
    }]
    }
  }

    that.updateStreamConfFlag = true;
    that.stream.streamConfiguration.logExpiration = Date.now() + 60000 * parseInt(that.expiration);

    that.stream.streamConfiguration.quota = parseInt(that.stream.streamConfiguration.quota);
    that.stream.ackStrategy = parseInt(that.stream.ackStrategy);

    // if (that.stream.direction == 'ACTIVE_SENDING') {
    //   that.stream.direction = 'ACTIVE_SENDING';
    // } else if (that.stream.direction == 'ACTIVE_RECEIVING') {
    //   that.stream.direction = 'ACTIVE_RECEIVING';
    // } else if (that.stream.direction == 'PASSIVE_SENDING') {
    //   that.stream.direction = 'PASSIVE_SENDING';
    // } else if (that.stream.direction == 'PASSIVE_RECEIVING') {
    //   that.stream.direction = 'PASSIVE_RECEIVING';
    // } else {
    //   that.stream.direction = 'BOTH';
    // }
    // Update Stream Conf.
    that.iotService.getCuiObjResponse().updateStreamConf({

      streamId: streamId,
      data: that.stream
    })
      .then(function (response) {
        that.stream.streamConfiguration.quota = that.stream.streamConfiguration.quota;
        that.stream.ackStrategy = that.stream.ackStrategy.toString();
        that.streamService.successMessage = "Stream Configuration updated Successfully."
        that.streamService.errorMessage = "";
        that.updateStreamConfFlag = false;
      })
      .fail(function (response) {
        that.streamService.successMessage = "";
        that.streamService.errorMessage = response.responseJSON.apiMessage;
        that.updateStreamConfFlag = false;
        // that.saving = false;
      });



  }

  deliveryUrl(event: any) {
    const that = this;
    that.deliveryUrlEntered = that.stream.deliveryConfig.destinationURI;
    if (that.deliveryUrlEntered.length == 0) {
      that.deliveryUrlError = 'Please enter delivery url';
    }

  }
  updateDelivery(streamId) {
    const that = this;
    // Stream.deliveryScript = window.btoa($scope.deliveryScript);
    that.stream.deliveryConfig.deliveryScript = that.streamSer.getDeliveryScriptBase64Encoded();
    if (that.stream.protocolSecurityType == 'BASIC') {
      //console.log("updateBasic")
      if (that.stream.protocolSecurityAttributes != undefined) {
      that.stream.protocolSecurityAttributes.push({
        name: "password",
        value: "dummyvalue"
      })
    }

    else{
      that.stream['protocolSecurityAttributes'] = [{
        name: "password",
        value: "dummyvalue"
    }]
    }
  }

    that.iotService.getCuiObjResponse().updateStreamDeliveryConfig({
      streamId: streamId,
      data: that.stream
    })
      .then(function (response) {
        that.successMsg = "Delivery Config Updated Successfully.";
        that.streamService.successMessage = "Delivery Config Updated Successfully."

      })
      .fail(function (response) {
        that.errorMsg = response.responseJSON.apiMessage;

      });
  }

  updateDeliverySchedule(streamId) {
    this.updateDelivery(streamId);
  }
  deliveryEnabled(event) {
    if (event == true) {
      this.stream.deliveryConfig.deliveryEnabled = true;
    } else {
      this.stream.deliveryConfig.deliveryEnabled = false;
    }
  }
  ngOnDestroy() {
    this.streamSer.viewStreamFlag = false;
    this.streamSer.viewDRSFlag = false;
    this.auditFlag=false;
    this.iotService.auditScriptFlag = false;
    this.streamSer.setDocinfoResponse(undefined);
    this.streamSer.setSshKeyPairObject(undefined);
    this.streamSer.setMutualAuthObject(undefined);
    this.streamSer.setOAuthTokenObject(undefined);
    this.streamSer.setFromCodeValue(undefined);
    this.streamSer.setToCodeValue(undefined);
    this.iotService.createStream = false;
  }

  resetUsername(event: any) {
    this.userNameError = '';
  }

  resetPasswordData(event: any) {
    this.passwordError = '';
  }

  changeAuthType() {
    const that = this;

    that.stream.protocolSecurityType = that.auth;

    if (that.auth == 'PUBLIC KEY') {
      that.stream.protocolSecurityType = 'PUBLICKEY';
      this.sshLoaded = true;
    }
    if (that.auth == 'OAuth Token') {
      that.stream.protocolSecurityType = 'OAUTH2';
      this.tokenLoaded = true;
    }
    if (that.auth == 'Mutual Auth') {
      that.stream.protocolSecurityType = 'MUAUTH';
      this.mutualAuthLoaded = true;
    }

  }

  validateprotocolSecurityAttributes() {
    const that = this;
    let hasError = false;
    if (that.stream.protocolSecurityType == "BASIC") {
      if (that.usernameValue == "" || that.usernameValue == undefined) {
        hasError = true;
        that.userNameError = "Please provide username"
      };
      if (that.passwordValue == "" || that.passwordValue == undefined) {
        hasError = true;
        that.passwordError = "Please provide password";
      }
    }
    if (that.stream.protocolSecurityType == "S3") {
      if (that.usernameValue == "" || that.usernameValue == undefined) {
        hasError = true;
        that.userNameError = "Please provide username"
      };
      if (that.passwordValue == "" || that.passwordValue == undefined) {
        hasError = true;
        that.passwordError = "Please provide password";
      }
    }
    if (that.stream.protocolSecurityType == "OAUTH2") {
      if (that.streamSer.getOAuthTokenObject() == undefined) {
        hasError = true;
        that.tokenError = 'Please select a token';
      };
    }
    if (that.stream.protocolSecurityType == "MUAUTH") {
      if (that.streamSer.getMutualAuthObject() == undefined) {
        hasError = true;
        that.mutualAuthError = 'Please select a keybundle';
      }
    }
    if (that.stream.protocolSecurityType == "PUBLICKEY") {
      if (that.streamSer.getSshKeyPairObject() == undefined) {
        hasError = true;
      }
    }
    return hasError;
  }

  auditStream(){
 
    this.auditFlag=true;
    this.auditRefreshFlag=!this.auditRefreshFlag;
     
     
  }
  closeAudit(){
    this.auditFlag=false;
  }

  goBackAuditList() {
    const that=this;
    that.iotService.auditScriptFlag = false;
    that.iotService.auditscript="";
    that.auditFlag = true;
  }

}


<article class="styleguide">
  <h1>Styleguide</h1>
  <p>This is a reference for UI elements, providing global classes to use in your component. This is a work in progress and may not always be up to date. Please contact <a href="mailto:calo@opentext.com?subject=Styleguide%20questions">Carlito</a> with any questions or requests.</p>
  <p>Also, <a href="https://334523.axshare.com/#id=vn2ttt&p=login">check the wireframe</a> for (somewhat) up to date versions of pages.</p>
 
  <mat-accordion>

    <mat-expansion-panel (opened)="panelOpenState = true"
    (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Buttons
        </mat-panel-title>
        <!-- <mat-panel-description>
          This is a summary of the content
        </mat-panel-description> -->
      </mat-expansion-panel-header>
      <p>These are the various button states and classes. Only the classes needed to be added in the HTML of the component. There shouldn't be any button style definitions in the SCSS except for rare occasions when global styles need to be overridden.</p>
      <p>Do not add the class "mat-raised-button" or the color parameter 'color="primary"'</p>

      <button class="btn btn-primary" title="Action to be performed">Primary</button>
      <p class="btn-instruction"> used for key actions like Save</p>
      <code class="btn-code">
        &lt;button class="btn btn-primary" title="Action to be performed"&gt;Primary&lt;/button&gt;
      </code>

      <button class="btn btn-primary" disabled title="Disabled">Primary Disabled</button>
      <code class="btn-code">
        &lt;button class="btn btn-primary" disabled title="Disabled"&gt;Primary Disabled&lt;/button&gt;
      </code>

      <button class="btn btn-secondary" title="Action to be performed">Secondary</button>
      <p class="btn-instruction"> used for all other actions besides Save</p>
      <code class="btn-code">
        &lt;button class="btn btn-secondary" title="Action to be performed"&gt;Secondary&lt;/button&gt;
      </code>

      <button class="btn btn-secondary" disabled title="Disabled">Secondary Disabled</button>
      <code class="btn-code">
        &lt;button class="btn btn-secondary" disabled title="Disabled">Secondary Disabled&lt;/button&gt;
      </code>

      <button class="btn btn-micro btn-primary" title="Action to be performed">Micro</button>
      <p class="btn-instruction"> used for all New buttons in the control-bar above tables.</p>
      <code class="btn-code">
        &lt;button class="btn btn-micro btn-primary" title="Action to be performed"&gt;Secondary&lt;/button&gt;
      </code>

      <button class="btn btn-micro btn-secondary" title="Action to be performed">Micro</button>
      <p class="btn-instruction"> used for all Select, Add buttons in the control-bar above tables.</p>
      <code class="btn-code">
        &lt;button class="btn btn-micro btn-secondary" title="Action to be performed"&gt;Secondary&lt;/button&gt;
      </code>

      <button class="btn btn-delete" title="Delete">Delete</button>
      <code class="btn-code">
        &lt;button class="btn btn-delete" disabled title="Delete">Delete&lt;/button&gt;
      </code>

    </mat-expansion-panel>

    <mat-expansion-panel (opened)="panelOpenState = true"
    (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Fonts
        </mat-panel-title>
        <!-- <mat-panel-description>
          This is a summary of the content
        </mat-panel-description> -->
      </mat-expansion-panel-header>
      <h2>Fonts</h2>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState = true"
      (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Icons
          </mat-panel-title>
          <!-- <mat-panel-description>
            This is a summary of the content
          </mat-panel-description> -->
        </mat-expansion-panel-header>
        <h2>Icons</h2>

    </mat-expansion-panel>

    <mat-expansion-panel (opened)="panelOpenState = true"
                         (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Tables
        </mat-panel-title>
        <!-- <mat-panel-description>
          Currently I am {{panelOpenState ? 'open' : 'closed'}}
        </mat-panel-description> -->
      </mat-expansion-panel-header>
      
        <p>These are the elements used in constructing a table.</p>
        <p>The header has class="table-header" and is styled along with the h2 it contains by styles.scss.</p>
        <p>The div with class="control-bar" will contain a search box if it's a standard list table. There may also be a button for creating new things. When present, give the button class="btn btn-primary" and provide an aria-label with text describing the action.</p>
        <p>The table itself should be encapsulated by a div with class="table-container". This div allows the margins to be controlled from styles.scss.</p>
        <p>If pagination is required for the table, include that in anothe div with class="table-container"</p>
        <pre>
          <code [innerHTML]="htmlTableHeader"></code>
        </pre>
        <!-- <pre>
          <code [innerHTML]="htmlControlBar"></code>
        </pre>
        <pre>
          <code [innerHTML]="htmlTable"></code>
        </pre> -->
        <!-- markup example -->
        <header class="table-header">
          <h2>Table Header</h2>
        </header>
        <div class="control-bar">
          <input placeholder="search" type="text">
          <button class="btn btn-primary" aria-label="Create new event">New</button>
        </div>
        <div class="table-container">
          <table>
            <tr>
              <th>Column 1</th>
              <th>Column 2</th>
              <th>Column 3</th>
              <th>Column 4</th>
              <th>Column 5</th>
            </tr>
            <tr>
              <td>Cell 1</td>
              <td>Cell 2</td>
              <td>Cell 3</td>
              <td>Cell 4</td>
              <td>Cell 5</td>
            </tr>
          </table>
        </div>
        <div class="table-container">
          <p>Pagination Component</p>
        </div>

    </mat-expansion-panel>
    
    <mat-expansion-panel (opened)="panelOpenState = true"
                         (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Toggle Switches
        </mat-panel-title>
        <!-- <mat-panel-description>
          Currently I am {{panelOpenState ? 'open' : 'closed'}}
        </mat-panel-description> -->
      </mat-expansion-panel-header>
      <p>Toggle switches inherit css from the global stylesheet, styles.scss. (click) functions are not shown in these examples.</p>
      <p>Note the inclusion of the title parameter in the button tag. This is required for WCAG compliance.</p>
      <button class="toggle-on toggle-switch" title="Description of action performed by button">
        <fa-icon [icon]="faCheckIcon" class="fa-2x"></fa-icon>
        <div class="toggle-on-btn"></div>
      </button>
      <button class="toggle-off toggle-switch" title="Description of action performed by button">
        <div class="toggle-off-btn"></div>
      </button>
      <pre>
        <code [innerHTML]="htmlToggleSwitches"></code>
      </pre>
      <p>You should be able to include this code in your component as is. If there are alignment issues, you may need to import the css into your component and tweak it as needed.</p>
      <p>Here is the minimum css needed to import if changes are needed because the circles or checkmark are misaligned in your toggle-switch. If Firefox-specific values are needed, uncomment the code for FF and tweak the values for top and left as needed.</p>
      <pre>
        <code [innerHTML]="cssToggleSwitches"></code>
      </pre>

    </mat-expansion-panel>

    <mat-expansion-panel (opened)="panelOpenState = true"
                         (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Utilities
        </mat-panel-title>
        <!-- <mat-panel-description>
          Currently I am {{panelOpenState ? 'open' : 'closed'}}
        </mat-panel-description> -->
      </mat-expansion-panel-header>
      <p>These are helper classes for things like colors, floats, and more to be added.</p>
      <p>Add these classes to your page elements as needed. Keep in mind, it is generally bad practice to use inline styles: <span class="text-red">style="margin:5px"</span> so use a class when needed. If one doesn't exist, create one in src/styles.scss.</p>

      <h2>Layout</h2>
      Float left (float:left)
      <code>
        pull-left
      </code>

      Float right (float:right)
      <code>
        pull-left
      </code>

      Clear (clear:both) - This is often needed in elements that follow floated elements.
      <code>
        clear
      </code>
      <br>&nbsp;<br>
      <h2>Code</h2>
      <p>Sometimes, when loading a component from a New button that's lower on the page, the component will load but the browser still needs to be scrolled up. If this doesn't happen, it looks like an error as the user is suddenly faced with what appears to be a blank screen.</p>
      <p>To avoid this, add the following like to your <code>create</code> function in the component's typescript:</p>
      <pre>
        <code>window.scrollTo(&#10100; top: 0, behavior: 'smooth' &#10101;);</code>
      </pre>
      <h2>Color</h2>
      <p>Lorum ipsum</p>
      
    </mat-expansion-panel>

  </mat-accordion>
  
  
</article>

<mat-sidenav-container class="mid-sidenav" autosize  style="height: 450vh;">
    <mat-sidenav class="midNav-container" #sidenav mode="side" disableClose="true" opened="true" scrollable="true">

        <div class="alert critical" *ngIf="eventGroupService.errorMessage">
            <h5>
                <fa-icon [icon]="faCriticalIcon"></fa-icon>
                <span>{{eventGroupService.errorMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)=eventGroupService.resetErrorMessage() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>
        <div class="alert success" *ngIf="eventGroupService.successMessage">
            <h5>
                <fa-icon [icon]="faSuccessIcon"></fa-icon>
                <span>{{eventGroupService.successMessage}}</span> <button class="alert-close-btn">
                    <fa-icon (click)=eventGroupService.resetSuccessMessage() [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>
        <div class="alert warning" *ngIf="eventGroupService.warningMessage">
            <h5>
                <fa-icon [icon]="faWarningIcon"></fa-icon>
                <span>{{eventGroupService.warningMessage}}</span>
                <button class="alert-close-btn">
                    <fa-icon (click)="eventGroupService.resetWarningMessage()" [icon]="faCloseIcon"></fa-icon>
                </button>
            </h5>
        </div>

        <h1>{{'create.event.group' | translate}}</h1>
        <mat-radio-group class="radio-group" [(ngModel)]=eventGroupCreate>
            <mat-radio-button color="primary" value=1 class="radio-button" (click)="toggleActivate(true)">
                {{'active.order' | translate}}
            </mat-radio-button>
            <mat-radio-button color="primary" value=2 class="radio-button" (click)="toggleActivate(false)">
                {{'inactive.order' | translate}}
            </mat-radio-button>
        </mat-radio-group>

        <mat-nav-list class="from-template user-selections">
            <h2>{{'device.templates' | translate}}</h2>
            <h2>{{'events' | translate}}</h2>
            <p *ngIf="!eventGroupService.deviceTemplateEventSelect">{{'select.events.deviceTemplates' | translate}}</p>
            <ul>
                <li *ngFor="let event of eventGroupService.selectedDeviceTemplateEventTemplateData">
                    <fa-icon [icon]="faCheckIcon"></fa-icon> {{event.eventName}}
                </li>
            </ul>
            <h2>{{'commands' | translate}}</h2>
            <p *ngIf="!eventGroupService.deviceTemplateCommandSelect">{{'select.command.deviceTemplates' | translate}}</p>
            <ul>
                <li *ngFor="let command of eventGroupService.selectedDeviceTemplateCommandTemplateData">
                    <fa-icon [icon]="faCheckIcon"></fa-icon> {{command.commandName}}
                </li>
            </ul>
            <h2>{{'devices' | translate}}</h2>
            <h2>{{'events' | translate}}</h2>
            <p *ngIf="!eventGroupService.eventSelect">{{'select.events.device' | translate}}</p>
            <ul>
                <li *ngFor="let event of eventGroupService.selectedEventTemplateData">
                    <fa-icon [icon]="faCheckIcon"></fa-icon> {{event.eventName}}
                </li>
            </ul>
            <h2>{{'commands' | translate}}</h2>
            <p *ngIf="!eventGroupService.commandSelect">{{'select.commands.device' | translate}}</p>
            <ul>
                <li *ngFor="let command of eventGroupService.selectedCommandTemplateData">
                    <fa-icon [icon]="faCheckIcon"></fa-icon> {{command.commandName}}
                </li>
            </ul>
            <div>
                <button class="btn btn-primary" [disabled]="!eventGroupService.eventGroupName || eventGroupCreated"
                    (click)="createEventGroup()">{{ 'save' | translate}}</button>
            </div>
        </mat-nav-list>
    </mat-sidenav>

    <div class="eventGroupData">
        <div class="form-data">
            <label for="event-group-name">{{ 'name' | translate}}</label>
            <input placeholder="required" id="event-group-name" type="text" value="" (keyup)="eventGroupName($event)"
                aria-label="Name">
            <label for="event-group-description">{{ 'description' | translate}}</label>
            <textarea placeholder="optional" id="event-group-description" value=""
                (keyup)="eventGroupDescription($event)" aria-label="Description"></textarea>
            <br />
            <label for="event-group-tags">{{ 'tags' | translate}}</label>
            <mat-chip-list #tagList aria-label="tags">
                <mat-chip class="ot-chip" *ngFor="let tag of tags" [selectable]="true" [removable]=true
                    (removed)="remove(tag)">
                    {{tag}}
                    <button class="ot-chip__close" matChipRemove></button>
                </mat-chip>
                <input [ngClass]="tagClass" placeholder="add a tag" #tagInput [matChipInputFor]="tagList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                    (matChipInputTokenEnd)="add($event)">
            </mat-chip-list>
        </div>
    </div>
    <div class="example-sidenav-content">
        <ng-container>
            <app-device-template-list></app-device-template-list>
            <app-device-list></app-device-list>
        </ng-container>
    </div>
</mat-sidenav-container>
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faAngleUp, faAngleDown, faTimes, faExclamationTriangle, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { faToggleOff, faToggleOn, faAngleLeft, faAngleRight, faCheck, faCopy, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { IotService } from '../../service/iot.service';
import { SideNavComponent } from '../../side-nav/side-nav.component';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER, I } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-new-document-info',
  templateUrl: './new-document-info.component.html',
  styleUrls: ['./new-document-info.component.scss']
})
export class NewDocumentInfoComponent implements OnInit {

  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;
  faCloseIcon = faTimes;
  faWarningIcon = faExclamationTriangle;
  faCriticalIcon= faExclamation;

  failed = faTimes;
  faToggleOffIcon = faToggleOff;
  faToggleOnIcon = faToggleOn;
  faAngleLeftIcon = faAngleLeft;
  faAngleRightIcon = faAngleRight;
  faCheckIcon = faCheck;
  faCopyIcon = faCopy;
  faAngleDoubleLeftIcon = faAngleDoubleLeft;
  faAngleDoubleRightIcon = faAngleDoubleRight;
  supportedDocTypeError='';
  supportedDocTemplateTypeError='';
  templateVersionError='';


  separatorKeysCodes: number[] = [ENTER, COMMA];
  tags = [];
  tagClass = 'tagInput';
  successMessage: string;
  warningMessage: string;
  errorMessage:string;
  isActive = true;
  docinfo;
  documentInfoCreated=false;



  supportLanguages = ['en'];



  constructor(public iotService: IotService, private translateService: TranslateService, private sideNavComponent: SideNavComponent, private router: Router) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
    this.sideNavComponent.menuClear();
    this.sideNavComponent.menuChange('Dashboard', 'sub-type');
    this.sideNavComponent.menuChange('documentInfo.header', 'sub-type');
    this.sideNavComponent.menuChange('documentInfo.create.new', 'sub-type');
  }

  ngOnInit(): void {
    this.initDocInfo();
  }

  resetWarningMessage(){
    this.warningMessage='';
    this.errorMessage='';
    // this.supportedDocTypeError='';
    // this.supportedDocTemplateTypeError='';
    // this.templateVersionError='';
  }


  initDocInfo() {
    const that = this;
    that.docinfo = {

      "creator": that.iotService.getCreator(),
      "creatorAppId": that.iotService.getCreatorAppId(),
      "realm": that.iotService.getRealm(),
      "supportedDocType": "",
      "supportedDocTemplateType": "",
      "docTemplateVersion": "",
      "name": [{
        "lang": "en_US",
        "text": ""
      }],
      "description": [{
        "lang": "en_US",
        "text": ""
      }],
      "active": that.isActive,
      "tags": that.tags
    };
  }

  save() {
    const that = this;
    that.documentInfoCreated=true;
    that.docinfo.active=that.isActive;
    that.iotService.getCuiObjResponse().createDocumentInfo({
      data: that.docinfo
    }).then(function (response) {
      console.log("Document Info created succesfully. ID :", response.id);
      that.router.navigateByUrl("/documentInfo");
    })
      .fail(function (err) {
        that.documentInfoCreated=false;
        if(that.docinfo.supportedDocType==''){
          that.supportedDocTypeError="Document type required";
        }
        if(that.docinfo.supportedDocTemplateType==''){
          that.supportedDocTemplateTypeError="Template type required";
        }
        if(that.docinfo.docTemplateVersion==''){
          that.templateVersionError="Template version required";
        }
        if (err.responseJSON.apiStatusCode) {
          that.errorMessage = err.responseJSON.apiMessage;
          console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
        } else {
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
        if (err === null) {
          that.errorMessage = "Error occured while creating token";
          console.log("Error occured while creating document info");
        }
      });

  }


  add(event: MatChipInputEvent): void {

    this.tagClass = 'tagInput';
    const input = event.input;
    const value = event.value;
    const index = this.tags.indexOf(value);
    if (index < 0) {
      if ((value || '').trim()) {
        if (this.tags != undefined && this.tags.includes(value)) {
          if (input) {
            input.value = '';
          }
        }
        else {
          this.tags.push(value);
          if (input) {
            input.value = '';
          }
        }
      }
    } else {
      this.tagClass = 'tagInput-duplicate';
    }
  }

  remove(tag: string): void {
    const that = this;
    that.tags.forEach((value, index) => {
      if (value == tag) that.tags.splice(index, 1);
    });

  }

  toggleActive() {
    this.isActive = !this.isActive;
  }

}

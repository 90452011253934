/** 
 * This is an auto generated code for tradingPartnerAudits service listing page.
 * This file can be customized and will not be overwritten.
 * @version 1.0.0 
 * @readonly 
 * @author - System generated 
 */

import { Injectable } from '@angular/core';
import { SelectionModel} from '@angular/cdk/collections';
import { IotService } from '../service/iot.service'; 
import { ListTradingPartnerAuditsService } from './ListTradingPartnerAudits.service';

@Injectable({ 
	  providedIn: 'root' 
})

export class TradingPartnerAuditsService extends ListTradingPartnerAuditsService {
	 constructor(public iotService:IotService) {
		 super(iotService);
	 }
	 auditPayload:any;
	 fnOnSelectionSupport(results){
		 const that = this; 
	 } 

	 fnRemoveSelectionSupport(results){ 
		 const that = this; 
	 } 

	 viewDetails(selectedId){ 
		
		const that = this; 
		that.iotService.getCuiObjResponse().getAudit({
			id: selectedId
		  }).then(function (response) {
			console.log(response);
			that.iotService.auditscript=response.parameters;
			that.iotService.auditScriptFlag=true;
		  }).fail(function (err) {
			if (err.responseJSON.apiStatusCode) {
			  console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
			} else {
			  console.log("Something went wrong. Please check chrome console or check with dev team");
			}
		});
	}

	/**
 * Use to set the AuditPayload
 */
getAuditPayload():any{
	return this.auditPayload;
  }
  /**
   * Use to get the AuditPayload
   */
  setAuditPayload(auditPayload:any){
	this.auditPayload = auditPayload;
  }


} 

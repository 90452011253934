import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IotService } from '../service/iot.service';
import { TradingPartnersService } from '../service/tradingPartners.service';
import { faAngleUp, faAngleDown, faTimes, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faToggleOff, faToggleOn, faAngleLeft, faAngleRight, faCheck, faCopy, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-trading-partner-code-detail',
  templateUrl: './trading-partner-code-detail.component.html',
  styleUrls: ['./trading-partner-code-detail.component.scss']
})
export class TradingPartnerCodeDetailComponent implements OnInit {

  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;
  faCloseIcon = faTimes;
  faWarningIcon = faExclamationTriangle;
  failed = faTimes;
  faToggleOffIcon = faToggleOff;
  faToggleOnIcon = faToggleOn;
  faAngleLeftIcon = faAngleLeft;
  faAngleRightIcon = faAngleRight;
  faCheckIcon = faCheck;
  faCopyIcon = faCopy;
  faAngleDoubleLeftIcon = faAngleDoubleLeft;
  faAngleDoubleRightIcon = faAngleDoubleRight;
  supportLanguages = ['en'];

  isActive = true;
  codeError;
  tradingPartnerCode;

  constructor(public iotService: IotService, private translateService: TranslateService,
    private router: Router, public tpService: TradingPartnersService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }


  ngOnInit(): void {
    this.loadTradingPartnerCode();
  }

  loadTradingPartnerCode(){
    const that=this;
    that.iotService.getCuiObjResponse().getTradingPartnerCodeById({
      codeId: that.tpService.getCodeId()
    }).then(function (response) {
      console.log("Trading Partner code created successFully, Id: ", response.id);
      that.tradingPartnerCode = response;
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.tpService.warningMessage = err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
      if (err === null) {
        console.log("Error occured while creating trading partner code");
      }
    });

  }

  resetData(){
    this.codeError="";
  }

  save() {
    const that = this;
    if (this.tradingPartnerCode.code == "" || this.tradingPartnerCode.code==undefined) {
      this.codeError="Code Value is Required";
    }
    else {
      that.iotService.getCuiObjResponse().updateTradingPartnerCodeById({
        tradingPartnerId:that.tpService.getCodeId(),
        data: that.tradingPartnerCode
      }).then(function (response) {
        console.log("Trading Partner code created successFully, Id: ", response.id);
        that.tradingPartnerCode = response;
          that.tpService.createResource = true;
          that.tpService.viewTPCode = false;
          that.tpService.resetMessage(); 
      }).fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          that.tpService.errorMessage = err.responseJSON.apiMessage;
          console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
        } else {
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
        if (err === null) {
          console.log("Error occured while creating trading partner code");
        }
      });
    }


  }

  toggleActive() {
    if(this.tradingPartnerCode.status=='ACTIVE'){
      this.deactivateTradingPartnercode(this.tradingPartnerCode.id);
    }
    else{
      this.activateTradingPartnercode(this.tradingPartnerCode.id);
    }
  }
  goBack() {
    this.tpService.createResource = true;
    this.tpService.viewTPCode = false;
    this.tpService.resetMessage();
  }

  activateTradingPartnercode(codeId) {
    const that = this;
    that.iotService.getCuiObjResponse().activateTradingPartnerCodes({
      codeId: codeId
    }).then(function (response) {
      console.log("Trading Partner code activated successFully, Id: ", codeId);
      that.loadTradingPartnerCode();

    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.tpService.errorMessage = err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
      if (err === null) {
        console.log("Error occured while creating trading partner code");
      }
    });

  }


  deactivateTradingPartnercode(codeId) {
    const that = this;
    that.iotService.getCuiObjResponse().deactivateTradingPartnerCodes({
      codeId: codeId
    }).then(function (response) {
      console.log("Trading Partner code activated successFully, Id: ", codeId);
      that.loadTradingPartnerCode();
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.tpService.errorMessage = err.responseJSON.apiMessage;
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
      if (err === null) {
        console.log("Error occured while creating trading partner code");
      }
    });

  }

}

import { Injectable } from '@angular/core';
import { IotService } from '../service/iot.service';
import { ListEventGroupsService } from './ListEventGroups.service';
import { ApplicationsService } from './applications.service';
import { RoutesService } from './routes.service';
import { SelectionModel } from '@angular/cdk/collections';
import { L } from '@angular/cdk/keycodes';

@Injectable({
  providedIn: 'root'
})

export class EventGroupsService extends ListEventGroupsService {
  //selection: SelectionModel<Element> = new SelectionModel<Element>(true, []);
  constructor(public iotService: IotService, public applicationsService: ApplicationsService, public routeService: RoutesService) {
    super(iotService);
    this.selection.clear();    
    this.loadSelected();
  }

  private selectedEventTemplate: any = undefined;
  private selectedCommandTemplate: any = undefined;
  private eventGroupData: any = undefined;
  deviceName: any = undefined;
  deviceTemplateName: any = undefined;
  eventGroupName: any = undefined;
  eventGroupDescription: any = undefined;
  eventGroupTags = [];
  eventSelect: boolean = false;
  commandSelect: boolean = false;
  deviceSelect: boolean = false;
  deviceTemplateSelect: boolean = false;
  eventTemplateSelect: boolean = false;
  eventId: string;
  commandId: string;
  warningMessage: string;
  errorMessage: string;
  successMessage: string;
  sourceDevice: string;
  sourceDevices = [];
  sourceCommandDevices = [];
  sourceCommandDeviceTemplates = [];
  sourceDeviceTemplate: string;
  sourceDeviceTemplates = [];
  eventGroupNameSelect: boolean = false;
  selectedEventTemplates = [];
  selectedCommandTemplates = [];
  selectedEventTemplateData = [];
  selectedCommandTemplateData = [];
  selectedDeviceTemplateEventTemplates = [];
  selectedDeviceTemplateCommandTemplates = [];
  deviceTemplateEventSelect: boolean = false;
  deviceTemplateCommandSelect: boolean = false;
  selectedDeviceTemplateEventTemplateData = [];
  selectedDeviceTemplateEventTemplateData1 = [];

  route: any;
  sourceStream: any;
  eventGroupIds = [];
  eventGroupNames = [];
  eventGroupsData = [];
  streamId: string;
  selectedDeviceTemplateCommandTemplateData = [];
  selectedDeviceTemplateCommandTemplateData1 = [];

  loadSelected() {
    if (this.routeService.getEventGroup()) {
      this.selection.select(this.routeService.getEventGroup().id);
    }
  }

  resetWarningMessage() {
    this.warningMessage = '';
  }

  resetSuccessMessage() {
    this.successMessage = '';
  }

  resetErrorMessage() {
    this.errorMessage = '';
  }

  setEventId(eventId: string) {
    this.eventId = eventId;
  }

  getEventId(): string {
    return this.eventId;
  }

  setCommandId(commandId: string) {
    this.commandId = commandId;
  }

  getCommandId(): string {
    return this.commandId;
  }

  setEventGroupName(eventGroupName: any) {
    this.eventGroupName = eventGroupName;
  }

  getEventGroupName(): any {
    return this.eventGroupName;
  }

  setEventGroupDescription(eventGroupDescription: any) {
    this.eventGroupDescription = eventGroupDescription;
  }

  getEventGroupDescription(): any {
    return this.eventGroupDescription;
  }

  setEventGroupTags(EventGroupTags: any) {
    this.eventGroupTags = EventGroupTags;
  }

  getEventGroupTags(): any {
    return this.eventGroupTags;
  }

  setEventGroupData(eventGroupData: any) {
    this.eventGroupData = eventGroupData;
  }

  getEventGroupData(): any {
    return this.eventGroupData;
  }

  setEventGroupSourceDevices(selectedDeviceEvents: any) {
    let sourceDevice = {
      'deviceId': selectedDeviceEvents.deviceId,
      'eventTemplateId': selectedDeviceEvents.eventTemplateId
    }
    this.sourceDevices.push(sourceDevice)
  }

  getEventGroupSourceDevices(): any {
    return this.sourceDevices;
  }

  setEventGroupSourceCommandDevices(selectedDeviceCommands: any) {
    let sourceCommandDevice = {
      'deviceId': selectedDeviceCommands.deviceId,
      'commandTemplateId': selectedDeviceCommands.commandTemplateId
    }
    this.sourceCommandDevices.push(sourceCommandDevice)
  }


  getEventGroupSourceCommandDevices(): any {
    return this.sourceCommandDevices;
  }

  setEventGroupSourceCommandDeviceTemplates(selectedDeviceCommandTemplates: any) {
    let sourceCommandDeviceTemplate = {
      'deviceTemplateId': selectedDeviceCommandTemplates.deviceTemplateId,
      'commandTemplateId': selectedDeviceCommandTemplates.commandTemplateId
    }
    this.sourceCommandDeviceTemplates.push(sourceCommandDeviceTemplate)
  }

  getEventGroupSourceCommandDeviceTemplates(): any {
    return this.sourceCommandDeviceTemplates;
  }

  setEventGroupSourceDeviceTemplates(selectedDeviceEventTemplates: any) {
  let sourceDeviceTemplate = {
      'deviceTemplateId': selectedDeviceEventTemplates.deviceTemplateId,
      'eventTemplateId': selectedDeviceEventTemplates.eventTemplateId
    }
    this.sourceDeviceTemplates.push(sourceDeviceTemplate)
  }

  getEventGroupSourceDeviceTemplates(): any {
    return this.sourceDeviceTemplates;
  }

  setSelectedEventTemplate(selectedEventTemplate: any) {
    this.selectedEventTemplate = selectedEventTemplate;
  }

  getSelectedEventTemplate(): any {
    return this.selectedEventTemplate;
  }

  setSelectedEventTemplateData(selectedEventTemplateData: any) {
    this.selectedEventTemplateData.push(selectedEventTemplateData);
  }

  getSelectedEventTemplateData(): any {
    return this.selectedEventTemplateData;
  }

  setSelectedCommandTemplateData(selectedCommandTemplateData: any) {
    this.selectedCommandTemplateData.push(selectedCommandTemplateData);
  }

  getSelectedCommandTemplateData(): any {
    return this.selectedCommandTemplateData;
  }

  setSelectedDeviceTemplateCommandTemplateData(selectedDeviceTemplateCommandTemplates: any) {
    this.selectedDeviceTemplateCommandTemplateData.push(selectedDeviceTemplateCommandTemplates);
  }

  getSelectedDeviceTemplateCommandTemplateData(): any {
    return this.selectedDeviceTemplateCommandTemplateData;
  }

  setSelectedDeviceTemplateCommandTemplateData1(selectedDeviceTemplateEventTemplates: any) {
    this.selectedDeviceTemplateCommandTemplateData1.push(selectedDeviceTemplateEventTemplates);
  }

  getSelectedDeviceTemplateCommandTemplateData1(): any {
    return this.selectedDeviceTemplateCommandTemplateData1;
  }


  setSelectedDeviceTemplateEventTemplateData(selectedDeviceTemplateEventTemplates: any) {
    this.selectedDeviceTemplateEventTemplateData.push(selectedDeviceTemplateEventTemplates);
  }

  getSelectedDeviceTemplateEventTemplateData(): any {
    return this.selectedDeviceTemplateEventTemplateData;
  }

  setSelectedDeviceTemplateEventTemplateData1(selectedDeviceTemplateEventTemplates: any) {
    this.selectedDeviceTemplateEventTemplateData1.push(selectedDeviceTemplateEventTemplates);
  }

  getSelectedDeviceTemplateEventTemplateData1(): any {
    return this.selectedDeviceTemplateEventTemplateData1;
  }

  setSelectedCommandTemplate(undefined: undefined) {
    return this.selectedCommandTemplate;
  }

  getSelectedCommandTemplate(): any {
    return this.selectedCommandTemplate;
  }


  fnOnSelectionSupport(results) {
    const that = this;
    if (that.iotService.createRoute == true) {
      this.routeService.setEventGroup(results);
    }

    else if (this.iotService.viewApplication== true)
    {
      that.eventGroupsData.push(results);
      that.createRoute(results);
    }
  }

  initRoute() {
    const that = this;
    that.route = {
      "creator": '',
      "creatorAppId": '',
      "realm": '',
      'name': [{
        'lang': 'en_us',
        'text': ''
      }],
      'description': [{
        'lang': 'en_us',
        'text': ''
      }],
      'routeType': 'EVENT',
      'streamId': '',
      'routeSourceId': '',
      'alertRoutingTopic': '',
      'active': true
    };
  }

  createRoute(results) {
    this.initRoute();
    const that = this;
    that.route.creator = that.iotService.getCreator();
    that.route.creatorAppId = that.iotService.getCreatorAppId();
    that.route.realm = that.iotService.getRealm();
    that.route.name[0].text = results.name[0].text + ' to ' + that.applicationsService.sourceStream.name[0].text

    if (that.applicationsService.sourceStream.id) {
      that.route.streamId = that.applicationsService.sourceStream.id;
    }
    if (results.id) {
      that.route.routeSourceId = results.id;
    }
    if (that.applicationsService.sourceStream.consumerTopic) {
      that.route.alertRoutingTopic = that.applicationsService.sourceStream.consumerTopic;
    }

    that.iotService.getCuiObjResponse().createRoute({
      data: that.route
    }).then(function (response) {
      that.eventGroupIds.push(response.routeSourceId);
      that.eventGroupNames.push(results.name[0].text);
      that.loadEventGroup(results, response.id);
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.applicationsService.errorMessage= err.responseJSON.apiMessage;
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  deleteRoute(results,eventSourceId){
		const that = this;
    var routeId=results;
		that.iotService.getCuiObjResponse().deleteRoute({
			routeId: routeId
		}).then(function (response) {
			let eventGroups = that.eventGroupsData;
			eventGroups.forEach((value, index) => {
				if (value.id == eventSourceId) eventGroups.splice(index, 1);
			});
			that.eventGroupsData=eventGroups;
      let subscriptions= that.applicationsService.subscriptionsData;
      subscriptions.forEach((value, index) => {
				if (value.routeId == routeId) subscriptions.splice(index, 1);
			});
      that.applicationsService.subscriptionsData=subscriptions;
		}).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.applicationsService.errorMessage= err.responseJSON.apiMessage;
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
	}

  removeRoutes(eventSourceId) {
    const that = this;
    that.iotService.getCuiObjResponse().getRoutes({
      qs:[['routeSourceId',eventSourceId],['sortBy', '-creation']]
    }).then(function(response): void {
      if(response) {
        that.deleteRoute(response[0].id, eventSourceId);
      }
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.applicationsService.errorMessage= err.responseJSON.apiMessage;
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  loadEventGroup(eventGroup, routeId) {
    const that = this;
    if (eventGroup) {
      that.applicationsService.eventGroupData.push(eventGroup);
      let subscriptionData = {
        "eventGroupName": eventGroup.name[0].text,
        "eventGroupId": eventGroup.id,
        "routeId": routeId
      }
      that.applicationsService.subscriptionsData.push(subscriptionData);
    }
  }

  fnRemoveSelectionSupport(results) {
    const that = this;
    if (that.iotService.createRoute == true) {
      this.routeService.setEventGroup(undefined);
    }

    else if (this.iotService.viewApplication== true)
    {
      that.removeRoutes(results.id);
    }
  }

  fnResetList() { }
}

<app-header></app-header>
<ng-container *ngIf="showPageLoading">
    <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
            <span class="sr-only" id="loading"></span>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="!showPageLoading">
    <ng-container *ngIf="!showSidenav"> 
        <app-getting-started (messageToEmit)="getMessage($event)"></app-getting-started>
    </ng-container>
    <ng-container *ngIf="showSidenav"> 
        <app-side-nav (messageToEmit)="getMessage($event)"></app-side-nav>
    </ng-container>
</ng-container>
<!-- <p> {{iotService.role}}</p> -->
<!-- <ng-container *ngIf="!showPageLoading">

    <app-getting-started *ngIf="!showSidenav" (messageToEmit)="getMessage($event)" [routerLink]="['/getting-started']"></app-getting-started>
    <ng-container *ngIf="showSidenav">
        <app-side-nav (messageToEmit)="getMessage($event)"></app-side-nav>
    </ng-container>
</ng-container> -->
<app-footer></app-footer>

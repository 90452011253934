<mat-sidenav-container class="mid-sidenav" autosize>
    <mat-sidenav class="midNav-container" #sidenav mode="side" disableClose="true" opened="true" scrollable="true">
      <div class="alert critical" *ngIf="saveErrorMsg.length > 0">
        <div *ngFor="let error of saveErrorMsg">
          <h5>
              <fa-icon [icon]="faCritical"></fa-icon>
              <span>{{error}}</span>
              <button class="alert-close-btn">
                  <fa-icon (click)="resetErrorMsg()" [icon]="faClose"></fa-icon>
              </button>
          </h5>
        </div>
      </div>
      <div class="alert success" *ngIf="successMessage">
          <h5>
              <fa-icon [icon]="faSuccess"></fa-icon>
              <span>{{successMessage}}</span>
              <button class="alert-close-btn">
                  <fa-icon (click)=resetSuccessMessage() [icon]="faClose"></fa-icon>
              </button>
          </h5>
      </div>
      <div style="margin-left: 0px;">
        <app-copy-resource-id></app-copy-resource-id>
    </div>
      <h1 *ngIf="mode == 0">{{'create.orchestration.config' | translate}}</h1>
      <h1 *ngIf="mode == 1">{{orchestration.name[0].text}} [{{orchestration.versionTag}}]</h1>
      <br>

      <ng-container>
        <h3>{{'document.info.details' | translate}}</h3>

        <h5>{{'origin.document.info' | translate}}</h5>
        <ng-container *ngIf="orchestrationConfigsService.sourceDocInfo">
          <ul class="streams-list">
            <li>
              <fa-icon [icon]="faCheck"></fa-icon>
              <span class="stream-link" (click)="toggleOriginDocInfo()" title="{{orchestrationConfigsService.sourceDocInfo.name[0].text}}">{{orchestrationConfigsService.sourceDocInfo.name[0].text}}</span>
              <fa-icon class="delete-icon" [icon]="faTimes" (click)="removeSourceDocInfo(orchestrationConfigsService.sourceDocInfo)" title="Delete"></fa-icon>
              <table *ngIf="showOriginDocInfo">
                <tr>
                  <td>
                    <dt>{{'name' | translate}}</dt>
                    <dd><a routerLink="/documentInfo/{{orchestrationConfigsService.sourceDocInfo.id}}">{{orchestrationConfigsService.sourceDocInfo.name[0].text}}</a></dd>
                  </td>
                  <td>
                    <dt>{{'documentType' | translate}}</dt>
                    <dd>{{orchestrationConfigsService.sourceDocInfo.supportedDocType}}</dd>
                  </td>
                </tr>
                <tr>
                  <td>
                    <dt>{{'templateType' | translate}}</dt>
                    <dd>{{orchestrationConfigsService.sourceDocInfo.supportedDocTemplateType}}</dd>
                  </td>
                  <td>
                    <dt>{{'templateVersion' | translate}}</dt>
                    <dd>{{orchestrationConfigsService.sourceDocInfo.docTemplateVersion}}</dd>
                  </td>
                </tr>
              </table>
            </li>
          </ul>
        </ng-container>
        <ng-container *ngIf="!orchestrationConfigsService.sourceDocInfo">
          <p>{{'select.originDocument.info' | translate}}</p>
        </ng-container>

        <h5>{{'target.document.info' | translate}}</h5>
        <ng-container *ngIf="orchestrationConfigsService.targetDocInfo">
          <ul class="streams-list">
            <li>
              <fa-icon [icon]="faCheck"></fa-icon>
              <span class="stream-link" (click)="toggleTargetDocInfo()" title="View {{orchestrationConfigsService.targetDocInfo.name[0].text}}">{{orchestrationConfigsService.targetDocInfo.name[0].text}}</span>
              <fa-icon class="delete-icon" [icon]="faTimes" (click)="removeTargetDocInfo(orchestrationConfigsService.targetDocInfo)" title="Delete"></fa-icon>
              <table *ngIf="showTargetDocInfo">
                <tr>
                  <td>
                    <dt>{{'name' | translate}}</dt>
                    <dd><a routerLink="/documentInfo/{{orchestrationConfigsService.targetDocInfo.id}}">{{orchestrationConfigsService.targetDocInfo.name[0].text}}</a></dd>
                  </td>
                  <td>
                    <dt>{{'documentType' | translate}}</dt>
                    <dd>{{orchestrationConfigsService.targetDocInfo.supportedDocType}}</dd>
                  </td>
                </tr>
                <tr>
                  <td>
                    <dt>{{'templateType' | translate}}</dt>
                    <dd>{{orchestrationConfigsService.targetDocInfo.supportedDocTemplateType}}</dd>
                  </td>
                  <td>
                    <dt>{{'templateVersion' | translate}}</dt>
                    <dd>{{orchestrationConfigsService.targetDocInfo.docTemplateVersion}}</dd>
                  </td>
                </tr>
              </table>
            </li>
          </ul>
        </ng-container>
        <ng-container *ngIf="!orchestrationConfigsService.targetDocInfo">
          <p>{{'select.targetDocument.info' | translate}}</p>
        </ng-container>
      </ng-container>
      <ng-container>
        <h5>{{'connection.streams' | translate}}</h5>
        <p *ngIf="orchestrationConfigsService.getStreamData().length < 1">{{'select.availableConnections.desc' | translate}}</p>
        <ul class="streams-list">
          <li *ngFor="let stream of orchestrationConfigsService.streamData">
            <fa-icon [icon]="faCheck"></fa-icon>

            <span class="stream-link" (click)="showStream(stream)" title="View {{stream.name[0].text}}">{{stream.name[0].text}}</span>

            <fa-icon class="delete-icon" [icon]="faTimes" (click)="removeStreamDocInfo(stream, 0)" title="Delete"></fa-icon>
            <ng-container *ngIf="selectedStream && selectedStream.id === stream.id">
              <table>
                <tr>
                  <td>
                    <dt>{{'stream.name' | translate}}</dt>
                    <dd><a routerLink="/streams/{{selectedStream.id}}" title="View Stream Details">{{selectedStream.name[0].text}}</a></dd>
                  </td>
                  <td>
                    <dt>{{'protocol.type' | translate}}</dt>
                    <dd>{{selectedStream.protocolType}}</dd>
                  </td>
                </tr>
                <tr>
                  <td>
                    <dt>{{'protocol.security.type' | translate}}</dt>
                    <dd>{{selectedStream.protocolSecurityType}}</dd>
                  </td>
                  <td>
                    <dt>{{'encryption' | translate}}</dt>
                    <dd>{{selectedStream.payloadSecurityType}}</dd>
                  </td>
                </tr>
                <tr>
                  <td>
                    <dt>{{'producer.topic' | translate}}</dt>
                    <dd>
                      <ng-container *ngIf="selectedStream.producerTopic">{{selectedStream.producerTopic}}</ng-container>
                      <ng-container *ngIf="!selectedStream.producerTopic">-</ng-container>
                    </dd>
                  </td>
                  <td>
                    <dt>{{'consumer.topic' | translate}}</dt>
                    <dd>
                      <ng-container *ngIf="selectedStream.consumerTopic">{{selectedStream.consumerTopic}}</ng-container>
                      <ng-container *ngIf="!selectedStream.consumerTopic">-</ng-container>
                    </dd>
                  </td>
                </tr>
              </table>
            </ng-container>
          </li>
        </ul>
      </ng-container>

      <ng-container *ngIf="mode == 1">
        <dl>
          <dt>{{'enabled' | translate}}{{'?' | translate}}</dt>
          <dd>
            <button *ngIf="orchestration.status == 'true'" class="toggle-on toggle-switch" (click)="changeActive(orchestration.id, 'true')" title="{{'method.disable.tooltip.orchestration' | translate}}">
              <fa-icon [icon]="faCheck" class="fa-2x"></fa-icon>
              <div class="toggle-on-btn"></div>
            </button>
            <button *ngIf="orchestration.status == 'false'" class="toggle-off toggle-switch" (click)="changeActive(orchestration.id, 'false')" title="{{'method.enable.tooltip.orchestration' | translate}}">
              <div class="toggle-off-btn"></div>
            </button>
          </dd>
        </dl>
      </ng-container>
      <ng-container>
        <div class="save-btns">
          <button class="btn btn-primary" (click)="save()" type="submit" [disabled]="saving">
            <span *ngIf="!saving">{{ 'save' | translate}}</span>
            <span *ngIf="saving">{{ 'saving' | translate}}
              <fa-icon [icon]="faSpinner" class="fa-spin load-animate" style="margin-left: 2px"></fa-icon>
            </span>
          </button>
          <button *ngIf="mode == 1" class="btn btn-delete pull-right" (click)="delete()">{{ 'delete' | translate}}</button>
          <button *ngIf="mode == 1" class="btn btn-secondary pull-right" (click)="auditOrchestration()">{{ 'audit' | translate}}</button>
        </div>
      </ng-container>
      <ng-container *ngIf="mode == 1">
        <div class="version-btns">
          <h5>{{'versioning' | translate}}</h5>
          <button class="btn btn-secondary pull-left" (click)="creteVersion()" type="submit">{{ 'orchestrationConfigs.version.btn' | translate}}</button>

          <button class="btn btn-secondary" style="margin-left: 10px" (click)="listVersions()" type="submit">{{ 'orchestrationConfigs.list.version.btn' | translate}}</button>

          <button *ngIf="mode ==1 && orchestration.versionLock === true && orchestration.versionTag!='Working-Copy'"
            class="btn btn-secondary pull-right" (click)="unlockVersion(orchestration.versionTag)" [disabled]="unlockingVersion">
            <fa-icon [icon]="faLock"></fa-icon>
            <span *ngIf="!unlockingVersion"> {{ 'orchestration.unlock.version' | translate}}</span>
            <span *ngIf="unlockingVersion"> {{ 'orchestration.unlocking.version' | translate}}</span>
          </button>

          <button *ngIf="mode == 1 && orchestration.versionLock === false && orchestration.versionTag!='Working-Copy'"
            class="btn btn-secondary pull-right" (click)="lockVersion(orchestration.versionTag)" [disabled]="lockingVersion">
            <fa-icon [icon]="faUnlock"></fa-icon>
            <span *ngIf="!lockingVersion"> {{ 'orchestration.lock.version' | translate}}</span>
            <span *ngIf="lockingVersion"> {{ 'orchestration.locking.version' | translate}}</span>
          </button>
        </div>
      </ng-container>

      <ng-container>
        <div class="testing">
          <h3><b>{{'testing' | translate}}</b></h3>
          <p *ngIf="mode == 0">{{'orchestration.saved.testing' | translate}}</p>
          <p *ngIf="mode == 1"><b>{{ 'input' | translate}}{{ ':' | translate}}</b>
            <input type="file" name="file" id="file" class="inputfile" (change)="importFile($event.target.files)" /> <label for="file" class="pull-right">{{'import.from.file' | translate}}</label> </p>
          <textarea *ngIf="mode == 1" [(ngModel)]="testInput" id="orchestration-testInput" value="" aria-label="Testing"></textarea>
          <button *ngIf="mode == 1" class="btn btn-secondary pull-right" (click)="test()" type="submit" [disabled]="testing">
            <span *ngIf="!testing">{{ 'test' | translate}}</span>
            <span *ngIf="testing">{{ 'testing' | translate}}
              <fa-icon [icon]="faSpinner" class="fa-spin load-animate" style="margin-left: 2px"></fa-icon>
            </span>
          </button>
        </div>
      </ng-container>

      <ng-container *ngIf="testResults != undefined">
        <div class="test-results">
          <h3><b>{{'delivery.status' | translate}}{{':' | translate}}</b>&nbsp;&nbsp;
            <span *ngIf="testResults.deliveryStatus == 'c'" style="color: green">{{'complete' | translate}}</span>
            <span *ngIf="testResults.deliveryStatus != 'c'" style="color: red">{{'failed' | translate}}</span>
          </h3>
          <table>
            <tr>
              <th>{{'task' | translate}}</th>
              <th>{{'status' | translate}}</th>
              <th>{{'timing' | translate}}</th>
              <th>{{'result' | translate}}</th>
            </tr>
            <tr *ngFor="let info of testResults.response[0].auditInfo">
              <td>{{info.serviceName}}</td>
              <td><span *ngIf="!info.errorInfo" style="color: green">{{'complete' | translate}}</span><span *ngIf="info.errorInfo" style="color: red">{{'failed' | translate}}</span></td>
              <td>{{info.timeInMLS}}</td>
              <td><span *ngIf="!info.errorInfo" style="color: green">{{'success' | translate}}</span><span *ngIf="info.errorInfo" style="color: red">{{'view.error' | translate}}</span></td>
            </tr>
          </table>
          <h3><b>{{'output' | translate}}{{':' | translate}}</b></h3>
          <textarea [(ngModel)]="testResults.response[0].returnPayload" placeholder="Results"></textarea>
        </div>
      </ng-container>

    </mat-sidenav>
    <div *ngIf="!auditFlag">
    <ng-container *ngIf="!orchestrationConfigsService.createTask && !createVersion && !listVersion && !startNewTP">
      <div class="orchestrationConfigData">
        <div class="col">
          <label for="orchestration-config-name">{{ 'orchestrationConfigs.name' | translate}}</label>
          <input placeholder="required" [(ngModel)]="orchestration.name[0].text"
            id="orchestration-config-name" type="text" value="" aria-label="Name">

          <label for="orchestration-config-description">{{ 'description' | translate}}</label>
          <textarea placeholder="optional" [(ngModel)]="orchestration.description[0].text" id="orchestration-config-description" value="" aria-label="Description"></textarea>

          <!-- <label for="orchestration-config-tags">{{ 'tags' | translate}}</label>
          <mat-chip-list #tagList aria-label="tags">
              <mat-chip class="ot-chip" *ngFor="let tag of tags" [selectable]="true" [removable]=true (removed)="remove(tag)">
                  {{tag}}
                  <button class="ot-chip__close" matChipRemove></button>
              </mat-chip>
              <input [ngClass]="tagClass" placeholder="add a tag" #tagInput [matChipInputFor]="tagList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="add($event)">
          </mat-chip-list> -->
        </div>
        <div class="col">
          <label for="orchestration-config-type">{{ 'orchestrationConfigs.orchestrationType' | translate}}</label>
          <select name="orchestration-config-type" [(ngModel)]="orchestration.orchestrationType" (change)="changeOrchType()" [disabled]="mode == 1">
            <option [value]="item.type" *ngFor="let item of orchestrationConfigTypes">
                {{item.name}}
            </option>
          </select>

          <label for="orchestration-config-name">{{'orchestrationConfigs.serviceRunTime' | translate}}</label>
          <input placeholder="required" [(ngModel)]="orchestration.serviceRunTime" id="orchestration-config-runtime" type="text" (keypress)="numberOnly($event)" value="" aria-label="Runtime">
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!orchestrationConfigsService.createTask && sourceDocLoaded && !createVersion && !listVersion && !startNewTP">
      <app-documentInfo [pageSize]="5" [showControlBar]=true [showNew]="true" pageTitle="Source Document Info"
        [displayedColumns]="['ADD','name','supportedDocTemplateType','docTemplateVersion','ACTIVE']"
        [showSelectColoumn]=true [showEnableColumn]=true multiSelect="false" instance="1" #sourceDocInfoService [showHeaderToolTips]=false>
      </app-documentInfo>
    </ng-container>

    <ng-container *ngIf="!orchestrationConfigsService.createTask && targetDocLoaded && !createVersion && !listVersion && !startNewTP">
      <app-documentInfo [pageSize]="5" [showControlBar]=true [showNew]="true" pageTitle="Target Document Info"
        [displayedColumns]="['ADD','name','supportedDocTemplateType','docTemplateVersion','ACTIVE']"
        [showSelectColoumn]=true [showEnableColumn]=true multiSelect="false" instance="2" #targetDocInfoService [showHeaderToolTips]=false>
      </app-documentInfo>
    </ng-container>

    <div class="container">
      <ng-container *ngIf="orchestration.orchestrationType == 'common' && !orchestrationConfigsService.createTask && !createVersion && !listVersion && !startNewTP">
        <header class="table-header">
          <h2>{{'relationships' | translate}}
            <button *ngIf="!hideRelatioshipTbl" class="btn btn-show-hide-table" (click)="toggleRelationshipTbl()" aria-label="Hide">
              <fa-icon [icon]="faMinus"></fa-icon>
            </button>
            <button *ngIf="hideRelatioshipTbl" class="btn btn-show-hide-table" (click)="toggleRelationshipTbl()" aria-label="Show">
              <fa-icon [icon]="faPlus"></fa-icon>
            </button>
          </h2>
        </header>
        <div *ngIf="!hideRelatioshipTbl">
          <div class="control-bar">
            <button  class="btn btn-micro btn-primary" (click)="newRelationship()" aria-label="Create new relationship">{{'new' | translate}}</button>
          </div>
          <div class="table-container">
            <ngx-skeleton-loader
            *ngIf="!loadingRelationshipList"
            count="5"
            [theme]="{'border-radius': '0px',
              height: '48px',
              width: '100%',
              margin: '1px',
              position: 'relative',
              top: '20px'
            }"></ngx-skeleton-loader>
            <table mat-table [dataSource]="orchestration.tagTpRelationship" *ngIf="orchestration.tagTpRelationship.length > 0">

              <ng-container matColumnDef="Requestor Trading Partner">
                <th mat-header-cell *matHeaderCellDef>{{'orchestration.relationship.reqTp' | translate}}  </th>
                <td mat-cell *matCellDef="let tpRel" class="name-cell">
                  <span *ngIf="tpRel.requestorTradingPartnerName">
                    {{tpRel.requestorTradingPartnerName}}
                  </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="Approver Trading Partner">
                <th mat-header-cell *matHeaderCellDef>{{'orchestration.relationship.appTp' | translate}}  </th>
                <td mat-cell *matCellDef="let tpRel" class="name-cell">
                  <span *ngIf="tpRel.approverTradingPartnerName">
                    {{tpRel.approverTradingPartnerName}}
                  </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="Delete">
                <th mat-header-cell *matHeaderCellDef>{{ 'delete.order' | translate}}</th>
                <td mat-cell *matCellDef="let tpRel" class="actions-cell">
                  <button class="btn btn-delete" (click)="deleteTpRelationship(tpRel)">
                    {{ 'delete.order' | translate}}
                  </button>
                </td>
              </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedTpRelationshipColumns"></tr>
                <tr mat-row *matRowDef="let tpRel; columns: displayedTpRelationshipColumns;"></tr>
            </table>
            <table mat-table [dataSource]="orchestration.tagTpCodeRelationship" *ngIf="orchestration.tagTpCodeRelationship.length > 0">

              <ng-container matColumnDef="From Code">
                <th mat-header-cell *matHeaderCellDef>{{'orchestration.relationship.from_code' | translate}}  </th>
                <td mat-cell *matCellDef="let codeRelationship" class="name-cell">
                  <span *ngIf="codeRelationship.fromCode">
                    {{codeRelationship.fromCode}}
                  </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="To Code">
                <th mat-header-cell *matHeaderCellDef>{{'orchestration.relationship.to_code' | translate}}  </th>
                <td mat-cell *matCellDef="let codeRelationship" class="name-cell">
                  <span *ngIf="codeRelationship.toCode">{{codeRelationship.toCode}}</span>
                </td>
              </ng-container>

              <ng-container matColumnDef="Delete">
                <th mat-header-cell *matHeaderCellDef>{{ 'delete.order' | translate}}</th>
                <td mat-cell *matCellDef="let codeRelationship" class="actions-cell">
                  <button class="btn btn-delete" type="submit" (click)="deleteCodeRelationship(codeRelationship)"
                   >{{ 'delete.order' | translate}}</button>
                </td>
              </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedCodeRelationshipColumns"></tr>
                <tr mat-row *matRowDef="let codeRelationship; columns: displayedCodeRelationshipColumns;"></tr>
            </table>
            <div *ngIf="orchestration.tagTpCodeRelationship.length <= 0 && orchestration.tagTpRelationship.length <= 0 && loadingRelationshipList" style="text-align:center">{{'no.relationships.created.yet' | translate}}</div>
            </div>
        </div>
      </ng-container>
    </div>

    <!-- <div class="container"> -->
      <ng-container *ngIf="!orchestrationConfigsService.createTask && !createVersion && !listVersion && !startNewTP">
        <ng-container *ngFor="let section of sections">
          <header class="table-header">
            <h2>{{section.title}} {{'tasks' | translate}}
              <button *ngIf="!section.hideTask" class="btn btn-show-hide-table" (click)="toggleTasksHide(section)" aria-label="Hide">
                <fa-icon [icon]="faMinus"></fa-icon>
              </button>
              <button *ngIf="section.hideTask" class="btn btn-show-hide-table" (click)="toggleTasksHide(section)" aria-label="Show">
                <fa-icon [icon]="faPlus"></fa-icon>
              </button>
            </h2>
          </header>
          <div *ngIf="!section.hideTask">
            <div class="control-bar">
              <button *ngIf="allowAddTask(section)" class="btn btn-micro btn-primary" (click)="createTask(section.type)" aria-label="Create new task">{{'new' | translate}}</button>
            </div>

            <div class="table-container">
              <ngx-skeleton-loader
              *ngIf="!getTaskList(section.type)"
              count="5"
              [theme]="{'border-radius': '0px',
                height: '48px',
                width: '100%',
                margin: '1px',
                position: 'relative',
                top: '20px'
              }"></ngx-skeleton-loader>
              <table mat-table [dataSource]="getTaskList(section.type)" *ngIf="getTaskListCount(section.type) > 0">

                <ng-container matColumnDef="Name">
                  <th mat-header-cell *matHeaderCellDef>{{'name' | translate}}  </th>
                  <!-- *ngIf="section.type === task.taskType" -->
                  <td mat-cell *matCellDef="let task" class="name-cell">
                    <span *ngIf="task.name">
                      <a title="{{task.name}}" (click)="editTask(task)">{{task.name}}</a>
                    </span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="ServiceParms">
                  <th mat-header-cell *matHeaderCellDef>{{ 'orchestrationConfigs.task.serviceParameters' | translate}} </th>
                  <td mat-cell *matCellDef="let task" class="serviceparms-cell">
                    <span *ngIf="task.serviceParms">{{task.serviceParms}}</span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="ContinueOnFailure">
                  <th mat-header-cell *matHeaderCellDef>{{ 'orchestrationConfigs.task.continue.on.failure' | translate}}</th>
                  <td mat-cell *matCellDef="let task" class="contOnFail-cell">
                    <span *ngIf="task.continueOnFailure != undefined">{{task.continueOnFailure}}</span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="Actions">
                  <th mat-header-cell *matHeaderCellDef> {{'actions' | translate}} </th>
                  <td mat-cell  *matCellDef="let task" class="actions-cell">
                    <fa-icon [icon]="faArrowUp" (click)="taskUp(task)"></fa-icon>&nbsp;&nbsp;
                    <fa-icon [icon]="faArrowDown" (click)="taskDown(task)"></fa-icon>&nbsp;&nbsp;
                    <!-- <fa-icon [icon]="faPencilAlt" (click)="editTask(task)"></fa-icon>&nbsp;&nbsp; -->
                    <fa-icon [icon]="faTimes" (click)="removeTask(task)" ></fa-icon>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let task; columns: displayedColumns;"></tr>
              </table>
              <div *ngIf="getTaskListCount(section.type) <= 0" style="text-align:center">{{'no' | translate}} {{section.type | lowercase}} {{'task.found' | translate}}</div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    <!-- </div> -->

    <ng-container *ngIf="!orchestrationConfigsService.createTask && streamDataLoaded && !createVersion && !listVersion && !startNewTP">
      <app-streams [pageSize]="5" [showControlBar]=true [showNew]="true" [displayedColumns]="['ADD','name','protocolType','streamType','direction']" showSelectColoumn=true showEnableColumn=true multiSelect="true" #streamService [showHeaderToolTips]=false></app-streams>
    </ng-container>

    <ng-container *ngIf="orchestrationConfigsService.createTask">
      <app-create-tasks taskType={{taskType}}></app-create-tasks>
    </ng-container>

    <ng-container *ngIf="createVersion">
      <div class="orchestrationVersionForm">
        <div class="col">
          <label for="orchestration-version-tag">{{ 'orchestrationConfigs.version.tag' | translate}}</label>
          <input placeholder="required" [(ngModel)]="orchestrationVersion.versionTag"
            id="orchestration-version-tag" type="text" value="" aria-label="Tag">

          <label for="orchestration-version-comments">{{ 'orchestrationConfigs.version.comments' | translate}}</label>
          <textarea placeholder="required" [(ngModel)]="orchestrationVersion.versionComments" id="orchestration-version-comments" value="" aria-label="Comments"></textarea>

          <mat-checkbox [(ngModel)]="orchestrationVersion.versionLock">{{ 'orchestrationConfigs.version.lock' | translate}}</mat-checkbox>
          <br>
          <div style="float: right">
            <button class="btn-secondary" (click)="back()" type="submit">{{ 'go.back' | translate}}</button>&nbsp;
            <button class="btn btn-primary" (click)="saveVersion()" type="submit">{{ 'save' | translate}}</button>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="listVersion">
      <header class="table-header">
        <h2>{{'available.versions' | translate}}</h2>
      </header>
      <div class="control-bar">
        <input type="text" placeholder="{{'orchestrationConfigs.version.tag' | translate}}" (keyup)="getSearchResults($event)"> <!--(keyup)="getSearchResults($event)" -->
      </div>
      <div class="table-container">
        <ngx-skeleton-loader
        *ngIf="!loadingVersionList"
        count="5"
        [theme]="{'border-radius': '0px',
          height: '48px',
          width: '100%',
          margin: '1px',
          position: 'relative',
          top: '20px'
        }"></ngx-skeleton-loader>
        <table mat-table [dataSource]="versionList" *ngIf="versionList.length > 0">

          <ng-container matColumnDef="Version Tag">
            <th mat-header-cell *matHeaderCellDef>{{'orchestrationConfigs.version.tag' | translate}}  </th>
            <!-- *ngIf="section.type === task.taskType" -->
            <td mat-cell *matCellDef="let version" class="name-cell">
              <span *ngIf="version.versionTag">
                {{version.versionTag}}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="Version Comments">
            <th mat-header-cell *matHeaderCellDef>{{'orchestrationConfigs.version.comments' | translate}}  </th>
            <td mat-cell *matCellDef="let version" class="name-cell">
              <span *ngIf="version.versionComments">{{version.versionComments}}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="Action">
            <th mat-header-cell *matHeaderCellDef>{{'action' | translate}}</th>
            <td mat-cell *matCellDef="let version" class="name-cell">
              <button class="btn btn-secondary" type="submit" (click)="applyVersion(version)">{{ 'apply' | translate}}</button>
            </td>
          </ng-container>

          <ng-container matColumnDef="State">
            <th mat-header-cell *matHeaderCellDef>{{'state' | translate}}</th>
            <td mat-cell *matCellDef="let version" class="name-cell">
              <span *ngIf="version.versionLock === true">
                <fa-icon [icon]="faLock" style="color: #00cc00;font-size: 1.5em"></fa-icon>
              </span>
              <span *ngIf="version.versionLock === false">
                <fa-icon [icon]="faUnlock" style="color: #337FFF;font-size: 1.5em"></fa-icon>
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="Delete">
            <th mat-header-cell *matHeaderCellDef>{{ 'delete' | translate}}</th>
            <td mat-cell *matCellDef="let version" class="name-cell">
              <button class="btn btn-delete" type="submit" (click)="deleteVersion(version)" [disabled]="version.versionLock">{{ 'delete' | translate}}</button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedVersionsColumns"></tr>
          <tr mat-row *matRowDef="let version; columns: displayedVersionsColumns;"></tr>
        </table>
        <div *ngIf="versionList.length <= 0 && loadingVersionList" style="text-align:center">{{'no.versions.found' | translate}}</div>
        </div>
      <div style="float: right; margin: 15px">
        <button class="btn btn-secondary" (click)="back()" type="submit">{{ 'go.back' | translate}}</button>&nbsp;
      </div>
    </ng-container>

    <ng-container *ngIf="startNewTP">
      <div class="orchestrationVersionForm">
        <div class="col">
          <label for="orchestration-version-tag">{{ 'orchestrationConfigs.relationship.type' | translate}}</label>
          <select [(ngModel)]="tpSelectedVal" name="orchestration-relationship" (change)="relationshipSelection($event)">
            <option [value]="1" [disabled]="orchestration.tagTpCodeRelationship.length > 0">{{'trading.partner.relationship' | translate}}</option>
            <option [value]="2" [disabled]="orchestration.tagTpRelationship.length > 0">{{'code.relationship' | translate}}</option>
          </select> <!--(keyup)="getSearchResults($event)" -->
        </div>
      </div>
      <ng-container *ngIf="tpSelectedVal == '1'">
        <app-tradingPartners [pageSize]="5" [showSelectColoumn]=true instance="1"
          [displayedColumns]="['ADD','name','description','codes','ACTIVE']"
          [showEnableColumn]=true multiSelect="false" #tradingPartners [showHeaderToolTips]=false>
        </app-tradingPartners>

        <app-tradingPartnerRelationships *ngIf="orchestrationConfigsService.getTradingPartner()" [pageSize]="5"
          instance="2" [showSelectColoumn]=true [showEnableColumn]=true multiSelect="false"
          [displayedColumns]="['ADD','requestorTradingPartnerName','approverTradingPartnerName','description','ACTIVE']"
          addnQueryParam=[tradingPartnerId::{{orchestrationConfigsService.getTradingPartner().id}}] #tradingPartnerRelationship [showHeaderToolTips]=false>
        </app-tradingPartnerRelationships>

        <div style="float: right; margin: 15px;">
          <button class="btn btn-secondary" (click)="back()" type="submit">{{ 'go.back' | translate}}</button>&nbsp;
          <button class="btn btn-primary" (click)="saveTpRelationship()" type="submit" [disabled]="!orchestrationConfigsService.getTradingPartnerRelationship()">{{ 'continue' | translate}}</button>
        </div>
      </ng-container>

      <ng-container *ngIf="tpSelectedVal == '2'">
        <app-tradingPartnerCodes [pageSize]="5" [showSelectColoumn]=true [showNew]=true
          [displayedColumns]="['ADD','code','qualifier','ACTIVE']"
          [showEnableColumn]=true multiSelect="false" #tpCodes [showHeaderToolTips]=false>
        </app-tradingPartnerCodes>

        <app-codeRelationships *ngIf="orchestrationConfigsService.getTpCode()" [pageSize]="5"
          [showSelectColoumn]=true [showEnableColumn]=true multiSelect="false" [showNew]=false
          [displayedColumns]="['ADD','fromCode','toCode','controlNumber','description','ACTIVE']"
          addnQueryParam=[fromCodeId::{{orchestrationConfigsService.getTpCode().id}}] #tpCodeRelationship [showHeaderToolTips]=false>
        </app-codeRelationships>

        <div style="float: right; margin: 15px;">
          <button class="btn btn-secondary" (click)="back()" type="submit">{{ 'go.back' | translate}}</button>&nbsp;
          <button class="btn btn-primary" (click)="saveCodeRelationship()" type="submit" [disabled]="!orchestrationConfigsService.getTpCodeRelationship()">{{ 'continue' | translate}}</button>
        </div>
      </ng-container>
    </ng-container>
    </div>

     <ng-container *ngIf="orchestration.id">
     <div  style="height: 110vh;">
        <app-audits *ngIf="auditFlag  && auditRefreshFlag && iotService.auditScriptFlag==false" [showNew]="false" [showControlBar]=false [displayedColumns]="['creation','requestorId','actionType','id']" showSelectColoumn=false showEnableColumn=false addnQueryParam=[resourceId::{{orchestration.id}}] [showHeaderToolTips]=false></app-audits>
        <app-audits *ngIf="auditFlag  && !auditRefreshFlag && iotService.auditScriptFlag==false" [showNew]="false" [showControlBar]=false [displayedColumns]="['creation','requestorId','actionType','id']" showSelectColoumn=false showEnableColumn=false addnQueryParam=[resourceId::{{orchestration.id}}] [showHeaderToolTips]=false></app-audits>
        <div class="close-btn" *ngIf="auditFlag && iotService.auditScriptFlag==false">
          <br>
          <button (click)="closeAudit()">{{'close' | translate}}</button>
       </div>
       <div  *ngIf="iotService.auditScriptFlag" style="margin: 0 5px 0 5px;">
              <app-ace></app-ace>
       </div>
      <div *ngIf="iotService.auditScriptFlag" class="orchestrationAudit">
          <button class="goBack" mat-raised-button color="primary" (click)="goBackAuditList()">{{ 'go.back' | translate}}</button>
          <br>
       </div>
    </div>
          <br>
       </ng-container>
</mat-sidenav-container>

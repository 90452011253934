/** 
 * This is an auto generated code for ecosystemBytemplates service listing page.
 * Please don't make any change to this file.
 * @version 1.0.0 
 * @readonly 
 * @author - System generated 
 */

import { Injectable } from '@angular/core';
import { SelectionModel} from '@angular/cdk/collections';
import { BaseListService } from './BaseList.service';
import { IotService } from '../service/iot.service'; 
@Injectable({ 
	  providedIn: 'root' 
})

export abstract class ListEcosystemBytemplatesService extends BaseListService {
	 constructor(public iotService:IotService) {
		 super(iotService);
	 }
} 

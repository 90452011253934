import { Component, OnInit } from '@angular/core';
import { IotService } from '../service/iot.service';
import { Location } from '@angular/common';
import {Router} from '@angular/router';
import { faToggleOff,faToggleOn,faAngleDoubleLeft, faAngleDoubleRight, faCheck, faCopy,faTimes, faExclamation, faCheckCircle, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import { SideNavComponent } from '../side-nav/side-nav.component';
import {MatChipInputEvent} from '@angular/material/chips';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { ActivatedRoute } from '@angular/router';
import { DeviceService } from '../service/device.service';
import { TranslateService } from '@ngx-translate/core';
import { DigitalTwinTemplatesService } from '../service/digitalTwinTemplates.service';
import { throwMatDuplicatedDrawerError } from '@angular/material/sidenav';


@Component({
  selector: 'app-template-detail',
  templateUrl: './template-detail.component.html',
  styleUrls: ['./template-detail.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class TemplateDetailComponent implements OnInit {

  separatorKeysCodes: number[] = [ENTER, COMMA];
  
  faToggleOffIcon = faToggleOff;
  faToggleOnIcon = faToggleOn;
  faAngleDoubleLeftIcon = faAngleDoubleLeft;
  faAngleDoubleRightIcon = faAngleDoubleRight;
  faCheckIcon = faCheck;
  faCopyIcon = faCopy;
  
  icon = this.faToggleOnIcon;
  toggleEnable = 'toggle-on';
  deviceTemplate;
  breakpoint: number;
  deviceTemplateActive: boolean = true;
  showReferences:boolean=false;
  tags:string[]=[];
  attributeData=[];
  attributeColumns: string[] = ['Name', 'Data Type', 'Value'];
  eventData=[];
  eventColumns:string[] = ['Name','Description','Data Point','Data Type'];
  previousEventElement;
  previousCommandElement;
  commandData=[];
  commandColumns:string[] = ['Name','Description','Arguments'];
  geofenceData=[];
  geofenceColumns:string[] = ['Name'];
  dataSource =[];
  base64String:string;
  tagClass = 'tagInput';
  tagList =[];
  geoResponse=[];
  geoTableData=[];
  supportLanguages = ['en'];
  showCommandFlag:boolean = false;
  showEventFlag:boolean = false;
  deviceTemplateForUpdate;
  displayedColumns: string[] = [ 'name', 'type'];
  faCloseIcon = faTimes;
  faCriticalIcon = faExclamation;
  faWarningIcon = faExclamationTriangle;
  faSuccessIcon = faCheckCircle;
  geofencesLoaded=false;
  eventsLoaded=false;
  commandsLoaded=false;
  attributesLoaded=false;
  events= [];
  commands=[];
  updatedVersion;
  auditFlag=false;
  auditRefreshFlag=true;
  constructor(private router: Router, private route: ActivatedRoute, private iotService:IotService,
    private location:Location,private sideNavComponent:SideNavComponent,private deviceService:DeviceService,
    private translateService: TranslateService,public digitalTwinTemplatesService:DigitalTwinTemplatesService) {
      this.translateService.addLangs(this.supportLanguages);
      this.translateService.setDefaultLang('en');
     }
  
  ngOnInit(): void {
  this.iotService.digitalTwinTemplate=true
  this.digitalTwinTemplatesService.resetErrorMessage();
  this.digitalTwinTemplatesService.resetSuccessMessage();
  this.digitalTwinTemplatesService.resetWarningMessage();

    if(window.innerWidth <= 1200 && window.innerWidth >650){
      this.breakpoint=2;
    } 
    else if(window.innerWidth <= 650){
      this.breakpoint=1;
    }
    else{
      this.breakpoint=4;
    }
    this.route.params.subscribe(params => {
      console.log(params['id']);
      var deviceTemplateId = params['id'];
      this.loadDeviceTemplate(deviceTemplateId);
    });
     
  }

  updateDeviceTemplate(deviceTemplateId,deviceTemplateForUpdate){
    const that = this;
    console.log(that.deviceTemplate)
  //  that.loadDeviceTemplateWithoutChildren(deviceTemplateId);
 //   var deviceTemplateForUpdate = this.deviceTemplate;
    that.iotService.getCuiObjResponse().updateDeviceTemplate({
      deviceTemplateId: deviceTemplateId,
      data: deviceTemplateForUpdate
    }).then(function(response) {
      that.getDeviceTemplate(deviceTemplateId);
      console.log("updated Device template"+response);
      that.deviceTemplateForUpdate.version=that.deviceTemplate.version;
      that.updatedVersion = response.version;
      if(response.confirmationRequired!=false){
        that.dataSource=response.associations.associatedResources;
        that.showReferences = true;
      }else{
        that.digitalTwinTemplatesService.successMessage = "Device Template updated successfully.";
        // that.getDeviceTemplate(deviceTemplateId);
      }
      //console.log("device"+that.device);
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.digitalTwinTemplatesService.errorMessage = err.responseJSON.apiMessage;
      } else{
        alert("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  confirmMutate(deviceTemplateId){
    const that = this;
    that.deviceTemplateForUpdate['mutate']=true;
//    that.deviceTemplateForUpdate['version']=that.updatedVersion;
    that.iotService.getCuiObjResponse().updateDeviceTemplate({
      deviceTemplateId: deviceTemplateId,
      data: that.deviceTemplateForUpdate
    }).then(function(response) {
      that.getDeviceTemplate(deviceTemplateId);
      console.log("updated Device template"+response);
      that.deviceTemplateForUpdate.version=response.version;
      that.digitalTwinTemplatesService.successMessage = "Device Template updated successfully.";

      that.showReferences = false;
      // that.loadDeviceTemplate(deviceTemplateId);
      //console.log("device"+that.device);
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        that.digitalTwinTemplatesService.errorMessage=err.responseJSON.apiMessage;
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else{
        alert("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  cancel(){
    this.showReferences=false;
  }
  ngOnDestroy():void{
   this.iotService.digitalTwinTemplate=false;
   this.iotService.auditScriptFlag = false;
   this.iotService.auditscript="";

  const that=this;
  let event = this.digitalTwinTemplatesService.getEvents();
  that.digitalTwinTemplatesService.setAttributes([]);
  that.digitalTwinTemplatesService.setEvents([]);
  that.digitalTwinTemplatesService.setCommands([]);
  that.digitalTwinTemplatesService.setGeofences([]);
  that.digitalTwinTemplatesService.resetErrorMessage();
  that.digitalTwinTemplatesService.resetSuccessMessage();
  that.digitalTwinTemplatesService.resetWarningMessage();
  that.auditFlag=false;
  }
  onResize(event) {
    if(event.target.innerWidth <= 1200 && event.target.innerWidth >650){
      this.breakpoint=2;
    } 
    else if(event.target.innerWidth <= 650){
      this.breakpoint=1;
    }
    else{
      this.breakpoint=4;
    }
  }

  loadDeviceTemplateWithoutChildren(deviceTemplateId){
    console.log(deviceTemplateId);
    const that = this;
    that.iotService.getCuiObjResponse().getDeviceTemplateDetailsWithoutChildren({
      deviceTemplateId: deviceTemplateId
    }).then(function(response) {
      console.log(response);
      var name=that.deviceTemplate.name[0].text;
      var description=that.deviceTemplate.description[0].text;
      that.deviceTemplateForUpdate = response;   
      if(!that.deviceTemplateForUpdate.description){
        that.deviceTemplateForUpdate.description = [];
        that.deviceTemplateForUpdate.description.push({ lang: 'en-us', text: "" });
      }
      that.deviceTemplateForUpdate.name[0].text= name;
      that.deviceTemplateForUpdate.description[0].text= description;
      // that.deviceTemplateForUpdate.tags=that.tags;
 //     that.deviceTemplateForUpdate.attributeTypes = that.digitalTwinTemplatesService.getAttributes();
      that.deviceTemplateForUpdate.attributeTypes =[];
      that.deviceTemplateForUpdate.eventTemplates =[];
      that.deviceTemplateForUpdate.commandTemplates=[];
      if(that.digitalTwinTemplatesService.getAttributes().length>0){
        that.digitalTwinTemplatesService.getAttributes().forEach(element => {
          that.deviceTemplateForUpdate.attributeTypes.push(element.id);
        });
      }
      
      console.log(that.digitalTwinTemplatesService.getEvents());
      if(that.digitalTwinTemplatesService.getEvents().length>0){
      that.digitalTwinTemplatesService.getEvents().forEach(element => {
        that.deviceTemplateForUpdate.eventTemplates.push(element.id);
      });
     }

     if(that.digitalTwinTemplatesService.getCommands().length>0){

      that.digitalTwinTemplatesService.getCommands().forEach(element => {
        that.deviceTemplateForUpdate.commandTemplates.push(element.id);
      });
    }
      that.updateDeviceTemplate(deviceTemplateId,that.deviceTemplateForUpdate);
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        alert("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  getDeviceTemplate(deviceTemplateId){
    console.log(deviceTemplateId);
    const that = this;
    this.iotService.getCuiObjResponse().getDeviceTemplate({
      deviceTemplateId: deviceTemplateId
    }).then(function(response) {
      console.log(response);
      that.deviceTemplate = response;   
    }).fail(function (err){
        if(err.responseJSON.apiStatusCode){
          alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
        } else{
          alert("Something went wrong. Please check chrome console or check with dev team");
        }
      });
  }

  loadDeviceTemplate(deviceTemplateId) {
    console.log(deviceTemplateId);
    const that = this;
    this.iotService.getCuiObjResponse().getDeviceTemplate({
      deviceTemplateId: deviceTemplateId
    }).then(function(response) {
      console.log(response);
      that.deviceTemplate = response;   
      if(!that.deviceTemplate.description){
        that.deviceTemplate.description = [];
        that.deviceTemplate.description.push({ lang: 'en-us', text: "" });
      }
      that.deviceTemplateActive = that.deviceTemplate.isActive;
      if(that.deviceTemplate.isActive) {
        that.icon = that.faToggleOnIcon;
        that.toggleEnable = 'toggle-on';
      } else {
        that.icon = that.faToggleOffIcon;
        that.toggleEnable = 'toggle-off';
      }
      
      that.digitalTwinTemplatesService.setDigitalTwinTemplateId(that.deviceTemplate.id);
      if(that.deviceTemplate.attributeTypes){
        that.attributeData = that.deviceTemplate.attributeTypes;
      }       
      that.digitalTwinTemplatesService.setAttributes(that.attributeData);

      if(that.deviceTemplate.eventTemplates){
        that.events=that.deviceTemplate.eventTemplates;
      }    
      that.digitalTwinTemplatesService.setEvents(that.events);

      if(that.deviceTemplate.commandTemplates){
        that.commands=that.deviceTemplate.commandTemplates;
      }     
      that.digitalTwinTemplatesService.setCommands(that.commands);
      that.attributesLoaded=true;
      that.eventsLoaded=true;
      that.commandsLoaded=true;
      that.loadEventData(that.deviceTemplate);
   //   that.loadCommandData(that.deviceTemplate);
     that.loadGeofence(that.deviceTemplate);

      that.sideNavComponent.menuClear();
      that.sideNavComponent.menuChange('Dashboard','sub-type');
      that.sideNavComponent.menuChange('templates.header','sub-type');
      that.sideNavComponent.menuChange(that.deviceTemplate.name[0].text,'sub-type');
      console.log(that.digitalTwinTemplatesService.getEvents())
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        alert("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  loadGeofence(deviceTemplate) {
    const that = this;
    that.geofenceData = [];
    
    that.iotService.getCuiObjResponse().searchGeofenceResourceAssociation({
      resourceId: deviceTemplate.id,
      resourceType: "DEVICETEMPLATE"
    }).then(function(deviceGeoResponse) {
      if(deviceGeoResponse.length != 0) {
        var length=deviceGeoResponse.length ;
        var itr=0;
        deviceGeoResponse.forEach(element => {
          that.digitalTwinTemplatesService.associationMap.set(element.geofenceId,element.id);
          that.iotService.getCuiObjResponse().getGeofenceNoPoly({
            id: element.geofenceId,
          }).then(function(geoResponse) {
            if(geoResponse) {

            
              itr++;
              console.log("geofence Device data"+geoResponse);
              that.geoResponse.push(geoResponse);
              var obj ={
               "name":[
                  {
                      "lang":"en_US",
                      "text":geoResponse.name[0].text,
                  }
              ],
                "state":element.state,
                "geofenceId":element.geofenceId,
                "id":element.geofenceId,
              }
              that.geoTableData.push(obj); 
              that.geofenceData=that.geoTableData;
              that.digitalTwinTemplatesService.setGeofences(that.geofenceData);
              if(itr==length){
                that.geofencesLoaded=true;
                console.log("*************************** association Map******************"+ JSON.stringify(that.digitalTwinTemplatesService.associationMap));
              }
            
            }
          })
        });
      }
      else{        
        that.digitalTwinTemplatesService.setGeofences([]);
        that.geofencesLoaded=true;
      }
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        alert("Something went wrong. Please check chrome console or check with dev team");
      }
    }); 
    setTimeout(()=>{ 
      //that.geofenceData=that.geoTableData;
    }, 5000);
  }

  loadEventData(deviceTemplate){
    const that = this;
    that.eventData = [];
    if(deviceTemplate.eventTemplates && deviceTemplate.eventTemplates.length != 0) {
      deviceTemplate.eventTemplates.forEach(eventTemplate => {
        var obj ={
          "eventTemplate":eventTemplate,
          "messageClass": 'bg-message'
        }
        that.eventData.push(obj); 
           
      });
    }
  //  that.digitalTwinTemplatesService.setEvents(that.eventData);
    that.showEventFlag=true;
  } 

  loadCommandData(deviceTemplate){
    const that = this;
    that.commandData = [];
    if(deviceTemplate.commandTemplates && deviceTemplate.commandTemplates.length != 0) {
      deviceTemplate.commandTemplates.forEach(commandTemplate => {          
        var obj ={
          "commandTemplate":commandTemplate,
          "messageClass": 'bg-message'
        }
        that.commandData.push(obj); 
        
      });
    }
  //  that.digitalTwinTemplatesService.setCommands(that.commandData);
    that.showCommandFlag=true;

  } 

  toggleIcon(deviceTemplateId) {
    const that = this;
    if (that.icon === that.faToggleOffIcon) {
      that.icon = that.faToggleOnIcon;
      that.toggleEnable = 'toggle-on';
      that.iotService.getCuiObjResponse().activateDeviceTemplate({
        deviceTemplateId: deviceTemplateId
      }).then(function(response) {
        console.log("activate Device");
        that.deviceTemplate.version = parseInt(that.deviceTemplate.version) + 1;
        that.deviceTemplate.isActive = true;
        //that.loadDevice(deviceId);
      }).fail(function (err){
        if(err.responseJSON.apiStatusCode){
          alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
        } else{
          alert("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    } else {
      that.icon = that.faToggleOffIcon;
      that.toggleEnable = 'toggle-off';
      that.iotService.getCuiObjResponse().deactivateDeviceTemplate({
        deviceTemplateId: deviceTemplateId
      }).then(function(response) {
        console.log("deactivate Device");
        that.deviceTemplate.version = parseInt(that.deviceTemplate.version) + 1;
        that.deviceTemplate.isActive = false;
        //that.loadDevice(deviceId);
      }).fail(function (err){
        if(err.responseJSON.apiStatusCode){
          alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
        } else{
          alert("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    }
  }

  add(event: MatChipInputEvent): void {
    this.tagClass = 'tagInput';
    const input = event.input;
    const value = event.value;
    var indice =0;
    if(this.deviceTemplate.tags != undefined){
      indice = this.deviceTemplate.tags.indexOf(value);
    }else{
      indice = -1;
    }
    if(indice<0){
    if ((value || '').trim()) {
      const that = this;
      that.iotService.getCuiObjResponse().createDeviceTemplateTag({
        deviceTemplateId: that.deviceTemplate.id,
        tag: value
      }).then(function(response) {
        console.log("added tag:"+value+" to deviceTemplate :"+that.deviceTemplate.id);
          that.deviceTemplate.version = parseInt(that.deviceTemplate.version) + 1;
          if(that.deviceTemplate.tags == undefined) {
            that.deviceTemplate.tags = [];
          }
          const index = that.deviceTemplate.tags.indexOf(value);
          if (index < 0) {
            that.deviceTemplate.tags.push(value);
            input.value = '';
          }
         
      }).fail(function (err){
        if(err.responseJSON.apiStatusCode){
          alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
        } else{
          alert("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    }
  }else{
    this.tagClass = 'tagInput-duplicate';
  }
  }

  remove(tag: string): void {
    const that = this;
    if(tag != undefined){
      that.iotService.getCuiObjResponse().deleteDeviceTemplateTag({
        deviceTemplateId: that.deviceTemplate.id,
        tag: tag
      }).then(function (response) {
          console.log("removed tag:"+tag+" to deviceTemplate :"+that.deviceTemplate.id);
          that.deviceTemplate.version = parseInt(that.deviceTemplate.version) + 1;

          const index = that.deviceTemplate.tags.indexOf(tag);

          if (index >= 0) {
            that.deviceTemplate.tags.splice(index, 1);
          }

      }).fail(function (err){
        if(err.responseJSON.apiStatusCode){
          alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
        } else{
          alert("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    }
  }

  commandMessage(command,expandedElement) {
    console.log("command message -- "+expandedElement);
    const that = this;
    if(that.previousCommandElement != undefined && that.previousCommandElement.commandTemplate.id != command.commandTemplate.id) {
      that.previousCommandElement.messageClass = 'bg-message';
    }
    var base64 = {
        "messageId": "Device's message id here",
        "deviceId": "DeviceID id here",
        "commandId": command.commandTemplate.id,
        "latitude": "valid latitude",
        "longitude": "valid longitude"
    };
    command.commandTemplate.args.forEach(value => {
        if (value.type == 'string') { base64[value.name] = "string" };
        if (value.type == 'decimal') { base64[value.name] = 1.1 };
        if (value.type == 'bool') { base64[value.name] = true };
        if (value.type == 'integer') { base64[value.name] = 1 };
    });
    if(expandedElement === null) {
      command.messageClass = 'bg-message';
    } else {
      command.messageClass = 'bg-message-selected';
    }
    that.previousCommandElement = command;
    that.base64String = JSON.stringify(base64, null, 2);
  }

  eventMessage(event,expandedElement) {
    console.log("event message -- "+expandedElement);
    const that = this;
    if(that.previousEventElement != undefined && that.previousEventElement.eventTemplate.id != event.eventTemplate.id) {
      that.previousEventElement.messageClass = 'bg-message';
    }
    var base64 = {
        "messageId": "Device's message id here",
        "deviceId": "DeviceID id here",
        "eventTemplateId": event.eventTemplate.id,
        "latitude": "valid latitude",
        "longitude": "valid longitude"
    };

    event.eventTemplate.eventFields.forEach(value => {
        if (value.type == 'string') { base64[value.name] = "string" };
        if (value.type == 'decimal') { base64[value.name] = 1.1 };
        if (value.type == 'bool') { base64[value.name] = true };
        if (value.type == 'integer') { base64[value.name] = 1 };
    });
    if(expandedElement === null) {
      event.messageClass = 'bg-message';
    } else {
      event.messageClass = 'bg-message-selected';
    }
    that.previousEventElement = event;
    that.base64String = JSON.stringify(base64, null, 2);
  }

  cloneDeviceTemplate() {
    const that = this;
    console.log("that.deviceTemplate.id :: "+that.deviceTemplate.id);
    that.deviceService.templateSelect = false;
    that.deviceService.createNewTemplate=true;
    that.deviceService.resetErrorMessage();
    that.deviceService.resetSuccessMessage();
    that.deviceService.resetWarningMessage();
    that.deviceService.setSelectedTemplate(undefined);
    
    that.deviceService.setDeviceName("Clone of " +that.deviceTemplate.name[0].text);
    if(that.deviceTemplate.description!=undefined){
      that.deviceService.setDeviceDescription(that.deviceTemplate.description[0].text);
    }
      that.deviceService.setTemplateName(undefined);
      that.deviceService.setAttributeData(that.deviceTemplate.attributeTypes);
      that.deviceService.setCommandData(that.deviceTemplate.commandTemplates);
      that.deviceService.setEventData(that.deviceTemplate.eventTemplates);
      that.deviceService.setGeofenceData(that.geoResponse);

      that.deviceService.setTemplateName(undefined);
      if(that.deviceTemplate.tags!=undefined){
        that.deviceService.setDeviceTags(that.deviceTemplate.tags);
      }
      that.deviceService.attributeSelect = true;
      that.deviceService.eventSelect = true;
      that.deviceService.commandSelect = true;
      that.deviceService.templateSelect = true;
      that.deviceService.geofenceSelect = true;
      that.deviceService.deviceNameSelect = true;
      console.log("Geofenc data"+that.deviceService.getGeofenceData())
    that.router.navigateByUrl("/createNewDigitalTwinTemplate/copy/"+that.deviceTemplate.id);
  }
  
  clone() {
    const that = this;

    var newAttributeTypes = [];
    if(that.deviceTemplate.attributeTypes && that.deviceTemplate.attributeTypes.length != 0) {
      that.deviceTemplate.attributeTypes.forEach(element => {
        newAttributeTypes.push(element.id);
      });
    }
    
    var newEventTemplates = [];
    if(that.deviceTemplate.eventTemplates && that.deviceTemplate.eventTemplates.length != 0) {
      that.deviceTemplate.eventTemplates.forEach(element => {
        newEventTemplates.push(element.id);
      });
    }
    
    var newCommandTemplates = [];
    if(that.deviceTemplate.commandTemplates && that.deviceTemplate.commandTemplates.length != 0) {
      that.deviceTemplate.commandTemplates.forEach(element => {
        newCommandTemplates.push(element.id);
      });
    }
    
    var cloneDeviceTemplate = {
      "creator": that.iotService.getCreator(),
      "creatorAppId": that.iotService.getCreatorAppId(),
      "realm": that.iotService.getRealm(),
      'name': that.deviceTemplate.name,
      'description': that.deviceTemplate.description,
      'attributeTypes': newAttributeTypes,
      'eventTemplates': newEventTemplates,
      'commandTemplates': newCommandTemplates
    }
    that.iotService.getCuiObjResponse().createDeviceTemplate({
      data: cloneDeviceTemplate
    }).then(function (response) {

      // activate for newly created deviceTemplate
      if(that.deviceTemplate.isActive === true) {
        that.iotService.getCuiObjResponse().activateDeviceTemplate({
          deviceTemplateId: response.id
        }).then(function (activateResponse) {
          console.log("deviceTemplate id : "+response.id+" is activated");
        })
      }

      // Save Tags for newly created deviceTemplate
      if(that.tags != []) {
        that.tags.forEach(element => {
          that.iotService.getCuiObjResponse().createDeviceTemplateTag({
              deviceTemplateId: response.id,
              tag: element
          }).then(function (response) {
            console.log("Added tag : "+element);
          })
        });
      }

      // add geofence assosciations.
      if(that.geofenceData != []) {

        that.geofenceData.forEach(element => {

          var geofenceAssociation = {
            "creator": that.iotService.getCreator(),
            "creatorAppId": that.iotService.getCreatorAppId(),
            "realm": that.iotService.getRealm(),
            "resourceId": response.id,
            "resourceType": "DEVICETEMPLATE",
            "geofenceId": element.geofenceId,
            "isActive": true
          }
          that.iotService.getCuiObjResponse().createGeofenceResourceAssociation({
            data: geofenceAssociation
  
          }).then(function (response) {
            console.log("Added geofenceAssociation for geofenceId : "+element.geofenceId);
          })
  
        });
      }

      that.router.navigateByUrl("/templates/"+response.id);
      
    }).fail(function (err) {
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
      if(err === null) {
        console.log("Error in createCommandTemplate");
      }
    });
  }

  auditDeviceTemplate(){
    this.auditFlag=true;
    this.auditRefreshFlag=!this.auditRefreshFlag;  
  }
  closeAudit(){
    this.auditFlag=false;
  }
  goBackAuditList() {
    const that=this;
    that.iotService.auditScriptFlag = false;
    that.iotService.auditscript="";
    that.auditFlag = true;
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StreamService {


  streamName;
  direction;
  streamType;
  ownerId;
  protocolType;
  customFTPprotocolType;
  customCAPSprotocolType;
  protocolSecurityType;
  payloadSecurityType;
  additionalSettingFlag: boolean = false;
  streamDocInfoDocType = '';
  streamDocInfoVersion = '';
  streamDocInfoTemplate = '';
  streamDocInfo: boolean = false;
  streamSSHKeyPair: boolean = false;
  selectedDocInfos: boolean = false;
  drsScriptFlag: boolean = false;
  fromCode: boolean = false;
  fromCode2: boolean = false;
  toCode: boolean = false;
  toCode2: boolean = false;
  fromCodeValue;
  fromCodeValue2;
  toCodeValue;
  toCodeValue2;
  showCodeExample: boolean = true;
  example = 'Code Examples';
  streamResponseProducerTopic;
  streamResponseClientId;
  streamResponseUsername;
  streamResponsePassword;
  streamResponseHost;
  streamResponsePort;
  streamResponseBasicAuthnHost;
  streamResponseBasicAuthnPort;
  streamResponseSendingPostMessageUri;
  streamEncryptionKey;
  docInfo2;
  selectedDocInfos2: boolean = false;
  docInfoId;
  sshKeyPairObject: any;
  sshKeyPair: any;
  sshKeyPairId: any;
  mutualAuth:any;
  mutualAuthObject:any;
  mutualAuthId : any;
  OAuthToken:any;
  OAuthTokenId:any;
  muAuthObject:any;
  createStreamFlag: boolean = false;;
  showListStream: boolean = false;;
  streamCreated: boolean = false;;
  x509ErrorMessage: boolean = false;
  streamNamesArray = [];
  docInfoList = false;
  warningMessage: string;
  errorMessage: string;
  DRSscriptBase64Encoded;
  DRSscript;
  deliveryScript;
  deliveryScriptBase64Encoded;
  createNewStreamFlag: boolean = false;;
  consumerTopic;
  applicationSelected;
  selectedApplication: boolean = false;
  selectedDevice:boolean = false;
  deviceSelected;
  docinfoResponse;
  viewStreamFlag:boolean = false;
  viewDeliveryScript;
  viewDeliveryScriptStreamFlag:boolean = false;
  viewDRSFlag:boolean = false;
  viewDRScriptStreamFlag:boolean = false;
  viewDRSScript;
  oAuthTokenObject;


  codeExamples = [
    {
      id: 1,
      example: 'Code Examples'
    },
    {
      id: 2,
      example: 'Active Receiving Sync Loop back request'
    },
    {
      id: 3,
      example: 'Active Receiving Sync Post with request and response'
    },
    {
      id: 4,
      example: 'Active Receiving Post with dynamic header values'
    },
    {
      id: 5,
      example: 'Active Receiving Post with dynamic path params'
    },
    {
      id: 6,
      example: 'Passive Sending GET with dynamic path params'
    }
  ];

  constructor() { }

  setStreamName(streamName) {
    this.streamName = streamName;
  }

  getStreamName() {
    return this.streamName;
  }

  setDirection(direction) {
    this.direction = direction;
  }

  getDirection() {
    return this.direction;
  }

  setStreamType(streamType) {
    this.streamType = streamType;
  }

  getStreamType() {
    return this.streamType;
  }
  setOwnerId(ownerId) {
    this.ownerId = ownerId;
  }

  getOwnerId() {
    return this.ownerId;
  }

  setProtocolType(protocolType) {
    this.protocolType = protocolType;
  }

  getProtocolType() {
    return this.protocolType;
  }

  setProtocolSecurityType(protocolSecurityType) {
    this.protocolSecurityType = protocolSecurityType;
  }

  getProtocolSecurityType() {
    return this.protocolSecurityType;
  }

  setPayloadSecurityType(payloadSecurityType) {
    this.payloadSecurityType = payloadSecurityType;
  }

  getPayloadSecurityType() {
    return this.payloadSecurityType;
  }

  setStreamDocInfoDocType(streamDocInfoDocType) {
    this.streamDocInfoDocType = streamDocInfoDocType;
  }

  getStreamDocInfoDocType() {
    return this.streamDocInfoDocType;
  }

  setStreamDocInfoVersion(streamDocInfoVersion) {
    this.streamDocInfoVersion = streamDocInfoVersion;
  }

  getStreamDocInfoVersion() {
    return this.streamDocInfoVersion;
  }

  setStreamDocInfoTemplate(streamDocInfoTemplate) {
    this.streamDocInfoTemplate = streamDocInfoTemplate;
  }

  getStreamDocInfoTemplate() {
    return this.streamDocInfoTemplate;
  }

  setCustomFTPprotocolType(customFTPprotocolType) {
    this.customFTPprotocolType = customFTPprotocolType;
  }

  getCustomFTPprotocolType() {
    return this.customFTPprotocolType;
  }
  setCustomCAPSprotocolType(customCAPSprotocolType) {
    this.customCAPSprotocolType = customCAPSprotocolType;
  }

  getCustomCAPSprotocolType() {
    return this.customCAPSprotocolType;
  }
  setFromCodeValue(fromCodeValue) {
    this.fromCodeValue = fromCodeValue;
  }
  getFromCodeValue() {
    return this.fromCodeValue;
  }

  setToCodeValue(toCodeValue) {
    this.toCodeValue = toCodeValue;
  }
  getToCodeValue() {
    return this.toCodeValue;
  }

  setExample(example) {
    this.example = example;
  }
  getExample() {
    return this.example;
  }

  setStreamResponseProducerTopic(streamResponseProducerTopic) {
    this.streamResponseProducerTopic = streamResponseProducerTopic;
  }
  getStreamResponseProducerTopic() {
    return this.streamResponseProducerTopic;
  }

  setStreamResponseClientId(streamResponseClientId) {
    this.streamResponseClientId = streamResponseClientId;
  }
  getStreamResponseClientId() {
    return this.streamResponseClientId;
  }

  setStreamResponseUsername(streamResponseUsername) {
    this.streamResponseUsername = streamResponseUsername;
  }
  getStreamResponseUsername() {
    return this.streamResponseUsername;
  }

  setStreamResponsePassword(streamResponsePassword) {
    this.streamResponsePassword = streamResponsePassword;
  }
  getStreamResponsePassword() {
    return this.streamResponsePassword;
  }

  setStreamResponseHost(streamResponseHost) {
    this.streamResponseHost = streamResponseHost;
  }

  getStreamResponseHost() {
    return this.streamResponseHost;
  }

  setStreamResponsePort(streamResponsePort) {
    this.streamResponsePort = streamResponsePort;
  }

  getStreamResponsePort() {
    return this.streamResponsePort;
  }

  setDocInfoId(docInfoId) {
    this.docInfoId = docInfoId;
  }

  getDocInfoId() {
    return this.docInfoId;
  }

  setSSHKeyPair(sshKey: any) {
    this.sshKeyPair = sshKey;
  }

  public getSshKeyPairObject(): any {
    return this.sshKeyPairObject;
  }
  public setSshKeyPairObject(value: any) {
    this.sshKeyPairObject = value;
  }
  
  getSSHKeyPair() {
    return this.sshKeyPair;
  }

  setMutualAuth(mutualAuth: any) {
    this.mutualAuth = mutualAuth;
  }

  getMutualAuth() {
    return this.mutualAuth;
  }

  setMutualAuthObject(mutualAuth: any) {
    this.mutualAuthObject = mutualAuth;
  }

  getMutualAuthObject() {
    return this.mutualAuthObject;
  }

  setOAuthToken(token: any) {
    this.OAuthToken = token;
  }

  public getOAuthTokenObject() {
    return this.oAuthTokenObject;
  }
  public setOAuthTokenObject(value) {
    this.oAuthTokenObject = value;
  }

  getOAuthToken() {
    if (this.OAuthToken) {
      console.log(this.OAuthToken);
      return this.OAuthToken;
    }
    return this.OAuthToken;
  }

  resetErrorMessage() {
    this.errorMessage = '';
  }

  resetWarningMessage() {
    this.warningMessage = '';
  }

  getDRSscriptBase64Encoded() {
    return this.DRSscriptBase64Encoded;
  }

  setDRSscriptBase64Encoded(DRSscriptBase64Encoded: any) {
    this.DRSscriptBase64Encoded = DRSscriptBase64Encoded;
  }

  getStreamResponseBasicAuthnHost() {
    return this.streamResponseBasicAuthnHost;
  }

  setStreamResponseBasicAuthnHost(streamResponseBasicAuthnHost: any) {
    this.streamResponseBasicAuthnHost = streamResponseBasicAuthnHost;
  }

  getStreamResponseBasicAuthnPort() {
    return this.streamResponseBasicAuthnPort;
  }

  setStreamResponseBasicAuthnPort(streamResponseBasicAuthnPort: any) {
    this.streamResponseBasicAuthnPort = streamResponseBasicAuthnPort;
  }

  getStreamResponseSendingPostMessageUri() {
    return this.streamResponseSendingPostMessageUri;
  }

  setStreamResponseSendingPostMessageUri(streamResponseSendingPostMessageUri: any) {
    this.streamResponseSendingPostMessageUri = streamResponseSendingPostMessageUri;
  }

  setStreamEncryptionKey(encryptionKey) {
    this.streamEncryptionKey = encryptionKey;
  }

  getStreamEncryptionKey() {
    return this.streamEncryptionKey;
  }
  ;

  setDRSscript(DRSscript) {
    this.DRSscript = DRSscript;
  }

  getDRSscript() {
    return this.DRSscript;
  }

  setDeliveryScript(deliveryScript) {
    this.deliveryScript = deliveryScript;
  }

  getDeliveryScript() {
    return this.deliveryScript;
  }

  setDeliveryScriptBase64Encoded(deliveryScriptBase64Encoded) {
    this.deliveryScriptBase64Encoded = deliveryScriptBase64Encoded;
  }

  getDeliveryScriptBase64Encoded() {
    return this.deliveryScriptBase64Encoded;
  }

  setsshKeyPairId(sshKeyPairId) {
    this.sshKeyPairId = sshKeyPairId;
  }

  getsshKeyPairId() {
    return this.sshKeyPairId;
  }

  setConsumerTopic(consumerTopic) {
    this.consumerTopic = consumerTopic;
  }

  getConsumerTopic() {
    return this.consumerTopic;
  }

  setMutualAuthId(mutualAuthId){
    this.mutualAuthId=mutualAuthId;
  }

  getMutualAuthId(){
    return this.mutualAuthId;
  }
  setOAuthTokenId(OAuthTokenId){
    this.OAuthTokenId=OAuthTokenId;
  }

  getOAuthTokenId(){
    return this.OAuthTokenId;
  }

  getfromCodeValue2() {
    return this.fromCodeValue2;
  }
  setfromCodeValue2(value) {
    this.fromCodeValue2 = value;
  }
  getToCodeValue2() {
    return this.toCodeValue2;
  }
  setToCodeValue2(value) {
    this.toCodeValue2 = value;
  }
  setDocInfo2(docinfo) {
    this.docInfo2 = docinfo;
  }
  getDocInfo2() {
    // console.log(" Docinfo 2" + JSON.stringify(this.docInfo2));
    return this.docInfo2;
  }

  setApplicationSelected(applicationSelected){
    this.applicationSelected = applicationSelected;
  }

  getApplicationSelected(){
    return this.applicationSelected;
  }
  
  setDeviceSelected(deviceSelected){
    this.deviceSelected = deviceSelected;
  }

  getDeviceSelected(){
    return this.deviceSelected;
  }
  setDocinfoResponse(docinfoResponse){
		this.docinfoResponse=docinfoResponse;
	 }

	 getDocinfoResponse(){
		return this.docinfoResponse;
	 }
   setViewDeliveryScript(viewDeliveryScript){
		this.viewDeliveryScript=viewDeliveryScript;
	 }

	 getViewDeliveryScript(){
		return this.viewDeliveryScript;
	 }
   setViewDRSScript(viewDRSScript){
		this.viewDRSScript=viewDRSScript;
	 }

	 getViewDRSScript(){
		return this.viewDRSScript;
	 }
}

<div class="main-content">
  <div class="column-view">
    <main>
      <section>
        <div class="section-container">
          <div id="container"></div>
        </div>
      </section>
    </main>
  </div>
</div>



import { Component, OnInit, ViewChild, Input ,Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { IotService } from '../service/iot.service';
import { TranslateService } from '@ngx-translate/core';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { faAngleUp, faAngleDown, faTimes, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faToggleOff, faToggleOn, faAngleLeft, faAngleRight, faCheck, faCopy,faExclamation, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { RulesService } from '../service/rules.service';
import {SelectionModel} from '@angular/cdk/collections';
import { throwMatDuplicatedDrawerError } from '@angular/material/sidenav';
import { Console } from 'console';
import { ActivatedRoute } from '@angular/router';
import { EcosystemTemplatesService } from '../service/ecosystemTemplates.service';
import { EcosystemsService } from '../service/ecosystems.service';
import { GeofencesService } from '../service/geofences.service';
import { GatewaysService } from '../service/gateways.service';
import { DigitalTwinsService } from '../service/digitalTwins.service';
import { DigitalTwinTemplatesService } from '../service/digitalTwinTemplates.service';

@Component({
  selector: 'app-create-rule-policy',
  templateUrl: './create-rule-policy.component.html',
  styleUrls: ['./create-rule-policy.component.scss']
})
@Injectable({
 providedIn: 'root' // just before your class
})
export class CreateRulePolicyComponent implements OnInit {
  separatorKeysCodes: number[] = [ENTER, COMMA];
  supportLanguages = ['en'];
  rulePolicy: any;
  tagClass = 'tagInput';
  warningMessage: string;
  faCheckIcon = faCheck;
  faTimesIcon = faTimes;
  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;
  faCloseIcon = faTimes;
  faWarningIcon = faExclamationTriangle;
  faCriticalIcon=faExclamation;
  showGateway=false;
  setDefault=false;
  //showGatewayDevices=false;
  showNoGatewayDeviceMessage=false;
  showGatewayDeviceEvents=false;
  allowSave=true;
  attributeIds = [];
  eventId;
  gatewayId;
  allowedMembers = [];
  timeout;
  allowedMemberText;
  showDeviceFromSideNav =true;
  //loading=false;
  triggerType = 'Select Trigger';
  viewLogLevel= 'Normal';
  gtwRuleSample= 'Upload Event';
  script = "";
  task;
  type = "Device Event"; // Event Lifecycle 2=Presence
  lifecyclestate = 1;
  presencestate = 1;
  cestate = 1; // 1 is device inside geofence, 2 is device outside geofence 
  healthstate = 1
  healthalert = 1
  ecosystemdevicestate = 1;
  ecosystempersonstate = 1;
  ecosystemapplicationstate = 1;
  ecosystemgroupstate = 1;
  ecosystemEvent=true;
  testResult = null;
  chkBoxModel;
  codeExampleType = "rule"
  code = "";
  iot = "iot";
  onload= false;
  gatewayPageNo = 1;
  geofencePageNo = 1;
  gateway;
  obervableEvents = [];
  selectedGatewayDevices = null;
  selectedGatewayGroupId = "";
  supportedCommands = [];
  samplegatewayinputjson = "";
  selectedGatewayEventList = [];
  selectedGatewayEventTemp;
  gatewayCode = ' // Send the event to the cloud platform for additional processing. \n\n utils.uploadEvent();';
  gatewayCodeExamples = 'Upload Event';
  gatewayHasDevices = false;
  dropdownSelection ="0";
  deviceTrackingState=1;
  qualifierExpression;
  geofencestate=1;
  selection: SelectionModel<Element> = new SelectionModel<Element>(true, []);
  defaultScript= "ZnVuY3Rpb24gcG9saWN5KHV0aWxzLCBpbnB1dCkgew0KDQogICAgLyoNCiAgICAvLyBnZXQgdGhlIGRldmljZSBldmVudCBtZXNzYWdlIGZyb20gdGhlIGlucHV0DQogICAgDQogICAgLy8gcGVyZm9ybSBzZW5kIGVtYWlsIGFjdGlvbiBvbiBhIGNvbmRpdGlvbiAgIA0KICAgIGlmIChwYXJzZUZsb2F0KGlucHV0LmZsdXhTZW5zb3IpID4gODApIHsNCiAgICAgIHV0aWxzLmRvQWN0aW9uKCJTRU5EX0VNQUlMIiwgInJlY2lwaWVudHM6YWRkcmVzc0Bub2VtYWlsLmNvbSIsICJzdWJqZWN0OmZsdXggY2FwYWNpdG9yIGFsZXJ0ISIsICJtZXNzYWdlOkZsdXggc2Vuc29yIGlzIGhpZ2ghICIgKyAiIHZhbHVlIGlzICIgKyBmbHV4U2Vuc29yKTsNCiAgICB9ICANCiAgDQogICAgLy8gVGhpcyBwb2xpY3kgaGFzIGFjY2VzcyB0byBnbG9iYWwgc2Vzc2lvbiBhdHRyaWJ1dGVzLg0KDQogICAgLy8gU2V0IGEgdmFsdWUgZm9yIGEga2V5LCBsaWtlIHRoaXM6DQogICAgdXRpbHMuZ2V0U2Vzc2lvbigpLnNldCgibXlfa2V5IiwgIjQyIik7DQoNCiAgICAvLyAuLi5hbmQgdG8gcmV0cmlldmUgdmFsdWVzOg0KICAgIHZhciB2ID0gdXRpbHMuZ2V0U2Vzc2lvbigpLmdldCgibXlfa2V5Iik7DQogICAgLy8gWW91IGNhbiBkZWxldGUgYSBrZXkgYWx0b2dldGhlcjoNCiAgICB1dGlscy5nZXRTZXNzaW9uKCkuZGVsZXRlKCJteV9rZXkiKTsNCg0KICAgIC8vIFRvIHRyYWNrIGNvdW50ZXJzLCB1c2UgdGhlc2UgZnVuY3Rpb25zOg0KICAgIHZhciBjb3VudCA9IHV0aWxzLmdldFNlc3Npb24oKS5pbmNyZW1lbnQoIm15X2NvdW50ZXIiKTsNCiAgICAvLyAuLi5vciBpbmNyZW1lbnQgYnkgYSBnaXZlbiByYW5nZS4uLg0KICAgIGNvdW50ID0gdXRpbHMuZ2V0U2Vzc2lvbigpLmluY3JlbWVudCgibXlfY291bnRlciIsIDEwKTsNCg0KICAgIC8vIFNhbWUgYXBwbGllcyBmb3IgZGVjcmVtZW50Og0KICAgIHV0aWxzLmdldFNlc3Npb24oKS5kZWNyZW1lbnQoIm15X2NvdW50ZXIiKTsNCg0KICAgIC8vIENvdW50ZXJzIGNhbiBiZSBhY2Nlc3NlZCBhdCBhbnkgdGltZToNCiAgICB2YXIgbXlDb3VudGVyID0gdXRpbHMuZ2V0U2Vzc2lvbigpLmdldCgibXlfY291bnRlciIpOw0KICAgICovDQp9";

  testScript = '{"attribute": "value"}';
  statechange = false;
  //eventId = null;
  deviceEventName = "Select";
  gatewaysGroupName = "Select Device";
  gatewayName = "Select Gateway";
  //selectGatewaysCount=0;
  gatewayEventName = "Select Event";
  sampleinputjson = "";
  GWIDCCodeExample = "// Issue a command to a specific device \n// utils.sendCommand(<deviceId>, <commandTemplateId>, \n \t// <commandArgs>...);\n\n";
  GWBCCodeExample = "// Issue a command to all devices that support the command \n// utils.broadcastCommand(<commandTemplateId>, \n \t// <commandArgs>...);\n\n";
  gatewayUtilExamples = "keyValue";
  triggerEvents = [];
  triggerDeviceTemplates = [];
  triggerDevices = [];
  gatewayDevice;
  triggerGeofences = [];
  ecosystems = [];
  ecosystemTemplates = [];
  subscribersList = [];
  tags = [];
  existingSubscriberList = [];
  existingClassifier = "";
  
  lifecycleStates = [];
  ecosystemTemplateId;
  ecosystemeventstate=1;
  rulePolicyId;
  gatewayGroups =[];
  gatewayGroup;
  //gatewayDevices = "";
  gatewayDeviceList=[];
  gatewayDeviceCount=0;
  rulePolicyNameInput;
  rulePolicyDescriptionInput;
  rulePolicyNameSelect = false;
  gatewayDeviceExamples;
  gatewayCommandExamples;
  gatewayBase64UtilExamples = "";
  commandArgs;
  errorInRulePolicyTimeout;
  errorInRulePolicyLogLevel;
  errorInRulePolicyTriggerType;
  errorInRulePolicyCustomMessage;
  ecosystemRulePolicy=false;
  updateEcosystemRulePolicy=false;
  ecosystemRuleRelationship=false;
  ecosystemTemplateRuleRelationship=false;
  gatewayRulePolicy=false;
  updateEcosysTemresponse:any;
  ecosysTemResponse:any;

  extendedRuleMappings=[];
  mappedRulePolicyIds=[];
  sourceEventKey=[];
  rulePolicyCreated=false;
  exampleDrodown = "Unselected";

  triggerTypes = [
    { value: 1, triggerType: 'Select Trigger' },
    { value: 2, triggerType: 'Device Event' },
    { value: 3, triggerType: 'Device Lifecycle' },
    { value: 4, triggerType: 'Device Presence' },
    { value: 5, triggerType: 'Device Geofence' },
    { value: 6, triggerType: 'Device Health' },
    { value: 7, triggerType: 'Device Tracking' },
    { value: 8, triggerType: 'Ecosystem Event' },
    { value: 9, triggerType: 'Ecosystem Devices' },
    { value: 10, triggerType: 'Ecosystem Persons' },
    { value: 11, triggerType: 'Ecosystem Applications' },
    { value: 12, triggerType: 'Ecosystem Groups' }
  ];

  logLevels = [
    { value: 1, viewLogLevel: 'Normal' },
    { value: 2, viewLogLevel: 'Fine' },
    { value: 3, viewLogLevel: 'Trace' }
  ];

  gatewayRuleSampleType = [
    { value: 'UploadEvent', gtwRuleSample: 'Upload Event' },
    { value: 'InvokeDeviceCommand', gtwRuleSample: 'Invoke Device Command' },
    { value: 'BroadcastCommand', gtwRuleSample: 'Broadcast Command' },
    { value: 'Utils', gtwRuleSample: 'Misc' }
  ];

  mode = 0;
  @ViewChild('editor') editor;

  //@Input() taskType:string = "";

  constructor(
    public iotService: IotService,
    private translateService: TranslateService,
    private sideNavComponent: SideNavComponent,
    private router: Router,
    public rulesService: RulesService,private route: ActivatedRoute, public ecosystemTemplatesService:EcosystemTemplatesService, public ecosystemsService:EcosystemsService, public devicesService:DigitalTwinsService, public templateService:DigitalTwinTemplatesService, public geofenceService:GeofencesService, public gatewayService:GatewaysService
  ) { 

    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
    if(!iotService.viewRulePolicy){
    this.sideNavComponent.menuClear();
    this.sideNavComponent.menuChange('Dashboard', 'sub-type');
    this.sideNavComponent.menuChange('rules.header', 'sub-type');
    this.sideNavComponent.menuChange('rules.create.new', 'sub-type'); 
    }
  }

  resetdata(){
    this.eventId=undefined;
    this.ecosystems=[];
    this.ecosystemTemplates=[];
    this.triggerDevices=[];
    this.triggerDeviceTemplates=[];
    this.triggerGeofences=[];    
    this.rulesService.showEcosystems=false;
    this.rulesService.showEcosystemTemplates=false;
    this.rulesService.showDevices=false;
    this.rulesService.showDeviceTemplates=false;
    //this.rulesService.setEvents(undefined);
    this.rulesService.setEvents(undefined);
    this.rulesService.setGateways(undefined);
    this.rulesService.selectedEvent=false;
    this.rulesService.devices=[];
    this.rulesService.deviceTemplates=[];
    this.rulesService.ecosystems=[];
    this.rulesService.ecosystemTemplates=[];
    this.rulesService.geofences=[];
    this.showGateway=false;
    this.rulesService.showGateway=false;
    this.iotService.createGatewayRule = false;
    this.gatewayRulePolicy=false;
    this.updateEcosystemRulePolicy=false;
    this.extendedRuleMappings=[];
    this.mappedRulePolicyIds=[];
    this.sourceEventKey=[];
    this.rulesService.triggerType="Select Trigger";
  }

  resetToDefault(){
    this.setDefault=true;
    this.triggerType="Select Trigger";
  }
  
  ngOnInit(): void {
    this.iotService.createRule=true;
    this.resetdata();
    this.resetToDefault();
    this.initRulePolicy();
    this.editor='';
    this.rulesService.showDevices = false;
    this.rulesService.showDeviceTemplates = false;
    this.rulesService.showDeviceTemplates = false;
    this.ecosystemsService.selection.clear();
    this.ecosystemTemplatesService.selection.clear();
    this.geofenceService.selection.clear();
    this.gatewayService.selection.clear();
    this.devicesService.selection.clear();
    this.templateService.selection.clear();
    if(!this.iotService.viewRulePolicy){
    this.sideNavComponent.menuClear();
    this.sideNavComponent.menuChange('Dashboard', 'sub-type');
    this.sideNavComponent.menuChange('rules.header', 'sub-type');
    this.sideNavComponent.menuChange('rules.create.new', 'sub-type');
    }

  //  this.sideNavComponent.menuChange('orchestrationConfigs.header', 'sub-type');
   // this.sideNavComponent.menuChange('orchestrationConfigs.create.new', 'sub-type');

   
    
    if(this.iotService.ecosystemTemplateAddRulePolicy){
    this.ecosystemTemplateId =this.iotService.ecosystemTemplateId;
    this.loadEcosystemTemplate(this.ecosystemTemplateId);
    this.updateEcosystemRulePolicy=true;
   }else if(this.iotService.ecosystemAddRulePolicy){
     var ecosystemId =this.iotService.ecosystemId;
      this.loadEcosystem(ecosystemId);
      this.updateEcosystemRulePolicy=true;
   }else if(this.iotService.gatewayAgentAddRulePolicy){
   var gatewayInfoId = this.iotService.gatewayInfoId;
   this.loadGateWayInfo(gatewayInfoId);
   this.iotService.gatewayAgentAddRulePolicy=false;
   }
  
  }

  ngOnDestroy() {
    this.resetdata();
    this.resetToDefault();
    this.iotService.createRule=false;
    this.iotService.viewRule=false;
    this.iotService.ecosystemTemplateAddRulePolicy=false;
    this.iotService.ecosystemAddRulePolicy=false;
    this.rulesService.ecosystems=[];
    this.rulesService.ecosystemTemplates=[];
    this.ecosystemRulePolicy=false;
    this.gatewayRulePolicy=false;
    this.ecosystemsService.selection.clear();
    this.ecosystemTemplatesService.selection.clear();
    this.geofenceService.selection.clear();
    this.gatewayService.selection.clear();
    this.devicesService.selection.clear();
    this.templateService.selection.clear();
    this.iotService.viewRulePolicy=false;
    this.rulesService.triggerType="Select Trigger";
  }

  initRulePolicy() {
    const that = this;
    that.rulePolicy = {
      "creator": that.iotService.getCreator(),
      "creatorAppId": that.iotService.getCreatorAppId(),
      "realm": that.iotService.getRealm(),
      "name": "Test Rule",
      "description": [{
        "lang": "en_US",
        "text": ""
      }],
      "state":"ACTIVE",
      "classifiers": [
        "DEVICE_EVENT"
    ],
    "qualifierExpression" : "",
      "tags": [],
      "logLevel": "NORMAL",
    "script": {
        "lang": "JS",
        "value": "",
        "timeout":30
    }
    };
  }

  rulePolicyName(event: any) {
    const that = this;
    that.rulePolicyNameInput = event.target.value;
    if (that.rulePolicyNameInput.length > 0) {
      that.rulePolicy.name = that.rulePolicyNameInput;
      that.rulesService.rulePolicyNameInput=that.rulePolicy.name;
    }
    else {
      that.rulePolicyNameInput.rulePolicyNameSelect = false;
      that.rulesService.rulePolicyNameSelect=false;
    }
  }

  rulePolicyDescription(event: any) {
    const that = this;
    if(event.target.value != undefined){
      that.rulePolicy.description[0].text = event.target.value;
    }
  }

  ruleTimeoutInput(event: any) {
    const that = this;
    that.rulePolicy.script.timeout = Number(event.target.value);
    if (that.rulePolicy.script.timeout> 0) {
      that.errorInRulePolicyTimeout = '';
    } else {
      that.rulePolicy.script.timeout = '';
    }
  }

  ruleLogLevelInput(event: any) {
    const that = this;
    that.rulePolicy.logLevel = event.target.value.toUpperCase();
    if (that.rulePolicy.logLevel.length> 0) {
      that.errorInRulePolicyLogLevel = '';
    } else {
      that.rulePolicy.logLevel = '';
    }
  }

  ruleViolationMessageInput(event: any) {
    const that = this;
    that.rulePolicy.customMessage = event.target.value;
    if (that.rulePolicy.customMessage.length> 0) {
      that.errorInRulePolicyCustomMessage = '';
    } else {
      that.rulePolicy.customMessage = '';
    }
  }

  ruleTriggerTypeInput(event: any) {
    const that = this;
    that.changeType(event.target.value);
   // that.resetdata();
    if(!that.ecosystemRulePolicy){
     that.resetdata();
    }
    that.setDefault=false;
    that.rulesService.triggerType=event.target.value;
    if (event.target.value=="Device Event"){
      that.rulePolicy.classifiers = ["DEVICE_EVENT"];
      that.rulesService.showEcosystemTemplates=true;
      that.rulesService.showDeviceTemplates=true;
    }
    else if (event.target.value=="Device Lifecycle"){
      that.rulePolicy.classifiers = ["DEVICE_LIFECYCLE_STATE_CHANGE"];
      that.rulesService.showDeviceTemplates=true;
    }
    else if (event.target.value=="Device Presence"){
      that.rulePolicy.classifiers = ["DEVICE_OPERATIONAL_STATE_CHANGE"];
      that.rulesService.showDeviceTemplates=true;
    }
    else if (event.target.value=="Device Geofence"){
      that.rulePolicy.classifiers = ["DEVICE_GEOFENCE_STATE_CHANGE"];
      that.rulesService.showDeviceTemplates=true;
    }
    else if (event.target.value=="Device Health"){
      that.rulePolicy.classifiers = ["DEVICE_HEALTH_STATE_CHANGE"];
      that.rulesService.showDeviceTemplates=true;
    }
    else if (event.target.value=="Device Tracking"){
      that.rulePolicy.classifiers = ["DEVICE_TRACKING"];
      that.rulesService.showDeviceTemplates=true;
    }
    else if (event.target.value=="Ecosystem Event"){
     that.rulePolicy.classifiers = ["ECOSYSTEM_EVENT"];
     that.rulesService.showEcosystemTemplates=true;
     that.ecosystemRulePolicy=true;
    }
    else if (event.target.value.includes("Ecosystem Devices")){
      that.triggerType="Ecosystem Devices";
      that.rulesService.showEcosystemTemplates=true;
     }
     else if (event.target.value.includes("Ecosystem Persons")){
      that.triggerType="Ecosystem Persons";
      that.rulesService.showEcosystemTemplates=true;
     }
     else if (event.target.value.includes("Ecosystem Applications")){
      that.triggerType="Ecosystem Applications";
      that.rulesService.showEcosystemTemplates=true;
     }
     else if (event.target.value.includes("Ecosystem Groups")){
      that.triggerType="Ecosystem Groups";
      that.rulesService.showEcosystemTemplates=true;
     }
  }

  ruleQualifierInput(event: any) {
    const that = this;
    that.rulePolicy.logLevel = event.target.value;
    if (that.rulePolicy.logLevel.length> 0) {
      that.errorInRulePolicyLogLevel = '';
    } else {
      that.rulePolicy.logLevel = '';
    }
  }


  getEventIds() {
    const that = this;
    that.eventId=undefined;
    if (that.rulesService.getEvents() != undefined) {
      that.eventId=that.rulesService.getEvents().id;
        if(that.showGateway){
          that.selectedGatewayEventTemp=that.rulesService.getEvents();
        }
    }
    return that.eventId;
  }

  getGateways() {
    const that = this;
    that.gatewayId=undefined;
    if (that.rulesService.getGateways() != undefined) {
      that.gateway=that.rulesService.getGateways();
      that.gatewayId=that.rulesService.getGateways().id;
      if(that.rulesService.selectGatewaysCount==0){
        that.selectGateways(that.gateway, true);        
      }
    }
    return that.gatewayId;
  }

  getDeviceIds() {
    const that = this;
    that.triggerDevices = [];
    if (that.rulesService.getDevices() != undefined) {
      that.rulesService.getDevices().forEach((value) => {
        that.triggerDevices.push(value.id);
      });
    }
    return that.triggerDevices;
  }

  getGatewayDevices() {
    const that = this;
    if (that.rulesService.getGatewayDevice() != undefined) {
      that.gatewayDevice=this.rulesService.getGatewayDevice();
      that.selectedGatewayDevices=that.gatewayDevice;
    }
    return that.gatewayDevice;
  }

  getDeviceTemplates() {
    const that = this;
    that.triggerDeviceTemplates = [];
    if (that.rulesService.getDeviceTemplates() != undefined) {
      that.rulesService.getDeviceTemplates().forEach((value) => {
        that.triggerDeviceTemplates.push(value.id);
      });
    }
    return that.triggerDeviceTemplates;
  }
  
  getGeofences() {
    const that = this;
    that.triggerGeofences = [];
    if (that.rulesService.getGeofences() != undefined) {
      that.rulesService.getGeofences().forEach((value) => {
        that.triggerGeofences.push(value.id);
      });
    }
    return that.triggerGeofences;
  }

  getEcosystems() {
    const that = this;
    that.ecosystems = [];
    if (that.rulesService.getEcosystems() != undefined) {
      that.rulesService.getEcosystems().forEach((value) => {
        that.ecosystems.push(value.id);
      });
    }
    return that.ecosystems;
  }

  getEcosystemTemplates() {
    const that = this;
    that.ecosystemTemplates = [];
    if (that.rulesService.getEcosystemTemplates() != undefined) {
      that.rulesService.getEcosystemTemplates().forEach((value) => {
        that.ecosystemTemplates.push(value.id);
      });
    }
    return that.ecosystemTemplates;
  }

  back(){
    const that=this;
    that.rulesService.createResource = true;
    that.resetdata();
    that.resetToDefault();
    that.rulesService.resetMessage();
    that.iotService.viewRulePolicy=false;
  }

  testRule(){
    const that=this;
    that.rulesService.createResource = true;
    //that.rulesService.resetMessage();
  }

  save() {
    const that = this;
    that.rulesService.resetMessage();
    that.rulePolicy.creator= that.iotService.getCreator();
    that.rulePolicy.creatorAppId= that.iotService.getCreatorAppId();
    that.rulePolicy.realm= that.iotService.getRealm();
    that.rulePolicy.script.value= that.editor.getEncodedAceEditorValue();
    if (that.tags.length>0)
    {
      that.rulePolicy.tags = that.tags;
    }
    that.getEventIds();
    that.getGateways();
    if(that.showGateway)
    {
      that.getGatewayDevices();
    }
    that.getDeviceIds();
    that.getDeviceTemplates();
    that.getGeofences();
    that.getEcosystems();
    that.getEcosystemTemplates();
    that.buildTriggers();
    that.rulePolicy.qualifierExpression=that.qualifierExpression;
    if(that.rulesService.errorMessage=="" && that.rulesService.warningMessage=="")
    {
      that.rulePolicyCreated=true;
    that.iotService.getCuiObjResponse().createRulePolicy({
        data: that.rulePolicy,
      })
      .then(function (response) {
        console.log(
          'Rule Policy created succesfully. ID :',
          response.id
        );
        if(that.updateEcosystemRulePolicy){
          that.updateEcosystemRuleRelationship(response); 
        }
        that.addGatewayRuleTag(response,that.gateway); 
       that.router.navigateByUrl('/rules');
      })
      .fail(function (err) {
        that.rulePolicyCreated=false;
        if (err.responseJSON.apiStatusCode) {
          that.rulesService.errorMessage = err.responseJSON.apiMessage;
          console.log(
            'Error Occured: Status: ' +
              err.responseJSON.status +
              ' Msg: ' +
              err.responseJSON.apiStatusCode
          );
        } else {
          console.log(
            'Something went wrong. Please check chrome console or check with dev team'
          );
        }
        if (err === null) {
          that.rulesService.errorMessage =
            'Error occured while creating rule policy';
          console.log('Error occured while creating rule policy');
        }
      });

    }
  }

  updateEcosystemRuleRelationship(response:any){
    const that=this;
    that.rulePolicyId= response.id;
    that.sourceEventKey=response.classifiers;
    let ecosystems=  that.rulesService.getEcosystems();
    ecosystems.forEach(ecosystem => {
     that.updateRulewithEcosystem(ecosystem.id,that.sourceEventKey,that.rulePolicyId); 
    }); 
    let ecosystemTemplates=  that.rulesService.getEcosystemTemplates();
    ecosystemTemplates.forEach(ecosystemTemplate => {
      that.mapEcosystemTemplateWithRule(ecosystemTemplate.id)
    });
  }

  changeEventType(){
    const that = this;
    that.sampleinputjson = "";
    that.testScript = "";
    that.loadEcosystemExample();
  }

  add(event: MatChipInputEvent): void {
    const that = this;
    that.tagClass = 'tagInput';
    const input = event.input;
    const value = event.value;
    const index = that.tags.indexOf(value);
    if (index < 0) {
      if ((value || '').trim()) {
        if (that.tags != undefined && that.tags.includes(value)) {
          if (input) {
            input.value = '';
          }
        } else {
          that.tags.push(value);
          if (input) {
            input.value = '';
          }
        }
      }
    } else {
      that.tagClass = 'tagInput-duplicate';
    }
  }

  remove(tag: string): void {
    const that = this;
    that.tags.forEach((value, index) => {
      if (value == tag) that.tags.splice(index, 1);
    });
  }

  addMember(): void {
    const that = this;

  }

  removeMember(element) {
    const that = this;
  
  }

  onChange(event){
    this.rulesService.resetMessage();
    this.resetdata();
  }


 chkchanged(){
   const that=this;
    if(that.chkBoxModel){
      if(that.gatewayEventName == 'generic_signal'){
        that.script = atob("ZnVuY3Rpb24gcG9saWN5KHV0aWxzLCBpbnB1dCkgew0KDQovKg0KIA0KICAvLyBwYXJzZSBldmVudCBKU09OIGRhdGENCiAgdmFyIGV2ZW50RGF0YSA9IEpTT04ucGFyc2UoaW5wdXQuZGF0YSk7DQogDQogIGlmKGV2ZW50RGF0YS5wcm94aW1pdHkgPiAxOCkgew0KICAgICAgLy8gdXBsb2FkIHRoZSBldmVudCB0byBjbG91ZA0KICAgICAgdXRpbHMudXBsb2FkRXZlbnQoKTsNCiAgfQ0KIA0KICAqLw0KDQp9");
      } else {
        that.script = atob("ZnVuY3Rpb24gcG9saWN5KHV0aWxzLCBpbnB1dCkgew0KDQogIC8qDQoNCiAgaWYgKHBhcnNlRmxvYXQoaW5wdXQuZmx1eFNlbnNvcikgPiA4MCkgew0KDQogICAgLy8gcGVyZm9ybSBpbnZva2UgZGV2aWNlIGNvbW1hbmQgYWN0aW9uIG9uIGEgY29uZGl0aW9uICAgDQogICAgdXRpbHMuc2VuZENvbW1hbmQoImRldmljZS1pZCIsICJjb21tYW5kLXRlbXBsYXRlLWlkIiwgIm5ld19jb2xvcjojMDBhOWU3Iik7DQogIA0KICB9IGVsc2UgaWYgKHBhcnNlRmxvYXQoaW5wdXQuZmx1eFNlbnNvcikgPCAyMCkgew0KDQogICAgLy8gdXBsb2FkIHRoZSBldmVudCB0byBjbG91ZA0KCXV0aWxzLnVwbG9hZEV2ZW50KCk7DQoNCiAgfQ0KDQogICovDQoNCn0=");
      }
    } else {
      that.script = atob("ZnVuY3Rpb24gcG9saWN5KHV0aWxzLCBpbnB1dCkgew0KDQogICAgLyoNCiAgICAvLyBnZXQgdGhlIGRldmljZSBldmVudCBtZXNzYWdlIGZyb20gdGhlIGlucHV0DQogICAgDQogICAgLy8gcGVyZm9ybSBzZW5kIGVtYWlsIGFjdGlvbiBvbiBhIGNvbmRpdGlvbiAgIA0KICAgIGlmIChwYXJzZUZsb2F0KGlucHV0LmZsdXhTZW5zb3IpID4gODApIHsNCiAgICAgIHV0aWxzLmRvQWN0aW9uKCJTRU5EX0VNQUlMIiwgInJlY2lwaWVudHM6YWRkcmVzc0Bub2VtYWlsLmNvbSIsICJzdWJqZWN0OmZsdXggY2FwYWNpdG9yIGFsZXJ0ISIsICJtZXNzYWdlOkZsdXggc2Vuc29yIGlzIGhpZ2ghICIgKyAiIHZhbHVlIGlzICIgKyBmbHV4U2Vuc29yKTsNCiAgICB9ICANCiAgDQogICAgLy8gVGhpcyBwb2xpY3kgaGFzIGFjY2VzcyB0byBnbG9iYWwgc2Vzc2lvbiBhdHRyaWJ1dGVzLg0KDQogICAgLy8gU2V0IGEgdmFsdWUgZm9yIGEga2V5LCBsaWtlIHRoaXM6DQogICAgdXRpbHMuZ2V0U2Vzc2lvbigpLnNldCgibXlfa2V5IiwgIjQyIik7DQoNCiAgICAvLyAuLi5hbmQgdG8gcmV0cmlldmUgdmFsdWVzOg0KICAgIHZhciB2ID0gdXRpbHMuZ2V0U2Vzc2lvbigpLmdldCgibXlfa2V5Iik7DQogICAgLy8gWW91IGNhbiBkZWxldGUgYSBrZXkgYWx0b2dldGhlcjoNCiAgICB1dGlscy5nZXRTZXNzaW9uKCkuZGVsZXRlKCJteV9rZXkiKTsNCg0KICAgIC8vIFRvIHRyYWNrIGNvdW50ZXJzLCB1c2UgdGhlc2UgZnVuY3Rpb25zOg0KICAgIHZhciBjb3VudCA9IHV0aWxzLmdldFNlc3Npb24oKS5pbmNyZW1lbnQoIm15X2NvdW50ZXIiKTsNCiAgICAvLyAuLi5vciBpbmNyZW1lbnQgYnkgYSBnaXZlbiByYW5nZS4uLg0KICAgIGNvdW50ID0gdXRpbHMuZ2V0U2Vzc2lvbigpLmluY3JlbWVudCgibXlfY291bnRlciIsIDEwKTsNCg0KICAgIC8vIFNhbWUgYXBwbGllcyBmb3IgZGVjcmVtZW50Og0KICAgIHV0aWxzLmdldFNlc3Npb24oKS5kZWNyZW1lbnQoIm15X2NvdW50ZXIiKTsNCg0KICAgIC8vIENvdW50ZXJzIGNhbiBiZSBhY2Nlc3NlZCBhdCBhbnkgdGltZToNCiAgICB2YXIgbXlDb3VudGVyID0gdXRpbHMuZ2V0U2Vzc2lvbigpLmdldCgibXlfY291bnRlciIpOw0KICAgICovDQp9");
    }
    that.loadAPIJSON();
    that.refresh();
  }

  loadAPIJSON(){

  }
    refresh(){

    }


    buildTriggers() {
      const that = this;
      var triggers = {
          "all": []
      }
      if (that.triggerType == 'Device Event') {
          if (that.chkBoxModel) {
            if (typeof  that.selectedGatewayDevices != null && that.selectedGatewayDevices != undefined) {
                  triggers.all.push({
                      "expr": "equal(f:deviceId,v:string(" + that.selectedGatewayDevices.id + "))"
                  });
                }
                else{
                  that.rulesService.errorMessage="You must select a Device";
                }
              var list = [];
              if (typeof that.selectedGatewayEventTemp != null && that.selectedGatewayEventTemp != undefined) {
                list.push({
                    "expr": "equal(f:eventTemplateId,v:string(" + that.selectedGatewayEventTemp.id + "))"
                });
              }
              else{
                that.rulesService.errorMessage="You must select an Event Template";
              }
  
              if (list.length > 0) {
                  triggers.all.push({
                      "any": list
                  });
              }
          } else {
              ///////// EVENT
              if (typeof that.eventId != undefined && that.eventId != null) {
                triggers.all.push({
                    "expr": "equal(f:eventTemplateId,v:string(" + that.eventId + "))"
                });
              }
              var list = [];
              that.triggerDeviceTemplates.forEach((value) => {
                if (typeof value != undefined && value != null) {
                list.push({
                  "expr": "equal(f:deviceTemplateId,v:string(" + value + "))"
              });
              }
              });

              that.triggerDevices.forEach((value) => {
                if (typeof value != undefined && value != null) {
                list.push({
                  "expr": "equal(f:deviceId,v:string(" + value + "))"
              });
              }
              });
  
              if (typeof that.eventId == undefined && that.eventId == null)
              {
                that.rulesService.errorMessage="You must select an Event Template";
              }
              if (that.triggerDevices.length>0 || that.triggerDeviceTemplates.length>0)
              {
                if (list.length > 0) {
                  triggers.all.push({
                      "any": list
                  })
              }
              }
              else
              {
                that.rulesService.warningMessage="Atleast one device or device template should be selected";
              }
  
              var ecoList = [];

              that.ecosystemTemplates.forEach((value) => {
                if (typeof value != undefined && value != null) {
                  ecoList.push({
                    "expr": "arrayContains(f:groupTemplateIds,v:string(" + value + "))"
                });
              }
              });
    
              that.ecosystems.forEach((value) => {
                if (typeof value != undefined && value != null) {
                  ecoList.push({
                  "expr": "arrayContains(f:groupIds,v:string(" + value + "))"
              });
              }
              });
              if (ecoList.length > 0) {
                  triggers.all.push({
                      "any": ecoList
                  });
              }
          }
      } else if (that.triggerType == 'Device Lifecycle') {
          ///////// Lifecycle
          var from = "";
          var to = "";
          switch (that.lifecyclestate) {
              case 1: // Created
                  from = "";
                  to = "PENDING_ACTIVATION";
                  break;
              case 2: // Registered
                  from = "PENDING_ACTIVATION";
                  to = "ACTIVE";
                  break;
              case 3: // Suspended
                  from = "ACTIVE";
                  to = "SUSPENDED";
                  break;
              case 4: // Unsuspended
                  from = "SUSPENDED";
                  to = "ACTIVE";
                  break;
              case 5: // Locked
                  from = "ACTIVE";
                  to = "LOCKED";
                  break;
              case 6: // Unlocked
                  from = "LOCKED";
                  to = "ACTIVE";
                  break;
              case 7: // Deleted
                  from = "SUSPENDED";
                  to = "DELETED";
                  break;
              case 8: // Reset
                  from = "DELETED";
                  to = "PENDING_ACTIVATION";
                  break;
          }
  
          if (from != "") {
              triggers.all.push({
                  "expr": "equal(f:fromState,v:string(" + from + "))"
              });
          }
          if (to != "") {
              triggers.all.push({
                  "expr": "equal(f:toState,v:string(" + to + "))"
              });
          }
  
          // Device Templates
          var list = [];
          that.triggerDeviceTemplates.forEach((value) => {
            list.push({
              "expr": "equal(f:deviceTemplateId,v:string(" + value + "))"
          });
          });
          // Devices  
          that.triggerDevices.forEach((value) => {
            list.push({
              "expr": "equal(f:deviceId,v:string(" + value + "))"
          });
          });
          if (that.triggerDevices.length>0 || that.triggerDeviceTemplates.length>0)
          {
            if (list.length > 0) {
              triggers.all.push({
                  "any": list
              })
          }
          }
          else
          {
            that.rulesService.warningMessage="Atleast one device or device template should be selected";
          }
      } else if (that.triggerType == 'Device Presence') {
          ///////// Presence
  
          var from = "";
          var to = "";
  
          if (that.presencestate == 1) {
              from = "STANDBY";
              to = "ONLINE";
          }
          if (that.presencestate == 2) {
              from = "OFFLINE";
              to = "ONLINE";
          }
          if (that.presencestate == 3) {
              from = "ONLINE";
              to = "OFFLINE";
          }
  
          if (from != "") {
              triggers.all.push({
                  "expr": "equal(f:fromState,v:string(" + from + "))"
              });
          }
          if (to != "") {
              triggers.all.push({
                  "expr": "equal(f:toState,v:string(" + to + "))"
              });
          }
  
  
          // Device Templates
          var list = [];
          that.triggerDeviceTemplates.forEach((value) => {
            list.push({
              "expr": "equal(f:deviceTemplateId,v:string(" + value + "))"
          });
          });
          // Devices
          that.triggerDevices.forEach((value) => {
            list.push({
              "expr": "equal(f:deviceId,v:string(" + value + "))"
          });
          });
  
          if (that.triggerDevices.length>0 || that.triggerDeviceTemplates.length>0)
          {
            if (list.length > 0) {
              triggers.all.push({
                  "any": list
              })
          }
          }
          else
          {
            that.rulesService.warningMessage="Atleast one device or device template should be selected";
          }
      } else if (that.triggerType == 'Device Geofence') {
          ///////// Presence
  
          var from = "";
          var to = "";
  
          if (that.geofencestate == 1) {
              from = "OUTSIDE";
              to = "INSIDE";
          }
          if (that.geofencestate == 2) {
              from = "INSIDE";
              to = "OUTSIDE";
          }
  
          if (from != "") {
              triggers.all.push({
                  "expr": "equal(f:fromState,v:string(" + from + "))"
              });
          }
          if (to != "") {
              triggers.all.push({
                  "expr": "equal(f:toState,v:string(" + to + "))"
              });
          }
  
  
          // Device Templates
          var list = [];
          that.triggerDeviceTemplates.forEach((value) => {
            list.push({
              "expr": "equal(f:deviceTemplateId,v:string(" + value + "))"
          });
          });
          // Devices
          that.triggerDevices.forEach((value) => {
            list.push({
              "expr": "equal(f:deviceId,v:string(" + value + "))"
          });
          });
          if (typeof that.triggerGeofences == undefined || that.triggerGeofences==null || that.triggerGeofences.length==0)
          {
            that.rulesService.warningMessage="Atleast one geofence should be selected";
          }
          else{
            //Geofences
            that.triggerGeofences.forEach((value) => {
              list.push({
                "expr": "equal(f:geofenceId,v:string(" + value + "))"
            });
            });
          }
  
          if (that.triggerDevices.length>0 || that.triggerDeviceTemplates.length>0)
          {
            if (list.length > 0) {
              triggers.all.push({
                  "any": list
              })
          }
          }
          else
          {
            that.rulesService.warningMessage="Atleast one device or device template should be selected";
          }
      } else if (that.triggerType == 'Device Health') {
          ///////// Presence
  
          var from = "";
          var to = "";
  
          if (that.healthstate == 1) {
              from = "";
              to = "OK";
          }
          if (that.healthstate == 2) {
              from = "";
              to = "WARNING";
          }
          if (that.healthstate == 3) {
              from = "";
              to = "CRITICAL";
          }
          if (that.healthstate == 4) {
              from = "";
              to = "FAILURE";
          }
          if (that.healthstate == 5) {
              from = "";
              to = "SUSPENDED";
          }
  
  
          if (from != "") {
              triggers.all.push({
                  "expr": "equal(f:fromState,v:string(" + from + "))"
              });
          }
          if (to != "") {
              triggers.all.push({
                  "expr": "equal(f:toState,v:string(" + to + "))"
              });
          }
  
  
          // Device Templates
          var list = [];
          that.triggerDeviceTemplates.forEach((value) => {
            list.push({
              "expr": "equal(f:deviceTemplateId,v:string(" + value + "))"
          });
          });
          // Devices
          that.triggerDevices.forEach((value) => {
            list.push({
              "expr": "equal(f:deviceId,v:string(" + value + "))"
          });
          });
  
          if (that.triggerDevices.length>0 || that.triggerDeviceTemplates.length>0)
          {
            if (list.length > 0) {
              triggers.all.push({
                  "any": list
              })
          }
          }
          else
          {
            that.rulesService.warningMessage="Atleast one device or device template should be selected";
          }
      } else if (that.triggerType == 'Device Health Alert') {
          ///////// Presence
  
          var from = "";
          var to = "";
  
          if (from != "") {
              triggers.all.push({
                  "expr": "equal(f:fromState,v:string(" + from + "))"
              });
          }
          if (to != "") {
              triggers.all.push({
                  "expr": "equal(f:toState,v:string(" + to + "))"
              });
          }
  
  
          // Device Templates
          var list = [];
          that.triggerDeviceTemplates.forEach((value) => {
            list.push({
              "expr": "equal(f:deviceTemplateId,v:string(" + value + "))"
          });
          });
          // Devices
          that.triggerDevices.forEach((value) => {
            list.push({
              "expr": "equal(f:deviceId,v:string(" + value + "))"
          });
          });
  
          if (that.triggerDevices.length>0 || that.triggerDeviceTemplates.length>0)
          {
            if (list.length > 0) {
              triggers.all.push({
                  "any": list
              })
          }
          }
          else
          {
            that.rulesService.warningMessage="Atleast one device or device template should be selected";
          }
      } else if (that.triggerType == 'Device Tracking') {
  
  
          var from = "";
          var to = "";
  
          if (that.deviceTrackingState == 2) {
              from = "IDLE";
              to = "NONIDLE";
          }
          if (that.deviceTrackingState == 1) {
              from = "NONIDLE";
              to = "IDLE";
          }
  
          if (from != "") {
              triggers.all.push({
                  "expr": "equal(f:fromState,v:string(" + from + "))"
              });
          }
          if (to != "") {
              triggers.all.push({
                  "expr": "equal(f:toState,v:string(" + to + "))"
              });
          }
  
  
          // Device Templates
          var list = [];
          that.triggerDeviceTemplates.forEach((value) => {
            list.push({
              "expr": "equal(f:deviceTemplateId,v:string(" + value + "))"
          });
          });
          // Devices  
          that.triggerDevices.forEach((value) => {
            list.push({
              "expr": "equal(f:deviceId,v:string(" + value + "))"
          });
          });

          //Geofences
          that.triggerGeofences.forEach((value) => {
            list.push({
              "expr": "equal(f:geofenceId,v:string(" + value + "))"
          });
          });    
        
  
          if (that.triggerDevices.length>0 || that.triggerDeviceTemplates.length>0)
          {
            if (list.length > 0) {
              triggers.all.push({
                  "any": list
              })
          }
          }
          else
          {
            that.rulesService.warningMessage="Atleast one device or device template should be selected";
          }
      } else if (that.triggerType == 'Ecosystem Event') {
          ///////// Ecosystem Event
  
          var list = [];
  
          //EventTemplateId
          if (typeof that.eventId != undefined && that.eventId != null) {
            triggers.all.push({
                "expr": "equal(f:eventTemplateId,v:string(" + that.eventId + "))"
            });
          }

          // Groups
          that.ecosystemTemplates.forEach((value) => {
              list.push({
                  "expr": "arrayContains(f:groupTemplateIds,v:string(" + value + "))"
              });
            });
  
            that.ecosystems.forEach((value) => {
              list.push({
                "expr": "arrayContains(f:groupIds,v:string(" + value + "))"
            });
            });
            if (typeof that.eventId == undefined || that.eventId == null)
              {
                that.rulesService.errorMessage="You must select an Event Template";
              }
  
              if (that.ecosystems.length>0 || that.ecosystemTemplates.length>0)
              {
                if (list.length > 0) {
                  triggers.all.push({
                      "any": list
                  })
              }
              }
              else
              {
                that.rulesService.warningMessage="Atleast one ecosystem or ecosystem template should be selected";
              }
        }
      else if (that.triggerType.includes('Ecosystem')) {
        ///////// Ecosystem Devices
        var list = [];
        // Groups
        that.ecosystemTemplates.forEach((value) => {
            list.push({
                "expr": "arrayContains(f:groupTemplateIds,v:string(" + value + "))"
            });
          });

          that.ecosystems.forEach((value) => {
            list.push({
              "expr": "arrayContains(f:groupIds,v:string(" + value + "))"
          });
          });

          if (that.ecosystems.length>0 || that.ecosystemTemplates.length>0)
          {
            if (list.length > 0) {
              triggers.all.push({
                  "any": list
              })
          }
          }
          else
          {
            that.rulesService.warningMessage="Atleast one ecosystem or ecosystem template should be selected";
          }
    }
      if (triggers.all.length > 0) {
          that.qualifierExpression = triggers;
      } else {
          triggers = {
            "all": []
          };
      }
      return triggers;
  }
  loadJSONFile(filepath) {
  //  return $http.get(filepath);
}

changeType(type) {
const that=this;
  that.type = type;
  //that.subscribersList = [];
  that.sampleinputjson = "";
  that.testScript = "";
  that.eventId = "";
  that.deviceEventName = "Select";
  that.triggerDeviceTemplates = [];
  that.triggerDevices = [];
  that.subscribersList = [];
    //var default=that.script;
    //that.originScript=default.slice(0);
    //console.log(that.originScript);
  if(type=="Ecosystem Applications"){
    that.ecosystemapplicationstate = 1;
        //that.script=that.originScript;
        that.script=atob(that.defaultScript);
    that.getEcosystemTemplates();
    that.getGroups();
    that.chkBoxModel = false;
        that.ecosystemEvent=true;
    that.changeEventType();
  } else if(type=="Ecosystem Persons"){
    that.ecosystempersonstate = 1;
        //that.script=that.originScript;
        that.script=atob(that.defaultScript);
    that.getEcosystemTemplates();
    that.getGroups();
    that.chkBoxModel = false;
        that.ecosystemEvent=true;
    that.changeEventType();
  } else if(type=="Ecosystem Devices"){
    that.ecosystemdevicestate = 1;
        //that.script=that.originScript;
        that.script=atob(that.defaultScript);
    that.getEcosystemTemplates();
    that.getGroups();
    that.chkBoxModel = false;
        that.ecosystemEvent=true;
    that.changeEventType();
  } else if(type=="Ecosystem Groups"){
    that.ecosystemgroupstate = 1;
        //that.script=that.originScript;
        that.script=atob(that.defaultScript);
    that.getEcosystemTemplates();
    that.getGroups();
    that.chkBoxModel = false;
        that.ecosystemEvent=true;
    that.changeEventType();
  } else if(type=="Ecosystem Event"){
    that.ecosystemeventstate = 1;
    that.getEcosystemTemplates();
    that.getGroups();
        var script=that.script;
         script=script.replace(" // get the device event message from the input","");
         that.script=script;
        that.ecosystemEvent=false;

    that.chkBoxModel = false;
    that.changeEventType();
    that.refresh();
  } else if(type=="Device Event"){
        //that.script=that.originScript;
        that.script=atob(that.defaultScript);
    that.getEvents();
      that.getDeviceTemplates();
      that.getDevices();
      that.getEcosystemTemplates();
    that.getGroups();
    //  that.buildTriggers();
      that.changeEventType();
        that.ecosystemEvent=true;
  } else if(type=="Device Lifecycle"){
        //that.script=that.originScript;
        that.script=atob(that.defaultScript);
    that.lifecyclestate = 1;
    that.getEvents();
      that.getDeviceTemplates();
      that.getDevices();
      that.chkBoxModel = false;
    //  that.buildTriggers();
      that.changeEventType();
        that.ecosystemEvent=true;
  } else if(type=="Device Presence"){
        //that.script=that.originScript;
        that.script=atob(that.defaultScript);
    that.presencestate = 1;
    that.getEvents();
      that.getDeviceTemplates();
      that.getDevices();
      that.chkBoxModel = false;
     // that.buildTriggers();
      that.changeEventType();
        that.ecosystemEvent=true;
}
else if(type=="Device Geofence"){
        //that.script=that.originScript;
        that.script=atob(that.defaultScript);
    that.geofencestate = 1;
    that.getEvents();
      that.getDeviceTemplates();
      that.getDevices();
      that.chkBoxModel = false;
     // that.buildTriggers();
      that.changeEventType();
        that.ecosystemEvent=true;
  }
    else if(type=="Device Health"){
        //that.script=that.originScript;
        that.script=atob(that.defaultScript);
        that.healthstate = 1;
        that.getEvents();
        that.getDeviceTemplates();
        that.getDevices();
        that.chkBoxModel = false;
      //  that.buildTriggers();
        that.changeEventType();
        that.ecosystemEvent=true;
    }
    else if(type=="Device Health Alert"){
        //that.script=that.originScript;
        that.script=atob(that.defaultScript);
        that.healthalert = 1;
        that.getEvents();
        that.getDeviceTemplates();
        that.getDevices();
        that.chkBoxModel = false;
       // that.buildTriggers();
        that.changeEventType();
        that.ecosystemEvent=true;
    }
    else if (type == "Device Tracking") {
        //that.script=that.originScript;
        that.script = atob(that.defaultScript);
        that.deviceTrackingState = 1;
        that.getEvents();
        that.getDeviceTemplates();
        that.getDevices();
        that.chkBoxModel = false;
       // that.buildTriggers();
        that.changeEventType();
        that.ecosystemEvent = true;
    }
 
 //   window.setTimeout(that.beautify2, 100);
}





beautifying = false;

safeToBeautify(e) {
  const that =this;
    if (!that.beautifying && e.action === 'insert' && e.start.row === 0 && e.start.column === 0) {
        return true;
    } else {
        return false;
    }
}

beautify2() {
const that= this;
var beautifying = true;
//  var session = that.editor2.getSession();
var session;
  // Get current text from editor
  var val = session.getValue();
  //cui.log('beautify before', val);

  // Remove leading spaces
  var array = val.split(/\n/);
  array[0] = array[0].trim();
  val = array.join('\n');

  // Beautify
  //val = js_beautify(val, beautificationOptions);
  //cui.log('beautify after', val);

  // Change current text to beautified text
  session.setValue(val);
  beautifying = false;
};


beautificationOptions = {
  indent_size: 2,
  indent_with_tabs: false,
};

  
getDevices(){

}

getEvents(){

}

getGroups(){

}

  loadEcosystemExample(){

  }

  OnSelection(event){

  }

  gatewayExamples(event: any){
    const that = this;
    that.gatewayCodeExamples=event.target.value;
    if(that.gatewayCodeExamples == 'Upload Event'){
      that.gatewayCode = ' // Send the event to the cloud platform for additional processing. \n\n utils.uploadEvent();';
      that.gatewayDeviceExamples = {};
      that.gatewayCommandExamples = "";
      that.gatewayUtilExamples = "";
      that.gatewayBase64UtilExamples = "";
      that.refresh();
    } else if(that.gatewayCodeExamples == 'Invoke Device Command'){
      //that.gatewayDeviceExamples = that.gatewayDevices[0];
      that.gatewayCode = '';
      that.gatewayDeviceExamples = {};
      that.gatewayCommandExamples = "";
      that.gatewayUtilExamples = "";
      that.gatewayBase64UtilExamples = "";
      that.refresh();
    } else if(that.gatewayCodeExamples == 'Broadcast Command'){
      //that.gatewayCommandExamples = that.supportedCommands[0];
      that.gatewayCode = '';
      that.gatewayDeviceExamples = {};
      that.gatewayCommandExamples = "";
      that.gatewayUtilExamples = "";
      that.gatewayBase64UtilExamples = "";
      that.refresh();
    } else if(that.gatewayCodeExamples == 'Misc'){
      that.gatewayCode = '';
      that.gatewayDeviceExamples = {};
      that.gatewayCommandExamples = "";
      that.gatewayUtilExamples = "keyValue";
      that.updateUtilExample();
      that.gatewayBase64UtilExamples = "";
      that.refresh();
    } else if(that.gatewayCodeExamples == 'Base64Utils'){
      that.gatewayCode = '';
      that.gatewayDeviceExamples = {};
      that.gatewayCommandExamples = "";
      that.gatewayUtilExamples = "";
      that.gatewayBase64UtilExamples = "Encode";
      that.updateBase64UtilExample();
      that.refresh();
    }
  }
    updateUtilExample(){
      const that =this;
			if(that.gatewayUtilExamples == 'keyValue'){
				that.gatewayCode = ' // Set a value for a key, like this: \n utils.kvSet("my_key", "42"); \n\n // ...and to retrieve values: \n var v = utils.kvGet("my_key");\n\n // You can delete a key altogether: \n utils.kvDelete("my_key");';
			} else if(that.gatewayUtilExamples == 'ManageCounters'){
				that.gatewayCode = ' // To track counters, use these functions: \n var count = utils.kvIncrement("my_counter"); \n\n // ...or increment by a given range... \n count = utils.kvIncrement("my_counter", 10); \n\n // Same applies for decrement:\n utils.kvDecrement("my_counter");\n\n // Counters can be accessed at any time:\n var myCounter = utils.kvGet("my_counter")';
			} else if(that.gatewayUtilExamples == 'GPSLocation'){
				that.gatewayCode = 'var position = utils.getGatewayPosition(); \n\n /* \n { \n\t "latitude": "32.776475",\n\t "longitude": "-79.931051", \n\t "elevation": "-3492", \n\t "fixPrecision": "1.0", \n\t "bearing": "127", \n\t  "velocity": "13.5" \n } \n */';
			}
			that.refresh();
		}
		
	  updateBase64UtilExample(){
      const that=this;
			if(that.gatewayBase64UtilExamples == 'Encode'){
				that.gatewayCode = 'var encoded = utils.base64.encode("a string to encode"); \n// "YSBzdHJpbmcgdG8gZW5jb2Rl"';
			} else if(that.gatewayBase64UtilExamples == 'Decode'){
				that.gatewayCode = 'var decoded = utils.base64.decode("YSBzdHJpbmcgdG8gZW5jb2Rl"); \n// "a string to encode"';
			}
			that.refresh();
		}


    updateGWDevExample(){
      const that =this;
			that.gatewayCode = 'utils.broadcastCommand(';
			if(typeof that.gatewayDeviceExamples !== "object"){
				//that.gatewayDeviceExamples = JSON.parse(that.gatewayDeviceExamples);
        that.gatewayDeviceExamples = that.gatewayDeviceExamples;
			}
			if(that.gatewayCodeExamples == 'Invoke Device Command'){
				that.gatewayCode = 'utils.sendCommand(';
			}
			if(typeof that.gatewayDeviceExamples != 'undefined'){
				if(that.gatewayCodeExamples == 'Invoke Device Command'){
					//that.gatewayCode = that.gatewayCode + '\n\t "' + that.gatewayDeviceExamples.id + '",';
          that.gatewayCode = that.gatewayCode + '\n\t "' + that.gatewayDeviceExamples + '",';
				}
			} else {
				if(that.gatewayCodeExamples == 'Invoke Device Command'){
					that.gatewayCode = that.gatewayCode + "\n\t <device Id>,";
				}
			}
			if(typeof that.gatewayCommandExamples != 'undefined'){
				that.gatewayCode = that.gatewayCode + '\n\t "' + that.gatewayCommandExamples.id + '",';
				that.commandArgs = '';
        // that.gatewayCommandExamples.args.forEach((value, index) => {
        //   that.commandArgs = that.commandArgs  + '\n\t "' + value.name + ':value here",';
        // });
				that.gatewayCode = that.gatewayCode + that.commandArgs;
			}
			that.gatewayCode =  that.gatewayCode.substring(0, that.gatewayCode.length - 1);
			that.gatewayCode = that.gatewayCode + "\n);";
			that.refresh();
		}

    updateSupportedCommands(){
      const that =this;
			if(typeof that.gatewayDeviceExamples !== "object"){
				that.gatewayDeviceExamples = JSON.parse(that.gatewayDeviceExamples);
			}
			that.supportedCommands = [];
			that.gatewayDeviceExamples.supportedCommands.forEach((value, key)=> {
				//$scope.supportedCommands.push(value);
				that.supportedCommands.push(value, function(e) { 
				    return e.id === value.id; 
				});
			});
		}

    onButtonToggle(event){
      const that= this;
      console.log("onButtonToggle: " + event)
      if( that.triggerType=="Device Lifecycle"){
      if(event=="Created"){
        that.lifecyclestate = 1;
      }
      else if(event=="Registered"){
        that.lifecyclestate = 2;
      }
      else if(event=="Suspended"){
        that.lifecyclestate = 3;
      }
      else if(event=="Unsuspended"){
        that.lifecyclestate = 4;
      }
      else if(event=="Locked"){
        that.lifecyclestate = 5;
      }
      else if(event=="Unlocked"){
        that.lifecyclestate = 6;
      }
      else if(event=="Deleted"){
        that.lifecyclestate = 7;
      }
      else if(event=="Reset"){
        that.lifecyclestate = 8;
      }
    }
   else if( that.triggerType=="Device Presence"){
      if(event=="ReInitially Connected"){
        that.presencestate = 1;
      }
      else if(event=="Connected"){
        that.presencestate = 2;
      }
      else if(event=="Disconnected"){
        that.presencestate = 3;
      }
    }

   else if( that.triggerType=="Device Geofence"){
      if(event=="Entering"){
        that.geofencestate = 1;
      }
      else if(event=="Exiting"){
        that.geofencestate = 2;
      }
    }
   else if( that.triggerType=="Device Health"){
      if(event=="OK"){
        that.healthstate = 1;
      }
      else if(event=="WARNING"){
        that.healthstate = 2;
      }
      else if(event=="CRITICAL"){
        that.healthstate = 3;
      }
      else if(event=="FAILURE"){
        that.healthstate = 4;
      }
      else if(event=="SUSPENDED"){
        that.healthstate = 5;
      }
    }
   else if( that.triggerType=="Device Tracking"){
      if(event=="IDLE"){
        that.deviceTrackingState = 1;
      }
      else if(event=="NONIDLE"){
        that.deviceTrackingState = 2;
      }
    }
   else if( that.triggerType=="Ecosystem Devices"){
      if(event=="Created"){
        that.rulePolicy.classifiers = ["DeviceCreatedEvent"];
      }
      else if(event=="Registration"){
        that.rulePolicy.classifiers = ["DeviceRegisteredEvent"];
      }
      else if(event=="Updated"){
        that.rulePolicy.classifiers = ["DeviceUpdatedEvent"];
      }
      else if(event=="Deleted"){
        that.rulePolicy.classifiers = ["DeviceDeletedEvent"];
      }
      else if(event=="Activated"){
        that.rulePolicy.classifiers = ["DeviceActivatedEvent"];
      }
      else if(event=="Deactivated"){
        that.rulePolicy.classifiers = ["DeviceDeactivatedEvent"];
      }
      else if(event=="Reset"){
        that.rulePolicy.classifiers = ["DeviceResetEvent"];
      }
      else if(event=="Lock"){
        that.rulePolicy.classifiers = ["DeviceLockedEvent"];
      }
      else if(event=="Unlock"){
        that.rulePolicy.classifiers = ["DeviceUnlockedEvent"];
      }
      else if(event=="Suspended"){
        that.rulePolicy.classifiers = ["DeviceSuspendedEvent"];
      }
      else if(event=="Unsuspended"){
        that.rulePolicy.classifiers = ["DeviceUnsuspendedEvent"];
      }
      else if(event=="Online"){
        that.rulePolicy.classifiers = ["DeviceOnlineEvent"];
      }
      else if(event=="Offline"){
        that.rulePolicy.classifiers = ["DeviceOfflineEvent"];
      }
      else if(event=="Migrate"){
        that.rulePolicy.classifiers = ["DeviceMigratedEvent"];
      }
    }

    else if( that.triggerType=="Ecosystem Persons"){
      if(event=="Activated"){
        that.rulePolicy.classifiers = ["PersonActivatedEvent"];
      }
      else if(event=="Updates"){
        that.rulePolicy.classifiers = ["PersonUpdatedEvent"];
      }
      else if(event=="Suspended"){
        that.rulePolicy.classifiers = ["PersonSuspendedEvent"];
      }
      else if(event=="Unsuspended"){
        that.rulePolicy.classifiers = ["PersonUnsuspendedEvent"];
      }
    }
    else if( that.triggerType="Ecosystem Applications"){
      if(event=="Created"){
        that.rulePolicy.classifiers = ["ApplicationCreatedEvent"];
      }
      else if(event=="Registered"){
        that.rulePolicy.classifiers = ["ApplicationRegisteredEvent"];
      }
      else if(event=="Updated"){
        that.rulePolicy.classifiers = ["ApplicationUpdatedEvent"];
      }
      else if(event=="ApplicationDeletedEvent"){
        that.rulePolicy.classifiers = ["DeviceDeletedEvent"];
      }
    }
    else if( that.triggerType=="Ecosystem Groups"){
      if(event=="Created"){
        that.rulePolicy.classifiers = ["GroupCreatedEvent"];
      }
      else if(event=="Activated"){
        that.rulePolicy.classifiers = ["GroupActivatedEvent"];
      }
      else if(event=="Deactivated"){
        that.rulePolicy.classifiers = ["GroupDeactivatedEvent"];
      }
      else if(event=="Updated"){
        that.rulePolicy.classifiers = ["GroupModifiedEvent"];
      }
      else if(event=="Suspended"){
        that.rulePolicy.classifiers = ["GroupSuspendedEvent"];
      }
      else if(event=="Unsuspended"){
        that.rulePolicy.classifiers = ["GroupUnsuspendedEvent"];
      }
      else if(event=="AddEntitlement"){
        that.rulePolicy.classifiers = ["GroupEntitlementCreatedEvent"];
      }
      else if(event=="RemoveEntitlement"){
        that.rulePolicy.classifiers = ["GroupEntitlementDeletedEvent"];
      }
      else if(event=="Deleted"){
        that.rulePolicy.classifiers = ["GroupDeletedEvent"];
      }
      else if(event=="AddMember"){
        that.rulePolicy.classifiers = ["GroupMembershipCreatedEvent"];
      }
      else if(event=="RemoveMember"){
        that.rulePolicy.classifiers = ["GroupMembershipDeletedEvent"];
      }
      else if(event=="AddRelationship"){
        that.rulePolicy.classifiers = ["MemberRelationshipCreatedEvent"];
      }
      else if(event=="RemoveRelationship"){
        that.rulePolicy.classifiers = ["MemberRelationshipDeletedEvent"];
      }
    }
    }

    tabClick(tab) {
      console.log(tab.index);
      if (tab.index == 0) {
        console.log("Device Templates");
        this.rulesService.showDeviceTemplates = true;
        this.rulesService.showDevices = false;
      }
      else if (tab.index == 1) {
        console.log("Devices");
        this.rulesService.showDevices = true;
        this.rulesService.showDeviceTemplates = false;
      }
    }

    subscribersTabClick(tab) {
      console.log(tab.index);
      if
      (tab.index == 0) {
        console.log("Ecosystem templates");
        this.rulesService.showEcosystems = false;
        this.rulesService.showEcosystemTemplates = true;
      } 
      else if (tab.index == 1) {
        console.log("Ecosystems");
        this.rulesService.showEcosystems = true;
        this.rulesService.showEcosystemTemplates = false;
      }
    }
  
   
    loadEcosystemTemplate(ecosystemTemplateId: any) {
      const that = this;
      that.iotService
        .getCuiObjResponse()
        .getEcosystemTemplateById({
          groupTemplateId: ecosystemTemplateId,
        })
        .then(function (response) {
      let ecosystemTemplates = that.rulesService.getEcosystemTemplates();
      ecosystemTemplates.push(response);
      that.rulesService.setEcosystemtemplates(ecosystemTemplates);
      that.ecosystemRulePolicy=true;
      that.iotService.viewRule=true;
      
        })
        .fail(function (err) {
          if (err.responseJSON && err.responseJSON.apiStatusCode) {
            that.rulesService.errorMessage = err.responseJSON.apiMessage;
            console.log(
              'Error Occured: Status: ' +
                err.responseJSON.status +
                ' Msg: ' +
                err.responseJSON.apiStatusCode
            );
          } else {
            console.log(
              'Something went wrong. Please check chrome console or check with dev team'
            );
          }
        });
    }

    loadEcosystem(ecosystemId: any) {
      const that = this;
      let rulePolicy=[];
      rulePolicy.push(that.rulePolicyId);
      that.ecosystemRuleRelationship=true;
      that.iotService
        .getCuiObjResponse()
        .getEcosystemById({
          groupId: ecosystemId,
        })
        .then(function (response) {
        that.ecosysTemResponse=response; 
         let ecosystems = that.rulesService.getEcosystems();
         ecosystems.push(response);
        that.rulesService.setEcosystems(ecosystems);
        that.ecosystemRulePolicy=true;
        that.iotService.viewRule=true;
        })
        .fail(function (err) {
          if (err.responseJSON && err.responseJSON.apiStatusCode) {
            that.rulesService.errorMessage = err.responseJSON.apiMessage;
            console.log(
              'Error Occured: Status: ' +
                err.responseJSON.status +
                ' Msg: ' +
                err.responseJSON.apiStatusCode
            );
          } else {
            console.log(
              'Something went wrong. Please check chrome console or check with dev team'
            );
          }
        });
    }

  addGatewayRuleTag(rulePolicy, selectedGateway){
    const that=this;
    //var gtwTag="gw:"+selectedGateway.id;
    if(that.chkBoxModel){
      if(!that.tags.includes("gw")){
          that.iotService.getCuiObjResponse().createRulePolicyTag({
            rulePolicyId: rulePolicy.id,
            tag: "gw:"+selectedGateway.id
          }).then(function(tagResult) {
            that.rulePolicy.version = parseInt(that.rulePolicy.version) + 1;
          }).fail(function (err) {
            if (err.responseJSON.apiStatusCode) {
              that.rulesService.errorMessage = err.responseJSON.apiMessage;
              console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
            } else {
              console.log("Something went wrong. Please check chrome console or check with dev team");
            }
          });
          //that.rulePolicy.tags = [];
          //that.rulePolicy.tags.push("gw:"+selectedGateway.id);
      }
    }
  }


    loadGateWayInfo(gatewayInfoId: any) {
      const that = this;
      that.iotService.getCuiObjResponse().getGatewayAgent({
        gatewayId: gatewayInfoId
      }).then(function (response) {
        that.rulesService.setGateways(response);
        that.iotService.viewRule=true;
        that.gatewayRulePolicy=true;
      }).fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          that.rulesService.errorMessage = err.responseJSON.apiMessage;
          console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
        } else {
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    }


  selectGateway(event){
    const that=this;
    if (event == true) {      
      this.showGateway=true;
      that.chkBoxModel=true;
      that.gatewayEventName = 'generic_signal';
      that.chkchanged();
      that.rulesService.showGateway= this.showGateway;
      that.iotService.createGatewayRule = true;
      that.rulePolicy.classifiers=[];
      that.rulePolicy.classifiers.push('SIGNAL');
      that.rulePolicy.classifiers.push('QUICK_SIGNAL');
    } else {
      that.resetdata();
      this.showGateway=false;
      that.chkBoxModel=false;
      this.rulesService.showEcosystemTemplates=true;
      that.rulesService.showDeviceTemplates=true;
      that.rulesService.showGateway=this.showGateway;
    }
  }

updateRulewithEcosystem(ecosystemId:any,sourceEventKeys:any,rulePolicyId:any){
   const that=this;
    let rulePolicy=[];
   
    rulePolicy.push(rulePolicyId);
    that.ecosystemRuleRelationship=true;
    that.iotService
      .getCuiObjResponse()
      .getEcosystemById({
        groupId: ecosystemId,
      })
      .then(function (response) {
       that.ecosysTemResponse=response; 
       let ecosystems = that.rulesService.getEcosystems();
       ecosystems.push(response);
      that.rulesService.setEcosystems(ecosystems);
      that.ecosystemRulePolicy=true;
      if(that.ecosysTemResponse.extendedRuleMappings!=undefined ){
        let extendedRuleMapping=[];
        extendedRuleMapping.push(that.ecosysTemResponse.extendedRuleMappings);
        const data= that.extendedRuleMappings;
        data.push({
          sourceEventKey:sourceEventKeys[0],
          mappedRulePolicyIds: rulePolicy
        });
        for(let i=0;i<that.ecosysTemResponse.extendedRuleMappings.length;i++)
        {
          data.push(that.ecosysTemResponse.extendedRuleMappings[i]);
        }
        that.ecosysTemResponse.extendedRuleMappings=data;
      }else{
        const data= that.extendedRuleMappings;
        data.push({
          sourceEventKey:sourceEventKeys[0],
          mappedRulePolicyIds: rulePolicy
        });
        that.ecosysTemResponse.extendedRuleMappings=data; 
    }
    that.updateEcosystemWithRulePolicy(that.ecosysTemResponse.id);
      })
      .fail(function (err) {
        if (err.responseJSON && err.responseJSON.apiStatusCode) {
          that.rulesService.errorMessage = err.responseJSON.apiMessage;
          console.log(
            'Error Occured: Status: ' +
              err.responseJSON.status +
              ' Msg: ' +
              err.responseJSON.apiStatusCode
          );
        } else {
          console.log(
            'Something went wrong. Please check chrome console or check with dev team'
          );
        }
      });
 
}

    updateEcosystemWithRulePolicy(ecosysTemId:any){

    const that=this;
      that.iotService.getCuiObjResponse().updateEcosystem({
      groupId: ecosysTemId,
      data: that.ecosysTemResponse,
    })
    .then(function (UpdatedEcosystemResponse) {
    that.ecosysTemResponse=UpdatedEcosystemResponse;
    })
    .fail(function (err) {
    if (err.responseJSON.apiStatusCode) {
      that.rulesService.errorMessage = err.responseJSON.apiMessage;
      console.log(
        'Error Occured: Status: ' +
          err.responseJSON.status +
          ' Msg: ' +
          err.responseJSON.apiStatusCode
      );
    } else {
      console.log(
        'Something went wrong. Please check chrome console or check with dev team'
      );
    }
    if (err === null) {
      that.rulesService.errorMessage =
        'Error occured while map rule to groupTemplate';
      console.log('Error occured while map rule to groupTemplate');
    }
    }); 

    }


 mapEcosystemTemplateWithRule(ecosystemTemplateId:any){
     const that=this;
     that.iotService.getCuiObjResponse().mapRuleToGroupTemplate({
      groupTemplateId: ecosystemTemplateId,sourceEventKey:that.sourceEventKey[0],rulePolicyId:that.rulePolicyId
    })
    .then(function (response) {
      console.log(
        'map ecosystem with rule'
      );
    })
    .fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        that.rulesService.errorMessage = err.responseJSON.apiMessage;
        console.log(
          'Error Occured: Status: ' +
            err.responseJSON.status +
            ' Msg: ' +
            err.responseJSON.apiStatusCode
        );
      } else {
        console.log(
          'Something went wrong. Please check chrome console or check with dev team'
        );
      }
      if (err === null) {
        that.rulesService.errorMessage =
          'Error occured while map rule to groupTemplate';
        console.log('Error occured while map rule to groupTemplate');
      }
    }); 
    }

    addGWToNewRule(gatewayId){
      const that=this;
      if(gatewayId){
        //load gateway object from gateway and then pre-select it
        that.chkBoxModel = true;
        that.iotService.getCuiObjResponse().getGateway({
        gatewayId: gatewayId
      })
      .then(function(response) {
        that.rulePolicy.name = response.name[0].text + "-rule";
        that.selectGateways(response, false);
        that.refresh();
      }).fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          that.rulesService.errorMessage = err.responseJSON.apiMessage;
          console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
        } else {
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
      });
      }
    }

    selectGateways(gateway, isOnLoad){
      const that =this;
      that.rulesService.selectGatewaysCount=1;
      that.rulesService.gatewayDevices="";
      that.rulesService.loading = true;
      that.gatewayName = gateway.name[0].text;
      var selectedGatewayId = gateway.id;
      if(!isOnLoad){
        that.gatewaysGroupName = "Select Device";
        //that.selectedGatewayDevices = null;
        that.obervableEvents = [];
        that.gatewayEventName = "Select Event";
        that.supportedCommands = [];
        that.selectedGatewayEventTemp = {};
      }
      that.iotService.getCuiObjResponse().getGroups({
        qs:[['owner.id',gateway.backingDeviceId],['name',"Gateway device group"]] // Hardcoded as discussed with Sam (as per old ui comment)
      })
      .then(function(response) {
        that.gatewayGroup=response[0];
        that.gatewayGroups.push(response[0]);
        that.subscribersList.push({"type":"ecosystemtemplate","value":response[0]});
        //let groupTemplates = [];
        //groupTemplates=that.rulesService.getEcosystemTemplates();
        //groupTemplates.push(response);
        //that.rulesService.setEcosystemtemplates(groupTemplates);
        //that.triggerDeviceTemplates.push(response[0])
        that.refresh();
        that.gatewayHasDevices = false;
        that.iotService.getCuiObjResponse().getEcosystemMemberships({
          ecosystemId: that.gatewayGroup.id
        })
        .then(function(response) {
          that.selectedGatewayGroupId = that.gatewayGroups[0].id;
          if(isOnLoad){
            var oldSelectedGatewayGroupId = that.gatewayGroups[0].id;
          }
          var count=0;
          var iterator=0
          response.forEach((value2, index)=> {
            that.obervableEvents = [];
            iterator++;
            if(value2.member.type == 'device'){
              that.iotService.getCuiObjResponse().getDevice({
                deviceId: value2.member.id
              })
              .then(function(response2) {
                if(response2.name[0].text != gateway.name[0].text){
                 // gatewayDevices.push(response2);
                 if(that.rulesService.gatewayDevices!="")
                 {
                  that.rulesService.gatewayDevices= that.rulesService.gatewayDevices+"^^id::"+response2.id;
                  that.gatewayDeviceList.push(response2);
                 }
                 else
                 {
                  that.rulesService.gatewayDevices= "id::"+response2.id;
                  that.gatewayDeviceList.push(response2);
                 }
                 that.gatewayDeviceCount++;
                  if(count==response.length){
                  setTimeout(function(){
                    that.rulesService.showGatewayDevices=true;
                  }, 3000);
                  }
                  else{
                    that.showNoGatewayDeviceMessage=true;
                  }
                  that.gatewayHasDevices = true;
                  if(!isOnLoad){
                    response2.observableEvents.forEach((value3, key3)=> {
                      that.obervableEvents.push(value3);
                      that.obervableEvents.push(value3, function(e) {
                        return e.id === value3.id;
                      });
                    });
                    response2.supportedCommands.forEach((value3, key3)=> {
                      that.supportedCommands.push(value3);
                      that.supportedCommands.push(value3, function(e) {
                        return e.id === value3.id;
                      });
                    });
                  }
                }
                console.log("that.rulesService.loading");
                setTimeout(function(){
                  that.rulesService.loading = false;
                }, 4000);
                that.refresh();
              }).fail(function (err) {
                if (err.responseJSON.apiStatusCode) {
                  that.rulesService.errorMessage = err.responseJSON.apiMessage;
                  console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
                } else {
                  console.log("Something went wrong. Please check chrome console or check with dev team");
                }
              });
            }
            count++;
          });
          if(!that.rulesService.showGatewayDevices && response.length==iterator){
            that.gatewayHasDevices=false;
            that.showNoGatewayDeviceMessage=true;
          }
        }).fail(function (err){
          console.log("err msg: "+ JSON.stringify(err));
          if(err.responseJSON.apiStatusCode){
            that.rulesService.errorMessage = err.responseJSON.apiMessage;
            alert("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
              } else{
                alert("Something went wrong. Please check chrome console or check with dev team");
              }
          });
      }).fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          that.rulesService.errorMessage = err.responseJSON.apiMessage;
          console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
        } else {
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
      });
      
    }

    selectGroup(device, isOnLoad){
      const that=this;
      that.gatewaysGroupName = device.name[0].text;
      that.selectedGatewayDevices = device ;
      that.obervableEvents = [];
      that.supportedCommands = [];
      if(!isOnLoad){
        that.selectedGatewayEventList = [];
        that.selectedGatewayEventTemp = {};
        that.gatewayEventName = "Select Event";
      }
      if( device.observableEvents){
        device.observableEvents.forEach((value, key)=> {
          that.obervableEvents.push(value);
          that.obervableEvents.push(value, function(e) {
            return e.id === value.id;
          });
        });
      }
      if( device.supportedCommands){
        device.supportedCommands.forEach((value, key)=> {
          that.supportedCommands.push(value);
          that.supportedCommands.push(value, function(e) {
            return e.id === value.id;
          });
        });
      }
      that.refresh();
    }

    // goBack() {
    //   this.gatewaysService.createResource = true;
    //   this.gatewaysService.createNewAdaptor= false;
    //   //this.tpService.resetMessage();
    // }
  }
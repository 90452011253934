import { Component, OnInit ,Output,EventEmitter} from '@angular/core';

@Component({
  selector: 'app-getting-started',
  templateUrl: './getting-started.component.html',
  styleUrls: ['./getting-started.component.scss']
})
export class GettingStartedComponent implements OnInit {

 
  matVersion: string = '5.1.0';
  breakpoint: number=3;
  constructor() { }

  ngOnInit() {
     if(window.innerWidth <= 1200 && window.innerWidth >650){
      this.breakpoint=2;
    } 
    else if(window.innerWidth <= 650){
      this.breakpoint=1;
    }
    else{
      this.breakpoint=3;
    }
  }
  
  onResize(event) {
    if(event.target.innerWidth <= 1200 && event.target.innerWidth >650){
      this.breakpoint=2;
    } 
    else if(event.target.innerWidth <= 650){
      this.breakpoint=1;
    }
    else{
      this.breakpoint=3;
    }
  }


  @Output() messageToEmit = new EventEmitter<boolean>();


  onClickGettingStarted(message:boolean){
    this.messageToEmit.emit(message);
    
  }

}

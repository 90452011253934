<!--
* This is an auto generated code for oAuthTokens listing page.
* Please don't make any change to this file.
* @version 1.0.0 
* @readonly 
* @author - System generated 
-->
<div class="container"> 
	<header class="table-header">
		<h2>
		 {{pageTitle | translate}}
			 <span *ngIf="showHeaderToolTips" id="ot-tooltip-bottom" class="ot--is-width-px-200">
				  <a class="ot-tooltip ot-tooltip--is-bottom"><fa-icon [icon]="faQuestionCircleIcon"></fa-icon>
					 <span class="ot-tooltip__text">{{'oAuthTokens.definition' | translate}}</span>
				</a>
			 </span>
		</h2>
	</header>
	<div *ngIf="showControlBar" class="control-bar">
		<input *ngIf="showSearch" type="text" placeholder="{{'oAuthTokens.search' | translate}}" (keyup)="getSearchResults($event)"> 
		<span *ngIf="newWithRoute">
			<button *ngIf="showNew" class="newBtn btn-primary" [routerLink]="['/createOAuthTokens']" (click)="createNew()" aria-label="">{{'oAuthTokens.new' | translate }}</button> 
		</span> 
		<span *ngIf="!newWithRoute">
			<button  class="newBtn btn-primary" (click)="createNew()" aria-label="">{{'oAuthTokens.new' | translate }}</button> 
		</span>
	</div>
	<div class="table-container">
	<ngx-skeleton-loader *ngIf="!dataSource" count="12" [theme]="{'border-radius': '0px',
		height: '48px', 
		width: '100%', 
		margin: '1px', 
		position: 'relative', 
		top: '20px' }"> 
	</ngx-skeleton-loader> 
	<ng-container *ngIf="totalCount > 0">
		<table mat-table [dataSource]="dataSource" class="oAuthTokensTable">
		<ng-container *ngIf="showSelectColoumn">
			<ng-container matColumnDef="ADD">
				<th mat-header-cell *matHeaderCellDef class="checkbox-cell addCol"> {{addSelectButtonLabel | translate}}</th>
					<td mat-cell *matCellDef="let results" class="checkbox-cell addCol">
						<ng-container *ngIf="results.active">
							<div class="btn-col">
								<ng-container *ngIf="oAuthTokensService.selection.isSelected(results.id)">
									<button class="selected btn btn-micro btn-secondary"  (click)="removeSelection(results)">
									<fa-icon [icon]="faCheckIcon"></fa-icon></button>
								</ng-container>
								<ng-container *ngIf="!oAuthTokensService.selection.isSelected(results.id)">
								 <button class="select btn btn-micro btn-secondary"  (click)="onSelection(results)">{{addSelectButtonLabel | translate}}</button>
								</ng-container>
							</div>
						</ng-container>
						<ng-container *ngIf="!results.active">
							<div class="btn-col">
								<ng-container *ngIf="oAuthTokensService.selection.isSelected(results.id)">
									<button class=" btn btn-micro btn-secondary" [disabled]="results.active" >
									<fa-icon [icon]="faCheckIcon"></fa-icon></button>
								</ng-container>
								<ng-container *ngIf="!oAuthTokensService.selection.isSelected(results.id)">
								 <button class=" btn btn-micro btn-secondary" [disabled]="!results.active" >{{addSelectButtonLabel | translate}}</button>
								</ng-container>
							</div>
					</ng-container>
					</td>
			</ng-container>
		</ng-container>
		<ng-container matColumnDef="name">
			<th class="name-cell nameCol" mat-header-cell *matHeaderCellDef mat-sort-header="name">
				<span class="left"> {{'oAuthTokens.name' | translate}} </span>
				<span class="right">
					<a (click)="getSortResults('name', 'asc')" [class.active]="ascName === true">
					<fa-icon title="{{'ascending' | translate}}" [icon]="faArrowUpIcon"></fa-icon>
				</a>
				<a (click)="getSortResults('name', 'desc')" [class.active]="descName === true">
					<fa-icon title="{{'descending' | translate}}" [icon]="faArrowDownIcon"></fa-icon>
				</a>
				</span>
			</th>
			<td class="name-cell nameCol" mat-cell *matCellDef="let results">
				<a title="{{'oAuthTokens.view.details' | translate}}" (click)=viewDetails(results.id)>
					{{results.name[0].text}}
				</a>
			</td>
		</ng-container>
		<ng-container matColumnDef="description">
			<th class="desc-cell descriptionCol" mat-header-cell *matHeaderCellDef>
				{{'oAuthTokens.description' | translate}} 
			</th>
			<ng-container *matCellDef="let results">
				<td class="desc-cell descriptionCol" mat-cell *ngIf="results.description">
					{{results.description[0].text}}
				</td> 
				<td mat-cell *ngIf="!results.description"> - </td> 
			</ng-container>
		</ng-container>
		<ng-container matColumnDef="clientId">
			<th class="desc-cell clientIdCol" mat-header-cell *matHeaderCellDef>
				{{'oAuthTokens.clientId' | translate}} 
			</th>
			<ng-container *matCellDef="let results">
				<td class="desc-cell clientIdCol" mat-cell *ngIf="results.clientId">
					{{results.clientId}} 
				</td> 
				<td mat-cell *ngIf="!results.clientId"> - </td> 
			</ng-container>
		</ng-container>
		<ng-container matColumnDef="creation">
			<th class="date-cell creationCol" mat-header-cell *matHeaderCellDef mat-sort-header="creation">
				<span class="left"> {{'oAuthTokens.creation' | translate}} </span>
				<span class="right">
					<a (click)="getSortResults('creation', 'asc')" [class.active]="ascCreation === true">
					<fa-icon title="{{'ascending' | translate}}" [icon]="faArrowUpIcon"></fa-icon>
				</a>
				<a (click)="getSortResults('creation', 'desc')" [class.active]="descCreation === true">
					<fa-icon title="{{'descending' | translate}}" [icon]="faArrowDownIcon"></fa-icon>
				</a>
				</span>
			</th>
			<td class="date-cell creationCol" mat-cell *matCellDef="let results">
					{{results.creation | date:'mediumDate' }} 
			</td>
		</ng-container>
		<ng-container matColumnDef="active">
			<th class="status-cell activeCol" mat-header-cell *matHeaderCellDef>
				{{'oAuthTokens.active' | translate}} 
			</th>
			<ng-container *matCellDef="let results">
				<td class="status-cell activeCol" mat-cell *ngIf="results.active">
					{{results.active}} 
				</td> 
				<td mat-cell *ngIf="!results.active"> false </td> 
			</ng-container>
		</ng-container>
		<ng-container *ngIf="showEnableColumn" matColumnDef="ACTIVE">
			<th class="status-cell stateCol" mat-header-cell *matHeaderCellDef> {{'generic.column.title.enabled'|translate}} </th>
				<ng-container *matCellDef="let results">
					<td class="status-cell stateCol" mat-cell *ngIf="results.active" >
						<button class="toggle-on toggle-switch" (click)="changeActive(results.id, 'true')" title="{{'method.disable.tooltip.oAuthTokens' | translate}}">
							<fa-icon [icon]="faCheckIcon" class="fa-2x"></fa-icon>
							<div class="toggle-on-btn"></div>
						</button>
					</td>
					<td class="status-cell stateCol" mat-cell *ngIf="!results.active" >
						<button class="toggle-off toggle-switch" (click)="changeActive(results.id, 'false')" title="{{'method.enable.tooltip.oAuthTokens' | translate}}">
							<div class="toggle-off-btn"></div>
						</button>
					</td>
			</ng-container>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let results; columns: displayedColumns;"></tr>
	</table>
	<app-pagination *ngIf="showPagination" [count]="totalCount" [pageSize]="pageSize" [currentPage]="currentPage" 
			[pageSizeOptions]="pageSizeOptions" (currentPageChange)="onPageChange($event)" 
			(currentPageSizeChange)="onPageSizeChange($event)"> 		</app-pagination>
	</ng-container>
	</div>
	<ng-container *ngIf="!countCallCompleted && !getCallCompleted && totalCount == 0" >
		<div class="table-container text-center"></div>
	</ng-container>
	<ng-container *ngIf="countCallCompleted && getCallCompleted && totalCount == 0" >
		<div class="table-container text-center">{{'generic.no.records.found' | translate}}</div>
	</ng-container>
</div>

import { Component, OnInit, Input } from '@angular/core';
import { throwMatDuplicatedDrawerError } from '@angular/material/sidenav';
import { faCheckCircle, faExclamation, faExclamationTriangle, faInfoCircle, faTimes, faQuestionCircle, faCheck } from '@fortawesome/free-solid-svg-icons';
import { IotService } from '../service/iot.service';
import { DeviceService } from '../service/device.service';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { Router } from '@angular/router';
import { AttributeListComponent} from '../mid-side-nav/attribute-list/attribute-list.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-mid-side-nav',
  templateUrl: './mid-side-nav.component.html',
  styleUrls: ['./mid-side-nav.component.scss'],
    host: {
      '(document:click)': 'onClick($event)',
    }
})
export class MidSideNavComponent implements OnInit {

  deviceCreate: string;
  @Input() data: any;

  faInfoIcon = faInfoCircle;
  faCloseIcon = faTimes;
  faCriticalIcon = faExclamation;
  faWarningIcon = faExclamationTriangle;
  faSuccessIcon = faCheckCircle;
  faQuestionCircleIcon = faQuestionCircle;
  faCheckIcon = faCheck;

  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;
  descriptionChecked = false;
  attributesChecked = false;
  eventsChecked = false;
  commandsChecked = false;
  ConnectionsChecked = false;
  reviewChecked = false;
  response: string;
  createdDevice;
  device: any;
  attributeIds = [];
  commandIds = [];
  eventIds = [];
  geofenceIds = [];
  tags=[];
  connectionIds = [];
  connectionEnabled=false;
  deviceCreated=false;
  deviceId: String;
  tagList = [];
  deviceTemplate: any;
  association:any;
  newDeviceCreated=false;
  deviceCreatedWithoutTemplate=false;
  supportLanguages = ['en'];

  constructor(public iotService: IotService,
    public deviceService : DeviceService,
    private sideNavComponent: SideNavComponent,
    private router: Router,private translateService: TranslateService) {
    //   this.response = this.deviceService.getData();
    this.sideNavComponent.menuClear();
    this.sideNavComponent.menuChange('Dashboard','sub-type');
    this.sideNavComponent.menuChange('devices.header','sub-type');
    this.sideNavComponent.menuChange('devices.create.new', 'sub-type');
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en'); 

  }


  onClick(event) {
      this.deviceService.resetSuccessMessage();
      this.deviceService.resetWarningMessage();
      
   }

  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }

  ngOnDestroy(): void {
    this.deviceService.setDeviceName(undefined);
    this.deviceService.setDeviceDescription(undefined);
    this.deviceService.setDeviceTags(undefined);
    this.deviceService.setTemplateName(undefined);
    this.deviceService.resetErrorMessage();
    // this.deviceService.resetSuccessMessage();
    this.deviceService.resetWarningMessage();
    // this.deviceService.setAssociationData(undefined);
    this.deviceService.createResource = false;
    this.deviceCreated=false;
    this.deviceId='';
    this.tags.length=0;
    this.tagList.length=0;
    this.deviceService.createFromScratch = false;
    if(this.deviceService.deviceTags!=undefined){
      this.deviceService.deviceTags.length=0;
    }
    this.deviceService.newTemplatename = '';
    this.deviceService.deviceDescription = '';
    this.deviceService.deviceNameSelect = false;
    this.deviceService.templateNameSelect = false;
    this.deviceService.createDeviceFromNewTemplate = false;
    this.deviceService.setNewTemplate(undefined);
    this.deviceService.createNewTemplate=false;
    this.newDeviceCreated = false;
  }

  ngOnInit(): void {
    this.deviceCreate = '1';
    this.deviceService.templateSelect = false;
    this.deviceService.resetErrorMessage();
    this.deviceService.resetWarningMessage();
    this.deviceService.resetSuccessMessage();
    this.deviceService.setSelectedTemplate(undefined);
    this.deviceService.setDeviceName(undefined);
    this.deviceService.setDeviceDescription(undefined);
    this.deviceService.setDeviceTags(undefined);
    this.deviceService.setTemplateName(undefined);
    this.deviceService.createResource = false;
    this.deviceService.createFromScratch = false;
    this.deviceCreated=false;
    this.tags.length=0;
    this.deviceService.setAttributeData(undefined);
    this.deviceService.setCommandData(undefined);
    this.deviceService.setEventData(undefined);
    this.deviceService.setGeofenceData(undefined);
    this.deviceService.setStreamData(undefined);
    this.deviceService.setSelectedTemplate(undefined);
    this.deviceService.setTemplateName(undefined);
    this.deviceService.attributeSelect = false;
    this.deviceService.eventSelect = false;
    this.deviceService.commandSelect = false;
    this.deviceService.templateSelect = false;
    this.deviceService.geofenceSelect = false;
    this.deviceService.streamSelect = false;
    this.deviceId='';
    this.deviceService.createNewTemplate=false;
    if(this.deviceService.streamSelect){
      this.connectionEnabled=true;
    }
  }

  createDeviceFromTemplate(templateId, newDevice) {
    const that = this;
    that.deviceService.resetErrorMessage();
    that.deviceService.resetSuccessMessage();
    if(that.deviceService.streamSelect){
      that.connectionEnabled=true;
    }
    that.device = {
      "device": {
        "name": [
          {
            "lang": "en_us",
            "text": that.deviceService.getDeviceName(),
          }
        ],
        "description": [
          {
            "lang": "en_us",
            "text": that.deviceService.getDeviceDescription(),
          }
        ],
        "tags":that.getTags(),
        "parentTemplateId": templateId
      },
      "connectionEnabled": that.connectionEnabled
    };
    that.newDeviceCreated = true;
    that.iotService.getCuiObjResponse().createActiveDevice({
      data: that.device
    }).then(function (response) {
      if(response!=undefined&& response.length!=0){
        if(that.deviceService.getStreamData()!=undefined ){
          that.addStreamsToDevice(response);
        }
        if (newDevice) {
          that.router.navigateByUrl("/devices/"+response.device.id);
        } else {
          that.deviceService.successMessage="Device created successfully !";
          if(that.deviceService.deviceTags!=undefined){
            that.deviceService.deviceTags.length=0;
          }
          that.ngOnDestroy();
        }
      }

    }).fail(function (msg) {
      that.newDeviceCreated = false;
      console.log(msg.responseJSON.apiMessage);
      that.deviceService.errorMessage = "To use this template, please activate the item(s) below.";

    });
  }

  viewDevice(){
    if(this.deviceId!=undefined){
      this.router.navigateByUrl("/devices/"+this.deviceId);
    }
   
  }

  //viewDeviceTemplate
  viewDeviceTemplate(parentDeviceTemplateId){
    this.router.navigateByUrl("/templates/"+parentDeviceTemplateId);  }  

  activateAttribute(attr){
    const that = this;

    that.iotService.getCuiObjResponse().activateAttributeType({
      attributeTypeId: attr.id
    }).then(function(response) {
      // console.log(response);
      console.log("attr activation successful")
      that.deviceService.resetErrorMessage();
      that.deviceService.successMessage ="Attribute is activated. Proceed for device creation after 5 mins"
      attr.isActive = true;

    }).fail(function (err){
      console.log("Error in attr activation")
    });
  }

  deactivateAttribute(id : any){
    const that = this;

    that.iotService.getCuiObjResponse().deactivateAttributeType({
      attributeTypeId: id
    }).then(function(response) {
      // console.log(response);
      console.log("attr deactivation successful");
      that.deviceService.resetErrorMessage();
      that.ngOnInit();
   //   that.attributeList = new AttributeListComponent(that.deviceService);

  //    that.attributeList.getAttributes();

    }).fail(function (err){
      console.log("Error in attr activation")
    });
  }

  disableCommand(id : any) {
    const that = this;
    this.iotService.getCuiObjResponse().deactivateCommandTemplate({
      commandTemplateId: id
    }).then(function (response) {
      // console.log(response);
      console.log("command deactivation successful");
      that.deviceService.resetErrorMessage();
      that.ngOnInit();
    }).fail(function (err) {
      if (err.responseJSON.apiStatusCode) {
        console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
      } else {
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  disableEvent(id : any) {
    const that = this;
    this.iotService.getCuiObjResponse().deactivateEventTemplate({
      eventTemplateId: id
    }).then(function(response) {
      // console.log(response);
      console.log("event deactivation successful");
      that.deviceService.resetErrorMessage();
      that.ngOnInit();
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }
  createDeviceFromScratch() {

    const that = this;
    that.deviceService.resetErrorMessage();
    if(that.deviceService.streamSelect){
      that.connectionEnabled=true;
    }
    that.device = {
      "device": {
        "name": [
          {
            "lang": "en_us",
            "text": that.deviceService.getDeviceName(),
          }
        ],
        "description": [
          {
            "lang": "en_us",
            "text": that.deviceService.getDeviceDescription(),
          }
        ],
        "tags":that.getTags(),
      },
      "connectionEnabled": that.connectionEnabled,
      "deviceTemplate": {
        "name": [
          {
            "lang": "en_us",
            "text": that.deviceService.getTemplateName(),
          }
        ],
        "description": [
          {
            "lang": "en_us",
            "text": that.deviceService.getDeviceDescription(),
          },
          
        ],
        "tags":that.getTags(),
        "attributeTypes": that.getAttributeIds(),
        "commandTemplates": that.getCommandIds(),
        "eventTemplates": that.getEventIds(),
        "geofences": that.getGeofenceIds()
      }
    };
    that.deviceCreatedWithoutTemplate=true;
    that.iotService.getCuiObjResponse().createActiveDevice({
      data: that.device
    }).then(function (response) {
      if(response!=undefined&& response.length!=0){
      if(that.deviceService.getStreamData()!=undefined ){
        that.addStreamsToDevice(response);
      }
      that.deviceService.successMessage="Device and Device Template created successfully!";
      that.deviceCreated=true;
      that.deviceId=response.device.id;
    }

    }).fail(function (msg) {
      that.deviceCreatedWithoutTemplate=false;
      console.log(msg.responseJSON.apiMessage)
      that.deviceService.resetErrorMessage();
    });
  }

  //  add streams to device.
  addStreamsToDevice(response: any) {

    const that = this;
    that.deviceService.getStreamData().forEach(element => {
      var obj = {
        "creator": that.iotService.getCreator(),
        "creatorAppId": that.iotService.getCreatorAppId(),
        "realm": that.iotService.getRealm(),
        "streamId": element.id,
        "deviceId": response.device.id
      }
      that.iotService.getCuiObjResponse().addDeviceToStream({
        data: obj,
        streamId: element.id
      }).then(function (streamDeviceResponse) {
        console.log("stream device added -- " + streamDeviceResponse);
      }).fail(function (msg) {
        console.log(msg.responseJSON.apiMessage)
      });
    });
  }


  getTags() {
    this.tags = [];
    if (this.deviceService.getDeviceTags() != undefined && this.deviceService.getDeviceTags().length > 0) {
      this.deviceService.getDeviceTags().forEach((value) => {
        this.tags.push(value);
      });
      // this.tags.push(this.deviceService.getDeviceTags());
    }
    return this.tags;
  }

  getAttributeIds() {
    this.attributeIds = [];
    if (this.deviceService.getAttributeData() != undefined) {
      this.deviceService.getAttributeData().forEach((value) => {
        this.attributeIds.push(value.id);
      });
    }

    return this.attributeIds;
  }

  getCommandIds() {
    this.commandIds = [];
    if (this.deviceService.getCommandData() != undefined) {
      this.deviceService.getCommandData().forEach((value) => {
        this.commandIds.push(value.id);
      });
    }
    return this.commandIds;
  }

  getEventIds() {
    this.eventIds = [];
    if (this.deviceService.getEventData() != undefined) {
      this.deviceService.getEventData().forEach((value) => {
        this.eventIds.push(value.id);
      });
    }
    return this.eventIds;
  }

  getGeofenceIds() {
    this.geofenceIds = [];
    if (this.deviceService.getGeofenceData() != undefined) {
      this.deviceService.getGeofenceData().forEach((value) => {
        this.geofenceIds.push(value.id);
      });
    }
    return this.geofenceIds;
  }

  reset(createFromScratch) {
    this.deviceService.resetErrorMessage();
    this.deviceService.resetSuccessMessage();
    this.deviceService.resetWarningMessage();
    this.deviceService.mapLoad = false;
    this.deviceService.createGeofence = false;
    this.deviceService.createResource = false;
    this.deviceService.createAttribute = false;
    this.deviceService.createEvent = false;
    this.deviceService.createCommand = false;
    this.deviceService.setAttributeData(undefined);
    this.deviceService.setCommandData(undefined);
    this.deviceService.setEventData(undefined);
    this.deviceService.setGeofenceData(undefined);
    this.deviceService.setStreamData(undefined);
    this.deviceService.setSelectedTemplate(undefined);
    this.deviceService.setTemplateName(undefined);
    this.deviceService.attributeSelect = false;
    this.deviceService.eventSelect = false;
    this.deviceService.commandSelect = false;
    this.deviceService.templateSelect = false;
    this.deviceService.geofenceSelect = false;
    this.deviceService.streamSelect = false;
    this.deviceService.deviceName = '';
    this.deviceService.newTemplatename = '';
    if(this.deviceService.deviceTags!=undefined){
      this.deviceService.deviceTags.length=0;
    }
    this.deviceService.deviceDescription = '';
    this.deviceService.deviceNameSelect = false;
    this.deviceService.templateNameSelect = false;
    this.deviceCreated=false;
    this.tags.length=0;
    this.deviceId='';
    if (createFromScratch === 2) {
      this.deviceService.createFromScratch = true;
    }
    else {
      this.deviceService.createFromScratch = false;
    }
  }

  resetRadio(val){
    this.deviceCreate = val;
  }


  
}



import { Component, OnInit } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IotService } from 'src/app/service/iot.service';
import { SideNavComponent } from 'src/app/side-nav/side-nav.component';
import { Router,NavigationEnd } from '@angular/router';
import {
  faToggleOff,
  faToggleOn,
  faCheck,
  faExclamation,
  faExclamationTriangle,
  faTimes,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import { EcosystemsService } from '../service/ecosystems.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CreateRulePolicyComponent } from '../create-rule-policy/create-rule-policy.component';
import { RulesService } from '../service/rules.service';

@Component({
  selector: 'app-ecosystem-detail',
  templateUrl: './ecosystem-detail.component.html',
  styleUrls: ['./ecosystem-detail.component.scss'],
})
export class EcosystemDetailComponent implements OnInit {
  faCheckIcon = faCheck;
  faToggleOffIcon = faToggleOff;
  faToggleOnIcon = faToggleOn;
  faCloseIcon = faTimes;
  faWarningIcon = faExclamationTriangle;
  faCriticalIcon = faExclamation;
  faSuccessIcon = faCheckCircle;
  supportLanguages = ['en'];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tags = [];
  tagClass = 'tagInput';
  ecosystem;
  ecosystemTemplateName;
  
  statusActiveClass = 'bg-lifecycle';
  statusSuspendedClass = 'bg-lifecycle';
  matVersion: string = '5.1.0';
  breakpoint: number;
  updateNameDesc: boolean = true;
  hasError: boolean = false;
  errorInName;
  // ecosystemMemberShips = [];
  relationship: any;
  targetEntityList: any[];
  effectiveDate;
  effectiveTime;
  expirationTime;
  expirationDate;
  relationNameError: string;
  sourceEntityError: string;
  targetEntityError: string;
  addRulePolicy = false;
  navigationSubscription;
  constructor(
    private route: ActivatedRoute,
    public iotService: IotService,
    private sideNavComponent: SideNavComponent,
    private translateService: TranslateService,
    public ecosystemService: EcosystemsService,
    private router: Router,
    // public createRulePolicyComponent: CreateRulePolicyComponent,
    public rulesService: RulesService
  ) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
     //   this.initialiseInvites();
      }
    });
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }

  ngOnInit(): void {
    if (window.innerWidth <= 1200 && window.innerWidth > 650) {
      this.breakpoint = 2;
    } else if (window.innerWidth <= 650) {
      this.breakpoint = 1;
    } else {
      this.breakpoint = 4;
    }

    this.iotService.showEcoviewer=false;
    this.ecosystemService.viewResource = true;
    this.ecosystemService.viewRelationShip = false;
    this.ecosystemService.viewMembership = false;
    this.ecosystemService.viewAttributes = false;
    this.iotService.viewRulePolicy = false;
    this.ecosystemService.setCustomAttributes(new Array());
    this.ecosystemService.setCustomAttributesForUpdate(new Array());
    this.ecosystemService.setRelationShipAttributes(new Array());
    this.ecosystemService.setRelationshipAttributesForUpdate(new Array());

    this.route.params.subscribe((params) => {
      var ecosystemId = params['id'];
      this.loadEcosystem(ecosystemId);
      this.getEcosystemMemberships(ecosystemId);
    });
    this.iotService.viewEcosystem = true;
    this.initRelation();
  }

  initRelation() {
    const that = this;
    that.relationship = {
      creator: that.iotService.getCreator(),
      creatorAppId: that.iotService.getCreatorAppId(),
      realm: that.iotService.getRealm(),
      sourceEntity: {
        id: '',
        type: 'groupMembership',
      },
      targetEntity: {
        id: '',
        type: 'groupMembership',
      },
      group: {
        id: '',
        type: 'group',
        realm: that.iotService.getRealm(),
      },
      relationName: '',
      effectiveInstant: 0,
      expirationInstant: 0,
      relationshipAttributes: [],
    };
  }
  getEcosystemMemberships(ecosystemId) {
    const that = this;
    that.iotService
      .getCuiObjResponse()
      .getEcosystemInstanceMembers({
        ecosystemId: ecosystemId,
      })
      .then(function (response) {
        // that.ecosystemMemberShips = response;
        that.ecosystemService.ecosystemMemberShips = response;
      })
      .fail(function (err) {
        if (err.responseJSON && err.responseJSON.apiStatusCode) {
          console.log(
            'Error Occured: Status: ' +
              err.responseJSON.status +
              ' Msg: ' +
              err.responseJSON.apiStatusCode
          );
        } else {
          console.log(
            'Something went wrong. Please check chrome console or check with dev team'
          );
        }
      });
  }

  loadEcosystem(ecosystemId: any) {
    const that = this;
    that.iotService
      .getCuiObjResponse()
      .getEcosystemById({
        groupId: ecosystemId,
      })
      .then(function (response) {
        console.log(response);
        that.ecosystem = response;
        that.ecosystemService.setParentEcosystemTemplateId(
          that.ecosystem.parentGroupTemplateId
        );
        that.ecosystemService.setEcosystemInstanceId(that.ecosystem.id);
        that.loadEcosystemTemplate(that.ecosystem.parentGroupTemplateId);

        if (!that.ecosystem.description) {
          that.ecosystem.description = [];
          that.ecosystem.description.push({ lang: 'en-us', text: '' });
        }
        if (!that.ecosystem.tags) {
          that.ecosystem['tags'] = [];
        }
        if (that.ecosystem.lifecycleState == 'ACTIVE') {
          that.statusActiveClass = 'bg-lifecycle-selected';
          that.statusSuspendedClass = 'bg-lifecycle';
        }
        if (that.ecosystem.lifecycleState == 'SUSPENDED') {
          that.statusActiveClass = 'bg-lifecycle';
          that.statusSuspendedClass = 'bg-lifecycle-selected';
        }
        
        that.sideNavComponent.menuClear();
        that.sideNavComponent.menuChange('Dashboard', 'sub-type');
        that.sideNavComponent.menuChange('ecosystems.header', 'sub-type');
        that.sideNavComponent.menuChange(
          that.ecosystem.name[0].text,
          'sub-type'
        );
      })
      .fail(function (err) {
        if (err.responseJSON && err.responseJSON.apiStatusCode) {
          console.log(
            'Error Occured: Status: ' +
              err.responseJSON.status +
              ' Msg: ' +
              err.responseJSON.apiStatusCode
          );
        } else {
          console.log(
            'Something went wrong. Please check chrome console or check with dev team'
          );
        }
      });
  }

  loadEcosystemTemplate(ecosystemTemplateId: any) {
    const that = this;
    that.iotService
      .getCuiObjResponse()
      .getEcosystemTemplateById({
        groupTemplateId: ecosystemTemplateId,
      })
      .then(function (response) {
        that.ecosystemTemplateName = response.name[0].text;
        if(response.allowedMemberTypes != undefined) {
        that.ecosystemService.allowedMemberTypes = response.allowedMemberTypes;
        }
        else{
          that.ecosystemService.allowedMemberTypes = [];
        }
      })
      .fail(function (err) {
        if (err.responseJSON && err.responseJSON.apiStatusCode) {
          console.log(
            'Error Occured: Status: ' +
              err.responseJSON.status +
              ' Msg: ' +
              err.responseJSON.apiStatusCode
          );
        } else {
          console.log(
            'Something went wrong. Please check chrome console or check with dev team'
          );
        }
      });
  }

  onResize(event) {
    if (event.target.innerWidth <= 1200 && event.target.innerWidth > 650) {
      this.breakpoint = 2;
    } else if (event.target.innerWidth <= 650) {
      this.breakpoint = 1;
    } else {
      this.breakpoint = 4;
    }
  }

  enableUpdate(event: any) {
    this.updateNameDesc = false;
  }

  ecosystemNameInput(event: any) {
    const that = this;
    that.ecosystem.name[0].text = event.target.value;
    if (that.ecosystem.name[0].text.length > 0) {
      that.errorInName = '';
    } else {
      that.ecosystem.name[0].text = '';
    }
  }

  goBackCustomAttributes() {
    this.ecosystemService.viewResource = true;
    this.ecosystemService.viewAttributes = false;
  }

  goBackRelation() {
    this.ecosystemService.viewResource = true;
    this.ecosystemService.viewRelationShip = false;
  }

  viewEcosystemTemplate(parentEcosystemTemplateId) {
    this.router.navigateByUrl(
      '/ecosystemTemplates/' + parentEcosystemTemplateId
    );
  }

  saveRelationship() {
    const that = this;
    if (!that.validateDate()) {
      that.relationship.group.id = that.ecosystem.id;
      that.relationship.relationshipAttributes =
        that.ecosystemService.getRelationShipAttributes();

      let attributesForUpdate =
        that.ecosystemService.getRelationshipAttributesForUpdate();
      if (attributesForUpdate.length > 0) {
        let existingAttributes = that.relationship.relationshipAttributes;
        existingAttributes.forEach((element) => {
          let result = attributesForUpdate.filter(
            (e) => e.attributeId == element.attributeTypeId
          );
          if (result.length > 0) {
            let attrValue = result[0].value;

            if (result[0].type == 'integer') {
              if (attrValue != '') {
                element.value = parseInt(attrValue);
                if (isNaN(attrValue)) {
                  that.ecosystemService.warningMessage =
                    'Please insert valid integer value in "' +
                    result[0].name +
                    '"';
                }
              }
            } else if (result[0].type == 'decimal') {
              if (attrValue != '') {
                if (attrValue % 1 == 0) {
                  attrValue = +(
                    parseFloat(attrValue) + parseFloat('0.00001')
                  ).toFixed(6);
                }
                element.value = parseFloat(attrValue);
                if (isNaN(attrValue)) {
                  that.ecosystemService.warningMessage =
                    'Please insert valid decimal value in "' +
                    result[0].name +
                    '"';
                }
              }
            } else if (result[0].type == 'bool') {
              if (attrValue == 'true') {
                element.value = true;
              } else {
                element.value = false;
              }
            }
          }
        });
        that.relationship.relationshipAttributes = existingAttributes;
      }

      that.iotService
        .getCuiObjResponse()
        .addRelationship({
          ecosystemId: that.ecosystem.id,
          data: that.relationship,
        })
        .then(function (response) {
          that.ecosystemService.successMessage =
            'Relationship created successfully!';
          console.log('Relation added successfully!! Id - ', response.id);
          that.goBackRelation();
        })
        .fail(function (err) {
          if (err.responseJSON && err.responseJSON.apiStatusCode) {
            that.ecosystemService.errorMessage = err.responseJSON.apiMessage;
            console.log(
              'Error Occured: Status: ' +
                err.responseJSON.status +
                ' Msg: ' +
                err.responseJSON.apiStatusCode
            );
          } else {
            console.log(
              'Something went wrong. Please check chrome console or check with dev team'
            );
          }
        });
    }
  }

  validateDate() {
    const that = this;
    var hasError = false;
    if (that.relationship.relationName == '') {
      that.relationNameError = 'Please enter a relationship value.';
      hasError = true;
    }
    if (that.relationship.sourceEntity.id == '') {
      that.sourceEntityError = 'Please enter a value for source.';
      hasError = true;
    }
    if (that.relationship.targetEntity.id == '') {
      that.targetEntityError = 'Please enter a value for target.';
      hasError = true;
    }

    if (that.effectiveDate != undefined && that.effectiveDate != '') {
      var date = new Date(that.effectiveDate);
      var day = date.getDate();
      var month = date.getMonth();
      var year = date.getFullYear();

      if (that.effectiveTime != undefined && that.effectiveTime != '') {
        var hours = that.effectiveTime.split(':')[0];
        var minutes = that.effectiveTime.split(':')[1];
        var seconds = that.effectiveTime.split(':')[2];
        that.relationship.effectiveInstant = new Date(
          new Date(year, month, day, hours, minutes, seconds)
        ).getTime();
      } else {
        that.relationship.effectiveInstant = new Date(
          new Date(year, month, day)
        ).getTime();
      }
    } else {
      delete that.relationship['effectiveInstant'];
    }

    if (that.expirationDate != undefined && that.expirationDate != '') {
      var date = new Date(that.expirationDate);
      var day = date.getDate();
      var month = date.getMonth();
      var year = date.getFullYear();

      if (that.expirationTime != undefined && that.expirationTime != '') {
        var hours = that.expirationTime.split(':')[0];
        var minutes = that.expirationTime.split(':')[1];
        var seconds = that.expirationTime.split(':')[2];
        that.relationship.expirationInstant = new Date(
          new Date(year, month, day, hours, minutes, seconds)
        ).getTime();
      } else {
        that.relationship.expirationInstant = new Date(
          new Date(year, month, day)
        ).getTime();
      }
    } else {
      delete that.relationship['expirationInstant'];
    }

    return hasError;
  }

  populateTargetList() {
    const that = this;
    that.targetEntityList = [];
    if (that.relationship.sourceEntity.id != '') {
      that.ecosystemService.ecosystemMemberShips.forEach((element) => {
        that.targetEntityList.push(element);
      });
      that.targetEntityList.forEach((value, key) => {
        if (value.id === that.relationship.sourceEntity.id) {
          that.targetEntityList.splice(key, 1);
        }
      });
    }
  }

  addCustomAttributes() {
    const that = this;
    let customAttributes = that.ecosystemService.getCustomAttributes();
    if (customAttributes.length > 0) {
      customAttributes.forEach((customAttribute) => {
        that.ecosystem.attributes.push(customAttribute);
      });

      var ecosystemForUpdate = JSON.parse(JSON.stringify(that.ecosystem));
      console.log('ecosystemForUpdate' + ecosystemForUpdate);
      if (!that.errorValidations(ecosystemForUpdate)) {
        that.iotService
          .getCuiObjResponse()
          .updateEcosystem({
            groupId: ecosystemForUpdate.id,
            data: ecosystemForUpdate,
          })
          .then(function (response) {
            console.log('updated Ecosystem' + response);
            that.ecosystemService.successMessage =
              'Attributes added successfully!';
            that.loadEcosystem(ecosystemForUpdate.id);
            that.goBackCustomAttributes();
          })
          .fail(function (err) {
            if (err.responseJSON.apiStatusCode) {
              that.ecosystemService.errorMessage = err.responseJSON.apiMessage;
              console.log(
                'Error Occured: Status: ' +
                  err.responseJSON.status +
                  ' Msg: ' +
                  err.responseJSON.apiStatusCode
              );
            } else {
              console.log(
                'Something went wrong. Please check chrome console or check with dev team'
              );
            }
          });
      }
    } else {
      that.ecosystemService.warningMessage =
            'Please select attribute(s) from the list';
    }
  }

  add(event: MatChipInputEvent): void {
    this.tagClass = 'tagInput';
    const input = event.input;
    const value = event.value;
    const index = this.ecosystem.tags.indexOf(value);
    if (index < 0) {
      if ((value || '').trim()) {
        if (
          this.ecosystem.tags != undefined &&
          this.ecosystem.tags.includes(value)
        ) {
          if (input) {
            input.value = '';
          }
        } else {
          this.ecosystem.tags.push(value);
          this.addTag(value);
          if (input) {
            input.value = '';
          }
        }
      }
    } else {
      this.tagClass = 'tagInput-duplicate';
    }
  }

  remove(tag: string): void {
    const that = this;
    that.ecosystem.tags.forEach((value, index) => {
      if (value == tag) that.ecosystem.tags.splice(index, 1);
      that.removeTag(tag);
    });
  }

  addTag(tag) {
    const that = this;
    that.iotService
      .getCuiObjResponse()
      .createEcosystemTag({
        groupId: that.ecosystem.id,
        tag: tag,
      })
      .then(function (response) {
        console.log('Tag added to ecosystem successfully ' + tag);
      })
      .fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          that.ecosystemService.errorMessage = err.responseJSON.apiMessage;
          console.log(
            'Error Occured: Status: ' +
              err.responseJSON.status +
              ' Msg: ' +
              err.responseJSON.apiStatusCode
          );
        } else {
          console.log(
            'Something went wrong. Please check chrome console or check with dev team'
          );
        }
      });
  }

  removeTag(tag) {
    const that = this;
    that.iotService
      .getCuiObjResponse()
      .deleteEcosystemTag({
        groupId: that.ecosystem.id,
        tag: tag,
      })
      .then(function (response) {
        console.log('Tag removed from ecosystem successfully ' + tag);
      })
      .fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          that.ecosystemService.errorMessage = err.responseJSON.apiMessage;
          console.log(
            'Error Occured: Status: ' +
              err.responseJSON.status +
              ' Msg: ' +
              err.responseJSON.apiStatusCode
          );
        } else {
          console.log(
            'Something went wrong. Please check chrome console or check with dev team'
          );
        }
      });
  }

  updateEcosystem() {
    const that = this;
    let attributesForUpdate =
      that.ecosystemService.getCustomAttributesForUpdate();

    if (attributesForUpdate.length > 0) {
      let existingAttributes = that.ecosystem.attributes;
      existingAttributes.forEach((element) => {
        let result = attributesForUpdate.filter(
          (e) => e.attributeId == element.attributeTypeId
        );
        if (result.length > 0) {
          let attrValue = result[0].value;

          if (result[0].type == 'integer') {
            if (attrValue != '') {
              element.value = parseInt(attrValue);
              if (isNaN(attrValue)) {
                that.ecosystemService.warningMessage =
                  'Please insert valid integer value in "' +
                  result[0].name +
                  '"';
              }
            }
          } else if (result[0].type == 'decimal') {
            if (attrValue != '') {
              if (attrValue % 1 == 0) {
                attrValue = +(
                  parseFloat(attrValue) + parseFloat('0.00001')
                ).toFixed(6);
              }
              element.value = parseFloat(attrValue);
              if (isNaN(attrValue)) {
                that.ecosystemService.warningMessage =
                  'Please insert valid decimal value in "' +
                  result[0].name +
                  '"';
              }
            }
          } else if (result[0].type == 'bool') {
            if (attrValue == 'true') {
              element.value = true;
            } else {
              element.value = false;
            }
          }
        }
      });
      that.ecosystem.attrributes = existingAttributes;
    }

    var ecosystemForUpdate = JSON.parse(JSON.stringify(that.ecosystem));
    console.log('ecosystemForUpdate' + ecosystemForUpdate);

    if (!that.errorValidations(ecosystemForUpdate)) {
      that.iotService
        .getCuiObjResponse()
        .updateEcosystem({
          groupId: ecosystemForUpdate.id,
          data: ecosystemForUpdate,
        })
        .then(function (response) {
          console.log('updated Ecosystem' + response);
          that.ecosystemService.successMessage =
            'Ecosystem updated successfully!';
          that.loadEcosystem(ecosystemForUpdate.id);
        })
        .fail(function (err) {
          if (err.responseJSON.apiStatusCode) {
            console.log(
              'Error Occured: Status: ' +
                err.responseJSON.status +
                ' Msg: ' +
                err.responseJSON.apiStatusCode
            );
          } else {
            console.log(
              'Something went wrong. Please check chrome console or check with dev team'
            );
          }
        });
    }
  }

  createRelationShip() {
    this.ecosystemService.viewResource = false;
    this.ecosystemService.viewAttributes = false;
    this.ecosystemService.viewRelationShip = true;
    this.ecosystemService.viewMembership = false;
    this.ecosystemService.setRelationShipAttributes(new Array());
    this.ecosystemService.setRelationshipAttributesForUpdate(new Array());
    this.initRelation();
    this.expirationDate = '';
    this.expirationTime = '';
    this.effectiveTime = '';
    this.effectiveDate = '';
  }

  createMemberShip() {
    this.ecosystemService.viewResource = false;
    this.ecosystemService.viewAttributes = false;
    this.ecosystemService.viewMembership = true;
    this.ecosystemService.viewRelationShip = false;
  }

  errorValidations(ecosystemForUpdate) {
    const that = this;
    that.hasError = false;
    if (
      ecosystemForUpdate.name[0].text == undefined ||
      ecosystemForUpdate.name[0].text == ''
    ) {
      that.errorInName = 'Name is mandatory';
      that.hasError = true;
    }

    if (
      ecosystemForUpdate.description != null &&
      ecosystemForUpdate.description[0].text != ''
    ) {
      if (
        ecosystemForUpdate.description[0].text != null &&
        ecosystemForUpdate.description[0].lang == null
      ) {
        delete ecosystemForUpdate.description;
        ecosystemForUpdate.description = [];
        ecosystemForUpdate.description.push({
          lang: 'en-us',
          text: that.ecosystem.description[0].text,
        });
      }
    }
    return that.hasError;
  }

  toggleActive(status) {
    this.ecosystem.active = status;
    if (this.ecosystem.active == true) {
      this.deactivateEcosystem(this.ecosystem.id);
    } else {
      this.activateEcosystem(this.ecosystem.id);
    }
  }

  activateEcosystem(id: any) {
    const that = this;
    that.iotService
      .getCuiObjResponse()
      .activateEcosystem({
        qs: [['groupId', id]],
      })
      .then(function (response) {
        that.loadEcosystem(id);
        console.log('Ecosystem activated successfully, Id: ', id);
      })
      .fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          that.ecosystemService.errorMessage = err.responseJSON.apiMessage;
          console.log(
            'Error Occured: Status: ' +
              err.responseJSON.status +
              ' Msg: ' +
              err.responseJSON.apiStatusCode
          );
        } else {
          console.log(
            'Something went wrong. Please check chrome console or check with dev team'
          );
        }
      });
  }

  deactivateEcosystem(id: any) {
    const that = this;
    that.iotService
      .getCuiObjResponse()
      .deactivateEcosystem({
        qs: [['groupId', id]],
      })
      .then(function (response) {
        that.loadEcosystem(id);
        console.log('Ecosystem deactivated successfully, Id: ', id);
      })
      .fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          that.ecosystemService.errorMessage = err.responseJSON.apiMessage;
          console.log(
            'Error Occured: Status: ' +
              err.responseJSON.status +
              ' Msg: ' +
              err.responseJSON.apiStatusCode
          );
        } else {
          console.log(
            'Something went wrong. Please check chrome console or check with dev team'
          );
        }
      });
  }

  suspendEcosystem(ecosystemId) {
    const that = this;
    that.iotService
      .getCuiObjResponse()
      .suspendEcosystem({
        qs: ['groupId', ecosystemId],
      })
      .then(function (response) {
        console.log('suspended Ecosystem');
        that.loadEcosystem(ecosystemId);
      })
      .fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          that.ecosystemService.errorMessage = err.responseJSON.apiMessage;
          console.log(
            'Error Occured: Status: ' +
              err.responseJSON.status +
              ' Msg: ' +
              err.responseJSON.apiStatusCode
          );
        } else {
          console.log(
            'Something went wrong. Please check chrome console or check with dev team'
          );
        }
      });
  }

  unsuspendEcosystem(ecosystemId) {
    const that = this;
    that.iotService
      .getCuiObjResponse()
      .unsuspendEcosystem({
        qs: ['groupId', ecosystemId],
      })
      .then(function (response) {
        console.log('unsuspended Ecosystem');
        that.loadEcosystem(ecosystemId);
      })
      .fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          that.ecosystemService.errorMessage = err.responseJSON.apiMessage;
          console.log(
            'Error Occured: Status: ' +
              err.responseJSON.status +
              ' Msg: ' +
              err.responseJSON.apiStatusCode
          );
        } else {
          console.log(
            'Something went wrong. Please check chrome console or check with dev team'
          );
        }
      });
  }

  createRuleFromEcosystem(ecosystemId: any) {
    this.iotService.ecosystemId = ecosystemId;
    this.iotService.ecosystemAddRulePolicy = true;
    this.iotService.viewRulePolicy = true;
  }

  resetErrorFields() {
    this.errorInName = '';
  }

  launchColumnView(ecosystemId: any) {
    this.iotService.showEcoviewer = true;
    this.iotService.ecoviewerId = ecosystemId;
  }
  ngOnDestroy() {
    this.iotService.viewEcosystem = false;
    this.iotService.ecosystemAddRulePolicy = false;
    this.iotService.viewRule = false;
    this.addRulePolicy = false;
    this.resetErrorFields();
    this.ecosystemService.resetErrorMessage();
    this.ecosystemService.resetSuccessMessage();
    this.ecosystemService.resetWarningMessage();
    this.ecosystemService.allowedMemberTypes = [];
    this.iotService.viewRulePolicy = false;
    if (this.navigationSubscription) {  
      this.navigationSubscription.unsubscribe();
   }
  }
}

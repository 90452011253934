import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IotService } from '../../service/iot.service';
import { DeviceService } from '../../service/device.service';
import { faAngleDoubleLeft, faAngleDoubleRight,faToggleOff,faToggleOn,faCheck} from '@fortawesome/free-solid-svg-icons';
import {SelectionModel} from '@angular/cdk/collections';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss']
})
export class EventListComponent implements OnInit {

 
  dataSource: any;
  displayedColumns: string[] = [' ','Name', 'Description', 'Tags','Enabled'];
  length = 1000;
  faAngleDoubleLeftIcon = faAngleDoubleLeft;
  faAngleDoubleRightIcon = faAngleDoubleRight;
  faToggleOffIcon = faToggleOff;
  faToggleOnIcon = faToggleOn;
  faCheckIcon = faCheck;
  eventSelected:string;
  selectedEvents=[];
  eventId;
 

  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;
  pageSize = 5;
  currentPage =1;
  showValue = false;
  events=[];
  private role: string;
  showSelect = true;
  HighlightRow : Number;
  selection: SelectionModel<Element> = new SelectionModel<Element>(true, []);
  supportLanguages = ['en'];

  eventCount:number=0;
 /*  pageListOptions: number[] = [1];
  totalPages=0;
  firstPageElement:number=1;
  lastPage:boolean=true;
  firstPage:boolean=true;
  defaultPages = 10; */
  showPagination=false;
  constructor(private iotService:IotService,private deviceService:DeviceService,private translateService: TranslateService) { 
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }

  ngOnInit(): void {
    this.getEvents();
    if(this.deviceService.getEventData()!=undefined){
      this.deviceService.getEventData().forEach((value,index)=>{
        this.selectedEvents.push(value);
       });
       this.selection=new SelectionModel<Element>(true, this.selectedEvents);
    }
    this.selection.isSelected = this.isChecked.bind(this);
    this.counEvents('');
  }
  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }

  isChecked(row: any): boolean {
    const found = this.selection.selected.find(el => el.id === row.id);
    if (found) {
      return true;
    }
    return false;
 }

  onEventSearch(event: any){
      this.eventSelected = event.target.value;
      let val = '*'+event.target.value+'*';
      const that = this;
      that.counEvents(val);
      that.currentPage =1;
      this.iotService.getCuiObjResponse().getEventTemplates({
        qs:[['pageSize',5],['page',this.currentPage],['name',val]]
      }).then(function(response) {
        // console.log(response);
        that.events = response;
        that.dataSource = that.events;
      }).fail(function (err){
        if(err.responseJSON.apiStatusCode){
          console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
        } else{
          console.log("Something went wrong. Please check chrome console or check with dev team");
        } 
      });
  }

  getEvents(){
    const that = this;
    this.iotService.getCuiObjResponse().getEventTemplates({
      qs:[['pageSize',5],['page',this.currentPage]]
    }).then(function(response) {
      // console.log(response);
      that.events = response;
      that.dataSource = that.events;
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  getSelectedEventForCurrentPage(selectedEvent){
    this.eventSelected = selectedEvent;
    let startValue = '*'+selectedEvent;
    let endValue = selectedEvent + '*';
    const that = this;
    this.iotService.getCuiObjResponse().getEventTemplates({
      qs:[['pageSize',5],['page',this.currentPage],['name',startValue],['name',endValue]]
    }).then(function(response) {
      // console.log(response);
      that.events = response;
      that.dataSource = that.events;
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      } 
    });
  }

  OnSelection(event,eventTemplateId){
      
    const that = this;
    console.log(event); //true or false
   
    this.iotService.getCuiObjResponse().getEventTemplate({
      eventTemplateId: eventTemplateId
    }).then(function(response) {
      // console.log(response);

      if (event == true) {
        that.selection.select(response);
        that.deviceService.eventSelect = true;
        that.selectedEvents.push(response);
      } 
      else {
        that.selection.clear();
        that.selectedEvents.forEach((value, index) => {
          if (value.id == eventTemplateId) that.selectedEvents.splice(index, 1);
        });
        that.selectedEvents.forEach((value, index) => {
          that.selection.select(value);
        });

      }
      that.deviceService.setEventData(that.selectedEvents);
      if (that.deviceService.getEventData().length < 1) {
        that.deviceService.eventSelect = false;
      }
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  enableEvent(eventTemplateId : any){
    const that = this;
    this.iotService.getCuiObjResponse().activateEventTemplate({
      eventTemplateId: eventTemplateId
    }).then(function(response) {
      // console.log(response);
      if(that.eventSelected){
        that.getSelectedEventForCurrentPage(that.eventSelected)
      }
      else{        
         that.getEvents();
      }
    }).fail(function (err){
      if (err.responseJSON.apiStatusCode) {
 
         var errorStr=err.responseJSON.apiMessage;
           var error = [];
           error=errorStr.split(":");
         if(err.responseJSON.apiStatusCode=="framework:resource:eventTemplate:duplicate"){
           var eventId = error[1].trim();
         that.deviceService.duplicateEventId = eventId;
         that.deviceService.duplicateEventFlag = true;
         that.deviceService.duplicateCommandFlag = false;
         that.deviceService.duplicateAttrFlag = false;
         that.deviceService.warningMessage="Event template with same name is active. Click here to "
           console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
         }
       } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  disableEvent(event:any,eventTemplateId : any){
    const that = this;
    this.iotService.getCuiObjResponse().deactivateEventTemplate({
      eventTemplateId: eventTemplateId
    }).then(function(response) {
      // console.log(response);
      that.selection.clear();
      that.selectedEvents.forEach((value, index) => {
        if (value.id == eventTemplateId) that.selectedEvents.splice(index, 1);
      });
      that.selectedEvents.forEach((value, index) => {
        that.selection.select(value);
      });
    that.deviceService.setEventData(that.selectedEvents);
    if(that.deviceService.getEventData()!=undefined && that.deviceService.getEventData().length<1){
      that.deviceService.eventSelect=false;
    }
    
      if(that.eventSelected){
        that.getSelectedEventForCurrentPage(that.eventSelected)
      }
      else{        
         that.getEvents();
      }
    }).fail(function (err){
      if(err.responseJSON.apiStatusCode){
        console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
      } else{
        console.log("Something went wrong. Please check chrome console or check with dev team");
      }
    });
  }

  dataTypes=[];
  dataPoints=[];

  getDataTypes(eventObject){
    this.dataTypes=[];
    if(eventObject.eventFields!=undefined){
    eventObject.eventFields.forEach(arg => {
      this.dataTypes.push(arg.type);
    });
    return this.dataTypes;    
  }
  }

  getDataPoints(eventObject){
    this.dataPoints=[];
    if(eventObject.eventFields!=undefined){
    eventObject.eventFields.forEach(elem => {
      this.dataPoints.push(elem.name);
    });
    return this.dataPoints;    
  }
  }

  createEvent(){
    this.deviceService.createResource=true;
    this.deviceService.createEvent=true;
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  counEvents(val) {
    const that = this;
    that.showPagination=false;
    if(val === "**") {
      val = '';
    }
    if(val === '') {
      that.iotService.getCuiObjResponse().countEventTemplates({
      }).then(function (response) {
        console.log(response);
        that.eventCount = response;
      //  that.getCountPages();
        that.showPagination=true;
      }).fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
        } else {
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    } else {
      that.iotService.getCuiObjResponse().countEventTemplates({
        qs:[['name',val]]
      }).then(function (response) {
        console.log(response);
        that.eventCount = response;
      //  that.getCountPages();
        that.showPagination=true;
      }).fail(function (err) {
        if (err.responseJSON.apiStatusCode) {
          console.log("Error Occured: Status: " + err.responseJSON.status + " Msg: " + err.responseJSON.apiStatusCode);
        } else {
          console.log("Something went wrong. Please check chrome console or check with dev team");
        }
      });
    }
  }

  onPageChange(page) {
    const that = this;
    console.log("page : "+page);
    that.currentPage = page;
    if(this.eventSelected) {
      this.getSelectedEventForCurrentPage(this.eventSelected);
    } else {
      this.getEvents();
    }
  }
  /* getCountPages() {
    const that = this;
    that.totalPages = Math.ceil(that.eventCount / that.pageSize);
    that.firstPageElement = 1;

    console.log("******** getCountPages *********");
    console.log("totalPages : " + that.totalPages);
    if (that.totalPages == 0) {
      that.firstPageElement = 1;
      that.pageListOptions = [1];
      that.lastPage = true;
      that.firstPage = true;
    } else if (that.totalPages > that.defaultPages) {
      that.pageListOptions = Array.from({length:that.defaultPages},(v,k)=>that.firstPageElement++);
      that.lastPage = false;
      that.firstPage = true;
    } else {
      that.pageListOptions = Array.from({length:that.totalPages},(v,k)=>that.firstPageElement++);
      that.lastPage = true;
      that.firstPage = true;
    }
    console.log("that.pageListOptions : "+that.pageListOptions);
  }

  onPageChange(page) {
    const that = this;
    console.log("page : "+page);
    that.currentPage = page;
    if(this.eventSelected) {
      this.getSelectedEventForCurrentPage(this.eventSelected);
    } else {
      this.getEvents();
    }
  }

  prevPage() {
    const that = this;
    that.lastPage=false;
    
    console.log("******** prevPage *********");
    console.log("that.firstPageElement : "+that.firstPageElement);
    console.log("that.totalPages : "+that.totalPages);

    if(that.firstPageElement > that.totalPages) {
      that.firstPageElement = that.firstPageElement-that.defaultPages-(that.totalPages % that.defaultPages);
    } else {
      that.firstPageElement = that.firstPageElement-(2*that.defaultPages);
    }

    if (that.firstPageElement < 1) {
      that.firstPageElement = 1;
      that.firstPage = true;
    } else if(that.firstPageElement === 1) {
      that.firstPage = true;
    }
    that.pageListOptions = Array.from({length:that.defaultPages},(v,k)=> that.firstPageElement++);

    console.log("that.pageListOptions : "+that.pageListOptions);
  }

  nextPage() {
    const that = this;
    that.firstPage = false;
    console.log("that.totalPages : "+that.totalPages);
    console.log("that.firstPageElement : "+that.firstPageElement);
    var last = that.totalPages - that.firstPageElement;
   
    console.log("last : "+last);
    if(last < 0) {
      that.firstPageElement = 1;
      that.firstPage = true;
      that.pageListOptions = Array.from({length:that.defaultPages},(v,k)=> that.firstPageElement++);
    } else {
      if(last < that.defaultPages) {
        that.pageListOptions = Array.from({length:last+1},(v,k)=> that.firstPageElement++);
        that.lastPage = true;
      } else {
        that.pageListOptions = Array.from({length:that.defaultPages},(v,k)=> that.firstPageElement++);
        that.lastPage = false;
      }  
    }
    console.log("that.pageListOptions : "+that.pageListOptions);
  }
 */
}

<div class="tradingPartnerCodeData">

    <div class=form>
        <label for="code"> {{ 'codeRelationships.controlNumber' | translate}}</label>
        <input placeholder="optional" [(ngModel)]="tradingPartnerCodeRelationShip.controlNumber" (click)="resetData()"
            id="code" type="text" aria-label="code">

        <label for="description"> {{ 'description' | translate}}</label>
        <textarea rows="2" [(ngModel)]="tradingPartnerCodeRelationShip.description[0].text" placeholder="optional"
            id="description" aria-label="Description"></textarea>

        <label>{{'enabled'|translate}}</label>
        <button *ngIf="isActive" class="toggle-on toggle-switch" (click)="toggleActive()">
            <fa-icon [icon]="faCheckIcon" class="fa-2x"></fa-icon>
            <div class="toggle-on-btn"></div>
        </button>

        <button *ngIf="!isActive" class="toggle-off toggle-switch" (click)="toggleActive()">
            <div class="toggle-off-btn"></div>
        </button>
    </div>
    <div *ngIf="loadCodes">
        <app-fromTPCodes [pageSize]="5" [showControlBar]=false [displayedColumns]="['ADD','code','qualifier','ACTIVE']"
            [showSelectColoumn]=true [showEnableColumn]=true [showActionColumn]=true multiSelect="false"
            addnQueryParam=[tradingPartnerId::{{tpService.tradingPartnerIds}}] [showHeaderToolTips]=false></app-fromTPCodes>

        <app-toTPCodes [pageSize]="5" [showControlBar]=false [displayedColumns]="['ADD','code','qualifier','ACTIVE']"
            [showSelectColoumn]=true [showEnableColumn]=true multiSelect="false"
            addnQueryParam=[tradingPartnerId::{{tpService.tradingPartnerIds}}] [showHeaderToolTips]=false></app-toTPCodes>
    </div>
    <div *ngIf="!loadCodes">
        <app-fromTPCodes [pageSize]="5" [showControlBar]=false [displayedColumns]="['ADD','code','qualifier','ACTIVE']"
            [showSelectColoumn]=true [showEnableColumn]=true [showActionColumn]=true multiSelect="false"
            addnQueryParam=[tradingPartnerId::{{tpService.tradingPartnerIds}}] [showHeaderToolTips]=false></app-fromTPCodes>

        <app-toTPCodes [pageSize]="5" [showControlBar]=false [displayedColumns]="['ADD','code','qualifier','ACTIVE']"
            [showSelectColoumn]=true [showEnableColumn]=true multiSelect="false"
            addnQueryParam=[tradingPartnerId::{{tpService.tradingPartnerIds}}] [showHeaderToolTips]=false></app-toTPCodes>
    </div>

    <div class="button-row pull-right"  style="margin-top: 5px; margin-right: 15px;">
        <button class="btn-secondary"(click)="goBack()">{{ 'go.back' |
            translate}}</button>
        <button class="btn-primary" (click)="save()">{{ 'save' | translate}}</button>
        <mat-checkbox class="checkbox" [(ngModel)]="createAnother">{{'create.another' | translate}}</mat-checkbox>
        <br>

    </div>

</div>
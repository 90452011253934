import { Component, OnInit } from '@angular/core';
import { IotService } from '../../service/iot.service';
import { DeviceService } from '../../service/device.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import { faTimes,faCheck} from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-create-command',
  templateUrl: './create-command.component.html',
  styleUrls: ['./create-command.component.scss']
})
export class CreateCommandComponent implements OnInit {

  commandTemplate;
  arguments = [];
  errorMsg;
  displayedColumns: string[] = ['Name', 'Description', 'Data Type',' '];

  faTimesIcon = faTimes;
  faCheckIcon = faCheck;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tags:string[]=[];
  supportLanguages = ['en'];

  nameError;
  commandDisabledClass;
  commandEnabledClass = 'bg-enabled-selected';
  showLoading:boolean=false;
  tagList =[];
  tagClass = 'tagInput';
  commandCreated=false;
  constructor(private iotService:IotService,private deviceService:DeviceService,private translateService: TranslateService) {
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
  }

  ngOnInit(): void {
    this.initCommandTemplate();
  }

  createCommand(){
    this.deviceService.errorMessage = null;
    this.showLoading = false;
    this.deviceService.createResource=false;
    this.deviceService.createCommand=false;
  }

  initCommandTemplate() {
    const that = this;
    that.commandTemplate = {
      "creator": that.iotService.getCreator(),
      "creatorAppId": that.iotService.getCreatorAppId(),
      "realm": that.iotService.getRealm(),
      'name': '',
      'description': [{
          'lang': 'en_us',
          'text': ''
      }],
      'isActive': true,
      'requiresAck': 'false'
    };

    that.arguments.push({
        name: '',
        description: '',
        type: ''
    });
  }

  add(event: MatChipInputEvent): void {
    this.tagClass = 'tagInput';
    const input = event.input;
    const value = event.value;
    const index = this.tagList.indexOf(value);
    this.tagList.push(value);

    if(index<0){
    if ((value || '').trim()) {
      const that = this;
      that.tags.push(value);
      // Reset the input value
      if (input) {
        input.value = '';
      }
    }
   }else{
    this.tagClass = 'tagInput-duplicate';
    }
  }

  toggleActivate(active) {
    const that = this;
    if (active) {
      that.commandDisabledClass = 'bg-disabled';
      that.commandEnabledClass = 'bg-enabled-selected';
      that.commandTemplate.isActive = true;
    } else {
      that.commandDisabledClass = 'bg-disabled-selected';
      that.commandEnabledClass = 'bg-enabled';
      that.commandTemplate.isActive = false;
    }
    console.log('isActive:',that.commandTemplate.isActive);
  }

  remove(tag: string): void {
    const that = this;
    const index = that.tags.indexOf(tag);
    if (index >= 0) {
      that.tags.splice(index, 1);
    }
  }

  addDataPoint() {
    const data = this.arguments;
    data.push({
      name: '',
      description: '',
      type: '',
      binding: '',
      bindingname: 'Select binding'
    });
    this.arguments = data;
    console.log("arguments :: "+this.arguments.length);
  }

  removeDataPoint(element) {
    const that = this;
    const index = that.arguments.indexOf(element);

    if (index >= 0) {
      that.arguments.splice(index, 1);
    }
    console.log("arguments :: "+that.arguments);
  }

  save() {
   
    const that = this;
    that.deviceService.errorMessage = null;
    if (that.commandTemplate.name === '') {
      that.nameError="Name Required";
      that.deviceService.errorMessage = "Please enter all required fields.";
    }

    if (that.arguments.length > 0) {

        that.commandTemplate.args = [];
        var count = 0;
        console.log("arguments :: "+this.arguments.length);
        
        that.arguments.forEach(value => {
          console.log("arguments name :: "+value.name);
          that.commandTemplate.args.push({
                'name': value.name,
                'description': [{
                    'lang': 'en_us',
                    'text': value.description
                }],
                'type': value.type,
                'index': count
            });
            count++;

        });
    }
    if (that.commandTemplate.requiresAck === 'true') {
        that.commandTemplate.requiresAck = true;
    }
    if (that.commandTemplate.requiresAck === 'false') {
        that.commandTemplate.requiresAck = false;
    }
    // if (undefined != that.targetDocInfo) {
    //     that.commandTemplate.documentInfoId = that.targetDocInfo.id;
    // }
    if (that.deviceService.errorMessage === null||that.deviceService.errorMessage === '') {
      that.commandCreated=true;
      that.iotService.getCuiObjResponse().createCommandTemplate({
            data: that.commandTemplate
        }).then(function (response) {
          that.showLoading=true;
          console.log("created CommandTemplate : "+response);

          // activate for newly created Command
          if(that.commandTemplate.isActive === true) {
            that.iotService.getCuiObjResponse().activateCommandTemplate({
              commandTemplateId: response.id
            }).then(function (activateResponse) {
              console.log("Command id : "+response.id+" is activated");
            }).fail(function (err){
              that.commandCreated=false;
              if(err.responseJSON.apiStatusCode){
                var apiStatusCode = err.responseJSON.apiStatusCode;
               
                if(apiStatusCode === 'framework:resource:commandTemplate:duplicate') {
                  that.deviceService.warningMessage="Command created but disabled, duplicate command exists.";
                }
                else {
                  that.deviceService.warningMessage="Command created but disabled. Error:Msg:"+ err.responseJSON.apiStatusCode;
                  console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
                } 
              } else{
                console.log("Something went wrong. Please check chrome console or check with dev team");
              }
            });
          }

          // Save Tags for newly created Command
          if(response.id != null && that.tags != []) {
            that.tags.forEach(element => {
              that.iotService.getCuiObjResponse().createCommandTemplateTag({
                  commandTemplateId: response.id,
                  tag: element
              }).then(function (response) {
                console.log("Added tag : "+element);
              })
            });
          }

          setTimeout(()=>{ 
            that.showLoading=false;
            that.deviceService.createResource=false;
            that.deviceService.createCommand=false;
          }, 5000);

        }).fail(function (err) {
          that.commandCreated=false;
          if(err.responseJSON.apiStatusCode){
            that.deviceService.errorMessage=err.responseJSON.apiMessage;
            console.log("Error Occured: Status: "+err.responseJSON.status+" Msg: "+ err.responseJSON.apiStatusCode);
          } else{
            console.log("Something went wrong. Please check chrome console or check with dev team");
          }
          if(err === null) {
            console.log("Error in createCommandTemplate");
          }
        });
    } else {
        that.deviceService.errorMessage = that.deviceService.errorMessage;
    }
  }

}

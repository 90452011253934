import { Injectable } from '@angular/core';
import { SelectionModel} from '@angular/cdk/collections';
import { IotService } from '../service/iot.service';
import { ListTradingPartnerRelationshipsService } from './ListTradingPartnerRelationships.service';
import { TradingPartnersService } from './tradingPartners.service';
import { OrchestrationConfigsService } from './orchestrationConfigs.service';

@Injectable({
	  providedIn: 'root'
})

export class TradingPartnerRelationshipsService extends ListTradingPartnerRelationshipsService {

	selection: SelectionModel<String> = new SelectionModel<String>(true, []);

	constructor(public iotService:IotService, public tpService: TradingPartnersService,
		public orchestrationConfigsService: OrchestrationConfigsService) {
		super(iotService);
		this.selection.clear();
		this.loadSelected();
	}

	loadSelected(){
	 // if (this.orchestrationConfigsService.getTradingPartnerRelationship()) {
		//  this.selection.select(this.orchestrationConfigsService.getTradingPartnerRelationship().id);
	 // }
	}

	fnOnSelectionSupport(results){
		const that = this;
		if(this.orchestrationConfigsService.getTradingPartnerRelationship() != undefined){
			this.orchestrationConfigsService.setTradingPartnerRelationship(undefined);
		}
		setTimeout(()=>{
			this.orchestrationConfigsService.setTradingPartnerRelationship(results);
		}, 500);
	}

	fnRemoveSelectionSupport(results){
		const that = this;
		this.orchestrationConfigsService.setTradingPartnerRelationship(undefined);
	}

	createNew(){
		this.tpService.createTpRelationShip=true;
		this.tpService.createResource=false;
	}

	showErrorMessage(err){
		if(err.responseJSON.apiStatusCode){
			this.tpService.errorMessage=err.responseJSON.apiMessage;
		}
		else{
			this.tpService.errorMessage="Some thing went wrong, Please check console ";
		}
	 }




}

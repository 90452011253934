<div class="credsData">
    <header>
        <h2>{{ 'stream.credentials' | translate }}</h2>
    </header><br>
    <h3>Stream Created Successfully</h3>
    <div *ngIf="showCopyToClipBoard">
        <label for="stream.cred.info">{{ 'stream.cred.info' | translate }}</label>
           <br>
   </div>
         
    <div *ngIf="streamService.streamResponseUsername != undefined">
        <label for="name">{{ 'user.name' | translate }} : </label> {{streamService.streamResponseUsername}}
        <br>
    </div>

    <div *ngIf="streamService.streamResponsePassword != undefined">
        <label for="password">{{ 'password' | translate }} : </label> {{streamService.streamResponsePassword}}
        <br>
    </div>

    <div *ngIf="streamService.streamResponseProducerTopic != undefined">
        <label for="name">{{ 'producer.topic' | translate }} : </label> {{streamService.streamResponseProducerTopic}}
        <br>
    </div>

    <div *ngIf="streamService.streamResponseClientId != undefined">
        <label for="clientId">{{ 'clientId' | translate }} : </label> {{streamService.streamResponseClientId}}
        <br>
    </div>

    <div *ngIf="streamService.streamResponseHost != undefined">
        <label for="host">{{ 'host' | translate }} : </label> {{streamService.streamResponseHost}}
        <br>
    </div>


    <div *ngIf="streamService.streamResponsePort != undefined">
        <label for="port">{{ 'port' | translate }} : </label> {{streamService.streamResponsePort}}
        <br>
    </div>

    <div *ngIf="streamService.getStreamEncryptionKey() != undefined">
        <label for="encryptionKey">{{ 'encryption Key' | translate }} : </label> {{streamService.getStreamEncryptionKey()}}
        <br><br>
    </div>

    <div *ngIf="streamService.streamResponseBasicAuthnHost != undefined">
        <label for="basicHost">{{ 'basic.authn.host' | translate }} : </label> {{streamService.streamResponseBasicAuthnHost}}
        <br>
    </div>

    <div *ngIf="streamService.streamResponseBasicAuthnPort != undefined">
        <label for="basicPort">{{ 'basic.authn.port' | translate }} : </label> {{streamService.streamResponseBasicAuthnPort}}
        <br>
    </div>

    <div *ngIf="streamService.streamResponseSendingPostMessageUri != undefined">
        <label for="basicMessageUrl">{{ 'basic.message.url' | translate }} : </label> {{streamService.streamResponseSendingPostMessageUri}}
        <br>
    </div>

    <div *ngIf="streamService.consumerTopic != undefined">
        <label for="consumerTopic">{{ 'consumer.topic' | translate }} : </label> {{streamService.consumerTopic}}
        <br>
    </div>
    <div style="padding-left: 2%;">
        <!-- <button mat-raised-button color="primary" [disabled]="!copyToclipboardFlag" (click)="navigateToApplicationListing()">{{ 'continue' | translate}}</button> -->
        <button class="btn btn-secondary" *ngIf="showCopyToClipBoard" [cdkCopyToClipboard]="getStreamCredentials()" (click)="copyToClipBoard()" mat-raised-button>
            <fa-icon [icon]="faCopyIcon"></fa-icon> {{'cdkCopyToClipboard' | translate}}
        </button>
        <button mat-raised-button color="primary" [disabled]="!copyToclipboardFlag" (click)="navigateToApplicationListing()">{{ 'continue' | translate}}</button>

        <!-- <button mat-raised-button color="primary" (click)="copyToClipBoard()">{{ 'copy.toClipboard' | translate}}</button> -->

    </div>

</div>
<div class="tradingPartnerCodeData" *ngIf="tradingPartnerCodeRelationShip &&fromTpCodeLoaded && toTpCodeLoaded">
    <div class=form>

        <label for="code"> {{ 'codeRelationships.controlNumber' | translate}}</label>
        <input placeholder="optional" [(ngModel)]="tradingPartnerCodeRelationShip.controlNumber" (click)="resetData()"
            id="code" type="text" aria-label="code">

        <label for="description"> {{ 'description' | translate}}</label>
        <textarea *ngIf="tradingPartnerCodeRelationShip.description" rows="2"
            [(ngModel)]="tradingPartnerCodeRelationShip.description[0].text" placeholder="optional" id="description"
            aria-label="Description"></textarea>
        <textarea *ngIf="!tradingPartnerCodeRelationShip.description" rows="2" placeholder="optional" id="description"
            aria-label="Description">-</textarea>

        <label>{{'enabled'|translate}}</label>
        <div *ngIf="tradingPartnerCodeRelationShip.status=='ACTIVE'">
            <button class="toggle-on toggle-switch"
                (click)="deactivateTPCodeRelationship(tradingPartnerCodeRelationShip.id)">
                <fa-icon [icon]="faCheckIcon" class="fa-2x"></fa-icon>
                <div class="toggle-on-btn"></div>
            </button>
        </div>

        <div *ngIf="tradingPartnerCodeRelationShip.status=='INACTIVE'">
            <button class="toggle-off toggle-switch"
                (click)="activateTPCodeRelationship(tradingPartnerCodeRelationShip.id)">
                <div class="toggle-off-btn"></div>
            </button>
        </div>

        <br>
        <app-fromTPCodes [pageSize]="5" [showControlBar]=false [displayedColumns]="['ADD','code','qualifier','ACTIVE']"
            [showSelectColoumn]=true [showEnableColumn]=true multiSelect="false"
            addnQueryParam=[tradingPartnerId::{{tpService.tradingPartnerIds}}]></app-fromTPCodes>

        <app-toTPCodes [pageSize]="5" [showControlBar]=false [displayedColumns]="['ADD','code','qualifier','ACTIVE']"
            [showSelectColoumn]=true [showEnableColumn]=true multiSelect="false"
            addnQueryParam=[tradingPartnerId::{{tpService.tradingPartnerIds}}]></app-toTPCodes>
            <br>
            <br>
        <div class="button-row pull-right">
            <button class="btn btn-primary" (click)="save()">{{ 'save' | translate}}</button>
            <button class="btn btn-secondary" (click)="goBack()">{{ 'go.back' | translate}}</button>
        </div>

        <br>
        <br>
    </div>
    <br>
    <br>
</div>
<ng-container *ngIf="!tradingPartnerCodeRelationShip || !fromTpCodeLoaded||!toTpCodeLoaded">
    <div>
        <br /><br />
    </div>
    <div class="d-flex justify-content-center pageLoading">
        <div class="spinner-border" role="status">
            <span class="sr-only" id="loading"></span>
        </div>
    </div>
</ng-container>